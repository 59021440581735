var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ExpandableItem_1;
import { action, makeObservable, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { Virtuoso } from 'react-virtuoso';
import { ScaleLoader } from 'react-spinners';
import { FaFolder } from '@react-icons/all-files/fa/FaFolder';
import { FaFolderOpen } from '@react-icons/all-files/fa/FaFolderOpen';
import { MdCheckBox } from '@react-icons/all-files/md/MdCheckBox';
import { MdCheckBoxOutlineBlank } from '@react-icons/all-files/md/MdCheckBoxOutlineBlank';
import { BsBoxArrowRight } from '@react-icons/all-files/bs/BsBoxArrowRight';
import { AppColor, ROOTFolder } from '../const/AppDefaults';
class TreeUIItem {
    constructor() {
        this.id = "";
        this.label = "";
        this.children = new Map();
        this.parentid = "";
        this.displaycount = 0;
        this.hide = false;
        this.color = 'orange';
        this.selectedCount = 0;
        makeObservable(this);
    }
    setId(id) {
        this.id = id;
    }
    setDisplayCount(count) {
        this.displaycount = count;
    }
    setSelectedCount(count) {
        this.selectedCount = count;
    }
    addChild(child) {
        this.children.set(child.id, child);
    }
    setParentid(id) {
        this.parentid = id;
    }
    removeChild(id) {
        this.children.delete(id);
    }
    setLabel(label) {
        this.label = label;
    }
    hideOrShowItem(hide) {
        this.hide = hide;
    }
    setColor(color) {
        this.color = color;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], TreeUIItem.prototype, "id", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], TreeUIItem.prototype, "label", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], TreeUIItem.prototype, "children", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TreeUIItem.prototype, "parentid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TreeUIItem.prototype, "displaycount", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TreeUIItem.prototype, "hide", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TreeUIItem.prototype, "color", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TreeUIItem.prototype, "selectedCount", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "setId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "setDisplayCount", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "setSelectedCount", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [TreeUIItem]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "addChild", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "setParentid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "removeChild", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "setLabel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "hideOrShowItem", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TreeUIItem.prototype, "setColor", null);
export class ExpandableTreeState {
    constructor() {
        this.ROOTID = "___treeroot___";
        this.nodes = new Map();
        this.selecteditems = new Set();
        this.expandednode = new Set();
        this.rootNodes = [];
        this.itemmap = new Map();
        this.arranging = false;
        this.activeitem = "";
        makeObservable(this);
    }
    setActiveItem(id) {
        if (this.activeitem === id) {
            this.activeitem = "";
        }
        else {
            this.activeitem = id;
        }
    }
    setNodes(nodes) {
        this.nodes.clear();
        this.rootNodes = [];
        nodes.forEach((node) => this.nodes.set(node.id, node));
        this.setArranging(true);
        this.arrangeNodes();
        this.setArranging(false);
    }
    setArranging(arrang) {
        this.arranging = arrang;
    }
    setExpandedNode(exp, expand) {
        if (expand) {
            this.expandednode.add(exp);
        }
        else {
            this.expandednode.delete(exp);
        }
    }
    setSelectedId(id) {
        this.selecteditems.add(id);
    }
    setUnSelectedId(id) {
        this.selecteditems.delete(id);
    }
    clearSelected() {
        this.selecteditems.clear();
    }
    collapseAll() {
        this.expandednode.clear();
    }
    showAll() {
        this.itemmap.forEach((ite, k) => ite.hideOrShowItem(false));
    }
    hideAll() {
        this.itemmap.forEach((ite, k) => ite.hideOrShowItem(true));
    }
    hideOrShowItems(id, hide) {
        var _a;
        (_a = this.itemmap.get(id)) === null || _a === void 0 ? void 0 : _a.hideOrShowItem(hide);
    }
    updateCount(id, count) {
        const trui = this.itemmap.get(id);
        if (trui !== undefined) {
            trui.setDisplayCount(count);
        }
    }
    reset() {
        this.nodes.clear();
        this.clearSelected();
        this.rootNodes = [];
        this.setSelectedId("");
        this.setActiveItem("");
    }
    buildUIObjForNodeAndAddToList(node) {
        const ret = new TreeUIItem();
        ret.setId(node.id);
        ret.setLabel(node.label);
        ret.setParentid(node.parentid);
        if (node.displaycount) {
            ret.setDisplayCount(node.displaycount);
        }
        node.children.forEach((child) => {
            ret.addChild(this.buildUIObjForNodeAndAddToList(child));
        });
        this.nodes.set(node.id, node);
        if (node.parentid === this.ROOTID) {
            this.rootNodes.push(ret);
        }
        this.itemmap.set(ret.id, ret);
        if (node.color !== undefined) {
            ret.setColor(node.color);
        }
        return ret;
    }
    addChild(node) {
        var _a;
        if (this.nodes.has(node.parentid) || node.parentid === this.ROOTID) {
            (_a = this.nodes.get(node.parentid)) === null || _a === void 0 ? void 0 : _a.children.push(node);
            const childui = this.buildUIObjForNodeAndAddToList(node);
            const trui = this.itemmap.get(node.parentid);
            if (trui !== undefined) {
                trui.setDisplayCount(trui.displaycount + 1);
                trui.addChild(childui);
            }
        }
    }
    removeChild(node) {
        var _a;
        if (this.itemmap.has(node.parentid) || node.parentid === this.ROOTID) {
            (_a = this.itemmap.get(node.parentid)) === null || _a === void 0 ? void 0 : _a.removeChild(node.id);
            if (node.parentid === this.ROOTID) {
                // lets try finsing this item!
                let index = -1;
                let found = false;
                for (const rn of this.rootNodes) {
                    index++;
                    if (rn.id === node.id) {
                        found = true;
                        break;
                    }
                }
                if (found) {
                    this.rootNodes.splice(index, 1);
                }
            }
            const trui = this.itemmap.get(node.parentid);
            if (trui !== undefined) {
                trui.setDisplayCount(trui.displaycount - 1);
            }
        }
    }
    increamentCount(count, id) {
        const trui = this.itemmap.get(id);
        if (trui !== undefined) {
            trui.setDisplayCount(trui.displaycount + count);
        }
    }
    updateNode(node) {
        var _a;
        if (this.nodes.has(node.id)) {
            this.nodes.set(node.id, node);
        }
        if (this.itemmap.has(node.id)) {
            const titem = this.itemmap.get(node.id);
            titem === null || titem === void 0 ? void 0 : titem.setDisplayCount((_a = node.displaycount) !== null && _a !== void 0 ? _a : 0);
            titem === null || titem === void 0 ? void 0 : titem.setLabel(node.label);
            if (node.parentid !== (titem === null || titem === void 0 ? void 0 : titem.parentid)) {
                this.removeChild(node);
                this.addChild(node);
            }
        }
    }
    arrangeNodes() {
        var _a;
        const rootnode = {
            id: this.ROOTID,
            label: 'Home',
            children: [],
            parentid: ""
        };
        // we assume the above comes with children properly arranged?
        const mapofnodes = new Map();
        this.nodes.forEach((node, k) => {
            mapofnodes.set(k, node);
            node.children = [];
        });
        mapofnodes.set(this.ROOTID, rootnode);
        this.nodes.forEach((node, k) => {
            const parent = mapofnodes.get(node.parentid);
            if (parent !== undefined) {
                parent.children.push(node);
            }
            else {
            }
        });
        (_a = mapofnodes.get(this.ROOTID)) === null || _a === void 0 ? void 0 : _a.children.forEach((child) => this.buildUIObjForNodeAndAddToList(child));
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], ExpandableTreeState.prototype, "nodes", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], ExpandableTreeState.prototype, "selecteditems", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], ExpandableTreeState.prototype, "expandednode", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], ExpandableTreeState.prototype, "rootNodes", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ExpandableTreeState.prototype, "arranging", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ExpandableTreeState.prototype, "activeitem", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "setActiveItem", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "setNodes", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "setArranging", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Boolean]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "setExpandedNode", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "setSelectedId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "setUnSelectedId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "clearSelected", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "collapseAll", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "addChild", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "removeChild", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, String]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "increamentCount", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ExpandableTreeState.prototype, "updateNode", null);
let ExpandableTree = class ExpandableTree extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Box, { style: {
                maxHeight: this.props.height, width: this.props.width,
                maxWidth: this.props.width, height: this.props.height, overflowX: 'auto'
            } },
            this.props.state.arranging && React.createElement(Flex, { justify: 'center', align: 'center', h: '100%', w: '100%' },
                React.createElement(ScaleLoader, { color: 'green' })),
            !this.props.state.arranging && React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: this.props.height }, totalCount: this.props.state.rootNodes.length, data: this.props.state.rootNodes.slice().sort((a, b) => {
                    if (this.props.sort !== undefined) {
                        return this.props.sort(a.id, b.id);
                    }
                    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                }), itemContent: (index, item) => React.createElement(Observer, null, () => React.createElement(Box, null,
                    React.createElement(ExpandableItem, { folderIconClose: this.props.folderIconClose, folderIconOpen: this.props.folderIconOpen, hidecheckb: this.props.hidecheckb, sort: this.props.sort, onSelect: (id) => {
                            this.props.state.setActiveItem(id);
                            this.props.onSelect(id, this.props.state.activeitem === id);
                        }, onChecked: (id, checked) => {
                            this.props.onChecked(id, checked);
                            if (checked) {
                                this.props.state.setSelectedId(id);
                            }
                            else {
                                this.props.state.setUnSelectedId(id);
                            }
                        }, key: item.id, intent: 0, state: this.props.state, item: item, showdocumentsicon: this.props.showdocumenticon, documentIconClicked: (id) => {
                            var _a, _b;
                            this.props.state.setActiveItem(id);
                            (_b = (_a = this.props).documentIconClicked) === null || _b === void 0 ? void 0 : _b.call(_a, id);
                        } }))) })));
    }
};
ExpandableTree = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ExpandableTree);
export { ExpandableTree };
let ExpandableItem = ExpandableItem_1 = class ExpandableItem extends React.Component {
    constructor(prop) {
        super(prop);
    }
    expandFolder() {
        this.props.state.setExpandedNode(this.props.item.id, true);
    }
    collapseFolder() {
        this.props.state.setExpandedNode(this.props.item.id, false);
    }
    render() {
        var _a, _b;
        if (this.props.item.hide) {
            return React.createElement(Box, null);
        }
        return React.createElement(Box, { mt: '10px' },
            React.createElement(Flex, { onClick: () => {
                    this.props.onSelect(this.props.item.id);
                    this.props.state.setExpandedNode(this.props.item.id, !this.props.state.expandednode.has(this.props.item.id));
                }, bg: this.props.state.activeitem === this.props.item.id ? '#829eb975' : '', _hover: { cursor: 'pointer', opacity: '0.9', backgroundColor: '#829eb975' }, align: 'center' },
                !this.props.hidecheckb &&
                    this.props.item.id !== ROOTFolder && React.createElement(Icon, { _hover: { cursor: 'pointer' }, onClick: (e) => {
                        e.stopPropagation();
                        this.props.onChecked(this.props.item.id, !this.props.state.selecteditems.has(this.props.item.id));
                    }, color: AppColor.CheckBoxColor, h: '20px', w: '20px', as: this.props.state.selecteditems.has(this.props.item.id) ? MdCheckBox : MdCheckBoxOutlineBlank }),
                React.createElement(Icon, { ml: '5px', onClick: (e) => {
                    }, _hover: { cursor: 'pointer' }, color: this.props.item.color, as: this.props.state.expandednode.has(this.props.item.id) ? (_a = this.props.folderIconOpen) !== null && _a !== void 0 ? _a : FaFolderOpen : (_b = this.props.folderIconClose) !== null && _b !== void 0 ? _b : FaFolder, h: '25px', w: '25px' }),
                React.createElement(Flex, { width: '90%' },
                    React.createElement(Text, { ml: '5px' }, this.props.item.label),
                    this.props.item.displaycount > 0 && React.createElement(Text, { ml: '5px' }, "(" + this.props.item.displaycount + ")"),
                    this.props.item.selectedCount > 0 && React.createElement(Text, { ml: '5px' }, "(S" + this.props.item.selectedCount + ")")),
                this.props.showdocumentsicon && React.createElement(Icon, { _hover: { cursor: 'pointer' }, onClick: (e) => {
                        var _a, _b;
                        e.stopPropagation();
                        this.props.state.setExpandedNode(this.props.item.id, !this.props.state.expandednode.has(this.props.item.id));
                        (_b = (_a = this.props).documentIconClicked) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.item.id);
                    }, color: AppColor.CheckBoxColor, h: '20px', w: '20px', as: BsBoxArrowRight })),
            this.props.state.expandednode.has(this.props.item.id) && React.createElement(Box, { mt: '2px', ml: (this.props.intent + 20) + 'px' }, Array.from(this.props.item.children.values()).slice().sort((a, b) => {
                if (this.props.sort !== undefined) {
                    return this.props.sort(a.id, b.id);
                }
                return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            })
                .map((val) => React.createElement(ExpandableItem_1, Object.assign({}, this.props, { key: val.id, item: val, intent: this.props.intent + 20, state: this.props.state })))));
    }
};
ExpandableItem = ExpandableItem_1 = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ExpandableItem);
