var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class DocumentInfoStore {
    constructor() {
        this.width = 300;
        this.top = 0;
        this.right = 0;
        this.show = false;
        this.posright = false;
        makeObservable(this);
    }
    setPosRight(right) {
        this.posright = right;
    }
    setShow(show) {
        this.show = show;
    }
    setWidth(wd) {
        this.width = wd;
    }
    setRight(right) {
        this.right = right;
    }
    setTop(top) {
        this.top = top;
    }
    reset() {
        this.setShow(false);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], DocumentInfoStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], DocumentInfoStore.prototype, "top", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], DocumentInfoStore.prototype, "right", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], DocumentInfoStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], DocumentInfoStore.prototype, "posright", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], DocumentInfoStore.prototype, "setPosRight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], DocumentInfoStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], DocumentInfoStore.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], DocumentInfoStore.prototype, "setRight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], DocumentInfoStore.prototype, "setTop", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], DocumentInfoStore.prototype, "reset", null);
