import { SecStore } from "@/background/store/SecurityStore";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { EncryptBox } from "@@/EncryptBox";
import { ServerPath } from "@@/ServerPath";
import { comm } from "@/Comm/comm";
import { AuthHeaders as AuthHeader } from "@@/AuthHeader";
import { CommonCrypto } from "@@/Cryptography";
import { UserRole } from "@/share/InternalUserStat";
import { MKService } from "./MasterKeyStore";
import { UserDevice } from "@@/Device";
import { ADMIN_SECRET_STORE } from "./admin/AdminSecurityStore";
import { AdminSecurity } from "./admin/AdminSecurity";
import { Letr, LetrMetadata } from "@@/Letr";
import { LStore } from "@/background/store/LetrStore";
import { ProjectObjectType, RoleStatus } from "@@/Project";
import { MStore } from "./MessageStore";
import { SchemaItemType } from "@@/Schema";
import { SchemaStore } from "./SchemaStore";
import { ProjectStore } from "./ProjectStore";
import { NoteStore } from "./Notestore";
import { AppConstants } from "@@/Constants";
import { timeout } from "./MediaStore";
import { CONVERTUTIL } from "./ConvertUtil";
import { decryptCell } from "@@/RuleParser";
export class LetrsRequest {
    constructor() {
        this.userid = "";
        this.publickey = "";
    }
}
export class LetrsNRequest extends LetrsRequest {
}
class Store {
    constructor() {
        this.orgcontactkey = "";
        this.letrsBoxById = new Map();
        this.letrsSearchBoxById = new Map();
        this.filemetakeys = new Map();
        this.systemMessagesString = "<<<<<<<<letrwingsystem>>>>>>>____>>>>>";
        this.activedisplayworksset = "";
        this.activeloadingworksheetid = "";
    }
    async loadInternalusers() {
        console.log('here');
        const resp = await comm.get(ServerPath.getInternalUsers);
        console.log(resp);
        if (resp === undefined) {
            return [];
        }
        return resp;
    }
    async checkIsAdmin() {
        const ret = await comm.get(ServerPath.isUserAdmin);
        if (ret !== undefined) {
            return true;
        }
        return false;
    }
    async cancelinvite(userid) {
        const req = { userid: userid };
        const res = await comm.post(ServerPath.cancelinvite, req);
        if (res !== undefined) {
            return res;
        }
        return false;
    }
    getLetrPassword(letrid) {
        const uref = this.letrsBoxById.get(letrid);
        return uref;
    }
    async getExternalContactDefaultPassword() {
        // const mk = await MKService.getMasterKey();
        // if (mk === undefined) {
        //     return undefined;
        // }
        // const header = await shareLib.getRequestHeaders();
        // const res = await LetrBoxStorage.get<Map<string, string>,ExternalContactPassword >(
        //     ServerPath.getOrgExternalContactPassword,
        //     new Map(), header);
        // if (res !== undefined && res !== 'InvalidDevice') {
        //     const password = LetrwingCrypto.decryptSecretBox(res.password, mk);
        //     return [password??"",res.passwordhint];
        // }
        return undefined;
    }
    async updateExternalContactPassword(password, passwordhint) {
        // const mk = await MKService.getMasterKey();
        // if (mk === undefined) {
        //     return false;
        // }
        // const pbox = LetrwingCrypto.secretBox(password, mk);
        // const req: ExternalContactPassword = {
        //     password: pbox,
        //     passwordhint: passwordhint
        // };
        // const header = await shareLib.getRequestHeaders();
        // const res = await LetrBoxStorage.postRequest<ExternalContactPassword, boolean>(ServerPath.addOrgExternalContactPassword,
        //     req, header);
        // if (res !== undefined && res !== 'InvalidDevice') {
        //     return res;
        // }
        return false;
    }
    async getAdminOrgDetail() {
        const res = await comm.get(ServerPath.getOrgAdminDetail);
        if (res !== undefined) {
            return res;
        }
        return undefined;
    }
    async activateUser(userid) {
        const parts = userid.split("__<<>>__");
        if (parts.length > 1) {
            userid = parts[1];
        }
        const req = {
            name: "",
            newrole: UserRole.User,
            disableuser: true,
            userid: userid
        };
        const res = await comm.post(ServerPath.activateUser, req);
        if (res !== undefined) {
            return res;
        }
        return -1;
    }
    async deleteUser(userid) {
        const parts = userid.split("__<<>>__");
        if (parts.length > 1) {
            userid = parts[1];
        }
        const req = {
            name: "",
            newrole: UserRole.User,
            disableuser: true,
            userid: userid
        };
        const res = await comm.post(ServerPath.deleteuser, req);
        if (res !== undefined) {
            return res;
        }
        return false;
    }
    async enableUser(activeuserid) {
        const parts = activeuserid.split("__<<>>__");
        if (parts.length > 1) {
            activeuserid = parts[1];
        }
        const req = {
            name: '',
            newrole: UserRole.Admin,
            disableuser: false,
            userid: activeuserid
        };
        const res = await comm.post(ServerPath.enableUser, req);
        if (res !== undefined) {
            return res;
        }
        return false;
    }
    async editUser(name, role, active, activeuserid) {
        const parts = activeuserid.split("__<<>>__");
        if (parts.length > 1) {
            activeuserid = parts[1];
        }
        const req = {
            name: name,
            newrole: role,
            disableuser: !active,
            userid: activeuserid
        };
        const res = await comm.post(ServerPath.edituser, req);
        if (res !== undefined) {
            return res;
        }
        return false;
    }
    async editOrgContact(id, name, email, number, defaultpassword, defaultpasswordhint) {
        if (!this.orgcontactkey) {
            await this.getOrgContactKey();
        }
        if (!this.orgcontactkey) {
            return undefined;
        }
        const cont = {
            name: name,
            email: email,
            password: {
                value: defaultpassword,
                hint: defaultpasswordhint,
                key: 'Password'
            },
            phonenumber: {
                countrycode: "+91",
                number: number + ""
            },
            connectemail: email ? true : false,
            connectsms: number ? true : false,
            connectwhatsapp: number ? true : false,
            extrainfo: [],
            id: id
        };
        const req = this.convertToDTO(cont);
        const res = await comm.post(ServerPath.editOrgContact, req);
        if (res !== undefined) {
            return this.convertDTOToUI(res);
        }
        return undefined;
    }
    async loadOrgContacts() {
        if (!this.orgcontactkey) {
            await this.getOrgContactKey();
        }
        const resp = await comm.get(ServerPath.getOrganisationContacts);
        if (resp !== undefined) {
            const ret = [];
            for (const re of resp) {
                const result = this.convertDTOToUI(re);
                if (result !== undefined) {
                    ret.push(result);
                }
            }
            return ret;
        }
        return [];
    }
    async resetUserPassword(password, userid) {
        const randomnonce = LetrwingCrypto.getRandomPassword();
        const matrix = InMemoryRepo.matrixbox;
        if (!matrix || matrix.length === 0) {
            return undefined;
        }
        const deviceregisterpassword = CommonCrypto.generatePasswordUsingMatrix(password, matrix, 2);
        await ADMIN_SECRET_STORE.checkAndgetuserSecret(userid);
        // now we have new device password 
        const userkey = ADMIN_SECRET_STORE.userNonhashedSecret.get(userid);
        if (!userkey) {
            return undefined;
        }
        const mainkey = CommonCrypto.produceUserKey(password, randomnonce);
        const passwordStorageKey = CommonCrypto.generatePasswordUsingMatrix(password, matrix, 4);
        const pkey = await LetrwingCrypto.getHash256(passwordStorageKey);
        const randpassword = LetrwingCrypto.secretBox(randomnonce, pkey);
        const passwordstorage = LetrwingCrypto.secretBox(userkey, mainkey);
        const req = {
            mainbox: LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(passwordstorage))),
            randpassword: LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(randpassword))),
            devicepassword: deviceregisterpassword,
            userid: userid
        };
        return await this.updateUserPassword(req);
    }
    async updateUserPassword(req) {
        const update = await comm.post(ServerPath.adminresetpassword, req);
        return update;
    }
    async getOrgContactKey() {
        const mk = await MKService.getMasterKeyClean();
        if (mk) {
            let resp = await comm.get(ServerPath.getOrgContactKeysBox);
            if (resp !== undefined) {
                // missing encrptmsg recreate this now
                if (!resp.encryptmsg) {
                    const okey = LetrwingCrypto.generateNewSecretKey();
                    const obox = LetrwingCrypto.secretBox(okey, mk);
                    const req = {
                        contactbox: obox
                    };
                    const sres = await comm.post(ServerPath.insertOrgContactKeysBox, req);
                    if (sres !== undefined) {
                        resp = sres.contactbox;
                    }
                    else {
                        resp = undefined;
                    }
                }
                if (resp !== undefined) {
                    const okey = LetrwingCrypto.decryptSecretBox(resp, mk);
                    if (okey !== undefined) {
                        this.orgcontactkey = okey;
                    }
                }
            }
        }
    }
    convertToDTO(cont) {
        const pbox = LetrwingCrypto.secretBox(JSON.stringify(cont.password), this.orgcontactkey);
        const pdto = Object.assign(Object.assign({}, cont), { pbox: pbox, info: new EncryptBox() });
        return pdto;
    }
    // public async showOrgBookToUser(id: string) {
    //     if (!this.orgcontactkey) {
    //         await this.getOrgContactKey();
    //     }
    //     const headers = await shareLib.getRequestHeaders();
    //     const tenat = await shareLib.getTenantId();
    //     const grpids = [tenat + AppConstants.TenantSep + id]
    //     const creq: CommBundleRequest = {
    //         grpids: grpids
    //     }
    //     const commbundles = await LetrBoxStorage.postRequest<CommBundleRequest, UserCommBundleResponse[]>
    //         (ServerPath.memberCommBundles, creq, headers);
    //     if (commbundles === undefined || commbundles === 'InvalidDevice') {
    //         return undefined;
    //     }
    //     try {
    //         const cbundle = commbundles[0];
    //         const ebox = LetrwingCrypto.box(this.orgcontactkey, cbundle.bundle.initPubKey);
    //         const req: OrgContactNewBox = {
    //             id: id,
    //             cbox: ebox,
    //             refereneid: cbundle.bundle.reference
    //         };
    //         const res = await LetrBoxStorage.postRequest<OrgContactNewBox, string>(
    //             ServerPath.showOrgContactTo, req, headers);
    //         if (res !== undefined && res !== 'InvalidDevice') {
    //             return true;
    //         }
    //         return false;
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // }
    async update(quota) {
        const res = await comm.post(ServerPath.updateQuota, quota);
        if (res !== undefined) {
            return res;
        }
        return undefined;
    }
    async estimate(quotae) {
        const res = await comm.post(ServerPath.calculatePrice, quotae);
        if (res !== undefined) {
            return res;
        }
        return undefined;
    }
    async getQuota() {
        const res = await comm.get(ServerPath.getQuota);
        if (res !== undefined) {
            return res;
        }
        return undefined;
    }
    async updateEmail(email, address) {
        const res = await comm.post(ServerPath.updateContactEmail, { email: email, address: address });
        return await this.getAdminOrgDetail();
    }
    async updateWatermark(mark) {
        const res = await comm.post(ServerPath.addwatermark, { watermark: mark });
        if (res !== undefined) {
            return true;
        }
        return false;
    }
    async hideOrgBookFromUser(id) {
        const req = {
            data: id
        };
        const res = await comm.post(ServerPath.hideOrgContactsFrom, req);
        if (res !== undefined) {
            return true;
        }
        return false;
    }
    // public async addOrgContact(name: string, email: string, number: string, defaultpassword: string,
    //     defaultpasswordhint: string) {
    //     if (!this.orgcontactkey) {
    //         await this.getOrgContactKey();
    //     }
    //     if (!this.orgcontactkey) {
    //         return undefined;
    //     }
    //     const cont: OrgContact = {
    //         name: name,
    //         email: email,
    //         password: {
    //             value: defaultpassword,
    //             hint: defaultpasswordhint,
    //             key: 'Password'
    //         },
    //         phonenumber: {
    //             countrycode: "+91",
    //             number: number + ""
    //         },
    //         connectemail: email ? true : false,
    //         connectsms: number ? true : false,
    //         connectwhatsapp: number ? true : false,
    //         extrainfo: [],
    //         id: ""
    //     };
    //     const req = this.convertToDTO(cont);
    //     const header = await shareLib.getRequestHeaders();
    //     const res = await LetrBoxStorage.postRequest<OrganisationContactDTO, OrgContactResponse>(ServerPath.addNewOrgContact,
    //         req, header);
    //     if (res !== undefined && res !== 'InvalidDevice') {
    //         if (res.isInternal) {
    //             return "internal";
    //         }
    //         if (res.isLetrwinguser) {
    //             return "external";
    //         }
    //         if (res.contact === undefined) {
    //             return undefined;
    //         }
    //         return this.convertDTOToUI(res.contact);
    //     }
    //     return undefined;
    // }
    convertDTOToUI(dto) {
        const pbox = dto.pbox;
        const pbstr = LetrwingCrypto.decryptSecretBox(pbox, this.orgcontactkey);
        if (pbstr) {
            try {
                const cbox = JSON.parse(pbstr);
                // we need info in future here 
                const oc = Object.assign(Object.assign({}, dto), { password: cbox, extrainfo: [] });
                return oc;
            }
            catch (ex) {
            }
        }
        return undefined;
    }
    async addInternalUser(name, email, role) {
        const req = {
            email: email,
            name: name,
            role: role
        };
        const resp = await comm.post(ServerPath.inviteInternalUser, req);
        if (resp !== undefined) {
            return resp;
        }
        return undefined;
    }
    async upgradeToAdminSession() {
        const mk = await MKService.getMasterKeyClean();
        if (mk === undefined) {
            return undefined;
        }
        const password = LetrwingCrypto.hash512str(mk);
        const req = {
            data: password
        };
        const ret = await comm.post(ServerPath.validAdminpassword, req);
        if (ret !== undefined) {
            if (ret) {
                ADMIN_SECRET_STORE.revealSecretBoxes(mk);
                await this.checkAndAddAppContactbox(mk);
            }
            return ret;
        }
        return false;
    }
    async checkAndAddAppContactbox(mk) {
        const neky = LetrwingCrypto.getNewKeypair();
        const keybox = LetrwingCrypto.secretBox(JSON.stringify(neky), await LetrwingCrypto.getHash256(mk));
        const appbox = {
            publickey: neky.publickey,
            id: '',
            keybox: keybox
        };
        const res = await comm.post(ServerPath.addAppUserGeneralKey, appbox);
        if (!res)
            return undefined;
        try {
            const str = LetrwingCrypto.decryptSecretBox(res.keybox, await LetrwingCrypto.getHash256(mk));
            if (!str)
                return undefined;
            const kst = JSON.parse(str);
            const ret = {
                id: res === null || res === void 0 ? void 0 : res.id,
                keybox: kst,
                publickey: kst.publickey
            };
            return ret;
        }
        catch (ex) { }
        return undefined;
    }
    async getOrgDetails() {
        const res = await comm.get(ServerPath.getOrdDetail);
        console.log(res);
        if (res !== undefined) {
            return res;
        }
        return undefined;
    }
    async getUserDevices(userid) {
        // const devices = await comm.getUserDevices(userid);
        const req = { userid: userid };
        const devices = await comm.post(ServerPath.getUserDevices, req);
        await ADMIN_SECRET_STORE.checkAndgetuserSecret(userid);
        const dkey = ADMIN_SECRET_STORE.getUserDeviceKey(userid);
        let udevices = [];
        console.log("Here before " + dkey);
        console.log(devices);
        if (dkey && devices !== undefined && devices.length > 0) {
            udevices = await Promise.all(devices.map(async (device) => {
                const dec = await this.decryptDeviceInfo(device, dkey.privatkey);
                const ud = new UserDevice();
                ud.id = device.id;
                ud.numberofsession = device.numberofsession;
                if (dec) {
                    ud.metadat = dec;
                }
                return ud;
            }));
        }
        return udevices;
    }
    // public async removeDevice(userid: string, deviceid: string) {
    //     return await comm.editDevice(userid, deviceid, 'Remove');
    // }
    // public async logoutDevice(userid: string, deviceid: string) {
    //     return await comm.editDevice(userid, deviceid, 'Logout');
    // }
    async decryptDeviceInfo(device, dkp) {
        try {
            const info = device.extrainfo;
            if (dkp !== undefined) {
                const debox = JSON.parse(AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(info)));
                const dinfo = AdminSecurity.decryptBoxMessageWithprivateKey(debox.encryptmsg, debox.publickey, debox.nonce, dkp);
                if (dinfo === undefined) {
                    return undefined;
                }
                return JSON.parse(dinfo);
            }
            return undefined;
        }
        catch (ex) {
            console.log(ex);
            return undefined;
        }
    }
    async getUsers() {
        const resp = await comm.get(ServerPath.adminusers);
        return resp;
    }
    async getUserTags(userid) {
        const secret = await ADMIN_SECRET_STORE.checkAndgetuserSecret(userid);
        if (!secret) {
            return undefined;
        }
        const kp = AdminSecurity.getKeypair();
        const req = new LetrsRequest();
        req.publickey = kp.publicKey;
        req.userid = userid;
        const ret = await comm.post(ServerPath.userAdminTags, req);
        if (ret !== undefined) {
            // const mainpassword = ADMIN_SECRET_STORE.getUserSecret(userid);
            const dmsg = AdminSecurity.decryptBoxMessage(ret.encryptmsg, ret.publickey, ret.nonce, kp);
            if (dmsg !== undefined) {
                const tags = JSON.parse(dmsg);
                const mainpassword = ADMIN_SECRET_STORE.getUserSecret(userid);
                if (mainpassword === undefined) {
                    return undefined;
                }
                // lets get the subject out now!
                for (const tag of tags) {
                    const mainkey = AdminSecurity.decryptPasswordBox(tag.box.encryptmsg, tag.box.nonce, mainpassword);
                    if (mainkey === undefined) {
                        continue;
                    }
                    const ptags = [];
                    for (const t of tag.tag) {
                        if (t.parent === "file") {
                            const teb = JSON.parse(AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(t.name)));
                            const dtagname = AdminSecurity.decryptSecretbox(teb.encryptmsg, teb.nonce, mainkey.password, 'utf8');
                            if (dtagname) {
                                t.name = dtagname;
                                ptags.push(t);
                            }
                        }
                    }
                    return ptags;
                }
            }
        }
        return undefined;
    }
    async loadUserLetrsForTag(userid, filter) {
        const secret = await ADMIN_SECRET_STORE.checkAndgetuserSecret(userid);
        if (!secret) {
            return undefined;
        }
        const res = await this.getNLetrs(userid, filter);
        if (res !== undefined && res.length > 0) {
            const usecret = ADMIN_SECRET_STORE.getUserSecret(userid);
            if (usecret !== undefined) {
                const ret = await this.decryptLetrAndLastMessage(res, usecret);
                // AEvent.publishLetrs(ret);
                return ret;
            }
        }
        return undefined;
    }
    async getNLetrs(userid, filter) {
        const kp = AdminSecurity.getKeypair();
        const req = new LetrsNRequest();
        req.publickey = kp.publicKey;
        req.userid = userid;
        req.filter = filter;
        const res = await comm.post(ServerPath.userAdminVLetrs, req);
        return res;
    }
    async decryptLetrAndLastMessage(res, userkey) {
        const ret = [];
        if (res !== undefined) {
            for (const letr of res) {
                if (letr.mypasswordbox === undefined || !letr.mypasswordbox) {
                    continue;
                }
                const mbox = await SecStore.decryptPasswordBox(letr.mypasswordbox, userkey);
                if (mbox === undefined) {
                    continue;
                }
                // now we have the password lets make the letr
                const subject = LetrwingCrypto.decryptSecretBox(letr.subject, mbox.password);
                if (subject === undefined) {
                    continue;
                }
                let message = "";
                if (letr.message !== undefined) {
                    const lm = LetrwingCrypto.decryptSecretBox(letr.message, mbox.password);
                    if (lm !== undefined) {
                        message = lm;
                    }
                }
                if (letr.searchbox !== undefined) {
                    const sbox = LetrwingCrypto.decryptSecretBox(letr.searchbox, mbox.password);
                    if (sbox !== undefined) {
                        try {
                            const sres = JSON.parse(sbox);
                            this.letrsSearchBoxById.set(letr.id, sres);
                        }
                        catch (ex) {
                        }
                    }
                }
                this.letrsBoxById.set(letr.id, mbox.password);
                ret.push(LStore.convertUserLetrToLetr(letr, subject, message));
            }
        }
        return ret;
    }
    convertUserLetrToLetr(letr, subject, message = "") {
        const letrui = new Letr();
        letrui.id = letr.id;
        letrui.lastmessage = message;
        letrui.recipients = letr.memberids;
        letrui.author = letr.creatorid;
        letrui.letrMetadata = new LetrMetadata();
        letrui.letrMetadata.id = letr.id;
        letrui.letrMetadata.creationtime = letr.updatetime;
        letrui.letrMetadata.updatetime = letr.updatetime;
        letrui.letrMetadata.tags = letr.tags;
        letrui.letrMetadata.subject = subject;
        letrui.isExternal = this.checkHasExternal(letr.creatorid, letr.memberids);
        letrui.hasConnects = letr.hasConnects;
        return letrui;
    }
    checkHasExternal(author, member) {
        // const tenant = AppConfig.tenantid;
        const tenant = InMemoryRepo.getTenant();
        if (tenant !== undefined) {
            if (!author.startsWith(tenant)) {
                return true;
            }
            for (const mem of member) {
                if (!mem.startsWith(tenant)) {
                    return true;
                }
            }
        }
        return false;
    }
    async getLetrwingContacts() {
        const res = await comm.get(ServerPath.letrwingcontacts);
        return res;
    }
    async deleteLetrwingContact(connectid, userid) {
        const res = await comm.post(ServerPath.deleteLetrwingContact, { userid: userid, connectid: connectid });
        return res;
    }
    async editDevice(userid, deviceid, requestType) {
        const req = { userid: userid, deviceid: deviceid, type: requestType };
        const res = await comm.post(ServerPath.userdeviceedit, req);
        return res;
    }
    async loadLetrProjects(letrid, parentid, userid) {
        const box = UserAdminStore.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        console.log('heree  ' + letrid + " " + parentid + " " + userid);
        // lets make a comm request 
        const res = await comm.post(ServerPath.adminGetProjectObjects, { letrid: letrid, parentid: parentid, aobjectid: parentid, userid: userid });
        let ret = [];
        if (res !== undefined) {
            ret = this.convertProjecObjectToProjectEntity(res, box, letrid);
        }
        return ret;
    }
    getLetrSearchBox(letrid) {
        const uref = this.letrsSearchBoxById.get(letrid);
        return uref;
    }
    convertProjecObjectToProjectEntity(objs, accesskey, letrid, attachmetada = true) {
        var _a, _b;
        const ret = [];
        // lets try this work now
        const sbox = UserAdminStore.getLetrSearchBox(letrid);
        if (sbox === undefined) {
            return [];
        }
        for (const re of objs) {
            const pbox = re.data;
            let fld = [];
            if (attachmetada && sbox && re.metadata) {
                for (const mfld of re.metadata) {
                    try {
                        mfld.data.nonce = sbox.nonce;
                        const str = LetrwingCrypto.decryptSecretBox(mfld.data, sbox.key);
                        console.log('sbox.key ' + sbox.key);
                        console.log(str);
                        if (mfld.objs && mfld.objs.length > 0) {
                            mfld.objsdec = this.convertProjecObjectToProjectEntity(mfld.objs, accesskey, letrid, false);
                        }
                        if (mfld.schms && mfld.schms.length > 0) {
                            if (mfld.schms) {
                                const shs = [];
                                for (const sh of mfld.schms) {
                                    const sok = CONVERTUTIL.convertBoxToSchema(sh, letrid, accesskey);
                                    if (sok)
                                        shs.push(sok);
                                }
                                mfld.schsdec = shs;
                            }
                        }
                        fld.push(Object.assign(Object.assign({}, mfld), { datastr: str }));
                    }
                    catch (ex) { }
                }
            }
            const pboxopen = LetrwingCrypto.decryptSecretBox(pbox, accesskey);
            if (pboxopen !== undefined) {
                // now if the object is type document then it has fileinfo 
                // optherwise it;s just the name ?
                if (re.type === ProjectObjectType.Document) {
                    try {
                        const fileino = JSON.parse(pboxopen);
                        const dentity = {
                            id: re.id,
                            name: fileino.name,
                            accesscode: fileino.accesscode,
                            accesskey: fileino.accesskey,
                            versions: fileino.versions,
                            documentreference: fileino.accessid,
                            size: fileino.size,
                            type: ProjectObjectType.Document,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            accessids: re.accessids,
                            origname: (_a = fileino.origname) !== null && _a !== void 0 ? _a : fileino.name,
                            metadata: fld
                        };
                        ret.push(dentity);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Folder) {
                    try {
                        const fde = JSON.parse(pboxopen);
                        const fen = {
                            name: fde.name,
                            color: (_b = fde.color) !== null && _b !== void 0 ? _b : 'orange',
                            id: re.id,
                            childrencount: re.childrencount,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            type: ProjectObjectType.Folder,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(fen);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Bookmark) {
                    try {
                        const bdetail = JSON.parse(pboxopen);
                        const ben = {
                            name: bdetail.name,
                            url: bdetail.url,
                            id: re.id,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            type: ProjectObjectType.Bookmark,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(ben);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Draft) {
                    try {
                        const bdetail = JSON.parse(pboxopen);
                        const ben = {
                            name: bdetail.name,
                            type: ProjectObjectType.Draft,
                            id: re.id,
                            updatetime: re.updatetime,
                            accesskey: bdetail.accesskey,
                            draftreference: bdetail.reference,
                            drafttype: bdetail.type,
                            draftsize: 0,
                            parentid: re.parentid,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(ben);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        return ret;
    }
    async getFileMetadata(id, accesskey = "", userid) {
        const header = [];
        header.push({ key: AuthHeader.FileID, value: id });
        header.push({ key: AuthHeader.ReqUserID, value: userid });
        const res = await comm.get(ServerPath.adminGetFileInfo, header);
        console.log(res);
        if (res !== undefined) {
            res.isDownloadable = true;
        }
        if (accesskey && res !== undefined && res.box !== undefined && res.ingeststatus !== undefined) {
            const key = this.getFileKey(res.box, accesskey);
            if (key !== undefined) {
                const undata = LetrwingCrypto.decryptSecretBox(res.ingeststatus, key);
                this.filemetakeys.set(id, key);
                if (undata !== undefined) {
                    try {
                        const indata = JSON.parse(undata);
                        console.log(indata);
                        res.ingestunboxed = indata;
                        res.ingestunboxed.hasviewer = res.hasviewer;
                    }
                    catch (ex) {
                    }
                }
            }
        }
        return res;
    }
    async getPdfViewerContent(id, contenname, userid) {
        const header = [];
        header.push({ key: AuthHeader.FileID, value: id });
        const req = { name: contenname, userid: userid };
        const res = await comm.post(ServerPath.adminGetFileViewerContent, req, header);
        return res;
    }
    async getPdfViewerPage(req) {
        const key = await this.getFileDataKey(req.id, req.key, req.userid);
        if (key === undefined || !key) {
            return undefined;
        }
        const pebox = await this.getPdfViewerContent(req.id, req.pagename, req.userid);
        if (pebox === undefined || pebox.box === undefined) {
            return undefined;
        }
        // page raw bytes.. 
        let str = LetrwingCrypto.decryptSecretBox(pebox.box, key);
        if (str === undefined) {
            return undefined;
        }
        return str;
    }
    async getFileDataKey(id, accesskey, userid) {
        var _a;
        if (this.filemetakeys.has(id)) {
            return (_a = this.filemetakeys.get(id)) !== null && _a !== void 0 ? _a : "";
        }
        const meta = await this.getFileMetadata(id, accesskey, userid);
        if (meta === undefined || meta.box === undefined) {
            return; // return no status for this is broken file..
        }
        const key = this.getFileKey(meta.box, accesskey);
        if (key === undefined) {
            return undefined; //invalid st
        }
        this.filemetakeys.set(id, key);
        return key;
    }
    getFileKey(box, key) {
        return LetrwingCrypto.decryptSecretBox(box, key);
    }
    async getDraftDetails(did, letrid, accesskey, userid) {
        const res = await comm.post(ServerPath.adminGetDraftdetails, { letrid: letrid, draftid: did, userid: userid });
        if (res !== undefined) {
            // now we have the respons elets do something here!!
            const metainfo = LetrwingCrypto.decryptSecretBox(res.metabox, accesskey);
            if (metainfo !== undefined && metainfo !== null) {
                res.metabox = new EncryptBox();
                return res;
            }
        }
        return undefined;
    }
    async getDraftContent(did, letrid, accesskey, versionnumber, userid) {
        const req = {
            draftid: did,
            letrid: letrid,
            startindex: 0,
            endindex: 0,
            userid: userid
        };
        console.log('request');
        console.log(req);
        const res = await comm.post(ServerPath.adminGetDraftChunkMeta, req);
        console.log(res);
        if (res === undefined) {
            return undefined;
        }
        const totalchunks = res.totalchunks;
        let draft = "";
        for (let i = 0; i < totalchunks; i++) {
            req.startindex = i;
            req.endindex = i;
            const rdata = await comm.post(ServerPath.adminGetDraftChunk, req);
            if (rdata !== undefined && rdata.length > 0) {
                for (const rdt of rdata) {
                    try {
                        const rbox = rdt.data;
                        const str = LetrwingCrypto.decryptSecretBox(rbox, accesskey);
                        draft += str;
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        const result = {
            granted: true,
            currentlockusername: "",
            readonly: true,
            decryptedcontent: draft,
            updates: []
        };
        return result;
    }
    async loadReplies(parentid, letrid, time, after, count, userid) {
        const box = UserAdminStore.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            messageid: parentid,
            userid: userid
        };
        const messages = await comm.post(ServerPath.adminGetMessageReplies, req);
        const ret = [];
        if (messages !== undefined) {
            for (const msg of messages) {
                const bmsg = this.convertServerMessageToBaseMessage(msg, box);
                if (bmsg !== undefined) {
                    ret.push(bmsg);
                }
            }
        }
        return ret;
    }
    convertServerMessageToBaseMessage(smsg, datakey) {
        return MStore.apiConvertServerMessageToBaseMessage(smsg, datakey);
    }
    async getLetrDetail(letrid, userid) {
        if (!letrid) {
            return;
        }
        const req = { letrid: letrid, userid: userid };
        const res = await comm.post(ServerPath.adminGetLetrDetails, req);
        if (res !== undefined) {
            res.isExternal = this.checkHasExternal(res.creatorid, res.memberDetails.map((mem) => mem.id));
        }
        return res;
    }
    async deleteLetr(letrid, userid) {
        const req = {
            letrid: letrid,
            userid: userid
        };
        return await comm.post(ServerPath.adminDeleteLetr, req);
    }
    async removeMemberFromLetr(letrid, member, isConnect, userid) {
        const req = {
            letrid: letrid,
            memberid: member,
            isConnect: isConnect,
            userid: userid
        };
        const ok = comm.post(ServerPath.adminRemoveMemberFromLetr, req);
        await timeout(1000);
        return await this.getLetrDetail(letrid, userid);
    }
    async getStorageLogs(req) {
        return await comm.post(ServerPath.getStorageLogs, req);
    }
    async getRecentBills() {
        const ret = await comm.get(ServerPath.getRecentBills);
        return ret;
    }
    async billPaid(billid) {
        const req = { orderid: billid };
        const res = await comm.post(ServerPath.paymentCompleted, req);
        return res;
    }
    async startBillPayment(billid) {
        const req = { orderid: billid };
        const res = await comm.post(ServerPath.startBillPayment, req);
        return res;
    }
    async addMembersToLetr(letrid, member, tags = [], userid) {
        for (const mem of member) {
            await this.addMemberToLetr(letrid, mem, tags, userid);
        }
        return await this.getLetrDetail(letrid, userid);
    }
    async addMemberToLetr(letrid, member, tags = [], userid) {
        const uref = UserAdminStore.getLetrPassword(letrid);
        if (!uref) {
            return undefined;
        }
        const creq = {
            grpids: [member.userid],
            userid: userid
        };
        const commbundles = await comm.post(ServerPath.adminMemberCommBundles, creq);
        if (commbundles === undefined) {
            return undefined;
        }
        const grpmembers = new Map();
        const ubundles = new Map();
        commbundles.forEach((cb) => {
            ubundles.set(cb.userid, cb.bundle);
        });
        const mempackte = [];
        const parts = member.userid.split(AppConstants.TenantSep);
        for (const [key, val] of ubundles) {
            const uinbox = { indirectionid: "v2", indirectionrefkey: uref };
            const mbox = {
                membersboxid: "",
                membersboxkey: "",
                indref: uinbox
            };
            const box = LetrwingCrypto.box(JSON.stringify(mbox), val.initPubKey);
            const mempackte = {
                letrid: letrid,
                permission: { userid: parts[0] + AppConstants.TenantSep + key, permission: member.permission, roleid: member.roleid },
                box: { box: box, bundleid: val.reference },
                tags: tags,
                userid: userid
            };
            await comm.post(ServerPath.adminAddMemberToLetr, mempackte);
        }
        ;
        return true;
    }
    async updateLetrMember(letrid, members, userid) {
        const req = [];
        for (const mem of members) {
            req.push({
                permission: mem,
                userid: userid,
                letrid: letrid,
                box: { box: new EncryptBox(), bundleid: '' },
                tags: []
            });
        }
        const ok = await comm.post(ServerPath.adminUpdateMemeber, req);
        return await this.getLetrDetail(letrid, userid);
    }
    async loadMessages(letrid, time, after, count, excludetype = [], userid) {
        const box = UserAdminStore.getLetrPassword(letrid);
        if (box === undefined) {
            console.log('undefined');
            return [];
        }
        const mfilter = {
            letrid: letrid,
            time: time,
            count: count,
            after: after,
            exclude: excludetype,
            ascending: false,
            userid: userid
        };
        console.log('mfilter');
        console.log(mfilter);
        const messages = await comm.post(ServerPath.adminLetrMessages, mfilter);
        const ret = [];
        if (messages !== undefined) {
            for (const msg of messages) {
                const bmsg = this.convertServerMessageToBaseMessage(msg, box);
                if (bmsg !== undefined) {
                    ret.push(bmsg);
                }
            }
        }
        return ret;
    }
    async getProjectFolders(letrid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const ret = await comm.post(ServerPath.adminGetProjectFolders, { letrid: letrid, parentid: '', aobjectid: '', userid: userid });
        if (ret !== undefined) {
            return this.convertProjecObjectToProjectEntity(ret, box, letrid);
        }
        return undefined;
    }
    async getNotes(letrid, objectid, refid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const res = await comm.post(ServerPath.admingetnotes, {
            letrid: letrid,
            objectid: objectid,
            refid: refid,
            userid: userid
        });
        if (res !== undefined) {
            const ret = [];
            res.forEach((ns) => {
                const no = NoteStore.convertServerObjectToNote(ns, letrid, objectid, box);
                if (no !== undefined) {
                    ret.push(no);
                }
            });
            const pmap = new Map();
            const dmap = new Map();
            if (res.length > 0 && res[0].linkedobjects && res[0].linkedobjects.length > 0) {
                const po = ProjectStore.convertProjecObjectToProjectEntity(res[0].linkedobjects, box, letrid);
                for (const pobj of po) {
                    pmap.set(pobj.id, pobj);
                }
            }
            const datasheet = new Map();
            const schema = new Map();
            const rows = new Map();
            if (res.length > 0 && res[0].datasheetboxes && res[0].datasheetboxes.length > 0) {
                for (const boxes of res[0].datasheetboxes) {
                    if (boxes.entries.length === 0)
                        continue;
                    const rowid = boxes.entries[0].rowid;
                    if (!datasheet.has(boxes.datasheetbox.id)) {
                        const dbox = SchemaStore.convertBoxToSheet(boxes.datasheetbox, letrid, box);
                        if (dbox !== undefined) {
                            datasheet.set(boxes.datasheetbox.id, dbox);
                        }
                    }
                    if (!schema.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id)) {
                        const sch = SchemaStore.convertBoxToSchema(boxes.schemabox, letrid, box);
                        if (sch) {
                            schema.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id, sch);
                        }
                    }
                    if (!rows.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid)) {
                        rows.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid, boxes.entries);
                    }
                }
            }
            if (pmap.size > 0 || rows.size > 0) {
                for (const pn of ret) {
                    if (pn.links && pn.links.length > 0) {
                        const boxes = [];
                        for (const ln of pn.links) {
                            const lparts = ln.split("---");
                            if (lparts[0].startsWith("datasheet")) {
                                const dlparts = lparts[0].split("-");
                                const did = dlparts[1];
                                const sid = dlparts[2];
                                const rid = dlparts[3];
                                // only if we have the rows
                                const entries = rows.get(did + "-" + sid + "-" + rid);
                                const sh = schema.get(did + '-' + sid);
                                const dh = datasheet.get(did);
                                if (sh && dh && entries) {
                                    // lets do something here now!!
                                    const dbox = {
                                        datasheet: dh,
                                        schema: sh,
                                        row: entries
                                    };
                                    boxes.push({
                                        objectid: "datasheet-" + did + "-" + sid + "-" + rid,
                                        name: "",
                                        fileid: sid,
                                        noteid: lparts[1],
                                        datasheetrow: dbox
                                    });
                                }
                                // this is datasheet reference we should just do this according to that!
                                continue;
                            }
                            const pobj = pmap.get(lparts[0]);
                            if (pobj && lparts.length > 2 && pobj.documentreference === lparts[2]) {
                                boxes.push({
                                    objectid: lparts[0],
                                    name: pobj.name,
                                    fileid: lparts.length > 2 ? lparts[2] : '',
                                    noteid: lparts[1],
                                    projectobject: pobj
                                });
                            }
                        }
                        pn.linkboxes = boxes;
                    }
                }
            }
            console.log(ret);
            return ret;
        }
        return undefined;
    }
    async getAllDatasheets(letrid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            datasheetid: '',
            userid: userid
        };
        const schemas = await comm.post(ServerPath.adminGetDatasheet, req);
        const ret = [];
        if (!schemas || schemas.length === 0) {
            return ret;
        }
        for (const sch of schemas) {
            const bc = SchemaStore.convertBoxToSheet(sch, letrid, box);
            if (bc) {
                ret.push(bc);
            }
        }
        return ret;
    }
    async checkAndGetAllRemoteItems(items, letrid, sid, userid) {
        const ritems = [];
        for (const item of items) {
            if (item.type === SchemaItemType.Remote) {
                ritems.push(item);
            }
        }
        if (ritems.length > 0) {
            let ids = new Set();
            for (const it of ritems) {
                const rit = it;
                if (rit.did && rit.sid && rit.cid) {
                    ids.add(rit.did + "~~~" + rit.sid + "~~~" + rit.cid);
                }
            }
            if (ids.size > 0) {
                const req = {
                    letrid: letrid,
                    schemaid: sid,
                    schemafields: Array.from(ids),
                    userid: userid
                };
                const res = await this.getAllDependent(req);
                return res;
            }
        }
        return undefined;
    }
    async getRows(letrid, sid, userid) {
        var _a;
        const entries = await comm.post(ServerPath.adminGetRows, { letrid: letrid, schemaid: sid, userid: userid });
        const ret = new Map();
        if (entries) {
            const fent = entries[0];
            const dobj = new Map();
            const sobj = new Map();
            const box = this.getLetrPassword(letrid);
            if (fent && box !== undefined) {
                if (fent.objs) {
                    const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, letrid);
                    for (const fe of dojs) {
                        dobj.set(fe.id, fe);
                    }
                }
                if (fent.shs) {
                    for (const sh of fent.shs) {
                        const sok = CONVERTUTIL.convertBoxToSchema(sh, letrid, box);
                        if (sok)
                            sobj.set(sh.id, sok);
                    }
                }
            }
            for (const ent of entries) {
                if (!ent.rowid) {
                    continue;
                }
                if (ent.linkedids && ent.linkedids.length > 0) {
                    // lets attach it's object here!
                    if (!ent.objsdec)
                        ent.objsdec = [];
                    if (!ent.schsdec)
                        ent.schsdec = [];
                    for (const lid of ent.linkedids) {
                        const parts = lid.split("-");
                        if (parts.length < 2)
                            continue;
                        if (lid.startsWith("objs-")) {
                            const bk = dobj.get(parts[1]);
                            if (bk) {
                                ent.objsdec.push(bk);
                            }
                        }
                        else if (lid.startsWith("dch-") && parts.length > 2) {
                            const sh = sobj.get(parts[2]);
                            if (sh) {
                                ent.schsdec.push(sh);
                            }
                        }
                    }
                }
                if (ent.schemaid !== sid) {
                    ent.rowindex = ent.rowindex / 100000;
                }
                if (!ret.has(ent.rowid)) {
                    ret.set(ent.rowid, {
                        rowindex: ent.rowindex, rowid: ent.rowid, entry: [],
                        childrenschemalist: ent.schemaid === sid ? ent.childrenschemalist : []
                    });
                }
                (_a = ret.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
            }
        }
        return Array.from(ret.values());
    }
    async getAllSchemas(letrid, datasheet, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            datasheetid: datasheet,
            userid: userid
        };
        const schemas = await comm.post(ServerPath.adminGetSchemas, req);
        const ret = [];
        if (!schemas || schemas.schemas.length === 0) {
            return ret;
        }
        // lets get the parent one first 
        const pschema = new Map();
        for (const sch of schemas.parentschemas) {
            const bc = SchemaStore.convertBoxToSchema(sch, letrid, box);
            if (bc) {
                pschema.set(bc.id, bc);
            }
        }
        for (const sch of schemas.schemas) {
            const bc = SchemaStore.convertBoxToSchema(sch, letrid, box);
            if (bc) {
                if (sch.parentschemaid && pschema.has(sch.parentschemaid)) {
                    bc.parentschema = pschema.get(sch.parentschemaid);
                }
                if (sch.childrenschemaids) {
                    bc.childrenschema = [];
                    for (const cids of sch.childrenschemaids) {
                        const csh = pschema.get(cids);
                        if (csh) {
                            bc.childrenschema.push(csh);
                        }
                    }
                }
                ret.push(bc);
            }
        }
        return ret;
    }
    async getComments(noteid, letrid, objectid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            noteid: noteid,
            objectid: objectid,
            userid: userid,
        };
        const res = await comm.post(ServerPath.adminGetcomments, req);
        if (res === undefined) {
            return [];
        }
        return res.map((res) => (Object.assign(Object.assign({}, res), { datastr: LetrwingCrypto.decryptSecretBox(res.data, box) })));
    }
    async getAllLetrRoles(letrid, userid) {
        var _a;
        const req = {
            letrid: letrid,
            id: '',
            capability: [],
            status: RoleStatus.Live,
            name: '',
            userid: userid
        };
        const ret = await comm.post(ServerPath.adminGetRoles, req);
        return (_a = ret === null || ret === void 0 ? void 0 : ret.map(re => (Object.assign(Object.assign({}, re), { letrid: letrid })))) !== null && _a !== void 0 ? _a : [];
    }
    async getLetrLinks(letrid, userid) {
        const uref = this.getLetrPassword(letrid);
        if (!uref) {
            return undefined;
        }
        const res = await comm.post(ServerPath.adminGetLetrLinks, { letrid: letrid, userid: userid });
        const ret = [];
        if (res !== undefined) {
            res.forEach((r) => {
                const re = this.decryptLetrLink(r, letrid);
                if (re !== undefined) {
                    ret.push(re);
                }
            });
        }
        return ret;
    }
    decryptLetrLink(letrlink, letrid) {
        const uref = this.getLetrPassword(letrid);
        if (!uref) {
            return undefined;
        }
        const lbox = letrlink.databox;
        const lrbox = letrlink.letrrefbox;
        const lrstr = LetrwingCrypto.decryptSecretBox(lrbox, uref);
        if (!lrstr) {
            return undefined;
        }
        const lubox = JSON.parse(lrstr);
        const ldstr = LetrwingCrypto.decryptSecretBox(lbox, lubox.password);
        if (!ldstr) {
            return undefined;
        }
        const ldbox = JSON.parse(ldstr);
        const sbox = letrlink.searchbox;
        if (sbox === undefined) {
            return undefined;
        }
        const searchstr = LetrwingCrypto.decryptSecretBox(sbox, ldbox.letrpassword);
        if (!searchstr) {
            return undefined;
        }
        const sdbox = JSON.parse(searchstr);
        const ret = {
            id: letrlink.id,
            letrid: letrid,
            userid: letrlink.userid,
            databox: ldbox,
            letrrefbox: lubox,
            name: lubox.name,
            memberpublickey: letrlink.memberpublickey,
            membersignkey: letrlink.membersignkey,
            url: letrlink.url,
            password: letrlink.password,
            allowedit: letrlink.allowedit,
            searchbox: sdbox,
            roleid: letrlink.roleid
        };
        return ret;
    }
    async updateLinksForLetr(letrid, members, userid) {
        const req = [];
        for (const mem of members) {
            req.push({
                permission: mem,
                userid: userid,
                letrid: letrid,
                box: { box: new EncryptBox(), bundleid: '' },
                tags: []
            });
        }
        await comm.post(ServerPath.adminUpdateLetrLink, req);
        return await this.getLetrLinks(letrid, userid);
    }
    async deleteLetrLink(letrid, linkid, userid) {
        const req = {
            id: linkid,
            letrid: letrid,
            userid: userid
        };
        const res = await comm.post(ServerPath.admindeleteletrlink, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async getUserAssignedTasks(req) {
        const tasks = await comm.post(ServerPath.adminGetUserAssignedTask, req);
        if (tasks != undefined) {
            this.parseTasks(tasks);
        }
        return tasks !== null && tasks !== void 0 ? tasks : [];
    }
    parseTasks(tasks) {
        for (const log of tasks) {
            const oid = log.letrid;
            const aid = InMemoryRepo.getLetridFromOrigId(log.letrid);
            if (!aid) {
                continue;
            }
            log.letrid = aid;
            const box = InMemoryRepo.getLetrPassword(log.letrid);
            if (box === undefined) {
                continue;
            }
            if (log.itemid.startsWith("obj-")) {
                if (log.obj) {
                    const obj = ProjectStore.convertProjecObjectToProjectEntity([log.obj], box, log.letrid);
                    if (obj && obj.length > 0) {
                        log.message = obj[0].name + " task assigned to you";
                        log.itemurl = oid + "~~Doc~~" + obj[0].id;
                    }
                    else {
                        log.message = undefined;
                    }
                }
            }
            else if (log.itemid.startsWith("sch-")) {
                if (log.databox) {
                    const dt = SchemaStore.convertMiniSchemaEnBoxToDt(log.databox.schemadata, log.itemid.substring("sch-".length), log.letrid, box);
                    const parts = log.itemid.split("-");
                    if (dt && parts.length > 2) {
                        log.message = dt.name + " task assigned to you";
                        log.itemurl = oid + "~~Datasheet~~" + log.databox.datasheetid + "~~" + log.databox.id + "~~" + parts[2];
                    }
                    else {
                        log.message = undefined;
                    }
                }
            }
        }
    }
    async getAllRules(schemaid, letrid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            schemaid: schemaid,
            letrid: letrid,
            userid: userid
        };
        const res = await comm.post(ServerPath.adminGetRowRule, req);
        if (!res) {
            return [];
        }
        const resp = [];
        for (const ro of res) {
            try {
                const bstr = LetrwingCrypto.decryptSecretBox(ro.data, box);
                if (!bstr)
                    continue;
                const rules = JSON.parse(bstr);
                resp.push({
                    schemaid: ro.schemaid,
                    letrid: ro.letrid,
                    id: ro.id,
                    data: rules.numberdata,
                    datarules: rules.datarules,
                    rowid: ro.rowid,
                });
            }
            catch (ex) { }
        }
        return resp;
    }
    // this will get all ifnormationto evaluate formulas
    async getAllDependent(req) {
        const box = this.getLetrPassword(req.letrid);
        if (box === undefined) {
            return undefined;
        }
        const res = await comm.post(ServerPath.adminGetSchemaDependent, req);
        if (res !== undefined && res.data) {
            for (const r of res.data) {
                r.schemaopen = CONVERTUTIL.convertBoxToSchema(r.schema, req.letrid, box);
                if (r.rules) {
                    const resp = [];
                    for (const ro of r.rules) {
                        try {
                            const bstr = LetrwingCrypto.decryptSecretBox(ro.data, box);
                            if (!bstr)
                                continue;
                            const rules = JSON.parse(bstr);
                            resp.push({
                                schemaid: ro.schemaid,
                                letrid: ro.letrid,
                                id: ro.id,
                                data: rules.numberdata,
                                datarules: rules.datarules,
                                rowid: ro.rowid,
                            });
                        }
                        catch (ex) { }
                    }
                    r.rulesopen = resp;
                }
            }
        }
        return res;
    }
    convertBoxToSchema(schema, letrid, box) {
        return CONVERTUTIL.convertBoxToSchema(schema, letrid, box);
    }
    async getAllDependentEnt(rules, sh, letrid, wsid, ent, schemacollection, userid) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        let databyschemacol = new Map();
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return databyschemacol;
        }
        let mycellids = new Set();
        for (const en of ent) {
            mycellids.add(en.cellid);
        }
        const alldata = new Set();
        const columnidsBySh = new Map();
        const akey = sh.accesskey;
        const nonce = sh.nonce;
        if (sh.rules) {
            // have to use nested as this is okay
            for (const rule of sh.rules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_a = columnidsBySh.get(sh.id)) === null || _a === void 0 ? void 0 : _a.add(parts[2]);
                        }
                    }
                }
            }
        }
        for (const ru of rules) {
            for (const rule of ru.data) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_b = columnidsBySh.get(sh.id)) === null || _b === void 0 ? void 0 : _b.add(parts[2]);
                        }
                    }
                }
            }
        }
        if (alldata.size > 0) {
            const req = {
                letrid: letrid,
                schemaid: wsid,
                schemafields: Array.from(alldata),
                userid: userid
            };
            const res = await this.getAllDependent(req);
            if (res) {
                const schemacellids = new Map();
                for (const d of alldata) {
                    const parts = d.split("~~~");
                    if (!schemacellids.has(parts[1])) {
                        schemacellids.set(parts[1], new Set());
                    }
                    (_c = schemacellids.get(parts[1])) === null || _c === void 0 ? void 0 : _c.add(parts[2]);
                }
                // fetch recurssively everything now!!
                for (const d of res.data) {
                    const dsh = d.schema;
                    let dens = d.entries;
                    let cellids = (_d = schemacellids.get(d.schema.id)) !== null && _d !== void 0 ? _d : new Set();
                    const dshopen = this.convertBoxToSchema(dsh, letrid, box);
                    if (!dshopen)
                        continue;
                    schemacollection.set(dshopen.id, dshopen);
                    let entrymap = new Map();
                    if (dens) {
                        for (const de of dens) {
                            let id = de.rowid + "~~~" + de.cellid;
                            if (!entrymap.has(id)) {
                                entrymap.set(id, []);
                            }
                            (_e = entrymap.get(id)) === null || _e === void 0 ? void 0 : _e.push(de);
                        }
                    }
                    const srules = new Map();
                    const rowrules = new Map();
                    if (dshopen.rules) {
                        for (const shrule of dshopen.rules) {
                            srules.set(shrule.colid, shrule);
                        }
                    }
                    if (d.rulesopen) {
                        for (const rr of d.rulesopen) {
                            for (const shrule of rr.data) {
                                rowrules.set(rr.rowid + "~~~" + shrule.colid, shrule);
                            }
                        }
                    }
                    // now check what all columns we need 
                    for (const cellid of cellids) {
                        // we need to make sure for all rows the entries their otherwise we need to add these 
                        for (const row of d.totalrows) {
                            const rid = row.id;
                            if (cellid.indexOf("----") !== -1) {
                                const cp = cellid.split("----")[0];
                                const rowsubens = dens.filter((en) => en.rowid === rid && en.cellid.split("----")[0] === cp);
                                if (rowsubens.length > 0) {
                                    // we have some sub rows lets get all subriowindex now and if we have entry for this great otherwise we need to add one
                                    const subrows = new Set();
                                    for (const re of rowsubens) {
                                        if (re.subrowindex) {
                                            subrows.add(re.subrowindex);
                                        }
                                    }
                                    // now we have all subrow lets check if we have all ids
                                    const myens = entrymap.get(row.id + "~~~" + cellid);
                                    const enmap = new Map();
                                    for (const mye of myens !== null && myens !== void 0 ? myens : []) {
                                        enmap.set((_f = mye.cellid + "----" + mye.subrowindex) !== null && _f !== void 0 ? _f : '', mye);
                                    }
                                    // okay lets check if we have all the data or create some of them
                                    for (const sr of subrows) {
                                        const me = enmap.get(cellid + "----" + sr);
                                        if (!me) {
                                            dens.push({
                                                celldata: '',
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: dsh.id,
                                                rowindex: row.rowidex,
                                                rowid: row.id,
                                                subrowindex: sr,
                                                id: LetrwingCrypto.getUUID(),
                                                sortstring: Math.random(),
                                                decrypted: false
                                            });
                                        }
                                    }
                                }
                                continue;
                            }
                            const cid = rid + "~~~" + cellid;
                            if (!entrymap.has(cid)) {
                                entrymap.set(cid, [{
                                        celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                        cellfindstr: '',
                                        cellid: cellid,
                                        schemaid: dsh.id,
                                        rowindex: row.rowidex,
                                        rowid: row.id,
                                        subrowindex: "",
                                        id: LetrwingCrypto.getUUID(),
                                        sortstring: Math.random(),
                                        decrypted: false
                                    }]);
                                dens.push({
                                    celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                    cellfindstr: '',
                                    cellid: cellid,
                                    schemaid: dsh.id,
                                    rowindex: row.rowidex,
                                    rowid: row.id,
                                    subrowindex: "",
                                    id: LetrwingCrypto.getUUID(),
                                    sortstring: Math.random(),
                                    decrypted: false
                                });
                            }
                        }
                    }
                    // these 
                    if (dshopen.rules || d.rulesopen) {
                        let filterentris = [];
                        for (const den of dens) {
                            cellids.add(den.cellid);
                            if (dshopen) {
                                const rowrule = rowrules.get(den.rowid + "~~~" + den.cellid);
                                if (rowrule) {
                                    den.ruleopen = rowrule;
                                }
                                else {
                                    const shrule = srules.get(den.cellid);
                                    if (shrule) {
                                        den.ruleopen = shrule;
                                    }
                                }
                                // if no rule open this one it's a leaf
                                if (!den.ruleopen) {
                                    // decryptCell(den, dshopen);
                                }
                                const id = dshopen.datasheetid + "~~~" + dshopen.id + "~~~" + den.cellid;
                                if (!databyschemacol.has(id)) {
                                    databyschemacol.set(id, new Map());
                                }
                                (_g = databyschemacol.get(id)) === null || _g === void 0 ? void 0 : _g.set(den.id, den);
                            }
                        }
                        if (dshopen) {
                            let isschemarule = false;
                            let colrules = new Map();
                            if (dshopen.rules) {
                                // lets check if rule involve on our column and on other entity!
                                for (const shrule of dshopen.rules) {
                                    colrules.set(shrule.colid, shrule);
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    isschemarule = true;
                                                    break;
                                                }
                                            }
                                            if (isschemarule)
                                                break;
                                        }
                                    }
                                    if (isschemarule)
                                        break;
                                }
                                if (isschemarule) {
                                    filterentris.push(...dens);
                                }
                            }
                        }
                        const rules = d.rulesopen;
                        let cellrules = [];
                        if (rules) {
                            for (const rr of rules) {
                                let breakit = false;
                                for (const shrule of rr.data) {
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    cellrules.push(rr);
                                                    breakit = true;
                                                    break;
                                                }
                                            }
                                            if (breakit)
                                                break;
                                        }
                                    }
                                    if (breakit)
                                        break;
                                }
                            }
                        }
                        if (cellrules.length > 0 || filterentris.length > 0) {
                            if (dshopen) {
                                const dmap = await this.getAllDependentEnt(cellrules, dshopen, letrid, wsid, dens, schemacollection, userid);
                                if (dmap) {
                                    // lets go over it
                                    for (const [k, v] of dmap) {
                                        const id = k;
                                        if (!databyschemacol.has(id)) {
                                            databyschemacol.set(id, new Map());
                                        }
                                        // possible to have duplicates too other side might beed to fix that!
                                        for (const [vk, vn] of v) {
                                            (_h = databyschemacol.get(id)) === null || _h === void 0 ? void 0 : _h.set(vn.id, vn);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // we do need to re do this as 
            }
        }
        // we should maybe fill in the missing cells now!
        return databyschemacol;
    }
    /**
     * get all entries and check then if they need stuff and recurrsively do this till you find the result!!
     *
     */
    // this is quite good stuff lets do something here!!
    async getAllDepedentForEntries(ens, sh, letrid, wsid, userid) {
        var _a, _b;
        let databyschemacol = new Map();
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return databyschemacol;
        }
        let mycellids = new Set();
        for (const en of ens) {
            if (typeof en === 'string') {
                mycellids.add(en);
            }
            else {
                mycellids.add(en.cellid);
            }
        }
        const alldata = new Set();
        const akey = sh.accesskey;
        const nonce = sh.nonce;
        if (sh.rules) {
            // have to use nested as this is okay
            for (const rule of sh.rules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                        }
                    }
                }
            }
        }
        for (const en of ens) {
            if (typeof en === 'string')
                continue;
            if (en.rule && !en.decrypted) {
                try {
                    const val = LetrwingCrypto.decryptSecretBox(en.rule, akey);
                    if (val) {
                        const rv = JSON.parse(val);
                        en.ruleopen = rv;
                    }
                }
                catch (ex) { }
            }
            if (en.ruleopen) {
                for (const pa of en.ruleopen.rule) {
                    for (const param of pa.params) {
                        if (!param.value)
                            continue;
                        const parts = param.value.split("~~~");
                        if (parts.length < 2)
                            continue;
                        if (parts[1] !== sh.id) {
                            alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                        }
                    }
                }
            }
        }
        if (alldata.size > 0) {
            const req = {
                letrid: letrid,
                schemaid: wsid,
                schemafields: Array.from(alldata),
                userid: userid
            };
            const res = await this.getAllDependent(req);
            if (res) {
                // fetch recurssively everything now!!
                for (const d of res.data) {
                    const dsh = d.schema;
                    const dens = d.entries;
                    if (dens && dens.length > 0) {
                        const dshopen = this.convertBoxToSchema(dsh, letrid, box);
                        if (dshopen) {
                            // lets first add all the entries and check if they need rules too
                            let doformulacheck = false;
                            let isschemarule = false;
                            let filterentris = [];
                            let cellids = new Set();
                            for (const den of dens) {
                                cellids.add(den.cellid);
                            }
                            let colrules = new Map();
                            if (dshopen.rules) {
                                // lets check if rule involve on our column and on other entity!
                                for (const shrule of dshopen.rules) {
                                    colrules.set(shrule.colid, shrule);
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    isschemarule = true;
                                                    break;
                                                }
                                            }
                                            if (isschemarule)
                                                break;
                                        }
                                    }
                                    if (isschemarule)
                                        break;
                                }
                            }
                            if (isschemarule) {
                                filterentris.push(...dens);
                            }
                            for (const den of dens) {
                                decryptCell(den, dshopen);
                                if (den.rule) {
                                    // okay we need this now!
                                    doformulacheck = true;
                                    try {
                                        const val = LetrwingCrypto.decryptSecretBox(den.rule, akey);
                                        if (val) {
                                            const rv = JSON.parse(val);
                                            den.ruleopen = rv;
                                        }
                                    }
                                    catch (ex) { }
                                    if (den.ruleopen && !isschemarule) {
                                        // lets if any rule is on other schema
                                        for (const pa of den.ruleopen.rule) {
                                            for (const param of pa.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    filterentris.push(den);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    const shrule = colrules.get(den.cellid);
                                    if (shrule) {
                                        den.ruleopen = shrule;
                                        den.ruleopen.isshrule = true;
                                    }
                                }
                                const id = dshopen.datasheetid + "~~~" + dshopen.id + "~~~" + den.cellid;
                                if (!databyschemacol.has(id)) {
                                    databyschemacol.set(id, new Map());
                                }
                                (_a = databyschemacol.get(id)) === null || _a === void 0 ? void 0 : _a.set(den.id, den);
                            }
                            if (filterentris.length > 0) {
                                // we should filter this first now!!
                                const dmap = await this.getAllDepedentForEntries(filterentris, dshopen, letrid, dshopen.id, userid);
                                if (dmap) {
                                    // lets go over it
                                    for (const [k, v] of dmap) {
                                        const id = k;
                                        if (!databyschemacol.has(id)) {
                                            databyschemacol.set(id, new Map());
                                        }
                                        // possible to have duplicates too other side might beed to fix that!
                                        for (const [vk, vn] of v) {
                                            (_b = databyschemacol.get(id)) === null || _b === void 0 ? void 0 : _b.set(vn.id, vn);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return databyschemacol;
    }
    convertBoxToSheet(schema, letrid, box) {
        const datastr = LetrwingCrypto.decryptSecretBox(schema.schemadata, box);
        if (!datastr) {
            return undefined;
        }
        try {
            const dataobj = JSON.parse(datastr);
            dataobj.letrid = letrid;
            dataobj.id = schema.id;
            dataobj.authorid = schema.authorid,
                dataobj.lastupdatetime = schema.lastupdatetime;
            dataobj.updatedby = schema.updatedby;
            return dataobj;
        }
        catch (ex) {
        }
        return undefined;
    }
    async getSchemaAnalytics(letrid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            schemaid: '',
            schemafields: [],
            userid: userid
        };
        const res = await comm.post(ServerPath.adminGetAllSchemaAnalytics, req);
        // now lets do something clever thing!
        if (!res) {
            return [];
        }
        for (const r of res) {
            if (r.sheet !== undefined) {
                r.dhopen = this.convertBoxToSheet(r.sheet, letrid, box);
            }
            if (r.schema !== undefined) {
                r.schemaopen = CONVERTUTIL.convertBoxToSchema(r.schema, letrid, box);
            }
        }
        return res;
    }
    async getFields(letrid, userid) {
        const box = this.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            fields: [],
            userid: userid
        };
        const res = await comm.post(ServerPath.adminGetField, req);
        const result = [];
        if (res) {
            for (const fld of res) {
                // lets create schema item here and we done here!!
                if (fld.data) {
                    const sstr = LetrwingCrypto.decryptSecretBox(fld.data, box);
                    if (sstr) {
                        try {
                            const sch = JSON.parse(sstr);
                            if (sch) {
                                result.push(Object.assign(Object.assign({}, sch), { id: fld.id, letrid: letrid }));
                            }
                        }
                        catch (ex) { }
                    }
                }
            }
        }
        return result;
    }
    async loadRowsInBatches(letrid, sid, dids, pk, nonce, key, cb, activeloadingworksheetid, requserid) {
        var _a;
        let startindex = 0;
        this.activeloadingworksheetid = activeloadingworksheetid;
        let keepgoing = true;
        let rows = new Map();
        while (keepgoing) {
            const rentries = await comm.post(ServerPath.adminGetRowsIncremental, {
                letrid: letrid, schemaid: sid, datacellids: dids, count: 10000,
                startrowindex: startindex, userid: requserid
            });
            if (!rentries || rentries.data.length === 0
                || this.activeloadingworksheetid !== activeloadingworksheetid)
                keepgoing = false;
            else {
                const entries = await this.convertRowResToEntry(rentries, sid);
                startindex = startindex + 1;
                let totalrows = 0;
                let newrows = [];
                if (entries && entries.length > 0) {
                    const fent = entries[0];
                    const dobj = new Map();
                    const sobj = new Map();
                    const box = InMemoryRepo.getLetrPassword(letrid);
                    if (fent && box !== undefined) {
                        if (fent.objs) {
                            const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, letrid);
                            for (const fe of dojs) {
                                dobj.set(fe.id, fe);
                            }
                        }
                        if (fent.shs) {
                            for (const sh of fent.shs) {
                                const sok = this.convertBoxToSchema(sh, letrid, box);
                                if (sok)
                                    sobj.set(sh.id, sok);
                            }
                        }
                    }
                    let i = -1;
                    for (const ent of entries) {
                        if (i > 0 && i % 5000 == 0)
                            await timeout(10);
                        i++;
                        if (!ent.rowid) {
                            continue;
                        }
                        // we should check and sabe all the public key saved ones as well!!
                        if (ent.linkedids && ent.linkedids.length > 0) {
                            // lets attach it's object here!
                            if (!ent.objsdec)
                                ent.objsdec = [];
                            if (!ent.schsdec)
                                ent.schsdec = [];
                            for (const lid of ent.linkedids) {
                                const parts = lid.split("-");
                                if (parts.length < 2)
                                    continue;
                                if (lid.startsWith("objs-")) {
                                    const bk = dobj.get(parts[1]);
                                    if (bk) {
                                        ent.objsdec.push(bk);
                                    }
                                }
                                else if (lid.startsWith("dch-") && parts.length > 2) {
                                    const sh = sobj.get(parts[2]);
                                    if (sh) {
                                        ent.schsdec.push(sh);
                                    }
                                }
                            }
                        }
                        if (ent.schemaid !== sid) {
                            ent.rowindex = ent.rowindex / 100000;
                        }
                        if (ent.rowindex > startindex) {
                            startindex = ent.rowindex;
                        }
                        if (!rows.has(ent.rowid)) {
                            const srow = {
                                rowid: ent.rowid,
                                rowindex: ent.rowindex,
                                entry: []
                            };
                            rows.set(ent.rowid, srow);
                            totalrows++;
                            newrows.push(srow);
                        }
                        if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                            continue;
                        }
                        (_a = rows.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
                    }
                }
                startindex += 1;
                cb(rows.size, newrows);
            }
        }
        await timeout(100);
        return [];
    }
    async convertRowResToEntry(res, sid) {
        const ret = [];
        const variables = res.variables;
        for (let i = 0; i < res.data.length; i++) {
            if (i > 0 && i % 5000 === 0)
                await timeout(10);
            const dst = res.data[i];
            let rowindex = 0;
            let id = "";
            let rowid = "";
            let cellid = "";
            let celldata = "";
            let updatetime = 0;
            let updatedby = "";
            let subrowindex = "";
            let childrenschemalist = [];
            let readonlyschemalist = [];
            let linkedids = [];
            let referenceids = [];
            let objs = [];
            let shs = [];
            for (let j = 0; j < dst.length; j++) {
                const va = variables[j];
                const value = dst[j];
                if (va === "rowindex") {
                    rowindex = parseFloat(value);
                }
                else if (va === "id") {
                    id = value;
                }
                else if (va === "rowid") {
                    rowid = value;
                }
                else if (va === "cellid") {
                    cellid = value;
                }
                else if (va === "celldata") {
                    celldata = value;
                }
                else if (va === "childrenschemalist") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        childrenschemalist = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "readonlyschemalist") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        readonlyschemalist = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "updatetime") {
                    updatetime = parseFloat(va);
                }
                else if (va === "updatedby") {
                    updatedby = value;
                }
                else if (va === "linkedids") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        linkedids = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "referenceids") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        referenceids = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "subrowindex") {
                    subrowindex = value;
                }
                else if (va === "objs") {
                    if (value && value.length > 0) {
                        try {
                            const ds = JSON.parse(value);
                            objs = ds !== null && ds !== void 0 ? ds : [];
                        }
                        catch (ex) { }
                    }
                }
                else if (va === "shs") {
                    if (value && value.length > 0) {
                        try {
                            const ds = JSON.parse(value);
                            shs = ds !== null && ds !== void 0 ? ds : [];
                        }
                        catch (ex) { }
                    }
                }
                else if (va === "rows") {
                }
            }
            const en = {
                id: id,
                subrowindex: subrowindex,
                linkedids: linkedids,
                objs: objs,
                shs: shs,
                cellid: cellid,
                celldata: celldata,
                rowid: rowid,
                rowindex: rowindex,
                referenceids: referenceids,
                childrenschemalist: childrenschemalist,
                schemaid: sid,
                cellfindstr: celldata,
                sortstring: -1
            };
            ret.push(en);
        }
        return ret;
    }
}
export const UserAdminStore = new Store();
