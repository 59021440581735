import { MediaLiveStore } from "@/background/store/MediaLiveStore";
import { comm } from "@/Comm/comm";
import { BEvent } from "@/events/BackgroundEvents";
import { AppState } from "@/share/AppState";
import { RoomMessgaeType } from "@/share/Room";
import { AppConstants } from "@@/Constants";
import { CommonCrypto } from "@@/Cryptography";
import { CommBundleStatus, RoomLiveMessageType } from "@@/EncryptBox";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { SecStore } from "./SecurityStore";
export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
class Store {
    constructor() {
        this.activemediaid = "";
        this.activecall = false;
        this.activecallconnectstate = -1;
        this.bundleByUser = new Map();
        this.pendingMessagesForBundleUser = new Map();
        BEvent.addSubscriberForAppState((st) => {
            var _a;
            if (st !== AppState.LoggedIn) {
                this.activecallconnectstate = -1;
                this.activecall = false;
                this.activemediaid = "";
                (_a = this.activeCallLiveStore) === null || _a === void 0 ? void 0 : _a.destroy();
                // kill media window !
                this.bundleByUser = new Map();
                this.pendingMessagesForBundleUser = new Map();
            }
        });
        BEvent.addSubscriberForShutdownMedia(() => {
            var _a;
            this.activecallconnectstate = -1;
            this.activecall = false;
            this.activemediaid = "";
            (_a = this.activeCallLiveStore) === null || _a === void 0 ? void 0 : _a.destroy();
            this.bundleByUser = new Map();
            this.pendingMessagesForBundleUser = new Map();
        });
    }
    setActiveMediaId(id) {
        this.activemediaid = id;
    }
    getActiveMediaId() {
        return this.activemediaid;
    }
    async connectCall(incoming) {
        if (this.activecall) {
            return false;
        }
        this.activecallconnectstate = 1;
        this.activecall = true;
        this.activeCallLiveStore = new MediaLiveStore(() => {
            if (this.activecallconnectstate === 1) {
                // we should try again 
            }
            else {
                this.activecallconnectstate = -1;
                // let other side decide now
            }
        }, () => {
            this.activecallconnectstate = 2;
        }, (msg) => {
            this.handleMessage(msg);
        });
        let trial = 0;
        while (trial < 5) {
            const connect = await this.activeCallLiveStore.connect(this.activemediaid, incoming);
            if (connect === 300) {
                break;
            }
            else if (connect === 500) {
                await timeout(1000);
            }
            else {
                break;
            }
            trial++;
        }
    }
    checkAndReconnectPeer(peerid) {
        var _a;
        (_a = this.activeCallLiveStore) === null || _a === void 0 ? void 0 : _a.sendMessage("userconnected-" + peerid);
    }
    async handleMessage(msg) {
        const mainpassword = InMemoryRepo.getMasterPassword();
        try {
            const livemessage = JSON.parse(msg);
            if (livemessage.messagetype === RoomLiveMessageType.NewMember) {
                const memeber = JSON.parse(msg);
                const roomMessage = {
                    message: memeber.roommessage,
                    messgaetype: RoomMessgaeType.NewMember
                };
                BEvent.publishSendMessageToMedia(JSON.stringify(roomMessage));
            }
            else if (livemessage.messagetype === RoomLiveMessageType.RoomMessage &&
                mainpassword !== undefined) {
                const actualmessage = JSON.parse(msg);
                const encryptmessage = actualmessage.roommessage;
                const rawmessgae = await this.decryptRoommessage(encryptmessage);
                if (rawmessgae !== undefined) {
                    const dmsg = rawmessgae;
                    if (dmsg.messagetype === "offer") {
                        // now we have the offer
                        const uimessage = {
                            peerid: encryptmessage.from,
                            messagetype: "offer",
                            message: dmsg.message,
                            fullname: encryptmessage.fullname
                        };
                        const roommessage = {
                            messgaetype: RoomMessgaeType.Offer,
                            message: uimessage
                        };
                        BEvent.publishSendMessageToMedia(JSON.stringify(roommessage));
                    }
                    else if (dmsg.messagetype === "answer") {
                        // now we have the offer
                        const uimessage = {
                            peerid: encryptmessage.from,
                            messagetype: "answer",
                            message: dmsg.message,
                            fullname: encryptmessage.fullname
                        };
                        const roommessage = {
                            messgaetype: RoomMessgaeType.Answer,
                            message: uimessage
                        };
                        BEvent.publishSendMessageToMedia(JSON.stringify(roommessage));
                    }
                    else if (dmsg.messagetype === "icecandiate") {
                        const uimessage = {
                            peerid: encryptmessage.from,
                            messagetype: "icecandiate",
                            message: dmsg.message,
                            fullname: encryptmessage.fullname
                        };
                        const roommessage = {
                            messgaetype: RoomMessgaeType.IceCandidate,
                            message: uimessage
                        };
                        BEvent.publishSendMessageToMedia(JSON.stringify(roommessage));
                    }
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }
    async getPeerMemberBundle(memberid) {
        const mid = memberid.split(AppConstants.TenantSep);
        const appmemberid = mid[0] + AppConstants.TenantSep + mid[1];
        const bundle = this.bundleByUser.get(memberid);
        if (bundle !== undefined) {
            return bundle;
        }
        if (mid[0] === "freesoul") {
            const req = {
                letrid: this.activemediaid,
                connectid: appmemberid
            };
            const commbundles = await comm.post(ServerPath.getConnectCommBundle, req);
            if (commbundles !== undefined) {
                const key = LetrwingCrypto.getMessageFromSignedKey(commbundles.signkey, commbundles.publickey);
                if (key == undefined) {
                    return undefined;
                }
                const cbundle = {
                    isconnect: true,
                    userIdentityKey: "",
                    userSignkey: "",
                    ephemeralKey: key,
                    initPubKey: key,
                    signedKey: "",
                    reference: "",
                    status: CommBundleStatus.Live,
                    creationtime: 0
                };
                this.bundleByUser.set(memberid, cbundle);
                const pendingones = this.pendingMessagesForBundleUser.get(memberid);
                if (pendingones !== undefined && pendingones.length > 0) {
                    for (const po of pendingones) {
                        await this.actuallySendMessageToMember(po.pmsg, po.actualBGMessage, cbundle);
                    }
                    this.pendingMessagesForBundleUser.delete(memberid);
                }
                return cbundle;
            }
        }
        const creq = {
            grpids: [appmemberid]
        };
        const commbundles = await comm.post(ServerPath.memberCommBundles, creq);
        if (commbundles === undefined) {
            return undefined;
        }
        const pendingones = this.pendingMessagesForBundleUser.get(memberid);
        if (pendingones !== undefined && pendingones.length > 0) {
            for (const po of pendingones) {
                await this.actuallySendMessageToMember(po.pmsg, po.actualBGMessage, commbundles[0].bundle);
            }
            this.pendingMessagesForBundleUser.delete(memberid);
        }
        this.bundleByUser.set(memberid, commbundles[0].bundle);
        return commbundles[0].bundle;
    }
    async sendMessageToMember(pmsg, actualBGMessage) {
        var _a, _b;
        const offerroommessage = JSON.parse(actualBGMessage);
        const peerid = offerroommessage.message.message.peerid;
        if (!this.bundleByUser.has(peerid)) {
            if (this.pendingMessagesForBundleUser.has(peerid)) {
                (_a = this.pendingMessagesForBundleUser.get(peerid)) === null || _a === void 0 ? void 0 : _a.push({
                    pmsg: pmsg,
                    actualBGMessage: actualBGMessage
                });
            }
            else {
                this.pendingMessagesForBundleUser.set(peerid, []);
                (_b = this.pendingMessagesForBundleUser.get(peerid)) === null || _b === void 0 ? void 0 : _b.push({
                    pmsg: pmsg,
                    actualBGMessage: actualBGMessage
                });
                this.getPeerMemberBundle(peerid);
            }
            return;
        }
        const pendingones = this.pendingMessagesForBundleUser.get(peerid);
        if (pendingones !== undefined && pendingones.length > 0) {
            for (const po of pendingones) {
                await this.actuallySendMessageToMember(po.pmsg, po.actualBGMessage);
            }
            this.pendingMessagesForBundleUser.delete(peerid);
        }
        await this.actuallySendMessageToMember(pmsg, actualBGMessage);
    }
    async actuallySendMessageToMember(pmsg, actualBGMessage, bundle) {
        var _a, _b, _c;
        try {
            if (pmsg.messgaetype === RoomMessgaeType.Offer) {
                const offerroommessage = JSON.parse(actualBGMessage);
                const offermessage = offerroommessage.message.message;
                if (bundle === undefined) {
                    bundle = await this.getPeerMemberBundle(offermessage.peerid);
                }
                if (bundle !== undefined) {
                    offermessage.messagetype = "offer";
                    const box = LetrwingCrypto.box(JSON.stringify(offermessage), bundle.initPubKey);
                    const message = {
                        to: offermessage.peerid,
                        message: box,
                        roomid: this.activemediaid,
                        keyreferenceid: bundle.reference,
                        commboxes: [],
                        from: "",
                        fullname: ""
                    };
                    (_a = this.activeCallLiveStore) === null || _a === void 0 ? void 0 : _a.sendMessage(JSON.stringify(message));
                }
            }
            else if (pmsg.messgaetype === RoomMessgaeType.Answer) {
                const answerroommessage = JSON.parse(actualBGMessage);
                const offermessage = answerroommessage.message.message;
                if (bundle === undefined) {
                    bundle = await this.getPeerMemberBundle(offermessage.peerid);
                }
                if (bundle !== undefined) {
                    offermessage.messagetype = "answer";
                    const box = LetrwingCrypto.box(JSON.stringify(offermessage), bundle.initPubKey);
                    const message = {
                        to: offermessage.peerid,
                        message: box,
                        roomid: this.activemediaid,
                        keyreferenceid: bundle.reference,
                        commboxes: [],
                        from: "",
                        fullname: ""
                    };
                    (_b = this.activeCallLiveStore) === null || _b === void 0 ? void 0 : _b.sendMessage(JSON.stringify(message));
                }
            }
            else if (pmsg.messgaetype === RoomMessgaeType.IceCandidate) {
                const answerroommessage = JSON.parse(actualBGMessage);
                const offermessage = answerroommessage.message.message;
                if (bundle === undefined) {
                    bundle = await this.getPeerMemberBundle(offermessage.peerid);
                }
                if (bundle !== undefined) {
                    offermessage.messagetype = "icecandiate";
                    const box = LetrwingCrypto.box(JSON.stringify(offermessage), bundle.initPubKey);
                    const message = {
                        to: offermessage.peerid,
                        message: box,
                        roomid: this.activemediaid,
                        keyreferenceid: bundle.reference,
                        commboxes: [],
                        from: "",
                        fullname: ""
                    };
                    (_c = this.activeCallLiveStore) === null || _c === void 0 ? void 0 : _c.sendMessage(JSON.stringify(message));
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }
    async decryptRoommessage(encryptmessage) {
        const mainpassword = InMemoryRepo.getMasterPassword();
        if (encryptmessage.commboxes !== undefined && mainpassword !== undefined &&
            encryptmessage.commboxes.length > 1) {
            const b1 = encryptmessage.commboxes[0];
            const b2 = encryptmessage.commboxes[1];
            const pass = await SecStore.decryptPasswordBox(b2, mainpassword);
            if (pass !== undefined) {
                const pkey = pass.password;
                const bundle = CommonCrypto.decryptCommBundleBox(b1, pkey);
                if (bundle !== undefined) {
                    const initkey = bundle.initkey;
                    const mydata = LetrwingCrypto.decryptRSABox(encryptmessage.message, initkey);
                    return mydata;
                }
            }
        }
        return undefined;
    }
}
export const MediaStore = new Store();
