import { ChatIconCh, IconType, AssignmentIcon, ActiveCallIcon } from "./Icons";
export var TagCategory;
(function (TagCategory) {
    TagCategory["grouping"] = "1";
    TagCategory["managing"] = "2";
    TagCategory["filled"] = "3";
    TagCategory["userdefined"] = "4";
})(TagCategory || (TagCategory = {}));
export const CHATID = "chat";
export const ASSIGNEDID = "assigned";
export class Tag {
    constructor(id, name, applytoall = false, category = TagCategory.grouping, icon = { name: '', type: IconType.FontAwesome5, color: 'black',
        size: 36 }, childrenEnabled = false, isDefault = false, isGroup = false, isPrivate = false, parent = "") {
        this.id = "";
        this.name = "";
        this.parent = "";
        this.icon = { name: '', type: IconType.FontAwesome5, color: 'black',
            size: 36 };
        this.sortname = "";
        this.applyToAll = false;
        this.category = TagCategory.grouping;
        this.isDefault = false;
        this.isGroupTag = false;
        this.isPrivateTag = false;
        this.color = "";
        this.id = id;
        this.name = name;
        this.applyToAll = applytoall;
        this.category = category;
        this.icon = icon;
        this.childrenEnabled = childrenEnabled;
        this.isDefault = isDefault;
        this.isGroupTag = isGroup;
        this.isPrivateTag = isPrivate;
        this.parent = parent;
    }
}
export const ChatTag = new Tag("chat", "Direct Messages", false, TagCategory.userdefined, ChatIconCh, false, false, false, false, "");
export const AssignedTag = new Tag("assigned", "Assigned", false, TagCategory.userdefined, AssignmentIcon, false, false, false, false, "");
export const ActiveCallTag = new Tag("activecall", "Active Meeting", false, TagCategory.userdefined, ActiveCallIcon, false, false, false, false, "");
