var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { BaseDialog, BaseDialogState } from './BaseDialog';
export class AccessGroupDetailState {
    constructor() {
        this.basedialogstate = new BaseDialogState();
        this.memberdetail = [];
        this.letrlinks = [];
        this.selectedall = false;
        makeObservable(this);
        this.basedialogstate.setHideFooter(true);
    }
    setHeader(title) {
        this.basedialogstate.setHeaderLabel(title);
    }
    setOpen(open) {
        this.basedialogstate.setOpen(open);
    }
    update() {
        // not sure if we need this 
    }
    setLetrLinks(lt) {
        this.letrlinks = lt;
    }
    setMembers(lt) {
        this.memberdetail = lt;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], AccessGroupDetailState.prototype, "memberdetail", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], AccessGroupDetailState.prototype, "letrlinks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupDetailState.prototype, "selectedall", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AccessGroupDetailState.prototype, "setLetrLinks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AccessGroupDetailState.prototype, "setMembers", null);
let AccessGroupDetail = class AccessGroupDetail extends React.Component {
    dialog() {
        return React.createElement(Box, null,
            React.createElement(Grid, { mt: '20px', h: '50px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(4, 1fr)', gap: 4 },
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Flex, null,
                        React.createElement(Text, { w: '80%', fontWeight: 'bold' }, "Name"))),
                React.createElement(GridItem, { h: '100%', colSpan: 2 },
                    React.createElement(Text, { fontWeight: 'bold' }, "Organisation Name"))),
            this.props.state.memberdetail.map((det) => React.createElement(Grid, { key: det.id, mt: '20px', h: '50px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(4, 1fr)', gap: 4 },
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Flex, null,
                        React.createElement(Text, { w: '80%', fontWeight: 'bold' }, det.name))),
                React.createElement(GridItem, { h: '100%', colSpan: 2 },
                    React.createElement(Text, { fontWeight: 'bold' }, det.orgname)))),
            this.props.state.letrlinks.map((det) => React.createElement(Grid, { key: det.id, mt: '20px', h: '50px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(4, 1fr)', gap: 4 },
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Flex, null,
                        React.createElement(Text, { w: '80%', fontWeight: 'bold' }, det.name))),
                React.createElement(GridItem, { h: '100%', colSpan: 2 },
                    React.createElement(Text, { fontWeight: 'bold' }, "Link")))));
    }
    render() {
        return React.createElement(BaseDialog, { body: this.dialog(), state: this.props.state.basedialogstate });
    }
};
AccessGroupDetail = __decorate([
    observer
], AccessGroupDetail);
export { AccessGroupDetail };
