import { UserStatus } from "@@/Group";
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "Admin";
    UserRole["User"] = "User";
})(UserRole || (UserRole = {}));
export class InternalUser {
    constructor() {
        this.id = "";
        this.name = "";
        this.lastloggedintime = 0;
        this.roleid = UserRole.User;
        this.status = UserStatus.Active;
        this.orgcontactvisible = false;
        this.email = "";
    }
}
