import { AppConfig } from '@/config/AppConfig';
import axios from 'axios';
import { BEvent } from '@/events/BackgroundEvents';
import { AuthHeaders as AuthHeader } from '@@/AuthHeader';
import { AppState } from '@/share/AppState';
class Comm {
    constructor() {
        this.axiosinstance = axios;
        this.baseurl = AppConfig.appurl + AppConfig.root;
        this.timeout = 10 * 60 * 1000;
        this.tenantid = "";
        this.clientid = "";
        this.sessionid = "";
        this.id = "";
        BEvent.addSubscriberForTenant((ten) => this.tenantid = ten);
        BEvent.addSubscriberForAppState((state) => {
            if (state === AppState.Register || state === AppState.Unknown) {
                this.tenantid = "";
                this.clientid = "";
                this.sessionid = "";
            }
        });
    }
    allReqHeaders() {
        const header = [
            { key: AuthHeader.CLIENTID, value: this.clientid },
            { key: AuthHeader.DEVICESESSION, value: this.sessionid },
            { key: AuthHeader.TenantID, value: this.tenantid },
            { key: AuthHeader.ClientType, value: "browser" },
            { key: AuthHeader.ID, value: this.id }
        ];
        return header;
    }
    setSessionid(sessionid) {
        this.sessionid = sessionid;
    }
    setTenantId(id) {
        this.tenantid = id;
    }
    getClientId() {
        return this.clientid;
    }
    setClientId(clientid) {
        this.clientid = clientid;
    }
    async getBlob(uri, object, header) {
        try {
            const config = { timeout: this.timeout,
                timeoutErrorMessage: 'Error connecting to ' + this.baseurl + "/" + uri, headers: {}, validateStatus: () => true,
                responseType: 'arraybuffer',
                decompress: true };
            const hds = this.allReqHeaders();
            hds.forEach((hs) => config.headers[hs.key] = hs.value);
            if (header && header.length > 0) {
                header.forEach((h) => config.headers[h.key] = h.value);
            }
            const res = await this.axiosinstance.post(this.baseurl + "/" + uri, object, config);
            if (res.status === 200 && res.data !== undefined && res.data) {
                return res.data;
            }
            else if (res.status === 600) {
            }
            else if (res.status === 502) {
                //BEvent.publishAppState(AppState.APPDown);
            }
        }
        catch (e) {
            console.log(e);
        }
        return undefined;
    }
    async post(uri, object, header) {
        try {
            const config = { timeout: this.timeout,
                timeoutErrorMessage: 'Error connecting to ' + this.baseurl + "/" + uri, headers: {}, validateStatus: () => true };
            const hds = this.allReqHeaders();
            hds.forEach((hs) => config.headers[hs.key] = hs.value);
            if (header && header.length > 0) {
                header.forEach((h) => config.headers[h.key] = h.value);
            }
            const res = await this.axiosinstance.post(this.baseurl + "/" + uri, object, config);
            if (res.status === 200 && res.data !== undefined && res.data) {
                return res.data.data;
            }
            else if (res.status === 600) {
            }
            else if (res.status === 502) {
                //BEvent.publishAppState(AppState.APPDown);
            }
        }
        catch (e) {
        }
        return undefined;
    }
    async postWithNetwork(uri, object, header) {
        try {
            const config = { timeout: this.timeout,
                timeoutErrorMessage: 'Error connecting to ' + this.baseurl + "/" + uri, headers: {}, validateStatus: () => true };
            const hds = this.allReqHeaders();
            hds.forEach((hs) => config.headers[hs.key] = hs.value);
            if (header && header.length > 0) {
                header.forEach((h) => config.headers[h.key] = h.value);
            }
            const res = await this.axiosinstance.post(this.baseurl + "/" + uri, object, config);
            if (res.status === 200 && res.data !== undefined && res.data) {
                return res.data.data;
            }
            else if (res.status === 600) {
            }
            else if (res.status === 502 || res.status === 503 || res.status === 500 || res.status === 404) {
                return "NetworkError";
            }
            else if (res.status === 403) {
                return "Unauthorised";
            }
        }
        catch (e) {
            console.log(e);
            return "NetworkError";
        }
        return undefined;
    }
    async abGetWithNetwork(uri, header, return403res) {
        try {
            const config = { timeout: this.timeout,
                timeoutErrorMessage: 'Error connecting to ' + this.baseurl + "/" + uri,
                headers: {}, validateStatus: () => true };
            const hds = this.allReqHeaders();
            hds.forEach((hs) => config.headers[hs.key] = hs.value);
            if (header && header.length > 0) {
                header.forEach((h) => config.headers[h.key] = h.value);
            }
            const res = await this.axiosinstance.get(this.baseurl + "/" + uri, config);
            console.log(res);
            if (res.status == 200 && res.data != undefined) {
                return res.data.data;
            }
            else if (res.status === 600) {
            }
            else if (res.status === 502 || res.status === 503 || res.status === 500 || res.status === 404 || res.status === 400) {
                return "NetworkError";
            }
        }
        catch (e) {
            return "NetworkError";
        }
        return undefined;
    }
    async abGet(uri, header, return403res) {
        try {
            const config = { timeout: this.timeout,
                timeoutErrorMessage: 'Error connecting to ' + this.baseurl + "/" + uri,
                headers: {}, validateStatus: () => true };
            const hds = this.allReqHeaders();
            hds.forEach((hs) => config.headers[hs.key] = hs.value);
            if (header && header.length > 0) {
                header.forEach((h) => config.headers[h.key] = h.value);
            }
            const res = await this.axiosinstance.get(uri, config);
            if (res.status == 200 && res.data != undefined) {
                return res.data.data;
            }
            else if (res.status === 600) {
            }
            else if (res.status === 502) {
                // BEvent.publishAppState(AppState.APPDown);
            }
        }
        catch (e) {
            console.log(e);
        }
        return undefined;
    }
    async get(uri, header) {
        try {
            const res = await this.abGet(this.baseurl + "/" + uri, header);
            return res;
        }
        catch (e) {
            console.log(e);
        }
        return undefined;
    }
    async getOr403(uri, header) {
        try {
            const config = { timeout: this.timeout,
                timeoutErrorMessage: 'Error connecting to ' + this.baseurl + "/" + uri,
                headers: {}, validateStatus: () => true };
            const hds = this.allReqHeaders();
            hds.forEach((hs) => config.headers[hs.key] = hs.value);
            if (header && header.length > 0) {
                header.forEach((h) => config.headers[h.key] = h.value);
            }
            const res = await this.axiosinstance.get(this.baseurl + "/" + uri, config);
            if (res.status == 200 && res.data != undefined) {
                return res.data.data;
            }
            else if (res.status === 600) {
            }
            else if (res.status === 502) {
                // BEvent.publishAppState(AppState.APPDown);
            }
            else if (res.status === 403) {
                return new Is403();
            }
        }
        catch (e) {
            console.log(e);
        }
        return undefined;
    }
}
export const comm = new Comm();
export class Is403 {
    constructor() {
        this.unauth = true;
    }
}
