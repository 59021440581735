var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
class Store {
    constructor() {
        this.activeid = "";
        this.positinleft = 0;
        this.positintop = 0;
        this.thumbpowidt = 300;
        this.thumpoheight = 300;
        this.bgcolor = "";
        this.rtDisplayItems = [];
        makeObservable(this);
    }
    setActiveId(id) {
        this.activeid = id;
    }
    setWidth(wi) {
        this.thumbpowidt = wi;
    }
    setHeight(hi) {
        this.thumpoheight = hi;
    }
    setTopPos(pos) {
        this.positintop = pos;
    }
    setLeftPos(pos) {
        this.positinleft = pos;
    }
    setBGColor(col) {
        this.bgcolor = col;
    }
    reset() {
        this.setBGColor("");
        this.rtDisplayItems.splice(0, this.rtDisplayItems.length);
    }
    addRtDisplayItems(items) {
        for (const it of items) {
            this.rtDisplayItems.push(it);
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "activeid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "positinleft", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "positintop", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "thumbpowidt", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "thumpoheight", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "bgcolor", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Store.prototype, "rtDisplayItems", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setActiveId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setTopPos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setLeftPos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setBGColor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Store.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Store.prototype, "addRtDisplayItems", null);
export const RemotePopupViewStore = new Store();
