export var MasterKeyRequestType;
(function (MasterKeyRequestType) {
    MasterKeyRequestType[MasterKeyRequestType["Get"] = 0] = "Get";
    MasterKeyRequestType[MasterKeyRequestType["Add"] = 1] = "Add";
    MasterKeyRequestType[MasterKeyRequestType["Has"] = 2] = "Has";
    MasterKeyRequestType[MasterKeyRequestType["GetM"] = 3] = "GetM";
})(MasterKeyRequestType || (MasterKeyRequestType = {}));
export class MasterKeyRequest {
    constructor() {
        this.type = MasterKeyRequestType.Get;
        this.masterkey = "";
        this.password = "";
    }
}
export class MasterKeyResponse {
    constructor() {
        this.type = MasterKeyRequestType.Get;
        this.masterkey = "";
        this.error = false;
        this.hasKey = false;
    }
}
