export var JobType;
(function (JobType) {
    JobType["Document"] = "Document";
    JobType["SearchIndex"] = "SearchIndex";
    JobType["DraftExport"] = "DraftExport";
})(JobType || (JobType = {}));
export var SearchDataType;
(function (SearchDataType) {
    SearchDataType["Message"] = "Message";
    SearchDataType["Drive"] = "Drive";
    SearchDataType["DriveName"] = "DriveName";
    SearchDataType["LetrName"] = "LetrName";
})(SearchDataType || (SearchDataType = {}));
export var SearchOperation;
(function (SearchOperation) {
    SearchOperation["Add"] = "Add";
    SearchOperation["Update"] = "Update";
    SearchOperation["Delete"] = "Delete";
})(SearchOperation || (SearchOperation = {}));
export var SearchMetadataOp;
(function (SearchMetadataOp) {
    SearchMetadataOp["Greater"] = "Greater";
    SearchMetadataOp["Less"] = "Less";
    SearchMetadataOp["GreateEqual"] = "GreateEqual";
    SearchMetadataOp["LessEqual"] = "LessEqual";
    SearchMetadataOp["Equal"] = "Equal";
    SearchMetadataOp["InBetween"] = "InBetween";
})(SearchMetadataOp || (SearchMetadataOp = {}));
export var SortType;
(function (SortType) {
    SortType["Date"] = "Date";
    SortType["Number"] = "Number";
    SortType["Text"] = "Text";
})(SortType || (SortType = {}));
export var SearchType;
(function (SearchType) {
    SearchType["And"] = "And";
    SearchType["Or"] = "Or";
    SearchType["Phrase"] = "Phrase";
})(SearchType || (SearchType = {}));
