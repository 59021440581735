export var REFILLType;
(function (REFILLType) {
    REFILLType["OneTime"] = "OneTime";
    REFILLType["LongLive"] = "LongLive";
    REFILLType["Both"] = "Both";
})(REFILLType || (REFILLType = {}));
export class UserPasswordBox {
    constructor(storagePasswordBox, encryptingBox, locker) {
        this.encryptbox = encryptingBox;
        this.storage = storagePasswordBox;
        this.vault = locker;
    }
}
export class EncryptBox {
    constructor() {
        this.encryptmsg = "";
        this.nonce = "";
        this.publickey = "";
    }
}
export class MasterKeyboxResponse {
    constructor(box, pbox) {
        this.box = box;
        this.pbox = pbox;
    }
}
export class MasterKeyBox {
    constructor(box, boxid, password) {
        this.box = box;
        this.boxid = boxid;
        this.password = password;
    }
}
export class PasswordVault {
    constructor(data, auth) {
        this.reference = "";
        this.data = data;
        this.auth = auth;
    }
}
export var CommBundleStatus;
(function (CommBundleStatus) {
    CommBundleStatus[CommBundleStatus["Live"] = 0] = "Live";
    CommBundleStatus[CommBundleStatus["Used"] = 1] = "Used";
})(CommBundleStatus || (CommBundleStatus = {}));
export class KeyStorage {
    constructor() {
        this.publickey = "";
        this.privatkey = "";
    }
}
export var RoomLiveMessageType;
(function (RoomLiveMessageType) {
    RoomLiveMessageType["NewMember"] = "NewMember";
    RoomLiveMessageType["MemberLeft"] = "MemberLeft";
    RoomLiveMessageType["RoomMessage"] = "RoomMessage";
})(RoomLiveMessageType || (RoomLiveMessageType = {}));
export class UserKeysLocker {
    constructor(identityKey, signKey, deviceInfoKey) {
        this.identityKey = identityKey;
        this.signKey = signKey;
        this.deviceInfoKey = deviceInfoKey;
    }
}
export class UserPublicBox {
    constructor(identityKey, signKey, deviceInfoKey) {
        this.identityKey = identityKey;
        this.signKey = signKey;
        this.deviceInfoKey = deviceInfoKey;
    }
}
export var IndexType;
(function (IndexType) {
    IndexType["Project"] = "Project";
    IndexType["Letr"] = "Letr";
    IndexType["Message"] = "Message";
})(IndexType || (IndexType = {}));
export class RegisterUserRequest {
    constructor() {
        this.name = "";
        this.vault = "";
        this.storage1 = "";
        this.storage2 = "";
        this.storage3 = "";
        this.encryptbox1 = ""; // pin random password
        this.encryptbox2 = ""; // secret random password
        this.pubKeys = new UserPublicBox("", "", "");
        this.deviceRegisteredPassword = "";
        this.keys = undefined;
        this.passwordbox = undefined;
        this.userindexkey = undefined;
        this.recoverkeybox = undefined;
    }
}
