var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class NoteUI {
    constructor() {
        this.id = "";
        this.visible = true;
        this.myindex = -1;
        this.mystartpage = -1;
        this.updating = false;
        makeObservable(this);
    }
    ;
    setUpdating(up) {
        this.updating = up;
    }
    setMyIndex(index) {
        this.myindex = index;
    }
    setVisible(visible) {
        this.visible = visible;
    }
    setNote(note) {
        this.note = note;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteUI.prototype, "note", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], NoteUI.prototype, "visible", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteUI.prototype, "updating", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteUI.prototype, "setUpdating", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteUI.prototype, "setVisible", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NoteUI.prototype, "setNote", null);
