import { comm } from "@/Comm/comm";
import { BEvent } from "@/events/BackgroundEvents";
import { AppState } from "@/share/AppState";
import { CommonCrypto } from "@@/Cryptography";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { SecStore } from "./SecurityStore";
class Store {
    constructor() {
        BEvent.addSubscriberForAppState((st) => {
            if (st === AppState.LoggedIn) {
                this.updateOrgContact();
            }
        });
    }
    async updateOrgContact() {
        var _a;
        const mainpassword = InMemoryRepo.getMasterPassword();
        const commkey = InMemoryRepo.getCommKey();
        if (mainpassword === undefined || commkey === undefined) {
            return undefined;
        }
        const cbox = await comm.get(ServerPath.getOrgContactNewBox);
        if (cbox !== undefined && cbox.commbox !== undefined) {
            const b1 = cbox.commbox[0];
            const b2 = cbox.commbox[1];
            const mybox = cbox.cbox;
            //okay now we have the mybox we can open this box and then we good tgo gp
            const pass = await SecStore.decryptPasswordBox(b2, mainpassword);
            if (pass !== undefined) {
                const pkey = pass.password;
                const bundle = CommonCrypto.decryptCommBundleBox(b1, pkey);
                if (bundle !== undefined) {
                    const initkey = bundle.initkey;
                    const mydata = LetrwingCrypto.decryptRSABoxRawString(mybox, initkey);
                    if (mydata !== null) {
                        const pbo = await SecStore.storePassword(mydata, mainpassword, commkey);
                        if (pbo !== undefined) {
                            const orcon = {
                                id: "",
                                passwordid: (_a = pbo.id) !== null && _a !== void 0 ? _a : ""
                            };
                            comm.post(ServerPath.updateOrgContactBox, orcon);
                            // that's it..
                        }
                    }
                }
            }
        }
    }
    // steps do we have org contacts 
    async getOrgContactKey() {
        const mainpassword = InMemoryRepo.getMasterPassword();
        if (mainpassword === undefined) {
            return undefined;
        }
        const ebox = await comm.get(ServerPath.getOrgContactBox);
        if (ebox !== undefined && ebox.pbox !== undefined) {
            // lets get the box first 
            const pass = await SecStore.decryptPasswordBox(ebox.pbox, mainpassword);
            if (pass !== undefined) {
                return pass;
            }
        }
        return undefined;
    }
    async getOrgContacts(lastch) {
        const key = await this.getOrgContactKey();
        if (key === undefined) {
            return [];
        }
        const req = {
            data: lastch
        };
        // now we can get all the contacts after last ch and we done
        const ocs = await comm.post(ServerPath.getOrgContacts, req);
        const ret = [];
        if (ocs !== undefined) {
            for (const oc of ocs) {
                try {
                    const ocbox = oc.pbox;
                    if (ocbox !== undefined) {
                        const data = LetrwingCrypto.decryptSecretBox(ocbox, key.password);
                        if (data !== undefined) {
                            const pstr = JSON.parse(data);
                            ret.push({
                                id: oc.id,
                                name: oc.name,
                                hint: pstr.hint,
                                password: pstr.value,
                                props: []
                            });
                        }
                    }
                }
                catch (ex) {
                }
            }
        }
        return ret;
    }
}
export const OrgContactStore = new Store();
