import { DeviceAndOrgUpdate, DeviceAndOrgUpdateStage } from '@/share/DeviceAndOrg';
import { createNewTeamReq } from '@@/Team';
import { OrgHeader, DeviceregisterRequest } from '@@/RegisterModel';
import { LetrwingCrypto } from '@@/LetrwingCommonCrypto';
import { ServerPath } from '@@/ServerPath';
import { AuthHeaders as AuthHeader } from '@@/AuthHeader';
import { comm } from '@/Comm/comm';
import { CommonCrypto } from '@@/Cryptography';
import { DStore } from './Devicestore';
import { LocalRepo as Repository } from '@/db/Repo';
import { LocalDeviceTypeName } from './AuthStore';
import { BEvent } from '@/events/BackgroundEvents';
class RegisterStore {
    async registerNewTeamAndSignInUser(req, progresscb) {
        const treq = createNewTeamReq(req.email, req.teamname, req.username);
        // we haveorg too we just need to create org here and we done!
        const res = await comm.post(ServerPath.registerOrg, treq.req, [OrgHeader,
            { key: AuthHeader.OrgSession, value: req.sessionid }]);
        if (res === undefined) {
            const update = new DeviceAndOrgUpdate();
            update.updateStage = DeviceAndOrgUpdateStage.RegCreationError;
            progresscb(update);
            return;
        }
        const update = new DeviceAndOrgUpdate();
        update.masterpassword = treq.masterPassword;
        update.updateStage = DeviceAndOrgUpdateStage.RegisteredOrg;
        progresscb(update);
        const ures = await this.registerUser(req.userpin, req.secret, res.token.commsessionid, req.username, res.token, res.org.id);
        if (ures === 'OK') {
            const update = new DeviceAndOrgUpdate();
            update.updateStage = DeviceAndOrgUpdateStage.RegisteredUser;
            progresscb(update);
            setTimeout(() => this.registerThisDevice(req.userpin, res.token, res.org.id, (dres) => {
                if (dres) {
                    const update = new DeviceAndOrgUpdate();
                    update.updateStage = DeviceAndOrgUpdateStage.ShowOrgCreated;
                    progresscb(update);
                }
                else {
                    const update = new DeviceAndOrgUpdate();
                    update.updateStage = DeviceAndOrgUpdateStage.ShowOrgCreatedAndDeviceFailed;
                    progresscb(update);
                }
            }), 100);
        }
        else {
            const update = new DeviceAndOrgUpdate();
            update.updateStage = DeviceAndOrgUpdateStage.AddingUserError;
            progresscb(update);
        }
    }
    async registerUserAndDevice(pin, secret, name, token, tenantid, progresscb) {
        const ures = await this.registerUser(pin, secret, token.commsessionid, name, token, tenantid);
        if (ures === 'OK') {
            const update = new DeviceAndOrgUpdate();
            update.updateStage = DeviceAndOrgUpdateStage.RegisteredUser;
            progresscb(update);
            this.registerThisDevice(pin, token, tenantid, (dres) => {
                const update = new DeviceAndOrgUpdate();
                if (dres) {
                    update.updateStage = DeviceAndOrgUpdateStage.RegsiteredDevice;
                }
                else {
                    update.updateStage = DeviceAndOrgUpdateStage.RegisterUserDeviceFail;
                }
                progresscb(update);
            });
        }
        else {
            const update = new DeviceAndOrgUpdate();
            update.updateStage = DeviceAndOrgUpdateStage.AddingUserError;
            progresscb(update);
        }
    }
    async registerUser(pin, secret, commsessionid, name, token, tenantid) {
        const rinfo = await this.getRegisterInfo(commsessionid, tenantid);
        if (rinfo !== undefined) {
            // now we cna register this user
            const rpacket = await CommonCrypto.generateNewUserPacket(pin, secret, rinfo.masterPublicKey, rinfo.matrix, name);
            if (rpacket !== 100 && rpacket !== 101 && rpacket !== undefined) {
                const headers = [];
                headers.push({ key: AuthHeader.AuthHeader, value: commsessionid });
                headers.push({ key: AuthHeader.TenantID, value: tenantid });
                const post = LetrwingCrypto.box(JSON.stringify(rpacket), rinfo.commKey);
                let response = await comm.post(ServerPath.saveRegisterInfo, post, headers);
                if (response !== undefined && response) {
                    token.deviceInfoKey = rpacket.pubKeys.deviceInfoKey;
                    return "OK";
                }
                return undefined;
            }
            else {
                return undefined;
            }
        }
    }
    async registerThisDevice(pin, token, tenantid, doneCb) {
        const gotDeviceId = async (id) => {
            const dpacket = await CommonCrypto.generateNewDevicePacket(pin, id, token.userid, DStore.getDeviceMetadata(), token.deviceInfoKey, token.matrix);
            // this is going to be on device
            const request = new DeviceregisterRequest();
            const devicerespassword = CommonCrypto.generatePasswordUsingMatrix(pin, token.matrix);
            request.deviceregisterpassword = devicerespassword;
            request.deviceid = id;
            request.devicepassword = dpacket.devicepassword;
            request.devicestoragekey = dpacket.devicestoragekey;
            request.deviceMatrixPassword = dpacket.matrixpassword;
            request.extrainfo = dpacket.metabox;
            const ebox = LetrwingCrypto.box(JSON.stringify(request), token.commpk);
            // just only place where doing this is okay
            const response = await comm.post(ServerPath.registerDevice, ebox, [{
                    key: AuthHeader.TenantID, value: tenantid
                }, { key: AuthHeader.AuthHeader, value: token.commsessionid }]);
            if (response) {
                BEvent.publishTenant(tenantid);
                const storage = {
                    tenant: tenantid, userid: token.userid, deviceid: id, _id: LocalDeviceTypeName, sessionid: "", sessionkey: "", localMatrixBox: dpacket.localmatrioxbox,
                    devicebox: dpacket.localbox
                };
                Repository.store({ typename: LocalDeviceTypeName, data: storage }, (err, document) => {
                    if (err !== null || document === null) {
                        doneCb(false);
                    }
                    else {
                        doneCb(true);
                    }
                });
            }
            else {
                doneCb(false);
            }
        };
        let deviceid = Repository.getDeviceId();
        if (deviceid === undefined || !deviceid) {
            deviceid = "WEB-" + LetrwingCrypto.getRandomPassword();
            Repository.storeDeviceId(deviceid);
        }
        gotDeviceId(deviceid);
    }
    async getRegisterInfo(commsessionid, tenantid) {
        const mykp = LetrwingCrypto.getNewKeypair();
        const headers = [];
        headers.push({ key: AuthHeader.RESPONSEKEY, value: mykp.publickey });
        headers.push({ key: AuthHeader.AuthHeader, value: commsessionid });
        headers.push({ key: AuthHeader.TenantID, value: tenantid });
        const res = await comm.get(ServerPath.registerinfo, headers);
        if (res) {
            const box = JSON.parse(LetrwingCrypto.fromBase64ToUTF8(res));
            const dmsg = LetrwingCrypto.decryptRSABox(box, mykp);
            if (dmsg) {
                return dmsg;
            }
        }
        return undefined;
    }
}
export const RegStore = new RegisterStore();
