import { LetrwingCrypto } from "./LetrwingCommonCrypto";
export var MessageDeliveryStatus;
(function (MessageDeliveryStatus) {
    MessageDeliveryStatus[MessageDeliveryStatus["Activating"] = 0] = "Activating";
    MessageDeliveryStatus[MessageDeliveryStatus["Active"] = 1] = "Active";
    MessageDeliveryStatus[MessageDeliveryStatus["Saved"] = 2] = "Saved";
    MessageDeliveryStatus[MessageDeliveryStatus["Delivered"] = 3] = "Delivered";
    MessageDeliveryStatus[MessageDeliveryStatus["Error"] = 4] = "Error";
})(MessageDeliveryStatus || (MessageDeliveryStatus = {}));
export var MessageType;
(function (MessageType) {
    MessageType["Text"] = "Text";
    MessageType["Thread"] = "Thread";
    MessageType["File"] = "File";
    MessageType["Image"] = "Image";
    MessageType["Project"] = "Project";
    MessageType["Note"] = "Note";
})(MessageType || (MessageType = {}));
export class MessageHolder {
    constructor(message, id, messagetype) {
        this.message = message;
        this.id = id;
        this.messagetype = messagetype;
    }
}
export class Text {
    constructor(id, letrid, authorid, createdAt, user, text) {
        this.type = MessageType.Text;
        this._id = "";
        this.letrid = "";
        this.text = "";
        this.cid = [];
        this.authorid = "";
        this.status = MessageDeliveryStatus.Activating;
        this.isHtml = false;
        this.replycount = 0;
        this.isSystemMessage = false;
        this.acl = "everyone";
        this._id = id;
        this.letrid = letrid;
        this.authorid = authorid;
        this.createdAt = createdAt;
        this.user = user;
        this.text = text;
    }
    setText(text) {
        this.text = text;
    }
    setstatus(status) {
        this.status = status;
    }
    addChildId(cid) {
        this.cid.push(cid);
    }
    setReplyCount(count) {
        this.replycount = count;
    }
}
export var LetrboxDocumentStatus;
(function (LetrboxDocumentStatus) {
    LetrboxDocumentStatus[LetrboxDocumentStatus["Uploading"] = 0] = "Uploading";
    LetrboxDocumentStatus[LetrboxDocumentStatus["Uploaded"] = 1] = "Uploaded";
    LetrboxDocumentStatus[LetrboxDocumentStatus["Downloading"] = 2] = "Downloading";
    LetrboxDocumentStatus[LetrboxDocumentStatus["Error"] = 3] = "Error";
    LetrboxDocumentStatus[LetrboxDocumentStatus["TryAgain"] = 4] = "TryAgain";
})(LetrboxDocumentStatus || (LetrboxDocumentStatus = {}));
export class LetrboxDocument {
    constructor(id, letrid, text, createdAt, user, documentStatus, name) {
        this.type = MessageType.File;
        this.documentreference = "";
        this.accessurl = "";
        this.accesscode = "";
        this.accesskey = "";
        this.size = 0;
        this.replycount = 0;
        this.status = MessageDeliveryStatus.Activating;
        this.versions = [];
        this.replaceinfo = [];
        this.acl = "everyone";
        this._id = id;
        this.letrid = letrid;
        this.text = text;
        this.createdAt = createdAt;
        this.user = user;
        this.documentStatus = documentStatus;
        this.name = name;
    }
    setstatus(status) {
        this.status = status;
    }
    setDocumentStatus(documentStatus) {
        this.documentStatus = documentStatus;
    }
}
export class LetrboxImage {
    constructor(id, letrid, text, createdAt, user, documentStatus, size) {
        this.type = MessageType.Image;
        this._id = "";
        this.letrid = "";
        this.text = ""; // this will be just part of 
        this.status = MessageDeliveryStatus.Activating;
        this.width = 0;
        this.height = 0;
        this.accessurl = "";
        this.accesscode = "";
        this.accesskey = "";
        this.documentreference = "";
        this.localuri = "";
        this.replycount = 0;
        this.acl = "everyone";
        this._id = id;
        this.letrid = letrid;
        this.text = text;
        this.createdAt = createdAt;
        this.user = user;
        this.documentStatus = documentStatus;
        this.size = size;
    }
    setstatus(status) {
        this.status = status;
    }
    setDocumentStatus(documentStatus) {
        this.documentStatus = documentStatus;
    }
    setUri(uri) {
        this.localuri = uri;
    }
}
export const systemMessagesString = "<<<<<<<<letrwingsystem>>>>>>>____>>>>>";
export const createMessageEncryptBox = (msg, secretkey) => {
    let ebox;
    if (msg.type === MessageType.Text) {
        ebox = LetrwingCrypto.secretBox(msg.text, secretkey);
    }
    else if (msg.type === MessageType.File) {
        const dmsg = msg;
        const fileid = dmsg.documentreference;
        const accesskey = dmsg.accesskey;
        const accesscode = dmsg.accesscode;
        const info = {
            accesscode: accesscode,
            accesskey: accesskey,
            accessid: fileid,
            size: dmsg.size,
            name: dmsg.name,
            width: 0,
            height: 0,
            origname: dmsg.name,
            versions: dmsg.versions,
            replace: dmsg.replaceinfo
        };
        ebox = LetrwingCrypto.secretBox(JSON.stringify(info), secretkey);
    }
    else if (msg.type === MessageType.Image) {
        const imsg = msg;
        const info = {
            accesscode: imsg.accesscode,
            accesskey: imsg.accesskey,
            accessid: imsg.documentreference,
            size: imsg.size,
            name: "image",
            width: imsg.width,
            height: imsg.height,
            versions: [],
            replace: [],
            origname: "image"
        };
        ebox = LetrwingCrypto.secretBox(JSON.stringify(info), secretkey);
    }
    else if (msg.type === MessageType.Project) {
        const pmsg = msg;
        const box = {
            objectid: pmsg.objectid,
            objecttype: pmsg.objecttype,
            text: pmsg.text,
            color: pmsg.color
        };
        ebox = LetrwingCrypto.secretBox(JSON.stringify(box), secretkey);
    }
    return ebox;
};
export const convertServerMessageToBaseMessage = (smsg, datakey) => {
    var _a, _b, _c, _d, _e, _f;
    const ebox = smsg.box;
    let msg = LetrwingCrypto.decryptSecretBox(ebox, datakey);
    if (msg !== undefined) {
        if (smsg.type === MessageType.Text) {
            const magicstring = "(" + systemMessagesString + ")";
            let issystem = false;
            if (msg.startsWith(magicstring) && msg.endsWith(magicstring)) {
                msg = msg.substring(magicstring.length + 1, msg.length - (magicstring.length));
                issystem = true;
            }
            const text = new Text(smsg.id, smsg.letrid, smsg.authorid, smsg.updatetime, { _id: smsg.authorid, name: (_a = smsg.authorname) !== null && _a !== void 0 ? _a : '' }, msg);
            if (smsg.isHtml) {
                text.isHtml = smsg.isHtml;
            }
            text.setstatus(MessageDeliveryStatus.Active);
            text.setReplyCount((_b = smsg.replyCount) !== null && _b !== void 0 ? _b : 0);
            text.isSystemMessage = issystem;
            return text;
        }
        else if (smsg.type === MessageType.File) {
            const fmsg = JSON.parse(msg);
            const file = new LetrboxDocument(smsg.id, smsg.letrid, "", smsg.updatetime, { _id: smsg.authorid, name: (_c = smsg.authorname) !== null && _c !== void 0 ? _c : '' }, LetrboxDocumentStatus.Uploaded, fmsg.name);
            file.accesscode = fmsg.accesscode;
            file.accesskey = fmsg.accesskey;
            file.documentreference = fmsg.accessid;
            file.size = fmsg.size;
            file.setstatus(MessageDeliveryStatus.Active);
            file.versions = fmsg.versions;
            file.replaceinfo = fmsg.replace;
            console.log(file);
            return file;
        }
        else if (smsg.type === MessageType.Image) {
            const fmsg = JSON.parse(msg);
            const image = new LetrboxImage(smsg.id, smsg.letrid, "", smsg.updatetime, { _id: smsg.authorid, name: (_d = smsg.authorname) !== null && _d !== void 0 ? _d : "" }, LetrboxDocumentStatus.Downloading, fmsg.size);
            image.accesscode = fmsg.accesscode;
            image.accesskey = fmsg.accesskey,
                image.documentreference = fmsg.accessid;
            image.setstatus(MessageDeliveryStatus.Active);
            return image;
        }
        else if (smsg.type === MessageType.Project) {
            const pmss = JSON.parse(msg);
            const pbm = {
                _id: smsg.id,
                createdAt: smsg.updatetime,
                letrid: smsg.letrid,
                status: MessageDeliveryStatus.Active,
                objectid: pmss.objectid,
                objecttype: pmss.objecttype,
                text: pmss.text,
                type: MessageType.Project,
                replycount: (_e = smsg.replyCount) !== null && _e !== void 0 ? _e : 0,
                user: { _id: "system", name: "System" },
                color: pmss.color
            };
            return pbm;
        }
        else if (smsg.type === MessageType.Note) {
            const pmss = JSON.parse(msg);
            const pbm = {
                _id: smsg.id,
                createdAt: smsg.updatetime,
                letrid: smsg.letrid,
                status: MessageDeliveryStatus.Active,
                objectid: pmss.objectid,
                objecttype: pmss.objecttype,
                text: pmss.text,
                type: MessageType.Note,
                replycount: (_f = smsg.replyCount) !== null && _f !== void 0 ? _f : 0,
                user: { _id: "system", name: "System" },
                color: pmss.color
            };
            return pbm;
        }
    }
};
