var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DateLib } from "@@/Datelib";
import { sortEntriesByRow } from "@@/RuleParser";
import { SchemaItemType, JSXType } from "@@/Schema";
import { numberToWord, stripHTML } from "@@/Util";
import { Box, Button, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import htmlToPdfmake from "html-to-pdfmake";
import { toJS } from "mobx";
import React from "react";
import { StoreContext } from "../uistore/PwaRootStore";
const XLSX = await import("xlsx-js-style");
import { timeout } from "@/background/store/MediaStore";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from "pdfmake/build/pdfmake";
import { Select as RSelect } from "chakra-react-select";
import { observer } from "mobx-react";
const pmake = pdfMake;
pmake.vfs = pdfFonts.pdfMake.vfs;
pmake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    },
    Bookman: {
        normal: 'Bookman Old Style Regular.ttf',
        bold: 'Bookman Old Style Bold.ttf',
        italics: 'Bookman Old Style Cyr Italic.ttf',
        bolditalics: 'Bookman Old Style Bold Italic.ttf'
    }
};
const generateExcelData = async (data, rstore) => {
    rstore.wdialog.setMessage("Creating sheet..");
    rstore.wdialog.setLabel("Export");
    rstore.wdialog.setOpen(true);
    const workbook = XLSX.utils.book_new();
    workbook.Props = {
        Title: data.filename,
        CreatedDate: new Date()
    };
    for (const wk of data.worksheets) {
        let dataarr = [];
        let headerarr = [];
        let subheadarr = [];
        let ranges = [];
        let colsinfo = [];
        workbook.SheetNames.push(wk.sheetname);
        const items = wk.header;
        if (items && items.length > 0) {
            let mycolindex = 0;
            let haschildren = false;
            for (let i = 0; i < items.length; i++) {
                const ii = items[i];
                if (ii.children && ii.children.length > 0) {
                    haschildren = true;
                    break;
                }
            }
            for (let i = 0; i < items.length; i++) {
                const ii = items[i];
                let addemoty = 0;
                if (ii.children && ii.children.length > 0) {
                    addemoty = ii.children.length;
                    ranges.push({
                        s: {
                            r: 0,
                            c: mycolindex
                        },
                        e: {
                            r: 0,
                            c: mycolindex + ii.children.length - 1
                        }
                    });
                    mycolindex += ii.children.length;
                }
                else {
                    if (haschildren) {
                        ranges.push({
                            s: {
                                r: 0,
                                c: mycolindex
                            },
                            e: {
                                r: haschildren ? 1 : 0,
                                c: mycolindex
                            }
                        });
                    }
                    mycolindex++;
                }
                headerarr.push({
                    v: ii.name,
                    s: {
                        font: { bold: true },
                        alignment: { horizontal: 'center' }
                    },
                    t: "s"
                });
                for (let eio = 0; eio < addemoty; eio++) {
                    headerarr.push({
                        v: '',
                        t: 's'
                    });
                }
                if (addemoty > 0) {
                    let index = 0;
                    for (let si = headerarr.length - addemoty; si < headerarr.length; si++) {
                        // now we can add stuff to this index!!
                        subheadarr[si] = ({
                            v: ii.children[index],
                            s: {
                                font: { bold: true },
                                alignment: { horizontal: 'center' }
                            },
                            t: "s"
                        });
                        index++;
                    }
                }
            }
            dataarr.push(headerarr);
            if (subheadarr.length > 0) {
                dataarr.push(subheadarr);
            }
        }
        const data = wk.rows;
        let arowindex = 0;
        for (const row of data) {
            let index = 0;
            const crow = [];
            for (const col of row) {
                crow.push({
                    t: col.type === 'number' ? 'n' : 's',
                    v: col.value
                });
                if (col.rowspan > 1) {
                    ranges.push({
                        s: {
                            r: arowindex,
                            c: index
                        },
                        e: {
                            r: arowindex + col.rowspan - 1,
                            c: index
                        }
                    });
                }
                index++;
            }
            arowindex++;
            dataarr.push(crow);
        }
        const wdata = XLSX.utils.aoa_to_sheet(dataarr);
        if (ranges.length > 0) {
            wdata['!merges'] = ranges;
        }
        if (wk.colwidths && wk.colwidths.length > 0) {
            for (const wids of wk.colwidths) {
                colsinfo.push({
                    wpx: wids
                });
            }
            wdata['!cols'] = colsinfo;
        }
        workbook.Sheets[wk.sheetname] = wdata;
        await timeout(20);
    }
    // now we can just download this and we done here!!
    XLSX.writeFile(workbook, data.filename + ".xlsx", { compression: true });
    rstore.wdialog.setOpen(false);
};
const generatePdf = (data) => {
    var _a;
    const pdfdata = data.data;
    const pdf = pdfMake.createPdf(pdfdata);
    if (data.output == 'download') {
        const pdfname = (_a = data.name) !== null && _a !== void 0 ? _a : 'data';
        pdf.download(pdfname + ".pdf");
    }
    else if (data.output === 'binary') {
        if (data.binarycb) {
            pdf.getBlob((dd) => { var _a; return (_a = data.binarycb) === null || _a === void 0 ? void 0 : _a.call(data, dd); });
        }
    }
    else {
        if (data.base64cb) {
            pdf.getBase64((dd) => {
                var _a;
                (_a = data.base64cb) === null || _a === void 0 ? void 0 : _a.call(data, dd);
            });
        }
    }
};
const getRemoteItemDetail = (item, entry, rstore) => {
    var _a;
    const itemref = item.remotref;
    const cid = item.remotcolref;
    if (!itemref || !cid) {
        return undefined;
    }
    rstore.getPwaStore().addSchemaRowsToRemote(itemref, cid, item.id);
    let remoteitemsmap = new Map();
    const items = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + cid);
    remoteitemsmap.set(item.id + "---" + itemref + "---" + cid, items !== null && items !== void 0 ? items : []);
    for (const ext of (_a = item.remoteextracol) !== null && _a !== void 0 ? _a : []) {
        rstore.getPwaStore().addSchemaRowsToRemote(itemref, ext.cid, item.id);
        const items = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + ext.cid);
        remoteitemsmap.set(item.id + "---" + itemref + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
    }
    const vals = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + cid);
    let mainval = "";
    let remotevals = [];
    if (vals) {
        // now we have this
        const bits = entry.value.split(",");
        if (bits && bits.length > 0) {
            remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1);
            if (remotevals.length > 0) {
                mainval = remotevals.map((val) => val.value).join(", ");
            }
        }
    }
    return remotevals;
};
let PwaCustomDisplayItemUI = class PwaCustomDisplayItemUI extends React.Component {
    constructor(prop) {
        super(prop);
        this.gconfig = undefined;
        this.state = {
            building: false,
            html: "",
            buttons: []
        };
    }
    componentDidMount() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const myid = ((_a = this.props.row.rowid) !== null && _a !== void 0 ? _a : '') + "---" + this.props.itemid + "---" + ((_b = this.props.subrowindex) !== null && _b !== void 0 ? _b : '');
        const rstore = this.context;
        const myrowcac = rstore.getPwaStore().cacheui.get((_c = this.props.row.rowid) !== null && _c !== void 0 ? _c : '');
        if (!myrowcac) {
            rstore.getPwaStore().cacheui.set((_d = this.props.row.rowid) !== null && _d !== void 0 ? _d : '', new Map());
        }
        const customstuff = (_f = rstore.getPwaStore().cacheui.get((_e = this.props.row.rowid) !== null && _e !== void 0 ? _e : '')) === null || _f === void 0 ? void 0 : _f.get(myid);
        if (customstuff && !this.props.editmode) {
            this.setState({ buttons: (_g = customstuff.buttons) !== null && _g !== void 0 ? _g : '', html: customstuff.html });
            return;
        }
        this.setState({ building: true });
        if (this.props.item.data) {
            const rstore = this.context;
            const sh = rstore.getPwaStore().getActiveSchema();
            if (!sh)
                return;
            const config = {
                myschemaid: sh.id,
                editmode: this.props.editmode,
                getuserdetail: (id) => {
                    return rstore.getPwaStore().activeusers.get(id);
                },
                getactiveuserid: () => { var _a, _b; return (_b = (_a = rstore.getPwaStore().currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; },
                canshare: (data) => navigator.share !== undefined && navigator.canShare(data),
                buildjsx: (builders) => {
                    let buil = [];
                    if (!Array.isArray(builders)) {
                        buil.push(builders);
                    }
                    else {
                        buil = builders;
                    }
                    return React.createElement(Box, null, buil.map((builder, index) => {
                        var _a, _b;
                        if (builder.type === JSXType.Text) {
                            return React.createElement(Box, null,
                                builder.showaddbtn && React.createElement(Button, { variant: "solid", onClick: () => { var _a; return (_a = builder.addbtnclick) === null || _a === void 0 ? void 0 : _a.call(builder); } }, "ADD"),
                                React.createElement(Text, null, (_a = builder.defaultvalue) !== null && _a !== void 0 ? _a : ''));
                        }
                        if (builder.type === JSXType.Button) {
                            return React.createElement(Box, null,
                                React.createElement(Button, { margin: '2px', variant: "solid", onClick: (e) => {
                                        if (builder.onclick) {
                                            e.stopPropagation();
                                            builder.onclick(e);
                                        }
                                    } }, builder.label));
                        }
                        if (builder.type === JSXType.NumberInput) {
                            const cbuilder = builder;
                            return React.createElement(Box, null,
                                builder.showaddbtn && React.createElement(Button, { variant: "solid", onClick: () => { var _a; return (_a = builder.addbtnclick) === null || _a === void 0 ? void 0 : _a.call(builder); } }, "ADD"),
                                React.createElement(CustomNumberInput, { key: index + '', builder: cbuilder }));
                        }
                        if (builder.type === JSXType.Choice) {
                            const cbuilder = builder;
                            let dval = [];
                            let dvals = ((_b = cbuilder.defaultvalue) !== null && _b !== void 0 ? _b : '').split(",");
                            const items = cbuilder.choice.map((val, index) => {
                                if (dvals.indexOf(val.vale) !== -1) {
                                    dval.push({ value: val.vale, label: val.label });
                                }
                                return { value: val.vale, label: val.label };
                            });
                            return React.createElement(Box, { key: index + "'" },
                                builder.showaddbtn && React.createElement(Button, { variant: "solid", onClick: () => { var _a; return (_a = builder.addbtnclick) === null || _a === void 0 ? void 0 : _a.call(builder); } }, "ADD"),
                                React.createElement(RSelect, { options: items, selectedOptionStyle: "check", maxMenuHeight: 100, isSearchable: true, defaultValue: dval, onChange: (e) => {
                                        var _a, _b;
                                        if (cbuilder.multiselec) {
                                            let valu = [];
                                            for (const eva of e) {
                                                valu.push(eva.value);
                                            }
                                            (_a = cbuilder.onclick) === null || _a === void 0 ? void 0 : _a.call(cbuilder, valu.join(","));
                                        }
                                        else {
                                            (_b = cbuilder.onclick) === null || _b === void 0 ? void 0 : _b.call(cbuilder, e.value);
                                        }
                                    }, isMulti: cbuilder.multiselec ? true : undefined, closeMenuOnSelect: cbuilder.multiselec ? false : true }),
                                cbuilder.displayhtml !== undefined && React.createElement(Box, { dangerouslySetInnerHTML: { __html: cbuilder.displayhtml } }));
                        }
                        return React.createElement(React.Fragment, null);
                    }));
                },
                share: (data) => {
                    if (navigator.share !== undefined && navigator.canShare(data)) {
                        navigator.share(data);
                    }
                },
                hiderows: (ids) => {
                },
                listentrysorter: (e1, e2) => sortEntriesByRow(e1, e2),
                schemaitems: sh.items.map((item) => {
                    const rit = toJS(item);
                    if (item.type === SchemaItemType.List) {
                        const lite = item;
                        if (lite.items) {
                            const ni = lite.items.map((it) => toJS(it));
                            rit.items = ni;
                        }
                    }
                    return rit;
                }),
                saveentrytoworksheet: async (entry, worksheetid, subrowindex, cb) => {
                    let en = [];
                    for (const ce of entry) {
                        en.push({
                            celldata: ce.data,
                            cellid: ce.cellid,
                            subrowindex: subrowindex,
                            id: '',
                            rowid: '',
                            rowindex: -1,
                            cellfindstr: ce.data,
                            schemaid: worksheetid,
                            sortstring: -1
                        });
                    }
                    const res = await rstore.getPwaStore().addRowToConnectedWorksheet(en, worksheetid);
                    console.log(res);
                    if (res === 'Failed' || res === 'NetworkError' || !res) {
                        cb([]);
                    }
                    else {
                        cb(entry);
                    }
                },
                showcustomform: (items, title, onsubmit) => {
                    rstore.customformstate.setFormItems(items);
                    rstore.customformstate.onsubmit = (data) => onsubmit(data);
                    rstore.customformstate.setTitle(title);
                    for (const item of items) {
                        if (item.defaultvalue) {
                            rstore.customformstate.setValueForId(item.id, item.defaultvalue);
                        }
                    }
                    rstore.customformstate.setOpen(true);
                },
                storevalyetootherentry: (eid, data, ignorerebuild = false, forceid, value) => {
                    var _a, _b;
                    (_b = (_a = this.props).storevale) === null || _b === void 0 ? void 0 : _b.call(_a, data, ignorerebuild, forceid, value, eid);
                },
                storevaluetorow: (data, ignorerebuilding = false, forceid, value) => {
                    var _a, _b;
                    (_b = (_a = this.props).storevale) === null || _b === void 0 ? void 0 : _b.call(_a, data, ignorerebuilding, forceid, value);
                },
                rows: rstore.getPwaStore().rowsarrids,
                getschemarows: (sid) => {
                    return rstore.getPwaStore().getDependSchemaRows(sid);
                },
                getSchemaRowsByName: (name) => {
                    return rstore.getPwaStore().getDependentSchemaRowsByName(name);
                },
                decryptCell: (entry, sid, pr = true) => {
                    if (!entry.decrypted) {
                        rstore.getPwaStore().decryptEntry(entry, sid);
                    }
                    return entry;
                },
                showalertdialog: (msg) => {
                    rstore.aletrDialogStore.setMessage(msg);
                    rstore.aletrDialogStore.setOpen(true);
                },
                showconfirmdialog: (msg, cb) => {
                    rstore.confirmStore.setMessage(msg);
                    rstore.confirmStore.setOkCB(() => cb());
                    rstore.confirmStore.setOpen(true);
                },
                showpanel: (panel) => {
                },
                showreportdialog: (reprt) => {
                },
                showworkingdialog: (msg, hide) => {
                    if (hide) {
                        rstore.wdialog.setOpen(false);
                    }
                    else {
                        rstore.wdialog.setMessage(msg);
                        rstore.wdialog.setOpen(true);
                    }
                },
                generateexcel: (data) => {
                    // now we should just build this and we done!!
                    generateExcelData(data, rstore);
                },
                getRemoteItemDetail: (item, entry) => {
                    return getRemoteItemDetail(item, entry, rstore);
                },
                generatepdf: (pdfdata) => {
                    generatePdf(pdfdata);
                },
                sendemail: (edata) => {
                },
                contentbuildingmessage: (msg) => {
                },
                contentready: (rows) => {
                },
                setselecttabid: (id) => {
                },
                fitpredictdata: (data, order, preci, type = 'linear') => {
                },
                predictdata: (x) => {
                    return undefined;
                },
                fitdatacurve: () => {
                    return [];
                },
                updateconfig: (builder) => {
                },
                htmltopdfmake: (htmldata) => htmlToPdfmake(htmldata),
                striphtml: (str) => { var _a; return (_a = stripHTML(str)) !== null && _a !== void 0 ? _a : ''; },
                getseleectedrow: () => {
                    return [this.props.row];
                },
                displaydate: (ts, includetime = false) => {
                    try {
                        return includetime ? DateLib.displayDate(ts) : DateLib.displayOnlyDate(ts);
                    }
                    catch (ex) {
                        console.log("Invalid date " + ex);
                    }
                    return "";
                },
                updaterow: (rowid) => {
                },
                numToWords: (str) => { var _a; return (_a = numberToWord(str)) !== null && _a !== void 0 ? _a : ''; },
                rgbToHex: (str) => ({
                    hex: '',
                    alpha: -1
                }),
                storeshareddata: (data) => {
                    rstore.getPwaStore().customcolshareddataforrows.set(this.props.itemid, {
                        data: data
                    });
                },
                selfrebuild: () => {
                    let id = this.props.itemid;
                    if (this.props.subrowindex) {
                        id += "----" + this.props.subrowindex;
                    }
                    rstore.getPwaStore().addRebuildId(id);
                    setTimeout(() => {
                        rstore.getPwaStore().removeRebuildId(id);
                    }, 50);
                },
                getshareddata: () => {
                    var _a, _b;
                    return (_b = (_a = rstore.getPwaStore().customcolshareddataforrows.get(this.props.itemid)) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : '';
                },
                getsubrowindex: this.props.subrowindex,
            };
            try {
                const func = new Function("config", this.props.item.data);
                const builder = func(config);
                this.gconfig = builder;
                const cahcui = { buttons: [], html: '' };
                if ((_h = this.gconfig) === null || _h === void 0 ? void 0 : _h.htmlbuilder) {
                    this.gconfig.htmlbuilder((html) => {
                        var _a, _b;
                        // santgise the html 
                        if (html === 'string') {
                            html = html.replace("<iframe", "<iframe-nouse");
                            html = html.replace("<script ", "<scriptnouse ");
                        }
                        if (!this.props.editmode) {
                            cahcui.html = html;
                            (_b = rstore.getPwaStore().cacheui.get((_a = this.props.row.rowid) !== null && _a !== void 0 ? _a : '')) === null || _b === void 0 ? void 0 : _b.set(myid, cahcui);
                        }
                        this.setState({ building: false, html: html });
                    });
                }
                else if ((_j = this.gconfig) === null || _j === void 0 ? void 0 : _j.buttons) {
                    if (!this.props.editmode) {
                        cahcui.buttons = this.gconfig.buttons;
                        (_l = rstore.getPwaStore().cacheui.get((_k = this.props.row.rowid) !== null && _k !== void 0 ? _k : '')) === null || _l === void 0 ? void 0 : _l.set(myid, cahcui);
                    }
                    this.setState({ building: false, buttons: this.gconfig.buttons });
                }
                else {
                    this.setState({ building: false });
                }
            }
            catch (ex) {
                console.log(ex);
                this.setState({ building: false });
            }
        }
        else {
            this.setState({ building: false });
        }
    }
    render() {
        return React.createElement(Box, null,
            this.state.building && React.createElement(Spinner, null),
            !this.state.building && this.state.buttons.length > 0 && React.createElement(Flex, { direction: 'column' }, this.state.buttons.map((btn) => React.createElement(Button, { margin: '2px', key: Math.random() + "", size: 'xs', colorScheme: btn.color, onClick: (e) => {
                    e.stopPropagation();
                    btn.onclick();
                }, ml: '10px' }, btn.label))),
            !this.state.building && typeof this.state.html === 'string' && React.createElement(Box, { dangerouslySetInnerHTML: { __html: this.state.html } }),
            !this.state.building && typeof this.state.html !== 'string' && this.state.html);
    }
};
PwaCustomDisplayItemUI.contextType = StoreContext;
PwaCustomDisplayItemUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PwaCustomDisplayItemUI);
export { PwaCustomDisplayItemUI };
class CustomNumberInput extends React.Component {
    constructor(prop) {
        var _a;
        super(prop);
        this.state = {
            value: (_a = this.props.builder.defaultvalue) !== null && _a !== void 0 ? _a : ''
        };
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(Input, { type: 'number', value: this.state.value, onWheel: (e) => e.currentTarget.blur(), onChange: (e) => {
                    var _a, _b, _c, _d, _e, _f;
                    if (!e.currentTarget.value) {
                        (_b = (_a = this.props.builder).onclick) === null || _b === void 0 ? void 0 : _b.call(_a, e.currentTarget.value);
                        this.setState({ value: e.currentTarget.value });
                        return;
                    }
                    const ne = parseFloat(e.currentTarget.value);
                    if (!isNaN(ne)) {
                        if ((this.props.builder.max === undefined || this.props.builder.max >= ne)
                            &&
                                (this.props.builder.min === undefined || this.props.builder.min <= ne)) {
                            (_d = (_c = this.props.builder).onclick) === null || _d === void 0 ? void 0 : _d.call(_c, ne + '');
                            this.setState({ value: ne + '' });
                            return;
                        }
                    }
                    else {
                        (_f = (_e = this.props.builder).onclick) === null || _f === void 0 ? void 0 : _f.call(_e, '');
                        this.setState({ value: '' });
                        return;
                    }
                } }),
            this.props.builder.max !== undefined &&
                React.createElement(Text, { fontSize: '12px', mt: '2px', mb: '2px' }, 'Max: ' + this.props.builder.max),
            this.props.builder.errormsg !== undefined && React.createElement(Text, { fontSize: '12px', mt: '2px', mb: '2px', color: 'red' }, this.props.builder.errormsg));
    }
}
