import { APPEvent } from "@/events/AppEvents";
import { BEvent } from "@/events/BackgroundEvents";
import { GroupTypeNameAndId } from "@/share/models";
import { LocalRepo } from "@/db/Repo";
import { NewInvitesType, PendingInvitesType } from "@/share/NewInvite";
import { BackgroundHander } from "./BackgrounHandler";
class MainApphandler {
    constructor() {
        APPEvent.addSubscriberForNewBackgroundMessage((msg) => this.handle(msg));
        APPEvent.addSubscriberForActiveLetr((letr) => this.sendMessage("activeletr", letr));
    }
    handle(data) {
        // we can do this easliy by doing json.parse to string and json.parse ,essage to right type!
        const message = JSON.parse(data);
        if (message.name === 'appstate') {
            const response = JSON.parse(data);
            if (response.message.username) {
                APPEvent.publishForUsername(response.message.username);
            }
            if (response.message.userid) {
                APPEvent.publishForUserID(response.message.userid);
            }
            if (response.message.deviceid) {
                APPEvent.publishForDeviceid(response.message.deviceid);
            }
            if (response.message.sessionid) {
                APPEvent.publishForSessionid(response.message.sessionid);
            }
            if (response.message.orgid) {
                APPEvent.publishForOrgID(response.message.orgid);
            }
            APPEvent.publishForRegisterTypes(response.message.registerTypes);
            APPEvent.publishAppState(response.message.state);
        }
        else if (message.name === 'regsiterstageupdate') {
            const response = JSON.parse(data);
            APPEvent.publishRegisterStage(response.message);
        }
        else if (message.name === 'organddevicerequpdate') {
            const response = JSON.parse(data);
            APPEvent.publishForDeviceOrgUpdate(response.message);
        }
        else if (message.name === 'logindeviceupdate') {
            const update = JSON.parse(data);
            APPEvent.publishForLoginUpdate(update.message);
        }
        else if (message.name === 'apperror') {
            APPEvent.publishApperror(message.message);
        }
        else if (message.name === 'groupsresponse') {
            this.getGroups();
        }
        else if (message.name === 'inviteresponse') {
            APPEvent.publishInviteResponse(message.message === 'OK' ? true : false);
        }
        else if (message.name === 'pendinginvites') {
            this.extractPendingInvites(message.message);
        }
        else if (message.name === 'newinvites') {
            this.extractNewInvites(message.message);
        }
        else if (message.name === 'externalcontacteditresponse') {
            const response = JSON.parse(data);
            APPEvent.publishExternalContactResponse(response.message);
        }
        else if (message.name === 'personalgroupresponse') {
            const response = JSON.parse(data);
            if (response.message.group) {
                APPEvent.publishGroups({ error: false, groups: [response.message.group] });
            }
            APPEvent.publishPersonalGroupResponse(response.message);
        }
        else if (message.name === 'masterkeyresponse') {
            const response = JSON.parse(data);
            APPEvent.publishMasterKeyResponse(response.message);
        }
        else if (message.name === 'tags') {
            console.log("tag request rec " + (new Date().getTime()));
            const response = JSON.parse(data);
            APPEvent.publishTagsResponse(response.message);
        }
        else if (message.name === 'letrs') {
            const response = JSON.parse(data);
            APPEvent.publishLetrsResponse(response.message);
        }
        else if (message.name === 'message') {
            const respo = JSON.parse(data);
            APPEvent.publishMessageResponse(respo.message);
        }
        else if (message.name === 'letredit') {
            const respo = JSON.parse(data);
            APPEvent.publishLetrEdit(respo.message);
        }
        else if (message.name === 'devices') {
            const dev = JSON.parse(data);
            APPEvent.publishDevices(dev.message);
        }
        else if (message.name === 'uploadprogress') {
            const prg = JSON.parse(data);
            APPEvent.publishUploadProgress(prg.message);
        }
        else if (message.name === 'deletemessage') {
            const msg = JSON.parse(data);
            APPEvent.publishDeleteMessage(msg.message);
        }
        else if (message.name === 'downloadfile') {
            const msg = JSON.parse(data);
            APPEvent.publishDownload(msg.message);
        }
        else if (message.name === 'deletereply') {
            const msg = JSON.parse(data);
            APPEvent.publishDeleteReply(msg.message);
        }
        else if (message.name === 'allreplies') {
            const msg = JSON.parse(data);
            APPEvent.publishAllReplies(msg.message);
        }
        else if (message.name === 'managedcontacts') {
            const msg = JSON.parse(data);
            APPEvent.publishManagedContacts(msg.message);
        }
        else if (message.name === 'managedcontactsinvites') {
            const msg = JSON.parse(data);
            APPEvent.publishManagedGroupInvites(msg.message);
        }
        else if (message.name === 'managedgroups') {
            const msg = JSON.parse(data);
            APPEvent.publishManagedGroups(msg.message);
        }
        else if (message.name === 'managedletrs') {
            const msg = JSON.parse(data);
            APPEvent.publishManagedLetrs(msg.message);
        }
        else if (message.name === 'unreadinfo') {
            const msg = JSON.parse(data);
            APPEvent.publishUnreadInfo(msg.message);
        }
        else if (message.name === 'filestatusresponse') {
            const msg = JSON.parse(data);
            APPEvent.publishFileStatusResponse(msg.message);
        }
        else if (message.name === 'pdfpagemetadta') {
            const msg = JSON.parse(data);
            APPEvent.publishPdfFileMeta(msg.message);
        }
        else if (message.name === 'pdfpage') {
            const msg = JSON.parse(data);
            APPEvent.publishPdfFilePage(msg.message);
        }
        else if (message.name === 'connects') {
            const msg = JSON.parse(data);
            APPEvent.publishConnects(msg.message);
        }
        else if (message.name === 'addconnect') {
            const msg = JSON.parse(data);
            APPEvent.publishAddConnect(msg.message);
        }
        else if (message.name === 'deleteconnects') {
            const msg = JSON.parse(data);
            APPEvent.publishDeleteConnect(msg.message);
        }
        else if (message.name === "addconnectpasswordhint") {
            if (message.message !== undefined) {
                const msg = JSON.parse(data);
                APPEvent.publishAddConnectPasswordhint(msg.message);
            }
            else {
                APPEvent.publishAddConnectPasswordhint(undefined);
            }
        }
        else if (message.name === 'letrconnectdetails') {
            if (message.message !== undefined) {
                const msg = JSON.parse(data);
                APPEvent.publishLetrConnectDetails(msg.message);
            }
            else {
                APPEvent.publishLetrConnectDetails(undefined);
            }
        }
        else if (message.name === 'acceptorgres') {
            if (message.message !== undefined) {
                const msg = JSON.parse(data);
                APPEvent.publishAcceptRes(msg.message);
            }
            else {
                APPEvent.publishAcceptRes(undefined);
            }
        }
        else if (message.name === 'newactivecallletrid') {
            APPEvent.publishNewActiveLetridCall(message.message);
        }
        else if (message.name === 'registeranotherdeviceresponse') {
            if (message.message === undefined) {
                APPEvent.publishRegisterAnotherDevice(undefined);
            }
            else {
                const msg = JSON.parse(data);
                APPEvent.publishRegisterAnotherDevice(msg.message);
            }
        }
        else if (message.name === 'loginviadeviceresponse') {
            const msg = JSON.parse(data);
            APPEvent.publishLoginViaDevice(msg.message);
        }
        else if (message.name === "gotprojects") {
            const msg = JSON.parse(data);
            APPEvent.publishProjects(msg.message);
        }
        else if (message.name === "createproject") {
            const msg = JSON.parse(data);
            APPEvent.publishCreateObjec(msg.message);
        }
        else if (message.name === 'getorgcontactsres') {
            const msg = JSON.parse(data);
            // lets publish this and we all done!
            APPEvent.publishOrgContacts(msg.message);
        }
        else if (message.name === 'updateprojectres') {
            const msg = JSON.parse(data);
            APPEvent.publishUpdateObject(msg.message);
        }
        else if (message.name === 'moveobject') {
            const msg = JSON.parse(data);
            APPEvent.publishMoveObject(msg.message);
        }
        else if (message.name === 'emptytrash') {
            const msg = JSON.parse(data);
            APPEvent.publishEmptyTrash(msg.message);
        }
        else if (message.name === "addaccessgroup") {
            const msg = JSON.parse(data);
            APPEvent.publishAddAccessGroup(msg.message);
        }
        else if (message.name === "updateaccessgroup") {
            const msg = JSON.parse(data);
            APPEvent.publishUpdateAccessGroup(msg.message);
        }
        else if (message.name === "deleteaccessgroup") {
            const msg = JSON.parse(data);
            APPEvent.publishDeleteAccessGroup(msg.message);
        }
        else if (message.name === "getaccessgroups") {
            const msg = JSON.parse(data);
            APPEvent.publishGetAccessGroup(msg.message);
        }
        else if (message.name === "updateobjectacl") {
            const msg = JSON.parse(data);
            APPEvent.publishUpdateObjectAcl(msg.message);
        }
        else if (message.name === "childtags") {
            const tags = JSON.parse(data);
            APPEvent.publishChildTag(tags.message);
        }
        else if (message.name === "taggingletrres") {
            const resp = JSON.parse(data);
            APPEvent.publishTaggingLetr(resp.message);
        }
        else if (message.name === "gotproject") {
            const poj = JSON.parse(data);
            APPEvent.publishProjectObjectElem(poj.message);
        }
        else if (message.name === 'isadmin') {
            const isadmin = JSON.parse(data);
            APPEvent.publishIsAdmin(isadmin.message);
        }
        else if (message.name === "messagereadtime") {
            const resp = JSON.parse(data);
            APPEvent.publishMessageReadTime(resp.message);
        }
        else if (message.name === "orgverificationdetail") {
            const res = JSON.parse(data);
            APPEvent.publishOrgverificationDetail(res.message);
        }
        else if (message.name === "policiesaccepted") {
            const res = JSON.parse(data);
            APPEvent.publishPoliciesAccepted(res.message);
        }
        else if (message.name === "policies") {
            const res = JSON.parse(data);
            APPEvent.publishPolicies(res.message);
        }
        else if (message.name === "calculateaclstring") {
            const res = JSON.parse(data);
            APPEvent.publishCalculateAclString(res.message);
        }
        else if (message.name === "updatemessageacl") {
            const res = JSON.parse(data);
            APPEvent.publishUpdateMessageAcl(res.message);
        }
        else if (message.name === "getmyprofile") {
            const res = JSON.parse(data);
            APPEvent.publishGetMyProfile(res.message);
        }
        else if (message.name === "addinternaluserres") {
            if (message.message === undefined) {
                APPEvent.publishGetAddInternalUserRes(undefined);
            }
            else {
                const res = JSON.parse(data);
                APPEvent.publishGetAddInternalUserRes(res.message);
            }
        }
        else if (message.name === "getmyteamres") {
            const res = JSON.parse(data);
            APPEvent.publishGetMyTeamRes(res.message);
        }
        else if (message.name === "getmyletrwingcontactsres") {
            const res = JSON.parse(data);
            APPEvent.publishGetMyLetrwingContactRes(res.message);
        }
        else if (message.name === "loadorgcontactsres") {
            const res = JSON.parse(data);
            APPEvent.publishLoadOrgContactsRes(res.message);
        }
        else if (message.name === "editorgcontactres") {
            const res = JSON.parse(data);
            APPEvent.publishEditOrgContactsRes(res.message);
        }
        else if (message.name === "allpendinginvites") {
            const res = JSON.parse(data);
            APPEvent.publishAllPendingInvites(res.message);
        }
        else if (message.name === "companydirectory") {
            const res = JSON.parse(data);
            APPEvent.publishCompanyDirectory(res.message);
        }
        else if (message.name === "connectresponse") {
            const res = JSON.parse(data);
            APPEvent.publishConnectResponse(res.message);
        }
        else if (message.name === "publishresponse") {
            const res = JSON.parse(data);
            APPEvent.publishContactPublishResponse(res.message);
        }
        else if (message.name === "contactpublishableres") {
            const res = JSON.parse(data);
            APPEvent.publishContactPublishableRes(res.message);
        }
        else if (message.name === "declineletrcallres") {
            const res = JSON.parse(data);
            APPEvent.publishDeclineCallRes(res.message);
        }
        else if (message.name === "incomingcall") {
            const res = JSON.parse(data);
            console.log('here publishing');
            console.log(res.message);
            APPEvent.publishIncomingCall(res.message);
        }
        else if (message.name === 'draftdetailresponse') {
            if (message.message === undefined) {
                APPEvent.publishDraftDetail(undefined);
            }
            else {
                const res = JSON.parse(data);
                APPEvent.publishDraftDetail(res.message);
            }
        }
        else if (message.name === 'draftlockresponse') {
            const resp = JSON.parse(data);
            APPEvent.publishDraftLockResponse(resp.message);
        }
        else if (message.name === "callactioned") {
            const resp = JSON.parse(data);
            APPEvent.publishCallActioned(resp.message);
        }
        else if (message.name === "callended") {
            const resp = JSON.parse(data);
            APPEvent.publishCallEnded(resp.message);
        }
        else if (message.name === "saveddraft") {
            const resp = JSON.parse(data);
            APPEvent.publishSavedDraftContent(resp.message);
        }
        else if (message.name === "lockdraftupdated") {
            const res = JSON.parse(data);
            APPEvent.publishUpdateDraftLock(res.message);
        }
        else if (message.name === 'publisheddraft') {
            const res = JSON.parse(data);
            APPEvent.publishDraftPublished(res.message);
        }
        else if (message.name === 'formatpaste') {
            APPEvent.publishFormatPaste(message.message);
        }
        else if (message.name === 'callrecordres') {
            const res = JSON.parse(data);
            APPEvent.publishCallRecordRes(res.message);
        }
        else if (message.name === 'draftexport') {
            const res = JSON.parse(data);
            APPEvent.publishDraftExport(res.message);
        }
        else if (message.name === 'draftexportstatus') {
            if (message.message === undefined) {
                APPEvent.publishDraftStatus(undefined);
            }
            else {
                const res = JSON.parse(data);
                APPEvent.publishDraftStatus(res.message);
            }
        }
        else if (message.name === 'adminorgdetailres') {
            const resp = JSON.parse(data);
            APPEvent.publishOrgDetails(resp.message);
        }
        else if (message.name === "adminusersres") {
            const resp = JSON.parse(data);
            APPEvent.publishAdminUsersRes(resp.message);
        }
        else if (message.name === "adminuserdevicesres") {
            const resp = JSON.parse(data);
            APPEvent.publishAdminUserDevicesRes(resp.message);
        }
        else if (message.name === 'editdocument') {
            const resp = JSON.parse(data);
            APPEvent.publishEditDocument(resp.message);
        }
        else if (message.name === "admindownloadfileres") {
            const resp = JSON.parse(data);
            APPEvent.publishAdminDownload(resp.message);
        }
        else if (message.name === "adminmessage") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminMessageResponse(respo.message);
        }
        else if (message.name === "adminloadusertagsres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminLoadUserTagsRes(respo.message);
        }
        else if (message.name === "adminuserletrsfortagres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminUserLetrsFortag(respo.message);
        }
        else if (message.name === "adminedituserres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminEditUserRequestRes(respo.message);
        }
        else if (message.name === "adminletrwingcontactsres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminLetrwingContactsRes(respo.message);
        }
        else if (message.name == "admindeleteletrwingcontactres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminDeleteLetrwingContactRes(respo.message);
        }
        else if (message.name === "adminupdatewatermarkres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminUpdateWatermarkRes(respo.message);
        }
        else if (message.name === "adminupdatecontactemailres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminUpdateContactEmailRes(respo.message);
        }
        else if (message.name === "admingetlicenseinfores") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminGetLicenseInfoRes(respo.message);
        }
        else if (message.name === "admineditdeviceres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminEditDeviceRes(respo.message);
        }
        else if (message.name === "admincancelinviteres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminCancelInviteRes(respo.message);
        }
        else if (message.name === "adminupdateuserdetailres") {
            const respo = JSON.parse(data);
            APPEvent.publishAdminUpdateUserDetailRes(respo.message);
        }
        else if (message.name === "searchresultmatchres") {
            const res = JSON.parse(data);
            APPEvent.publishSearchMatchResp(res.message);
        }
        else if (message.name === "projectobjectdetails") {
            const res = JSON.parse(data);
            APPEvent.publishProjectObjectDetails(res.message);
        }
        else if (message.name === "letrsearch") {
            const res = JSON.parse(data);
            APPEvent.publishLetrSearch(res.message);
        }
        else if (message.name === 'messagebyidsres') {
            const res = JSON.parse(data);
            APPEvent.publishMsgByIds(res.message);
        }
        else if (message.name === 'miniletrs') {
            const res = JSON.parse(data);
            APPEvent.publishMiniLetrs(res.message);
        }
        else if (message.name === 'adminprojectobjres') {
            const res = JSON.parse(data);
            APPEvent.publishAdminProjectObjectRes(res.message);
        }
        else if (message.name === "createletrlinksres") {
            if (message.message === undefined) {
                APPEvent.publishCreateLetrlink(undefined);
            }
            else {
                const res = JSON.parse(data);
                APPEvent.publishCreateLetrlink(res.message);
            }
        }
        else if (message.name === "getletrlinkres") {
            const res = JSON.parse(data);
            APPEvent.publishGetLetrlink(res.message);
        }
        else if (message.name === "deletelinkletr") {
            const res = JSON.parse(data);
            APPEvent.publishDeleteLetrlink(res.message);
        }
        else if (message.name === "sendLetrEmailLinkres") {
            const res = JSON.parse(data);
            APPEvent.publishSendLetrlinkemail(res.message);
        }
        else if (message.name === 'adminpdfpage') {
            const msg = JSON.parse(data);
            APPEvent.publishAdminPdfFilePage(msg.message);
        }
        else if (message.name === 'admindraftdetailresponse') {
            if (message.message === undefined) {
                APPEvent.publishAdminDraftDetail(undefined);
            }
            else {
                const res = JSON.parse(data);
                APPEvent.publishAdminDraftDetail(res.message);
            }
        }
        else if (message.name === "getprojectfoldersres") {
            const res = JSON.parse(data);
            APPEvent.publishProjectFolders(res.message);
        }
        else if (message.name === 'adminDraftLockResponse') {
            const resp = JSON.parse(data);
            APPEvent.publishAdminDraftLockResponse(resp.message);
        }
        else if (message.name === 'adminLetrsResponse') {
            const resp = JSON.parse(data);
            APPEvent.publishAdminLetrsResponse(resp.message);
        }
        else if (message.name === 'admineditletrmember') {
            const resp = JSON.parse(data);
            APPEvent.publishAdminEditLetrMember(resp.message);
        }
        else if (message.name === 'getobjectparentsres') {
            const resp = JSON.parse(data);
            APPEvent.publishObjectParentFolder(resp.message);
        }
        else if (message.name === "savednote") {
            const resp = JSON.parse(data);
            APPEvent.publishSaveNote(resp.message);
        }
        else if (message.name === "getnotesres") {
            const resp = JSON.parse(data);
            APPEvent.publishGetNote(resp.message);
        }
        else if (message.name === "notesupdate") {
            const resp = JSON.parse(data);
            APPEvent.publishNoteUpdate(resp.message);
        }
        else if (message.name === "deletenote") {
            const resp = JSON.parse(data);
            APPEvent.publishNoteDelete(resp.message);
        }
        else if (message.name === "getcommentres") {
            const resp = JSON.parse(data);
            APPEvent.publishGetComments(resp.message);
        }
        else if (message.name === "addcommentres") {
            const resp = JSON.parse(data);
            APPEvent.publishSaveComments(resp.message);
        }
        else if (message.name === "deletedeviceres") {
            const resp = JSON.parse(data);
            APPEvent.publishDeleteDevice(resp.message);
        }
        else if (message.name === "adminpasswordres") {
            const resp = JSON.parse(data);
            APPEvent.publishAdminPassword(resp.message);
        }
        else if (message.name === "gotdraftupdates") {
            const resp = JSON.parse(data);
            APPEvent.publishDraftUpdates(resp.message);
        }
        else if (message.name === "createtabresp") {
            const resp = JSON.parse(data);
            APPEvent.publishForCreatedResp(resp.message);
        }
        else if (message.name === "gettabsresp") {
            const resp = JSON.parse(data);
            APPEvent.publishForTabsReq(resp.message);
        }
        else if (message.name === "deletetab") {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteTab(resp.message);
        }
        else if (message.name === "addversion") {
            const resp = JSON.parse(data);
            APPEvent.publishForAddVersion(resp.message);
        }
        else if (message.name === "getversion") {
            const resp = JSON.parse(data);
            APPEvent.publishForGetVersion(resp.message);
        }
        else if (message.name === "shownewusersecretdialog") {
            const resp = JSON.parse(data);
            APPEvent.publishForShowSecretDialog(resp.message);
        }
        else if (message.name === "resetpasswordverifyemail") {
            const resp = JSON.parse(data);
            APPEvent.publishForResentVerifyEmail(resp.message);
        }
        else if (message.name === "resetpasswordpinresp") {
            const resp = JSON.parse(data);
            APPEvent.publishForVerifyPinRes(resp.message);
        }
        else if (message.name === 'resetpasswordresponse') {
            const resp = JSON.parse(data);
            APPEvent.publishForResetPasswordResponse(resp.message);
        }
        else if (message.name === 'addschema') {
            const resp = JSON.parse(data);
            APPEvent.publishForAddSchema(resp.message);
        }
        else if (message.name === 'adddatasheet') {
            const resp = JSON.parse(data);
            APPEvent.publishForAddDatasheet(resp.message);
        }
        else if (message.name === 'getdatasheets') {
            const resp = JSON.parse(data);
            APPEvent.publishForGetDatasheet(resp.message);
        }
        else if (message.name === 'getschemas') {
            const resp = JSON.parse(data);
            APPEvent.publishForGetSchema(resp.message);
        }
        else if (message.name === 'addentry') {
            const resp = JSON.parse(data);
            APPEvent.publishForAddEntry(resp.message);
        }
        else if (message.name === 'updateentry') {
            const resp = JSON.parse(data);
            APPEvent.publishForUpdateEntry(resp.message);
        }
        else if (message.name === 'getrows') {
            const resp = JSON.parse(data);
            APPEvent.publishForGetRows(resp.message);
        }
        else if (message.name === 'partialrows') {
            const resp = JSON.parse(data);
            APPEvent.publishPartialRows(resp.message);
        }
        else if (message.name === "updateschema") {
            const resp = JSON.parse(data);
            APPEvent.publishForUpdateSchema(resp.message);
        }
        else if (message.name === 'deleteschema') {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteSchema(resp.message);
        }
        else if (message.name === 'addrowtochildsch') {
            const resp = JSON.parse(data);
            APPEvent.publishForAddRow(resp.message);
        }
        else if (message.name === "admingetprojectfoldersres") {
            const res = JSON.parse(data);
            APPEvent.publishAdminProjectFolders(res.message);
        }
        else if (message.name === 'deleterows') {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteRow(resp.message);
        }
        else if (message.name === "admingetnotesres") {
            const resp = JSON.parse(data);
            APPEvent.publishAdminGetNote(resp.message);
        }
        else if (message.name === "updatedatasheet") {
            const resp = JSON.parse(data);
            APPEvent.publishForUpdateDatasheet(resp.message);
        }
        else if (message.name === "deletedatasheet") {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteDatasheet(resp.message);
        }
        else if (message.name === 'admingetdatasheetsres') {
            const resp = JSON.parse(data);
            APPEvent.publishForAdminGetDatasheet(resp.message);
        }
        else if (message.name === 'admingetrowsres') {
            const resp = JSON.parse(data);
            APPEvent.publishForAdminGetRows(resp.message);
        }
        else if (message.name === 'admingetschemasres') {
            const resp = JSON.parse(data);
            APPEvent.publishForAdminGetSchema(resp.message);
        }
        else if (message.name === "admingetcommentres") {
            const resp = JSON.parse(data);
            APPEvent.publishAdminGetComments(resp.message);
        }
        else if (message.name === "addbookmark") {
            const resp = JSON.parse(data);
            APPEvent.publishForAddBookmark(resp.message);
        }
        else if (message.name === "deletebookmark") {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteBookmark(resp.message);
        }
        else if (message.name === "getmetadataobject") {
            const req = JSON.parse(data);
            APPEvent.publishForGetMetadata(req.message);
        }
        else if (message.name === "addmetadataobject") {
            const req = JSON.parse(data);
            APPEvent.publishForAddMetadata(req.message);
        }
        else if (message.name === "deleteuser") {
            const req = JSON.parse(data);
            APPEvent.publishAdminDeleteUser(req.message);
        }
        else if (message.name === "addfieldtoobject") {
            const req = JSON.parse(data);
            APPEvent.publishForAddField(req.message);
        }
        else if (message.name === "getrole") {
            const req = JSON.parse(data);
            APPEvent.publishForGetRole(req.message);
        }
        else if (message.name === "addrole") {
            const req = JSON.parse(data);
            APPEvent.publishForAddRole(req.message);
        }
        else if (message.name === "updaterole") {
            const req = JSON.parse(data);
            APPEvent.publishForUpdateRole(req.message);
        }
        else if (message.name === "deleterole") {
            const req = JSON.parse(data);
            APPEvent.publishForDeleteRole(req.message);
        }
        else if (message.name === "quota") {
            const req = JSON.parse(data);
            APPEvent.publishrQuota(req.message);
        }
        else if (message.name === "storagelogs") {
            const req = JSON.parse(data);
            APPEvent.publishrStorageLogs(req.message);
        }
        else if (message.name === "recentbills") {
            const req = JSON.parse(data);
            APPEvent.publishrRecentBills(req.message);
        }
        else if (message.name === "startbill") {
            const req = JSON.parse(data);
            APPEvent.publishStartBill(req.message);
        }
        else if (message.name === "billdone") {
            const req = JSON.parse(data);
            APPEvent.publishBillDone(req.message);
        }
        else if (message.name === "newnotifycount") {
            const req = JSON.parse(data);
            APPEvent.publishNewNotifyCount(req.message);
        }
        else if (message.name === "getrecentlogs") {
            const req = JSON.parse(data);
            APPEvent.publishRecentLogs(req.message);
        }
        else if (message.name === "getletrlogs") {
            const req = JSON.parse(data);
            APPEvent.publishLetrLogs(req.message);
        }
        else if (message.name === "activateuser") {
            const req = JSON.parse(data);
            APPEvent.publishActivateUser(req.message);
        }
        else if (message.name === "assignedtask") {
            const req = JSON.parse(data);
            APPEvent.publishAssignedTasks(req.message);
        }
        else if (message.name === "newassignedtasks") {
            const req = JSON.parse(data);
            APPEvent.publishNewAssignedTasks(req.message);
        }
        else if (message.name === "notifytaskcount") {
            const req = JSON.parse(data);
            APPEvent.publishAssignedTasksCount(req.message);
        }
        else if (message.name === "addentries") {
            const req = JSON.parse(data);
            APPEvent.publishForAddEntries(req.message);
        }
        else if (message.name === "addwatermark") {
            const req = JSON.parse(data);
            APPEvent.publishAddWatermark(req.message);
        }
        else if (message.name === "editcomment") {
            const req = JSON.parse(data);
            APPEvent.publishEditComment(req.message);
        }
        else if (message.name === "processingfiles") {
            const req = JSON.parse(data);
            APPEvent.publishProcessingFiles(req.message);
        }
        else if (message.name === "newfilescount") {
            const req = JSON.parse(data);
            APPEvent.publishNewFileCount(req.message);
        }
        else if (message.name === "admingetrole") {
            const req = JSON.parse(data);
            APPEvent.publishForAdminGetRole(req.message);
        }
        else if (message.name === "admingetletrlinkres") {
            const req = JSON.parse(data);
            APPEvent.publishAdminGetLetrlink(req.message);
        }
        else if (message.name === "admindeletelinkletr") {
            const res = JSON.parse(data);
            APPEvent.publishAdminDeleteLetrlink(res.message);
        }
        else if (message.name === "admindeleteletrres") {
            const res = JSON.parse(data);
            APPEvent.publishAdminLetrDeleteRes(res.message);
        }
        else if (message.name === "addwebform") {
            const res = JSON.parse(data);
            APPEvent.publishAddWebForm(res.message);
        }
        else if (message.name === "updatewebform") {
            const res = JSON.parse(data);
            APPEvent.publishUpdateWebForm(res.message);
        }
        else if (message.name === "getwebform") {
            const res = JSON.parse(data);
            APPEvent.publishGetWebForm(res.message);
        }
        else if (message.name === "deletewebform") {
            const res = JSON.parse(data);
            APPEvent.publishDeleteWebForm(res.message);
        }
        else if (message.name === "adminassignedtask") {
            const req = JSON.parse(data);
            APPEvent.publishAdminAssignedTasks(req.message);
        }
        else if (message.name === "addprinttemplate") {
            const res = JSON.parse(data);
            APPEvent.publishAddPrintTemplate(res.message);
        }
        else if (message.name === "updateprinttemplate") {
            const res = JSON.parse(data);
            APPEvent.publishUpdatePrintTemplate(res.message);
        }
        else if (message.name === "getprinttemplate") {
            const res = JSON.parse(data);
            APPEvent.publishGetPrintTemplat(res.message);
        }
        else if (message.name === "deleteprinttemplate") {
            const res = JSON.parse(data);
            APPEvent.publishDeletePrintTemplat(res.message);
        }
        else if (message.name === 'getschemaanaytics') {
            const res = JSON.parse(data);
            APPEvent.publishSchemaAnalytics(res.message);
        }
        else if (message.name === "dependentresp") {
            const res = JSON.parse(data);
            APPEvent.publishDependResp(res.message);
        }
        else if (message.name === 'rowrules') {
            const res = JSON.parse(data);
            APPEvent.publishRowRules(res.message);
        }
        else if (message.name === 'admindependentresp') {
            const res = JSON.parse(data);
            APPEvent.publishAdminDependResp(res.message);
        }
        else if (message.name === 'admingetschemaanaytics') {
            const res = JSON.parse(data);
            APPEvent.publishAdminSchemaAnalytics(res.message);
        }
        else if (message.name === "admingetmetadata") {
            const req = JSON.parse(data);
            APPEvent.publishForAdminGetMetadata(req.message);
        }
        else if (message.name === "duplicaterowsres") {
            const req = JSON.parse(data);
            APPEvent.publishDuplicateRowRes(req.message);
        }
        else if (message.name === 'adminpartialrows') {
            const resp = JSON.parse(data);
            APPEvent.publishAdminPartialRows(resp.message);
        }
        else if (message.name === 'addappuser') {
            const resp = JSON.parse(data);
            APPEvent.publishAddAppUse(resp.message);
        }
        else if (message.name === 'updateadduser') {
            const resp = JSON.parse(data);
            APPEvent.publishUpdateAppUse(resp.message);
        }
        else if (message.name === 'getappuser') {
            const resp = JSON.parse(data);
            APPEvent.publishGetAppUse(resp.message);
        }
        else if (message.name === 'deleteappuser') {
            const resp = JSON.parse(data);
            APPEvent.publishDeleteAppUse(resp.message);
        }
        else if (message.name === 'schemaupdate') {
            const resp = JSON.parse(data);
            APPEvent.publishSchemaUpdate(resp.message);
        }
        else if (message.name === "getorgappusers") {
            const resp = JSON.parse(data);
            APPEvent.publishGetorgAppUse(resp.message);
        }
        else if (message.name === 'remoterowsres') {
            const resp = JSON.parse(data);
            APPEvent.publishRemoteRows(resp.message);
        }
        else if (message.name === 'updaterows') {
            const resp = JSON.parse(data);
            APPEvent.publishUpdateRows(resp.message);
        }
        else if (message.name === 'getreportschemas') {
            const resp = JSON.parse(data);
            APPEvent.publishForGetReportSchema(resp.message);
        }
        else if (message.name === 'getwebviews') {
            const resp = JSON.parse(data);
            APPEvent.publishForGetWebViews(resp.message);
        }
        else if (message.name === 'addwebview') {
            const resp = JSON.parse(data);
            APPEvent.publishForAddWebViews(resp.message);
        }
        else if (message.name === 'deletewebview') {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteWebViews(resp.message);
        }
        else if (message.name === 'updatewebview') {
            const resp = JSON.parse(data);
            APPEvent.publishForUpdateWebViews(resp.message);
        }
        else if (message.name === 'attachbarcode') {
            const resp = JSON.parse(data);
            APPEvent.publishForAttachBarcod(resp.message);
        }
        else if (message.name === 'gettriggers') {
            const resp = JSON.parse(data);
            APPEvent.publishForGettriggers(resp.message);
        }
        else if (message.name === 'addtriggers') {
            const resp = JSON.parse(data);
            APPEvent.publishForAddtriggers(resp.message);
        }
        else if (message.name === 'renameletr') {
            const resp = JSON.parse(data);
            APPEvent.publishForRennameLetr(resp.message);
        }
        else if (message.name === 'getintegrations') {
            const resp = JSON.parse(data);
            APPEvent.publishForGetIntegrationss(resp.message);
        }
        else if (message.name === "addintegration") {
            const resp = JSON.parse(data);
            APPEvent.publishForAddIntegrationss(resp.message);
        }
        else if (message.name === "deleteintegration") {
            const resp = JSON.parse(data);
            APPEvent.publishForDeleteIntegrationss(resp.message);
        }
        else if (message.name === "updateandaddrows") {
            const resp = JSON.parse(data);
            APPEvent.publishForUpdateAndAddRows(resp.message);
        }
        else if (message.name === "getupdatesince") {
            const resp = JSON.parse(data);
            APPEvent.publishForGetUpdateSince(resp.message);
        }
        else if (message.name === "storeschemastatedata") {
            const resp = JSON.parse(data);
            APPEvent.publishForStoreSchemaStateData(resp.message);
        }
        else if (message.name === "getschemastatedata") {
            const resp = JSON.parse(data);
            APPEvent.publishForGetSchemaStateData(resp.message);
        }
    }
    extractNewInvites(id) {
        if (id !== 'error') {
            LocalRepo.getAndRemove(NewInvitesType, id, (err, document) => {
                if (err !== null || document === null) {
                }
                else {
                    APPEvent.publishNewInvite(document.newinvites);
                }
            });
        }
    }
    extractPendingInvites(id) {
        if (id !== 'error') {
            LocalRepo.getAndRemove(PendingInvitesType, id, (err, document) => {
                if (err !== null || document === null) {
                }
                else {
                    document.pendingcon.forEach((pi) => {
                        APPEvent.publishPendingInvites(pi);
                    });
                }
            });
        }
    }
    getGroups() {
        LocalRepo.get(GroupTypeNameAndId, GroupTypeNameAndId, (err, document) => {
            let resp;
            if (err != null || document === null || document.error) {
                // just send error message
                resp = { error: true, groups: [] };
            }
            else {
                const grps = document.data;
                resp = { error: false, groups: grps };
            }
            APPEvent.publishGroups(resp);
        });
    }
    sendFileMessage(message) {
        BackgroundHander.uploadFile(message);
    }
    sendMessage(event, message) {
        const packet = { name: event, message: message };
        BEvent.publishNewAppMessage(JSON.stringify(packet));
    }
}
export const Apphandler = new MainApphandler();
