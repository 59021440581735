import { SchemaItemType, UpdateType } from "@@/Schema";
import { CONVERTUTIL } from "./ConvertUtil";
import { EncryptBox } from "@@/EncryptBox";
import { InMemoryRepo } from "./InMemoryDb";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ProjectStore } from "./ProjectStore";
import { ServerPath } from "@@/ServerPath";
import { comm } from "@/Comm/comm";
import { decryptCell } from "@@/RuleParser";
import { timeout } from "@@/Util";
class Store {
    constructor() {
        this.activedisplayworksset = "";
        this.activeloadingworksheetid = "";
        this.activeloadingremoteworksheetid = "";
    }
    async addDatasheet(sheet) {
        const box = InMemoryRepo.getLetrPassword(sheet.letrid);
        if (box === undefined) {
            return undefined;
        }
        const sbox = this.convertSheetToBox(sheet, box, sheet.letrid);
        if (sheet.schemasunbox) {
            // lets conevrt this 
            const sboxes = [];
            for (const sh of sheet.schemasunbox) {
                const accesskey = LetrwingCrypto.generateNewSecretKey();
                const nonce = LetrwingCrypto.getRandmBytes(24);
                sh.accesskey = accesskey;
                sh.nonce = nonce;
                const shbox = this.convertSchemaToBox(sh, box, sheet.letrid);
                if (shbox) {
                    shbox.parentschemaid = sh.parentschemaid;
                    shbox.metaschemaids = sh.metaschemaids;
                    shbox.childrenschemaids = sh.childrensids;
                    sboxes.push(shbox);
                }
            }
            sbox.schemas = sboxes;
        }
        // now we have everything lets just dop something easier!!
        const ssche = await comm.post(ServerPath.addDatasheet, sbox);
        if (!ssche) {
            return undefined;
        }
        const ret = this.convertBoxToSheet(ssche, sheet.letrid, box);
        return ret;
    }
    async updateDatasheet(sheet) {
        const box = InMemoryRepo.getLetrPassword(sheet.letrid);
        if (box === undefined) {
            return undefined;
        }
        const sbox = this.convertSheetToBox(sheet, box, sheet.letrid);
        // now we have everything lets just dop something easier!!
        const ssche = await comm.post(ServerPath.updateDatasheet, sbox);
        if (!ssche) {
            return undefined;
        }
        const ret = this.convertBoxToSheet(ssche, sheet.letrid, box);
        return ret;
    }
    async deleteSchema(req) {
        const ssche = await comm.post(ServerPath.deleteSchema, req);
        return ssche !== null && ssche !== void 0 ? ssche : false;
    }
    async deleteDatasheet(id, letrid) {
        const req = {
            id: id,
            letrid: letrid
        };
        // now we have everything lets just dop something easier!!
        const ssche = await comm.post(ServerPath.deleteDatasheet, req);
        return ssche !== null && ssche !== void 0 ? ssche : false;
    }
    async addSchema(schema) {
        var _a;
        const box = InMemoryRepo.getLetrPassword(schema.letrid);
        if (box === undefined) {
            return undefined;
        }
        const accesskey = LetrwingCrypto.generateNewSecretKey();
        const nonce = LetrwingCrypto.getRandmBytes(24);
        schema.accesskey = accesskey;
        schema.nonce = nonce;
        const rsabox = LetrwingCrypto.getNewKeypair();
        const sbox = this.convertSchemaToBox(schema, box, schema.letrid);
        sbox.schemapublickey = rsabox.publickey;
        sbox.schemaintegrationbox = LetrwingCrypto.secretBox(JSON.stringify(rsabox), accesskey);
        if (schema.parentschema) {
            sbox.parentschemaid = schema.parentschema.id;
        }
        const autolcols = [];
        const iditems = schema.items.filter((item) => item.type === SchemaItemType.ID);
        if (iditems && iditems.length > 0) {
            iditems.forEach((id) => {
                const iitem = id;
                autolcols.push({
                    cellid: id.id,
                    length: parseInt(iitem.length + "")
                });
            });
        }
        sbox.autoids = autolcols;
        // now we have everything lets just dop something easier!!
        const ssche = await comm.post(ServerPath.addSchema, sbox);
        if (!ssche) {
            return undefined;
        }
        const ret = this.convertBoxToSchema(ssche, schema.letrid, box);
        if (ret && schema.parentschema && ssche.parentschemaid === ((_a = schema.parentschema) === null || _a === void 0 ? void 0 : _a.id)) {
            ret.parentschema = schema.parentschema;
        }
        return ret;
    }
    async deleteRows_old(rids, sid, letrid) {
        const res = [];
        for (const rid of rids) {
            const req = {
                letrid: letrid,
                schemaid: sid,
                rowid: rid,
                entries: [],
                assignedto: [],
                updateassignee: true
            };
            const ok = await comm.post(ServerPath.deleteRow, req);
            if (ok) {
                res.push(rid);
            }
        }
        return res;
    }
    async deleteRows(rids, sid, letrid, deleteall = false) {
        if (deleteall) {
            const req = {
                letrid: letrid,
                schemaid: sid,
                rowids: [],
                deleteall: true
            };
            return await comm.post(ServerPath.deleteRows, req);
        }
        else {
            let chunkSize = 1000;
            let ids = [];
            for (let i = 0; i < rids.length; i += chunkSize) {
                const chunk = rids.slice(i, i + chunkSize);
                const req = {
                    letrid: letrid,
                    schemaid: sid,
                    rowids: chunk,
                };
                const ret = await comm.post(ServerPath.deleteRows, req);
                if (ret !== undefined) {
                    ids = ids.concat(ret.rowids);
                }
            }
            const res = {
                schemaid: sid,
                letrid: letrid,
                deleteall: false,
                rowids: ids
            };
            return res;
        }
    }
    async duplicateRows(rids, sid, letrid, duplicateall = false) {
        let res = [];
        if (duplicateall) {
            const req = {
                letrid: letrid,
                schemaid: sid,
                rowids: [],
                duplicateall: true
            };
            const ret = await comm.post(ServerPath.duplicateRows, req);
            if (ret !== undefined) {
                res = ret;
            }
        }
        else {
            let chunkSize = 1000;
            for (let i = 0; i < rids.length; i += chunkSize) {
                const chunk = rids.slice(i, i + chunkSize);
                const req = {
                    letrid: letrid,
                    schemaid: sid,
                    rowids: chunk,
                    duplicateall: false
                };
                const ret = await comm.post(ServerPath.duplicateRows, req);
                if (ret !== undefined) {
                    res = res.concat(ret);
                }
            }
        }
        return res;
    }
    getUpdateEntries(entries, letrid, sid, schemaaccesskey, schemanonce, rowid = "", sitems = [], deleteentries = [], notifyvies = [], assigncols = []) {
        const sentries = [];
        const assignedto = [];
        const smap = new Map();
        for (const it of sitems) {
            if (it.type == SchemaItemType.List) {
                const lit = it;
                if (lit.items) {
                    for (const li of lit.items) {
                        smap.set(it.id + "----" + li.item.id, li.item);
                    }
                }
            }
            else {
                smap.set(it.id, it);
            }
        }
        let savedentryre = [];
        let uass = false;
        const assigncol = new Map();
        for (const entry of entries) {
            const sitem = smap.get(entry.cellid);
            if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                assignedto.push(...entry.celldata.split(","));
                const parts = entry.celldata.split(",");
                if (parts.length > 0) {
                    for (const pt of parts) {
                        if (!assigncol.has(pt)) {
                            assigncol.set(pt, {
                                userid: pt,
                                columnid: []
                            });
                        }
                        const cols = assigncol.get(pt);
                        if (cols && cols.columnid.indexOf(entry.cellid) === -1) {
                            cols.columnid.push(entry.cellid);
                        }
                    }
                }
                uass = true;
            }
            const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
            const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
            let rowindex = entry.rowindex;
            if (entry.schemaid !== sid && rowindex < 1 && rowindex > 0) {
                rowindex = rowindex * 100000;
            }
            savedentryre.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, decrypted: false }));
            delete entry.objs;
            delete entry.schsdec;
            delete entry.objsdec;
            delete entry.shs;
            delete entry.remotevalue;
            let rule = "";
            if (entry.ruleopen) {
                rule = JSON.stringify(entry.ruleopen);
                delete entry.ruleopen;
            }
            const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
            sentries.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
        }
        const arreq = {
            letrid: letrid,
            schemaid: sid,
            entries: sentries,
            rowid: rowid,
            assignedto: assignedto,
            updateassignee: uass,
            deletecellids: deleteentries,
            assignedcolumnids: Array.from(assigncol.values()),
            notifyviews: notifyvies,
            colusers: assigncols
        };
        return arreq;
    }
    async updateSchema(schema, rowupdatereq = []) {
        var _a, _b, _c;
        const box = InMemoryRepo.getLetrPassword(schema.letrid);
        if (box === undefined) {
            return undefined;
        }
        if (!schema.schemaintegrationbox) {
            const rsabox = LetrwingCrypto.getNewKeypair();
            schema.schemapublickey = rsabox.publickey;
            schema.schemaintegrationbox = LetrwingCrypto.secretBox(JSON.stringify(rsabox), schema.accesskey);
        }
        const sbox = this.convertSchemaToBox(schema, box, schema.letrid);
        if (rowupdatereq.length > 0) {
            let arreq = [];
            for (const req of rowupdatereq) {
                const areq = this.getUpdateEntries(req.entries, req.letrid, req.sid, req.accesskey, req.nonce, (_a = req.rowid) !== null && _a !== void 0 ? _a : '', req.schemaitems, (_b = req.deleteentries) !== null && _b !== void 0 ? _b : [], req.nvs, req.assigns);
                arreq.push(areq);
            }
            sbox['rowupdatereq'] = arreq;
        }
        const autolcols = [];
        const iditems = schema.items.filter((item) => item.type === SchemaItemType.ID);
        if (iditems && iditems.length > 0) {
            iditems.forEach((id) => {
                const iitem = id;
                autolcols.push({
                    cellid: id.id,
                    length: parseInt(iitem.length + "")
                });
            });
        }
        sbox.autoids = autolcols;
        // now we have everything lets just dop something easier!!
        const ssche = await comm.post(ServerPath.updateSchema, sbox);
        if (!ssche) {
            return undefined;
        }
        const ret = this.convertBoxToSchema(ssche, schema.letrid, box);
        if (ret && schema.parentschema && ssche.parentschemaid === ((_c = schema.parentschema) === null || _c === void 0 ? void 0 : _c.id)) {
            ret.parentschema = schema.parentschema;
        }
        return ret;
    }
    async getAllDatasheets(letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            datasheetid: ''
        };
        const schemas = await comm.post(ServerPath.getDatasheet, req);
        const ret = [];
        if (!schemas || schemas.length === 0) {
            return ret;
        }
        for (const sch of schemas) {
            const bc = this.convertBoxToSheet(sch, letrid, box);
            if (bc) {
                ret.push(bc);
            }
        }
        return ret;
    }
    async getAllSchemas(letrid, datasheet, schemaids) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            datasheetid: datasheet
        };
        if (schemaids.length > 0) {
            req['schemaids'] = schemaids;
        }
        const schemas = await comm.post(ServerPath.getSchemas, req);
        const ret = [];
        if (!schemas || schemas.schemas.length === 0) {
            return ret;
        }
        // lets get the parent one first 
        const pschema = new Map();
        for (const sch of schemas.parentschemas) {
            const bc = this.convertBoxToSchema(sch, letrid, box);
            if (bc) {
                pschema.set(bc.id, bc);
            }
        }
        for (const sch of schemas.schemas) {
            const bc = this.convertBoxToSchema(sch, letrid, box);
            if (bc) {
                pschema.set(bc.id, Object.assign({}, bc)); // before assigning parent to this!
                if (sch.parentschemaid && pschema.has(sch.parentschemaid)) {
                    bc.parentschema = pschema.get(sch.parentschemaid);
                    bc.parentschemaid = sch.parentschemaid;
                    if (bc.parentschema) {
                        bc.parentschema.parentschema = undefined;
                        bc.parentschema.childrenschema = undefined;
                    }
                }
                ret.push(bc);
            }
        }
        for (const sch of ret) {
            if (sch.childrensids) {
                for (const cid of sch.childrensids) {
                    const chsch = pschema.get(cid);
                    if (chsch) {
                        if (!sch.childrenschema) {
                            sch.childrenschema = [];
                        }
                        sch.childrenschema.push(Object.assign(Object.assign({}, chsch), { parentschema: undefined, childrenschema: undefined }));
                    }
                }
            }
        }
        return ret;
    }
    async updateAndDeleteEntries(allentrues, deleteentries = [], letrid, sid, schemaaccesskey, schemanonce, sitems = [], notifyvies = [], assigncols = []) {
        var _a, _b;
        const req = [];
        const savedentryrerow = new Map();
        let index = 0;
        for (const entries of allentrues) {
            const sentries = [];
            const assignedto = [];
            const smap = new Map();
            sitems.forEach((si) => smap.set(si.id, si));
            let savedentryre = [];
            let uass = false;
            for (const entry of entries) {
                const sitem = smap.get(entry.cellid);
                if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                    assignedto.push(...entry.celldata.split(","));
                    uass = true;
                }
                const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                let rowindex = entry.rowindex;
                if (entry.schemaid !== sid && rowindex < 1 && rowindex > 0) {
                    rowindex = rowindex * 100000;
                }
                savedentryre.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, decrypted: false }));
                delete entry.objs;
                delete entry.schsdec;
                delete entry.objsdec;
                delete entry.shs;
                delete entry.remotevalue;
                let rule = "";
                if (entry.ruleopen) {
                    rule = JSON.stringify(entry.ruleopen);
                    delete entry.ruleopen;
                }
                const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                sentries.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
                savedentryrerow.set(entries[0].rowid, savedentryre);
            }
            const delen = deleteentries[index];
            let rowid = entries.length > 0 ? entries[0].rowid : "";
            if (delen && delen.length > 0) {
                for (const del of delen) {
                    del.celldata = "";
                    del.cellfindstr = "";
                    if (!rowid) {
                        rowid = del.rowid;
                    }
                }
            }
            const arreq = {
                letrid: letrid,
                schemaid: sid,
                entries: sentries,
                rowid: rowid,
                assignedto: assignedto,
                updateassignee: uass,
                deletecellids: deleteentries[index],
                colusers: assigncols[index],
                notifyviews: notifyvies[index]
            };
            index++;
            req.push(arreq);
        }
        const res = await comm.post(ServerPath.updaterows, req);
        const ret = [];
        if (res) {
            const entrybyrows = new Map();
            for (const er of res) {
                if (!entrybyrows.has(er.rowid)) {
                    entrybyrows.set(er.rowid, []);
                }
                (_a = entrybyrows.get(er.rowid)) === null || _a === void 0 ? void 0 : _a.push(er);
            }
            for (const [rowid, ens] of entrybyrows) {
                const entrybycellid = new Map();
                for (const er of ens) {
                    let cellid = er.cellid;
                    if (er.subrowindex) {
                        cellid += "----" + er.subrowindex;
                    }
                    entrybycellid.set(cellid, er);
                }
                const savedentryre = (_b = savedentryrerow.get(rowid)) !== null && _b !== void 0 ? _b : [];
                for (const se of savedentryre !== null && savedentryre !== void 0 ? savedentryre : []) {
                    let cellid = se.cellid;
                    if (se.subrowindex) {
                        cellid += "----" + se.subrowindex;
                    }
                    const ren = entrybycellid.get(cellid);
                    if (ren) {
                        se.id = ren.id;
                    }
                }
                const row = { rowid: rowid, rowindex: -1, entry: savedentryre };
                ret.push(row);
            }
            return ret;
        }
    }
    async updateSchemaDependentRows(addentr, updateend, letrid, sid, updateschemaid, sitems = [], schemaaccesskey, schemanonce) {
        const addrows = [];
        const updaterows = [];
        const smap = new Map();
        for (const it of sitems) {
            if (it.type == SchemaItemType.List) {
                const lit = it;
                if (lit.items) {
                    for (const li of lit.items) {
                        smap.set(it.id + "----" + li.item.id, li.item);
                    }
                }
            }
            else {
                smap.set(it.id, it);
            }
        }
        for (const entries of addentr) {
            const sentries = [];
            for (const entry of entries) {
                const sitem = smap.get(entry.cellid);
                const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                let rowindex = entry.rowindex;
                if (entry.schemaid !== sid && rowindex < 1 && rowindex > 0) {
                    rowindex = rowindex * 100000;
                }
                delete entry.objs;
                delete entry.schsdec;
                delete entry.objsdec;
                delete entry.shs;
                delete entry.remotevalue;
                let rule = "";
                if (entry.ruleopen) {
                    rule = JSON.stringify(entry.ruleopen);
                    delete entry.ruleopen;
                }
                const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                sentries.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
            }
            const arreq = {
                entries: sentries,
            };
            addrows.push(arreq);
        }
        for (const entries of updateend) {
            const sentries = [];
            for (const entry of entries) {
                const sitem = smap.get(entry.cellid);
                const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                let rowindex = entry.rowindex;
                if (entry.schemaid !== sid && rowindex < 1 && rowindex > 0) {
                    rowindex = rowindex * 100000;
                }
                delete entry.objs;
                delete entry.schsdec;
                delete entry.objsdec;
                delete entry.shs;
                delete entry.remotevalue;
                let rule = "";
                if (entry.ruleopen) {
                    rule = JSON.stringify(entry.ruleopen);
                    delete entry.ruleopen;
                }
                const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                sentries.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
            }
            const arreq = {
                entries: sentries,
            };
            updaterows.push(arreq);
        }
        const req = {
            letrid: letrid,
            schemaid: sid,
            updateschemaid: updateschemaid,
            updaterows: [],
            addrows: [],
            dataid: '',
            eids: Array.from(smap.keys())
        };
        const res = await comm.post(ServerPath.updateSchemaDependentRows, req);
        if (!res) {
            return [];
        }
        return res.rows;
    }
    async updateEntries(entries, letrid, sid, schemaaccesskey, schemanonce, rowid = "", sitems = [], deleteentries = [], notifyvies = [], assigncols = [], cun = []) {
        const sentries = [];
        const assignedto = [];
        const smap = new Map();
        for (const it of sitems) {
            if (it.type == SchemaItemType.List) {
                const lit = it;
                if (lit.items) {
                    for (const li of lit.items) {
                        smap.set(it.id + "----" + li.item.id, li.item);
                    }
                }
            }
            else {
                smap.set(it.id, it);
            }
        }
        let savedentryre = [];
        let uass = false;
        const assigncol = new Map();
        for (const entry of entries) {
            const sitem = smap.get(entry.cellid);
            if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                assignedto.push(...entry.celldata.split(","));
                const parts = entry.celldata.split(",");
                if (parts.length > 0) {
                    for (const pt of parts) {
                        if (!assigncol.has(pt)) {
                            assigncol.set(pt, {
                                userid: pt,
                                columnid: []
                            });
                        }
                        const cols = assigncol.get(pt);
                        if (cols && cols.columnid.indexOf(entry.cellid) === -1) {
                            cols.columnid.push(entry.cellid);
                        }
                    }
                }
                uass = true;
            }
            const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
            const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
            let rowindex = entry.rowindex;
            if (entry.schemaid !== sid && rowindex < 1 && rowindex > 0) {
                rowindex = rowindex * 100000;
            }
            savedentryre.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, decrypted: false }));
            delete entry.objs;
            delete entry.schsdec;
            delete entry.objsdec;
            delete entry.shs;
            delete entry.remotevalue;
            let rule = "";
            if (entry.ruleopen) {
                rule = JSON.stringify(entry.ruleopen);
                delete entry.ruleopen;
            }
            const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
            sentries.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
        }
        const arreq = {
            letrid: letrid,
            schemaid: sid,
            entries: sentries,
            rowid: rowid,
            assignedto: assignedto,
            updateassignee: uass,
            deletecellids: deleteentries,
            assignedcolumnids: Array.from(assigncol.values()),
            notifyviews: notifyvies,
            colusers: assigncols,
            customnotify: cun
        };
        const res = await comm.post(ServerPath.updaterow, arreq);
        if (res) {
            // lets get the id sorted first 
            const entrybycellid = new Map();
            for (const er of res) {
                let cellid = er.cellid;
                if (er.subrowindex) {
                    cellid += "----" + er.subrowindex;
                }
                entrybycellid.set(cellid, er);
            }
            for (const se of savedentryre) {
                let cellid = se.cellid;
                if (se.subrowindex) {
                    cellid += "----" + se.subrowindex;
                }
                const ren = entrybycellid.get(cellid);
                if (ren) {
                    se.id = ren.id;
                }
            }
            const row = { rowid: rowid, rowindex: -1, entry: savedentryre };
            return row;
        }
        return undefined;
    }
    async getUpdateSchemaSince(mysid, since, reqsid, letrid) {
        const req = {
            schemaid: mysid,
            reqschemaid: reqsid,
            since: since,
            letrid: letrid,
            dataid: ""
        };
        const res = await comm.post(ServerPath.getUpdatesSince, req);
        const result = {
            delrows: [],
            entries: [],
            reqsid: reqsid,
            sid: mysid
        };
        if (res !== undefined) {
            // now we can just return this from here!
            for (const re of res) {
                if (re.type === UpdateType.RowDelete) {
                    const dre = JSON.parse(JSON.stringify(re));
                    result.delrows.push(...dre.data.itemids);
                }
                else if (re.type === UpdateType.Row) {
                    const dre = JSON.parse(JSON.stringify(re));
                    result.entries.push(...dre.data);
                }
                else if (re.type === UpdateType.Cell) {
                    const dre = JSON.parse(JSON.stringify(re));
                    result.entries.push(...dre.data);
                }
            }
        }
        return result;
    }
    async storeEntriesInChunk(eentries, letrid, sid, schemaaccesskey, schemanonce, rowid = "", sitems = [], notifyvies = [], assigncols = []) {
        var _a, _b;
        let windowsize = 1;
        let ret = [];
        let batchrows = [];
        let activebatches = [];
        let batchreq = [];
        const smap = new Map();
        sitems.forEach((si) => smap.set(si.id, si));
        let uass = false;
        const dobj = new Map();
        const sobj = new Map();
        const cmap = new Map();
        let rowindex = 0;
        const res = [];
        for (const entries of eentries) {
            const assignedto = [];
            let celiid = 0;
            const sentries = [];
            let savedentryre = [];
            for (const entry of entries) {
                const sitem = smap.get(entry.cellid);
                if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                    assignedto.push(...entry.celldata.split(","));
                    uass = true;
                }
                const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                let rule = "";
                if (entry.ruleopen) {
                    rule = JSON.stringify(entry.ruleopen);
                    delete entry.ruleopen;
                }
                const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                savedentryre.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
                (_a = entry.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((obj) => dobj.set(obj.id, obj));
                (_b = entry.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sh) => sobj.set(sh.id, sh));
                entry.clientid = rowindex + "_" + celiid + "_" + Math.random() + "";
                delete entry.objs;
                delete entry.schsdec;
                delete entry.objsdec;
                delete entry.shs;
                delete entry.ruleopen;
                delete entry.rule;
                delete entry.remotevalue;
                sentries.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg }));
                cmap.set(entry.clientid, sentries[sentries.length - 1]);
                celiid++;
            }
            const arreq = {
                letrid: letrid,
                schemaid: sid,
                entries: sentries,
                rowid: rowid,
                assignedto: assignedto,
                updateassignee: uass,
                colusers: assigncols[rowindex],
                notifyviews: notifyvies[rowindex]
            };
            activebatches.push(arreq);
            rowindex++;
            if (activebatches.length % 300 === 0) {
                batchreq.push(activebatches);
                activebatches = [];
            }
            if (batchreq.length > 1) {
                const pm = [];
                for (const re of batchreq) {
                    pm.push(comm.post(ServerPath.addrows, re));
                }
                const cres = await Promise.all(pm);
                for (const r of cres) {
                    if (r) {
                        res.push(...r);
                    }
                }
                batchreq = [];
            }
        }
        if (activebatches.length > 0) {
            batchreq.push(activebatches);
        }
        if (batchreq.length > 0) {
            const pm = [];
            for (const re of batchreq) {
                pm.push(comm.post(ServerPath.addrows, re));
            }
            const cres = await Promise.all(pm);
            for (const r of cres) {
                if (r) {
                    res.push(...r);
                }
            }
        }
        if (res !== undefined) {
            return res.map((r) => {
                let ens = [];
                for (const entr of r) {
                    const ent = cmap.get(entr.clientid);
                    if (!ent)
                        continue;
                    ent.id = entr.id;
                    ens.push(ent);
                    ent.rowid = entr.rowid;
                    ent.rowindex = entr.rowindex;
                    if (ent.linkedids) {
                        if (!ent.objsdec)
                            ent.objsdec = [];
                        if (!ent.schsdec)
                            ent.schsdec = [];
                        for (const lid of ent.linkedids) {
                            const parts = lid.split("-");
                            if (parts.length < 2)
                                continue;
                            if (lid.startsWith("objs-")) {
                                const bk = dobj.get(parts[1]);
                                if (bk) {
                                    ent.objsdec.push(bk);
                                }
                            }
                            else if (lid.startsWith("dch-") && parts.length > 2) {
                                const sh = sobj.get(parts[2]);
                                if (sh) {
                                    ent.schsdec.push(sh);
                                }
                            }
                        }
                    }
                }
                return { rowid: r[0].rowid, rowindex: r[0].rowindex, entry: ens };
            });
        }
        return res;
    }
    async storeEntriesBatch(batchEentries, letrid, sid, schemaaccesskey, schemanonce, rowid = "", sitems = [], notifyvies = [], assigncols = []) {
        var _a, _b;
        const smap = new Map();
        sitems.forEach((si) => smap.set(si.id, si));
        let uass = false;
        const dobj = new Map();
        const sobj = new Map();
        const cmap = new Map();
        let rowindex = 0;
        const batchreq = [];
        const res = [];
        // maybe lets do batch and then 
        let activebatches = [];
        for (const eentries of batchEentries) {
            // lets now send these first!!
        }
        if (activebatches.length > 0) {
            const pm = [];
            for (const re of batchreq) {
                pm.push(comm.post(ServerPath.addrows, re));
            }
            const cres = await Promise.all(pm);
            for (const r of cres) {
                if (r) {
                    res.push(...r);
                }
            }
        }
        for (const eentries of batchEentries) {
            const req = [];
            for (const entries of eentries) {
                const assignedto = [];
                let celiid = 0;
                const sentries = [];
                let savedentryre = [];
                for (const entry of entries) {
                    const sitem = smap.get(entry.cellid);
                    if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                        assignedto.push(...entry.celldata.split(","));
                        uass = true;
                    }
                    const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                    const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                    let rule = "";
                    if (entry.ruleopen) {
                        rule = JSON.stringify(entry.ruleopen);
                        delete entry.ruleopen;
                    }
                    const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                    savedentryre.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
                    (_a = entry.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((obj) => dobj.set(obj.id, obj));
                    (_b = entry.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sh) => sobj.set(sh.id, sh));
                    entry.clientid = rowindex + "_" + celiid + "_" + Math.random() + "";
                    delete entry.objs;
                    delete entry.schsdec;
                    delete entry.objsdec;
                    delete entry.shs;
                    delete entry.ruleopen;
                    delete entry.rule;
                    delete entry.remotevalue;
                    sentries.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg }));
                    cmap.set(entry.clientid, sentries[sentries.length - 1]);
                    celiid++;
                }
                const arreq = {
                    letrid: letrid,
                    schemaid: sid,
                    entries: sentries,
                    rowid: rowid,
                    assignedto: assignedto,
                    updateassignee: uass,
                    colusers: assigncols[rowindex],
                    notifyviews: notifyvies[rowindex]
                };
                req.push(arreq);
                rowindex++;
            }
            batchreq.push(req);
        }
        // lets upload batch now!!
        // we need to process two batch at a time!!
        const pm = [];
        for (const re of batchreq) {
            pm.push(comm.post(ServerPath.addrows, re));
        }
        const cres = await Promise.all(pm);
        for (const r of cres) {
            if (r) {
                res.push(...r);
            }
        }
        // const res = await comm.post<EntryAddRes[][], AddRowRequest[]>(ServerPath.addrows, req);
        if (res !== undefined) {
            return res.map((r) => {
                let ens = [];
                for (const entr of r) {
                    const ent = cmap.get(entr.clientid);
                    if (!ent)
                        continue;
                    ens.push(ent);
                    ent.rowid = entr.rowid;
                    ent.rowindex = entr.rowindex;
                    if (ent.linkedids) {
                        if (!ent.objsdec)
                            ent.objsdec = [];
                        if (!ent.schsdec)
                            ent.schsdec = [];
                        for (const lid of ent.linkedids) {
                            const parts = lid.split("-");
                            if (parts.length < 2)
                                continue;
                            if (lid.startsWith("objs-")) {
                                const bk = dobj.get(parts[1]);
                                if (bk) {
                                    ent.objsdec.push(bk);
                                }
                            }
                            else if (lid.startsWith("dch-") && parts.length > 2) {
                                const sh = sobj.get(parts[2]);
                                if (sh) {
                                    ent.schsdec.push(sh);
                                }
                            }
                        }
                    }
                }
                return { rowid: r[0].rowid, rowindex: r[0].rowindex, entry: ens };
            });
        }
        return res;
    }
    async storeEntries(eentries, letrid, sid, schemaaccesskey, schemanonce, rowid = "", sitems = [], notifyvies = [], assigncols = []) {
        var _a, _b;
        const smap = new Map();
        sitems.forEach((si) => smap.set(si.id, si));
        let uass = false;
        const req = [];
        const dobj = new Map();
        const sobj = new Map();
        const cmap = new Map();
        let rowindex = 0;
        for (const entries of eentries) {
            const assignedto = [];
            let celiid = 0;
            const sentries = [];
            let savedentryre = [];
            for (const entry of entries) {
                const sitem = smap.get(entry.cellid);
                if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                    assignedto.push(...entry.celldata.split(","));
                    uass = true;
                }
                const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                let rule = "";
                if (entry.ruleopen) {
                    rule = JSON.stringify(entry.ruleopen);
                    delete entry.ruleopen;
                }
                const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                savedentryre.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
                (_a = entry.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((obj) => dobj.set(obj.id, obj));
                (_b = entry.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sh) => sobj.set(sh.id, sh));
                entry.clientid = rowindex + "_" + celiid + "_" + Math.random() + "";
                delete entry.objs;
                delete entry.schsdec;
                delete entry.objsdec;
                delete entry.shs;
                delete entry.ruleopen;
                delete entry.rule;
                delete entry.remotevalue;
                sentries.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg }));
                cmap.set(entry.clientid, sentries[sentries.length - 1]);
                celiid++;
            }
            const arreq = {
                letrid: letrid,
                schemaid: sid,
                entries: sentries,
                rowid: rowid,
                assignedto: assignedto,
                updateassignee: uass,
                colusers: assigncols[rowindex],
                notifyviews: notifyvies[rowindex]
            };
            req.push(arreq);
            rowindex++;
        }
        const res = await comm.post(ServerPath.addrows, req);
        if (res !== undefined) {
            return res.map((r) => {
                let ens = [];
                for (const entr of r) {
                    const ent = cmap.get(entr.clientid);
                    if (!ent)
                        continue;
                    ens.push(ent);
                    ent.rowid = entr.rowid;
                    ent.rowindex = entr.rowindex;
                    if (ent.linkedids) {
                        if (!ent.objsdec)
                            ent.objsdec = [];
                        if (!ent.schsdec)
                            ent.schsdec = [];
                        for (const lid of ent.linkedids) {
                            const parts = lid.split("-");
                            if (parts.length < 2)
                                continue;
                            if (lid.startsWith("objs-")) {
                                const bk = dobj.get(parts[1]);
                                if (bk) {
                                    ent.objsdec.push(bk);
                                }
                            }
                            else if (lid.startsWith("dch-") && parts.length > 2) {
                                const sh = sobj.get(parts[2]);
                                if (sh) {
                                    ent.schsdec.push(sh);
                                }
                            }
                        }
                    }
                }
                return { rowid: r[0].rowid, rowindex: r[0].rowindex, entry: ens };
            });
        }
        return res;
    }
    async addSchemaView(view, sh, letrid, asscols = []) {
        var _a, _b;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const codebox = LetrwingCrypto.secretBox(view.code, box);
        const datast = {
            letrkey: box,
            accesskey: sh.accesskey,
            nonce: sh.nonce
        };
        const databox = LetrwingCrypto.secretBox(JSON.stringify(datast), await LetrwingCrypto.getHash256(view.code));
        let edj = "";
        let data = "";
        if (view.displayjs) {
            data = view.displayjs;
        }
        if (view.customfilerjs) {
            data += "-----letrwing----" + view.customfilerjs;
        }
        if (view.customdefaultjs) {
            if (!view.customfilerjs) {
                data += "-----letrwing----" + "";
            }
            data += "-----letrwingdefault----" + view.customdefaultjs;
        }
        if (data) {
            const displayjss = LetrwingCrypto.secretBoxWithNonce(data, sh.accesskey, sh.nonce).encryptmsg;
            edj = displayjss;
        }
        const closeview = Object.assign(Object.assign({}, view), { displayjs: edj, filters: [], letrid: letrid, codebox: codebox, databox: databox, otherviews: [], users: [] });
        if (view.filters && view.filters.length > 0) {
            for (const fl of view.filters) {
                const edata = LetrwingCrypto.secretBox(JSON.stringify(fl.data), sh.accesskey);
                closeview.filters.push({
                    columnid: fl.columnid,
                    data: edata
                });
            }
        }
        const req = Object.assign(Object.assign({}, closeview), { assigncols: asscols });
        const res = await comm.post(ServerPath.addWebView, req);
        if (res) {
            let code = "";
            if (res.codebox) {
                code = (_a = LetrwingCrypto.decryptSecretBox(res.codebox, box)) !== null && _a !== void 0 ? _a : '';
            }
            let djs = "";
            let fjs = "";
            let defjs = "";
            if (res.displayjs) {
                const eb = new EncryptBox();
                eb.encryptmsg = res.displayjs;
                eb.nonce = sh.nonce;
                const data = (_b = LetrwingCrypto.decryptBoxWithNonceToStr(eb, sh.accesskey, sh.nonce)) !== null && _b !== void 0 ? _b : '';
                let dparts = data.split("-----letrwing----");
                if (dparts.length > 0) {
                    djs = dparts[0];
                }
                if (dparts.length > 1) {
                    let sdp = dparts[1].split("-----letrwingdefault----");
                    if (sdp.length > 0) {
                        fjs = sdp[0];
                    }
                    if (sdp.length > 1) {
                        defjs = sdp[1];
                    }
                }
            }
            const ret = Object.assign(Object.assign({}, res), { displayjs: djs, filters: [], code: code, otherviews: [], users: [], customdefaultjs: defjs, customfilerjs: fjs });
            for (const fl of res.filters) {
                try {
                    const str = LetrwingCrypto.decryptSecretBox(fl.data, sh.accesskey);
                    if (str === undefined)
                        continue;
                    ret.filters.push({
                        columnid: fl.columnid,
                        data: JSON.parse(str)
                    });
                }
                catch (ex) { }
            }
            return ret;
        }
        return undefined;
    }
    async getSchemaView(sh, letrid) {
        var _a, _b;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            id: "",
            schemaid: sh.id,
            letrid: letrid
        };
        const ress = await comm.post(ServerPath.getWebViews, req);
        if (ress) {
            const resp = [];
            for (const res of ress) {
                let code = "";
                if (res.codebox) {
                    code = (_a = LetrwingCrypto.decryptSecretBox(res.codebox, box)) !== null && _a !== void 0 ? _a : '';
                }
                let djs = "";
                let fjs = "";
                let defjs = "";
                if (res.displayjs) {
                    const eb = new EncryptBox();
                    eb.encryptmsg = res.displayjs;
                    eb.nonce = sh.nonce;
                    const data = (_b = LetrwingCrypto.decryptBoxWithNonceToStr(eb, sh.accesskey, sh.nonce)) !== null && _b !== void 0 ? _b : '';
                    let dparts = data.split("-----letrwing----");
                    if (dparts.length > 0) {
                        djs = dparts[0];
                    }
                    if (dparts.length > 1) {
                        let sdp = dparts[1].split("-----letrwingdefault----");
                        if (sdp.length > 0) {
                            fjs = sdp[0];
                        }
                        if (sdp.length > 1) {
                            defjs = sdp[1];
                        }
                    }
                }
                const ret = Object.assign(Object.assign({}, res), { displayjs: djs, filters: [], code: code, otherviews: [], users: [], customfilerjs: fjs, customdefaultjs: defjs });
                for (const fl of res.filters) {
                    try {
                        const str = LetrwingCrypto.decryptSecretBox(fl.data, sh.accesskey);
                        if (str === undefined)
                            continue;
                        ret.filters.push({
                            columnid: fl.columnid,
                            data: JSON.parse(str)
                        });
                    }
                    catch (ex) { }
                }
                resp.push(ret);
            }
            return resp;
        }
        return [];
    }
    async updateSchemaView(view, sh, letrid, asscols = []) {
        var _a, _b;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const codebox = LetrwingCrypto.secretBox(view.code, box);
        const datast = {
            letrkey: box,
            accesskey: sh.accesskey,
            nonce: sh.nonce
        };
        let edj = "";
        let data = "";
        if (view.displayjs) {
            data = view.displayjs;
        }
        if (view.customfilerjs) {
            data += "-----letrwing----" + view.customfilerjs;
        }
        if (view.customdefaultjs) {
            if (!view.customfilerjs) {
                data += "-----letrwing----" + "";
            }
            data += "-----letrwingdefault----" + view.customdefaultjs;
        }
        if (data) {
            const displayjss = LetrwingCrypto.secretBoxWithNonce(data, sh.accesskey, sh.nonce).encryptmsg;
            edj = displayjss;
        }
        const databox = LetrwingCrypto.secretBox(JSON.stringify(datast), await LetrwingCrypto.getHash256(view.code));
        const closeview = Object.assign(Object.assign({}, view), { displayjs: edj, filters: [], letrid: letrid, codebox: codebox, databox: databox, otherviews: [], users: [] });
        if (view.filters && view.filters.length > 0) {
            for (const fl of view.filters) {
                const edata = LetrwingCrypto.secretBox(JSON.stringify(fl.data), sh.accesskey);
                closeview.filters.push({
                    columnid: fl.columnid,
                    data: edata
                });
            }
        }
        const req = Object.assign(Object.assign({}, closeview), { assigncols: asscols });
        const res = await comm.post(ServerPath.updateWebView, req);
        if (res) {
            let code = "";
            if (res.codebox) {
                code = (_a = LetrwingCrypto.decryptSecretBox(res.codebox, box)) !== null && _a !== void 0 ? _a : '';
            }
            let djs = "";
            let fjs = "";
            let defjs = "";
            if (res.displayjs) {
                const eb = new EncryptBox();
                eb.encryptmsg = res.displayjs;
                eb.nonce = sh.nonce;
                const data = (_b = LetrwingCrypto.decryptBoxWithNonceToStr(eb, sh.accesskey, sh.nonce)) !== null && _b !== void 0 ? _b : '';
                let dparts = data.split("-----letrwing----");
                if (dparts.length > 0) {
                    djs = dparts[0];
                }
                if (dparts.length > 1) {
                    let sdp = dparts[1].split("-----letrwingdefault----");
                    if (sdp.length > 0) {
                        fjs = sdp[0];
                    }
                    if (sdp.length > 1) {
                        defjs = sdp[1];
                    }
                }
            }
            const ret = Object.assign(Object.assign({}, res), { displayjs: djs, customdefaultjs: defjs, filters: [], code: code, otherviews: [], users: [], customfilerjs: fjs });
            for (const fl of res.filters) {
                try {
                    const str = LetrwingCrypto.decryptSecretBox(fl.data, sh.accesskey);
                    if (str === undefined)
                        continue;
                    ret.filters.push({
                        columnid: fl.columnid,
                        data: JSON.parse(str)
                    });
                }
                catch (ex) { }
            }
            return ret;
        }
        return undefined;
    }
    async deleteSchemaView(id, schemaid, letrid) {
        const req = {
            id: id,
            schemaid: schemaid,
            letrid: letrid
        };
        const ret = await comm.post(ServerPath.deleteWebView, req);
        return ret !== null && ret !== void 0 ? ret : false;
    }
    async addSchemaWebForm(form) {
        const box = InMemoryRepo.getLetrPassword(form.letrid);
        if (box === undefined) {
            return undefined;
        }
        const sbox = await CONVERTUTIL.convertSchemaWebFormToBox(form, form.letrid, box);
        if (!sbox)
            return undefined;
        const ret = await comm.post(ServerPath.addSchemaForm, sbox);
        if (!ret)
            return undefined;
        return await CONVERTUTIL.convertSchemaWebboxToForm(ret, box);
    }
    async updateSchemaWebForm(form) {
        const box = InMemoryRepo.getLetrPassword(form.letrid);
        if (box === undefined) {
            return undefined;
        }
        const sbox = await CONVERTUTIL.convertSchemaWebFormToBox(form, form.letrid, box);
        if (!sbox)
            return undefined;
        const ret = await comm.post(ServerPath.updateSchemaForm, sbox);
        if (!ret)
            return undefined;
        return await CONVERTUTIL.convertSchemaWebboxToForm(ret, box);
    }
    async getSchemaWebForm(id, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const ret = await comm.post(ServerPath.getSchemaForm, {
            schemaid: id,
            letrid: letrid
        });
        if (!ret)
            return [];
        const res = [];
        for (const r of ret) {
            const openr = await CONVERTUTIL.convertSchemaWebboxToForm(r, box);
            if (openr) {
                res.push(openr);
            }
        }
        return res;
    }
    async deleteSchemaWebForm(id, schemaid, letrid) {
        const req = {
            id: id,
            schemaid: schemaid,
            letrid: letrid
        };
        const res = await comm.post(ServerPath.deleteSchemaForm, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async addSchemaPrintTemplate(form) {
        const box = InMemoryRepo.getLetrPassword(form.letrid);
        if (box === undefined) {
            return undefined;
        }
        const sbox = await CONVERTUTIL.convertSchemaPrintTemplateToBox(form, form.letrid, box);
        if (!sbox)
            return undefined;
        const ret = await comm.post(ServerPath.addSchemaPrintTemplate, sbox);
        if (!ret)
            return undefined;
        return await CONVERTUTIL.convertSchemaPrintServerToForm(ret, box);
    }
    async updateSchemaPrintTemplate(form) {
        const box = InMemoryRepo.getLetrPassword(form.letrid);
        if (box === undefined) {
            return undefined;
        }
        const sbox = await CONVERTUTIL.convertSchemaPrintTemplateToBox(form, form.letrid, box);
        if (!sbox)
            return undefined;
        const ret = await comm.post(ServerPath.updateSchemaPrintTemplate, sbox);
        if (!ret)
            return undefined;
        return await CONVERTUTIL.convertSchemaPrintServerToForm(ret, box);
    }
    async checkAndGetAllRemoteItems(items, letrid, sid) {
        const ritems = [];
        for (const item of items) {
            if (item.type === SchemaItemType.Remote) {
                ritems.push(item);
            }
            else if (item.type === SchemaItemType.List) {
                const lit = item;
                if (!lit.items)
                    continue;
                for (const it of lit.items) {
                    if (it.item.type !== SchemaItemType.Remote)
                        continue;
                    const iitem = it.item;
                    if (iitem.type === SchemaItemType.Remote) {
                        ritems.push(iitem);
                    }
                }
            }
        }
        if (ritems.length > 0) {
            let ids = new Set();
            for (const it of ritems) {
                const rit = it;
                if (rit.did && rit.sid && rit.cid) {
                    ids.add(rit.sid + "~~~attach");
                }
            }
            if (ids.size > 0) {
                const req = {
                    letrid: letrid,
                    schemaid: sid,
                    schemafields: Array.from(ids)
                };
                const res = await this.getAllDependent(req);
                // now lets check and do the dance here now!!
                // we should check and get all stuff 
                // lets check which one need working now and we should call them 
                return res;
            }
        }
        return undefined;
    }
    async getAllRules(schemaid, letrid) {
        var _a, _b;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            schemaid: schemaid,
            letrid: letrid
        };
        const res = await comm.post(ServerPath.getRowRule, req);
        if (!res) {
            return [];
        }
        const resp = [];
        for (const r of res) {
            try {
                const bstr = LetrwingCrypto.decryptSecretBox(r.data, box);
                if (!bstr)
                    continue;
                const rules = JSON.parse(bstr);
                resp.push({
                    schemaid: r.schemaid,
                    letrid: r.letrid,
                    id: r.id,
                    data: (_a = rules.numberdata) !== null && _a !== void 0 ? _a : [],
                    rowid: r.rowid,
                    datarules: (_b = rules.datarules) !== null && _b !== void 0 ? _b : []
                });
            }
            catch (ex) { }
        }
        return resp;
    }
    async storeRules(rule) {
        var _a, _b, _c, _d;
        const box = InMemoryRepo.getLetrPassword(rule.letrid);
        if (box === undefined) {
            return [];
        }
        const dsch = new Set();
        for (const r of rule.data) {
            for (const rp of r.rule) {
                for (const pp of rp.params) {
                    if (pp.type === 'schema') {
                        const parts = pp.value.split("~~~");
                        if (parts.length > 2) {
                            let pid = r.colid + "~~~" + parts[0] + "~~~" + parts[1] + "~~~" + parts[2];
                            dsch.add(pid);
                        }
                    }
                }
            }
        }
        for (const r of rule.datarules) {
            for (const rp of r.rule) {
                for (const pp of rp.params) {
                    if (pp.type === 'schema') {
                        const parts = pp.value.split("~~~");
                        if (parts.length > 2) {
                            let pid = r.colid + "~~~" + parts[0] + "~~~" + parts[1] + "~~~" + parts[2];
                            dsch.add(pid);
                        }
                    }
                }
            }
        }
        const pk = {
            datarules: (_a = rule.datarules) !== null && _a !== void 0 ? _a : [],
            numberdata: (_b = rule.data) !== null && _b !== void 0 ? _b : []
        };
        const rrcl = {
            id: rule.id,
            schemaid: rule.schemaid,
            letrid: rule.letrid,
            data: LetrwingCrypto.secretBox(JSON.stringify(pk), box),
            rowid: rule.rowid,
            dependents: Array.from(dsch.values())
        };
        const res = await comm.post(ServerPath.addRowRule, rrcl);
        if (!res)
            return [];
        const resp = [];
        for (const r of res) {
            try {
                const bstr = LetrwingCrypto.decryptSecretBox(r.data, box);
                if (!bstr)
                    continue;
                const rules = JSON.parse(bstr);
                resp.push({
                    schemaid: r.schemaid,
                    letrid: r.letrid,
                    id: r.id,
                    data: (_c = rules.numberdata) !== null && _c !== void 0 ? _c : [],
                    rowid: r.rowid,
                    datarules: (_d = rules.datarules) !== null && _d !== void 0 ? _d : []
                });
            }
            catch (ex) { }
        }
        return resp;
    }
    async getAllDependentEnt(rules, sh, letrid, wsid, ent, schemacollection, doneids = new Map(), dodatarules = false, fetcviewdepen = true) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
        let databyschemacol = new Map();
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return databyschemacol;
        }
        // so we need to make sure only get the entries we dont aleadry have
        const rulebycellid = new Map();
        if (sh.rules) {
            for (const rule of sh.rules) {
                rulebycellid.set(rule.colid, rule);
            }
        }
        if (rules) {
            for (const r of rules) {
                for (const sr of (_a = r.data) !== null && _a !== void 0 ? _a : []) {
                    rulebycellid.set(r.rowid + "---" + sr.colid, sr);
                }
            }
        }
        let mycellids = new Set();
        for (const en of ent) {
            mycellids.add(en.cellid);
            const rid = en.rowid + "---" + en.cellid;
            if (rulebycellid.has(rid)) {
                en.ruleopen = rulebycellid.get(rid);
            }
            else if (rulebycellid.has(en.cellid) && !en.ruleopen) {
                en.ruleopen = rulebycellid.get(en.cellid);
            }
        }
        const alldata = new Set();
        const columnidsBySh = new Map();
        const akey = sh.accesskey;
        const nonce = sh.nonce;
        if (sh.rules) {
            // have to use nested as this is okay
            for (const rule of sh.rules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_b = columnidsBySh.get(sh.id)) === null || _b === void 0 ? void 0 : _b.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_c = columnidsBySh.get(sh.id)) === null || _c === void 0 ? void 0 : _c.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (dodatarules && sh.datarules && sh.datarules.length > 0) {
            for (const rule of sh.datarules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_d = columnidsBySh.get(sh.id)) === null || _d === void 0 ? void 0 : _d.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_e = columnidsBySh.get(sh.id)) === null || _e === void 0 ? void 0 : _e.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        for (const ru of rules) {
            for (const rule of (_f = ru.data) !== null && _f !== void 0 ? _f : []) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_g = columnidsBySh.get(sh.id)) === null || _g === void 0 ? void 0 : _g.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_h = columnidsBySh.get(sh.id)) === null || _h === void 0 ? void 0 : _h.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (dodatarules) {
                for (const rule of (_j = ru.datarules) !== null && _j !== void 0 ? _j : []) {
                    for (const pa of rule.rule) {
                        for (const param of pa.params) {
                            const parts = param.value.split("~~~");
                            if (parts.length > 2) {
                                if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                    alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                                }
                                if (columnidsBySh.has(sh.id)) {
                                    columnidsBySh.set(sh.id, new Set());
                                }
                                (_k = columnidsBySh.get(sh.id)) === null || _k === void 0 ? void 0 : _k.add(parts[2]);
                                if (param.conds && param.conds.length > 0) {
                                    for (const con of param.conds) {
                                        if (con.params && con.params.length > 0) {
                                            for (const cc of con.params) {
                                                if (cc.leftcond) {
                                                    alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                    (_l = columnidsBySh.get(sh.id)) === null || _l === void 0 ? void 0 : _l.add(cc.leftcond);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (fetcviewdepen && sh.viewdepentschemas) {
            for (const sid of sh.viewdepentschemas) {
                alldata.add(sid + "~~~attach");
            }
        }
        if (alldata.size > 0) {
            let removeids = new Set();
            for (const d of alldata) {
                const parts = d.split("~~~");
                if (!d.endsWith("attach") && doneids.has(parts[1])) {
                    const ds = doneids.get(parts[1]);
                    if ((ds === null || ds === void 0 ? void 0 : ds.has(parts[2])) || (ds === null || ds === void 0 ? void 0 : ds.has("attach"))) {
                        removeids.add(d);
                    }
                }
                else if (d.endsWith("attach") && doneids.has(parts[0]) && ((_m = doneids.get(parts[0])) === null || _m === void 0 ? void 0 : _m.has("attach"))) {
                    removeids.add(d);
                }
            }
            for (const r of removeids) {
                alldata.delete(r);
            }
            const req = {
                letrid: letrid,
                schemaid: wsid,
                schemafields: Array.from(alldata)
            };
            const res = await this.getAllDependent(req);
            if (res) {
                const schemacellids = new Map();
                for (const d of alldata) {
                    let msid = "";
                    const parts = d.split("~~~");
                    let cid = "";
                    if (d.endsWith("attach")) {
                        cid = "attach";
                        msid = parts[0];
                    }
                    else {
                        cid = parts[2];
                        msid = parts[1];
                    }
                    if (!doneids.has(msid)) {
                        doneids.set(msid, new Set());
                    }
                    (_o = doneids.get(msid)) === null || _o === void 0 ? void 0 : _o.add(cid);
                    if (!schemacellids.has(msid)) {
                        schemacellids.set(msid, new Set());
                    }
                    if (d.endsWith("attach")) {
                        (_p = schemacellids.get(msid)) === null || _p === void 0 ? void 0 : _p.add("attach");
                        continue;
                    }
                    (_q = schemacellids.get(msid)) === null || _q === void 0 ? void 0 : _q.add(parts[2]);
                }
                for (const d of res.data) {
                    const dsh = d.schema;
                    let dens = d.entries;
                    let cellids = (_r = schemacellids.get(d.schema.id)) !== null && _r !== void 0 ? _r : new Set();
                    const dshopen = this.convertBoxToSchema(dsh, letrid, box);
                    if (!dshopen)
                        continue;
                    schemacollection.set(dshopen.id, dshopen);
                    if (cellids.has("attach")) {
                        cellids.clear();
                        for (const it of dshopen.items) {
                            if (it.type == SchemaItemType.List) {
                                const lit = it;
                                if (lit.items) {
                                    for (const li of lit.items) {
                                        cellids.add(it.id + "----" + li.item.id);
                                    }
                                }
                            }
                            else {
                                cellids.add(it.id);
                            }
                        }
                    }
                    let entrymap = new Map();
                    let enrows = new Set();
                    if (dens) {
                        for (const de of dens) {
                            enrows.add(de.rowid);
                            let id = de.rowid + "~~~" + de.cellid;
                            if (!entrymap.has(id)) {
                                entrymap.set(id, []);
                            }
                            (_s = entrymap.get(id)) === null || _s === void 0 ? void 0 : _s.push(de);
                        }
                    }
                    const srules = new Map();
                    const rowrules = new Map();
                    if (dshopen.rules) {
                        for (const shrule of dshopen.rules) {
                            srules.set(shrule.colid, shrule);
                        }
                    }
                    if (d.rulesopen) {
                        for (const rr of d.rulesopen) {
                            for (const shrule of (_t = rr.data) !== null && _t !== void 0 ? _t : []) {
                                rowrules.set(rr.rowid + "~~~" + shrule.colid, shrule);
                            }
                        }
                    }
                    let cells = Array.from(cellids);
                    const subrowmap = new Map();
                    for (const dn of dens) {
                        const cid = dn.cellid;
                        if (cid.indexOf("----") !== -1) {
                            const pcid = cid.split("----")[0];
                            const rowid = dn.rowid;
                            const myid = rowid + "----" + pcid;
                            if (!subrowmap.has(myid)) {
                                subrowmap.set(myid, new Set());
                            }
                            const srid = dn.subrowindex;
                            if (srid) {
                                (_u = subrowmap.get(myid)) === null || _u === void 0 ? void 0 : _u.add(srid);
                            }
                        }
                    }
                    // now check what all columns we need 
                    for (let i = 0; i < cells.length; i++) {
                        let cellid = cells[i];
                        if (i % 1 !== 0)
                            await timeout(1);
                        // we need to make sure for all rows the entries their otherwise we need to add these 
                        for (const row of d.totalrows) {
                            const rid = row.id;
                            if (!enrows.has(rid))
                                continue;
                            if (cellid.indexOf("----") !== -1) {
                                const cp = cellid.split("----")[0];
                                const subrows = subrowmap.get(rid + "----" + cp);
                                if (subrows && subrows.size > 0) {
                                    // we have some sub rows lets get all subriowindex now and if we have entry for this great otherwise we need to add one
                                    // now we have all subrow lets check if we have all ids
                                    const myens = entrymap.get(row.id + "~~~" + cellid);
                                    const enmap = new Map();
                                    for (const mye of myens !== null && myens !== void 0 ? myens : []) {
                                        enmap.set((_v = mye.cellid + "----" + mye.subrowindex) !== null && _v !== void 0 ? _v : '', mye);
                                    }
                                    // okay lets check if we have all the data or create some of them
                                    for (const sr of subrows) {
                                        const me = enmap.get(cellid + "----" + sr);
                                        if (!me) {
                                            dens.push({
                                                celldata: '',
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: dsh.id,
                                                rowindex: row.rowidex,
                                                rowid: row.id,
                                                subrowindex: sr,
                                                id: LetrwingCrypto.getUUID(),
                                                sortstring: Math.random(),
                                                decrypted: false
                                            });
                                        }
                                    }
                                }
                                continue;
                            }
                            const cid = rid + "~~~" + cellid;
                            if (!entrymap.has(cid)) {
                                entrymap.set(cid, [{
                                        celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                        cellfindstr: 'test',
                                        cellid: cellid,
                                        schemaid: dsh.id,
                                        rowindex: row.rowidex,
                                        rowid: row.id,
                                        subrowindex: "",
                                        id: LetrwingCrypto.getUUID(),
                                        sortstring: Math.random(),
                                        decrypted: false
                                    }]);
                                dens.push({
                                    celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                    cellfindstr: 'test 1',
                                    cellid: cellid,
                                    schemaid: dsh.id,
                                    rowindex: row.rowidex,
                                    rowid: row.id,
                                    subrowindex: "",
                                    id: LetrwingCrypto.getUUID(),
                                    sortstring: Math.random(),
                                    decrypted: false
                                });
                            }
                        }
                    }
                    for (const den of dens) {
                        cellids.add(den.cellid);
                        if (dshopen) {
                            const rowrule = rowrules.get(den.rowid + "~~~" + den.cellid);
                            if (rowrule) {
                                den.ruleopen = rowrule;
                            }
                            else {
                                const shrule = srules.get(den.cellid);
                                if (shrule) {
                                    den.ruleopen = shrule;
                                }
                            }
                            // if no rule open this one it's a leaf
                            if (!den.ruleopen) {
                                decryptCell(den, dshopen);
                            }
                            const id = dshopen.datasheetid + "~~~" + dshopen.id + "~~~" + den.cellid;
                            if (!databyschemacol.has(id)) {
                                databyschemacol.set(id, new Map());
                            }
                            (_w = databyschemacol.get(id)) === null || _w === void 0 ? void 0 : _w.set(den.id, den);
                        }
                    }
                    // these 
                    // these 
                    if (dshopen.rules || d.rulesopen) {
                        let filterentris = [];
                        if (dshopen) {
                            let isschemarule = false;
                            let colrules = new Map();
                            if (dshopen.rules) {
                                // lets check if rule involve on our column and on other entity!
                                for (const shrule of dshopen.rules) {
                                    colrules.set(shrule.colid, shrule);
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                // we s
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    isschemarule = true;
                                                    break;
                                                }
                                                // we should also check condisiotn as well
                                                if (param.conds) {
                                                    for (const cond of param.conds) {
                                                        for (const subcon of cond.params) {
                                                            if (subcon.leftcond && !!cellids.has(subcon.leftcond)) {
                                                                isschemarule = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (isschemarule)
                                                break;
                                        }
                                    }
                                    if (isschemarule)
                                        break;
                                }
                                if (isschemarule) {
                                    filterentris.push(...dens);
                                }
                            }
                        }
                        const rules = d.rulesopen;
                        let cellrules = [];
                        if (rules) {
                            for (const rr of rules) {
                                let breakit = false;
                                for (const shrule of (_x = rr.data) !== null && _x !== void 0 ? _x : []) {
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    cellrules.push(rr);
                                                    breakit = true;
                                                    break;
                                                }
                                                if (param.conds) {
                                                    for (const cond of param.conds) {
                                                        for (const subcon of cond.params) {
                                                            if (subcon.leftcond && !!cellids.has(subcon.leftcond)) {
                                                                cellrules.push(rr);
                                                                breakit = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (breakit)
                                                break;
                                        }
                                    }
                                    if (breakit)
                                        break;
                                }
                            }
                        }
                        if (cellrules.length > 0 || filterentris.length > 0) {
                            if (dshopen) {
                                const dmap = await this.getAllDependentEnt(cellrules, dshopen, letrid, wsid, dens, schemacollection, doneids);
                                if (dmap) {
                                    // lets go over it
                                    for (const [k, v] of dmap) {
                                        const id = k;
                                        if (!databyschemacol.has(id)) {
                                            databyschemacol.set(id, new Map());
                                        }
                                        // possible to have duplicates too other side might beed to fix that!
                                        for (const [vk, vn] of v) {
                                            (_y = databyschemacol.get(id)) === null || _y === void 0 ? void 0 : _y.set(vn.id, vn);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // we do need to re do this as 
            }
        }
        // we should maybe fill in the missing cells now!
        return databyschemacol;
    }
    /**
     * get all entries and check then if they need stuff and recurrsively do this till you find the result!!
     *
     */
    // this is quite good stuff lets do something here!!
    async getAllDepedentForEntries(ens, sh, letrid, wsid) {
        var _a, _b;
        let databyschemacol = new Map();
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return databyschemacol;
        }
        let mycellids = new Set();
        for (const en of ens) {
            if (typeof en === 'string') {
                mycellids.add(en);
            }
            else {
                mycellids.add(en.cellid);
            }
        }
        const alldata = new Set();
        const akey = sh.accesskey;
        const nonce = sh.nonce;
        if (sh.rules) {
            // have to use nested as this is okay
            for (const rule of sh.rules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                        }
                    }
                }
            }
        }
        for (const en of ens) {
            if (typeof en === 'string')
                continue;
            if (en.rule && !en.decrypted) {
                try {
                    const val = LetrwingCrypto.decryptSecretBox(en.rule, akey);
                    if (val) {
                        const rv = JSON.parse(val);
                        en.ruleopen = rv;
                    }
                }
                catch (ex) { }
            }
            if (en.ruleopen) {
                for (const pa of en.ruleopen.rule) {
                    for (const param of pa.params) {
                        if (!param.value)
                            continue;
                        const parts = param.value.split("~~~");
                        if (parts.length < 2)
                            continue;
                        if (parts[1] !== sh.id) {
                            alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                        }
                    }
                }
            }
        }
        if (alldata.size > 0) {
            const req = {
                letrid: letrid,
                schemaid: wsid,
                schemafields: Array.from(alldata)
            };
            const res = await this.getAllDependent(req);
            if (res) {
                // fetch recurssively everything now!!
                for (const d of res.data) {
                    const dsh = d.schema;
                    const dens = d.entries;
                    if (dens && dens.length > 0) {
                        const dshopen = this.convertBoxToSchema(dsh, letrid, box);
                        if (dshopen) {
                            // lets first add all the entries and check if they need rules too
                            let doformulacheck = false;
                            let isschemarule = false;
                            let filterentris = [];
                            let cellids = new Set();
                            for (const den of dens) {
                                cellids.add(den.cellid);
                            }
                            let colrules = new Map();
                            if (dshopen.rules) {
                                // lets check if rule involve on our column and on other entity!
                                for (const shrule of dshopen.rules) {
                                    colrules.set(shrule.colid, shrule);
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    isschemarule = true;
                                                    break;
                                                }
                                            }
                                            if (isschemarule)
                                                break;
                                        }
                                    }
                                    if (isschemarule)
                                        break;
                                }
                            }
                            if (isschemarule) {
                                filterentris.push(...dens);
                            }
                            for (const den of dens) {
                                decryptCell(den, dshopen);
                                if (den.rule) {
                                    // okay we need this now!
                                    doformulacheck = true;
                                    try {
                                        const val = LetrwingCrypto.decryptSecretBox(den.rule, akey);
                                        if (val) {
                                            const rv = JSON.parse(val);
                                            den.ruleopen = rv;
                                        }
                                    }
                                    catch (ex) { }
                                    if (den.ruleopen && !isschemarule) {
                                        // lets if any rule is on other schema
                                        for (const pa of den.ruleopen.rule) {
                                            for (const param of pa.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    filterentris.push(den);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    const shrule = colrules.get(den.cellid);
                                    if (shrule) {
                                        den.ruleopen = shrule;
                                        den.ruleopen.isshrule = true;
                                    }
                                }
                                const id = dshopen.datasheetid + "~~~" + dshopen.id + "~~~" + den.cellid;
                                if (!databyschemacol.has(id)) {
                                    databyschemacol.set(id, new Map());
                                }
                                (_a = databyschemacol.get(id)) === null || _a === void 0 ? void 0 : _a.set(den.id, den);
                            }
                            if (filterentris.length > 0) {
                                // we should filter this first now!!
                                const dmap = await this.getAllDepedentForEntries(filterentris, dshopen, letrid, dshopen.id);
                                if (dmap) {
                                    // lets go over it
                                    for (const [k, v] of dmap) {
                                        const id = k;
                                        if (!databyschemacol.has(id)) {
                                            databyschemacol.set(id, new Map());
                                        }
                                        // possible to have duplicates too other side might beed to fix that!
                                        for (const [vk, vn] of v) {
                                            (_b = databyschemacol.get(id)) === null || _b === void 0 ? void 0 : _b.set(vn.id, vn);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return databyschemacol;
    }
    async getSchemaPrintTemplate(id, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const ret = await comm.post(ServerPath.getSchemaPrintTemplate, {
            schemaid: id,
            letrid: letrid
        });
        if (!ret)
            return [];
        const res = [];
        for (const r of ret) {
            const openr = await CONVERTUTIL.convertSchemaPrintServerToForm(r, box);
            if (openr) {
                res.push(openr);
            }
        }
        return res;
    }
    // this will get all ifnormationto evaluate formulas
    async getAllDependent(req) {
        var _a;
        const box = InMemoryRepo.getLetrPassword(req.letrid);
        if (box === undefined) {
            return undefined;
        }
        if (!req.schemafields)
            return undefined;
        // lets make a reuat by schemaids first and then do some clever stuff here!!
        const schemabyele = new Map();
        for (const sf of req.schemafields) {
            const parts = sf.split("~~~");
            let sh = "";
            if (sf.endsWith("attach")) {
                sh = parts[0];
            }
            else {
                if (parts.length > 1) {
                    sh = parts[1];
                }
            }
            if (!sh)
                continue;
            if (!schemabyele.has(sh)) {
                schemabyele.set(sh, []);
            }
            (_a = schemabyele.get(sh)) === null || _a === void 0 ? void 0 : _a.push(sf);
        }
        const reqa = [];
        // lets now do this cleverly here!
        let areq = Object.assign(Object.assign({}, req), { schemafields: [] });
        let i = 0;
        for (let k of schemabyele.keys()) {
            const sdata = schemabyele.get(k);
            if (!sdata)
                continue;
            if (i > 0 && i % 6 === 0) {
                reqa.push(areq);
                areq = Object.assign(Object.assign({}, req), { schemafields: [] });
            }
            areq.schemafields.push(...sdata);
            i++;
        }
        if (areq.schemafields.length > 0) {
            reqa.push(areq);
        }
        const pm = [];
        for (const re of reqa) {
            pm.push(comm.post(ServerPath.getSchemaDependent, re));
        }
        const cres = await Promise.all(pm);
        const res = {
            data: []
        };
        for (const r of cres) {
            if (r) {
                res.data.push(...r.data);
            }
        }
        //const res = await comm.post<SchemaDependentResponse, SchemaDependentRequest>(ServerPath.getSchemaDependent, req);
        if (res !== undefined && res.data) {
            for (const r of res.data) {
                r.schemaopen = CONVERTUTIL.convertBoxToSchema(r.schema, req.letrid, box);
                if (r.rules) {
                    const resp = [];
                    for (const ro of r.rules) {
                        try {
                            const bstr = LetrwingCrypto.decryptSecretBox(ro.data, box);
                            if (!bstr)
                                continue;
                            const rules = JSON.parse(bstr);
                            resp.push({
                                schemaid: ro.schemaid,
                                letrid: ro.letrid,
                                id: ro.id,
                                data: rules.numberdata,
                                datarules: rules.datarules,
                                rowid: ro.rowid,
                            });
                        }
                        catch (ex) { }
                    }
                    r.rulesopen = resp;
                }
                if (r.entries && r.entries.length > 0) {
                    const fent = r.entries[0];
                    const dobj = new Map();
                    const sobj = new Map();
                    const box = InMemoryRepo.getLetrPassword(req.letrid);
                    if (fent && box !== undefined) {
                        if (fent.objs) {
                            const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, req.letrid);
                            for (const fe of dojs) {
                                dobj.set(fe.id, fe);
                            }
                        }
                        if (fent.shs) {
                            for (const sh of fent.shs) {
                                const sok = this.convertBoxToSchema(sh, req.letrid, box);
                                if (sok)
                                    sobj.set(sh.id, sok);
                            }
                        }
                    }
                    let i = -1;
                    for (const ent of r.entries) {
                        if (i > 0 && i % 5000 == 0)
                            await timeout(10);
                        i++;
                        if (!ent.rowid) {
                            continue;
                        }
                        // we should check and sabe all the public key saved ones as well!!
                        if (ent.linkedids && ent.linkedids.length > 0) {
                            // lets attach it's object here!
                            if (!ent.objsdec)
                                ent.objsdec = [];
                            if (!ent.schsdec)
                                ent.schsdec = [];
                            for (const lid of ent.linkedids) {
                                const parts = lid.split("-");
                                if (parts.length < 2)
                                    continue;
                                if (lid.startsWith("objs-")) {
                                    const bk = dobj.get(parts[1]);
                                    if (bk) {
                                        ent.objsdec.push(bk);
                                    }
                                }
                                else if (lid.startsWith("dch-") && parts.length > 2) {
                                    const sh = sobj.get(parts[2]);
                                    if (sh) {
                                        ent.schsdec.push(sh);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return res;
    }
    async deleteSchemaPrintTemplate(id, schemaid, letrid) {
        const req = {
            id: id,
            schemaid: schemaid,
            letrid: letrid
        };
        const res = await comm.post(ServerPath.deleteSchemaPrintTemplate, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async storeEntry(entries, letrid, sid, schemaaccesskey, schemanonce, rowid = "", sitems = [], notifyvies = [], assigncols = [], cun = []) {
        var _a, _b;
        const sentries = [];
        const assignedto = [];
        const smap = new Map();
        for (const it of sitems) {
            if (it.type == SchemaItemType.List) {
                const lit = it;
                if (lit.items) {
                    for (const li of lit.items) {
                        smap.set(it.id + "----" + li.item.id, li.item);
                    }
                }
            }
            else {
                smap.set(it.id, it);
            }
        }
        let uass = false;
        const dobj = new Map();
        const sobj = new Map();
        const assigncol = new Map();
        for (const entry of entries) {
            const sitem = smap.get(entry.cellid);
            if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                assignedto.push(...entry.celldata.split(","));
                const parts = entry.celldata.split(",");
                if (parts.length > 0) {
                    for (const pt of parts) {
                        if (!assigncol.has(pt)) {
                            assigncol.set(pt, {
                                userid: pt,
                                columnid: []
                            });
                        }
                        const cols = assigncol.get(pt);
                        if (cols && cols.columnid.indexOf(entry.cellid) === -1) {
                            cols.columnid.push(entry.cellid);
                        }
                    }
                }
                uass = true;
            }
            const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
            const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
            (_a = entry.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((obj) => dobj.set(obj.id, obj));
            (_b = entry.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sh) => sobj.set(sh.id, sh));
            delete entry.objs;
            delete entry.schsdec;
            delete entry.objsdec;
            delete entry.shs;
            delete entry.remotevalue;
            let rule = "";
            if (entry.ruleopen) {
                rule = JSON.stringify(entry.ruleopen);
                delete entry.ruleopen;
            }
            const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
            sentries.push(Object.assign(Object.assign({}, entry), { celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
        }
        const arreq = {
            letrid: letrid,
            schemaid: sid,
            entries: sentries,
            rowid: rowid,
            assignedto: assignedto,
            updateassignee: uass,
            assignedcolumnids: Array.from(assigncol.values()),
            colusers: assigncols,
            notifyviews: notifyvies,
            customnotify: cun
        };
        const res = await comm.post(ServerPath.addrow, arreq);
        if (res && res.length > 0) {
            for (const ent of res) {
                if (ent.linkedids) {
                    if (!ent.objsdec)
                        ent.objsdec = [];
                    if (!ent.schsdec)
                        ent.schsdec = [];
                    for (const lid of ent.linkedids) {
                        const parts = lid.split("-");
                        if (parts.length < 2)
                            continue;
                        if (lid.startsWith("objs-")) {
                            const bk = dobj.get(parts[1]);
                            if (bk) {
                                ent.objsdec.push(bk);
                            }
                        }
                        else if (lid.startsWith("dch-") && parts.length > 2) {
                            const sh = sobj.get(parts[2]);
                            if (sh) {
                                ent.schsdec.push(sh);
                            }
                        }
                    }
                }
            }
            const row = { rowid: res[0].rowid, rowindex: res[0].rowindex, entry: res };
            return row;
        }
        return undefined;
        // now we can just store these and we all done here
    }
    async getTriggersKeys() {
        const keys = await comm.get(ServerPath.getNodeKeys);
        return keys;
    }
    async addTriggers(trig, sid, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const keys = await this.getTriggersKeys();
        if (!keys || keys.length === 0) {
            return undefined;
        }
        const lbs = [];
        for (const key of keys) {
            try {
                const keybox = LetrwingCrypto.box(box, key.publickey);
                if (keybox) {
                    lbs.push({
                        nodebox: keybox,
                        nodename: key.myname,
                        nodepublickey: key.publickey
                    });
                }
            }
            catch (ex) { }
        }
        if (lbs.length === 0) {
            return undefined;
        }
        const tbox = LetrwingCrypto.secretBox(JSON.stringify(trig), box);
        const tse = {
            id: sid,
            letrid: letrid,
            nodeboxes: lbs,
            triggerbox: tbox
        };
        const res = await comm.post(ServerPath.addTrigger, tse);
        if (res) {
            return trig;
        }
        return undefined;
    }
    async getTriggers(sid, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const re = {
            id: sid,
            letrid: letrid,
            nodeboxes: [],
            triggerbox: new EncryptBox()
        };
        const res = await comm.post(ServerPath.getTriggerBox, re);
        if (res) {
            if (res.triggerbox) {
                const str = LetrwingCrypto.decryptSecretBox(res.triggerbox, box);
                if (str) {
                    const data = JSON.parse(str);
                    for (const d of data) {
                        d.schemaid = sid;
                        d.letrid = letrid;
                    }
                    return data;
                }
            }
        }
        return undefined;
    }
    async getSchemaAnalytics(letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            schemaid: '',
            schemafields: []
        };
        const res = await comm.post(ServerPath.getAllSchemaAnalytics, req);
        // now lets do something clever thing!
        if (!res) {
            return [];
        }
        for (const r of res) {
            if (r.sheet && r.schema) {
                r.dhopen = this.convertBoxToSheet(r.sheet, letrid, box);
                r.schemaopen = CONVERTUTIL.convertBoxToSchema(r.schema, letrid, box);
            }
        }
        return res;
    }
    async loadRowsInBatches(letrid, sid, dids, pk, nonce, key, cb, activeloadingworksheetid, rowids = []) {
        var _a;
        let startindex = 0;
        this.activeloadingworksheetid = activeloadingworksheetid;
        let keepgoing = true;
        let rows = new Map();
        while (keepgoing) {
            const rentries = await comm.post(ServerPath.getRowsIncremental, { letrid: letrid, schemaid: sid, datacellids: dids, count: 10000, startrowindex: startindex, rowids: rowids });
            if (!rentries || rentries.data.length === 0 || this.activeloadingworksheetid !== activeloadingworksheetid)
                keepgoing = false;
            else {
                const entries = await this.convertRowResToEntry(rentries, sid, key, nonce);
                let totalrows = 0;
                let newrows = [];
                if (entries && entries.length > 0) {
                    const fent = entries[0];
                    const dobj = new Map();
                    const sobj = new Map();
                    const box = InMemoryRepo.getLetrPassword(letrid);
                    if (fent && box !== undefined) {
                        if (fent.objs) {
                            const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, letrid);
                            for (const fe of dojs) {
                                dobj.set(fe.id, fe);
                            }
                        }
                        if (fent.shs) {
                            for (const sh of fent.shs) {
                                const sok = this.convertBoxToSchema(sh, letrid, box);
                                if (sok)
                                    sobj.set(sh.id, sok);
                            }
                        }
                    }
                    let i = -1;
                    for (const ent of entries) {
                        if (i > 0 && i % 5000 == 0)
                            await timeout(10);
                        i++;
                        if (!ent.rowid) {
                            continue;
                        }
                        // we should check and sabe all the public key saved ones as well!!
                        if (ent.linkedids && ent.linkedids.length > 0) {
                            // lets attach it's object here!
                            if (!ent.objsdec)
                                ent.objsdec = [];
                            if (!ent.schsdec)
                                ent.schsdec = [];
                            for (const lid of ent.linkedids) {
                                const parts = lid.split("-");
                                if (parts.length < 2)
                                    continue;
                                if (lid.startsWith("objs-")) {
                                    const bk = dobj.get(parts[1]);
                                    if (bk) {
                                        ent.objsdec.push(bk);
                                    }
                                }
                                else if (lid.startsWith("dch-") && parts.length > 2) {
                                    const sh = sobj.get(parts[2]);
                                    if (sh) {
                                        ent.schsdec.push(sh);
                                    }
                                }
                            }
                        }
                        if (ent.schemaid !== sid) {
                            ent.rowindex = ent.rowindex / 100000;
                        }
                        if (ent.rowindex > startindex) {
                            startindex = ent.rowindex;
                        }
                        if (!rows.has(ent.rowid)) {
                            const srow = {
                                rowid: ent.rowid,
                                rowindex: ent.rowindex,
                                entry: []
                            };
                            rows.set(ent.rowid, srow);
                            totalrows++;
                            newrows.push(srow);
                        }
                        if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                            continue;
                        }
                        (_a = rows.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
                    }
                }
                startindex += 1;
                cb(rows.size, newrows);
            }
        }
        await timeout(100);
        return [];
    }
    async attachBarcode(code) {
        const res = await comm.post(ServerPath.attachbarcode, code);
        return res !== null && res !== void 0 ? res : false;
    }
    async convertRowResToEntry(res, sid, key, nonce) {
        const ret = [];
        const variables = res.variables;
        for (let i = 0; i < res.data.length; i++) {
            if (i > 0 && i % 5000 === 0)
                await timeout(10);
            const dst = res.data[i];
            let rowindex = 0;
            let id = "";
            let rowid = "";
            let cellid = "";
            let celldata = "";
            let updatetime = 0;
            let updatedby = "";
            let subrowindex = "";
            let childrenschemalist = [];
            let readonlyschemalist = [];
            let linkedids = [];
            let referenceids = [];
            let objs = [];
            let shs = [];
            let msid = sid;
            let bc = '';
            for (let j = 0; j < dst.length; j++) {
                const va = variables[j];
                const value = dst[j];
                if (va === "rowindex") {
                    rowindex = parseFloat(value);
                }
                else if (va === "id") {
                    id = value;
                }
                else if (va === "rowid") {
                    rowid = value;
                }
                else if (va === "cellid") {
                    cellid = value;
                }
                else if (va === "celldata") {
                    celldata = value;
                }
                else if (va === "childrenschemalist") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        childrenschemalist = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "readonlyschemalist") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        readonlyschemalist = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "updatetime") {
                    updatetime = parseFloat(value);
                }
                else if (va === "updatedby") {
                    updatedby = value;
                }
                else if (va === "linkedids") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const vany = JSON.parse(value);
                        const ds = vany;
                        linkedids = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (va === "referenceids") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        referenceids = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "subrowindex") {
                    subrowindex = value;
                }
                else if (va === "objs") {
                    if (value && value.length > 0) {
                        try {
                            const ds = JSON.parse(value);
                            objs = ds !== null && ds !== void 0 ? ds : [];
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }
                }
                else if (va === "shs") {
                    if (value && value.length > 0) {
                        try {
                            const ds = JSON.parse(value);
                            shs = ds !== null && ds !== void 0 ? ds : [];
                        }
                        catch (ex) { }
                    }
                }
                else if (va === "rows") {
                }
                else if (va === 'sid') {
                    msid = value;
                }
                else if (va === 'rowbarcode') {
                    bc = value;
                }
            }
            if (bc && bc.startsWith("crypt-") && key && nonce) {
                bc = bc.substring("crypt-".length);
                const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: bc, nonce: '', publickey: '' }, key, nonce);
                bc = val !== null && val !== void 0 ? val : '';
            }
            const en = {
                id: id,
                subrowindex: subrowindex,
                linkedids: linkedids,
                objs: objs,
                shs: shs,
                cellid: cellid,
                celldata: celldata,
                rowid: rowid,
                rowindex: rowindex,
                referenceids: referenceids,
                childrenschemalist: childrenschemalist,
                schemaid: msid,
                cellfindstr: celldata,
                sortstring: -1,
                rowbarcode: bc,
                updatedby: updatedby,
                updatetime: updatetime
            };
            ret.push(en);
        }
        return ret;
    }
    async addSchemaStateData(req) {
        const res = await comm.post(ServerPath.saveSchemaState, req);
        return res;
    }
    async getSchemaStateData(schemaid, letrid, id) {
        let req = {
            id: id,
            letrid: letrid,
            schemaid: schemaid,
            data: ''
        };
        const res = await comm.post(ServerPath.getSchemaState, req);
        return res;
    }
    async getRemoteRows(letrid, sid, rowids, datacellids) {
        var _a;
        const rentries = await comm.post(ServerPath.getRemoteRows, { letrid: letrid, schemaid: sid, datacellids: datacellids, count: 100000, startrowindex: -1, rowids: rowids });
        if (rentries == undefined)
            return [];
        const entries = await this.convertRowResToEntry(rentries, sid, '', '');
        const ret = new Map();
        const pubents = [];
        if (entries && entries.length > 0) {
            const fent = entries[0];
            const dobj = new Map();
            const sobj = new Map();
            const box = InMemoryRepo.getLetrPassword(letrid);
            if (fent && box !== undefined) {
                if (fent.objs) {
                    const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, letrid);
                    for (const fe of dojs) {
                        dobj.set(fe.id, fe);
                    }
                }
                if (fent.shs) {
                    for (const sh of fent.shs) {
                        const sok = this.convertBoxToSchema(sh, letrid, box);
                        if (sok)
                            sobj.set(sh.id, sok);
                    }
                }
            }
            let i = -1;
            for (const ent of entries) {
                if (i > 0 && i % 5000 == 0)
                    await timeout(10);
                i++;
                if (!ent.rowid) {
                    continue;
                }
                // we should check and sabe all the public key saved ones as well!!
                if (ent.linkedids && ent.linkedids.length > 0) {
                    // lets attach it's object here!
                    if (!ent.objsdec)
                        ent.objsdec = [];
                    if (!ent.schsdec)
                        ent.schsdec = [];
                    for (const lid of ent.linkedids) {
                        const parts = lid.split("-");
                        if (parts.length < 2)
                            continue;
                        if (lid.startsWith("objs-")) {
                            const bk = dobj.get(parts[1]);
                            if (bk) {
                                ent.objsdec.push(bk);
                            }
                        }
                        else if (lid.startsWith("dch-") && parts.length > 2) {
                            const sh = sobj.get(parts[2]);
                            if (sh) {
                                ent.schsdec.push(sh);
                            }
                        }
                    }
                }
                if (ent.schemaid !== sid) {
                    ent.rowindex = ent.rowindex / 100000;
                }
                if (!ret.has(ent.rowid)) {
                    ret.set(ent.rowid, {
                        rowindex: ent.rowindex, rowid: ent.rowid, entry: [],
                        childrenschemalist: ent.schemaid === sid ? ent.childrenschemalist : []
                    });
                }
                // we should also check if the ent is 
                if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                    continue;
                }
                (_a = ret.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
            }
        }
        return Array.from(ret.values());
    }
    async getReportRows(letrid, sid, rowids, datacellids) {
        var _a;
        const rentries = await comm.post(ServerPath.getRemoteRows, { letrid: letrid, schemaid: sid, datacellids: datacellids, count: 100000, startrowindex: -1, rowids: rowids });
        if (rentries == undefined)
            return [];
        const entries = await this.convertRowResToEntry(rentries, sid, "", "");
        const ret = new Map();
        const pubents = [];
        if (entries && entries.length > 0) {
            const fent = entries[0];
            const dobj = new Map();
            const sobj = new Map();
            const box = InMemoryRepo.getLetrPassword(letrid);
            if (fent && box !== undefined) {
                if (fent.objs) {
                    const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, letrid);
                    for (const fe of dojs) {
                        dobj.set(fe.id, fe);
                    }
                }
                if (fent.shs) {
                    for (const sh of fent.shs) {
                        const sok = this.convertBoxToSchema(sh, letrid, box);
                        if (sok)
                            sobj.set(sh.id, sok);
                    }
                }
            }
            let i = -1;
            for (const ent of entries) {
                if (i > 0 && i % 5000 == 0)
                    await timeout(10);
                i++;
                if (!ent.rowid) {
                    continue;
                }
                // we should check and sabe all the public key saved ones as well!!
                if (ent.linkedids && ent.linkedids.length > 0) {
                    // lets attach it's object here!
                    if (!ent.objsdec)
                        ent.objsdec = [];
                    if (!ent.schsdec)
                        ent.schsdec = [];
                    for (const lid of ent.linkedids) {
                        const parts = lid.split("-");
                        if (parts.length < 2)
                            continue;
                        if (lid.startsWith("objs-")) {
                            const bk = dobj.get(parts[1]);
                            if (bk) {
                                ent.objsdec.push(bk);
                            }
                        }
                        else if (lid.startsWith("dch-") && parts.length > 2) {
                            const sh = sobj.get(parts[2]);
                            if (sh) {
                                ent.schsdec.push(sh);
                            }
                        }
                    }
                }
                if (ent.schemaid !== sid) {
                    ent.rowindex = ent.rowindex / 100000;
                }
                if (!ret.has(ent.rowid)) {
                    ret.set(ent.rowid, {
                        rowindex: ent.rowindex, rowid: ent.rowid, entry: [],
                        childrenschemalist: ent.schemaid === sid ? ent.childrenschemalist : []
                    });
                }
                // we should also check if the ent is 
                if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                    continue;
                }
                (_a = ret.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
            }
        }
        return Array.from(ret.values());
    }
    async getRows(letrid, sid, dids, pk, nonce, key) {
        var _a;
        const rentries = await comm.post(ServerPath.getRows, { letrid: letrid, schemaid: sid, datacellids: dids, startrowindex: 0, count: 0 });
        if (rentries == undefined)
            return [];
        const entries = await this.convertRowResToEntry(rentries, sid, key, nonce);
        const ret = new Map();
        const pubents = [];
        if (entries && entries.length > 0) {
            const fent = entries[0];
            const dobj = new Map();
            const sobj = new Map();
            const box = InMemoryRepo.getLetrPassword(letrid);
            if (fent && box !== undefined) {
                if (fent.objs) {
                    const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, letrid);
                    for (const fe of dojs) {
                        dobj.set(fe.id, fe);
                    }
                }
                if (fent.shs) {
                    for (const sh of fent.shs) {
                        const sok = this.convertBoxToSchema(sh, letrid, box);
                        if (sok)
                            sobj.set(sh.id, sok);
                    }
                }
            }
            let i = -1;
            for (const ent of entries) {
                if (i > 0 && i % 5000 == 0)
                    await timeout(10);
                i++;
                if (!ent.rowid) {
                    continue;
                }
                // we should check and sabe all the public key saved ones as well!!
                if (ent.linkedids && ent.linkedids.length > 0) {
                    // lets attach it's object here!
                    if (!ent.objsdec)
                        ent.objsdec = [];
                    if (!ent.schsdec)
                        ent.schsdec = [];
                    for (const lid of ent.linkedids) {
                        const parts = lid.split("-");
                        if (parts.length < 2)
                            continue;
                        if (lid.startsWith("objs-")) {
                            const bk = dobj.get(parts[1]);
                            if (bk) {
                                ent.objsdec.push(bk);
                            }
                        }
                        else if (lid.startsWith("dch-") && parts.length > 2) {
                            const sh = sobj.get(parts[2]);
                            if (sh) {
                                ent.schsdec.push(sh);
                            }
                        }
                    }
                }
                if (ent.schemaid !== sid) {
                    ent.rowindex = ent.rowindex / 100000;
                }
                if (!ret.has(ent.rowid)) {
                    ret.set(ent.rowid, {
                        rowindex: ent.rowindex, rowid: ent.rowid, entry: [],
                        childrenschemalist: ent.schemaid === sid ? ent.childrenschemalist : []
                    });
                }
                // we should also check if the ent is 
                if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                    continue;
                }
                (_a = ret.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
            }
        }
        if (pubents.length > 0) {
            this.savePubEns(pubents, letrid, sid, key, nonce, pk);
        }
        return Array.from(ret.values());
    }
    async savePubEns(en, letrid, sid, key, nonce, pk) {
        const pubents = [];
        for (const ent of en) {
            try {
                const emsg = ent.celldata.substring("pbk---".length);
                const jsonstring = LetrwingCrypto.getUTF8FromBase64(emsg);
                const js = JSON.parse(jsonstring);
                const rawdata = LetrwingCrypto.decryptRSABoxRawString(js, { privatkey: pk, publickey: '' });
                if (rawdata) {
                    // now lets encrypt this again and send save it!
                    const eben = LetrwingCrypto.secretBoxWithNonce(rawdata, key, nonce);
                    ent.celldata = eben.encryptmsg;
                    ent.cellfindstr = eben.encryptmsg;
                    pubents.push(Object.assign(Object.assign({}, ent), { celldata: rawdata, cellfindstr: rawdata }));
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
        if (pubents.length > 0) {
            await this.updatePKEntries(pubents, letrid, sid, key, nonce);
        }
    }
    async updatePKEntries(entries, letrid, sid, schemaaccesskey, schemanonce, notifyvies = [], assigncols = []) {
        const sentries = [];
        let savedentryre = [];
        let uass = false;
        for (const entry of entries) {
            const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
            const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
            let rowindex = entry.rowindex;
            if (entry.schemaid !== sid && rowindex < 1 && rowindex > 0) {
                rowindex = rowindex * 100000;
            }
            savedentryre.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg }));
            delete entry.objs;
            delete entry.schsdec;
            delete entry.objsdec;
            delete entry.shs;
            delete entry.remotevalue;
            let rule = "";
            if (entry.ruleopen) {
                rule = JSON.stringify(entry.ruleopen);
                delete entry.ruleopen;
            }
            const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
            sentries.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
        }
        const arreq = {
            letrid: letrid,
            schemaid: sid,
            entries: sentries,
            rowid: "",
            assignedto: [],
            updateassignee: uass,
            deletecellids: []
        };
        await comm.post(ServerPath.updatepkrow, arreq);
    }
    async addRowToSchema(reques) {
        const req = await comm.post(ServerPath.addRowsToChild, reques);
        return req !== null && req !== void 0 ? req : false;
    }
    async removeRowToSchema(reques) {
        const req = await comm.post(ServerPath.removeRowsToChild, reques);
        return req !== null && req !== void 0 ? req : false;
    }
    convertBoxToSchema(schema, letrid, box) {
        return CONVERTUTIL.convertBoxToSchema(schema, letrid, box);
    }
    convertBoxToSheet(schema, letrid, box) {
        const datastr = LetrwingCrypto.decryptSecretBox(schema.schemadata, box);
        if (!datastr) {
            return undefined;
        }
        try {
            const dataobj = JSON.parse(datastr);
            dataobj.letrid = letrid;
            dataobj.id = schema.id;
            dataobj.authorid = schema.authorid,
                dataobj.lastupdatetime = schema.lastupdatetime;
            dataobj.updatedby = schema.updatedby;
            return dataobj;
        }
        catch (ex) {
        }
        return undefined;
    }
    convertMiniDatasheetEnBoxToDt(box, id, letrid, accekey) {
        const datastr = LetrwingCrypto.decryptSecretBox(box, accekey);
        if (!datastr) {
            return undefined;
        }
        try {
            const dataobj = JSON.parse(datastr);
            dataobj.letrid = letrid;
            dataobj.id = id;
            return dataobj;
        }
        catch (ex) {
        }
        return undefined;
    }
    convertMiniSchemaEnBoxToDt(box, id, letrid, accekey) {
        const datastr = LetrwingCrypto.decryptSecretBox(box, accekey);
        if (!datastr) {
            return undefined;
        }
        try {
            const dataobj = JSON.parse(datastr);
            dataobj.letrid = letrid;
            dataobj.id = id;
            return dataobj;
        }
        catch (ex) {
        }
        return undefined;
    }
    convertSchemaToBox(schema, box, letrid) {
        var _a, _b;
        const enbox = LetrwingCrypto.secretBox(JSON.stringify(schema), box);
        let rules = "";
        if (schema.rules) {
            rules = JSON.stringify(schema.rules);
        }
        const rbox = LetrwingCrypto.secretBox(rules, box);
        let drules = "";
        if (schema.datarules) {
            drules = JSON.stringify(schema.datarules);
        }
        const dbox = LetrwingCrypto.secretBox(drules, box);
        const dsch = new Set();
        if (schema.rules && schema.rules.length > 0) {
            for (const r of schema.rules) {
                for (const rp of r.rule) {
                    for (const pp of rp.params) {
                        if (pp.type === 'schema') {
                            const parts = pp.value.split("~~~");
                            if (parts.length > 2) {
                                let pid = r.colid + "~~~" + parts[0] + "~~~" + parts[1] + "~~~" + parts[2];
                                dsch.add(pid);
                            }
                        }
                    }
                }
            }
        }
        if (schema.datarules && schema.datarules.length > 0) {
            for (const r of schema.datarules) {
                for (const rp of r.rule) {
                    for (const pp of rp.params) {
                        if (pp.type === 'schema') {
                            const parts = pp.value.split("~~~");
                            if (parts.length > 2) {
                                let pid = r.colid + "~~~" + parts[0] + "~~~" + parts[1] + "~~~" + parts[2];
                                dsch.add(pid);
                            }
                        }
                    }
                }
            }
        }
        let frules = "";
        if (schema.footers) {
            frules = JSON.stringify(schema.footers);
        }
        const fbox = LetrwingCrypto.secretBox(frules, box);
        const number = [];
        for (const si of schema.items) {
            if (si.type === SchemaItemType.Remote) {
                const rit = si;
                if (rit.did && rit.sid && rit.cid) {
                    dsch.add(rit.did + "~~~" + rit.sid + "~~~" + rit.cid + "~~~remote");
                }
            }
            else if (si.type === SchemaItemType.List) {
                const lit = si;
                if (!lit.items)
                    continue;
                for (const it of lit.items) {
                    if (it.item.type === SchemaItemType.Remote) {
                        const rit = it.item;
                        if (rit.did && rit.sid && rit.cid) {
                            dsch.add(rit.did + "~~~" + rit.sid + "~~~" + rit.cid + "~~~remote");
                        }
                    }
                }
            }
            else if (si.type === SchemaItemType.SquenceNumber) {
                const item = si;
                if (item.data) {
                    number.push(item.data);
                }
            }
        }
        if (schema.webformbox) {
            schema.webformbox.rules = (_a = schema.rules) !== null && _a !== void 0 ? _a : [];
        }
        const ret = {
            id: schema.id,
            viewers: schema.viewers,
            schemadata: enbox,
            letrid: letrid,
            authorid: '',
            lastupdatetime: -1,
            updatedby: '',
            datasheetid: schema.datasheetid,
            metaschemaids: schema.metaschemaids,
            schemametadata: (_b = schema.schemametadata) !== null && _b !== void 0 ? _b : [],
            myelemntids: this.getAllSchemaIds(schema),
            rulebox: rbox,
            datarulebox: dbox,
            webformbox: LetrwingCrypto.secretBox(JSON.stringify(schema.webformbox), schema.webformkeys.shkey),
            dependentschemas: Array.from(dsch.values()),
            footerbox: fbox,
            viewdepentschemas: schema.viewdepentschemas,
            barcodecolumnid: schema.barcodecolumnid,
            numbers: number
        };
        return ret;
    }
    async addIntegration(data) {
        const box = InMemoryRepo.getLetrPassword(data.letrid);
        if (!box) {
            return undefined;
        }
        const databox = {
            model: data,
            letrkey: box
        };
        const mykey = await comm.get(ServerPath.getIntegrationKey);
        if (!mykey)
            return undefined;
        const ebox = LetrwingCrypto.box(JSON.stringify(databox), mykey);
        // now we have everything lets just store this!!
        const req = {
            schemaid: data.schemaid,
            letrid: data.letrid,
            localbox: LetrwingCrypto.secretBox(JSON.stringify(databox), box),
            box: ebox,
            id: ""
        };
        const res = await comm.post(ServerPath.addIntegration, req);
        if (res) {
            data.id = res.id;
            return data;
        }
        return undefined;
    }
    async updateIntegration(data) {
        const box = InMemoryRepo.getLetrPassword(data.letrid);
        if (!box) {
            return undefined;
        }
        const databox = {
            model: data,
            letrkey: box
        };
        const mykey = await comm.get(ServerPath.getIntegrationKey);
        if (!mykey)
            return undefined;
        const ebox = LetrwingCrypto.box(JSON.stringify(databox), mykey);
        // now we have everything lets just store this!!
        const req = {
            schemaid: data.schemaid,
            letrid: data.letrid,
            localbox: LetrwingCrypto.secretBox(JSON.stringify(databox), box),
            box: ebox,
            id: data.id
        };
        const res = await comm.post(ServerPath.updateIntegration, req);
        if (res) {
            data.id = res.id;
            return data;
        }
        return undefined;
    }
    async deleteIntegration(data) {
        const req = {
            schemaid: data.schemaid,
            localbox: new EncryptBox(),
            letrid: data.letrid,
            box: new EncryptBox(),
            id: data.id
        };
        const res = await comm.post(ServerPath.deleteIntegration, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async getIntegrations(schemaid, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (!box) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            schemaid: schemaid,
            localbox: new EncryptBox(),
            id: '',
            box: new EncryptBox()
        };
        const res = await comm.post(ServerPath.getIntegrations, req);
        if (!res) {
            return undefined;
        }
        const ret = [];
        // now we can send these out as well!!
        for (const r of res) {
            try {
                if (r.localbox) {
                    const rb = LetrwingCrypto.decryptSecretBox(r.localbox, box);
                    if (rb) {
                        const rbmb = JSON.parse(rb);
                        const rbm = rbmb.model;
                        rbm.id = r.id;
                        ret.push(rbm);
                    }
                }
            }
            catch (ex) { }
        }
        return ret;
    }
    getAllSchemaIds(sh) {
        var _a;
        const ids = [];
        for (const it of (_a = sh.items) !== null && _a !== void 0 ? _a : []) {
            if (it.type === SchemaItemType.List) {
                const lit = it;
                if (lit.items && lit.items.length > 0) {
                    for (const lli of lit.items) {
                        ids.push(it.id + "----" + lli.id);
                    }
                }
            }
            else {
                ids.push(it.id);
            }
        }
        return ids;
    }
    convertSheetToBox(schema, box, letrid) {
        const enbox = LetrwingCrypto.secretBox(JSON.stringify(schema), box);
        const ret = {
            id: schema.id,
            viewers: schema.viewers,
            schemadata: enbox,
            letrid: letrid,
            authorid: '',
            lastupdatetime: -1,
            updatedby: ''
        };
        return ret;
    }
}
export const SchemaStore = new Store();
