import { LayerIcon, FlagIcon, ArchiveIcon, ChatIcon } from "./Icons";
import { CHATID, Tag, TagCategory } from "./Tags";
class DefaultTags {
    constructor() {
        this.ALLLetrsName = "All Letrs";
        this.FlaggedTagName = "Flagged Letrs";
        this.FiledTagName = "Tagged Letrs";
        this.PrivateTagName = "Private Letrs";
        this.GroupTagName = "Group Letrs";
        this.ArchiveTagName = "Archived Letrs";
        this.ChatTagID = "__chat__";
        this.NotificationTagName = "Notifications";
        this.allletrs = new Tag('all', this.ALLLetrsName, true, TagCategory.grouping, LayerIcon, false, true);
        this.fltag = new Tag('flag', this.FlaggedTagName, false, TagCategory.managing, FlagIcon, false);
        this.ftag = new Tag('file', this.FiledTagName, false, TagCategory.filled, undefined, true);
        this.atag = new Tag('archive', this.ArchiveTagName, false, TagCategory.managing, ArchiveIcon, false);
        this.wingstag = new Tag(CHATID, "Chat", false, TagCategory.grouping, ChatIcon, false, false, false, false);
        this.DefaultTags = [
            this.allletrs,
            this.ftag,
            this.atag,
        ];
        this.standardtagsids = new Set(["all", "flag", "file", "archive", CHATID]);
    }
}
export const DEFAULT_TAGS = new DefaultTags();
