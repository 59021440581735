import { Event } from '@/events/Event';
class BackgrountEvent {
    constructor() {
        this.newfiles = new Event("newfiles");
        this.notifytaskcounts = new Event("notifytaskcount");
        this.notifycounts = new Event("notfiycount");
        this.quota = new Event("quota");
        this.draftupdates = new Event("getdraftupdates");
        this.updatecontacts = new Event("contactsupdates");
        this.newinvites = new Event("gotnewinvites");
        this.commentupdate = new Event("addcomment");
        this.notesupdate = new Event("notesupdate");
        this.draftcontent = new Event("getdraftcontent");
        this.savedraftcontent = new Event("savedraftcontet");
        this.newmanagedContacts = new Event("managedcontacts");
        this.newmessagefromapp = new Event("newappmessage");
        this.newmessagetoapp = new Event("sendnewmessagetoapp");
        this.appstate = new Event("appstate");
        this.newPublicContacts = new Event("newpubliccontacts");
        this.newLetrs = new Event("newletr");
        this.newLetrsAvailable = new Event("newletravailable");
        this.letrUpdateAvailable = new Event("letrupdateavailable");
        this.tenant = new Event("tenant");
        this.newMessagesAvailable = new Event("newmessageavail");
        this.reconnect = new Event("reconnectlive");
        this.deletemessagefromapp = new Event("deletemessage");
        this.replyfromapp = new Event("reply");
        this.editreplyfromapp = new Event("editreply");
        this.deletereplyfromapp = new Event("deletereply");
        this.allreplies = new Event("allreplies");
        this.newmanagedLetr = new Event("managedletrs");
        this.newmanagedLetrResponse = new Event("newmanagedletrresponse");
        this.unreaddetails = new Event("unreaddetails");
        this.unreadinfo = new Event("unreadinfo");
        this.sendmessgaetomedia = new Event("sendmessagetomedia");
        this.shutdownmedia = new Event("shutdownmedia");
        this.newactiveletrid = new Event("newactiveletrid");
        this.childtags = new Event("childtags");
        this.callactioned = new Event("callactioned");
        this.callended = new Event("callended");
        this.schemaupdate = new Event("schemaupdate");
    }
    addSubscriberForSchemaUpdate(sub) {
        this.schemaupdate.subscribe(sub);
    }
    removeSubscriberForSchemaUpdate(sub) {
        this.schemaupdate.unsubscribe(sub);
    }
    publishSchemaUpdate(update) {
        this.schemaupdate.publish(update);
    }
    addSubscriberForNotifyTaskCount(sub) {
        this.notifytaskcounts.subscribe(sub);
    }
    removeSubscriberNotifyTaskCount(sub) {
        this.notifytaskcounts.unsubscribe(sub);
    }
    publishNotifyTaskCount(tags) {
        this.notifytaskcounts.publish(tags);
    }
    addSubscriberForNotifyFileCount(sub) {
        this.newfiles.subscribe(sub);
    }
    removeSubscriberNotifyFileCount(sub) {
        this.newfiles.unsubscribe(sub);
    }
    publishNotifyFileCount(tags) {
        this.newfiles.publish(tags);
    }
    addSubscriberForNotifyCount(sub) {
        this.notifycounts.subscribe(sub);
    }
    removeSubscriberrNotifyCount(sub) {
        this.notifycounts.unsubscribe(sub);
    }
    publishrNotifyCount(tags) {
        this.notifycounts.publish(tags);
    }
    addSubscriberForQuota(sub) {
        this.quota.subscribe(sub);
    }
    removeSubscriberrQuota(sub) {
        this.quota.unsubscribe(sub);
    }
    publishrQuota(tags) {
        this.quota.publish(tags);
    }
    addSubscriberForDraftUpdates(sub) {
        this.draftupdates.subscribe(sub);
    }
    removeSubscriberrDraftUpdates(sub) {
        this.draftupdates.unsubscribe(sub);
    }
    publishrDraftUpdates(tags) {
        this.draftupdates.publish(tags);
    }
    addSubscriberForContactUpdate(sub) {
        this.updatecontacts.subscribe(sub);
    }
    removeSubscriberContactUpdate(sub) {
        this.updatecontacts.unsubscribe(sub);
    }
    publishContactUpdates(tags) {
        this.updatecontacts.publish(tags);
    }
    addSubscriberForInvites(sub) {
        this.newinvites.subscribe(sub);
    }
    removeSubscriberInvites(sub) {
        this.newinvites.unsubscribe(sub);
    }
    publishInvites(tags) {
        this.newinvites.publish(tags);
    }
    addSubscriberForCommentsUpdate(sub) {
        this.commentupdate.subscribe(sub);
    }
    removeSubscriberCommentsUpdate(sub) {
        this.commentupdate.unsubscribe(sub);
    }
    publishrCommentsUpdate(tags) {
        this.commentupdate.publish(tags);
    }
    addSubscriberForNotesUpdate(sub) {
        this.notesupdate.subscribe(sub);
    }
    removeSubscriberNotesUpdate(sub) {
        this.notesupdate.unsubscribe(sub);
    }
    publishrNotesUpdate(tags) {
        this.notesupdate.publish(tags);
    }
    addSubscriberForDraftContent(sub) {
        this.draftcontent.subscribe(sub);
    }
    removeSubscriberForrDraftContent(sub) {
        this.draftcontent.unsubscribe(sub);
    }
    publishrDraftContent(tags) {
        this.draftcontent.publish(tags);
    }
    addSubscriberForChildTags(sub) {
        this.childtags.subscribe(sub);
    }
    removeSubscriberForChildTag(sub) {
        this.childtags.unsubscribe(sub);
    }
    publishChildTag(tags) {
        this.childtags.publish(tags);
    }
    publishCallEnded(req) {
        this.callended.publish(req);
    }
    addSubscriberForCallEnded(sub) {
        this.callended.subscribe(sub);
    }
    removeSubscriberForCallEnded(sub) {
        this.callended.unsubscribe(sub);
    }
    publishCallActioned(req) {
        this.callactioned.publish(req);
    }
    addSubscriberForCallActioned(sub) {
        this.callactioned.subscribe(sub);
    }
    removeSubscriberForCallActioned(sub) {
        this.callactioned.unsubscribe(sub);
    }
    addSubscriberForShutdownMedia(sub) {
        this.shutdownmedia.subscribe(sub);
    }
    removeSubscriberForShutdownMedia(sub) {
        this.shutdownmedia.unsubscribe(sub);
    }
    publishShutdownMedia() {
        this.shutdownmedia.publish();
    }
    addSubscriberForNewActiveLetridCall(sub) {
        this.newactiveletrid.subscribe(sub);
    }
    removeSubscriberForNewActiveLetridCall(sub) {
        this.newactiveletrid.unsubscribe(sub);
    }
    publishNewActiveLetridCall(letrid) {
        this.newactiveletrid.publish(letrid);
    }
    addSubscriberForSendMessageToMedia(sub) {
        this.sendmessgaetomedia.subscribe(sub);
    }
    removeSubscriberForSendMessageToMedia(sub) {
        this.sendmessgaetomedia.unsubscribe(sub);
    }
    publishSendMessageToMedia(cont) {
        this.sendmessgaetomedia.publish(cont);
    }
    addSubscriberForUnreadLetr(sub) {
        this.unreaddetails.subscribe(sub);
    }
    removeSubscriberForUnreadLetr(sub) {
        this.unreaddetails.unsubscribe(sub);
    }
    publishUnreadLetr(cont) {
        this.unreaddetails.publish(cont);
    }
    addSubscriberForUnreadInfo(sub) {
        this.unreadinfo.subscribe(sub);
    }
    removeSubscriberForUnreadInfo(sub) {
        this.unreadinfo.unsubscribe(sub);
    }
    publishUnreadInfo(cont) {
        this.unreadinfo.publish(cont);
    }
    addSubscriberForNewPubliContacts(sub) {
        this.newmanagedContacts.subscribe(sub);
    }
    removeSubscriberForNewPublicContact(sub) {
        this.newmanagedContacts.unsubscribe(sub);
    }
    publishNewPubliContact(cont) {
        this.newmanagedContacts.publish(cont);
    }
    addSubscriberForNewManagedLetrResponse(sub) {
        this.newmanagedLetrResponse.subscribe(sub);
    }
    removeSubscriberForNewManagedLetrResponse(sub) {
        this.newmanagedLetrResponse.unsubscribe(sub);
    }
    publishNewManagedLetrResponse(mlr) {
        this.newmanagedLetrResponse.publish(mlr);
    }
    addSubscriberForNewManagedLetr(sub) {
        this.newmanagedLetr.subscribe(sub);
    }
    removeSubscriberForNewManagedLetr(sub) {
        this.newmanagedLetr.unsubscribe(sub);
    }
    publishForNewManagedLetr(email) {
        this.newmanagedLetr.publish(email);
    }
    addSubscriberForReconnect(sub) {
        this.reconnect.subscribe(sub);
    }
    removeSubscriberForReconnect(sub) {
        this.reconnect.unsubscribe(sub);
    }
    publishReconnect() {
        this.reconnect.publish();
    }
    addSubscriberForDeleteMessage(sub) {
        this.deletemessagefromapp.subscribe(sub);
    }
    deleteSubscriberForDeleteMessage(sub) {
        this.deletemessagefromapp.unsubscribe(sub);
    }
    publishDeleteMessage(msg) {
        this.deletemessagefromapp.publish(msg);
    }
    addSubscriberForMessageAvailable(sub) {
        this.newMessagesAvailable.subscribe(sub);
    }
    removeSubscriberForMessageAvailable(sub) {
        this.newMessagesAvailable.unsubscribe(sub);
    }
    publishMessageAvailable(msg) {
        this.newMessagesAvailable.publish(msg);
    }
    addSubscriberForLetrUpdateAvailable(sub) {
        this.letrUpdateAvailable.subscribe(sub);
    }
    removeSubscriberForLetrUpdateAvailable(sub) {
        this.letrUpdateAvailable.unsubscribe(sub);
    }
    publishLetrUpdateAvailable(msg) {
        this.letrUpdateAvailable.publish(msg);
    }
    addSubscriberForNewLetrAvailable(sub) {
        this.newLetrsAvailable.subscribe(sub);
    }
    removeSubscriberForNewLetrAvailable(sub) {
        this.newLetrsAvailable.unsubscribe(sub);
    }
    publishNewLetrAvailable(msg) {
        this.newLetrsAvailable.publish(msg);
    }
    addSubscriberForNewLetr(sub) {
        this.newLetrs.subscribe(sub);
    }
    removeSubscriberForNewLetr(sub) {
        this.newLetrs.unsubscribe(sub);
    }
    publishNewLetr(msg) {
        this.newLetrs.publish(msg);
    }
    addSubscriberForNewPublicContacts(sub) {
        this.newPublicContacts.subscribe(sub);
    }
    removeSubscriberForNewPublicContacts(sub) {
        this.newPublicContacts.unsubscribe(sub);
    }
    publishNewPublicContacts(msg) {
        this.newPublicContacts.publish(msg);
    }
    addSubscriberForTenant(sub) {
        this.tenant.subscribe(sub);
    }
    removeSubscriberForTenant(sub) {
        this.tenant.unsubscribe(sub);
    }
    publishTenant(msg) {
        this.tenant.publish(msg);
    }
    addSubscriberForAppState(sub) {
        this.appstate.subscribe(sub);
    }
    removeSubscriberForAppState(sub) {
        this.appstate.unsubscribe(sub);
    }
    publishAppState(state) {
        this.appstate.publish(state);
    }
    addSubscriberForSendToApp(sub) {
        this.newmessagetoapp.subscribe(sub);
    }
    removeSubscriberFoSendToApp(sub) {
        this.newmessagetoapp.unsubscribe(sub);
    }
    publishSendToApp(msg) {
        this.newmessagetoapp.publish(msg);
    }
    addSubscriberForNewAppMessage(sub) {
        this.newmessagefromapp.subscribe(sub);
    }
    removeSubscriberForNewAppMessage(sub) {
        this.newmessagefromapp.unsubscribe(sub);
    }
    publishNewAppMessage(msg) {
        this.newmessagefromapp.publish(msg);
    }
    addSubscriberForReply(sub) {
        this.replyfromapp.subscribe(sub);
    }
    deleteSubscriberForReply(sub) {
        this.replyfromapp.unsubscribe(sub);
    }
    publishReply(msg) {
        this.replyfromapp.publish(msg);
    }
    addSubscriberForEditReply(sub) {
        this.editreplyfromapp.subscribe(sub);
    }
    deleteSubscriberForEditReply(sub) {
        this.editreplyfromapp.unsubscribe(sub);
    }
    publishEditReply(msg) {
        this.editreplyfromapp.publish(msg);
    }
    addSubscriberForDeleteReply(sub) {
        this.deletereplyfromapp.subscribe(sub);
    }
    deleteSubscriberForDeleteReply(sub) {
        this.deletereplyfromapp.unsubscribe(sub);
    }
    publishDeleteReply(msg) {
        this.deletereplyfromapp.publish(msg);
    }
    addSubscriberForAllReplies(sub) {
        this.allreplies.subscribe(sub);
    }
    deleteSubscriberForAllReplies(sub) {
        this.allreplies.unsubscribe(sub);
    }
    publishAllReplies(res) {
        this.allreplies.publish(res);
    }
}
export const BEvent = new BackgrountEvent();
