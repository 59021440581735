export var FileProcessItemStatus;
(function (FileProcessItemStatus) {
    FileProcessItemStatus["Waiting"] = "Waiting";
    FileProcessItemStatus["InQueue"] = "InQueue";
    FileProcessItemStatus["Processing"] = "Processing";
    FileProcessItemStatus["Done"] = "Done";
})(FileProcessItemStatus || (FileProcessItemStatus = {}));
export var JobPriority;
(function (JobPriority) {
    JobPriority["High"] = "High";
    JobPriority["Low"] = "Low";
})(JobPriority || (JobPriority = {}));
export var Bookmarkstatus;
(function (Bookmarkstatus) {
    Bookmarkstatus["Live"] = "Live";
    Bookmarkstatus["Dead"] = "Dead";
})(Bookmarkstatus || (Bookmarkstatus = {}));
