var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ALLViewer, AnnotationType } from "@@/Annotation";
import { testIsMobile } from "@@/Util";
import { action, makeObservable, observable } from "mobx";
export class NoteDetailsStore {
    constructor() {
        this.topposition = 30;
        this.leftposition = 200;
        this.width = testIsMobile() ? 300 : 350;
        this.height = testIsMobile() ? 200 : 250;
        this.dragging = false;
        this.show = false;
        this.text = "";
        this.editmode = false;
        this.links = [];
        this.activeaccessids = new Set([ALLViewer]);
        this.showlinksdialog = false;
        this.readonly = false;
        this.annotationtype = undefined;
        this.showextra = false;
        this.thickness = 3;
        this.strokewidth = 3;
        this.onok = () => { };
        makeObservable(this);
    }
    setThickness(th) {
        this.thickness = th;
    }
    setSWidth(th) {
        this.strokewidth = th;
    }
    setShowExtraOptions(show) {
        this.showextra = show;
    }
    setActiveAnnotationType(type) {
        this.annotationtype = type;
    }
    setShowLinksDialog(show) {
        this.showlinksdialog = show;
    }
    selectAccessids(ids) {
        this.activeaccessids.clear();
        for (const id of ids) {
            this.addActiveAccessId(id);
        }
    }
    setReadonly(readonly) {
        this.readonly = readonly;
    }
    addActiveAccessId(id) {
        this.activeaccessids.add(id);
    }
    removeActiveAccessId(id) {
        this.activeaccessids.delete(id);
    }
    clearActiveAccessId() {
        this.activeaccessids.clear();
    }
    deleteLink(link) {
        const id = this.links.indexOf(link);
        if (id !== undefined && id > -1) {
            this.links.splice(id, 1);
        }
    }
    clearLinks() {
        this.links.splice(0, this.links.length);
    }
    setLink(link) {
        this.links.push(link);
    }
    setContent(content) {
        this.text = content;
    }
    setEditMode(edit) {
        this.editmode = edit;
    }
    setShow(show) {
        this.show = show;
    }
    setDragging(drag) {
        this.dragging = drag;
    }
    setToppos(pos) {
        this.topposition = pos;
    }
    setLeftPos(pos) {
        this.leftposition = pos;
    }
    reset() {
        this.activeaccessids = new Set([ALLViewer]);
        this.showextra = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "topposition", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "leftposition", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "height", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "dragging", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "text", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "editmode", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], NoteDetailsStore.prototype, "links", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], NoteDetailsStore.prototype, "activeaccessids", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], NoteDetailsStore.prototype, "showlinksdialog", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "readonly", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], NoteDetailsStore.prototype, "annotationtype", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "showextra", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "thickness", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NoteDetailsStore.prototype, "strokewidth", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setThickness", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setSWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setShowExtraOptions", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setActiveAnnotationType", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setShowLinksDialog", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "selectAccessids", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setReadonly", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "addActiveAccessId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "removeActiveAccessId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "clearActiveAccessId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "deleteLink", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "clearLinks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setLink", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setContent", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setEditMode", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setDragging", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setToppos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "setLeftPos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], NoteDetailsStore.prototype, "reset", null);
