import { JobType, PWaDb } from "@/app/uistore/PWAIndexDb";
import { comm } from "@/Comm/comm";
import { JobPriority } from "@@/File";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { JobType as ModuleJobType } from "@@/ModuleJob";
import { ProjectObjectType } from "@@/Project";
import { SchemaItemType } from "@@/Schema";
import { ServerPath } from "@@/ServerPath";
class Processor {
    async processJob(job) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48;
        try {
            if (job.jobtype === JobType.WebForm && job.data.webform) {
                const actualjob = job.data;
                const dataid = job.userid + "----" + job.data.webform.id;
                if (actualjob.files.length > 0) {
                    let index = -1;
                    for (const aj of actualjob.files) {
                        index++;
                        // lets upload this file!!
                        if (actualjob.progress.length < index)
                            continue;
                        const mypr = ((_a = actualjob.progress) !== null && _a !== void 0 ? _a : [])[index];
                        if (!mypr.done) {
                            // just make sure to store this!!
                            const res = await this.uploadFile(aj.file, job.userid, actualjob.webform, mypr, async (pr) => {
                                actualjob.progress[index] = pr;
                                job.jobtime = new Date().getTime();
                                await PWaDb.addDataJob(job);
                            });
                            if (res === 'NetworkIssue') {
                                // stop this here!
                                return "NetworkError";
                            }
                            else if (res === 'Failed') {
                                // continue with next!!
                                continue;
                            }
                            mypr.done = true;
                            await PWaDb.addDataJob(job);
                        }
                        if (mypr.objectid) {
                            continue;
                        }
                        const foldername = ((_b = actualjob.webform.dh) === null || _b === void 0 ? void 0 : _b.name) + "!" + ((_c = actualjob.webform.sh) === null || _c === void 0 ? void 0 : _c.name);
                        const url = ServerPath.pwainsertProjectObject;
                        const fen = {
                            id: "",
                            name: foldername,
                            parentid: "root",
                            childrencount: 0,
                            updatetime: 0,
                            type: ProjectObjectType.Folder,
                            color: 'orange',
                            accessids: [],
                        };
                        const dent = fen;
                        const det = {
                            name: dent.name,
                            color: (_d = dent.color) !== null && _d !== void 0 ? _d : 'orange'
                        };
                        const ef = LetrwingCrypto.secretBox(JSON.stringify(det), actualjob.webform.letrkey);
                        const ret = {
                            type: ProjectObjectType.Folder,
                            id: "",
                            data: ef,
                            parentid: "root",
                            updatetime: 0,
                            childrencount: 0,
                            creatorid: dataid,
                            accessids: [],
                            name: LetrwingCrypto.secretBox(dent.name, actualjob.webform.letrkey)
                        };
                        const res = await comm.postWithNetwork(url, ret);
                        if (res === 'Unauthorised') {
                            return "Failed";
                        }
                        if (res === 'NetworkError') {
                            return "NetworkError";
                        }
                        if (!res) {
                            continue;
                        }
                        // we have parentid now!
                        let parentid = "root";
                        if (res) {
                            parentid = res.id;
                        }
                        let f = aj.file;
                        // now we have this we can add actual project obejct now too!!
                        let documentent = {
                            name: f.name,
                            accesscode: (_f = (_e = mypr.meta) === null || _e === void 0 ? void 0 : _e.meta.boxid) !== null && _f !== void 0 ? _f : '',
                            accesskey: (_h = (_g = mypr.meta) === null || _g === void 0 ? void 0 : _g.accesskey) !== null && _h !== void 0 ? _h : '',
                            documentreference: (_k = (_j = mypr.meta) === null || _j === void 0 ? void 0 : _j.meta.id) !== null && _k !== void 0 ? _k : '',
                            size: f.size,
                            versions: [{
                                    name: f.name,
                                    isDownloadable: true,
                                    filid: (_m = (_l = mypr.meta) === null || _l === void 0 ? void 0 : _l.meta.id) !== null && _m !== void 0 ? _m : '',
                                    boxid: (_p = (_o = mypr.meta) === null || _o === void 0 ? void 0 : _o.meta.boxid) !== null && _p !== void 0 ? _p : '',
                                    versionnumber: 1,
                                    acesskey: (_r = (_q = mypr.meta) === null || _q === void 0 ? void 0 : _q.accesskey) !== null && _r !== void 0 ? _r : '',
                                    size: f.size,
                                    creatorid: (_s = res.creatorid) !== null && _s !== void 0 ? _s : ''
                                }],
                            type: ProjectObjectType.Document,
                            parentid: parentid,
                            updatetime: 0,
                            id: "",
                            origname: f.name,
                            accessids: []
                        };
                        // lets do comething here now
                        const finfo = {
                            accesscode: documentent.accesscode,
                            accesskey: documentent.accesskey,
                            accessid: documentent.documentreference,
                            size: documentent.size,
                            versions: documentent.versions,
                            replace: [],
                            width: 0,
                            height: 0,
                            name: documentent.name,
                            origname: documentent.origname
                        };
                        const ef1 = LetrwingCrypto.secretBox(JSON.stringify(finfo), actualjob.webform.letrkey);
                        const ret1 = {
                            type: ProjectObjectType.Document,
                            id: documentent.id,
                            data: ef1,
                            parentid: parentid,
                            creatorid: dataid,
                            updatetime: 0,
                            childrencount: 0,
                            accessids: [],
                            itemreference: documentent.documentreference,
                            name: LetrwingCrypto.secretBox(documentent.name, actualjob.webform.letrkey)
                        };
                        const jobs = [];
                        // lets create a job too here!!
                        if (((_t = actualjob.webform.pubkeys) !== null && _t !== void 0 ? _t : []).length > 0 && actualjob.webform.searchbox) {
                            // lets create job here now!!
                            const pubkeys = (_u = actualjob.webform.pubkeys) !== null && _u !== void 0 ? _u : [];
                            const seb = actualjob.webform.searchbox;
                            const oletrid = actualjob.webform.letrid;
                            const filejob = {
                                fileid: documentent.documentreference,
                                key: documentent.accesskey,
                                filesize: documentent.size,
                                oldrefid: "",
                                fileext: (_v = documentent.name.split('.').pop()) !== null && _v !== void 0 ? _v : '',
                                searchkey: (_w = seb === null || seb === void 0 ? void 0 : seb.key) !== null && _w !== void 0 ? _w : '',
                                searchnonce: (_x = seb === null || seb === void 0 ? void 0 : seb.nonce) !== null && _x !== void 0 ? _x : '',
                                searchrandomdata: (_y = seb === null || seb === void 0 ? void 0 : seb.randomdata) !== null && _y !== void 0 ? _y : '',
                                letrid: oletrid !== null && oletrid !== void 0 ? oletrid : '',
                                addwatermark: true,
                                ocrdocument: true,
                                ocrlang: ''
                            };
                            // now we have file job we can do other things here!!
                            const job = {
                                jobtype: ModuleJobType.Document,
                                data: LetrwingCrypto.getBase64FromUT8(JSON.stringify(filejob))
                            };
                            for (const re of pubkeys) {
                                const box = LetrwingCrypto.box(JSON.stringify(job), re.publickey);
                                if (box !== null) {
                                    jobs.push({
                                        data: box,
                                        lrvmdata: re,
                                        reference: documentent.documentreference,
                                        priority: JobPriority.Low
                                    });
                                }
                            }
                        }
                        const sres = {
                            object: ret1,
                            job: jobs,
                            dataid: dataid
                        };
                        const urlq = ServerPath.pwaaddProjectObject;
                        const res1 = await comm.postWithNetwork(urlq, sres);
                        if (res1 === 'Unauthorised') {
                            return "Failed";
                        }
                        if (res1 === 'NetworkError') {
                            return 'NetworkError';
                        }
                        if (res1) {
                            mypr.objectid = res1.id;
                            job.jobstatusmessage = "Uploaded " + index + " files";
                            await PWaDb.addDataJob(job);
                        }
                    }
                }
                const mapofobjectids = new Map();
                for (const f of job.data.progress) {
                    const itemid = f.itemid;
                    if (!mapofobjectids.has(itemid)) {
                        mapofobjectids.set(itemid, []);
                    }
                    const filep = f.objectid;
                    if (filep) {
                        (_z = mapofobjectids.get(itemid)) === null || _z === void 0 ? void 0 : _z.push(filep);
                    }
                }
                let key = (_1 = (_0 = job.data.webform.sh) === null || _0 === void 0 ? void 0 : _0.accesskey) !== null && _1 !== void 0 ? _1 : '';
                let nonce = (_3 = (_2 = job.data.webform.sh) === null || _2 === void 0 ? void 0 : _2.nonce) !== null && _3 !== void 0 ? _3 : '';
                const entries = new Map();
                for (const en of job.data.form) {
                    if (!entries.has(en.key)) {
                        entries.set(en.key, []);
                    }
                    const eben = LetrwingCrypto.secretBoxWithNonce(en.value.celldata, key, nonce).encryptmsg;
                    (_4 = entries.get(en.key)) === null || _4 === void 0 ? void 0 : _4.push(Object.assign(Object.assign({}, en.value), { celldata: eben, cellfindstr: eben }));
                }
                if (mapofobjectids.size > 0) {
                    for (const [k, v] of mapofobjectids) {
                        if (k.indexOf("----") !== -1) {
                            const parts = k.split("----");
                            if (parts.length > 2) {
                                const id = parts[0] + "----" + parts[1];
                                const subrowindex = parts[2] + "----" + parts[3];
                                const eben = LetrwingCrypto.secretBoxWithNonce(v.map((ch) => "objs-" + ch).join(","), key, nonce).encryptmsg;
                                const entry = {
                                    celldata: eben,
                                    cellfindstr: eben,
                                    cellid: id,
                                    schemaid: '',
                                    rowindex: -1,
                                    rowid: '',
                                    id: "",
                                    subrowindex: subrowindex,
                                    sortstring: Math.random(),
                                    linkedids: v.map((ch) => "objs-" + ch),
                                };
                                entries.set(k, [entry]);
                            }
                        }
                        else {
                            const eben = LetrwingCrypto.secretBoxWithNonce(v.map((ch) => "objs-" + ch).join(","), key, nonce).encryptmsg;
                            const entry = {
                                celldata: eben,
                                cellfindstr: eben,
                                cellid: k,
                                schemaid: '',
                                rowindex: -1,
                                rowid: '',
                                id: "",
                                subrowindex: '0',
                                sortstring: Math.random(),
                                linkedids: v.map((ch) => "objs-" + ch),
                            };
                            entries.set(k, [entry]);
                        }
                    }
                }
                // now we can just add these now!!
                const allitems = (_6 = (_5 = actualjob.webform.sh) === null || _5 === void 0 ? void 0 : _5.items) !== null && _6 !== void 0 ? _6 : [];
                const schemaItemDefaults = new Map();
                for (const it of (_7 = actualjob.webform.schemaItemDefault) !== null && _7 !== void 0 ? _7 : []) {
                    schemaItemDefaults.set(it.id, it);
                }
                const dtim = new Date().getTime() + "";
                for (const it of allitems) {
                    if (it.type === SchemaItemType.List) {
                        const subitems = it.items;
                        // lets get subrows first
                        const srows = new Set();
                        // lets check all the sub items there are and then add the default value 
                        for (const si of subitems) {
                            const id = it.id + "----" + si.id;
                            if (entries.has(id)) {
                                // that should give us some rows
                                const ens = entries.get(id);
                                for (const e of ens !== null && ens !== void 0 ? ens : []) {
                                    if (e.subrowindex && !srows.has((_8 = e.subrowindex) !== null && _8 !== void 0 ? _8 : '')) {
                                        srows.add(e.subrowindex);
                                    }
                                }
                            }
                        }
                        if (srows.size > 0) {
                            for (const si of subitems) {
                                const id = it.id + "----" + si.id;
                                let df = si.item.defaultvalue;
                                if (schemaItemDefaults.has(id)) {
                                    df = (_9 = schemaItemDefaults.get(id)) === null || _9 === void 0 ? void 0 : _9.value;
                                }
                                if (si.item.type === SchemaItemType.Date && !df) {
                                    df = dtim;
                                }
                                if (df) {
                                    const ens = entries.get(id);
                                    const emap = new Map();
                                    for (const e of ens !== null && ens !== void 0 ? ens : []) {
                                        if (e.subrowindex) {
                                            emap.set(e.subrowindex, e);
                                        }
                                    }
                                    for (const sr of srows) {
                                        if (!emap.has(sr)) {
                                            const eben = LetrwingCrypto.secretBoxWithNonce(df, key, nonce).encryptmsg;
                                            const entry = {
                                                celldata: eben,
                                                cellfindstr: eben,
                                                cellid: id,
                                                schemaid: '',
                                                rowindex: -1,
                                                rowid: '',
                                                id: "",
                                                subrowindex: sr,
                                                sortstring: Math.random()
                                            };
                                            if (!entries.has(id)) {
                                                entries.set(id, []);
                                            }
                                            (_10 = entries.get(id)) === null || _10 === void 0 ? void 0 : _10.push(entry);
                                        }
                                    }
                                }
                            }
                        }
                        continue;
                    }
                    if ((!entries.has(it.id))) {
                        let def = it.defaultvalue;
                        if (schemaItemDefaults.has(it.id)) {
                            def = (_11 = schemaItemDefaults.get(it.id)) === null || _11 === void 0 ? void 0 : _11.value;
                        }
                        if (!def) {
                            if (it.type === SchemaItemType.Date) {
                                def = dtim;
                            }
                            else {
                                continue;
                            }
                        }
                        const eben = LetrwingCrypto.secretBoxWithNonce(def, key, nonce).encryptmsg;
                        const entry = {
                            celldata: eben,
                            cellfindstr: eben,
                            cellid: it.id,
                            schemaid: '',
                            rowindex: -1,
                            rowid: '',
                            id: "",
                            subrowindex: "0",
                            sortstring: Math.random()
                        };
                        entries.set(it.id, [entry]);
                    }
                }
                const ens = [];
                for (const [k, v] of entries) {
                    for (const en of v) {
                        ens.push(en);
                    }
                }
                // lets do this now!
                const arreq = {
                    letrid: actualjob.webform.letrid,
                    schemaid: actualjob.webform.schemaid,
                    entries: ens,
                    rowid: "",
                    assignedto: [],
                    updateassignee: false,
                    dataid: dataid,
                    notifyviews: job.notifyview,
                    colusers: job.assigncols,
                    customnotify: job.customnotify
                };
                const url = ServerPath.pwaaddRow;
                try {
                    const res = await comm.postWithNetwork(url, arreq);
                    if (res === 'NetworkError') {
                        return 'NetworkError';
                    }
                    if (res) {
                        job.updatedentries = res !== 'Unauthorised' ? res : [];
                        return 'Success';
                    }
                    return 'Failed';
                }
                catch (ex) {
                    console.log(ex);
                    return 'NetworkError';
                }
            }
            else if (job.jobtype === JobType.EditRow && job.data.webform) {
                const actualjob = job.data;
                const dataid = job.userid + "----" + job.data.webform.id;
                let schemaaccesskey = (_13 = (_12 = job.data.webform.sh) === null || _12 === void 0 ? void 0 : _12.accesskey) !== null && _13 !== void 0 ? _13 : '';
                let schemanonce = (_15 = (_14 = job.data.webform.sh) === null || _14 === void 0 ? void 0 : _14.nonce) !== null && _15 !== void 0 ? _15 : '';
                if (actualjob.files.length > 0) {
                    let index = -1;
                    for (const aj of actualjob.files) {
                        index++;
                        // lets upload this file!!
                        if (actualjob.progress.length < index)
                            continue;
                        const mypr = ((_16 = actualjob.progress) !== null && _16 !== void 0 ? _16 : [])[index];
                        if (!mypr.done) {
                            // just make sure to store this!!
                            const res = await this.uploadFile(aj.file, job.userid, actualjob.webform, mypr, async (pr) => {
                                actualjob.progress[index] = pr;
                                job.jobtime = new Date().getTime();
                                await PWaDb.addDataJob(job);
                            });
                            if (res === 'NetworkIssue') {
                                // stop this here!
                                return "NetworkError";
                            }
                            else if (res === 'Failed') {
                                // continue with next!!
                                continue;
                            }
                            mypr.done = true;
                            await PWaDb.addDataJob(job);
                        }
                        if (mypr.objectid) {
                            continue;
                        }
                        const foldername = ((_17 = actualjob.webform.dh) === null || _17 === void 0 ? void 0 : _17.name) + "!" + ((_18 = actualjob.webform.sh) === null || _18 === void 0 ? void 0 : _18.name);
                        const url = ServerPath.pwainsertProjectObject;
                        const fen = {
                            id: "",
                            name: foldername,
                            parentid: "root",
                            childrencount: 0,
                            updatetime: 0,
                            type: ProjectObjectType.Folder,
                            color: 'orange',
                            accessids: [],
                        };
                        const dent = fen;
                        const det = {
                            name: dent.name,
                            color: (_19 = dent.color) !== null && _19 !== void 0 ? _19 : 'orange'
                        };
                        const ef = LetrwingCrypto.secretBox(JSON.stringify(det), actualjob.webform.letrkey);
                        const ret = {
                            type: ProjectObjectType.Folder,
                            id: "",
                            data: ef,
                            parentid: "root",
                            updatetime: 0,
                            childrencount: 0,
                            creatorid: dataid,
                            accessids: [],
                            name: LetrwingCrypto.secretBox(dent.name, actualjob.webform.letrkey)
                        };
                        const res = await comm.postWithNetwork(url, ret);
                        if (res === 'Unauthorised') {
                            return "Failed";
                        }
                        if (res === 'NetworkError') {
                            return "NetworkError";
                        }
                        if (!res) {
                            continue;
                        }
                        // we have parentid now!
                        let parentid = "root";
                        if (res) {
                            parentid = res.id;
                        }
                        let f = aj.file;
                        // now we have this we can add actual project obejct now too!!
                        let documentent = {
                            name: f.name,
                            accesscode: (_21 = (_20 = mypr.meta) === null || _20 === void 0 ? void 0 : _20.meta.boxid) !== null && _21 !== void 0 ? _21 : '',
                            accesskey: (_23 = (_22 = mypr.meta) === null || _22 === void 0 ? void 0 : _22.accesskey) !== null && _23 !== void 0 ? _23 : '',
                            documentreference: (_25 = (_24 = mypr.meta) === null || _24 === void 0 ? void 0 : _24.meta.id) !== null && _25 !== void 0 ? _25 : '',
                            size: f.size,
                            versions: [{
                                    name: f.name,
                                    isDownloadable: true,
                                    filid: (_27 = (_26 = mypr.meta) === null || _26 === void 0 ? void 0 : _26.meta.id) !== null && _27 !== void 0 ? _27 : '',
                                    boxid: (_29 = (_28 = mypr.meta) === null || _28 === void 0 ? void 0 : _28.meta.boxid) !== null && _29 !== void 0 ? _29 : '',
                                    versionnumber: 1,
                                    acesskey: (_31 = (_30 = mypr.meta) === null || _30 === void 0 ? void 0 : _30.accesskey) !== null && _31 !== void 0 ? _31 : '',
                                    size: f.size,
                                    creatorid: (_32 = res.creatorid) !== null && _32 !== void 0 ? _32 : ''
                                }],
                            type: ProjectObjectType.Document,
                            parentid: parentid,
                            updatetime: 0,
                            id: "",
                            origname: f.name,
                            accessids: []
                        };
                        // lets do comething here now
                        const finfo = {
                            accesscode: documentent.accesscode,
                            accesskey: documentent.accesskey,
                            accessid: documentent.documentreference,
                            size: documentent.size,
                            versions: documentent.versions,
                            replace: [],
                            width: 0,
                            height: 0,
                            name: documentent.name,
                            origname: documentent.origname
                        };
                        const ef1 = LetrwingCrypto.secretBox(JSON.stringify(finfo), actualjob.webform.letrkey);
                        const ret1 = {
                            type: ProjectObjectType.Document,
                            id: documentent.id,
                            data: ef1,
                            parentid: parentid,
                            creatorid: dataid,
                            updatetime: 0,
                            childrencount: 0,
                            accessids: [],
                            itemreference: documentent.documentreference,
                            name: LetrwingCrypto.secretBox(documentent.name, actualjob.webform.letrkey)
                        };
                        const jobs = [];
                        // lets create a job too here!!
                        if (((_33 = actualjob.webform.pubkeys) !== null && _33 !== void 0 ? _33 : []).length > 0 && actualjob.webform.searchbox) {
                            // lets create job here now!!
                            const pubkeys = (_34 = actualjob.webform.pubkeys) !== null && _34 !== void 0 ? _34 : [];
                            const seb = actualjob.webform.searchbox;
                            const oletrid = actualjob.webform.letrid;
                            const filejob = {
                                fileid: documentent.documentreference,
                                key: documentent.accesskey,
                                filesize: documentent.size,
                                oldrefid: "",
                                fileext: (_35 = documentent.name.split('.').pop()) !== null && _35 !== void 0 ? _35 : '',
                                searchkey: (_36 = seb === null || seb === void 0 ? void 0 : seb.key) !== null && _36 !== void 0 ? _36 : '',
                                searchnonce: (_37 = seb === null || seb === void 0 ? void 0 : seb.nonce) !== null && _37 !== void 0 ? _37 : '',
                                searchrandomdata: (_38 = seb === null || seb === void 0 ? void 0 : seb.randomdata) !== null && _38 !== void 0 ? _38 : '',
                                letrid: oletrid !== null && oletrid !== void 0 ? oletrid : '',
                                addwatermark: true,
                                ocrdocument: true,
                                ocrlang: ''
                            };
                            // now we have file job we can do other things here!!
                            const job = {
                                jobtype: ModuleJobType.Document,
                                data: LetrwingCrypto.getBase64FromUT8(JSON.stringify(filejob))
                            };
                            for (const re of pubkeys) {
                                const box = LetrwingCrypto.box(JSON.stringify(job), re.publickey);
                                if (box !== null) {
                                    jobs.push({
                                        data: box,
                                        lrvmdata: re,
                                        reference: documentent.documentreference,
                                        priority: JobPriority.Low
                                    });
                                }
                            }
                        }
                        const sres = {
                            object: ret1,
                            job: jobs,
                            dataid: dataid
                        };
                        const urlq = ServerPath.pwaaddProjectObject;
                        const res1 = await comm.postWithNetwork(urlq, sres);
                        if (res1 === 'Unauthorised') {
                            return "Failed";
                        }
                        if (res1 === 'NetworkError') {
                            return 'NetworkError';
                        }
                        if (res1) {
                            mypr.objectid = res1.id;
                            job.jobstatusmessage = "Uploaded " + index + " files";
                            await PWaDb.addDataJob(job);
                        }
                    }
                }
                let key = (_40 = (_39 = job.data.webform.sh) === null || _39 === void 0 ? void 0 : _39.accesskey) !== null && _40 !== void 0 ? _40 : '';
                let nonce = (_42 = (_41 = job.data.webform.sh) === null || _41 === void 0 ? void 0 : _41.nonce) !== null && _42 !== void 0 ? _42 : '';
                const fentries = new Map();
                const entries = (_44 = (_43 = actualjob.addentry) === null || _43 === void 0 ? void 0 : _43.entries) !== null && _44 !== void 0 ? _44 : [];
                for (const en of entries) {
                    let key = en.cellid;
                    if (en.cellid.indexOf("----") !== -1) {
                        key = en.cellid + "----" + en.subrowindex;
                    }
                    fentries.set(key, en);
                }
                const mapofobjectids = new Map();
                for (const f of job.data.progress) {
                    const itemid = f.itemid;
                    if (!mapofobjectids.has(itemid)) {
                        mapofobjectids.set(itemid, []);
                    }
                    const filep = f.objectid;
                    if (filep) {
                        (_45 = mapofobjectids.get(itemid)) === null || _45 === void 0 ? void 0 : _45.push(filep);
                    }
                }
                // const fentries: Map<string, Entry> = new Map();
                // const entries = actualjob.addentry?.entries ?? [];
                // for (const en of entries) {
                //     fentries.set(en.cellid, en);
                // }
                if (mapofobjectids.size > 0) {
                    for (const [k, v] of mapofobjectids) {
                        let id = k;
                        if (k.indexOf("----") !== -1) {
                            const parts = k.split("----");
                            if (parts.length > 2) {
                                id = parts[0] + "----" + parts[1];
                            }
                            if (parts.length > 3) {
                                const subrowindex = parts[2] + "----" + parts[3];
                                id = id + '----' + subrowindex;
                            }
                        }
                        const ent = fentries.get(id);
                        let linkids = [];
                        if (ent !== undefined) {
                            if (ent.linkedids !== undefined && ent.linkedids.length > 0) {
                                ent.linkedids.map((link) => linkids.push(link));
                            }
                            v.map((ch) => linkids.push("objs-" + ch));
                            ent.linkedids = linkids;
                        }
                    }
                }
                const sentries = [];
                const assignedto = [];
                const sitems = (_47 = (_46 = actualjob.addentry) === null || _46 === void 0 ? void 0 : _46.schemaitems) !== null && _47 !== void 0 ? _47 : [];
                const updatedEntries = [];
                for (const en of entries) {
                    updatedEntries.push(en);
                }
                const smap = new Map();
                sitems.forEach((si) => smap.set(si.id, si));
                let savedentryre = [];
                let uass = false;
                for (const entry of updatedEntries) {
                    const sitem = smap.get(entry.cellid);
                    if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                        assignedto.push(...entry.celldata.split(","));
                        uass = true;
                    }
                    const eben = LetrwingCrypto.secretBoxWithNonce(entry.celldata, schemaaccesskey, schemanonce);
                    const fben = LetrwingCrypto.secretBoxWithNonce(entry.cellfindstr, schemaaccesskey, schemanonce);
                    let rowindex = entry.rowindex;
                    if (entry.schemaid !== ((_48 = actualjob.addentry) === null || _48 === void 0 ? void 0 : _48.sid) && rowindex < 1 && rowindex > 0) {
                        rowindex = rowindex * 100000;
                    }
                    savedentryre.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, decrypted: false }));
                    delete entry.objs;
                    delete entry.schsdec;
                    delete entry.objsdec;
                    delete entry.shs;
                    delete entry.remotevalue;
                    let rule = "";
                    if (entry.ruleopen) {
                        rule = JSON.stringify(entry.ruleopen);
                        delete entry.ruleopen;
                    }
                    const rbox = LetrwingCrypto.secretBox(rule, schemaaccesskey);
                    sentries.push(Object.assign(Object.assign({}, entry), { rowindex: rowindex, celldata: eben.encryptmsg, cellfindstr: fben.encryptmsg, rule: rbox }));
                }
                if (actualjob.addentry !== undefined && actualjob.addentry.rowid !== undefined) {
                    const arreq = {
                        letrid: actualjob.addentry.letrid,
                        schemaid: actualjob.addentry.sid,
                        entries: sentries,
                        rowid: actualjob.addentry.rowid,
                        assignedto: assignedto,
                        updateassignee: uass,
                        deletecellids: [],
                        dataid: dataid,
                        notifyviews: job.notifyview,
                        colusers: job.assigncols,
                        customnotify: job.customnotify
                    };
                    const url = ServerPath.updateRow;
                    try {
                        const res = await comm.postWithNetwork(url, arreq);
                        if (res === 'NetworkError') {
                            return 'NetworkError';
                        }
                        if (res) {
                            job.updatedentries = res !== 'Unauthorised' ? res : [];
                            await PWaDb.addDataJob(job);
                            return 'Success';
                        }
                        return 'Failed';
                    }
                    catch (ex) {
                        console.log(ex);
                        return 'NetworkError';
                    }
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
        return "NetworkError";
    }
    async uploadFile(file, appuserid, form, filepro, pcb) {
        try {
            const size = file.size;
            let secretkey = LetrwingCrypto.generateNewSecretKey();
            let accesskey = LetrwingCrypto.generateNewSecretKey();
            const spbox = LetrwingCrypto.secretBox(secretkey, accesskey);
            let meta = undefined;
            let nextsize = 0;
            let uploadedsize = filepro.totaldonechunks;
            const chunksize = 1 * 1024 * 1024;
            const dataid = appuserid + "----" + form.id;
            if (!filepro.meta) {
                const url = ServerPath.pwastoreShareBox;
                // now we need to decoide this shoudl work now?!!
                try {
                    const res = await comm.postWithNetwork(url, spbox);
                    if (res === 'NetworkError') {
                        return "NetworkIssue";
                    }
                    if (res) {
                        const tchunks = Math.ceil(size / (1 * 1024 * 1024));
                        const rmeta = {
                            id: "",
                            letrid: form.letrid,
                            isDownloadable: true,
                            isMedia: false,
                            creatorid: dataid,
                            orgid: "",
                            totalchunks: tchunks,
                            boxid: res,
                            size: size,
                            hasviewer: false,
                            totalfilechunks: tchunks,
                            trial: 0,
                            trialutime: 0
                        };
                        const url1 = ServerPath.pwaaddFileHeader;
                        const resq = await comm.postWithNetwork(url1, rmeta);
                        if (resq === "NetworkError") {
                            return "NetworkIssue";
                        }
                        let mres = "";
                        if (!resq) {
                            return "Failed";
                        }
                        rmeta.id = resq;
                        meta = rmeta;
                        filepro.meta = {
                            meta: rmeta,
                            skey: secretkey,
                            accesskey: accesskey,
                            doneindex: 0
                        };
                        filepro.doneindex = 0;
                        await pcb(filepro);
                    }
                }
                catch (ex) {
                    return "NetworkIssue";
                }
            }
            if (!filepro.meta) {
                // this is failed 
                return "Failed";
            }
            secretkey = filepro.meta.skey;
            accesskey = filepro.meta.accesskey;
            let index = filepro.meta.doneindex;
            // now lets upload one by one!!
            while (uploadedsize < size) {
                // lets get the data now
                const csize = uploadedsize + chunksize;
                nextsize = csize < size ? chunksize : size - uploadedsize;
                const ar = await readUploadedFileAsArrayBuffer(file, uploadedsize, nextsize);
                if (!ar) {
                    // failed 
                    return "Failed";
                }
                // lets upload chunk
                if (ar !== null && ar instanceof ArrayBuffer) {
                    const ubuffer = new Uint8Array(ar);
                    const chunk = {
                        size: nextsize,
                        index: index,
                        data: LetrwingCrypto.secretBoxRaw(ubuffer, secretkey),
                        reference: filepro.meta.meta.id,
                        dataid: dataid
                    };
                    const url1 = ServerPath.pwaaddFileChunk;
                    const resq3 = await comm.postWithNetwork(url1, chunk);
                    if (resq3 === 'NetworkError') {
                        return 'NetworkIssue';
                    }
                    if (!resq3) {
                        return "Failed";
                    }
                    // okay lets do something clever here now!!
                    uploadedsize += nextsize;
                    index++;
                    // done is actually one ahead..
                    filepro.meta.doneindex = index;
                    filepro.doneindex = index;
                    filepro.totaldonechunks = uploadedsize;
                    await pcb(filepro);
                }
                else {
                    return "Failed";
                }
            }
            // now lets upload 
            return 'Success';
        }
        catch (ex) {
            console.log(ex);
        }
        return 'NetworkIssue';
    }
}
export const JobProcessor = new Processor();
const readUploadedFileAsArrayBuffer = (inputFile, offset, size) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(null);
        };
        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        const buffer = inputFile.slice(offset, offset + size);
        temporaryFileReader.readAsArrayBuffer(buffer);
    });
};
