var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import React from 'react';
import { Observer, observer } from "mobx-react";
import { action, makeObservable, observable } from 'mobx';
import { BaseDialog, BaseDialogState } from './BaseDialog';
import { Box, Checkbox, Flex, FormControl, FormLabel, Grid, GridItem, Input, Text } from '@chakra-ui/react';
import { Virtuoso } from 'react-virtuoso';
import { instanceOfLetrLinkUnboxed, SAFESEP } from '@@/Letr';
import { ScaleLoader } from 'react-spinners';
import { Apphandler } from '@/ipc/MainAppHandler';
import { APPEvent } from '@/events/AppEvents';
export class NewAccessGroupState {
    constructor() {
        this.basedialogState = new BaseDialogState();
        this.name = "";
        this.users = [];
        this.links = [];
        this.selectedall = false;
        this.selectedids = new Set();
        this.creating = false;
        this.activeletrid = "";
        makeObservable(this);
        this.basedialogState.setHeaderLabel("New Access Group");
        this.basedialogState.setOkLabel("Create");
        this.basedialogState.onOk = () => { this.createAccessGroup(); };
        APPEvent.addSubscriberForAddAccessGroup((grp) => {
            if (this.creating && (grp === null || grp === void 0 ? void 0 : grp.projectid) === this.activeletrid) {
                this.setCreating(false);
                this.setOpen(false);
            }
        });
    }
    setSelectedAll(sl) {
        this.selectedall = sl;
        this.users.forEach((u) => this.setSelected(u.id, sl));
        this.links.forEach((link) => this.setSelected("freesoul" + SAFESEP + link.id, sl));
        this.toggleOkButton();
    }
    setCreating(cr) {
        this.creating = cr;
    }
    toggleAllSelect() {
        this.setSelectedAll(!this.selectedall);
    }
    setUsers(us) {
        this.users = us;
    }
    setLinks(us) {
        this.links = us;
    }
    setOpen(open) {
        this.basedialogState.setOpen(open);
        this.toggleOkButton();
        if (!open) {
            this.setSelectedAll(false);
            this.setName("");
        }
    }
    setName(name) {
        this.name = name;
        this.toggleOkButton();
    }
    createAccessGroup() {
        if (this.name.trim() && this.selectedids.size > 0) {
            this.setCreating(true);
            const ag = {
                id: "",
                accessids: Array.from(this.selectedids),
                name: this.name,
                projectid: this.activeletrid
            };
            Apphandler.sendMessage("addaccessgroup", ag);
        }
    }
    setSelected(id, sel) {
        if (sel) {
            this.selectedids.add(id);
        }
        else {
            this.selectedids.delete(id);
        }
        this.toggleOkButton();
    }
    toggleOkButton() {
        if (this.name.trim() && this.selectedids.size > 0) {
            this.basedialogState.setDisableOkButton(false);
        }
        else {
            this.basedialogState.setDisableOkButton(true);
        }
    }
    getUsers() {
        return this.users.slice().sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    getLinks() {
        return this.links.slice().sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
}
__decorate([
    observable,
    __metadata("design:type", BaseDialogState)
], NewAccessGroupState.prototype, "basedialogState", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NewAccessGroupState.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], NewAccessGroupState.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], NewAccessGroupState.prototype, "links", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NewAccessGroupState.prototype, "selectedall", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], NewAccessGroupState.prototype, "selectedids", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NewAccessGroupState.prototype, "creating", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NewAccessGroupState.prototype, "setSelectedAll", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NewAccessGroupState.prototype, "setCreating", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], NewAccessGroupState.prototype, "setUsers", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], NewAccessGroupState.prototype, "setLinks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NewAccessGroupState.prototype, "setName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Boolean]),
    __metadata("design:returntype", void 0)
], NewAccessGroupState.prototype, "setSelected", null);
let NewAccessGroup = class NewAccessGroup extends React.Component {
    constructor(prop) {
        super(prop);
    }
    dialog() {
        return React.createElement(Box, null,
            React.createElement(Flex, null,
                React.createElement(FormControl, { id: 'group-name' },
                    React.createElement(FormLabel, null, "Name"),
                    React.createElement(Input, { onChange: (e) => this.props.state.setName(e.currentTarget.value), type: 'text', value: this.props.state.name }))),
            React.createElement(Grid, { h: '50px', mt: '20px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(6, 1fr)', gap: 4 },
                React.createElement(GridItem, { h: '100%', w: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Flex, { w: '100%', align: 'center' },
                        React.createElement(Checkbox, { isChecked: this.props.state.selectedall, onChange: () => this.props.state.toggleAllSelect() }),
                        this.props.state.selectedall && React.createElement(Text, { ml: '10px' }, "Unselect All"),
                        !this.props.state.selectedall && React.createElement(Text, { ml: '10px' }, "Select All"))),
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Flex, null,
                        React.createElement(Text, { w: '80%', fontWeight: 'bold' }, "Name"))),
                React.createElement(GridItem, { h: '100%', colSpan: 2 },
                    React.createElement(Text, { fontWeight: 'bold' }, "Organisation Name"))),
            [...this.props.state.getUsers(), ...this.props.state.getLinks()].length > 0 &&
                React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: '400px' }, data: [...this.props.state.getUsers(), ...this.props.state.getLinks()], itemContent: (index, ob) => {
                        if (instanceOfLetrLinkUnboxed(ob)) {
                            return React.createElement(AccessLinkRow, { gstate: this.props.state, row: ob, key: ob.id });
                        }
                        else {
                            return React.createElement(AccessUserRow, { gstate: this.props.state, row: ob, key: ob.id });
                        }
                    } })),
            this.props.state.creating && React.createElement(Flex, { w: '100%', justify: 'center' },
                React.createElement(ScaleLoader, { color: 'green' })));
    }
    render() {
        return React.createElement(BaseDialog, { body: this.dialog(), state: this.props.state.basedialogState });
    }
};
NewAccessGroup = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], NewAccessGroup);
export { NewAccessGroup };
let AccessUserRow = class AccessUserRow extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(Grid, { h: '50px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(6, 1fr)', gap: 4 },
                React.createElement(GridItem, { h: '100%', w: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Checkbox, { isChecked: this.props.gstate.selectedids.has(this.props.row.id), onChange: (e) => this.props.gstate.setSelected(this.props.row.id, e.currentTarget.checked) })),
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Text, null, this.props.row.name)),
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Text, null, this.props.row.orgname))));
    }
};
AccessUserRow = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], AccessUserRow);
let AccessLinkRow = class AccessLinkRow extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(Grid, { h: '50px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(6, 1fr)', gap: 4 },
                React.createElement(GridItem, { h: '100%', w: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Checkbox, { isChecked: this.props.gstate.selectedids.has("freesoul" + SAFESEP + this.props.row.id), onChange: (e) => this.props.gstate.setSelected("freesoul" + SAFESEP + this.props.row.id, e.currentTarget.checked) })),
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Text, null, this.props.row.name)),
                React.createElement(GridItem, { h: '100%', rowSpan: 1, colSpan: 2 },
                    React.createElement(Text, null, "Link"))));
    }
};
AccessLinkRow = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], AccessLinkRow);
