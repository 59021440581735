export var LoginTypes;
(function (LoginTypes) {
    LoginTypes["SSO"] = "SSO";
    LoginTypes["Email"] = "Email";
    LoginTypes["QRCode"] = "QRCode";
    LoginTypes["OrgCode"] = "OrgCode";
})(LoginTypes || (LoginTypes = {}));
export class AdminLoginDetails {
    constructor() {
        this.authTypes = [];
    }
}
export class QRCodeSessionDetails {
    constructor() {
        this.deviceid = "";
        this.sessionid = "";
        this.authcode = "";
    }
}
