import { comm } from "@/Comm/comm";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { AuthHeaders as AuthHeader } from "@@/AuthHeader";
import { AppConfig } from "@/config/AppConfig";
var ConnecState;
(function (ConnecState) {
    ConnecState[ConnecState["Connected"] = 0] = "Connected";
    ConnecState[ConnecState["Destroyed"] = 1] = "Destroyed";
    ConnecState[ConnecState["Connecting"] = 2] = "Connecting";
})(ConnecState || (ConnecState = {}));
export class MediaLiveStore {
    constructor(closecb, socketopencb, messagecb) {
        this.closecb = closecb;
        this.socketopencb = socketopencb;
        this.messagecb = messagecb;
        this.connectstate = ConnecState.Destroyed;
        this.currentCode = -1;
        this.opencb = () => this.open();
        this.onclosecb = (ev) => this.close(ev.code);
        this.onmessagecb = (ev) => this.message(ev);
        this.lastHeartBeat = 0;
    }
    checkAndPingConnection() {
        var _a;
        const ctime = new Date().getTime();
        const diff = ctime - this.lastHeartBeat;
        if (diff > (20 * 1000)) {
            (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close(3000); // we need to do reattemp now!
            this.socket = undefined;
            if (this.mnitorTiemout) {
                clearTimeout(this.mnitorTiemout);
            }
            this.mnitorTiemout = undefined;
            return false;
        }
        else {
            this.sendMessage('ping');
            return true;
        }
    }
    async destroy() {
        this.sendMessage("disconnect");
        setTimeout(() => {
            var _a;
            (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close(1000);
            this.socket = undefined;
        }, 20);
    }
    close(code) {
        this.connectstate = ConnecState.Destroyed;
        this.socket = undefined;
        this.closecb();
    }
    async message(messageEvent) {
        const data = messageEvent.data;
        this.lastHeartBeat = new Date().getTime();
        if (data) {
            if (data === 'ping') {
                this.sendMessage('pong');
                return;
            }
            else if (data === 'pong') {
                return;
            }
            else {
                this.messagecb(data);
            }
        }
    }
    sendMessage(msg) {
        var _a;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.send(msg);
    }
    async connect(letrid, incoming) {
        if (this.connectstate === ConnecState.Connected ||
            this.connectstate === ConnecState.Connecting) {
            return 100;
        }
        this.connectstate = ConnecState.Connecting;
        const req = {
            letrid: letrid,
            incoming: incoming
        };
        console.log('starting call');
        console.log(req);
        const res = await comm.post(ServerPath.getRoomSession, req);
        if (res !== undefined && res !== 'Everyone Left') {
            this.currentCode = Math.random();
            const tenantid = InMemoryRepo.getTenant();
            this.socket = new WebSocket(AppConfig.wssurl + AppConfig.root + "/room/wshandler?token="
                + encodeURIComponent(res) + "&" +
                AuthHeader.TenantIDGET + "=" + encodeURIComponent(tenantid !== null && tenantid !== void 0 ? tenantid : "") + "&roomid=" + encodeURIComponent(letrid));
            this.socket.onopen = this.opencb;
            this.socket.onclose = this.onclosecb;
            this.socket.onmessage = this.onmessagecb;
            return 200;
        }
        else if (res !== undefined && res === 'Everyone Left') {
            return 300;
        }
        else {
            return 500;
        }
    }
    open() {
        this.connectstate = ConnecState.Connected;
        this.lastHeartBeat = new Date().getTime();
        this.monitor(this.currentCode);
        this.socketopencb();
    }
    monitor(code) {
        if (this.currentCode === code) {
            if (this.checkAndPingConnection()) {
                this.mnitorTiemout = setTimeout(() => this.monitor(code), 10 * 1000);
            }
            else {
                // we should do reconnection here..
            }
        }
    }
}
