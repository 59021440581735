var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { APPEvent } from "@/events/AppEvents";
import { Apphandler } from "@/ipc/MainAppHandler";
import { LetrRequestType } from "@/share/Letr";
import { ALLViewer, MYSELF } from "@@/Annotation";
import { action, makeObservable, observable } from "mobx";
import { AccessGroupDetailState } from "../UIComponents/AccessGroupDetail";
import { BaseDialogState } from "../UIComponents/BaseDialog";
import { NewAccessGroupState } from "../UIComponents/NewAccessGroup";
export class AccessGroupState {
    constructor() {
        this.basedialogState = new BaseDialogState();
        this.memberdetail = [];
        this.accessgroupmap = new Map();
        this.selectedgroup = new Set();
        this.newaccessdialog = new NewAccessGroupState();
        this.detailaccessdialog = new AccessGroupDetailState();
        this.loading = false;
        this.applying = false;
        this.selectedall = false;
        this.name = "";
        this.error = "";
        this.showdetailid = "";
        this.activeletrid = "";
        this.selectedObjectids = [];
        this.letrlinks = [];
        this.aclmode = "project";
        this.myid = "";
        this.letrDetailloading = false;
        makeObservable(this);
        this.basedialogState.setHeaderLabel("Access Group");
        this.basedialogState.setOkLabel("Apply");
        this.basedialogState.onClose = () => this.setOpen(false);
        APPEvent.addSubscriberForGetAccessGroup((grps) => {
            if (this.loading) {
                grps === null || grps === void 0 ? void 0 : grps.forEach((grp) => grp.projectid === this.activeletrid &&
                    this.setAccessGroup(grp));
                this.setLoading(false);
            }
        });
        APPEvent.addSubscriberForAddAccessGroup((grp) => {
            if ((grp === null || grp === void 0 ? void 0 : grp.projectid) === this.activeletrid) {
                this.setAccessGroup(grp);
            }
        });
        APPEvent.addSubscriberForGetLetrlink((links) => {
            if (links.letrid === this.activeletrid) {
                this.letrlinks = links.links;
                this.newaccessdialog.setLinks(this.letrlinks);
            }
        });
        APPEvent.addSubscriberForUpdateObjectAcl((up) => {
            var _a;
            if (this.applying) {
                if (up) {
                    (_a = this.onApplied) === null || _a === void 0 ? void 0 : _a.call(this, Array.from(this.selectedgroup));
                    this.setOpen(false);
                }
                else {
                    this.setError("Sorry, but failed to apply access group");
                }
                this.setApplying(false);
            }
        });
        APPEvent.addSubscriberForCalculateAclString((aclstr) => {
            var _a, _b;
            if (this.applying) {
                if (aclstr !== undefined) {
                    (_a = this.onApplied) === null || _a === void 0 ? void 0 : _a.call(this, Array.from(this.selectedgroup));
                    (_b = this.appliedAclString) === null || _b === void 0 ? void 0 : _b.call(this, aclstr);
                }
                else {
                    this.setError("Sorry, but failed to apply access group");
                }
                this.setApplying(false);
            }
        });
        APPEvent.addSubscriberForActiveLetr((lt) => {
            this.setActiveLetrId(lt);
        });
        APPEvent.addSubscriberForLocalLetrDetail((res) => {
            if (res.type === LetrRequestType.FetchDetail && res.letrdetail !== undefined &&
                res.letrid === this.activeletrid) {
                this.memberdetail = res.letrdetail.memberDetails;
                this.setLetrDetailLoading(false);
            }
        });
        APPEvent.addSubscriberForLetrsResponse((res) => {
            if (res.type === LetrRequestType.FetchDetail && res.letrdetail !== undefined &&
                res.letrid === this.activeletrid) {
                this.memberdetail = res.letrdetail.memberDetails;
                this.setLetrDetailLoading(false);
            }
        });
        APPEvent.addSubscriberForUpdateMessageAcl((res) => {
            if (res !== undefined && res) {
            }
        });
    }
    getLetrLinks() {
        const req = {
            letrid: this.activeletrid
        };
        Apphandler.sendMessage("getletrlink", req);
    }
    setError(err) {
        this.error = err;
    }
    setActiveLetrId(letrid) {
        this.activeletrid = letrid;
    }
    setAclMode(mode) {
        this.aclmode = mode;
    }
    setShowAccessGroupDetail(id) {
        this.showdetailid = id;
    }
    deleteGroup(ac) {
        this.accessgroupmap.delete(ac.id);
        Apphandler.sendMessage("deleteaccessgroup", ac);
    }
    setOpen(open) {
        this.basedialogState.setOpen(open);
        if (!open) {
            this.setLoading(false);
            this.setApplying(false);
            this.setError("");
        }
    }
    setName(name) {
        this.name = name;
    }
    setApplying(app) {
        this.applying = app;
    }
    setSelectedAll(sl) {
        if (!this.selectedgroup.has("everyone")) {
            this.selectedall = sl;
            Array.from(this.accessgroupmap.values()).forEach((ag) => this.setSelectedGroup(ag.id, sl));
        }
    }
    setLetrDetailLoading(loading) {
        this.letrDetailloading = loading;
    }
    loadLetrDetail() {
        if (!this.activeletrid) {
            return;
        }
        this.setLetrDetailLoading(true);
        const req = {
            type: LetrRequestType.FetchDetail,
            letrid: this.activeletrid
        };
        Apphandler.sendMessage("letrs", req);
    }
    apply() {
        var _a, _b;
        if (this.applying) {
            return;
        }
        this.setApplying(true);
        if (this.aclmode === 'project' && this.selectedgroup.size > 0) {
            const req = {
                projectid: this.activeletrid,
                aclids: Array.from(this.selectedgroup),
                applytochildren: true,
                objectid: this.selectedObjectids
            };
            console.log(req);
            Apphandler.sendMessage("updateobjectacl", req);
        }
        else if (this.aclmode === 'message' || this.aclmode === 'annotations') {
            if (this.selectedgroup.has(ALLViewer) || this.selectedgroup.has(MYSELF)) {
                (_a = this.onApplied) === null || _a === void 0 ? void 0 : _a.call(this, Array.from(this.selectedgroup));
                (_b = this.appliedAclString) === null || _b === void 0 ? void 0 : _b.call(this, "");
                this.setApplying(false);
                return;
            }
            const req = {
                letrid: this.activeletrid,
                groupids: Array.from(this.selectedgroup)
            };
            Apphandler.sendMessage("calculateaclstring", req);
        }
    }
    updateMessageAcl(id, ids) {
        const req = {
            letrid: this.activeletrid,
            id: id,
            aclids: Array.from(this.selectedgroup)
        };
        Apphandler.sendMessage("updatemessageacl", req);
    }
    setLoading(loading) {
        this.loading = loading;
    }
    setSelectedGroup(id, add) {
        if (add) {
            if (id === "everyone" || id === MYSELF) {
                this.setSelectedAll(false);
                this.selectedgroup.add(id);
            }
            else if (!this.selectedgroup.has("everyone")) {
                this.selectedgroup.add(id);
            }
        }
        else {
            this.selectedgroup.delete(id);
        }
    }
    openNewAccessGroup() {
        this.newaccessdialog.setUsers(this.memberdetail);
        this.newaccessdialog.setLinks(this.letrlinks);
        this.newaccessdialog.activeletrid = this.activeletrid;
        this.newaccessdialog.setOpen(true);
    }
    setAccessGroup(ac) {
        this.accessgroupmap.set(ac.id, ac);
    }
    unselectAll() {
        Array.from(this.accessgroupmap.values()).forEach((it) => this.setSelectedGroup(it.id, false));
        this.setSelectedGroup(ALLViewer, false);
        this.setSelectedGroup(MYSELF, false);
    }
    toggleAllSelect() {
        if (this.selectedall) {
            this.setSelectedAll(false);
            Array.from(this.accessgroupmap.values()).forEach((it) => this.setSelectedGroup(it.id, false));
        }
        else {
            this.setSelectedAll(true);
            Array.from(this.accessgroupmap.values()).forEach((it) => this.setSelectedGroup(it.id, true));
        }
    }
    loadAccessGroups() {
        if (this.loading) {
            return;
        }
        // now we can load the groups
        this.setLoading(true);
        Apphandler.sendMessage("getaccessgroups", this.activeletrid);
    }
    get accessgroups() {
        const grps = [];
        if (this.aclmode === 'annotations') {
            grps.push({ name: "Myself", id: "myself", accessids: [], projectid: this.activeletrid });
        }
        grps.push({ name: this.aclmode === 'annotations' ? "Everyone" : "Inherit", id: "everyone", accessids: [], projectid: this.activeletrid });
        return [...grps,
            ...Array.from(this.accessgroupmap.values()).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))];
    }
    clearAccessGroup() {
        this.accessgroupmap.clear();
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], AccessGroupState.prototype, "accessgroupmap", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], AccessGroupState.prototype, "selectedgroup", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "applying", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "selectedall", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "error", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "showdetailid", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], AccessGroupState.prototype, "aclmode", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AccessGroupState.prototype, "letrDetailloading", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setError", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setActiveLetrId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setAclMode", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setShowAccessGroupDetail", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "deleteGroup", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setApplying", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setSelectedAll", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setLetrDetailLoading", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setLoading", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Boolean]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setSelectedGroup", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "setAccessGroup", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "unselectAll", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "toggleAllSelect", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AccessGroupState.prototype, "clearAccessGroup", null);
