var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { APPEvent } from "@/events/AppEvents";
import { action, makeObservable, observable } from "mobx";
export class AletrDialogStore {
    constructor() {
        this.message = "";
        this.open = false;
        makeObservable(this);
        APPEvent.addSubscriberFoApperror((err) => {
            this.setMessage(err);
            this.setOpen(true);
        });
    }
    setMessage(msg) {
        this.message = msg;
    }
    setOpen(open) {
        this.open = open;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], AletrDialogStore.prototype, "message", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AletrDialogStore.prototype, "open", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AletrDialogStore.prototype, "setMessage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AletrDialogStore.prototype, "setOpen", null);
