var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export var ChartType;
(function (ChartType) {
    ChartType["Line"] = "line";
    ChartType["Pie"] = "pie";
    ChartType["Bar"] = "bar";
})(ChartType || (ChartType = {}));
export class ChartViewState {
    constructor(id) {
        this.id = id;
        this.buildingchart = false;
        this.xid = "";
        this.yid = "";
        this.colorfield = "";
        this.colorfieldname = "";
        this.xname = "";
        this.yname = "";
        this.xdataset = [];
        this.ydataset = [];
        this.secondarydataset = new Map();
        this.color = "";
        this.ctype = ChartType.Line;
        this.showxdatasetopts = false;
        this.showcolordatasetopts = false;
        this.showydatasetopts = false;
        this.cnref = undefined;
        makeObservable(this);
    }
    setBuildingChart(building) {
        var _a;
        this.buildingchart = building;
        (_a = this.cnref) === null || _a === void 0 ? void 0 : _a.destroy();
    }
    setColor(color) {
        this.color = color;
    }
    setShowXDatasetOpts(show) {
        this.showxdatasetopts = show;
    }
    setShowColorDatasetOpts(show) {
        this.showcolordatasetopts = show;
    }
    setShowYDatasetOpts(show) {
        this.showydatasetopts = show;
    }
    setXID(xid) {
        this.xid = xid;
    }
    setColorFieldid(xid) {
        this.colorfield = xid;
    }
    setSecondaryDataSet(data) {
        this.secondarydataset.set(data.id, data);
    }
    deleteSecondaryDataSet(id) {
        this.secondarydataset.delete(id);
    }
    setColorFieldName(name) {
        this.colorfieldname = name;
    }
    setYID(yid) {
        this.yid = yid;
    }
    setXName(xname) {
        this.xname = xname;
    }
    setYName(yname) {
        this.yname = yname;
    }
    setChartType(ctype) {
        this.ctype = ctype;
    }
    setXDataset(ds) {
        this.xdataset = ds;
    }
    setYDataset(ds) {
        this.ydataset = ds;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "buildingchart", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "xid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "yid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "colorfield", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "colorfieldname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "xname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "yname", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], ChartViewState.prototype, "xdataset", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], ChartViewState.prototype, "ydataset", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], ChartViewState.prototype, "secondarydataset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "color", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], ChartViewState.prototype, "ctype", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "showxdatasetopts", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "showcolordatasetopts", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ChartViewState.prototype, "showydatasetopts", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setBuildingChart", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setColor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setShowXDatasetOpts", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setShowColorDatasetOpts", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setShowYDatasetOpts", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setXID", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setColorFieldid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setSecondaryDataSet", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "deleteSecondaryDataSet", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setColorFieldName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setYID", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setXName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setYName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setChartType", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setXDataset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ChartViewState.prototype, "setYDataset", null);
