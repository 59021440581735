import { AuthHeaders } from "./AuthHeader";
export class RegisterInfo {
    constructor() {
        this.email = "";
        this.fullname = "";
        this.id = "";
        this.masterPublicKey = "";
        this.commKey = "";
        this.matrix = [];
    }
}
export class DeviceregisterRequest {
    constructor() {
        this.deviceid = "";
        this.deviceregisterpassword = "";
        this.devicepassword = "";
        this.extrainfo = "";
        this.devicestoragekey = "";
        this.deviceMatrixPassword = "";
    }
}
export var AuthMethod;
(function (AuthMethod) {
    AuthMethod["SSO"] = "SSO";
    AuthMethod["SentCode"] = "SentCode";
    AuthMethod["New"] = "New"; // new user register this user after otp
})(AuthMethod || (AuthMethod = {}));
export class OrgCheckResponse {
    constructor() {
        this.tenantid = "";
        this.auth = AuthMethod.New;
    }
}
export class NewUserVerifiedResponse {
    constructor() {
        this.sessionid = "";
        this.organisationsInvites = [];
        this.res = AuthMethod.New;
    }
}
export var IdentityTokenType;
(function (IdentityTokenType) {
    IdentityTokenType["Register"] = "Register";
    IdentityTokenType["Launch"] = "Launch";
})(IdentityTokenType || (IdentityTokenType = {}));
export class IdentityToken {
    constructor() {
        this.userid = "";
        this.commsessionid = "";
        this.commpk = "";
        this.tokenType = IdentityTokenType.Register;
        this.deviceInfoKey = "";
        this.matrix = [];
    }
}
export const OrgHeader = { key: AuthHeaders.TenantID, value: AuthHeaders.OrgTenantIDValue };
