import { AppConfig } from "@/config/AppConfig";
class Lib {
    getTenant() {
        let tenant = AppConfig.tenantid;
        return tenant;
    }
    getLoginHeaders() {
    }
}
export const LetrwingLib = new Lib();
