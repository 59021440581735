import { Box, Button, Flex, Input, Text, Icon, Select } from '@chakra-ui/react';
import { MdImage } from '@react-icons/all-files/md/MdImage';
import { TiTick } from '@react-icons/all-files/ti/TiTick';
import * as QRCode from 'html5-qrcode';
import React from 'react';
import ReactDOM from 'react-dom';
import { MdClose } from 'react-icons/md';
import { AppColor } from '../const/AppDefaults';
export class LWBarcodeScanner extends React.Component {
    constructor(prop) {
        super(prop);
        this.entercb = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.submitBarcode();
            }
        };
        this.fileref = null;
        this.qr = undefined;
        this.state = {
            userinput: '',
            errormessage: '',
            showcamera: false,
            devicids: [],
            showretry: false,
            scannertype: 'auto',
            width: -1,
            height: -1,
            displayheight: 0,
            displaywidth: 0
        };
    }
    async componentDidMount() {
        window.addEventListener("keydown", this.entercb);
        const cms = await QRCode.Html5Qrcode.getCameras();
        if (!cms) {
            this.setState({ errormessage: 'Sorry, found no camera on this device or maybe we dont have permissions' });
            return;
        }
        await this.startScanning();
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.entercb);
    }
    submitBarcode() {
        if (this.state.userinput.trim()) {
            this.props.cb(this.state.userinput, 'bc');
        }
    }
    addErrorMessage(msg) {
        this.setState({ errormessage: msg });
    }
    async cancel() {
        var _a, _b, _c;
        if (((_a = this.qr) === null || _a === void 0 ? void 0 : _a.isScanning) || ((_b = this.qr) === null || _b === void 0 ? void 0 : _b.getState()) === QRCode.Html5QrcodeScannerState.PAUSED) {
            await ((_c = this.qr) === null || _c === void 0 ? void 0 : _c.stop());
        }
        this.qr = undefined;
    }
    async startScanning() {
        var _a;
        if (!this.qr) {
            this.qr = new QRCode.Html5Qrcode("maincontainer");
        }
        const config = { fps: 10, qrbox: (vw, vh) => {
                if (this.state.scannertype === 'auto') {
                    return { width: vw * .9, height: vh * .9 };
                }
                let w = this.state.width;
                let h = this.state.height;
                if (this.state.scannertype === 'br') {
                    if (w === -1 && h === -1) {
                        const vmst = (vw * .9).toFixed(2);
                        this.setState({ displayheight: 100, displaywidth: parseFloat(vmst) });
                    }
                    return { width: w > -1 ? w : vw * .9, height: h > -1 ? h : 100 };
                }
                if (this.state.scannertype === 'qr') {
                    if (w === -1 && h === -1) {
                        const vmst = (vw * .7).toFixed(2);
                        const vmhst = (vh * .7).toFixed(2);
                        this.setState({ displayheight: parseFloat(vmst),
                            displaywidth: parseFloat(vmhst) });
                    }
                    return { width: w > -1 ? w : vw * .7, height: h > -1 ? h : vh * .7 };
                }
                return { width: w > -1 ? w : 200, height: h > -1 ? h : 200 };
            }
        };
        try {
            await ((_a = this.qr) === null || _a === void 0 ? void 0 : _a.start({ facingMode: "environment" }, config, async (decodedText, decodedResult) => {
                if (decodedResult.result.format !== undefined) {
                    let close = false;
                    if (decodedResult.result.format.format === QRCode.Html5QrcodeSupportedFormats.QR_CODE) {
                        close = this.props.cb(decodedText, 'qr');
                    }
                    else {
                        close = this.props.cb(decodedText, 'bc');
                    }
                    if (close) {
                        await this.cancel();
                        this.props.closeme();
                    }
                    else {
                        this.setState({ errormessage: "Invalid code.." });
                    }
                }
            }, () => {
            }));
            this.setState({ showretry: false });
        }
        catch (ex) {
            this.addErrorMessage("Sorry, failed to start scanning.");
            this.setState({ showretry: true });
        }
    }
    render() {
        return React.createElement(Box, { boxShadow: '5px 5px 5px 5px lightgray', pos: 'fixed', left: this.props.x, top: this.props.y, zIndex: 1000000, backgroundColor: 'white', width: (window.innerWidth < 600 ? window.innerWidth - 30 : 600) + 'px', borderWidth: '1px', borderColor: 'lightgray' },
            React.createElement("input", { accept: "image/*", type: 'file', style: { display: 'none' }, ref: (ref) => {
                    this.fileref = ref;
                }, onChange: async (e) => {
                    var _a;
                    if (!this.qr)
                        return;
                    const fs = e.currentTarget.files;
                    if (fs && fs.length > 0) {
                        const fil = fs.item(0);
                        if (fil) {
                            await this.cancel();
                            try {
                                const decodedResult = await ((_a = this.qr) === null || _a === void 0 ? void 0 : _a.scanFileV2(fil, true));
                                if (decodedResult.result.format !== undefined) {
                                    let close = false;
                                    if (decodedResult.result.format.format === QRCode.Html5QrcodeSupportedFormats.QR_CODE) {
                                        close = this.props.cb(decodedResult.result.text, 'qr');
                                    }
                                    else {
                                        close = this.props.cb(decodedResult.result.text, 'bc');
                                    }
                                    if (close) {
                                        await this.cancel();
                                        this.props.closeme();
                                    }
                                    else {
                                        this.setState({ errormessage: "Invalid code.." });
                                        this.startScanning();
                                    }
                                }
                                else {
                                    this.setState({ errormessage: "Invalid type.." });
                                    this.startScanning();
                                }
                            }
                            catch (ex) {
                                this.addErrorMessage("Sorry, not able to detect barcode or QR code.");
                                await this.startScanning();
                            }
                        }
                        else {
                            await this.startScanning();
                        }
                    }
                    else {
                        await this.startScanning();
                    }
                } }),
            React.createElement(Box, { h: '145px' },
                React.createElement(Flex, { h: '50px', align: 'center' },
                    React.createElement(Box, null,
                        React.createElement(Input, { focusBorderColor: AppColor.HeaderIconColor, height: '30px', marginLeft: '5px', borderColor: 'lightgray', borderWidth: '1px', autoFocus: true, value: this.state.userinput, placeholder: 'Input barcode', w: '200px', onChange: (e) => {
                                this.setState({ userinput: e.currentTarget.value });
                            } }),
                        React.createElement(Text, { pos: 'absolute', ml: '10px', color: 'red', mt: '5px' }, this.state.errormessage)),
                    React.createElement(Icon, { color: 'green', as: TiTick, width: '36px', height: '36px', _hover: { cursor: 'pointer' }, onClick: () => {
                            this.submitBarcode();
                        } }),
                    React.createElement(Box, { pos: 'relative', borderRadius: '20px' },
                        React.createElement(Icon, { color: 'purple', as: MdImage, width: '36px', height: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                                var _a;
                                (_a = this.fileref) === null || _a === void 0 ? void 0 : _a.click();
                            } })),
                    this.state.showretry && React.createElement(Box, { pos: 'relative', borderRadius: '20px' },
                        React.createElement(Button, { onClick: async () => {
                                var _a;
                                if ((_a = this.qr) === null || _a === void 0 ? void 0 : _a.isScanning) {
                                    try {
                                        this.qr.stop();
                                    }
                                    catch (Ex) {
                                    }
                                }
                                await this.startScanning();
                            } }, "Retry")),
                    React.createElement(Icon, { pos: 'absolute', right: '5px', as: MdClose, color: 'red', width: '25px', height: '25px', _hover: { cursor: 'pointer' }, onClick: async () => {
                            await this.cancel();
                            this.props.closeme();
                        } })),
                React.createElement(Flex, { h: '50px', align: 'center', mt: '10px' },
                    React.createElement(Flex, { align: 'center' },
                        React.createElement(Text, { w: '140px' }, "Scan Type: "),
                        React.createElement(Select, { value: this.state.scannertype, onChange: (e) => {
                                const type = e.currentTarget.value;
                                if (type === this.state.scannertype)
                                    return;
                                this.setState({ scannertype: e.currentTarget.value, width: -1, height: -1 });
                                setTimeout(async () => {
                                    await this.cancel();
                                    await this.startScanning();
                                }, 50);
                            } },
                            React.createElement("option", { value: 'auto' }, "Auto"),
                            React.createElement("option", { value: 'br' }, "Barcode"),
                            React.createElement("option", { value: 'qr' }, "QR Code")))),
                React.createElement(Flex, { h: '20px', align: 'center' },
                    this.state.scannertype !== 'auto' && React.createElement(Flex, { ml: '10px', onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                if (this.state.displayheight > 0 && this.state.displaywidth > 0) {
                                    this.setState({ width: this.state.displaywidth,
                                        height: this.state.displayheight });
                                    setTimeout(async () => {
                                        await this.cancel();
                                        await this.startScanning();
                                    }, 50);
                                }
                            }
                        } },
                        React.createElement(Text, { width: '30px' }, "W: "),
                        React.createElement(Input, { style: { borderWidth: '1px', borderColor: 'lightgray' }, width: '100px', placeholder: 'Adjust width', type: 'number', value: this.state.displaywidth, onChange: (e) => {
                                const num = parseFloat(e.currentTarget.value);
                                this.setState({ displaywidth: num });
                            } }),
                        React.createElement(Text, { ml: '5px', width: '30px' }, "H: "),
                        React.createElement(Input, { style: { borderWidth: '1px', borderColor: 'lightgray' }, width: '100px', placeholder: 'Adjust height', type: 'number', value: this.state.displayheight, onChange: (e) => {
                                const num = parseFloat(e.currentTarget.value);
                                this.setState({ displayheight: num });
                            } }),
                        React.createElement(Icon, { ml: '10px', color: 'green', as: TiTick, width: '36px', height: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                                if (this.state.displayheight > 0 && this.state.displaywidth > 0) {
                                    this.setState({ width: this.state.displaywidth,
                                        height: this.state.displayheight });
                                    setTimeout(async () => {
                                        await this.cancel();
                                        await this.startScanning();
                                    }, 50);
                                }
                            } })),
                    this.state.scannertype !== 'auto' && React.createElement(Flex, { ml: '10px' }))),
            React.createElement(Box, { id: 'maincontainer' }));
    }
}
let ref = null;
export const BarcodeScanner = async (x, y, cb) => {
    if (ref) {
        await ref.cancel();
    }
    const div = document.getElementById("qrreader");
    if (div) {
        div.remove();
    }
    const nnewdiv = document.createElement("div");
    nnewdiv.id = "qrreader";
    document.body.appendChild(nnewdiv);
    ReactDOM.render(React.createElement(LWBarcodeScanner, { ref: (r) => ref = r, x: x, y: y, closeme: () => {
            if (nnewdiv) {
                nnewdiv.remove();
            }
        }, cb: (data, type) => {
            if (cb(data, type)) {
                ref === null || ref === void 0 ? void 0 : ref.cancel();
                if (nnewdiv) {
                    nnewdiv.remove();
                }
                return true;
            }
            ref === null || ref === void 0 ? void 0 : ref.addErrorMessage("Invalid code..");
            return false;
        } }), nnewdiv);
};
export const BarcodeScanner_old = (x, y, cb) => {
    const div = document.getElementById("qrreader");
    if (div) {
        div.remove();
    }
    const maindiv = document.createElement("div");
    maindiv.style.boxShadow = "5px 5px 5px 5px lightgray";
    maindiv.style.borderWidth = "1px";
    maindiv.style.borderColor = "lightgray";
    const condiv = document.createElement("div");
    const headerdiv = document.createElement("div");
    const messagediv = document.createElement("div");
    condiv.appendChild(headerdiv);
    condiv.appendChild(messagediv);
    headerdiv.style.height = '100px';
    condiv.style.borderBottomWidth = "1px";
    condiv.style.borderBottomColor = "lightgray";
    headerdiv.style.display = 'flex';
    condiv.style.marginBottom = "5px";
    headerdiv.style.alignItems = 'center';
    maindiv.appendChild(condiv);
    const button = document.createElement("button");
    headerdiv.appendChild(button);
    button.textContent = "Cancel";
    button.style.margin = "10px";
    const textinput = document.createElement("input");
    headerdiv.appendChild(textinput);
    textinput.style.borderWidth = '1px';
    textinput.style.borderColor = 'gray';
    textinput.style.marginLeft = '10px';
    textinput.placeholder = "Enter barcode";
    const textbutton = document.createElement("button");
    headerdiv.appendChild(textbutton);
    textbutton.textContent = "Submit";
    textbutton.style.margin = "10px";
    textbutton.onclick = () => {
        if (!textinput.value.trim())
            return;
        if (cb(textinput.value, 'bc')) {
            scnanner.clear();
            maindiv.remove();
        }
    };
    const msgdiv = document.createElement("span");
    messagediv.appendChild(msgdiv);
    const dd = document.createElement("div");
    maindiv.style.width = (window.innerWidth < 600 ? window.innerWidth - 30 : 600) + 'px';
    dd.id = "qrreader";
    maindiv.style.position = 'fixed';
    maindiv.style.left = x + 'px';
    maindiv.style.zIndex = "1000000";
    maindiv.style.backgroundColor = 'white';
    maindiv.style.top = y + 'px';
    maindiv.appendChild(dd);
    document.body.appendChild(maindiv);
    const scnanner = new QRCode.Html5QrcodeScanner("qrreader", {
        fps: 10,
        rememberLastUsedCamera: true,
        // Only support camera scan type.
        supportedScanTypes: [QRCode.Html5QrcodeScanType.SCAN_TYPE_CAMERA, QRCode.Html5QrcodeScanType.SCAN_TYPE_FILE]
    }, false);
    scnanner.render((decodedText, decodedResult) => {
        if (decodedResult.result.format !== undefined) {
            let close = false;
            if (decodedResult.result.format.format === QRCode.Html5QrcodeSupportedFormats.QR_CODE) {
                msgdiv.textContent = "Decoding QR code...";
                close = cb(decodedText, 'qr');
            }
            else {
                msgdiv.textContent = "Decoding..";
                close = cb(decodedText, 'bc');
            }
            if (close) {
                scnanner.clear();
                maindiv.remove();
            }
            else {
                msgdiv.textContent = "Invalid code..";
            }
        }
    }, (ermsg, error) => {
    });
    button.onclick = () => {
        scnanner.clear();
        maindiv.remove();
    };
};
