import { LetrwingCrypto } from "./LetrwingCommonCrypto";
export var UIType;
(function (UIType) {
    UIType[UIType["Choice"] = 0] = "Choice";
    UIType[UIType["MultiChoice"] = 1] = "MultiChoice";
    UIType[UIType["SingleText"] = 2] = "SingleText";
    UIType[UIType["MultiText"] = 3] = "MultiText";
    UIType[UIType["Date"] = 4] = "Date";
    UIType[UIType["Switch"] = 5] = "Switch";
})(UIType || (UIType = {}));
export var FieldStatus;
(function (FieldStatus) {
    FieldStatus["Dead"] = "Dead";
    FieldStatus["Live"] = "Live";
})(FieldStatus || (FieldStatus = {}));
export var ChoiceType;
(function (ChoiceType) {
    ChoiceType[ChoiceType["Text"] = 0] = "Text";
    ChoiceType[ChoiceType["Number"] = 1] = "Number";
    ChoiceType[ChoiceType["Color"] = 2] = "Color";
})(ChoiceType || (ChoiceType = {}));
export var ListDataItemType;
(function (ListDataItemType) {
    ListDataItemType[ListDataItemType["FormInput"] = 0] = "FormInput";
    ListDataItemType[ListDataItemType["Text"] = 1] = "Text";
    ListDataItemType[ListDataItemType["Number"] = 2] = "Number";
    ListDataItemType[ListDataItemType["Boolean"] = 3] = "Boolean";
    ListDataItemType[ListDataItemType["Choice"] = 4] = "Choice";
    ListDataItemType[ListDataItemType["URL"] = 5] = "URL";
    ListDataItemType[ListDataItemType["Date"] = 6] = "Date";
})(ListDataItemType || (ListDataItemType = {}));
export var SquenceNumberPrefix;
(function (SquenceNumberPrefix) {
    SquenceNumberPrefix["Text"] = "Text";
    SquenceNumberPrefix["Date"] = "Date";
})(SquenceNumberPrefix || (SquenceNumberPrefix = {}));
export const convertBoxToSheet = (schema, letrid, box) => {
    const datastr = LetrwingCrypto.decryptSecretBox(schema.schemadata, box);
    if (!datastr) {
        return undefined;
    }
    try {
        const dataobj = JSON.parse(datastr);
        dataobj.letrid = letrid;
        dataobj.id = schema.id;
        dataobj.authorid = schema.authorid,
            dataobj.lastupdatetime = schema.lastupdatetime;
        dataobj.updatedby = schema.updatedby;
        return dataobj;
    }
    catch (ex) {
    }
    return undefined;
};
export const convertBoxToSchema = (schema, letrid, box) => {
    const datastr = LetrwingCrypto.decryptSecretBox(schema.schemadata, box);
    if (!datastr) {
        return undefined;
    }
    let rules = undefined;
    if (schema.rulebox) {
        const rstr = LetrwingCrypto.decryptSecretBox(schema.rulebox, box);
        if (rstr && (rstr === null || rstr === void 0 ? void 0 : rstr.trim())) {
            try {
                const rbb = JSON.parse(rstr);
                rules = rbb;
            }
            catch (ex) {
                console.log(ex);
            }
        }
    }
    try {
        const dataobj = JSON.parse(datastr);
        dataobj.letrid = letrid;
        dataobj.id = schema.id;
        dataobj.authorid = schema.authorid,
            dataobj.lastupdatetime = schema.lastupdatetime;
        dataobj.updatedby = schema.updatedby;
        dataobj.metaschemaids = schema.metaschemaids;
        dataobj.childrensids = schema.childrenschemaids;
        dataobj.rules = rules;
        return dataobj;
    }
    catch (ex) {
    }
    return undefined;
};
export var ViewType;
(function (ViewType) {
    ViewType["Grid"] = "Grid";
    ViewType["Kanban"] = "Kanban";
    ViewType["Calendar"] = "Calendar";
    ViewType["Timeline"] = "Timeline";
    ViewType["Chart"] = "Chart";
})(ViewType || (ViewType = {}));
export var QueryType;
(function (QueryType) {
    QueryType["In"] = "In";
    QueryType["NotIn"] = "NotIn";
    QueryType["Eq"] = "Eq";
    QueryType["Neq"] = "Neq";
    QueryType["NotExists"] = "NotExists";
})(QueryType || (QueryType = {}));
export var TriggerType;
(function (TriggerType) {
    TriggerType["Reminds"] = "Reminds";
    TriggerType["MissedDeadline"] = "MissedDeadline";
    TriggerType["Less"] = "Less";
    TriggerType["Greater"] = "Greater";
    TriggerType["Equal"] = "Equal";
    TriggerType["LessEqual"] = "LessEqual";
    TriggerType["GreaterEqual"] = "GreaterEqual";
})(TriggerType || (TriggerType = {}));
export var ReminderUnit;
(function (ReminderUnit) {
    ReminderUnit["hr"] = "hr";
    ReminderUnit["min"] = "min";
    ReminderUnit["days"] = "days";
    ReminderUnit["missed"] = "missed";
})(ReminderUnit || (ReminderUnit = {}));
export var SchemaActionType;
(function (SchemaActionType) {
    SchemaActionType["POSTSAVE"] = "POSTSAVE";
    SchemaActionType["PRESAVE"] = "PRESAVE";
})(SchemaActionType || (SchemaActionType = {}));
export var UpdateType;
(function (UpdateType) {
    UpdateType["Row"] = "Row";
    UpdateType["Cell"] = "Cell";
    UpdateType["RowDelete"] = "RowDelete";
    UpdateType["SchemaUpdate"] = "SchemaUpdate";
    UpdateType["SchemaDelete"] = "SchemaDelete";
    UpdateType["RowRule"] = "RowRule";
    UpdateType["DeleteRowRule"] = "DeleteRowRule";
})(UpdateType || (UpdateType = {}));
export var SchemaItemType;
(function (SchemaItemType) {
    SchemaItemType[SchemaItemType["Text"] = 0] = "Text";
    SchemaItemType[SchemaItemType["Number"] = 1] = "Number";
    SchemaItemType[SchemaItemType["Boolean"] = 2] = "Boolean";
    SchemaItemType[SchemaItemType["Date"] = 3] = "Date";
    SchemaItemType[SchemaItemType["Color"] = 4] = "Color";
    SchemaItemType[SchemaItemType["Image"] = 5] = "Image";
    SchemaItemType[SchemaItemType["Choice"] = 6] = "Choice";
    SchemaItemType[SchemaItemType["DateRange"] = 7] = "DateRange";
    SchemaItemType[SchemaItemType["Currency"] = 8] = "Currency";
    SchemaItemType[SchemaItemType["FormInput"] = 9] = "FormInput";
    SchemaItemType[SchemaItemType["URL"] = 10] = "URL";
    SchemaItemType[SchemaItemType["UserChoice"] = 11] = "UserChoice";
    SchemaItemType[SchemaItemType["Objects"] = 12] = "Objects";
    SchemaItemType[SchemaItemType["List"] = 13] = "List";
    SchemaItemType[SchemaItemType["Remote"] = 14] = "Remote";
    SchemaItemType[SchemaItemType["ID"] = 15] = "ID";
    SchemaItemType[SchemaItemType["SquenceNumber"] = 16] = "SquenceNumber";
    SchemaItemType[SchemaItemType["CustomDisplayCol"] = 17] = "CustomDisplayCol";
})(SchemaItemType || (SchemaItemType = {}));
export var Type;
(function (Type) {
    Type[Type["Text"] = 0] = "Text";
    Type[Type["Number"] = 1] = "Number";
    Type[Type["Boolean"] = 2] = "Boolean";
})(Type || (Type = {}));
export const dataValidator = (data) => {
    if (data.type === Type.Text) {
        // everything is string so text is good here
        return true;
    }
    else if (data.type === Type.Number) {
        const val = data.data.trim();
        if (val === '') {
            return false;
        }
        const fval = parseFloat(val);
        if (isNaN(fval)) {
            return false;
        }
        // this can be true number 
        return true;
    }
    else if (data.type === Type.Boolean) {
        // internally all true boolean is "true"
        // and everything else is false
        const val = data.data.trim();
        if (val === "true") {
            return true;
        }
        else {
            return false;
        }
    }
};
export const extractValue = (data) => {
    if (data.type === Type.Text) {
        return data.data;
    }
    else if (data.type == Type.Number) {
        let ret = undefined;
        if (data.data.indexOf('.') >= 0) {
            ret = parseFloat(data.data);
        }
        else {
            ret = parseInt(data.data);
        }
        if (isNaN(ret)) {
            return undefined;
        }
        return ret;
    }
    else if (data.type === Type.Boolean) {
        if (data.data === 'true') {
            return true;
        }
        return false;
    }
};
export var RuleType;
(function (RuleType) {
    RuleType["Add"] = "Add";
    RuleType["Subtract"] = "Subtract";
    RuleType["Multiply"] = "Multiply";
    RuleType["Divide"] = "Divide";
    RuleType["Power"] = "Power";
    RuleType["Number"] = "Number";
    RuleType["Root"] = "Root";
    RuleType["Condition"] = "Condition";
    RuleType["Insert"] = "Insert";
    RuleType["Upsert"] = "Upsert";
    RuleType["Copy"] = "Copy";
    RuleType["Join"] = "Join";
    RuleType["Append"] = "New Row";
})(RuleType || (RuleType = {}));
export var Comparator;
(function (Comparator) {
    Comparator["Equal"] = "Equal";
    Comparator["NotEqual"] = "NotEqual";
    Comparator["Greater"] = "Greater";
    Comparator["GreaterAndEqual"] = "GreaterAndEqual";
    Comparator["Less"] = "Less";
    Comparator["LessAndEqual"] = "LessAndEqual";
    Comparator["Contains"] = "Contains";
    Comparator["NotContains"] = "NotContains";
})(Comparator || (Comparator = {}));
export var ConditionType;
(function (ConditionType) {
    ConditionType[ConditionType["And"] = 0] = "And";
    ConditionType[ConditionType["Or"] = 1] = "Or";
})(ConditionType || (ConditionType = {}));
export var CustomFilterType;
(function (CustomFilterType) {
    CustomFilterType["Text"] = "Text";
    CustomFilterType["Number"] = "Number";
    CustomFilterType["Choice"] = "Choice";
    CustomFilterType["Date"] = "Date";
    CustomFilterType["DateRange"] = "DateRange";
    CustomFilterType["CheckBox"] = "Checkbox";
})(CustomFilterType || (CustomFilterType = {}));
export var CustomTabDisplayOpt;
(function (CustomTabDisplayOpt) {
    CustomTabDisplayOpt["Tree"] = "Tree";
    CustomTabDisplayOpt["Table"] = "Table";
    CustomTabDisplayOpt["Cards"] = "Cards";
})(CustomTabDisplayOpt || (CustomTabDisplayOpt = {}));
export var JSXType;
(function (JSXType) {
    JSXType["Button"] = "Button";
    JSXType["Choice"] = "Choice";
    JSXType["NumberInput"] = "NumberInput";
    JSXType["Text"] = "Text";
})(JSXType || (JSXType = {}));
;
export var IntegrationType;
(function (IntegrationType) {
    IntegrationType["IndiaMart"] = "IndiaMart";
})(IntegrationType || (IntegrationType = {}));
