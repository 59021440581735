import { comm } from "@/Comm/comm";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { MStore } from "./MessageStore";
import { LStore } from "./LetrStore";
class AllRepliesStore {
    async loadAllReplies(userid, time, after, count) {
        const mfilter = {
            letrid: "",
            time: time,
            count: count,
            after: after,
            exclude: [],
            ascending: false,
            userid: ""
        };
        const messageReplyResponse = await comm.post(ServerPath.allreplies, { filter: mfilter,
            userid: "" });
        const ret = [];
        if (messageReplyResponse !== undefined) {
            for (const res of messageReplyResponse) {
                console.log(res);
                const uletr = res.dto;
                const letr = await LStore.apiDecryptLetr(uletr);
                if (letr != undefined) {
                    const box = InMemoryRepo.getLetrPassword(letr.id);
                    console.log(box + " " + res.pMessage.letrid);
                    const rret = [];
                    const replies = res.latestReplies;
                    if (box != undefined) {
                        const pmsg = MStore.apiConvertServerMessageToBaseMessage(res.pMessage, box);
                        if (pmsg !== undefined) {
                            for (const r of replies) {
                                const rmsg = MStore.apiConvertServerMessageToBaseMessage(r, box);
                                if (rmsg != undefined) {
                                    rret.push(rmsg);
                                }
                            }
                            const mrr = {
                                pMessage: pmsg,
                                latestReplies: rret,
                                letr: letr,
                            };
                            ret.push(mrr);
                        }
                    }
                }
            }
        }
        console.log(ret);
        return ret;
    }
}
export const AStore = new AllRepliesStore();
