export const isPrimitive = (test) => {
    return test !== Object(test);
};
export const Clipboard = (text) => {
    navigator.clipboard.writeText(text);
};
export const randomAlphaNumericString = (length) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};
export const hex2 = (c) => {
    c = Math.round(c);
    if (c < 0)
        c = 0;
    if (c > 255)
        c = 255;
    var s = c.toString(16);
    if (s.length < 2)
        s = "0" + s;
    return s;
};
export const color = (r, g, b) => {
    return "#" + hex2(r) + hex2(g) + hex2(b);
};
export const hexToRGBA = (hex, alpha = "") => {
    var r = parseInt(hex.slice(1, 3), 16), g = parseInt(hex.slice(3, 5), 16), b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }
    else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
};
export const shade = (col, light) => {
    // TODO: Assert that col is good and that -1 < light < 1
    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);
    if (light < 0) {
        r = (1 + light) * r;
        g = (1 + light) * g;
        b = (1 + light) * b;
    }
    else {
        r = (1 - light) * r + light * 255;
        g = (1 - light) * g + light * 255;
        b = (1 - light) * b + light * 255;
    }
    return color(r, g, b);
};
export const removeTags = (str) => {
    if (!str)
        return '';
    else
        return str.replace(/(<([^>]+)>)/ig, '');
};
export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
