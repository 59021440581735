export class Response {
    constructor() {
        this.ok = false;
        this.message = "";
        this.unauth = false;
    }
}
export class UserPasswordBankResponse {
    constructor() {
        this.encryptbox = "";
        this.storage = "";
        this.vault = "";
        this.publickey = "";
        this.deviceStorageKey = "";
    }
}
export class TagsRequest {
    constructor() {
        this.stags = [];
    }
}
export class TagsResponse {
    constructor() {
        this.tags = [];
    }
}
