import { comm } from '@/Comm/comm';
import { UploadStatus } from '@/share/FileRequest';
import { LetrwingCrypto } from '@@/LetrwingCommonCrypto';
import { ServerPath } from '@@/ServerPath';
import { JobPriority, Bookmarkstatus } from '@@/File';
import { JobType } from '@@/ModuleJob';
import { SecStore } from '@/background/store/SecurityStore';
import { LiveStore } from '@/background/store/LiveStore';
import { AuthHeaders as AuthHeader } from '@@/AuthHeader';
import { InMemoryRepo } from '@/background/store/InMemoryDb';
import { EncryptBox } from '@@/EncryptBox';
import streamSaver from 'streamsaver';
import { CONVERTUTIL } from './ConvertUtil';
class FileStore {
    constructor() {
        this.uploadQueue = [];
        this.isUploadQueueRunning = false;
        this.filemetakeys = new Map();
        this.localfilepaths = new Map();
        this.downloadQueue = [];
        this.runningDownloadQueu = false;
    }
    uploadFile(req, pcb) {
        const file = new File([req.file], req.file.name);
        this.uploadQueue.push({ req: req, pcb: pcb });
        this.runUploadQueue();
    }
    runUploadQueue() {
        if (this.isUploadQueueRunning || this.uploadQueue.length === 0) {
            return;
        }
        this.isUploadQueueRunning = true;
        const nextitem = this.uploadQueue.splice(0, 1);
        const pcb = nextitem[0].pcb;
        const req = nextitem[0].req;
        this.uploadItem(req, pcb, async (id, secretkey, box) => {
            this.isUploadQueueRunning = false;
            this.runUploadQueue();
            if (id && id !== undefined && !nextitem[0].req.isimage && nextitem[0]) {
                await this.addUploadedFileToJobQueue(id, secretkey !== null && secretkey !== void 0 ? secretkey : "", nextitem[0].req.file.size, nextitem[0].req.file.name, "", nextitem[0].req.letrid, nextitem[0].req.ocrdocument, nextitem[0].req.ocrlang, nextitem[0].req.addwatermark);
                pcb({
                    id: req.id, status: UploadStatus.Done, uploaded: 100, fileid: id,
                    accesskey: secretkey !== null && secretkey !== void 0 ? secretkey : '', databoxid: box !== null && box !== void 0 ? box : ""
                });
            }
        });
    }
    async addBookmark(bookmark, key, fileid) {
        const fkey = await this.getFileDataKey(fileid, key);
        if (fkey === undefined || !fkey) {
            return undefined;
        }
        const box = {
            id: '',
            fileid: fileid,
            status: Bookmarkstatus.Live,
            bookmark: LetrwingCrypto.secretBox(JSON.stringify(bookmark), fkey)
        };
        const res = await comm.post(ServerPath.addBookmark, box);
        if (res) {
            // lets do something fun here now!!
            const rbook = LetrwingCrypto.decryptSecretBox(res.bookmark, fkey);
            if (rbook) {
                try {
                    const resultbook = JSON.parse(rbook);
                    resultbook.id = res.id;
                    return resultbook;
                }
                catch (Ex) {
                }
            }
        }
        return undefined;
    }
    async getBookmark(fileid, mkey) {
        const key = await this.getFileDataKey(fileid, mkey);
        if (key === undefined || !key) {
            return undefined;
        }
        const req = {
            id: '',
            fileid: fileid,
            status: Bookmarkstatus.Live,
            bookmark: new EncryptBox()
        };
        const ret = [];
        const result = await this.getPdfViewerPage({
            id: fileid,
            pagename: 'bookmarks',
            key: mkey,
        });
        if (result) {
            try {
                const bms = JSON.parse(result);
                ret.push(...bms);
            }
            catch (ex) {
            }
        }
        const res = await comm.post(ServerPath.getBookmark, req);
        if (res) {
            for (const bb of res) {
                if (bb.status === Bookmarkstatus.Dead) {
                    continue;
                }
                const rbook = LetrwingCrypto.decryptSecretBox(bb.bookmark, key);
                if (rbook) {
                    try {
                        const resultbook = JSON.parse(rbook);
                        resultbook.id = bb.id;
                        resultbook.custom = true;
                        ret.push(resultbook);
                    }
                    catch (Ex) {
                    }
                }
            }
        }
        return ret;
    }
    async deleteBookmark(id, fileid) {
        const req = {
            id: id,
            fileid: fileid,
            status: Bookmarkstatus.Dead,
            bookmark: new EncryptBox()
        };
        const res = comm.post(ServerPath.deleteBookmark, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async getActiveFilesSince(req) {
        const res = await comm.post(ServerPath.getActiveFilesSince, req);
        const resp = [];
        if (res && res.length > 0) {
            const objs = res[0].objects;
            const objectbyid = new Map();
            for (const obj of objs) {
                objectbyid.set(obj.id, obj);
            }
            for (const pi of res) {
                const obkectid = pi.objectid;
                const obj = objectbyid.get(obkectid);
                const lid = InMemoryRepo.getLetridFromOrigId(pi.letrid);
                if (!lid)
                    continue;
                const box = InMemoryRepo.getLetrPassword(lid);
                if (!box)
                    continue;
                if (obj) {
                    const ney = CONVERTUTIL.convertProjecObjectToProjectEntity([obj], box, lid, false);
                    if (ney && ney.length > 0) {
                        pi.message = ney[0].name;
                        pi.itemurl = pi.letrid + "~~Doc~~" + pi.objectid;
                        pi.letrid = lid;
                        resp.push(pi);
                    }
                    else {
                        continue;
                    }
                }
            }
        }
        return resp;
    }
    async addUploadedFileToJobQueue(id, secretkey, size, name, oldrefid = "", letrid = "", ocr = true, ocrlang = "en", addwatermark = false) {
        var _a, _b, _c, _d;
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        const oletrid = InMemoryRepo.getLetrOid(letrid);
        const filejob = {
            fileid: id,
            key: secretkey,
            filesize: size,
            oldrefid: oldrefid,
            fileext: (_a = name.split('.').pop()) !== null && _a !== void 0 ? _a : '',
            searchkey: (_b = sbox === null || sbox === void 0 ? void 0 : sbox.key) !== null && _b !== void 0 ? _b : '',
            searchnonce: (_c = sbox === null || sbox === void 0 ? void 0 : sbox.nonce) !== null && _c !== void 0 ? _c : '',
            searchrandomdata: (_d = sbox === null || sbox === void 0 ? void 0 : sbox.randomdata) !== null && _d !== void 0 ? _d : '',
            letrid: oletrid !== null && oletrid !== void 0 ? oletrid : '',
            addwatermark: addwatermark,
            ocrdocument: ocr,
            ocrlang: ocrlang
        };
        const job = {
            jobtype: JobType.Document,
            data: LetrwingCrypto.getBase64FromUT8(JSON.stringify(filejob))
        };
        const fmeta = await this.getFileMetadata(id);
        let orgname = "";
        let res = undefined;
        if (fmeta && fmeta.orgid) {
            const req = {
                orgname: fmeta.orgid
            };
            res = await comm.post(ServerPath.getorgmodpubkey, req);
        }
        else {
            res = await comm.get(ServerPath.getmodpubkey);
        }
        if (res !== undefined) {
            const req = [];
            for (const re of res) {
                const box = LetrwingCrypto.box(JSON.stringify(job), re.publickey);
                if (box !== null) {
                    req.push({
                        data: box,
                        lrvmdata: re,
                        reference: id,
                        priority: JobPriority.Low
                    });
                }
            }
            if (req.length > 0) {
                const mid = await comm.post(ServerPath.addnewjob, req);
            }
        }
    }
    async uploadBlob(blob, letrid, name, oldrefid = "") {
        const size = blob.byteLength;
        const secretkey = LetrwingCrypto.generateNewSecretKey();
        const accesskey = LetrwingCrypto.generateNewSecretKey();
        const spbox = LetrwingCrypto.secretBox(secretkey, accesskey);
        const box = await SecStore.storeShareBox(spbox);
        if (box === undefined) {
            return undefined;
        }
        const meta = {
            id: "",
            letrid: letrid,
            isDownloadable: false,
            isMedia: false,
            creatorid: "",
            orgid: "",
            totalchunks: 0,
            boxid: box,
            size: size,
            hasviewer: false,
            totalfilechunks: 1,
            trial: 0,
            trialutime: 0
        };
        const mres = await comm.post(ServerPath.addFileHeader, meta);
        if (mres === undefined) {
            return undefined;
        }
        const chunk = {
            size: blob.byteLength,
            index: 0,
            data: LetrwingCrypto.secretBoxRaw(blob, secretkey),
            reference: mres !== null && mres !== void 0 ? mres : "",
        };
        const res = await comm.post(ServerPath.addFileChunk, chunk);
        if (res === undefined || !res) {
            return undefined;
        }
        await this.addUploadedFileToJobQueue(mres, accesskey, blob.byteLength, name);
        return [mres, accesskey];
    }
    async uploadItem(req, pcb, doneCb) {
        const size = req.file.size;
        const secretkey = LetrwingCrypto.generateNewSecretKey();
        const accesskey = LetrwingCrypto.generateNewSecretKey();
        const spbox = LetrwingCrypto.secretBox(secretkey, accesskey);
        const box = await SecStore.storeShareBox(spbox);
        if (box === undefined) {
            pcb({ id: req.id, status: UploadStatus.Failed, uploaded: 0, fileid: "", accesskey: "", databoxid: "" });
            doneCb();
            return;
        }
        const runningitems = [];
        const tchunks = Math.ceil(size / (1 * 1024 * 1024));
        const meta = {
            id: "",
            letrid: req.letrid,
            isDownloadable: req.isDownloadable,
            isMedia: false,
            creatorid: "",
            orgid: "",
            totalchunks: tchunks,
            boxid: box,
            size: size,
            hasviewer: false,
            totalfilechunks: tchunks,
            trial: 0,
            trialutime: 0
        };
        const mres = await comm.post(ServerPath.addFileHeader, meta);
        if (mres === undefined) {
            pcb({ id: req.id, status: UploadStatus.Failed, uploaded: 0, fileid: "", accesskey: "", databoxid: "" });
            doneCb();
            return;
        }
        pcb({ id: req.id, status: UploadStatus.Uploading, uploaded: 5, fileid: "", accesskey: "", databoxid: "" });
        let nextsize = 0;
        let uploadedsize = 0;
        const chunksize = 1 * 1024 * 1024; // 1 MB chunks
        let index = 0;
        let localtimeout;
        let activeid = 0;
        let declined = false;
        const onload = async (ev) => {
            if (ev.target === null || ev.target.error) {
                pcb({ id: req.id, status: UploadStatus.Failed, uploaded: 0, fileid: "", accesskey: "", databoxid: "" });
                doneCb();
                return;
            }
            const ar = ev.target.result;
            if (ar !== null && ar instanceof ArrayBuffer) {
                const ubuffer = new Uint8Array(ar);
                const chunk = {
                    size: nextsize,
                    index: index,
                    data: LetrwingCrypto.secretBoxRaw(ubuffer, secretkey),
                    reference: mres !== null && mres !== void 0 ? mres : "",
                };
                // now we can also upload hi via websocket 
                const newid = mres !== null && mres !== void 0 ? mres : "";
                const code = Math.random();
                activeid = code;
                const res = await comm.post(ServerPath.addFileChunk, chunk);
                uploaded(res);
            }
        };
        // make this fater!
        function readFileChunk() {
            const r = new FileReader();
            if (uploadedsize < size) {
                const csize = uploadedsize + chunksize;
                nextsize = csize < size ? chunksize : size - uploadedsize;
                const buffer = req.file.slice(uploadedsize, uploadedsize + nextsize);
                r.onload = (e) => onload(e);
                r.readAsArrayBuffer(buffer);
            }
            else {
                doneCb(mres, accesskey, box);
            }
        }
        function uploaded(ok) {
            if (localtimeout !== undefined) {
                clearTimeout(localtimeout);
            }
            activeid = Math.random();
            if (declined) {
                return;
            }
            if (ok === undefined || !ok) {
                pcb({ id: req.id, status: UploadStatus.Failed, uploaded: 0, fileid: "", accesskey: "", databoxid: "" });
                doneCb();
                return;
            }
            uploadedsize += nextsize;
            let usize = Math.floor((uploadedsize / size) * 100);
            if (usize < 5) {
                usize = 5;
            }
            pcb({
                id: req.id, status: UploadStatus.Uploading, uploaded: usize, fileid: "",
                accesskey: "", databoxid: ""
            });
            index++;
            readFileChunk();
        }
        readFileChunk();
    }
    async getFileMetadata(id, accesskey = "") {
        var _a;
        const header = [];
        header.push({ key: AuthHeader.FileID, value: id });
        const res = await comm.get(ServerPath.getFileInfo, header);
        if (res !== undefined &&
            res.creatorid === InMemoryRepo.getuserId() && res.orgid === InMemoryRepo.getTenant()) {
            res.isDownloadable = true;
        }
        if (accesskey && res !== undefined && res.box !== undefined && res.ingeststatus !== undefined) {
            const key = this.getFileKey(res.box, accesskey);
            if (key !== undefined) {
                const undata = LetrwingCrypto.decryptSecretBox(res.ingeststatus, key);
                this.filemetakeys.set(id, key);
                if (undata !== undefined) {
                    try {
                        const indata = JSON.parse(undata);
                        res.ingestunboxed = indata;
                        res.ingestunboxed.hasviewer = res.hasviewer;
                    }
                    catch (ex) {
                    }
                }
            }
        }
        if (res && (res === null || res === void 0 ? void 0 : res.letrmark)) {
            const mletrid = InMemoryRepo.getLetridFromOrigId((_a = res === null || res === void 0 ? void 0 : res.letrid) !== null && _a !== void 0 ? _a : '');
            if (mletrid) {
                const letrbox = InMemoryRepo.getLetrPassword(mletrid);
                if (letrbox) {
                    try {
                        const undata = LetrwingCrypto.decryptSecretBox(res.letrmark.watermark, letrbox);
                        if (undata && undata.trim()) {
                            res.watermarkstr = {
                                watermark: undata
                            };
                        }
                    }
                    catch (ex) { }
                }
            }
        }
        return res;
    }
    async getPdfViewerContent(id, contenname) {
        const header = [];
        header.push({ key: AuthHeader.FileID, value: id });
        const req = { name: contenname };
        const res = await comm.post(ServerPath.getFileViewerContent, req, header);
        return res;
    }
    async getPdfViewerPage(req) {
        const key = await this.getFileDataKey(req.id, req.key);
        if (key === undefined || !key) {
            return undefined;
        }
        const pebox = await this.getPdfViewerContent(req.id, req.pagename);
        if (pebox === undefined || pebox.box === undefined) {
            return undefined;
        }
        // page raw bytes.. 
        try {
            let str = LetrwingCrypto.decryptSecretBox(pebox.box, key);
            if (str === undefined) {
                return undefined;
            }
            return str;
        }
        catch (ex) {
            console.log(ex);
        }
        return undefined;
    }
    async getFileDataKey(id, accesskey) {
        var _a;
        if (this.filemetakeys.has(id)) {
            return (_a = this.filemetakeys.get(id)) !== null && _a !== void 0 ? _a : "";
        }
        const meta = await this.getFileMetadata(id);
        if (meta === undefined || meta.box === undefined) {
            return; // return no status for this is broken file..
        }
        const key = this.getFileKey(meta.box, accesskey);
        if (key === undefined) {
            return undefined; //invalid st
        }
        this.filemetakeys.set(id, key);
        return key;
    }
    getFileKey(box, key) {
        return LetrwingCrypto.decryptSecretBox(box, key);
    }
    // file id
    async getFileStatus(req) {
        const key = await this.getFileDataKey(req.id, req.acceskey);
        if (key === undefined || !key) {
            return undefined;
        }
        const header = [];
        header.push({ key: AuthHeader.FileID, value: req.id });
        const status = await comm.get(ServerPath.getFileProcessStatus, header);
        // now we need to decrypt these 
        if (status !== undefined) {
            const statusstr = LetrwingCrypto.decryptSecretBox(status, key);
            if (statusstr === undefined) {
                return undefined; // again key not valid broken stuff
            }
            try {
                const meta = JSON.parse(statusstr);
                return meta;
            }
            catch (ex) { }
        }
        return undefined;
    }
    async downloadBase64(base64, name) {
        const bytes = LetrwingCrypto.base64ToBin(base64);
        const fileStream = streamSaver.createWriteStream(name, {
            size: bytes.length,
            writableStrategy: undefined,
            readableStrategy: undefined // (optional)
        });
        const writer = fileStream.getWriter();
        await writer.write(bytes);
        await writer.close();
    }
    async downloadFile(req, progressCb) {
        this.downloadQueue.push({
            req: req,
            progressCb: progressCb
        });
        progressCb({
            id: req.id,
            letrid: req.letrid,
            downloadfailed: false,
            requesttype: req.requesttype,
            inqueue: true
        });
        this.run_download_queue();
    }
    async run_download_queue() {
        if (this.runningDownloadQueu) {
            return;
        }
        this.runningDownloadQueu = true;
        while (this.downloadQueue.length > 0) {
            const item = this.downloadQueue.splice(0, 1)[0];
            await this.downloadItem(item.req, item.progressCb);
        }
        this.runningDownloadQueu = false;
    }
    async downloadItem(req, progressCb) {
        var _a, _b, _c;
        const lpath = this.localfilepaths.get(req.id);
        if (!req.savepath && lpath !== undefined) {
            const ret = {
                id: req.id,
                letrid: req.letrid,
                downloadfailed: false,
                requesttype: req.requesttype,
                downloadpath: lpath
            };
            progressCb(ret);
            return;
        }
        const meta = await this.getFileMetadata(req.id);
        if (meta === undefined || meta.box === undefined ||
            (meta.totalchunks === 0)) {
            const ret = {
                id: req.id,
                letrid: req.letrid,
                downloadfailed: true,
                requesttype: req.requesttype
            };
            progressCb(ret);
            return ret;
        }
        const key = LetrwingCrypto.decryptSecretBox(meta.box, (_a = req.accesskey) !== null && _a !== void 0 ? _a : '');
        if (key === undefined) {
            const ret = {
                id: req.id,
                letrid: req.letrid,
                downloadfailed: true,
                requesttype: req.requesttype
            };
            progressCb(ret);
            return;
        }
        this.filemetakeys.set(req.id, key);
        let index = 0;
        let totalchunks = meta.totalchunks;
        let filepath = new Uint8Array(req.downloadasset ? 0 : req.size);
        let totaldownloaded = 0;
        const fileStream = streamSaver.createWriteStream((_c = (_b = req.info) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "", {
            size: req.size,
            writableStrategy: undefined,
            readableStrategy: undefined // (optional)
        });
        const writer = fileStream.getWriter();
        function downloadViaWebSocket() {
            var _a;
            const req = {
                startchunk: index,
                endchunk: getEndChunkIndex()
            };
            LiveStore.downloadChunk((_a = meta === null || meta === void 0 ? void 0 : meta.id) !== null && _a !== void 0 ? _a : '', req, (chs) => {
                if (chs === 'SocketDisconnect') {
                    downloadViaHttp();
                    return;
                }
                gotChunks(chs);
            });
        }
        function getEndChunkIndex() {
            if (index + 5 > totalchunks) {
                return totalchunks;
            }
            return index + 5;
        }
        async function downloadViaHttp() {
            var _a;
            const req = {
                startchunk: index,
                endchunk: getEndChunkIndex()
            };
            const header = [];
            header.push({ key: AuthHeader.FileID, value: (_a = meta === null || meta === void 0 ? void 0 : meta.id) !== null && _a !== void 0 ? _a : "" });
            const chunks = await comm.post(ServerPath.getFileChunks, req, header);
            gotChunks(chunks);
        }
        async function gotChunks(chunks) {
            if (chunks === undefined || chunks.length === 0) {
                const ret = {
                    id: req.id,
                    letrid: req.letrid,
                    downloadfailed: true,
                    requesttype: req.requesttype
                };
                progressCb(ret);
                return;
            }
            chunks.sort((a, b) => a.index - b.index);
            for (const chunk of chunks) {
                const buffer = LetrwingCrypto.decryptSecretBoxRaw(chunk.data, key !== null && key !== void 0 ? key : '');
                if (buffer === undefined) {
                    filepath = new Uint8Array();
                    const ret = {
                        id: req.id,
                        letrid: req.letrid,
                        downloadfailed: true,
                        requesttype: req.requesttype,
                        reason: "Unauthorise access"
                    };
                    progressCb(ret);
                    return;
                }
                if (req.downloadasset) {
                    try {
                        await writer.write(buffer);
                    }
                    catch (ex) {
                        console.log(ex);
                        filepath = new Uint8Array();
                        const ret = {
                            id: req.id,
                            letrid: req.letrid,
                            downloadfailed: true,
                            requesttype: req.requesttype,
                            reason: "Unauthorise access"
                        };
                        progressCb(ret);
                        return;
                    }
                }
                else {
                    filepath.set(buffer, totaldownloaded);
                }
                totaldownloaded += buffer.length;
            }
            index += chunks.length;
            const progress = Math.floor((index / totalchunks) * 100);
            const ret = {
                id: req.id,
                letrid: req.letrid,
                downloadfailed: false,
                requesttype: req.requesttype,
                progress: progress
            };
            progressCb(ret);
            if (index < totalchunks) {
                download();
            }
            else {
                const fdata = (req.downloadasset ? "" : LetrwingCrypto.getBase64(filepath));
                const ret = {
                    id: req.id,
                    letrid: req.letrid,
                    downloadfailed: false,
                    requesttype: req.requesttype,
                    downloadpath: fdata
                };
                if (req.downloadasset) {
                    await writer.close();
                }
                else {
                    FStore.addPath(req.id, fdata);
                }
                progressCb(ret);
                return;
            }
        }
        function download() {
            if (false && LiveStore.isAlive()) {
                downloadViaWebSocket();
            }
            else {
                downloadViaHttp();
            }
        }
        download();
    }
    addPath(id, path) {
        this.localfilepaths.set(id, path);
    }
    async deleteFile(id) {
    }
}
export const FStore = new FileStore();
