import { comm } from "@/Comm/comm";
import { BEvent } from "@/events/BackgroundEvents";
import { AnnotationType, NoteStatus, ALLViewer, NoteType } from "@@/Annotation";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { MessageDeliveryStatus, MessageType } from "@@/Message";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { MStore } from "./MessageStore";
import { ProjectStore } from "./ProjectStore";
import { SchemaStore } from "./SchemaStore";
class Store {
    async getNotes(letrid, objectid, refid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const res = await comm.post(ServerPath.getnotes, {
            letrid: letrid,
            objectid: objectid, refid: refid
        });
        if (res !== undefined) {
            const ret = [];
            res.forEach((ns) => {
                const no = this.convertServerObjectToNote(ns, letrid, objectid, box);
                if (no !== undefined) {
                    ret.push(no);
                }
            });
            const pmap = new Map();
            const dmap = new Map();
            if (res.length > 0 && res[0].linkedobjects && res[0].linkedobjects.length > 0) {
                const po = ProjectStore.convertProjecObjectToProjectEntity(res[0].linkedobjects, box, letrid);
                for (const pobj of po) {
                    pmap.set(pobj.id, pobj);
                }
            }
            const datasheet = new Map();
            const schema = new Map();
            const rows = new Map();
            if (res.length > 0 && res[0].datasheetboxes && res[0].datasheetboxes.length > 0) {
                for (const boxes of res[0].datasheetboxes) {
                    if (boxes.entries.length === 0)
                        continue;
                    const rowid = boxes.entries[0].rowid;
                    if (!datasheet.has(boxes.datasheetbox.id)) {
                        const dbox = SchemaStore.convertBoxToSheet(boxes.datasheetbox, letrid, box);
                        if (dbox !== undefined) {
                            datasheet.set(boxes.datasheetbox.id, dbox);
                        }
                    }
                    if (!schema.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id)) {
                        const sch = SchemaStore.convertBoxToSchema(boxes.schemabox, letrid, box);
                        if (sch) {
                            schema.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id, sch);
                        }
                    }
                    if (!rows.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid)) {
                        rows.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid, boxes.entries);
                    }
                }
            }
            if (pmap.size > 0 || rows.size > 0) {
                for (const pn of ret) {
                    if (pn.links && pn.links.length > 0) {
                        const boxes = [];
                        for (const ln of pn.links) {
                            const lparts = ln.split("---");
                            if (lparts[0].startsWith("datasheet")) {
                                const dlparts = lparts[0].split("-");
                                const did = dlparts[1];
                                const sid = dlparts[2];
                                const rid = dlparts[3];
                                // only if we have the rows
                                const entries = rows.get(did + "-" + sid + "-" + rid);
                                const sh = schema.get(did + '-' + sid);
                                const dh = datasheet.get(did);
                                if (sh && dh && entries) {
                                    // lets do something here now!!
                                    const dbox = {
                                        datasheet: dh,
                                        schema: sh,
                                        row: entries
                                    };
                                    boxes.push({
                                        objectid: "datasheet-" + did + "-" + sid + "-" + rid,
                                        name: "",
                                        fileid: sid,
                                        noteid: lparts[1],
                                        datasheetrow: dbox
                                    });
                                }
                                // this is datasheet reference we should just do this according to that!
                                continue;
                            }
                            const pobj = pmap.get(lparts[0]);
                            if (pobj && lparts.length > 2 && pobj.documentreference === lparts[2]) {
                                boxes.push({
                                    objectid: lparts[0],
                                    name: pobj.name,
                                    fileid: lparts.length > 2 ? lparts[2] : '',
                                    noteid: lparts[1],
                                    projectobject: pobj
                                });
                            }
                        }
                        pn.linkboxes = boxes;
                    }
                }
            }
            console.log(ret);
            return ret;
        }
        return undefined;
    }
    parseComments(comms, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        return comms.map((res) => (Object.assign(Object.assign({}, res), { datastr: LetrwingCrypto.decryptSecretBox(res.data, box) })));
    }
    async updates(notes, letrid, objectid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        // now lets go through them and we need to publish them that's all!!
        const ret = [];
        notes.forEach((obj) => {
            var _a;
            if (obj.status === NoteStatus.Dead) {
                ret.push({
                    id: obj.id,
                    data: {
                        detail: [],
                        match: "",
                        boxes: [],
                        notetype: AnnotationType.Text,
                        color: "",
                        storekthickness: 0
                    },
                    parentid: obj.parentid,
                    viewer: obj.viewers,
                    authorid: obj.authorid,
                    authorname: (_a = obj.authorname) !== null && _a !== void 0 ? _a : '',
                    replycount: obj.replycount,
                    type: obj.ntype,
                    status: NoteStatus.Dead,
                    updatetime: obj.modifytime,
                    aclstr: obj.aclstr,
                    notereference: obj.notereference
                });
            }
            else {
                const n = this.convertServerObjectToNote(obj, letrid, objectid, box);
                if (n) {
                    ret.push(n);
                }
            }
        });
        const pmap = new Map();
        const dmap = new Map();
        if (notes.length > 0 && notes[0].linkedobjects && notes[0].linkedobjects.length > 0) {
            const po = ProjectStore.convertProjecObjectToProjectEntity(notes[0].linkedobjects, box, letrid);
            for (const pobj of po) {
                pmap.set(pobj.id, pobj);
            }
        }
        const datasheet = new Map();
        const schema = new Map();
        const rows = new Map();
        if (notes.length > 0 && notes[0].datasheetboxes && notes[0].datasheetboxes.length > 0) {
            for (const boxes of notes[0].datasheetboxes) {
                const rowid = boxes.entries[0].rowid;
                if (!datasheet.has(boxes.datasheetbox.id)) {
                    const dbox = SchemaStore.convertBoxToSheet(boxes.datasheetbox, letrid, box);
                    if (dbox !== undefined) {
                        datasheet.set(boxes.datasheetbox.id, dbox);
                    }
                }
                if (!schema.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id)) {
                    const sch = SchemaStore.convertBoxToSchema(boxes.schemabox, letrid, box);
                    if (sch) {
                        schema.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id, sch);
                    }
                }
                if (!rows.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid)) {
                    rows.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid, boxes.entries);
                }
            }
        }
        if (pmap.size > 0 || dmap.size > 0) {
            for (const pn of ret) {
                if (pn.links && pn.links.length > 0) {
                    const boxes = [];
                    for (const ln of pn.links) {
                        const lparts = ln.split("---");
                        if (lparts[0].startsWith("datasheet")) {
                            const dlparts = lparts[0].split("-");
                            const did = dlparts[1];
                            const sid = dlparts[2];
                            const rid = dlparts[3];
                            // only if we have the rows
                            const entries = rows.get(did + "-" + sid + "-" + rid);
                            const sh = schema.get(did + '-' + sid);
                            const dh = datasheet.get(did);
                            if (sh && dh && entries) {
                                // lets do something here now!!
                                const dbox = {
                                    datasheet: dh,
                                    schema: sh,
                                    row: entries
                                };
                                boxes.push({
                                    objectid: "datasheet-" + did + "-" + sid + "-" + rid,
                                    name: "",
                                    fileid: sid,
                                    noteid: lparts[1],
                                    datasheetrow: dbox
                                });
                            }
                            // this is datasheet reference we should just do this according to that!
                            continue;
                        }
                        const pobj = pmap.get(lparts[0]);
                        if (pobj && lparts.length > 2 && pobj.documentreference === lparts[2]) {
                            boxes.push({
                                objectid: lparts[0],
                                name: pobj.name,
                                fileid: lparts.length > 2 ? lparts[2] : '',
                                noteid: lparts[1],
                                projectobject: pobj
                            });
                        }
                    }
                    pn.linkboxes = boxes;
                }
            }
        }
        if (ret.length > 0) {
            BEvent.publishrNotesUpdate({
                letrid: letrid,
                objectid: objectid,
                notes: ret
            });
        }
    }
    async getComments(noteid, letrid, objectid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            noteid: noteid,
            objectid: objectid
        };
        const res = await comm.post(ServerPath.getcomments, req);
        if (res === undefined) {
            return [];
        }
        return res.map((res) => (Object.assign(Object.assign({}, res), { datastr: LetrwingCrypto.decryptSecretBox(res.data, box) })));
    }
    async addComment(comment, letrid, objectid, noteid) {
        if (noteid.startsWith("datasheet-")) {
            const note = await this.savenote({
                id: noteid,
                viewer: [ALLViewer],
                parentid: "",
                type: NoteType.Note,
                authorid: "",
                authorname: "",
                replycount: 0,
                updatetime: 0,
                status: NoteStatus.Live,
                aclstr: "everyone",
                data: {
                    detail: [],
                    match: "",
                    notetype: AnnotationType.Text,
                    boxes: [],
                    color: '',
                    storekthickness: 0
                }
            }, letrid, objectid);
        }
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            noteid: noteid,
            letrid: letrid,
            data: LetrwingCrypto.secretBox(comment, box)
        };
        const res = await comm.post(ServerPath.savereply, req);
        if (res === undefined) {
            return undefined;
        }
        const str = LetrwingCrypto.decryptSecretBox(res.data, box);
        const commn = Object.assign(Object.assign({}, res), { datastr: str });
        return commn;
    }
    async updateComment(letrid, objectid, commentid, comment) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            noteid: commentid,
            letrid: letrid,
            data: LetrwingCrypto.secretBox(comment, box)
        };
        const res = await comm.post(ServerPath.updateComment, req);
        if (res === undefined) {
            return undefined;
        }
        const str = LetrwingCrypto.decryptSecretBox(res.data, box);
        const commn = Object.assign(Object.assign({}, res), { datastr: str });
        return commn;
    }
    async updateNote(note, letrid, objectid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const snote = this.convertNoteToServerObject(note, letrid, objectid, box);
        const res = await comm.post(ServerPath.updatenotes, snote);
        if (res !== undefined) {
            await MStore.updateMessageAcl("system-note-" + note.id, letrid, note.viewer);
            return this.convertServerObjectToNote(res, letrid, objectid, box);
        }
        return undefined;
    }
    async deleteComment(req) {
        const res = await comm.post(ServerPath.deleteComment, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async deleteNote(req) {
        const res = await comm.post(ServerPath.deleteNote, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async savenote(note, letrid, objectid, comments = []) {
        var _a, _b, _c;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const links = [];
        if (note.linkboxes) {
            for (const link of note.linkboxes) {
                if (!link.note) {
                    continue;
                }
                link.note.type = NoteType.NoteLink;
                link.note.viewer = note.viewer;
                link.note.authorid = note.authorid;
                // lets store these first
                const ln = this.convertNoteToServerObject(link.note, letrid, link.objectid, box, link.initiatorid);
                const res = await comm.post(ServerPath.savenote, ln);
                if (res !== undefined) {
                    links.push(link.objectid + "---" + res.id + "---" + res.notereference);
                }
            }
        }
        note.linkboxes = [];
        // lets now do something clever here 
        // we should be able to do this nicely
        const snote = this.convertNoteToServerObject(note, letrid, objectid, box);
        snote.links = links;
        const res = await comm.post(ServerPath.savenote, snote);
        if (res !== undefined) {
            const ret = this.convertServerObjectToNote(res, letrid, objectid, box);
            if (ret !== undefined) {
                const pmsg = {
                    objectid: res.objectid,
                    objecttype: (_a = ret === null || ret === void 0 ? void 0 : ret.data.notetype) !== null && _a !== void 0 ? _a : AnnotationType.Freehand,
                    text: "New note added",
                    letrid: letrid !== null && letrid !== void 0 ? letrid : "",
                    _id: "system-note-" + res.id,
                    createdAt: new Date().getTime(),
                    type: MessageType.Note,
                    replycount: 0,
                    user: { _id: "", name: "" },
                    status: MessageDeliveryStatus.Activating,
                    color: ret.data.color
                };
                if (comments.length > 0) {
                    for (const com of comments) {
                        await this.addComment((_b = com.datastr) !== null && _b !== void 0 ? _b : "", letrid, objectid, ret.id);
                    }
                }
                // now lets add this message first
                await MStore.saveMessage(pmsg, res.objectid, (_c = res.aclstr) !== null && _c !== void 0 ? _c : "");
                const pmap = new Map();
                const dmap = new Map();
                if (res.linkedobjects && res.linkedobjects.length > 0) {
                    const po = ProjectStore.convertProjecObjectToProjectEntity(res.linkedobjects, box, letrid);
                    for (const pobj of po) {
                        pmap.set(pobj.id, pobj);
                    }
                }
                const datasheet = new Map();
                const schema = new Map();
                const rows = new Map();
                if (res.datasheetboxes && res.datasheetboxes.length > 0) {
                    for (const boxes of res.datasheetboxes) {
                        const rowid = boxes.entries[0].rowid;
                        if (!datasheet.has(boxes.datasheetbox.id)) {
                            const dbox = SchemaStore.convertBoxToSheet(boxes.datasheetbox, letrid, box);
                            if (dbox !== undefined) {
                                datasheet.set(boxes.datasheetbox.id, dbox);
                            }
                        }
                        if (!schema.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id)) {
                            const sch = SchemaStore.convertBoxToSchema(boxes.schemabox, letrid, box);
                            if (sch) {
                                schema.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id, sch);
                            }
                        }
                        if (!rows.has(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid)) {
                            rows.set(boxes.datasheetbox.id + '-' + boxes.schemabox.id + "-" + rowid, boxes.entries);
                        }
                    }
                }
                if (pmap.size > 0 || rows.size > 0) {
                    if (ret) {
                        const pn = ret;
                        if (pn.links && pn.links.length > 0) {
                            const boxes = [];
                            for (const ln of pn.links) {
                                const lparts = ln.split("---");
                                if (lparts[0].startsWith("datasheet")) {
                                    const dlparts = lparts[0].split("-");
                                    const did = dlparts[1];
                                    const sid = dlparts[2];
                                    const rid = dlparts[3];
                                    // only if we have the rows
                                    const entries = rows.get(did + "-" + sid + "-" + rid);
                                    const sh = schema.get(did + '-' + sid);
                                    const dh = datasheet.get(did);
                                    if (sh && dh && entries) {
                                        // lets do something here now!!
                                        const dbox = {
                                            datasheet: dh,
                                            schema: sh,
                                            row: entries
                                        };
                                        boxes.push({
                                            objectid: "datasheet-" + did + "-" + sid + "-" + rid,
                                            name: "",
                                            fileid: sid,
                                            noteid: lparts[1],
                                            datasheetrow: dbox
                                        });
                                    }
                                    // this is datasheet reference we should just do this according to that!
                                    continue;
                                }
                                const pobj = pmap.get(lparts[0]);
                                if (pobj) {
                                    boxes.push({
                                        objectid: lparts[0],
                                        name: pobj.name,
                                        fileid: lparts.length > 2 ? lparts[2] : '',
                                        noteid: lparts[1],
                                        projectobject: pobj
                                    });
                                }
                            }
                            pn.linkboxes = boxes;
                        }
                    }
                }
            }
            return ret;
        }
        return undefined;
    }
    convertServerObjectToNote(obj, letrid, objectid, box) {
        var _a;
        const ebox = obj.data;
        const estr = LetrwingCrypto.decryptSecretBox(ebox, box);
        if (!estr) {
            return undefined;
        }
        try {
            const detail = JSON.parse(estr);
            const note = {
                id: obj.id,
                data: detail,
                parentid: obj.parentid,
                viewer: obj.viewers,
                authorid: obj.authorid,
                authorname: (_a = obj.authorname) !== null && _a !== void 0 ? _a : '',
                replycount: obj.replycount,
                type: obj.ntype,
                status: obj.status,
                updatetime: obj.modifytime,
                aclstr: obj.aclstr,
                notereference: obj.notereference,
                links: obj.links
            };
            return note;
        }
        catch (ex) {
        }
        return undefined;
    }
    convertNoteToServerObject(obj, letrid, objectid, box, initiatorid = "") {
        const sbox = LetrwingCrypto.secretBox(JSON.stringify(obj.data), box);
        const snote = {
            id: obj.id,
            letrid: letrid,
            objectid: objectid,
            data: sbox,
            viewers: obj.viewer,
            authorid: "",
            status: NoteStatus.Live,
            ntype: obj.type,
            parentid: obj.parentid,
            replycount: 0,
            modifytime: 0,
            aclstr: obj.aclstr,
            notereference: obj.notereference,
            linkedobjects: [],
            initiatorid: initiatorid
        };
        return snote;
    }
}
export const NoteStore = new Store();
