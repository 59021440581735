var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
import { ALLViewer, AnnotationType } from "@@/Annotation";
import { ColorStore } from "@/app/UIComponents/ColorDialog";
import { CommentState } from "../UIComponents/Comments";
import { FindDict } from "./FindMatchTerm";
import { ViewerFindState } from "./ViewerFindState";
import { SelectMemberDialogState } from "../UIComponents/SelectMemberDialog";
import { NoteUI } from "./NoteUI";
import { NotesFilterState } from "./NotesFilterState";
import { isIpadScreen, testIsMobile } from "@@/Util";
import { PdfBookmarkState } from "./PdfBookmarkState";
import { NoteDetailsStore } from "./NoteDetailsStore";
export class PwaMiniViwerState {
    constructor() {
        this.activepagenumber = 1;
        this.scrollnoteid = "";
        this.zoomfactor = window.innerWidth < 1100 ? 1 : 0.7;
        this.loadingactivepage = false;
        this.totalpages = 0;
        this.annotate = false;
        this.viewportwidth = 0;
        this.viewportheight = 0;
        this.deletingbookmarks = false;
        this.showlinkdialog = false;
        this.showinoutlinks = new Set();
        this.commentstate = new CommentState();
        this.viewerfindstate = new ViewerFindState();
        this.loadingcomments = false;
        this.annotationtype = undefined;
        this.pagestate = new Map();
        this.mainpagestate = new Map();
        this.sethighlightterms = new Map();
        this.findtermswordindex = new Map();
        this.findterms = new Map();
        this.notesbypage = new Map();
        this.hasdict = false;
        this.textannotationdragging = false;
        this.textannotatestartpage = 0;
        this.annotatepagecods = "";
        this.activemultitype = AnnotationType.Freehand;
        this.pageshaveactivehighlight = new Set();
        this.activeannotcolor = "";
        this.notedetailstate = new NoteDetailsStore();
        this.notelinksdialogleft = 0;
        this.notelinksdialogtop = 0;
        this.activenoteboxref = null;
        this.showbookmarklinkdetail = new Set();
        this.activerequestid = "";
        this.activeletrid = "";
        this.atcivescrollbookmark = undefined;
        this.activeobjectid = "";
        this.saving = false;
        this.loading = false;
        this.notes = new Map();
        this.activedotednotesid = [];
        this.pages = new Map();
        this.editmode = false;
        this.editnote = undefined;
        this.editnoteid = "";
        this.notestimeout = -1;
        this.lastnotesupdatetime = -1;
        this.smstate = new SelectMemberDialogState();
        this.appliedaclstr = ALLViewer;
        this.colorstate = new ColorStore();
        this.deletingnote = false;
        this.activedraftid = "";
        this.fileid = "";
        this.mainzoomfactor = 0.5;
        this.pagesnumber = [];
        this.showannotation = true;
        this.downx = -1;
        this.downy = -1;
        this.activenoteid = "";
        this.allnotesbypage = new Map();
        this.showfilter = false;
        this.showbookmark = false;
        this.showaddbookmark = false;
        this.addbookmarktitle = "";
        this.addbookmarkpage = 0;
        this.addbookmarktopoffset = 0;
        this.addbookmarkleftoffset = 0;
        this.addingbookmark = false;
        this.accesskey = "";
        this.bookmarkstate = new PdfBookmarkState();
        this.leftbookmark = window.innerWidth - 600;
        this.topbookmark = 300;
        this.lastcommenttime = -1;
        this.authors = new Map();
        this.notefilterstate = new NotesFilterState();
        this.shownotespanel = !isIpadScreen() ? true : false;
        this.showmenu = false;
        this.showextra = false;
        makeObservable(this);
        this.colorstate.onok = () => {
            this.redrawOngoing();
        };
    }
    setShowExtraOptions(show) {
        this.showextra = show;
    }
    setShowMenu(show) {
        this.showmenu = show;
    }
    parseAndBuildFindDic(dics) {
        const words = [];
        for (let i = 0; i < dics.words.length; i++) {
            const word = dics.words[i];
            const pagestr = dics.pages[i];
            const pterms = [];
            const pages = pagestr.split(";");
            for (const page of pages) {
                const parts = page.split("!");
                if (parts.length > 1) {
                    const words = parts[1].split(",");
                    try {
                        const pgnum = parseInt(parts[0]);
                        const wnums = [];
                        for (const word of words) {
                            try {
                                wnums.push(parseInt(word));
                            }
                            catch (ex) { }
                        }
                        pterms.push({ pagenumber: pgnum, matchindex: wnums });
                    }
                    catch (ex) { }
                }
            }
            words.push({ word: word, pagedictionay: pterms });
        }
        this.finddict = new FindDict(words);
        this.buildfinddict();
        this.setHasDict(true);
    }
    setShowNotesPanel(show) {
        this.shownotespanel = show;
        if (show) {
            this.setBookmarkDialog(false);
            ;
        }
    }
    resetFilter() {
        this.notes.forEach((n, k) => {
            if (n.note !== undefined) {
                n.setVisible(true);
            }
        });
    }
    setFilter(filter) {
        for (const n of this.notes.values()) {
            if (n.note !== undefined) {
                if (filter.toDate !== undefined && filter.toDate > 0) {
                    if (n.note.updatetime <= filter.toDate) {
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.fromDate !== undefined && filter.fromDate > 0) {
                    if (n.note.updatetime >= filter.fromDate - 1) {
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.toPage !== undefined && filter.toPage > 0) {
                    const parts = n.note.data.match.split(":");
                    if (parts.length > 1) {
                        const stpg = parseInt(parts[1]);
                        if (stpg <= filter.toPage - 1) {
                        }
                        else {
                            n.setVisible(false);
                            continue;
                        }
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.fromPage !== undefined && filter.fromPage > 0) {
                    const parts = n.note.data.match.split(":");
                    if (parts.length > 1) {
                        const stpg = parseInt(parts[1]);
                        if (stpg >= filter.fromPage - 1) {
                        }
                        else {
                            n.setVisible(false);
                            continue;
                        }
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.type != undefined && filter.type.length > 0) {
                    if (filter.type.indexOf(n.note.data.notetype) !== -1) {
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.authors != undefined && filter.authors.length > 0) {
                    if (filter.authors.indexOf(n.note.authorid) !== -1) {
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.notetype != undefined && filter.notetype.length > 0) {
                    if (n.note.linkboxes !== undefined && n.note.linkboxes.length > 0 && filter.notetype.indexOf(n.note.type) !== -1) {
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                if (filter.notetype != undefined && filter.filterlinknote) {
                    if (n.note.linkboxes === undefined) {
                    }
                    else {
                        n.setVisible(false);
                        continue;
                    }
                }
                console.log("setting visible true for " + n.note.data.notetype);
                n.setVisible(true);
            }
        }
    }
    setShowFilter(show) {
        this.showfilter = show;
    }
    convertNoteToNoteUI(note) {
        const noteui = new NoteUI();
        noteui.setNote(note);
        noteui.setVisible(true);
        return noteui;
    }
    getVisNoteActiveIndex(id) {
        return this.activedotednotesid.indexOf(id);
    }
    getAllNotesArray() {
        const allnotesbyid = Array.from(this.notes.values()).filter((n) => n.visible).sort(this.sortNoteByPage).reverse();
        this.activedotednotesid = allnotesbyid.map((n) => { var _a, _b; return (_b = (_a = n.note) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; });
        return allnotesbyid;
    }
    setShowAddBookmark(show) {
        this.showaddbookmark = show;
    }
    setAddBookmarkTitle(title) {
        this.addbookmarktitle = title;
    }
    setAddingBookmark(adding) {
        this.addingbookmark = adding;
    }
    setAddBookmarkLeft(left) {
        this.addbookmarkleftoffset = left;
    }
    setAddBookmarkPage(page) {
        this.addbookmarkpage = page;
    }
    setDeletingBookmarks(deleting) {
        this.deletingbookmarks = deleting;
    }
    setAddBookmarkTop(top) {
        this.addbookmarktopoffset = top;
    }
    sort(n1, n2) {
        const parts1 = n1.data.match.split(":");
        const parts2 = n2.data.match.split(":");
        const n1page = parseInt(parts1[1]) + 1;
        const n2page = parseInt(parts2[1]) + 1;
        const t1 = n1.updatetime;
        const t2 = n2.updatetime;
        if (n1page === n2page) {
            if (n1.data.notetype === AnnotationType.Text && n2.data.notetype === AnnotationType.Text) {
                const n1startindex = parseInt(parts1[2]);
                const n2startindex = parseInt(parts2[2]);
                if (n2startindex === n1startindex) {
                    return t1 - t2;
                }
                return n1startindex - n2startindex;
            }
            else if (n1.data.notetype === AnnotationType.Rectangle && n2.data.notetype === AnnotationType.Rectangle) {
            }
            else if (n1.data.notetype === AnnotationType.Freehand && n2.data.notetype === AnnotationType.Rectangle) {
            }
        }
        return n1page > n2page ? 1 : -1;
    }
    sortNoteByPage(nui1, nui2) {
        const n1 = nui1.note;
        const n2 = nui2.note;
        if (n1 !== undefined && n2 !== undefined) {
            const parts1 = n1.data.match.split(":");
            const parts2 = n2.data.match.split(":");
            let n1page = parseInt(parts1[1]);
            let n2page = parseInt(parts2[1]);
            if (n1.data.notetype !== AnnotationType.Multi) {
                n1page += 1;
            }
            if (n2.data.notetype !== AnnotationType.Multi) {
                n2page += 1;
            }
            if (n1page === n2page) {
                const t1 = n1.updatetime;
                const t2 = n2.updatetime;
                return t1 - t2;
            }
            return n1page - n2page;
        }
        return 0;
    }
    sortByTime(n1, n2) {
        const parts1 = n1.data.match.split(":");
        const parts2 = n2.data.match.split(":");
        const n1page = parseInt(parts1[1]) + 1;
        const n2page = parseInt(parts2[1]) + 1;
        const t1 = n1.updatetime;
        const t2 = n2.updatetime;
        if (t1 === t2) {
            return n1page - n2page;
        }
        return t1 - t2;
    }
    sortNoteByPageAndCods(n1, n2) {
        const parts1 = n1.data.match.split(":");
        const parts2 = n2.data.match.split(":");
        const n1page = parseInt(parts1[1]) + 1;
        const n2page = parseInt(parts2[1]) + 1;
        if (n1page === n2page) {
            // lets check the type 
            if (n1.data.notetype !== n2.data.notetype) {
                // text followed by rectangel followed by free hand
                if (n1.data.notetype === AnnotationType.Text) {
                    return -1;
                }
                if (n2.data.notetype === AnnotationType.Text) {
                    return 1;
                }
                if (n1.data.notetype === AnnotationType.Rectangle) {
                    return -1;
                }
                if (n2.data.notetype === AnnotationType.Rectangle) {
                    return 1;
                }
            }
            const t1 = n1.updatetime;
            const t2 = n2.updatetime;
            if (n1.data.notetype === AnnotationType.Text) {
                // compare the words start position 
                const parts1 = n1.data.match.split(":");
                const parts2 = n2.data.match.split(":");
                const n1startindex = parseInt(parts1[2]);
                const n2startindex = parseInt(parts2[2]);
                if (n2startindex === n1startindex) {
                    return t1 - t2;
                }
                return n1startindex - n2startindex;
            }
            else if (n1.data.notetype === AnnotationType.Rectangle) {
                // compare top1 and top2 if equal compare left1 and left2 if equal return time diff
                let rect1 = [];
                let rect2 = [];
                if (parts1.length > 5) {
                    const bits = parts1;
                    rect1 = [parseFloat(bits[2]), parseFloat(bits[3]),
                        parseFloat(bits[4]),
                        parseFloat(bits[5])];
                }
                if (parts2.length > 5) {
                    const bits = parts2;
                    rect2 = [parseFloat(bits[2]), parseFloat(bits[3]),
                        parseFloat(bits[4]),
                        parseFloat(bits[5])];
                }
                if (rect1.length > 0 && rect2.length > 0) {
                    const top1 = Math.min(rect1[3], rect1[1]);
                    const top2 = Math.min(rect2[0], rect2[2]);
                    const diff = top1 - top2;
                    if (diff != 0) {
                        return diff;
                    }
                    const left1 = Math.min(rect1[0], rect1[2]);
                    const left2 = Math.min(rect2[0], rect2[2]);
                    const ldiff = left1 - left2;
                    if (ldiff != 0) {
                        return ldiff;
                    }
                    return t1 - t2;
                }
            }
            else {
                const rects = n1.data.boxes;
                const rects2 = n2.data.boxes;
                let top1 = 0;
                let left1 = 0;
                let y1 = 0;
                for (let point of rects) {
                    if (y1 === 0 || point[1] < y1) {
                        y1 = point[1];
                        top1 = point[1];
                        left1 = point[0];
                    }
                }
                let top2 = 0;
                let left2 = 0;
                let y2 = 0;
                for (let point of rects2) {
                    if (y2 === 0 || point[1] < y2) {
                        y2 = point[1];
                        top2 = point[1];
                        left2 = point[0];
                    }
                }
                // now we have everything lets do some comparison!
                const diff = top1 - top2;
                if (diff != 0) {
                    return diff;
                }
                const ldiff = left1 - left2;
                if (ldiff != 0) {
                    return ldiff;
                }
                return t1 - t2;
            }
            return t1 - t2;
        }
        return n1page > n2page ? 1 : -1;
    }
    sortRectangleNotes(n1, n2) {
        const parts1 = n1.data.match.split(":");
        const parts2 = n2.data.match.split(":");
        const n1page = parseInt(parts1[1]) + 1;
        const n2page = parseInt(parts2[1]) + 1;
        const n1startindex = parseInt(parts1[5]);
        const n2startindex = parseInt(parts2[5]);
        const t1 = n1.updatetime;
        const t2 = n2.updatetime;
        if (n1page === n2page) {
            if (n2startindex === n1startindex) {
                return t1 - t2;
            }
            return n1startindex - n2startindex;
        }
        return n1page > n2page ? 1 : -1;
    }
    setShowLinkDialog(show) {
        this.showlinkdialog = show;
    }
    sortFreeHandNotes(n1, n2) {
        const parts1 = n1.data.match.split(":");
        const parts2 = n2.data.match.split(":");
        const n1page = parseInt(parts1[1]) + 1;
        const n2page = parseInt(parts2[1]) + 1;
        const t1 = n1.updatetime;
        const t2 = n2.updatetime;
        const boxes1 = n1.data.boxes.sort((a, b) => {
            return a[1] - b[1];
        });
        const boxes2 = n2.data.boxes.sort((a, b) => {
            return a[1] - b[1];
        });
        const box1Y0 = boxes1[0][1];
        const box1Y1 = boxes1[boxes1.length - 1][1];
        const box2Y0 = boxes2[0][1];
        const box2Y1 = boxes2[boxes2.length - 1][1];
        if (box1Y0 === box2Y0 && box1Y1 === box2Y1) {
            return t1 - t2;
        }
        return box1Y0 > box2Y0 && box1Y1 > box2Y1 ? 1 : -1;
    }
    sortTextNotes(note1, note2) {
        const parts1 = note1.data.match.split(":");
        const parts2 = note2.data.match.split(":");
        const n1page = parseInt(parts1[3]) + 1;
        const n2page = parseInt(parts2[3]) + 1;
        const n1startindex = parseInt(parts1[2]);
        const n2startindex = parseInt(parts2[2]);
        const t1 = note1.updatetime;
        const t2 = note2.updatetime;
        if (n1page === n2page) {
            if (n2startindex === n1startindex) {
                return t1 - t2;
            }
            return n1startindex - n2startindex;
        }
        return n1page > n2page ? 1 : -1;
    }
    buildfinddict() {
        if (!this.finddict) {
            return;
        }
        const terms = [];
        for (const word of this.finddict.words) {
            const wt = word.word;
            for (const pg of word.pagedictionay) {
                pg.matchindex.sort((w1, w2) => w1 - w2).forEach((w) => {
                    terms.push({
                        term: wt,
                        pagenumber: pg.pagenumber,
                        wordindex: w
                    });
                });
            }
        }
        const fobjs = terms.sort((term1, term2) => {
            const diff = term1.pagenumber - term2.pagenumber;
            if (diff != 0) {
                return diff;
            }
            return term1.wordindex - term2.wordindex;
        }).map((term) => ({
            text: term.term,
            data: term
        }));
        this.viewerfindstate.setItems(fobjs);
    }
    clearFindTerms() {
        this.findtermswordindex.clear();
    }
    addFindTermMatch(term) {
        term.forEach((term) => {
            var _a;
            if (!this.findtermswordindex.has(term.pagenumber)) {
                this.findtermswordindex.set(term.pagenumber, new Set());
            }
            (_a = this.findtermswordindex.get(term.pagenumber)) === null || _a === void 0 ? void 0 : _a.add(term.wordindex);
        });
    }
    getNotesForPage(pgnumber) {
        const objs = this.notesbypage.get(pgnumber);
        return objs !== null && objs !== void 0 ? objs : [];
    }
    arrangeNotesByPage() {
        // lets now arrange this 
        for (const [_, note] of this.notes) {
            this.arrangeNote(note);
        }
    }
    deleteLink(link) {
        this.notedetailstate.deleteLink(link);
    }
    clearLinks() {
        this.notedetailstate.clearLinks();
    }
    setLink(link) {
        this.notedetailstate.setLink(link);
    }
    setShowAnnotation(show) {
        this.showannotation = show;
    }
    loadComments() {
        if (this.loadingcomments || !this.editnoteid) {
            return;
        }
        this.setLoadingComments(true);
        const getcoom = {
            letrid: this.activeletrid,
            objectid: this.activeobjectid,
            noteid: this.editnoteid
        };
    }
    redrawOngoing() {
        for (const [pg, state] of this.pagestate) {
            if (this.annotationtype === AnnotationType.Text) {
                state.redrawOnGoingTextAnnotation(this.colorstate.color);
            }
            else if (this.annotationtype === AnnotationType.Rectangle) {
                state.redrawOnGoingRectangle(this.colorstate.color, this.notedetailstate.thickness);
            }
            else if (this.annotationtype === AnnotationType.Freehand) {
                state.redrawOnGoingFreeHand(this.colorstate.color, this.notedetailstate.thickness);
            }
            else if (this.annotationtype === AnnotationType.Multi) {
                state.redrawAllMultiRects(this.colorstate.color, this.notedetailstate.thickness);
            }
        }
        for (const [pg, state] of this.mainpagestate) {
            if (this.annotationtype === AnnotationType.Text) {
                state.redrawOnGoingTextAnnotation(this.colorstate.color);
            }
            else if (this.annotationtype === AnnotationType.Rectangle) {
                state.redrawOnGoingRectangle(this.colorstate.color, this.notedetailstate.thickness);
            }
            else if (this.annotationtype === AnnotationType.Freehand) {
                state.redrawOnGoingFreeHand(this.colorstate.color, this.notedetailstate.thickness);
            }
            else if (this.annotationtype === AnnotationType.Multi) {
                state.redrawAllMultiRects(this.colorstate.color, this.notedetailstate.thickness);
            }
        }
    }
    setNoteLinksDialogLeft(left) {
        this.notelinksdialogleft = left;
    }
    removeShowBookmarkDetail(id) {
        this.showbookmarklinkdetail.delete(id);
    }
    setBookmarkDialog(show) {
        this.showbookmark = show;
        if (show) {
            this.setShowNotesPanel(false);
            ;
        }
    }
    setShowBookmark(id) {
        this.showbookmarklinkdetail.add(id);
    }
    clearShowBookmark() {
        this.showbookmarklinkdetail.clear();
    }
    setNoteLinksDialogTop(top) {
        this.notelinksdialogtop = top;
    }
    setDeletingNote(dn) {
        this.deletingnote = dn;
    }
    setLoadingComments(loading) {
        this.loadingcomments = loading;
    }
    setAppliedAclString(str) {
        this.appliedaclstr = str;
    }
    setThickness(th) {
        this.notedetailstate.setThickness(th);
        // lets do something funny here!
        this.redrawOngoing();
    }
    setSWidth(th) {
        this.notedetailstate.setSWidth(th);
    }
    selectAccessids(ids) {
        this.notedetailstate.activeaccessids.clear();
        for (const id of ids) {
            this.addActiveAccessId(id);
        }
    }
    addActiveAccessId(id) {
        this.notedetailstate.activeaccessids.add(id);
    }
    removeActiveAccessId(id) {
        this.notedetailstate.activeaccessids.delete(id);
    }
    arrangeNote(noteui) {
        var _a, _b, _c;
        if (noteui.note !== undefined) {
            const note = noteui.note;
            const addtopage = true;
            const makring = note.data.match;
            const bits = makring.split(":");
            const stpg = parseInt(bits[1]);
            if (note.data.notetype === AnnotationType.Text) {
                const endpg = parseInt(bits[3]);
                for (let i = stpg; i <= endpg; i++) {
                    const pgstt = this.pagestate.get(i);
                    const mpgstt = this.mainpagestate.get(i);
                    if (addtopage && pgstt) {
                        pgstt.addNotes([noteui]);
                    }
                    if (addtopage && mpgstt) {
                        mpgstt.addNotes([noteui]);
                    }
                    if (!this.notesbypage.has(i)) {
                        this.notesbypage.set(i, []);
                    }
                    (_a = this.notesbypage.get(i)) === null || _a === void 0 ? void 0 : _a.push(noteui);
                }
            }
            else if (note.data.notetype === AnnotationType.Rectangle ||
                note.data.notetype === AnnotationType.Freehand) {
                const pgstt = this.pagestate.get(stpg);
                const mpgstt = this.mainpagestate.get(stpg);
                if (addtopage && pgstt) {
                    pgstt.addNotes([noteui]);
                }
                if (addtopage && mpgstt) {
                    mpgstt.addNotes([noteui]);
                }
                if (!this.notesbypage.has(stpg)) {
                    this.notesbypage.set(stpg, []);
                }
                (_b = this.notesbypage.get(stpg)) === null || _b === void 0 ? void 0 : _b.push(noteui);
            }
            else if (note.data.notetype === AnnotationType.Multi) {
                const mk = note.data.match.split(":");
                if (mk.length > 1 && mk[0] === 'multi' && note.data.multiboxes) {
                    try {
                        for (const box of note.data.multiboxes) {
                            console.log(box);
                            const pgstt = this.pagestate.get(box.pgnum);
                            const mpgstt = this.mainpagestate.get(box.pgnum);
                            if (pgstt) {
                                pgstt.addNotes([noteui]);
                            }
                            if (mpgstt) {
                                mpgstt.addNotes([noteui]);
                            }
                            if (!this.notesbypage.has(box.pgnum)) {
                                this.notesbypage.set(box.pgnum, []);
                            }
                            (_c = this.notesbypage.get(box.pgnum)) === null || _c === void 0 ? void 0 : _c.push(noteui);
                        }
                    }
                    catch (ex) { }
                }
            }
        }
    }
    addHightlight(pg, posi) {
        var _a;
        if (!this.sethighlightterms.has(pg)) {
            this.sethighlightterms.set(pg, new Set());
        }
        (_a = this.sethighlightterms.get(pg)) === null || _a === void 0 ? void 0 : _a.add(posi);
    }
    clearAllOngoingRects() {
    }
    setEditMode(mode) {
        this.editmode = mode;
        if (!mode) {
            this.notedetailstate.activeaccessids = new Set([ALLViewer]);
            this.appliedaclstr = "---";
        }
    }
    setEditNoteId(id) {
        this.editnoteid = id;
        this.setLoadingComments(false);
        this.commentstate.clear();
        this.loadComments();
        this.lastcommenttime = -1;
        this.scrollnoteid = id;
    }
    setSaving(saving) {
        this.saving = saving;
    }
    setActiveAnnotColor(color) {
        this.activeannotcolor = color;
    }
    clearAllOnGoingTextAnn(miniviewer = true) {
        const state = miniviewer ? this.pagestate : this.mainpagestate;
        state.forEach((st) => {
            st.clearTextRects();
        });
        this.pageshaveactivehighlight.clear();
    }
    clearAllMulti(miniviewer = true) {
        const state = miniviewer ? this.pagestate : this.mainpagestate;
        state.forEach((st) => {
            st.clearAllMulti();
        });
        this.pageshaveactivehighlight.clear();
    }
    clearAllAnnotationFromPageAbove(mypage, miniviewer = true) {
        var _a;
        const state = miniviewer ? this.pagestate : this.mainpagestate;
        for (let i = mypage - 1; i >= 0; i--) {
            (_a = state.get(i)) === null || _a === void 0 ? void 0 : _a.clearTextRects();
            this.pageshaveactivehighlight.delete(i);
        }
    }
    clearAllAnnotationFromPageBelow(mypage, miniviewer = true) {
        var _a;
        const state = miniviewer ? this.pagestate : this.mainpagestate;
        for (let i = mypage + 1; i < state.size; i++) {
            (_a = state.get(i)) === null || _a === void 0 ? void 0 : _a.clearTextRects();
            this.pageshaveactivehighlight.delete(i);
        }
    }
    removePgHasHighlight(pg) {
        this.pageshaveactivehighlight.delete(pg);
    }
    addPgHasActiveHighlight(pg) {
        this.pageshaveactivehighlight.add(pg);
    }
    paintAllPagesBelowMe(mypagenumber, miniviewer = true) {
        const state = miniviewer ? this.pagestate : this.mainpagestate;
        for (let i = mypagenumber + 1; i <= this.textannotatestartpage; i++) {
            const st = state.get(i);
            if (i === this.textannotatestartpage) {
                // we need to paint everything between!
                st === null || st === void 0 ? void 0 : st.paintWordsBetween(0, st.endword);
            }
            else {
                st === null || st === void 0 ? void 0 : st.paintAllWords();
                this.addPgHasActiveHighlight(i);
            }
        }
    }
    paintAllPagesAboveMe(mypagenumber, miniviewer = true) {
        const state = miniviewer ? this.pagestate : this.mainpagestate;
        for (let i = mypagenumber - 1; i >= this.textannotatestartpage; i--) {
            const st = state.get(i);
            if (i === this.textannotatestartpage) {
                // we need to paint everything between!
                console.log((st === null || st === void 0 ? void 0 : st.startword) + " startword" + " " + i);
                st === null || st === void 0 ? void 0 : st.paintWordsBetween(st.startword, st.pdetails.length - 1);
            }
            else {
                st === null || st === void 0 ? void 0 : st.paintAllWords();
                this.addPgHasActiveHighlight(i);
            }
        }
    }
    setTextAnnotateStartPage(pg) {
        this.textannotatestartpage = pg;
    }
    setTextAnnotationDragging(dragging) {
        this.textannotationdragging = dragging;
    }
    addActiveAnnotationType(type) {
        const body = document.getElementsByTagName("body");
        if (body && body.length > 0) {
            if (type) {
                body[0].style.overflow = 'hidden';
            }
            else {
                body[0].style.overflow = '';
            }
        }
        const pdfviewer = document.getElementById("pdf-viewer");
        if (testIsMobile() && pdfviewer) {
            if (type) {
                pdfviewer.style.overflowY = 'hidden';
            }
            else {
                pdfviewer.style.overflowY = 'auto';
            }
        }
        this.annotationtype = type;
        this.setAppliedAclString("");
        this.notedetailstate.activeaccessids = new Set([ALLViewer]);
        this.commentstate.clear();
    }
    setViewportwidth(wid) {
        this.viewportwidth = wid;
    }
    setViewportHeight(hg) {
        this.viewportheight = hg;
    }
    loadMorePages() {
        if (this.pagesnumber.length >= this.totalpages) {
            return;
        }
        this.increamentPageNumber();
    }
    increamentPageNumber() {
        const inc = this.pagesnumber.length + 5 > this.totalpages ? this.totalpages - this.pagesnumber.length
            : 5;
        for (let i = 0; i < inc; i++) {
            this.pagesnumber.push(this.pagesnumber.length + i);
        }
    }
    setHasDict(has) {
        this.hasdict = has;
    }
    setShowLinksForNote(noteid) {
        this.showinoutlinks.add(noteid);
    }
    clearShowLinksForNote() {
        this.showinoutlinks.clear();
    }
    removeShowLinksForNote(noteid) {
        this.showinoutlinks.delete(noteid);
    }
    incrementNoteCount(id) {
        const n = this.notes.get(id);
        if (n && n.note) {
            n.note.replycount++;
            console.log("Incrementing note count");
            this.notes.delete(id);
            this.notes.set(id, n);
        }
    }
    decrementNoteCount(id) {
        const n = this.notes.get(id);
        if (n && n.note && n.note.replycount > 0) {
            n.note.replycount--;
            this.notes.delete(id);
            this.notes.set(id, n);
        }
    }
    addNoteToMap(n) {
        if (n.note !== undefined) {
            this.notes.set(n.note.id, n);
        }
    }
    setActiveMultiAnnotationType(ty) {
        this.activemultitype = ty;
    }
    reset() {
        this.activemultitype = AnnotationType.Text;
        this.atcivescrollbookmark = undefined;
        this.setDeletingBookmarks(false);
        this.setAddingBookmark(false);
        this.bookmarkstate.reset();
        this.clearShowBookmark();
        this.showinoutlinks.clear();
        this.setShowLinkDialog(false);
        this.clearLinks();
        this.hasdict = false;
        this.pagesnumber = [];
        this.notesbypage.clear();
        this.findterms.clear();
        this.findtermswordindex.clear();
        this.pagestate.clear();
        this.mainpagestate.clear();
        this.setActivePageNumber(1);
        this.setZoomFactor(window.innerWidth < 1100 ? 1 : 0.7);
        this.setMainZoomFactor(0.5);
        this.setTotalPages(1);
        this.setTextAnnotationDragging(false);
        this.sethighlightterms.clear();
        this.clearAllOnGoingTextAnn();
        this.clearAllOnGoingTextAnn(false);
        this.clearAllMulti();
        this.clearAllMulti(false);
        this.setSaving(false);
        this.setLoadingNotes(false);
        this.notedetailstate.activeaccessids = new Set([ALLViewer]);
        this.appliedaclstr = ALLViewer;
        this.annotationtype = undefined;
        this.setLoadingComments(false);
        this.commentstate.clear();
        this.lastnotesupdatetime = -1;
        this.lastcommenttime = -1;
        this.setEditMode(false);
        this.setEditNoteId("");
        this.editnote = undefined;
        this.setShowNotesPanel(!isIpadScreen() ? true : false);
    }
    setLoadingNotes(loading) {
        this.loading = loading;
    }
    setTotalPages(pages) {
        this.totalpages = pages;
    }
    setLoadingActivePage(loading) {
        this.loadingactivepage = loading;
    }
    setActivePageNumber(num) {
        this.activepagenumber = num;
    }
    findMatchFor(pagenumber, x, y) {
        const ps = this.pagestate.get(pagenumber);
        if (ps !== undefined) {
            return ps.findMatchFor(x, y);
        }
        return undefined;
    }
    cleanup() {
    }
    setActivePageNumberStr(num) {
        this.activepagenumber = num;
    }
    setZoomFactor(fc) {
        this.zoomfactor = fc;
        let widthoffset = window.innerWidth < 1100 ? .9 : .4;
        for (const pgstate of Array.from(this.pagestate.values())) {
            pgstate.setWidth((window.innerWidth * widthoffset) * fc);
        }
    }
    setMainZoomFactor(fc) {
        this.mainzoomfactor = fc;
        for (const pgstate of Array.from(this.pagestate.values())) {
            pgstate.setMainWidth((window.innerWidth - 200) * fc);
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "activepagenumber", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "zoomfactor", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "loadingactivepage", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "totalpages", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "annotate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "viewportwidth", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "viewportheight", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "deletingbookmarks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showlinkdialog", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaMiniViwerState.prototype, "showinoutlinks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "loadingcomments", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaMiniViwerState.prototype, "annotationtype", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaMiniViwerState.prototype, "findtermswordindex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "hasdict", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "textannotationdragging", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "textannotatestartpage", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "annotatepagecods", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaMiniViwerState.prototype, "activemultitype", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaMiniViwerState.prototype, "pageshaveactivehighlight", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "activeannotcolor", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "notelinksdialogleft", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "notelinksdialogtop", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaMiniViwerState.prototype, "showbookmarklinkdetail", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "saving", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaMiniViwerState.prototype, "notes", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "editmode", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "editnoteid", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaMiniViwerState.prototype, "appliedaclstr", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "deletingnote", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "mainzoomfactor", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaMiniViwerState.prototype, "pagesnumber", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showannotation", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showfilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showbookmark", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showaddbookmark", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "addbookmarktitle", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "addbookmarkpage", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "addbookmarktopoffset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "addbookmarkleftoffset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "addingbookmark", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "leftbookmark", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "topbookmark", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaMiniViwerState.prototype, "authors", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "shownotespanel", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showmenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaMiniViwerState.prototype, "showextra", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowExtraOptions", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowMenu", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowAddBookmark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setAddBookmarkTitle", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setAddingBookmark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setAddBookmarkLeft", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setAddBookmarkPage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setDeletingBookmarks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setAddBookmarkTop", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowLinkDialog", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearFindTerms", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "addFindTermMatch", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "deleteLink", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearLinks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setLink", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowAnnotation", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setNoteLinksDialogLeft", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "removeShowBookmarkDetail", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setBookmarkDialog", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowBookmark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearShowBookmark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setNoteLinksDialogTop", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setDeletingNote", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setLoadingComments", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setAppliedAclString", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setThickness", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setSWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "selectAccessids", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "addActiveAccessId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "removeActiveAccessId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setEditMode", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setEditNoteId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setSaving", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setActiveAnnotColor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearAllOnGoingTextAnn", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearAllMulti", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearAllAnnotationFromPageAbove", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearAllAnnotationFromPageBelow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "removePgHasHighlight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "addPgHasActiveHighlight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setTextAnnotateStartPage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setTextAnnotationDragging", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "addActiveAnnotationType", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setViewportwidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setViewportHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "loadMorePages", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "increamentPageNumber", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setHasDict", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setShowLinksForNote", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "clearShowLinksForNote", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "removeShowLinksForNote", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "incrementNoteCount", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "decrementNoteCount", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [NoteUI]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "addNoteToMap", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setActiveMultiAnnotationType", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setLoadingNotes", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setTotalPages", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setLoadingActivePage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setActivePageNumber", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setActivePageNumberStr", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setZoomFactor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaMiniViwerState.prototype, "setMainZoomFactor", null);
