export var UploadStatus;
(function (UploadStatus) {
    UploadStatus[UploadStatus["Done"] = 0] = "Done";
    UploadStatus[UploadStatus["Failed"] = 1] = "Failed";
    UploadStatus[UploadStatus["Uploading"] = 2] = "Uploading";
})(UploadStatus || (UploadStatus = {}));
export var FileDownloadType;
(function (FileDownloadType) {
    FileDownloadType[FileDownloadType["Preview"] = 0] = "Preview";
    FileDownloadType[FileDownloadType["Download"] = 1] = "Download";
    FileDownloadType[FileDownloadType["Metadata"] = 2] = "Metadata";
})(FileDownloadType || (FileDownloadType = {}));
