export class FastSearch {
    constructor(data, pattern) {
        this.data = data;
        this.pattern = pattern;
        this.badmatchtable = new Map();
        this.buildBadMatchTable();
    }
    find(offset) {
        // now we can use this to do things like fast search etc!!
        if (this.pattern.length > this.data.length || offset > this.data.length || (this.pattern.length + offset) > this.data.length) {
            return -1;
        }
        let exhausted = false;
        while (!exhausted) {
            let found = true;
            let movech = this.data[offset + this.pattern.length - 1];
            let move = this.badmatchtable.get(movech);
            if (move === undefined) {
                move = this.pattern.length;
            }
            for (let i = this.pattern.length - 1; i >= 0; i--) {
                let ch = this.pattern[i];
                // lets compare now
                const cchindex = offset + i;
                const cch = this.data[cchindex];
                if (cch != ch) {
                    found = false;
                    offset = (offset + (move));
                    break;
                }
            }
            if (found) {
                return offset;
            }
            if (offset > this.data.length || (offset + this.pattern.length) > this.data.length) {
                break;
            }
        }
        return -1;
    }
    buildBadMatchTable() {
        const plen = this.pattern.length;
        const lch = this.pattern[this.pattern.length - 1];
        this.badmatchtable.set(lch, plen);
        for (let i = 0; i < this.pattern.length - 1; i++) {
            const ch = this.pattern[i];
            if (ch !== lch) {
                const val = plen - i - 1;
                this.badmatchtable.set(ch, val);
            }
        }
    }
}
