export var PersonalGroupEditOpt;
(function (PersonalGroupEditOpt) {
    PersonalGroupEditOpt[PersonalGroupEditOpt["Delete"] = 0] = "Delete";
    PersonalGroupEditOpt[PersonalGroupEditOpt["Add"] = 1] = "Add";
})(PersonalGroupEditOpt || (PersonalGroupEditOpt = {}));
export class PersonalGroupEdit {
    constructor() {
        this.name = "";
        this.id = "";
        this.memberids = [];
        this.edit = PersonalGroupEditOpt.Add;
    }
}
export class PersonalGroupresponse {
    constructor() {
        this.error = false;
        this.edit = PersonalGroupEditOpt.Add;
    }
}
