var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, } from "@chakra-ui/react";
import { observer } from 'mobx-react';
import { StoreContext } from '@/app/uistore/PwaRootStore';
let ConfirmDialog = class ConfirmDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.cancelRef = React.createRef();
    }
    close() {
        const rstore = this.context;
        rstore.confirmStore.setOpen(false);
    }
    cancel() {
        var _a, _b;
        const rstore = this.context;
        rstore.confirmStore.setOpen(false);
        (_b = (_a = rstore.confirmStore).cancelcb) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    ok() {
        var _a, _b;
        const rstore = this.context;
        (_b = (_a = rstore.confirmStore).okcb) === null || _b === void 0 ? void 0 : _b.call(_a);
        this.close();
    }
    render() {
        const rstore = this.context;
        return (React.createElement(AlertDialog, { isOpen: rstore.confirmStore.open, leastDestructiveRef: this.cancelRef, onClose: () => this.close() },
            React.createElement(AlertDialogOverlay, null,
                React.createElement(AlertDialogContent, null,
                    React.createElement(AlertDialogHeader, { fontSize: "lg", fontWeight: "bold" }, "Confirm"),
                    React.createElement(AlertDialogBody, null, rstore.confirmStore.message),
                    React.createElement(AlertDialogFooter, null,
                        React.createElement(Button, { ref: this.cancelRef, onClick: () => this.cancel() }, "Cancel"),
                        React.createElement(Button, { colorScheme: "red", onClick: () => this.ok(), ml: 3 }, "OK"))))));
    }
};
ConfirmDialog.contextType = StoreContext;
ConfirmDialog = __decorate([
    observer
], ConfirmDialog);
export { ConfirmDialog };
