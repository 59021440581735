import nacl from 'tweetnacl';
import naclutil from 'tweetnacl-util';
import { v4 as uuidv4 } from 'uuid';
import { EncryptBox } from './EncryptBox';
class CommonCrypto {
    set256HashGen(fn) {
        this.hash256 = fn;
    }
    async getHash256(str) {
        var _a, _b;
        return (_b = await ((_a = this.hash256) === null || _a === void 0 ? void 0 : _a.call(this, str))) !== null && _b !== void 0 ? _b : "";
    }
    getUUID() {
        return uuidv4();
    }
    setRandomGen(ran) {
        nacl.setPRNG(ran);
    }
    getRawBytes(n) {
        return nacl.randomBytes(n);
    }
    getRandmBytes(len = 32) {
        return naclutil.encodeBase64(this.getRawBytes(len));
    }
    getRandomPassword() {
        return this.getRandmBytes(32);
    }
    getNewKeypair() {
        const box = nacl.box.keyPair();
        const ret = { privatkey: naclutil.encodeBase64(box.secretKey), publickey: naclutil.encodeBase64(box.publicKey) };
        return ret;
    }
    getSignKeyPair() {
        const box = nacl.sign.keyPair();
        const ret = { privatkey: naclutil.encodeBase64(box.secretKey), publickey: naclutil.encodeBase64(box.publicKey) };
        return ret;
    }
    signMessage(msg, signkey) {
        const smsg = nacl.sign(naclutil.decodeUTF8(msg), naclutil.decodeBase64(signkey.privatkey));
        return naclutil.encodeBase64(smsg);
    }
    secretBoxRaw(msg, key) {
        const nonce = this.getRawBytes(24);
        const secret = nacl.secretbox(msg, nonce, naclutil.decodeBase64(key));
        const ret = new EncryptBox();
        ret.encryptmsg = naclutil.encodeBase64(secret);
        ret.nonce = naclutil.encodeBase64(nonce);
        return ret;
    }
    secretBox(msg, key) {
        return this.secretBoxRaw(naclutil.decodeUTF8(msg), key);
    }
    secretBoxWithNonce(msg, key, nonce) {
        const secret = nacl.secretbox(naclutil.decodeUTF8(msg), naclutil.decodeBase64(nonce), naclutil.decodeBase64(key));
        const ret = new EncryptBox();
        ret.encryptmsg = naclutil.encodeBase64(secret);
        ret.nonce = "";
        return ret;
    }
    decryptBoxWithNonceToStr(box, key, nonce) {
        const msg = this.decryptBoxWithNonce(box, key, nonce);
        if (msg) {
            return naclutil.encodeUTF8(msg);
        }
        return undefined;
    }
    decryptBoxWithNonce(box, key, nonce) {
        try {
            const msg = nacl.secretbox.open(naclutil.decodeBase64(box.encryptmsg), naclutil.decodeBase64(nonce), naclutil.decodeBase64(key));
            if (msg === null) {
                return undefined;
            }
            return msg;
        }
        catch (ex) {
        }
        return undefined;
    }
    convertJSObjToUnit8Array(obj) {
        if (obj instanceof Object) {
            const oobj = obj;
            const keys = Object.keys(oobj);
            const ret = new Uint8Array(keys.length);
            let index = 0;
            keys.forEach(key => {
                ret.set([parseInt(obj[key])], index);
                index++;
            });
            return ret;
        }
        return undefined;
    }
    boxBase64Msg(msg, theirpublickey) {
        return this.boxRaw(naclutil.decodeBase64(msg), theirpublickey);
    }
    boxRaw(msg, theirpublickey) {
        const kp = this.getNewKeypair();
        const nonce = this.getRawBytes(24);
        const box = nacl.box(msg, nonce, naclutil.decodeBase64(theirpublickey), naclutil.decodeBase64(kp.privatkey));
        const ret = new EncryptBox();
        ret.encryptmsg = naclutil.encodeBase64(box);
        ret.nonce = naclutil.encodeBase64(nonce);
        ret.publickey = kp.publickey;
        return ret;
    }
    box(msg, theirpublickey) {
        return this.boxRaw(naclutil.decodeUTF8(msg), theirpublickey);
    }
    decryptSecretBox(box, key) {
        try {
            const msg = this.decryptSecretBoxRaw(box, key);
            if (msg === undefined) {
                return undefined;
            }
            return naclutil.encodeUTF8(msg);
        }
        catch (ex) {
            console.log(ex);
        }
        return undefined;
    }
    decryptSecretBoxRaw(box, key) {
        try {
            const msg = nacl.secretbox.open(naclutil.decodeBase64(box.encryptmsg), naclutil.decodeBase64(box.nonce), naclutil.decodeBase64(key));
            if (msg === null) {
                return undefined;
            }
            return msg;
        }
        catch (ex) { }
        return undefined;
    }
    decryptRSABoxRawString(box, mykey) {
        try {
            const msg = nacl.box.open(naclutil.decodeBase64(box.encryptmsg), naclutil.decodeBase64(box.nonce), naclutil.decodeBase64(box.publickey), naclutil.decodeBase64(mykey.privatkey));
            if (msg !== null) {
                return naclutil.encodeUTF8(msg);
            }
            return null;
        }
        catch (ex) {
            console.log(ex);
        }
        return null;
    }
    decryptRSABox(box, mykey) {
        try {
            const msg = this.decryptRSABoxRawString(box, mykey);
            if (msg !== null) {
                return JSON.parse(msg);
            }
            return undefined;
        }
        catch (ex) { }
        return undefined;
    }
    hash512str(str) {
        return naclutil.encodeBase64(this.hash512(str));
    }
    hash512(str) {
        return nacl.hash(naclutil.decodeUTF8(str));
    }
    getBase64(arr) {
        return naclutil.encodeBase64(arr);
    }
    getUTF8FromBase64(str) {
        return naclutil.encodeUTF8(naclutil.decodeBase64(str));
    }
    getUTF8FromBin(bin) {
        return naclutil.encodeUTF8(bin);
    }
    getBinFromUTF8(str) {
        return naclutil.decodeUTF8(str);
    }
    base64ToBin(str) {
        return naclutil.decodeBase64(str);
    }
    getBase64FromUT8(str) {
        return naclutil.encodeBase64(naclutil.decodeUTF8(str));
    }
    fromBase64ToUTF8(str) {
        return naclutil.encodeUTF8(naclutil.decodeBase64(str));
    }
    generateNewSecretKey() {
        const dataranbytes = this.getRandomPassword();
        const datakeyrawbytes = this.hash512(dataranbytes);
        const datakey = this.getBase64(datakeyrawbytes.subarray(0, 32));
        return datakey;
    }
    getMessageFromSignedKey(signkey, message) {
        const rawmessage = nacl.sign.open(naclutil.decodeBase64(message), naclutil.decodeBase64(signkey));
        if (rawmessage !== null) {
            return naclutil.encodeUTF8(rawmessage);
        }
        return undefined;
    }
}
export const LetrwingCrypto = new CommonCrypto();
