var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { FastSearch } from "@@/FastSearch";
export class ViewerFindState {
    constructor() {
        this.searchterm = "";
        this.finditems = [];
        this.searchstring = "";
        this.index = new Map();
        this.totalmatch = 0;
        this.findcods = new Map();
        makeObservable(this);
    }
    setItems(items) {
        this.finditems = items;
        this.index.clear();
        this.findcods.clear();
        this.createSearchIndex();
    }
    createSearchIndex() {
        this.searchstring = "";
        let len = 0;
        for (const item of this.finditems) {
            if (item.text.trim()) {
                this.index.set(len, item);
                this.searchstring += item.text.trim().toLowerCase();
                len = this.searchstring.length;
            }
        }
    }
    resetTotalMatch() {
        this.totalmatch = 0;
    }
    findMatch(cb) {
        if (!this.searchterm.trim()) {
            return;
        }
        this.totalmatch = 0;
        let previndex = 0;
        const term = this.searchterm.replace(/\s/g, "").toLowerCase();
        const ffsearch = new FastSearch(this.searchstring, term);
        while (true) {
            // const index = this.searchstring.indexOf(term, previndex);
            const index = ffsearch.find(previndex);
            if (index === -1) {
                break;
            }
            // lets find what all matches we need to highlight!
            this.totalmatch++;
            let sitem = undefined;
            let pos = 0;
            for (let i = index; i >= 0; i--) {
                const iitem = this.index.get(i);
                if (iitem !== undefined) {
                    sitem = iitem;
                    pos = i;
                    break;
                }
            }
            if (sitem !== undefined) {
                // we have position as well lets do something simpler here!
                const termlen = sitem.text.length;
                // okay we have the 
                const slen = termlen - (index - pos);
                if (term.length <= slen) {
                    // that's the only match
                    cb(this.searchterm, index - pos, (index - pos) + term.length, sitem);
                }
                else {
                    cb(this.searchterm, index - pos, sitem.text.length, sitem);
                    let nitem = sitem;
                    let remlen = (term.length) - (nitem.text.length - (index - pos));
                    while (true) {
                        console.log(remlen);
                        const nextitemkey = pos + nitem.text.length;
                        const nnitem = this.index.get(nextitemkey);
                        if (nnitem !== undefined) {
                            nitem = nnitem;
                            const ntlength = nitem.text.length;
                            if (ntlength >= remlen) {
                                cb(this.searchterm, 0, remlen, nitem);
                                break;
                            }
                            else {
                                // now we still have bit fo stuff left 
                                cb(this.searchterm, 0, ntlength, nitem);
                                pos = nextitemkey;
                                remlen -= ntlength;
                            }
                        }
                        else {
                            break;
                        }
                    }
                    // this is the next item index!
                }
            }
            previndex = index + term.length;
            if (previndex >= this.searchstring.length) {
                break;
            }
        }
    }
    setSearchTerm(term) {
        this.searchterm = term;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ViewerFindState.prototype, "searchterm", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ViewerFindState.prototype, "totalmatch", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], ViewerFindState.prototype, "findcods", void 0);
__decorate([
    observable,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ViewerFindState.prototype, "setItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ViewerFindState.prototype, "resetTotalMatch", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function]),
    __metadata("design:returntype", void 0)
], ViewerFindState.prototype, "findMatch", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ViewerFindState.prototype, "setSearchTerm", null);
