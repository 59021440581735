import { stripHtml } from "string-strip-html";
export const numberToWord = (n) => {
    const numbersToWords = new Map();
    numbersToWords.set(0, "zero");
    numbersToWords.set(1, "one");
    numbersToWords.set(2, "two");
    numbersToWords.set(3, "three");
    numbersToWords.set(4, "four");
    numbersToWords.set(5, "five");
    numbersToWords.set(6, "six");
    numbersToWords.set(7, "seven");
    numbersToWords.set(8, "eight");
    numbersToWords.set(9, "nine");
    numbersToWords.set(10, "ten");
    numbersToWords.set(11, "eleven");
    numbersToWords.set(12, "twelve");
    numbersToWords.set(13, "thirteen");
    numbersToWords.set(14, "fourteen");
    numbersToWords.set(15, "fifteen");
    numbersToWords.set(16, "sixteen");
    numbersToWords.set(17, "seventeen");
    numbersToWords.set(18, "eighteen");
    numbersToWords.set(19, "nineteen");
    numbersToWords.set(20, "twenty");
    numbersToWords.set(30, "thirty");
    numbersToWords.set(40, "forty");
    numbersToWords.set(50, "fifty");
    numbersToWords.set(60, "sixty");
    numbersToWords.set(70, "seventy");
    numbersToWords.set(80, "eighty");
    numbersToWords.set(90, "ninety");
    function convertNumberToWords(num) {
        // if number present in object no need to go further
        const n = numbersToWords.get(num);
        if (n)
            return n;
        let words = "";
        //Crore
        if (num >= 10000000) {
            words += convertNumberToWords(Math.floor(num / 10000000)) + " crore ";
            num %= 10000000;
        }
        //Lac
        if (num >= 100000 && num < 10000000) {
            words += convertNumberToWords(Math.floor(num / 100000)) + " lac ";
            num %= 100000;
        }
        //Thousand
        if (num >= 1000 && num < 100000) {
            words += convertNumberToWords(Math.floor(num / 1000)) + " thousand ";
            num %= 1000;
        }
        // If the number is greater than or equal to 100 and less than 1000
        if (num >= 100 && num < 1000) {
            words += convertNumberToWords(Math.floor(num / 100)) + " hundred";
            num %= 100;
        }
        // If the number is greater than zero, handle the remaining digits
        if (num > 0) {
            // If the words string is not empty, add "and"
            if (words !== "")
                words += " and ";
            // If the number is less than 20, look up the word form in the numbersToWords object
            if (num < 20)
                words += numbersToWords.get(num);
            else {
                // Otherwise, add the word form of the tens place to the words string
                //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
                words += numbersToWords.get(Math.floor(num / 10) * 10);
                // If the ones place is not zero, add the word form of the ones place
                if (num % 10 > 0) {
                    words += "-" + numbersToWords.get(num % 10);
                }
            }
        }
        // Return the word form of the number
        return words;
    }
    return convertNumberToWords(n);
};
export const stripHTML = (html) => {
    const ret = stripHtml(html + "").result;
    return ret;
};
export const isLocalServer = (url) => {
    if (url.indexOf("localhost") === 0)
        return true;
    if (url.indexOf("127.0.0") === 0)
        return true;
    if (url.indexOf("192.168.") === 0)
        return true;
    if (url.indexOf("10.0") === 0)
        return true;
    return false;
};
export const getExt = (name) => {
    const lindex = name.lastIndexOf('.');
    if (lindex > -1) {
        return name.substring(lindex + 1);
    }
    return "";
};
export const hexcolorbright = (color) => {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
};
export const checkAncestoryForEvent = (e, att, val) => {
    const attval = e.getAttribute(att);
    if (attval) {
        return attval === val;
    }
    if (e.parentElement) {
        return checkAncestoryForEvent(e.parentElement, att, val);
    }
    return false;
};
export const checkPasswordStrength = (word) => {
    word = word.trim();
    if (word.length < 6 || !(/\d/.test(word)) || (word.match(/^[a-z0-9]+$/i) !== null)) {
        return false;
    }
    return true;
};
export const emailStringVerify = (email) => {
    const reg = /^\S+@\S+$/;
    return reg.test(email);
};
export const testIsMobile = () => {
    return isMobileScreen();
};
export const isIpadScreen = () => {
    return isMobileScreen();
};
export const isLandScapeMode = () => {
    return window.innerHeight < window.innerWidth;
};
export const isMobileScreen = () => {
    return window.innerWidth < 1000;
};
export const formatBytes = (a, b = 2) => {
    if (0 === a) {
        return "0 Bytes";
    }
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
};
export const getInitials = (name) => {
    const nparts = name.split(' ');
    let initials = nparts[0].substring(0, 1).toUpperCase();
    if (nparts.length > 1) {
        initials += nparts[nparts.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};
export const milisecondsToMin = (time) => {
    return Math.floor(time / 60000);
};
const colormap = { "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
    "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
    "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
    "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
    "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
    "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
    "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
    "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
    "honeydew": "#f0fff0", "hotpink": "#ff69b4",
    "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
    "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
    "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
    "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
    "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
    "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
    "navajowhite": "#ffdead", "navy": "#000080",
    "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
    "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
    "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
    "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
    "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
    "violet": "#ee82ee",
    "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5",
    "yellow": "#ffff00", "yellowgreen": "#9acd32" };
export const colorNameToHex = (name) => {
    var _a;
    return (_a = colormap[name]) !== null && _a !== void 0 ? _a : '';
};
export const colorBright = (color) => {
    const rgb = convertRGBStringToRGB(color);
    if (rgb.length > 2) {
        return rgbColorBright(rgb[0], rgb[1], rgb[2]);
    }
    return false;
};
export const rgbColorBright = (r, g, b) => {
    const hsp = Math.sqrt(0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b));
    return hsp > 127.5;
};
export const convertRGBStringToRGB = (rgb) => {
    let clerstr = "";
    if (!rgb.startsWith('#') && !rgb.startsWith('rgba') && !rgb.startsWith('rgb')) {
        rgb = colorNameToHex(rgb);
    }
    if (rgb.startsWith("#")) {
        const bigint = parseInt(rgb.substring(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        rgb = "rgb(" + r + "," + g + "," + b + ")";
    }
    if (rgb.startsWith('rgba')) {
        clerstr = rgb.replace("rgba(", "").replace(")", "");
    }
    else if (rgb.startsWith('rgb')) {
        clerstr = rgb.replace("rgb(", "").replace(")", "");
    }
    const parts = clerstr.split(",");
    if (parts.length > 2) {
        return [parseInt(parts[0]), parseInt(parts[1]), parseInt(parts[2]), parseFloat(parts[3])];
    }
    return [];
};
export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
