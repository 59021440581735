var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
import { AnnotationType, NoteType } from "@@/Annotation";
export class NotesFilterState {
    constructor() {
        this.open = false;
        this.toDate = null;
        this.fromDate = null;
        this.filteractive = false;
        this.authorids = new Set();
        this.contains = "";
        this.toPage = 0;
        this.fromPage = 0;
        this.allcb = true;
        this.annotationtypes = new Set();
        this.noteallcb = true;
        this.notetypes = new Set();
        makeObservable(this);
    }
    setContains(cont) {
        this.contains = cont;
    }
    setOpen(open) {
        this.open = open;
    }
    setToDate(date) {
        this.toDate = date;
    }
    setFromDate(date) {
        this.fromDate = date;
    }
    setToPage(page) {
        this.toPage = page;
    }
    setFromPage(page) {
        this.fromPage = page;
    }
    setFilterActive(active) {
        this.filteractive = active;
    }
    selectAuthorid(authorid) {
        if (this.authorids.has(authorid)) {
            this.authorids.delete(authorid);
        }
        else {
            this.authorids.add(authorid);
        }
    }
    setAllCb(select) {
        if (select) {
            this.annotationtypes.clear();
        }
        this.allcb = select;
    }
    selectAnnotationType(type) {
        if (this.annotationtypes.has(type)) {
            this.annotationtypes.delete(type);
        }
        else {
            this.setAllCb(false);
            this.annotationtypes.add(type);
        }
    }
    setNoteAllCb(select) {
        if (select) {
            this.notetypes.clear();
        }
        this.noteallcb = select;
    }
    selectNoteType(type) {
        if (this.notetypes.has(type)) {
            this.notetypes.delete(type);
        }
        else {
            this.setNoteAllCb(false);
            this.notetypes.add(type);
        }
    }
    reset() {
        this.toDate = null;
        this.fromDate = null;
        this.toPage = 0;
        this.fromPage = 0;
        this.filteractive = false;
        this.annotationtypes.clear();
        this.authorids.clear();
        this.setAllCb(true);
        this.notetypes.clear();
        this.setNoteAllCb(true);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], NotesFilterState.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NotesFilterState.prototype, "toDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NotesFilterState.prototype, "fromDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], NotesFilterState.prototype, "filteractive", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], NotesFilterState.prototype, "authorids", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], NotesFilterState.prototype, "contains", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], NotesFilterState.prototype, "toPage", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], NotesFilterState.prototype, "fromPage", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], NotesFilterState.prototype, "allcb", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], NotesFilterState.prototype, "annotationtypes", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], NotesFilterState.prototype, "noteallcb", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], NotesFilterState.prototype, "notetypes", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setContains", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setOpen", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setToDate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setFromDate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setToPage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setFromPage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setFilterActive", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "selectAuthorid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setAllCb", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "selectAnnotationType", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "setNoteAllCb", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "selectNoteType", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], NotesFilterState.prototype, "reset", null);
