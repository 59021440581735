export const ALLViewer = "everyone";
export const MYSELF = "myself";
export var AnnotationType;
(function (AnnotationType) {
    AnnotationType["Rectangle"] = "rectangle";
    AnnotationType["Freehand"] = "freehand";
    AnnotationType["Text"] = "text";
    AnnotationType["Multi"] = "multi";
})(AnnotationType || (AnnotationType = {}));
export var NoteType;
(function (NoteType) {
    NoteType["Note"] = "Note";
    NoteType["NoteLink"] = "NoteLink";
    NoteType["Reply"] = "Reply";
})(NoteType || (NoteType = {}));
export var NoteStatus;
(function (NoteStatus) {
    NoteStatus["Live"] = "Live";
    NoteStatus["Dead"] = "Dead";
})(NoteStatus || (NoteStatus = {}));
