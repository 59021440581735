import { comm } from "@/Comm/comm";
import { AddNewConnectResponseStatus } from "@/share/models";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { SecStore } from "./SecurityStore";
class Store {
    async getConnects() {
        // now we have the connects lets add these 
        const connects = await comm.get(ServerPath.getConnects);
        return connects !== null && connects !== void 0 ? connects : [];
    }
    async fetchLetrConnectDetail(letrid, connectid) {
        const mainpassword = InMemoryRepo.getMasterPassword();
        if (mainpassword === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            connectid: connectid
        };
        const res = await comm.post(ServerPath.letrConnectDetail, req);
        if (res === undefined || res.connectuserpasswoirdbox === undefined) {
            return undefined;
        }
        if (res.connectPasswordBox !== undefined) {
            const pass = await SecStore.decryptPasswordBox(res.connectPasswordBox, mainpassword);
            if (pass !== undefined) {
                res.userpassword = pass.password;
            }
        }
        const cbox = await SecStore.decryptPasswordBox(res.connectuserpasswoirdbox, mainpassword);
        if (cbox === undefined) {
            return undefined;
        }
        const ubox = LetrwingCrypto.decryptSecretBox(res.connectUserBox, cbox.password);
        if (ubox === undefined) {
            return undefined;
        }
        try {
            const upasswordboxes = JSON.parse(ubox);
            for (const upb of upasswordboxes) {
                if (upb.id === connectid) {
                    res.accesspassword = upb.password;
                    break;
                }
            }
        }
        catch (e) {
            return undefined;
        }
        console.log(res);
        return res;
    }
    async addConnectsPasswordHints(hints) {
        const mainpassword = InMemoryRepo.getMasterPassword();
        const commkey = InMemoryRepo.getCommKey();
        if (mainpassword === undefined || commkey === undefined) {
            return undefined;
        }
        const ret = [];
        for (const hint of hints) {
            const password = hint.connectpassword;
            const letrid = hint.letrid;
            const key = await LetrwingCrypto.getHash256(password.password);
            const box = InMemoryRepo.getLetrPassword(letrid);
            if (box === undefined) {
                ret.push({ letrid: letrid, connectid: hint.connectpassword.id, success: false });
                continue;
            }
            const hpobox = await SecStore.generatePasswordBoxFor(password.password, mainpassword);
            const habox = LetrwingCrypto.secretBox(box, key);
            const hpid = await SecStore.storeShareBox(habox);
            if (hpid === undefined || (hpobox === null || hpobox === void 0 ? void 0 : hpobox.ebox) === undefined) {
                ret.push({ letrid: letrid, connectid: hint.connectpassword.id, success: false });
                continue;
            }
            const req = {
                hint: hint.connectpassword.hint,
                connectid: hint.connectpassword.id,
                letrid: letrid,
                hintboxid: hpid,
                hintpasswordbox: hpobox.ebox
            };
            const stored = await comm.post(ServerPath.addConnectHintPassword, req);
            ret.push({ letrid: letrid, connectid: hint.connectpassword.id, success: stored !== null && stored !== void 0 ? stored : false });
        }
        return ret;
    }
    async deleteConnect(email) {
        const req = { emails: email };
        const res = await comm.post(ServerPath.deleteConnect, req);
        if (res !== undefined) {
            return res;
        }
        return false;
    }
    async addConnect(email) {
        const req = { emailid: email };
        const res = await comm.post(ServerPath.addConnects, req);
        if (res !== undefined) {
            if (res.letrwingmemeber) {
                return {
                    name: "",
                    email: email,
                    res: AddNewConnectResponseStatus.ConnectLetrwingUser
                };
            }
            return {
                name: res.dto.name,
                email: res.dto.email,
                res: AddNewConnectResponseStatus.Added
            };
        }
        else {
            return {
                name: "",
                email: email,
                res: AddNewConnectResponseStatus.Failed
            };
        }
    }
}
export const ConnectStore = new Store();
