import { hasExternal } from './Letr';
import { AppConstants } from './Constants';
export var IconType;
(function (IconType) {
    IconType["MaterialCommunityIcon"] = "MaterialCommunityIcon";
    IconType["MaterialIcon"] = "MaterialIcon";
    IconType["FontAwesome5"] = "FontAwesome5";
    IconType["FontAwesome"] = "FontAwesome";
    IconType["MaterialDesign"] = "MaterialDesign";
    IconType["MaterialIcons"] = "MaterialIcons";
})(IconType || (IconType = {}));
export const SettingIcon = {
    name: 'settings',
    type: IconType.MaterialCommunityIcon,
    color: 'black',
    size: 22
};
export const LayerIcon = {
    name: 'layer-group',
    type: IconType.FontAwesome5,
    color: 'black',
    size: 22
};
export const FlagIcon = {
    name: 'flag',
    type: IconType.FontAwesome5,
    color: 'black',
    size: 22
};
export const FlagChIcon = {
    name: 'FaFlag',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const GroupIcon = {
    name: 'users',
    type: IconType.FontAwesome5,
    color: 'black',
    size: 22
};
export const GroupChIcon = {
    name: 'FaUsers',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const UserIcon = {
    name: 'user',
    type: IconType.FontAwesome5,
    color: 'black',
    size: 22
};
export const UserChIcon = {
    name: 'FaUser',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const ArchiveIcon = {
    name: 'file-archive',
    type: IconType.FontAwesome5,
    color: 'black',
    size: 22
};
export const ArchiveChIcon = {
    name: 'FaFileArchive',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const ChatIcon = {
    name: 'FaBox',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const ChatIconCh = {
    name: 'chat-bubble-outline',
    type: IconType.MaterialIcon,
    color: 'black',
    size: 22
};
export const ArchiveIconWeb = {
    name: 'file-archive-o',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const UserIconWeb = {
    name: 'user-o',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const GroupIconWeb = {
    name: 'users',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const FlagIconWeb = {
    name: 'flag-o',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const LayerIconWeb = {
    name: 'layers',
    type: IconType.MaterialIcon,
    color: 'black',
    size: 22
};
export const LayerIconChWeb = {
    name: 'MdLayers',
    type: IconType.MaterialDesign,
    color: 'black',
    size: 22
};
export const FiledIcon = {
    name: 'folder-o',
    type: IconType.FontAwesome,
    color: 'black',
    size: 22
};
export const BellIcon = {
    name: 'bell',
    type: IconType.FontAwesome5,
    color: 'black',
    size: 22
};
export const AssignmentIcon = {
    name: 'assignment',
    type: IconType.MaterialIcons,
    color: 'black',
    size: 22
};
export const ActiveCallIcon = {
    name: 'activecall',
    type: IconType.MaterialIcons,
    color: 'black',
    size: 22
};
export function getWebVersion(icon) {
    if (icon.name === ArchiveIcon.name && icon.type === ArchiveIcon.type) {
        return ArchiveIconWeb;
    }
    else if (icon.name == UserIcon.name && icon.type === UserIcon.type) {
        return UserIconWeb;
    }
    else if (icon.name == GroupIcon.name && icon.type === GroupIcon.type) {
        return GroupIconWeb;
    }
    else if (icon.name === FlagIcon.name && icon.type === FlagIcon.type) {
        return FlagIconWeb;
    }
    else if (icon.name === LayerIcon.name && icon.type === LayerIcon.type) {
        return LayerIconWeb;
    }
    return icon;
}
export function getChVersion(icon) {
    if (icon.name === ArchiveIcon.name && icon.type === ArchiveIcon.type) {
        return ArchiveChIcon;
    }
    else if (icon.name == UserIcon.name && icon.type === UserIcon.type) {
        return UserChIcon;
    }
    else if (icon.name == GroupIcon.name && icon.type === GroupIcon.type) {
        return GroupChIcon;
    }
    else if (icon.name === FlagIcon.name && icon.type === FlagIcon.type) {
        return FlagChIcon;
    }
    else if (icon.name === LayerIcon.name && icon.type === LayerIcon.type) {
        return LayerIconChWeb;
    }
    return icon;
}
export const getIconForLetr = (letr) => {
    const urec = new Set(letr.recipients);
    if (letr.author) {
        urec.add(letr.author);
    }
    const count = urec.size;
    if (count > 2) {
        return "group";
    }
    if (count > 1 && letr.hasConnects) {
        return "group";
    }
    return "user";
};
export const getIconForLetrUI = (letr) => {
    const urec = new Set(letr.recipients);
    if (letr.author) {
        urec.add(letr.author);
    }
    const count = urec.size;
    if (count > 2) {
        return "group";
    }
    return "user";
};
export const getIconColorForLetrUI = (letrui, tenant) => {
    const external = hasExternalLetrUI(letrui, tenant);
    return external ? 'red' : 'black';
};
export const hasExternalLetrUI = (letr, tenant) => {
    const recp = letr.recipients;
    if (recp) {
        for (const r in recp) {
            const bits = r.split(AppConstants.TenantSep);
            if (bits[0] !== tenant) {
                return true;
            }
        }
    }
    return false;
};
export const getIconColor = (letr, tenant) => {
    if (letr.hasConnects) {
        return "orange";
    }
    const external = hasExternal(letr, tenant);
    return external ? 'red' : 'black';
};
