var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, Switch, Select as ChakraSelect, ModalFooter, ModalHeader, ModalOverlay, NumberInput, NumberInputField } from "@chakra-ui/react";
import { Button, Box } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import { Select } from "chakra-react-select";
let CustomFormDisplayDialog = class CustomFormDisplayDialog extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
    }
    checkAllMantoryFields() {
        const state = this.props.customformstate;
        for (const f of state.items) {
            const val = state.values.get(f.id);
            if (f.required && (val === undefined || val.value === "")) {
                this.props.alertcb("Mandtory fields cant be empty");
                return false;
            }
            if (f.type === 'number' && val) {
                const nt = f;
                const uvalue = parseFloat(val.value + "");
                if (val.value === "") {
                    continue; // ignore 
                }
                if (isNaN(uvalue) || uvalue < nt.min || uvalue > nt.max) {
                    this.props.alertcb("Field " + f.itemlabel + " out of range");
                    return false;
                }
            }
        }
        return true;
    }
    render() {
        return (React.createElement(Modal, { isOpen: this.props.customformstate.open, onClose: () => {
                this.props.customformstate.setOpen(false);
            } },
            React.createElement(ModalOverlay, null),
            React.createElement(ModalContent, null,
                React.createElement(ModalHeader, null, this.props.customformstate.title),
                React.createElement(ModalBody, null, this.props.customformstate.items.map((item) => {
                    var _a, _b, _c;
                    return React.createElement(FormControl, { key: item.id + "_form", isRequired: item.required },
                        React.createElement(FormLabel, null, item.itemlabel),
                        item.type === 'number' && React.createElement(FormNumberInput, { defaultvalue: item.defaultvalue + "", min: item.min, max: item.max, onChange: (val) => this.props.customformstate.setValueForId(item.id, val) }),
                        item.type === 'text' && React.createElement(FormInput, { defaultvalue: ((_a = item.defaultvalue) !== null && _a !== void 0 ? _a : '') + '', onChange: (val) => this.props.customformstate.setValueForId(item.id, val) }),
                        item.type === 'choice' && React.createElement(ChoiceInput, { defaultvalue: ((_b = item.defaultvalue) !== null && _b !== void 0 ? _b : '') + '', multichoice: (_c = item.multichoice) !== null && _c !== void 0 ? _c : false, choices: item.values, onchange: (val) => this.props.customformstate.setValueForId(item.id, val) }),
                        item.type === 'boolean' && React.createElement(CheckboxInput, { defaultvalue: item.defaultvalue ? true : false, onchange: (val) => this.props.customformstate.setValueForId(item.id, val) }));
                })),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { colorScheme: "red", mr: 3, onClick: () => this.props.customformstate.setOpen(false) }, "Cancel"),
                    React.createElement(Box, { width: '60px' },
                        React.createElement(Button, { disabled: this.props.customformstate.values.size === 0, onClick: () => {
                                var _a, _b;
                                if (this.checkAllMantoryFields()) {
                                    this.props.customformstate.setOpen(false);
                                    (_b = (_a = this.props.customformstate).onsubmit) === null || _b === void 0 ? void 0 : _b.call(_a, Array.from(this.props.customformstate.values.values()));
                                }
                            }, colorScheme: "blue" }, "Submit"))))));
    }
};
CustomFormDisplayDialog = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomFormDisplayDialog);
export { CustomFormDisplayDialog };
class FormNumberInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        console.log("we actually getting here??");
        return React.createElement(NumberInput, { onChange: (nstr, num) => this.props.onChange(nstr), min: this.props.min, max: this.props.max, defaultValue: this.props.defaultvalue },
            React.createElement(NumberInputField, null));
    }
}
let FormInput = class FormInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return (React.createElement(Box, null,
            React.createElement(Input, { defaultValue: this.props.defaultvalue, onChange: (str) => this.props.onChange(str.target.value) })));
    }
};
FormInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], FormInput);
let ChoiceInput = class ChoiceInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            id: []
        };
    }
    componentDidMount() {
        if (this.props.defaultvalue) {
            this.setState({ id: (this.props.defaultvalue.split(",")) });
        }
    }
    render() {
        if (this.props.multichoice) {
            return React.createElement(Select, { isMulti: true, options: this.props.choices.map((lt) => ({ label: lt[1], value: lt[0] })), selectedOptionStyle: "check", maxMenuHeight: 100, isSearchable: true, value: this.props.choices.filter((lt) => {
                    if (this.state.id.indexOf(lt[0]) != -1) {
                        return true;
                    }
                    return false;
                }).map((lt) => ({ label: lt[1], value: lt[0] })), onChange: (e) => {
                    this.setState({ id: e.map((e) => e.value) });
                    this.props.onchange(e.map((lt) => lt.value).join(","));
                }, closeMenuOnSelect: false });
        }
        return React.createElement(ChakraSelect, { defaultValue: this.props.defaultvalue, variant: "flushed", onChange: (e) => {
                this.props.onchange(e.currentTarget.value);
            } }, this.props.choices.map((ch) => React.createElement("option", { key: ch[0], value: ch[0] }, ch[1])));
    }
};
ChoiceInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ChoiceInput);
class CheckboxInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        return React.createElement(Switch, { defaultChecked: (_a = this.props.defaultvalue) !== null && _a !== void 0 ? _a : false, onChange: e => {
                this.props.onchange(e.currentTarget.checked);
            } });
    }
}
