// import { Cryptobox } from "../security/Cryptbox";
// import { security, KeyPair } from "../security/Security";
// import { comm, ResetPasswordReq } from "../communication/comm";
import { UserKeysLocker } from "@@/EncryptBox";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { AdminSecurity } from "./AdminSecurity";
// import { AEvent } from "../events/AppEvent";
// import { myStorage } from "../storage/LocalStorage";
// import { LoginState } from "./LoginStore";
// import { CommonCrypto } from "../../../../letrcore/src/Cryptography";
// import { LetrwingCrypto } from "../../../../letrcore/src/LetrwingCommonCrypto";
// import { ServerPath } from "../../../../letrcore/src/ServerPath";
import { comm } from "@/Comm/comm";
import { CommonCrypto } from "@@/Cryptography";
import { myAdminStorage } from "./AdminLocalStore";
import { ServerPath } from "@@/ServerPath";
class UserAdminLockerResponse {
    constructor() {
        this.id = "";
        this.storage3 = "";
        this.index = -1;
        this.vault = "";
    }
}
export class StorageboxRequest {
    constructor() {
        this.publickey = "";
    }
}
export class EncryptboxResponse {
    constructor() {
        this.encryptmsg = "";
        this.nonce = "";
        this.publickey = "";
    }
}
class AdminSecurityStore {
    constructor() {
        // AEvent.addSubscriberForLoginState((state) => {
        //     if(state === LoginState.LoggedOut) {
        //        this.reset();
        //     }
        // });
        this.keys = undefined;
        this.datakey = "admin_datakey";
        this.mdkey = "mdkey";
        this.userSecret = new Map();
        this.userVault = new Map();
        this.userNonhashedSecret = new Map();
        this.matrix = undefined;
        this.mkstr = "";
        this.orgcontactskey = "";
        LetrwingCrypto.set256HashGen(async (str) => {
            return AdminSecurity.generateHash(str);
        });
    }
    async getStorageBoxes() {
        const kp = AdminSecurity.getKeypair();
        const req = new StorageboxRequest();
        req.publickey = kp.publicKey;
        const ret = await comm.post(ServerPath.getstorageboxes, req);
        if (ret !== undefined) {
            const dmsg = AdminSecurity.decryptBoxMessage(ret.encryptmsg, ret.publickey, ret.nonce, kp);
            if (dmsg !== undefined) {
                const cboxes = JSON.parse(dmsg);
                return cboxes;
            }
        }
        return undefined;
    }
    async getAllCryptBoxes() {
        const kp = AdminSecurity.getKeypair();
        const req = new StorageboxRequest();
        req.publickey = kp.publicKey;
        const ret = await comm.post(ServerPath.getAllCryptBoxes, req);
        if (ret !== undefined) {
            const dmsg = AdminSecurity.decryptBoxMessage(ret.encryptmsg, ret.publickey, ret.nonce, kp);
            if (dmsg !== undefined) {
                const cboxes = JSON.parse(dmsg);
                return cboxes;
            }
        }
        return undefined;
    }
    async revealSecretBoxes(masterpassword) {
        if (this.keys !== undefined && this.keys.length > 0) {
            return true;
        }
        try {
            // const password = LetrwingCrypto.hash512str(masterpassword);
            // // const res = await comm.unlock(password);
            // // const header: Map<string, string> = new Map();
            // // header.set("password", password);
            // const header: Header[] = [
            //     { key: "password", value: password },
            // ];
            // const res = await comm.get<Boolean>(ServerPath.passwordcheck, header);
            // if(res === undefined || !res) {
            //     return false;
            // }
            const storageboxes = await this.getAllCryptBoxes();
            if (storageboxes === undefined) {
                return false;
            }
            const boxes = AdminSecurity.decryptSecretbox(storageboxes.encryptmsg, storageboxes.nonce, masterpassword, 'utf8');
            console.log('boxes');
            console.log(boxes);
            if (!boxes) {
                return false;
            }
            const sboxes = JSON.parse(boxes);
            console.log('sboxes');
            console.log(sboxes);
            if (sboxes.length > 0) {
                this.keys = sboxes;
                // AEvent.publishMasterKey('ok');
                // this.getAndSetOrgContactsKey(masterpassword);
                return true;
            }
        }
        catch (ex) {
            return false;
        }
    }
    // public async storeShareBox(box: EncryptBox) {
    //    return await comm.storeShareBox(box);
    // }
    // public async storePassword(password: string, mainpassowrd: string, userid: string) {
    //     const boxc = await CommonCrypto.generatePasswordBoxFor(password, mainpassowrd);
    //     if(boxc === undefined) {
    //         return undefined;
    //     }
    //     const pid = await comm.addUserPasswordBox(userid, boxc.box);
    //     if (pid === undefined) {
    //         return undefined; 
    //     }
    //     boxc.opbox.id = pid.valueOf();
    //     boxc.opbox.ebox = boxc.box;
    //     return boxc.opbox;
    // }
    // private async getAndSetOrgContactsKey(mpassword: string) {
    //     let kbox = await comm.getOrgContactKeyBox();
    //     if (kbox === undefined) {
    //         const okey = LetrwingCrypto.generateNewSecretKey();
    //         const obox = LetrwingCrypto.secretBox(okey, mpassword);
    //         kbox = await comm.saveOrgContactKeyBox({contactbox: obox});
    //     }
    //     if(kbox !== undefined) {
    //         const okey = LetrwingCrypto.decryptSecretBox(kbox.contactbox, mpassword);
    //         if (okey !== undefined) {
    //             this.orgcontactskey = okey;
    //             // now lets publish this key and we can finally have phone book on
    //             AEvent.publishOrgContactKey(okey);
    //         }
    //     }
    // }
    // public async revealMasterKey(pwd: string, masterkey: string) {
    //     if (masterkey.trim().length ===0 && pwd.length === 0) {
    //         return false;
    //     }
    //     if(this.keys !== undefined && this.keys.length > 0) {
    //         return true;
    //     }
    //     const storageboxes = await comm.getAllCryptBoxes();
    //     if(storageboxes === undefined) {
    //         return false;
    //     }
    //     let userKeysBox:Cryptobox|undefined = undefined;
    //     const boxname =  masterkey ? await AdminSecurity.generate512Hash("iupassword") : 
    //     await AdminSecurity.generate512Hash("password");
    //     let identitybox: Cryptobox|undefined = undefined;
    //     /*
    //     for(const box of storageboxes) {
    //         if(box.name === boxname) {
    //             userKeysBox = box;
    //         }else if (box.name === AdminSecurity.masterkey) {
    //             identitybox = box;
    //         }
    //      }*/
    //      if(userKeysBox === undefined && identitybox === undefined) {
    //          return false;
    //      }
    //      let ukeysstr: string| undefined = undefined;
    //      // now we have key box and how o open this!
    //      if(pwd !== undefined) {
    //         const password = await AdminSecurity.generateHash(pwd);
    //         const spassword = await AdminSecurity.generateHash(password);
    //         const upassword = await AdminSecurity.generateHash(spassword);
    //         ukeysstr = AdminSecurity.decryptSecretbox(userKeysBox.data, userKeysBox.extrainfo, upassword);
    //      }else if (masterkey !== undefined) {
    //      }
    //      if(ukeysstr === undefined) {
    //         return false;
    //      }
    //      const ustr = AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(ukeysstr));
    //      // now we can convert
    //      this.keys = JSON.parse(ustr) as KeyStorage[];
    //      return true;
    // }
    async checkAndgetuserSecret(userid) {
        if (this.keys === undefined) {
            return false;
        }
        if (this.userSecret.has(userid)) {
            return true;
        }
        const locker = await comm.post(ServerPath.userAdminLocker, { "userid": userid });
        if (locker === undefined) {
            return false;
        }
        const storage = AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(locker.storage3));
        // now we have locker we will try decryting locker if the index is -1 then we will try using the 
        const ebox = JSON.parse(storage);
        const ukey = this.tryDecyptingUserStorage(ebox, this.keys);
        if (ukey === undefined) {
            return false;
        }
        const mp = await AdminSecurity.generateHash(ukey);
        this.userSecret.set(userid, mp);
        this.userNonhashedSecret.set(userid, ukey);
        const vault = await this.tryDecryptinguserVault(locker.vault, mp);
        if (vault !== undefined) {
            this.userVault.set(userid, vault);
        }
        return true;
    }
    getUserSecret(userid) {
        return this.userSecret.get(userid);
    }
    getUserDeviceKey(userid) {
        var _a, _b;
        return (_b = (_a = this.userVault.get(userid)) === null || _a === void 0 ? void 0 : _a.deviceInfoKey) !== null && _b !== void 0 ? _b : "";
    }
    async tryDecryptinguserVault(vault, mainpassword) {
        try {
            const rval = JSON.parse(AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(vault)));
            const data = AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(rval.data));
            const box = JSON.parse(data);
            const lockerstr = AdminSecurity.decryptSecretbox(box.encryptmsg, box.nonce, (mainpassword), 'utf8');
            if (lockerstr === undefined) {
                return undefined;
            }
            const ulockerjson = JSON.parse(lockerstr);
            const ulocker = new UserKeysLocker(ulockerjson.identityKey, ulockerjson.signKey, ulockerjson.deviceInfoKey);
            const auth = LetrwingCrypto.hash512str(ulocker.identityKey.privatkey +
                ulocker.signKey.privatkey + mainpassword);
            if (auth === rval.auth) {
                return ulocker;
            }
        }
        catch (ex) {
        }
        return undefined;
    }
    tryDecyptingUserStorage(box, userkeys) {
        let ukey = undefined;
        for (const nbox of userkeys) {
            // lets first grab the 
            const skey = nbox.privatkey;
            const pkey = nbox.publickey;
            if (pkey === undefined || skey === undefined) {
                continue;
            }
            const dmesg = LetrwingCrypto.decryptRSABoxRawString(box, nbox);
            if (dmesg) {
                ukey = dmesg;
                break;
            }
        }
        return ukey;
    }
    // public async checkAndStorePasswordToSession(mpassword: string) {
    //     this.reset();
    //     if(await this.revealSecretBoxes(mpassword)) {
    //         await this.storePasswordToSession(mpassword);
    //     }
    // }
    // public async storePasswordToSession(mpassword: string) {
    //     const key = AdminSecurity.passwordGenerator();
    //     const box =  AdminSecurity.encryptSecretBox(mpassword, key);
    //     const sbox = AdminSecurity.localEncodeBase64(AdminSecurity.localDecodeUTF8(JSON.stringify(box)));
    //     const ret = await comm.storeMKData(sbox);
    //     if(ret) {
    //         myAdminStorage.setItem(this.datakey, key);
    //         this.mkstr = mpassword;
    //     }
    // }
    //     public async checkSessionHasMasterKey() {
    //         const key = myAdminStorage.getItem(this.datakey);
    //         const data = await comm.getMKData();
    // ;        if(data) {
    //             try {
    //                 const mkbox = JSON.parse(AdminSecurity.localEncodeUTF8(AdminSecurity.localDecodeBase64(data))) as EncryptBox;
    //                 const mk = AdminSecurity.decryptSecretbox(mkbox.encryptmsg, mkbox.nonce, key, 'utf8');
    //                 const ret = await this.revealSecretBoxes(mk);
    //                 if(ret) {
    //                     await this.storePasswordToSession(mk);
    //                 }
    //                 return ret;
    //             }catch(e) {}
    //         }
    //         return false;
    //     }
    async decryptPasswordBox(box) {
        //return await CommonCrypto.decryptPasswordBox(mainpassword, box);
    }
    // public async resetUserPassword(password: string, userid: string) {
    //     const randomnonce = LetrwingCrypto.getRandomPassword();
    //     const matrix = await this.getMatrix();
    //     if(!matrix) {
    //         return undefined;
    //     }
    //     AdminSecurity.updateMatrix(matrix);
    //     const deviceregisterpassword = CommonCrypto.generatePasswordUsingMatrix(password, matrix, 2);
    //     await this.checkAndgetuserSecret(userid);
    //     // now we have new device password 
    //     const userkey = this.userNonhashedSecret.get(userid)
    //     if(!userkey) {
    //         return undefined;
    //     }
    //     console.log(userkey);
    //     const mainkey = CommonCrypto.produceUserKey(password, randomnonce);
    //     const passwordStorageKey = CommonCrypto.generatePasswordUsingMatrix(password, matrix, 4);
    //     const pkey = await LetrwingCrypto.getHash256(passwordStorageKey);
    //     const randpassword = LetrwingCrypto.secretBox(randomnonce, pkey);
    //     const passwordstorage =LetrwingCrypto.secretBox(userkey, mainkey);
    //     const req:ResetPasswordReq = {
    //         mainbox: AdminSecurity.localEncodeBase64(AdminSecurity.localDecodeUTF8(JSON.stringify(passwordstorage))),
    //         randpassword: AdminSecurity.localEncodeBase64(AdminSecurity.localDecodeUTF8(JSON.stringify(randpassword))),
    //         devicepassword: deviceregisterpassword,
    //         userid: userid
    //     };
    //     return await comm.updateUserPassword(req);
    // }
    // public async getMatrix() {
    //     if(!this.matrix) {
    //         this.matrix = await comm.getMatrix();
    //     }
    //     return this.matrix;
    // }
    // public async zapSecret() {
    //     this.reset();
    //     AEvent.publishMasterKey("");
    //     await this.storePasswordToSession("");
    // }
    reset() {
        myAdminStorage.clearItem(this.datakey);
        this.keys = undefined;
        this.userSecret = new Map();
    }
    async decryptPasswordBoxNew(box, usersec) {
        return await CommonCrypto.decryptPasswordBox(usersec, box);
    }
    getMasterPassword() {
        return this.mkstr;
    }
    async securePassword(password) {
        const mp = this.getMasterPassword();
        if (!mp) {
            return undefined;
        }
        return LetrwingCrypto.secretBox(password, mp);
    }
}
export const ADMIN_SECRET_STORE = new AdminSecurityStore();
