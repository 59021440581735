var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Avatar, Box, Button, Center, Checkbox, Flex, FormControl, FormLabel, Heading, Icon, Image, Input, InputGroup, SimpleGrid, Spinner, Stack, Tag, TagLabel, TagRightIcon, Text } from "@chakra-ui/react";
import { BeatLoader, ScaleLoader } from "react-spinners";
import { ChoiceType, SchemaItemType } from "@@/Schema";
import { Select } from 'chakra-react-select';
import { observer } from "mobx-react";
import { SketchPicker } from "react-color";
import { StoreContext } from "../uistore/PwaRootStore";
import React, { Suspense } from "react";
import { APPEvent } from "@/events/AppEvents";
import { AppColor } from "../const/AppDefaults";
import { BarcodeScanner } from "../UIComponents/BarcodeScanner";
import { BiBarcodeReader } from "@react-icons/all-files/bi/BiBarcodeReader";
import { DateLib } from "@@/Datelib";
import DatePicker from "react-datepicker";
import { FaBarcode } from "@react-icons/all-files/fa/FaBarcode";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { FaUserCircle } from "@react-icons/all-files/fa/FaUserCircle";
import { FcProcess } from "@react-icons/all-files/fc/FcProcess";
import { FiWifi } from "@react-icons/all-files/fi/FiWifi";
import { FiWifiOff } from "@react-icons/all-files/fi/FiWifiOff";
import { GrFormClose } from "@react-icons/all-files/gr/GrFormClose";
import { JobStatus } from "../uistore/PWAIndexDb";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { MdAdd } from "@react-icons/all-files/md/MdAdd";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { MdHttps } from '@react-icons/all-files/md/MdHttps';
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { MdRefresh } from "@react-icons/all-files/md/MdRefresh";
import { OfflineMessage } from "../UIComponents/PwaOfflineMessage";
import { OrganisationMenu } from "../UIComponents/orgsnisationMenu";
import { Quill } from 'react-quill';
import { RemotePopupViewStore } from "../uistore/RemotePopupViewStore";
import hicon from "@/assets/wicon_header.png";
import { testIsMobile } from "@@/Util";
import { timeout } from "@@/Util";
import { PwaCustomDisplayItemUI } from "../UIComponents/PwaCustomDisplayItem";
const ReactQuill = React.lazy(async () => await import("react-quill"));
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);
const isMobile = () => window.innerWidth < 650;
let PwaForm = class PwaForm extends React.Component {
    componentDidMount() {
    }
    render() {
        const rstore = this.context;
        const form = rstore.getPwaStore().activeforms.get(rstore.getPwaStore().selecteditemid);
        const items = rstore.getPwaStore().currentitems.slice().filter(fl => !rstore.getPwaStore().readonlyitems.has(fl.id)
            && fl.type !== SchemaItemType.ID && fl.type !== SchemaItemType.SquenceNumber
            && !rstore.getPwaStore().checkSchemaCellHasRule(fl.id)).sort((it1, it2) => it1.orderindex - it2.orderindex);
        return React.createElement(Box, { margin: 'auto', marginTop: '10px', w: window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100, h: '100%' },
            React.createElement(Box, { w: '100%', marginTop: '5px' },
                rstore.getPwaStore().formmessage !== '' && React.createElement(Box, { bg: '#dedfe6', px: 4 },
                    React.createElement(Flex, { justify: 'space-between', align: 'center' },
                        React.createElement(Text, { color: 'red' }, rstore.getPwaStore().formmessage),
                        React.createElement(Icon, { as: MdClose, width: '25px', height: '25px', _hover: { cursor: 'pointer' }, onClick: () => rstore.getPwaStore().addFormmessage("") }))),
                React.createElement(Box, { pos: 'relative', bg: 'whiteAlpha.400', boxShadow: '0 0 5px #b6b6b6', w: window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100, h: testIsMobile() ? window.innerHeight - 250 + "px" : '500px', borderWidth: 1, borderColor: '#dfdfdf' },
                    React.createElement(Box, { m: '10px', width: '95%', height: testIsMobile() ? window.innerHeight - 300 + "px" : '400px', maxH: testIsMobile() ? (window.innerHeight - 300) + "px" : '400px', overflow: 'auto' }, items.map((item) => {
                        var _a, _b;
                        let ritemns = [];
                        let remoteitemsmap = new Map();
                        if (item.type === SchemaItemType.Remote) {
                            const ri = item;
                            const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                            ritemns = items !== null && items !== void 0 ? items : [];
                            remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                            if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                const rtextra = ri.rtextra;
                                for (const ext of rtextra) {
                                    const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                    remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                }
                            }
                        }
                        let itemtype = "";
                        if (item.type === SchemaItemType.Text || item.type === SchemaItemType.FormInput) {
                            itemtype = "Text";
                        }
                        else if (item.type === SchemaItemType.Date) {
                            itemtype = "Date";
                        }
                        else if (item.type === SchemaItemType.Number) {
                            itemtype = "Number";
                        }
                        else if (item.type === SchemaItemType.Choice) {
                            itemtype = "Choice";
                        }
                        else if (item.type === SchemaItemType.Remote) {
                            itemtype = "Choice";
                        }
                        else if (item.type === SchemaItemType.UserChoice) {
                            itemtype = "Choice";
                        }
                        else if (item.type === SchemaItemType.Boolean) {
                            itemtype = "ON / OFF";
                        }
                        else if (item.type === SchemaItemType.Color) {
                            itemtype = "Color";
                        }
                        else if (item.type === SchemaItemType.Objects) {
                            itemtype = "File";
                        }
                        else if (item.type === SchemaItemType.URL) {
                            itemtype = "Link";
                        }
                        else if (item.type === SchemaItemType.List) {
                            itemtype = "List";
                        }
                        else if (item.type === SchemaItemType.CustomDisplayCol) {
                            itemtype = "Custom";
                        }
                        return React.createElement(FormControl, { key: item.id, isRequired: item.required, mt: '10px' },
                            React.createElement(FormLabel, { ml: '10px' }, item.name + " ( " + itemtype + " )"),
                            item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_a = item.excludedate) !== null && _a !== void 0 ? _a : false, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: item.id, id: item.id, format: (_b = item.format) !== null && _b !== void 0 ? _b : 'dd/MM/yyyy' }),
                            item.type === SchemaItemType.Choice &&
                                React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: false, schemafieldname: item.name, key: item.id, id: item.id, list: item.items, multiselect: item.multiselect, item: item }),
                            item.type === SchemaItemType.UserChoice && React.createElement(ChoiceInput, { showbarcodescanner: false, item: item, schemafieldname: item.name, key: item.id, id: item.id, list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                        if (u1.type != u2.type) {
                                            if (u1.type === 'manageruser') {
                                                return -1;
                                            }
                                            if (u2.type === 'manageruser') {
                                                return 1;
                                            }
                                            return 0;
                                        }
                                        return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                                    }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))], multiselect: item.multiselect }),
                            item.type === SchemaItemType.Text &&
                                React.createElement(TextInput, { showbarcodeinput: true, key: item.id, id: item.id, richtext: item.richtext, item: item }),
                            item.type === SchemaItemType.Number &&
                                React.createElement(NumberInput, { showbarcodeinput: true, key: item.id, id: item.id, item: item, minval: item.minvalue, maxval: item.maxvalue }),
                            item.type === SchemaItemType.Color && React.createElement(ColorInput, { item: item, key: item.id, id: item.id }),
                            item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { key: item.id, id: item.id, item: item }),
                            item.type === SchemaItemType.FormInput && React.createElement(FormInputUI, { showbarcodeinput: true, key: item.id, id: item.id, item: item }),
                            item.type === SchemaItemType.URL && React.createElement(URLInputUI, { key: item.id, id: item.id, scheme: item.scheme }),
                            item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { key: item.id, id: item.id }),
                            !isMobile() && item.type === SchemaItemType.List && React.createElement(ListDisplayTable, { key: item.id, item: item }),
                            isMobile() && item.type === SchemaItemType.List && React.createElement(ListDisplay, { key: item.id, item: item }),
                            item.type === SchemaItemType.Remote && React.createElement(RemoteDisplayItem, { id: item.id, item: item, remoteitemsmap: remoteitemsmap, rtitems: ritemns }),
                            item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { key: item.id, id: item.id, itemid: item.id, item: item }));
                    })))));
    }
};
PwaForm.contextType = StoreContext;
PwaForm = __decorate([
    observer
], PwaForm);
export { PwaForm };
let ObjectInput = class ObjectInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.ref = null;
        this.state = {
            activeobjs: []
        };
    }
    filesSelected() {
        const rstore = this.context;
        if (this.ref && this.ref.files) {
            // lets add them now!!
            for (const f of this.ref.files) {
                rstore.getPwaStore().addFile(this.props.id, f);
            }
            this.ref.value = '';
        }
    }
    render() {
        const rstore = this.context;
        const files = rstore.getPwaStore().objectselectedfiles.get(this.props.id);
        return React.createElement(Box, { mt: '4px', ml: '4px' },
            React.createElement(Flex, null,
                React.createElement("input", { style: { display: 'none' }, ref: (ref) => this.ref = ref, type: 'file', multiple: true, onChange: () => {
                        this.filesSelected();
                    } }),
                React.createElement(Button, { variant: 'solid', w: '100px', onClick: () => {
                        var _a;
                        (_a = this.ref) === null || _a === void 0 ? void 0 : _a.click();
                    } }, "Select Files"),
                files !== undefined && files.length > 0 && React.createElement(Text, { ml: '5px' }, files.length + " files")),
            React.createElement(Box, { mt: '5px', maxH: '200px', overflow: 'auto' }, files != undefined && files.map((f, index) => React.createElement(Flex, { key: f.name + "_" + index, borderBottomColor: 'lightgray', borderBottomWidth: '1px', margin: '5px', w: '100%', align: 'center' },
                React.createElement(Text, { w: '70%', maxW: '70%' }, f.name),
                React.createElement(Flex, { w: '20%', justify: 'flex-end', align: 'center' },
                    React.createElement(Icon, { as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => rstore.getPwaStore().removeFile(this.props.id, f) }))))));
    }
};
ObjectInput.contextType = StoreContext;
ObjectInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ObjectInput);
let CustomDisplayItemCont = class CustomDisplayItemCont extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            refreshing: false
        };
    }
    render() {
        const rstore = this.context;
        const schemaroui = rstore.getPwaStore().buildNewRowFromNewEntries();
        if (!schemaroui) {
            return React.createElement(Box, null);
        }
        let id = this.props.id;
        let cid = this.props.id;
        let rindex = "0";
        if (this.props.id.indexOf("----") !== -1) {
            const parts = this.props.id.split("----");
            cid = parts[0] + "----" + parts[1];
            rindex = parts[2];
        }
        if (rstore.getPwaStore().loadingrows) {
            return React.createElement(Box, { minH: '90px' });
        }
        if (this.state.refreshing || (rstore.getPwaStore().rebuildingcustom && !rstore.getPwaStore().ignorerebuildcustomcomponent.has(this.props.id))) {
            return React.createElement(Box, { minH: '90px' });
        }
        if (rstore.getPwaStore().forcerebuildcustom.has(this.props.id) ||
            rstore.getPwaStore().forcerebuildcustom.has(cid)) {
            return React.createElement(Box, { minH: '90px' });
        }
        return React.createElement(Box, { minH: '90px' },
            React.createElement(Icon, { as: MdRefresh, onClick: async () => {
                    this.setState({ refreshing: true });
                    await timeout(200);
                    this.setState({ refreshing: false });
                }, _hover: { cursor: 'pointer' } }),
            React.createElement(PwaCustomDisplayItemUI, { editmode: true, itemid: cid, storevale: (data, ignorerebuilding, ids, fvalue, cellid) => {
                    const value = data;
                    const entry = {
                        celldata: value,
                        cellfindstr: fvalue !== null && fvalue !== void 0 ? fvalue : value,
                        cellid: cellid !== null && cellid !== void 0 ? cellid : cid,
                        schemaid: '',
                        rowindex: -1,
                        rowid: '',
                        id: "",
                        subrowindex: rindex,
                        sortstring: Math.random()
                    };
                    let mycellid = this.props.id;
                    if (cellid) {
                        mycellid = cellid;
                        if (this.props.id.indexOf("----") !== -1) {
                            mycellid += "----" + rindex;
                        }
                    }
                    rstore.getPwaStore().addFormEntry(mycellid !== null && mycellid !== void 0 ? mycellid : this.props.id, entry, ignorerebuilding, ids);
                }, row: schemaroui, item: this.props.item, subrowindex: rindex }));
    }
};
CustomDisplayItemCont.contextType = StoreContext;
CustomDisplayItemCont = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomDisplayItemCont);
let ListDisplay = class ListDisplay extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            listitems: [LetrwingCrypto.getUUID()],
            newrows: 0,
            showrows: true
        };
    }
    componentDidMount() {
        const rstore = this.context;
        let ailists = rstore.getPwaStore().ailistitems.get(this.props.item.id);
        if (ailists && ailists.length > 0) {
            this.setState({ listitems: ailists });
            rstore.getPwaStore().ailistitems.delete(this.props.item.id);
        }
        rstore.getPwaStore().newlistitemsmap.set(this.props.item.id, this.state.listitems);
    }
    render() {
        const rstore = this.context;
        const lid = this.props.item.id;
        if (this.state.listitems.length === 0)
            return React.createElement(React.Fragment, null);
        return React.createElement(Box, null,
            React.createElement(Box, { mt: '4px', ml: '4px', mb: '20px' },
                React.createElement(Flex, { align: 'center' },
                    React.createElement(Input, { width: '30%', type: 'number', onChange: (e) => {
                            const rn = parseInt(e.currentTarget.value);
                            this.setState({ newrows: rn });
                        } }),
                    this.state.newrows > 0 && React.createElement(Icon, { as: FaPlus, _hover: { cursor: 'pointer' }, onClick: () => {
                            const rows = this.state.listitems;
                            for (let i = 0; i < this.state.newrows; i++) {
                                const uuid = LetrwingCrypto.getUUID();
                                rows.push(uuid);
                            }
                            rstore.getPwaStore().newlistitemsmap.set(lid, rows);
                            this.setState({ listitems: rows });
                        } }),
                    React.createElement(Button, { ml: '10px', variant: 'unstyled', onClick: () => {
                            this.setState({ showrows: !this.state.showrows });
                        } }, this.state.showrows ? 'Hide Rows' : 'Show Rows'),
                    React.createElement(Text, { ml: '10px' }, 'Total Rows ' + (this.state.listitems.length))),
                this.state.showrows && this.state.listitems.map((lt, index) => React.createElement(Box, { ml: '10px', key: lt },
                    React.createElement(Icon, { as: MdDelete, margin: '5px', onClick: () => {
                            const litms = this.state.listitems;
                            litms.splice(index, 1);
                            this.setState({ listitems: litms });
                            rstore.getPwaStore().newlistitemsmap.set(lid, litms);
                        } }),
                    this.props.item.items.filter((item) => !rstore.getPwaStore().checkSchemaCellHasRule(this.props.item.id + "----" + item.item.id)).map((litem) => {
                        var _a, _b;
                        const item = litem.item;
                        let ritemns = [];
                        let remoteitemsmap = new Map();
                        if (item.type === SchemaItemType.Remote) {
                            const ri = item;
                            const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                            ritemns = items !== null && items !== void 0 ? items : [];
                            remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                            if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                const rtextra = ri.rtextra;
                                for (const ext of rtextra) {
                                    const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                    remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                }
                            }
                        }
                        let itemtype = "";
                        if (item.type === SchemaItemType.Text || item.type === SchemaItemType.FormInput) {
                            itemtype = "Text";
                        }
                        else if (item.type === SchemaItemType.Date) {
                            itemtype = "Date";
                        }
                        else if (item.type === SchemaItemType.Number) {
                            itemtype = "Number";
                        }
                        else if (item.type === SchemaItemType.Choice) {
                            itemtype = "Choice";
                        }
                        else if (item.type === SchemaItemType.Remote) {
                            itemtype = "Choice";
                        }
                        else if (item.type === SchemaItemType.UserChoice) {
                            itemtype = "Choice";
                        }
                        else if (item.type === SchemaItemType.Boolean) {
                            itemtype = "ON / OFF";
                        }
                        else if (item.type === SchemaItemType.Color) {
                            itemtype = "Color";
                        }
                        else if (item.type === SchemaItemType.Objects) {
                            itemtype = "File";
                        }
                        else if (item.type === SchemaItemType.URL) {
                            itemtype = "Link";
                        }
                        return React.createElement(FormControl, { key: lid + "----" + item.id + "----" + lt, isRequired: item.required, mt: '10px' },
                            React.createElement(FormLabel, { ml: '10px' }, item.name + " (" + itemtype + " )"),
                            item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_a = item.excludedate) !== null && _a !== void 0 ? _a : false, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: lid + "----" + item.id + +"----" + lt, id: lid + "----" + item.id + "----" + lt, format: (_b = item.format) !== null && _b !== void 0 ? _b : 'dd/MM/yyyy' }),
                            item.type === SchemaItemType.Choice &&
                                React.createElement(ChoiceInput, { showbarcodescanner: false, item: item, schemafieldname: item.name, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, list: item.items, multiselect: item.multiselect }),
                            item.type === SchemaItemType.UserChoice && React.createElement(ChoiceInput, { showbarcodescanner: false, item: item, schemafieldname: item.name, key: item.id, id: lid + "----" + item.id + "----" + lt, list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                        if (u1.type != u2.type) {
                                            if (u1.type === 'manageruser') {
                                                return -1;
                                            }
                                            if (u2.type === 'manageruser') {
                                                return 1;
                                            }
                                            return 0;
                                        }
                                        return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                                    }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))], multiselect: item.multiselect }),
                            item.type === SchemaItemType.Text &&
                                React.createElement(TextInput, { showbarcodeinput: true, key: lid + "----" + item.id + "----" + lt, item: item, id: lid + "----" + item.id + "----" + lt, richtext: item.richtext }),
                            item.type === SchemaItemType.Number &&
                                React.createElement(NumberInput, { showbarcodeinput: true, item: item, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, minval: item.minvalue, maxval: item.maxvalue }),
                            item.type === SchemaItemType.Color && React.createElement(ColorInput, { item: item, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt }),
                            item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, item: item }),
                            item.type === SchemaItemType.FormInput && React.createElement(FormInputUI, { showbarcodeinput: true, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, item: item }),
                            item.type === SchemaItemType.URL && React.createElement(URLInputUI, { key: lid + "----" + item.id, id: lid + "----" + item.id, scheme: item.scheme }),
                            item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt }),
                            item.type === SchemaItemType.Remote && React.createElement(RemoteDisplayItem, { id: lid + "----" + item.id + "----" + lt, item: item, remoteitemsmap: remoteitemsmap, rtitems: ritemns }),
                            item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { key: item.id, id: lid + "----" + item.id + "----" + lt, itemid: lid + "----" + item.id, item: item, subrowindex: lt }));
                    }))),
                this.state.showrows && React.createElement(Icon, { as: MdAdd, mt: '5px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                        const rows = this.state.listitems;
                        const uuid = LetrwingCrypto.getUUID();
                        rows.push(uuid);
                        rstore.getPwaStore().newlistitemsmap.set(lid, rows);
                        this.setState({ listitems: rows });
                    } })));
    }
};
ListDisplay.contextType = StoreContext;
ListDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplay);
let ListDisplayTable = class ListDisplayTable extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            listitems: [LetrwingCrypto.getUUID()],
            newrows: 0,
            showrows: true
        };
    }
    componentDidMount() {
        const rstore = this.context;
        rstore.getPwaStore().newlistitemsmap.set(this.props.item.id, this.state.listitems);
    }
    render() {
        const rstore = this.context;
        const lid = this.props.item.id;
        const listSchemaItems = this.props.item.items.filter((item) => !rstore.getPwaStore().checkSchemaCellHasRule(this.props.item.id + "----" + item.item.id)
            && !rstore.getPwaStore().readonlyitems.has(this.props.item.id + "----" + item.item.id));
        if (this.state.listitems.length === 0)
            return React.createElement(React.Fragment, null);
        return React.createElement(Box, null,
            React.createElement(Box, { mt: '4px', ml: '4px' },
                React.createElement(Flex, { align: 'center', marginBottom: '20px' },
                    React.createElement(Input, { width: '30%', type: 'number', onChange: (e) => {
                            const rn = parseInt(e.currentTarget.value);
                            this.setState({ newrows: rn });
                        } }),
                    this.state.newrows > 0 && React.createElement(Icon, { as: FaPlus, _hover: { cursor: 'pointer' }, onClick: () => {
                            const rows = this.state.listitems;
                            for (let i = 0; i < this.state.newrows; i++) {
                                const uuid = LetrwingCrypto.getUUID();
                                rows.push(uuid);
                            }
                            rstore.getPwaStore().newlistitemsmap.set(lid, rows);
                            this.setState({ listitems: rows });
                        } }),
                    React.createElement(Button, { ml: '10px', variant: 'unstyled', onClick: () => {
                            this.setState({ showrows: !this.state.showrows });
                        } }, this.state.showrows ? 'Hide Rows' : 'Show Rows'),
                    React.createElement(Text, { ml: '10px' }, 'Total Rows ' + (this.state.listitems.length))),
                React.createElement(Box, { mt: '4px', ml: '4px' },
                    React.createElement(SimpleGrid, { wordBreak: 'break-word', ml: '30px', columns: listSchemaItems.length + 1, width: listSchemaItems.length > 0 ? listSchemaItems.length * 300 : 600, spacingX: '300px', spacingY: '20px' }, listSchemaItems.map((litem, index) => {
                        const item = litem.item;
                        return React.createElement(Flex, { className: 'border-light', key: item.id + "_header" + index, w: '300px', maxW: '300px', paddingLeft: '10px', paddingRight: '10px' },
                            React.createElement(Text, { fontWeight: 'bold' }, item.name));
                    })),
                    this.state.showrows && this.state.listitems.map((lt, index) => React.createElement(Flex, { key: lt, align: 'center' },
                        React.createElement(Icon, { h: '20px', w: '20px', mr: '10px', as: MdDelete, color: AppColor.DeleteIconColor, onClick: () => {
                                const litms = this.state.listitems;
                                litms.splice(index, 1);
                                this.setState({ listitems: litms });
                                rstore.getPwaStore().newlistitemsmap.set(lid, litms);
                            } }),
                        React.createElement(SimpleGrid, { className: 'grid', width: listSchemaItems.length > 0 ? listSchemaItems.length * 300 : 600, columns: listSchemaItems.length + 1, spacingX: '300px', spacingY: '20px' }, listSchemaItems.map((litem, idx) => {
                            var _a, _b;
                            const item = litem.item;
                            let ritemns = [];
                            let remoteitemsmap = new Map();
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                    const rtextra = ri.rtextra;
                                    for (const ext of rtextra) {
                                        const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                    }
                                }
                            }
                            return React.createElement(Flex, { key: lid + "----" + item.id + "----" + lt, className: 'border-light', w: '300px', maxW: '300px', paddingLeft: '10px', paddingRight: '10px', align: 'center' },
                                React.createElement(FormControl, { key: lid + "----" + item.id + "----" + lt, isRequired: item.required, mt: '10px' },
                                    item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_a = item.excludedate) !== null && _a !== void 0 ? _a : false, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: lid + "----" + item.id + +"----" + lt, id: lid + "----" + item.id + "----" + lt, format: (_b = item.format) !== null && _b !== void 0 ? _b : 'dd/MM/yyyy' }),
                                    item.type === SchemaItemType.Choice &&
                                        React.createElement(ChoiceInput, { showbarcodescanner: false, item: item, schemafieldname: item.name, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, list: item.items, multiselect: item.multiselect }),
                                    item.type === SchemaItemType.Text &&
                                        React.createElement(TextInput, { key: lid + "----" + item.id + "----" + lt, item: item, id: lid + "----" + item.id + "----" + lt, richtext: item.richtext }),
                                    item.type === SchemaItemType.Number &&
                                        React.createElement(NumberInput, { item: item, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, minval: item.minvalue, maxval: item.maxvalue }),
                                    item.type === SchemaItemType.Color && React.createElement(ColorInput, { item: item, key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt }),
                                    item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, item: item }),
                                    item.type === SchemaItemType.FormInput && React.createElement(FormInputUI, { key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt, item: item }),
                                    item.type === SchemaItemType.URL && React.createElement(URLInputUI, { key: lid + "----" + item.id, id: lid + "----" + item.id, scheme: item.scheme }),
                                    item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { key: lid + "----" + item.id + "----" + lt, id: lid + "----" + item.id + "----" + lt }),
                                    item.type === SchemaItemType.Remote && React.createElement(RemoteDisplayItem, { id: lid + "----" + item.id + "----" + lt, item: item, remoteitemsmap: remoteitemsmap, rtitems: ritemns }),
                                    item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { key: item.id, id: lid + "----" + item.id + "----" + lt, itemid: lid + "----" + item.id, item: item, subrowindex: lt })));
                        })))),
                    this.state.showrows && React.createElement(Icon, { as: MdAdd, mt: '5px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            const rows = this.state.listitems;
                            const uuid = LetrwingCrypto.getUUID();
                            rows.push(uuid);
                            rstore.getPwaStore().newlistitemsmap.set(lid, rows);
                            this.setState({ listitems: rows });
                        } }))));
    }
};
ListDisplayTable.contextType = StoreContext;
ListDisplayTable = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplayTable);
let RemoteDisplayItem = class RemoteDisplayItem extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            id: ''
        };
    }
    render() {
        const rstore = this.context;
        const item = this.props.item;
        const rtmap = this.props.remoteitemsmap;
        const rtitems = this.props.rtitems;
        const ritem = item;
        let rtextra = ritem.rtextra;
        if (rtextra !== undefined) {
            rtextra = rtextra.filter((ext) => !ext.isObject);
        }
        let selecteditemsDisplayItems = [];
        let rtDisplayItems = [];
        if (this.state.id) {
            const id = ritem.id + "---" + ritem.sid + "---" + this.state.id;
            const items = rtmap.get(id);
            if (items !== undefined) {
                const itmap = new Map();
                items.map((it) => {
                    if (it.rowid !== undefined) {
                        itmap.set(it.rowid, it);
                    }
                });
                for (const it of rtitems) {
                    if (it.rowid !== undefined) {
                        const item = itmap.get(it.rowid);
                        if (item !== undefined) {
                            const newitem = {
                                id: it.id,
                                value: item.value,
                                rowid: it.rowid
                            };
                            rtDisplayItems.push(newitem);
                        }
                    }
                }
            }
        }
        else {
            rtDisplayItems = this.props.rtitems;
        }
        const en = rstore.getPwaStore().formentry.get(this.props.item.id);
        if (en) {
            const id = ritem.id + "---" + ritem.sid + "---" + ritem.cid;
            const vals = rstore.getPwaStore().dependentdata.get(id);
            if (vals) {
                // now we have this
                const bits = en.celldata.split(",");
                if (bits && bits.length > 0) {
                    selecteditemsDisplayItems = vals.filter((val) => bits.indexOf(val.id) !== -1);
                }
            }
        }
        return React.createElement(Box, null,
            rtextra !== undefined && rtextra.length > 0 && rtextra.map((val) => React.createElement(Tag, { key: val.cid, margin: '4px', bg: this.state.id === val.cid ? AppColor.SideBarIconColor : '#EDF2F7', _hover: { cursor: 'pointer' }, onClick: () => {
                    this.setState({ id: val.cid });
                } },
                React.createElement(TagLabel, null, val.name),
                React.createElement(TagRightIcon, { key: val.cid + "_icon", _hover: { cursor: 'pointer' }, onClick: (e) => {
                        e.stopPropagation();
                        this.setState({ id: '' });
                    }, as: GrFormClose }))),
            React.createElement(ChoiceInput, { showbarcodescanner: rtDisplayItems.length > 0 && !rtDisplayItems[0].ischoice ? true : false, schemafieldname: item.name, key: item.id, id: this.props.id, list: rtDisplayItems.map((item) => {
                    var _a, _b;
                    const ret = {
                        id: item.id,
                        name: item.value,
                        supportid: ((_a = item.rowbarcode) !== null && _a !== void 0 ? _a : '') + '---' + ((_b = item.rowid) !== null && _b !== void 0 ? _b : ''),
                        type: ChoiceType.Text
                    };
                    return ret;
                }), multiselect: ritem.singleselect ? false : true, item: item }),
            React.createElement(Box, { w: '200px', maxHeight: '200px', overflow: 'auto' }, selecteditemsDisplayItems.map((item, index) => React.createElement(Box, { m: '10px', key: this.props.item.id + "_main_" + "_" + index },
                React.createElement(RemoteView, { key: this.props.item.id + "_" + index, index: index, item: this.props.item, rtItem: item })))));
    }
};
RemoteDisplayItem.contextType = StoreContext;
RemoteDisplayItem = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteDisplayItem);
let RemoteView = class RemoteView extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
    }
    componentDidMount() {
    }
    render() {
        const rstore = this.context;
        const ritem = this.props.item;
        const rtextra = ritem.rtextra;
        if (rtextra === undefined) {
            return React.createElement(Box, null);
        }
        const rowid = this.props.rtItem.rowid;
        if (!rowid) {
            return React.createElement(Box, null);
        }
        let remoteitemsmap = new Map();
        const items = rstore.getPwaStore().dependentdata.get(ritem.id + "---" + ritem.sid + "---" + ritem.cid);
        remoteitemsmap.set(ritem.id + "---" + ritem.sid + "---" + ritem.cid, items !== null && items !== void 0 ? items : []);
        for (const ext of rtextra) {
            const items = rstore.getPwaStore().dependentdata.get(ritem.id + "---" + ritem.sid + "---" + ext.cid);
            remoteitemsmap.set(ritem.id + "---" + ritem.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
        }
        let rtDisplayItems = [];
        for (const ext of rtextra) {
            const id = ritem.id + "---" + ritem.sid + "---" + ext.cid;
            const items = rstore.getPwaStore().dependentdata.get(id);
            if (items !== undefined) {
                for (const it of items) {
                    if (it.rowid === rowid) {
                        let ri = {
                            id: it.id,
                            label: ext.name,
                            rowid: it.rowid,
                            value: it.value,
                            objects: it.objects
                        };
                        rtDisplayItems.push(ri);
                        break;
                    }
                }
            }
        }
        if (rtDisplayItems.length === 0) {
            return React.createElement(Box, null);
        }
        return React.createElement(Box, { ref: (re) => this.myref = re, className: 'remote-thumbnail', pos: 'relative', backgroundColor: '#FFFFFF' },
            React.createElement(Box, { onClick: (e) => {
                    e.stopPropagation();
                    // if (this.props.obj.documentreference === ThumbNailStore.activethumid) return;
                    RemotePopupViewStore.setActiveId(this.props.item.id);
                    if (this.myref) {
                        RemotePopupViewStore.reset();
                        RemotePopupViewStore.setLeftPos(this.myref.getBoundingClientRect().x);
                        RemotePopupViewStore.setTopPos(this.myref.getBoundingClientRect().y);
                        RemotePopupViewStore.setBGColor('#FFFFFF');
                        RemotePopupViewStore.addRtDisplayItems(rtDisplayItems);
                    }
                }, width: this.props.width !== undefined ? this.props.width + "px" : '200px', height: this.props.height !== undefined ? this.props.height + "px" : '100px' }, rtDisplayItems.map((en) => React.createElement(Box, { w: '100%', key: en.id + "_tn" },
                React.createElement(Text, { fontWeight: 'bold', className: 'remote-thumbnail-text' }, en.label),
                React.createElement(Text, { className: 'remote-thumbnail-text' }, en.value)))));
    }
};
RemoteView.contextType = StoreContext;
RemoteView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteView);
let FormInputUI = class FormInputUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
        const rstore = this.context;
        if (this.props.item.defaultvalue && !rstore.getPwaStore().formentry.has(this.props.id)) {
            let id = this.props.id;
            let rindex = "0";
            if (this.props.id.indexOf("----") !== -1) {
                const parts = this.props.id.split("----");
                id = parts[0] + "----" + parts[1];
            }
            const entry = {
                celldata: this.props.item.defaultvalue,
                cellfindstr: this.props.item.defaultvalue,
                cellid: id,
                subrowindex: rindex,
                schemaid: '',
                rowindex: -1,
                rowid: '',
                id: '',
                sortstring: Math.random()
            };
            rstore.getPwaStore().addFormEntry(this.props.id, entry);
        }
    }
    onChange(val) {
        const rstore = this.context;
        let id = this.props.id;
        let rindex = "0";
        if (this.props.id.indexOf("----") !== -1) {
            const parts = this.props.id.split("----");
            id = parts[0] + "----" + parts[1];
            rindex = parts[2];
        }
        const entry = {
            celldata: val,
            cellfindstr: val,
            cellid: id,
            subrowindex: rindex,
            schemaid: '',
            rowindex: -1,
            rowid: '',
            id: '',
            sortstring: Math.random()
        };
        rstore.getPwaStore().addFormEntry(this.props.id, entry);
    }
    render() {
        const rstore = this.context;
        const formvalue = rstore.getPwaStore().formentry.get(this.props.id);
        let text = "";
        if (formvalue) {
            text = formvalue.celldata;
        }
        else {
            if ((this.props.item.defaultvalue)) {
                text = this.props.item.defaultvalue;
            }
            const fd = rstore.getPwaStore().schemaItemDefaults.get(this.props.id);
            if (fd) {
                text = fd.value;
            }
        }
        let checked = text && text === 'true' ? true : false;
        return React.createElement(Flex, { align: 'center' },
            React.createElement(Box, { w: '90%', mr: '10px' },
                React.createElement(Input, { defaultValue: text, ref: (ref) => {
                        this.ref = ref;
                    }, onChange: (e) => {
                        this.onChange(e.currentTarget.value);
                    } })),
            this.props.showbarcodeinput && React.createElement(Icon, { onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        let obj = "";
                        if (type === 'bc' && this.ref !== undefined && this.ref !== null) {
                            this.ref.value = data;
                            this.onChange(data);
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }));
    }
};
FormInputUI.contextType = StoreContext;
FormInputUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], FormInputUI);
let URLInputUI = class URLInputUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        const rstore = this.context;
        const formvalue = rstore.getPwaStore().formentry.get(this.props.id);
        let text = "";
        let urld = undefined;
        if (formvalue) {
            text = formvalue.celldata;
            try {
                urld = JSON.parse(text);
            }
            catch (ex) { }
        }
        return React.createElement(Flex, null,
            React.createElement(InputGroup, { width: '70%' },
                React.createElement(Input, { focusBorderColor: AppColor.HeaderIconColor, _placeholder: { fontSize: 12 }, placeholder: "URL", defaultValue: urld !== undefined ? urld.url : '', onChange: (e) => {
                        const val = e.currentTarget.value;
                        let suld = rstore.getPwaStore().formentry.get(this.props.id);
                        let url = {
                            url: e.currentTarget.value,
                            name: ''
                        };
                        if (suld) {
                            const ud = JSON.parse(suld.celldata);
                            url.name = ud.name;
                        }
                        let id = this.props.id;
                        let rindex = "0";
                        if (this.props.id.indexOf("----") !== -1) {
                            const parts = this.props.id.split("----");
                            id = parts[0] + "----" + parts[1];
                            rindex = parts[2];
                        }
                        const entry = {
                            celldata: JSON.stringify(url),
                            cellfindstr: JSON.stringify(url),
                            cellid: id,
                            schemaid: '',
                            rowindex: -1,
                            rowid: '',
                            id: "",
                            subrowindex: rindex,
                            sortstring: Math.random()
                        };
                        rstore.getPwaStore().addFormEntry(this.props.id, entry);
                    } })),
            React.createElement(Input, { focusBorderColor: AppColor.HeaderIconColor, _placeholder: { fontSize: 12 }, placeholder: 'Name (Optional)', width: '30%', defaultValue: (_a = urld === null || urld === void 0 ? void 0 : urld.name) !== null && _a !== void 0 ? _a : '', onChange: (e) => {
                    const val = e.currentTarget.value;
                    let suld = rstore.getPwaStore().formentry.get(this.props.id);
                    let url = {
                        name: e.currentTarget.value,
                        url: ''
                    };
                    if (suld) {
                        const ud = JSON.parse(suld.celldata);
                        url.url = ud.url;
                    }
                    let id = this.props.id;
                    let rindex = "0";
                    if (this.props.id.indexOf("----") !== -1) {
                        const parts = this.props.id.split("----");
                        id = parts[0] + "----" + parts[1];
                        rindex = parts[2];
                    }
                    const entry = {
                        celldata: JSON.stringify(url),
                        cellfindstr: JSON.stringify(url),
                        cellid: id,
                        schemaid: '',
                        rowindex: -1,
                        rowid: '',
                        id: "",
                        subrowindex: rindex,
                        sortstring: Math.random()
                    };
                    rstore.getPwaStore().addFormEntry(this.props.id, entry);
                } }));
    }
};
URLInputUI.contextType = StoreContext;
URLInputUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], URLInputUI);
let CheckboxUI = class CheckboxUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
        const rstore = this.context;
        if (this.props.item.defaultvalue && !rstore.getPwaStore().formentry.has(this.props.id)) {
            let id = this.props.id;
            let rindex = "0";
            if (this.props.id.indexOf("----") !== -1) {
                const parts = this.props.id.split("----");
                id = parts[0] + "----" + parts[1];
                rindex = parts[2];
            }
            const entry = {
                celldata: this.props.item.defaultvalue,
                cellfindstr: this.props.item.defaultvalue,
                cellid: id,
                schemaid: '',
                rowindex: -1,
                rowid: '',
                id: "",
                subrowindex: rindex,
                sortstring: Math.random()
            };
            rstore.getPwaStore().addFormEntry(this.props.id, entry);
        }
    }
    render() {
        const rstore = this.context;
        const formvalue = rstore.getPwaStore().formentry.get(this.props.id);
        let text = "";
        if (formvalue) {
            text = formvalue.celldata;
        }
        else {
            if (this.props.item.defaultvalue === 'true') {
                text = 'true';
            }
            const fd = rstore.getPwaStore().schemaItemDefaults.get(this.props.id);
            if (fd) {
                text = fd.value === 'true' ? 'true' : 'false';
            }
        }
        let checked = text && text === 'true' ? true : false;
        return React.createElement(Box, null,
            React.createElement(Checkbox, { defaultChecked: checked, onChange: (e) => {
                    const val = e.currentTarget.checked ? "true" : 'false';
                    let id = this.props.id;
                    let rindex = "0";
                    if (this.props.id.indexOf("----") !== -1) {
                        const parts = this.props.id.split("----");
                        id = parts[0] + "----" + parts[1];
                        rindex = parts[2];
                    }
                    const entry = {
                        celldata: val,
                        cellfindstr: val,
                        cellid: id,
                        schemaid: '',
                        rowindex: -1,
                        rowid: '',
                        id: "",
                        subrowindex: rindex,
                        sortstring: Math.random()
                    };
                    rstore.getPwaStore().addFormEntry(this.props.id, entry);
                } }));
    }
};
CheckboxUI.contextType = StoreContext;
CheckboxUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CheckboxUI);
let TextInput = class TextInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];
        this.modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['clean'],
            ],
        };
    }
    componentDidMount() {
        const rstore = this.context;
        if (this.props.item.defaultvalue && !rstore.getPwaStore().formentry.has(this.props.id)) {
            let id = this.props.id;
            let rindex = "0";
            if (this.props.id.indexOf("----") !== -1) {
                const parts = this.props.id.split("----");
                id = parts[0] + "----" + parts[1];
                rindex = parts[2];
            }
            const entry = {
                celldata: this.props.item.defaultvalue,
                cellfindstr: this.props.item.defaultvalue,
                cellid: id,
                subrowindex: rindex,
                schemaid: '',
                rowindex: -1,
                rowid: '',
                id: '',
                sortstring: Math.random()
            };
            rstore.getPwaStore().addFormEntry(this.props.id, entry);
        }
    }
    render() {
        const rstore = this.context;
        const formvalue = rstore.getPwaStore().formentry.get(this.props.id);
        let text = "";
        if (formvalue) {
            text = formvalue.celldata;
        }
        else {
            if ((this.props.item.defaultvalue)) {
                text = this.props.item.defaultvalue;
            }
            const fd = rstore.getPwaStore().schemaItemDefaults.get(this.props.id);
            if (fd) {
                text = fd.value;
            }
        }
        return React.createElement(Suspense, { fallback: React.createElement(Flex, { style: { height: '100%' }, justify: 'center', align: 'center' },
                React.createElement(ScaleLoader, { color: 'green' })) },
            React.createElement(Flex, { align: 'center' },
                React.createElement(Box, { w: '90%', mr: '10px' },
                    React.createElement(ReactQuill, { theme: "snow", value: text, ref: (ref) => {
                            this.ref = ref;
                        }, modules: this.props.richtext ? this.modules : { toolbar: false }, formats: this.props.richtext ? this.formats : [], onChange: (content, delta, source, editor) => {
                            let id = this.props.id;
                            let rindex = "0";
                            if (this.props.id.indexOf("----") !== -1) {
                                const parts = this.props.id.split("----");
                                id = parts[0] + "----" + parts[1];
                                rindex = parts[2];
                            }
                            const entry = {
                                celldata: editor.getHTML(),
                                cellfindstr: editor.getText(),
                                cellid: id,
                                subrowindex: rindex,
                                schemaid: '',
                                rowindex: -1,
                                rowid: '',
                                id: '',
                                sortstring: Math.random()
                            };
                            rstore.getPwaStore().addFormEntry(this.props.id, entry);
                        } })),
                this.props.showbarcodeinput && React.createElement(Icon, { onClick: (e) => {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                        const y = (testIsMobile() ? 5 : 20);
                        BarcodeScanner(x, y, ((data, type) => {
                            let obj = "";
                            if (type === 'bc') {
                                this.ref.editor.setText(data);
                                return true;
                            }
                            return false;
                        }));
                    }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } })));
    }
};
TextInput.contextType = StoreContext;
TextInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], TextInput);
let NumberInput = class NumberInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
        const rstore = this.context;
        if (this.props.item.defaultvalue && !rstore.getPwaStore().formentry.has(this.props.id)) {
            let id = this.props.id;
            let rindex = "0";
            if (this.props.id.indexOf("----") !== -1) {
                const parts = this.props.id.split("----");
                id = parts[0] + "----" + parts[1];
                rindex = parts[2];
            }
            const entry = {
                celldata: this.props.item.defaultvalue,
                cellfindstr: this.props.item.defaultvalue,
                cellid: id,
                schemaid: '',
                rowindex: -1,
                rowid: '',
                id: '',
                subrowindex: rindex,
                sortstring: Math.random()
            };
            rstore.getPwaStore().addFormEntry(this.props.id, entry);
        }
    }
    onChange(val) {
        const rstore = this.context;
        let id = this.props.id;
        let rindex = "0";
        if (this.props.id.indexOf("----") !== -1) {
            const parts = this.props.id.split("----");
            id = parts[0] + "----" + parts[1];
            rindex = parts[2];
        }
        const entry = {
            celldata: val,
            cellfindstr: val,
            cellid: id,
            schemaid: '',
            rowindex: -1,
            rowid: '',
            id: '',
            subrowindex: rindex,
            sortstring: Math.random()
        };
        rstore.getPwaStore().addFormEntry(this.props.id, entry);
    }
    render() {
        const rstore = this.context;
        const formvalue = rstore.getPwaStore().formentry.get(this.props.id);
        let text = "";
        if (formvalue) {
            text = formvalue.celldata;
        }
        else {
            if ((this.props.item.defaultvalue !== undefined && this.props.item.defaultvalue.trim() !== '')) {
                text = this.props.item.defaultvalue;
            }
            const fd = rstore.getPwaStore().schemaItemDefaults.get(this.props.id);
            if (fd) {
                text = fd.value;
            }
        }
        return React.createElement(Flex, { align: 'center' },
            React.createElement(Input, { ref: (ref) => {
                    this.ref = ref;
                }, type: 'number', value: text, width: '90%', mr: '10px', onWheel: (e) => e.currentTarget.blur(), onChange: (e) => {
                    // if ((!e.currentTarget.value || !this.props.minval || parseFloat(e.currentTarget.value) >= this.props.minval) &&
                    //     (!e.currentTarget.value || !this.props.maxval || parseFloat(e.currentTarget.value) <= this.props.maxval)) {
                    this.onChange(e.currentTarget.value);
                } }),
            this.props.showbarcodeinput && React.createElement(Icon, { onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        if (isNaN(Number(data))) {
                            APPEvent.publishApperror("Sorry, barcode is not a valid number");
                            return false;
                        }
                        if (type === 'bc' && this.ref !== undefined && this.ref !== null) {
                            this.ref.value = data;
                            this.setState({ value: data });
                            this.onChange(data);
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }));
    }
};
NumberInput.contextType = StoreContext;
NumberInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], NumberInput);
let ColorInput = class ColorInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    convertRGBToString(rgb) {
        return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + rgb.a + ")";
    }
    convertRGBStringToRGB(rgb) {
        const clerstr = rgb.replace("rgba(", "").replace(")", "");
        const parts = clerstr.split(",");
        return { r: parseInt(parts[0]), g: parseInt(parts[1]), b: parseInt(parts[2]), a: parseFloat(parts[3]) };
    }
    render() {
        const rstore = this.context;
        const formvalue = rstore.getPwaStore().formentry.get(this.props.id);
        let text = undefined;
        if (formvalue) {
            text = this.convertRGBStringToRGB(formvalue.celldata);
        }
        else {
            if ((this.props.item.defaultvalue)) {
                text = this.convertRGBStringToRGB(this.props.item.defaultvalue);
            }
            const fd = rstore.getPwaStore().schemaItemDefaults.get(this.props.id);
            if (fd) {
                text = this.convertRGBStringToRGB(fd.value);
            }
        }
        return React.createElement(Box, null,
            text !== undefined && React.createElement(Box, { mb: '5px', w: '20px', h: '20px', borderRadius: '10px', bg: this.convertRGBToString(text) }),
            !text && React.createElement(Box, { mb: '5px' },
                React.createElement(Text, null, "Select Color")),
            React.createElement(SketchPicker, { color: text, onChange: ({ rgb }) => {
                    const value = this.convertRGBToString(rgb);
                    let id = this.props.id;
                    let rindex = "0";
                    if (this.props.id.indexOf("----") !== -1) {
                        const parts = this.props.id.split("----");
                        id = parts[0] + "----" + parts[1];
                        rindex = parts[2];
                    }
                    const entry = {
                        celldata: value,
                        cellfindstr: value,
                        cellid: id,
                        schemaid: '',
                        rowindex: -1,
                        rowid: '',
                        id: "",
                        subrowindex: rindex,
                        sortstring: Math.random()
                    };
                    rstore.getPwaStore().addFormEntry(this.props.id, entry);
                } }));
    }
};
ColorInput.contextType = StoreContext;
ColorInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ColorInput);
let DateInput = class DateInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        const rstore = this.context;
        const formvalue = (_a = rstore.getPwaStore().formentry.get(this.props.id)) !== null && _a !== void 0 ? _a : '';
        let date = undefined;
        if (formvalue && formvalue.celldata) {
            try {
                const idate = parseInt(formvalue.celldata, 10);
                date = new Date(idate);
            }
            catch (e) {
            }
        }
        return React.createElement(Box, null,
            React.createElement(DatePicker, { showTimeInput: this.props.showtime, selected: date, showTimeSelectOnly: this.props.disabledate, dateFormat: (!this.props.disabledate ? this.props.format : "") + "" + (this.props.showtime ? " HH:mm" : ''), onChange: (date) => {
                    const id = date.getTime();
                    let cid = this.props.id;
                    let rindex = "0";
                    if (this.props.id.indexOf("----") !== -1) {
                        const parts = this.props.id.split("----");
                        cid = parts[0] + "----" + parts[1];
                        rindex = parts[2];
                    }
                    const entry = {
                        celldata: id + '',
                        cellfindstr: '',
                        cellid: cid,
                        schemaid: '',
                        rowindex: -1,
                        rowid: '',
                        id: '',
                        subrowindex: rindex,
                        sortstring: Math.random()
                    };
                    rstore.getPwaStore().addFormEntry(this.props.id, entry);
                } }));
    }
};
DateInput.contextType = StoreContext;
DateInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], DateInput);
let ChoiceInput = class ChoiceInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.supportidmap = new Map();
        this.bidmap = new Map();
        this.ref = null;
        this.state = {
            id: []
        };
    }
    componentDidMount() {
        const rstore = this.context;
        this.supportidmap.clear();
        this.bidmap.clear();
        if (this.props.item.defaultvalue && !rstore.getPwaStore().formentry.has(this.props.id)) {
            let id = this.props.id;
            let rindex = "0";
            if (this.props.id.indexOf("----") !== -1) {
                const parts = this.props.id.split("----");
                id = parts[0] + "----" + parts[1];
                rindex = parts[2];
            }
            const entry = {
                celldata: this.props.item.defaultvalue,
                cellfindstr: this.props.item.defaultvalue,
                cellid: id,
                schemaid: '',
                rowindex: -1,
                rowid: '',
                id: '',
                subrowindex: rindex,
                sortstring: Math.random()
            };
            this.state = {
                id: this.props.item.defaultvalue.split(",")
            };
            rstore.getPwaStore().addFormEntry(this.props.id, entry);
        }
        for (const ci of this.props.list) {
            if (ci.supportid) {
                const parts = ci.supportid.split("---");
                if (parts.length > 1) {
                    const p1 = parts[0];
                    if (p1) {
                        this.bidmap.set(p1, ci.id);
                    }
                    const p2 = parts[1];
                    if (p2) {
                        this.supportidmap.set(p2, ci.id);
                    }
                }
            }
        }
    }
    onChange(ids) {
        const rstore = this.context;
        let id = this.props.id;
        let rindex = "0";
        if (this.props.id.indexOf("----") !== -1) {
            const parts = this.props.id.split("----");
            id = parts[0] + "----" + parts[1];
            rindex = parts[2];
        }
        const entry = {
            celldata: ids,
            cellfindstr: '',
            cellid: id,
            schemaid: '',
            rowindex: -1,
            rowid: '',
            id: "",
            subrowindex: rindex,
            sortstring: Math.random()
        };
        rstore.getPwaStore().addFormEntry(this.props.id, entry);
    }
    render() {
        var _a, _b, _c;
        const rstore = this.context;
        const formvalue = (_a = rstore.getPwaStore().formentry.get(this.props.id)) === null || _a === void 0 ? void 0 : _a.celldata;
        if (this.props.list === undefined || (this.props.list !== undefined && this.props.list.length === 0)) {
            return React.createElement(Box, null);
        }
        let dvalue = undefined;
        if (!formvalue && rstore.getPwaStore().schemaItemDefaults.has(this.props.id)) {
            const vals = ((_b = rstore.getPwaStore().schemaItemDefaults.get(this.props.id)) !== null && _b !== void 0 ? _b : '');
            dvalue = this.props.list.filter((lt) => lt.id === vals).map((lt) => ({ label: lt.name, value: lt.id }));
        }
        else if (!formvalue && this.props.item.defaultvalue) {
            dvalue = this.props.list.filter((lt) => lt.id === this.props.item.defaultvalue).map((lt) => ({ label: lt.name, value: lt.id }));
        }
        else if (formvalue) {
            const parts = formvalue.split(",");
            dvalue = this.props.list.filter((lt) => parts.indexOf(lt.id) !== -1).map((lt) => ({ label: lt.name, value: lt.id })).sort((s1, s2) => {
                const sindex = parts.indexOf(s1.value);
                const s2index = parts.indexOf(s2.value);
                return sindex - s2index;
            });
        }
        if (!dvalue) {
            dvalue = undefined;
        }
        // if (dvalue) {
        //     dvalue=this.props.list.filter((lt) => {
        //                 if (this.state.id.indexOf(lt.id) != -1) {
        //                     return true
        //                 }
        //                 return false;
        //             }).map((lt) => ({ label: lt.name, value: lt.id }));
        // }
        if (this.props.multiselect) {
            return React.createElement(Flex, { align: 'center' },
                React.createElement(Box, { width: '90%', mr: '10px' },
                    React.createElement(Select, { isMulti: true, value: dvalue, ref: (ref) => this.ref = ref, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                            this.setState({ id: e.map((lt) => lt.value) });
                            this.onChange(e.map((lt) => lt.value).join(","));
                        }, closeMenuOnSelect: false })),
                this.props.list.length > 0 && this.props.showbarcodeinputscanner && React.createElement(Icon, { onClick: (e) => {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                        const y = (testIsMobile() ? 5 : 20);
                        BarcodeScanner(x, y, ((data, type) => {
                            const aids = [];
                            if (type === 'bc') {
                                for (const ci of this.props.list) {
                                    if (ci.name.toLowerCase() === data.toLowerCase()) {
                                        const checkid = ci.id;
                                        aids.push(checkid);
                                    }
                                }
                            }
                            if (aids.length > 0) {
                                this.setState({ id: aids });
                                this.onChange(aids.join(","));
                                return true;
                            }
                            return false;
                        }));
                    }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }),
                this.props.list.length > 0 && this.props.showbarcodescanner && React.createElement(Icon, { ml: '5px', onClick: (e) => {
                        const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                        const y = (testIsMobile() ? 5 : 20);
                        BarcodeScanner(x, y, ((data, type) => {
                            var _a, _b;
                            let obj = "";
                            if (type === 'bc') {
                                const checkid = data;
                                obj = (_a = this.bidmap.get(checkid)) !== null && _a !== void 0 ? _a : '';
                            }
                            else {
                                try {
                                    const tr = JSON.parse(data);
                                    const checkid = tr.rowid;
                                    obj = (_b = this.supportidmap.get(checkid)) !== null && _b !== void 0 ? _b : '';
                                }
                                catch (ex) { }
                            }
                            if (obj) {
                                let id = this.props.id;
                                let rindex = "0";
                                if (this.props.id.indexOf("----") !== -1) {
                                    const parts = this.props.id.split("----");
                                    id = parts[0] + "----" + parts[1];
                                    rindex = parts[2];
                                }
                                let evalue = [];
                                const eentr = rstore.getPwaStore().formentry.get(this.props.id);
                                if (eentr) {
                                    evalue = eentr.celldata.split(",");
                                    if (evalue.indexOf(obj) !== -1) {
                                        return true;
                                    }
                                }
                                evalue.push(obj);
                                const entry = {
                                    celldata: evalue.join(","),
                                    cellfindstr: '',
                                    cellid: id,
                                    schemaid: '',
                                    rowindex: -1,
                                    rowid: '',
                                    id: "",
                                    subrowindex: rindex,
                                    sortstring: Math.random()
                                };
                                this.setState({ id: evalue });
                                rstore.getPwaStore().addFormEntry(this.props.id, entry);
                                if (this.ref) {
                                    const fparts = entry.celldata.split(",");
                                    const vals = this.props.list.filter((lt) => fparts.indexOf(lt.id) !== -1).map((lt) => ({ label: lt.name, value: lt.id }));
                                    this.ref.setValue(vals);
                                }
                                return true;
                            }
                            return false;
                        }));
                    }, mt: '10px', as: FaBarcode, _hover: { cursor: 'pointer' } }));
        }
        return React.createElement(Flex, { align: 'center' },
            React.createElement(Box, { width: '90%', mr: '10px' },
                React.createElement(Select, { placeholder: 'Select', value: (_c = dvalue === null || dvalue === void 0 ? void 0 : dvalue[0]) !== null && _c !== void 0 ? _c : undefined, ref: (ref) => this.ref = ref, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                        let id = this.props.id;
                        let rindex = "0";
                        if (this.props.id.indexOf("----") !== -1) {
                            const parts = this.props.id.split("----");
                            id = parts[0] + "----" + parts[1];
                            rindex = parts[2];
                        }
                        const entry = {
                            celldata: e.value,
                            cellfindstr: '',
                            cellid: id,
                            schemaid: '',
                            rowindex: -1,
                            rowid: '',
                            id: "",
                            subrowindex: rindex,
                            sortstring: Math.random()
                        };
                        this.setState({ id: [e.value] });
                        rstore.getPwaStore().addFormEntry(this.props.id, entry);
                    }, closeMenuOnSelect: true })),
            this.props.list.length > 0 && this.props.showbarcodeinputscanner && React.createElement(Icon, { onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        let checkid = "";
                        if (type === 'bc') {
                            for (const ci of this.props.list) {
                                if (ci.name.toLowerCase() === data.toLowerCase()) {
                                    checkid = ci.id;
                                    break;
                                }
                            }
                        }
                        if (checkid) {
                            this.setState({ id: [checkid] });
                            this.onChange(checkid);
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }),
            this.props.list.length > 0 && this.props.showbarcodescanner && React.createElement(Icon, { ml: '5px', onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        var _a, _b;
                        let obj = "";
                        if (type === 'bc') {
                            const checkid = data;
                            obj = (_a = this.bidmap.get(checkid)) !== null && _a !== void 0 ? _a : '';
                        }
                        else {
                            try {
                                const tr = JSON.parse(data);
                                const checkid = tr.rowid;
                                obj = (_b = this.supportidmap.get(checkid)) !== null && _b !== void 0 ? _b : '';
                            }
                            catch (ex) { }
                        }
                        if (obj) {
                            let id = this.props.id;
                            let rindex = "0";
                            if (this.props.id.indexOf("----") !== -1) {
                                const parts = this.props.id.split("----");
                                id = parts[0] + "----" + parts[1];
                                rindex = parts[2];
                            }
                            const entry = {
                                celldata: obj,
                                cellfindstr: '',
                                cellid: id,
                                schemaid: '',
                                rowindex: -1,
                                rowid: '',
                                id: "",
                                subrowindex: rindex,
                                sortstring: Math.random()
                            };
                            this.setState({ id: [obj] });
                            rstore.getPwaStore().addFormEntry(this.props.id, entry);
                            if (this.ref) {
                                const fparts = entry.celldata.split(",");
                                const vals = this.props.list.filter((lt) => fparts.indexOf(lt.id) !== -1).map((lt) => ({ label: lt.name, value: lt.id }));
                                this.ref.setValue(vals);
                            }
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: FaBarcode, _hover: { cursor: 'pointer' } }));
    }
};
ChoiceInput.contextType = StoreContext;
ChoiceInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ChoiceInput);
class SessionNameCom extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            name: '',
            sending: false
        };
    }
    componentDidMount() {
        this.setState({
            name: '',
            sending: false
        });
    }
    async setupName() {
        const rstore = this.context;
        const res = await rstore.getPwaStore().giveSetupName(this.state.name);
        if (!res) {
            APPEvent.publishApperror("Sorry, failed to update session name but you will be able to update it from within the app later.");
        }
        const name = this.state.name;
        this.setState({ sending: false, name: '' });
        await timeout(50);
        if (res) {
            rstore.getPwaStore().setSessionName(name);
        }
        else {
            rstore.getPwaStore().setSessionName("Missing");
        }
    }
    render() {
        const rstore = this.context;
        return React.createElement(Flex, { flexDirection: "column", width: "100wh", height: "100vh", backgroundColor: "gray.200", justifyContent: "center", alignItems: "center" },
            React.createElement(OfflineMessage, null),
            React.createElement(Stack, { flexDir: "column", mb: "2", justifyContent: "center", alignItems: "center" },
                React.createElement(Avatar, { bg: "teal.500" }),
                React.createElement(Heading, { color: "teal.400" }, "Give this setup a name."),
                React.createElement(Text, { textAlign: 'center', color: 'gray', fontWeight: 'bold' }, "Name will be helpful to recognise loggedin devices."),
                React.createElement(Box, { minW: { base: "90%", md: "468px" } },
                    React.createElement(Stack, { spacing: 4, p: "1rem", backgroundColor: "whiteAlpha.900", boxShadow: "md" },
                        React.createElement(FormControl, null,
                            React.createElement(InputGroup, null,
                                React.createElement(Input, { type: "email", value: this.state.name, onChange: (e) => {
                                        this.setState({ name: e.currentTarget.value });
                                    }, placeholder: "Name" }))),
                        !this.state.sending && React.createElement(Button, { borderRadius: 0, disabled: !rstore.getPwaStore().online, variant: "solid", colorScheme: "teal", width: "full", onClick: () => {
                                this.setState({ sending: true });
                                this.setupName();
                            } }, "Save"),
                        this.state.sending && React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                            React.createElement(BeatLoader, null))))));
    }
}
SessionNameCom.contextType = StoreContext;
let PwaHeader = class PwaHeader extends React.Component {
    constructor() {
        super(...arguments);
        this.ref = null;
        this.uref = null;
        this.pref = null;
    }
    orgClicked() {
    }
    componentDidMount() {
        const rstore = this.context;
    }
    setMenuPo() {
        const rstore = this.context;
        let top = 30;
        let left = window.innerWidth - 350;
        if (this.ref) {
            const rect = this.ref.getBoundingClientRect();
            top = rect.top + 32;
        }
        if (window.innerWidth < 350) {
            left = 5;
        }
        rstore.getPwaStore().orgmenustore.setPosRight(true);
        rstore.getPwaStore().orgmenustore.setLeft(5);
        rstore.getPwaStore().orgmenustore.setTop(top);
        let width = 350;
        if (window.innerWidth < 400) {
            width = window.innerWidth - 30;
        }
        rstore.getPwaStore().orgmenustore.setWidth(width);
    }
    setUserMenuPo() {
        const rstore = this.context;
        let top = 30;
        let left = window.innerWidth - 350;
        if (this.uref) {
            const rect = this.uref.getBoundingClientRect();
            top = rect.top + 32;
        }
        if (window.innerWidth < 350) {
            left = 5;
        }
        rstore.getPwaStore().usermenu.setPosRight(true);
        rstore.getPwaStore().usermenu.setLeft(5);
        rstore.getPwaStore().usermenu.setTop(top);
        let width = 350;
        if (window.innerWidth < 400) {
            width = window.innerWidth - 30;
        }
        rstore.getPwaStore().usermenu.setWidth(width);
    }
    setProcessMenuPo() {
        const rstore = this.context;
        let top = 30;
        let left = window.innerWidth - 350;
        if (this.pref) {
            const rect = this.pref.getBoundingClientRect();
            top = rect.top + 32;
        }
        rstore.getPwaStore().processmenu.setPosRight(true);
        if (window.innerWidth < 350) {
            left = 5;
            rstore.getPwaStore().processmenu.setPosRight(false);
        }
        rstore.getPwaStore().processmenu.setLeft(5);
        rstore.getPwaStore().processmenu.setTop(top);
        let width = 350;
        if (window.innerWidth < 400) {
            width = window.innerWidth - 30;
        }
        rstore.getPwaStore().processmenu.setWidth(width);
    }
    showMenu() {
        const rstore = this.context;
        this.setMenuPo();
        rstore.getPwaStore().orgmenustore.setShow(true);
        rstore.getPwaStore().usermenu.setShow(false);
        rstore.getPwaStore().processmenu.setShow(false);
    }
    async decryptOrg(or, code) {
        const rstore = this.context;
        const user = await rstore.getPwaStore().decryptOrg(or, code);
        return user;
    }
    render() {
        const rstore = this.context;
        return React.createElement(Flex, { h: 16, alignItems: 'center', justifyContent: 'space-between' },
            React.createElement(OrganisationMenu, { decryptorg: async (or, code) => {
                    return await this.decryptOrg(or, code);
                }, orgclicked: (or) => {
                    rstore.getPwaStore().orgmenustore.setSelectedOrgid(or.id);
                    rstore.getPwaStore().setSelectOrgId(or.id);
                    rstore.getPwaStore().orgmenustore.setShow(false);
                }, state: rstore.getPwaStore().orgmenustore }),
            React.createElement(UserMenu, null),
            React.createElement(ProcessMenu, null),
            React.createElement(Center, { height: '70px', className: testIsMobile() ? 'mobile-logo-container' : 'logo-container' },
                React.createElement(Image, { src: hicon })),
            !rstore.getPwaStore().online && React.createElement(Flex, { ml: '2px' },
                React.createElement(Spinner, null),
                React.createElement(Text, { ml: '4px' }, "Connecting...")),
            React.createElement(Flex, { alignItems: 'center', mr: '10px' },
                React.createElement(Stack, { direction: 'row', spacing: 2 },
                    React.createElement(Flex, { pos: 'relative', ref: (ref) => {
                            this.pref = ref;
                            this.setProcessMenuPo();
                        }, _hover: { cursor: 'pointer' }, onClick: () => {
                            rstore.getPwaStore().processmenu.setShow(true);
                            rstore.getPwaStore().setJobUpdate(false);
                            rstore.getPwaStore().usermenu.setShow(false);
                            rstore.getPwaStore().orgmenustore.setShow(false);
                        } },
                        React.createElement(Icon, { size: '30px', w: '40px', h: testIsMobile() ? '30px' : '40px', as: FcProcess }),
                        rstore.getPwaStore().jobupdate
                            && React.createElement(Box, { pos: 'absolute', top: '-10px', width: '10px', height: '10px', borderRadius: '5px', bg: 'red', right: '0px', fontWeight: 'bold', fontSize: '16px' })),
                    React.createElement(Icon, { size: '30px', w: '40px', h: testIsMobile() ? '30px' : '40px', as: rstore.getPwaStore().online ? FiWifi : FiWifiOff, color: rstore.getPwaStore().online ? 'green' : 'red' }),
                    React.createElement(Flex, { ref: (ref) => {
                            this.ref = ref;
                            this.setMenuPo();
                        } },
                        React.createElement(Icon, { color: '#404040', size: '30px', w: '40px', h: testIsMobile() ? '30px' : '40px', as: MdMenu, _hover: { cursor: 'pointer' }, onClick: () => {
                                this.showMenu();
                            } })),
                    React.createElement(Flex, { ref: (ref) => {
                            this.uref = ref;
                            this.setUserMenuPo();
                        } },
                        React.createElement(Icon, { size: '30px', w: '40px', h: testIsMobile() ? '30px' : '40px', as: FaUserCircle, _hover: { cursor: 'pointer' }, onClick: () => {
                                rstore.getPwaStore().usermenu.setShow(true);
                                rstore.getPwaStore().orgmenustore.setShow(false);
                                rstore.getPwaStore().processmenu.setShow(false);
                            } })))));
    }
};
PwaHeader.contextType = StoreContext;
PwaHeader = __decorate([
    observer
], PwaHeader);
let ProcessMenu = class ProcessMenu extends React.Component {
    render() {
        const rstore = this.context;
        if (!rstore.getPwaStore().processmenu.show) {
            return React.createElement(React.Fragment, null);
        }
        const ps = rstore.getPwaStore().processmenu;
        return React.createElement(Box, { zIndex: 10, bg: 'white', h: '200px', maxH: '200px', overflow: 'hidden', boxShadow: '5px 5px 5px 5px lightgray', right: !ps.posright ? undefined : ps.left + "px", w: ps.width + "px", pos: 'fixed', top: ps.top + "px", left: ps.posright ? undefined : ps.left + "px" },
            React.createElement(Flex, { pos: 'relative', w: '100%', h: '50px', borderBottomColor: 'lightgray', align: 'center', borderBottomWidth: '1px' },
                React.createElement(Icon, { top: '19px', w: '25px', h: '25px', right: '40px', pos: 'absolute', as: MdRefresh, _hover: { cursor: 'pointer' }, onClick: () => {
                        var _a;
                        rstore.getPwaStore().refereshQueue();
                        if (!navigator.serviceWorker) {
                            rstore.getPwaStore().processJobLocally();
                        }
                        else {
                            (_a = navigator.serviceWorker.controller) === null || _a === void 0 ? void 0 : _a.postMessage({ type: "syncdata" });
                        }
                    } }),
                React.createElement(Icon, { top: '19px', w: '25px', h: '25px', right: '10px', pos: 'absolute', as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => ps.setShow(false) })),
            React.createElement(Box, { h: '120px', maxH: '120px', overflow: 'auto' }, Array.from(rstore.getPwaStore().alljobs.values()).sort((j1, j2) => j1.jobtime - j2.jobtime).reverse().map((job) => {
                var _a, _b;
                return React.createElement(Flex, { key: job.jobid, mt: '10px', h: '70px', borderBottomWidth: '1px', borderBottomColor: 'lightgray', align: 'center', justify: 'space-between' },
                    React.createElement(Flex, { flexDir: 'column' },
                        React.createElement(Text, { fontWeight: 'bold' }, job.data.webform.name),
                        React.createElement(Text, { mt: '5px' }, DateLib.displayDate(job.jobtime))),
                    job.status === JobStatus.Pending && React.createElement(Flex, null,
                        React.createElement(Text, null, "Waiting")),
                    job.status === JobStatus.Done && React.createElement(Text, null, "Saved"),
                    (job.status === JobStatus.Progress || job.status === JobStatus.NetworkStall) && React.createElement(Flex, { align: 'center', flexDir: 'row' },
                        React.createElement(Spinner, null),
                        React.createElement(Text, { ml: '3px' }, (_a = job.jobstatusmessage) !== null && _a !== void 0 ? _a : 'Saving')),
                    job.status === JobStatus.Error && React.createElement(Flex, { align: 'center' },
                        React.createElement(Text, null, (_b = job.jobstatusmessage) !== null && _b !== void 0 ? _b : 'Failed'),
                        React.createElement(Button, { ml: '5px', onClick: () => {
                                rstore.getPwaStore().retryJob(job);
                            }, variant: 'unstyled' }, "Retry"),
                        React.createElement(Icon, { ml: '5px', as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => rstore.getPwaStore().removeJob(job.jobid) })));
            })));
    }
};
ProcessMenu.contextType = StoreContext;
ProcessMenu = __decorate([
    observer
], ProcessMenu);
let UserMenu = class UserMenu extends React.Component {
    render() {
        const rstore = this.context;
        if (!rstore.getPwaStore().usermenu.show) {
            return React.createElement(React.Fragment, null);
        }
        const ps = rstore.getPwaStore().usermenu;
        return React.createElement(Box, { zIndex: 10, bg: 'white', h: '200px', maxH: '200px', overflow: 'hidden', boxShadow: '5px 5px 5px 5px lightgray', right: !ps.posright ? undefined : ps.left + "px", w: "200px", pos: 'fixed', top: ps.top + "px", left: ps.posright ? undefined : ps.left + "px" },
            React.createElement(Flex, { pos: 'relative', w: '100%', h: '50px', borderBottomColor: 'lightgray', align: 'center', borderBottomWidth: '1px' },
                React.createElement(Icon, { top: '19px', w: '25px', h: '25px', right: '10px', pos: 'absolute', as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => ps.setShow(false) })),
            React.createElement(Flex, { align: 'center', borderBottomColor: 'lightgray', borderBottomWidth: '1px', ml: '5px', h: '50px', _hover: { cursor: 'pointer' }, onClick: () => {
                    rstore.confirmStore.setMessage("Are you sure you want to wipe this device?");
                    rstore.confirmStore.setOkCB(() => {
                        rstore.getPwaStore().wipe();
                    });
                    rstore.confirmStore.setOpen(true);
                } },
                React.createElement(Icon, { as: FaTrash }),
                React.createElement(Text, { ml: '5px' }, "Wipe Device")),
            React.createElement(Flex, { align: 'center', borderBottomColor: 'lightgray', borderBottomWidth: '1px', ml: '5px', h: '50px', _hover: { cursor: 'pointer' }, onClick: () => {
                } },
                React.createElement(Icon, { as: MdHttps }),
                React.createElement(Text, { ml: '5px' }, "Web form")));
    }
};
UserMenu.contextType = StoreContext;
UserMenu = __decorate([
    observer
], UserMenu);
