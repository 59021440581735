var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ALLViewer } from "@@/Annotation";
import { CalendarViewState } from "./CalendarViewState";
import { SchemaItemType, convertBoxToSchema } from "@@/Schema";
import { canShowRemoteEntry, convertDepToRows, decryptCell, parser } from "@@/RuleParser";
import { PWaDb } from "./PWAIndexDb";
import { TimelineState } from "./TimelineState";
import { action, makeObservable, observable } from "mobx";
import { isIpadScreen, stripHTML, testIsMobile, timeout } from "@@/Util";
import { AccessGroupState } from "./AccessgroupState";
import { CONVERTUTIL } from "@/background/store/ConvertUtil";
import { ColorStore } from "../UIComponents/ColorDialog";
import { CommentState } from "../UIComponents/Comments";
import { DateLib } from "@@/Datelib";
import { FileUploadDialogState } from "../UIComponents/FileUploadDialog";
import { FilterDialogState } from "../UIComponents/FilterDialogState";
import { FooterResultExecStore } from "./FooterResultExecStore";
import { KanbanStore } from "./KanbanStore";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { NotesFilterState } from "./NotesFilterState";
import { ProjectStore } from "@/background/store/ProjectStore";
import { SchemaUIRow } from "./SchemaUiRow";
import { SelectMemberDialogState } from "../UIComponents/SelectMemberDialog";
import { ServerPath } from "@@/ServerPath";
import { comm } from "@/Comm/comm";
import { toast } from "react-toastify";
export class PwaRemoteStore {
    constructor() {
        this.width = testIsMobile() || isIpadScreen() ? window.innerWidth - 50 : window.innerWidth / 2;
        this.left = isIpadScreen() ? 20 : 100;
        this.top = 100;
        this.dragging = false;
        this.title = "";
        this.show = false;
        this.linkingmode = false;
        this.size = 0;
        this.schemas = new Map();
        this.sortedschemaitems = [];
        this.activelterid = "";
        this.loading = false;
        this.activeschema = "";
        this.loadingcontent = false;
        this.showform = false;
        this.storingform = false;
        this.reqdid = "";
        this.reqsid = "";
        this.reqrowid = "";
        this.formentry = new Map();
        // @observable public rowsMap: SchemaRow[] = [];
        this.rowsMap = new Map();
        this.newrowsmap = [];
        this.buildingview = false;
        this.showlinkdialog = false;
        this.showactiveviewmenu = false;
        this.activeview = "grid";
        this.commentstate = new CommentState();
        this.smstate = new SelectMemberDialogState();
        this.lastcommenttime = -1;
        this.showstatuesmenu = false;
        this.loadmore = false;
        this.activecardsttaus = "";
        this.activecardgroupby = "";
        this.activecardstatusname = "";
        this.activecardgroupname = "";
        this.startnotes = false;
        this.showgroupbymenu = false;
        this.showganttstartdate = false;
        this.showganttstartdatename = "Task Start Date";
        this.showganttenddatename = "Task End Date";
        this.colorfieldname = "Default Colors";
        this.colorfieldid = "";
        this.showcolorfield = false;
        this.showchartmenu = false;
        this.links = [];
        this.selectedrows = new Map();
        this.schemanotesloading = false;
        this.accessstorestate = new AccessGroupState();
        this.activeloadednotesref = "";
        this.deletenoteids = new Set();
        this.shownotespanel = !isIpadScreen() ? true : false;
        this.sindex = 0;
        this.descfieldname = "";
        this.descfieldid = "";
        this.showdescfield = false;
        this.loadingcomments = false;
        this.deletingnote = false;
        this.enableannotation = true;
        this.showfilter = false;
        this.showbookmarklinkdetail = new Set();
        this.colorstate = new ColorStore();
        this.lastnotesupdatetime = -1;
        this.notesbyid = new Map();
        this.activeaccessids = new Set([ALLViewer]);
        this.appliedaclstr = "---";
        this.editnoteid = "";
        this.editmode = false;
        this.showcomments = false;
        this.loadingnotes = false;
        this.authors = new Map();
        this.activerequestid = "";
        this.notefilterstate = new NotesFilterState();
        this.showganttenddate = false;
        this.ganttstartid = "";
        this.ganttendid = "";
        this.ganttasknameid = "";
        this.ganttasknamefieldname = "";
        this.activerowid = "";
        this.savingrow = false;
        this.savingnote = false;
        this.editrowproperty = new Map();
        this.activerowindex = -1;
        this.notelinksdialogleft = 0;
        this.notelinksdialogtop = 70;
        this.chartstate = new Map();
        this.colors = ['#fec1ce', '#d7f3f2', '#cbd8f9'];
        this.activebuilingid = -1;
        this.kanbanstatus = new KanbanStore();
        this.timelinestatus = new TimelineState();
        this.calendarstate = new CalendarViewState();
        this.showinoutlinks = new Set();
        this.activedatasheet = "";
        this.fileuploadstate = new FileUploadDialogState();
        this.activereqid = "";
        this.objectviewer = false;
        this.checkingandcollecting = false;
        this.rowRules = new Map();
        this.depschemarows = new Map();
        this.depschema = new Map();
        this.fetchingdepend = false;
        this.schemasmap = new Map();
        this.waitingentries = [];
        this.parsingentry = new Set();
        this.gettinganas = false;
        this.anas = new Map();
        this.anasschema = new Map();
        this.colwithschemarules = new Set();
        this.dependentdata = new Map();
        this.footerruleexestate = new FooterResultExecStore();
        this.hiddenrows = new Set();
        this.showrowsilter = false;
        this.showcolumnview = false;
        this.hiddencolumns = new Set();
        this.filterdialogstate = new FilterDialogState();
        this.cfx = 0;
        this.cfy = 0;
        this.selectallrows = false;
        this.activeaction = "unknown";
        this.loadedentry = 0;
        this.rowsarrids = [];
        this.activecustomtab = "";
        this.loadingreportschema = false;
        this.activereportrowids = [];
        this.activeschemametadatamap = new Set();
        this.activewebform = undefined;
        this.uirows = [];
        this.currentitems = [];
        this.reportSchemaItems = new Set();
        this.activeuserid = "";
        makeObservable(this);
    }
    getActiveUserId() {
        return this.activeuserid + "----" + this.activeschema;
    }
    setLoadingContent(loading) {
        this.loadingcontent = loading;
    }
    async getRemoteRows(schemaid, rowids, userid, reportid) {
        var _a, _b;
        this.setLoadingContent(true);
        if (this.activewebform === undefined || !((_a = this.activewebform) === null || _a === void 0 ? void 0 : _a.letrkey)) {
            this.setLoadingContent(false);
            toast("Failed to load details report.", {
                position: 'top-center',
                type: 'error'
            });
            return;
        }
        const box = this.activewebform.letrkey;
        const aschema = this.schemas.get(this.activeschema);
        if (!aschema || !box) {
            toast("Failed to load details report.", {
                position: 'top-center',
                type: 'error'
            });
            return;
        }
        // lets fetch content now and do the stuff here!!
        let res = await this.loadRemoteRows(schemaid, rowids, userid, reportid);
        if (res === 'NetworkError') {
            const offdata = await PWaDb.getSchemaReport(reportid);
            if (!offdata) {
                toast("Network issues failed to load rows", {
                    position: 'top-center',
                    type: 'error'
                });
                this.setLoadingContent(false);
                return;
            }
            res = offdata;
        }
        // now lets build the rows and othe rneccessary bits and we done!!
        if (!res) {
            this.setLoadingContent(false);
            return;
        }
        if (res.rows.length === 0) {
            this.setLoadingContent(false);
            return;
        }
        await this.addRowInline(res.rows, true, true);
        // lets do something clever now!
        // firts rebuild the 
        this.clearRowRules();
        if (res.rowrules) {
            for (const rr of res.rowrules) {
                this.addRowRule(rr);
            }
        }
        await this.addRuleEntryToRows(aschema);
        await this.addRowsToMobs();
        const mapofdata = new Map();
        if (res.dsch) {
            for (const en of res.dsch) {
                if (!mapofdata.has(en.id)) {
                    mapofdata.set(en.id, []);
                }
                (_b = mapofdata.get(en.id)) === null || _b === void 0 ? void 0 : _b.push(...en.entries);
            }
        }
        // we should create some missing columns that we might need!!
        // there might be rule 
        this.depschemarows = convertDepToRows(mapofdata);
        if (res.shs) {
            for (const shh of res.shs) {
                this.schemasmap.set(shh.id, shh);
            }
        }
        this.depschemarows.set(aschema.id, Array.from(this.rowsMap.values()));
        this.setLoadingContent(false);
        return;
    }
    clearRowMap() {
        this.rowsMap.clear();
        this.rowsarrids.splice(0, this.rowsarrids.length);
        this.uirows.splice(0, this.uirows.length);
    }
    addRow(r, ignoremobx) {
        this.rowsMap.set(r.rowid, r);
        this.rowsarrids.push(r);
        if (!ignoremobx) {
            const sr = new SchemaUIRow();
            sr.addEntries(r.entry);
            sr.rowid = r.rowid;
            sr.rowindex = r.rowindex;
            sr.childrenschemalist = r.childrenschemalist;
            sr.linkedids = r.linkedids;
            sr.objsdec = r.objsdec;
            sr.shsdec = r.shsdec;
            sr.referenceids = r.referenceids;
            this.uirows.push(sr);
        }
    }
    addRowToMobx(r) {
        const sr = new SchemaUIRow();
        sr.addEntries(r.entry);
        sr.rowid = r.rowid;
        sr.rowindex = r.rowindex;
        sr.childrenschemalist = r.childrenschemalist;
        sr.linkedids = r.linkedids;
        sr.objsdec = r.objsdec;
        sr.shsdec = r.shsdec;
        sr.referenceids = r.referenceids;
        this.uirows.push(sr);
    }
    async addRowsToMobs() {
        let index = 0;
        // const itemit = this.selecteditemid;
        for (const r of this.rowsarrids) {
            // if (itemit !== this.selecteditemid) return;
            this.addRowToMobx(r);
            if (index % 1000 === 0)
                await timeout(10);
        }
    }
    async addRowInline(row, clear = true, ignoremobx = false) {
        // const itemit = this.selecteditemid;
        if (clear) {
            this.clearRowMap();
        }
        let index = 0;
        for (const r of row) {
            // if (itemit !== this.selecteditemid) return;
            this.addRow(r, ignoremobx);
            if (index > 0 && index % 1000 == 0) {
                await timeout(100);
            }
            ;
            index++;
        }
        await timeout(10);
    }
    async addRowInline_old(row, clear = true) {
        if (clear) {
            this.rowsMap.clear();
            this.rowsarrids.splice(0, this.rowsarrids.length);
            this.uirows.splice(0, this.uirows.length);
        }
        let index = 0;
        for (const r of row) {
            this.rowsMap.set(r.rowid, r);
            this.rowsarrids.push(r);
            const sr = new SchemaUIRow();
            sr.addEntries(r.entry);
            sr.rowid = r.rowid;
            sr.rowindex = r.rowindex;
            sr.childrenschemalist = r.childrenschemalist;
            sr.linkedids = r.linkedids;
            sr.objsdec = r.objsdec;
            sr.shsdec = r.shsdec;
            sr.referenceids = r.referenceids;
            this.uirows.push(sr);
            if (index > 0 && index % 1000 == 0) {
                await timeout(100);
            }
            ;
            index++;
        }
        await timeout(10);
    }
    async addRuleEntryToRows(sh) {
        let i = 0;
        for (const row of this.rowsMap.values()) {
            this.addRuleEntryToRow(row, sh);
            if (i > 0 && i % 1000 === 0)
                await timeout(50);
            i++;
        }
    }
    addRuleEntryToRow(row, sh) {
        var _a;
        const rulebycellid = new Map();
        if (sh && sh.rules) {
            for (const rule of sh.rules) {
                rulebycellid.set(rule.colid, rule);
            }
        }
        const rowrules = this.rowRules.get(sh.id + "~~~" + row.rowid);
        const rowrmap = new Map();
        if (rowrules) {
            for (const r of rowrules.data) {
                rowrmap.set(r.colid, r);
            }
        }
        for (const [k, v] of rulebycellid) {
            if (!rowrmap.has(k)) {
                rowrmap.set(k, v);
            }
        }
        const emap = new Map();
        for (const en of row.entry) {
            const id = en.cellid.split("----");
            if (!emap.has(id[0])) {
                emap.set(id[0], []);
            }
            let colrule = rulebycellid.get(en.cellid);
            if (colrule)
                colrule.isshrule = true;
            if (rowrmap.size > 0) {
                let cr = rowrmap.get(en.cellid);
                if (cr) {
                    colrule = cr;
                }
            }
            if (en.ruleopen) {
                //if this already had it
                en.decrypted = false;
            }
            en.ruleopen = colrule;
            if (en.ruleopen) {
                // new rule 
                en.decrypted = false;
            }
            (_a = emap.get(id[0])) === null || _a === void 0 ? void 0 : _a.push(en);
        }
        // now lets go over all cells needing rule
        for (const [k, v] of rowrmap) {
            if (k.indexOf("----") !== -1) {
                // lets add entry to this as well now!
                const parts = k.split("----");
                const ens = emap.get(parts[0]);
                if (!ens || (ens === null || ens === void 0 ? void 0 : ens.length) === 0)
                    continue;
                // we have subrows lets add entry for each
                const subrows = this.arrangeListCells(ens);
                const entrymap = new Map();
                // now we have these subrows lets see if we can make sense of this now
                for (const en of ens) {
                    if (!en.subrowindex)
                        continue;
                    entrymap.set(en.cellid + "----" + en.subrowindex, en);
                }
                for (const sr of subrows) {
                    const sid = k + "----" + sr;
                    if (!entrymap.has(sid)) {
                        let en = {
                            celldata: '',
                            cellfindstr: '',
                            cellid: k,
                            schemaid: sh.id,
                            rowindex: row.rowindex,
                            rowid: row.rowid,
                            subrowindex: sr,
                            id: LetrwingCrypto.getUUID(),
                            sortstring: Math.random(),
                            decrypted: false
                        };
                        const id = en.cellid.split("----");
                        if (!emap.has(id[0])) {
                            emap.set(id[0], []);
                        }
                        let colrule = rulebycellid.get(en.cellid);
                        if (colrule)
                            colrule.isshrule = true;
                        if (rowrmap.size > 0) {
                            let cr = rowrmap.get(en.cellid);
                            if (cr) {
                                colrule = cr;
                            }
                        }
                        en.ruleopen = colrule;
                        row.entry.push(en);
                    }
                }
            }
            else {
                if (!emap.has(k)) {
                    let en = {
                        celldata: '',
                        cellfindstr: '',
                        cellid: k,
                        schemaid: sh.id,
                        rowindex: row.rowindex,
                        rowid: row.rowid,
                        subrowindex: "",
                        id: LetrwingCrypto.getUUID(),
                        sortstring: Math.random(),
                        decrypted: false
                    };
                    const id = en.cellid.split("----");
                    if (!emap.has(id[0])) {
                        emap.set(id[0], []);
                    }
                    let colrule = rulebycellid.get(en.cellid);
                    if (colrule)
                        colrule.isshrule = true;
                    if (rowrmap.size > 0) {
                        let cr = rowrmap.get(en.cellid);
                        if (cr) {
                            colrule = cr;
                        }
                    }
                    en.ruleopen = colrule;
                    row.entry.push(en);
                }
            }
        }
    }
    arrangeListCells(en) {
        // the way to arrange these will be via the 
        var _a;
        en.sort((en1, en2) => {
            var _a, _b;
            const s1 = (_a = en1.subrowindex) !== null && _a !== void 0 ? _a : -1;
            const s2 = (_b = en2.subrowindex) !== null && _b !== void 0 ? _b : -1;
            if (s1 === -1 && s2 === -1) {
                return 0;
            }
            if (s1 === -1) {
                return 1;
            }
            if (s2 === -1) {
                return -1;
            }
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (parts1[0] === parts2[0]) {
                // if(parts1.length < 2 || parts2.length < 2) {
                //     return -1;
                // }
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        let trow = [];
        const rows = [];
        for (const e of en) {
            const s1 = (_a = e.subrowindex) !== null && _a !== void 0 ? _a : -1;
            if (s1 !== -1) {
                if (trow.indexOf(s1) === -1) {
                    trow.push(s1);
                }
            }
        }
        this.setLoadingContent(false);
        return trow;
    }
    addRowRule(rule) {
        if (rule.data && rule.data.length > 0) {
            this.rowRules.set(rule.id, rule);
        }
    }
    clearRowRules() {
        this.rowRules.clear();
    }
    async loadRemoteRows(schemaid, rowids, userid, reportid) {
        var _a, _b, _c;
        if (this.activewebform === undefined || !((_a = this.activewebform) === null || _a === void 0 ? void 0 : _a.letrkey)) {
            return undefined;
        }
        const box = this.activewebform.letrkey;
        const sh = this.schemas.get(this.activeschema);
        if (!sh) {
            return undefined;
        }
        const cids = [];
        if (sh.items && sh.items.length > 0) {
            for (let i = 0; i < sh.items.length; i++) {
                const item = sh.items[i];
                cids.push(item.id);
                if (item.type === SchemaItemType.List) {
                    const litem = item;
                    if (litem.items && litem.items.length > 0) {
                        cids.push(...litem.items.map((il) => litem.id + "----" + il.id));
                    }
                }
            }
        }
        const req = {
            cellids: cids,
            userid: userid,
            webformid: this.activewebform.id,
            schemaid: sh.id,
            rowids: rowids,
            letrid: this.activewebform.letrid,
        };
        const rentries = await comm.postWithNetwork(ServerPath.pwagetRemoteRows, req);
        if (rentries === 'Unauthorised') {
            return undefined;
        }
        if (rentries === 'NetworkError') {
            return 'NetworkError';
        }
        if (!rentries) {
            return undefined;
        }
        // lets now get these entries 
        const ret = new Map();
        const pubents = [];
        const entries = rentries.entries;
        if (entries && entries.length > 0) {
            const fent = entries[0];
            const dobj = new Map();
            const sobj = new Map();
            if (fent && box !== undefined) {
                if (fent.objs) {
                    const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, sh.letrid);
                    for (const fe of dojs) {
                        dobj.set(fe.id, fe);
                    }
                }
                if (fent.shs) {
                    for (const sh of fent.shs) {
                        const sok = CONVERTUTIL.convertBoxToSchema(sh, sh.letrid, box);
                        if (sok)
                            sobj.set(sh.id, sok);
                    }
                }
            }
            let i = -1;
            for (const ent of entries) {
                if (i > 0 && i % 5000 == 0)
                    await timeout(10);
                i++;
                if (!ent.rowid) {
                    continue;
                }
                // we should check and sabe all the public key saved ones as well!!
                if (ent.linkedids && ent.linkedids.length > 0) {
                    // lets attach it's object here!
                    if (!ent.objsdec)
                        ent.objsdec = [];
                    if (!ent.schsdec)
                        ent.schsdec = [];
                    for (const lid of ent.linkedids) {
                        const parts = lid.split("-");
                        if (parts.length < 2)
                            continue;
                        if (lid.startsWith("objs-")) {
                            const bk = dobj.get(parts[1]);
                            if (bk) {
                                ent.objsdec.push(bk);
                            }
                        }
                        else if (lid.startsWith("dch-") && parts.length > 2) {
                            const sh = sobj.get(parts[2]);
                            if (sh) {
                                ent.schsdec.push(sh);
                            }
                        }
                    }
                }
                if (ent.schemaid !== sh.id) {
                    ent.rowindex = ent.rowindex / 100000;
                }
                if (!ret.has(ent.rowid)) {
                    ret.set(ent.rowid, {
                        rowindex: ent.rowindex, rowid: ent.rowid, entry: [],
                        childrenschemalist: ent.schemaid === sh.id ? ent.childrenschemalist : []
                    });
                }
                // we should also check if the ent is 
                if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                    continue;
                }
                (_b = ret.get(ent.rowid)) === null || _b === void 0 ? void 0 : _b.entry.push(ent);
            }
        }
        const rules = await this.getAllRules(sh.id, sh.letrid, box, userid, this.activewebform);
        // we have remote items now and we just need some simple stuff now!!
        const depns = await this.checkAndGetAllRemoteItems(sh.items, sh.letrid, sh.id, this.activewebform, userid);
        // lets get all depednt too!
        if (depns === null || depns === void 0 ? void 0 : depns.resp) {
            this.decryptAndSetupDepRemoteItems(depns.resp, (_c = depns.dsch) !== null && _c !== void 0 ? _c : [], sh);
        }
        let fetchd = false;
        if (sh.rules && sh.rules.length > 0) {
            fetchd = true;
        }
        else if (rules && rules.length > 0) {
            fetchd = true;
        }
        const shmap = new Map();
        const darr = [];
        if (fetchd && ret.size > 0) {
            let ens = [];
            for (const row of ret.values()) {
                ens.push(...row.entry);
            }
            const depps = await this.getAllDependentEnt(rules, sh, sh.letrid, sh.id, ens, shmap, box, this.activewebform, userid);
            for (const [k, v] of depps) {
                const dd = {
                    id: k,
                    entries: Array.from(v.values())
                };
                darr.push(dd);
            }
        }
        const resp = {
            id: reportid,
            rows: Array.from(ret.values()),
            remoteres: depns,
            shs: Array.from(shmap.values()),
            dsch: darr,
            schemaid: sh.id,
            rowrules: rules
        };
        // update schema data there now!!
        await PWaDb.addSchemaReport(resp);
        return resp;
    }
    async getAllRules(schemaid, letrid, box, appuserid, form) {
        var _a, _b;
        const req = {
            schemaid: schemaid,
            letrid: letrid,
            creatorid: appuserid + "----" + form.id
        };
        const res = await comm.post(ServerPath.pwagetRowRule, req);
        if (!res) {
            return [];
        }
        const resp = [];
        for (const r of res) {
            try {
                const bstr = LetrwingCrypto.decryptSecretBox(r.data, box);
                if (!bstr)
                    continue;
                const rules = JSON.parse(bstr);
                resp.push({
                    schemaid: r.schemaid,
                    letrid: r.letrid,
                    id: r.id,
                    data: (_a = rules.numberdata) !== null && _a !== void 0 ? _a : [],
                    rowid: r.rowid,
                    datarules: (_b = rules.datarules) !== null && _b !== void 0 ? _b : []
                });
            }
            catch (ex) { }
        }
        return resp;
    }
    async checkAndGetAllRemoteItems(items, letrid, sid, form, appuserid) {
        var _a;
        const ritems = [];
        for (const item of items) {
            if (item.type === SchemaItemType.Remote) {
                ritems.push(item);
            }
            else if (item.type === SchemaItemType.List) {
                for (const iii of item.items) {
                    if (iii.item.type === SchemaItemType.Remote) {
                        const litem = Object.assign(Object.assign({}, item), { id: item.id + "----" + iii.item.id });
                        ritems.push(litem);
                    }
                }
            }
        }
        if (ritems.length > 0) {
            let ids = new Set();
            for (const it of ritems) {
                const rit = it;
                if (rit.did && rit.sid && rit.cid) {
                    ids.add(rit.did + "~~~" + rit.sid + "~~~" + rit.cid);
                    if (rit.conds && rit.conds.length > 0) {
                        for (const cond of rit.conds) {
                            for (const p of cond.params) {
                                ids.add(rit.did + "~~~" + rit.sid + "~~~" + p.leftcond);
                            }
                        }
                    }
                }
            }
            if (ids.size > 0) {
                const req = {
                    letrid: letrid,
                    schemaid: sid,
                    schemafields: Array.from(ids),
                    dataid: appuserid + "----" + form.id
                };
                const res = await this.getAllDependent(req, form.letrkey, form.id);
                // now lets check and do the dance here now!!
                // we should check and get all stuff 
                // lets check which one need working now and we should call them 
                const darr = [];
                if (res) {
                    for (const rd of res) {
                        if (!rd.schemaopen)
                            continue;
                        if (rd.schemaopen.rules || rd.rulesopen) {
                            // shall we just get all the depend
                            const data = await this.getAllDependentEnt((_a = rd.rulesopen) !== null && _a !== void 0 ? _a : [], rd.schemaopen, req.letrid, rd.schema.id, rd.entries, new Map(), form.letrkey, form, appuserid);
                            // now we have everything we need and we can now do some parsing of rules as well?
                            for (const [k, v] of data) {
                                const dd = {
                                    id: k,
                                    entries: Array.from(v.values())
                                };
                                darr.push(dd);
                            }
                        }
                    }
                }
                const ret = {
                    resp: res,
                    dsch: darr
                };
                return ret;
            }
        }
        return undefined;
    }
    async getAllDependentEnt(rules, sh, letrid, wsid, ent, schemacollection, box, form, appuserid) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        let databyschemacol = new Map();
        const rulebycellid = new Map();
        if (sh.rules) {
            for (const rule of sh.rules) {
                rulebycellid.set(rule.colid, rule);
            }
        }
        if (rules) {
            for (const r of rules) {
                for (const sr of r.data) {
                    rulebycellid.set(r.rowid + "---" + sr.colid, sr);
                }
            }
        }
        let mycellids = new Set();
        for (const en of ent) {
            mycellids.add(en.cellid);
            const rid = en.rowid + "---" + en.cellid;
            if (rulebycellid.has(rid)) {
                en.ruleopen = rulebycellid.get(rid);
            }
            else if (rulebycellid.has(en.cellid) && !en.ruleopen) {
                en.ruleopen = rulebycellid.get(en.cellid);
            }
        }
        const alldata = new Set();
        const columnidsBySh = new Map();
        const akey = sh.accesskey;
        const nonce = sh.nonce;
        if (sh.rules) {
            // have to use nested as this is okay
            for (const rule of sh.rules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_a = columnidsBySh.get(sh.id)) === null || _a === void 0 ? void 0 : _a.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                (_b = columnidsBySh.get(sh.id)) === null || _b === void 0 ? void 0 : _b.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        for (const ru of rules) {
            for (const rule of ru.data) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_c = columnidsBySh.get(sh.id)) === null || _c === void 0 ? void 0 : _c.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_d = columnidsBySh.get(sh.id)) === null || _d === void 0 ? void 0 : _d.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (alldata.size > 0) {
            const req = {
                letrid: letrid,
                schemaid: wsid,
                schemafields: Array.from(alldata),
                dataid: appuserid + "----" + form.id
            };
            const res = await this.getAllDependent(req, box, "");
            if (res) {
                const schemacellids = new Map();
                for (const d of alldata) {
                    const parts = d.split("~~~");
                    if (!schemacellids.has(parts[1])) {
                        schemacellids.set(parts[1], new Set());
                    }
                    (_e = schemacellids.get(parts[1])) === null || _e === void 0 ? void 0 : _e.add(parts[2]);
                }
                // fetch recurssively everything now!!
                for (const d of res) {
                    const dsh = d.schema;
                    let dens = d.entries;
                    let cellids = (_f = schemacellids.get(d.schema.id)) !== null && _f !== void 0 ? _f : new Set();
                    const dshopen = convertBoxToSchema(dsh, letrid, box);
                    if (!dshopen)
                        continue;
                    schemacollection.set(dshopen.id, dshopen);
                    let entrymap = new Map();
                    if (dens) {
                        for (const de of dens) {
                            let id = de.rowid + "~~~" + de.cellid;
                            if (!entrymap.has(id)) {
                                entrymap.set(id, []);
                            }
                            (_g = entrymap.get(id)) === null || _g === void 0 ? void 0 : _g.push(de);
                        }
                    }
                    const srules = new Map();
                    const rowrules = new Map();
                    if (dshopen.rules) {
                        for (const shrule of dshopen.rules) {
                            srules.set(shrule.colid, shrule);
                        }
                    }
                    if (d.rulesopen) {
                        for (const rr of d.rulesopen) {
                            for (const shrule of rr.data) {
                                rowrules.set(rr.rowid + "~~~" + shrule.colid, shrule);
                            }
                        }
                    }
                    // now check what all columns we need 
                    for (const cellid of cellids) {
                        // we need to make sure for all rows the entries their otherwise we need to add these 
                        for (const row of d.totalrows) {
                            const rid = row.id;
                            if (cellid.indexOf("----") !== -1) {
                                const cp = cellid.split("----")[0];
                                const rowsubens = dens.filter((en) => en.rowid === rid && en.cellid.split("----")[0] === cp);
                                if (rowsubens.length > 0) {
                                    // we have some sub rows lets get all subriowindex now and if we have entry for this great otherwise we need to add one
                                    const subrows = new Set();
                                    for (const re of rowsubens) {
                                        if (re.subrowindex) {
                                            subrows.add(re.subrowindex);
                                        }
                                    }
                                    // now we have all subrow lets check if we have all ids
                                    const myens = entrymap.get(row.id + "~~~" + cellid);
                                    const enmap = new Map();
                                    for (const mye of myens !== null && myens !== void 0 ? myens : []) {
                                        enmap.set((_h = mye.cellid + "----" + mye.subrowindex) !== null && _h !== void 0 ? _h : '', mye);
                                    }
                                    // okay lets check if we have all the data or create some of them
                                    for (const sr of subrows) {
                                        const me = enmap.get(cellid + "----" + sr);
                                        if (!me) {
                                            dens.push({
                                                celldata: '',
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: dsh.id,
                                                rowindex: row.rowidex,
                                                rowid: row.id,
                                                subrowindex: sr,
                                                id: LetrwingCrypto.getUUID(),
                                                sortstring: Math.random(),
                                                decrypted: false
                                            });
                                        }
                                    }
                                }
                                continue;
                            }
                            const cid = rid + "~~~" + cellid;
                            if (!entrymap.has(cid)) {
                                entrymap.set(cid, [{
                                        celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                        cellfindstr: '',
                                        cellid: cellid,
                                        schemaid: dsh.id,
                                        rowindex: row.rowidex,
                                        rowid: row.id,
                                        subrowindex: "",
                                        id: LetrwingCrypto.getUUID(),
                                        sortstring: Math.random(),
                                        decrypted: false
                                    }]);
                                dens.push({
                                    celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                    cellfindstr: '',
                                    cellid: cellid,
                                    schemaid: dsh.id,
                                    rowindex: row.rowidex,
                                    rowid: row.id,
                                    subrowindex: "",
                                    id: LetrwingCrypto.getUUID(),
                                    sortstring: Math.random(),
                                    decrypted: false
                                });
                            }
                        }
                    }
                    // these 
                    if (dshopen.rules || d.rulesopen) {
                        let filterentris = [];
                        for (const den of dens) {
                            cellids.add(den.cellid);
                            if (dshopen) {
                                const rowrule = rowrules.get(den.rowid + "~~~" + den.cellid);
                                if (rowrule) {
                                    den.ruleopen = rowrule;
                                }
                                else {
                                    const shrule = srules.get(den.cellid);
                                    if (shrule) {
                                        den.ruleopen = shrule;
                                    }
                                }
                                // if no rule open this one it's a leaf
                                if (!den.ruleopen) {
                                    decryptCell(den, dshopen);
                                }
                                const id = dshopen.datasheetid + "~~~" + dshopen.id + "~~~" + den.cellid;
                                if (!databyschemacol.has(id)) {
                                    databyschemacol.set(id, new Map());
                                }
                                (_j = databyschemacol.get(id)) === null || _j === void 0 ? void 0 : _j.set(den.id, den);
                            }
                        }
                        if (dshopen) {
                            let isschemarule = false;
                            let colrules = new Map();
                            if (dshopen.rules) {
                                // lets check if rule involve on our column and on other entity!
                                for (const shrule of dshopen.rules) {
                                    colrules.set(shrule.colid, shrule);
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    isschemarule = true;
                                                    break;
                                                }
                                                // we should also check condisiotn as well
                                                if (param.conds) {
                                                    for (const cond of param.conds) {
                                                        for (const subcon of cond.params) {
                                                            if (subcon.leftcond && !!cellids.has(subcon.leftcond)) {
                                                                isschemarule = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (isschemarule)
                                                break;
                                        }
                                    }
                                    if (isschemarule)
                                        break;
                                }
                                if (isschemarule) {
                                    filterentris.push(...dens);
                                }
                            }
                        }
                        const rules = d.rulesopen;
                        let cellrules = [];
                        if (rules) {
                            for (const rr of rules) {
                                let breakit = false;
                                for (const shrule of rr.data) {
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    cellrules.push(rr);
                                                    breakit = true;
                                                    break;
                                                }
                                                if (param.conds) {
                                                    for (const cond of param.conds) {
                                                        for (const subcon of cond.params) {
                                                            if (subcon.leftcond && !!cellids.has(subcon.leftcond)) {
                                                                cellrules.push(rr);
                                                                breakit = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (breakit)
                                                break;
                                        }
                                    }
                                    if (breakit)
                                        break;
                                }
                            }
                        }
                        if (cellrules.length > 0 || filterentris.length > 0) {
                            if (dshopen) {
                                const dmap = await this.getAllDependentEnt(cellrules, dshopen, letrid, wsid, dens, schemacollection, box, form, appuserid);
                                if (dmap) {
                                    // lets go over it
                                    for (const [k, v] of dmap) {
                                        const id = k;
                                        if (!databyschemacol.has(id)) {
                                            databyschemacol.set(id, new Map());
                                        }
                                        // possible to have duplicates too other side might beed to fix that!
                                        for (const [vk, vn] of v) {
                                            (_k = databyschemacol.get(id)) === null || _k === void 0 ? void 0 : _k.set(vn.id, vn);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // we do need to re do this as 
            }
        }
        // we should maybe fill in the missing cells now!
        return databyschemacol;
    }
    async getAllDependent(req, box, id) {
        const url = ServerPath.pwagetSchemaDependent;
        const res = await comm.post(url, req);
        if (res !== undefined) {
            for (const r of res.data) {
                r.schemaopen = convertBoxToSchema(r.schema, req.letrid, box);
                if (r.rules) {
                    const resp = [];
                    for (const ro of r.rules) {
                        try {
                            const bstr = LetrwingCrypto.decryptSecretBox(ro.data, box);
                            if (!bstr)
                                continue;
                            const rules = JSON.parse(bstr);
                            resp.push({
                                schemaid: ro.schemaid,
                                letrid: ro.letrid,
                                id: ro.id,
                                data: rules.numberdata,
                                datarules: rules.datarules,
                                rowid: ro.rowid,
                            });
                        }
                        catch (ex) { }
                    }
                    r.rulesopen = resp;
                }
            }
            return res.data;
        }
        return undefined;
    }
    decryptAndSetupDepRemoteItems(data, darr, sh) {
        var _a;
        const mapofdpsh = new Map();
        data.forEach((dt) => {
            mapofdpsh.set(dt.schema.id, dt);
        });
        const mapofdata = new Map();
        const mapofschema = new Map();
        let doneenid = new Set();
        data.forEach((dt) => {
            var _a;
            if (dt.schemaopen) {
                mapofschema.set(dt.schema.id, dt.schemaopen);
            }
            for (const en of dt.entries) {
                if (!doneenid.has(en.id)) {
                    if (!mapofdata.has(dt.schema.datasheetid + "~~~" + dt.schema.id + "~~~" + en.cellid)) {
                        mapofdata.set(dt.schema.datasheetid + "~~~" + dt.schema.id + "~~~" + en.cellid, []);
                    }
                    (_a = mapofdata.get(dt.schema.datasheetid + "~~~" + dt.schema.id + "~~~" + en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
                    doneenid.add(en.id);
                }
            }
        });
        for (const en of darr) {
            if (!mapofdata.has(en.id)) {
                mapofdata.set(en.id, []);
            }
            for (const e of en.entries) {
                if (!doneenid.has(e.id)) {
                    (_a = mapofdata.get(en.id)) === null || _a === void 0 ? void 0 : _a.push(e);
                    doneenid.add(e.id);
                }
            }
        }
        // we should create some missing columns that we might need!!
        // there might be rule 
        const depschemarows = convertDepToRows(mapofdata);
        sh.items.forEach((it) => {
            var _a, _b, _c, _d, _e, _f, _g;
            if (it.type === SchemaItemType.Remote) {
                const srit = it;
                if (srit.did && srit.sid && srit.cid) {
                    const dp = mapofdpsh.get(srit.sid);
                    if (dp !== undefined && dp.schemaopen !== undefined) {
                        const items = (_a = dp.schemaopen.items) !== null && _a !== void 0 ? _a : [];
                        let ischoice = false;
                        for (const item of items) {
                            if (item.id === srit.cid) {
                                // found it 
                                if (item.type === SchemaItemType.Choice) {
                                    // ohh all we need here is choice
                                    ischoice = true;
                                    const id = srit.id + "---" + srit.sid + "---" + srit.cid;
                                    this.dependentdata.set(id, []);
                                    item.items.forEach((it) => {
                                        var _a;
                                        return (_a = this.dependentdata.get(id)) === null || _a === void 0 ? void 0 : _a.push({
                                            id: it.id,
                                            value: it.name
                                        });
                                    });
                                }
                                else {
                                    // not an choice 
                                    // we need to check conditions and parse the rules here!
                                    const enmap = new Map();
                                    for (const en of dp.entries) {
                                        if (!enmap.has(en.cellid)) {
                                            enmap.set(en.cellid, []);
                                        }
                                        (_b = enmap.get(en.cellid)) === null || _b === void 0 ? void 0 : _b.push(en);
                                    }
                                    if (srit.conds && srit.conds.length > 0) {
                                        // lets chekc if any rules apply to my entries we can open them up!
                                        for (const con of srit.conds) {
                                            for (const cp of con.params) {
                                                // now we have this 
                                                if (cp.leftcond) {
                                                    const ens = enmap.get(cp.leftcond);
                                                    if (ens) {
                                                        for (const e of ens) {
                                                            if (e.ruleopen) {
                                                                parser([e], dp.schemaopen, depschemarows, mapofschema);
                                                            }
                                                            else if (!e.decrypted) {
                                                                decryptCell(e, dp.schemaopen);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    const id = srit.id + "---" + srit.sid + "---" + srit.cid;
                                    this.dependentdata.set(id, []);
                                    const entries = dp.entries;
                                    for (const en of entries) {
                                        if (!en.decrypted) {
                                            decryptCell(en, dp.schemaopen);
                                        }
                                        if (en.cellid !== item.id)
                                            continue;
                                        // now we can do some clever filtering and we done!!
                                        if (srit.conds && srit.conds.length > 0) {
                                            const rows = depschemarows.get(srit.sid);
                                            if (!rows)
                                                continue;
                                            const myens = [];
                                            const rind = en.rowid;
                                            for (const r of rows) {
                                                if (r.rowid === rind) {
                                                    for (const e1 of r.entry) {
                                                        myens.push(e1);
                                                    }
                                                }
                                            }
                                            if (!canShowRemoteEntry(en, srit.conds, myens)) {
                                                continue;
                                            }
                                        }
                                        if (item.type === SchemaItemType.Text) {
                                            (_c = this.dependentdata.get(id)) === null || _c === void 0 ? void 0 : _c.push({
                                                id: en.id,
                                                value: (_d = stripHTML(en.celldata)) !== null && _d !== void 0 ? _d : ''
                                            });
                                        }
                                        else if (item.type === SchemaItemType.Date) {
                                            (_e = this.dependentdata.get(id)) === null || _e === void 0 ? void 0 : _e.push({
                                                id: en.id,
                                                value: DateLib.displayDateWIthFormat(en.celldata, item.inlcudetime, (_f = item.excludedate) !== null && _f !== void 0 ? _f : false)
                                            });
                                        }
                                        else {
                                            (_g = this.dependentdata.get(id)) === null || _g === void 0 ? void 0 : _g.push({
                                                id: en.id,
                                                value: en.celldata
                                            });
                                        }
                                    }
                                }
                                break;
                            }
                        }
                    }
                }
            }
        });
    }
    setActiveSchemaMetadata(smd) {
        this.activeschemametadatamap.clear();
        if (smd !== undefined) {
            this.activeschemametadatamap.add(smd.id);
            this.activeschemametadata = smd;
            if (smd.schemaitemids !== undefined && smd.schemaitemids.length > 0 && !smd.useall) {
                smd.schemaitemids.map((id) => this.reportSchemaItems.add(id));
            }
        }
        else {
            this.activeschemametadata = undefined;
        }
    }
    setActiveReportRowIds(ids) {
        this.activereportrowids = ids;
    }
    addSchema(sch) {
        this.schemas.set(sch.id, sch);
    }
    showColumn(id) {
        this.hiddencolumns.delete(id);
    }
    setShowColumns(cols) {
        this.showcolumnview = cols;
    }
    hideRow(id) {
        this.hiddenrows.add(id);
    }
    unhideRow(id) {
        this.hiddenrows.delete(id);
    }
    setItems(its) {
        this.currentitems.splice(0, this.currentitems.length);
        this.currentitems.push(...its);
    }
    parseCellRules(fitem, sh) {
        var _a;
        if (fitem.decrypted) {
            return;
        }
        if (this.parsingentry.has(fitem.id)) {
            return;
        }
        this.parsingentry.add(fitem.id);
        const achema = sh;
        if (fitem.ruleopen) {
            const wen = Object.assign({}, fitem);
            // lets now rebuild this rule now
            if (!this.depschemarows.has(sh.id) ||
                ((_a = this.depschemarows.get(sh.id)) === null || _a === void 0 ? void 0 : _a.length) !== this.rowsMap.size) {
                this.depschemarows.set(sh.id, Array.from(this.rowsMap.values()));
            }
            if (!this.schemasmap.has(sh.id)) {
                this.schemasmap.set(sh.id, achema);
            }
            parser([wen], achema, this.depschemarows, this.schemasmap, this.dependentdata);
            // now we have this cell done we just need to remove this cell from the 
            fitem.celldata = wen.celldata;
            fitem.decrypted = true;
            const frow = this.rowsMap.get(fitem.rowid);
            if (frow) {
                for (const en of frow.entry) {
                    if (en.id === fitem.id) {
                        en.decrypted = true;
                        en.celldata = wen.celldata;
                        break;
                    }
                }
            }
        }
        this.parsingentry.delete(fitem.id);
    }
    getActiveSchema() {
        if (this.activeschema) {
            const sh = this.schemas.get(this.activeschema);
            if (sh) {
                return sh;
            }
        }
        return undefined;
    }
    decryptCell(fitem, sh) {
        var _a, _b, _c;
        if (fitem.decrypted) {
            return;
        }
        const achema = sh;
        let myitem = undefined;
        for (const si of achema.items) {
            if (si.type === SchemaItemType.List) {
                const lsi = si;
                for (const ssi of lsi.items) {
                    if (ssi.item.id === fitem.cellid) {
                        myitem = ssi.item;
                        break;
                    }
                }
            }
            else {
                if (si.id === fitem.cellid) {
                    myitem = si;
                    break;
                }
            }
        }
        if ((myitem === null || myitem === void 0 ? void 0 : myitem.type) === SchemaItemType.SquenceNumber) {
            fitem.decrypted = true;
            fitem.celldata = fitem.celldata.substring("sqn-".length);
            return fitem;
        }
        if (fitem.celldata.startsWith("pbk---")) {
            if (!achema.schemaintegrationbox) {
                return;
            }
            let pk = "";
            try {
                const dstr = LetrwingCrypto.decryptSecretBox(achema.schemaintegrationbox, achema.accesskey);
                if (dstr) {
                    const djs = JSON.parse(dstr);
                    pk = djs.privatkey;
                }
            }
            catch (ex) { }
            if (fitem.schemaid === ((_a = achema.parentschema) === null || _a === void 0 ? void 0 : _a.id)) {
                pk = achema.parentschema.webformkeys.priavatekey;
            }
            if (!pk) {
                fitem.celldata = '';
                console.log("missing key for rule parsing");
                return;
            }
            try {
                const emsg = fitem.celldata.substring("pbk---".length);
                const jsonstring = LetrwingCrypto.getUTF8FromBase64(emsg);
                const js = JSON.parse(jsonstring);
                const rawdata = LetrwingCrypto.decryptRSABoxRawString(js, { privatkey: pk, publickey: '' });
                fitem.celldata = rawdata !== null && rawdata !== void 0 ? rawdata : '';
                fitem.cellfindstr = rawdata !== null && rawdata !== void 0 ? rawdata : '';
                fitem.decrypted = true;
            }
            catch (ex) {
                console.log(ex);
            }
            return;
        }
        let key = achema.accesskey;
        let nonce = achema.nonce;
        if (fitem.schemaid === ((_b = achema.parentschema) === null || _b === void 0 ? void 0 : _b.id)) {
            key = achema.parentschema.accesskey;
            nonce = achema.parentschema.nonce;
        }
        const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: fitem.celldata, nonce: '', publickey: '' }, key, nonce);
        fitem.celldata = val !== null && val !== void 0 ? val : '';
        if (fitem.cellfindstr) {
            const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: fitem.cellfindstr, nonce: '', publickey: '' }, key, nonce);
            fitem.cellfindstr = val !== null && val !== void 0 ? val : '';
        }
        else {
            fitem.cellfindstr = '';
        }
        if (myitem && myitem.type === SchemaItemType.Remote) {
            const srit = myitem;
            const id = srit.id + "---" + srit.sid + "---" + srit.cid;
            const dens = this.dependentdata.get(id);
            if (dens) {
                const mit = fitem.celldata.split(",");
                for (const vk of dens) {
                    if (mit.indexOf(vk.id) !== -1) {
                        if (!fitem.remotevalue)
                            fitem.remotevalue = [];
                        (_c = fitem.remotevalue) === null || _c === void 0 ? void 0 : _c.push(vk.value);
                    }
                }
            }
        }
        //lets also attach display value here!!
        fitem.decrypted = true;
    }
    async getFileMeta(id, contenname, rowid, dataid, key, cellid, entryid, objectid) {
        const req = {
            name: contenname, fileid: id, rowid: rowid, dataid: dataid, cellid: cellid,
            entryid: entryid, objectid: objectid, schemaid: this.activeschema
        };
        const res = await comm.postWithNetwork(ServerPath.getPwaFileMeta, req);
        if (res && res !== 'NetworkError' && res !== 'Unauthorised') {
            if (!res.box) {
                return undefined;
            }
            const mykey = LetrwingCrypto.decryptSecretBox(res.box, key);
            return mykey;
        }
        return res;
    }
    async getPdfViewerContent(id, contenname, rowid, dataid, key, cellid, entryid, objectid) {
        if (!this.activeschema) {
            return undefined;
        }
        const mykey = await this.getFileMeta(id, contenname, rowid, dataid, key, cellid, entryid, objectid);
        if (mykey === 'Unauthorised') {
            return undefined;
        }
        if (!mykey) {
            return undefined;
        }
        if (mykey === 'NetworkError') {
            return undefined;
        }
        const req = {
            name: contenname, fileid: id, rowid: rowid, dataid: dataid, cellid: cellid,
            entryid: entryid, objectid: objectid, schemaid: this.activeschema
        };
        const res = await comm.post(ServerPath.getPwaFileViewerContent, req);
        if (res) {
            try {
                let str = LetrwingCrypto.decryptSecretBox(res.box, mykey);
                if (str === undefined) {
                    return undefined;
                }
                return str;
            }
            catch (ex) {
                console.log(ex);
            }
        }
        return undefined;
    }
    setActiveSchema(id, aschema) {
        this.hiddencolumns.clear();
        this.hiddenrows.clear();
        this.setShowColumns(false);
        this.depschemarows.clear();
        this.clearRowRules();
        this.loadedentry = 0;
        this.waitingentries = [];
        this.schemadep = undefined;
        this.colwithschemarules.clear();
        this.selectedrows.clear();
        this.activeschema = id;
        this.formentry.clear();
        this.kanbanstatus.clear();
        this.timelinestatus.clear();
        this.chartstate.clear();
        this.calendarstate.clear();
        this.activeloadednotesref = "";
        this.notesbyid.clear();
        this.rowsMap.clear();
        this.rowsarrids.splice(0, this.rowsarrids.length);
        this.newrowsmap.splice(0, this.newrowsmap.length);
        if (aschema !== undefined) {
            this.setItems(aschema.items);
            if (aschema === null || aschema === void 0 ? void 0 : aschema.rules) {
                // lets go over them now!!
                for (const cr of aschema.rules) {
                    let isvalid = false;
                    for (const pr of cr.rule) {
                        for (const rrv of pr.params) {
                            if (rrv.type !== 'schema') {
                                isvalid = true;
                                break;
                            }
                            if (rrv.value && rrv.value.split("~~~").length > 2) {
                                isvalid = true;
                                break;
                            }
                        }
                    }
                    if (isvalid) {
                        this.colwithschemarules.add(cr.colid);
                    }
                }
            }
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "left", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "top", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "dragging", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "title", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "linkingmode", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "schemas", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activeschema", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loadingcontent", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showform", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "storingform", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "formentry", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaRemoteStore.prototype, "newrowsmap", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "buildingview", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showlinkdialog", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showactiveviewmenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activeview", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showstatuesmenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loadmore", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activecardsttaus", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activecardgroupby", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activecardstatusname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activecardgroupname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "startnotes", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showgroupbymenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showganttstartdate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showganttstartdatename", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showganttenddatename", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "colorfieldname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "colorfieldid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showcolorfield", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showchartmenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaRemoteStore.prototype, "links", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "selectedrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "schemanotesloading", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "deletenoteids", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "shownotespanel", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "sindex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "descfieldname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "descfieldid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showdescfield", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loadingcomments", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "deletingnote", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "enableannotation", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showfilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "showbookmarklinkdetail", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "notesbyid", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "activeaccessids", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaRemoteStore.prototype, "appliedaclstr", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "editnoteid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "editmode", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showcomments", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loadingnotes", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "authors", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showganttenddate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "ganttstartid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "ganttendid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "ganttasknameid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "ganttasknamefieldname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activerowid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "savingrow", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "savingnote", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "editrowproperty", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "notelinksdialogleft", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "notelinksdialogtop", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "chartstate", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "showinoutlinks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "objectviewer", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "checkingandcollecting", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "rowRules", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "fetchingdepend", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "parsingentry", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "gettinganas", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "anas", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaRemoteStore.prototype, "anasschema", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "colwithschemarules", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "hiddenrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showrowsilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "showcolumnview", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "hiddencolumns", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaRemoteStore.prototype, "activeaction", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loadedentry", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activecustomtab", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "loadingreportschema", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaRemoteStore.prototype, "activereportrowids", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaRemoteStore.prototype, "activeschemametadata", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "activeschemametadatamap", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaRemoteStore.prototype, "uirows", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaRemoteStore.prototype, "currentitems", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaRemoteStore.prototype, "reportSchemaItems", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "setLoadingContent", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "clearRowMap", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Boolean]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "addRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "addRowToMobx", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array, Boolean, Boolean]),
    __metadata("design:returntype", Promise)
], PwaRemoteStore.prototype, "addRowInline", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array, Boolean]),
    __metadata("design:returntype", Promise)
], PwaRemoteStore.prototype, "addRowInline_old", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], PwaRemoteStore.prototype, "addRuleEntryToRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "addRuleEntryToRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "addRowRule", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "clearRowRules", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "setActiveSchemaMetadata", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "setActiveReportRowIds", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "addSchema", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "showColumn", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "setShowColumns", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "hideRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "unhideRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "setItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "parseCellRules", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], PwaRemoteStore.prototype, "setActiveSchema", null);
