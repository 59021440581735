import { SecStore } from "@/background/store/SecurityStore";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { MasterKeyBox } from "@@/EncryptBox";
import { ServerPath } from "@@/ServerPath";
import { comm } from "@/Comm/comm";
import { AuthHeaders as AuthHeader } from "@@/AuthHeader";
import { CommonCrypto } from "@@/Cryptography";
import { Auth } from "./AuthStore";
class MasterKeyStore {
    storeMasterKey(masterKey, mypassword, cb) {
        Auth.getValidatePasswordHeader(mypassword, async (header) => {
            if (header === undefined) {
                cb(false);
                return false;
            }
            const mpawd = InMemoryRepo.getMasterPassword();
            const ckey = InMemoryRepo.getCommKey();
            if (!mpawd || !ckey) {
                cb(false);
                return false;
            }
            const password = await SecStore.generateAndStoreNewPasswordBox(mpawd, ckey);
            if (password === undefined) {
                cb(false);
                return false;
            }
            const pid = password.id;
            const pkey = password.password;
            const ebox = LetrwingCrypto.secretBox(masterKey, pkey);
            if (ebox === undefined) {
                cb(false);
                return false;
            }
            const loginpassword = LetrwingCrypto.hash512str(masterKey);
            const sbox = new MasterKeyBox(ebox, pid, loginpassword);
            const sres = await comm.post(ServerPath.addMasterKey, sbox, [header]);
            if (sres === undefined || !sres) {
                cb(false);
                return false;
            }
            cb(true);
            return true;
        });
    }
    async getMasterKeyClean() {
        const kp = LetrwingCrypto.getNewKeypair();
        const box = await comm.get(ServerPath.getMasterKey, [{ key: AuthHeader.RESPONSEKEY, value: kp.publickey }]);
        if (box === undefined) {
            return undefined;
        }
        const obox = LetrwingCrypto.decryptRSABox(box, kp);
        if (obox === undefined) {
            return undefined;
        }
        const mainpassword = InMemoryRepo.getMasterPassword();
        if (mainpassword === undefined) {
            return undefined;
        }
        const res = await SecStore.decryptPasswordBox(obox.pbox, mainpassword);
        if (res === undefined) {
            return undefined;
        }
        const masterkey = LetrwingCrypto.decryptSecretBox(obox.box.box, res.password);
        if (masterkey === undefined) {
            return undefined;
        }
        return masterkey;
    }
    getMasterKey(password, cb) {
        Auth.getValidatePasswordHeader(password, async (header) => {
            if (header === undefined) {
                cb(undefined);
                return undefined;
            }
            const kp = LetrwingCrypto.getNewKeypair();
            const box = await comm.get(ServerPath.getMasterKey, [{ key: AuthHeader.RESPONSEKEY, value: kp.publickey }, header]);
            if (box === undefined) {
                cb(undefined);
                return undefined;
            }
            const obox = LetrwingCrypto.decryptRSABox(box, kp);
            if (obox === undefined) {
                cb(undefined);
                return undefined;
            }
            const mpwd = InMemoryRepo.getMasterPassword();
            if (mpwd === undefined) {
                cb(undefined);
                return undefined;
            }
            const res = await CommonCrypto.openPasswordProtectedBox(obox.box.box, obox.pbox, mpwd);
            if (res === undefined) {
                cb(undefined);
                return undefined;
            }
            cb(res);
            return res;
        });
    }
    async hasMasterKey() {
        const haskey = await comm.get(ServerPath.hasMasterKey);
        if (haskey === undefined || !haskey) {
            return false;
        }
        return haskey;
    }
    saveMasterPassword(masterpassword, password, cb) {
        const save = async () => {
            const header = SecStore.getSecurityCommHeader();
            const res = await comm.get(ServerPath.getAdminCryptBoxes, [header.header]);
            if (res === undefined) {
                cb(false);
                return false;
            }
            try {
                const box = LetrwingCrypto.decryptRSABox(res, header.kp);
                if (box === undefined) {
                    cb(false);
                    return false;
                }
                const bbox = LetrwingCrypto.decryptSecretBox(box, masterpassword);
                if (bbox === undefined) {
                    cb(false);
                    return false;
                }
                MKService.storeMasterKey(masterpassword, password, cb);
                return true;
            }
            catch (ex) {
            }
            cb(false);
            return false;
        };
        save();
    }
}
export const MKService = new MasterKeyStore();
