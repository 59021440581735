import { comm } from "@/Comm/comm";
import { BEvent } from "@/events/BackgroundEvents";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { LetrboxDocument, LetrboxDocumentStatus, LetrboxImage, MessageDeliveryStatus, MessageType, Text } from "@@/Message";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { LStore } from "./LetrStore";
import { SearchStore } from "./SearchStore";
import { SearchDataType, SearchOperation } from "@@/ModuleJob";
class MessageStore {
    constructor() {
        this.systemMessagesString = "<<<<<<<<letrwingsystem>>>>>>>____>>>>>";
        this.activeletrid = "";
    }
    async loadLatestMessagesForActiveLetr(time) {
        if (this.activeletrid) {
            const msgs = await this.loadMessages(this.activeletrid, time, true, 100000);
            if (msgs !== undefined && msgs.length > 0) {
                BEvent.publishMessageAvailable(msgs);
            }
            // we also need edits and delete and maybe replies..
        }
    }
    async loadMessages(letrid, time, after, count, excludetype = []) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const mfilter = {
            letrid: letrid,
            time: time,
            count: count,
            after: after,
            exclude: excludetype,
            ascending: false,
            userid: ""
        };
        const messages = await comm.post(ServerPath.getNMessages, mfilter);
        const ret = [];
        if (messages !== undefined) {
            for (const msg of messages) {
                const bmsg = this.convertServerMessageToBaseMessage(msg, box);
                if (bmsg !== undefined) {
                    ret.push(bmsg);
                }
            }
        }
        return ret;
    }
    async receivedMessage(msg, fsince = -1) {
        const letrid = msg.letrid;
        let box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined && fsince > -1) {
            await LStore.fetchLetrUpdatesSince(msg.updatetime - 60 * 1000);
            box = InMemoryRepo.getLetrPassword(msg.letrid);
            ;
        }
        if (box === undefined) {
            return [];
        }
        const bmsg = this.convertServerMessageToBaseMessage(msg, box);
        if (bmsg !== undefined) {
            BEvent.publishMessageAvailable([bmsg]);
        }
    }
    receivedDeleteMessage(msg) {
        var _a;
        const letrid = msg.letrid;
        if (letrid === undefined) {
            return undefined;
        }
        const pmsg = {
            type: msg.type,
            _id: msg.id,
            letrid: msg.letrid,
            text: '',
            createdAt: msg.updatetime,
            user: { _id: msg.authorid, name: (_a = msg.authorname) !== null && _a !== void 0 ? _a : '' },
            replycount: msg.replyCount != undefined ? msg.replyCount : 0,
            status: MessageDeliveryStatus.Active
        };
        BEvent.publishDeleteMessage(pmsg);
    }
    receivedReply(res) {
        const msg = res.latestReplies[0];
        const letrid = msg.letrid;
        if (letrid === undefined) {
            return undefined;
        }
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        console.log('server message');
        console.log(msg);
        const rmsg = this.convertServerMessageToBaseMessage(msg, box);
        if (rmsg != undefined) {
            const replyMessage = {
                reply: rmsg,
                replyCount: res.totalCount,
                letrid: letrid,
                parentid: msg.parentid
            };
            BEvent.publishReply(replyMessage);
        }
    }
    receivedEditedReply(msg) {
        const letrid = msg.letrid;
        if (letrid === undefined) {
            return undefined;
        }
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const rmsg = this.convertServerMessageToBaseMessage(msg, box);
        if (rmsg !== undefined) {
            BEvent.publishEditReply(rmsg);
        }
    }
    receivedDeletedReply(res) {
        var _a;
        const msg = res.latestReplies[0];
        const letrid = msg.letrid;
        if (letrid === undefined) {
            return undefined;
        }
        const pmsg = {
            type: msg.type,
            _id: msg.id,
            letrid: msg.letrid,
            text: '',
            createdAt: msg.updatetime,
            user: { _id: msg.authorid, name: (_a = msg.authorname) !== null && _a !== void 0 ? _a : '' },
            replycount: msg.replyCount != undefined ? msg.replyCount : 0,
            status: MessageDeliveryStatus.Active
        };
        const replyMessage = {
            reply: pmsg,
            replyCount: res.totalCount,
            letrid: letrid,
            parentid: msg.parentid
        };
        BEvent.publishDeleteReply(replyMessage);
    }
    receivedNewPublicContact() {
        BEvent.publishNewPublicContacts("fetch");
    }
    setActiveLetr(letrid) {
        this.activeletrid = letrid;
    }
    async getMessagesForIds(ids, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box != undefined) {
            const req = {
                ids: ids,
                letrid: letrid
            };
            const messages = await comm.post(ServerPath.getMessagesForIds, req);
            const ret = [];
            if (messages !== undefined) {
                for (const msg of messages) {
                    const bmsg = this.convertServerMessageToBaseMessage(msg, box);
                    if (bmsg !== undefined) {
                        ret.push(bmsg);
                    }
                }
            }
            return ret;
        }
    }
    async saveMessage(msg, objectid = "", acl = "") {
        const letrid = msg.letrid;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box != undefined) {
            const ebox = this.createMessageEncryptBox(msg, box);
            if (ebox !== undefined) {
                const smsg = {
                    id: msg._id.startsWith("system-") ? msg._id : "",
                    parentid: "",
                    box: ebox,
                    authorname: "",
                    authorid: "",
                    type: msg.type,
                    updatetime: 0,
                    letrid: letrid,
                    count: 0,
                    isHtml: true,
                    objectid: objectid,
                    acl: acl
                };
                const savedmsg = await comm.post(ServerPath.saveMessage, smsg);
                if (savedmsg !== undefined) {
                    // now we have saved message lets convert this 
                    savedmsg.authorname = InMemoryRepo.getName();
                    if (savedmsg.type === MessageType.Text) {
                        await SearchStore.addToIndex(msg.text, savedmsg.id, letrid, SearchOperation.Add, SearchDataType.Message);
                    }
                    return this.convertServerMessageToBaseMessage(savedmsg, box);
                }
            }
        }
        return undefined;
    }
    async editMessage(msg) {
        const letrid = msg.letrid;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box != undefined) {
            const ebox = this.createMessageEncryptBox(msg, box);
            if (ebox !== undefined) {
                const smsg = {
                    id: msg._id,
                    parentid: "",
                    box: ebox,
                    authorname: "",
                    authorid: "",
                    type: msg.type,
                    updatetime: 0,
                    letrid: letrid,
                    count: 0,
                    isHtml: true
                };
                const emsg = await comm.post(ServerPath.editMessage, smsg);
                if (emsg === undefined) {
                    return undefined;
                }
                emsg.authorname = InMemoryRepo.getName();
                return this.convertServerMessageToBaseMessage(emsg, box);
            }
            return undefined;
        }
    }
    async deleteMessage(msg) {
        const id = msg._id;
        const ok = await comm.post(ServerPath.deleteMessage, { messageid: id });
        return ok;
    }
    async loadReplies(parentid, letrid, time, after, count) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        const mfilter = {
            letrid: letrid,
            time: time,
            count: count,
            after: after,
            exclude: [],
            ascending: false,
            userid: ""
        };
        const messages = await comm.post(ServerPath.getNReplyMessages, {
            filter: mfilter,
            parentid: parentid
        });
        const ret = [];
        if (messages !== undefined) {
            for (const msg of messages) {
                console.log("here  " + msg.letrid);
                const bmsg = this.convertServerMessageToBaseMessage(msg, box);
                if (bmsg !== undefined) {
                    ret.push(bmsg);
                }
            }
        }
        return ret;
    }
    async reply(msg, parentid, lastTime) {
        if (!parentid.trim()) {
            return undefined;
        }
        const letrid = msg.letrid;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box != undefined) {
            const ebox = this.createMessageEncryptBox(msg, box);
            if (ebox !== undefined) {
                const smsg = {
                    id: msg._id,
                    parentid: parentid,
                    box: ebox,
                    authorname: "",
                    authorid: "",
                    type: msg.type,
                    updatetime: 0,
                    letrid: letrid,
                    count: 0,
                    isHtml: true
                };
                const replyreq = {
                    reply: smsg,
                    letrid: letrid,
                    parentid: parentid
                };
                const res = await comm.post(ServerPath.replyMessage, replyreq);
                if (res !== undefined) {
                    const lmsg = await this.loadReplies(parentid, letrid, lastTime, true, 100000);
                    const rep = {
                        latestreplied: lmsg,
                        totalcount: res.totalCount
                    };
                    return rep;
                }
                return undefined;
            }
        }
    }
    createMessageEncryptBox(msg, secretkey) {
        let ebox;
        if (msg.type === MessageType.Text) {
            ebox = LetrwingCrypto.secretBox(msg.text, secretkey);
        }
        else if (msg.type === MessageType.File) {
            const dmsg = msg;
            const fileid = dmsg.documentreference;
            const accesskey = dmsg.accesskey;
            const accesscode = dmsg.accesscode;
            const info = {
                accesscode: accesscode,
                accesskey: accesskey,
                accessid: fileid,
                size: dmsg.size,
                name: dmsg.name,
                width: 0,
                height: 0,
                versions: dmsg.versions,
                replace: dmsg.replaceinfo,
                origname: dmsg.name
            };
            ebox = LetrwingCrypto.secretBox(JSON.stringify(info), secretkey);
        }
        else if (msg.type === MessageType.Image) {
            const imsg = msg;
            const info = {
                accesscode: imsg.accesscode,
                accesskey: imsg.accesskey,
                accessid: imsg.documentreference,
                size: imsg.size,
                name: "image",
                width: imsg.width,
                height: imsg.height,
                versions: [],
                replace: [],
                origname: "image"
            };
            ebox = LetrwingCrypto.secretBox(JSON.stringify(info), secretkey);
        }
        else if (msg.type === MessageType.Project) {
            const pmsg = msg;
            const box = {
                objectid: pmsg.objectid,
                objecttype: pmsg.objecttype,
                text: pmsg.text,
                color: pmsg.color
            };
            ebox = LetrwingCrypto.secretBox(JSON.stringify(box), secretkey);
        }
        else if (msg.type === MessageType.Note) {
            const pmsg = msg;
            const box = {
                objectid: pmsg.objectid,
                objecttype: pmsg.objecttype,
                text: pmsg.text,
                color: pmsg.color
            };
            ebox = LetrwingCrypto.secretBox(JSON.stringify(box), secretkey);
        }
        return ebox;
    }
    async getMessageReadReceipt(letrid, mid) {
        const req = {
            letrid: letrid,
            messageid: mid
        };
        const res = await comm.post(ServerPath.MessageReadReceipt, req);
        return res;
    }
    async updateMessageAcl(id, letrid, aclids) {
        const emsg = await comm.post(ServerPath.editMessageAcl, { id: id, letrid: letrid, aclids: aclids });
        if (emsg == undefined) {
            return false;
        }
        return emsg;
    }
    apiConvertServerMessageToBaseMessage(smsg, datakey) {
        return this.convertServerMessageToBaseMessage(smsg, datakey);
    }
    convertServerMessageToBaseMessage(smsg, datakey) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const ebox = smsg.box;
        let msg = LetrwingCrypto.decryptSecretBox(ebox, datakey);
        if (msg !== undefined) {
            if (smsg.type === MessageType.Text) {
                const magicstring = "(" + this.systemMessagesString + ")";
                let issystem = false;
                if (msg.startsWith(magicstring) && msg.endsWith(magicstring)) {
                    msg = msg.substring(magicstring.length + 1, msg.length - (magicstring.length));
                    issystem = true;
                }
                const text = new Text(smsg.id, smsg.letrid, smsg.authorid, smsg.updatetime, { _id: smsg.authorid, name: (_a = smsg.authorname) !== null && _a !== void 0 ? _a : '' }, msg);
                if (smsg.isHtml) {
                    text.isHtml = smsg.isHtml;
                }
                text.setstatus(MessageDeliveryStatus.Active);
                text.setReplyCount((_b = smsg.replyCount) !== null && _b !== void 0 ? _b : 0);
                text.isSystemMessage = issystem;
                return text;
            }
            else if (smsg.type === MessageType.File) {
                const fmsg = JSON.parse(msg);
                const file = new LetrboxDocument(smsg.id, smsg.letrid, "", smsg.updatetime, { _id: smsg.authorid, name: (_c = smsg.authorname) !== null && _c !== void 0 ? _c : '' }, LetrboxDocumentStatus.Uploaded, fmsg.name);
                file.accesscode = fmsg.accesscode;
                file.accesskey = fmsg.accesskey;
                file.documentreference = fmsg.accessid;
                file.size = fmsg.size;
                file.setstatus(MessageDeliveryStatus.Active);
                file.versions = fmsg.versions;
                file.replaceinfo = fmsg.replace;
                file.replycount = (_d = smsg.replyCount) !== null && _d !== void 0 ? _d : 0;
                return file;
            }
            else if (smsg.type === MessageType.Image) {
                const fmsg = JSON.parse(msg);
                const image = new LetrboxImage(smsg.id, smsg.letrid, "", smsg.updatetime, { _id: smsg.authorid, name: (_e = smsg.authorname) !== null && _e !== void 0 ? _e : "" }, LetrboxDocumentStatus.Downloading, fmsg.size);
                image.accesscode = fmsg.accesscode;
                image.accesskey = fmsg.accesskey,
                    image.documentreference = fmsg.accessid;
                image.setstatus(MessageDeliveryStatus.Active);
                image.width = fmsg.width;
                image.height = fmsg.height;
                image.replycount = (_f = smsg.replyCount) !== null && _f !== void 0 ? _f : 0;
                return image;
            }
            else if (smsg.type === MessageType.Project) {
                const pmss = JSON.parse(msg);
                const pbm = {
                    _id: smsg.id,
                    createdAt: smsg.updatetime,
                    letrid: smsg.letrid,
                    status: MessageDeliveryStatus.Active,
                    objectid: pmss.objectid,
                    objecttype: pmss.objecttype,
                    text: pmss.text,
                    type: MessageType.Project,
                    replycount: (_g = smsg.replyCount) !== null && _g !== void 0 ? _g : 0,
                    user: { _id: "system", name: "System" },
                    color: pmss.color
                };
                return pbm;
            }
            else if (smsg.type === MessageType.Note) {
                const pmss = JSON.parse(msg);
                const pbm = {
                    _id: smsg.id,
                    createdAt: smsg.updatetime,
                    letrid: smsg.letrid,
                    status: MessageDeliveryStatus.Active,
                    objectid: pmss.objectid,
                    objecttype: pmss.objecttype,
                    text: pmss.text,
                    type: MessageType.Note,
                    replycount: (_h = smsg.replyCount) !== null && _h !== void 0 ? _h : 0,
                    user: { _id: "system", name: "System" },
                    color: pmss.color
                };
                return pbm;
            }
        }
    }
}
export const MStore = new MessageStore();
