var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Flex, FormControl, FormHelperText, Icon, Input, Spinner, Text } from "@chakra-ui/react";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { observer } from "mobx-react";
import React from "react";
import { MdKeyboardReturn } from '@react-icons/all-files/md/MdKeyboardReturn';
import { BeatLoader } from "react-spinners";
import { APPEvent } from "@/events/AppEvents";
let OrganisationMenu = class OrganisationMenu extends React.Component {
    constructor(prop) {
        super(prop);
    }
    async decryptOrg(or) {
        var _a, _b;
        const duser = await this.props.decryptorg(or, (_a = this.props.state.orgcodein.get(or.orgid)) !== null && _a !== void 0 ? _a : '');
        if (!duser) {
            APPEvent.publishApperror((_b = "Sorry, failed to decrypt " + or.orgname) !== null && _b !== void 0 ? _b : '');
        }
        else {
            this.props.state.replaceOrg(or, duser);
            this.props.orgclicked(duser);
            // we should select this org as well and cliose this menu!
        }
        this.props.state.removeDecryptOrg(or.orgid);
    }
    render() {
        if (!this.props.state.show) {
            return React.createElement(React.Fragment, null);
        }
        let maxheight = 350;
        if (window.innerHeight < 350) {
            maxheight = window.innerHeight - 10;
        }
        return React.createElement(Box, { zIndex: 500, bg: 'white', h: maxheight + 'px', maxH: maxheight + 'px', overflow: 'hidden', boxShadow: '5px 5px 5px 5px lightgray', right: !this.props.state.posright ? 'undefined' : this.props.state.left + "px", w: this.props.state.width + "px", pos: 'fixed', top: this.props.state.top + "px", left: this.props.state.posright ? 'undefined' : this.props.state.left + "px" },
            React.createElement(Flex, { pos: 'relative', w: '100%', h: '70px', borderBottomColor: 'lightgray', align: 'center', borderBottomWidth: '1px' },
                React.createElement(Text, { ml: '10px', fontWeight: 'bold', fontSize: '20px' }, "Organisations"),
                React.createElement(Icon, { top: '19px', w: '25px', h: '25px', right: '10px', pos: 'absolute', as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => this.props.state.setShow(false) })),
            React.createElement(Box, { mt: '5px', w: '100%', h: (maxheight - 80) + 'px', maxH: (maxheight - 80) + 'px', overflow: 'auto' },
                this.props.state.loading && React.createElement(Flex, { w: '100%', h: (maxheight - 80) + 'px', justify: 'center', align: 'center' },
                    React.createElement(BeatLoader, null)),
                !this.props.state.loading && this.props.state.orgs.length === 0 && React.createElement(Flex, { w: '100%', h: (maxheight - 80) + 'px', justify: 'center', align: 'center' },
                    React.createElement(Text, null, "No organisations found for you.")),
                !this.props.state.loading && this.props.state.orgs.length > 0 && this.props.state.orgs.map((or) => {
                    var _a, _b, _c, _d;
                    return React.createElement(Box, { onClick: () => {
                            if (or.isopen) {
                                this.props.orgclicked(or);
                            }
                        }, _hover: { cursor: 'pointer', opacity: '0.9', backgroundColor: '#829eb975' }, key: or.id, bg: this.props.state.selectedodgid === or.orgid ? '#829eb9' : 'white', w: '100%', mt: '10px' },
                        React.createElement(Flex, { w: '100%', align: 'center' },
                            React.createElement(Flex, { ml: '10px', align: 'center', justify: 'center', w: '50px', h: '50px', borderRadius: '25px', bg: '#f2f2f2', color: 'black' },
                                React.createElement(Text, null, (_b = (_a = or.orgname) === null || _a === void 0 ? void 0 : _a.slice(0, 1)) !== null && _b !== void 0 ? _b : 'M')),
                            React.createElement(Box, { ml: '30px' },
                                React.createElement(Text, { fontWeight: 'bold', fontSize: '20px' }, (_c = or.orgname) !== null && _c !== void 0 ? _c : ''),
                                or.isopen && React.createElement(Text, { mt: '5px', fontStyle: 'italic' }, "Organisation Enabled"),
                                !or.isopen && React.createElement(Box, { mt: '5px' },
                                    React.createElement(FormControl, null,
                                        React.createElement(Flex, { align: 'center' },
                                            React.createElement(Input, { w: '120px', value: (_d = this.props.state.orgcodein.get(or.orgid)) !== null && _d !== void 0 ? _d : '', onChange: (e) => {
                                                    this.props.state.setInputText(e.currentTarget.value, or.orgid);
                                                }, placeholder: "Code" }),
                                            !this.props.state.decryptingorg.has(or.orgid) &&
                                                this.props.state.orgcodein.get(or.orgid) !== ''
                                                && React.createElement(Icon, { w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: (e) => {
                                                        e.stopPropagation();
                                                        this.decryptOrg(or);
                                                    }, as: MdKeyboardReturn, color: 'green' }),
                                            this.props.state.decryptingorg.has(or.orgid) && React.createElement(Spinner, null)),
                                        or.hint !== '' && React.createElement(FormHelperText, { ml: '5px' }, "Hint: " + or.hint))))));
                })));
    }
};
OrganisationMenu = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], OrganisationMenu);
export { OrganisationMenu };
