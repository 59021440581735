var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { action, makeObservable, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import '../css/App.css';
export class BaseDialogState {
    constructor() {
        this.open = false;
        this.oklabel = "OK";
        this.cancelLabel = "Cancel";
        this.header = "Dialog";
        this.disbaleOkButton = false;
        this.disbaleCancelButton = false;
        this.hideFooter = false;
        this.modalSize = "4xl";
        this.dialogref = React.createRef();
        this.maxheight = 600;
        this.zindex = 100;
        this.hideCancelButton = false;
        this.hideCloseButton = false;
        makeObservable(this);
    }
    setHeight(height) {
        this.maxheight = height;
    }
    setHideFooter(hide) {
        this.hideFooter = hide;
    }
    setZindex(index) {
        this.zindex = index;
    }
    setDefaultZindex() {
        this.setZindex(100);
    }
    setDisableOkButton(disbale) {
        this.disbaleOkButton = disbale;
    }
    setDisableCancelButton(disbale) {
        this.disbaleCancelButton = disbale;
    }
    setHideCancelButton(hide) {
        this.hideCancelButton = hide;
    }
    setHideCloseButton(hide) {
        this.hideCloseButton = hide;
    }
    setHeaderLabel(header) {
        this.header = header;
    }
    setOpen(open) {
        this.open = open;
    }
    setOkLabel(lbl) {
        this.oklabel = lbl;
    }
    setCancelLabel(lbl) {
        this.cancelLabel = lbl;
    }
    setModalSize(size) {
        this.modalSize = size;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "oklabel", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "cancelLabel", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "header", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "disbaleOkButton", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "disbaleCancelButton", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "hideFooter", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "modalSize", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "maxheight", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "zindex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "hideCancelButton", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseDialogState.prototype, "hideCloseButton", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setHideFooter", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setZindex", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setDisableOkButton", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setDisableCancelButton", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setHideCancelButton", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setHideCloseButton", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setHeaderLabel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setOpen", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setOkLabel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setCancelLabel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseDialogState.prototype, "setModalSize", null);
let BaseDialog = class BaseDialog extends React.Component {
    close() {
        var _a, _b;
        this.props.state.setOpen(false);
        (_b = (_a = this.props.state).onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    componentDidMount() {
        var _a, _b;
        (_b = (_a = this.props.state).onOpen) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    ok() {
        var _a, _b;
        (_b = (_a = this.props.state).onOk) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    render() {
        return (React.createElement(Observer, null, () => React.createElement(Modal, { isOpen: this.props.state.open, size: this.props.state.modalSize, onClose: () => this.close(), closeOnOverlayClick: false, closeOnEsc: false },
            React.createElement(ModalOverlay, { zIndex: 100 }),
            React.createElement(ModalContent, { zIndex: this.props.state.zindex },
                React.createElement(ModalHeader, null, this.props.state.header),
                React.createElement(ModalCloseButton, null),
                React.createElement(ModalBody, { style: { overflowY: 'auto' }, ref: this.props.state.dialogref },
                    !this.props.state.hideFooter && React.createElement(Flex, { w: '100%', justify: 'flex-end' },
                        React.createElement(Button, { onClick: () => this.ok(), disabled: this.props.state.disbaleOkButton ? true : false, bg: "#b9c3cd" }, this.props.state.oklabel)),
                    this.props.body)))));
    }
};
BaseDialog = __decorate([
    observer
], BaseDialog);
export { BaseDialog };
