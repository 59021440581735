var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
import { ExpandableTreeState } from "../UIComponents/ExpandableTree";
export class PdfBookmarkState {
    constructor() {
        this.bookmarks = new Map();
        this.loading = false;
        this.treestate = new ExpandableTreeState();
        this.selectedbookmark = "";
        this.checkedids = new Set();
        makeObservable(this);
    }
    setLoading(loading) {
        this.loading = loading;
    }
    setSelectedBookmarkId(id) {
        this.selectedbookmark = id;
    }
    getCustomSelectedIds() {
        return Array.from(this.checkedids).filter((id) => {
            const bk = this.bookmarks.get(id);
            if (bk && bk.custom) {
                return true;
            }
            return false;
        });
    }
    hasCustomSelected() {
        for (const id of this.checkedids) {
            const bk = this.bookmarks.get(id);
            if (bk && bk.custom) {
                return true;
            }
        }
    }
    addBookmark(book, addtostate = false) {
        this.bookmarks.set(book.id, book);
        if (addtostate) {
            console.log("adding here ");
            this.treestate.addChild({
                id: book.id,
                label: book.title,
                parentid: (book.parentid === '' ? this.treestate.ROOTID : book.parentid),
                children: [],
            });
        }
    }
    setCheckedId(id) {
        this.checkedids.add(id);
    }
    removeCheckedId(id) {
        this.checkedids.delete(id);
    }
    removeBookmark(id) {
        const book = this.bookmarks.get(id);
        this.bookmarks.delete(id);
        if (!book) {
            return;
        }
        this.treestate.removeChild({
            id: book.id,
            label: book.title,
            parentid: book.parentid === '' ? this.treestate.ROOTID : book.parentid,
            children: [],
        });
    }
    reset() {
        this.bookmarks.clear();
        this.setLoading(false);
        this.treestate.reset();
        this.checkedids.clear();
    }
    sortBookmark(b1, b2) {
        if (!b1.sortindex) {
            b1.sortindex = 9999999;
        }
        if (!b2.sortindex) {
            b2.sortindex = 9999999;
        }
        const pgdiff = b1.sortindex - b2.sortindex;
        return pgdiff;
    }
    arrangeTree() {
        this.treestate.reset();
        const nodes = [];
        for (const book of this.bookmarks.values()) {
            nodes.push({
                id: book.id,
                label: book.title,
                parentid: book.parentid === '' ? this.treestate.ROOTID : book.parentid,
                children: [],
            });
        }
        this.treestate.setNodes(nodes);
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], PdfBookmarkState.prototype, "bookmarks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PdfBookmarkState.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PdfBookmarkState.prototype, "selectedbookmark", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PdfBookmarkState.prototype, "checkedids", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PdfBookmarkState.prototype, "setSelectedBookmarkId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Boolean]),
    __metadata("design:returntype", void 0)
], PdfBookmarkState.prototype, "addBookmark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PdfBookmarkState.prototype, "setCheckedId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PdfBookmarkState.prototype, "removeCheckedId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PdfBookmarkState.prototype, "removeBookmark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PdfBookmarkState.prototype, "reset", null);
