export var GroupType;
(function (GroupType) {
    GroupType["External"] = "External";
    GroupType["Internal"] = "Internal";
})(GroupType || (GroupType = {}));
export var MemberType;
(function (MemberType) {
    MemberType["External"] = "external";
    MemberType["Internal"] = "internal";
})(MemberType || (MemberType = {}));
export var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["Active"] = "Active";
    CompanyStatus["InActive"] = "InActive";
})(CompanyStatus || (CompanyStatus = {}));
export class GroupMember {
    constructor() {
        this.type = MemberType.Internal;
        this.id = "";
        this.orgname = "";
        this.email = "";
    }
}
export class Group {
    constructor() {
        this.id = "";
        this.name = "";
        this.type = GroupType.Internal;
        this.members = [];
        this.isUserGroup = false;
        this.userid = "";
        this.orgGrp = false;
        this.email = "";
    }
}
export class GroupsResponse {
    constructor() {
        this.contacts = [];
    }
}
export class PendingContacts {
    constructor() {
        this.id = "";
        this.email = "";
        this.fullname = "";
    }
}
export class Invitations {
    constructor() {
        this.id = "";
        this.invitingusername = "";
        this.invitingcompanyname = "";
    }
}
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "Admin";
    UserRole["User"] = "User";
})(UserRole || (UserRole = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "active";
    UserStatus["Inactive"] = "inactive";
    UserStatus["Disable"] = "disable";
    UserStatus["Register"] = "Register";
    UserStatus["PendingIdentity"] = "pendingidentity";
})(UserStatus || (UserStatus = {}));
