export var AppColor;
(function (AppColor) {
    AppColor["Default"] = "#7441ce";
    AppColor["HeaderColor"] = "#2C4893";
    AppColor["GreetingMessage"] = "#829eb9";
    AppColor["ButtonColor"] = "lightgrey";
    AppColor["LinkButtonColor"] = "#FFFFFF";
    // HeaderBackgroundGradient = 'linear-gradient(2deg, #23168d,#30269d,#5c4fc8);',
    AppColor["HeaderBackgroundGradient"] = "#516a82";
    AppColor["SideBarIconColor"] = "#54718c";
    AppColor["MessageColor"] = "#829eb9";
    AppColor["IconColor"] = "#4c3480";
    AppColor["FileIconColor"] = "#4e4e4e";
    AppColor["CheckBoxColor"] = "#4c3480";
    AppColor["ButtonIconColor"] = "#FFFFFF";
    AppColor["CloseButton"] = "#fc6e6c";
    AppColor["IconButtonBackground"] = "#faf5ff";
    // LoginScreenBackground = "linear-gradient(2deg, #23168d,#30269d,#5c4fc8);",
    AppColor["LoginScreenBackground"] = "#f2f2f2";
    AppColor["FolderIconColor"] = "#dd6b20";
    AppColor["DocumentIconColor"] = "#319795";
    AppColor["TrashIconColor"] = "#805ad5";
    AppColor["DraftIconColor"] = "#3182ce";
    AppColor["BookmarkIconColor"] = "#3139ce";
    AppColor["FilterTagBgColor"] = "#8da4cc";
    AppColor["FilterTagTextColor"] = "#FFFFFF";
    AppColor["FilterTagCloseButton"] = "#eccaca";
    AppColor["CustomTabLayoutHeader"] = "#808080";
    AppColor["CustomTabButton"] = "#404040";
    AppColor["DeleteIconColor"] = "#cd6060";
    AppColor["ActionMenuIcon"] = "#647f86";
    AppColor["InfoColor"] = "#404040";
    AppColor["LoaderColor"] = "#e0e7fe";
    AppColor["PDFViewerHeader"] = "#404040";
    AppColor["TopBarIconColor"] = "#829eb9";
    AppColor["InputLabelColor"] = "#404040";
    AppColor["FilterActive"] = "#33a851";
    AppColor["FilterInactive"] = "#404040";
    AppColor["ResetFilter"] = "#cd6060";
    AppColor["NoteCommentLabel"] = "#404040";
    AppColor["NoteBoxIcon"] = "#404040";
    AppColor["NoteBoxLightIcon"] = "#f2f2f2";
    AppColor["CheckBox"] = "#404040";
    AppColor["NoteBoxText"] = "#404040";
    AppColor["NoteBoxTextLight"] = "#f2f2f2";
    AppColor["NoteRestrictedAccessIcon"] = "#f39f9f";
    AppColor["NoteUnrestrictedAccessIcon"] = "#33a851";
    AppColor["LinkNameColor"] = "#404040";
    AppColor["DialogHeaderBackground"] = "#EFF0F1";
    AppColor["DialogHeaderBorderBottom"] = "#D6D8DA";
    AppColor["FilterHeaderFontColor"] = "#829eb9";
    AppColor["FilterItemLabelFontColor"] = "#404040";
    AppColor["TableBorderColor"] = "#cbcbcb";
    AppColor["SearchDocumentTitle"] = "#718aa1";
    AppColor["SearchLetrTitle"] = "#4e4e4e";
    AppColor["AnnotationDialogHeader"] = "#f2f2f2";
    AppColor["EditButtonColor"] = "#404040";
    AppColor["HeaderIconColor"] = "#54718c";
    AppColor["TopBarIconTextColor"] = "#4a6783";
    AppColor["LoginButtonIconColor"] = "#54718c";
    AppColor["PasswordMessage"] = "#aa2d2d";
    AppColor["MasterPassword"] = "#404040";
    AppColor["VersionNumber"] = "#585858";
    AppColor["DateColor"] = "#808080";
    AppColor["MainLoaderColor"] = "#3c8da3";
    AppColor["HighlightColor"] = "#b7ffe7a1";
})(AppColor || (AppColor = {}));
export var FontSize;
(function (FontSize) {
    FontSize["MessageFontSize"] = "15px";
    FontSize["ButtonTextSize"] = "14px";
    FontSize["TextFontSize"] = "14px";
    FontSize["HeaderFontSize"] = "16px";
    FontSize["IconTitleFontSize"] = "12px";
    FontSize["InfoText"] = "12px";
    FontSize["MiniViewerHeader"] = "14px";
    FontSize["MiniViewerPageNumber"] = "16px";
    FontSize["InputLabel"] = "14px";
    FontSize["NoteCommentLabel"] = "12px";
    FontSize["NoteBoxText"] = "10px";
    FontSize["LinkName"] = "14px";
    FontSize["FilterHeaderFontSize"] = "16px";
    FontSize["FilterItemLabelFontSize"] = "14px";
    FontSize["DialogHeaderFont"] = "12px";
    FontSize["DateFontSize"] = "10px";
    FontSize["MobileInputLabel"] = "12px";
    FontSize["SmallIconLabel"] = "10px";
})(FontSize || (FontSize = {}));
export const ROOTFolder = "root";
