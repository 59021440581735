export var MessageUIRequestType;
(function (MessageUIRequestType) {
    MessageUIRequestType[MessageUIRequestType["Add"] = 0] = "Add";
    MessageUIRequestType[MessageUIRequestType["Delete"] = 1] = "Delete";
    MessageUIRequestType[MessageUIRequestType["Fetch"] = 2] = "Fetch";
    MessageUIRequestType[MessageUIRequestType["Edit"] = 3] = "Edit";
    MessageUIRequestType[MessageUIRequestType["FetchReplies"] = 4] = "FetchReplies";
    MessageUIRequestType[MessageUIRequestType["AddReply"] = 5] = "AddReply";
    MessageUIRequestType[MessageUIRequestType["EditReply"] = 6] = "EditReply";
    MessageUIRequestType[MessageUIRequestType["DeleteReply"] = 7] = "DeleteReply";
    MessageUIRequestType[MessageUIRequestType["MessageUpdates"] = 8] = "MessageUpdates";
    MessageUIRequestType[MessageUIRequestType["FetchAllReplies"] = 9] = "FetchAllReplies";
})(MessageUIRequestType || (MessageUIRequestType = {}));
export class MessageUIRequest {
    constructor() {
        this.letrid = "";
        this.type = MessageUIRequestType.Fetch;
        this.time = 0;
        this.count = 20;
        this.before = true;
        this.sortAsc = false;
        this.replaceId = ""; // useful when adding new message
        this.parentid = "";
        this.excludetype = [];
    }
}
export class AdminMessageUIRequest {
    constructor() {
        this.letrid = "";
        this.type = MessageUIRequestType.Fetch;
        this.time = 0;
        this.count = 20;
        this.before = true;
        this.sortAsc = false;
        this.replaceId = ""; // useful when adding new message
        this.parentid = "";
        this.excludetype = [];
        this.userid = "";
    }
}
export class MessageUIResponse {
    constructor() {
        this.letrid = "";
        this.msg = [];
        this.type = MessageUIRequestType.Fetch;
        this.replaceId = "";
        this.newcount = 0;
        this.parentid = "";
    }
}
