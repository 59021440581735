var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DateLib } from "@@/Datelib";
import { ProjectObjectType } from "@@/Project";
import { SchemaItemType } from "@@/Schema";
import { Box, Flex, Text, Icon, Tag, Link, TagLabel, SimpleGrid, Select as ChakraSelect } from "@chakra-ui/react";
import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { FaClipboard } from "@react-icons/all-files/fa/FaClipboard";
import { TiTick } from "@react-icons/all-files/ti/TiTick";
import { Observer, observer } from "mobx-react";
import React from 'react';
import { toast } from "react-toastify";
import { Virtuoso } from "react-virtuoso";
import { StoreContext } from "../uistore/PwaRootStore";
import { Clipboard } from '@/util/Util';
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { ThumbNailStore } from "../uistore/ThumbNailStore";
import { PopupImageThumb } from "./PopupImageThumb";
import { MdRefresh } from "@react-icons/all-files/md/MdRefresh";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { AppColor } from "../const/AppDefaults";
import { sortEntriesByRow } from "@@/RuleParser";
import { FadeLoader } from "react-spinners";
import { BsCardHeading } from "@react-icons/all-files/bs/BsCardHeading";
const cardcolors = [
    ["#0a447d", "#adbaff"],
    ["#0a447d", "#eafbf9"]
];
let PwaReports = class PwaReports extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
        const rstore = this.context;
        let reportid = "";
        if (rstore.getPwaStore().selecteditemid.indexOf("web-") === 0) {
            const wfv = rstore.getPwaStore().activeviews.get(rstore.getPwaStore().selecteditemid.substring("web-".length));
            if (wfv !== undefined && (wfv === null || wfv === void 0 ? void 0 : wfv.schemareports) !== undefined && wfv.schemareports.length > 0) {
                reportid = wfv.schemareports[0].id;
            }
        }
        else {
            const wf = rstore.getPwaStore().activeforms.get(rstore.getPwaStore().selecteditemid);
            if (wf !== undefined && wf.schemareports !== undefined && wf.schemareports.length > 0) {
                reportid = wf.schemareports[0].id;
            }
        }
        this.getReportRows(reportid);
    }
    async getReportRows(id) {
        var _a, _b, _c, _d, _e, _f;
        const rstore = this.context;
        let smd = undefined;
        let wf;
        if (rstore.getPwaStore().selecteditemid.indexOf("web-") === 0) {
            const wfv = rstore.getPwaStore().activeviews.get(rstore.getPwaStore().selecteditemid.substring("web-".length));
            if (wfv !== undefined) {
                wf = {
                    id: rstore.getPwaStore().selecteditemid,
                    schemaid: wfv.schemaid,
                    history: false,
                    backgroundcolor: '',
                    textcolor: '',
                    referer: '',
                    letrid: wfv.letrid,
                    code: wfv.code,
                    creatorid: wfv.creatorid,
                    lastmodifiedby: '',
                    lastmodifytime: 0,
                    name: wfv.name,
                    letrkey: (_a = wfv.letrkey) !== null && _a !== void 0 ? _a : '',
                    schemakey: (_c = (_b = wfv.shopen) === null || _b === void 0 ? void 0 : _b.accesskey) !== null && _c !== void 0 ? _c : '',
                    schemanonce: (_e = (_d = wfv.shopen) === null || _d === void 0 ? void 0 : _d.nonce) !== null && _e !== void 0 ? _e : '',
                    searchbox: wfv.searchboxopen,
                    pubkeys: wfv.keys,
                    useall: false,
                    validids: [],
                    sh: wfv.shopen,
                    schemareports: wfv.schemareports
                };
            }
        }
        else {
            wf = rstore.getPwaStore().activeforms.get(rstore.getPwaStore().selecteditemid);
        }
        const userid = (_f = rstore.getPwaStore().currentroute) === null || _f === void 0 ? void 0 : _f.id;
        if (!wf || !userid || !id) {
            return;
        }
        console.log("Report row" + id);
        console.log(wf);
        if (wf.schemareports !== undefined) {
            rstore.getPwaRemoteStore().activewebform = wf;
            for (let i = 0; i < wf.schemareports.length; i++) {
                let item = wf.schemareports[i];
                if (item.id === id) {
                    smd = item;
                    break;
                }
            }
            if (smd !== undefined) {
                rstore.getPwaRemoteStore().setActiveSchemaMetadata(smd);
                rstore.getPwaStore().setActiveSchemaMetadata(smd);
                let schemaid = "";
                let datasheetid = "";
                let rowids = [];
                const bits = smd.id.split("---");
                if (bits.length > 0) {
                    datasheetid = bits[0];
                }
                if (bits.length > 1) {
                    schemaid = bits[1];
                }
                if (bits.length > 2) {
                    rowids.push(bits[2]);
                }
                let schema;
                if (!rstore.getPwaRemoteStore().schemas.has(schemaid)) {
                    schema = await rstore.getPwaStore().getRemoteSchema(schemaid);
                    if (schema !== undefined) {
                        rstore.getPwaRemoteStore().addSchema(schema);
                    }
                }
                else {
                    schema = rstore.getPwaRemoteStore().schemas.get(schemaid);
                }
                if (schema !== undefined) {
                    rstore.getPwaRemoteStore().setActiveSchema(schemaid, schema);
                    rstore.getPwaRemoteStore().getRemoteRows(schemaid, rowids, userid, smd.id);
                }
            }
            else {
                rstore.getPwaRemoteStore().setActiveSchemaMetadata(undefined);
            }
        }
    }
    render() {
        var _a, _b, _c, _d, _e;
        const rstore = this.context;
        const reportSchemaItems = rstore.getPwaRemoteStore().reportSchemaItems;
        console.log("reportSchemaItems");
        console.log(reportSchemaItems);
        let items = rstore.getPwaRemoteStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        if (reportSchemaItems.size > 0) {
            items = items.filter((it) => reportSchemaItems.has(it.id));
        }
        const rowsarr = [];
        const srows = rstore.getPwaRemoteStore().uirows.slice().sort((r1, r2) => r1.rowindex - r2.rowindex).reverse();
        for (let i = 0; i < srows.length; i = (window.innerWidth < 1100) ? i + 1 : i + 2) {
            const dr = [];
            const sr = srows[i];
            sr.uirowindex = i;
            dr.push(sr);
            if ((window.innerWidth >= 1100) && (i + 1) < srows.length) {
                srows[i + 1].uirowindex = i + 1;
                dr.push(srows[i + 1]);
            }
            rowsarr.push(dr);
        }
        let wf;
        if (rstore.getPwaStore().selecteditemid.indexOf("web-") === 0) {
            const wfv = rstore.getPwaStore().activeviews.get(rstore.getPwaStore().selecteditemid.substring("web-".length));
            if (wfv !== undefined) {
                wf = {
                    id: rstore.getPwaStore().selecteditemid,
                    schemaid: wfv.schemaid,
                    history: false,
                    backgroundcolor: '',
                    textcolor: '',
                    referer: '',
                    letrid: wfv.letrid,
                    code: wfv.code,
                    creatorid: wfv.creatorid,
                    lastmodifiedby: '',
                    lastmodifytime: 0,
                    name: wfv.name,
                    letrkey: (_a = wfv.letrkey) !== null && _a !== void 0 ? _a : '',
                    schemakey: (_c = (_b = wfv.shopen) === null || _b === void 0 ? void 0 : _b.accesskey) !== null && _c !== void 0 ? _c : '',
                    schemanonce: (_e = (_d = wfv.shopen) === null || _d === void 0 ? void 0 : _d.nonce) !== null && _e !== void 0 ? _e : '',
                    searchbox: wfv.searchboxopen,
                    pubkeys: wfv.keys,
                    useall: false,
                    validids: [],
                    sh: wfv.shopen,
                    schemareports: wfv.schemareports
                };
            }
        }
        else {
            wf = rstore.getPwaStore().activeforms.get(rstore.getPwaStore().selecteditemid);
        }
        if (wf === undefined) {
            return React.createElement(Box, null,
                React.createElement(Text, null, 'jijhih223232i'));
        }
        // return <Box><Text>{'jijhihi'}</Text></Box>
        return React.createElement(Box, { margin: 'auto', marginTop: '10px', w: window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100, h: '100%' },
            React.createElement(PopupImageThumb, null),
            React.createElement(Box, { bg: '#dedfe6', px: 4 },
                React.createElement(Flex, { w: '100%', justify: 'center', paddingTop: '5px', alignItems: 'center' },
                    React.createElement(Text, { fontWeight: 'bold' }, rstore.getPwaStore().activeviewtitle)),
                React.createElement(Flex, { h: 16, alignItems: 'center' },
                    React.createElement(Flex, { alignItems: 'center', _hover: { cursor: 'pointer' }, onClick: () => {
                            let hash = window.location.hash;
                            hash = hash.replace("~~~reports", "");
                            window.history.replaceState({}, document.title, hash);
                            rstore.getPwaStore().setShowReport(false);
                        } },
                        React.createElement(Icon, { as: BiArrowBack })),
                    React.createElement(Flex, { alignItems: 'center', ml: '6px' },
                        React.createElement(Icon, { w: '30px', h: '25px', _hover: { cursor: 'pointer' }, as: MdRefresh, onClick: () => {
                                var _a, _b;
                                this.getReportRows((_b = (_a = rstore.getPwaRemoteStore().activeschemametadata) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '');
                            } })),
                    rstore.getPwaStore().formmessage !== '' && React.createElement(Flex, { justify: 'space-between', align: 'center' },
                        React.createElement(Text, { color: 'red' }, rstore.getPwaStore().formmessage),
                        React.createElement(Icon, { as: MdClose, width: '25px', height: '25px', _hover: { cursor: 'pointer' }, onClick: () => rstore.getPwaStore().addFormmessage("") })))),
            wf.schemareports !== undefined && wf.schemareports.length > 0 &&
                React.createElement(Flex, { align: 'center', mt: '5px', justify: 'center' },
                    React.createElement(Text, { color: AppColor.SideBarIconColor, mr: '10px', fontSize: 12, whiteSpace: 'nowrap' }, "Select Report"),
                    React.createElement(Observer, null, () => React.createElement(ChakraSelect, { width: '200px', size: 'sm', onChange: (e) => {
                            this.getReportRows(e.currentTarget.value);
                        } }, (wf === null || wf === void 0 ? void 0 : wf.schemareports) !== undefined && (wf === null || wf === void 0 ? void 0 : wf.schemareports.map((tab, index) => React.createElement("option", { key: tab.id, value: tab.id }, tab.name)))))),
            rstore.getPwaRemoteStore().loadingcontent && React.createElement(Box, { w: '100%', height: window.innerHeight - 250 },
                React.createElement(Flex, { flexDirection: 'column', h: '100%', align: 'center', justify: 'center' },
                    React.createElement(FadeLoader, { color: AppColor.MainLoaderColor }),
                    React.createElement(Text, { mt: '15px', color: AppColor.MessageColor }, "Loading report"))),
            !rstore.getPwaRemoteStore().loadingcontent && rstore.getPwaRemoteStore().rowsMap.size === 0 &&
                React.createElement(Flex, { flexDirection: 'column', align: 'center', justify: 'center', w: '100%', height: window.innerHeight - 250 },
                    React.createElement(Icon, { h: '30px', w: '30px', as: BsCardHeading, color: AppColor.SideBarIconColor }),
                    React.createElement(Text, { mt: '15px', color: AppColor.MessageColor }, "No entries to show")),
            !rstore.getPwaRemoteStore().loadingcontent && rstore.getPwaRemoteStore().rowsMap.size > 0 &&
                React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: window.innerHeight - 250, }, data: rowsarr, itemContent: (index, row) => React.createElement(RowDisplay, { rows: row, items: items, key: row[0].rowid + "_rowdisplay" }) })));
    }
};
PwaReports.contextType = StoreContext;
PwaReports = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PwaReports);
export { PwaReports };
class RowDisplay extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Flex, { margin: '10px' }, this.props.rows.map((row, index) => React.createElement(ViewCard, { key: row.rowid, rowui: row, displayitems: this.props.items })));
    }
}
let ViewCard = class ViewCard extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        const rstore = this.context;
        const myids = new Set();
        const enmap = new Map();
        const sh = rstore.getPwaRemoteStore().getActiveSchema();
        for (const en of this.props.rowui.entry) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            if (!en.decrypted && sh) {
                if (en.ruleopen) {
                    rstore.getPwaRemoteStore().parseCellRules(en, sh);
                }
                else {
                    rstore.getPwaRemoteStore().decryptCell(en, sh);
                }
            }
            if (en.decrypted) {
                (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
        }
        let listindex = new Set();
        let index = 0;
        for (const item of this.props.displayitems) {
            if (item.type === SchemaItemType.List) {
                listindex.add(index);
            }
            index++;
        }
        const displyitemsrow = [];
        let mitems = [];
        for (let i = 0; i < this.props.displayitems.length; i++) {
            const item = this.props.displayitems[i];
            if (item.type === SchemaItemType.List) {
                if (mitems.length > 0) {
                    displyitemsrow.push(mitems);
                    mitems = [];
                }
                displyitemsrow.push([item]);
            }
            else {
                if (mitems.length > 1) {
                    displyitemsrow.push(mitems);
                    mitems = [];
                }
                mitems.push(item);
            }
        }
        if (mitems.length > 0) {
            displyitemsrow.push(mitems);
        }
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : 500;
        return React.createElement(Box, { ml: window.innerWidth < 600 ? '0px' : '20px', w: totalwidth + "px", bg: this.props.rowui.uirowindex > -1 ? (this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][1] : cardcolors[1][1]) : 'white', boxShadow: '0 0 5px #b6b6b6' },
            React.createElement(Box, { padding: '5px' }, displyitemsrow.map((entrys, index) => {
                return React.createElement(Flex, { mt: '10px', key: this.props.rowui.rowid + "_entryitems" + "_" + index, w: '100%', justifyContent: 'space-between' }, entrys.map((en) => React.createElement(Box, { key: en.id, w: en.type === SchemaItemType.List ? totalwidth + 'px' : totalwidth / 2 + 'px' },
                    React.createElement(Flex, { color: this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][0] : cardcolors[1][0], w: '100%', justify: (en.type === SchemaItemType.List) ?
                            'center' : 'flex-start', align: 'center' },
                        React.createElement(Text, { fontWeight: 'bold' }, en.name)),
                    React.createElement(DisplayItemRow, { rowui: this.props.rowui, item: en, entry: enmap }))));
            })));
    }
};
ViewCard.contextType = StoreContext;
ViewCard = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ViewCard);
class DisplayItemRow extends React.Component {
    constructor(prop) {
        super(prop);
    }
    copyLinkPassword(password, label) {
        const rstore = this.context;
        Clipboard(password);
        toast("Password coped to the clipboard", {
            position: 'top-center',
            type: 'info'
        });
    }
    render() {
        var _a, _b, _c;
        if (this.props.item.type === SchemaItemType.List) {
            return React.createElement(ListDisplay, { rowui: this.props.rowui, item: this.props.item, ens: this.props.entry });
        }
        const ens = this.props.entry.get(this.props.item.id);
        if (!ens || (ens === null || ens === void 0 ? void 0 : ens.length) === 0) {
            return React.createElement(Box, { h: '20px' });
        }
        const rstore = this.context;
        const en = ens[0];
        const entry = this.props.item;
        let choices = [];
        let remotevals = [];
        let udata = undefined;
        let obids = [];
        let vids = new Set();
        if (entry.type === SchemaItemType.Choice) {
            const ebots = en.celldata.split(",");
            if (ebots.length > 0) {
                if (!entry.multiselect) {
                    const cval = ebots[0];
                    for (const val of entry.items) {
                        if (cval === (val.id)) {
                            choices.push(val);
                            break;
                        }
                    }
                }
                else {
                    for (const val of entry.items) {
                        if (ebots.indexOf(val.id) !== -1) {
                            choices.push(val);
                        }
                    }
                }
            }
            en.displaystr = choices.map(c => c.name).join(", ");
        }
        else if (entry.type === SchemaItemType.UserChoice) {
            const ebots = en.celldata.split(",");
            en.displaystr = "";
        }
        else if (entry.type === SchemaItemType.Remote) {
            const uent = entry;
            const id = uent.id + "---" + uent.sid + "---" + uent.cid;
            const vals = rstore.getPwaRemoteStore().dependentdata.get(id);
            if (vals) {
                // now we have this
                const bits = en.celldata.split(",");
                if (bits && bits.length > 0) {
                    remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1);
                    if (remotevals.length > 0) {
                        en.displaystr = remotevals.map((val) => val.value).join(", ");
                    }
                }
            }
        }
        if (en.celldata && entry.type === SchemaItemType.URL) {
            try {
                udata = JSON.parse(en.celldata);
                en.displaystr = "<a href=\"" + udata.url + "\" >" + udata.name + "</a>";
            }
            catch (ex) { }
        }
        if (en.linkedids && en.linkedids.length > 0 && entry.type === SchemaItemType.Objects) {
            const pmap = new Map();
            (_a = en.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((ob) => pmap.set("objs-" + ob.id, ob));
            const smap = new Map();
            (_b = en.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sj) => smap.set(sj.id, sj));
            for (const l of en.linkedids) {
                if (l.startsWith("objs-")) {
                    const obj = pmap.get(l);
                    if (obj) {
                        const tds = obj.type === ProjectObjectType.Document ? obj.thumbdetail : undefined;
                        obids.push({
                            id: l,
                            type: 'document',
                            obj: obj,
                            name: obj.name,
                            thumdet: tds
                        });
                        vids.add(l);
                    }
                }
                else if (l.startsWith("dch-")) {
                    const parts = l.split("-");
                    if (parts.length > 2) {
                        const did = parts[1];
                        const sid = parts[2];
                        const sh = smap.get(sid);
                        if (sh) {
                            obids.push({
                                id: l,
                                type: 'datasheet',
                                obj: sh.name,
                                name: sh.name
                            });
                            vids.add(l);
                        }
                    }
                }
            }
            en.displaystr = obids.map(c => { var _a; return (_a = c.name) !== null && _a !== void 0 ? _a : ''; }).join(", ");
        }
        return React.createElement(Box, null,
            entry.type === SchemaItemType.Text && React.createElement(Box, { dangerouslySetInnerHTML: { __html: en.celldata } }),
            entry.type === SchemaItemType.Date && React.createElement(Flex, { flexWrap: 'wrap', w: '100%' },
                React.createElement(Text, null, DateLib.displayDateWIthFormat(en.celldata, entry.inlcudetime, (_c = entry.excludedate) !== null && _c !== void 0 ? _c : false))),
            entry.type === SchemaItemType.Choice && choices.length > 0 && !entry.multiselect && React.createElement(Flex, { w: '100%' },
                React.createElement(Text, null, choices != undefined && choices.length > 0 ? choices[0].name : '')),
            entry.type === SchemaItemType.Choice && choices.length > 0 && entry.multiselect && React.createElement(Flex, { flexWrap: 'wrap', w: '100%' }, choices.map((ch) => React.createElement(Tag, { key: ch.id, m: '3px' }, ch.name))),
            entry.type === SchemaItemType.Remote && React.createElement(Flex, { flexWrap: 'wrap', w: '100%' }, remotevals.map((ch) => React.createElement(Tag, { key: ch.id, m: '3px' }, ch.value))),
            entry.type === SchemaItemType.Color && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: en.celldata }),
            entry.type === SchemaItemType.Boolean && React.createElement(Flex, { w: '100%', align: 'center' }, en.celldata === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' })),
            entry.type === SchemaItemType.Number && React.createElement(Flex, { w: '100%', paddingRight: '5px' }, en.celldata),
            entry.type === SchemaItemType.FormInput && React.createElement(Flex, { align: 'center' },
                React.createElement(Text, null, entry.enablesecret ? '****' : en.celldata),
                entry.enablesecret && React.createElement(Icon, { onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.copyLinkPassword(en.celldata, entry.name);
                    }, as: FaClipboard, ml: '5px' })),
            entry.type === SchemaItemType.URL && udata !== undefined && React.createElement(Link, { href: (udata.url), isExternal: true },
                ((udata.name !== undefined && udata.name !== '') ? udata.name :
                    udata.url),
                React.createElement(Icon, { as: FiExternalLink, mx: '2px' })),
            entry.type === SchemaItemType.Objects && React.createElement(Flex, { flexWrap: 'wrap', maxW: '100%', mt: '10px' }, obids.map((obj, index) => {
                let name = "";
                let shwthumb = false;
                let ratio = -1;
                let tdet = {
                    name: '',
                    width: 0,
                    height: 0
                };
                if (obj.type === 'document') {
                    const bo = obj.obj;
                    name = bo.name;
                    if (bo.type === ProjectObjectType.Document) {
                        const do1 = bo;
                        if (do1.thumbdetail) {
                            let zerothum = -1;
                            for (const fg of do1.thumbdetail) {
                                if (fg.name.startsWith("0_thumb")) {
                                    if (fg.width > 0 && fg.height > 0) {
                                        zerothum = fg.height / fg.width;
                                        tdet = fg;
                                    }
                                    break;
                                }
                            }
                            if (zerothum > 0) {
                                shwthumb = true;
                                ratio = zerothum;
                            }
                        }
                    }
                }
                else {
                    name = obj.obj + "";
                }
                if (shwthumb) {
                    return React.createElement(Box, { key: obj.id + "_" + index, w: '100px', onClick: () => {
                            // lets open this
                            if (ThumbNailStore.activethumid) {
                                ThumbNailStore.setActiveThumb("");
                            }
                        } },
                        React.createElement(Flex, { align: 'center' },
                            React.createElement(ThumbNail, { clickcb: () => {
                                }, rowid: en.rowid, cellid: en.cellid, entryid: en.id, obj: obj.obj, ratio: ratio, thumbdet: tdet })),
                        React.createElement(Flex, { align: 'center' },
                            React.createElement(Tag, { ml: '5px', key: obj.id, margin: '4px' },
                                React.createElement(TagLabel, null, name))));
                }
                return React.createElement(Tag, { onClick: () => {
                        // lets open this
                    }, key: obj.id, margin: '4px' },
                    React.createElement(TagLabel, null, name));
            })));
    }
}
DisplayItemRow.contextType = StoreContext;
let ThumbNail = class ThumbNail extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
        this.state = {
            hover: false
        };
    }
    async componentDidMount() {
        const rstore = this.context;
        if (!ThumbNailStore.thumbs.has(this.props.obj.documentreference)) {
            if (!ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference)) {
                ThumbNailStore.setLoadingThumb(this.props.obj.documentreference);
                const page = await rstore.getPwaRemoteStore().getPdfViewerContent(this.props.obj.documentreference, "0_thumb", this.props.rowid, rstore.getPwaStore().getActiveFormUserId(), this.props.obj.accesskey, this.props.cellid, this.props.entryid, this.props.obj.id);
                ThumbNailStore.addThumb(this.props.obj.documentreference, page !== null && page !== void 0 ? page : '');
            }
        }
    }
    render() {
        var _a, _b;
        return React.createElement(Box, { ref: (re) => this.myref = re, width: '100px', height: 75 + 'px', pos: 'relative' },
            ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference) && React.createElement(Text, null, "Loading.."),
            ThumbNailStore.thumbs.has(this.props.obj.documentreference) && React.createElement("img", { onMouseDown: () => {
                    console.log("oops");
                }, onClick: (e) => {
                    e.stopPropagation();
                    if (this.props.obj.documentreference === ThumbNailStore.activethumid)
                        return;
                    ThumbNailStore.setActiveThumb(this.props.obj.documentreference);
                    if (this.myref) {
                        let left = this.myref.getBoundingClientRect().x;
                        if (window.innerWidth < 400) {
                            left = 0;
                        }
                        else if ((window.innerWidth - left) < 350) {
                            const diff = window.innerWidth - left;
                            const cover = 350 - diff;
                            let newleft = left - cover;
                            if (newleft < 0) {
                                newleft = 0;
                            }
                            left = newleft;
                        }
                        ThumbNailStore.setLeftPos(left);
                        ThumbNailStore.setTopPos(this.myref.getBoundingClientRect().y);
                    }
                    ThumbNailStore.clickcb = () => this.props.clickcb();
                }, width: '100px', height: '75px', style: { objectFit: 'contain', width: 100, height: 75, cursor: 'pointer' }, src: (_a = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _a !== void 0 ? _a : '' }),
            this.state.hover && React.createElement("img", { style: { position: 'absolute', top: 0, left: 0, width: 300, height: 200, objectFit: 'contain' }, src: (_b = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _b !== void 0 ? _b : '' }));
    }
};
ThumbNail.contextType = StoreContext;
ThumbNail = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ThumbNail);
let ListDisplay = class ListDisplay extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        let displayitems = ((_a = this.props.item.items) !== null && _a !== void 0 ? _a : []).map((it) => (Object.assign(Object.assign({}, it.item), { id: this.props.item.id + "----" + it.item.id })));
        const sortedmap = new Map();
        let sortedrows = [];
        displayitems.forEach((item) => {
            const ens = this.props.ens.get(item.id);
            if (ens) {
                ens.sort(sortEntriesByRow);
                ens.forEach((en) => {
                    if (en.subrowindex) {
                        if (sortedrows.indexOf(en.subrowindex) === -1) {
                            sortedrows.push(en.subrowindex);
                        }
                        if (!sortedmap.has(en.subrowindex)) {
                            sortedmap.set(en.subrowindex, new Map());
                        }
                        const cellid = en.cellid;
                        let cellmap = sortedmap.get(en.subrowindex);
                        if (!cellmap) {
                            cellmap = new Map();
                            sortedmap.set(en.subrowindex, cellmap);
                        }
                        cellmap.set(cellid, [en]);
                    }
                });
            }
        });
        sortedrows.sort((s1, s2) => {
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (parts1[0] === parts2[0]) {
                // if(parts1.length < 2 || parts2.length < 2) {
                //     return -1;
                // }
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : 450;
        let swidth = displayitems.length * 120;
        if (swidth < totalwidth) {
            swidth = totalwidth;
        }
        return React.createElement(Box, { mt: '10px', ml: '10px', w: window.innerWidth < 600 ? (window.innerWidth - 55) + "px" : '450px', maxW: window.innerWidth < 600 ? (window.innerWidth - 55) + "px" : '450px', overflowX: 'auto', minH: '40px' },
            React.createElement(SimpleGrid, { color: this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][0] : cardcolors[1][0], wordBreak: 'break-word', columns: displayitems.length, width: swidth + "px", spacingX: '20px', spacingY: '20px', borderBottomColor: 'lightgray', borderBottomWidth: '1px' }, displayitems.map((item, index) => React.createElement(Flex, { key: item.id + "_header" + index, w: '100px', maxW: '100px' },
                React.createElement(Text, { fontWeight: 'bold' }, item.name)))),
            sortedrows.map((sr) => React.createElement(SimpleGrid, { width: swidth + "px", wordBreak: 'break-word', borderBottomColor: 'lightgray', borderBottomWidth: '1px', mt: '5px', key: sr + "_row", columns: displayitems.length, spacingX: '20px', spacingY: '20px' },
                displayitems.map((item) => {
                    const enmap = sortedmap.get(sr);
                    const key = item.id + "_" + sr;
                    if (!enmap) {
                        return React.createElement(Box, { key: key, w: '100px', maxW: '100px' });
                    }
                    const en = enmap === null || enmap === void 0 ? void 0 : enmap.get(item.id);
                    if (!en) {
                        return React.createElement(Box, { key: key, w: '100px', maxW: '100px' });
                    }
                    return React.createElement(Box, { key: key, w: '100px', maxW: '100px' },
                        React.createElement(DisplayItemRow, { rowui: this.props.rowui, item: item, entry: enmap }));
                }),
                sortedrows.length === 0 && React.createElement(Box, { w: '100%', h: '20px' }))));
        /*
        return <Box ml={'10px'} w={window.innerWidth < 600 ? (window.innerWidth - 35) + "px" : '500px'}
            bg={'whiteAlpha.400'} boxShadow={'0 0 5px #b6b6b6'} >
            <Box padding='5px'>
                {displyitemsrow.map((entrys, index) => {
                    return <Flex key={this.props.item.id + "_entryitems" + "_" + index} w='100%' justifyContent={'space-between'}>
                        {entrys.map((en) => <Box key={en.id} w='240px' >
                            <Text fontWeight={'bold'}>{en.name}</Text>
                            <DisplayItemRow item={en} entry={this.props.ens} />
                        </Box>)}
                    </Flex>
                })}
            </Box>
        </Box>*/
    }
};
ListDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplay);
