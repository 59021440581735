var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { observer } from 'mobx-react';
import React from 'react';
import { ThumbNailStore } from '../uistore/ThumbNailStore';
let PopupImageThumb = class PopupImageThumb extends React.Component {
    constructor(prop) {
        super(prop);
        window.addEventListener('mousedown', () => {
            if (ThumbNailStore.activethumid) {
                ThumbNailStore.setActiveThumb("");
            }
        });
    }
    render() {
        var _a;
        if (ThumbNailStore.activethumid === '') {
            return React.createElement(React.Fragment, null);
        }
        let top = ThumbNailStore.positintop;
        let left = ThumbNailStore.positinleft;
        if (top > 250) {
            top -= 200;
        }
        if (left > 150) {
            left -= 100;
        }
        return React.createElement("img", { onMouseDown: () => {
                if (ThumbNailStore.activethumid) {
                    ThumbNailStore.setActiveThumb("");
                }
            }, style: { objectFit: 'contain', width: 300, zIndex: 10000,
                height: 300, position: 'fixed', left: left, top: top }, src: (_a = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(ThumbNailStore.activethumid)) !== null && _a !== void 0 ? _a : '' });
    }
};
PopupImageThumb = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PopupImageThumb);
export { PopupImageThumb };
