export var ExternalEditOpt;
(function (ExternalEditOpt) {
    ExternalEditOpt[ExternalEditOpt["Delete"] = 0] = "Delete";
})(ExternalEditOpt || (ExternalEditOpt = {}));
export class ExternalContactEdit {
    constructor(grp, editopt) {
        this.grp = grp;
        this.editopt = editopt;
    }
}
export class ExternalContactEditResponse {
    constructor(grp, editopt, success) {
        this.grp = grp;
        this.editopt = editopt;
        this.success = success;
    }
}
