import { comm } from "@/Comm/comm";
import { AppState } from "@/share/AppState";
import { Auth } from "@/background/store/AuthStore";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { AuthHeaders as AuthHeader } from "@@/AuthHeader";
import { AppConfig } from "@/config/AppConfig";
import { BEvent } from "@/events/BackgroundEvents";
import { instanceOfNewMessage, instanceOfUpdateLetrLive, parseEvent, instanceOfEditMessage, instanceOfDeleteLetr, instanceOfDeleteMessage, instanceOfReply, instanceOfReplyEdit, instanceOfDeleteReply, instanceOfNewManagedLetr, instanceOfUnreadDetail, instanceOfExternalContact, instanceOfActiveCallLetr, instanceOfCallActioned, instanceOfCallEnded } from '@@/Live';
import { LStore } from "@/background/store/LetrStore";
import { GStore } from "@/background/store/GroupStore";
import { MStore } from "./MessageStore";
import { ManagedStore } from "./ManagedStore";
import { UnreadStore } from "./UnreadStore";
import { NStore } from "./NotifyStore";
import { OrgContactStore } from "./OrgContactStore";
import { TStore } from "./TagStore";
import { timeout } from "./MediaStore";
import { NoteStore } from "./Notestore";
var ConnecState;
(function (ConnecState) {
    ConnecState[ConnecState["Connected"] = 0] = "Connected";
    ConnecState[ConnecState["Destroyed"] = 1] = "Destroyed";
    ConnecState[ConnecState["Connecting"] = 2] = "Connecting";
})(ConnecState || (ConnecState = {}));
class Store {
    constructor() {
        this.connectstate = ConnecState.Destroyed;
        this.currentCode = -1;
        this.opencb = () => this.open();
        this.onclosecb = (ev) => this.close(ev.code);
        this.onerrorcb = () => this.error();
        this.onmessagecb = (ev) => this.message(ev);
        this.retryAttemp = 0;
        this.lastHeartBeat = 0;
        this.pendingReques = [];
        this.uploadedReq = new Map();
        this.downloadReq = new Map();
        this.activeobjectid = "";
        this.activeletrid = "";
        this.lastpulltimefornotes = 0;
        BEvent.addSubscriberForAppState((state) => {
            if (state === AppState.LoggedIn) {
                this.connect(false);
            }
            else if (state === AppState.Logout || state === AppState.Register || state === AppState.Login) {
                this.destroy();
            }
        });
        BEvent.addSubscriberForReconnect(() => {
            this.connect(false);
        });
    }
    checkAndPingConnection() {
        var _a;
        const ctime = new Date().getTime();
        const diff = ctime - this.lastHeartBeat;
        if (diff > (20 * 1000)) {
            (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close(3000); // we need to do reattemp now!
            this.socket = undefined;
            if (this.mnitorTiemout) {
                clearTimeout(this.mnitorTiemout);
            }
            this.mnitorTiemout = undefined;
            return false;
        }
        else {
            this.sendMessage('ping');
            // we need to revisit updates properly!!
            //this.sendMessage("quota"); 
            // this.sendMessage("updates");
            this.sendMessage("assigned");
            this.sendMessage("fileupdates");
            return true;
        }
    }
    checkUpdatesForObjectDelta(letrid, objectid, draftid, since, ignid = "") {
        this.sendMessage("draftupdates-" + since + "-" + letrid + "-" + draftid + "-" + objectid + "-" + ignid);
    }
    checkUpdatesForObjectNotes(objectid, letrid, since, refid) {
        this.sendMessage("noteupdates-" + since + "-" + objectid + "-" + letrid + "-" + refid);
    }
    checkUpdatesForNotesComments(noteid, letrid, since) {
        this.sendMessage("comments-" + since + "-" + letrid + "-" + noteid);
    }
    checkSchemaUpdates(letrid, datasheetid, since, schemaid) {
        this.sendMessage("schemaupdate-" + letrid + "-" + schemaid + "-" + since);
    }
    isAlive() {
        if (this.socket !== undefined && this.socket.OPEN) {
            return true;
        }
        return false;
    }
    downloadChunk(id, req, cb) {
        if (this.socket === undefined || this.connectstate === ConnecState.Destroyed) {
            cb("SocketDisconnect");
            return;
        }
        // now we can download the chunk here..
        const wreq = "download-" + id + "-" + JSON.stringify(req);
        this.downloadReq.set(id, {
            req: req,
            id: id,
            cb: cb
        });
        this.socket.send(wreq);
    }
    async destroy() {
        var _a;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close(1000);
        this.socket = undefined;
    }
    close(code) {
        this.connectstate = ConnecState.Destroyed;
        this.socket = undefined;
        Auth.checkAndReconnect();
    }
    getLetrUpdates(letrid) {
        this.sendMessage("letrupdates-" + letrid);
    }
    async message(messageEvent) {
        var _a, _b, _c;
        const data = messageEvent.data;
        this.lastHeartBeat = new Date().getTime();
        if (data) {
            if (data === 'ping') {
                this.sendMessage('pong');
                return;
            }
            else if (data === 'pong') {
                return;
            }
            else if (data.startsWith("quota")) {
                const qt = data.substring("quota-".length);
                try {
                    const quota = JSON.parse(qt);
                    BEvent.publishrQuota(quota);
                }
                catch (ex) { }
                return;
            }
            else if (data.startsWith("updates-")) {
                const dec = data.substring("updates-".length);
                try {
                    const count = parseInt(dec);
                    BEvent.publishrNotifyCount(count);
                }
                catch (ex) { }
                return;
            }
            else if (data.startsWith("assigned-")) {
                const dec = data.substring("assigned-".length);
                try {
                    const count = parseInt(dec);
                    BEvent.publishNotifyTaskCount(count);
                }
                catch (ex) { }
                return;
            }
            else if (data.startsWith("fileupdates-")) {
                const dec = data.substring("fileupdates-".length);
                try {
                    const count = parseInt(dec);
                    BEvent.publishNotifyFileCount(count);
                }
                catch (ex) { }
                return;
            }
            else if (data.startsWith("uploadresponse-")) {
                const parts = data.split("-");
                if (parts.length > 2 &&
                    this.uploadedReq.has(parts[1])) {
                    const ok = parts[2] === 'OK' ? true : false;
                    (_a = this.uploadedReq.get(parts[1])) === null || _a === void 0 ? void 0 : _a.cb(ok);
                }
                return;
            }
            else if (data.startsWith("downloadresponse-")) {
                const parts = data.split("-");
                if (parts.length > 3 && this.downloadReq.has(parts[1])) {
                    const ok = parts[2] === 'OK' ? true : false;
                    if (ok) {
                        const chunks = JSON.parse(parts[3]);
                        (_b = this.downloadReq.get(parts[1])) === null || _b === void 0 ? void 0 : _b.cb(chunks);
                    }
                    else {
                        (_c = this.downloadReq.get(parts[1])) === null || _c === void 0 ? void 0 : _c.cb(undefined);
                    }
                }
                return;
            }
            else if (data.startsWith("noteupdates-")) {
                const parts = data.split("-");
                if (parts.length > 3) {
                    const letrid = parts[2];
                    const objectid = parts[1];
                    const nstr = parts.slice(3).join("-");
                    ;
                    try {
                        const notes = JSON.parse(nstr);
                        // now we have the updates lets publish this and we done !
                        NoteStore.updates(notes, letrid, objectid);
                    }
                    catch (ex) {
                        console.log("Exception");
                        console.log(ex);
                    }
                }
                return;
            }
            else if (data.startsWith("comments-")) {
                const parts = data.split("-", 4);
                if (parts.length > 3) {
                    const letrid = parts[2];
                    const noteid = parts[1];
                    const nstr = parts[3];
                    try {
                        const notes = JSON.parse(nstr);
                        if (notes.length > 0) {
                            const comms = NoteStore.parseComments(notes, letrid);
                            if (comms) {
                                BEvent.publishrCommentsUpdate({
                                    letrid: letrid,
                                    noteid: noteid,
                                    comments: comms
                                });
                            }
                        }
                    }
                    catch (ex) {
                    }
                }
                return;
            }
            else if (data.startsWith("draftupdates-")) {
                const parts = data.split("-", 2);
                if (parts.length > 1) {
                    const nstr = parts[1];
                    try {
                        const updates = JSON.parse(nstr);
                        if (updates.length > 0) {
                            BEvent.publishrDraftUpdates(updates);
                        }
                    }
                    catch (ex) {
                    }
                }
                return;
            }
            else if (data.startsWith("schemaupdate-")) {
                const parts = data.split("-", 4);
                if (parts.length > 3) {
                    const nstr = parts[3];
                    try {
                        const supdate = JSON.parse(nstr);
                        if (supdate.itemcount > 0) {
                            const update = {
                                letrid: parts[1],
                                schemaid: parts[2],
                                itemcount: supdate.itemcount
                            };
                            BEvent.publishSchemaUpdate(update);
                        }
                    }
                    catch (ex) {
                    }
                }
                return;
            }
            const edata = parseEvent(data);
            if (edata === 'unknown') {
                return;
            }
            if (edata === 'NewLetr') {
                LStore.fetchNewLetrs();
            }
            else if (edata === 'TagChange') {
                TStore.loadChildTags();
            }
            else if (edata === 'UpdateOrgContact') {
                // now lets get the box if we have to
                OrgContactStore.updateOrgContact();
            }
            else if (edata === "ActiveLetrCall") {
                console.log("HERE INST ACTIVE CALL  1");
                NStore.sendNotification("New active ongoing call. Please check active call Letr tag to join");
            }
            else if (edata === 'NewInvite') {
                this.getInvites();
            }
            else if (edata === 'deleteme') {
                Auth.deleteThiDevice(() => console.log());
            }
            else if (edata === "newmanagedcontact") {
                ManagedStore.getAllNewManagedContacts();
            }
            else if (edata === 'NewPublicContact') {
                MStore.receivedNewPublicContact();
            }
            else if (instanceOfActiveCallLetr(edata)) {
                // NStore.sendNotification("New active ongoing meeting. Please check active meeting Letr tag to join");
                BEvent.publishNewActiveLetridCall(edata.activecallletrid);
            }
            else if (instanceOfUpdateLetrLive(edata)) {
                const letr = edata.letr;
                // now we have new edit letr here
                LStore.letrUpdateAvailable(letr);
            }
            else if (instanceOfNewMessage(edata)) {
                const msg = edata.msg;
                MStore.receivedMessage(msg, this.lastHeartBeat);
            }
            else if (instanceOfEditMessage(edata)) {
                const msg = edata.emsg;
                MStore.receivedMessage(msg);
            }
            else if (instanceOfDeleteLetr(edata)) {
                const letrid = edata.letrid;
                // todo: fix me
            }
            else if (instanceOfDeleteMessage(edata)) {
                const bmsg = edata.dmsg;
                MStore.receivedDeleteMessage(bmsg);
            }
            else if (instanceOfReply(edata)) {
                const rmsg = edata.rmsg;
                MStore.receivedReply(rmsg);
            }
            else if (instanceOfReplyEdit(edata)) {
                const remsg = edata.remsg;
                await timeout(1000);
                MStore.receivedEditedReply(remsg);
            }
            else if (instanceOfDeleteReply(edata)) {
                const rdmsg = edata.rdmsg;
                MStore.receivedDeletedReply(rdmsg);
            }
            else if (instanceOfNewManagedLetr(edata)) {
                BEvent.publishForNewManagedLetr(edata.email);
            }
            else if (instanceOfUnreadDetail(edata)) {
                UnreadStore.parseAndPublishUnreadDetails(edata.detail);
            }
            else if (instanceOfExternalContact(edata)) {
                NStore.sendNotification(edata.message);
                BEvent.publishContactUpdates("");
            }
            else if (instanceOfCallActioned(edata)) {
                const req = {
                    letrid: edata.callid
                };
                BEvent.publishCallActioned(req);
            }
            else if (instanceOfCallEnded(edata)) {
                const req = {
                    letrid: edata.activecallid
                };
                BEvent.publishCallEnded(req);
            }
        }
    }
    async getInvites() {
        const inv = await GStore.getAllInvitations();
        if (inv) {
            BEvent.publishInvites(inv);
        }
    }
    async uploadFileChunk(chunk, id, cb) {
        if (this.socket === undefined || this.connectstate === ConnecState.Destroyed) {
            this.pendingReques.push({ chunk: chunk, id: id, cb: cb });
            this.connect(false);
            // we need to 
        }
        else {
            this.actuallyUpload(chunk, id, cb);
        }
    }
    actuallyUpload(chunk, id, cb) {
        const message = "upload-" + id + "-" + JSON.stringify(chunk);
        this.sendMessage(message);
        this.uploadedReq.set(id, { chunk: chunk, id: id, cb: cb });
    }
    sendMessage(msg) {
        var _a;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.send(msg);
    }
    async connect(isreconnect) {
        if (this.connectstate === ConnecState.Connected ||
            this.connectstate === ConnecState.Connecting) {
            return;
        }
        this.connectstate = ConnecState.Connecting;
        const loggedin = await Auth.isLoggedIn();
        if (!loggedin) {
            this.connectstate = ConnecState.Destroyed;
            if (this.pendingReques.length != 0) {
                this.pendingReques.forEach((pr) => pr.cb(false));
            }
            this.pendingReques = [];
            return;
        }
        if (this.lastHeartBeat > 0) {
            await LStore.fetchLetrUpdatesSince(this.lastHeartBeat);
            await MStore.loadLatestMessagesForActiveLetr(this.lastHeartBeat);
        }
        const res = await comm.get(ServerPath.websocketLogin);
        if (res !== undefined) {
            this.currentCode = Math.random();
            const tenantid = InMemoryRepo.getTenant();
            this.socket = new WebSocket(AppConfig.wssurl + AppConfig.root + "/wshandler?token=" + res + "&" +
                AuthHeader.TenantIDGET + "=" + tenantid);
            this.socket.onopen = this.opencb;
            this.socket.onclose = this.onclosecb;
            this.socket.onerror = this.onerrorcb;
            this.socket.onmessage = this.onmessagecb;
            this.retryAttemp = 0;
        }
        else {
            this.connectstate = ConnecState.Destroyed;
            if (this.retryAttemp > 5) {
                return;
            }
            this.retryAttemp++;
            // now we need to attemp reconnect again..
            const timeout = 10 * 1000 * (this.retryAttemp);
            setTimeout(() => this.connect(false), timeout);
        }
    }
    error() {
        // lets reconnect etc
    }
    open() {
        this.connectstate = ConnecState.Connected;
        this.lastHeartBeat = new Date().getTime();
        this.monitor(this.currentCode);
        this.checkAndUploadPendingReq();
    }
    checkAndUploadPendingReq() {
        if (this.pendingReques.length > 0) {
            this.pendingReques.forEach((pr) => this.uploadFileChunk(pr.chunk, pr.id, pr.cb));
        }
        this.pendingReques = [];
    }
    monitor(code) {
        if (this.currentCode === code) {
            if (this.checkAndPingConnection()) {
                this.mnitorTiemout = setTimeout(() => this.monitor(code), 10 * 1000);
            }
            else {
                // we should do reconnection here..
            }
        }
    }
}
export const LiveStore = new Store();
