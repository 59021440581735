var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ProjectObjectType } from '@@/Project';
import { Box, Flex, Icon, Tag, TagLabel, Text } from '@chakra-ui/react';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { observer } from 'mobx-react';
import React from 'react';
import { AppColor } from '../const/AppDefaults';
import { StoreContext } from '../uistore/PwaRootStore';
import { RemotePopupViewStore } from '../uistore/RemotePopupViewStore';
import { ThumbNailStore } from '../uistore/ThumbNailStore';
let PwaRemotePopupView = class PwaRemotePopupView extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        if (RemotePopupViewStore.activeid === '') {
            return React.createElement(React.Fragment, null);
        }
        let top = RemotePopupViewStore.positintop;
        let left = RemotePopupViewStore.positinleft;
        if (top > 250) {
            top -= 220;
        }
        if (left > 150) {
            left -= 100;
        }
        let rtDisplayItems = RemotePopupViewStore.rtDisplayItems;
        return React.createElement(Box, { style: { width: 300, zIndex: 9007,
                height: 300, position: 'fixed', left: left, top: top }, backgroundColor: RemotePopupViewStore.bgcolor ? RemotePopupViewStore.bgcolor : 'lightgrey', className: 'remote-popup-card' },
            React.createElement(Icon, { right: '5px', pos: 'absolute', as: MdClose, color: AppColor.DeleteIconColor, h: '20px', w: '20px', _hover: { cursor: 'pointer' }, onClick: () => {
                    if (RemotePopupViewStore.activeid) {
                        RemotePopupViewStore.setActiveId("");
                    }
                } }),
            React.createElement(Box, { overflow: 'auto', height: 280, mb: '15px' }, rtDisplayItems.map((en) => React.createElement(Box, { w: '100%', key: en.id + "_tn" },
                React.createElement(Text, { fontWeight: 'bold' }, en.label),
                en.objects === undefined && React.createElement(Text, null, en.value),
                en.objects !== undefined &&
                    React.createElement(Flex, { flexWrap: 'wrap', maxW: '100%', mt: '10px', maxH: '300px', overflow: 'auto' }, en.objects.map((obj, index) => {
                        var _a, _b, _c;
                        let name = "";
                        let shwthumb = false;
                        let ratio = -1;
                        let tdet = {
                            name: '',
                            width: 0,
                            height: 0
                        };
                        if (obj.type === 'document') {
                            const bo = obj.obj;
                            name = bo.name;
                            if (bo.type === ProjectObjectType.Document) {
                                const do1 = bo;
                                if (do1.thumbdetail) {
                                    let zerothum = -1;
                                    for (const fg of do1.thumbdetail) {
                                        if (fg.name.startsWith("0_thumb")) {
                                            if (fg.width > 0 && fg.height > 0) {
                                                zerothum = fg.height / fg.width;
                                                tdet = fg;
                                            }
                                            break;
                                        }
                                    }
                                    if (zerothum > 0) {
                                        shwthumb = true;
                                        ratio = zerothum;
                                    }
                                }
                            }
                        }
                        else {
                            name = obj.obj + "";
                        }
                        if (shwthumb) {
                            return React.createElement(Box, { key: obj.id + "_" + index, w: '100px', onClick: () => {
                                    // lets open this
                                    if (ThumbNailStore.activethumid) {
                                        ThumbNailStore.setActiveThumb("");
                                    }
                                } },
                                React.createElement(Flex, { align: 'center' },
                                    React.createElement(ThumbNail, { clickcb: () => {
                                        }, rowid: (_a = en.rowid) !== null && _a !== void 0 ? _a : '', cellid: (_b = en.cellid) !== null && _b !== void 0 ? _b : '', entryid: en.id, schemaid: (_c = en.schemaid) !== null && _c !== void 0 ? _c : '', obj: obj.obj, ratio: ratio, thumbdet: tdet })),
                                React.createElement(Flex, { align: 'center' },
                                    React.createElement(Tag, { ml: '5px', key: obj.id, margin: '4px' },
                                        React.createElement(TagLabel, null, name))));
                        }
                        return React.createElement(Tag, { onClick: () => {
                                // lets open this
                            }, key: obj.id, margin: '4px' },
                            React.createElement(TagLabel, null, name));
                    }))))));
    }
};
PwaRemotePopupView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PwaRemotePopupView);
export { PwaRemotePopupView };
let ThumbNail = class ThumbNail extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
        this.state = {
            hover: false
        };
    }
    async componentDidMount() {
        const rstore = this.context;
        if (!ThumbNailStore.thumbs.has(this.props.obj.documentreference)) {
            if (!ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference)) {
                ThumbNailStore.setLoadingThumb(this.props.obj.documentreference);
                const page = await rstore.getPwaStore().getPdfViewerContent(this.props.obj.documentreference, "0_thumb", this.props.rowid, rstore.getPwaStore().getActiveFormUserId(), this.props.obj.accesskey, this.props.cellid, this.props.entryid, this.props.obj.id, this.props.schemaid);
                ThumbNailStore.addThumb(this.props.obj.documentreference, page !== null && page !== void 0 ? page : '');
            }
        }
    }
    render() {
        var _a, _b;
        return React.createElement(Box, { ref: (re) => this.myref = re, width: '100px', height: 75 + 'px', pos: 'relative' },
            ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference) && React.createElement(Text, null, "Loading.."),
            ThumbNailStore.thumbs.has(this.props.obj.documentreference) && React.createElement("img", { onMouseDown: () => {
                    console.log("oops");
                }, onClick: (e) => {
                    e.stopPropagation();
                    if (this.props.obj.documentreference === ThumbNailStore.activethumid)
                        return;
                    ThumbNailStore.setActiveThumb(this.props.obj.documentreference);
                    if (this.myref) {
                        let left = this.myref.getBoundingClientRect().x;
                        if (window.innerWidth < 400) {
                            left = 0;
                        }
                        else if ((window.innerWidth - left) < 350) {
                            const diff = window.innerWidth - left;
                            const cover = 350 - diff;
                            let newleft = left - cover;
                            if (newleft < 0) {
                                newleft = 0;
                            }
                            left = newleft;
                        }
                        ThumbNailStore.setLeftPos(left);
                        ThumbNailStore.setTopPos(this.myref.getBoundingClientRect().y);
                    }
                    ThumbNailStore.clickcb = () => this.props.clickcb();
                }, width: '100px', height: '75px', style: { objectFit: 'contain', width: 100, height: 75, cursor: 'pointer' }, src: (_a = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _a !== void 0 ? _a : '' }),
            this.state.hover && React.createElement("img", { style: { position: 'absolute', top: 0, left: 0, width: 300, height: 200, objectFit: 'contain' }, src: (_b = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _b !== void 0 ? _b : '' }));
    }
};
ThumbNail.contextType = StoreContext;
ThumbNail = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ThumbNail);
