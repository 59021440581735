var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import { StoreContext } from "../uistore/PwaRootStore";
let OfflineMessage = class OfflineMessage extends React.Component {
    render() {
        const rstore = this.context;
        if (rstore.getPwaStore().online) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Text, { pos: 'fixed', top: '0px', textAlign: 'center', width: window.innerWidth, bg: 'red', color: 'white' }, "You're offline. App might not work as expected.");
    }
};
OfflineMessage.contextType = StoreContext;
OfflineMessage = __decorate([
    observer
], OfflineMessage);
export { OfflineMessage };
