import { comm } from "@/Comm/comm";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { OrgHeader } from "@@/RegisterModel";
class Service {
    async getLoginDetails(appcode, appid, devicecode) {
        const req = {
            appcode: appid,
            devicecode: devicecode,
        };
        const res = await comm.post(ServerPath.verifyAndGetLoginAnotherDevice, req, [OrgHeader]);
        if (res !== undefined) {
            const uebox = LetrwingCrypto.decryptSecretBox(res, await LetrwingCrypto.getHash256(appcode));
            if (uebox !== undefined) {
                try {
                    const ddata = JSON.parse(uebox);
                    // now we have everything lets just make another org call to get the
                    // identity token and we done
                    const req = {
                        tenant: ddata.tenant,
                        email: ddata.email,
                        appid: appid,
                        deviceocde: devicecode
                    };
                    const ires = await comm.post(ServerPath.getIdentityTokenForAnotherDeviceLogin, req, [OrgHeader]);
                    if (ires !== undefined) {
                        const ret = {
                            token: ires,
                            password: ddata.password,
                            tenant: ddata.tenant
                        };
                        return ret;
                    }
                }
                catch (ex) { }
            }
        }
        return undefined;
    }
}
export const LoginViaDeviceStore = new Service();
