class RenderLocalRepo {
    constructor() {
        this.deviceidkey = "X-Letrwing-Deviceid";
    }
    store(data, cb) {
        localStorage.setItem(data.typename + data.data._id, JSON.stringify(data.data));
        cb === null || cb === void 0 ? void 0 : cb(null, data.data);
    }
    get(typename, id, callback) {
        const item = localStorage.getItem(typename + id);
        if (item === null) {
            callback(new Error("No match"), null);
            return;
        }
        else {
            callback(null, JSON.parse(item));
        }
    }
    storeDeviceId(deviceid) {
        localStorage.setItem(this.deviceidkey, deviceid);
    }
    getDeviceId() {
        return localStorage.getItem(this.deviceidkey);
    }
    deleteAllDB() {
        localStorage.clear();
    }
    getAndRemove(typename, id, callback) {
        this.get(typename, id, (err, document) => {
            localStorage.removeItem(typename);
            if (err !== null || document === null) {
                callback(err, document);
                return;
            }
            callback(null, document);
        });
    }
}
export const LocalRepo = new RenderLocalRepo();
