var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ALLViewer } from '@@/Annotation';
import { Box, Flex, Text, Icon } from '@chakra-ui/react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { BaseDialog, BaseDialogState } from './BaseDialog';
import { MdCheckBox } from '@react-icons/all-files/md/MdCheckBox';
import { MdCheckBoxOutlineBlank } from '@react-icons/all-files/md/MdCheckBoxOutlineBlank';
import { AppColor } from '../const/AppDefaults';
export class SelectMemberDialogState {
    constructor() {
        this.members = [];
        this.letrlinks = [];
        this.activetab = "contact";
        this.basestate = new BaseDialogState();
        this.selectedcontacts = new Set();
        this.readmode = false;
        this.showallviewers = false;
        this.allviewers = false;
        makeObservable(this);
        this.basestate.setHeaderLabel("Select Member");
        this.basestate.setOkLabel("Done");
        this.basestate.onClose = () => {
            this.basestate.setOpen(false);
        };
        this.basestate.onOk = () => {
            var _a;
            (_a = this.ondonecb) === null || _a === void 0 ? void 0 : _a.call(this);
            this.basestate.setOpen(false);
        };
    }
    setTab(tab) {
        this.activetab = tab;
    }
    setLetrMembers(mems) {
        this.members.splice(0, this.members.length);
        this.members.push(...mems);
    }
    addLetrMembers(mem) {
        this.members.push(mem);
    }
    setLetrLinks(mems) {
        this.letrlinks.splice(0, this.letrlinks.length);
        this.letrlinks.push(...mems);
    }
    reset() {
        this.members.splice(0, this.members.length);
        this.letrlinks.splice(0, this.letrlinks.length);
        this.setTab('contact');
        this.selectedcontacts.clear();
        this.readmode = false;
    }
    setSelect(id, sel) {
        if (sel) {
            this.selectedcontacts.add(id);
        }
        else {
            this.selectedcontacts.delete(id);
        }
    }
    setShowAllViewers(show) {
        this.showallviewers = show;
    }
    selectAllViewers(id, sel) {
        if (sel) {
            this.selectedcontacts.clear();
            this.selectedcontacts.add(id);
        }
        else {
            this.selectedcontacts.delete(id);
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], SelectMemberDialogState.prototype, "members", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], SelectMemberDialogState.prototype, "letrlinks", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], SelectMemberDialogState.prototype, "activetab", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], SelectMemberDialogState.prototype, "selectedcontacts", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SelectMemberDialogState.prototype, "showallviewers", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SelectMemberDialogState.prototype, "allviewers", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "setTab", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "setLetrMembers", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "addLetrMembers", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "setLetrLinks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Boolean]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "setSelect", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "setShowAllViewers", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Boolean]),
    __metadata("design:returntype", void 0)
], SelectMemberDialogState.prototype, "selectAllViewers", null);
let SelectMemberDialog = class SelectMemberDialog extends React.Component {
    constructor(prop) {
        super(prop);
    }
    onCbSelected(id, sel) {
        if (id === ALLViewer && sel) {
            this.props.state.selectedcontacts.clear();
        }
        this.props.state.setSelect(id, sel);
    }
    dialog() {
        return React.createElement(Box, null,
            !this.props.state.readmode && this.props.state.showallviewers && React.createElement(Flex, { align: 'center' },
                " ",
                React.createElement(Icon, { w: '20px', h: '20px', color: AppColor.CheckBox, as: this.props.state.selectedcontacts.has(ALLViewer) ? MdCheckBox : MdCheckBoxOutlineBlank, ml: '1px', onClick: () => this.props.state.selectAllViewers(ALLViewer, !this.props.state.selectedcontacts.has(ALLViewer)) }),
                React.createElement(Text, { ml: '10px' }, "All Viewers")),
            React.createElement(Flex, { w: '100%' },
                React.createElement(Text, { h: '25px', fontWeight: 'bold', _hover: { cursor: 'pointer' }, minW: '72px', onClick: () => this.props.state.setTab("contact"), m: '10px', borderBottomColor: this.props.state.activetab === 'contact'
                        ? 'orange.500' : 'transparent', borderBottomWidth: '1px', variant: 'unstyled' }, "Members"),
                React.createElement(Text, { h: '25px', ml: '10px', fontWeight: 'bold', _hover: { cursor: 'pointer' }, minW: '50px', onClick: () => this.props.state.setTab("link"), m: '10px', borderBottomColor: this.props.state.activetab === 'link'
                        ? 'orange.500' : 'transparent', borderBottomWidth: '1px', variant: 'unstyled' }, "Links")),
            this.props.state.activetab === 'contact' && React.createElement(Box, null, this.props.state.members.map((mem) => React.createElement(Flex, { key: mem.id, w: '100%', align: 'center' },
                !this.props.state.readmode && !this.props.state.selectedcontacts.has(ALLViewer) && React.createElement(Icon, { w: '20px', h: '20px', color: AppColor.CheckBox, as: this.props.state.selectedcontacts.has(mem.id) ? MdCheckBox : MdCheckBoxOutlineBlank, ml: '1px', onClick: () => this.onCbSelected(mem.id, !this.props.state.selectedcontacts.has(mem.id)) }),
                React.createElement(Text, { ml: '10px' }, mem.name + (mem.orgname ? " (" + mem.orgname + ")" : ""))))),
            this.props.state.activetab === 'link' && React.createElement(Box, null, this.props.state.letrlinks.map((mem) => React.createElement(Flex, { key: mem.id, w: '100%', align: 'center' },
                !this.props.state.readmode && !this.props.state.selectedcontacts.has(ALLViewer) && React.createElement(Icon, { w: '20px', h: '20px', color: AppColor.CheckBox, as: this.props.state.selectedcontacts.has(mem.id) ? MdCheckBox : MdCheckBoxOutlineBlank, ml: '1px', onClick: () => this.onCbSelected(mem.id, !this.props.state.selectedcontacts.has(mem.id)) }),
                React.createElement(Text, { ml: '10px' }, mem.name)))));
    }
    render() {
        return (React.createElement(BaseDialog, { body: this.dialog(), state: this.props.state.basestate }));
    }
};
SelectMemberDialog = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], SelectMemberDialog);
export { SelectMemberDialog };
