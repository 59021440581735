var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
import { SchemaUIRow } from "./SchemaUiRow";
export class PwaEditStore {
    constructor() {
        this.show = false;
        this.activerowid = "";
        this.editrowproperty = new Map();
        this.showpasswordfor = new Set();
        this.removingrowsfromsh = new Set();
        this.editrowslistitems = [];
        this.deleteentries = [];
        this.changedlistitems = new Set();
        this.savingrow = false;
        this.rowindex = 0;
        this.selectedrows = new Set();
        this.selectallrows = false;
        this.showactions = false;
        this.ignorerebuildcustomcomponent = new Set();
        this.rebuildingcustom = false;
        this.forcerebuildcustom = new Set();
        this.activerow = new Map();
        this.showdetail = false;
        this.selectedid = "";
        makeObservable(this);
    }
    setIgnoreCustomComponentId(re) {
        this.ignorerebuildcustomcomponent.add(re);
    }
    removeIgnoreCustomComponentId(re) {
        this.ignorerebuildcustomcomponent.delete(re);
    }
    setSelectedRow(id) {
        this.selectedrows.add(id.rowid);
    }
    setUnSelectedRow(id) {
        this.selectedrows.delete(id);
        this.selectallrows = false;
    }
    setActiveRow(id, row) {
        this.activerow.clear();
        this.activerow.set(id, row);
    }
    clearActiveRow() {
        this.activerow.clear();
    }
    setSelectedId(id) {
        this.selectedid = id;
    }
    setActiveRowId(id) {
        this.showpasswordfor.clear();
        this.removingrowsfromsh.clear();
        this.editrowslistitems.splice(0, this.editrowslistitems.length);
        this.activerowid = id;
        this.editrowproperty.clear();
        this.setSavingRow(false);
        this.deleteentries.splice(0, this.deleteentries.length);
        this.changedlistitems.clear();
        this.selectedrows.clear();
        this.selectallrows = false;
        this.ignorerebuildcustomcomponent.clear();
        this.setRebuildingCustom(false);
    }
    setRowIndex(index) {
        if (index >= 0) {
            this.rowindex = index;
        }
    }
    setShow(show) {
        this.show = show;
    }
    setShowDetail(show) {
        this.showdetail = show;
    }
    reset() {
        this.setActiveRowId("");
        this.setShow(false);
    }
    addEntryForDeletion(en) {
        this.deleteentries.push(en);
    }
    addIdToRemovingCh(id) {
        this.removingrowsfromsh.add(id);
    }
    removeIdToRemovingCh(id) {
        this.removingrowsfromsh.delete(id);
    }
    setSavingRow(saving) {
        this.savingrow = saving;
    }
    setHidePasswordFor(pwd) {
        this.showpasswordfor.delete(pwd);
    }
    setShowPasswordFor(pwd) {
        this.showpasswordfor.add(pwd);
    }
    removePropertiesFromActiveRow(id) {
        this.editrowproperty.delete(id);
    }
    setRebuildingCustom(fetch) {
        this.rebuildingcustom = fetch;
    }
    setPropertiesForActiveRow(id, entry, ignorerebuilding = false, forceids) {
        this.editrowproperty.set(id, entry);
        console.log(this.editrowproperty);
        if (ignorerebuilding && !forceids)
            return;
        if (!ignorerebuilding) {
            this.setIgnoreCustomComponentId(id);
            this.setRebuildingCustom(true);
            setTimeout(() => {
                this.removeIgnoreCustomComponentId(id);
                this.setRebuildingCustom(false);
            }, 10);
        }
        else if (forceids) {
            for (const fc of forceids) {
                this.addRebuildId(fc);
            }
            setTimeout(() => {
                for (const fc of forceids) {
                    this.removeRebuildId(fc);
                }
            }, 50);
        }
    }
    addRebuildId(id) {
        this.forcerebuildcustom.add(id);
    }
    removeRebuildId(id) {
        this.forcerebuildcustom.delete(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "activerowid", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaEditStore.prototype, "editrowproperty", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaEditStore.prototype, "showpasswordfor", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaEditStore.prototype, "removingrowsfromsh", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaEditStore.prototype, "editrowslistitems", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaEditStore.prototype, "deleteentries", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "savingrow", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "rowindex", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaEditStore.prototype, "selectedrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "selectallrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "showactions", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaEditStore.prototype, "ignorerebuildcustomcomponent", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "rebuildingcustom", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaEditStore.prototype, "forcerebuildcustom", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaEditStore.prototype, "activerow", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "showdetail", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaEditStore.prototype, "selectedid", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setIgnoreCustomComponentId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "removeIgnoreCustomComponentId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setSelectedRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setUnSelectedRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, SchemaUIRow]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setActiveRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "clearActiveRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setSelectedId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setActiveRowId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setRowIndex", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setShowDetail", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "addEntryForDeletion", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "addIdToRemovingCh", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "removeIdToRemovingCh", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setSavingRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setHidePasswordFor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setShowPasswordFor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "removePropertiesFromActiveRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setRebuildingCustom", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object, Boolean, Array]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "setPropertiesForActiveRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "addRebuildId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaEditStore.prototype, "removeRebuildId", null);
