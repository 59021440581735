import { AppConfig } from '@/config/AppConfig';
class NotifyStore {
    async checkAndAskForNotificationPerm() {
        if (!window.Notification) {
            return false;
        }
        if (window.Notification.permission !== 'granted') {
            await window.Notification.requestPermission();
        }
        return window.Notification.permission === 'granted';
    }
    async sendNotification(msg) {
        if (await this.checkAndAskForNotificationPerm()) {
            const not = new window.Notification(AppConfig.appName, {
                body: msg,
                silent: true,
                icon: AppConfig.iconpath
            });
        }
    }
}
export const NStore = new NotifyStore();
