var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class FilterDialogState {
    constructor() {
        this.valuesbyid = new Map();
        this.searchstr = "";
        this.idlist = "_allnr";
        this.inputref = null;
        this.scanneractive = false;
        makeObservable(this);
    }
    addValue(id, value) {
        this.valuesbyid.set(id, value);
    }
    deleteValue(id) {
        this.valuesbyid.delete(id);
    }
    setSearchString(search) {
        this.searchstr = search;
    }
    setSearchIdList(list) {
        this.idlist = list;
    }
    setScannerActive(show) {
        this.scanneractive = show;
    }
    clear() {
        this.setSearchIdList("_allnr");
        this.setSearchString("");
        this.valuesbyid.clear();
        this.setScannerActive(false);
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], FilterDialogState.prototype, "valuesbyid", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], FilterDialogState.prototype, "searchstr", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], FilterDialogState.prototype, "idlist", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], FilterDialogState.prototype, "scanneractive", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], FilterDialogState.prototype, "addValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FilterDialogState.prototype, "deleteValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FilterDialogState.prototype, "setSearchString", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FilterDialogState.prototype, "setSearchIdList", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FilterDialogState.prototype, "setScannerActive", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FilterDialogState.prototype, "clear", null);
