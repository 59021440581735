var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class FooterResultExecStore {
    constructor() {
        this.show = false;
        this.zindex = -1;
        this.top = 0;
        this.left = 0;
        this.rules = [];
        this.execing = new Set();
        this.colres = new Map();
        this.dragging = false;
        makeObservable(this);
    }
    setExecId(id) {
        this.execing.add(id);
    }
    clearExecId(id) {
        this.execing.delete(id);
    }
    setZIndex(index) {
        this.zindex = index;
    }
    setDragging(drag) {
        this.dragging = drag;
    }
    setTop(top) {
        this.top = top;
    }
    setLeft(left) {
        this.left = left;
    }
    setShow(show) {
        this.show = show;
    }
    setRules(rules) {
        this.rules.push(...rules);
    }
    clearRules() {
        this.rules.splice(0, this.rules.length);
    }
    reset() {
        this.clearRules();
        this.execing.clear();
        this.colres.clear();
    }
    addResult(index, res) {
        this.colres.set(index, res);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], FooterResultExecStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], FooterResultExecStore.prototype, "zindex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], FooterResultExecStore.prototype, "top", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], FooterResultExecStore.prototype, "left", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], FooterResultExecStore.prototype, "rules", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], FooterResultExecStore.prototype, "execing", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], FooterResultExecStore.prototype, "colres", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], FooterResultExecStore.prototype, "dragging", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setExecId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "clearExecId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setZIndex", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setDragging", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setTop", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setLeft", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "setRules", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "clearRules", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], FooterResultExecStore.prototype, "addResult", null);
