import { comm } from "@/Comm/comm";
import { AuthHeaders as AuthHeader } from "@@/AuthHeader";
import { CommonCrypto } from "@@/Cryptography";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
class SecurityStore {
    async generatePasswordBox(mainPassword) {
        const boxc = await CommonCrypto.generateNewPasswordBox(mainPassword);
        return boxc === null || boxc === void 0 ? void 0 : boxc.opbox;
    }
    async generatePasswordBoxFor(password, mainPassword) {
        const boxc = await CommonCrypto.generatePasswordBoxFor(password, mainPassword);
        if (boxc === undefined) {
            return undefined;
        }
        boxc.opbox.ebox = boxc.box;
        return boxc === null || boxc === void 0 ? void 0 : boxc.opbox;
    }
    async generateAndStoreNewPasswordBox(mainpassowrd, commkey) {
        const boxc = await CommonCrypto.generateNewPasswordBox(mainpassowrd);
        if (boxc === undefined) {
            return undefined;
        }
        const ebox = LetrwingCrypto.box(JSON.stringify(boxc.box), commkey);
        const pid = await comm.post(ServerPath.AddPasswordBank, ebox);
        if (pid === undefined) {
            return pid;
        }
        boxc.opbox.id = pid.valueOf();
        boxc.opbox.ebox = boxc.box;
        return boxc.opbox;
    }
    async storeNShareBox(box) {
        const pid = await comm.post(ServerPath.AddShareBoxes, { data: box });
        return pid;
    }
    async storePassword(password, mainpassowrd, commkey) {
        const boxc = await CommonCrypto.generatePasswordBoxFor(password, mainpassowrd);
        if (boxc === undefined) {
            return undefined;
        }
        const ebox = LetrwingCrypto.box(JSON.stringify(boxc.box), commkey);
        const pid = await comm.post(ServerPath.AddPasswordBank, ebox);
        if (pid === undefined) {
            return pid;
        }
        boxc.opbox.id = pid.valueOf();
        boxc.opbox.ebox = boxc.box;
        return boxc.opbox;
    }
    async decryptPasswordBox(box, mainpassword) {
        return await CommonCrypto.decryptPasswordBox(mainpassword, box);
    }
    getSecurityCommHeader() {
        const kp = LetrwingCrypto.getNewKeypair();
        return { kp: kp, header: { key: AuthHeader.RESPONSEKEY, value: kp.publickey } };
    }
    async storeShareBox(box) {
        const pid = await comm.post(ServerPath.AddShareBox, box);
        return pid;
    }
}
export const SecStore = new SecurityStore();
