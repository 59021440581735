export const PendingInvitesType = "pendinginvitestype";
export const NewInvitesType = "newinvitestype";
export class NewInvite {
    constructor() {
        this.name = "";
        this.email = "";
        this.isInternal = false;
    }
}
export class NewInviteAcceptDecline {
    constructor() {
        this.id = "";
        this.accept = false;
        this.isPublic = false;
        this.email = "";
    }
}
