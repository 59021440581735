export var AuthHeaders;
(function (AuthHeaders) {
    AuthHeaders["CLIENTID"] = "X-Clientid";
    AuthHeaders["CLIENTAUTH"] = "X-Client-Auth";
    AuthHeaders["DEVICESESSION"] = "X-Device-Session";
    AuthHeaders["RESPONSEKEY"] = "X-PK";
    AuthHeaders["AuthHeader"] = "X-Auth";
    AuthHeaders["FileID"] = "X-FILE-ID";
    AuthHeaders["FILEACCESSCODE"] = "X-FILE-ACCESS";
    AuthHeaders["OrigFileID"] = "X-ORIG-File-ID";
    AuthHeaders["OrigFILEACCESSCODE"] = "X-ORIG-FILE-ACCESS";
    AuthHeaders["TenantID"] = "X-Tenant-ID";
    AuthHeaders["ClientType"] = "X-Client-Type";
    AuthHeaders["OrgSession"] = "X-ORG-SESSION";
    AuthHeaders["OrgTenantIDValue"] = "org";
    AuthHeaders["TenantIDGET"] = "tenantid";
    AuthHeaders["DeviceMatrixAuth"] = "X-DEVICE-MATRIX-AUTH";
    AuthHeaders["QRCODESession"] = "X-QRCODE-SESSION";
    AuthHeaders["QRCODEAuthCode"] = "X-QRCODE-AuthCode";
    AuthHeaders["QRCODEDeviceId"] = "X-QRCODE-DeviceId";
    AuthHeaders["VerifyPassword"] = "X-Verify-Password";
    AuthHeaders["WebMailSession"] = "X-Web-Mail-Session";
    AuthHeaders["ReqUserID"] = "X-REQUSER-ID";
    AuthHeaders["ID"] = "id";
})(AuthHeaders || (AuthHeaders = {}));
