export var FileType;
(function (FileType) {
    FileType["PDF"] = "pdf";
    FileType["DOCX"] = "docx";
    FileType["ODT"] = "ODT";
    FileType["DOC"] = "doc";
    FileType["PPT"] = "ppt";
    FileType["PPTX"] = "pptx";
    FileType["RTF"] = "rtf";
    FileType["ODP"] = "odp";
    FileType["TXT"] = "txt";
})(FileType || (FileType = {}));
