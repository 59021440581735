import React from 'react';
import ReactDOM from 'react-dom';
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import sha256 from 'crypto-js/sha256';
import * as Base64Con from 'crypto-js/enc-base64';
import './app/css/App.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import "react-datepicker/dist/react-datepicker.css";
import { Pwa } from './app/pwa';
LetrwingCrypto.set256HashGen((str) => {
    return new Promise((resolve, reject) => {
        const data = sha256(str);
        resolve(Base64Con.stringify(data));
    });
});
async function registerBGSync() {
    const reg = await navigator.serviceWorker.ready;
    try {
        if (reg.sync) {
            const tags = await reg.sync.getTags();
            if (tags.includes("sync-messages")) {
                console.log("Messages sync already requested");
            }
            else {
                await reg.sync.register("sync-messages");
            }
        }
    }
    catch (ex) {
        console.log(ex);
        console.log("Background Sync could not be registered!");
    }
}
let ispwa = false;
window.addEventListener('load', async () => {
    if ('serviceWorker' in navigator) {
        const regs = await navigator.serviceWorker.getRegistrations();
        let hasworker = false;
        if (regs && regs.length > 0) {
            await registerBGSync();
        }
        else {
            navigator.serviceWorker.register('service-worker.bundle.js').then(async (registration) => {
                console.log(registration);
                await registerBGSync();
            }).catch(registrationError => {
                console.log('SW registration failed: ', registrationError);
            });
        }
    }
});
window.addEventListener('DOMContentLoaded', () => {
    let displayMode = 'browser tab';
    if (window.matchMedia('(display-mode: standalone)').matches) {
        ispwa = true;
        window.history.pushState({ noBackExitsApp: true }, '');
    }
});
// stop app from closing down by back button action ?
window.addEventListener('popstate', function (event) {
    if (ispwa && event.state && event.state.noBackExitsApp) {
        window.history.pushState({ noBackExitsApp: true }, '');
    }
});
ReactDOM.render(React.createElement(Pwa, null), document.getElementById('root'));
