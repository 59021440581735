import { deleteDB, openDB } from "idb";
export var JobStatus;
(function (JobStatus) {
    JobStatus[JobStatus["Done"] = 0] = "Done";
    JobStatus[JobStatus["Progress"] = 1] = "Progress";
    JobStatus[JobStatus["NetworkStall"] = 2] = "NetworkStall";
    JobStatus[JobStatus["Error"] = 3] = "Error";
    JobStatus[JobStatus["Pending"] = 4] = "Pending";
})(JobStatus || (JobStatus = {}));
export var JobType;
(function (JobType) {
    JobType[JobType["WebForm"] = 0] = "WebForm";
    JobType[JobType["EditRow"] = 1] = "EditRow";
    JobType[JobType["DeleteRows"] = 2] = "DeleteRows";
})(JobType || (JobType = {}));
export var PendDatatype;
(function (PendDatatype) {
    PendDatatype["Form"] = "form";
})(PendDatatype || (PendDatatype = {}));
class DB {
    constructor() {
    }
    async init() {
        const db = await openDB("LetrWingDB", 6, {
            upgrade: async (mydb) => {
                try {
                    if (!mydb.objectStoreNames.contains("userdetails")) {
                        mydb.createObjectStore("userdetails", { autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("pendingdata")) {
                        mydb.createObjectStore("pendingdata", { keyPath: 'id', autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("organisationdetails")) {
                        mydb.createObjectStore("organisationdetails", { keyPath: 'id', autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("datajob")) {
                        mydb.createObjectStore("datajob", { keyPath: 'jobid', autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("appversion")) {
                        mydb.createObjectStore("appversion", { autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("schemarowdata")) {
                        mydb.createObjectStore("schemarowdata", { keyPath: "id", autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("pdfcontent")) {
                        mydb.createObjectStore("pdfcontent", { keyPath: "id", autoIncrement: true });
                    }
                    if (!mydb.objectStoreNames.contains("schemareportdata")) {
                        mydb.createObjectStore("schemareportdata", { keyPath: "id", autoIncrement: true });
                    }
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            blocked: (cv, bv) => {
                console.log("blocked for " + cv + " " + bv);
            }
        });
        // these twi ibjectsore should need to be there
        if (!db.objectStoreNames.contains("userdetails") &&
            !db.objectStoreNames.contains("pendingdata") &&
            !db.objectStoreNames.contains("organisationdetails") && !db.objectStoreNames.contains("datajob")) {
            return false;
        }
        this.mydb = db;
        return true;
    }
    async addPdfPage(page) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        await this.mydb.put("pdfcontent", page);
    }
    async getPdfPage(id) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        return await this.mydb.get("pdfcontent", id);
    }
    async addSchemaRows(data) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        await this.mydb.put("schemarowdata", data);
    }
    async addSchemaReport(data) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        await this.mydb.put("schemareportdata", data);
    }
    async getSchemaReport(id) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        return await this.mydb.get("schemareportdata", id);
    }
    async getSchemaRows(id) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        return await this.mydb.get("schemarowdata", id);
    }
    async updateAppVersion(version) {
        if (!this.mydb) {
            return undefined;
        }
        ;
        const rt = await this.mydb.put("appversion", version, "appversion");
        return rt;
    }
    async getAppVersion() {
        if (!this.mydb) {
            return undefined;
        }
        ;
        return await this.mydb.get("appversion", "appversion");
    }
    async addDataJob(job) {
        if (!this.mydb) {
            return false;
        }
        ;
        const rt = await this.mydb.put("datajob", job);
        return true;
    }
    async getAllDataJob() {
        if (!this.mydb) {
            return [];
        }
        return await this.mydb.getAll("datajob");
    }
    async removeDataJob(id) {
        if (!this.mydb)
            return [];
        await this.mydb.delete("datajob", id);
    }
    async getDataJob(id) {
        if (!this.mydb)
            return undefined;
        return await this.mydb.get("datajob", id);
    }
    async addUserSession(sess) {
        if (!this.mydb)
            return false;
        const rt = await this.mydb.put("userdetails", sess, "usersession");
        if (rt === "usersession")
            return true;
        return false;
    }
    async removeOrg(id) {
        if (!this.mydb)
            return false;
        await this.mydb.delete("organisationdetails", id);
    }
    async addOrganisation(org) {
        if (!this.mydb)
            return false;
        const rt = await this.mydb.put("organisationdetails", org);
        if (rt === org.id)
            return true;
        return false;
    }
    async getAllOrganisations() {
        var _a;
        if (!this.mydb)
            return [];
        const orgs = await ((_a = this.mydb) === null || _a === void 0 ? void 0 : _a.getAll("organisationdetails"));
        return orgs !== null && orgs !== void 0 ? orgs : [];
    }
    async deleteDB() {
        await deleteDB("LetrWingDB");
    }
    async wipeDb() {
        if (this.mydb) {
            await this.mydb.delete("userdetails", "usersession");
            const keys = await this.mydb.getAllKeys("organisationdetails");
            for (const k of keys) {
                await this.mydb.delete("organisationdetails", k);
            }
            const pkeys = await this.mydb.getAllKeys("pendingdata");
            for (const k of pkeys) {
                await this.mydb.delete("pendingdata", k);
            }
        }
    }
    setupReady() {
        return this.mydb !== undefined && this.mydb.objectStoreNames.contains("userdetails");
    }
    async getUserSession() {
        if (!this.mydb)
            return undefined;
        return await this.mydb.get("userdetails", "usersession");
    }
    async getOrgBox(id) {
        if (!this.mydb)
            return undefined;
        return await this.mydb.get("organisationdetails", id);
    }
}
export const PWaDb = new DB();
