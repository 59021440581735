import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ProjectObjectType } from "@@/Project";
import { InMemoryRepo } from "./InMemoryDb";
class Util {
    async convertSchemaPrintTemplateToBox(form, letrid, box) {
        try {
            const formbox = LetrwingCrypto.secretBox(LetrwingCrypto.getBase64FromUT8(JSON.stringify(form)), box);
            const namedata = {
                id: form.id,
                name: form.name,
                pagewidth: form.pagewidth,
                pageheight: form.pageheight,
                pageunits: form.pageunits,
                schemaid: form.schemaid,
                letrid: form.letrid,
                paperorientation: form.paperorientation,
                papersize: form.papersize,
            };
            const namebox = LetrwingCrypto.secretBox(LetrwingCrypto.getBase64FromUT8(JSON.stringify(namedata)), box);
            const ret = {
                id: form.id,
                letrid: letrid,
                schemaid: form.schemaid,
                data: formbox,
                namebox: namebox
            };
            return ret;
        }
        catch (Ex) { }
        return undefined;
    }
    async convertSchemaWebFormToBox(form, letrid, box) {
        var _a, _b, _c, _d, _e;
        try {
            const key = form.code;
            form.letrkey = box;
            const enkey = await LetrwingCrypto.getHash256(key);
            const formbox = LetrwingCrypto.secretBox(LetrwingCrypto.getBase64FromUT8(JSON.stringify(form)), enkey);
            const codebox = LetrwingCrypto.secretBox(enkey, box);
            const ret = {
                id: form.id,
                letrid: letrid,
                schemaid: form.schemaid,
                creatorid: form.creatorid,
                lastmodifiedby: form.lastmodifiedby,
                lastmodifytime: form.lastmodifytime,
                databox: formbox,
                codebox: codebox,
                host: form.referer,
                history: (_a = form.history) !== null && _a !== void 0 ? _a : false,
                appformbox: ((_b = form.webformusers) !== null && _b !== void 0 ? _b : []),
                appusers: ((_c = form.webformusers) !== null && _c !== void 0 ? _c : []).map((form) => form.id),
                schemareports: (_d = form.schemareports) !== null && _d !== void 0 ? _d : [],
                remoteitems: (_e = form.remoteitems) !== null && _e !== void 0 ? _e : []
            };
            return ret;
        }
        catch (Ex) { }
        return undefined;
    }
    async convertSchemaPrintServerToForm(box, lbox) {
        try {
            const openbox = LetrwingCrypto.decryptSecretBox(box.data, lbox);
            // now we have everything 
            if (!openbox)
                return undefined;
            const sform = JSON.parse(LetrwingCrypto.fromBase64ToUTF8(openbox));
            sform.id = box.id,
                sform.letrid = box.letrid;
            return sform;
        }
        catch (ex) { }
        return undefined;
    }
    async convertSchemaWebboxToForm(box, lbox) {
        var _a, _b, _c;
        try {
            const code = LetrwingCrypto.decryptSecretBox(box.codebox, lbox);
            if (!code)
                return undefined;
            const openbox = LetrwingCrypto.decryptSecretBox(box.databox, code);
            // now we have everything 
            if (!openbox)
                return undefined;
            const sform = JSON.parse(LetrwingCrypto.fromBase64ToUTF8(openbox));
            sform.id = box.id,
                sform.letrid = box.letrid;
            sform.lastmodifiedby = box.lastmodifiedby;
            sform.lastmodifytime = box.lastmodifytime;
            sform.creatorid = box.creatorid;
            sform.webformusers = box.appformbox;
            sform.formusers = box.formusers;
            sform.schemareports = (_a = box.schemareports) !== null && _a !== void 0 ? _a : [];
            sform.history = (_b = box.history) !== null && _b !== void 0 ? _b : false;
            sform.remoteitems = (_c = box.remoteitems) !== null && _c !== void 0 ? _c : [];
            return sform;
        }
        catch (ex) { }
        return undefined;
    }
    convertBoxToSchema(schema, letrid, box) {
        const datastr = LetrwingCrypto.decryptSecretBox(schema.schemadata, box);
        if (!datastr) {
            return undefined;
        }
        let rules = undefined;
        if (schema.rulebox) {
            const rstr = LetrwingCrypto.decryptSecretBox(schema.rulebox, box);
            if (rstr && (rstr === null || rstr === void 0 ? void 0 : rstr.trim())) {
                try {
                    const rbb = JSON.parse(rstr);
                    rules = rbb;
                }
                catch (ex) { }
            }
        }
        let drules = undefined;
        if (schema.datarulebox) {
            const rstr = LetrwingCrypto.decryptSecretBox(schema.datarulebox, box);
            if (rstr && (rstr === null || rstr === void 0 ? void 0 : rstr.trim())) {
                try {
                    const rbb = JSON.parse(rstr);
                    drules = rbb;
                }
                catch (ex) { }
            }
        }
        let frules = undefined;
        if (schema.footerbox) {
            const fstr = LetrwingCrypto.decryptSecretBox(schema.footerbox, box);
            if (fstr && fstr.trim()) {
                try {
                    const rbb = JSON.parse(fstr);
                    frules = rbb;
                }
                catch (ex) {
                }
            }
        }
        try {
            const dataobj = JSON.parse(datastr);
            dataobj.letrid = letrid;
            dataobj.id = schema.id;
            dataobj.authorid = schema.authorid,
                dataobj.lastupdatetime = schema.lastupdatetime;
            dataobj.updatedby = schema.updatedby;
            dataobj.metaschemaids = schema.metaschemaids;
            dataobj.childrensids = schema.childrenschemaids;
            dataobj.rules = rules;
            dataobj.footers = frules;
            dataobj.schemametadata = schema.schemametadata;
            dataobj.viewdepentschemas = schema.viewdepentschemas;
            dataobj.barcodecolumnid = schema.barcodecolumnid;
            dataobj.schemaintegrationbox = schema.schemaintegrationbox;
            dataobj.schemapublickey = schema.schemapublickey;
            dataobj.datarules = drules;
            return dataobj;
        }
        catch (ex) {
        }
        return undefined;
    }
    convertProjecObjectToProjectEntity(objs, accesskey, letrid, attachmetada = true) {
        var _a, _b;
        const ret = [];
        // lets try this work now
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        for (const re of objs) {
            const pbox = re.data;
            let fld = [];
            if (attachmetada && sbox && re.metadata) {
                for (const mfld of re.metadata) {
                    try {
                        mfld.data.nonce = sbox.nonce;
                        const str = LetrwingCrypto.decryptSecretBox(mfld.data, sbox.key);
                        if (mfld.objs && mfld.objs.length > 0) {
                            mfld.objsdec = this.convertProjecObjectToProjectEntity(mfld.objs, accesskey, letrid, false);
                        }
                        if (mfld.schms && mfld.schms.length > 0) {
                            if (mfld.schms) {
                                const shs = [];
                                for (const sh of mfld.schms) {
                                    const sok = this.convertBoxToSchema(sh, letrid, accesskey);
                                    if (sok)
                                        shs.push(sok);
                                }
                                mfld.schsdec = shs;
                            }
                        }
                        fld.push(Object.assign(Object.assign({}, mfld), { datastr: str }));
                    }
                    catch (ex) { }
                }
            }
            const pboxopen = LetrwingCrypto.decryptSecretBox(pbox, accesskey);
            if (pboxopen !== undefined) {
                // now if the object is type document then it has fileinfo 
                // optherwise it;s just the name ?
                if (re.type === ProjectObjectType.Document) {
                    try {
                        const fileino = JSON.parse(pboxopen);
                        const dentity = {
                            id: re.id,
                            name: fileino.name,
                            accesscode: fileino.accesscode,
                            accesskey: fileino.accesskey,
                            versions: fileino.versions,
                            documentreference: fileino.accessid,
                            size: fileino.size,
                            type: ProjectObjectType.Document,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            accessids: re.accessids,
                            origname: (_a = fileino.origname) !== null && _a !== void 0 ? _a : fileino.name,
                            metadata: fld,
                            thumbdetail: re.thumbdetails
                        };
                        ret.push(dentity);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Folder) {
                    try {
                        const fde = JSON.parse(pboxopen);
                        const fen = {
                            name: fde.name,
                            color: (_b = fde.color) !== null && _b !== void 0 ? _b : 'orange',
                            id: re.id,
                            childrencount: re.childrencount,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            type: ProjectObjectType.Folder,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(fen);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Bookmark) {
                    try {
                        const bdetail = JSON.parse(pboxopen);
                        const ben = {
                            name: bdetail.name,
                            url: bdetail.url,
                            id: re.id,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            type: ProjectObjectType.Bookmark,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(ben);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Draft) {
                    try {
                        const bdetail = JSON.parse(pboxopen);
                        const ben = {
                            name: bdetail.name,
                            type: ProjectObjectType.Draft,
                            id: re.id,
                            updatetime: re.updatetime,
                            accesskey: bdetail.accesskey,
                            draftreference: bdetail.reference,
                            drafttype: bdetail.type,
                            draftsize: 0,
                            parentid: re.parentid,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(ben);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        return ret;
    }
}
export const CONVERTUTIL = new Util();
