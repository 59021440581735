import { DeviceMetaData } from "./DeviceMetadata";
export class UserDevice {
    constructor() {
        this.id = "";
        this.metadat = new DeviceMetaData();
        this.numberofsession = 0;
        this.lastloggedintime = 0;
    }
}
export class EncryptedUserDevice {
    constructor() {
        this.id = "";
        this.extrainfo = "";
        this.numberofsession = 0;
    }
}
export class DeleteDeviceRequest {
    constructor() {
        this.deviceid = "";
    }
}
export class DeviceLoginServerResponse {
    constructor() {
        this.sesisonid = "";
        this.sessionpublickey = "";
        this.fullname = "";
        this.userid = "";
        this.email = "";
    }
}
export var DeviceLoginResponse;
(function (DeviceLoginResponse) {
    DeviceLoginResponse["Success"] = "Success";
    DeviceLoginResponse["InValidDevice"] = "InValidDevice";
    DeviceLoginResponse["Error"] = "Error";
})(DeviceLoginResponse || (DeviceLoginResponse = {}));
