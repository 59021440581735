import nacl from 'tweetnacl';
import { encodeBase64, decodeBase64, encodeUTF8, decodeUTF8 } from 'tweetnacl-util';
import { LetrwingCrypto } from '@@/LetrwingCommonCrypto';
export class KeyPair {
    constructor() {
        this.publicKey = "";
        this.secretKey = "";
    }
}
export class Security {
    constructor() {
        this.storagekey = encodeBase64(nacl.hash(decodeUTF8('storage')));
        this.istoragekey = encodeBase64(nacl.hash(decodeUTF8('istoragebox')));
        this.masterkey = encodeBase64(nacl.hash(decodeUTF8('masterkey')));
        this.matrix = [];
    }
    updateMatrix(matrix) {
        this.matrix = matrix;
    }
    getBoxKey() {
        return LetrwingCrypto.getNewKeypair();
    }
    getKeypair() {
        const kp = nacl.box.keyPair();
        const ret = new KeyPair();
        ret.publicKey = encodeBase64(kp.publicKey);
        ret.secretKey = encodeBase64(kp.secretKey);
        return ret;
    }
    encryptSecretBox(msg, key) {
        const nonce = nacl.randomBytes(24);
        const secret = nacl.secretbox(decodeUTF8(msg), nonce, decodeBase64(key));
        return {
            encryptmsg: encodeBase64(secret),
            nonce: encodeBase64(nonce),
            publickey: ""
        };
    }
    encryptBoxMessage(msg, publickey) {
        const nonce = nacl.randomBytes(24);
        const keyPair = this.getKeypair();
        return this.encryptBoxMessageWithKey(msg, publickey, keyPair, nonce);
    }
    // all input message are utf8..
    encryptBoxMessageWithKey(msg, publickey, keyPair, nonce) {
        const emsg = encodeBase64(nacl.box(decodeUTF8(msg), nonce, decodeBase64(publickey), decodeBase64(keyPair.secretKey)));
        return {
            encryptmsg: emsg,
            nonce: encodeBase64(nonce),
            publickey: keyPair.publicKey
        };
    }
    decryptBoxMessageWithprivateKey(msg, publicKey, nonce, secretKey) {
        const dmsg = nacl.box.open(decodeBase64(msg), decodeBase64(nonce), decodeBase64(publicKey), decodeBase64(secretKey));
        if (dmsg) {
            const ret = encodeUTF8(dmsg);
            return ret;
        }
        return undefined;
    }
    decryptBoxMessage(msg, publicKey, nonce, myKeyP) {
        return this.decryptBoxMessageWithprivateKey(msg, publicKey, nonce, myKeyP.secretKey);
    }
    async generateKeyFromStr(str) {
        if (str.length < 12) {
            return undefined;
        }
        const firstPin = str.substring(0, 6);
        const fkey = await this.generateHash(firstPin);
        const skey = await this.generateHash(fkey + str.substring(6, 12));
        const finalkey = await this.generateHash(skey + str);
        return finalkey;
    }
    async generateHash(str) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(str);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        return encodeBase64(new Uint8Array(hashBuffer));
    }
    async generate512Hash(str) {
        return encodeBase64(nacl.hash(decodeUTF8(str)));
    }
    decryptPasswordBox(msg, nonce, key) {
        const pstrbox = this.decryptSecretbox(msg, nonce, key, 'utf8');
        if (pstrbox === undefined) {
            return undefined;
        }
        return JSON.parse(pstrbox);
    }
    decryptSecretBoxAndGetRawBytes(msg, nonce, key) {
        const dmsg = nacl.secretbox.open(decodeBase64(msg), decodeBase64(nonce), decodeBase64(key));
        return dmsg;
    }
    decryptSecretbox(msg, nonce, key, encodeMode = 'base64') {
        const dmsg = this.decryptSecretBoxAndGetRawBytes(msg, nonce, key);
        if (dmsg) {
            if (encodeMode === 'utf8') {
                return encodeUTF8(dmsg);
            }
            return encodeBase64(dmsg);
        }
        return undefined;
    }
    sha512UTF8(str) {
        return encodeBase64(nacl.hash(decodeUTF8(str)));
    }
    sha512(str) {
        return encodeBase64(nacl.hash(decodeBase64(str)));
    }
    passwordGenerator() {
        return encodeBase64(nacl.randomBytes(32));
    }
    convertJSObjToUnit8Array(obj) {
        if (obj instanceof Object) {
            const oobj = obj;
            const values = Object.values(oobj);
            const ret = new Uint8Array(values.length);
            let index = 0;
            values.forEach(val => {
                ret.set([parseInt(val)], index);
                index++;
            });
            return ret;
        }
        return undefined;
    }
    localEncodeUTF8(bytes) {
        return encodeUTF8(bytes);
    }
    localEncodeBase64(bytes) {
        return encodeBase64(bytes);
    }
    localDecodeUTF8(str) {
        return decodeUTF8(str);
    }
    localDecodeBase64(data) {
        return decodeBase64(data);
    }
    async produceKeyFromArray(array) {
        let randomstring = "";
        array.forEach(val => {
            randomstring += this.getValueFromLookupTable(val);
        });
        const size = randomstring.length / 2;
        const firsthalf = randomstring.substring(0, size);
        const secondhalf = randomstring.substring(size);
        const firsthash = decodeBase64(await this.generateHash(firsthalf));
        const secondhash = decodeBase64(await this.generateHash(secondhalf));
        // now we just need to get unit8 of both and we done!
        const combineArray = new Uint8Array(firsthash.length + secondhash.length);
        combineArray.set(firsthash);
        combineArray.set(secondhash, firsthash.length);
        const key = await this.generateHash(encodeBase64(combineArray));
        return key;
    }
    getValueFromLookupTable(num) {
        num = num % this.matrix.length;
        return this.matrix[num];
    }
}
export const AdminSecurity = new Security();
