var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Flex, FormControl, FormLabel, Icon, Link, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { ChoiceType, SchemaItemType } from "@@/Schema";
import { StoreContext } from "../uistore/PwaRootStore";
import React from "react";
import { AppColor } from "../const/AppDefaults";
import { ProjectObjectType } from "@@/Project";
import { DateLib } from "@@/Datelib";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { SchemaCustomPanel } from "./PwaRowsView";
import { Quill } from 'react-quill';
import { RemotePopupViewStore } from "../uistore/RemotePopupViewStore";
import { Observer, observer } from "mobx-react";
import { testIsMobile } from "@@/Util";
import { PwaCustomDisplayItemUI } from "../UIComponents/PwaCustomDisplayItem";
import { FcDocument } from "@react-icons/all-files/fc/FcDocument";
import { FcDataSheet } from "@react-icons/all-files/fc/FcDataSheet";
const ReactQuill = React.lazy(async () => await import("react-quill"));
import { toast } from "react-toastify";
import { Clipboard } from '@/util/Util';
import { PwaPdfPageStore } from "../UIComponents/PwaPdfPageStore";
import { getDisplayForID, sortEntriesByRow } from "@@/RuleParser";
import { ThumbNailStore } from "../uistore/ThumbNailStore";
import { TiTick } from "@react-icons/all-files/ti/TiTick";
import { FaClipboard } from "@react-icons/all-files/fa/FaClipboard";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FaListAlt } from "@react-icons/all-files/fa/FaListAlt";
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);
const isMobile = () => window.innerWidth < 650;
const viewheight = () => window.innerHeight - 330;
let PwaDetail = class PwaDetail extends React.Component {
    componentDidMount() {
        var _a;
        const rstore = this.context;
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get((_a = this.props.state.selectedid) !== null && _a !== void 0 ? _a : '');
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        if (perms.has("viewrows") && !rstore.getPwaStore().customrowjs.trim()) {
            rstore.getPwaStore().setActiveTab('detail');
        }
        if (!perms.has("viewrows") && rstore.getPwaStore().customrowjs.trim()) {
            rstore.getPwaStore().setActiveTab('custom');
        }
    }
    renderRow() {
        var _a;
        const rstore = this.context;
        const row = rstore.getPwaStore().rowsMap.get(this.props.state.selectedid);
        if (!row) {
            return React.createElement(Box, null);
        }
        const chscmeacell = new Map();
        const achema = rstore.getPwaStore().getActiveSchema();
        if (achema === undefined) {
            return React.createElement(Box, null);
        }
        const flds = rstore.getPwaStore().currentitems.slice().
            sort((it1, it2) => it1.orderindex - it2.orderindex).filter((fld) => !rstore.getPwaStore().hiddencolumns.has(fld.id));
        const emap = new Map();
        const sh = rstore.getPwaStore().getActiveSchema();
        if (sh === undefined) {
            return React.createElement(Box, null);
        }
        for (const en of row.entry) {
            if (!emap.has(en.cellid)) {
                emap.set(en.cellid, []);
            }
            if (!en.decrypted && sh) {
                if (en.ruleopen) {
                    rstore.getPwaStore().parseCellRules(en, sh);
                }
                else {
                    rstore.getPwaStore().decryptCell(en, sh);
                }
            }
            if (en.decrypted) {
                (_a = emap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
        }
        let activerowui = rstore.getPwaStore().uirows.get(rstore.getPwaStore().pwaeditstore.selectedid);
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get(row.rowid);
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        return React.createElement(Box, { key: row.rowid, paddingLeft: '10px', paddingRight: '20px' }, flds.map((item) => {
            var _a, _b, _c;
            const ents = emap.get(item.id);
            let defaultvalue;
            let dnum = 0;
            let myid = "";
            let urld = undefined;
            let obids = [];
            let vids = new Set();
            let ritemns = [];
            let remoteitemsmap = new Map();
            if (item.type === SchemaItemType.Remote) {
                const ri = item;
                const items = this.props.deps.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                ritemns = items !== null && items !== void 0 ? items : [];
                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                    const rtextra = ri.rtextra;
                    for (const ext of rtextra) {
                        const items = this.props.deps.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                    }
                }
            }
            if (!ents) {
            }
            else {
                const ent = ents[0];
                myid = ent.id;
                if (!ent.decrypted) {
                    rstore.getPwaStore().decryptCell(ent, achema);
                }
                defaultvalue = ent.celldata;
                if (defaultvalue && item.type === SchemaItemType.Date) {
                    dnum = parseInt(defaultvalue);
                }
                if (defaultvalue && item.type === SchemaItemType.URL) {
                    try {
                        urld = JSON.parse(defaultvalue);
                    }
                    catch (ex) { }
                }
                if (ent.linkedids && ent.linkedids.length > 0 && item.type === SchemaItemType.Objects) {
                    const pmap = new Map();
                    (_a = ent.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((ob) => pmap.set("objs-" + ob.id, ob));
                    const smap = new Map();
                    (_b = ent.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sj) => smap.set(sj.id, sj));
                    for (const l of ent.linkedids) {
                        if (l.startsWith("objs-")) {
                            const obj = pmap.get(l);
                            if (obj) {
                                obids.push({
                                    id: l,
                                    type: 'document',
                                    obj: obj
                                });
                                vids.add(l);
                            }
                        }
                        else if (l.startsWith("dch-")) {
                            const parts = l.split("-");
                            if (parts.length > 2) {
                                const did = parts[1];
                                const sid = parts[2];
                                const sh = smap.get(sid);
                                if (sh) {
                                    obids.push({
                                        id: l,
                                        type: 'datasheet',
                                        obj: sh.name,
                                        shs: sh
                                    });
                                    vids.add(l);
                                }
                            }
                        }
                    }
                }
            }
            const shname = (_c = chscmeacell.get(item.id)) !== null && _c !== void 0 ? _c : '';
            return React.createElement(FormControl, { key: item.id, isRequired: item.required, className: 'panel-detail' },
                React.createElement(FormLabel, { fontWeight: 'bold', fontSize: '14px' }, item.name + (shname !== '' ? " (" + shname + ")" : '')),
                activerowui !== undefined && React.createElement(DisplayItemRow, { rowui: activerowui, item: item, entry: emap, isGrid: false }));
        }));
    }
    convertToDataRules() {
        const rstore = this.context;
        let ret = [];
        // const ope = rstore.getPwaStore().rowDataRules.get(rstore.getPwaStore().activeschema + "~~~" + this.props.state.selectedid);
        // if (ope && ope.datarules) {
        //     ret = ope.datarules;
        // }
        return ret;
    }
    convertToRules() {
        var _a, _b;
        const rstore = this.context;
        let ret = [];
        const ope = rstore.getPwaStore().rowRules.get((_b = (_a = rstore.getPwaStore().getActiveSchema()) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '' + "~~~" + this.props.state.selectedid);
        if (ope && ope.data) {
            ret = ope.data;
        }
        return ret;
    }
    render() {
        var _a;
        const rstore = this.context;
        if (!this.props.state.showdetail) {
            return React.createElement(Box, null);
        }
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get((_a = this.props.state.selectedid) !== null && _a !== void 0 ? _a : '');
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        let activerowui = rstore.getPwaStore().uirows.get(rstore.getPwaStore().pwaeditstore.selectedid);
        const height = viewheight();
        if (activerowui === undefined) {
            return React.createElement(Flex, { flexDir: 'column', align: 'center', justify: 'center', p: 4, w: '100%', h: height },
                React.createElement(Icon, { w: '30px', h: '30px', as: FaListAlt }),
                React.createElement(Text, { mt: '10px', fontSize: '12px', color: AppColor.TopBarIconColor, fontWeight: 'bold' }, "Please select an item to view detail."));
        }
        return React.createElement(Box, { margin: 'auto', w: '100%', h: "100%", bg: 'white' },
            React.createElement(Flex, { h: '40px' },
                React.createElement(Box, { w: '90%' }, this.props.state.selectedid !== '' && React.createElement(Flex, { align: 'center', mb: '10px', w: '100%', flexWrap: 'wrap' },
                    perms.has("viewrows") && React.createElement(Text, { onClick: () => rstore.getPwaStore().setActiveTab('detail'), className: (rstore.getPwaStore().activedetailtab === 'detail') ? 'tab-item-mobile active' : 'tab-item-mobile', _hover: { cursor: 'pointer' }, fontWeight: 'bold' }, "Detail"),
                    rstore.getPwaStore().customrowjs.trim() && React.createElement(Text, { onClick: () => rstore.getPwaStore().setActiveTab('custom'), className: (rstore.getPwaStore().activedetailtab === 'custom') ? 'tab-item-mobile active' : 'tab-item-mobile', _hover: { cursor: 'pointer' }, fontWeight: 'bold', ml: testIsMobile() ? '2px' : '10px' }, 'Custom'))),
                this.props.state.selectedid !== '' && React.createElement(Flex, { align: 'center' }, perms.has("editrow") && React.createElement(Icon, { as: FaEdit, mx: '10px', w: '25px', h: '25px', color: AppColor.TopBarIconColor, _hover: { cursor: 'pointer' }, onClick: () => {
                        if (activerowui !== undefined) {
                            rstore.getPwaStore().pwaeditstore.setRowIndex(activerowui.uirowindex);
                            rstore.getPwaStore().pwaeditstore.setActiveRowId(activerowui.rowid);
                            rstore.getPwaStore().pwaeditstore.setShow(true);
                            /*   this.props.virtref?.current?.scrollToIndex({
                                   index: rstore.getPwaStore().pwaeditstore.rowindex,
                                   behavior: 'smooth',
                                   align: 'start'
                               })*/
                        }
                    } })),
                this.props.state.selectedid !== '' && React.createElement(Flex, { align: 'center' }, perms.has("editrow") && React.createElement(Icon, { as: MdClose, mx: '10px', w: '25px', h: '25px', color: AppColor.CloseButton, _hover: { cursor: 'pointer' }, onClick: () => {
                        if (activerowui !== undefined) {
                            rstore.getPwaStore().pwaeditstore.setRowIndex(0);
                            rstore.getPwaStore().pwaeditstore.setSelectedId('');
                            rstore.getPwaStore().pwaeditstore.setActiveRowId('');
                            rstore.getPwaStore().pwaeditstore.clearActiveRow();
                        }
                    } }))),
            this.props.state.selectedid !== '' && React.createElement(Box, { w: '100%', h: '100%' },
                React.createElement(Box, { height: height - 50, maxH: height - 50, overflow: 'auto' },
                    rstore.getPwaStore().activedetailtab === 'detail' && this.renderRow(),
                    rstore.getPwaStore().activedetailtab === 'custom' && rstore.getPwaStore().customrowjs && React.createElement(CustomDefinedTab, null))));
    }
};
PwaDetail.contextType = StoreContext;
PwaDetail = __decorate([
    observer
], PwaDetail);
export { PwaDetail };
class DisplayItemRow extends React.Component {
    constructor(prop) {
        super(prop);
    }
    copyLinkPassword(password, label) {
        Clipboard(password);
        toast("Password coped to the clipboard", {
            position: 'top-center',
            type: 'info'
        });
    }
    async documentClicked(dmessgae, rowid, entryid, cellid) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const rstore = this.context;
        if (dmessgae.id === rstore.getPwaStore().activeitemid) {
            rstore.getPwaStore().setViewerActive(true);
            rstore.getPwaStore().setActiveItemid(dmessgae.id, rowid, entryid);
            setTimeout(() => rstore.getPwaStore().setShow(true), 100);
            return;
        }
        PwaPdfPageStore.pages = new Map();
        rstore.getPwaStore().orgname = (_a = dmessgae.origname) !== null && _a !== void 0 ? _a : dmessgae.name;
        rstore.getPwaStore().setTitle(dmessgae.name);
        rstore.getPwaStore().setActiveItemid(dmessgae.id, rowid, entryid);
        PwaPdfPageStore.activeobjectid = dmessgae.id;
        rstore.getPwaStore().setViewerActive(true);
        // rstore.documentDetailStore.canedit = false;
        // rstore.getPwaStore().draftid = "";
        rstore.getPwaStore().info = {
            fileid: dmessgae.documentreference,
            letrid: rstore.getPwaStore().getLetrid(),
            accesskey: dmessgae.accesskey,
            boxid: dmessgae.accesscode,
            version: (_b = dmessgae.versions) !== null && _b !== void 0 ? _b : [],
            replace: []
        };
        rstore.getPwaStore().setActiveDocumentId(dmessgae.id);
        rstore.getPwaStore().setLoading(false);
        rstore.getPwaMiniViewerStore().reset();
        rstore.getPwaMiniViewerStore().activeletrid = rstore.getPwaStore().getLetrid();
        rstore.getPwaMiniViewerStore().activeobjectid = dmessgae.id;
        rstore.getPwaMiniViewerStore().activedraftid = dmessgae.documentreference;
        // rstore.draftDetailStore.letrid = rstore.projectStore.activeletrid;
        // rstore.draftDetailStore.objectid = dmessgae.id;
        // rstore.draftDetailStore.draftid = dmessgae.documentreference;
        rstore.getPwaMiniViewerStore().fileid = dmessgae.documentreference;
        rstore.getPwaMiniViewerStore().accesskey = dmessgae.accesskey;
        // rstore.miniviewStore.getAllNotes();
        let metadata = rstore.getPwaStore().filemetamap.get(dmessgae.documentreference);
        rstore.getPwaStore().activedocumentreference = dmessgae.documentreference;
        rstore.getPwaStore().activerowid = rowid;
        rstore.getPwaStore().activeaccesskey = dmessgae.accesskey;
        rstore.getPwaStore().activecellid = cellid;
        rstore.getPwaStore().activeentryid = entryid;
        setTimeout(() => rstore.getPwaStore().setShow(true), 100);
        if (metadata === undefined) {
            //Fetch metadata
            await rstore.getPwaStore().fetchMetadata((res) => {
                metadata = res;
            });
        }
        if (metadata !== undefined) {
            rstore.getPwaStore().setPdfPageDetails((_d = (_c = metadata.ingestunboxed) === null || _c === void 0 ? void 0 : _c.detail) !== null && _d !== void 0 ? _d : []);
            rstore.getPwaStore().enableWatermark(metadata.watermarkstr !== undefined);
            rstore.getPwaStore().setWatermark((_f = (_e = metadata.watermarkstr) === null || _e === void 0 ? void 0 : _e.watermark) !== null && _f !== void 0 ? _f : '');
            rstore.getPwaMiniViewerStore().setTotalPages((_j = (_h = (_g = metadata.ingestunboxed) === null || _g === void 0 ? void 0 : _g.detail) === null || _h === void 0 ? void 0 : _h.length) !== null && _j !== void 0 ? _j : 0);
            rstore.getPwaMiniViewerStore().increamentPageNumber();
            rstore.getPwaStore().resetPdfPage();
            rstore.getPwaStore().loadFindDicts((fdict) => {
                setTimeout(() => rstore.getPwaMiniViewerStore().parseAndBuildFindDic(fdict), 10);
            });
            rstore.getPwaStore().checkAndGetBookmarks((bookmarks) => {
                rstore.getPwaMiniViewerStore().bookmarkstate.reset();
                rstore.getPwaMiniViewerStore().bookmarkstate.setLoading(true);
                if (bookmarks && bookmarks.length > 0) {
                    bookmarks.forEach((pg, index) => {
                        pg.sortindex = index + 1;
                        rstore.getPwaMiniViewerStore().bookmarkstate.addBookmark(pg);
                    });
                }
                rstore.getPwaMiniViewerStore().bookmarkstate.arrangeTree();
                rstore.getPwaMiniViewerStore().bookmarkstate.setLoading(false);
            });
            if (metadata.ingestunboxed !== undefined) {
                if (!metadata.ingestunboxed.haspdf &&
                    metadata.ingestunboxed.hasviewer && metadata.ingestunboxed.isExcel) {
                    rstore.getPwaStore().setIsExcel(true);
                }
                else {
                    rstore.getPwaStore().setViewable(metadata.ingestunboxed.haspdf);
                    rstore.getPwaStore().setIsExcel(false);
                }
                rstore.getPwaStore().setQueued(false);
            }
            else {
                rstore.getPwaStore().setQueued(true);
                rstore.getPwaStore().setViewable(false);
            }
        }
    }
    render() {
        var _a, _b, _c;
        if (this.props.item.type === SchemaItemType.List) {
            return React.createElement(ListDisplay, { rowui: this.props.rowui, item: this.props.item, ens: this.props.entry, isGrid: this.props.isGrid });
        }
        const ens = this.props.entry.get(this.props.item.id);
        if (!ens || (ens === null || ens === void 0 ? void 0 : ens.length) === 0) {
            return React.createElement(Box, { h: '20px' },
                React.createElement(Text, null, ''));
        }
        const rstore = this.context;
        const en = ens[0];
        const entry = this.props.item;
        let choices = [];
        let remotevals = [];
        let udata = undefined;
        let obids = [];
        let vids = new Set();
        if (entry.type === SchemaItemType.Choice) {
            const ebots = en.celldata.split(",");
            if (ebots.length > 0) {
                if (!entry.multiselect) {
                    const cval = ebots[0];
                    for (const val of entry.items) {
                        if (cval === (val.id)) {
                            choices.push(val);
                            break;
                        }
                    }
                }
                else {
                    for (const val of entry.items) {
                        if (ebots.indexOf(val.id) !== -1) {
                            choices.push(val);
                        }
                    }
                }
            }
            en.displaystr = choices.map(c => c.name).join(", ");
        }
        else if (entry.type === SchemaItemType.UserChoice) {
            const ebots = en.celldata.split(",");
            if (ebots.length > 0) {
                const users = [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                        if (u1.type != u2.type) {
                            if (u1.type === 'manageruser') {
                                return -1;
                            }
                            if (u2.type === 'manageruser') {
                                return 1;
                            }
                            return 0;
                        }
                        return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                    }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))];
                if (!entry.multiselect) {
                    const cval = ebots[0];
                    for (const val of users) {
                        if (cval === (val.id)) {
                            choices.push(val);
                            break;
                        }
                    }
                }
                else {
                    for (const val of users) {
                        if (ebots.indexOf(val.id) !== -1) {
                            choices.push(val);
                        }
                    }
                }
            }
            en.displaystr = choices.map(c => c.name).join(", ");
        }
        else if (entry.type === SchemaItemType.Remote) {
            const uent = entry;
            let uentID = uent.id;
            if (uentID.indexOf("----") !== -1) {
                const bits = uentID.split("----");
                if (bits.length > 1) {
                    uentID = bits[1];
                }
            }
            const id = uentID + "---" + uent.sid + "---" + uent.cid;
            const vals = rstore.getPwaStore().dependentdata.get(id);
            if (vals) {
                // now we have this
                const bits = en.celldata.split(",");
                if (bits && bits.length > 0) {
                    remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1).sort((r1, r2) => {
                        const r1index = bits.indexOf(r1.id);
                        const r2index = bits.indexOf(r2.id);
                        return r1index - r2index;
                    });
                    if (remotevals.length > 0) {
                        en.displaystr = remotevals.map((val) => val.value).join(", ");
                    }
                }
            }
        }
        if (en.celldata && entry.type === SchemaItemType.URL) {
            try {
                udata = JSON.parse(en.celldata);
                en.displaystr = "<a href=\"" + udata.url + "\" >" + udata.name + "</a>";
            }
            catch (ex) { }
        }
        if (en.linkedids && en.linkedids.length > 0 && entry.type === SchemaItemType.Objects) {
            const pmap = new Map();
            (_a = en.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((ob) => pmap.set("objs-" + ob.id, ob));
            const smap = new Map();
            (_b = en.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sj) => smap.set(sj.id, sj));
            for (const l of en.linkedids) {
                if (l.startsWith("objs-")) {
                    const obj = pmap.get(l);
                    if (obj) {
                        const tds = obj.type === ProjectObjectType.Document ? obj.thumbdetail : undefined;
                        obids.push({
                            id: l,
                            type: 'document',
                            obj: obj,
                            name: obj.name,
                            thumdet: tds
                        });
                        vids.add(l);
                    }
                }
                else if (l.startsWith("dch-")) {
                    const parts = l.split("-");
                    if (parts.length > 2) {
                        const did = parts[1];
                        const sid = parts[2];
                        const sh = smap.get(sid);
                        if (sh) {
                            obids.push({
                                id: l,
                                type: 'datasheet',
                                obj: sh.name,
                                name: sh.name
                            });
                            vids.add(l);
                        }
                    }
                }
            }
            en.displaystr = obids.map(c => { var _a; return (_a = c.name) !== null && _a !== void 0 ? _a : ''; }).join(", ");
        }
        let showalert = rstore.getPwaStore().checkRowEnnrtyNeedAlert(this.props.rowui, en.cellid);
        return React.createElement(Box, { width: '100%', backgroundColor: showalert ? '#ee5555' : '' },
            entry.type === SchemaItemType.ID && React.createElement(Box, null,
                React.createElement(Text, null, getDisplayForID(en)),
                " "),
            entry.type === SchemaItemType.SquenceNumber && React.createElement(Box, null,
                React.createElement(Text, null, en.celldata),
                " "),
            entry.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { itemid: entry.id, row: this.props.rowui, item: entry, subrowindex: this.props.subrowindex }),
            entry.type === SchemaItemType.Text && React.createElement(Box, { dangerouslySetInnerHTML: { __html: en.celldata } }),
            entry.type === SchemaItemType.Date && React.createElement(Flex, { flexWrap: 'wrap', w: '100%' },
                React.createElement(Text, null, DateLib.displayDateWIthFormat(en.celldata, entry.inlcudetime, (_c = entry.excludedate) !== null && _c !== void 0 ? _c : false))),
            entry.type === SchemaItemType.Choice && choices.length > 0 && !entry.multiselect &&
                React.createElement(Flex, { w: '100%', align: 'center' },
                    choices.length > 0 && choices[0].color !== undefined && choices[0].color.length > 0 && React.createElement(Box, { _hover: { cursor: 'pointer' }, key: 'main_col_blob' + choices[0].id, borderWidth: 1, borderColor: '#404040', title: choices[0].name, marginRight: '5px', borderRadius: '10px', width: '20px', height: '20px', bg: choices[0].color }),
                    React.createElement(Text, null, choices != undefined && choices.length > 0 ? choices[0].name : '')),
            entry.type === SchemaItemType.Choice && choices.length > 0 && entry.multiselect && React.createElement(Box, { w: '100%' }, choices.map((ch) => React.createElement(Flex, { align: 'center', key: 'tag_col_' + ch.id },
                ch.color && ch.color.length > 0 && React.createElement(Box, { _hover: { cursor: 'pointer' }, borderWidth: 1, borderColor: '#404040', title: ch.name, margin: '2px', borderRadius: '10px', width: '20px', height: '20px', bg: ch.color }),
                React.createElement(Tag, { key: ch.id, m: '3px' }, ch.name)))),
            entry.type === SchemaItemType.UserChoice && !entry.multiselect && React.createElement(Flex, { w: '100%', justify: 'center' },
                React.createElement(Text, null, choices != undefined && choices.length > 0 ? choices[0].name : '')),
            entry.type === SchemaItemType.Remote && React.createElement(Flex, { flexWrap: 'wrap', w: '100%', justify: 'center' }, remotevals.map((ch, index) => {
                return React.createElement(Box, { margin: '5px', key: ch.id + '_main', width: '100%' },
                    React.createElement(RemoteView, { index: index, item: entry, rtItem: ch }),
                    React.createElement(Tag, { key: ch.id, m: '3px' }, ch.value));
            })),
            entry.type === SchemaItemType.UserChoice && entry.multiselect && React.createElement(Flex, { flexWrap: 'wrap', w: '100%', justify: 'center' }, choices.map((ch) => React.createElement(Tag, { key: ch.id, m: '3px' }, ch.name))),
            entry.type === SchemaItemType.Color && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: en.celldata }),
            entry.type === SchemaItemType.Boolean && React.createElement(Flex, { w: '100%', align: 'center' }, en.celldata === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' })),
            entry.type === SchemaItemType.Number && React.createElement(Flex, { w: '100%', paddingRight: '5px' }, en.celldata),
            entry.type === SchemaItemType.FormInput && React.createElement(Flex, { align: 'center' },
                React.createElement(Text, null, entry.enablesecret ? '****' : en.celldata),
                entry.enablesecret && React.createElement(Icon, { onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.copyLinkPassword(en.celldata, entry.name);
                    }, as: FaClipboard, ml: '5px' })),
            entry.type === SchemaItemType.URL && udata !== undefined && React.createElement(Link, { href: (udata.url), isExternal: true },
                ((udata.name !== undefined && udata.name !== '') ? udata.name :
                    udata.url),
                React.createElement(Icon, { as: FiExternalLink, mx: '2px' })),
            entry.type === SchemaItemType.Objects && React.createElement(Flex, { flexWrap: 'wrap', maxW: '100%', mt: '10px' }, obids.map((obj, index) => {
                let name = "";
                let shwthumb = false;
                let ratio = -1;
                let tdet = {
                    name: '',
                    width: 0,
                    height: 0
                };
                if (obj.type === 'document') {
                    const bo = obj.obj;
                    name = bo.name;
                    if (bo.type === ProjectObjectType.Document) {
                        const do1 = bo;
                        if (do1.thumbdetail) {
                            let zerothum = -1;
                            for (const fg of do1.thumbdetail) {
                                if (fg.name.startsWith("0_thumb")) {
                                    if (fg.width > 0 && fg.height > 0) {
                                        zerothum = fg.height / fg.width;
                                        tdet = fg;
                                    }
                                    break;
                                }
                            }
                            if (zerothum > 0) {
                                shwthumb = true;
                                ratio = zerothum;
                            }
                        }
                    }
                }
                else {
                    name = obj.obj + "";
                }
                if (shwthumb) {
                    return React.createElement(Box, { key: obj.id + "_" + index, w: '100px', onClick: () => {
                            // lets open this
                            if (ThumbNailStore.activethumid) {
                                ThumbNailStore.setActiveThumb("");
                            }
                        } },
                        React.createElement(Flex, { align: 'center' },
                            React.createElement(ThumbNail, { clickcb: () => {
                                }, rowid: en.rowid, cellid: en.cellid, entryid: en.id, obj: obj.obj, ratio: ratio, thumbdet: tdet }),
                            !ThumbNailStore.loadingthumbs.has(obj.obj.documentreference) &&
                                ThumbNailStore.thumbs.has(obj.obj.documentreference) &&
                                React.createElement(Observer, null, () => React.createElement(Icon, { as: FaEye, ml: '5px', _hover: { cursor: 'pointer' }, onClick: (e) => {
                                        if (ThumbNailStore.activethumid) {
                                            ThumbNailStore.setActiveThumb("");
                                        }
                                        if (obj.type === 'document') {
                                            if (rstore.getPwaStore().online) {
                                                this.documentClicked(obj.obj, en.rowid, en.id, en.cellid);
                                            }
                                            else {
                                                toast("Sorry, content is not available offline. Please ensure that your device is connected to the interent.", {
                                                    position: 'top-right',
                                                    type: 'error'
                                                });
                                            }
                                        }
                                        else if (obj.type === 'datasheet') {
                                        }
                                    } }))),
                        React.createElement(Flex, { align: 'center' },
                            React.createElement(Tag, { ml: '5px', key: obj.id, margin: '4px' },
                                React.createElement(TagLabel, { _hover: { cursor: 'pointer' }, onClick: () => {
                                        if (rstore.getPwaStore().online) {
                                            this.documentClicked(obj.obj, en.rowid, en.id, en.cellid);
                                        }
                                        else {
                                            toast("Sorry, content is not available offline. Please ensure that your device is connected to the interent.", {
                                                position: 'top-right',
                                                type: 'error'
                                            });
                                        }
                                    } }, name))));
                }
                return React.createElement(Tag, { onClick: () => {
                        // lets open this
                    }, key: obj.id, margin: '4px' },
                    React.createElement(TagLeftIcon, { as: obj.type === 'document' ? FcDocument : FcDataSheet }),
                    React.createElement(TagLabel, { whiteSpace: 'normal', _hover: { cursor: 'pointer' }, onClick: () => {
                            if (obj.type === 'document') {
                                if (rstore.getPwaStore().online) {
                                    this.documentClicked(obj.obj, en.rowid, en.id, en.cellid);
                                }
                                else {
                                    toast("Sorry, content is not available offline. Please ensure that your device is connected to the interent.", {
                                        position: 'top-right',
                                        type: 'error'
                                    });
                                }
                            }
                            else if (obj.type === 'datasheet') {
                            }
                        } }, name));
            })));
    }
}
DisplayItemRow.contextType = StoreContext;
let CustomDisplayItemCont = class CustomDisplayItemCont extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        const rstore = this.context;
        if (rstore.getPwaStore().loadingrows) {
            return React.createElement(React.Fragment, null);
        }
        // if(rstore.getPwaStore().fetchingupdates) {
        //     return <></>
        // }
        return React.createElement(PwaCustomDisplayItemUI, { itemid: this.props.itemid, storevale: (Data) => { var _a, _b; return (_b = (_a = this.props).storevale) === null || _b === void 0 ? void 0 : _b.call(_a, Data); }, row: this.props.row, item: this.props.item, subrowindex: this.props.subrowindex });
    }
};
CustomDisplayItemCont.contextType = StoreContext;
CustomDisplayItemCont = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomDisplayItemCont);
let ThumbNail = class ThumbNail extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
        this.state = {
            hover: false
        };
    }
    async componentDidMount() {
        const rstore = this.context;
        if (!ThumbNailStore.thumbs.has(this.props.obj.documentreference)) {
            if (!ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference)) {
                ThumbNailStore.setLoadingThumb(this.props.obj.documentreference);
                rstore.getPwaStore().addDocumentEntity(this.props.obj.id, this.props.obj);
                const page = await rstore.getPwaStore().getPdfViewerContent(this.props.obj.documentreference, "0_thumb", this.props.rowid, rstore.getPwaStore().getActiveFormUserId(), this.props.obj.accesskey, this.props.cellid, this.props.entryid, this.props.obj.id);
                ThumbNailStore.addThumb(this.props.obj.documentreference, page !== null && page !== void 0 ? page : '');
            }
        }
    }
    render() {
        var _a, _b;
        return React.createElement(Box, { ref: (re) => this.myref = re, width: '100px', height: 75 + 'px', pos: 'relative' },
            ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference) && React.createElement(Text, null, "Loading.."),
            ThumbNailStore.thumbs.has(this.props.obj.documentreference) && React.createElement("img", { onMouseDown: () => {
                    console.log("oops");
                }, onClick: (e) => {
                    e.stopPropagation();
                    if (this.props.obj.documentreference === ThumbNailStore.activethumid)
                        return;
                    ThumbNailStore.setActiveThumb(this.props.obj.documentreference);
                    if (this.myref) {
                        let left = this.myref.getBoundingClientRect().x;
                        if (window.innerWidth < 400) {
                            left = 0;
                        }
                        else if ((window.innerWidth - left) < 350) {
                            const diff = window.innerWidth - left;
                            const cover = 350 - diff;
                            let newleft = left - cover;
                            if (newleft < 0) {
                                newleft = 0;
                            }
                            left = newleft;
                        }
                        ThumbNailStore.setLeftPos(left);
                        ThumbNailStore.setTopPos(this.myref.getBoundingClientRect().y);
                    }
                    ThumbNailStore.clickcb = () => this.props.clickcb();
                }, width: '100px', height: '75px', style: { objectFit: 'contain', width: 100, height: 75, cursor: 'pointer' }, src: (_a = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _a !== void 0 ? _a : '' }),
            this.state.hover && React.createElement("img", { style: { position: 'absolute', top: 0, left: 0, width: 300, height: 200, objectFit: 'contain' }, src: (_b = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _b !== void 0 ? _b : '' }));
    }
};
ThumbNail.contextType = StoreContext;
ThumbNail = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ThumbNail);
class CustomDefinedTab extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            rebuild: false
        };
    }
    render() {
        const rstore = this.context;
        if (this.state.rebuild) {
            return React.createElement(Box, null,
                React.createElement(Text, null, "Rebuilding"));
        }
        return React.createElement(Box, null,
            React.createElement(SchemaCustomPanel, { viewkey: 'customrowpaneldet', js: rstore.getPwaStore().customrowjs.trim() }));
    }
}
CustomDefinedTab.contextType = StoreContext;
let ListDisplay = class ListDisplay extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        let displayitems = ((_a = this.props.item.items) !== null && _a !== void 0 ? _a : []).map((it) => (Object.assign(Object.assign({}, it.item), { id: this.props.item.id + "----" + it.item.id })));
        const sortedmap = new Map();
        let sortedrows = [];
        displayitems.forEach((item) => {
            const ens = this.props.ens.get(item.id);
            if (ens) {
                ens.sort(sortEntriesByRow);
                ens.forEach((en) => {
                    if (en.subrowindex) {
                        if (sortedrows.indexOf(en.subrowindex) === -1) {
                            sortedrows.push(en.subrowindex);
                        }
                        if (!sortedmap.has(en.subrowindex)) {
                            sortedmap.set(en.subrowindex, new Map());
                        }
                        const cellid = en.cellid;
                        let cellmap = sortedmap.get(en.subrowindex);
                        if (!cellmap) {
                            cellmap = new Map();
                            sortedmap.set(en.subrowindex, cellmap);
                        }
                        cellmap.set(cellid, [en]);
                    }
                });
            }
        });
        sortedrows.sort((s1, s2) => {
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (parts1[0] === parts2[0]) {
                // if(parts1.length < 2 || parts2.length < 2) {
                //     return -1;
                // }
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : Math.floor((window.innerWidth - 195) / 2);
        let swidth = displayitems.length * 120;
        if (swidth < totalwidth) {
            swidth = totalwidth;
        }
        return React.createElement(Box, { mt: '10px', ml: '10px', w: this.props.isGrid ? undefined : window.innerWidth < 600 ? (window.innerWidth - 55) + "px" : (Math.floor((window.innerWidth - 195) / 2)) + 'px', maxW: this.props.isGrid ? undefined :
                window.innerWidth < 600 ? (window.innerWidth - 55) + "px" : (Math.floor((window.innerWidth - 195) / 2)) + 'px', overflowX: this.props.isGrid ? 'hidden' : 'auto', minH: '40px' },
            !this.props.isGrid && React.createElement(Flex, { width: 150 * displayitems.length, alignItems: 'center', justifyContent: 'center', borderBottomColor: 'lightgray', borderBottomWidth: '1px' }, displayitems.map((item, index) => React.createElement(Flex, { justifyContent: 'center', paddingLeft: '5px', key: item.id + "_header" + index, w: '150px', maxW: '150px' },
                React.createElement(Text, { fontWeight: 'bold' }, item.name)))),
            sortedrows.map((sr, index) => {
                return React.createElement(Flex, { width: 150 * displayitems.length, alignItems: 'center', justifyItems: 'center', wordBreak: 'break-word', borderBottomColor: this.props.isGrid ? 'lightgray' : 'lightgray', borderBottomWidth: index < (sortedmap.size - 1) ? this.props.isGrid ? '1px' : '1px' : '0px', mt: '5px', key: sr + "_row" },
                    displayitems.map((item) => {
                        const enmap = sortedmap.get(sr);
                        const key = item.id + "_" + sr;
                        if (!enmap) {
                            return React.createElement(Box, { paddingLeft: '5px', key: key, w: '150px', maxW: '150px' });
                        }
                        const en = enmap === null || enmap === void 0 ? void 0 : enmap.get(item.id);
                        if (!en) {
                            return React.createElement(Box, { paddingLeft: '5px', key: key, w: '150px', maxW: '150px' });
                        }
                        return React.createElement(Flex, { justifyContent: 'center', paddingLeft: '5px', key: key + "_cont", w: '150px', maxW: '150px' },
                            React.createElement(DisplayItemRow, { rowui: this.props.rowui, item: item, entry: enmap, isGrid: this.props.isGrid, subrowindex: sr }));
                    }),
                    sortedrows.length === 0 && React.createElement(Box, { w: '100%', h: '20px' }));
            }));
        /*
                                                return <Box ml={'10px'} w={window.innerWidth < 600 ? (window.innerWidth - 35) + "px" : '500px'}
                                                    bg={'whiteAlpha.400'} boxShadow={'0 0 5px #b6b6b6'} >
                                                    <Box padding='5px'>
                                                        {displyitemsrow.map((entrys, index) => {
                                                            return <Flex key={this.props.item.id + "_entryitems" + "_" + index} w='100%' justifyContent={'space-between'}>
                                                                {entrys.map((en) => <Box key={en.id} w='240px' >
                                                                    <Text fontWeight={'bold'}>{en.name}</Text>
                                                                    <DisplayItemRow item={en} entry={this.props.ens} />
                                                                </Box>)}
                                                            </Flex>
                                                        })}
                                                    </Box>
                                                </Box>*/
    }
};
ListDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplay);
let RemoteView = class RemoteView extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
    }
    componentDidMount() {
    }
    render() {
        const rstore = this.context;
        const ritem = this.props.item;
        const rtextra = ritem.rtextra;
        if (rtextra === undefined || rtextra.length === 0) {
            return React.createElement(Box, null);
        }
        const rowid = this.props.rtItem.rowid;
        if (!rowid) {
            return React.createElement(Box, null);
        }
        let rtDisplayItems = [];
        const mid = ritem.id + "---" + ritem.sid + "---" + ritem.cid;
        for (const ext of rtextra) {
            let uentID = ritem.id;
            if (uentID.indexOf("----") !== -1) {
                const bits = uentID.split("----");
                if (bits.length > 1) {
                    uentID = bits[1];
                }
            }
            const id = uentID + "---" + ritem.sid + "---" + ext.cid;
            if (id == mid)
                continue;
            const items = rstore.getPwaStore().dependentdata.get(id);
            if (items !== undefined) {
                for (const it of items) {
                    if (it.rowid === rowid) {
                        let ri = {
                            id: it.id,
                            label: ext.name,
                            rowid: it.rowid,
                            value: it.value,
                            objects: it.objects,
                            cellid: it.cellid,
                            schemaid: it.schemaid
                        };
                        rtDisplayItems.push(ri);
                        break;
                    }
                }
            }
        }
        if (rtDisplayItems.length === 0) {
            return React.createElement(Box, null);
        }
        return React.createElement(Box, { w: '100%', ref: (re) => this.myref = re, className: 'remote-thumbnail', pos: 'relative', backgroundColor: '#FFFFFF' },
            React.createElement(Box, { onClick: (e) => {
                    e.stopPropagation();
                    // if (this.props.obj.documentreference === ThumbNailStore.activethumid) return;
                    RemotePopupViewStore.setActiveId(this.props.item.id);
                    if (this.myref) {
                        RemotePopupViewStore.reset();
                        RemotePopupViewStore.setLeftPos(this.myref.getBoundingClientRect().x);
                        RemotePopupViewStore.setTopPos(this.myref.getBoundingClientRect().y);
                        RemotePopupViewStore.setBGColor('#FFFFFF');
                        RemotePopupViewStore.addRtDisplayItems(rtDisplayItems);
                    }
                }, width: '100%', height: '100px' }, rtDisplayItems.map((en) => React.createElement(Box, { w: '100%', key: en.id + "_tn" },
                React.createElement(Text, { fontWeight: 'bold', className: 'remote-thumbnail-text' }, en.label),
                React.createElement(Text, { className: 'remote-thumbnail-text' }, en.value)))));
    }
};
RemoteView.contextType = StoreContext;
RemoteView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteView);
