var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { observable, action, makeObservable } from "mobx";
export class SortDialogState {
    constructor() {
        this.valuesbyid = new Map();
        makeObservable(this);
    }
    addValue(id, value) {
        this.valuesbyid.clear();
        this.valuesbyid.set(id, value);
    }
    deleteValue(id) {
        this.valuesbyid.delete(id);
    }
    clear() {
        this.valuesbyid.clear();
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], SortDialogState.prototype, "valuesbyid", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], SortDialogState.prototype, "addValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], SortDialogState.prototype, "deleteValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SortDialogState.prototype, "clear", null);
