export var AppState;
(function (AppState) {
    AppState[AppState["Register"] = 0] = "Register";
    AppState[AppState["LoggedIn"] = 1] = "LoggedIn";
    AppState[AppState["Login"] = 2] = "Login";
    AppState[AppState["Logout"] = 3] = "Logout";
    AppState[AppState["ResetPassword"] = 4] = "ResetPassword";
    AppState[AppState["Unknown"] = 5] = "Unknown";
    AppState[AppState["APPDown"] = 6] = "APPDown";
})(AppState || (AppState = {}));
