var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import { MdSend } from "@react-icons/all-files/md/MdSend";
import { observable, action, makeObservable } from "mobx";
import { Observer, observer } from "mobx-react";
import React, { createRef, Suspense } from "react";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { Virtuoso } from "react-virtuoso";
import { DateLib } from "@@/Datelib";
import { BeatLoader } from "react-spinners";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { MdSave } from "@react-icons/all-files/md/MdSave";
import { AppColor } from "../const/AppDefaults";
const ReactQuillEdit = require("react-quill");
const ReactQuillCommentEdit = React.lazy(async () => await import("react-quill"));
export class CommentState {
    constructor() {
        this.comments = new Map();
        this.html = "";
        this.offset = 44;
        this.deletcomments = new Set();
        this.sendingcomments = new Map();
        this.activenoteid = "";
        this.editmode = false;
        this.text = "";
        this.edittingCommentId = "";
        this.editingHtml = "";
        this.editingtext = "";
        this.savingedit = false;
        this.edittingcomments = new Map();
        makeObservable(this);
    }
    setEditCommentId(id) {
        var _a;
        if (this.savingedit) {
            return;
        }
        this.edittingCommentId = id;
        const comment = this.comments.get(id);
        if (comment !== undefined) {
            this.setEditText((_a = comment.datastr) !== null && _a !== void 0 ? _a : "");
        }
        else {
            this.setEditText("");
        }
    }
    setEditText(text) {
        this.editingHtml = text;
    }
    setEditPlainText(text) {
        this.editingtext = text;
    }
    setHtml(html) {
        this.html = html;
    }
    setText(text) {
        this.text = text.trim();
    }
    removeSendingComment(id) {
        this.sendingcomments.delete(id);
    }
    setSendingComment(comm) {
        this.sendingcomments.set(comm.id, comm);
    }
    setComment(comm) {
        this.comments.set(comm.id, comm);
    }
    removeComment(comm) {
        this.comments.delete(comm);
        this.removeCommentFromDelete(comm);
    }
    removeCommentFromDelete(comm) {
        this.deletcomments.delete(comm);
    }
    setOffset(off) {
        this.offset = off;
    }
    addoDeleteList(del) {
        this.deletcomments.add(del);
    }
    clear() {
        this.comments.clear();
        this.sendingcomments.clear();
        this.activenoteid = "";
        this.deletcomments.clear();
        this.setEdittingComment(false);
        this.editmode = false;
        this.edittingCommentId = "";
    }
    setEdittingComment(editting) {
        this.savingedit = editting;
    }
    updateComment(com) {
        this.comments.set(com.id, com);
    }
    getComments() {
        return [...Array.from(this.comments.values()),
            ...Array.from(this.sendingcomments.values())].sort((c1, c2) => c1.updatetime - c2.updatetime);
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], CommentState.prototype, "comments", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "html", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "offset", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], CommentState.prototype, "deletcomments", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], CommentState.prototype, "sendingcomments", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "text", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "edittingCommentId", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "editingHtml", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "editingtext", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CommentState.prototype, "savingedit", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], CommentState.prototype, "edittingcomments", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setEditCommentId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setEditText", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setEditPlainText", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setHtml", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setText", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "removeSendingComment", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setSendingComment", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setComment", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "removeComment", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "removeCommentFromDelete", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setOffset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "addoDeleteList", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "clear", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "setEdittingComment", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CommentState.prototype, "updateComment", null);
let Comments = class Comments extends React.Component {
    constructor(prop) {
        super(prop);
        this.vref = null;
    }
    buildCommentBox(com) {
        const formats = [
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];
        const modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'color': [] }],
            ],
        };
        const authorname = (com.authorid === this.props.userid) ? 'Me' : com.authorname;
        return React.createElement(Observer, null, () => {
            var _a;
            return React.createElement(Box, { bg: '#f4f7ff', mt: '10px', ml: '5px', mr: '5px', padding: '5px' },
                React.createElement(Flex, { h: '40px', borderBottomWidth: 1, borderBottomColor: '#e5e5e5' },
                    React.createElement(Text, { w: '70%', fontWeight: 'bold', fontSize: 12 }, authorname),
                    React.createElement(Text, { w: '30%', textAlign: 'right', fontSize: 12 }, DateLib.displayDate(com.updatetime))),
                this.props.state.edittingCommentId === com.id &&
                    React.createElement(Box, { width: '100%' },
                        React.createElement(Suspense, { fallback: React.createElement(Flex, { style: { height: '100%' }, justify: 'center', align: 'center' },
                                React.createElement(BeatLoader, { color: 'green' })) },
                            React.createElement(ReactQuillCommentEdit, { theme: "snow", modules: modules, formats: formats, defaultValue: this.props.state.editingHtml, onChange: (content, delta, source, editor) => {
                                    this.props.state.setEditText(editor.getHTML());
                                    this.props.state.setEditPlainText(editor.getText());
                                } }))),
                this.props.state.edittingCommentId !== com.id && React.createElement(Box, { mt: '10px', overflow: 'auto', maxW: '100%', dangerouslySetInnerHTML: { __html: (_a = com.datastr) !== null && _a !== void 0 ? _a : '' } }),
                React.createElement(Flex, { mt: '10px', align: 'center', justify: 'flex-end' },
                    this.props.showeditoption(com) && !this.props.state.sendingcomments.has(com.id)
                        && !this.props.state.deletcomments.has(com.id) && this.props.state.edittingCommentId !== com.id &&
                        React.createElement(Flex, null,
                            React.createElement(Icon, { mr: '10px', as: MdDelete, _hover: { cursor: 'pointer' }, onClick: () => {
                                    this.props.state.addoDeleteList(com.id);
                                    this.props.deleteComment(com.id);
                                }, color: AppColor.DeleteIconColor, className: 'message-option-button' }),
                            React.createElement(Icon, { mr: '10px', as: FaEdit, _hover: { cursor: 'pointer' }, onClick: () => {
                                    var _a;
                                    this.props.state.setEditText((_a = com.datastr) !== null && _a !== void 0 ? _a : "");
                                    this.props.state.setEditCommentId(com.id);
                                }, color: AppColor.EditButtonColor, className: 'message-option-button' })),
                    this.props.showeditoption(com) && !this.props.state.sendingcomments.has(com.id)
                        && !this.props.state.deletcomments.has(com.id) && this.props.state.edittingCommentId === com.id &&
                        React.createElement(Flex, null,
                            !this.props.state.savingedit && React.createElement(Icon, { mr: '10px', as: MdSave, _hover: { cursor: 'pointer' }, onClick: () => {
                                    var _a, _b;
                                    this.props.state.setEdittingComment(true);
                                    (_b = (_a = this.props).onEdit) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.state.editingHtml, this.props.state.editingtext, this.props.state.edittingCommentId);
                                }, color: AppColor.EditButtonColor, className: 'message-option-button' }),
                            this.props.state.savingedit && React.createElement(Spinner, null),
                            !this.props.state.savingedit && React.createElement(Icon, { mr: '10px', as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => {
                                    this.props.state.setEditText("");
                                    this.props.state.setEditCommentId("");
                                }, color: AppColor.DeleteIconColor, className: 'message-option-button' })),
                    this.props.state.sendingcomments.has(com.id) && React.createElement(Text, { fontWeight: 'bold', ml: '10px' }, "Sending.."),
                    this.props.state.deletcomments.has(com.id) && React.createElement(Text, { fontWeight: 'bold', ml: '10px' }, "Deleting.."),
                    this.props.state.edittingcomments.has(com.id) && React.createElement(Text, { fontWeight: 'bold', ml: '10px' }, "Saving..")));
        });
    }
    render() {
        return React.createElement(Box, { pos: 'relative', bg: 'white', maxH: this.props.height },
            !this.props.hideheader && React.createElement(Flex, { w: '100%', h: '40px', className: 'comment-header' },
                React.createElement(Box, { width: '90%', style: { justifyContent: 'flex-start' } },
                    React.createElement(Text, null, 'Comments')),
                React.createElement(Box, { width: '10%', style: { justifyContent: 'flex-end' } },
                    React.createElement(Icon, { size: '20px', w: '20px', h: '20px', color: '#404040', as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => { this.props.onClose(); } }))),
            React.createElement(Box, { h: this.props.height + "px", w: '100%', paddingTop: '40px' },
                React.createElement(Observer, null, () => React.createElement(Virtuoso, { ref: ref => this.vref = ref, style: { height: this.props.height - 190, maxHeight: this.props.height - 190 }, data: this.props.state.getComments(), itemContent: (index, com) => this.buildCommentBox(com) }))),
            this.props.hideeditor === undefined ||
                (this.props.hideeditor === undefined && !this.props.hideeditor)
                    && React.createElement(Box, { pos: 'absolute', bottom: '0px', w: '100%' },
                        React.createElement(CommentEditor, { state: this.props.state, onSend: (html, text) => {
                                this.props.onSend(html, text);
                                setTimeout(() => { var _a; return (_a = this.vref) === null || _a === void 0 ? void 0 : _a.scrollToIndex(this.props.state.comments.size - 1); }, 10);
                            } })));
    }
};
Comments = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], Comments);
export { Comments };
let CommentEditor = class CommentEditor extends React.Component {
    constructor(props) {
        super(props);
        this.quillContainerRef = createRef();
        this.quillref = createRef();
        this.sendButtonRef = createRef();
    }
    textChanged() {
        var _a;
        const currentHeight = (_a = this.quillContainerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight;
        this.props.state.setOffset((currentHeight !== null && currentHeight !== void 0 ? currentHeight : 88) - 44);
    }
    render() {
        const keyBindings = {};
        const formats = [
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];
        const modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'color': [] }],
            ],
        };
        return (React.createElement(Box, { zIndex: 22000 },
            React.createElement(Box, null,
                React.createElement(Box, { backgroundColor: 'blue' }),
                React.createElement(Box, { ref: this.quillContainerRef, className: 'editorBox comments', position: 'relative', display: 'flex', flexDirection: 'column-reverse' },
                    React.createElement(ReactQuillEdit, { ref: this.quillref, theme: "snow", onChange: (con, delta, source, editor) => {
                            var _a, _b;
                            this.props.state.setHtml(editor.getHTML());
                            this.props.state.setText((_b = (_a = this.quillref.current) === null || _a === void 0 ? void 0 : _a.getEditor().getText()) !== null && _b !== void 0 ? _b : "");
                            this.textChanged();
                        }, modules: modules, formats: formats }),
                    React.createElement(Box, { pos: 'relative' },
                        React.createElement(Box, { className: 'enterButton', style: { color: 'grey', top: this.props.state.offset + "px" }, ref: this.sendButtonRef, onClick: () => {
                                var _a;
                                this.props.onSend(this.props.state.html, this.props.state.text);
                                this.props.state.setHtml("");
                                (_a = this.quillref.current) === null || _a === void 0 ? void 0 : _a.getEditor().setText("");
                            }, _hover: { cursor: 'pointer' } },
                            React.createElement(Icon, { h: '42px', w: '25px', as: MdSend })))))));
    }
};
CommentEditor = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CommentEditor);
