export var LetrRequestType;
(function (LetrRequestType) {
    LetrRequestType[LetrRequestType["Add"] = 0] = "Add";
    LetrRequestType[LetrRequestType["Delete"] = 1] = "Delete";
    LetrRequestType[LetrRequestType["Fetch"] = 2] = "Fetch";
    LetrRequestType[LetrRequestType["FetchDetail"] = 3] = "FetchDetail";
})(LetrRequestType || (LetrRequestType = {}));
export var LetrEditrequestType;
(function (LetrEditrequestType) {
    LetrEditrequestType[LetrEditrequestType["Add"] = 0] = "Add";
    LetrEditrequestType[LetrEditrequestType["Delete"] = 1] = "Delete";
    LetrEditrequestType[LetrEditrequestType["update"] = 2] = "update";
    LetrEditrequestType[LetrEditrequestType["updatelink"] = 3] = "updatelink";
})(LetrEditrequestType || (LetrEditrequestType = {}));
