export class FindDicStore {
    constructor(words, pages) {
        this.words = words;
        this.pages = pages;
    }
}
export class FindMatchTerm {
    constructor(word, pagedictionay) {
        this.word = word;
        this.pagedictionay = pagedictionay;
    }
}
export class FindDict {
    constructor(words) {
        this.words = words;
    }
}
