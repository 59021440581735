// import { LetrwingCrypto } from "../../letrcore/LetrwingCommonCrypto";
// import { ServerPath } from "../../letrcore/ServerPath";
// import { CallRecord, CallRecordDto, CallType } from "../models/CallRecord";
// import { LetrBoxStorage } from "../storage/Letrboxstorage";
// import { shareLib } from "../util/RequestUtil";
// import { LService } from "./LetrServiceNew";
// import { SecurityService } from "./SecurityService";
import { comm } from "@/Comm/comm";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { LStore } from "./LetrStore";
import { SecStore } from "./SecurityStore";
class Store {
    async fetchCallLogs(since, after, count, ascending, type) {
        const mainpassword = InMemoryRepo.getMasterPassword();
        if (mainpassword === undefined) {
            return undefined;
        }
        const ret = [];
        const filter = {
            before: since,
            count: count,
            after: after,
            ascending: ascending
        };
        console.log(filter);
        const res = await comm.post(ServerPath.getCallRecords, filter);
        console.log(res);
        if (res === undefined) {
            return ret;
        }
        // now we can decrypt these here!
        for (const rs of res) {
            const mbox = await SecStore.decryptPasswordBox(rs.databox, mainpassword);
            if (mbox === undefined) {
                continue;
            }
            let subject = LetrwingCrypto.decryptSecretBox(rs.subject, mbox.password);
            if (subject === undefined) {
                continue;
            }
            let title = rs.title;
            if (!title) {
                title = subject;
            }
            LStore.letrsubjectById.set(rs.letrid, title);
            // LStore.letrPasswordById.set(rs.letrid, mbox.password);
            ret.push({
                id: rs.id,
                title: title,
                time: rs.starttime,
                type: rs.type,
                letrid: rs.letrid
            });
        }
        return ret;
    }
}
export const CallStore = new Store();
