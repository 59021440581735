var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, action } from "mobx";
export class TimelineState {
    constructor() {
        this.timelinecards = new Map();
        this.buildingview = false;
        this.defaultstarttime = -1;
        this.defaultendtime = -1;
        this.selectedcard = "";
        this.width = 600;
        this.height = 600;
        this.buildcount = 0;
        makeObservable(this);
    }
    setWidth(width) {
        this.width = width;
    }
    setHeight(height) {
        this.height = height;
    }
    setTimelineCard(card) {
        if (card.rangeDates.length < 2) {
            card.rangeDates.push(card.rangeDates[0]);
        }
        if (card.rangeDates.length > 0) {
            card.rangeDates.sort((r1, r2) => r1 - r2);
        }
        if (this.defaultstarttime === -1 || this.defaultstarttime > card.rangeDates[0]) {
            this.defaultstarttime = card.rangeDates[0];
        }
        if (this.defaultendtime === -1 || this.defaultendtime < card.rangeDates[1]) {
            this.defaultendtime = card.rangeDates[1];
        }
        card.indexitem = this.timelinecards.size - 1;
        this.timelinecards.set(card.id, card);
    }
    clearItems() {
        this.timelinecards.clear();
    }
    setSelectedId(id) {
        this.selectedcard = id;
    }
    updateElement(id, ele) {
        const card = this.timelinecards.get(id);
        console.log("got card");
        console.log(card);
        if (card) {
            if (ele.rangeDates.length < 2) {
                ele.rangeDates.push(card.rangeDates[0]);
            }
            if (ele.rangeDates.length > 1) {
                ele.rangeDates.sort((r1, r2) => r1 - r2);
            }
            if (this.defaultstarttime === -1 || this.defaultstarttime > ele.rangeDates[0]) {
                this.defaultstarttime = ele.rangeDates[0];
            }
            if (this.defaultendtime === -1 || this.defaultendtime < ele.rangeDates[1]) {
                this.defaultendtime = ele.rangeDates[1];
            }
            const index = card.indexitem;
            if (index !== undefined) {
                ele.indexitem = card.indexitem;
            }
            this.timelinecards.set(id, ele);
        }
    }
    sortItems(a, b) {
        var _a, _b;
        return ((_a = a.sortnumvalue) !== null && _a !== void 0 ? _a : 0) - ((_b = b.sortnumvalue) !== null && _b !== void 0 ? _b : 0);
    }
    setBuildingView(building) {
        this.buildingview = building;
    }
    clear() {
        this.timelinecards.clear();
        this.setSelectedId("");
        this.buildcount = 0;
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], TimelineState.prototype, "timelinecards", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineState.prototype, "buildingview", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineState.prototype, "selectedcard", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], TimelineState.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], TimelineState.prototype, "height", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineState.prototype, "buildcount", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "setHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "setTimelineCard", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "clearItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "setSelectedId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "updateElement", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "setBuildingView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineState.prototype, "clear", null);
