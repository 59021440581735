export class LoginDevice {
    constructor(password) {
        this.password = password;
    }
}
export var LoginDeviceUpdateState;
(function (LoginDeviceUpdateState) {
    LoginDeviceUpdateState[LoginDeviceUpdateState["ValidatingPin"] = 0] = "ValidatingPin";
    LoginDeviceUpdateState[LoginDeviceUpdateState["ValidatingDevice"] = 1] = "ValidatingDevice";
    LoginDeviceUpdateState[LoginDeviceUpdateState["InvalidDevice"] = 2] = "InvalidDevice";
    LoginDeviceUpdateState[LoginDeviceUpdateState["InvalidPin"] = 3] = "InvalidPin";
    LoginDeviceUpdateState[LoginDeviceUpdateState["Success"] = 4] = "Success";
    LoginDeviceUpdateState[LoginDeviceUpdateState["Bootstrapping"] = 5] = "Bootstrapping";
})(LoginDeviceUpdateState || (LoginDeviceUpdateState = {}));
export class LoginDeviceUpdate {
    constructor(state, errormessage = "") {
        this.state = state;
        this.errormessage = errormessage;
    }
}
export class LoginDeviceRequest {
    constructor(password, masterpassword, sessionid = "") {
        this.password = password;
        this.masterpassword = masterpassword;
        this.sessionid = sessionid;
    }
}
