var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ProjectObjectType } from "@@/Project";
import { Bookmarkstatus } from "@@/File";
import { ChartType, ChartViewState } from "./ChartViewState";
import { ReminderUnit, RuleType, SchemaItemType, TriggerType, ViewType, convertBoxToSchema, convertBoxToSheet, UpdateType } from "@@/Schema";
import { JobStatus, JobType, PWaDb } from "./PWAIndexDb";
import { WorkerMessageType } from "./PwaModel";
import { SearchMetadataOp } from "@@/ModuleJob";
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { canShowRemoteEntry, checkRowSatifyCond, convertDepToRows, decryptCell, parser, sortEntriesByRow, textRuleParser } from "@@/RuleParser";
import { stripHTML as LWStripHTML, stripHTML, timeout } from "@@/Util";
import { CONVERTUTIL } from "@/background/store/ConvertUtil";
import { DateLib } from "@@/Datelib";
import { DocumentInfoStore } from "./DocumentInfoStore";
import { EncryptBox } from "@@/EncryptBox";
import { FilterDialogState } from "../UIComponents/FilterDialogState";
import { decryptCell as GDecryptcell } from "@@/RuleParser";
import { JobProcessor } from "@/serviceworker/JobProcessor";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { OrganisationMenuStore } from "./OrgsniationMenuStore";
import { ProcessMenuStore } from "./ProcessMenuState";
import { ProjectStore } from "@/background/store/ProjectStore";
import { PwaEditStore } from "./PwaEditStore";
import { PwaPdfPageStore } from "../UIComponents/PwaPdfPageStore";
import React from "react";
import { SchemaUIRow } from "./SchemaUiRow";
import { ServerPath } from "@@/ServerPath";
import { SortDialogState } from "../UIComponents/SortDialogState";
import { UserMenuStore } from "./UserMenuStore";
import { comm } from "@/Comm/comm";
import { toast } from 'react-toastify';
import versionText from '@/appversion.txt';
import { SheetChooserStore } from "./SheetChooserStore";
import { SimpleSchemaUIRow } from "./SimpleSchemaUIRow";
import { OEM } from 'tesseract.js';
import Tesseract from "tesseract.js";
export var PwaAuthState;
(function (PwaAuthState) {
    PwaAuthState[PwaAuthState["Loggedin"] = 0] = "Loggedin";
    PwaAuthState[PwaAuthState["Loggedout"] = 1] = "Loggedout";
    PwaAuthState[PwaAuthState["AppError"] = 2] = "AppError";
})(PwaAuthState || (PwaAuthState = {}));
export class PwaStore {
    constructor() {
        this.viewscolorcode = [[
                "#5e75e845", "black",
            ], ["#bdcbf878", "black"],
            ["#e85eae45", "black"]];
        this.loadingrows = false;
        this.formkey = "form_";
        this.loadingrowsmessage = "";
        this.chartstate = new Map();
        this.pwaauthstate = PwaAuthState.Loggedout;
        this.formmessage = "";
        this.sessionname = "";
        this.jobupdate = false;
        this.buildingwebform = false;
        this.statusmessage = "";
        this.showdetail = false;
        this.addnewviewentry = false;
        this.addnewviewentryviaai = false;
        this.fetchingupdates = false;
        this.activeview = ViewType.Grid;
        this.displayjs = "";
        this.showaicreate = false;
        this.webfilterjs = "";
        this.aicontent = "";
        this.custompaneljs = "";
        this.notificationregistered = false;
        this.keepbuttonwithcustomview = false;
        this.customSorter = undefined;
        this.smeatda = [];
        this.quicksearchactive = false;
        this.rowreadonly = new Map();
        this.rowpermissions = new Map();
        this.ocrworker = undefined;
        this.colors = ['#eb4438', '#7869b5', '#33a851'];
        this.lastitemid = "";
        this.appupdating = false;
        this.online = true;
        this.chartreload = false;
        this.onesignalinitstage = 0;
        this.activeversion = versionText;
        this.depschemarows = new Map();
        this.selectedorgid = "";
        this.selectedrowids = new Set();
        this.selecteditemid = "";
        this.currentpermissions = new Set();
        this.loadingcontents = false;
        this.loadingitem = false;
        this.loadingerror = false;
        this.showinfopanelshow = false;
        this.parsingentry = new Set();
        this.aimessages = "";
        this.schemaItemDefaults = new Map();
        this.procesisng = false;
        this.activeforms = new Map();
        this.activeviews = new Map();
        this.viewsearchtext = "";
        this.columnwidths = new Map();
        this.orgmenustore = new OrganisationMenuStore();
        this.usermenu = new UserMenuStore();
        this.processmenu = new ProcessMenuStore();
        this.currentitems = [];
        this.readonlyitems = new Set();
        this.formentry = new Map();
        this.objectselectedfiles = new Map();
        this.linkforms = new Map();
        this.listitems = [];
        this.newlistitemsmap = new Map();
        this.ailistitems = new Map();
        this.activewebform = undefined;
        this.activeviewtitle = "";
        this.activeiswebform = false;
        this.currentroute = undefined;
        this.dependentdata = new Map();
        this.alljobs = new Map();
        this.schemasmap = new Map();
        this.rowsMap = new Map();
        this.rowsarrids = [];
        this.uirows = new Map();
        this.lastloadtime = 0;
        this.rtdependentdata = new Map();
        this.zindex = 10;
        this.rowRules = new Map();
        this.storingform = false;
        this.checkingandcollecting = false;
        this.editselectedfiles = new Map();
        this.lastorgchecktime = 0;
        this.currentsession = "";
        this.currentcode = "";
        this.newrows = [];
        this.showrows = true;
        this.addrows = 0;
        this.deleteingrows = false;
        this.pwaeditstore = new PwaEditStore();
        this.showreport = false;
        this.activeschemametadatamap = new Set();
        this.filterdialogstate = new FilterDialogState();
        this.showrowsilter = false;
        this.hiddenrows = new Set();
        this.schemaviews = new Map();
        this.activeusers = new Map();
        this.sortdialogstate = new SortDialogState();
        this.showssortdialog = false;
        this.showScrollToButton = false;
        this.tableref = null;
        this.panelref = null;
        this.cardview = false;
        this.showcolumnview = false;
        this.cfx = 0;
        this.cfy = 0;
        this.hiddencolumns = new Set();
        this.zoomfactor = 1;
        this.pagestate = new Map();
        this.activeitemid = ""; // this is the item showing in right panel
        this.vieweractive = false;
        this.title = "";
        this.orgname = "";
        this.activeDocumentId = "";
        this.loading = false;
        this.show = false;
        this.activefileid = "";
        this.activefilekey = "";
        this.ref = null;
        this.pagedetails = [];
        this.activepagenumber = 0;
        this.filemetamap = new Map();
        this.filemetakeys = new Map();
        this.activedocumentreference = "";
        this.activerowid = "";
        this.activeaccesskey = "";
        this.activecellid = "";
        this.activeentryid = "";
        this.loadingfinddict = false;
        this.initdocumentmap = new Map();
        this.gettingbookmarks = false;
        this.showwatermakr = false;
        this.watermakr = "";
        this.virtref = React.createRef();
        this.jumpingtopage = false;
        this.docmap = new Map();
        this.showdocumentinfo = false;
        this.documentinfo = new DocumentInfoStore();
        this.isViewable = false;
        this.queued = false;
        this.isexcel = false;
        this.triggersbyCols = new Map();
        this.triggerrowids = new Set();
        this.searchactive = false;
        this.customcolshareddataforrows = new Map();
        this.ignorerebuildcustomcomponent = new Set();
        this.rebuildingcustom = false;
        this.cacheui = new Map();
        this.activedetailtab = "detail";
        this.customrowjs = "";
        this.forcerebuildcustom = new Set();
        this.newrowindex = "";
        this.sheetchooser = new SheetChooserStore();
        this.twopanelview = false;
        this.activedisplayview = "grid";
        this.creatingworker = false;
        this.savingjobs = false;
        makeObservable(this);
        // do it after one minute so that everything else get done!!
        this.appversioncheck = setTimeout(() => this.checkAppVersionAndUpdate(), 60000);
        window.addEventListener("online", () => this.setInternetStatus(true));
        window.addEventListener("offline", () => this.setInternetStatus(false));
    }
    async init() {
        if (!navigator.serviceWorker) {
            console.log("Service worker not supported");
            return;
        }
        const reg = await navigator.serviceWorker.ready;
        navigator.serviceWorker.addEventListener("message", (msg) => {
            try {
                if (msg.data && msg.data.data !== undefined && msg.data.type !== undefined) {
                    const workermessge = msg.data;
                    if (workermessge.type === WorkerMessageType.Data) {
                        const dd = JSON.parse(workermessge.data);
                        this.updateLocalDataMap(dd.id);
                    }
                }
            }
            catch (ex) { }
        });
    }
    setActiveTab(tab) {
        this.activedetailtab = tab;
    }
    setActiveDisplayView(view) {
        this.activedisplayview = view;
        if (this.selecteditemid) {
            localStorage.setItem(this.selecteditemid + "_view", view);
        }
    }
    setShowAiCreate(show) {
        this.showaicreate = show;
    }
    getAllFields() {
        const achema = this.getActiveSchema();
        if (!achema) {
            return [];
        }
        let sitems = [];
        if (achema.parentschema) {
            sitems.push(...achema.parentschema.items.slice().sort((it1, it2) => it1.orderindex - it2.orderindex).map(f => (Object.assign(Object.assign({}, f), { isparent: true }))));
        }
        const myitems = achema.items.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        for (const citem of myitems) {
            if (citem.type === SchemaItemType.List) {
                sitems.push(citem);
                for (const lit of citem.items) {
                    sitems.push(Object.assign(Object.assign({}, lit.item), { id: citem.id + "----" + lit.id, name: citem.name + "!" + lit.item.name }));
                }
            }
            else {
                sitems.push(citem);
            }
        }
        return sitems;
    }
    async addSchemaStateData(req) {
        const res = await comm.post(ServerPath.addSchemaState, req);
        return res;
    }
    async getSchemaStateData(req) {
        const res = await comm.post(ServerPath.pwagetSchemaState, req);
        return res;
    }
    buildNewRowFromNewEntries() {
        const srowui = {
            id: '',
            rowid: this.newrowindex,
            rowindex: -1,
            entry: [],
        };
        const newnes = [];
        for (const [_, en] of this.formentry) {
            newnes.push(toJS(Object.assign(Object.assign({}, en), { decrypted: true })));
        }
        srowui.entry = newnes;
        return srowui;
    }
    buildNewRowFromEdits() {
        if (!this.pwaeditstore.activerowid)
            return undefined;
        const row = this.uirows.get(this.pwaeditstore.activerowid);
        if (!row)
            return undefined;
        const srowui = Object.assign({}, row);
        const newnes = [];
        const donekeys = new Set();
        for (const en of srowui.entry) {
            let id = en.cellid;
            if (en.subrowindex && en.subrowindex.indexOf("----") !== -1) {
                id += "----" + en.subrowindex;
            }
            const een = this.pwaeditstore.editrowproperty.get(id);
            if (een) {
                donekeys.add(id);
                newnes.push(toJS(Object.assign(Object.assign({}, een), { decrypted: true })));
            }
            else {
                newnes.push(toJS(Object.assign(Object.assign({}, en), { decrypted: true })));
            }
        }
        for (const k of this.pwaeditstore.editrowproperty.values()) {
            let id = k.cellid;
            if (k.subrowindex && k.subrowindex.indexOf("----") !== -1) {
                id += "----" + k.subrowindex;
            }
            if (!donekeys.has(id)) {
                newnes.push(toJS(Object.assign(Object.assign({}, k), { decrypted: true })));
            }
        }
        srowui.entry = newnes;
        return srowui;
    }
    buildNeListwRowFromEdits(subrowindex) {
        if (!this.pwaeditstore.activerowid)
            return undefined;
        const row = this.rowsMap.get(this.pwaeditstore.activerowid);
        if (!row)
            return undefined;
        const srowui = Object.assign({}, row);
        const newnes = [];
        for (const en of srowui.entry) {
            const listen = this.pwaeditstore.editrowproperty.get(en.cellid + "----" + subrowindex);
            if (listen) {
                newnes.push(listen);
            }
            else {
                newnes.push(en);
            }
        }
        srowui.entry = newnes;
        return srowui;
    }
    setSearchActive(show) {
        this.searchactive = show;
    }
    setTriggerRowIds(rowid, add) {
        if (add) {
            this.triggerrowids.add(rowid);
        }
        else {
            this.triggerrowids.delete(rowid);
        }
    }
    checkRowEnnrtyNeedAlert(schemarow, colid) {
        var _a, _b;
        let aschema = undefined;
        let wf = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (wfv1) {
                aschema = wfv1.shopen;
            }
        }
        else {
            wf = this.activeforms.get(this.selecteditemid);
            if (wf) {
                aschema = wf.sh;
            }
        }
        if (!aschema)
            return false;
        if (this.triggersbyCols.size === 0 || !this.triggersbyCols.has(colid))
            return false;
        const coltgs = Array.from((_b = (_a = this.triggersbyCols.get(colid)) === null || _a === void 0 ? void 0 : _a.values()) !== null && _b !== void 0 ? _b : []);
        if (coltgs.length === 0)
            return false;
        for (const en of schemarow.entry) {
            if (en.cellid === colid) {
                for (const tg of coltgs) {
                    const rule = {
                        type: RuleType.Add,
                        conds: tg.conds,
                        params: [],
                    };
                    let validrows = !tg.conds || tg.conds.length == 0 || checkRowSatifyCond(rule, schemarow, en, aschema, aschema);
                    if (validrows) {
                        try {
                            const myval = parseFloat(en.celldata);
                            const thre = parseFloat(tg.comparevalue);
                            let diff = 0;
                            const time = new Date().getTime();
                            if (!isNaN(myval)) {
                                if (tg.triggertype === TriggerType.Equal) {
                                    if (myval === thre) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.triggertype === TriggerType.Greater) {
                                    if (myval > thre) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.triggertype === TriggerType.GreaterEqual) {
                                    if (myval >= thre) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.triggertype === TriggerType.Less) {
                                    if (myval < thre) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.triggertype === TriggerType.LessEqual) {
                                    if (myval <= thre) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.reminderunit === ReminderUnit.days) {
                                    diff = parseInt(tg.comparevalue) * (24 * 60 * 60 * 1000);
                                    const rev = myval - diff;
                                    if (rev > 0 && rev <= time) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.reminderunit === ReminderUnit.hr) {
                                    diff = parseInt(tg.comparevalue) * 60 * 60 * 1000;
                                    const rev = myval - diff;
                                    if (rev > 0 && rev <= time) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.reminderunit === ReminderUnit.min) {
                                    diff = parseInt(tg.comparevalue) * (60 * 1000);
                                    const rev = myval - diff;
                                    if (rev > 0 && rev <= time) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                                else if (tg.reminderunit === ReminderUnit.missed) {
                                    diff = -(60 * 1000);
                                    const rev = myval - diff;
                                    if (rev > 0 && rev <= time) {
                                        this.setTriggerRowIds(schemarow.rowid, true);
                                        return true;
                                    }
                                }
                            }
                        }
                        catch (ex) { }
                    }
                }
            }
        }
        this.setTriggerRowIds(schemarow.rowid, false);
        return false;
    }
    setViewable(vable) {
        this.isViewable = vable;
    }
    setQueued(sum) {
        this.queued = sum;
    }
    setIsExcel(excel) {
        this.isexcel = excel;
    }
    showDocumentInfo(show) {
        this.showdocumentinfo = show;
    }
    enableWatermark(enable) {
        this.showwatermakr = enable;
    }
    setWatermark(mark) {
        this.watermakr = mark;
    }
    /*Minipdfviewr*/
    setPdfPageDetails(details) {
        this.pagedetails = details;
        details.forEach((det) => {
            if (det.words !== undefined && det.words.length > 0) {
                PwaPdfPageStore.pages.set(det.filename + "-words", JSON.stringify(det.words));
            }
        });
    }
    setActiveFileKey(key) {
        this.activefilekey = key;
    }
    setActiveFileId(fid) {
        this.activefileid = fid;
    }
    resetPdfPage() {
        this.setShow(false);
        this.setActiveFileId("");
    }
    setFromKey(key) {
        this.formkey = key;
    }
    setLoadingFindDict(loading) {
        this.loadingfinddict = loading;
    }
    setGettingBookmarks(loading) {
        this.gettingbookmarks = loading;
    }
    getPdfInfo() {
        // const req: PdfFileMetadataRequest = {
        //     id: this.activefileid,
        //     accesskey: this.activefilekey
        // };
        // Apphandler.sendMessage("pdffilemeta", req);
    }
    async loadFindDicts(cb) {
        this.setLoadingFindDict(true);
        const mykey = this.filemetakeys.get(this.activedocumentreference);
        if (!mykey) {
            return;
        }
        if (this.info === undefined) {
            return;
        }
        const ret = await this.getPdfViewerContent(this.activedocumentreference, 'finddict', this.activerowid, this.getActiveFormUserId(), this.activeaccesskey, this.activecellid, this.activeentryid, this.activeDocumentId, "", mykey);
        this.setLoadingFindDict(false);
        if (ret !== undefined) {
            const dict = LetrwingCrypto.fromBase64ToUTF8(ret);
            const fdict = JSON.parse(dict);
            cb(fdict);
        }
    }
    async checkAndGetBookmarks(cb) {
        this.setGettingBookmarks(true);
        const mykey = this.filemetakeys.get(this.activedocumentreference);
        if (!mykey) {
            return;
        }
        if (this.info === undefined) {
            return;
        }
        const result = await this.getPdfViewerContent(this.activedocumentreference, 'bookmarks', this.activerowid, this.getActiveFormUserId(), this.activeaccesskey, this.activecellid, this.activeentryid, this.activeDocumentId, "", mykey);
        const ret = [];
        if (result !== undefined) {
            try {
                const bms = JSON.parse(result);
                ret.push(...bms);
            }
            catch (ex) {
            }
        }
        const req = {
            name: "", fileid: this.activedocumentreference, rowid: this.activerowid, dataid: this.getActiveFormUserId(), cellid: this.activecellid,
            entryid: this.activeentryid, objectid: this.activeDocumentId
        };
        const res = await comm.postWithNetwork(ServerPath.pwaGetBookmark, req);
        if (res && res !== 'NetworkError' && res !== 'Unauthorised') {
            for (const bb of res) {
                if (bb.status === Bookmarkstatus.Dead) {
                    continue;
                }
                const rbook = LetrwingCrypto.decryptSecretBox(bb.bookmark, mykey);
                if (rbook) {
                    try {
                        const resultbook = JSON.parse(rbook);
                        resultbook.id = bb.id;
                        resultbook.custom = true;
                        ret.push(resultbook);
                    }
                    catch (Ex) {
                    }
                }
            }
        }
        cb(ret);
    }
    async loadWords(page, cb) {
        const deet = this.pagedetails[page];
        if (!deet) {
            return;
        }
        const mykey = this.filemetakeys.get(this.activedocumentreference);
        if (!mykey) {
            return;
        }
        if (this.info === undefined) {
            return;
        }
        const ret = await this.getPdfViewerContent(this.activedocumentreference, deet.filename + "-words", this.activerowid, this.getActiveFormUserId(), this.activeaccesskey, this.activecellid, this.activeentryid, this.activeDocumentId, "", mykey);
        let pagestr = "";
        if (ret !== undefined) {
            pagestr = LetrwingCrypto.fromBase64ToUTF8(ret);
        }
        cb(pagestr);
    }
    async fetchMetadata(cb) {
        if (this.info === undefined) {
            return;
        }
        const metadata = await this.getFileMeta(this.activedocumentreference, "", this.activerowid, this.getActiveFormUserId(), this.activeaccesskey, "", this.activeentryid, this.activeDocumentId);
        cb(this.filemetamap.get(this.activedocumentreference));
    }
    async getPdfPage(page, cb) {
        if (this.pagedetails.length < page) {
            return;
        }
        const deet = this.pagedetails[page];
        if (!deet) {
            return;
        }
        const mykey = this.filemetakeys.get(this.activedocumentreference);
        if (!mykey) {
            return;
        }
        if (this.info === undefined) {
            return;
        }
        const ret = await this.getPdfViewerContent(this.activedocumentreference, deet.filename, this.activerowid, this.getActiveFormUserId(), this.activeaccesskey, this.activecellid, this.activeentryid, this.activeDocumentId, "", mykey);
        if (ret !== undefined) {
            cb(ret);
        }
        // if (this.pagedetails.length < page) {
        //     return;
        // }
        // const deet = this.pagedetails[page];
        // if(!deet) {
        //     return;
        // }
        // const req: PdfPageRequest = {
        //     id: this.activefileid,
        //     pagename: deet.filename,
        //     pagenumber: page,
        //     accesskey: this.activefilekey
        // };
        // Apphandler.sendMessage("pdfpage", req);
        // if (!PdfPageStore.pages.has(deet.filename + "-words")) {
        //     const req: PdfPageRequest = {
        //         id: this.activefileid,
        //         pagename: deet.filename + "-words",
        //         pagenumber: page,
        //         accesskey: this.activefilekey
        //     };
        //     deet.wordloaded = true;
        //     Apphandler.sendMessage("pdfpage", req);
        // }
    }
    getPdfPageDetail(num) {
        if (this.pagedetails.length > num) {
            return this.pagedetails[num];
        }
        return undefined;
    }
    clear() {
        this.pagedetails = [];
    }
    setShow(show) {
        var _a;
        this.show = show;
        if (!this.show) {
            (_a = this.onclosecb) === null || _a === void 0 ? void 0 : _a.call(this);
        }
    }
    /***** */
    setLoading(loading) {
        this.loading = loading;
    }
    setActiveItemid(id, rowid, entryid, route = true) {
        this.activeitemid = id;
        if (id && route) {
            this.addDocumentToHash(id, rowid, entryid);
        }
    }
    setViewerActive(active) {
        this.vieweractive = active;
    }
    setTitle(title) {
        this.title = title;
    }
    hideColumn(id) {
        this.hiddencolumns.add(id);
        if (id.indexOf("----") != -1) {
            const parent = id.split("----")[0];
            let wf = undefined;
            let aschema = undefined;
            if (this.selecteditemid.startsWith("web-")) {
                const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
                if (wfv1) {
                    aschema = wfv1.shopen;
                }
            }
            else {
                wf = this.activeforms.get(this.selecteditemid);
                if (wf) {
                    aschema = wf.sh;
                }
            }
            if (aschema) {
                const its = aschema.items.filter((it) => it.id === parent);
                if (its.length > 0) {
                    const aitem = its[0].items;
                    let hasallhid = true;
                    for (const ai of aitem) {
                        if (!this.hiddencolumns.has(parent + "----" + ai.item.id)) {
                            hasallhid = false;
                            break;
                        }
                    }
                    if (hasallhid) {
                        this.hiddencolumns.add(parent);
                    }
                }
            }
        }
    }
    setActiveDocumentId(id) {
        this.activeDocumentId = id;
    }
    showColumn(id) {
        this.hiddencolumns.delete(id);
        if (id.indexOf("----") != -1) {
            this.hiddencolumns.delete(id.split("----")[0]);
        }
    }
    setShowColumns(cols) {
        this.showcolumnview = cols;
    }
    clearSelectedRowids() {
        this.selectedrowids.clear();
    }
    addSelectedRowid(id) {
        this.selectedrowids.add(id);
    }
    removeSelectedRowid(id) {
        this.selectedrowids.delete(id);
    }
    setCustompaneljs(js) {
        this.custompaneljs = js;
    }
    setDisplayJS(js) {
        this.displayjs = js;
    }
    setWebFilterJS(js) {
        this.webfilterjs = js;
    }
    setKeepButtonsWithCustomView(js) {
        this.keepbuttonwithcustomview = js;
    }
    selectAllRow() {
        this.rowsMap.forEach((rm) => this.pwaeditstore.selectedrows.add(rm.rowid));
        this.pwaeditstore.selectallrows = true;
    }
    unselectAllRow() {
        this.pwaeditstore.selectedrows.clear();
        this.pwaeditstore.selectallrows = false;
    }
    setCardView(cardview) {
        localStorage.setItem("cardview", cardview ? '1' : '0');
        this.cardview = cardview;
        localStorage.setItem("twopanel", '0');
        this.twopanelview = false;
    }
    setTwoPanelView(view) {
        localStorage.setItem("twopanel", view ? '1' : '0');
        this.twopanelview = view;
    }
    setSearchText(text) {
        this.viewsearchtext = text;
    }
    setShowScrollToButton(show) {
        this.showScrollToButton = show;
    }
    async convertRowResToEntry(res, sid, key, nonce) {
        const ret = [];
        const variables = res.variables;
        for (let i = 0; i < res.data.length; i++) {
            if (i > 0 && i % 5000 === 0)
                await timeout(10);
            const dst = res.data[i];
            let rowindex = 0;
            let id = "";
            let rowid = "";
            let cellid = "";
            let celldata = "";
            let updatetime = 0;
            let updatedby = "";
            let subrowindex = "";
            let childrenschemalist = [];
            let readonlyschemalist = [];
            let linkedids = [];
            let referenceids = [];
            let objs = [];
            let shs = [];
            let msid = sid;
            let bc = '';
            for (let j = 0; j < dst.length; j++) {
                const va = variables[j];
                const value = dst[j];
                if (va === "rowindex") {
                    rowindex = parseFloat(value);
                }
                else if (va === "id") {
                    id = value;
                }
                else if (va === "rowid") {
                    rowid = value;
                }
                else if (va === "cellid") {
                    cellid = value;
                }
                else if (va === "celldata") {
                    celldata = value;
                }
                else if (va === "childrenschemalist") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        childrenschemalist = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "readonlyschemalist") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        readonlyschemalist = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "updatetime") {
                    updatetime = parseFloat(value);
                }
                else if (va === "updatedby") {
                    updatedby = value;
                }
                else if (va === "linkedids") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const vany = JSON.parse(value);
                        const ds = vany;
                        linkedids = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (va === "referenceids") {
                    if (!value || value.length == 0)
                        continue;
                    try {
                        const ds = JSON.parse(value);
                        referenceids = ds !== null && ds !== void 0 ? ds : [];
                    }
                    catch (ex) { }
                }
                else if (va === "subrowindex") {
                    subrowindex = value;
                }
                else if (va === "objs") {
                    if (value && value.length > 0) {
                        try {
                            const ds = JSON.parse(value);
                            objs = ds !== null && ds !== void 0 ? ds : [];
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }
                }
                else if (va === "shs") {
                    if (value && value.length > 0) {
                        try {
                            const ds = JSON.parse(value);
                            shs = ds !== null && ds !== void 0 ? ds : [];
                        }
                        catch (ex) { }
                    }
                }
                else if (va === "rows") {
                }
                else if (va === 'sid') {
                    msid = value;
                }
                else if (va === 'rowbarcode') {
                    bc = value;
                }
            }
            if (bc && bc.startsWith("crypt-") && key && nonce) {
                bc = bc.substring("crypt-".length);
                const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: bc, nonce: '', publickey: '' }, key, nonce);
                bc = val !== null && val !== void 0 ? val : '';
            }
            const en = {
                id: id,
                subrowindex: subrowindex,
                linkedids: linkedids,
                objs: objs,
                shs: shs,
                cellid: cellid,
                celldata: celldata,
                rowid: rowid,
                rowindex: rowindex,
                referenceids: referenceids,
                childrenschemalist: childrenschemalist,
                schemaid: msid,
                cellfindstr: celldata,
                sortstring: -1,
                rowbarcode: bc,
                updatetime: updatetime,
                updatedby: updatedby
            };
            ret.push(en);
        }
        return ret;
    }
    canshowEntryFromLM(entries, fil) {
        let lm = 0;
        for (const en of entries) {
            if (en.updatetime && en.updatetime > lm) {
                lm = en.updatetime;
            }
        }
        if (fil.optype === SearchMetadataOp.Equal) {
            const fdate = new Date(parseInt(fil.value));
            const ldate = new Date(lm);
            fdate.setSeconds(0);
            fdate.setMilliseconds(0);
            ldate.setSeconds(0);
            ldate.setMilliseconds(0);
            if (ldate.getTime() !== fdate.getTime()) {
                return false;
            }
        }
        else if (fil.optype === SearchMetadataOp.GreateEqual ||
            fil.optype === SearchMetadataOp.Greater ||
            fil.optype === SearchMetadataOp.Less ||
            fil.optype === SearchMetadataOp.LessEqual) {
            try {
                const fdate = new Date(parseInt(fil.value));
                const ldate = new Date(lm);
                fdate.setSeconds(0);
                fdate.setMilliseconds(0);
                ldate.setSeconds(0);
                ldate.setMilliseconds(0);
                const v1 = ldate.getTime();
                const v2 = fdate.getTime();
                if (fil.optype === SearchMetadataOp.GreateEqual && v1 < v2) {
                    return false;
                }
                else if (fil.optype === SearchMetadataOp.Greater && v1 <= v2) {
                    return false;
                }
                else if (fil.optype === SearchMetadataOp.Less && v1 >= v2) {
                    return false;
                }
                else if (fil.optype === SearchMetadataOp.LessEqual && v1 > v2) {
                    return false;
                }
            }
            catch (ex) {
                return false;
            }
        }
        else if (fil.optype === SearchMetadataOp.InBetween) {
            try {
                const fdate = new Date(parseInt(fil.value));
                const fdate2 = new Date(parseInt(fil.value2));
                const ldate = new Date(lm);
                fdate.setSeconds(0);
                fdate.setMilliseconds(0);
                ldate.setSeconds(0);
                ldate.setMilliseconds(0);
                fdate2.setSeconds(0);
                fdate2.setMilliseconds(0);
                const v1 = ldate.getTime();
                const v2 = fdate.getTime();
                const v3 = fdate2.getTime();
                if (v1 >= v2 && v1 <= v3) {
                    // just leave it!!
                }
                else {
                    return false;
                }
            }
            catch (ex) {
                return false;
            }
        }
        return true;
    }
    canShowRow(entries, filter) {
        var _a, _b, _c, _d, _e;
        const enmap = new Map();
        for (const en of entries) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
        }
        let sh = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (wfv1 === undefined) {
                return false;
            }
            sh = wfv1.shopen;
        }
        else {
            const wf = this.activeforms.get(this.selecteditemid);
            if (wf === undefined) {
                return false;
            }
            sh = wf.sh;
        }
        if (sh === undefined) {
            return false;
        }
        for (const fil of filter) {
            if (fil.id === "lastmodifief") {
                if (!this.canshowEntryFromLM(entries, fil)) {
                    return false;
                }
                continue;
            }
            const ens = enmap.get(fil.id);
            if (!ens || ens.length === 0) {
                return false;
            }
            let nomatch = true;
            entryloop: for (const en of ens) {
                if (!en.decrypted) {
                    if (en.ruleopen) {
                        this.parseCellRules(en, sh);
                    }
                    else {
                        this.decryptCell(en, sh);
                    }
                }
                if (fil.fielditem.type === SchemaItemType.FormInput || fil.fielditem.type === SchemaItemType.URL) {
                    if (en.celldata.toLowerCase().indexOf(fil.value.toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.Text) {
                    const est = (_b = LWStripHTML(en.celldata)) !== null && _b !== void 0 ? _b : '';
                    const cval = (_c = LWStripHTML(fil.value)) !== null && _c !== void 0 ? _c : '';
                    if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.ID) {
                    const est = (_d = (en.celldata)) !== null && _d !== void 0 ? _d : '';
                    let cval = (_e = (fil.value)) !== null && _e !== void 0 ? _e : '';
                    cval = cval.replace('-', '');
                    if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.SquenceNumber) {
                    const est = en.celldata;
                    let cval = fil.value;
                    if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if ((fil.fielditem.type === SchemaItemType.Choice) ||
                    (fil.fielditem.type === SchemaItemType.UserChoice)) {
                    let vals = fil.value.split(",");
                    let carr = en.celldata.split(",");
                    if (!fil.fielditem.multiselect) {
                        carr.splice(1, carr.length);
                    }
                    const fv = vals.filter(val => carr.includes(val));
                    if (fv.length === 0) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.Remote) {
                    let vals = fil.value.split(",");
                    let carr = en.celldata.split(",");
                    const fv = vals.filter(val => carr.includes(val));
                    if (fv.length === 0) {
                        continue entryloop;
                    }
                }
                else if (fil.optype === SearchMetadataOp.Equal) {
                    if (en.celldata.toLowerCase() !== fil.value.toLowerCase()) {
                        continue entryloop;
                    }
                }
                else if (fil.optype === SearchMetadataOp.GreateEqual ||
                    fil.optype === SearchMetadataOp.Greater ||
                    fil.optype === SearchMetadataOp.Less ||
                    fil.optype === SearchMetadataOp.LessEqual) {
                    try {
                        const v1 = parseFloat(en.celldata);
                        const v2 = parseFloat(fil.value);
                        if (fil.optype === SearchMetadataOp.GreateEqual && v1 < v2) {
                            continue entryloop;
                        }
                        else if (fil.optype === SearchMetadataOp.Greater && v1 <= v2) {
                            continue entryloop;
                        }
                        else if (fil.optype === SearchMetadataOp.Less && v1 >= v2) {
                            continue entryloop;
                        }
                        else if (fil.optype === SearchMetadataOp.LessEqual && v1 > v2) {
                            continue entryloop;
                        }
                    }
                    catch (ex) {
                        continue entryloop;
                    }
                }
                else if (fil.optype === SearchMetadataOp.InBetween) {
                    try {
                        const v1 = parseFloat(en.celldata);
                        const v2 = parseFloat(fil.value);
                        const v3 = parseFloat(fil.value2);
                        if (v1 >= v2 && v1 <= v3) {
                            // just leave it!!
                        }
                        else {
                            continue entryloop;
                        }
                    }
                    catch (ex) {
                        continue entryloop;
                    }
                }
                nomatch = false;
                break;
            }
            // if we find a no
            if (nomatch) {
                return false;
            }
        }
        return true;
    }
    searchCanShowRow(entries, filter) {
        var _a, _b, _c, _d, _e;
        const enmap = new Map();
        for (const en of entries) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
        }
        let sh = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (wfv1 === undefined) {
                return false;
            }
            sh = wfv1.shopen;
        }
        else {
            const wf = this.activeforms.get(this.selecteditemid);
            if (wf === undefined) {
                return false;
            }
            sh = wf.sh;
        }
        if (sh === undefined) {
            return false;
        }
        for (const fil of filter) {
            const ens = enmap.get(fil.id);
            if (!ens || ens.length === 0) {
                continue;
            }
            let nomatch = true;
            entryloop: for (const en of ens) {
                if (!en.decrypted) {
                    if (en.ruleopen) {
                        this.parseCellRules(en, sh);
                    }
                    else {
                        this.decryptCell(en, sh);
                    }
                }
                if (fil.fielditem.type === SchemaItemType.FormInput || fil.fielditem.type === SchemaItemType.URL) {
                    if (en.celldata.toLowerCase().indexOf(fil.value.toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.Text) {
                    const est = (_b = LWStripHTML(en.celldata)) !== null && _b !== void 0 ? _b : '';
                    let cval = (_c = LWStripHTML(fil.value)) !== null && _c !== void 0 ? _c : '';
                    cval = cval.replace('-', '');
                    if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.ID) {
                    const est = (_d = (en.celldata)) !== null && _d !== void 0 ? _d : '';
                    let cval = (_e = (fil.value)) !== null && _e !== void 0 ? _e : '';
                    cval = cval.replace('-', '');
                    if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.SquenceNumber) {
                    const est = en.celldata;
                    let cval = fil.value;
                    if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                        continue entryloop;
                    }
                }
                else if ((fil.fielditem.type === SchemaItemType.Choice) ||
                    (fil.fielditem.type === SchemaItemType.UserChoice)) {
                    let cval = fil.value;
                    let carr = en.celldata.split(",");
                    if (!fil.fielditem.multiselect) {
                        carr.splice(1, carr.length);
                    }
                    const chItems = fil.fielditem.items;
                    let nmatch = true;
                    for (const ch of chItems) {
                        if (carr.includes(ch.id) && ch.name.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) !== -1) {
                            nmatch = false;
                            break;
                        }
                    }
                    if (nmatch) {
                        continue entryloop;
                    }
                }
                else if (fil.fielditem.type === SchemaItemType.Remote) {
                    let cval = fil.value;
                    let carr = en.celldata.split(",");
                    const ri = fil.fielditem;
                    let riID = ri.id;
                    if (riID.indexOf("----") !== -1) {
                        const bits = riID.split("----");
                        if (bits.length > 1) {
                            riID = bits[1];
                        }
                    }
                    const items = this.dependentdata.get(riID + "---" + ri.sid + "---" + ri.cid);
                    let nmatch = true;
                    if (items !== undefined) {
                        for (const it of items) {
                            if (carr.includes(it.id) && it.value.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) !== -1) {
                                nmatch = false;
                                break;
                            }
                        }
                        let rowids = new Set();
                        for (const it of items) {
                            if (carr.includes(it.id)) {
                                if (it.rowid) {
                                    rowids.add(it.rowid);
                                }
                            }
                        }
                        if (nmatch && ri.rtextra && ri.rtextra.length > 0) {
                            const rtextra = ri.rtextra;
                            for (const ext of rtextra) {
                                let riID = ri.id;
                                if (riID.indexOf("----") !== -1) {
                                    const bits = riID.split("----");
                                    if (bits.length > 1) {
                                        riID = bits[1];
                                    }
                                }
                                const items = this.dependentdata.get(riID + "---" + ri.sid + "---" + ext.cid);
                                if (items !== undefined) {
                                    let nm = true;
                                    for (const it of items) {
                                        if (it.rowid && rowids.has(it.rowid)
                                            && it.value.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) !== -1) {
                                            nmatch = false;
                                            break;
                                        }
                                    }
                                    if (!nmatch) {
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (nmatch) {
                        continue entryloop;
                    }
                }
                else if (fil.optype === SearchMetadataOp.Equal) {
                    if (en.celldata.toLowerCase() !== fil.value.toLowerCase()) {
                        continue entryloop;
                    }
                }
                else if (fil.optype === SearchMetadataOp.GreateEqual ||
                    fil.optype === SearchMetadataOp.Greater ||
                    fil.optype === SearchMetadataOp.Less ||
                    fil.optype === SearchMetadataOp.LessEqual) {
                    try {
                        const v1 = parseFloat(en.celldata);
                        const v2 = parseFloat(fil.value);
                        if (fil.optype === SearchMetadataOp.GreateEqual && v1 < v2) {
                            continue entryloop;
                        }
                        else if (fil.optype === SearchMetadataOp.Greater && v1 <= v2) {
                            continue entryloop;
                        }
                        else if (fil.optype === SearchMetadataOp.Less && v1 >= v2) {
                            continue entryloop;
                        }
                        else if (fil.optype === SearchMetadataOp.LessEqual && v1 > v2) {
                            continue entryloop;
                        }
                    }
                    catch (ex) {
                        continue entryloop;
                    }
                }
                else if (fil.optype === SearchMetadataOp.InBetween) {
                    try {
                        const v1 = parseFloat(en.celldata);
                        const v2 = parseFloat(fil.value);
                        const v3 = parseFloat(fil.value2);
                        if (v1 >= v2 && v1 <= v3) {
                            // just leave it!!
                        }
                        else {
                            continue entryloop;
                        }
                    }
                    catch (ex) {
                        continue entryloop;
                    }
                }
                nomatch = false;
                break;
            }
            // if we find a no
            if (!nomatch) {
                return true;
            }
        }
        return false;
    }
    scanCanShowRow(entries, barcode) {
        var _a;
        let sh = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (wfv1 === undefined) {
                return false;
            }
            sh = wfv1.shopen;
        }
        else {
            const wf = this.activeforms.get(this.selecteditemid);
            if (wf === undefined) {
                return false;
            }
            sh = wf.sh;
        }
        if (sh === undefined) {
            return false;
        }
        for (const en of entries) {
            if (!en.decrypted) {
                this.decryptCell(en, sh);
            }
            if (en.rowbarcode && en.rowbarcode.startsWith("crypt-")) {
                en.rowbarcode = (_a = this.decryptBCWithKeyNonde(en.rowbarcode, sh.accesskey, sh.nonce)) !== null && _a !== void 0 ? _a : '';
            }
            if (en.rowbarcode !== undefined && en.rowbarcode === barcode) {
                return true;
            }
        }
        return false;
    }
    async slowFilterRows(search = false, openw = undefined, workingcb = undefined) {
        // lets check if we need rule or not!!
        let slow = false;
        const vids = Array.from(this.filterdialogstate.valuesbyid.values());
        if (vids && vids.length > 0) {
            for (const v of vids) {
                // check if v has rule
                if (this.checkSchemaCellHasRule(v.fielditem.id)) {
                    slow = true;
                    break;
                }
            }
        }
        let i = 0;
        if (slow) {
            openw === null || openw === void 0 ? void 0 : openw();
        }
        let totaltime = 0;
        for (const [_, row] of this.rowsMap) {
            if (slow && totaltime > (10 * 1000)) {
                if (workingcb) {
                    workingcb("Checked " + (i + 1) + "/" + this.rowsMap.size);
                }
                totaltime = 0;
                await timeout(0);
            }
            let st = 0;
            if (slow) {
                st = new Date().getTime();
            }
            this.filterRow(row, search);
            if (slow) {
                let en = new Date().getTime();
                totaltime += (en - st);
            }
            i++;
        }
    }
    filterRow(row, search = false) {
        const entries = row.entry;
        if (search) {
            if (!this.searchCanShowRow(entries, Array.from(this.filterdialogstate.valuesbyid.values()))) {
                this.hiddenrows.add(row.rowid);
            }
        }
        else {
            if (!this.canShowRow(entries, Array.from(this.filterdialogstate.valuesbyid.values()))) {
                this.hiddenrows.add(row.rowid);
            }
            else {
            }
        }
    }
    filterRows(search = false) {
        this.hiddenrows.clear();
        rowloop: for (const [_, row] of this.rowsMap) {
            this.filterRow(row, search);
        }
    }
    filterUsingScanner(code = "", rowid = "") {
        this.hiddenrows.clear();
        for (const [_, row] of this.rowsMap) {
            const entries = row.entry;
            if (rowid.trim() && row.rowid.trim() === rowid) {
                //do nothing
            }
            else if (!rowid && code && this.scanCanShowRow(entries, code)) {
                //do nothing
            }
            else {
                this.hiddenrows.add(row.rowid);
            }
        }
    }
    showAllRows() {
        this.hiddenrows.clear();
        this.selectFirstRow();
    }
    selectFirstRow() {
        const wv = this.getActiveWebview();
        if (wv) {
            let srows = Array.from(this.uirows.values()).filter((r) => !this.hiddenrows.has(r.rowid)).slice().filter((r) => !r.hidden).sort((r1, r2) => r1.rowindex - r2.rowindex)
                .map((i) => i.rowid);
            if (wv.reverseorder) {
                srows.reverse();
            }
            if (srows.length > 0) {
                this.pwaeditstore.setSelectedId(srows[0]);
            }
        }
    }
    setShowSortDialog(show) {
        this.showssortdialog = show;
    }
    setChartReload(rel) {
        this.chartreload = rel;
    }
    setShowRowFilter(show) {
        this.showrowsilter = show;
    }
    setLoadingRowsmessage(msg) {
        this.loadingrowsmessage = msg;
    }
    setAddNewViewEntry(st) {
        if (st !== this.addnewviewentry && st) {
            this.newrowindex = LetrwingCrypto.getUUID();
        }
        this.addnewviewentry = st;
        // we can add default values!
    }
    setAddNewViewEntryViaAi(st) {
        this.addnewviewentryviaai = st;
        this.aicontent = "";
        this.aimessages = "";
    }
    setAiMessages(msg) {
        this.aimessages = msg;
    }
    setAiContent(msg) {
        this.aicontent = msg;
    }
    clearCurrentPermissions() {
        this.currentpermissions.clear();
        this.rowpermissions.clear();
    }
    addPerm(per) {
        this.currentpermissions.add(per);
    }
    removePerm(per) {
        this.currentpermissions.delete(per);
    }
    setActiveSchemaMetadata(smd) {
        if (smd !== undefined) {
            this.activeschemametadata = smd;
        }
        else {
            this.activeschemametadata = undefined;
        }
    }
    async getRemoteSchema(schemaid) {
        var _a, _b, _c;
        let letrid = "";
        let letrkey = "";
        if (this.selecteditemid.indexOf("web-") === 0) {
            const wfv = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (wfv === undefined) {
                return;
            }
            letrid = wfv.letrid;
            letrkey = (_a = wfv.letrkey) !== null && _a !== void 0 ? _a : "";
        }
        else {
            const wf = this.activeforms.get(this.selecteditemid);
            if (wf === undefined) {
                return;
            }
            letrid = wf.letrid;
            letrkey = (_b = wf.letrkey) !== null && _b !== void 0 ? _b : "";
        }
        if (this.activeschemametadata === undefined) {
            return;
        }
        const req = {
            letrid: letrid,
            schemaid: schemaid,
            schemaitems: (_c = this.activeschemametadata.schemaitemids) !== null && _c !== void 0 ? _c : [],
            dataid: this.getActiveFormUserId()
        };
        const url = ServerPath.pwaGetRemoteSchema;
        let ret;
        try {
            const res = await comm.postWithNetwork(url, req);
            if (res === undefined) {
            }
            else if (res === 'NetworkError') {
            }
            else if (res === 'Unauthorised') {
            }
            else if (res) {
                const schema = convertBoxToSchema(res.schema, letrid, letrkey);
                ret = schema;
            }
        }
        catch (ex) {
            console.log(ex);
        }
        return ret;
    }
    setShowReport(show) {
        this.showreport = show;
    }
    addNewRows(rows) {
        this.newrows = rows;
    }
    setAddRows(num) {
        this.addrows = num;
    }
    setStoringForm(storing) {
        this.storingform = storing;
    }
    setCollectingData(col) {
        this.checkingandcollecting = col;
    }
    setDeleteingRows(del) {
        this.deleteingrows = del;
    }
    async deleteRows() {
        var _a;
        let firstRowId = "";
        if (this.cardview) {
            firstRowId = this.pwaeditstore.activerowid;
        }
        else {
            firstRowId = Array.from(this.pwaeditstore.selectedrows.keys())[0];
        }
        let arow = this.rowsMap.get(firstRowId);
        let wf = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (!wfv1 || !wfv1.shopen || !wfv1.letrkey) {
                this.addFormmessage("Unauthroise action");
                toast("heere1.", {
                    position: 'top-right',
                    type: 'error'
                });
                return undefined;
            }
            const wfv = toJS(wfv1);
            if (!wfv || !wfv.shopen || !wfv.letrkey) {
                this.addFormmessage("Unauthroise action");
                toast("heere2.", {
                    position: 'top-right',
                    type: 'error'
                });
                return undefined;
            }
            const dummyform = {
                id: this.selecteditemid,
                schemaid: wfv.schemaid,
                history: false,
                backgroundcolor: '',
                textcolor: '',
                referer: '',
                letrid: wfv.letrid,
                code: wfv.code,
                creatorid: wfv.creatorid,
                lastmodifiedby: '',
                lastmodifytime: 0,
                name: wfv.name,
                letrkey: wfv.letrkey,
                schemakey: toJS((_a = wfv.shopen) === null || _a === void 0 ? void 0 : _a.accesskey),
                schemanonce: toJS(wfv.shopen.nonce),
                searchbox: toJS(wfv.searchboxopen),
                pubkeys: toJS(wfv.keys),
                useall: false,
                validids: [],
                sh: wfv.shopen
            };
            wf = dummyform;
        }
        else {
            wf = this.activeforms.get(this.selecteditemid);
            if (!wf) {
                toast("heere3.", {
                    position: 'top-right',
                    type: 'error'
                });
                this.addFormmessage("Unauthroise action");
                return undefined;
            }
        }
        const aschema = wf.sh;
        if (!arow || aschema === undefined || wf === undefined || !this.selectedorgid) {
            toast("Unable to delete. Missing information.", {
                position: 'top-center',
                type: 'error'
            });
            return;
        }
        const req = {
            letrid: aschema.letrid,
            schemaid: aschema.id,
            rowids: [],
            dataid: this.selectedorgid + "----" + wf.id
        };
        if (!this.cardview) {
            if (this.pwaeditstore.selectallrows && this.hiddenrows.size === 0) {
                req['deleteall'] = true;
            }
            else {
                const dids = Array.from(this.pwaeditstore.selectedrows.keys()).filter((id) => !this.hiddenrows.has(id));
                req['rowids'] = dids;
            }
        }
        else {
            req['rowids'] = [this.pwaeditstore.activerowid];
        }
        const url = ServerPath.pwaDeleteRows;
        this.setDeleteingRows(true);
        try {
            const res = await comm.postWithNetwork(url, req);
            if (res === undefined) {
                toast("Sorry, unable to delete. Please try again later.", {
                    position: 'top-center',
                    type: 'error'
                });
            }
            else if (res === 'NetworkError') {
                toast("Sorry, network error. Please try again later.", {
                    position: 'top-center',
                    type: 'error'
                });
            }
            else if (res === 'Unauthorised') {
                toast("Sorry, couldn't process the delete request.", {
                    position: 'top-center',
                    type: 'error'
                });
            }
            else if (res && res.rowids.length > 0) {
                for (const rid of res.rowids) {
                    this.removeRow(rid);
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
        this.setDeleteingRows(false);
    }
    async saveActiveRowEdits() {
        var _a, _b, _c, _d, _e;
        let arow = this.rowsMap.get(this.pwaeditstore.activerowid);
        try {
            if (!arow) {
                console.log("no row found ?");
                return;
            }
            if (this.pwaeditstore.editrowproperty.size === 0 && this.pwaeditstore.deleteentries.length === 0 && this.objectselectedfiles.size === 0) {
                return;
            }
            let wf = undefined;
            let appusers = [];
            let otherview = [];
            if (this.selecteditemid.startsWith("web-")) {
                const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
                if (!wfv1 || !wfv1.shopen || !wfv1.letrkey) {
                    this.addFormmessage("Unauthroise action");
                    toast("heere1.", {
                        position: 'top-right',
                        type: 'error'
                    });
                    return undefined;
                }
                const wfv = toJS(wfv1);
                if (!wfv || !wfv.shopen || !wfv.letrkey) {
                    this.addFormmessage("Unauthroise action");
                    toast("heere2.", {
                        position: 'top-right',
                        type: 'error'
                    });
                    return undefined;
                }
                otherview.push(wfv1);
                otherview.push(...(_a = wfv1.otherviews) !== null && _a !== void 0 ? _a : []);
                appusers = ((_b = wfv1.users) !== null && _b !== void 0 ? _b : []).filter((ap) => ap.type === 'appuser');
                const dummyform = {
                    id: this.selecteditemid,
                    schemaid: wfv.schemaid,
                    history: false,
                    backgroundcolor: '',
                    textcolor: '',
                    referer: '',
                    letrid: wfv.letrid,
                    code: wfv.code,
                    creatorid: wfv.creatorid,
                    lastmodifiedby: '',
                    lastmodifytime: 0,
                    name: wfv.name,
                    letrkey: wfv.letrkey,
                    schemakey: toJS((_c = wfv.shopen) === null || _c === void 0 ? void 0 : _c.accesskey),
                    schemanonce: toJS(wfv.shopen.nonce),
                    searchbox: toJS(wfv.searchboxopen),
                    pubkeys: toJS(wfv.keys),
                    useall: false,
                    validids: [],
                    sh: wfv.shopen
                };
                wf = dummyform;
            }
            else {
                wf = this.activeforms.get(this.selecteditemid);
                if (!wf) {
                    toast("heere3.", {
                        position: 'top-right',
                        type: 'error'
                    });
                    this.addFormmessage("Unauthroise action");
                    return undefined;
                }
            }
            const achema = wf.sh;
            if (!achema) {
                toast("heere4.", {
                    position: 'top-right',
                    type: 'error'
                });
                this.addFormmessage("Unauthroise action");
                return undefined;
            }
            this.pwaeditstore.setSavingRow(true);
            // now lets just save thes evalues and we done hurraty!!
            const cellmap = new Map();
            for (const shi of achema.items) {
                cellmap.set(shi.id, toJS(shi));
                if (shi.type === SchemaItemType.List) {
                    const litem = shi;
                    if (litem.items && litem.items.length > 0) {
                        for (const item of litem.items) {
                            cellmap.set(litem.id + "----" + item.id, toJS(item.item));
                            for (const id of this.pwaeditstore.changedlistitems) {
                                if (id.indexOf(litem.id + "----" + item.id) !== -1) {
                                    cellmap.set(id, toJS(item.item)); //save we have sitem for this now!
                                    if (item.item.required) {
                                        // const val = this.formentry.get(id);
                                        const val = this.pwaeditstore.editrowproperty.get(id);
                                        if (val === undefined || val === null
                                            || val.celldata === undefined || val.celldata === null ||
                                            (val.celldata + '').trim() === '') {
                                            this.addFormmessage(item.item.name + " field of " + shi.name + " is  a mandatory field.");
                                            this.pwaeditstore.setSavingRow(false);
                                            return;
                                        }
                                    }
                                    if (item.item.type === SchemaItemType.URL) {
                                        // lets hyst 
                                        // const val = this.formentry.get(id);
                                        const val = this.pwaeditstore.editrowproperty.get(id);
                                        if (val === null || val === void 0 ? void 0 : val.celldata) {
                                            try {
                                                const ud = JSON.parse(val.celldata);
                                                if (!ud.url.trim()) {
                                                    this.addFormmessage(item.item.name + " field of " + shi.name + " is missing url.");
                                                    this.pwaeditstore.setSavingRow(false);
                                                    return;
                                                }
                                            }
                                            catch (ex) {
                                                this.addFormmessage(shi.name + " is missing url.");
                                                this.pwaeditstore.setSavingRow(false);
                                                return;
                                            }
                                        }
                                    }
                                    if (item.item.type === SchemaItemType.Number) {
                                        const val = this.pwaeditstore.editrowproperty.get(id);
                                        if (val === null || val === void 0 ? void 0 : val.celldata) {
                                            try {
                                                const it = item.item;
                                                const num = parseFloat(val.celldata);
                                                if (num && it.minvalue !== undefined && it.minvalue !== null && num < it.minvalue) {
                                                    this.addFormmessage("Please enter a number between " + it.minvalue +
                                                        ' and ' + it.maxvalue + ' for field ' + it.name + ' of ' + shi.name);
                                                    this.pwaeditstore.setSavingRow(false);
                                                    return;
                                                }
                                                else if (num && it.maxvalue !== undefined && it.maxvalue !== null && num > it.maxvalue) {
                                                    this.addFormmessage("Please enter a number between " + it.minvalue +
                                                        ' and ' + it.maxvalue + ' for field ' + it.name + ' of ' + shi.name);
                                                    this.pwaeditstore.setSavingRow(false);
                                                    return;
                                                }
                                            }
                                            catch (ex) {
                                                this.addFormmessage((val === null || val === void 0 ? void 0 : val.celldata) + " is not a number.");
                                                this.pwaeditstore.setSavingRow(false);
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ;
                    }
                }
                const sentry = this.pwaeditstore.editrowproperty.get(shi.id);
                if (shi.required && sentry && (sentry.celldata === undefined || (sentry.celldata + '').trim() === '')) {
                    this.addFormmessage(shi.name + " is  a mandatory field.");
                    this.pwaeditstore.setSavingRow(false);
                    return;
                }
                if (shi.type === SchemaItemType.URL) {
                    // lets hyst 
                    const val = this.pwaeditstore.editrowproperty.get(shi.id);
                    if (val === null || val === void 0 ? void 0 : val.celldata) {
                        try {
                            const ud = JSON.parse(val.celldata);
                            if (!ud.url.trim()) {
                                this.addFormmessage(shi.name + " is missing url.");
                                this.pwaeditstore.setSavingRow(false);
                                return;
                            }
                        }
                        catch (ex) {
                            this.addFormmessage(shi.name + " is missing url.");
                            this.pwaeditstore.setSavingRow(false);
                            return;
                        }
                    }
                }
                if (shi.type === SchemaItemType.Number) {
                    const val = this.pwaeditstore.editrowproperty.get(shi.id);
                    if (val === null || val === void 0 ? void 0 : val.celldata) {
                        try {
                            const it = shi;
                            const num = parseFloat(val.celldata);
                            if (num && it.minvalue !== undefined && it.minvalue !== null && num < it.minvalue) {
                                this.addFormmessage("Please enter a number between " + it.minvalue +
                                    ' and ' + it.maxvalue + ' for ' + it.name);
                                this.pwaeditstore.setSavingRow(false);
                                return;
                            }
                            else if (num && it.maxvalue !== undefined && it.maxvalue !== null && num > it.maxvalue) {
                                this.addFormmessage("Please enter a number between " + it.minvalue +
                                    ' and ' + it.maxvalue + ' for ' + it.name);
                                this.pwaeditstore.setSavingRow(false);
                                return;
                            }
                        }
                        catch (ex) {
                            this.addFormmessage((val === null || val === void 0 ? void 0 : val.celldata) + " is not a number.");
                            this.pwaeditstore.setSavingRow(false);
                            return;
                        }
                    }
                }
            }
            const sh = achema;
            const itemsmap = new Set();
            for (const si of (_d = achema.items) !== null && _d !== void 0 ? _d : []) {
                if (si.type === SchemaItemType.List) {
                    for (const sii of si.items) {
                        itemsmap.add(si.id + "----" + sii.id);
                    }
                }
                else {
                    itemsmap.add(si.id);
                }
            }
            const sentries = [];
            const fdata = [];
            for (const [k, val] of this.pwaeditstore.editrowproperty) {
                const citem = cellmap.get(k);
                if (citem) {
                    if (k.indexOf("----") !== -1) {
                        if (citem.type === SchemaItemType.Number) {
                            let sortstring = -1;
                            if (val.celldata.indexOf(".") > -1) {
                                sortstring = sh.numberoffset + parseFloat(val.celldata);
                            }
                            else {
                                sortstring = sh.numberoffset + parseInt(val.celldata);
                            }
                            val.sortstring = sortstring;
                        }
                        else if (citem.type === SchemaItemType.Date) {
                            if (val.celldata) {
                                val.sortstring = sh.dateoffset + parseInt(val.celldata);
                            }
                        }
                        const parts = k.split("----");
                        if (parts.length < 3)
                            continue;
                        const lid = parts[0];
                        const itemid = parts[1];
                        const uuid = parts[2];
                        const subrowindex = val.subrowindex;
                        const entrucellid = lid + "----" + itemid;
                        const entryk = entrucellid + "----" + subrowindex;
                        const entry = Object.assign(Object.assign({}, toJS(val)), { ruleopen: undefined });
                        entry.cellid = entrucellid;
                        entry.subrowindex = subrowindex;
                        fdata.push({
                            key: entryk,
                            value: toJS(val),
                        });
                        sentries.push(entry);
                        // now lets do something fun here!!
                        continue;
                    }
                    if (citem.type === SchemaItemType.Number) {
                        let sortstring = -1;
                        if (val.celldata.indexOf(".") > -1) {
                            sortstring = sh.numberoffset + parseFloat(val.celldata);
                        }
                        else {
                            sortstring = sh.numberoffset + parseInt(val.celldata);
                        }
                        val.sortstring = sortstring;
                    }
                    else if (citem.type === SchemaItemType.Date) {
                        if (val.celldata) {
                            val.sortstring = sh.dateoffset + parseInt(val.celldata);
                        }
                    }
                    fdata.push({
                        key: val.cellid,
                        value: toJS(val),
                    });
                    sentries.push(toJS(val));
                }
            }
            let key = sh.accesskey;
            let nonce = sh.nonce;
            if (arow.entry[0].schemaid !== achema.id) {
                if (!sh.parentschema) {
                    return;
                }
                key = sh.parentschema.accesskey;
                nonce = sh.parentschema.nonce;
            }
            let custn = [];
            if (sentries.length > 0 || this.pwaeditstore.deleteentries.length > 0 || this.objectselectedfiles.size > 0) {
                if (achema && achema.custompostaction) {
                    const actionfn = new Function("config", achema.custompostaction);
                    const actionconfig = {
                        getschemarows: (id) => this.getDependSchemaRows(id),
                        decryptCell: (entry, sid) => {
                            if (!entry.decrypted) {
                                this.decryptEntry(entry, sid);
                            }
                            return entry;
                        },
                        stripHTML: (msg) => LWStripHTML(msg)
                    };
                    const bui = actionfn(actionconfig);
                    console.log(bui);
                    if (bui.notificationpackets !== undefined) {
                        let rows = [];
                        const row = this.uirows.get(this.pwaeditstore.activerowid);
                        if (row) {
                            rows = row.entry;
                        }
                        custn = await new Promise((ac, rej) => {
                            bui.notificationpackets(sentries, false, rows, (msg) => {
                                ac(msg);
                            });
                        });
                    }
                }
                const reqid = Math.random() + "";
                const req = {
                    letrid: achema.letrid,
                    sid: achema.id,
                    reqid: reqid,
                    entries: sentries,
                    accesskey: key,
                    nonce: nonce,
                    rowid: this.pwaeditstore.activerowid,
                    duplicaterequest: false,
                    schemaitems: Array.from(cellmap.values()),
                    deleteentries: this.pwaeditstore.deleteentries.map((en) => (Object.assign(Object.assign({}, en), { celldata: '', cellfindstr: '' }))),
                    nvs: [],
                    assigns: [],
                    custompackets: custn
                };
                // Apphandler.sendMessage("updaterow", req);
                const org = this.orgmenustore.orgsmap.get(this.selectedorgid);
                if (!org) {
                    toast("Failed to add job to queue. Please try again or contact support.", {
                        position: 'top-right',
                        type: 'error'
                    });
                    return;
                }
                const files = [];
                const filepro = [];
                if (this.objectselectedfiles.size > 0) {
                    for (const [k, v] of this.objectselectedfiles) {
                        if (!cellmap.has(k))
                            continue;
                        // doneids.add(k);
                        let effectivekey = k;
                        // if (k.indexOf("----") != -1) {
                        //     const parts = k.split("----");
                        //     if (parts.length < 3) continue;
                        //     const lid = parts[0];
                        //     const itemid = parts[1];
                        //     const uuid = parts[2];
                        //     const litems = this.newlistitemsmap.get(lid);
                        //     if (!litems || litems.length === 0) continue;
                        //     let ki = litems.indexOf(uuid);
                        //     if (ki === -1) continue;
                        //     effectivekey = lid + "----" + itemid + "----" + ki + "----" + uuid;
                        // }
                        for (const file of v) {
                            const id = LetrwingCrypto.getUUID();
                            const up = {
                                itemid: effectivekey,
                                id: id,
                                file: file
                            };
                            files.push(up);
                            const upp = {
                                fileid: id,
                                key: '',
                                nonce: '',
                                totalchunks: Math.ceil(file.size / (1024 * 1024)),
                                totaldonechunks: 0,
                                totalsize: file.size,
                                doneindex: 0,
                                done: false,
                                objectid: '',
                                itemid: k
                            };
                            filepro.push(upp);
                        }
                    }
                }
                const job = {
                    form: fdata,
                    files: files,
                    progress: filepro,
                    webform: toJS(wf),
                    addentry: toJS(req),
                };
                const dj = {
                    userid: this.selectedorgid,
                    data: job,
                    jobtype: JobType.EditRow,
                    jobid: LetrwingCrypto.getUUID(),
                    status: JobStatus.Pending,
                    orgid: org.orgid,
                    jobtime: new Date().getTime(),
                    customnotify: req.custompackets
                };
                if (job.webform.id.startsWith("web-") && otherview.length > 0) {
                    dj.notifyview = this.checkWithViewNeedNotification(sentries, otherview);
                    dj.assigncols = this.checkRowNeedsAutoAssignment(sentries, appusers, job.webform.schemaid, otherview);
                }
                if (!await PWaDb.addDataJob(dj)) {
                    dj.status = JobStatus.Error;
                    // this.addFormmessage("Failed to add job to queue. Please try again or contact support.");
                    toast("Failed to add job to queue. Please try again or contact support.", {
                        position: 'top-right',
                        type: 'error'
                    });
                    dj.jobstatusmessage = "Failed to add to queue.";
                }
                else {
                    if (navigator.serviceWorker.controller) {
                        (_e = navigator.serviceWorker.controller) === null || _e === void 0 ? void 0 : _e.postMessage({ type: "syncdata" });
                    }
                    else {
                        // lets ask our local system to do it
                        this.processJobLocally();
                    }
                    //  this.addFormmessage("Job added to queue. Monitor progress using progress dialog.");
                    /* toast("Job added to queue. Monitor progress using progress dialog.", {
                        position: 'top-right',
                        type: 'success',
                        autoClose: 2000
                    });*/
                }
                this.addDataJobToMap(dj);
                this.processmenu.setShow(true);
                return dj;
            }
            else {
                this.pwaeditstore.setSavingRow(false);
            }
        }
        catch (ex) {
            console.log(ex);
            toast("Failed to add job to queue. Please try again or contact support.", {
                position: 'top-right',
                type: 'error',
            });
            // this.addFormmessage("Failed to add job to queue. Please try again or contact support.");
        }
    }
    resetSchema() {
        this.customcolshareddataforrows = new Map();
        this.setChartReload(false);
        this.setLoadingRows(false);
        this.setLoadingItem(false);
        this.setFetchingUpdates(false);
        this.dependentdata.clear();
        this.rtdependentdata.clear();
        this.clearRowRules();
        this.rowsMap.clear();
        this.rowsarrids.splice(0, this.rowsarrids.length);
        // this.uirows.splice(0, this.uirows.length);
        this.uirows.clear();
        this.cacheui.clear();
    }
    clearRowRules() {
        this.rowRules.clear();
    }
    clearDatajobs() {
        this.alljobs.clear();
    }
    async refereshQueue() {
        const jobs = await PWaDb.getAllDataJob();
        this.clearDatajobs();
        for (const j of jobs) {
            this.addDataJobToMap(j);
        }
    }
    async updateLocalDataMap(id) {
        const d = await PWaDb.getDataJob(id);
        let showmenu = false;
        if (!d) {
            this.removeDataJob(id);
        }
        else {
            this.addDataJobToMap(d);
            showmenu = true;
        }
        if (this.processmenu.show) {
        }
        else if (showmenu) {
            this.setJobUpdate(true);
        }
    }
    setJobUpdate(up) {
        this.jobupdate = up;
    }
    setBuildingForm(building) {
        this.buildingwebform = building;
    }
    setInternetStatus(st) {
        var _a;
        this.online = st;
        if (st) {
            if (this.selectedorgid) {
                if (this.activeforms.size === 0 && this.activeviews.size === 0) {
                    this.loadContentAndAdd(this.selectedorgid, undefined);
                }
            }
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                (_a = navigator.serviceWorker.controller) === null || _a === void 0 ? void 0 : _a.postMessage({ type: "syncdata" });
            }
            else {
                // lets ask our local system to do it
                this.processJobLocally();
            }
        }
    }
    setSessionName(name) {
        this.sessionname = name;
    }
    clearActiveEdits() {
        this.formentry.clear();
        this.objectselectedfiles.clear();
        this.resetListItems();
        this.addFormmessage("");
    }
    cancelEntryEdit() {
        this.pwaeditstore.reset();
    }
    resetSchemaForm() {
        this.clearActiveEdits();
        this.resetSchema();
        this.setAddNewViewEntry(false);
        this.setAddNewViewEntryViaAi(false);
        this.setFromKey(Math.random() + "__");
    }
    getAIPrompt() {
        if (this.webfilterjs) {
            const f = new Function("config", this.webfilterjs);
            let config = {
                getschemarows: (sid) => this.getDependSchemaRows(sid),
                decryptcell: (entry, sid, pr) => {
                    if (!entry.decrypted) {
                        this.decryptEntry(entry, sid);
                    }
                    return entry;
                },
                getactiveuserid: () => { var _a, _b; return (_b = (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; }
            };
            const bui = f(config);
            if (bui.getpromptfortext) {
                const obj = bui.getpromptfortext("");
                if (obj && obj.openaireques) {
                    return obj.openaireques.prompt;
                }
            }
        }
        return "";
    }
    async createOcrWorker() {
        try {
            if (!this.ocrworker) {
                if (!this.creatingworker) {
                    this.creatingworker = true;
                    const scheduler = Tesseract.createScheduler();
                    const worker = await Tesseract.createWorker("eng", OEM.DEFAULT);
                    scheduler.addWorker(worker);
                    this.ocrworker = scheduler;
                    this.creatingworker = false;
                }
                else {
                    let n = 0;
                    while (n < 5 && !this.ocrworker) {
                        await timeout(100);
                        n++;
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    async runAi(prompt = "") {
        var _a, _b, _c, _d;
        const achema = this.activeviews.get(this.selecteditemid.substring("web-".length));
        if (this.aicontent && this.webfilterjs && achema) {
            this.setAiMessages("Running automation pipeline..");
            try {
                const f = new Function("config", this.webfilterjs);
                let config = {
                    getschemarows: (sid) => this.getDependSchemaRows(sid),
                    decryptcell: (entry, sid, pr) => {
                        if (!entry.decrypted) {
                            this.decryptEntry(entry, sid);
                        }
                        return entry;
                    },
                    getactiveuserid: () => { var _a, _b; return (_b = (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; }
                };
                const bui = f(config);
                if (bui.getpromptfortext) {
                    const obj = bui.getpromptfortext(this.aicontent);
                    if (obj && obj.openaireques) {
                        if (prompt) {
                            obj.openaireques.prompt = prompt;
                        }
                        const req = {
                            userid: (_b = (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
                            openairequest: obj.openaireques,
                            schemaid: (_d = (_c = achema.sh) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '',
                            letrid: '',
                            webformid: this.selecteditemid
                        };
                        const resp = await comm.post(ServerPath.pwaLLMRequest, req);
                        if (resp !== undefined) {
                            const ens = obj.promptrespparser(resp);
                        }
                        else {
                            toast("Failed to generate response. Please contact support", {
                                position: 'top-center'
                            });
                        }
                    }
                }
            }
            catch (E) {
            }
        }
        this.setAiMessages("");
    }
    async ocrFileOnBackend(files) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const achema = this.activeviews.get(this.selecteditemid.substring("web-".length));
        if (!achema)
            return;
        this.setAiMessages("Extracting text from images...");
        let images = [];
        for (const f of files) {
            const b64 = await new Promise((a, r) => {
                const fr = new FileReader();
                fr.onloadend = function () {
                    var _a, _b;
                    a((_b = (_a = fr.result) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
                };
                fr.readAsDataURL(f);
            });
            if (b64) {
                images.push(b64);
            }
        }
        if (images.length > 0) {
            // lets do this now!!
            const req = {
                userid: (_b = (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
                images: images,
                id: '',
                language: [],
                schemaid: (_d = (_c = achema.sh) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '',
                letrid: '',
                webformid: this.selecteditemid
            };
            const id = await comm.post(ServerPath.pwaOcrImage, req);
            if (id !== undefined && id) {
                let n = 0;
                while (n < 100) {
                    await timeout(1000);
                    const statuscheck = {
                        userid: (_f = (_e = this.currentroute) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : '',
                        images: images,
                        id: id,
                        language: [],
                        schemaid: (_h = (_g = achema.sh) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : '',
                        letrid: '',
                        webformid: this.selecteditemid
                    };
                    const st = await comm.post(ServerPath.pwaOcrImageStatus, statuscheck);
                    if (st && st.status === "Done") {
                        if (st.results) {
                            let text = "";
                            for (const r of st.results) {
                                text += r;
                            }
                            this.setAiContent(text);
                        }
                        else {
                        }
                        break;
                    }
                    n++;
                }
            }
            else {
                toast("Sorry, failed to extract text. Please contact support", {
                    position: 'top-center'
                });
            }
        }
        this.setAiMessages("");
    }
    async ocrFile(files) {
        this.setAiMessages("Extracting text from images");
        await this.createOcrWorker();
        if (!this.ocrworker) {
            toast("Something isn't working properly. Please contact support.", {
                position: 'top-center'
            });
        }
        else {
            let text = "";
            for (const file of files) {
                try {
                    const result = await this.ocrworker.addJob("recognize", file);
                    console.log(result);
                    if (result && result.data && result.data.text) {
                        text += result.data.text;
                    }
                }
                catch (e) { }
            }
            this.setAiContent(text);
        }
        this.setAiMessages("");
    }
    addFile(id, file) {
        var _a;
        if (!this.objectselectedfiles.has(id)) {
            this.objectselectedfiles.set(id, []);
        }
        (_a = this.objectselectedfiles.get(id)) === null || _a === void 0 ? void 0 : _a.push(file);
    }
    getNextZIndex() {
        this.zindex = this.zindex + 1;
        return this.zindex;
    }
    removeFile(id, file) {
        const files = this.objectselectedfiles.get(id);
        if (files) {
            const findex = files.indexOf(file);
            if (findex > -1) {
                files.splice(findex, 1);
            }
            if (files.length === 0) {
                this.objectselectedfiles.delete(id);
            }
        }
    }
    setLoadingRows(loading) {
        this.loadingrows = loading;
    }
    removeRow(id) {
        const row = this.rowsMap.get(id);
        if (row === undefined)
            return;
        const rindex = this.binary_search_iterative(row.rowindex);
        console.log("rowindex " + row.rowindex + " found index " + rindex);
        if (rindex !== null && rindex > -1) {
            this.rowsarrids.splice(rindex, 1);
            // this.uirows.splice(rindex, 1);
            this.uirows.delete(id);
        }
        this.rowsMap.delete(id);
    }
    hiderow(id) {
        const row = this.rowsMap.get(id);
        if (row === undefined)
            return;
        this.hiddenrows.add(row.rowid);
        const rindex = this.binary_search_iterative(row.rowindex);
        row.vis = false;
        if (rindex !== null && rindex > -1) {
            // this.uirows[rindex].setVisibility(true);
            const srow = this.uirows.get(id);
            if (srow !== undefined) {
                srow.setVisibility(true);
            }
        }
    }
    showRow(id) {
        const row = this.rowsMap.get(id);
        if (row === undefined)
            return;
        row.vis = true;
        const srow = this.uirows.get(id);
        if (srow !== undefined) {
            srow.setVisibility(false);
        }
    }
    binary_search(rowindex, arr) {
        let lo = 0, hi = arr.length - 1, mid = 0;
        while (lo <= hi) {
            mid = Math.floor((lo + hi) / 2);
            if (arr[mid].rowindex > rowindex)
                hi = mid - 1;
            else if (arr[mid].rowindex < rowindex)
                lo = mid + 1;
            else
                return mid;
        }
        return null;
    }
    binary_search_iterative(rowindex) {
        let lo = 0, hi = this.rowsarrids.length - 1, mid = 0;
        while (lo <= hi) {
            mid = Math.floor((lo + hi) / 2);
            if (this.rowsarrids[mid].rowindex > rowindex)
                hi = mid - 1;
            else if (this.rowsarrids[mid].rowindex < rowindex)
                lo = mid + 1;
            else
                return mid;
        }
        return null;
    }
    getActiveFormUserId() {
        var _a, _b;
        return ((_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) + "----" + ((_b = this.currentroute) === null || _b === void 0 ? void 0 : _b.itemid);
    }
    setFetchingUpdates(updates) {
        this.fetchingupdates = updates;
    }
    async loadUpdates() {
    }
    setupItemsAndROList(achema, existing) {
        var _a;
        if (!achema.shopen)
            return [];
        let sh = undefined;
        let letrid = "";
        let letrkey = "";
        let loadingid = this.selecteditemid;
        let items = new Map();
        sh = achema.shopen;
        letrid = achema.letrid;
        letrkey = (_a = achema.letrkey) !== null && _a !== void 0 ? _a : '';
        let ub = undefined;
        let autouser = false;
        let autocol = new Map();
        if (sh) {
            for (const it of sh.items) {
                if (it.type == SchemaItemType.List) {
                    const lit = it;
                    if (lit.items) {
                        for (const li of lit.items) {
                            items.set(it.id + "----" + li.item.id, Object.assign(Object.assign({}, li.item), { id: it.id + "----" + li.item.id }));
                        }
                    }
                }
                else {
                    items.set(it.id, it);
                }
            }
        }
        const colsids = new Set();
        const readonly = new Set();
        achema.columnids.forEach((co) => colsids.add(co));
        let autocolids = new Set();
        if (achema.appformbox) {
            for (const apb of achema.appformbox) {
                if (apb.id.startsWith("schem-")) {
                    if (apb.autocolumnid) {
                        autocol.set(apb.autocolumnid, apb);
                        continue;
                    }
                }
                if (!ub && apb.id === this.selectedorgid) {
                    ub = apb;
                    ub.mypermissions.forEach((pe) => this.addPerm(pe));
                    ub.readonlyfields.forEach((rd) => {
                        colsids.add(rd);
                        readonly.add(rd);
                    });
                }
            }
        }
        if (!ub) {
            if (achema.autoformbox) {
                for (const apb of achema.autoformbox) {
                    if (apb.id === this.selectedorgid && apb.autocolumnid) {
                        const au = autocol.get(apb.autocolumnid);
                        if (au) {
                            ub = apb;
                            autouser = true;
                            au.mypermissions.forEach((pe) => this.addPerm(pe));
                            ub.readonlyfields.forEach((rd) => {
                                colsids.add(rd);
                                readonly.add(rd);
                            });
                            // this is coulmn we have in schema view and we just need 
                            autocolids.add(apb.autocolumnid);
                        }
                    }
                }
            }
        }
        if (existing) {
        }
        this.setROItems(Array.from(readonly));
        let users = [];
        if (achema.users) {
            users = achema.users;
        }
        this.clearActiveUsers();
        for (const u of users) {
            let id = u.id;
            this.addActiveUsers(Object.assign(Object.assign({}, u), { id: id }));
        }
        if (colsids.size === 0) {
            this.setFetchingUpdates(false);
            this.setLoadingRows(false);
            this.setLoadingItem(false);
            this.setItems([]);
            return [];
        }
        else {
            const shitems = Array.from(items.values()).filter((it) => colsids.has(it.id));
            if (shitems.length > 0) {
                const additems = new Set();
                for (const sii of shitems) {
                    additems.add(sii.id);
                    if (sii.id.indexOf("----") !== -1) {
                        additems.add(sii.id.split("----")[0]);
                    }
                }
                const boxeditems = [];
                for (const it of sh.items) {
                    if (!additems.has(it.id))
                        continue;
                    if (it.type == SchemaItemType.List) {
                        const lit = it;
                        const localit = Object.assign(Object.assign({}, it), { items: [] });
                        if (lit.items) {
                            for (const li of lit.items) {
                                if (additems.has(it.id + "----" + li.item.id)) {
                                    localit.items.push(li);
                                }
                            }
                        }
                        boxeditems.push(localit);
                    }
                    else {
                        boxeditems.push(it);
                    }
                }
                this.setItems(boxeditems);
                return Array.from(autocol.values());
            }
            else {
                this.clearRowMap();
                this.setFetchingUpdates(false);
                this.setLoadingRows(false);
                this.setLoadingItem(false);
                this.setItems([]);
                return [];
            }
        }
    }
    setColWidth(id, wd) {
        this.columnwidths.set(id, wd);
        localStorage.setItem(this.selecteditemid + id, wd + '');
    }
    // we might need to do similar for 
    async loadRowsForActiveForm(since, updates = false, issaving = false) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (since === 0 && this.lastitemid === this.selecteditemid)
            return;
        this.lastitemid = this.selecteditemid;
        this.lastloadtime = new Date().getTime();
        let sh = undefined;
        let letrid = "";
        let letrkey = "";
        let loadingid = this.selecteditemid;
        let items = new Map();
        let autocolboxes = [];
        let autouser = false;
        let loadingfilter = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            const achema = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (!achema || !achema.shopen || !this.currentroute) {
                this.setLoadingRows(false);
                this.setLoadingItem(false);
                toast("Failed to load details for the view.", {
                    position: 'top-center',
                    type: 'error'
                });
                return;
            }
            sh = achema.shopen;
            letrid = achema.letrid;
            letrkey = (_a = achema.letrkey) !== null && _a !== void 0 ? _a : '';
            if (achema.customfilerjs && !issaving) {
                // okay lets get the custom filter !!
                const f = new Function("config", achema.customfilerjs);
                let config = {
                    getschemarows: (sid) => this.getDependSchemaRows(sid),
                    decryptcell: (entry, sid, pr) => {
                        if (!entry.decrypted) {
                            this.decryptEntry(entry, sid);
                        }
                        return entry;
                    },
                    getactiveuserid: () => { var _a, _b; return (_b = (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; }
                };
                const bui = f(config);
                if (bui && bui.getloadingfilter) {
                    // okay we do now 
                    loadingfilter = bui.getloadingfilter();
                    if (loadingfilter && loadingfilter.andquery) {
                        for (const aq of loadingfilter.andquery) {
                            if (aq.value) {
                                const eben = LetrwingCrypto.secretBoxWithNonce(aq.value, sh.accesskey, sh.nonce);
                                aq.value = eben.encryptmsg;
                            }
                            else if (aq.values) {
                                let nvals = [];
                                for (const aqv of aq.values) {
                                    const eben = LetrwingCrypto.secretBoxWithNonce(aqv, sh.accesskey, sh.nonce);
                                    nvals.push(eben.encryptmsg);
                                }
                                aq.values = nvals;
                            }
                        }
                    }
                }
            }
            let ub = undefined;
            let autocol = new Map();
            if (sh) {
                for (const it of sh.items) {
                    if (it.type == SchemaItemType.List) {
                        const lit = it;
                        if (lit.items) {
                            for (const li of lit.items) {
                                items.set(it.id + "----" + li.item.id, Object.assign(Object.assign({}, li.item), { id: it.id + "----" + li.item.id }));
                            }
                        }
                    }
                    else {
                        items.set(it.id, it);
                    }
                }
            }
            const colsids = new Set();
            const readonly = new Set();
            if (sh.custompanels) {
                this.customrowjs = (_b = sh.custompanels) !== null && _b !== void 0 ? _b : '';
            }
            else {
                this.customrowjs = '';
            }
            if (sh === null || sh === void 0 ? void 0 : sh.colwidths) {
                for (const cl of sh.colwidths) {
                    this.columnwidths.set(cl.colid, cl.width);
                }
            }
            if (sh.custompanelcode) {
                this.setCustompaneljs(sh.custompanelcode);
            }
            else {
                this.setCustompaneljs("");
            }
            if (sh) {
                for (const itm of (_c = sh === null || sh === void 0 ? void 0 : sh.items) !== null && _c !== void 0 ? _c : []) {
                    if (!this.columnwidths.has(itm.id)) {
                        const lst = localStorage.getItem(this.selecteditemid + itm.id);
                        if (lst) {
                            const lstg = parseFloat(lst);
                            if (!isNaN(lstg) && lstg > 0) {
                                this.columnwidths.set(itm.id, lstg);
                                continue;
                            }
                        }
                        this.columnwidths.set(itm.id, 300);
                    }
                }
            }
            achema.columnids.forEach((co) => colsids.add(co));
            if (achema.appformbox) {
                for (const apb of achema.appformbox) {
                    if (apb.id.startsWith("schem-")) {
                        if (apb.autocolumnid) {
                            autocol.set(apb.autocolumnid, apb);
                            continue;
                        }
                    }
                    if (!ub && apb.id === this.selectedorgid) {
                        ub = apb;
                        ub.mypermissions.forEach((pe) => this.addPerm(pe));
                        ub.readonlyfields.forEach((rd) => {
                            colsids.add(rd);
                            readonly.add(rd);
                        });
                    }
                }
            }
            if (!ub) {
                if (achema.autoformbox) {
                    for (const apb of achema.autoformbox) {
                        if (apb.id === this.selectedorgid && apb.autocolumnid) {
                            const au = autocol.get(apb.autocolumnid);
                            if (au) {
                                autocolboxes.push(au);
                                ub = apb;
                                autouser = true;
                                au.mypermissions.forEach((pe) => this.addPerm(pe));
                                ub.readonlyfields.forEach((rd) => {
                                    colsids.add(rd);
                                    readonly.add(rd);
                                });
                            }
                        }
                    }
                }
            }
            this.setROItems(Array.from(readonly));
            if (colsids.size === 0) {
                this.setFetchingUpdates(false);
                this.setLoadingRows(false);
                this.setLoadingItem(false);
                this.setItems([]);
                return;
            }
            else {
                const shitems = Array.from(items.values()).filter((it) => colsids.has(it.id));
                if (shitems.length > 0) {
                    const additems = new Set();
                    for (const sii of shitems) {
                        additems.add(sii.id);
                        if (sii.id.indexOf("----") !== -1) {
                            additems.add(sii.id.split("----")[0]);
                        }
                    }
                    const boxeditems = [];
                    for (const it of sh.items) {
                        if (!additems.has(it.id))
                            continue;
                        if (it.type == SchemaItemType.List) {
                            const lit = it;
                            const localit = Object.assign(Object.assign({}, it), { items: [] });
                            if (lit.items) {
                                for (const li of lit.items) {
                                    if (additems.has(it.id + "----" + li.item.id)) {
                                        localit.items.push(li);
                                    }
                                }
                            }
                            boxeditems.push(localit);
                        }
                        else {
                            boxeditems.push(it);
                        }
                    }
                    this.setItems(boxeditems);
                }
                else {
                    this.clearRowMap();
                    this.setFetchingUpdates(false);
                    this.setLoadingRows(false);
                    this.setLoadingItem(false);
                    this.setItems([]);
                    return;
                }
            }
            /*

            if (!this.currentpermissions.has("viewrows") && achema.type !== ViewType.Chart) {
                this.setFetchingUpdates(false);
                this.clearRowMap();
                this.lastloadtime = 0;

                this.setLoadingRows(false);
                return;
            }*/
            if (!updates) {
                this.setLoadingRows(true);
            }
        }
        else {
            const achema = this.activeforms.get(this.selecteditemid);
            if (!achema || !achema.sh || !this.currentroute) {
                this.setLoadingRows(false);
                toast("Failed to load details for the form.", {
                    position: 'top-center',
                    type: 'error'
                });
                return;
            }
            sh = achema.sh;
            letrkey = (_d = achema.letrkey) !== null && _d !== void 0 ? _d : '';
        }
        if (sh.custompanels) {
            this.customrowjs = (_e = sh.custompanels) !== null && _e !== void 0 ? _e : '';
        }
        else {
            this.customrowjs = '';
        }
        if (updates) {
            this.setFetchingUpdates(true);
        }
        else {
            this.setLoadingRows(true);
        }
        // lets fetch content now and do the stuff here!!
        let res = await this.fetchRows(sh, since, this.currentroute.id, this.selecteditemid, letrkey, issaving, loadingfilter);
        if (this.selecteditemid !== loadingid) {
            return;
        }
        if (res === 'NetworkError') {
            if (since === 0 || !updates) {
                this.lastloadtime = 0;
                const offdata = await PWaDb.getSchemaRows(sh.id);
                if (!offdata) {
                    toast("Network issues failed to load rows", {
                        position: 'top-center',
                        type: 'error'
                    });
                    this.setLoadingRows(false);
                    return;
                }
                res = offdata;
            }
            else {
                this.setFetchingUpdates(false);
                this.setLoadingItem(false);
                return;
            }
        }
        if (this.selecteditemid !== loadingid) {
            return;
        }
        // now lets build the rows and othe rneccessary bits and we done!!
        if (!res) {
            this.setFetchingUpdates(false);
            this.setLoadingRows(false);
            this.setLoadingItem(false);
            return;
        }
        if (res.delrows && res.delrows.length > 0) {
            res.delrows.forEach((r) => this.removeRow(r));
        }
        const mapofdata = new Map();
        if (res.dsch) {
            for (const en of res.dsch) {
                if (!mapofdata.has(en.id)) {
                    mapofdata.set(en.id, []);
                }
                (_f = mapofdata.get(en.id)) === null || _f === void 0 ? void 0 : _f.push(...en.entries);
            }
        }
        // we should create some missing columns that we might need!!
        // there might be rule 
        let dsrows = convertDepToRows(mapofdata);
        for (const s of dsrows.keys()) {
            this.depschemarows.set(s, (_g = dsrows.get(s)) !== null && _g !== void 0 ? _g : []);
        }
        this.depschemarows = convertDepToRows(mapofdata);
        if (res.shs) {
            for (const shh of res.shs) {
                this.schemasmap.set(shh.id, shh);
            }
            this.schemasmap.set(sh.id, sh);
        }
        if (res.rows.length === 0 && !updates) {
            this.clearRowMap();
            this.setLoadingRows(false);
            this.setLoadingItem(false);
            return;
        }
        else if (res.rows.length === 0 && updates) {
            this.setFetchingUpdates(false);
            this.setLoadingItem(false);
            this.setLoadingRows(false);
            return;
        }
        if (this.selecteditemid !== loadingid) {
            return;
        }
        await this.addRowInline(res.rows, since === 0, true);
        if (this.selecteditemid !== loadingid) {
            return;
        }
        // lets do something clever now!
        // firts rebuild the 
        if (!issaving) {
            this.clearRowRules();
            if (res.rowrules) {
                for (const rr of res.rowrules) {
                    this.addRowRule(rr);
                }
            }
        }
        if (this.selecteditemid !== loadingid) {
            return;
        }
        let updaterowmap = this.rowsMap;
        if (issaving) {
            updaterowmap = new Map();
            for (const r of res.rows) {
                updaterowmap.set(r.rowid, r);
            }
        }
        await this.addRuleEntryToRows(sh, updaterowmap);
        if (!updates) {
            let rmap = new Map();
            let i = 0;
            let lengh = res.rows.length;
            while (i < lengh) {
                let r = res.rows[i];
                const sr = new SimpleSchemaUIRow(true, r.entry);
                sr.rowid = r.rowid;
                sr.rowindex = r.rowindex;
                sr.childrenschemalist = r.childrenschemalist;
                sr.linkedids = r.linkedids;
                sr.objsdec = r.objsdec;
                sr.shsdec = r.shsdec;
                sr.referenceids = r.referenceids;
                rmap.set(sr.rowid, sr);
                i++;
                if (i > 0 && i % 5000 === 0) {
                    await timeout(100);
                }
            }
            this.uirows = rmap;
        }
        else {
            let rmap = new Map();
            let i = 0;
            let lengh = res.rows.length;
            while (i < lengh) {
                let r = res.rows[i];
                const sr = new SimpleSchemaUIRow(true, r.entry);
                sr.rowid = r.rowid;
                sr.rowindex = r.rowindex;
                sr.childrenschemalist = r.childrenschemalist;
                sr.linkedids = r.linkedids;
                sr.objsdec = r.objsdec;
                sr.shsdec = r.shsdec;
                sr.referenceids = r.referenceids;
                this.cacheui.delete(sr.rowid);
                runInAction(() => this.uirows.set(sr.rowid, sr));
                i++;
                if (i > 0 && i % 5000 === 0) {
                    await timeout(100);
                }
            }
            // await this.addRowsToMobs(updates, res.rows);
        }
        if (this.selecteditemid !== loadingid) {
            return;
        }
        this.depschemarows.set(sh.id, Array.from(this.rowsMap.values()));
        if (this.selecteditemid !== loadingid) {
            return;
        }
        if (this.selecteditemid.startsWith("web-")) {
            // lets filter the rows and throw away non needed rows!!
            const achema = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (achema) {
                if (achema.filters && this.rowsMap.size > 0) {
                    const sfils = this.createFilterMeta(achema, items);
                    this.smeatda = sfils;
                    let updaterowmap = new Map();
                    let updrmap = new Map();
                    if (issaving) {
                        for (const r of res.rows) {
                            updaterowmap.set(r.rowid, r);
                            let sro = this.uirows.get(r.rowid);
                            if (sro) {
                                updrmap.set(r.rowid, sro);
                            }
                        }
                    }
                    let srows = await this.filterAndRemoveRows(sfils, sh, updaterowmap);
                    let sset = new Set(srows);
                    this.clearRowReadonly();
                    if (autouser && this.rowsMap.size > 0 && autocolboxes.length > 0) {
                        sset = await this.filterRowsBasedOnAuto(autocolboxes, "appcontact-" + this.selectedorgid, sh, sset, updrmap);
                    }
                    if (this.selecteditemid === loadingid) {
                        for (const s of sset) {
                            this.showRow(s);
                        }
                    }
                    // now we can just 
                    if (achema.customfilerjs) {
                        // run filter code here now!
                        await this.filterViewRows(achema, updaterowmap, issaving);
                    }
                }
                else {
                    this.clearRowMap();
                }
            }
        }
        else {
            let i = 0;
            for (const ui of this.uirows.values()) {
                ui.setVisibility(false);
                i++;
                if (i > 0 && i % 500 === 0)
                    await timeout(10);
            }
        }
        if (this.selecteditemid !== loadingid) {
            return;
        }
        if (since === 0) {
            this.buildDataChart(false);
        }
        this.setFetchingUpdates(false);
        this.setLoadingRows(false);
        this.setLoadingItem(false);
        this.processmenu.setShow(false);
        return;
    }
    async filterViewRows(view, workrows = new Map(), issaving = false) {
        if (view.customfilerjs) {
            const f = new Function("config", view.customfilerjs);
            let config = {
                getschemarows: (sid) => this.getDependSchemaRows(sid),
                decryptcell: (entry, sid, pr) => {
                    if (!entry.decrypted) {
                        this.decryptEntry(entry, sid);
                    }
                    return entry;
                }
            };
            const bui = f(config);
            if (!bui || !bui.canShowRow)
                return;
            let hidros = [];
            let i = 0;
            let wm = workrows.size > 0 ? workrows : this.rowsMap;
            for (const [_, row] of wm) {
                if (!this.hiddenrows.has(row.rowid) && !bui.canShowRow(row.entry, issaving)) {
                    hidros.push(row.rowid);
                }
                if (i > 0 && i % 1000 === 0)
                    await timeout(10);
                i++;
            }
            if (hidros.length > 0) {
                for (const s of hidros) {
                    this.hiddenrows.add(s);
                }
            }
        }
    }
    addChart(ctype, id) {
        const cstate = new ChartViewState(id);
        cstate.setChartType(ctype);
        this.chartstate.set(id, cstate);
        return id;
    }
    clearChartState() {
        this.chartstate.clear();
    }
    async getUpdateSchemaSince(mysid, since, reqsid) {
        const req = {
            schemaid: mysid,
            reqschemaid: reqsid,
            since: since,
            letrid: '',
            dataid: this.getActiveFormUserId()
        };
        const res = await comm.post(ServerPath.pwagetUpdatesSince, req);
        const result = {
            delrows: [],
            entries: [],
            sid: mysid,
            reqsid: reqsid
        };
        if (res !== undefined) {
            // now we can just return this from here!
            for (const re of res) {
                if (re.type === UpdateType.RowDelete) {
                    const dre = JSON.parse(JSON.stringify(re));
                    result.delrows.push(...dre.data.itemids);
                }
                else if (re.type === UpdateType.Row) {
                    const dre = JSON.parse(JSON.stringify(re));
                    result.entries.push(...dre.data);
                }
                else if (re.type === UpdateType.Cell) {
                    const dre = JSON.parse(JSON.stringify(re));
                    result.entries.push(...dre.data);
                }
            }
        }
        return result;
    }
    buildDataChart(update) {
        var _a, _b, _c, _d;
        if (!update) {
            this.clearChartState();
        }
        const activeview = this.activeviews.get(this.selecteditemid.substring("web-".length));
        if (activeview && activeview.type === ViewType.Chart && activeview.shopen) {
            const items = new Map();
            const achema = activeview.shopen;
            if (achema) {
                for (const it of achema.items) {
                    if (it.type == SchemaItemType.List) {
                        const lit = it;
                        if (lit.items) {
                            for (const li of lit.items) {
                                items.set(it.id + "----" + li.item.id, li.item);
                            }
                        }
                    }
                    else {
                        items.set(it.id, it);
                    }
                }
            }
            let index = -1;
            for (const con of activeview.configs) {
                index++;
                let cst = undefined;
                const ccon = con;
                const label = ccon.labelcolumnid;
                const labelitem = items.get(label);
                if (labelitem) {
                    let donefirst = false;
                    for (const dd of ccon.datacolumnids) {
                        const ddparts = dd.split("~~~");
                        if (ddparts.length > 2) {
                            const dit = ddparts[0];
                            let cold = (_a = ddparts[1]) !== null && _a !== void 0 ? _a : '';
                            let coitem = undefined;
                            if (cold) {
                                const ci = items.get(cold);
                                if (!ci) {
                                    cold = '';
                                }
                                coitem = ci;
                            }
                            const type = ddparts[2];
                            let ctype = ChartType.Line;
                            if (type === 'line') {
                                ctype = ChartType.Line;
                            }
                            else if (type === 'pie') {
                                ctype = ChartType.Pie;
                            }
                            else if (type === 'bar') {
                                ctype = ChartType.Bar;
                            }
                            const ditem = items.get(dit);
                            if (ditem) {
                                let id = index + "_" + dd + "_" + label + "_" + ctype;
                                if (!donefirst) {
                                    if (update) {
                                        const ls = this.chartstate.get(id);
                                        if (ls) {
                                            cst = id;
                                        }
                                    }
                                    if (!cst) {
                                        cst = this.addChart(ctype, id);
                                    }
                                    donefirst = true;
                                    const charts = this.chartstate.get(cst);
                                    if (charts) {
                                        charts.setChartType(ctype);
                                        charts.setXID(labelitem.id);
                                        charts.setXName(labelitem.name);
                                        charts.setYName(ditem.name);
                                        charts.setYID(ditem.id);
                                        charts.setShowXDatasetOpts(false);
                                        if (coitem) {
                                            charts.setColorFieldid(coitem.id);
                                            charts.setColorFieldName(coitem.name);
                                        }
                                        this.buildChart(charts.id, labelitem.id, ditem.id, ctype, achema);
                                    }
                                }
                                else if (cst) {
                                    const cstsrt = this.chartstate.get(cst);
                                    if (cstsrt) {
                                        cstsrt.setSecondaryDataSet({
                                            id: Math.random() + "",
                                            itemid: ditem.id,
                                            items: this.getDatapointsForChart(ditem.id, achema, (_b = coitem === null || coitem === void 0 ? void 0 : coitem.id) !== null && _b !== void 0 ? _b : ''),
                                            type: ctype,
                                            label: ditem.name,
                                            colorid: (_c = coitem === null || coitem === void 0 ? void 0 : coitem.id) !== null && _c !== void 0 ? _c : '',
                                            colorlabel: (_d = coitem === null || coitem === void 0 ? void 0 : coitem.name) !== null && _d !== void 0 ? _d : ''
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    getDatapointsForChart(yfield, achema, colorid) {
        const ydata = [];
        if (!achema) {
            return ydata;
        }
        const sitemmap = new Map();
        if (achema.parentschema) {
            achema.parentschema.items.forEach((item) => sitemmap.set(item.id, item));
        }
        achema.items.forEach((item) => sitemmap.set(item.id, item));
        const yfd = sitemmap.get(yfield);
        if (!yfd) {
            return ydata;
        }
        let index = 0;
        for (const [_, item] of this.rowsMap) {
            if (this.hiddenrows.has(item.rowid))
                continue;
            index++;
            let mycolor = this.colors[(index % 3 === 0 ? 2 : index % 2 === 0 ? 1 : 0)];
            let doneyset = false;
            for (const fitem of item.entry) {
                if (fitem.cellid !== yfield && (colorid &&
                    fitem.cellid !== colorid)) {
                    continue;
                }
                if (!fitem.decrypted) {
                    if (fitem.ruleopen) {
                        this.parseCellRules(fitem, achema);
                    }
                    else {
                        this.decryptCell(fitem, achema);
                    }
                    fitem.decrypted = true;
                }
                let value = fitem.celldata;
                if (!value) {
                    continue;
                }
                if (fitem.cellid === yfield) {
                    ydata.push({
                        val: value,
                        color: mycolor,
                        id: item.rowid
                    });
                }
                else if (fitem.cellid === colorid) {
                    mycolor = fitem.celldata;
                    if (doneyset) {
                        ydata[ydata.length - 1].color = mycolor;
                    }
                }
            }
        }
        return ydata;
    }
    buildChart(id, xfield, yfield, ctype, achema, colorid) {
        var _a, _b, _c;
        const cstate = this.chartstate.get(id);
        if (!cstate) {
            return;
        }
        const sitemmap = new Map();
        if (achema.parentschema) {
            achema.parentschema.items.forEach((item) => sitemmap.set(item.id, item));
        }
        achema.items.forEach((item) => sitemmap.set(item.id, item));
        const field = sitemmap.get(xfield);
        const yfd = sitemmap.get(yfield);
        if (!field || !yfd) {
            return;
        }
        cstate.setBuildingChart(true);
        // now lets build this!!
        cstate.setChartType(ctype);
        cstate.setYName(yfd.name);
        cstate.setXName(field.name);
        cstate.setYID(yfd.id);
        cstate.setXID(field.id);
        if (colorid) {
            const cofile = sitemmap.get(colorid);
            if (cofile) {
                cstate.setColorFieldid(cofile === null || cofile === void 0 ? void 0 : cofile.id);
                cstate.setColorFieldName(cofile.name);
            }
        }
        const xdata = [];
        const ydata = [];
        let index = 0;
        for (const [_, item] of this.rowsMap) {
            if (this.hiddenrows.has(item.rowid))
                continue;
            index++;
            let mycolor = this.colors[(index % 3 === 0 ? 2 : index % 2 === 0 ? 1 : 0)];
            let doneyset = false;
            for (const fitem of item.entry) {
                if (fitem.cellid !== xfield && fitem.cellid !== yfield && (colorid &&
                    fitem.cellid !== colorid)) {
                    continue;
                }
                if (!fitem.decrypted) {
                    if (fitem.ruleopen) {
                        this.parseCellRules(fitem, achema);
                    }
                    else {
                        this.decryptCell(fitem, achema);
                    }
                    fitem.decrypted = true;
                }
                let value = fitem.celldata;
                if (!value) {
                    continue;
                }
                if (fitem.cellid === xfield) {
                    const sitem = sitemmap.get(fitem.cellid);
                    if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.Text) {
                        let tmp = document.createElement("DIV");
                        tmp.innerHTML = value;
                        value = (_a = tmp.textContent) !== null && _a !== void 0 ? _a : '';
                    }
                    else if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.Date) {
                        const sitem = sitemmap.get(fitem.cellid);
                        value = (_c = DateLib.displayDateWIthFormat(fitem.celldata, sitem.inlcudetime, (_b = sitem.excludedate) !== null && _b !== void 0 ? _b : false)) !== null && _c !== void 0 ? _c : '';
                    }
                    else if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.Choice) {
                        let parts = fitem.celldata.split(",");
                        if (parts.length > 0) {
                            if (!sitem.multiselect) {
                                parts = [parts[0]];
                            }
                            const items = sitem.items.filter((it) => parts.indexOf(it.id) !== -1);
                            if (items.length > 0) {
                                value = items.map((it) => it.name).join(", ");
                            }
                            else {
                                value = "";
                            }
                        }
                        else {
                            value = "";
                        }
                    }
                    else if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.Boolean) {
                        value = (fitem.celldata === 'true' ? 'Checked' : 'Unchecked');
                    }
                    else if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) == SchemaItemType.UserChoice) {
                        let parts = fitem.celldata.split(",");
                        if (parts.length > 0) {
                            if (!sitem.multiselect) {
                                parts = [parts[0]];
                            }
                            const items = Array.from(this.activeusers.values()).filter((it) => parts.indexOf(it.id) !== -1);
                            if (items.length > 0) {
                                value = items.map((it) => it.name).join(", ");
                            }
                            else {
                                value = "";
                            }
                        }
                        else {
                            value = "";
                        }
                    }
                    xdata.push(value);
                }
                else if (fitem.cellid === yfield) {
                    doneyset = true;
                    ydata.push({
                        val: value,
                        color: mycolor,
                        id: item.rowid
                    });
                }
                else if (fitem.cellid === colorid) {
                    mycolor = fitem.celldata;
                    if (doneyset) {
                        ydata[ydata.length - 1].color = mycolor;
                    }
                }
            }
        }
        cstate.setXDataset(xdata);
        cstate.setYDataset(ydata);
        cstate.setBuildingChart(false);
    }
    async filterRowsBasedOnAuto(autocolids, myid, sh, furtherfilter, workrows = new Map()) {
        var _a;
        let itemid = this.selecteditemid;
        const visrows = Array.from(workrows.size > 0 ? workrows.values() : this.uirows.values()).filter((ui) => furtherfilter.has(ui.rowid));
        const mypermissions = new Set();
        const readonlyids = new Set();
        const enmap = new Map();
        for (let i = 0; i < visrows.length; i++) {
            if (i % 1000 === 0)
                await timeout(20);
            if (itemid !== this.selecteditemid)
                return furtherfilter;
            const vr = visrows[i];
            if (!enmap.has(vr.rowid)) {
                enmap.set(vr.rowid, new Map());
            }
            const map = new Map();
            for (const en of vr.entry) {
                if (!map.has(en.cellid)) {
                    map.set(en.cellid, []);
                }
                (_a = map.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
            let hasmatch = false;
            for (const col of autocolids) {
                if (col.autocolumnid) {
                    const ens = map.get(col.autocolumnid);
                    if (ens) {
                        let colmatch = false;
                        for (const en of ens) {
                            if (!en.decrypted && sh) {
                                if (en.ruleopen) {
                                    this.parseCellRules(en, sh);
                                }
                                else {
                                    this.decryptCell(en, sh);
                                }
                            }
                            const cd = en.celldata.split(",");
                            if (cd.indexOf(myid) !== -1) {
                                // lets 
                                colmatch = true;
                                break;
                            }
                        }
                        if (colmatch) {
                            for (const p of col.mypermissions) {
                                mypermissions.add(p);
                            }
                            for (const r of col.readonlyfields) {
                                readonlyids.add(r);
                            }
                            hasmatch = true;
                        }
                    }
                }
            }
            if (!hasmatch) {
                vr.setVisibility(true);
                const rr = this.rowsMap.get(vr.rowid);
                if (rr) {
                    rr.vis = false;
                }
                furtherfilter.delete(vr.rowid);
            }
            else {
                this.addRowPermission(vr.rowid, Array.from(mypermissions));
                this.addRowReadonly(vr.rowid, Array.from(readonlyids));
            }
        }
        return furtherfilter;
    }
    createFilterMeta(achema, items) {
        var _a, _b, _c, _d;
        const sfils = [];
        for (const fl of achema.filters) {
            const fd = fl.data;
            const csi = items.get(fl.columnid);
            if (csi) {
                const v1 = (_a = fd.value) !== null && _a !== void 0 ? _a : '';
                const v2 = (_b = fd.value2) !== null && _b !== void 0 ? _b : '';
                let v1f = -1;
                let v2f = -1;
                if (csi.type === SchemaItemType.Date) {
                    if (v1 !== '') {
                        try {
                            v1f = parseInt(v1);
                        }
                        catch (ex) { }
                    }
                    if (v2 !== '') {
                        try {
                            v2f = parseInt(v2);
                        }
                        catch (ex) { }
                    }
                }
                else if (csi.type === SchemaItemType.Number) {
                    if (v1 !== '') {
                        try {
                            v1f = parseFloat(v1);
                        }
                        catch (ex) { }
                    }
                    if (v2 !== '') {
                        try {
                            v2f = parseFloat(v2);
                        }
                        catch (ex) { }
                    }
                }
                const sm = {
                    id: fl.columnid,
                    value: (_c = fd === null || fd === void 0 ? void 0 : fd.value) !== null && _c !== void 0 ? _c : '',
                    fielditem: csi,
                    value2: (_d = fd === null || fd === void 0 ? void 0 : fd.value2) !== null && _d !== void 0 ? _d : '',
                    v2float: v1f,
                    vfloat: v2f,
                    optype: fd.op,
                    numbercompare: (csi.type === SchemaItemType.Date || csi.type === SchemaItemType.Number)
                };
                sfils.push(sm);
            }
        }
        return sfils;
    }
    async filterAndRemoveRows(filter, sh, updaterow = new Map()) {
        var _a, _b, _c;
        let itemid = this.selecteditemid;
        const showrows = [];
        let count = 0;
        let wrow = updaterow.size > 0 ? updaterow : this.rowsMap;
        rowloop: for (const [_, row] of wrow) {
            const entries = row.entry;
            if (count > 1000) {
                count = 0;
                await timeout(20);
            }
            if (itemid !== this.selecteditemid)
                return;
            count++;
            const enmap = new Map();
            for (const en of entries) {
                if (!enmap.has(en.cellid)) {
                    enmap.set(en.cellid, []);
                }
                (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
            for (const fil of filter) {
                const ens = enmap.get(fil.id);
                if (!ens || ens.length === 0) {
                    this.hiderow(row.rowid);
                    continue rowloop;
                }
                let nomatch = true;
                entryloop: for (const en of ens) {
                    if (!en.decrypted && sh) {
                        if (en.ruleopen) {
                            this.parseCellRules(en, sh);
                        }
                        else {
                            this.decryptCell(en, sh);
                        }
                    }
                    if (fil.fielditem.type === SchemaItemType.FormInput || fil.fielditem.type === SchemaItemType.URL) {
                        if (en.celldata.toLowerCase().indexOf(fil.value.toLowerCase()) === -1) {
                            continue entryloop;
                        }
                    }
                    else if (fil.fielditem.type === SchemaItemType.Text) {
                        const est = (_b = LWStripHTML(en.celldata)) !== null && _b !== void 0 ? _b : '';
                        const cval = (_c = LWStripHTML(fil.value)) !== null && _c !== void 0 ? _c : '';
                        if (est.trim().toLowerCase().indexOf(cval.trim().toLowerCase()) === -1) {
                            continue entryloop;
                        }
                    }
                    else if ((fil.fielditem.type === SchemaItemType.Choice) ||
                        (fil.fielditem.type === SchemaItemType.UserChoice)) {
                        let vals = fil.value.split(",");
                        let carr = en.celldata.split(",");
                        if (!fil.fielditem.multiselect) {
                            carr.splice(1, carr.length);
                        }
                        const fv = vals.filter(val => carr.includes(val));
                        if (fv.length === 0) {
                            continue entryloop;
                        }
                    }
                    else if (fil.optype === SearchMetadataOp.Equal) {
                        if (en.celldata.toLowerCase() !== fil.value.toLowerCase()) {
                            continue entryloop;
                        }
                    }
                    else if (fil.optype === SearchMetadataOp.GreateEqual ||
                        fil.optype === SearchMetadataOp.Greater ||
                        fil.optype === SearchMetadataOp.Less ||
                        fil.optype === SearchMetadataOp.LessEqual) {
                        try {
                            const v1 = parseFloat(en.celldata);
                            const v2 = parseFloat(fil.value);
                            if (fil.optype === SearchMetadataOp.GreateEqual && v1 < v2) {
                                continue entryloop;
                            }
                            else if (fil.optype === SearchMetadataOp.Greater && v1 <= v2) {
                                continue entryloop;
                            }
                            else if (fil.optype === SearchMetadataOp.Less && v1 >= v2) {
                                continue entryloop;
                            }
                            else if (fil.optype === SearchMetadataOp.LessEqual && v1 > v2) {
                                continue entryloop;
                            }
                        }
                        catch (ex) {
                            continue entryloop;
                        }
                    }
                    else if (fil.optype === SearchMetadataOp.InBetween) {
                        try {
                            const v1 = parseFloat(en.celldata);
                            const v2 = parseFloat(fil.value);
                            const v3 = parseFloat(fil.value2);
                            if (v1 >= v2 && v1 <= v3) {
                                // just leave it!!
                            }
                            else {
                                continue entryloop;
                            }
                        }
                        catch (ex) {
                            continue entryloop;
                        }
                    }
                    nomatch = false;
                    break;
                }
                // if we find a no
                if (nomatch) {
                    this.hiderow(row.rowid);
                    continue rowloop;
                }
            }
            showrows.push(row.rowid);
        }
        return showrows;
    }
    arrangeListCells(en) {
        // the way to arrange these will be via the 
        var _a;
        en.sort((en1, en2) => {
            var _a, _b;
            const s1 = (_a = en1.subrowindex) !== null && _a !== void 0 ? _a : -1;
            const s2 = (_b = en2.subrowindex) !== null && _b !== void 0 ? _b : -1;
            if (s1 === -1 && s2 === -1) {
                return 0;
            }
            if (s1 === -1) {
                return 1;
            }
            if (s2 === -1) {
                return -1;
            }
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (parts1[0] === parts2[0]) {
                // if(parts1.length < 2 || parts2.length < 2) {
                //     return -1;
                // }
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        let trow = [];
        const rows = [];
        for (const e of en) {
            const s1 = (_a = e.subrowindex) !== null && _a !== void 0 ? _a : -1;
            if (s1 !== -1) {
                if (trow.indexOf(s1) === -1) {
                    trow.push(s1);
                }
            }
        }
        return trow;
    }
    addRuleEntryToRow(row, sh) {
        var _a;
        const rulebycellid = new Map();
        if (sh && sh.rules) {
            for (const rule of sh.rules) {
                rulebycellid.set(rule.colid, rule);
            }
        }
        const customcols = new Set();
        if (sh) {
            for (const item of sh.items) {
                if (item.type === SchemaItemType.CustomDisplayCol) {
                    customcols.add(item.id);
                }
                else if (item.type === SchemaItemType.List) {
                    const listItem = item;
                    for (const ei of listItem.items) {
                        if (ei.item.type === SchemaItemType.CustomDisplayCol) {
                            customcols.add(listItem.id + "----" + ei.id);
                        }
                    }
                }
            }
        }
        const rowrules = this.rowRules.get(sh.id + "~~~" + row.rowid);
        const rowrmap = new Map();
        if (rowrules) {
            for (const r of rowrules.data) {
                rowrmap.set(r.colid, r);
            }
        }
        for (const [k, v] of rulebycellid) {
            if (!rowrmap.has(k)) {
                rowrmap.set(k, v);
            }
        }
        const emap = new Map();
        for (const en of row.entry) {
            const id = en.cellid.split("----");
            if (!emap.has(id[0])) {
                emap.set(id[0], []);
            }
            let colrule = rulebycellid.get(en.cellid);
            if (colrule)
                colrule.isshrule = true;
            if (rowrmap.size > 0) {
                let cr = rowrmap.get(en.cellid);
                if (cr) {
                    colrule = cr;
                }
            }
            if (en.ruleopen) {
                //if this already had it
                en.decrypted = false;
            }
            en.ruleopen = colrule;
            if (en.ruleopen) {
                // new rule 
                en.decrypted = false;
            }
            (_a = emap.get(id[0])) === null || _a === void 0 ? void 0 : _a.push(en);
        }
        for (const k of customcols) {
            if (k.indexOf("----") !== -1) {
                // lets add entry to this as well now!
                const parts = k.split("----");
                const ens = emap.get(parts[0]);
                if (!ens || (ens === null || ens === void 0 ? void 0 : ens.length) === 0)
                    continue;
                // we have subrows lets add entry for each
                const subrows = this.arrangeListCells(ens);
                const entrymap = new Map();
                // now we have these subrows lets see if we can make sense of this now
                for (const en of ens) {
                    if (!en.subrowindex)
                        continue;
                    entrymap.set(en.cellid + "----" + en.subrowindex, en);
                }
                for (const sr of subrows) {
                    const sid = k + "----" + sr;
                    if (!entrymap.has(sid)) {
                        let en = {
                            celldata: '',
                            cellfindstr: '',
                            cellid: k,
                            schemaid: sh.id,
                            rowindex: row.rowindex,
                            rowid: row.rowid,
                            subrowindex: sr,
                            id: LetrwingCrypto.getUUID(),
                            sortstring: Math.random(),
                            decrypted: true
                        };
                        const id = en.cellid.split("----");
                        if (!emap.has(id[0])) {
                            emap.set(id[0], []);
                        }
                        row.entry.push(en);
                    }
                }
            }
            else {
                if (!emap.has(k)) {
                    let en = {
                        celldata: '',
                        cellfindstr: '',
                        cellid: k,
                        schemaid: sh.id,
                        rowindex: row.rowindex,
                        rowid: row.rowid,
                        subrowindex: "",
                        id: LetrwingCrypto.getUUID(),
                        sortstring: Math.random(),
                        decrypted: true
                    };
                    const id = en.cellid.split("----");
                    if (!emap.has(id[0])) {
                        emap.set(id[0], []);
                    }
                    row.entry.push(en);
                }
            }
        }
        // now lets go over all cells needing rule
        for (const [k, v] of rowrmap) {
            if (k.indexOf("----") !== -1) {
                // lets add entry to this as well now!
                const parts = k.split("----");
                const ens = emap.get(parts[0]);
                if (!ens || (ens === null || ens === void 0 ? void 0 : ens.length) === 0)
                    continue;
                // we have subrows lets add entry for each
                const subrows = this.arrangeListCells(ens);
                const entrymap = new Map();
                // now we have these subrows lets see if we can make sense of this now
                for (const en of ens) {
                    if (!en.subrowindex)
                        continue;
                    entrymap.set(en.cellid + "----" + en.subrowindex, en);
                }
                for (const sr of subrows) {
                    const sid = k + "----" + sr;
                    if (!entrymap.has(sid)) {
                        let en = {
                            celldata: '',
                            cellfindstr: '',
                            cellid: k,
                            schemaid: sh.id,
                            rowindex: row.rowindex,
                            rowid: row.rowid,
                            subrowindex: sr,
                            id: LetrwingCrypto.getUUID(),
                            sortstring: Math.random(),
                            decrypted: false
                        };
                        const id = en.cellid.split("----");
                        if (!emap.has(id[0])) {
                            emap.set(id[0], []);
                        }
                        let colrule = rulebycellid.get(en.cellid);
                        if (colrule)
                            colrule.isshrule = true;
                        if (rowrmap.size > 0) {
                            let cr = rowrmap.get(en.cellid);
                            if (cr) {
                                colrule = cr;
                            }
                        }
                        en.ruleopen = colrule;
                        row.entry.push(en);
                    }
                }
            }
            else {
                if (!emap.has(k)) {
                    let en = {
                        celldata: '',
                        cellfindstr: '',
                        cellid: k,
                        schemaid: sh.id,
                        rowindex: row.rowindex,
                        rowid: row.rowid,
                        subrowindex: "",
                        id: LetrwingCrypto.getUUID(),
                        sortstring: Math.random(),
                        decrypted: false
                    };
                    const id = en.cellid.split("----");
                    if (!emap.has(id[0])) {
                        emap.set(id[0], []);
                    }
                    let colrule = rulebycellid.get(en.cellid);
                    if (colrule) {
                        colrule.isshrule = true;
                    }
                    if (rowrmap.size > 0) {
                        let cr = rowrmap.get(en.cellid);
                        if (cr) {
                            colrule = cr;
                        }
                    }
                    en.ruleopen = colrule;
                    row.entry.push(en);
                }
            }
        }
    }
    async addRuleEntryToRows(sh, updaterows) {
        let itemit = this.selecteditemid;
        let i = 0;
        for (const row of updaterows.values()) {
            if (itemit !== this.selecteditemid)
                return;
            this.addRuleEntryToRow(row, sh);
            if (i > 0 && i % 1000 === 0)
                await timeout(50);
            i++;
        }
    }
    addRowRule(rule) {
        if (rule.data && rule.data.length > 0) {
            this.rowRules.set(rule.id, rule);
        }
    }
    clearRowMap() {
        this.rowsMap.clear();
        this.rowsarrids.splice(0, this.rowsarrids.length);
        // this.uirows.splice(0, this.uirows.length);
        this.uirows.clear();
    }
    addRow(r, ignoremobx) {
        this.rowsMap.set(r.rowid, r);
        this.rowsarrids.push(r);
        if (!ignoremobx) {
            const sr = new SchemaUIRow();
            sr.addEntries(r.entry);
            sr.rowid = r.rowid;
            sr.rowindex = r.rowindex;
            sr.childrenschemalist = r.childrenschemalist;
            sr.linkedids = r.linkedids;
            sr.objsdec = r.objsdec;
            sr.shsdec = r.shsdec;
            sr.referenceids = r.referenceids;
            this.uirows.set(r.rowid, sr);
        }
    }
    addRowToRemoteSheet(entry, schemaid) {
        let dropws = this.depschemarows.get(schemaid);
        if (!dropws) {
            dropws = [];
        }
        const row = {
            rowid: entry[0].rowid,
            rowindex: entry[0].rowindex,
            entry: entry,
        };
        dropws.push(row);
        this.depschemarows.set(schemaid, dropws);
    }
    // now we should be able to add data to any remote 
    // 
    async addRowToConnectedWorksheet(entry, schemaid) {
        var _a, _b;
        let sh = this.schemasmap.get(schemaid);
        const wvv = this.getActiveWebview();
        if (sh && wvv) {
            // check for mandatory fiels?
            const sentr = [];
            for (const en of entry) {
                const eben = LetrwingCrypto.secretBoxWithNonce(en.celldata, sh.accesskey, sh.nonce).encryptmsg;
                const sen = Object.assign(Object.assign({}, en), { celldata: eben, cellfindstr: eben });
                sentr.push(sen);
            }
            console.log("we here ");
            console.log(sentr);
            const arreq = {
                letrid: wvv.letrid,
                schemaid: schemaid,
                entries: sentr,
                rowid: "",
                assignedto: [],
                updateassignee: false,
                dataid: this.getActiveFormUserId(),
                notifyviews: [],
                colusers: [],
                fromschemaid: (_b = (_a = wvv.sh) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''
            };
            const url = ServerPath.pwaaddToConnectedWorksheet;
            try {
                const res = await comm.postWithNetwork(url, arreq);
                if (res === 'NetworkError') {
                    return 'NetworkError';
                }
                if (res && res !== 'Unauthorised') {
                    this.addRowToRemoteSheet(res, schemaid);
                    return res;
                }
                return 'Failed';
            }
            catch (ex) {
                console.log(ex);
                return 'NetworkError';
            }
        }
    }
    addRowToMobx(r) {
        const sr = new SchemaUIRow();
        sr.addEntries(r.entry);
        sr.rowid = r.rowid;
        sr.rowindex = r.rowindex;
        sr.childrenschemalist = r.childrenschemalist;
        sr.linkedids = r.linkedids;
        sr.objsdec = r.objsdec;
        sr.shsdec = r.shsdec;
        sr.referenceids = r.referenceids;
        sr.setVisibility(true);
        // console.log('this.uirows.length '+this.uirows.length); 
        // this.uirows.push(sr);
        this.uirows.set(r.rowid, sr);
    }
    async addRowsToMobs(updates = false, rows = []) {
        let index = 0;
        const itemit = this.selecteditemid;
        if (updates) {
            for (const r of rows) {
                if (itemit !== this.selecteditemid)
                    return;
                r.vis = false;
                this.cacheui.delete(r.rowid);
                this.addRowToMobx(r);
            }
        }
        else {
            for (const r of this.rowsarrids) {
                if (itemit !== this.selecteditemid)
                    return;
                this.addRowToMobx(r);
                if (index % 500 === 0)
                    await timeout(10);
                index++;
            }
        }
    }
    async addRowInline(row, clear = true, ignoremobx = false) {
        const itemit = this.selecteditemid;
        if (clear) {
            this.clearRowMap();
        }
        let index = 0;
        for (const r of row) {
            if (itemit !== this.selecteditemid)
                return;
            this.addRow(r, ignoremobx);
            if (index > 0 && index % 1000 == 0) {
                await timeout(100);
            }
            ;
            index++;
        }
        await timeout(10);
    }
    addListItem(id) {
        this.listitems.push(id);
    }
    addFormmessage(ms) {
        this.formmessage = ms;
    }
    getLetrid() {
        var _a, _b;
        let achema = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            achema = (_a = this.activeviews.get(this.selecteditemid.substring("web-".length))) === null || _a === void 0 ? void 0 : _a.shopen;
        }
        else {
            achema = (_b = this.activeforms.get(this.selecteditemid)) === null || _b === void 0 ? void 0 : _b.sh;
        }
        if (achema !== undefined) {
            return achema.letrid;
        }
        return "";
    }
    checkSchemaCellHasRule(cellid) {
        var _a, _b;
        let achema = undefined;
        if (this.selecteditemid.startsWith("web-")) {
            achema = (_a = this.activeviews.get(this.selecteditemid.substring("web-".length))) === null || _a === void 0 ? void 0 : _a.shopen;
        }
        else {
            achema = (_b = this.activeforms.get(this.selecteditemid)) === null || _b === void 0 ? void 0 : _b.sh;
        }
        if (!achema) {
            return false;
        }
        if (achema.rules) {
            for (const ar of achema.rules) {
                if (ar.colid === cellid) {
                    return true;
                }
            }
        }
        return false;
    }
    removeListItem(id) {
        const index = this.listitems.indexOf(id);
        if (index > -1) {
            this.listitems.splice(index, 1);
        }
    }
    resetListItems() {
        this.listitems.splice(0, this.listitems.length);
    }
    setRebuildingCustom(fetch) {
        this.rebuildingcustom = fetch;
    }
    setIgnoreCustomComponentId(re) {
        this.ignorerebuildcustomcomponent.add(re);
    }
    removeIgnoreCustomComponentId(re) {
        this.ignorerebuildcustomcomponent.delete(re);
    }
    addFormEntry(id, val, ignorerebuilding = false, forceids) {
        this.formentry.set(id, val);
        if (ignorerebuilding && !forceids)
            return;
        if (!ignorerebuilding) {
            this.setIgnoreCustomComponentId(id);
            this.setRebuildingCustom(true);
            setTimeout(() => {
                this.removeIgnoreCustomComponentId(id);
                this.setRebuildingCustom(false);
            }, 10);
        }
        else if (forceids) {
            for (const fc of forceids) {
                this.addRebuildId(fc);
            }
            setTimeout(() => {
                for (const fc of forceids) {
                    this.removeRebuildId(fc);
                }
            }, 50);
        }
    }
    addRebuildId(id) {
        this.forcerebuildcustom.add(id);
    }
    removeRebuildId(id) {
        this.forcerebuildcustom.delete(id);
    }
    getCountOfItemsSaving() {
        const fds = Array.from(this.alljobs.values()).filter((f) => f.data.webform.id === this.selecteditemid &&
            (f.status !== JobStatus.Done && f.status !== JobStatus.Error));
        return fds.length;
    }
    setLoadingContents(con) {
        this.loadingcontents = con;
    }
    setLoadingItem(con) {
        this.loadingitem = con;
    }
    setPwaLoginState(st) {
        this.pwaauthstate = st;
        if (st === PwaAuthState.Loggedout) {
            if (this.currentsessiontimeout) {
                clearTimeout(this.currentsessiontimeout);
            }
            this.orgmenustore.reset();
            window.location.hash = "";
        }
    }
    setSelectItemId(id, updatehash = true) {
        if (id && id !== this.selecteditemid) {
            if (id !== this.lastitemid) {
                this.resetSchemaForm();
            }
        }
        this.selecteditemid = id;
        if (updatehash) {
            let hash = "#" + this.selectedorgid;
            if (id) {
                hash += "~~~" + this.selecteditemid;
            }
            window.location.hash = hash;
        }
    }
    async wipe() {
        await PWaDb.wipeDb();
        await comm.get(ServerPath.pwaWipe);
        comm.setClientId("");
        comm.setSessionid("");
        this.reset();
        this.setPwaLoginState(PwaAuthState.Loggedout);
    }
    async wipeDevice(id, name) {
        const req = {
            id: id,
            sessionname: name
        };
        const res = await comm.post(ServerPath.pwaWipeDevice, req);
        if (res !== undefined && res) {
            this.usermenu.removeDevices(id);
        }
    }
    setNotificationRegistered(resg) {
        this.notificationregistered = resg;
    }
    reset() {
        this.setJobUpdate(false);
        this.sessionname = "";
        this.selectedorgid = "";
        this.selecteditemid = "";
        this.loadingcontents = false;
        this.loadingitem = false;
        this.activeforms.clear();
        this.activeviews.clear();
        this.orgmenustore.reset();
        this.usermenu.reset();
        this.addFormmessage("");
        this.setSelectOrgId("");
        this.resetSchemaForm();
        this.notificationregistered = false;
        this.alljobs.clear();
        window.localStorage.removeItem("notificationregistered");
        this.currentroute = undefined;
        if (this.currentsessiontimeout) {
            clearTimeout(this.currentsessiontimeout);
        }
        this.pwaeditstore.reset();
    }
    getJobStatsForSelectedItem() {
        let newjobs = 0;
        let editjobs = 0;
        let deletrows = 0;
        for (const [_, job] of this.alljobs) {
            if (job.data.webform.id === this.selecteditemid) {
                if (job.status !== JobStatus.Pending && job.status !== JobStatus.Progress)
                    continue;
                if (job.jobtype === JobType.WebForm) {
                    newjobs++;
                }
                else if (job.jobtype === JobType.EditRow) {
                    editjobs++;
                }
                else if (job.jobtype === JobType.DeleteRows) {
                    deletrows++;
                }
            }
        }
        return [newjobs, editjobs, deletrows];
    }
    addDataJobToMap(job) {
        this.alljobs.set(job.jobid, job);
        this.actuallyAddJob(job);
    }
    async actuallyAddJob(job) {
        var _a, _b, _c;
        if (job && job.data.webform && job.data.webform.id === this.selecteditemid && job.status === JobStatus.Done) {
            // maybe go over the list find the last know item?
            let loadtime = job.jobtime;
            await this.loadRowsForActiveForm(this.lastloadtime, true, true);
            const updatedentries = job.updatedentries;
            if (updatedentries && updatedentries.length > 0) {
                this.pwaeditstore.setSelectedId(updatedentries[0].rowid);
                const wv = this.getActiveWebview();
                if (wv && !this.hiddenrows.has(this.pwaeditstore.selectedid)) {
                    let i = 0;
                    let index = 0;
                    let searchindex = (_a = this.uirows.get(this.pwaeditstore.selectedid)) === null || _a === void 0 ? void 0 : _a.rowindex;
                    let srows = Array.from(this.uirows.values()).filter((r) => !this.hiddenrows.has(r.rowid));
                    srows.sort((r1, r2) => r1.rowindex - r2.rowindex);
                    if (searchindex !== undefined) {
                        let val = this.binary_search(searchindex, srows);
                        if (val !== null) {
                            index = val;
                        }
                    }
                    if (wv.reverseorder) {
                        index = srows.length - 1 - index;
                    }
                    (_b = this.tableref) === null || _b === void 0 ? void 0 : _b.scrollToIndex(index);
                    (_c = this.panelref) === null || _c === void 0 ? void 0 : _c.scrollToIndex(index);
                }
            }
        }
        await comm.get(ServerPath.index);
    }
    removeDataJob(id) {
        // we need to load this 
        this.alljobs.delete(id);
    }
    setSelectOrgId(id, updatehash = true) {
        this.selectedorgid = id;
        if (updatehash) {
            let hash = "";
            if (id) {
                hash += "#" + id;
            }
            window.location.hash = hash;
        }
        localStorage.setItem("lastorg", id);
    }
    async verifyCode(email, phonenumber, code) {
        const req = {
            email: email,
            phone: phonenumber,
            cc: "0091",
            code: code
        };
        if (email) {
            const res = await comm.post(ServerPath.validateEmailCode, req);
            return res !== null && res !== void 0 ? res : undefined;
        }
        else if (phonenumber) {
            const res = await comm.post(ServerPath.validatePhoneCode, req);
            return res !== null && res !== void 0 ? res : undefined;
        }
        return undefined;
    }
    async sendAuthCode(email, phonenumber) {
        const req = {
            email: email,
            phone: phonenumber,
            cc: "0091",
            code: ''
        };
        if (email) {
            const res = await comm.post(ServerPath.sendPwaEmailAuth, req);
            return res !== null && res !== void 0 ? res : false;
        }
        else if (phonenumber) {
            const res = await comm.post(ServerPath.sendPwaPhoneAuth, req);
            return res !== null && res !== void 0 ? res : false;
        }
        return false;
    }
    async checkAppState() {
        var _a;
        comm.setTenantId("pwa");
        try {
            let n = 0;
            while (!await PWaDb.init() && n < 5) {
                await PWaDb.deleteDB();
                n++;
            }
            if (!PWaDb.setupReady()) {
                return false;
            }
            (_a = navigator.serviceWorker.controller) === null || _a === void 0 ? void 0 : _a.postMessage({ type: "dbready" });
            return true;
        }
        catch (ex) {
            console.log(ex);
        }
        return false;
    }
    convertOrgDataToAppUserOpen(ordata) {
        const ret = [];
        const doneor = new Set();
        for (const ogd of ordata) {
            if (doneor.has(ogd.user.orgid)) {
                continue;
            }
            // lets do this now
            const r = Object.assign(Object.assign({}, ogd.user), { code: '', privatekey: '', isopen: false });
            const b = ogd.box;
            try {
                if (b) {
                    const bst = LetrwingCrypto.decryptSecretBox(b, this.currentcode);
                    if (bst) {
                        const od = JSON.parse(bst);
                        r.code = od.code;
                        r.privatekey = od.privatekey;
                        r.isopen = true;
                    }
                }
            }
            catch (ex) {
                console.log(ex);
            }
            doneor.add(r.orgid);
            ret.push(r);
        }
        return ret;
    }
    clearWebFormMap() {
        this.activeforms.clear();
    }
    clearWebViewMap() {
        this.activeviews.clear();
    }
    cleanParsingEntry() {
        this.parsingentry.clear();
    }
    clearFormInmemoryData() {
        this.depschemarows.clear();
        this.schemasmap.clear();
    }
    addDetailToHash() {
        window.location.hash = window.location.hash + "~~~detail";
    }
    addReportToHash() {
        window.location.hash = window.location.hash + "~~~reports";
    }
    addDocumentToHash(id, rowid, entryid) {
        window.location.hash = window.location.hash + "~~~Doc~~" + rowid + "~~" + entryid + "~~" + id;
    }
    setActiveViewTitle(title) {
        this.activeviewtitle = title;
    }
    setShowDetail(show) {
        this.showdetail = show;
    }
    handleRoute(route, cb) {
        var _a;
        if (this.pwaauthstate !== PwaAuthState.Loggedin)
            return;
        if (!this.currentroute || this.currentroute.id !== route.id) {
            this.currentroute = route;
            this.setLoadingContents(true);
            this.setSelectItemId("", false);
            this.loadContentAndAdd(this.currentroute.id, cb);
        }
        else {
            if (route.itemid) {
                if (this.currentroute.itemid != route.itemid) {
                    if (this.selecteditemid !== route.itemid) {
                        this.setSelectItemId(route.itemid, false);
                    }
                    this.cleanParsingEntry();
                    this.setLoadingItem(true);
                    if (route.itemid.startsWith("web-")) {
                        this.customSorter = undefined;
                        this.loadWebItem(route.itemid, route.id);
                    }
                    else {
                        this.loadItem(route.itemid, route.id);
                    }
                }
                if (route.view && route.view === 'detail') {
                    this.setShowReport(false);
                    this.setShowDetail(true);
                }
                else if (route.view && route.view === 'reports') {
                    this.setShowDetail(false);
                    this.setShowReport(true);
                }
                else if (route.view && route.view.startsWith('Doc~~')) {
                    const docparts = route.view.split("~~");
                    if (docparts.length > 3) {
                        const rowid = docparts[1];
                        const entryid = docparts[2];
                        const docid = docparts[3];
                        const doc = this.docmap.get(docid);
                        if (doc === undefined) {
                            this.getProjectObj(rowid, docid, entryid, (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.itemid, cb);
                        }
                        else {
                            cb === null || cb === void 0 ? void 0 : cb(doc);
                        }
                    }
                }
                else {
                    this.setShowDetail(false);
                    this.setShowReport(false);
                }
            }
            else {
                this.setSelectItemId("");
            }
        }
        this.currentroute = route;
    }
    async getProjectObj(rowid, docid, entryid, itemid, cb) {
        var _a, _b, _c;
        let schemaid = "";
        let letrkey = "";
        let letrid = "";
        if (itemid.startsWith("web-")) {
            const wv = this.activeviews.get(this.selecteditemid.substring("web-".length));
            if (wv && wv.shopen) {
                schemaid = wv.shopen.id;
                letrkey = (_a = wv.letrkey) !== null && _a !== void 0 ? _a : '';
                letrid = wv.letrid;
            }
        }
        else {
            const wf = this.activeforms.get(this.selecteditemid);
            if (wf && wf.sh) {
                schemaid = wf.sh.id;
                letrkey = wf.letrkey;
                letrid = wf.letrid;
            }
        }
        const req = {
            rowid: rowid, dataid: this.getActiveFormUserId(), schemaid: schemaid, projectid: docid
        };
        const res = await comm.postWithNetwork(ServerPath.getPwaProjectObject, req);
        if (res && res !== 'NetworkError' && res !== 'Unauthorised') {
            const result = ProjectStore.convertProjecObjectToProjectEntity([res], letrkey, letrid);
            if (result.length > 0) {
                this.initdocumentmap.clear();
                const initdoc = result[0];
                this.orgname = (_b = initdoc.origname) !== null && _b !== void 0 ? _b : initdoc.name;
                this.setTitle(initdoc.name);
                // this.setActiveItemid(initdoc.id, rowid);
                this.info = {
                    fileid: initdoc.documentreference,
                    letrid: letrid,
                    accesskey: initdoc.accesskey,
                    boxid: initdoc.accesscode,
                    version: (_c = initdoc.versions) !== null && _c !== void 0 ? _c : [],
                    replace: []
                };
                this.initdocumentmap.set(docid, result[0]);
                const metadata = await this.getFileMeta(initdoc.documentreference, "", rowid, this.getActiveFormUserId(), initdoc.accesskey, "", entryid, initdoc.id);
                if (metadata !== undefined) {
                    cb === null || cb === void 0 ? void 0 : cb(initdoc);
                }
            }
        }
    }
    async loadContentAndAdd(id, cb) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        this.clearWebFormMap();
        this.clearWebViewMap();
        const ress = await this.loadContentsForApp(id);
        for (const r of (_a = ress[0]) !== null && _a !== void 0 ? _a : []) {
            this.addWebForm(r);
        }
        for (const r of (_b = ress[1]) !== null && _b !== void 0 ? _b : []) {
            this.addWebView(r);
        }
        this.setLoadingContents(false);
        if ((_c = this.currentroute) === null || _c === void 0 ? void 0 : _c.itemid) {
            if (this.currentroute.itemid.startsWith("web-")) {
                const view = this.activeviews.get(this.currentroute.itemid.substring("web-".length));
                if (view) {
                    if (this.online) {
                        this.setSelectItemId(this.currentroute.itemid, false);
                        this.setLoadingItem(true);
                        this.loadWebItem(this.currentroute.itemid, this.currentroute.id);
                        if ((_d = this.currentroute) === null || _d === void 0 ? void 0 : _d.view.startsWith("Doc~~")) {
                            console.log("HERE reload");
                            const docparts = (_e = this.currentroute) === null || _e === void 0 ? void 0 : _e.view.split("~~");
                            if (docparts.length > 3) {
                                const rowid = docparts[1];
                                const entryid = docparts[2];
                                const docid = docparts[3];
                                console.log("HERE rowid" + rowid + " docid " + docid);
                                this.getProjectObj(rowid, docid, entryid, (_f = this.currentroute) === null || _f === void 0 ? void 0 : _f.itemid, cb);
                            }
                        }
                    }
                    else {
                        this.setSelectItemId("", false);
                    }
                }
            }
            else {
                const org = this.activeforms.get(this.currentroute.itemid);
                if (org) {
                    if (this.online || org.availoffline) {
                        // okay we have this now!!
                        this.setSelectItemId(org.id, false);
                        this.setLoadingItem(true);
                        this.loadItem(org.id, this.currentroute.id);
                        if (((_g = this.currentroute) === null || _g === void 0 ? void 0 : _g.view) === 'detail') {
                            this.setShowReport(false);
                            this.setShowDetail(true);
                        }
                        else if (((_h = this.currentroute) === null || _h === void 0 ? void 0 : _h.view) === 'reports') {
                            this.setShowDetail(false);
                            this.setShowReport(true);
                        }
                        else if ((_j = this.currentroute) === null || _j === void 0 ? void 0 : _j.view.startsWith("Doc~~")) {
                            console.log("HERE reload");
                            const docparts = (_k = this.currentroute) === null || _k === void 0 ? void 0 : _k.view.split("~~");
                            if (docparts.length > 3) {
                                const rowid = docparts[1];
                                const entryid = docparts[2];
                                const docid = docparts[3];
                                console.log("HERE rowid" + rowid + " docid " + docid);
                                this.getProjectObj(rowid, docid, entryid, (_l = this.currentroute) === null || _l === void 0 ? void 0 : _l.itemid, cb);
                            }
                        }
                    }
                    else {
                        this.setSelectItemId("", false);
                    }
                }
            }
        }
    }
    addWebForm(form) {
        this.activeforms.set(form.id, form);
    }
    deleteWebForm(form) {
        this.activeforms.delete(form);
    }
    addWebView(form) {
        this.activeviews.set(form.id, form);
    }
    deleteWebView(form) {
        this.activeviews.delete(form);
    }
    offlineWorkables() {
    }
    async parseWorkableResp(res, org) {
        var _a;
        let openforms = [];
        // now we have the forms lets do something neat here!!
        for (const fo of (_a = res.forms) !== null && _a !== void 0 ? _a : []) {
            let ub = undefined;
            if (fo.appformbox) {
                for (const apb of fo.appformbox) {
                    if (apb.id === org.id) {
                        ub = apb;
                        break;
                    }
                }
            }
            if (ub) {
                const pkey = org.privatekey;
                if (!pkey)
                    continue;
                const mycode = LetrwingCrypto.decryptRSABoxRawString(ub.formbox, { privatkey: pkey, publickey: org.publickey });
                if (!mycode)
                    continue;
                const openform = await this.decodeSchemaserverWeb(fo, mycode);
                if (openform) {
                    openforms.push(openform);
                }
            }
        }
        return openforms;
    }
    async loadContentsForApp(id) {
        var _a;
        if (!this.selectedorgid)
            return [[], []];
        const org = this.orgmenustore.orgsmap.get(id);
        if (!org || !org.isopen) {
            return [[], []];
        }
        // now lets get org box for offline support
        const orgbox = await PWaDb.getOrgBox(org.orgid);
        const req = {
            id: id
        };
        const res = await comm.postWithNetwork(ServerPath.getUserWorkables, req);
        if (res === 'Unauthorised') {
            return [[], []];
        }
        if (res === 'NetworkError') {
            let openforms = [];
            let openvies = [];
            if (orgbox === null || orgbox === void 0 ? void 0 : orgbox.forms) {
                // now we have the forms lets do something neat here!!
                for (const fo of (_a = orgbox.forms) !== null && _a !== void 0 ? _a : []) {
                    let ub = undefined;
                    if (fo.appformbox) {
                        for (const apb of fo.appformbox) {
                            if (apb.id === id) {
                                ub = apb;
                                break;
                            }
                        }
                    }
                    if (ub) {
                        const pkey = org.privatekey;
                        if (!pkey)
                            continue;
                        const mycode = LetrwingCrypto.decryptRSABoxRawString(ub.formbox, { privatkey: pkey, publickey: org.publickey });
                        if (!mycode)
                            continue;
                        const openform = await this.decodeSchemaserverWeb(fo, mycode);
                        if (openform) {
                            openforms.push(openform);
                        }
                    }
                }
            }
            if (orgbox === null || orgbox === void 0 ? void 0 : orgbox.views) {
                openvies = await this.parseLockedViews(orgbox.views, org);
            }
            return [openforms, openvies];
        }
        else if (res) {
            if (orgbox) {
                // lets open these forms now!!
                orgbox.forms = res.forms;
                orgbox.views = res.views;
                await PWaDb.addOrganisation(orgbox);
            }
            return [await this.parseWorkableResp(res, org), (orgbox === null || orgbox === void 0 ? void 0 : orgbox.views) ? await this.parseLockedViews(orgbox.views, org) : []];
        }
        return [[], []];
    }
    convertViewFilterToAcFilter(sv) {
        var _a, _b, _c, _d;
        const items = new Map();
        const ret = [];
        if (sv.shopen) {
            const achema = sv.shopen;
            for (const it of achema.items) {
                if (it.type == SchemaItemType.List) {
                    const lit = it;
                    if (lit.items) {
                        for (const li of lit.items) {
                            items.set(it.id + "----" + li.item.id, li.item);
                        }
                    }
                }
                else {
                    items.set(it.id, it);
                }
            }
        }
        else {
            return ret;
        }
        if (sv.filters && sv.filters.length > 0) {
            for (const fl of sv.filters) {
                const fd = fl.data;
                const csi = items.get(fl.columnid);
                if (csi) {
                    const v1 = (_a = fd.value) !== null && _a !== void 0 ? _a : '';
                    const v2 = (_b = fd.value2) !== null && _b !== void 0 ? _b : '';
                    let v1f = -1;
                    let v2f = -1;
                    if (csi.type === SchemaItemType.Date) {
                        if (v1 !== '') {
                            try {
                                v1f = parseInt(v1);
                            }
                            catch (ex) { }
                        }
                        if (v2 !== '') {
                            try {
                                v2f = parseInt(v2);
                            }
                            catch (ex) { }
                        }
                    }
                    else if (csi.type === SchemaItemType.Number) {
                        if (v1 !== '') {
                            try {
                                v1f = parseFloat(v1);
                            }
                            catch (ex) { }
                        }
                        if (v2 !== '') {
                            try {
                                v2f = parseFloat(v2);
                            }
                            catch (ex) { }
                        }
                    }
                    const sm = {
                        id: fl.columnid,
                        value: (_c = fd === null || fd === void 0 ? void 0 : fd.value) !== null && _c !== void 0 ? _c : '',
                        fielditem: csi,
                        value2: (_d = fd === null || fd === void 0 ? void 0 : fd.value2) !== null && _d !== void 0 ? _d : '',
                        v2float: v1f,
                        vfloat: v2f,
                        optype: fd.op,
                        numbercompare: (csi.type === SchemaItemType.Date || csi.type === SchemaItemType.Number)
                    };
                    ret.push(sm);
                }
            }
        }
        return ret;
    }
    checkRowNeedsAutoAssignment(entries, appuser, achemaid, otherview) {
        var _a;
        const apmap = new Map();
        for (const ap of appuser) {
            apmap.set(ap.id, ap);
        }
        const ret = [];
        const enmap = new Map();
        for (const en of entries) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
        }
        for (const view of otherview) {
            if (view.appformbox) {
                for (const abox of view.appformbox) {
                    if (abox.id.startsWith("schem-")) {
                        const col = abox.autocolumnid;
                        if (col) {
                            const ens = enmap.get(col);
                            if (ens) {
                                for (const en of ens) {
                                    const parts = en.celldata.split(",");
                                    const userboxes = [];
                                    for (const pp of parts) {
                                        const appuser = apmap.get(pp);
                                        if (appuser) {
                                            userboxes.push({
                                                id: appuser.id,
                                                databox: LetrwingCrypto.box(view.code, appuser.publickey),
                                                mypermissions: toJS(abox.mypermissions),
                                                readonlyfields: toJS(abox.readonlyfields),
                                            });
                                        }
                                        ret.push({
                                            rowid: en.rowid,
                                            columid: en.cellid,
                                            schemaid: achemaid,
                                            userboxes: userboxes,
                                            userids: parts,
                                            viewid: view.id,
                                            id: ''
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return ret;
    }
    checkWithViewNeedNotification(entries, otherview) {
        // we need first check if any view matches with this entry if they do then we just need to notify them!!
        const nvs = [];
        for (const v of otherview) {
            if (this.canShowRow(entries, this.convertViewFilterToAcFilter(v))) {
                if (v.customfilerjs) {
                    const f = new Function("config", v.customfilerjs);
                    let config = {
                        getschemarows: (sid) => this.getDependSchemaRows(sid),
                        decryptcell: (entry, sid, pr) => {
                            if (!entry.decrypted) {
                                this.decryptEntry(entry, sid);
                            }
                            return entry;
                        }
                    };
                    const builder = f(config);
                    if (!builder || !builder.canShowRow(entries)) {
                        continue;
                    }
                }
                nvs.push(v.id);
            }
        }
        return nvs;
    }
    async parseLockedViews(views, org) {
        const id = org.id;
        const openvies = [];
        if (views) {
            let i = 1;
            for (const fo of views !== null && views !== void 0 ? views : []) {
                try {
                    let ub = undefined;
                    let autouser = false;
                    if (fo.appformbox) {
                        for (const apb of fo.appformbox) {
                            if (apb.id === id) {
                                ub = apb;
                                break;
                            }
                        }
                    }
                    if (!ub) {
                        if (fo.autoformbox) {
                            for (const apb of fo.autoformbox) {
                                if (apb.id === id) {
                                    ub = apb;
                                    autouser = true;
                                    break;
                                }
                            }
                        }
                    }
                    if (ub) {
                        const pkey = org.privatekey;
                        if (!pkey)
                            continue;
                        const mycode = LetrwingCrypto.decryptRSABoxRawString(ub.databox, { privatkey: pkey, publickey: org.publickey });
                        if (!mycode)
                            continue;
                        const openform = await this.decodeSchemaServerWebView([fo], mycode);
                        if (openform && openform.length > 0) {
                            if (i % 3 === 0) {
                                openform[0].bgcolor = this.viewscolorcode[2][0];
                            }
                            else if (i % 2 === 0) {
                                openform[0].bgcolor = this.viewscolorcode[1][0];
                            }
                            else {
                                openform[0].bgcolor = this.viewscolorcode[0][0];
                            }
                            i++;
                            openform[0].autousersview = autouser;
                            openvies.push(openform[0]);
                        }
                    }
                }
                catch (ex) { }
            }
        }
        return openvies;
    }
    decodeSchemaServerWebViewViaLK(res, sh, letrkey) {
        var _a;
        let code = "";
        if (res.codebox) {
            code = (_a = LetrwingCrypto.decryptSecretBox(res.codebox, letrkey)) !== null && _a !== void 0 ? _a : '';
        }
        const ret = Object.assign(Object.assign({}, res), { filters: [], code: code, otherviews: [], users: [] });
        for (const fl of res.filters) {
            try {
                const str = LetrwingCrypto.decryptSecretBox(fl.data, sh.accesskey);
                if (str === undefined)
                    continue;
                ret.filters.push({
                    columnid: fl.columnid,
                    data: JSON.parse(str)
                });
            }
            catch (ex) { }
        }
        return ret;
    }
    async decodeSchemaServerWebView(ress, code) {
        var _a;
        const key = code.trim();
        const enkey = await LetrwingCrypto.getHash256(key);
        const resp = [];
        for (const res of ress) {
            const ddata = LetrwingCrypto.decryptSecretBox(res.databox, enkey);
            if (ddata) {
                try {
                    const openkeys = JSON.parse(ddata);
                    const openview = Object.assign(Object.assign({}, res), { filters: [], code: code, users: res.users, otherviews: [] });
                    openview.letrkey = openkeys.letrkey;
                    for (const fl of res.filters) {
                        try {
                            const str = LetrwingCrypto.decryptSecretBox(fl.data, openkeys.accesskey);
                            if (str === undefined)
                                continue;
                            openview.filters.push({
                                columnid: fl.columnid,
                                data: JSON.parse(str)
                            });
                        }
                        catch (ex) { }
                    }
                    if (res.searchbox) {
                        try {
                            const sjj = LetrwingCrypto.decryptSecretBox(res.searchbox, openkeys.letrkey);
                            if (sjj) {
                                openview.searchboxopen = JSON.parse(sjj);
                            }
                        }
                        catch (Ex) { }
                    }
                    if (res.sh) {
                        const schema = convertBoxToSchema(res.sh, res.letrid, openkeys.letrkey);
                        openview.shopen = schema;
                        if (res.dh) {
                            const dhee = convertBoxToSheet(res.dh, res.letrid, openkeys.letrkey);
                            openview.dhopen = dhee;
                        }
                    }
                    let djs = "";
                    let fjs = "";
                    let defjs = "";
                    if (res.displayjs && openview.shopen) {
                        const eb = new EncryptBox();
                        eb.encryptmsg = res.displayjs;
                        eb.nonce = openview.shopen.nonce;
                        const data = (_a = LetrwingCrypto.decryptBoxWithNonceToStr(eb, openview.shopen.accesskey, openview.shopen.nonce)) !== null && _a !== void 0 ? _a : '';
                        let dparts = data.split("-----letrwing----");
                        if (dparts.length > 0) {
                            djs = dparts[0];
                        }
                        if (dparts.length > 1) {
                            let sdp = dparts[1].split("-----letrwingdefault----");
                            if (sdp.length > 0) {
                                fjs = sdp[0];
                            }
                            if (sdp.length > 1) {
                                defjs = sdp[1];
                            }
                        }
                    }
                    openview.displayjs = djs;
                    openview.customfilerjs = fjs;
                    openview.customdefaultjs = defjs;
                    if (res.otherviews && openview.shopen && openview.letrkey) {
                        const ore = [];
                        for (const ov of res.otherviews) {
                            ore.push(this.decodeSchemaServerWebViewViaLK(ov, openview.shopen, openview.letrkey));
                        }
                        openview.otherviews = ore;
                    }
                    resp.push(openview);
                }
                catch (ex) { }
            }
        }
        return resp;
    }
    async decodeSchemaserverWeb(res, code) {
        var _a, _b;
        const key = code.trim();
        const enkey = await LetrwingCrypto.getHash256(key);
        const ddata = LetrwingCrypto.decryptSecretBox(res.databox, enkey);
        if (ddata) {
            const jdata = JSON.parse(LetrwingCrypto.fromBase64ToUTF8(ddata));
            if (jdata) {
                jdata.letrid = res.letrid;
                jdata.creatorname = res.creatorname;
                if (res.keys) {
                    jdata.pubkeys = res.keys;
                }
                if (res.searchbox) {
                    try {
                        const sjj = LetrwingCrypto.decryptSecretBox(res.searchbox, jdata.letrkey);
                        if (sjj) {
                            jdata.searchbox = JSON.parse(sjj);
                        }
                    }
                    catch (Ex) { }
                }
                jdata.id = res.id;
                if (res.sh) {
                    const schema = convertBoxToSchema(res.sh, jdata.letrid, jdata.letrkey);
                    jdata.sh = schema;
                    if (res.dh) {
                        const dhee = convertBoxToSheet(res.dh, jdata.letrid, jdata.letrkey);
                        jdata.dh = dhee;
                    }
                }
                if (jdata.sh !== undefined) {
                    const schema = jdata.sh;
                    const sh = schema === null || schema === void 0 ? void 0 : schema.items;
                    const sitems = sh.filter((s) => { var _a; return !this.checkIfFieldHasRule(s.id, (_a = schema.rules) !== null && _a !== void 0 ? _a : []); });
                    const items = [];
                    if (jdata.useall) {
                        items.push(...sitems.filter((item) => item.type !== SchemaItemType.UserChoice));
                    }
                    else {
                        for (const item of sitems) {
                            if (item.type !== SchemaItemType.UserChoice)
                                if (item.type === SchemaItemType.List) {
                                    const validlistdataitems = [];
                                    const myitems = item.items;
                                    for (const mit of myitems) {
                                        if (jdata.validids.indexOf(item.id + "----" + mit.item.id) != -1 && !this.checkIfFieldHasRule(item.id + "----" + mit.item.id, (_a = schema.rules) !== null && _a !== void 0 ? _a : [])) {
                                            validlistdataitems.push(mit);
                                        }
                                    }
                                    if (validlistdataitems.length > 0) {
                                        const nlit = Object.assign(Object.assign({}, item), { items: validlistdataitems });
                                        items.push(nlit);
                                    }
                                }
                                else {
                                    if (jdata.validids.indexOf(item.id) != -1) {
                                        items.push(item);
                                    }
                                }
                        }
                    }
                    if (items) {
                        const defs = (_b = jdata.schemaItemDefault) !== null && _b !== void 0 ? _b : [];
                        let aonline = true;
                        for (const i of items) {
                            if (i.type === SchemaItemType.List) {
                                for (const ii of i.items) {
                                    if (ii.item.type === SchemaItemType.Remote) {
                                        aonline = false;
                                        break;
                                    }
                                }
                            }
                            else {
                                if (i.type === SchemaItemType.Remote) {
                                    aonline = false;
                                    break;
                                }
                            }
                        }
                        jdata.availoffline = aonline;
                        return jdata;
                    }
                }
                return undefined;
            }
        }
    }
    async cleanupDB() {
    }
    setActiveView(view) {
        this.activeview = view;
    }
    setShowinfopanelshow(show) {
        this.showinfopanelshow = show;
    }
    checkWebViewCanShowAIForm() {
        if (this.webfilterjs) {
            try {
                const f = new Function("config", this.webfilterjs);
                let config = {
                    getschemarows: (sid) => this.getDependSchemaRows(sid),
                    decryptcell: (entry, sid, pr) => {
                        if (!entry.decrypted) {
                            this.decryptEntry(entry, sid);
                        }
                        return entry;
                    },
                    getactiveuserid: () => { var _a, _b; return (_b = (_a = this.currentroute) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; }
                };
                const bui = f(config);
                if (bui.showaifilling && bui.getpromptfortext) {
                    this.setShowAiCreate(true);
                    return;
                }
            }
            catch (E) {
                this.setShowAiCreate(false);
            }
        }
        this.setShowAiCreate(false);
    }
    async loadWebItem(itemid, userid) {
        var _a, _b, _c, _d, _e;
        const view = this.activeviews.get(itemid.substring("web-".length));
        if (itemid === this.lastitemid) {
            if (view && view.type !== ViewType.Chart) {
                let ltime = this.lastloadtime;
                // less than 15 mimnutes ago!
                if (new Date().getTime() - ltime < (15 * 60 * 1000)) {
                    this.loadRowsForActiveForm(this.lastloadtime, true);
                    this.setLoadingItem(false);
                    return;
                }
            }
        }
        else {
            this.setShowinfopanelshow(false);
            this.setCustompaneljs((_b = (_a = view === null || view === void 0 ? void 0 : view.shopen) === null || _a === void 0 ? void 0 : _a.custompanelcode) !== null && _b !== void 0 ? _b : '');
            this.setDisplayJS((_c = view === null || view === void 0 ? void 0 : view.displayjs) !== null && _c !== void 0 ? _c : '');
            this.setWebFilterJS((_d = view === null || view === void 0 ? void 0 : view.customfilerjs) !== null && _d !== void 0 ? _d : '');
            this.setKeepButtonsWithCustomView(false);
            this.setShowAiCreate(false);
            this.clearSelectedRowids();
        }
        this.checkWebViewCanShowAIForm();
        this.columnwidths.clear();
        this.smeatda = [];
        this.setItems([]);
        this.clearRowReadonly();
        this.clearCurrentPermissions();
        this.clearActiveUsers();
        if (view) {
            this.setActiveView(view === null || view === void 0 ? void 0 : view.type);
            this.setActiveViewTitle(view.name);
            const users = (_e = view.users) !== null && _e !== void 0 ? _e : [];
            for (const u of users) {
                let id = u.id;
                this.addActiveUsers(Object.assign(Object.assign({}, u), { id: id }));
            }
            if (this.selecteditemid === itemid) {
                this.setLoadingItem(false);
                this.lastitemid = ""; // make sure it's not this specially for offline!!
                this.setLoadingRows(true);
                this.loadRowsForActiveForm(0);
                if (view.customfilerjs) {
                    const f = new Function("config", view.customfilerjs);
                    let config = {
                        getschemarows: (sid) => this.getDependSchemaRows(sid),
                        decryptcell: (entry, sid, pr) => {
                            if (!entry.decrypted) {
                                this.decryptEntry(entry, sid);
                            }
                            return entry;
                        }
                    };
                    const bui = f(config);
                    if (bui && bui.sortRowCb) {
                        this.customSorter = bui.sortRowCb;
                    }
                    else {
                        this.customSorter = undefined;
                    }
                }
            }
        }
        else {
            window.history.replaceState({}, document.title, '#' + this.selectedorgid);
            this.currentroute = this.parseHash(window.location.hash);
            this.setSelectItemId("", false);
        }
    }
    decryptEntry(e, sid) {
        if (e.decrypted)
            return e;
        const ash = this.getActiveSchema();
        if (ash && ash.id === sid) {
            const req = ash;
            if (req) {
                if (e.ruleopen) {
                    parser([e], req, this.depschemarows, this.schemasmap);
                }
                else if (!e.decrypted) {
                    GDecryptcell(e, req);
                }
                e.decrypted = true;
            }
        }
        else {
            const sh = this.schemasmap.get(sid);
            if (sh) {
                if (e.ruleopen) {
                    parser([e], sh, this.depschemarows, this.schemasmap);
                }
                else if (!e.decrypted) {
                    GDecryptcell(e, sh);
                }
                e.decrypted = true;
            }
        }
        return e;
    }
    decryptBC(bc, sid) {
        var _a, _b, _c, _d;
        let key = "";
        let nonce = "";
        const ash = this.getActiveSchema();
        if (ash && ash.id === sid) {
            const req = ash;
            key = (_a = req === null || req === void 0 ? void 0 : req.accesskey) !== null && _a !== void 0 ? _a : '';
            nonce: (_b = req === null || req === void 0 ? void 0 : req.nonce) !== null && _b !== void 0 ? _b : '';
        }
        else {
            const sh = this.schemasmap.get(sid);
            key = (_c = sh === null || sh === void 0 ? void 0 : sh.accesskey) !== null && _c !== void 0 ? _c : '';
            nonce: (_d = sh === null || sh === void 0 ? void 0 : sh.nonce) !== null && _d !== void 0 ? _d : '';
        }
        if (bc && bc.startsWith("crypt-") && key && nonce) {
            return this.decryptBCWithKeyNonde(bc, key, nonce);
        }
        return undefined;
    }
    addSchemaRowsToRemote(sid, cid, myitemid) {
        var _a, _b, _c, _d, _e, _f;
        const sh = this.schemasmap.get(sid);
        if (!sh)
            return;
        const rows = this.depschemarows.get(sid);
        if (!rows || rows.length === 0)
            return;
        const id = myitemid + "---" + sid + "---" + cid;
        if (this.dependentdata.has(id))
            return;
        this.dependentdata.set(id, []);
        let myitem = undefined;
        for (const ii of sh.items) {
            if (ii.id === cid) {
                myitem = ii;
                break;
            }
            else if (ii.type === SchemaItemType.List && ii.items.length > 0) {
                for (const si of ii.items) {
                    if (ii.id + "----" + si.item.id === cid) {
                        myitem = ii;
                        break;
                    }
                }
                if (myitem)
                    break;
            }
        }
        if (!myitem)
            return;
        if (myitem.type === SchemaItemType.Choice) {
            myitem.items.forEach((it) => {
                var _a;
                (_a = this.dependentdata.get(id)) === null || _a === void 0 ? void 0 : _a.push({
                    id: it.id,
                    value: it.name,
                    ischoice: true
                });
            });
            return;
        }
        for (const row of rows) {
            for (const en of row.entry) {
                if (en.cellid !== cid)
                    continue;
                if (en.cellid === cid) {
                    if (!en.decrypted) {
                        this.decryptEntry(en, sid);
                    }
                }
                if (en.rowbarcode && en.rowbarcode.startsWith("crypt-")) {
                    en.rowbarcode = (_a = this.decryptBC(en.rowbarcode, sid)) !== null && _a !== void 0 ? _a : '';
                }
                if (myitem.type === SchemaItemType.Text) {
                    (_b = this.dependentdata.get(id)) === null || _b === void 0 ? void 0 : _b.push({
                        id: en.id,
                        rowid: en.rowid,
                        rowbarcode: en.rowbarcode,
                        value: (_c = LWStripHTML(en.celldata)) !== null && _c !== void 0 ? _c : '',
                    });
                }
                else if (myitem.type === SchemaItemType.Date) {
                    (_d = this.dependentdata.get(id)) === null || _d === void 0 ? void 0 : _d.push({
                        id: en.id,
                        rowid: en.rowid,
                        rowbarcode: en.rowbarcode,
                        value: DateLib.displayDateWIthFormat(en.celldata, myitem.inlcudetime, (_e = myitem.excludedate) !== null && _e !== void 0 ? _e : false),
                    });
                }
                else {
                    (_f = this.dependentdata.get(id)) === null || _f === void 0 ? void 0 : _f.push({
                        id: en.id,
                        rowid: en.rowid,
                        rowbarcode: en.rowbarcode,
                        value: en.celldata,
                    });
                }
            }
        }
    }
    getDependentSchemaRowsByName(schemaname) {
        let sh = undefined;
        for (const lsh of this.schemasmap.values()) {
            if (lsh.name === schemaname) {
                sh = lsh;
                break;
            }
        }
        if (sh) {
            return this.getDependSchemaRows(sh.id);
        }
        return [];
    }
    getDependSchemaRows(sid) {
        var _a;
        const sh = this.getActiveSchema();
        if (sh && sid === sh.id) {
            return Array.from(this.uirows.values());
        }
        return (_a = this.depschemarows.get(sid)) !== null && _a !== void 0 ? _a : [];
    }
    async loadItem(itemid, userid) {
        this.setDisplayJS("");
        this.setWebFilterJS("");
        this.setCustompaneljs("");
        this.setShowinfopanelshow(false);
        this.setKeepButtonsWithCustomView(false);
        this.clearSelectedRowids();
        if (itemid === this.lastitemid) {
            this.setLoadingItem(false);
            return;
        }
        ;
        this.setItems([]);
        const fid = this.activeforms.get(itemid);
        if (fid) {
            this.setActiveViewTitle(fid.name);
        }
        const items = await this.buildFormData(itemid, userid);
        if (this.selecteditemid === itemid) {
            this.setItems(items);
            this.setLoadingItem(false);
            if (fid === null || fid === void 0 ? void 0 : fid.history) {
                this.loadRowsForActiveForm(0);
            }
        }
    }
    buildNewRowFromDataRules(ens, req) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!req || !req.datarules)
            return ens;
        this.schemasmap.set(req.id, req);
        const delentry = [];
        const ret = [];
        let workrules = new Map();
        let pendingrules = new Set();
        let donerules = [];
        const enmap = new Map();
        for (const en of ens) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
        }
        req.datarules.forEach((dr) => {
            if (!enmap.has(dr.colid)) {
                workrules.set(dr.colid, dr);
                pendingrules.add(dr.colid);
            }
        });
        const kens = ens.map((en) => (Object.assign({}, toJS(en))));
        const fakerow = {
            rowid: '',
            rowindex: -1,
            entry: kens,
        };
        this.addRuleEntryToRow(fakerow, req);
        if (!this.depschemarows.has(req.id) ||
            ((_b = this.depschemarows.get(req.id)) === null || _b === void 0 ? void 0 : _b.length) !== this.rowsMap.size) {
            this.depschemarows.set(req.id, Array.from(this.rowsMap.values()));
        }
        const myindex = (_d = (_c = this.depschemarows.get(req.id)) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
        if (req) {
            (_e = this.depschemarows.get(req.id)) === null || _e === void 0 ? void 0 : _e.push(fakerow);
            if (!this.schemasmap.has(req.id)) {
                this.schemasmap.set(req.id, req);
            }
            for (const kn of fakerow.entry) {
                if (!kn.ruleopen) {
                    kn.decrypted = true;
                }
                else {
                    parser([kn], req, this.depschemarows, this.schemasmap, this.dependentdata);
                    kn.decrypted = true;
                }
            }
            if (myindex > -1) {
                (_f = this.depschemarows.get(req.id)) === null || _f === void 0 ? void 0 : _f.splice(myindex, 1);
            }
        }
        while (pendingrules.size > 0) {
            let startsize = pendingrules.size;
            nextworkruke: for (const rule of workrules.values()) {
                // lets check if this rule can run or no!!
                let canrun = true;
                nextrule: for (const r of rule.rule) {
                    if (r.conds) {
                        for (const con of r.conds) {
                            for (const cp of con.params) {
                                if (pendingrules.has(cp.leftcond)) {
                                    continue nextworkruke; // we cant run this!!
                                }
                            }
                        }
                    }
                    for (const cp of r.params) {
                        const values = cp.value.split("~~~");
                        if (values.length > 2) {
                            const sid = values[1];
                            const cid = values[2];
                            if (req.id === sid) {
                                if (pendingrules.has(cid)) {
                                    continue nextworkruke;
                                }
                            }
                            if (cp.conds) {
                                for (const con of cp.conds) {
                                    for (const cp of con.params) {
                                        if (pendingrules.has(cp.rightcond)) {
                                            continue nextworkruke; // we cant run this!!
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (canrun) {
                    pendingrules.delete(rule.colid);
                    const myrows = this.depschemarows.get(req.id);
                    if (!myrows) {
                        this.depschemarows.set(req.id, Array.from(this.rowsMap.values()));
                    }
                    // lets add new row
                    const index = this.rowsMap.size;
                    // now lets run this and get the value for this!!
                    const val = textRuleParser(fakerow, rule, this.depschemarows, this.schemasmap, req.id, this.dependentdata, false);
                    if (!val || val.length === 0) {
                        continue;
                    }
                    const entryies = (_g = enmap.get(rule.colid)) !== null && _g !== void 0 ? _g : [];
                    // we need to also get other ones as well
                    // maybe what we need is 
                    const donevals = new Map();
                    const delvals = new Map();
                    const allsubrowindex = [];
                    // we should overrite the existing values too!!
                    if (entryies) {
                        for (const et of entryies) {
                            if (et.ruleopen) {
                                this.parseCellRules(et, req);
                            }
                            else {
                                this.decryptCell(et, req);
                            }
                            if (et.celldata) {
                                if (!donevals.has(et.celldata)) {
                                    donevals.set(et.celldata, []);
                                }
                                if (!delvals.has(et.celldata)) {
                                    delvals.set(et.celldata, []);
                                }
                                (_h = donevals.get(et.celldata)) === null || _h === void 0 ? void 0 : _h.push(et);
                                (_j = delvals.get(et.celldata)) === null || _j === void 0 ? void 0 : _j.push(et);
                            }
                            allsubrowindex.push((_k = et.subrowindex) !== null && _k !== void 0 ? _k : '');
                        }
                    }
                    if (rule.colid.indexOf("----") !== -1) {
                        const mapbysubrowindex = new Map();
                        if (entryies) {
                            for (const en of entryies) {
                                mapbysubrowindex.set((_l = en.subrowindex) !== null && _l !== void 0 ? _l : '', en);
                            }
                        }
                        const parentid = rule.colid.split("----")[0];
                        // just make sure all subrowindex are there too!!
                        const listitem = req.items.filter(it => it.id === parentid);
                        if (listitem && listitem.length > 0) {
                            const lit = listitem[0];
                            if (lit.items && lit.items.length > 0) {
                                for (const litem of lit.items) {
                                    if (lit.id + "----" + litem.item.id === rule.colid)
                                        continue; // we already done this!
                                    const lens = enmap.get(lit.id + "----" + litem.item.id);
                                    if (lens && lens.length > 0) {
                                        for (const en of lens) {
                                            const sr = en.subrowindex;
                                            if (sr && allsubrowindex.indexOf(sr) === -1) {
                                                // wee have missing column 
                                                entryies === null || entryies === void 0 ? void 0 : entryies.push({
                                                    celldata: "",
                                                    cellfindstr: '',
                                                    cellid: rule.colid,
                                                    schemaid: req.id,
                                                    rowindex: en.rowindex,
                                                    subrowindex: sr,
                                                    rowid: en.rowid,
                                                    id: "",
                                                    sortstring: Math.random()
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (entryies) {
                        entryies.sort(sortEntriesByRow);
                    }
                    const mycellen = [];
                    for (let i = 0; i < val.length; i++) {
                        const tr = val[i];
                        if (entryies && entryies.length > i) {
                            const en = entryies[i];
                            en.celldata = tr.celldata;
                            fakerow.entry.push(en);
                            mycellen.push(en);
                        }
                        else {
                            const entry = {
                                celldata: tr.celldata,
                                cellfindstr: '',
                                cellid: tr.cellid,
                                schemaid: req.id,
                                rowindex: -1,
                                subrowindex: tr.subrowindex,
                                rowid: '',
                                id: "",
                                sortstring: Math.random()
                            };
                            fakerow.entry.push(entry);
                            mycellen.push(entry);
                        }
                    }
                    enmap.set(rule.colid, mycellen);
                }
            }
            let endsize = pendingrules.size;
            if (startsize === endsize) {
                // we cant run anything break this
                break;
            }
        }
        return fakerow.entry;
    }
    setItems(its) {
        this.currentitems.splice(0, this.currentitems.length);
        this.currentitems.push(...its);
    }
    setROItems(its) {
        this.readonlyitems.clear();
        its.forEach((f) => this.readonlyitems.add(f));
    }
    clearRowReadonly() {
        this.rowreadonly.clear();
    }
    clearActiveUsers() {
        this.activeusers.clear();
    }
    addActiveUsers(u) {
        this.activeusers.set(u.id, u);
    }
    addRowReadonly(rowid, ros) {
        this.rowreadonly.set(rowid, new Set(ros));
    }
    addRowPermission(rowid, ros) {
        this.rowpermissions.set(rowid, new Set(ros));
    }
    async arrangeOrgs(orgs, boxes) {
        const omap = new Map();
        for (const og of orgs) {
            if (og.status && og.status === 'Dead')
                continue;
            if (og.orgbox) {
                omap.set(og.orgid, og);
            }
        }
        const boxmap = new Map();
        const eboxmap = new Map();
        for (const b of boxes) {
            try {
                const bst = LetrwingCrypto.decryptSecretBox(b, this.currentcode);
                if (bst) {
                    const od = JSON.parse(bst);
                    boxmap.set(od.orgid, od);
                    eboxmap.set(od.orgid, b);
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
        const ret = [];
        for (const org of orgs) {
            if (!org.orgbox)
                continue;
            if (org.status && org.status === 'Dead')
                continue;
            const ob = boxmap.get(org.orgid);
            const bb = eboxmap.get(org.orgid);
            const stb = {
                id: org.orgid,
                user: org,
            };
            if (ob && bb) {
                // lets check if we can still decypt and private key is same 
                const mob = org.orgbox;
                try {
                    const pk = LetrwingCrypto.decryptSecretBox(mob, await LetrwingCrypto.getHash256(ob.code));
                    if (pk && pk === ob.privatekey) {
                        stb.box = bb;
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
            ret.push(stb);
        }
        return ret;
    }
    async checkServerReachable() {
        const res = await comm.get(ServerPath.PWAReachable);
        if (!res) {
            return false;
        }
        return true;
    }
    updateMyCurrentOrgs(orgs) {
        // we should make sure we add new org if noit there and also do some stuf!!
        const validids = new Set();
        let unselect = false;
        for (const o of orgs) {
            validids.add(o.id);
            if (!this.orgmenustore.orgsmap.has(o.id)) {
                this.orgmenustore.addOrg(o);
            }
            else if (!o.isopen) {
                const oldolr = this.orgmenustore.orgsmap.get(o.id);
                if (oldolr) {
                    this.orgmenustore.replaceOrg(oldolr, o);
                    if (this.selectedorgid === o.id) {
                        unselect = true;
                    }
                }
            }
        }
        const dols = [];
        for (const o of orgs) {
            if (!validids.has(o.id)) {
                dols.push(o);
                if (this.selectedorgid === o.id) {
                    unselect = true;
                }
            }
        }
        if (dols.length > 0) {
            this.orgmenustore.removeOrg(dols);
        }
        if (unselect) {
            this.orgmenustore.setSelectedOrgid("");
        }
    }
    checkSyncData() {
        var _a;
        // just flag worker ?
        // or local processor 
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            (_a = navigator.serviceWorker.controller) === null || _a === void 0 ? void 0 : _a.postMessage({ type: "syncdata" });
        }
        else {
            // lets ask our local system to do it
            this.processJobLocally();
        }
    }
    async checkCurrentViews(forms) {
        const validids = new Set();
        // lets just make sure we show correct data!!
        for (const f of forms) {
            validids.add(f.id);
            this.addWebView(f);
            if (this.selecteditemid === f.id && f.shopen) {
                if (this.activeview !== f.type) {
                    this.setActiveView(f.type);
                }
                const items = new Map();
                const sh = f.shopen;
                if (sh) {
                    for (const it of sh.items) {
                        if (it.type == SchemaItemType.List) {
                            const lit = it;
                            if (lit.items) {
                                for (const li of lit.items) {
                                    items.set(it.id + "----" + li.item.id, Object.assign(Object.assign({}, li.item), { id: it.id + "----" + li.item.id }));
                                }
                            }
                        }
                        else {
                            items.set(it.id, it);
                        }
                    }
                }
                // before we do this!!
                const autocols = this.setupItemsAndROList(f);
                const sfils = this.createFilterMeta(f, items);
                if (sfils.length > 0) {
                    if (this.smeatda.length > 0) {
                        const cl = new Map();
                        for (const sm of this.smeatda) {
                            cl.set(sm.id, sm);
                        }
                        let hasdi = false;
                        for (const ss of sfils) {
                            const ccf = cl.get(ss.id);
                            if (!ccf) {
                                hasdi = true;
                                break;
                            }
                            if (ccf.value != ss.value || ccf.value2 !== ss.value2 || ccf.optype !== ss.optype) {
                                hasdi = true;
                                break;
                            }
                        }
                        if (!hasdi)
                            return;
                    }
                }
                let srows = await this.filterAndRemoveRows(sfils, f.shopen);
                let sset = new Set(srows);
                if (autocols.length > 0 && this.rowsMap.size > 0) {
                    sset = await this.filterRowsBasedOnAuto(autocols, "appcontact-" + this.selectedorgid, sh, sset);
                }
                if (this.selecteditemid === f.id) {
                    for (const s of sset) {
                        this.showRow(s);
                    }
                }
            }
        }
        let delviews = [];
        for (const f of this.activeviews.values()) {
            if (!validids.has(f.id)) {
                delviews.push(f.id);
                if (this.selecteditemid === f.id) {
                    this.setSelectItemId(""); // move the user back!
                }
            }
        }
        for (const ii of delviews) {
            this.deleteWebView(ii);
        }
    }
    async checkCurrentForms(forms) {
        const validids = new Set();
        // lets just make sure we show correct data!!
        for (const f of forms) {
            validids.add(f.id);
            this.addWebForm(f);
            if (this.selecteditemid === f.id) {
                const items = await this.buildFormData(f.id, this.selectedorgid);
                this.setItems(items);
            }
        }
        let delviews = [];
        for (const f of this.activeforms.values()) {
            if (!validids.has(f.id)) {
                delviews.push(f.id);
                if (this.selecteditemid === f.id) {
                    this.setSelectItemId(""); // move the user back!
                }
            }
        }
        for (const ii of delviews) {
            this.deleteWebForm(ii);
        }
    }
    async checkAppVersionAndUpdate() {
        clearTimeout(this.appversioncheck);
        if (this.appupdating)
            return;
        const res = await fetch("/appversion.txt");
        if (res.ok) {
            const txt = await res.text();
            if (txt.trim() !== this.activeversion) {
                await this.updateApp();
            }
            else {
                // after 20 minutes!
                this.appversioncheck = setTimeout(() => this.checkAppVersionAndUpdate(), 20 * 60 * 1000);
            }
        }
        else {
            this.appversioncheck = setTimeout(() => this.checkAppVersionAndUpdate(), 20 * 60 * 1000);
        }
    }
    checkSchemaCellHasRuleWithsh(cellid, sh) {
        if (sh.rules) {
            for (const ar of sh.rules) {
                if (ar.colid === cellid) {
                    return true;
                }
            }
        }
        return false;
    }
    checkRowCellhasRule(rowid, cellid, sh) {
        const rules = this.rowRules.get(sh.id + "~~~" + rowid);
        if (rules) {
            for (const ru of rules.data) {
                if (ru.colid === cellid) {
                    return true;
                }
            }
        }
        return this.checkSchemaCellHasRuleWithsh(cellid, sh);
    }
    checkCellHasRule(fitem, sh) {
        return this.checkRowCellhasRule(fitem.rowid, fitem.cellid, sh);
    }
    parseCellRules(fitem, sh) {
        var _a;
        if (fitem.decrypted) {
            return;
        }
        if (this.parsingentry.has(fitem.id)) {
            return;
        }
        this.parsingentry.add(fitem.id);
        const achema = sh;
        if (fitem.ruleopen) {
            const wen = Object.assign({}, fitem);
            // lets now rebuild this rule now
            if (!this.depschemarows.has(sh.id) ||
                ((_a = this.depschemarows.get(sh.id)) === null || _a === void 0 ? void 0 : _a.length) !== this.rowsMap.size) {
                this.depschemarows.set(sh.id, Array.from(this.rowsMap.values()));
            }
            if (!this.schemasmap.has(sh.id)) {
                this.schemasmap.set(sh.id, achema);
            }
            parser([wen], achema, this.depschemarows, this.schemasmap, this.dependentdata);
            // now we have this cell done we just need to remove this cell from the 
            fitem.celldata = wen.celldata;
            fitem.decrypted = true;
            const frow = this.rowsMap.get(fitem.rowid);
            if (frow) {
                for (const en of frow.entry) {
                    if (en.id === fitem.id) {
                        en.decrypted = true;
                        en.celldata = wen.celldata;
                        break;
                    }
                }
            }
        }
        this.parsingentry.delete(fitem.id);
    }
    getActiveSchema() {
        if (this.selecteditemid) {
            if (this.selecteditemid.startsWith("web-")) {
                const wv = this.activeviews.get(this.selecteditemid.substring("web-".length));
                if (wv && wv.shopen) {
                    return wv.shopen;
                }
                return undefined;
            }
            const wf = this.activeforms.get(this.selecteditemid);
            if (wf && wf.sh) {
                return wf.sh;
            }
        }
        return undefined;
    }
    getActiveWebview() {
        if (this.selecteditemid) {
            if (this.selecteditemid.startsWith("web-")) {
                const wv = this.activeviews.get(this.selecteditemid.substring("web-".length));
                if (wv) {
                    return wv;
                }
                return undefined;
            }
        }
        return undefined;
    }
    decryptCell(fitem, sh) {
        var _a, _b, _c;
        if (fitem.decrypted) {
            return;
        }
        const achema = sh;
        let myitem = undefined;
        for (const si of achema.items) {
            if (si.type === SchemaItemType.List) {
                const lsi = si;
                for (const ssi of lsi.items) {
                    if (ssi.item.id === fitem.cellid) {
                        myitem = ssi.item;
                        break;
                    }
                }
            }
            else {
                if (si.id === fitem.cellid) {
                    myitem = si;
                    break;
                }
            }
        }
        if ((myitem === null || myitem === void 0 ? void 0 : myitem.type) === SchemaItemType.ID) {
            fitem.decrypted = true;
            return fitem;
        }
        if ((myitem === null || myitem === void 0 ? void 0 : myitem.type) === SchemaItemType.SquenceNumber) {
            fitem.decrypted = true;
            fitem.celldata = fitem.celldata.substring("sqn-".length);
            return fitem;
        }
        if (fitem.celldata.startsWith("pbk---")) {
            if (!achema.schemaintegrationbox) {
                return;
            }
            let pk = "";
            try {
                const dstr = LetrwingCrypto.decryptSecretBox(achema.schemaintegrationbox, achema.accesskey);
                if (dstr) {
                    const djs = JSON.parse(dstr);
                    pk = djs.privatkey;
                }
            }
            catch (ex) { }
            if (fitem.schemaid === ((_a = achema.parentschema) === null || _a === void 0 ? void 0 : _a.id)) {
                pk = achema.parentschema.webformkeys.priavatekey;
            }
            if (!pk) {
                fitem.celldata = '';
                console.log("missing key for rule parsing");
                return;
            }
            try {
                const emsg = fitem.celldata.substring("pbk---".length);
                const jsonstring = LetrwingCrypto.getUTF8FromBase64(emsg);
                const js = JSON.parse(jsonstring);
                const rawdata = LetrwingCrypto.decryptRSABoxRawString(js, { privatkey: pk, publickey: '' });
                fitem.celldata = rawdata !== null && rawdata !== void 0 ? rawdata : '';
                fitem.cellfindstr = rawdata !== null && rawdata !== void 0 ? rawdata : '';
                fitem.decrypted = true;
            }
            catch (ex) {
                console.log(ex);
            }
            return;
        }
        let key = achema.accesskey;
        let nonce = achema.nonce;
        if (fitem.schemaid === ((_b = achema.parentschema) === null || _b === void 0 ? void 0 : _b.id)) {
            key = achema.parentschema.accesskey;
            nonce = achema.parentschema.nonce;
        }
        const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: fitem.celldata, nonce: '', publickey: '' }, key, nonce);
        fitem.celldata = val !== null && val !== void 0 ? val : '';
        if (fitem.cellfindstr) {
            const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: fitem.cellfindstr, nonce: '', publickey: '' }, key, nonce);
            fitem.cellfindstr = val !== null && val !== void 0 ? val : '';
        }
        else {
            fitem.cellfindstr = '';
        }
        if (myitem && myitem.type === SchemaItemType.Remote) {
            const srit = myitem;
            const id = srit.id + "---" + srit.sid + "---" + srit.cid;
            const dens = this.dependentdata.get(id);
            if (dens) {
                const mit = fitem.celldata.split(",");
                for (const vk of dens) {
                    if (mit.indexOf(vk.id) !== -1) {
                        if (!fitem.remotevalue)
                            fitem.remotevalue = [];
                        (_c = fitem.remotevalue) === null || _c === void 0 ? void 0 : _c.push(vk.value);
                    }
                }
            }
        }
        //lets also attach display value here!!
        fitem.decrypted = true;
    }
    // we will fetch all schema and dependent!
    async fetchRows(sh, since, orguserid, formid, box, issaving = false, loadingfilter = undefined) {
        var _a, _b, _c, _d, _e;
        console.log(loadingfilter);
        const cids = [];
        if (sh.items && sh.items.length > 0) {
            for (let i = 0; i < sh.items.length; i++) {
                const item = sh.items[i];
                cids.push(item.id);
                if (item.type === SchemaItemType.List) {
                    const litem = item;
                    if (litem.items && litem.items.length > 0) {
                        cids.push(...litem.items.map((il) => litem.id + "----" + il.id));
                    }
                }
            }
        }
        if (sh.parentschema && sh.parentschema.items && sh.parentschema.items.length > 0) {
            for (let i = 0; i < sh.parentschema.items.length; i++) {
                const item = sh.parentschema.items[i];
                cids.push(item.id);
                if (item.type === SchemaItemType.List) {
                    const litem = item;
                    if (litem.items && litem.items.length > 0) {
                        cids.push(...litem.items.map((il) => litem.id + "----" + il.id));
                    }
                }
            }
            // we should also add some of the things like 
        }
        if (sh.childrenschema && sh.childrenschema.length > 0) {
            for (const ch of sh.childrenschema) {
                if (ch.items && ch.items.length > 0) {
                    for (let i = 0; i < ch.items.length; i++) {
                        const item = ch.items[i];
                        cids.push(item.id);
                        if (item.type === SchemaItemType.List) {
                            const litem = item;
                            if (litem.items && litem.items.length > 0) {
                                cids.push(...litem.items.map((il) => litem.id + "----" + il.id));
                            }
                        }
                    }
                }
            }
        }
        const ret = new Map();
        let startindex = 0;
        let rentriesd = {
            delrows: [],
            entries: {
                data: [],
                variables: []
            }
        };
        let i = 0;
        while (true) {
            i++;
            const req = {
                since: since,
                cellids: cids,
                userid: orguserid,
                webformid: formid,
                schemaid: sh.id,
                total: since <= 0 ? 10000 : -1,
                startindex: since <= 0 ? startindex : -1,
                query: loadingfilter
            };
            const rentries = await comm.postWithNetwork(ServerPath.pwahistorynew, req);
            if (rentries === 'Unauthorised') {
                return undefined;
            }
            if (rentries === 'NetworkError') {
                console.log("we got network error");
                return 'NetworkError';
            }
            if (!rentries) {
                return undefined;
            }
            // lets now get these entries 
            if (!rentries.entries || rentries.entries.data.length === 0)
                break;
            rentriesd.delrows.push(...rentries.delrows);
            const pubents = [];
            const entries = await this.convertRowResToEntry(rentries.entries, sh.id, sh.accesskey, sh.nonce);
            if (entries && entries.length > 0) {
                const fent = entries[0];
                const dobj = new Map();
                const sobj = new Map();
                if (fent && box !== undefined) {
                    if (fent.objs) {
                        const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, sh.letrid);
                        for (const fe of dojs) {
                            dobj.set(fe.id, fe);
                        }
                    }
                    if (fent.shs) {
                        for (const sh of fent.shs) {
                            const sok = CONVERTUTIL.convertBoxToSchema(sh, sh.letrid, box);
                            if (sok)
                                sobj.set(sh.id, sok);
                        }
                    }
                }
                let i = -1;
                let lrindex = 0;
                for (const ent of entries) {
                    if (i > 0 && i % 5000 == 0)
                        await timeout(10);
                    i++;
                    if (!ent.rowid) {
                        continue;
                    }
                    // we should check and sabe all the public key saved ones as well!!
                    if (ent.linkedids && ent.linkedids.length > 0) {
                        // lets attach it's object here!
                        if (!ent.objsdec)
                            ent.objsdec = [];
                        if (!ent.schsdec)
                            ent.schsdec = [];
                        for (const lid of ent.linkedids) {
                            const parts = lid.split("-");
                            if (parts.length < 2)
                                continue;
                            if (lid.startsWith("objs-")) {
                                const bk = dobj.get(parts[1]);
                                if (bk) {
                                    ent.objsdec.push(bk);
                                }
                            }
                            else if (lid.startsWith("dch-") && parts.length > 2) {
                                const sh = sobj.get(parts[2]);
                                if (sh) {
                                    ent.schsdec.push(sh);
                                }
                            }
                        }
                    }
                    if (ent.schemaid !== sh.id) {
                        ent.rowindex = ent.rowindex / 100000;
                    }
                    if (!ret.has(ent.rowid)) {
                        ret.set(ent.rowid, {
                            rowindex: ent.rowindex, rowid: ent.rowid, entry: [],
                            childrenschemalist: ent.schemaid === sh.id ? ent.childrenschemalist : []
                        });
                    }
                    if (ent.rowindex > lrindex) {
                        lrindex = ent.rowindex;
                    }
                    // we should also check if the ent is 
                    if (ent.cellid.indexOf("----") !== -1 && !ent.subrowindex) {
                        continue;
                    }
                    (_a = ret.get(ent.rowid)) === null || _a === void 0 ? void 0 : _a.entry.push(ent);
                }
                if (startindex === lrindex || since > 0) {
                    break;
                }
                startindex = lrindex + 1;
            }
        }
        const shmap = new Map();
        let depns = undefined;
        const darr = [];
        let rules = [];
        if (!issaving) {
            rules = await this.getAllRules(sh.id, sh.letrid, box, orguserid, formid);
            // we have remote items now and we just need some simple stuff now!!
            depns = await this.checkAndGetAllRemoteItems(sh.items, sh.letrid, sh.id, formid, orguserid, box);
            // lets get all depednt too!
            let setupviewde = new Set();
            if (depns === null || depns === void 0 ? void 0 : depns.resp) {
                depns.resp.forEach((dt) => {
                    if (dt.schema.viewdepentschemas) {
                        for (const vs of dt.schema.viewdepentschemas) {
                            setupviewde.add(vs);
                        }
                    }
                });
                this.decryptAndSetupDepRemoteItems(depns.resp, (_b = depns.dsch) !== null && _b !== void 0 ? _b : [], sh);
            }
            if (depns === null || depns === void 0 ? void 0 : depns.dsch) {
                const mapofdata = new Map();
                let doneenid = new Set();
                for (const en of depns.dsch) {
                    if (!mapofdata.has(en.id)) {
                        mapofdata.set(en.id, []);
                    }
                    for (const e of en.entries) {
                        if (!doneenid.has(e.id)) {
                            (_c = mapofdata.get(en.id)) === null || _c === void 0 ? void 0 : _c.push(e);
                            doneenid.add(e.id);
                        }
                    }
                }
                const depschemarows = convertDepToRows(mapofdata);
                for (const s in depschemarows) {
                    if (setupviewde.has(s)) {
                        this.depschemarows.set(s, (_d = depschemarows.get(s)) !== null && _d !== void 0 ? _d : []);
                    }
                }
            }
            let fetchd = false;
            if (sh.viewdepentschemas) {
                fetchd = true;
            }
            if (sh.rules && sh.rules.length > 0) {
                fetchd = true;
            }
            else if (rules && rules.length > 0) {
                fetchd = true;
            }
            const donesh = new Map();
            if (depns && depns.resp) {
                for (const dt of depns.resp) {
                    const dd = (_e = dt.schemaopen) === null || _e === void 0 ? void 0 : _e.viewdepentschemas;
                    if (dd && dd.length > 0) {
                        for (const ddv of dd) {
                            donesh.set(ddv, new Set(["attach"]));
                        }
                    }
                }
            }
            if (fetchd) {
                let ens = [];
                for (const row of ret.values()) {
                    row.entry.forEach((en) => {
                        ens.push(Object.assign(Object.assign({}, en), { ruleopen: undefined }));
                    });
                }
                const depps = await this.getAllDependentEnt(rules, sh, sh.letrid, sh.id, ens, shmap, box, formid, orguserid, donesh);
                for (const [k, v] of depps) {
                    const dd = {
                        id: k,
                        entries: Array.from(v.values())
                    };
                    darr.push(dd);
                }
            }
            const triggers = await this.getTriggers(sh.id, sh.letrid, box, orguserid, formid);
            if (triggers !== undefined && triggers.length > 0) {
                this.addTrigger(triggers);
            }
        }
        const localrows = new Map();
        let rows = Array.from(ret.values());
        const resproes = rows;
        // this is just update
        /*if (since > 0) {
            let index = 0
            if (this.rowsMap.size > 0) {
                for (const [_, row] of this.rowsMap) {
                    if (rentriesd.delrows.indexOf(row.rowid) === -1 && ret.has(row.rowid)) {
                        const ens: Entry[] = [];
                        for (const en of row.entry) {

                            ens.push({ ...toJS(en), ruleopen: undefined });
                        }
                        const sr: SchemaRow = { ...row, entry: ens };
                        rows.push(sr);
                    }
                    index++;
                    if (index % 100 === 0) await timeout(10);
                }
            }
        }*/
        const resp = {
            id: sh.id,
            rows: rows,
            remoteres: depns,
            shs: Array.from(shmap.values()),
            dsch: darr,
            delrows: rentriesd.delrows,
            rowrules: rules
        };
        if (!issaving) {
            // update schema data there now!!
            await PWaDb.addSchemaRows(resp);
        }
        const nresp = resp;
        nresp.rows = resproes;
        return nresp;
    }
    addTrigger(tr) {
        var _a;
        this.triggersbyCols.clear();
        for (const tt of tr) {
            if (!this.triggersbyCols.has(tt.columnid)) {
                this.triggersbyCols.set(tt.columnid, new Map());
            }
            (_a = this.triggersbyCols.get(tt.columnid)) === null || _a === void 0 ? void 0 : _a.set(tt.id, tt);
        }
    }
    async getTriggers(sid, letrid, box, appuserid, form) {
        const re = {
            id: sid,
            letrid: letrid,
            nodeboxes: [],
            triggerbox: new EncryptBox(),
            dataid: appuserid + "----" + form
        };
        const res = await comm.post(ServerPath.pwaGetTriggerBox, re);
        if (res) {
            if (res.triggerbox) {
                const str = LetrwingCrypto.decryptSecretBox(res.triggerbox, box);
                if (str) {
                    const data = JSON.parse(str);
                    for (const d of data) {
                        d.schemaid = sid;
                        d.letrid = letrid;
                    }
                    return data;
                }
            }
        }
        return undefined;
    }
    async getAllRules(schemaid, letrid, box, appuserid, form) {
        var _a, _b;
        const req = {
            schemaid: schemaid,
            letrid: letrid,
            creatorid: appuserid + "----" + form
        };
        const res = await comm.post(ServerPath.pwagetRowRule, req);
        if (!res) {
            return [];
        }
        const resp = [];
        for (const r of res) {
            try {
                const bstr = LetrwingCrypto.decryptSecretBox(r.data, box);
                if (!bstr)
                    continue;
                const rules = JSON.parse(bstr);
                resp.push({
                    schemaid: r.schemaid,
                    letrid: r.letrid,
                    id: r.id,
                    data: (_a = rules.numberdata) !== null && _a !== void 0 ? _a : [],
                    rowid: r.rowid,
                    datarules: (_b = rules.datarules) !== null && _b !== void 0 ? _b : []
                });
            }
            catch (ex) { }
        }
        return resp;
    }
    async checkSession() {
        var _a;
        if (this.currentsessiontimeout) {
            clearTimeout(this.currentsessiontimeout);
        }
        if (this.pwaauthstate === PwaAuthState.Loggedout)
            return;
        this.checkSyncData();
        if (navigator.onLine) {
            const sor = (_a = this.selectedorgid) !== null && _a !== void 0 ? _a : '';
            const sit = this.selecteditemid;
            const resp = await comm.postWithNetwork(ServerPath.pwalatest, {
                activeorgs: sor,
                activeview: sit,
                since: this.lastloadtime
            });
            if (resp === 'NetworkError') {
                if (this.currentsessiontimeout) {
                    clearTimeout(this.currentsessiontimeout);
                }
                this.currentsessiontimeout = setTimeout(() => {
                    this.checkSession();
                }, 60000);
                return;
            }
            if (resp === 'Unauthorised' || (resp && !(resp === null || resp === void 0 ? void 0 : resp.validsession))) {
                this.wipe();
                return;
            }
            if ((!resp)) {
                return;
            }
            const ores = await this.parseOrgResponse(resp.respo);
            this.updateMyCurrentOrgs(ores);
            if (sor && this.selectedorgid === sor) {
                const org = this.orgmenustore.orgsmap.get(sor);
                if (!org || !org.isopen) {
                    return [];
                }
                // now lets get org box for offline support
                const orgbox = await PWaDb.getOrgBox(org.orgid);
                if (orgbox) {
                    // lets open these forms now!!
                    orgbox.forms = resp.workables.forms;
                    orgbox.views = resp.workables.views;
                    await PWaDb.addOrganisation(orgbox);
                }
                const fms = await this.parseWorkableResp(resp.workables, org);
                await this.checkCurrentForms(fms);
                const views = await this.parseLockedViews(resp.workables.views, org);
                await this.checkCurrentViews(views);
            }
            if (resp.rowsupdated && (!this.loadingrows && !this.fetchingupdates) && this.selecteditemid && this.selecteditemid === sit) {
                this.loadRowsForActiveForm(this.lastloadtime, true);
            }
            // now lets do something 
        }
        if (this.currentsessiontimeout) {
            clearTimeout(this.currentsessiontimeout);
        }
        this.currentsessiontimeout = setTimeout(() => {
            this.checkSession();
        }, 60000 * 5); // every 5 minutes!!
    }
    async updateApp() {
        if (this.appupdating) {
            return; // next time it will be clean now!
        }
        this.appupdating = true;
        const regs = await navigator.serviceWorker.getRegistrations();
        for (const r of regs) {
            await r.update();
        }
    }
    async parseOrgResponse(res) {
        const localorgs = await PWaDb.getAllOrganisations();
        const orgs = await this.arrangeOrgs(res.appusers, res.myboxes);
        const localorgmap = new Map();
        const validorgs = new Set();
        if (localorgs) {
            localorgs.forEach((lo) => localorgmap.set(lo.id, lo));
        }
        for (const o of orgs) {
            const lb = localorgmap.get(o.id);
            if (!o.box && (lb === null || lb === void 0 ? void 0 : lb.box)) {
                try {
                    const bst = LetrwingCrypto.decryptSecretBox(lb.box, this.currentcode);
                    if (bst) {
                        const od = JSON.parse(bst);
                        const pk = LetrwingCrypto.decryptSecretBox(o.user.orgbox, await LetrwingCrypto.getHash256(od.code));
                        if (pk && pk === od.privatekey) {
                            o.box = lb.box;
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
            // this will be updated later on!!
            if (lb === null || lb === void 0 ? void 0 : lb.forms) {
                o.forms = lb.forms;
            }
            await PWaDb.addOrganisation(o);
            validorgs.add(o.id);
        }
        for (const og of localorgmap.keys()) {
            if (!validorgs.has(og)) {
                await PWaDb.removeOrg(og);
            }
        }
        return this.convertOrgDataToAppUserOpen(orgs);
    }
    async getMyOrganisations() {
        // lets get all the valid orgsnaitsions and we done!!
        // if there are any we dont want we can delete them and even remove from UI too!!
        const localorgs = await PWaDb.getAllOrganisations();
        if (!navigator.onLine || !await this.checkServerReachable()) {
            if (localorgs && localorgs.length > 0) {
                return this.convertOrgDataToAppUserOpen(localorgs);
            }
            return [];
        }
        // now lets ghet these and we done !!
        const res = await comm.abGetWithNetwork(ServerPath.getAllorganisatiomDetails);
        if (res && res !== 'NetworkError') {
            return this.parseOrgResponse(res);
        }
        else if (res === 'NetworkError') {
            // offline lets get data from cache!!
            if (localorgs && localorgs.length > 0) {
                return this.convertOrgDataToAppUserOpen(localorgs);
            }
        }
        // else we have everything here lets build this
        return [];
    }
    async getMyDevices() {
        // lets get all the valid orgsnaitsions and we done!!
        // if there are any we dont want we can delete them and even remove from UI too!!
        // now lets ghet these and we done !!
        const res = await comm.abGetWithNetwork(ServerPath.getMyDevices);
        if (res && res !== 'NetworkError') {
            return res;
        }
        else if (res === 'NetworkError') {
            // offline lets get data from cache!!
        }
        return [];
    }
    async setupUserSession(sid) {
        const code = LetrwingCrypto.generateNewSecretKey();
        const sess = {
            sessioncode: code,
            sessionid: sid,
            sessionname: ''
        };
        this.setSessionName("");
        await PWaDb.addUserSession(sess);
        this.currentsession = sid;
        this.currentcode = code;
        comm.setSessionid(sid);
    }
    parseHash(chash) {
        if (chash && chash.length > 1) {
            const parts = chash.substring(1).split("~~~");
            const route = {
                id: parts[0],
                itemid: '',
                view: ''
            };
            if (parts.length > 1) {
                route.itemid = parts[1];
            }
            if (parts.length > 2) {
                route.view = parts[2];
            }
            return route;
        }
        return undefined;
    }
    async compareOrgs(neworgs) {
        // now we have these and we can just compare and so something nice here
    }
    async registerForNotifications(id) {
        let reg = {
            name: '',
            notificationid: id
        };
        const res = await comm.post(ServerPath.registernot, reg);
        if (res) {
            window.localStorage.setItem("notificationregistered", "registered");
            this.setNotificationRegistered(true);
        }
    }
    async deregisterForNotifications() {
        const req = {};
        const res = await comm.post(ServerPath.unregisternot, req);
        if (res) {
            window.localStorage.removeItem("notificationregistered");
            this.setNotificationRegistered(false);
        }
    }
    async userLoggedIn() {
        var _a, _b;
        const usess = await PWaDb.getUserSession();
        if (!usess) {
            this.currentsession = "";
            this.currentcode = "";
            this.setSessionName("");
            return false;
        }
        const sid = usess.sessionid;
        comm.setSessionid(sid);
        const res = await comm.abGetWithNetwork(ServerPath.pwaindex);
        if (res && res !== 'NetworkError') {
            this.currentcode = usess.sessioncode;
            this.currentsession = usess.sessionid;
            this.setSessionName((_a = usess.sessionname) !== null && _a !== void 0 ? _a : '');
            let reg = localStorage.getItem("notificationregistered");
            if (reg) {
                this.setNotificationRegistered(true);
            }
            const res = await this.getMyOrganisations(); // get user organisations here now!
            const devices = await this.getMyDevices();
            for (const d of devices) {
                if (d.id !== this.currentsession) {
                    this.usermenu.addDevices(d);
                }
            }
            this.orgmenustore.setOrganisations(res);
            this.currentsessiontimeout = setTimeout(() => {
                this.checkSession();
            }, 60000);
            this.refereshQueue();
            return true;
        }
        if (res === 'NetworkError') {
            this.currentcode = usess.sessioncode;
            this.currentsession = usess.sessionid;
            this.setSessionName((_b = usess.sessionname) !== null && _b !== void 0 ? _b : '');
            if (!navigator.onLine) {
                this.setInternetStatus(false);
            }
            const res = await this.getMyOrganisations(); // get user organisations here now!
            this.orgmenustore.setOrganisations(res);
            this.currentsessiontimeout = setTimeout(() => {
                this.checkSession();
            }, 60000);
            this.syncdatatimeout = setTimeout(() => this.checkSyncData(), 30000);
            this.refereshQueue();
            return true;
        }
        this.setSessionName("");
        return false;
    }
    async decryptOrg(or, code) {
        try {
            if (or.orgbox) {
                const key = await LetrwingCrypto.getHash256(code);
                const cc = LetrwingCrypto.decryptSecretBox(or.orgbox, key);
                if (cc) {
                    or.privatekey = cc;
                    or.code = code;
                    const oob = {
                        orgid: or.orgid,
                        code: code,
                        privatekey: cc,
                        appuserid: or.id
                    };
                    const ebox = LetrwingCrypto.secretBox(JSON.stringify(oob), this.currentcode);
                    let orgbox = new EncryptBox();
                    if (or.orgbox) {
                        orgbox = Object.assign({}, or.orgbox);
                    }
                    let codebox = new EncryptBox();
                    if (or.codebox) {
                        codebox = Object.assign({}, or.codebox);
                    }
                    const obox = {
                        user: {
                            id: or.id,
                            orgbox: orgbox,
                            codebox: codebox,
                            email: or.email,
                            orgname: or.orgname,
                            countrycode: or.countrycode,
                            phonenumber: or.phonenumber,
                            hint: or.hint,
                            name: or.name,
                            orgid: or.orgid,
                            publickey: or.publickey
                        },
                        box: ebox,
                        id: or.orgid
                    };
                    await PWaDb.addOrganisation(obox);
                    const users = this.convertOrgDataToAppUserOpen([obox]);
                    if (users.length > 0) {
                        // we shodul store this first 
                        //await comm.post<boolean, EncryptBox>(ServerPath.storeEboxToSession, ebox);
                        users[0].isopen = true;
                        return users[0];
                    }
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
        return undefined;
    }
    setFileMeta(id, res) {
        this.filemetamap.set(id, res);
    }
    async getFileMeta(id, contenname, rowid, dataid, key, cellid, entryid, objectid, schemaid = "") {
        const req = {
            name: contenname, fileid: id, rowid: rowid, dataid: dataid, cellid: cellid,
            entryid: entryid, objectid: objectid
        };
        if (schemaid) {
            req['schemaid'] = schemaid;
        }
        const res = await comm.postWithNetwork(ServerPath.getPwaFileMeta, req);
        if (res && res !== 'NetworkError' && res !== 'Unauthorised') {
            if (!res.box) {
                return undefined;
            }
            const mykey = LetrwingCrypto.decryptSecretBox(res.box, key);
            if (res.ingeststatus !== undefined && mykey !== undefined) {
                const undata = LetrwingCrypto.decryptSecretBox(res.ingeststatus, mykey);
                try {
                    const indata = JSON.parse(undata !== null && undata !== void 0 ? undata : '');
                    res.ingestunboxed = indata;
                    res.ingestunboxed.hasviewer = res.hasviewer;
                }
                catch (ex) {
                }
                this.filemetakeys.set(id, mykey);
            }
            this.setFileMeta(id, res);
            return mykey;
        }
        return res;
    }
    addDocumentEntity(id, doc) {
        this.docmap.set(id, doc);
    }
    async getPdfViewerContent(id, contenname, rowid, dataid, key, cellid, entryid, objectid, schemaid = "", mykey = undefined) {
        if (!mykey) {
            mykey = await this.getFileMeta(id, contenname, rowid, dataid, key, cellid, entryid, objectid, schemaid);
            if (mykey === 'Unauthorised') {
                return undefined;
            }
            if (!mykey) {
                return undefined;
            }
            if (mykey === 'NetworkError') {
                return undefined;
            }
        }
        const req = {
            name: contenname, fileid: id, rowid: rowid, dataid: dataid, cellid: cellid,
            entryid: entryid, objectid: objectid
        };
        if (schemaid) {
            req['schemaid'] = schemaid;
        }
        const res = await comm.post(ServerPath.getPwaFileViewerContent, req);
        if (res) {
            try {
                let str = LetrwingCrypto.decryptSecretBox(res.box, mykey);
                if (str === undefined) {
                    return undefined;
                }
                return str;
            }
            catch (ex) {
                console.log(ex);
            }
        }
        return undefined;
    }
    async createFormEntryJob() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        try {
            // lets now do this
            if (this.formentry.size === 0 && this.objectselectedfiles.size === 0) {
                this.addFormmessage("Please add atleast one entry");
                return undefined;
            }
            ;
            let otherview = [];
            let wf = undefined;
            let appusers = [];
            let defaultvalues = new Map();
            if (this.selecteditemid.startsWith("web-")) {
                const wfv1 = this.activeviews.get(this.selecteditemid.substring("web-".length));
                if (!wfv1 || !wfv1.shopen || !wfv1.letrkey) {
                    this.addFormmessage("Unauthroise action");
                    return undefined;
                }
                const wfv = toJS(wfv1);
                if (!wfv || !wfv.shopen || !wfv.letrkey) {
                    this.addFormmessage("Unauthroise action");
                    return undefined;
                }
                otherview.push(wfv1);
                otherview.push(...(_a = wfv1.otherviews) !== null && _a !== void 0 ? _a : []);
                appusers = ((_b = wfv1.users) !== null && _b !== void 0 ? _b : []).filter((ap) => ap.type === 'appuser');
                const dummyform = {
                    id: this.selecteditemid,
                    schemaid: wfv.schemaid,
                    history: false,
                    backgroundcolor: '',
                    textcolor: '',
                    referer: '',
                    letrid: wfv.letrid,
                    code: wfv.code,
                    creatorid: wfv.creatorid,
                    lastmodifiedby: '',
                    lastmodifytime: 0,
                    name: wfv.name,
                    letrkey: wfv.letrkey,
                    schemakey: toJS((_c = wfv.shopen) === null || _c === void 0 ? void 0 : _c.accesskey),
                    schemanonce: toJS(wfv.shopen.nonce),
                    searchbox: toJS(wfv.searchboxopen),
                    pubkeys: toJS(wfv.keys),
                    useall: false,
                    validids: [],
                    sh: toJS(wfv1.shopen)
                };
                wf = dummyform;
                if (wfv.customdefaultjs) {
                    const func = new Function(wfv.customdefaultjs);
                    let dvals = func();
                    if (dvals) {
                        for (const obj of dvals) {
                            defaultvalues.set(obj.id, obj.value);
                        }
                    }
                }
            }
            else {
                wf = this.activeforms.get(this.selecteditemid);
                if (!wf) {
                    this.addFormmessage("Unauthroise action");
                    return undefined;
                }
            }
            const entrymap = new Map();
            let sentries = [];
            // other wise lets create the entry now
            const items = this.currentitems;
            const fite = items;
            const entries = this.formentry;
            const itemsmap = new Set();
            for (const si of (_e = (_d = wf.sh) === null || _d === void 0 ? void 0 : _d.items) !== null && _e !== void 0 ? _e : []) {
                if (si.type === SchemaItemType.List) {
                    for (const sii of si.items) {
                        itemsmap.add(si.id + "----" + sii.id);
                        const litems = this.newlistitemsmap.get(si.id + "----" + sii.id);
                        for (const ll of litems !== null && litems !== void 0 ? litems : []) {
                            const checkid = si.id + "----" + sii.id + "----" + ll;
                            let lev = entries.get(checkid);
                            if (!lev && defaultvalues.has(si.id + "----" + sii.id)) {
                                entries.set(checkid, {
                                    celldata: (_f = defaultvalues.get(si.id + "----" + sii.id)) !== null && _f !== void 0 ? _f : '',
                                    cellfindstr: '',
                                    rowid: '',
                                    rowindex: -1,
                                    cellid: si.id + "----" + sii.id,
                                    subrowindex: ll,
                                    schemaid: (_h = (_g = wf.sh) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : '',
                                    id: '',
                                    sortstring: -1
                                });
                            }
                        }
                    }
                }
                else {
                    itemsmap.add(si.id);
                    if (!entries.has(si.id) && defaultvalues.has(si.id)) {
                        entries.set(si.id, {
                            celldata: (_j = defaultvalues.get(si.id)) !== null && _j !== void 0 ? _j : '',
                            cellfindstr: '',
                            rowid: '',
                            rowindex: -1,
                            cellid: si.id,
                            subrowindex: "",
                            schemaid: (_l = (_k = wf.sh) === null || _k === void 0 ? void 0 : _k.id) !== null && _l !== void 0 ? _l : '',
                            id: '',
                            sortstring: -1
                        });
                    }
                }
            }
            for (const ii of fite) {
                let ev = entries.get(ii.id);
                if (ii.type !== SchemaItemType.List && ii.required && (ev === undefined || ev === null || ev.celldata === undefined ||
                    ev.celldata === null || (ev.celldata + '').trim() === '')) {
                    const msg = "Item " + ii.name + " is a mamdatory field";
                    this.addFormmessage(msg);
                    return undefined;
                }
                else if (ii.type === SchemaItemType.List) {
                    const li = ii;
                    for (const iii of li.items) {
                        const iite = iii.item;
                        if (iite.type !== SchemaItemType.List && iite.required) {
                            const litems = this.newlistitemsmap.get(ii.id);
                            if ((litems === null || litems === void 0 ? void 0 : litems.length) === 0) {
                                const msg = "Item " + iite.name + " is a mamdatory field";
                                this.addFormmessage(msg);
                                return undefined;
                            }
                            for (const ll of litems !== null && litems !== void 0 ? litems : []) {
                                const checkid = ii.id + "----" + iite.id + "----" + ll;
                                let lev = entries.get(ii.id + "----" + iite.id);
                                if (lev === undefined || (lev.celldata + '').trim() === '') {
                                    const msg = "Item " + iite.name + " is a mamdatory field";
                                    this.addFormmessage(msg);
                                    return undefined;
                                }
                            }
                        }
                    }
                }
            }
            const fdata = [];
            const doneids = new Set();
            for (const [k, v] of this.formentry) {
                if (!itemsmap.has(v.cellid))
                    continue;
                //  doneids.add(k);
                // we should fix sub row index here too!!
                if (k.indexOf("----") !== -1) {
                    // now we need to check this properly
                    const parts = k.split("----");
                    if (parts.length < 3)
                        continue;
                    const lid = parts[0];
                    const itemid = parts[1];
                    const uuid = parts[2];
                    const litems = this.newlistitemsmap.get(lid);
                    if (!litems || litems.length === 0)
                        continue;
                    const rowindex = litems.indexOf(uuid);
                    if (rowindex === -1)
                        continue;
                    const subrowindex = rowindex + "----" + uuid;
                    const entrucellid = lid + "----" + itemid;
                    const entryk = entrucellid + "----" + subrowindex;
                    const entry = Object.assign(Object.assign({}, toJS(v)), { ruleopen: undefined });
                    entry.cellid = entrucellid;
                    entry.subrowindex = subrowindex;
                    sentries.push(entry);
                    // now lets do something fun here!!
                    continue;
                }
                sentries.push(toJS(v));
            }
            ;
            if (sentries.length > 0 && wf.sh) {
                sentries = this.buildNewRowFromDataRules(sentries, wf.sh);
            }
            let achema = wf.sh;
            let custn = [];
            if (achema && achema.custompostaction) {
                const actionfn = new Function("config", achema.custompostaction);
                const actionconfig = {
                    getschemarows: (id) => this.getDependSchemaRows(id),
                    decryptCell: (entry, sid) => {
                        if (!entry.decrypted) {
                            this.decryptEntry(entry, sid);
                        }
                        return entry;
                    },
                    stripHTML: (msg) => LWStripHTML(msg)
                };
                const bui = actionfn(actionconfig);
                console.log(bui);
                if (bui.notificationpackets !== undefined) {
                    let rows = [];
                    const row = this.uirows.get(this.pwaeditstore.activerowid);
                    if (row) {
                        rows = row.entry;
                    }
                    custn = await new Promise((ac, rej) => {
                        bui.notificationpackets(sentries, true, [], (msg) => {
                            ac(msg);
                        });
                    });
                }
            }
            for (const ae of sentries) {
                const k = ae.cellid;
                const v = ae;
                if (!itemsmap.has(k))
                    continue;
                doneids.add(k);
                // we should fix sub row index here too!!
                if (k.indexOf("----") != -1) {
                    const subrowindex = (_m = v.subrowindex) !== null && _m !== void 0 ? _m : '';
                    const entrucellid = v.cellid;
                    const entryk = entrucellid + "----" + subrowindex;
                    const entry = Object.assign(Object.assign({}, toJS(v)), { ruleopen: undefined });
                    entry.cellid = entrucellid;
                    entry.subrowindex = subrowindex;
                    if (!entrymap.has(entrucellid)) {
                        entrymap.set(entrucellid, []);
                    }
                    (_o = entrymap.get(entrucellid)) === null || _o === void 0 ? void 0 : _o.push(entry);
                    fdata.push({
                        key: entryk,
                        value: entry
                    });
                    // now lets do something fun here!!
                    continue;
                }
                if (!entrymap.has(k)) {
                    entrymap.set(k, []);
                }
                (_p = entrymap.get(k)) === null || _p === void 0 ? void 0 : _p.push(toJS(v));
                fdata.push({
                    key: k,
                    value: Object.assign(Object.assign({}, toJS(v)), { ruleopen: undefined })
                });
            }
            ;
            const files = [];
            const filepro = [];
            if (this.objectselectedfiles.size > 0) {
                for (const [k, v] of this.objectselectedfiles) {
                    if (!itemsmap.has(k))
                        continue;
                    doneids.add(k);
                    let effectivekey = k;
                    if (k.indexOf("----") != -1) {
                        const parts = k.split("----");
                        if (parts.length < 3)
                            continue;
                        const lid = parts[0];
                        const itemid = parts[1];
                        const uuid = parts[2];
                        const litems = this.newlistitemsmap.get(lid);
                        if (!litems || litems.length === 0)
                            continue;
                        let ki = litems.indexOf(uuid);
                        if (ki === -1)
                            continue;
                        effectivekey = lid + "----" + itemid + "----" + ki + "----" + uuid;
                    }
                    for (const file of v) {
                        const id = LetrwingCrypto.getUUID();
                        const up = {
                            itemid: effectivekey,
                            id: id,
                            file: file
                        };
                        files.push(up);
                        const upp = {
                            fileid: id,
                            key: '',
                            nonce: '',
                            totalchunks: Math.ceil(file.size / (1024 * 1024)),
                            totaldonechunks: 0,
                            totalsize: file.size,
                            doneindex: 0,
                            done: false,
                            objectid: '',
                            itemid: k
                        };
                        filepro.push(upp);
                    }
                }
            }
            const job = {
                form: fdata,
                files: files,
                progress: filepro,
                webform: toJS(wf),
            };
            const org = this.orgmenustore.orgsmap.get(this.selectedorgid);
            if (!org) {
                toast("Failed to add job to queue. Please try again or contact support.", {
                    position: 'top-right',
                    type: 'error'
                });
                return;
            }
            const dj = {
                userid: this.selectedorgid,
                data: job,
                jobtype: JobType.WebForm,
                jobid: LetrwingCrypto.getUUID(),
                status: JobStatus.Pending,
                orgid: org.orgid,
                jobtime: new Date().getTime(),
                customnotify: custn
            };
            if (job.webform.id.startsWith("web-") && otherview.length > 0) {
                dj.notifyview = this.checkWithViewNeedNotification(Array.from(this.formentry.values()), otherview);
                dj.assigncols = this.checkRowNeedsAutoAssignment(Array.from(this.formentry.values()), appusers, job.webform.schemaid, otherview);
            }
            if (!await PWaDb.addDataJob(dj)) {
                dj.status = JobStatus.Error;
                // this.addFormmessage("Failed to add job to queue. Please try again or contact support.");
                toast("Failed to add job to queue. Please try again or contact support.", {
                    position: 'top-right',
                    type: 'error'
                });
                dj.jobstatusmessage = "Failed to add to queue.";
            }
            else {
                if (navigator.serviceWorker.controller) {
                    (_q = navigator.serviceWorker.controller) === null || _q === void 0 ? void 0 : _q.postMessage({ type: "syncdata" });
                }
                else {
                    // lets ask our local system to do it
                    this.processJobLocally();
                }
                //  this.addFormmessage("Job added to queue. Monitor progress using progress dialog.");
                /* toast("Job added to queue. Monitor progress using progress dialog.", {
                     position: 'top-right',
                     type: 'success',
                     autoClose: 2000
                 });*/
            }
            this.clearActiveEdits();
            this.addDataJobToMap(dj);
            this.processmenu.setShow(true);
            return dj;
        }
        catch (ex) {
            console.log(ex);
            toast("Failed to add job to queue. Please try again or contact support.", {
                position: 'top-right',
                type: 'error',
            });
            // this.addFormmessage("Failed to add job to queue. Please try again or contact support.");
        }
    }
    async processJobLocally() {
        var _a, _b;
        if (!this.procesisng) {
            console.log("starting procesing??");
            this.procesisng = true;
            // anything older need cleaning as well
            let donejobs = new Set();
            const comparetime = new Date().getTime() - (24 * 60 * 60 * 1000);
            while (true) {
                const sjobs = Array.from(this.alljobs.values()).filter((job) => job.status === JobStatus.Done && !donejobs.has(job.jobid));
                sjobs.filter((j) => j.status === JobStatus.Done && j.jobtime < comparetime).forEach((j) => donejobs.add(j.jobid));
                // in progress for more than 5 mins need repicking! !!
                const jobs = Array.from(this.alljobs.values()).filter((job) => job.status === JobStatus.Pending || (job.status === JobStatus.Progress &&
                    (new Date().getTime() - job.jobtime) > (5 * 60 * 1000)));
                if (jobs.length === 0)
                    break;
                for (const j of jobs) {
                    if (j.orgid) {
                        const org = await PWaDb.getOrgBox(j.orgid);
                        if (!org) {
                            // we dot have org box anymore!!
                            donejobs.add(j.jobid);
                            continue;
                        }
                    }
                    j.status = JobStatus.Progress;
                    j.jobtime = new Date().getTime();
                    await PWaDb.addDataJob(toJS(j));
                    this.addDataJobToMap(j);
                    let achema = j.data.webform.sh;
                    // we should so the 
                    if ((j.jobtype === JobType.WebForm || j.jobtype === JobType.EditRow) &&
                        achema && achema.custompostaction) {
                        const actionfn = new Function("config", achema.custompostaction);
                        const actionconfig = {
                            getschemarows: (id) => this.getDependSchemaRows(id),
                            decryptCell: (entry, sid) => {
                                if (!entry.decrypted) {
                                    this.decryptEntry(entry, sid);
                                }
                                return entry;
                            },
                            stripHTML: (msg) => LWStripHTML(msg)
                        };
                        const bui = actionfn(actionconfig);
                        console.log(bui);
                        if (bui.notificationpackets !== undefined) {
                            let sentries = [];
                            let rows = [];
                            if (j.jobtype === JobType.EditRow) {
                                const row = this.uirows.get(this.pwaeditstore.activerowid);
                                if (row) {
                                    rows = row.entry;
                                }
                                sentries = (_b = (_a = j.data.addentry) === null || _a === void 0 ? void 0 : _a.entries) !== null && _b !== void 0 ? _b : [];
                            }
                            else {
                                sentries = j.data.form.map((f) => f.value);
                            }
                            console.log(sentries);
                            j.customnotify = await new Promise((ac, rej) => {
                                bui.notificationpackets(sentries, j.jobtype === JobType.WebForm, rows, (msg) => {
                                    ac(msg);
                                });
                            });
                        }
                    }
                    const res = await JobProcessor.processJob(j);
                    if (res === 'NetworkError') {
                        j.status = JobStatus.NetworkStall;
                        j.jobtime = new Date().getTime();
                        await PWaDb.addDataJob(toJS(j));
                        this.addDataJobToMap(j);
                    }
                    else if (res == 'Failed') {
                        j.status = JobStatus.Error;
                        j.jobtime = new Date().getTime();
                        await PWaDb.addDataJob(toJS(j));
                        this.addDataJobToMap(j);
                    }
                    else {
                        j.status = JobStatus.Done;
                        j.jobtime = new Date().getTime();
                        await PWaDb.addDataJob(toJS(j));
                        this.addDataJobToMap(j);
                    }
                    await timeout(30);
                }
            }
            // remove these jobs
            for (const d of donejobs) {
                await PWaDb.removeDataJob(d);
                this.alljobs.delete(d);
            }
        }
        this.procesisng = false;
    }
    async removeJob(id) {
        await PWaDb.removeDataJob(id);
        this.removeDataJob(id);
    }
    async retryJob(dj) {
        dj.status = JobStatus.Pending;
        dj.jobtime = new Date().getTime();
        if (await PWaDb.addDataJob(toJS(dj))) {
            this.checkSyncData();
        }
        else {
            dj.status = JobStatus.Error;
            dj.jobstatusmessage = "Failed to add to queue.";
        }
        this.addDataJobToMap(dj);
    }
    async selectorg(org) {
    }
    async giveSetupName(name) {
        const usess = await PWaDb.getUserSession();
        if (!usess)
            return false;
        // lets do this here!!
        const req = {
            name: name
        };
        const res = await comm.post(ServerPath.updateSessionName, req);
        if (res) {
            usess.sessionname = name;
            await PWaDb.addUserSession(usess);
        }
        return res !== null && res !== void 0 ? res : false;
    }
    checkIfFieldHasRule(cellid, rules) {
        if (rules) {
            for (const ar of rules) {
                if (ar.colid === cellid) {
                    return true;
                }
            }
        }
        return false;
    }
    async buildFormData(id, appuserid) {
        var _a, _b;
        const form = this.activeforms.get(id);
        if (!form || !form.sh)
            return [];
        this.schemaItemDefaults.clear();
        const schema = form.sh;
        const sh = schema.items;
        if (sh.length === 0)
            return [];
        if (form.schemaItemDefault) {
            // we have defualt here lets build this now!
            for (const d of (_a = form.schemaItemDefault) !== null && _a !== void 0 ? _a : []) {
                this.schemaItemDefaults.set(d.id, d);
            }
        }
        const sitems = sh.filter((s) => { var _a; return !this.checkIfFieldHasRule(s.id, (_a = schema.rules) !== null && _a !== void 0 ? _a : []); });
        const items = [];
        const boxeditems = [];
        for (const item of sitems) {
            if (item.type !== SchemaItemType.UserChoice)
                boxeditems.push(item);
            if (item.type === SchemaItemType.List) {
                const validlistdataitems = [];
                const myitems = item.items;
                for (const mit of myitems) {
                    items.push(Object.assign(Object.assign({}, mit.item), { id: item.id + "----" + mit.item.id }));
                }
            }
            else {
                items.push(item);
            }
        }
        const ritems = [];
        for (const item of items) {
            if (item.type === SchemaItemType.Remote) {
                // these are showing items!
                ritems.push(item);
            }
        }
        this.dependentdata.clear();
        if (ritems.length > 0) {
            let ids = new Set();
            for (const ri of ritems) {
                ids.add(ri.did + "~~~" + ri.sid + "~~~" + ri.cid);
            }
            try {
                const resp = await this.checkAndGetAllRemoteItems(items, form.letrid, form.schemaid, form.id, appuserid, form.letrkey);
                if (resp === null || resp === void 0 ? void 0 : resp.resp) {
                    this.decryptAndSetupDepRemoteItems(resp.resp, (_b = resp.dsch) !== null && _b !== void 0 ? _b : [], schema);
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
        return boxeditems;
    }
    async fetchAndShowWebForm(link) {
        const url = new URL(link);
        const hash = window.location.hash.substring(1);
        let code = "";
        if (hash) {
            code = hash.substring("code;".length);
        }
        const id = url.searchParams.get("id");
        if (!id || !code) {
            toast("Sorry, invalid form url");
            return;
        }
        this.setBuildingForm(true);
        comm.id = id;
        const detail = await comm.get(ServerPath.webformuserdetail);
        if (detail) {
            // now we have the details lets do something with it!!
            const enkey = await LetrwingCrypto.getHash256(code.trim());
            const ddata = LetrwingCrypto.decryptSecretBox(detail.databox, enkey);
            if (ddata) {
                const jdata = JSON.parse(LetrwingCrypto.fromBase64ToUTF8(ddata));
                jdata.letrid = detail.letrid;
                this.activeiswebform = true;
                this.activewebform = jdata;
                this.setActiveViewTitle(jdata.name);
                // lets go with this now!!
            }
            else {
                toast("Sorry, failed to extract form.");
            }
        }
        else {
            toast("Sorry, failed to fetch form details");
        }
        this.setBuildingForm(false);
    }
    async checkAndGetAllRemoteItems(items, letrid, sid, form, appuserid, letrkey, since = 0) {
        var _a;
        const ritems = [];
        for (const item of items) {
            if (item.type === SchemaItemType.Remote) {
                ritems.push(item);
            }
            else if (item.type === SchemaItemType.List) {
                for (const iii of item.items) {
                    if (iii.item.type === SchemaItemType.Remote) {
                        const litem = Object.assign(Object.assign({}, iii.item), { id: item.id + "----" + iii.item.id });
                        ritems.push(litem);
                    }
                }
            }
        }
        if (ritems.length > 0) {
            let ids = new Set();
            for (const it of ritems) {
                const rit = it;
                if (rit.did && rit.sid && rit.cid) {
                    ids.add(rit.did + "~~~" + rit.sid + "~~~" + rit.cid);
                    if (rit.conds && rit.conds.length > 0) {
                        for (const cond of rit.conds) {
                            for (const p of cond.params) {
                                ids.add(rit.did + "~~~" + rit.sid + "~~~" + p.leftcond);
                            }
                        }
                    }
                    if (rit.rtextra !== undefined) {
                        for (const rt of rit.rtextra) {
                            if (rt.cid) {
                                ids.add(rit.did + "~~~" + rit.sid + "~~~" + rt.cid);
                            }
                        }
                    }
                }
            }
            if (ids.size > 0) {
                const req = {
                    letrid: letrid,
                    schemaid: sid,
                    schemafields: Array.from(ids),
                    dataid: appuserid + "----" + form,
                    time: since
                };
                const res = await this.getAllDependent(req, letrkey, form);
                // now lets check and do the dance here now!!
                // we should check and get all stuff 
                // lets check which one need working now and we should call them 
                const darr = [];
                if (res) {
                    for (const rd of res) {
                        if (!rd.schemaopen)
                            continue;
                        if (rd.schemaopen.rules || rd.rulesopen) {
                            // shall we just get all the depend
                            const data = await this.getAllDependentEnt((_a = rd.rulesopen) !== null && _a !== void 0 ? _a : [], rd.schemaopen, req.letrid, rd.schema.id, rd.entries, new Map(), letrkey, form, appuserid);
                            // now we have everything we need and we can now do some parsing of rules as well?
                            for (const [k, v] of data) {
                                const dd = {
                                    id: k,
                                    entries: Array.from(v.values())
                                };
                                darr.push(dd);
                            }
                        }
                    }
                }
                const ret = {
                    resp: res,
                    dsch: darr
                };
                return ret;
            }
        }
        return undefined;
    }
    async getAllDependentEnt(rules, sh, letrid, wsid, ent, schemacollection, box, form, appuserid, doneids = new Map()) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        let databyschemacol = new Map();
        const rulebycellid = new Map();
        if (sh.rules) {
            for (const rule of sh.rules) {
                rulebycellid.set(rule.colid, rule);
            }
        }
        if (rules) {
            for (const r of rules) {
                if (!r.data)
                    continue;
                for (const sr of r.data) {
                    rulebycellid.set(r.rowid + "---" + sr.colid, sr);
                }
            }
        }
        let mycellids = new Set();
        for (const en of ent) {
            mycellids.add(en.cellid);
            const rid = en.rowid + "---" + en.cellid;
            if (rulebycellid.has(rid)) {
                en.ruleopen = rulebycellid.get(rid);
            }
            else if (rulebycellid.has(en.cellid) && !en.ruleopen) {
                en.ruleopen = rulebycellid.get(en.cellid);
            }
        }
        const alldata = new Set();
        const columnidsBySh = new Map();
        const akey = sh.accesskey;
        const nonce = sh.nonce;
        if (sh.rules) {
            // have to use nested as this is okay
            for (const rule of sh.rules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_a = columnidsBySh.get(sh.id)) === null || _a === void 0 ? void 0 : _a.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_b = columnidsBySh.get(sh.id)) === null || _b === void 0 ? void 0 : _b.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (sh.datarules && sh.datarules.length > 0) {
            for (const rule of sh.datarules) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_c = columnidsBySh.get(sh.id)) === null || _c === void 0 ? void 0 : _c.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_d = columnidsBySh.get(sh.id)) === null || _d === void 0 ? void 0 : _d.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        for (const ru of rules) {
            if (!ru.data)
                continue;
            for (const rule of ru.data) {
                for (const pa of rule.rule) {
                    for (const param of pa.params) {
                        const parts = param.value.split("~~~");
                        if (parts.length > 2) {
                            if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                            }
                            if (columnidsBySh.has(sh.id)) {
                                columnidsBySh.set(sh.id, new Set());
                            }
                            (_e = columnidsBySh.get(sh.id)) === null || _e === void 0 ? void 0 : _e.add(parts[2]);
                            if (param.conds && param.conds.length > 0) {
                                for (const con of param.conds) {
                                    if (con.params && con.params.length > 0) {
                                        for (const cc of con.params) {
                                            if (cc.leftcond) {
                                                alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                (_f = columnidsBySh.get(sh.id)) === null || _f === void 0 ? void 0 : _f.add(cc.leftcond);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (true) {
                for (const rule of (_g = ru.datarules) !== null && _g !== void 0 ? _g : []) {
                    for (const pa of rule.rule) {
                        for (const param of pa.params) {
                            const parts = param.value.split("~~~");
                            if (parts.length > 2) {
                                if (parts[1] !== sh.id || (!mycellids.has(parts[2]))) {
                                    alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + parts[2]);
                                }
                                if (columnidsBySh.has(sh.id)) {
                                    columnidsBySh.set(sh.id, new Set());
                                }
                                (_h = columnidsBySh.get(sh.id)) === null || _h === void 0 ? void 0 : _h.add(parts[2]);
                                if (param.conds && param.conds.length > 0) {
                                    for (const con of param.conds) {
                                        if (con.params && con.params.length > 0) {
                                            for (const cc of con.params) {
                                                if (cc.leftcond) {
                                                    alldata.add(parts[0] + "~~~" + parts[1] + "~~~" + cc.leftcond);
                                                    (_j = columnidsBySh.get(sh.id)) === null || _j === void 0 ? void 0 : _j.add(cc.leftcond);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (sh.viewdepentschemas) {
            for (const sid of sh.viewdepentschemas) {
                alldata.add(sid + "~~~attach");
            }
        }
        if (alldata.size > 0) {
            let removeids = new Set();
            for (const d of alldata) {
                const parts = d.split("~~~");
                if (!d.endsWith("attach") && doneids.has(parts[1])) {
                    const ds = doneids.get(parts[1]);
                    if (ds === null || ds === void 0 ? void 0 : ds.has(parts[2])) {
                        removeids.add(d);
                    }
                }
            }
            for (const r of removeids) {
                alldata.delete(r);
            }
            const req = {
                letrid: letrid,
                schemaid: wsid,
                schemafields: Array.from(alldata),
                dataid: appuserid + "----" + form,
                time: -1
            };
            const res = await this.getAllDependent(req, box, "");
            if (res) {
                const schemacellids = new Map();
                for (const d of alldata) {
                    const parts = d.split("~~~");
                    if (parts[1] === "attach") {
                        continue;
                    }
                    if (!doneids.has(parts[1])) {
                        doneids.set(parts[1], new Set());
                    }
                    (_k = doneids.get(parts[1])) === null || _k === void 0 ? void 0 : _k.add(parts[2]);
                    if (!schemacellids.has(parts[1])) {
                        schemacellids.set(parts[1], new Set());
                    }
                    (_l = schemacellids.get(parts[1])) === null || _l === void 0 ? void 0 : _l.add(parts[2]);
                }
                // fetch recurssively everything now!!
                for (const d of res) {
                    const dsh = d.schema;
                    let dens = d.entries;
                    const dshopen = CONVERTUTIL.convertBoxToSchema(dsh, letrid, box);
                    if (!dshopen)
                        continue;
                    if (alldata.has(dshopen.id + "~~~attach")) {
                        if (!doneids.has(dshopen.id)) {
                            doneids.set(dshopen.id, new Set());
                        }
                        if (!schemacellids.has(dshopen.id)) {
                            schemacellids.set(dshopen.id, new Set());
                        }
                        for (const cid of dshopen.items) {
                            if (cid.type === SchemaItemType.List) {
                                for (const scid of cid.items) {
                                    (_m = doneids.get(dshopen.id)) === null || _m === void 0 ? void 0 : _m.add(cid.id + "----" + scid);
                                    (_o = schemacellids.get(dshopen.id)) === null || _o === void 0 ? void 0 : _o.add(cid.id + "----" + scid);
                                }
                            }
                            else {
                                (_p = doneids.get(dshopen.id)) === null || _p === void 0 ? void 0 : _p.add(cid.id);
                                (_q = schemacellids.get(dshopen.id)) === null || _q === void 0 ? void 0 : _q.add(cid.id);
                            }
                        }
                    }
                    let cellids = (_r = schemacellids.get(d.schema.id)) !== null && _r !== void 0 ? _r : new Set();
                    schemacollection.set(dshopen.id, dshopen);
                    let entrymap = new Map();
                    if (dens) {
                        for (const de of dens) {
                            let id = de.rowid + "~~~" + de.cellid;
                            if (!entrymap.has(id)) {
                                entrymap.set(id, []);
                            }
                            (_s = entrymap.get(id)) === null || _s === void 0 ? void 0 : _s.push(de);
                        }
                    }
                    const srules = new Map();
                    const rowrules = new Map();
                    if (dshopen.rules) {
                        for (const shrule of dshopen.rules) {
                            srules.set(shrule.colid, shrule);
                        }
                    }
                    if (d.rulesopen) {
                        for (const rr of d.rulesopen) {
                            for (const shrule of rr.data) {
                                rowrules.set(rr.rowid + "~~~" + shrule.colid, shrule);
                            }
                        }
                    }
                    const subrowmap = new Map();
                    for (const dn of dens) {
                        const cid = dn.cellid;
                        if (cid.indexOf("----") !== -1) {
                            const pcid = cid.split("----")[0];
                            const rowid = dn.rowid;
                            const myid = rowid + "----" + pcid;
                            if (!subrowmap.has(myid)) {
                                subrowmap.set(myid, new Set());
                            }
                            const srid = dn.subrowindex;
                            if (srid) {
                                (_t = subrowmap.get(myid)) === null || _t === void 0 ? void 0 : _t.add(srid);
                            }
                        }
                    }
                    // now check what all columns we need 
                    for (const cellid of cellids) {
                        // we need to make sure for all rows the entries their otherwise we need to add these 
                        for (const row of d.totalrows) {
                            const rid = row.id;
                            if (cellid.indexOf("----") !== -1) {
                                const cp = cellid.split("----")[0];
                                const subrows = subrowmap.get(rid + "----" + cp);
                                if (subrows && subrows.size > 0) {
                                    // we have some sub rows lets get all subriowindex now and if we have entry for this great otherwise we need to add one
                                    // now we have all subrow lets check if we have all ids
                                    const myens = entrymap.get(row.id + "~~~" + cellid);
                                    const enmap = new Map();
                                    for (const mye of myens !== null && myens !== void 0 ? myens : []) {
                                        enmap.set((_u = mye.cellid + "----" + mye.subrowindex) !== null && _u !== void 0 ? _u : '', mye);
                                    }
                                    // okay lets check if we have all the data or create some of them
                                    for (const sr of subrows) {
                                        const me = enmap.get(cellid + "----" + sr);
                                        if (!me) {
                                            dens.push({
                                                celldata: '',
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: dsh.id,
                                                rowindex: row.rowidex,
                                                rowid: row.id,
                                                subrowindex: sr,
                                                id: LetrwingCrypto.getUUID(),
                                                sortstring: Math.random(),
                                                decrypted: false
                                            });
                                        }
                                    }
                                }
                                continue;
                            }
                            const cid = rid + "~~~" + cellid;
                            if (!entrymap.has(cid)) {
                                entrymap.set(cid, [{
                                        celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                        cellfindstr: '',
                                        cellid: cellid,
                                        schemaid: dsh.id,
                                        rowindex: row.rowidex,
                                        rowid: row.id,
                                        subrowindex: "",
                                        id: LetrwingCrypto.getUUID(),
                                        sortstring: Math.random(),
                                        decrypted: false
                                    }]);
                                dens.push({
                                    celldata: LetrwingCrypto.secretBoxWithNonce('', dshopen.accesskey, dshopen.nonce).encryptmsg,
                                    cellfindstr: '',
                                    cellid: cellid,
                                    schemaid: dsh.id,
                                    rowindex: row.rowidex,
                                    rowid: row.id,
                                    subrowindex: "",
                                    id: LetrwingCrypto.getUUID(),
                                    sortstring: Math.random(),
                                    decrypted: false
                                });
                            }
                        }
                    }
                    // these 
                    if (dshopen.rules || d.rulesopen) {
                        let filterentris = [];
                        for (const den of dens) {
                            cellids.add(den.cellid);
                            if (dshopen) {
                                const rowrule = rowrules.get(den.rowid + "~~~" + den.cellid);
                                if (rowrule) {
                                    den.ruleopen = rowrule;
                                }
                                else {
                                    const shrule = srules.get(den.cellid);
                                    if (shrule) {
                                        den.ruleopen = shrule;
                                    }
                                }
                                // if no rule open this one it's a leaf
                                if (!den.ruleopen) {
                                    decryptCell(den, dshopen);
                                }
                                const id = dshopen.datasheetid + "~~~" + dshopen.id + "~~~" + den.cellid;
                                if (!databyschemacol.has(id)) {
                                    databyschemacol.set(id, new Map());
                                }
                                (_v = databyschemacol.get(id)) === null || _v === void 0 ? void 0 : _v.set(den.id, den);
                            }
                        }
                        if (dshopen) {
                            let isschemarule = false;
                            let colrules = new Map();
                            if (dshopen.rules) {
                                // lets check if rule involve on our column and on other entity!
                                for (const shrule of dshopen.rules) {
                                    colrules.set(shrule.colid, shrule);
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    isschemarule = true;
                                                    break;
                                                }
                                                // we should also check condisiotn as well
                                                if (param.conds) {
                                                    for (const cond of param.conds) {
                                                        for (const subcon of cond.params) {
                                                            if (subcon.leftcond && !!cellids.has(subcon.leftcond)) {
                                                                isschemarule = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (isschemarule)
                                                break;
                                        }
                                    }
                                    if (isschemarule)
                                        break;
                                }
                                if (isschemarule) {
                                    filterentris.push(...dens);
                                }
                            }
                        }
                        const rules = d.rulesopen;
                        let cellrules = [];
                        if (rules) {
                            for (const rr of rules) {
                                let breakit = false;
                                for (const shrule of rr.data) {
                                    if (cellids.has(shrule.colid)) {
                                        // this rule apply to one of our column!
                                        for (const cru of shrule.rule) {
                                            for (const param of cru.params) {
                                                if (!param.value)
                                                    continue;
                                                const parts = param.value.split("~~~");
                                                if (parts.length < 2)
                                                    continue;
                                                if (parts[1] !== dsh.id || (parts.length > 2 && !cellids.has(parts[2]))) {
                                                    cellrules.push(rr);
                                                    breakit = true;
                                                    break;
                                                }
                                                if (param.conds) {
                                                    for (const cond of param.conds) {
                                                        for (const subcon of cond.params) {
                                                            if (subcon.leftcond && !!cellids.has(subcon.leftcond)) {
                                                                cellrules.push(rr);
                                                                breakit = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (breakit)
                                                break;
                                        }
                                    }
                                    if (breakit)
                                        break;
                                }
                            }
                        }
                        if (cellrules.length > 0 || filterentris.length > 0) {
                            if (dshopen) {
                                const dmap = await this.getAllDependentEnt(cellrules, dshopen, letrid, wsid, dens, schemacollection, box, form, appuserid, doneids);
                                if (dmap) {
                                    // lets go over it
                                    for (const [k, v] of dmap) {
                                        const id = k;
                                        if (!databyschemacol.has(id)) {
                                            databyschemacol.set(id, new Map());
                                        }
                                        // possible to have duplicates too other side might beed to fix that!
                                        for (const [vk, vn] of v) {
                                            (_w = databyschemacol.get(id)) === null || _w === void 0 ? void 0 : _w.set(vn.id, vn);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // we do need to re do this as 
            }
        }
        // we should maybe fill in the missing cells now!
        return databyschemacol;
    }
    async getAllDependent(req, box, id) {
        var _a;
        const url = ServerPath.pwagetSchemaDependent;
        const schemabyele = new Map();
        for (const sf of req.schemafields) {
            const parts = sf.split("~~~");
            let sh = "";
            if (sf.endsWith("attach")) {
                sh = parts[0];
            }
            else {
                if (parts.length > 1) {
                    sh = parts[1];
                }
            }
            if (!sh)
                continue;
            if (!schemabyele.has(sh)) {
                schemabyele.set(sh, []);
            }
            (_a = schemabyele.get(sh)) === null || _a === void 0 ? void 0 : _a.push(sf);
        }
        const reqa = [];
        // lets now do this cleverly here!
        let areq = Object.assign(Object.assign({}, req), { schemafields: [] });
        let i = 0;
        for (let k of schemabyele.keys()) {
            const sdata = schemabyele.get(k);
            if (!sdata)
                continue;
            if (i > 0 && i % 5 === 0) {
                reqa.push(areq);
                areq = Object.assign(Object.assign({}, req), { schemafields: [] });
            }
            areq.schemafields.push(...sdata);
            i++;
        }
        if (areq.schemafields.length > 0) {
            reqa.push(areq);
        }
        const pm = [];
        for (const re of reqa) {
            pm.push(comm.post(url, re));
        }
        const cres = await Promise.all(pm);
        const res = {
            data: []
        };
        for (const r of cres) {
            if (r) {
                res.data.push(...r.data);
            }
        }
        // const res = await comm.post<SchemaDependentResponse, SchemaDependentRequest>(url, req);
        if (res !== undefined) {
            for (const r of res.data) {
                r.schemaopen = CONVERTUTIL.convertBoxToSchema(r.schema, req.letrid, box);
                if (r.rules) {
                    const resp = [];
                    for (const ro of r.rules) {
                        try {
                            const bstr = LetrwingCrypto.decryptSecretBox(ro.data, box);
                            if (!bstr)
                                continue;
                            const rules = JSON.parse(bstr);
                            resp.push({
                                schemaid: ro.schemaid,
                                letrid: ro.letrid,
                                id: ro.id,
                                data: rules.numberdata,
                                datarules: rules.datarules,
                                rowid: ro.rowid,
                            });
                        }
                        catch (ex) { }
                    }
                    r.rulesopen = resp;
                }
                if (r.entries && r.entries.length > 0) {
                    const fent = r.entries[0];
                    const dobj = new Map();
                    const sobj = new Map();
                    if (fent && box !== undefined) {
                        if (fent.objs) {
                            const dojs = ProjectStore.convertProjecObjectToProjectEntity(fent.objs, box, req.letrid);
                            for (const fe of dojs) {
                                dobj.set(fe.id, fe);
                            }
                        }
                        if (fent.shs) {
                            for (const sh of fent.shs) {
                                const sok = CONVERTUTIL.convertBoxToSchema(sh, req.letrid, box);
                                if (sok)
                                    sobj.set(sh.id, sok);
                            }
                        }
                    }
                    let i = -1;
                    for (const ent of r.entries) {
                        if (i > 0 && i % 5000 == 0)
                            await timeout(10);
                        i++;
                        if (!ent.rowid) {
                            continue;
                        }
                        // we should check and sabe all the public key saved ones as well!!
                        if (ent.linkedids && ent.linkedids.length > 0) {
                            // lets attach it's object here!
                            if (!ent.objsdec)
                                ent.objsdec = [];
                            if (!ent.schsdec)
                                ent.schsdec = [];
                            for (const lid of ent.linkedids) {
                                const parts = lid.split("-");
                                if (parts.length < 2)
                                    continue;
                                if (lid.startsWith("objs-")) {
                                    const bk = dobj.get(parts[1]);
                                    if (bk) {
                                        ent.objsdec.push(bk);
                                    }
                                }
                                else if (lid.startsWith("dch-") && parts.length > 2) {
                                    const sh = sobj.get(parts[2]);
                                    if (sh) {
                                        ent.schsdec.push(sh);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return res.data;
        }
        return undefined;
    }
    decryptAndSetupDepRemoteItems(data, darr, sh) {
        var _a;
        const mapofdpsh = new Map();
        data.forEach((dt) => {
            mapofdpsh.set(dt.schema.id, dt);
        });
        const mapofdata = new Map();
        const mapofschema = new Map();
        let doneenid = new Set();
        data.forEach((dt) => {
            var _a;
            if (dt.schemaopen) {
                mapofschema.set(dt.schema.id, dt.schemaopen);
            }
            for (const en of dt.entries) {
                if (!doneenid.has(en.id)) {
                    if (!mapofdata.has(dt.schema.datasheetid + "~~~" + dt.schema.id + "~~~" + en.cellid)) {
                        mapofdata.set(dt.schema.datasheetid + "~~~" + dt.schema.id + "~~~" + en.cellid, []);
                    }
                    (_a = mapofdata.get(dt.schema.datasheetid + "~~~" + dt.schema.id + "~~~" + en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
                    doneenid.add(en.id);
                }
            }
        });
        for (const en of darr) {
            if (!mapofdata.has(en.id)) {
                mapofdata.set(en.id, []);
            }
            for (const e of en.entries) {
                if (!doneenid.has(e.id)) {
                    (_a = mapofdata.get(en.id)) === null || _a === void 0 ? void 0 : _a.push(e);
                    doneenid.add(e.id);
                }
            }
        }
        // we should create some missing columns that we might need!!
        // there might be rule 
        const depschemarows = convertDepToRows(mapofdata);
        let ritems = [];
        for (const it of sh.items) {
            if (it.type === SchemaItemType.Remote) {
                ritems.push(it);
            }
            else if (it.type === SchemaItemType.List) {
                const lit = it;
                if (lit.items) {
                    for (const li of lit.items) {
                        if (li.item.type === SchemaItemType.Remote) {
                            ritems.push(li.item);
                        }
                    }
                }
            }
        }
        ritems.forEach((it) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            if (it.type === SchemaItemType.Remote) {
                const srit = it;
                if (srit.did && srit.sid && srit.cid) {
                    const dp = mapofdpsh.get(srit.sid);
                    if (dp !== undefined && dp.schemaopen !== undefined) {
                        const items = (_a = dp.schemaopen.items) !== null && _a !== void 0 ? _a : [];
                        let ischoice = false;
                        for (const item of items) {
                            if (item.id === srit.cid) {
                                // found it 
                                if (item.type === SchemaItemType.Choice) {
                                    // ohh all we need here is choice
                                    ischoice = true;
                                    const id = srit.id + "---" + srit.sid + "---" + srit.cid;
                                    this.dependentdata.set(id, []);
                                    item.items.forEach((it) => {
                                        var _a;
                                        return (_a = this.dependentdata.get(id)) === null || _a === void 0 ? void 0 : _a.push({
                                            id: it.id,
                                            value: it.name,
                                            ischoice: true
                                        });
                                    });
                                }
                                else {
                                    // not an choice 
                                    // we need to check conditions and parse the rules here!
                                    const enmap = new Map();
                                    for (const en of dp.entries) {
                                        if (!enmap.has(en.cellid)) {
                                            enmap.set(en.cellid, []);
                                        }
                                        (_b = enmap.get(en.cellid)) === null || _b === void 0 ? void 0 : _b.push(en);
                                    }
                                    if (srit.conds && srit.conds.length > 0) {
                                        // lets chekc if any rules apply to my entries we can open them up!
                                        for (const con of srit.conds) {
                                            for (const cp of con.params) {
                                                // now we have this 
                                                if (cp.leftcond) {
                                                    const ens = enmap.get(cp.leftcond);
                                                    if (ens) {
                                                        for (const e of ens) {
                                                            if (!e.decrypted) {
                                                                if (e.ruleopen) {
                                                                    parser([e], dp.schemaopen, depschemarows, mapofschema);
                                                                }
                                                                else if (!e.decrypted) {
                                                                    decryptCell(e, dp.schemaopen);
                                                                }
                                                                e.decrypted = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    const id = srit.id + "---" + srit.sid + "---" + srit.cid;
                                    this.dependentdata.set(id, []);
                                    const entries = dp.entries;
                                    for (const en of entries) {
                                        if (!en.decrypted) {
                                            decryptCell(en, dp.schemaopen);
                                        }
                                        if (en.rowbarcode && en.rowbarcode.startsWith("crypt-")) {
                                            en.rowbarcode = (_c = this.decryptBCWithKeyNonde(en.rowbarcode, dp.schemaopen.accesskey, dp.schemaopen.nonce)) !== null && _c !== void 0 ? _c : '';
                                        }
                                        if (en.cellid !== item.id)
                                            continue;
                                        // now we can do some clever filtering and we done!!
                                        if (srit.conds && srit.conds.length > 0) {
                                            const rows = depschemarows.get(srit.sid);
                                            if (!rows)
                                                continue;
                                            const myens = [];
                                            const rind = en.rowid;
                                            for (const r of rows) {
                                                if (r.rowid === rind) {
                                                    for (const e1 of r.entry) {
                                                        myens.push(e1);
                                                    }
                                                }
                                            }
                                            if (!canShowRemoteEntry(en, srit.conds, myens)) {
                                                continue;
                                            }
                                        }
                                        if (item.type === SchemaItemType.Text) {
                                            (_d = this.dependentdata.get(id)) === null || _d === void 0 ? void 0 : _d.push({
                                                id: en.id,
                                                value: (_e = stripHTML(en.celldata)) !== null && _e !== void 0 ? _e : '',
                                                rowid: en.rowid,
                                                rowbarcode: en.rowbarcode
                                            });
                                        }
                                        else if (item.type === SchemaItemType.Date) {
                                            (_f = this.dependentdata.get(id)) === null || _f === void 0 ? void 0 : _f.push({
                                                id: en.id,
                                                rowid: en.rowid,
                                                rowbarcode: en.rowbarcode,
                                                value: DateLib.displayDateWIthFormat(en.celldata, item.inlcudetime, (_g = item.excludedate) !== null && _g !== void 0 ? _g : false)
                                            });
                                        }
                                        else {
                                            (_h = this.dependentdata.get(id)) === null || _h === void 0 ? void 0 : _h.push({
                                                id: en.id,
                                                value: en.celldata,
                                                rowid: en.rowid,
                                                rowbarcode: en.rowbarcode
                                            });
                                        }
                                    }
                                }
                                break;
                            }
                        }
                        const rtextra = srit.rtextra;
                        if (rtextra !== undefined && rtextra.length > 0) {
                            for (const ext of rtextra) {
                                const extid = ext.cid;
                                if (ext.cid) {
                                    for (const item of items) {
                                        if (item.id === ext.cid) {
                                            // found it 
                                            const enmap = new Map();
                                            for (const en of dp.entries) {
                                                if (!enmap.has(en.cellid)) {
                                                    enmap.set(en.cellid, []);
                                                }
                                                (_j = enmap.get(en.cellid)) === null || _j === void 0 ? void 0 : _j.push(en);
                                            }
                                            if (item.type === SchemaItemType.Choice) {
                                            }
                                            else {
                                                // not an choice 
                                                // we need to check conditions and parse the rules here!
                                                if (srit.conds && srit.conds.length > 0) {
                                                    // lets chekc if any rules apply to my entries we can open them up!
                                                    for (const con of srit.conds) {
                                                        for (const cp of con.params) {
                                                            // now we have this 
                                                            if (cp.leftcond) {
                                                                const ens = enmap.get(cp.leftcond);
                                                                if (ens) {
                                                                    for (const e of ens) {
                                                                        if (e.ruleopen) {
                                                                            parser([e], dp.schemaopen, depschemarows, mapofschema);
                                                                        }
                                                                        else if (!e.decrypted) {
                                                                            GDecryptcell(e, dp.schemaopen);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                const id = srit.id + "---" + srit.sid + "---" + ext.cid;
                                                this.dependentdata.set(id, []);
                                                // this.rtdependentdata.set(id, []);
                                                const entries = dp.entries;
                                                for (const en of entries) {
                                                    if (!en.decrypted) {
                                                        GDecryptcell(en, dp.schemaopen);
                                                    }
                                                    if (en.rowbarcode && en.rowbarcode.startsWith("crypt-")) {
                                                        en.rowbarcode = (_k = this.decryptBCWithKeyNonde(en.rowbarcode, dp.schemaopen.accesskey, dp.schemaopen.nonce)) !== null && _k !== void 0 ? _k : '';
                                                    }
                                                    if (en.cellid !== item.id)
                                                        continue;
                                                    // now we can do some clever filtering and we done!!
                                                    let cons = srit.conds;
                                                    if (cons && cons.length > 0) {
                                                        // cons = filterInvalidConsitions(cons);
                                                    }
                                                    if (cons && cons.length > 0) {
                                                        const rows = depschemarows.get(srit.sid);
                                                        if (!rows)
                                                            continue;
                                                        const myens = [];
                                                        const rind = en.rowid;
                                                        for (const r of rows) {
                                                            if (r.rowid === rind) {
                                                                for (const e1 of r.entry) {
                                                                    myens.push(e1);
                                                                }
                                                            }
                                                        }
                                                        if (!canShowRemoteEntry(en, cons, myens)) {
                                                            continue;
                                                        }
                                                    }
                                                    if (item.type === SchemaItemType.Text) {
                                                        (_l = this.dependentdata.get(id)) === null || _l === void 0 ? void 0 : _l.push({
                                                            id: en.id,
                                                            rowid: en.rowid,
                                                            rowbarcode: en.rowbarcode,
                                                            value: (_m = stripHTML(en.celldata)) !== null && _m !== void 0 ? _m : '',
                                                        });
                                                        // this.rtdependentdata.get(id)?.push({
                                                        //     id: en.id,
                                                        //     rowid: [en.rowid],
                                                        //     rowbarcode: en.rowbarcode,
                                                        //     value: stripHTML(en.celldata) ?? '',
                                                        // });
                                                    }
                                                    else if (item.type === SchemaItemType.Date) {
                                                        (_o = this.dependentdata.get(id)) === null || _o === void 0 ? void 0 : _o.push({
                                                            id: en.id,
                                                            rowid: en.rowid,
                                                            rowbarcode: en.rowbarcode,
                                                            value: DateLib.displayDateWIthFormat(en.celldata, item.inlcudetime, (_p = item.excludedate) !== null && _p !== void 0 ? _p : false),
                                                        });
                                                        // this.rtdependentdata.get(id)?.push({
                                                        //     id: en.id,
                                                        //     rowid: [en.rowid],
                                                        //     rowbarcode: en.rowbarcode,
                                                        //     value: DateLib.displayDateWIthFormat(en.celldata,
                                                        //         (item as DateItem).inlcudetime, (item as DateItem).excludedate ?? false)
                                                        // });
                                                    }
                                                    else if (item.type === SchemaItemType.Objects) {
                                                        if (en.linkedids && en.linkedids.length > 0) {
                                                            let obids = [];
                                                            const pmap = new Map();
                                                            (_q = en.objsdec) === null || _q === void 0 ? void 0 : _q.forEach((ob) => pmap.set("objs-" + ob.id, ob));
                                                            const smap = new Map();
                                                            (_r = en.schsdec) === null || _r === void 0 ? void 0 : _r.forEach((sj) => smap.set(sj.id, sj));
                                                            for (const l of en.linkedids) {
                                                                if (l.startsWith("objs-")) {
                                                                    const obj = pmap.get(l);
                                                                    if (obj) {
                                                                        const tds = obj.type === ProjectObjectType.Document ? obj.thumbdetail : undefined;
                                                                        obids.push({
                                                                            id: l,
                                                                            type: 'document',
                                                                            obj: obj,
                                                                            name: obj.name,
                                                                            thumdet: tds
                                                                        });
                                                                    }
                                                                }
                                                                else if (l.startsWith("dch-")) {
                                                                    const parts = l.split("-");
                                                                    if (parts.length > 2) {
                                                                        const did = parts[1];
                                                                        const sid = parts[2];
                                                                        const sh = smap.get(sid);
                                                                        if (sh) {
                                                                            obids.push({
                                                                                id: l,
                                                                                type: 'datasheet',
                                                                                obj: sh.name,
                                                                                name: sh.name
                                                                            });
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            if (obids.length > 0) {
                                                                (_s = this.dependentdata.get(id)) === null || _s === void 0 ? void 0 : _s.push({
                                                                    id: en.id,
                                                                    rowid: en.rowid,
                                                                    rowbarcode: en.rowbarcode,
                                                                    value: '',
                                                                    objects: obids,
                                                                    cellid: en.cellid,
                                                                    schemaid: en.schemaid
                                                                });
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        (_t = this.dependentdata.get(id)) === null || _t === void 0 ? void 0 : _t.push({
                                                            id: en.id,
                                                            rowid: en.rowid,
                                                            rowbarcode: en.rowbarcode,
                                                            value: en.celldata
                                                        });
                                                        // this.rtdependentdata.get(id)?.push({
                                                        //     id: en.id,
                                                        //     rowid: [en.rowid],
                                                        //     rowbarcode: en.rowbarcode,
                                                        //     value: en.celldata
                                                        // });
                                                    }
                                                }
                                            }
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    }
    decryptBCWithKeyNonde(bc, key, nonce) {
        if (bc && bc.startsWith("crypt-") && key && nonce) {
            bc = bc.substring("crypt-".length);
            const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: bc, nonce: '', publickey: '' }, key, nonce);
            bc = val !== null && val !== void 0 ? val : '';
            return bc;
        }
        return undefined;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "loadingrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "formkey", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "loadingrowsmessage", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "chartstate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "pwaauthstate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "formmessage", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "sessionname", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "jobupdate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "buildingwebform", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "statusmessage", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showdetail", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "addnewviewentry", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "addnewviewentryviaai", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "fetchingupdates", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaStore.prototype, "activeview", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "displayjs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showaicreate", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "webfilterjs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "aicontent", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "custompaneljs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "notificationregistered", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "keepbuttonwithcustomview", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "quicksearchactive", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "rowreadonly", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "rowpermissions", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "online", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "chartreload", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "selectedorgid", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "selectedrowids", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "selecteditemid", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "currentpermissions", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "loadingcontents", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "loadingitem", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "loadingerror", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showinfopanelshow", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "parsingentry", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "aimessages", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "activeforms", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "activeviews", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "viewsearchtext", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "columnwidths", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaStore.prototype, "currentitems", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "readonlyitems", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "formentry", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "objectselectedfiles", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "linkforms", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaStore.prototype, "listitems", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "activeviewtitle", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "alljobs", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "uirows", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "rowRules", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "storingform", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "checkingandcollecting", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "editselectedfiles", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], PwaStore.prototype, "newrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PwaStore.prototype, "showrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], PwaStore.prototype, "addrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "deleteingrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showreport", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "activeschemametadata", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "activeschemametadatamap", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showrowsilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "hiddenrows", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "activeusers", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showssortdialog", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showScrollToButton", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PwaStore.prototype, "cardview", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showcolumnview", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "hiddencolumns", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "zoomfactor", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "activeitemid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "vieweractive", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "title", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "activeDocumentId", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "activefileid", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "filemetamap", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PwaStore.prototype, "loadingfinddict", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PwaStore.prototype, "gettingbookmarks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showwatermakr", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "watermakr", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "docmap", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "showdocumentinfo", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "isViewable", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "queued", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "isexcel", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], PwaStore.prototype, "triggersbyCols", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "triggerrowids", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "searchactive", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "ignorerebuildcustomcomponent", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PwaStore.prototype, "rebuildingcustom", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaStore.prototype, "activedetailtab", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], PwaStore.prototype, "forcerebuildcustom", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PwaStore.prototype, "twopanelview", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PwaStore.prototype, "activedisplayview", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveTab", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveDisplayView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowAiCreate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setSearchActive", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setViewable", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setQueued", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setIsExcel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "showDocumentInfo", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "enableWatermark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setWatermark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setPdfPageDetails", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveFileId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "resetPdfPage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setFromKey", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setLoadingFindDict", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setGettingBookmarks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clear", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setLoading", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String, String, Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveItemid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setViewerActive", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setTitle", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "hideColumn", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveDocumentId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "showColumn", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowColumns", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearSelectedRowids", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addSelectedRowid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeSelectedRowid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setDisplayJS", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setWebFilterJS", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setKeepButtonsWithCustomView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "selectAllRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "unselectAllRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setCardView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setTwoPanelView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowScrollToButton", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "filterRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "filterRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "filterUsingScanner", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "showAllRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowSortDialog", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setChartReload", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowRowFilter", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setLoadingRowsmessage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setAddNewViewEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setAddNewViewEntryViaAi", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setAiMessages", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setAiContent", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearCurrentPermissions", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addPerm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removePerm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveSchemaMetadata", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowReport", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addNewRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setAddRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setStoringForm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setCollectingData", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setDeleteingRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "resetSchema", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearRowRules", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearDatajobs", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setJobUpdate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setBuildingForm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setInternetStatus", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setSessionName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearActiveEdits", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "cancelEntryEdit", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "resetSchemaForm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, File]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addFile", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, File]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeFile", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setLoadingRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "hiderow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "showRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setFetchingUpdates", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Number]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setColWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Map, Boolean]),
    __metadata("design:returntype", Promise)
], PwaStore.prototype, "filterViewRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addChart", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearChartState", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object, String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "getDatapointsForChart", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String, String, String, Object, String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "buildChart", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRuleEntryToRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Map]),
    __metadata("design:returntype", Promise)
], PwaStore.prototype, "addRuleEntryToRows", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRowRule", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearRowMap", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array, String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRowToRemoteSheet", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRowToMobx", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array, Boolean, Boolean]),
    __metadata("design:returntype", Promise)
], PwaStore.prototype, "addRowInline", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addListItem", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addFormmessage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeListItem", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "resetListItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setRebuildingCustom", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setIgnoreCustomComponentId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeIgnoreCustomComponentId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object, Boolean, Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addFormEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRebuildId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeRebuildId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setLoadingContents", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setLoadingItem", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setPwaLoginState", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setNotificationRegistered", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addDataJobToMap", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "removeDataJob", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setSelectOrgId", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearWebFormMap", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearWebViewMap", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "cleanParsingEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearFormInmemoryData", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveViewTitle", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setShowDetail", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addWebForm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "deleteWebForm", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addWebView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "deleteWebView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setActiveView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setROItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearRowReadonly", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "clearActiveUsers", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addActiveUsers", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRowReadonly", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addRowPermission", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "parseCellRules", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addTrigger", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "setFileMeta", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], PwaStore.prototype, "addDocumentEntity", null);
