var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { SketchPicker } from 'react-color';
import { AppColor, FontSize } from "../const/AppDefaults";
export class ColorStore {
    constructor() {
        this.topposition = 30;
        this.leftposition = 200;
        this.width = 250;
        this.height = 400;
        this.dragging = false;
        this.show = false;
        this.color = "";
        this.onok = () => { };
        makeObservable(this);
    }
    reset() {
        this.topposition = 30;
        this.leftposition = 200;
        this.width = 250;
        this.height = 400;
        this.dragging = false;
        this.show = false;
        this.color = "";
    }
    setColor(color) {
        this.color = color;
    }
    setRGBAString(color) {
        this.color = color;
    }
    setShow(show) {
        this.show = show;
    }
    setDragging(drag) {
        this.dragging = drag;
    }
    setToppos(pos) {
        this.topposition = pos;
    }
    setLeftPos(pos) {
        this.leftposition = pos;
    }
    convertRGBToString(rgb) {
        return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + rgb.a + ")";
    }
    convertRGBStringToRGB(rgb) {
        let clearstr = '';
        if (rgb.startsWith("rgba(")) {
            clearstr = rgb.replace("rgba(", "").replace(")", "");
        }
        else if (rgb.startsWith("rgb(")) {
            clearstr = rgb.replace("rgb(", "").replace(")", "");
        }
        const parts = clearstr.split(",");
        return { r: parseInt(parts[0]), g: parseInt(parts[1]), b: parseInt(parts[2]), a: parseFloat(parts[3]) };
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "topposition", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "leftposition", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "height", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "dragging", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ColorStore.prototype, "color", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "setColor", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "setRGBAString", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "setDragging", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "setToppos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], ColorStore.prototype, "setLeftPos", null);
let ColorDialog = class ColorDialog extends React.Component {
    constructor(prop) {
        super(prop);
        this.adjustx = 0;
        this.adjusty = 0;
        this.boxref = null;
        window.addEventListener("mouseup", () => {
            this.props.state.setDragging(false);
        });
        window.addEventListener("mousemove", (e) => {
            if (this.props.state.dragging) {
                // lets move this dialog 
                this.props.state.setToppos(e.clientY - this.adjusty);
                this.props.state.setLeftPos(e.clientX - this.adjustx);
            }
        });
    }
    render() {
        if (!this.props.state.show) {
            return React.createElement(Box, null);
        }
        return React.createElement(Box, { "color-box-drag": 'colorbox', ref: (ref) => this.boxref = ref, bg: 'white', borderRadius: '4px', borderWidth: '1px', borderColor: 'transparent', boxShadow: '5px 5px 5px #f2f2f2', zIndex: 10005000, cursor: this.props.state.dragging ? 'grabbing' : '', onMouseDown: (e) => {
                var _a, _b;
                const tgt = e.target;
                if (tgt.getAttribute("color-box-drag")) {
                    this.props.state.setDragging(true);
                    if (this.boxref) {
                        this.adjustx = e.clientX - ((_a = this.boxref) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().left);
                        this.adjusty = e.clientY - ((_b = this.boxref) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().top);
                    }
                }
            }, position: 'fixed', left: this.props.state.leftposition + "px", top: this.props.state.topposition + "px", width: this.props.state.width + "px", height: this.props.state.height + "px" },
            React.createElement(Flex, { "border-bottom": '1px solid lightgray', "color-box-drag": 'colorbox', height: "35px", bg: AppColor.AnnotationDialogHeader, align: 'center' },
                React.createElement(Flex, { width: '90%', "color-box-drag": 'colorbox' },
                    React.createElement(Text, { "color-box-drag": 'colorbox', fontWeight: 'bold', fontSize: FontSize.InfoText, color: AppColor.InfoColor, ml: '5px' }, "Color Chooser"),
                    React.createElement(Box, { "color-box-drag": 'colorbox', bg: this.props.state.color, w: '20px', h: '20px', ml: '10px', borderRadius: '10px', borderWidth: '1px', borderColor: 'transparent' })),
                this.props.resetbtn &&
                    React.createElement(Button, { colorScheme: 'lwcolor', size: 'xs', mr: '5px', onClick: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.props.state.setColor('');
                            this.props.state.setShow(false);
                            this.props.state.onok();
                        } }, "Reset"),
                React.createElement(Button, { colorScheme: 'lwcolor', size: 'xs', mr: '5px', onClick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.state.setShow(false);
                        this.props.state.onok();
                    } }, "OK")),
            React.createElement(Box, { "color-box-drag": 'colorbox', height: this.props.state.height * .80 + "px", maxH: this.props.state.height * .80 + "px", overflowY: 'auto' },
                React.createElement(Box, null,
                    React.createElement(Flex, { mt: '10px', justify: 'center' },
                        React.createElement(SketchPicker, { color: this.props.state.convertRGBStringToRGB(this.props.state.color), onChange: ({ rgb }) => this.props.state.setColor(this.props.state.convertRGBToString(rgb)) })))));
    }
};
ColorDialog = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ColorDialog);
export { ColorDialog };
