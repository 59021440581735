export class SimpleSchemaUIRow {
    constructor(vis = false, entries = []) {
        this.rowindex = -1;
        this.uirowindex = -1;
        this.rowid = "";
        this.childrenschemalist = [];
        this.entry = [];
        this.hidden = false;
        this.objsdec = [];
        this.shsdec = [];
        this.linkedids = [];
        this.referenceids = [];
        this.hidden = vis;
        this.entry = entries;
    }
    setVisibility(vis) {
        this.hidden = vis;
    }
    addEntries(entries) {
        this.entry = entries;
    }
}
