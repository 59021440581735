import React from "react";
import { AletrDialogStore, } from "./AlertDialogStore";
import { ConfirmStore } from "./ConfirmDialogStore";
import { PwaStore } from "./PwaStore";
import { toast } from 'react-toastify';
import { PwaRemoteStore } from "./PwaRemoteStore";
import { PwaMiniViwerState } from "./PwaMiniViewerState";
import { PwaWorkingDialogState } from "./PwaWorkingDialogState";
import { CustomdisplayFormState } from "./CustomDisplayFormState";
export const StoreContext = React.createContext(null);
export class RootStore {
    constructor() {
        this.pwastore = new PwaStore();
        this.alertstore = new AletrDialogStore();
        this.confirmstore = new ConfirmStore();
        this.pwaremotestore = new PwaRemoteStore();
        this.pwaminiviewerstore = new PwaMiniViwerState();
        this.wdialog = new PwaWorkingDialogState();
        this.customformstate = new CustomdisplayFormState();
    }
    getPwaStore() {
        return this.pwastore;
    }
    getPwaMiniViewerStore() {
        return this.pwaminiviewerstore;
    }
    get confirmStore() {
        return this.confirmstore;
    }
    get aletrDialogStore() {
        return this.alertstore;
    }
    showToastSuccess(message) {
        toast(message, {
            position: 'top-right',
            type: 'success'
        });
    }
    showToastError(message) {
        toast(message, {
            position: 'top-right',
            type: 'error'
        });
    }
    getPwaRemoteStore() {
        return this.pwaremotestore;
    }
}
