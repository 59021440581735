import { AppConstants } from "./Constants";
import { LetrwingCrypto } from "./LetrwingCommonCrypto";
export var LetrType;
(function (LetrType) {
    LetrType["Letr"] = "Letr";
    LetrType["Wing"] = "Wing";
})(LetrType || (LetrType = {}));
export class Letr {
    constructor() {
        this.id = "";
        this.letrMetadata = new LetrMetadata();
        this.author = "";
        this.recipients = [];
        this.lastmessage = undefined;
        this.title = "";
        this.isExternal = false;
        this.readonly = false;
        this.hasConnects = false;
        this.numberofconnects = 0;
        this.letrtype = LetrType.Letr;
        this.authorname = "";
        this.origletrid = "";
    }
}
export class LetrMetadata {
    constructor() {
        this.id = "";
        this.creationtime = 0;
        this.updatetime = 0;
        this.subject = "";
        this.tags = [];
    }
}
export const hasExternal = (letr, tenant) => {
    const recp = letr.recipients;
    if (recp) {
        for (const r of recp) {
            const bits = r.split(AppConstants.TenantSep);
            if (bits[0] !== tenant) {
                return true;
            }
        }
    }
    return false;
};
export var LeterPermissionType;
(function (LeterPermissionType) {
    LeterPermissionType["View"] = "View";
    LeterPermissionType["Edit"] = "Edit";
})(LeterPermissionType || (LeterPermissionType = {}));
export function instanceOfLetrLinkUnboxed(obj) {
    return "letrid" in obj &&
        "id" in obj &&
        "letrrefbox" in obj &&
        "databox" in obj;
}
export const SAFESEP = "__<<>>__";
class LetrStore {
    // include the creatore in the number..
    createNewLetrBox(subject, memberid, myId) {
        var _a;
        const datakey = LetrwingCrypto.generateNewSecretKey();
        const ret = {
            datakey: datakey,
        };
        const subjectbox = LetrwingCrypto.secretBox(subject, datakey);
        const perm = [];
        const members = new Set();
        for (const key of memberid) {
            perm.push({ userid: key.userid, permission: (_a = key.permission) !== null && _a !== void 0 ? _a : LeterPermissionType.View, roleid: key.roleid });
            members.add(key.userid);
        }
        const totalusers = members.size + 1;
        members.add(myId);
        const sletr = {
            id: "",
            subject: subjectbox,
            memeberid: Array.from(Array.from(members)),
            permissions: perm
        };
        return { keysBox: ret, req: sletr };
    }
}
export const LStore = new LetrStore();
