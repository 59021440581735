var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export const TesseractLang = [
    { label: 'Arabic', value: 'ara' },
    { label: 'Assamese', value: 'asm' },
    { label: 'Bengali', value: 'ben' },
    { label: 'English', value: 'eng' },
    { label: 'German', value: 'deu' },
    { label: 'German - Fraktur', value: 'deu_frak' },
    { label: 'Gujarati', value: 'guj' },
    { label: 'Hindi', value: 'hin' },
    { label: 'Irish', value: 'gle' },
    { label: 'Japanese', value: 'jpn' },
    { label: 'Oriya', value: 'ori' },
    { label: 'Persian', value: 'fas' },
    { label: 'Punjabi / Panjabi', value: 'pan' },
    { label: 'Sanskrit', value: 'san' },
    { label: 'Scottish Gaelic', value: 'gla' },
    { label: 'Sindhi', value: 'snd' },
    { label: 'Tamil', value: 'tam' },
    { label: 'Urdu', value: 'urd' }
];
export class LetrwingFile {
    constructor() {
        this.id = 0;
        this.cancelable = true;
        this.isDownloadable = false;
        this.failed = false;
        this.done = false;
        this.uploadAsImage = false;
        this.inqueue = false;
        this.uploading = false;
        this.ocr = true;
        this.addwatermark = true;
        this.langs = new Map();
        this.showlanguagemenu = false;
        this.file = undefined;
        this.progress = 0;
        makeObservable(this);
        this.langs.set("eng", { label: 'English', value: 'eng' });
    }
    setUploadAsImage(image) {
        this.uploadAsImage = image;
    }
    setInQueue(inqueue) {
        this.inqueue = inqueue;
    }
    setShowLangMenu(inqueue) {
        this.showlanguagemenu = inqueue;
    }
    setIsDownloadble(down) {
        console.log(down);
        this.isDownloadable = down;
    }
    setDone(done) {
        this.done = done;
    }
    setOcr(ocr) {
        this.ocr = ocr;
    }
    setWatermark(wm) {
        this.addwatermark = wm;
    }
    addLang(wm) {
        this.langs.set(wm.value, wm);
    }
    removeLang(wm) {
        this.langs.delete(wm);
    }
    clearLang() {
        this.langs.clear();
    }
    setFailed(failed) {
        this.failed = failed;
    }
    setCancelable(cancel) {
        this.cancelable = cancel;
    }
    setProgress(prog) {
        this.progress = prog;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "cancelable", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "isDownloadable", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "failed", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "done", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "uploadAsImage", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "inqueue", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "ocr", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "addwatermark", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], LetrwingFile.prototype, "langs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LetrwingFile.prototype, "showlanguagemenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], LetrwingFile.prototype, "progress", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setUploadAsImage", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setInQueue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setShowLangMenu", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setIsDownloadble", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setDone", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setOcr", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setWatermark", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "addLang", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "removeLang", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "clearLang", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setFailed", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setCancelable", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], LetrwingFile.prototype, "setProgress", null);
export class EditorStore {
    constructor() {
        this.editorHTML = '';
        this.selectedFiles = [];
        this.editorHeight = 0;
        this.editorHeightOffset = 0;
        makeObservable(this);
    }
    setEditorHTML(html) {
        this.editorHTML = html;
    }
    setEditorHeight(height) {
        this.editorHeight = height;
    }
    setEditorHeightOffset(offset) {
        this.editorHeightOffset = offset;
    }
    resetSelectedFiles() {
        this.selectedFiles = [];
    }
    removeSelectedFile(f) {
        this.selectedFiles.map((file, index) => {
            if (file.id === f.id) {
                this.selectedFiles.splice(index, 1);
            }
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], EditorStore.prototype, "editorHTML", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], EditorStore.prototype, "selectedFiles", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], EditorStore.prototype, "editorHeight", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], EditorStore.prototype, "editorHeightOffset", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], EditorStore.prototype, "setEditorHTML", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], EditorStore.prototype, "setEditorHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], EditorStore.prototype, "setEditorHeightOffset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], EditorStore.prototype, "resetSelectedFiles", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [LetrwingFile]),
    __metadata("design:returntype", void 0)
], EditorStore.prototype, "removeSelectedFile", null);
