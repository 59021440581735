import { comm } from "@/Comm/comm";
import { DraftExportStage } from "@/share/Draft";
import { DraftType } from "@@/Draft";
import { EncryptBox } from "@@/EncryptBox";
import { JobPriority } from "@@/File";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { JobType } from "@@/ModuleJob";
import { ServerPath } from "@@/ServerPath";
class Store {
    async createDraft(letrid, name) {
        const meta = {
            name: name,
            date: new Date().getTime()
        };
        const accesskey = LetrwingCrypto.generateNewSecretKey();
        const ebox = LetrwingCrypto.secretBox(JSON.stringify(meta), accesskey);
        const id = await comm.post(ServerPath.createNewDraft, {
            letrid: letrid,
            metabox: ebox
        });
        if (id === undefined) {
            return undefined;
        }
        return [accesskey, id];
    }
    async drafting(did, letrid) {
    }
    async getLockForDraft(did, letrid) {
    }
    async releaseLockForDraft(did, letrid) {
    }
    async getDraftContent(did, letrid, accesskey, versionnumber, objectid, oid) {
        const req = {
            draftid: did,
            letrid: letrid,
            startindex: 0,
            endindex: 0
        };
        const res = await comm.post(ServerPath.getDraftChunkMeta, req);
        if (res === undefined) {
            return undefined;
        }
        const totalchunks = res.totalchunks;
        let draft = "";
        for (let i = 0; i <= totalchunks; i++) {
            const wait = [];
            for (let j = 0; j < 5 && i <= totalchunks; j++) {
                req.startindex = i;
                req.endindex = i;
                wait.push(comm.post(ServerPath.getDraftChunk, req));
                i++;
            }
            i--;
            const res = await Promise.all(wait);
            for (const rdata of res) {
                if (rdata !== undefined && rdata.length > 0) {
                    for (const rdt of rdata) {
                        try {
                            const rbox = rdt.data;
                            const str = LetrwingCrypto.decryptSecretBox(rbox, accesskey);
                            draft += str;
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }
                }
            }
        }
        let time = 0; // all updates
        if (res.lastupdatetime) {
            time = res.lastupdatetime;
        }
        const updates = await this.getDraftUpdates(letrid, objectid, oid, did, time);
        const result = {
            granted: true,
            currentlockusername: "",
            readonly: true,
            decryptedcontent: draft,
            updates: updates !== null && updates !== void 0 ? updates : [],
            lastupdatedtime: time
        };
        return result;
    }
    async getDraftContentForRead(did, letrid, accesskey, versionnumber, objectid, oid) {
        const res = await this.getDraftContent(did, letrid, accesskey, versionnumber, objectid, oid);
        if (res !== undefined) {
            return res;
        }
        const updates = await this.getDraftUpdates(letrid, objectid, oid, did, 0);
        const result = {
            granted: true,
            currentlockusername: "",
            readonly: true,
            decryptedcontent: "",
            updates: updates !== null && updates !== void 0 ? updates : [],
            lastupdatedtime: 0
        };
        return result;
    }
    async getDraftContent_old(did, letrid, accesskey, versionnumber) {
        const dconreq = {
            id: did,
            letrid: letrid,
            versionnumber: versionnumber
        };
        const res = await comm.post(ServerPath.getDraftContent, dconreq);
        if (res !== undefined) {
            try {
                // lets do something here!
                const result = {
                    granted: true,
                    currentlockusername: "",
                    readonly: true,
                    updates: []
                };
                const bin = LetrwingCrypto.getUTF8FromBin(res);
                const datac = JSON.parse(bin);
                // now we have datac lets do something else now!
                if (datac !== undefined && datac.data) {
                    const opencintent = LetrwingCrypto.decryptSecretBox(datac.data, accesskey);
                    // lets do something clever here!
                    result.decryptedcontent = opencintent;
                    return result;
                }
            }
            catch (ex) {
                return undefined;
            }
        }
        return undefined;
    }
    async storeDraftUpdate(letrid, objectid, draftid, update, id, origid) {
        const djob = {
            id: id,
            letrid: letrid,
            draftid: draftid,
            origobjectid: origid,
            objectid: objectid,
            time: new Date().getTime(),
            update: update,
            userid: ''
        };
        const res = await comm.post(ServerPath.draftUpdate, djob);
        return res !== null && res !== void 0 ? res : false;
    }
    async getDraftUpdates(letrid, objectid, origid, draftid, since) {
        const djob = {
            id: "",
            letrid: letrid,
            draftid: draftid,
            objectid: objectid,
            origobjectid: origid,
            time: since,
            update: new EncryptBox(),
            userid: ''
        };
        const updates = await comm.post(ServerPath.getDraftUpdates, djob);
        // now lets go and do this!!
        return updates;
    }
    async exportExcel(content, letrid, accesskey, draftid) {
        const ejob = {
            type: DraftType.XLSX,
            data: content,
            resultkey: LetrwingCrypto.generateNewSecretKey()
        };
        const job = {
            jobtype: JobType.DraftExport,
            data: LetrwingCrypto.getBase64FromUT8(JSON.stringify(ejob))
        };
        const res = await comm.get(ServerPath.getmodpubkey);
        if (res !== undefined) {
            const req = [];
            for (const re of res) {
                const box = LetrwingCrypto.box(JSON.stringify(job), re.publickey);
                if (box !== null) {
                    req.push({
                        data: box,
                        lrvmdata: re,
                        reference: LetrwingCrypto.hash512str("draft" + draftid),
                        priority: JobPriority.High
                    });
                }
            }
            if (req.length > 0) {
                const mid = await comm.post(ServerPath.addnewjob, req);
                if (mid === undefined) {
                    return false;
                }
                // now we should just store everything and we done!!
                // we have draftid etc!
                const detail = {
                    id: '',
                    exportid: mid,
                    letrid: letrid,
                    draftid: draftid,
                    detail: LetrwingCrypto.secretBox(ejob.resultkey, accesskey)
                };
                // now lets just store this and we done!!
                const res = await comm.post(ServerPath.draftexport, detail);
                return res !== null && res !== void 0 ? res : false;
            }
        }
        return false;
    }
    async getDraftExportJobStatus(draftid, letrid, accesskey) {
        var _a;
        // now we are going to get these!!
        const req = {
            letrid: letrid,
            draftid: draftid,
        };
        const res = await comm.post(ServerPath.draftExportJobStatus, req);
        if (res !== undefined) {
            // lets see if we can get the result!!
            const result = [];
            for (const ed of res) {
                const ekey = LetrwingCrypto.decryptSecretBox(ed.detail, accesskey);
                if (ekey === undefined) {
                    continue;
                }
                const obj = {
                    time: (_a = ed.ctime) !== null && _a !== void 0 ? _a : 0,
                    stage: DraftExportStage.Processing,
                    id: ed.id,
                    draftid: draftid
                };
                if (ed.result !== undefined) {
                    if (ed.result.systemerror) {
                        obj.stage = DraftExportStage.Failed;
                    }
                    else {
                        const rdata = LetrwingCrypto.decryptSecretBox(ed.result.result, ekey);
                        obj.result = rdata;
                        obj.stage = DraftExportStage.Done;
                    }
                }
                result.push(obj);
            }
            return result;
        }
        return undefined;
    }
    async getPasteFormatted(content) {
        const res = await comm.post(ServerPath.getPasteFormmated, { data: content });
        return res;
    }
    async getEditDraft(did, letrid, accesskey, objectid, oid) {
        const req = {
            id: did,
            letrid: letrid,
            accesskey: "",
            readonlymode: true,
            objectid: objectid,
            origid: oid
        };
        const res = await comm.post(ServerPath.lockEdit, req);
        if (res === null || res === void 0 ? void 0 : res.granted) {
            res.readonly = false;
            const resp = await this.getDraftContent(did, letrid, accesskey, -1, objectid, oid);
            // we should also now get the draft updates
            if (resp !== undefined) {
                res.decryptedcontent = resp.decryptedcontent;
                res.updates = resp.updates;
                res.lastupdatedtime = resp.lastupdatedtime;
            }
            else {
                // all time usually now these will be the case
                const updates = await this.getDraftUpdates(letrid, objectid, oid, did, 0);
                res.updates = updates !== null && updates !== void 0 ? updates : [];
            }
        }
        else {
        }
        return res;
    }
    async getDraftDetails(did, letrid, accesskey) {
        const res = await comm.post(ServerPath.getDraftdetail, { letrid: letrid, draftid: did });
        if (res !== undefined) {
            // now we have the respons elets do something here!!
            const metainfo = LetrwingCrypto.decryptSecretBox(res.metabox, accesskey);
            if (metainfo !== undefined && metainfo !== null) {
                res.metabox = new EncryptBox();
                return res;
            }
        }
        return undefined;
    }
    async publishContent(did, letrid, accesskey, draft, draftupdatetime = -1) {
        const dbox = LetrwingCrypto.secretBox(draft, accesskey);
        // now we have dbox lets store this that's all!
        // lets now stpre this
        const dcontent = {
            id: did,
            letrid: letrid,
            data: dbox,
            size: 0,
            modifierid: "",
            lastmodifiedtime: draftupdatetime,
            referenceid: did
        };
        const res = await comm.post(ServerPath.updateDraftContent, dcontent);
        if (res === undefined || !res) {
            return undefined;
        }
        return res;
    }
    async updateLock(did, letrid) {
        const req = {
            letrid: letrid,
            id: did
        };
        const res = await comm.post(ServerPath.updateLock, req);
        if (res === undefined || !res) {
            return false;
        }
        return true;
    }
    // this is serialised object 
    async storeDraft(draft, letrid, did, accesskey, time = -1) {
        const strlength = draft.length;
        // okay we have this now!
        const chunksize = 2 * 1024 * 1024;
        const totachunks = Math.ceil(strlength / chunksize);
        const dmeta = {
            id: did,
            letrid: letrid,
            draftid: did,
            size: strlength,
            totalchunks: totachunks,
            lastupdatetime: time > 0 ? time : 0
        };
        const res = await comm.post(ServerPath.addDraftChunkMeta, dmeta);
        if (res === undefined || !res) {
            return false;
        }
        let offset = 0;
        let chunkindex = 0;
        while (offset < strlength) {
            const chunks = [];
            for (let i = 0; i < 5 && offset < strlength; i++) {
                let end = offset + chunksize;
                if (end > strlength) {
                    end = strlength;
                }
                const str = draft.substring(offset, end);
                const ebox = LetrwingCrypto.secretBox(str, accesskey);
                const cbox = {
                    id: did,
                    letrid: letrid,
                    draftid: did,
                    chunkindex: chunkindex,
                    data: ebox
                };
                chunks.push(cbox);
                offset = end;
                chunkindex++;
            }
            const promises = [];
            for (const chunk of chunks) {
                promises.push(comm.post(ServerPath.addDraftChunk, chunk));
            }
            const wait = await Promise.all(promises);
            for (const res of wait) {
                if (!res) {
                    return false;
                }
            }
        }
        return true;
    }
}
export const DraftStore = new Store();
