var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Flex, Tag, Text, Grid, GridItem, Checkbox, Icon, Button, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { action, makeObservable, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import React, { createRef } from 'react';
import { BaseDialog, BaseDialogState } from '@/app/UIComponents/BaseDialog';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaFileImport } from '@react-icons/all-files/fa/FaFileImport';
import { AppColor } from '@/app/const/AppDefaults';
import { LetrwingFile, TesseractLang } from '@/app/uistore/EditorStore';
import { ImageType } from '@/app/uistore/ImageType';
import { FileType } from '@/app/uistore/FileType';
import { APPEvent } from '@/events/AppEvents';
import { UploadStatus } from '@/share/FileRequest';
import { Apphandler } from '@/ipc/MainAppHandler';
import { formatBytes, testIsMobile } from '@@/Util';
import { Virtuoso } from 'react-virtuoso';
import { MdCloudUpload } from '@react-icons/all-files/md/MdCloudUpload';
import { MdClose } from '@react-icons/all-files/md/MdClose';
export class FileUploadDialogState {
    constructor() {
        this.open = false;
        this.subject = "";
        this.accept = "*";
        this.editting = false;
        this.subscribed = false;
        this.letrid = "";
        this.singleFileMode = false;
        this.basedialogState = new BaseDialogState();
        this.selectedFiles = [];
        this.showdropzone = false;
        this.selectedfilesbyids = new Map();
        this.uploadProgressCb = (progress) => {
            var _a, _b, _c, _d, _e;
            const id = progress.id;
            const lfile = this.selectedfilesbyids.get(id);
            if (this.selectedFiles.length > 0 && lfile !== undefined) {
                lfile.setProgress(progress.uploaded);
                if (progress.status === UploadStatus.Failed) {
                    lfile.setFailed(true);
                }
                else if (progress.status === UploadStatus.Done) {
                    lfile.setDone(true);
                    let width = 0;
                    let height = 0;
                    if (lfile.uploadAsImage && lfile.file !== undefined) {
                        width = 0;
                        height = 0;
                    }
                    (_a = this.fileUploadedCb) === null || _a === void 0 ? void 0 : _a.call(this, {
                        fileid: progress.fileid,
                        accesskey: progress.accesskey,
                        databoxid: progress.databoxid,
                        size: (_c = (_b = lfile.file) === null || _b === void 0 ? void 0 : _b.size) !== null && _c !== void 0 ? _c : 0,
                        filename: (_e = (_d = lfile.file) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : "",
                        isDownloadable: true,
                        uploadAsImage: lfile.uploadAsImage,
                        width: width,
                        height: height
                    });
                }
            }
        };
        makeObservable(this);
        this.basedialogState.setHeaderLabel("File Uploader");
        this.basedialogState.setOkLabel("Upload");
        this.basedialogState.onOk = () => this.uploadFiles();
        this.basedialogState.setDisableOkButton(true);
        this.basedialogState.setModalSize('6xl');
        this.basedialogState.onClose = () => {
            if (this.subscribed) {
                APPEvent.removeSubscriberForUploadProgress(this.uploadProgressCb);
            }
            this.subscribed = false;
            this.clear();
        };
    }
    showDropZone(show) {
        this.showdropzone = show;
    }
    setOpen(open) {
        var _a;
        this.open = open;
        (_a = this.openstatecb) === null || _a === void 0 ? void 0 : _a.call(this, open);
        this.basedialogState.setOpen(open);
        if (!this.subscribed) {
            this.subscribed = true;
            APPEvent.addSubscriberForUploadProgress(this.uploadProgressCb);
        }
    }
    addSelectedFile(file) {
        const lfile = new LetrwingFile();
        lfile.id = Math.random();
        lfile.file = file;
        lfile.progress = 0;
        if (this.singleFileMode) {
            this.selectedFiles = [];
            this.selectedfilesbyids = new Map();
        }
        lfile.setIsDownloadble(true);
        this.selectedFiles.push(lfile);
        this.selectedfilesbyids.set(lfile.id, lfile);
    }
    removeSelectedFile(file) {
        const index = this.selectedFiles.indexOf(file);
        if (index > -1) {
            this.selectedFiles.splice(index, 1);
            this.selectedfilesbyids.delete(file.id);
        }
    }
    clear() {
        this.selectedFiles = [];
        this.selectedfilesbyids = new Map();
    }
    retryFile(file) {
        if (!file.file)
            return;
        file.setFailed(false);
        file.setDone(false);
        file.setProgress(0);
        file.setCancelable(false);
        file.uploading = false;
        const req = {
            id: file.id,
            file: file.file,
            path: "",
            name: file.file.name,
            isDownloadable: true,
            letrid: this.letrid,
            isimage: false,
            ocrdocument: file.ocr,
            addwatermark: file.addwatermark,
            ocrlang: Array.from(file.langs.values()).map((vl) => vl.value).join("+")
        };
        Apphandler.sendFileMessage(req);
    }
    uploadFiles() {
        if (this.selectedFiles.length === 0) {
            return;
        }
        for (const sfile of this.selectedFiles) {
            if (sfile.file != undefined &&
                !sfile.failed &&
                !sfile.done && !sfile.uploading) {
                sfile.setCancelable(false);
                sfile.uploading = true;
                const req = {
                    id: sfile.id,
                    file: sfile.file,
                    path: "",
                    name: sfile.file.name,
                    isDownloadable: true,
                    letrid: this.letrid,
                    isimage: false,
                    ocrdocument: sfile.ocr,
                    addwatermark: sfile.addwatermark,
                    ocrlang: Array.from(sfile.langs.values()).map((vl) => vl.value).join("+")
                };
                Apphandler.sendFileMessage(req);
            }
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], FileUploadDialogState.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], FileUploadDialogState.prototype, "subject", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], FileUploadDialogState.prototype, "editting", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], FileUploadDialogState.prototype, "selectedFiles", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], FileUploadDialogState.prototype, "showdropzone", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FileUploadDialogState.prototype, "showDropZone", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FileUploadDialogState.prototype, "setOpen", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [File]),
    __metadata("design:returntype", void 0)
], FileUploadDialogState.prototype, "addSelectedFile", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [LetrwingFile]),
    __metadata("design:returntype", void 0)
], FileUploadDialogState.prototype, "removeSelectedFile", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FileUploadDialogState.prototype, "clear", null);
const ALLOWED_FILE_TYPES = [FileType.PDF.toString(),
    FileType.DOCX.toString(),
    FileType.PPT.toString(),
    FileType.PPTX.toString(),
    FileType.TXT.toString(),
    FileType.RTF.toString(),
    FileType.DOC.toString(),
    FileType.ODP.toString(),
    FileType.ODT.toString(),
    ImageType.PNG.toString(),
    ImageType.JPEG.toString(),
    ImageType.JPG.toString(),
    ImageType.CDR.toString(),
    ImageType.AI.toString()
];
const Images_File_Types = [ImageType.PNG.toString(),
    ImageType.JPEG.toString(),
    ImageType.JPG.toString(),
    ImageType.GIF.toString(),
    ImageType.JFIF.toString(),
    ImageType.GIFF.toString()];
let FileUploadDialog = class FileUploadDialog extends React.Component {
    constructor(prop) {
        super(prop);
        this.fileSelectedHandler = (files) => {
            if (files === null) {
                return;
            }
            const length = files.length;
            for (let i = 0; i < length; i++) {
                const file = files.item(i);
                if (file === null) {
                    continue;
                }
                this.props.state.addSelectedFile(file);
            }
            this.props.state.basedialogState.setDisableOkButton(false);
        };
        this.inputRef = createRef();
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    dropHandler(ev) {
        if (!this.props.state.showdropzone) {
            return;
        }
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.dataTransfer.items.length) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    if (file === null) {
                        continue;
                    }
                    this.props.state.addSelectedFile(file);
                }
            }
        }
        else if (ev.dataTransfer.files.length) {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                const file = ev.dataTransfer.files[i];
                if (file === null) {
                    continue;
                }
                this.props.state.addSelectedFile(file);
            }
        }
        this.props.state.showDropZone(false);
        this.props.state.basedialogState.setDisableOkButton(false);
    }
    dragOverHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        if (ev.dataTransfer.items.length > 0 || ev.dataTransfer.files.length > 0) {
            ev.preventDefault();
            ev.stopPropagation();
            this.props.state.showDropZone(true);
        }
    }
    dragEnterHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        if (ev.dataTransfer.items.length > 0 || ev.dataTransfer.files.length > 0) {
            ev.preventDefault();
            ev.stopPropagation();
            this.props.state.showDropZone(true);
        }
    }
    paste(ev) {
        if (ev.clipboardData.items.length > 0 || ev.clipboardData.files.length > 0) {
            if (ev.clipboardData.items.length > 0) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < ev.clipboardData.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (ev.clipboardData.items[i].kind === 'file') {
                        const file = ev.clipboardData.items[i].getAsFile();
                        if (file === null) {
                            continue;
                        }
                        this.props.state.addSelectedFile(file);
                    }
                }
            }
            else if (ev.clipboardData.files.length > 0) {
                // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < ev.clipboardData.files.length; i++) {
                    const file = ev.clipboardData.files[i];
                    if (file === null) {
                        continue;
                    }
                    this.props.state.addSelectedFile(file);
                }
            }
            this.props.state.showDropZone(false);
            this.props.state.basedialogState.setDisableOkButton(false);
        }
    }
    dialog() {
        const selectedFiles = this.props.state.selectedFiles;
        return React.createElement(Box, null,
            React.createElement("input", { id: "myInput", type: "file", ref: this.inputRef, style: { display: 'none' }, multiple: this.props.state.singleFileMode ? false : true, accept: this.props.state.accept, onChange: ({ target }) => this.fileSelectedHandler(target.files) }),
            React.createElement(Box, { className: 'fileBrowserButton', onClick: () => { var _a, _b; (_b = (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.click(); }, _hover: { cursor: 'pointer' } },
                React.createElement(Button, { rightIcon: React.createElement(FaFileImport, null), color: '#FFFFFF', backgroundColor: AppColor.Default, variant: "solid", _hover: { cursor: 'pointer', backgroundColor: '#ab81f5' } }, "Browser Files")),
            React.createElement(Box, { onDragEnter: (e) => this.dragEnterHandler(e), onDragOver: (e) => this.dragOverHandler(e), onDrop: (e) => this.dropHandler(e), position: 'relative', onPaste: (e) => this.paste(e) },
                this.props.state.showdropzone && React.createElement(Box, { className: 'dropzone' },
                    React.createElement(Icon, { as: MdCloudUpload, h: '150px', w: '150px' })),
                selectedFiles.length == 0 && React.createElement(Box, { h: '100%' },
                    React.createElement(Flex, { flexDirection: 'column', h: '100%', align: 'center', justify: 'center' },
                        React.createElement(Text, { mt: '20px' }, "Please select a file to upload."))),
                !testIsMobile() && this.props.state.selectedFiles.length > 0 && this.renderTable(),
                testIsMobile() && this.props.state.selectedFiles.length > 0 && this.renderMobileTable()));
    }
    renderTable() {
        return React.createElement(Box, { maxHeight: '60vh' },
            React.createElement(Grid, { h: '50px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(10, 1fr)', gap: 4, className: 'fileupload-table-header' },
                React.createElement(GridItem, { colSpan: 2 }, "Name"),
                React.createElement(GridItem, null, "Size"),
                React.createElement(GridItem, null, "Type"),
                React.createElement(GridItem, null, "Watermark"),
                React.createElement(GridItem, null, "OCR"),
                React.createElement(GridItem, { w: '200px' }, "Document Language"),
                React.createElement(GridItem, null, "Progress"),
                React.createElement(GridItem, null)),
            React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: '50vh' }, data: this.props.state.selectedFiles, itemContent: (index, ob) => React.createElement(UploadRow, { retry: (file) => this.props.state.retryFile(file), key: ob.id, item: ob, removeFileSelection: () => this.removeFileSelection(ob) }) })));
    }
    renderMobileTable() {
        return React.createElement(Box, { maxHeight: '60vh' },
            React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: '50vh' }, data: this.props.state.selectedFiles, itemContent: (index, ob) => React.createElement(UploadRowMobile, { retry: (file) => this.props.state.retryFile(file), key: ob.id, item: ob, removeFileSelection: () => this.removeFileSelection(ob) }) })));
    }
    removeFileSelection(file) {
        if (!file.cancelable) {
            return;
        }
        this.props.state.removeSelectedFile(file);
        if (this.props.state.selectedFiles.length === 0) {
            this.props.state.basedialogState.setDisableOkButton(true);
        }
    }
    render() {
        return (React.createElement(BaseDialog, { body: this.dialog(), state: this.props.state.basedialogState }));
    }
};
FileUploadDialog = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], FileUploadDialog);
export { FileUploadDialog };
let UploadRowMobile = class UploadRowMobile extends React.Component {
    constructor(prop) {
        super(prop);
    }
    isViewableFile(file) {
        const name = file.name.toLowerCase();
        const ext = name.substring(name.lastIndexOf('.') + 1);
        return ALLOWED_FILE_TYPES.includes(ext);
    }
    isImageFile(file) {
        const name = file.name.toLowerCase();
        const ext = name.substring(name.lastIndexOf('.') + 1);
        return Images_File_Types.includes(ext);
    }
    render() {
        const lfile = this.props.item;
        const file = lfile.file;
        if (file === undefined) {
            return React.createElement(Box, { mt: '10px' },
                React.createElement(Box, { minHeight: '50px', mt: '5px', className: 'fileupload-table-row' }));
        }
        const viewable = this.isViewableFile(file);
        const isImageFile = this.isImageFile(file);
        return React.createElement(Flex, { mt: '10px', key: lfile.id, align: 'center' },
            React.createElement(Box, { width: '80%' },
                React.createElement(Flex, null,
                    React.createElement(Text, { fontSize: '12px' }, file.name)),
                React.createElement(Flex, null,
                    React.createElement(Text, { fontSize: '10px' }, formatBytes(file.size))),
                React.createElement(Flex, { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '10px' }, file.type),
                viewable && React.createElement(Flex, null,
                    React.createElement(Text, { fontSize: '10px', mr: '10px', color: AppColor.SideBarIconColor }, 'Watermark'),
                    React.createElement(Checkbox, { defaultChecked: true, checked: lfile.addwatermark, onChange: (e) => {
                            lfile.setWatermark(e.currentTarget.checked);
                        } })),
                viewable && React.createElement(Flex, { align: 'center' },
                    React.createElement(Text, { fontSize: '10px', mr: '10px', color: AppColor.SideBarIconColor }, 'OCR'),
                    React.createElement(Checkbox, { defaultChecked: true, checked: lfile.ocr, onChange: (e) => {
                            lfile.setOcr(e.currentTarget.checked);
                        } })),
                viewable && lfile.ocr && React.createElement(Flex, { pos: 'relative', align: 'center' },
                    React.createElement(Text, { fontSize: '10px', mr: '10px', color: AppColor.SideBarIconColor }, 'Document Language'),
                    lfile.langs.size === 0 && React.createElement(Text, { w: '200px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => lfile.setShowLangMenu(!lfile.showlanguagemenu) }),
                    lfile.langs.size > 0 && React.createElement(Flex, { _hover: { cursor: 'pointer' }, onClick: () => lfile.setShowLangMenu(!lfile.showlanguagemenu), flexWrap: 'wrap', maxH: '25px', maxW: '200px', overflowX: 'auto' }, Array.from(lfile.langs.values()).map((vl) => React.createElement(Tag, { key: vl.value, m: '3px' }, vl.label))),
                    React.createElement(Box, { zIndex: '1000', pos: 'relative' },
                        React.createElement(Box, { pos: 'absolute', top: '-15px', left: '0px', w: '200px', h: '100%' },
                            React.createElement(Languages, { item: lfile }))))),
            React.createElement(Box, null,
                !lfile.failed && !lfile.done
                    && React.createElement(CircularProgress, { value: lfile.progress, color: AppColor.Default },
                        React.createElement(CircularProgressLabel, null, lfile.progress + "%")),
                lfile.failed && React.createElement(Box, null,
                    React.createElement(Text, { color: 'red', fontWeight: 'bold' }, "Upload Failed!"),
                    React.createElement(Button, { mt: '5px', onClick: () => {
                            this.props.retry(lfile);
                        }, colorScheme: 'green', variant: 'unstyled' }, "Retry")),
                lfile.done && React.createElement(Icon, { title: 'Upload Successful', fontSize: '25px', as: FaCheck })),
            React.createElement(Box, null, lfile.cancelable && React.createElement(Icon, { w: '30px', h: '30px', title: 'Remove file selection', _hover: { cursor: lfile.cancelable ? 'pointer' : '' }, onClick: () => this.props.removeFileSelection(lfile), as: GrFormClose })));
    }
};
UploadRowMobile = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], UploadRowMobile);
let UploadRow = class UploadRow extends React.Component {
    constructor(prop) {
        super(prop);
    }
    isViewableFile(file) {
        const name = file.name.toLowerCase();
        const ext = name.substring(name.lastIndexOf('.') + 1);
        return ALLOWED_FILE_TYPES.includes(ext);
    }
    isImageFile(file) {
        const name = file.name.toLowerCase();
        const ext = name.substring(name.lastIndexOf('.') + 1);
        return Images_File_Types.includes(ext);
    }
    render() {
        const lfile = this.props.item;
        const file = lfile.file;
        if (file === undefined) {
            return React.createElement(Box, { mt: '10px' },
                React.createElement(Grid, { minHeight: '50px', mt: '5px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(10, 1fr)', gap: 4, className: 'fileupload-table-row' }));
        }
        const viewable = this.isViewableFile(file);
        const isImageFile = this.isImageFile(file);
        return React.createElement(Box, { mt: '10px', key: lfile.id },
            React.createElement(Grid, { minHeight: '50px', mt: '5px', templateRows: 'repeat(1, 1fr)', templateColumns: 'repeat(10, 1fr)', gap: 4, className: 'fileupload-table-row' },
                React.createElement(GridItem, { colSpan: 2 }, file.name),
                React.createElement(GridItem, null, formatBytes(file.size)),
                React.createElement(GridItem, { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', title: file.type }, file.type),
                !viewable && React.createElement(GridItem, null),
                viewable && React.createElement(GridItem, { flexDir: 'column' },
                    React.createElement(Checkbox, { defaultChecked: true, checked: lfile.addwatermark, onChange: (e) => {
                            lfile.setWatermark(e.currentTarget.checked);
                        } })),
                !viewable && React.createElement(GridItem, null),
                viewable && React.createElement(GridItem, { flexDir: 'column' },
                    React.createElement(Checkbox, { defaultChecked: true, checked: lfile.ocr, onChange: (e) => {
                            lfile.setOcr(e.currentTarget.checked);
                        } })),
                (!viewable || !lfile.ocr) && React.createElement(GridItem, { w: '200px' }),
                viewable && lfile.ocr && React.createElement(GridItem, { pos: 'relative', w: '200px' },
                    lfile.langs.size === 0 && React.createElement(Text, { w: '200px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => lfile.setShowLangMenu(!lfile.showlanguagemenu) }),
                    lfile.langs.size > 0 && React.createElement(Flex, { _hover: { cursor: 'pointer' }, onClick: () => lfile.setShowLangMenu(!lfile.showlanguagemenu), flexWrap: 'wrap', maxH: '25px', maxW: '200px', overflowX: 'auto' }, Array.from(lfile.langs.values()).map((vl) => React.createElement(Tag, { key: vl.value, m: '3px' }, vl.label))),
                    React.createElement(Box, { zIndex: '1000', pos: 'relative' },
                        React.createElement(Box, { pos: 'absolute', top: '-15px', left: '0px', w: '200px', h: '100%' },
                            React.createElement(Languages, { item: lfile })))),
                React.createElement(GridItem, null,
                    React.createElement(Box, null,
                        !lfile.failed && !lfile.done
                            && React.createElement(CircularProgress, { value: lfile.progress, color: AppColor.Default },
                                React.createElement(CircularProgressLabel, null, lfile.progress + "%")),
                        lfile.failed && React.createElement(Box, null,
                            React.createElement(Text, { color: 'red', fontWeight: 'bold' }, "Upload Failed!"),
                            React.createElement(Button, { mt: '5px', onClick: () => {
                                    this.props.retry(lfile);
                                }, colorScheme: 'green', variant: 'unstyled' }, "Retry")),
                        lfile.done && React.createElement(Icon, { title: 'Upload Successful', fontSize: '25px', as: FaCheck }))),
                React.createElement(GridItem, null, lfile.cancelable && React.createElement(Icon, { w: '30px', h: '30px', title: 'Remove file selection', _hover: { cursor: lfile.cancelable ? 'pointer' : '' }, onClick: () => this.props.removeFileSelection(lfile), as: GrFormClose }))));
    }
};
UploadRow = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], UploadRow);
let Languages = class Languages extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        if (!this.props.item.showlanguagemenu) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Box, { pos: 'relative', backgroundColor: 'white', maxHeight: '200px', overflow: 'auto', w: '200px', boxShadow: '10px 10px 10px lightgray', marginTop: '15px' },
            React.createElement(Flex, { pos: 'absolute', top: '3px', h: '20px', w: '200px', align: 'center', justify: 'flex-end' },
                React.createElement(Icon, { as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => this.props.item.setShowLangMenu(false) })),
            React.createElement(Box, { h: '25px' }),
            TesseractLang.map((field) => React.createElement(Observer, { key: field.value }, () => React.createElement(Flex, { align: 'center', ml: '5px', w: '100%', h: '40px', key: field.value, _hover: { cursor: 'pointer', opacity: '0.9', backgroundColor: '#829eb975' } },
                React.createElement(Checkbox, { defaultChecked: this.props.item.langs.has(field.value), onChange: (e) => {
                        if (e.currentTarget.checked) {
                            this.props.item.addLang(field);
                        }
                        else {
                            this.props.item.removeLang(field.value);
                        }
                    } }),
                React.createElement(Text, { title: field.label, ml: '10px', overflow: 'hidden', textOverflow: 'ellipsis', maxW: '200px', fontWeight: 'bold' }, field.label)))));
    }
};
Languages = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], Languages);
