import { LetrwingCrypto } from "./LetrwingCommonCrypto";
import { Organisation } from "./Organisation";
import { IdentityToken } from "./RegisterModel";
export var OrgnisationSetupstage;
(function (OrgnisationSetupstage) {
    OrgnisationSetupstage[OrgnisationSetupstage["creatingorg"] = 0] = "creatingorg";
    OrgnisationSetupstage[OrgnisationSetupstage["addinguser"] = 1] = "addinguser";
    OrgnisationSetupstage[OrgnisationSetupstage["addingdevice"] = 2] = "addingdevice";
    OrgnisationSetupstage[OrgnisationSetupstage["done"] = 3] = "done";
    OrgnisationSetupstage[OrgnisationSetupstage["failed"] = 4] = "failed";
})(OrgnisationSetupstage || (OrgnisationSetupstage = {}));
export class NewTeamResponse {
    constructor() {
        this.org = new Organisation("", "", 'Email');
        this.token = new IdentityToken();
    }
}
export const createNewTeamReq = (email, teamname, username) => {
    const teammatrix = [];
    for (let i = 0; i < 100; i++) {
        const string = LetrwingCrypto.getRandmBytes(32);
        teammatrix.push(string);
    }
    // now we have 100 string matrix more than enough for us!
    const stroagekey = LetrwingCrypto.getRandomPassword();
    const userkeys = [];
    const userkeyspub = [];
    const userkeysnonsigned = [];
    const identitykey = LetrwingCrypto.getNewKeypair();
    const signkey = LetrwingCrypto.getSignKeyPair();
    for (let i = 0; i < 50; i++) {
        const key = LetrwingCrypto.getNewKeypair();
        userkeys.push(key);
        userkeysnonsigned.push(key.publickey);
        userkeyspub.push(LetrwingCrypto.signMessage(key.publickey, signkey));
    }
    const searchkeys = LetrwingCrypto.getNewKeypair();
    const jobkeys = LetrwingCrypto.getNewKeypair();
    // now main key master key wohoo!
    const masterkey = LetrwingCrypto.getRandomPassword();
    const loginpassword = LetrwingCrypto.hash512str(LetrwingCrypto.hash512str(masterkey));
    // now lets just give everyone something to look at!
    // now we just need t stroe these info and we done here!
    const teammatrixlocked = LetrwingCrypto.secretBox(JSON.stringify(teammatrix), stroagekey);
    const randompassword = LetrwingCrypto.getRandomPassword(); // this is for generatig protect key..
    const lockedprivatekeys = LetrwingCrypto.secretBox(JSON.stringify(userkeys), masterkey);
    const lockedIdentityKey = LetrwingCrypto.secretBox(JSON.stringify(identitykey), masterkey);
    const lockedSignKey = LetrwingCrypto.secretBox(JSON.stringify(signkey), masterkey);
    const lockedStorageKey = LetrwingCrypto.secretBox(stroagekey, masterkey);
    /**
     * Box shape:
     * Locked signkey, locked identitykey, locked priavte keys, public key, signe dpublic key, identity key public for admin,
     * teammatrix, storagekey, protectkey password..
     */
    // lets store this create org and setup team..
    const req = {
        adminemail: email,
        teamname: teamname,
        storagekey: stroagekey,
        teammatrixlocked: teammatrixlocked,
        lockedIdentityKey: lockedIdentityKey,
        lockedSignKey: lockedSignKey,
        userpublickeys: userkeysnonsigned,
        usersignedpubkeys: userkeyspub,
        lockedUserKeys: lockedprivatekeys,
        identitypubkey: identitykey.publickey,
        signpubkey: signkey.publickey,
        lockedStorageKey: lockedStorageKey,
        randompassword: randompassword,
        adminname: username,
        searchpubkey: searchkeys.publickey,
        adminpassword: loginpassword,
        jobpubkey: jobkeys.publickey,
        searchbox: LetrwingCrypto.secretBox(JSON.stringify(searchkeys), masterkey),
        jobbox: LetrwingCrypto.secretBox(JSON.stringify(jobkeys), masterkey)
    };
    return { req: req, masterPassword: masterkey };
};
