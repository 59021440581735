var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SchemaItemType, ChoiceType } from '@@/Schema';
import { Box, Button, Checkbox, Flex, FormControl, Icon, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Tag, TagLabel, TagRightIcon, Text } from '@chakra-ui/react';
import { Select as CSelect } from 'chakra-react-select';
import { observer } from 'mobx-react';
import React, { Suspense } from 'react';
import { ScaleLoader } from 'react-spinners';
import DatePicker from "react-datepicker";
import { SketchPicker } from "react-color";
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { SearchMetadataOp } from '@@/ModuleJob';
import { AppColor, FontSize } from '../const/AppDefaults';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
const ReactQuill = React.lazy(async () => await import("react-quill"));
let Panel = class Panel extends React.Component {
    constructor(prop) {
        super(prop);
    }
    renderRow() {
        const fields = this.props.items;
        return React.createElement(Box, { key: "filter", paddingLeft: '10px', paddingRight: '10px' },
            fields.map((item) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                const dmeta = this.props.state.valuesbyid.get(item.id);
                const dval = (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '';
                const dval2 = (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _b !== void 0 ? _b : '';
                let nval = 0;
                let nval2 = 0;
                if (dval && item.type === SchemaItemType.Date) {
                    nval = parseInt(dval);
                    nval2 = parseInt(dval2);
                }
                let ritemns = [];
                let remoteitemsmap = new Map();
                if (item.type === SchemaItemType.Remote) {
                    const ri = item;
                    let riID = ri.id;
                    if (riID.indexOf("----") !== -1) {
                        const bits = riID.split("----");
                        if (bits.length > 1) {
                            riID = bits[1];
                        }
                    }
                    const items = (_c = this.props.dependentdata) === null || _c === void 0 ? void 0 : _c.get(riID + "---" + ri.sid + "---" + ri.cid);
                    ritemns = items !== null && items !== void 0 ? items : [];
                    remoteitemsmap.set(riID + "---" + ri.sid + "---" + ri.cid, ritemns);
                    if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                        const rtextra = ri.rtextra;
                        for (const ext of rtextra) {
                            const items = (_d = this.props.dependentdata) === null || _d === void 0 ? void 0 : _d.get(riID + "---" + ri.sid + "---" + ext.cid);
                            remoteitemsmap.set(riID + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                        }
                    }
                }
                return React.createElement(FormControl, { key: item.id, mt: '10px' },
                    React.createElement(Flex, { align: 'center', mb: '15px' }, item.type !== SchemaItemType.Date && item.type !== SchemaItemType.Number && React.createElement(Text, { mr: '40px', style: { color: AppColor.FilterHeaderFontColor,
                            fontSize: FontSize.FilterHeaderFontSize, fontWeight: 'bold' } }, item.name)),
                    (item.type === SchemaItemType.ID || item.type === SchemaItemType.SquenceNumber) &&
                        React.createElement(Input, { defaultValue: dval !== null && dval !== void 0 ? dval : '', placeholder: 'Contains', onChange: (e) => {
                                const ch = e.currentTarget.value;
                                if (ch.trim() === '' || ch.trim() === (dval === null || dval === void 0 ? void 0 : dval.trim())) {
                                    this.props.state.deleteValue(item.id);
                                    return;
                                }
                                const sitem = {
                                    id: item.id,
                                    value: ch + '',
                                    value2: '',
                                    fielditem: item,
                                    v2float: -1,
                                    vfloat: -1,
                                    optype: SearchMetadataOp.Equal,
                                    numbercompare: false
                                };
                                this.props.state.addValue(item.id, sitem);
                            } }),
                    item.type === SchemaItemType.Date && React.createElement(Box, null,
                        React.createElement(Flex, { align: 'center', mb: '15px' },
                            React.createElement(Text, { mr: '40px', style: { color: AppColor.FilterHeaderFontColor,
                                    fontSize: FontSize.FilterHeaderFontSize, fontWeight: 'bold' } }, item.name),
                            React.createElement(Flex, null,
                                React.createElement(Select, { size: 'xs', bg: AppColor.FilterHeaderFontColor, borderColor: AppColor.FilterHeaderFontColor, color: 'white', onChange: (e) => {
                                        var _a, _b, _c, _d, _e;
                                        const sitem = {
                                            id: item.id,
                                            value: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '',
                                            fielditem: item,
                                            value2: (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _b !== void 0 ? _b : '',
                                            v2float: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _c !== void 0 ? _c : -1,
                                            vfloat: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _d !== void 0 ? _d : -1,
                                            optype: SearchMetadataOp[e.currentTarget.value],
                                            numbercompare: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _e !== void 0 ? _e : false
                                        };
                                        this.props.state.addValue(item.id, sitem);
                                    }, defaultValue: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _e !== void 0 ? _e : SearchMetadataOp.Equal }, Object.keys(SearchMetadataOp).map((op) => React.createElement("option", { value: op, key: op }, op))))),
                        React.createElement(Flex, { w: '100%' },
                            React.createElement(Flex, { w: (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween ? '50%' : '100%' },
                                (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween &&
                                    React.createElement(Text, { mr: '5px', color: AppColor.FilterItemLabelFontColor, fontSize: FontSize.FilterItemLabelFontSize }, "Date1: "),
                                React.createElement(DateInput, { disbaledate: (_f = item.excludedate) !== null && _f !== void 0 ? _f : false, defaultvalue: nval, onChange: (ts) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                        if (!ts) {
                                            const sitem = {
                                                id: item.id,
                                                fielditem: item,
                                                value: ts + '',
                                                value2: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _a !== void 0 ? _a : '',
                                                v2float: (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _b !== void 0 ? _b : -1,
                                                vfloat: ts + ((_c = item.randomoffest) !== null && _c !== void 0 ? _c : 0),
                                                optype: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _d !== void 0 ? _d : SearchMetadataOp.Equal,
                                                numbercompare: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _e !== void 0 ? _e : false
                                            };
                                            this.props.state.addValue(item.id, sitem);
                                        }
                                        else {
                                            const sitem = {
                                                id: item.id,
                                                fielditem: item,
                                                value: ts + '',
                                                value2: (_f = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _f !== void 0 ? _f : '',
                                                v2float: (_g = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _g !== void 0 ? _g : -1,
                                                vfloat: ts + ((_h = item.randomoffest) !== null && _h !== void 0 ? _h : 0),
                                                optype: (_j = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _j !== void 0 ? _j : SearchMetadataOp.Equal,
                                                numbercompare: (_k = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _k !== void 0 ? _k : false
                                            };
                                            this.props.state.addValue(item.id, sitem);
                                        }
                                    }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: item.id, id: item.id, format: (_g = item.format) !== null && _g !== void 0 ? _g : 'dd/MM/yyyy' })),
                            (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween && React.createElement(Flex, { w: (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween ? '50%' : '100%' },
                                React.createElement(Text, { ml: '5px', mr: '5px' }, "Date2: "),
                                React.createElement(DateInput, { disbaledate: (_h = item.excludedate) !== null && _h !== void 0 ? _h : false, defaultvalue: nval2, onChange: (ts) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                        if (!ts) {
                                            const sitem = {
                                                id: item.id,
                                                fielditem: item,
                                                value: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '',
                                                value2: ts + "",
                                                v2float: ts + ((_b = item.randomoffest) !== null && _b !== void 0 ? _b : 0),
                                                vfloat: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _c !== void 0 ? _c : -1,
                                                optype: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _d !== void 0 ? _d : SearchMetadataOp.Equal,
                                                numbercompare: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _e !== void 0 ? _e : false
                                            };
                                            this.props.state.addValue(item.id, sitem);
                                        }
                                        else {
                                            const sitem = {
                                                id: item.id,
                                                value: (_f = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _f !== void 0 ? _f : '',
                                                fielditem: item,
                                                value2: ts + "",
                                                v2float: ts + ((_g = item.randomoffest) !== null && _g !== void 0 ? _g : 0),
                                                vfloat: (_h = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _h !== void 0 ? _h : -1,
                                                optype: (_j = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _j !== void 0 ? _j : SearchMetadataOp.Equal,
                                                numbercompare: (_k = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _k !== void 0 ? _k : false
                                            };
                                            this.props.state.addValue(item.id, sitem);
                                        }
                                    }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: item.id, id: item.id, format: (_j = item.format) !== null && _j !== void 0 ? _j : 'dd/MM/yyyy' })))),
                    item.type === SchemaItemType.Choice &&
                        React.createElement(Box, { paddingRight: '10px' },
                            React.createElement(ChoiceInput, { multiselect: item.multiselect, schemafieldname: item.name, defaultvalue: dval, onChange: (ch) => {
                                    if (!ch.trim()) {
                                        this.props.state.deleteValue(item.id);
                                    }
                                    else {
                                        const sitem = {
                                            id: item.id,
                                            fielditem: item,
                                            value: ch + '',
                                            value2: '',
                                            v2float: -1,
                                            vfloat: -1,
                                            optype: SearchMetadataOp.Equal,
                                            numbercompare: false
                                        };
                                        this.props.state.addValue(item.id, sitem);
                                    }
                                }, key: item.id, id: item.id, list: (_k = item.items) !== null && _k !== void 0 ? _k : [] })),
                    item.type === SchemaItemType.Text &&
                        React.createElement(TextInput, { defaultvalue: dval, onChange: (ch, pch) => {
                                if (ch.trim() === '') {
                                    this.props.state.deleteValue(item.id);
                                }
                                else {
                                    const sitem = {
                                        id: item.id,
                                        value: ch + '',
                                        fielditem: item,
                                        value2: '',
                                        v2float: -1,
                                        vfloat: -1,
                                        optype: SearchMetadataOp.Equal,
                                        numbercompare: false
                                    };
                                    this.props.state.addValue(item.id, sitem);
                                }
                            }, key: item.id, id: item.id, richtext: false }),
                    item.type === SchemaItemType.FormInput && React.createElement(Input, { defaultValue: dval !== null && dval !== void 0 ? dval : '', placeholder: 'Contains', onChange: (e) => {
                            const ch = e.currentTarget.value;
                            if (ch.trim() === '' || ch.trim() === (dval === null || dval === void 0 ? void 0 : dval.trim())) {
                                this.props.state.deleteValue(item.id);
                                return;
                            }
                            const sitem = {
                                id: item.id,
                                value: ch + '',
                                value2: '',
                                fielditem: item,
                                v2float: -1,
                                vfloat: -1,
                                optype: SearchMetadataOp.Equal,
                                numbercompare: false
                            };
                            this.props.state.addValue(item.id, sitem);
                        } }),
                    item.type === SchemaItemType.Number && React.createElement(Box, { paddingRight: '10px' },
                        React.createElement(Flex, { align: 'center', mb: '15px' },
                            React.createElement(Text, { mr: '40px', style: { color: AppColor.FilterHeaderFontColor,
                                    fontSize: FontSize.FilterHeaderFontSize, fontWeight: 'bold' } }, item.name),
                            React.createElement(Flex, null,
                                React.createElement(Select, { size: 'xs', bg: AppColor.FilterHeaderFontColor, borderColor: AppColor.FilterHeaderFontColor, color: 'white', defaultValue: (_l = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _l !== void 0 ? _l : SearchMetadataOp.Equal, onChange: (e) => {
                                        var _a, _b, _c, _d, _e;
                                        const sitem = {
                                            id: item.id,
                                            fielditem: item,
                                            value: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '',
                                            value2: (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _b !== void 0 ? _b : '',
                                            v2float: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _c !== void 0 ? _c : -1,
                                            vfloat: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _d !== void 0 ? _d : -1,
                                            optype: SearchMetadataOp[e.currentTarget.value],
                                            numbercompare: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _e !== void 0 ? _e : false
                                        };
                                        this.props.state.addValue(item.id, sitem);
                                    } }, Object.keys(SearchMetadataOp).map((op) => React.createElement("option", { value: op, key: op }, op))))),
                        React.createElement(Flex, { w: '100%' },
                            React.createElement(Flex, { align: 'center', w: (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween ? '50%' : '100%' },
                                (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween &&
                                    React.createElement(Text, { ml: '5px', mr: '5px', color: AppColor.FilterItemLabelFontColor, fontSize: FontSize.FilterItemLabelFontSize }, "Value1:"),
                                React.createElement(CustomNumberInput, { defaultvalue: dval, onChange: (ch) => {
                                        var _a, _b, _c, _d, _e, _f;
                                        if (ch.trim() === '') {
                                            this.props.state.deleteValue(item.id);
                                            return;
                                        }
                                        const nitem = item;
                                        if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                                            (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                                            const sitem = {
                                                id: item.id,
                                                fielditem: item,
                                                value: ch + '',
                                                value2: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _a !== void 0 ? _a : '',
                                                v2float: (_c = (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _b !== void 0 ? _b : nitem.minvalue) !== null && _c !== void 0 ? _c : 0,
                                                vfloat: parseFloat(ch) + ((_d = item.randomoffest) !== null && _d !== void 0 ? _d : 0),
                                                optype: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _e !== void 0 ? _e : SearchMetadataOp.Equal,
                                                numbercompare: (_f = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _f !== void 0 ? _f : false
                                            };
                                            this.props.state.addValue(item.id, sitem);
                                        }
                                    }, key: item.id, id: item.id, minvalue: item.minvalue, maxvalue: item.maxvalue })),
                            (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween && React.createElement(Flex, { w: (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween ? '50%' : '100%' },
                                React.createElement(Flex, { align: 'center' },
                                    React.createElement(Text, { ml: '5px', mr: '5px', color: AppColor.FilterItemLabelFontColor, fontSize: FontSize.FilterItemLabelFontSize }, "Value2:"),
                                    React.createElement(CustomNumberInput, { defaultvalue: dval2, onChange: (ch) => {
                                            var _a, _b, _c, _d, _e, _f;
                                            if (ch.trim() === '') {
                                                this.props.state.deleteValue(item.id);
                                                return;
                                            }
                                            const nitem = item;
                                            if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                                                (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                                                const sitem = {
                                                    id: item.id,
                                                    value: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '',
                                                    fielditem: item,
                                                    value2: ch + '',
                                                    v2float: parseFloat(ch) + ((_b = item.randomoffest) !== null && _b !== void 0 ? _b : 0),
                                                    vfloat: (_d = (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _c !== void 0 ? _c : nitem.minvalue) !== null && _d !== void 0 ? _d : 0,
                                                    optype: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _e !== void 0 ? _e : SearchMetadataOp.Equal,
                                                    numbercompare: (_f = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _f !== void 0 ? _f : false
                                                };
                                                this.props.state.addValue(item.id, sitem);
                                            }
                                        }, key: item.id, id: item.id, minvalue: item.minvalue, maxvalue: item.maxvalue }))))),
                    item.type === SchemaItemType.Color && React.createElement(ColorInput, { defaultvalue: dval, onChange: (ch) => {
                            if (!ch.trim()) {
                                this.props.state.deleteValue(item.id);
                            }
                            else {
                                const sitem = {
                                    id: item.id,
                                    value: ch + '',
                                    fielditem: item,
                                    value2: '',
                                    v2float: -1,
                                    vfloat: -1,
                                    optype: SearchMetadataOp.Equal,
                                    numbercompare: false
                                };
                                this.props.state.addValue(item.id, sitem);
                            }
                        }, key: item.id, id: item.id }),
                    item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { defaultvalue: dval === 'true' ? true : false, onChange: (ch) => {
                            const val = ch ? "true" : 'false';
                            const sitem = {
                                id: item.id,
                                value: val + '',
                                value2: '',
                                fielditem: item,
                                v2float: -1,
                                vfloat: -1,
                                optype: SearchMetadataOp.Equal,
                                numbercompare: false
                            };
                            this.props.state.addValue(item.id, sitem);
                        }, key: item.id, id: item.id }),
                    item.type === SchemaItemType.Remote &&
                        React.createElement(RemoteDisplayItem, { item: item, defaultValue: dval !== null && dval !== void 0 ? dval : '', remoteitemsmap: remoteitemsmap, rtitems: ritemns, onChange: (ch) => {
                                if (!ch.trim()) {
                                    this.props.state.deleteValue(item.id);
                                }
                                else {
                                    const sitem = {
                                        id: item.id,
                                        fielditem: item,
                                        value: ch + '',
                                        value2: '',
                                        v2float: -1,
                                        vfloat: -1,
                                        optype: SearchMetadataOp.Equal,
                                        numbercompare: false
                                    };
                                    this.props.state.addValue(item.id, sitem);
                                }
                            } }));
            }),
            (["lm"]).map(m => {
                var _a, _b, _c;
                const dmeta = this.props.state.valuesbyid.get("lastmodifief");
                const dval = (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '';
                const dval2 = (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _b !== void 0 ? _b : '';
                let nval = 0;
                let nval2 = 0;
                if (dval) {
                    nval = parseInt(dval);
                    nval2 = parseInt(dval2);
                }
                return React.createElement(Box, null,
                    React.createElement(Flex, { align: 'center', mb: '15px' },
                        React.createElement(Text, { mr: '40px', style: { color: AppColor.FilterHeaderFontColor,
                                fontSize: FontSize.FilterHeaderFontSize, fontWeight: 'bold' } }, 'Last Modified'),
                        React.createElement(Flex, null,
                            React.createElement(Select, { size: 'xs', bg: AppColor.FilterHeaderFontColor, borderColor: AppColor.FilterHeaderFontColor, color: 'white', onChange: (e) => {
                                    var _a, _b, _c, _d, _e;
                                    const sitem = {
                                        id: "lastmodifief",
                                        value: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '',
                                        fielditem: {
                                            id: 'lm',
                                            name: "Last Modified",
                                            required: false,
                                            orderindex: -1,
                                            type: SchemaItemType.Date,
                                        },
                                        value2: (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _b !== void 0 ? _b : '',
                                        v2float: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _c !== void 0 ? _c : -1,
                                        vfloat: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _d !== void 0 ? _d : -1,
                                        optype: SearchMetadataOp[e.currentTarget.value],
                                        numbercompare: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _e !== void 0 ? _e : false
                                    };
                                    this.props.state.addValue("lastmodifief", sitem);
                                }, defaultValue: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _c !== void 0 ? _c : SearchMetadataOp.Equal }, Object.keys(SearchMetadataOp).map((op) => React.createElement("option", { value: op, key: op }, op))))),
                    React.createElement(Flex, { w: '100%' },
                        React.createElement(Flex, { w: (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween ? '50%' : '100%' },
                            (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween &&
                                React.createElement(Text, { mr: '5px', color: AppColor.FilterItemLabelFontColor, fontSize: FontSize.FilterItemLabelFontSize }, "Date1: "),
                            React.createElement(DateInput, { disbaledate: false, defaultvalue: nval, onChange: (ts) => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h;
                                    if (!ts) {
                                        const sitem = {
                                            id: "lastmodifief",
                                            fielditem: {
                                                id: 'lm',
                                                name: "Last Modified",
                                                required: false,
                                                orderindex: -1,
                                                type: SchemaItemType.Date,
                                            },
                                            value: ts + '',
                                            value2: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _a !== void 0 ? _a : '',
                                            v2float: (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _b !== void 0 ? _b : -1,
                                            vfloat: ts + (0),
                                            optype: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _c !== void 0 ? _c : SearchMetadataOp.Equal,
                                            numbercompare: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _d !== void 0 ? _d : false
                                        };
                                        this.props.state.addValue("lastmodifief", sitem);
                                    }
                                    else {
                                        const sitem = {
                                            id: "lastmodifief",
                                            fielditem: {
                                                id: 'lm',
                                                name: "Last Modified",
                                                required: false,
                                                orderindex: -1,
                                                type: SchemaItemType.Date,
                                            },
                                            value: ts + '',
                                            value2: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value2) !== null && _e !== void 0 ? _e : '',
                                            v2float: (_f = dmeta === null || dmeta === void 0 ? void 0 : dmeta.v2float) !== null && _f !== void 0 ? _f : -1,
                                            vfloat: ts,
                                            optype: (_g = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _g !== void 0 ? _g : SearchMetadataOp.Equal,
                                            numbercompare: (_h = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _h !== void 0 ? _h : false
                                        };
                                        this.props.state.addValue("lastmodifief", sitem);
                                    }
                                }, showtime: true, use24hrformat: true, key: 'lastmodified_inbet', id: "lastmodified_inbet", format: 'dd/MM/yyyy' })),
                        (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween && React.createElement(Flex, { w: (dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) === SearchMetadataOp.InBetween ? '50%' : '100%' },
                            React.createElement(Text, { ml: '5px', mr: '5px' }, "Date2: "),
                            React.createElement(DateInput, { disbaledate: false, defaultvalue: nval2, onChange: (ts) => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h;
                                    if (!ts) {
                                        const sitem = {
                                            id: "lastmodifief",
                                            fielditem: {
                                                id: 'lm',
                                                name: "Last Modified",
                                                required: false,
                                                orderindex: -1,
                                                type: SchemaItemType.Date,
                                            },
                                            value: (_a = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _a !== void 0 ? _a : '',
                                            value2: ts + "",
                                            v2float: ts,
                                            vfloat: (_b = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _b !== void 0 ? _b : -1,
                                            optype: (_c = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _c !== void 0 ? _c : SearchMetadataOp.Equal,
                                            numbercompare: (_d = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _d !== void 0 ? _d : false
                                        };
                                        this.props.state.addValue("lastmodifief", sitem);
                                    }
                                    else {
                                        const sitem = {
                                            id: "lastmodifief",
                                            value: (_e = dmeta === null || dmeta === void 0 ? void 0 : dmeta.value) !== null && _e !== void 0 ? _e : '',
                                            fielditem: {
                                                id: 'lm',
                                                name: "Last Modified",
                                                required: false,
                                                orderindex: -1,
                                                type: SchemaItemType.Date,
                                            },
                                            value2: ts + "",
                                            v2float: ts,
                                            vfloat: (_f = dmeta === null || dmeta === void 0 ? void 0 : dmeta.vfloat) !== null && _f !== void 0 ? _f : -1,
                                            optype: (_g = dmeta === null || dmeta === void 0 ? void 0 : dmeta.optype) !== null && _g !== void 0 ? _g : SearchMetadataOp.Equal,
                                            numbercompare: (_h = dmeta === null || dmeta === void 0 ? void 0 : dmeta.numbercompare) !== null && _h !== void 0 ? _h : false
                                        };
                                        this.props.state.addValue("lastmodifief", sitem);
                                    }
                                }, showtime: true, use24hrformat: true, key: 'lastmodified_inbet2', id: 'lastmodified_inbet2', format: 'dd/MM/yyyy' }))));
            }));
    }
    render() {
        return React.createElement(Box, { pos: 'relative', w: '100%', maxH: '500px' },
            React.createElement(Flex, { pos: 'absolute', w: '100%', align: 'center', justify: 'flex-end', backgroundColor: 'white', borderBottomWidth: '1px', borderBottomColor: 'lightgray' },
                React.createElement(Button, { disabled: this.props.state.valuesbyid.size === 0 ? true : false, onClick: () => {
                        this.props.onApply();
                    } }, "Apply"),
                React.createElement(Icon, { ml: '10px', h: '22px', w: '22px', as: MdClose, color: AppColor.DeleteIconColor, _hover: { cursor: 'pointer' }, onClick: () => {
                        this.props.state.clear();
                        this.props.onClosePress();
                    } })),
            React.createElement(Box, { h: '40px' }),
            React.createElement(Box, { w: '100%', overflow: 'auto', maxH: '400px' }, this.renderRow()));
    }
};
Panel = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], Panel);
export const PwaFilterPanel = (prop) => React.createElement(Panel, Object.assign({}, prop));
export default PwaFilterPanel;
let RemoteDisplayItem = class RemoteDisplayItem extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            id: ''
        };
    }
    render() {
        var _a;
        const item = this.props.item;
        const rtmap = this.props.remoteitemsmap;
        const defaultValue = (_a = this.props.defaultValue) !== null && _a !== void 0 ? _a : '';
        const rtitems = this.props.rtitems;
        const ritem = item;
        const rtextra = ritem.rtextra;
        let rtDisplayItems = [];
        if (this.state.id) {
            let riID = ritem.id;
            if (riID.indexOf("----") !== -1) {
                const bits = riID.split("----");
                if (bits.length > 1) {
                    riID = bits[1];
                }
            }
            const id = riID + "---" + ritem.sid + "---" + this.state.id;
            const items = rtmap.get(id);
            if (items !== undefined) {
                const itmap = new Map();
                items.map((it) => {
                    if (it.rowid !== undefined) {
                        itmap.set(it.rowid, it);
                    }
                });
                for (const it of rtitems) {
                    if (it.rowid !== undefined) {
                        const item = itmap.get(it.rowid);
                        if (item !== undefined) {
                            const newitem = {
                                id: it.id,
                                value: item.value,
                                rowid: it.rowid
                            };
                            rtDisplayItems.push(newitem);
                        }
                    }
                }
            }
        }
        else {
            rtDisplayItems = this.props.rtitems;
        }
        return React.createElement(Box, null,
            rtextra !== undefined && rtextra.length > 0 && rtextra.map((val) => React.createElement(Tag, { key: val.cid, margin: '4px', bg: this.state.id === val.cid ? AppColor.SideBarIconColor : '#EDF2F7', _hover: { cursor: 'pointer' }, onClick: () => {
                    this.setState({ id: val.cid });
                } },
                React.createElement(TagLabel, null, val.name),
                React.createElement(TagRightIcon, { key: val.cid + "_icon", _hover: { cursor: 'pointer' }, onClick: (e) => {
                        e.stopPropagation();
                        this.setState({ id: '' });
                    }, as: GrFormClose }))),
            React.createElement(ChoiceInput, { multiselect: ritem.singleselect ? false : true, schemafieldname: item.name, defaultvalue: defaultValue, onChange: (ch) => {
                    this.props.onChange(ch);
                }, key: item.id, id: item.id, list: rtDisplayItems.map((item) => {
                    const ret = {
                        id: item.id,
                        name: item.value,
                        type: ChoiceType.Text
                    };
                    return ret;
                }) }));
    }
};
RemoteDisplayItem = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteDisplayItem);
let CheckboxUI = class CheckboxUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(Checkbox, { defaultChecked: this.props.defaultvalue, onChange: (e) => {
                    this.props.onChange(e.currentTarget.checked);
                } }));
    }
};
CheckboxUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CheckboxUI);
let TextInput = class TextInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];
        this.modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['clean'],
            ],
        };
    }
    render() {
        return React.createElement(Suspense, { fallback: React.createElement(Flex, { style: { height: '100%' }, justify: 'center', align: 'center' },
                React.createElement(ScaleLoader, { color: 'green' })) },
            React.createElement(ReactQuill, { theme: "snow", ref: (ref) => {
                    this.ref = ref;
                }, placeholder: 'Contains', defaultValue: this.props.defaultvalue, modules: this.props.richtext ? this.modules : { toolbar: false }, formats: this.props.richtext ? this.formats : [], onChange: (content, delta, source, editor) => {
                    if (this.ref)
                        this.props.onChange(editor.getHTML(), editor.getText());
                } }));
    }
};
TextInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], TextInput);
let CustomNumberInput = class CustomNumberInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            value: prop.defaultvalue
        };
    }
    render() {
        return React.createElement(NumberInput, { allowMouseWheel: true, value: this.state.value, onChange: (val) => {
                const ch = val;
                if ((!ch || this.props.minvalue === undefined || parseFloat(ch) >= this.props.minvalue) &&
                    (!ch || this.props.maxvalue === undefined || parseFloat(ch) <= this.props.maxvalue)) {
                    this.setState({ value: ch });
                    this.props.onChange(val);
                }
            }, min: this.props.minvalue, max: this.props.maxvalue },
            React.createElement(NumberInputField, null),
            React.createElement(NumberInputStepper, null,
                React.createElement(NumberIncrementStepper, null),
                React.createElement(NumberDecrementStepper, null)));
    }
};
CustomNumberInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomNumberInput);
let ColorInput = class ColorInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            color: this.props.defaultvalue
        };
    }
    convertRGBToString(rgb) {
        return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + rgb.a + ")";
    }
    convertRGBStringToRGB(rgb) {
        const clerstr = rgb.replace("rgba(", "").replace(")", "");
        const parts = clerstr.split(",");
        return { r: parseInt(parts[0]), g: parseInt(parts[1]), b: parseInt(parts[2]), a: parseFloat(parts[3]) };
    }
    render() {
        return React.createElement(Box, null,
            this.state.color !== '' && React.createElement(Box, { mb: '5px', w: '20px', h: '20px', borderRadius: '10px', bg: this.state.color }),
            this.state.color === '' && React.createElement(Box, { mb: '5px' },
                React.createElement(Text, null, "Select Color")),
            React.createElement(SketchPicker, { color: this.convertRGBStringToRGB(this.state.color), onChange: ({ rgb }) => {
                    const cl = this.convertRGBToString(rgb);
                    this.props.onChange(cl);
                    this.setState({ color: cl });
                } }));
    }
};
ColorInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ColorInput);
let DateInput = class DateInput extends React.Component {
    constructor(prop) {
        super(prop);
        let date;
        if (prop.defaultvalue) {
            if (this.props.defaultvalue) {
                try {
                    date = new Date(this.props.defaultvalue);
                }
                catch (e) {
                }
            }
        }
        this.state = {
            date: date
        };
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(DatePicker, { showTimeInput: this.props.showtime, selected: this.state.date, showTimeSelectOnly: this.props.disbaledate, dateFormat: (this.props.disbaledate ? "" : this.props.format) + "" + (this.props.showtime ? " HH:mm" : ''), onChange: (date) => {
                    this.props.onChange(date.getTime());
                    this.setState({ date: date });
                } }));
    }
};
DateInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], DateInput);
let ChoiceInput = class ChoiceInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            id: this.props.defaultvalue.split(",")
        };
    }
    render() {
        if (this.props.multiselect) {
            return React.createElement(Box, null,
                React.createElement(CSelect, { isMulti: true, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), selectedOptionStyle: "check", value: this.props.list.filter((lt) => {
                        if (this.state.id.indexOf(lt.id) != -1) {
                            return true;
                        }
                        return false;
                    }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                        this.setState({ id: e.map((e) => e.value) });
                        this.props.onChange(e.map((lt) => lt.value).join(","));
                    }, closeMenuOnSelect: false }));
        }
        return React.createElement(Box, null,
            React.createElement(CSelect, { selectedOptionStyle: "check", options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), value: this.props.list.filter((lt) => {
                    if (this.state.id.indexOf(lt.id) != -1) {
                        return true;
                    }
                    return false;
                }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                    this.setState({ id: [e.value] });
                    this.props.onChange(e.value);
                } }));
    }
};
ChoiceInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ChoiceInput);
