export var ImageType;
(function (ImageType) {
    ImageType["PNG"] = "png";
    ImageType["JPG"] = "jpg";
    ImageType["JPEG"] = "jpeg";
    ImageType["SVG"] = "svg";
    ImageType["GIF"] = "gif";
    ImageType["GIFF"] = "giff";
    ImageType["JFIF"] = "jfif";
    ImageType["CDR"] = "cdr";
    ImageType["PSD"] = "psd";
    ImageType["AI"] = "ai";
})(ImageType || (ImageType = {}));
