var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Box, Center, Spinner, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { StoreContext } from '@/app/uistore/PwaRootStore';
import { BaseDialog } from '@/app/UIComponents/BaseDialog';
let PwaWorkingDialog = class PwaWorkingDialog extends React.Component {
    buildWorkingDialog() {
        const rstore = this.context;
        return React.createElement(Box, { w: '100%', h: '100%' },
            React.createElement(Center, { w: '100%' },
                React.createElement(Spinner, null)),
            React.createElement(Center, { w: '100%' },
                React.createElement(Text, { mt: '15px' }, rstore.wdialog.msg)));
    }
    render() {
        const rstore = this.context;
        return (React.createElement(BaseDialog, { state: rstore.wdialog.baseDialog, body: this.buildWorkingDialog() }));
    }
};
PwaWorkingDialog.contextType = StoreContext;
PwaWorkingDialog = __decorate([
    observer
], PwaWorkingDialog);
export { PwaWorkingDialog };
