export class Event {
    constructor(name) {
        this.name = name;
        this.listners = [];
    }
    subscribe(list) {
        this.listners.push(list);
    }
    unsubscribe(list) {
        const index = this.listners.indexOf(list);
        if (index > -1) {
            this.listners.splice(index, 1);
        }
    }
    publish(data) {
        this.listners.forEach(lit => {
            lit(data);
        });
    }
}
