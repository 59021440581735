import { CommBundleStatus, PasswordVault, REFILLType, RegisterUserRequest, UserKeysLocker, UserPublicBox } from "./EncryptBox";
import { LetrwingCrypto } from "./LetrwingCommonCrypto";
class CommonCryptofunc {
    constructor() {
        this.devicepasswordshit = 4;
    }
    generateDeviceRegisterPassword(password, matrix) {
        // now we just need to do something neat here!
        const chbits = password.split("");
        let devicepassword = "";
        const it = chbits.length / 2;
        for (let i = 0; i < it; i++) {
            const ch = (chbits[i].charCodeAt(0) + chbits[chbits.length - i - 1].charCodeAt(0)) % matrix.length;
            devicepassword += matrix[ch];
        }
        return devicepassword;
    }
    generatePasswordUsingMatrix(password, matrix, shift = 2) {
        let devicepassword = "";
        let n = 0;
        if (shift === 0) {
            shift = 2;
        }
        const nonaplha = password.replace(/[a-zA-Z]/g, '');
        while (n < shift) {
            for (let i = 0; i < password.length; i++) {
                const code = password[i].charCodeAt(0);
                let row = code % matrix.length;
                if (row >= matrix.length) {
                    row = matrix.length - 1;
                }
                if (row < 0) {
                    row = 0;
                }
                let col = (code + row) % (matrix[row].length);
                if (col < 0) {
                    col = 0;
                }
                if (col >= matrix[row].length) {
                    col = matrix[row].length - 1;
                }
                const mrow = matrix[row];
                devicepassword += mrow[col];
            }
            password = password.substring(1) + password[0];
            n++;
        }
        devicepassword += nonaplha;
        const hash = LetrwingCrypto.hash512str(devicepassword);
        return hash;
    }
    produceUserKey(password, randompass) {
        const combinestring = password + randompass;
        const firsthalf = combinestring.substring(0, Math.ceil(combinestring.length / 2));
        const hash = LetrwingCrypto.hash512str(firsthalf);
        const nextpart = LetrwingCrypto.hash512(combinestring.substring(firsthalf.length) + hash);
        // now we just need to get first 256 bytes
        const key = LetrwingCrypto.getBase64(nextpart.subarray(0, 32));
        return key;
    }
    async getLocalDevicePasswordSKey(pin, userid, deviceid, matrix) {
        const spacket = this.generatePasswordUsingMatrix(pin + deviceid + userid, matrix, this.devicepasswordshit);
        const key = await LetrwingCrypto.getHash256(spacket);
        return key;
    }
    getMatrixPasswordFromBox(password, box) {
        const key = this.getLocalMatrixBoxKey(password);
        const pass = LetrwingCrypto.decryptSecretBox(box, key);
        return pass;
    }
    getLocalMatrixBoxKey(pin) {
        return LetrwingCrypto.getBase64(LetrwingCrypto.hash512(pin).subarray(0, 32));
    }
    async getClientIdForDevice(userid, deviceid) {
        return await LetrwingCrypto.getHash256(deviceid + userid);
    }
    async generateNewDevicePacket(pin, deviceid, userid, deviceMetadata, deviceInfoKey, matrix) {
        const devicepassword = LetrwingCrypto.getRandomPassword();
        const key = await this.getLocalDevicePasswordSKey(pin, userid, deviceid, matrix);
        const secretbox = LetrwingCrypto.secretBox(devicepassword, key);
        const devicemBox = LetrwingCrypto.box(JSON.stringify(deviceMetadata), deviceInfoKey);
        const devicematrixpassword = LetrwingCrypto.getRandomPassword();
        const localbox = LetrwingCrypto.secretBox(devicematrixpassword, this.getLocalMatrixBoxKey(pin));
        const ret = { devicepassword: devicepassword, metabox: LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(devicemBox))), localbox: secretbox,
            devicestoragekey: LetrwingCrypto.getRandomPassword(), localmatrioxbox: localbox, matrixpassword: devicematrixpassword };
        return ret;
    }
    async getDevicePasswordFrom(pin, deviceid, userid, matrix, box) {
        const key = await this.getLocalDevicePasswordSKey(pin, userid, deviceid, matrix);
        const password = LetrwingCrypto.decryptSecretBox(box, key);
        return password;
    }
    async generateNewUserPacket(password, secret, masterpublickey, matrix, name) {
        const randomnonce1 = LetrwingCrypto.getRandomPassword();
        const mainkey = this.produceUserKey(password, randomnonce1);
        if (!mainkey) {
            return 100;
        }
        const randomnonce2 = LetrwingCrypto.getRandomPassword();
        const secretkey = this.produceUserKey(secret, randomnonce2);
        if (!secretkey) {
            return 101;
        }
        const deviceRegisterPassword = this.generatePasswordUsingMatrix(password, matrix, 2);
        const identityKey = LetrwingCrypto.getNewKeypair();
        const signKey = LetrwingCrypto.getSignKeyPair();
        const deviceInfoKey = LetrwingCrypto.getNewKeypair();
        const locker = new UserKeysLocker(identityKey, signKey, deviceInfoKey);
        const randomPassBytes = LetrwingCrypto.getRandomPassword();
        // now we have password lets just create key and we done!
        const passwordKey = await LetrwingCrypto.getHash256(randomPassBytes);
        const pbox = await this.generateNewPasswordBox(passwordKey);
        if (pbox === undefined) {
            return undefined;
        }
        pbox.opbox.id = "Registerkey";
        const keys = await CommonCrypto.refillCommKeys(REFILLType.Both, pbox.opbox, identityKey, signKey);
        if (keys === undefined) {
            return undefined;
        }
        const indexkey = LetrwingCrypto.generateNewSecretKey();
        const lockerStr = JSON.stringify(locker);
        const encodedData = LetrwingCrypto.secretBox(lockerStr, passwordKey);
        const indexbox = LetrwingCrypto.secretBox(indexkey, passwordKey);
        const auth = LetrwingCrypto.hash512str(identityKey.privatkey + signKey.privatkey + passwordKey);
        const passwordVault = new PasswordVault(LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(encodedData))), auth);
        const passwordStorageKey = this.generatePasswordUsingMatrix(password, matrix, 4);
        const pkey = await LetrwingCrypto.getHash256(passwordStorageKey);
        const encodedPinRandomPass = LetrwingCrypto.secretBox(randomnonce1, pkey);
        const secretpasswordStorageKey = this.generatePasswordUsingMatrix(secret, matrix, 4);
        const skey = await LetrwingCrypto.getHash256(secretpasswordStorageKey);
        const encodedSecretRandomPass = LetrwingCrypto.secretBox(randomnonce2, skey);
        const passwordStorage1 = LetrwingCrypto.secretBox(randomPassBytes, mainkey);
        const passwordStorage2 = LetrwingCrypto.secretBox(randomPassBytes, secretkey);
        const passwordstage3 = LetrwingCrypto.box(randomPassBytes, masterpublickey);
        const pubkeys = new UserPublicBox(identityKey.publickey, signKey.publickey, deviceInfoKey.publickey);
        const req = new RegisterUserRequest();
        req.name = name;
        req.storage1 = LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(passwordStorage1)));
        req.storage2 = LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(passwordStorage2)));
        if (passwordstage3) {
            req.storage3 = LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(passwordstage3)));
        }
        req.vault = LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(passwordVault)));
        req.encryptbox1 = LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(encodedPinRandomPass)));
        req.encryptbox2 = LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(encodedSecretRandomPass)));
        req.pubKeys = pubkeys;
        req.deviceRegisteredPassword = deviceRegisterPassword;
        req.keys = keys;
        req.recoverkeybox = LetrwingCrypto.secretBox(secret, passwordKey);
        req.passwordbox = pbox.box;
        req.userindexkey = indexbox;
        return req;
    }
    async decryptPasswordBox(mainpassword, pbox) {
        if (!pbox.encryptmsg || !pbox.nonce) {
            return undefined;
        }
        const passwordbox = LetrwingCrypto.decryptSecretBox(pbox, mainpassword);
        if (passwordbox === undefined) {
            return undefined;
        }
        return JSON.parse(passwordbox);
    }
    async openPasswordProtectedBox(databox, box, password) {
        const res = await this.decryptPasswordBox(password, box);
        if (res === undefined) {
            return undefined;
        }
        const data = LetrwingCrypto.decryptSecretBox(databox, res.password);
        if (data === null) {
            return undefined;
        }
        return data;
    }
    async generatePasswordBoxFor(password, mainpassword) {
        if (!mainpassword) {
            return undefined;
        }
        const passwordbox = {
            password: password,
            id: ""
        };
        const encryptedpbox = LetrwingCrypto.secretBox(JSON.stringify(passwordbox), mainpassword);
        if (encryptedpbox === undefined) {
            return undefined;
        }
        return { box: encryptedpbox, opbox: passwordbox };
    }
    async generateNewPasswordBox(mainpassword) {
        if (!mainpassword) {
            return undefined;
        }
        const newpassword = LetrwingCrypto.getRandomPassword();
        return await this.generatePasswordBoxFor(newpassword, mainpassword);
    }
    async refillCommKeys(which, passwordbox, identitykey, signkey) {
        // okay lets just check and refill if needed!
        // we can make a comm request if we 
        var _a;
        try {
            const pid = passwordbox.id;
            const password = passwordbox.password;
            const longtermbox = [];
            const onetimebundle = [];
            const longTermPubBox = [];
            const oneTimeTermPubBox = [];
            // 30 keys for now!
            for (let i = 0; i < 30; i++) {
                if (which === REFILLType.Both || which === REFILLType.LongLive) {
                    const lbundle = await this.makeLongLiveCommBundle(identitykey, signkey);
                    if (lbundle === undefined) {
                        return undefined;
                    }
                    longtermbox.push(lbundle);
                    const signedpubkey = LetrwingCrypto.signMessage(lbundle.longlivekeyapir.publickey, lbundle.signkey);
                    longTermPubBox.push({
                        userIdentityKey: lbundle.identityKeyPair.publickey,
                        signedKey: signedpubkey,
                        userSignkey: lbundle.signkey.publickey,
                        ephemeralKey: undefined,
                        status: CommBundleStatus.Live,
                        creationtime: lbundle.creationtime,
                        reference: pid + '_' + lbundle.reference,
                        initPubKey: lbundle.initkey.publickey
                    });
                }
                if (which === REFILLType.Both || which === REFILLType.OneTime) {
                    const otbundle = await this.makeOneTimeCommBundle(identitykey, signkey);
                    if (otbundle === undefined) {
                        return undefined;
                    }
                    onetimebundle.push(otbundle);
                    const signedpubkey = LetrwingCrypto.signMessage(otbundle.longlivekeyapir.publickey, otbundle.signkey);
                    oneTimeTermPubBox.push({
                        userIdentityKey: otbundle.identityKeyPair.publickey,
                        signedKey: signedpubkey,
                        userSignkey: otbundle.signkey.publickey,
                        ephemeralKey: (_a = otbundle.ephemeralkeypair) === null || _a === void 0 ? void 0 : _a.publickey,
                        status: CommBundleStatus.Live,
                        creationtime: otbundle.creationtime,
                        reference: pid + '_' + otbundle.reference,
                        initPubKey: otbundle.initkey.publickey
                    });
                }
            }
            const sbox = {
                storage1: longtermbox,
                storage2: onetimebundle,
                reference: pid
            };
            let encryptBoxes = [];
            encryptBoxes = encryptBoxes.concat(sbox.storage1.map(sb => {
                return { box: LetrwingCrypto.secretBox(JSON.stringify(sb), password), reference: pid + "_" + sb.reference };
            }));
            encryptBoxes = encryptBoxes.concat(sbox.storage2.map(sb => {
                return { box: LetrwingCrypto.secretBox(JSON.stringify(sb), password), reference: pid + "_" + sb.reference };
            }));
            const submitreq = {
                storage1: encryptBoxes,
                reference: pid
            };
            const request = {
                commbox: submitreq,
                longtermpubkey: longTermPubBox,
                onetimepubkey: oneTimeTermPubBox,
                reference: pid
            };
            return request;
        }
        catch (ex) {
            console.log(ex);
        }
        return undefined;
    }
    decryptCommBundleBox(box, password) {
        const data = LetrwingCrypto.decryptSecretBox(box, password);
        if (data !== undefined) {
            const cbundle = JSON.parse(data);
            return cbundle;
        }
        return undefined;
    }
    async makeLongLiveCommBundle(identitykp, signkp) {
        const identitykey = identitykp;
        const signkey = signkp;
        if (identitykey == undefined || signkey == undefined) {
            return undefined;
        }
        const keypair = LetrwingCrypto.getNewKeypair();
        const initkey = LetrwingCrypto.getNewKeypair();
        const lbundle = {
            identityKeyPair: identitykey,
            signkey: signkey,
            longlivekeyapir: keypair,
            reference: Math.random().toString(),
            status: CommBundleStatus.Live,
            creationtime: Math.floor((new Date()).getTime() / 1000),
            initkey: initkey
        };
        return lbundle;
    }
    async makeOneTimeCommBundle(identitykp, signkp) {
        const identitykey = identitykp;
        const signkey = signkp;
        if (identitykey == undefined || signkey == undefined) {
            return undefined;
        }
        const keypair = LetrwingCrypto.getNewKeypair();
        const ekeypair = LetrwingCrypto.getNewKeypair();
        const initkey = LetrwingCrypto.getNewKeypair();
        const otbundle = {
            identityKeyPair: identitykey,
            signkey: signkey,
            longlivekeyapir: keypair,
            ephemeralkeypair: ekeypair,
            reference: Math.random().toString(),
            status: CommBundleStatus.Live,
            initkey: initkey,
            creationtime: Math.floor((new Date()).getTime() / 1000)
        };
        return otbundle;
    }
    async setupUserLocker(password, passwordBox, matrix) {
        try {
            const passwordStorageKey = this.generatePasswordUsingMatrix(password, matrix, 4);
            const pkey = await LetrwingCrypto.getHash256(passwordStorageKey);
            const randompassword = LetrwingCrypto.decryptSecretBox(passwordBox.encryptbox, pkey);
            if (!randompassword) {
                return false;
            }
            const mainkey = this.produceUserKey(password, randompassword);
            if (mainkey === undefined) {
                return false;
            }
            const mainpasswordgen = LetrwingCrypto.decryptSecretBox(passwordBox.storage, mainkey);
            if (mainpasswordgen == null) {
                return false;
            }
            const mainpassword = await LetrwingCrypto.getHash256(mainpasswordgen);
            const data = LetrwingCrypto.fromBase64ToUTF8(passwordBox.vault.data);
            const lockerstr = LetrwingCrypto.decryptSecretBox(JSON.parse(data), mainpassword);
            if (lockerstr === undefined) {
                return false;
            }
            const ulockerjson = JSON.parse(lockerstr);
            const ulocker = new UserKeysLocker(ulockerjson.identityKey, ulockerjson.signKey, ulockerjson.deviceInfoKey);
            const auth = LetrwingCrypto.hash512str(ulocker.identityKey.privatkey +
                ulocker.signKey.privatkey + mainpassword);
            if (auth === passwordBox.vault.auth) {
                const ret = { mainpassword: mainpassword, keysLocker: ulocker };
                return ret;
            }
            return false;
        }
        catch (ex) {
            console.log(ex);
        }
        return false;
    }
}
export const CommonCrypto = new CommonCryptofunc();
