import { LetrProgress } from "@/share/models";
import { LeterPermissionType, Letr, LetrMetadata, LStore as CoominLStore } from '@@/Letr';
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { SecStore } from "@/background/store/SecurityStore";
import { EncryptBox, IndexType } from "@@/EncryptBox";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { comm } from "@/Comm/comm";
import { ServerPath } from "@@/ServerPath";
import { CHATID } from "@@/Tags";
import { BEvent } from "@/events/BackgroundEvents";
import { CommonCrypto } from "@@/Cryptography";
import { AppConstants } from "@@/Constants";
import { RoleStatus } from "@@/Project";
class LetrStore {
    constructor() {
        this.activetagid = "";
        this.letrsubjectById = new Map();
        this.letrviewkeyboxbyid = new Map();
        this.letrischat = new Set();
        this.newletrscb = () => {
            this.fetchNewLetrs();
        };
        BEvent.addSubscriberForNewLetr(this.newletrscb);
        BEvent.addSubscriberForAppState((st) => {
            this.lastactivetextsearch = undefined;
        });
    }
    async addWatermark(letrid, watermark) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const enbox = LetrwingCrypto.secretBox(watermark, box);
        const req = {
            letrid: letrid,
            watermark: enbox
        };
        const res = await comm.post(ServerPath.addWatermarkForLetr, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async addSubjectToIndex(subject, letrid) {
        const ikey = InMemoryRepo.getIndexKey();
        if (ikey) {
            const indexbox = {
                // always use lower case
                index: LetrwingCrypto.secretBox(subject, ikey),
                indextype: IndexType.Letr,
                dataid: letrid
            };
            await comm.post(ServerPath.AddIndex, indexbox);
        }
    }
    async getLetrDetailForIncomingCall(letrid) {
        const ldetail = await this.getLetrDetail(letrid);
        return ldetail;
    }
    async getAllLetrRoles(letrid) {
        var _a;
        const req = {
            letrid: letrid,
            id: '',
            capability: [],
            status: RoleStatus.Live,
            name: ''
        };
        const ret = await comm.post(ServerPath.getRoles, req);
        return (_a = ret === null || ret === void 0 ? void 0 : ret.map(re => (Object.assign(Object.assign({}, re), { letrid: letrid })))) !== null && _a !== void 0 ? _a : [];
    }
    async addRole(role) {
        role.id = "";
        const ret = await comm.post(ServerPath.addRole, role);
        if (ret) {
            ret.letrid = role.letrid;
        }
        return ret;
    }
    async updateRole(role) {
        const ret = await comm.post(ServerPath.updateRole, role);
        if (ret) {
            ret.letrid = role.letrid;
        }
        return ret;
    }
    async deleteRole(role) {
        const ret = await comm.post(ServerPath.deleteRole, role);
        return ret !== null && ret !== void 0 ? ret : false;
    }
    async getAllIndex() {
        const ikey = InMemoryRepo.getIndexKey();
        if (ikey) {
            // we can now create neat index stuff 
            const indexes = await comm.get(ServerPath.GetLetrIndex);
            let indexstring = "";
            const mapping = [];
            const ids = [];
            if (indexes != null && indexes.length > 0) {
                for (const index of indexes) {
                    const ebox = index.index;
                    const data = LetrwingCrypto.decryptSecretBox(ebox, ikey);
                    if (data) {
                        if (indexstring) {
                            indexstring += " ";
                        }
                        const startindex = indexstring.length;
                        const endindex = startindex + data.length - 1;
                        indexstring += data;
                        mapping.push({
                            startindex: startindex,
                            endindex: endindex,
                            dataid: index.dataid,
                        });
                        ids.push(index.dataid);
                    }
                }
                this.localIndex = {
                    datamapper: mapping.sort((a, b) => a.startindex - b.startindex),
                    text: indexstring,
                    letrids: ids
                };
            }
        }
    }
    async fetchAndPublishRemainIngChat(filter) {
        const letrs = await this.fetchLetrs(filter);
        if (letrs.length > 0) {
            BEvent.publishNewLetrAvailable(letrs);
        }
    }
    // new code to get each user pblic key bundle!
    async createNewLetr(re, progressCb) {
        var _a;
        const mainpassword = InMemoryRepo.getMasterPassword();
        const commkey = InMemoryRepo.getCommKey();
        const myuserid = InMemoryRepo.getuserId();
        const myuseridwithtenant = InMemoryRepo.getUserIDWithTenant();
        if (myuseridwithtenant === undefined ||
            mainpassword === undefined || commkey === undefined || myuserid === undefined) {
            progressCb({ error: "Failed creating secure channel", progress: LetrProgress.Error });
            return;
        }
        if (re.tagids.indexOf(CHATID) !== -1) {
            const ids = new Set();
            re.members.forEach((rc) => ids.add(rc.userid));
            ids.add(myuseridwithtenant);
            // remove yourself first
            const id = LetrwingCrypto.hash512str([...Array.from(ids), myuserid].sort((a, b) => a > b ? 1 : a < b ? -1 : 0).join(","));
            const ldetail = await this.getLetrDetail(id);
            if (ldetail !== undefined) {
                const filter = {
                    tagid: CHATID,
                    before: new Date().getTime(),
                    after: 0,
                    count: 1,
                    filterids: [id]
                };
                const res = await this.fetchLetrs(filter);
                if (res === undefined) {
                    progressCb({ error: "Failed creating secure channel", progress: LetrProgress.Error });
                    return undefined;
                }
                // we should load all the chat since this time as well otherwise this will look bit akward as well!
                const everythingfilter = {
                    tagid: CHATID,
                    before: new Date().getTime(),
                    after: res[0].letrMetadata.updatetime - 1000,
                    count: 10000,
                };
                this.fetchAndPublishRemainIngChat(everythingfilter);
                const result = {
                    letr: res[0],
                    connectPassword: []
                };
                return result;
            }
        }
        const grpids = re.members.map(p => p.userid);
        let commbundles = [];
        if (grpids.length > 0) {
            const creq = {
                grpids: grpids
            };
            const acommbundles = await comm.post(ServerPath.memberCommBundles, creq);
            if (acommbundles === undefined) {
                progressCb({ error: "Failed creating letr", progress: LetrProgress.Error });
                return undefined;
            }
            commbundles = [...commbundles, ...acommbundles];
        }
        // now we should arrange memebers fo groups and tthe bundles..
        const grpmembers = new Map();
        const ubundles = new Map();
        commbundles.forEach((cb) => {
            const grps = cb.grpids;
            grps.forEach((grp) => {
                var _a;
                if (!grpmembers.has(grp)) {
                    grpmembers.set(grp, []);
                }
                (_a = grpmembers.get(grp)) === null || _a === void 0 ? void 0 : _a.push(cb.userid);
            });
            ubundles.set(cb.userid, cb.bundle);
        });
        const memberPermission = [];
        const grpPermissionMap = new Map();
        re.members.forEach((lm) => {
            var _a;
            if (!grpPermissionMap.has(lm.permission)) {
                grpPermissionMap.set(lm.permission, []);
            }
            (_a = grpPermissionMap.get(lm.permission)) === null || _a === void 0 ? void 0 : _a.push(lm.userid);
        });
        grpmembers.forEach((grps, gm) => {
            var _a;
            const perm = ((_a = grpPermissionMap.get(LeterPermissionType.Edit)) === null || _a === void 0 ? void 0 : _a.some((item) => grps.includes(item))) ?
                LeterPermissionType.Edit : LeterPermissionType.View;
            memberPermission.push({ userid: gm, permission: perm, roleid: '' });
        });
        const nreq = CoominLStore.createNewLetrBox(re.subject, memberPermission, myuseridwithtenant);
        const inreformembers = new Map();
        const uinbox = { indirectionid: "v2", indirectionrefkey: nreq.keysBox.datakey };
        if (memberPermission.length > 0) {
            for (const mp of memberPermission) {
                inreformembers.set(mp.userid, uinbox);
            }
        }
        inreformembers.set(myuserid, uinbox);
        const mybox = inreformembers.get(myuserid);
        if (mybox === undefined) {
            progressCb({ error: "Failed creating secure channel", progress: LetrProgress.Error });
            return undefined;
        }
        const eachmemeberbox = new Map();
        for (const member of memberPermission) {
            const mindrefbox = inreformembers.get(member.userid);
            if (mindrefbox === undefined) {
                progressCb({ error: "Failed creating letr", progress: LetrProgress.Error });
                return undefined;
            }
            const mbox = {
                membersboxid: "",
                membersboxkey: "",
                indref: mindrefbox
            };
            // now we can create box... 
            const publickey = ubundles.get(member.userid);
            if (publickey !== undefined) {
                const box = LetrwingCrypto.box(JSON.stringify(mbox), publickey.initPubKey);
                // now we can use this for everything else..
                eachmemeberbox.set(member.userid, { box: box, bundleid: publickey.reference });
            }
        }
        const myboxpd = await SecStore.generatePasswordBoxFor(nreq.keysBox.datakey, mainpassword);
        if (myboxpd === undefined) {
            progressCb({ error: "Failed creating letr", progress: LetrProgress.Error });
            return undefined;
        }
        const embox = [];
        eachmemeberbox.forEach((val, key) => {
            embox.push({ userid: key, box: val });
        });
        // lets create new password for each user and store them in a box 
        // and then we need to send those password back!
        const cmems = re.connectids;
        const newpassword = LetrwingCrypto.getRandomPassword();
        const callkey = LetrwingCrypto.getNewKeypair();
        const signkey = LetrwingCrypto.getSignKeyPair();
        const ckst = {
            keypair: callkey,
            signkeypair: signkey
        };
        const cspbox = LetrwingCrypto.secretBox(nreq.keysBox.datakey, newpassword);
        const cpassword = [];
        const connectboxes = [];
        const cpbox = LetrwingCrypto.secretBox(JSON.stringify(ckst), nreq.keysBox.datakey);
        if (cspbox !== undefined && cpbox !== undefined) {
            for (const cmem of cmems) {
                connectboxes.push({
                    memberid: cmem,
                    box: cspbox,
                    memberpublickey: LetrwingCrypto.signMessage(callkey.publickey, signkey),
                    memberpublicbox: cpbox,
                    membersignkey: signkey.publickey
                });
                cpassword.push({ id: cmem, password: newpassword });
            }
        }
        let cbdid = "";
        let connectbox;
        let cbox;
        if (cpassword.length > 0) {
            const cpass = await SecStore.generatePasswordBox(mainpassword);
            if (cpass !== undefined) {
                cbdid = cpass.id;
                connectbox = LetrwingCrypto.secretBox(JSON.stringify(cpassword), cpass.password);
                // now we have tcb lets do something simpler!
                cbox = cpass.ebox;
            }
        }
        const rl = Math.floor(Math.random() * (8 - 3 + 1) + 3);
        const skey = {
            key: LetrwingCrypto.generateNewSecretKey(),
            nonce: LetrwingCrypto.getRandmBytes(24),
            randomdata: LetrwingCrypto.getRandmBytes(rl)
        };
        const searchbox = LetrwingCrypto.secretBox(JSON.stringify(skey), nreq.keysBox.datakey);
        const lvkey = LetrwingCrypto.getNewKeypair();
        const viewkeybox = LetrwingCrypto.secretBox(JSON.stringify(lvkey), nreq.keysBox.datakey);
        const sletr = {
            eachmemeberbox: embox,
            myboxpassword: (_a = myboxpd.ebox) !== null && _a !== void 0 ? _a : new EncryptBox(),
            shareletr: nreq.req,
            tags: re.tagids,
            selectedGrpIds: nreq.req.memeberid,
            connectmemberids: re.connectids,
            connectboxid: cbdid,
            connectboxes: connectboxes,
            connectemailhint: re.connectemailhint,
            connectbox: connectbox,
            letrtype: re.letrtype,
            searchbox: searchbox,
            connectpasswordbox: cbox !== null && cbox !== void 0 ? cbox : new EncryptBox(),
            version: "v2",
            viewkeybox: viewkeybox
        };
        // now we can just store this and we good for now!
        const res = await comm.post(ServerPath.createShareLetr, sletr);
        if (res === undefined) {
            if (re.tagids.includes(CHATID)) {
                // we should try twice
                await this.fetchNewLetrs();
            }
            else {
                progressCb({ error: "Failed creating secure channel", progress: LetrProgress.Error });
            }
            return undefined;
        }
        const dres = await this.decrytLetr(res);
        if (dres === undefined) {
            progressCb({ error: "Failed creating secure channel", progress: LetrProgress.Error });
            return undefined;
        }
        const letr = this.convertUserLetrToLetr(res, re.subject);
        letr.letrviewkey = dres.viewbox;
        const ret = {
            letr: letr,
            connectPassword: cpassword.map((cp) => ({
                id: cp.id,
                password: cp.password
            }))
        };
        if (!re.tagids.includes(CHATID)) {
            this.addSubjectToIndex(letr.letrMetadata.subject, letr.id);
        }
        return ret;
    }
    async addVersion(objectid, letrid, ver) {
        const pass = this.getLetrPassword(letrid);
        if (pass === undefined) {
            return undefined;
        }
        const vs = LetrwingCrypto.secretBox(JSON.stringify(ver), pass);
        const ov = {
            id: '',
            letrid: letrid,
            objectid: objectid,
            versionbox: vs,
            versionnumber: -1,
            creatorid: '',
            createtime: 0
        };
        const res = await comm.post(ServerPath.addversion, ov);
        if (res) {
            try {
                const vino = LetrwingCrypto.decryptSecretBox(res.versionbox, pass);
                if (vino) {
                    const vj = JSON.parse(vino);
                    vj.versionnumber = res.versionnumber;
                    vj.creatorid = res.creatorid;
                    vj.id = vj.id;
                    return vj;
                }
            }
            catch (ex) { }
        }
        return undefined;
    }
    async getVersion(objectid, letrid) {
        var _a;
        const pass = this.getLetrPassword(letrid);
        if (pass === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            objectid: objectid
        };
        const res = await comm.post(ServerPath.getversion, req);
        if (!res) {
            return [];
        }
        const ret = [];
        for (const ov of res) {
            try {
                const vino = LetrwingCrypto.decryptSecretBox(ov.versionbox, pass);
                if (vino) {
                    const vj = JSON.parse(vino);
                    vj.versionnumber = ov.versionnumber;
                    vj.creatorid = ov.creatorid;
                    vj.id = vj.id;
                    vj.creatime = (_a = ov.createtime) !== null && _a !== void 0 ? _a : 0;
                    ret.push(vj);
                }
            }
            catch (ex) { }
        }
        return ret;
    }
    async getAllTabs(letrid, objectid) {
        const pass = this.getLetrPassword(letrid);
        if (pass === undefined) {
            return [];
        }
        const req = {
            letrid: letrid,
            objectid: objectid
        };
        const tabs = await comm.post(ServerPath.getAllTabs, req);
        const res = [];
        if (tabs) {
            for (const tab of tabs) {
                try {
                    const name = LetrwingCrypto.decryptSecretBox(tab.data, pass);
                    tab.name = name;
                    if (tab.configdata) {
                        const str = LetrwingCrypto.decryptSecretBox(tab.configdata, pass);
                        if (str) {
                            const cda = JSON.parse(str);
                            tab.confiddataopen = cda;
                        }
                    }
                    res.push(tab);
                }
                catch (ex) {
                    console.log(ex);
                }
            }
        }
        return res;
    }
    async getTabs(letrid) {
        const pass = this.getLetrPassword(letrid);
        if (pass === undefined) {
            return [];
        }
        const req = {
            letrid: letrid
        };
        const tabs = await comm.post(ServerPath.gettab, req);
        const res = [];
        if (tabs) {
            for (const tab of tabs) {
                try {
                    const name = LetrwingCrypto.decryptSecretBox(tab.data, pass);
                    tab.name = name;
                    if (tab.configdata) {
                        const str = LetrwingCrypto.decryptSecretBox(tab.configdata, pass);
                        if (str) {
                            const cda = JSON.parse(str);
                            tab.confiddataopen = cda;
                        }
                    }
                    ;
                    res.push(tab);
                }
                catch (ex) {
                    console.log(ex);
                }
            }
        }
        return res;
    }
    async removeTab(letrid, tabid) {
    }
    async createNewTab(tabname, objectid, letrid, viewers, tabconfig) {
        const pass = this.getLetrPassword(letrid);
        if (pass === undefined) {
            return false;
        }
        const namebox = LetrwingCrypto.secretBox(tabname, pass);
        const configdata = LetrwingCrypto.secretBox(JSON.stringify(tabconfig), pass);
        const tab = {
            id: '',
            data: namebox,
            letrid: letrid,
            modelid: objectid,
            viewers: viewers,
            creatorid: '',
            configdata: configdata
        };
        const resp = await comm.post(ServerPath.addtab, tab);
        if (resp !== undefined) {
            return true;
        }
        return false;
    }
    async deleteTab(tabid, letrid) {
        const req = {
            id: tabid,
            letrid: letrid
        };
        const res = await comm.post(ServerPath.deletetab, req);
        return res !== null && res !== void 0 ? res : false;
    }
    getLetrPassword(letrid) {
        const uref = InMemoryRepo.getLetrPassword(letrid);
        return uref;
    }
    async updateMembersForLetr(letrid, member) {
        const req = [];
        for (const mem of member) {
            req.push({
                permission: mem,
                userid: mem.userid,
                letrid: letrid,
                box: { box: new EncryptBox(), bundleid: '' },
                tags: []
            });
        }
        await comm.post(ServerPath.updateMemeber, req);
        return await this.getLetrDetail(letrid);
    }
    async updateLinksForLetr(letrid, member) {
        const req = [];
        for (const mem of member) {
            req.push({
                permission: mem,
                userid: mem.userid,
                letrid: letrid,
                box: { box: new EncryptBox(), bundleid: '' },
                tags: []
            });
        }
        await comm.post(ServerPath.updateLetrLink, req);
        return await this.getLetrLinks(letrid);
    }
    async addMembersToLetr(letrid, member, tags = []) {
        for (const mem of member) {
            await this.addMemberToLetr(letrid, mem, tags);
        }
        return await this.getLetrDetail(letrid);
    }
    async addMemberToLetr(letrid, member, tags = []) {
        const uref = InMemoryRepo.getLetrPassword(letrid);
        if (uref === undefined) {
            return undefined;
        }
        const creq = {
            grpids: [member.userid]
        };
        const commbundles = await comm.post(ServerPath.memberCommBundles, creq);
        if (commbundles === undefined) {
            return undefined;
        }
        const grpmembers = new Map();
        const ubundles = new Map();
        commbundles.forEach((cb) => {
            const grps = cb.grpids;
            grps.forEach((grp) => {
                var _a;
                if (!grpmembers.has(grp)) {
                    grpmembers.set(grp, []);
                }
                (_a = grpmembers.get(grp)) === null || _a === void 0 ? void 0 : _a.push(cb.userid);
            });
            ubundles.set(cb.userid, cb.bundle);
        });
        const mempackte = [];
        for (const [key, val] of ubundles) {
            const uinbox = { indirectionid: "v2", indirectionrefkey: uref };
            const mbox = {
                membersboxid: "",
                membersboxkey: "",
                indref: uinbox
            };
            const box = LetrwingCrypto.box(JSON.stringify(mbox), val.initPubKey);
            mempackte.push({
                letrid: letrid,
                permission: { userid: key, permission: member.permission, roleid: member.roleid },
                box: { box: box, bundleid: val.reference },
                tags: tags
            });
        }
        ;
        await comm.post(ServerPath.addMemberToLetr, mempackte);
        return await this.getLetrDetail(letrid);
    }
    async removeMemberFromLetr(letrid, member, isConnect) {
        const req = {
            letrid: letrid,
            memberid: member,
            isConnect: isConnect
        };
        const ok = await comm.post(ServerPath.removeMemberToLetr, req);
        return await this.getLetrDetail(letrid);
    }
    async letrUpdateAvailable(letr) {
        const uletr = await this.decrytLetr(letr);
        if (uletr !== undefined && uletr.subject !== undefined) {
            if (this.localIndex !== undefined && this.localIndex.letrids.indexOf(letr.id) === -1) {
                this.localIndex.letrids.push(letr.id);
                this.localIndex.datamapper.push({
                    startindex: this.localIndex.text.length,
                    endindex: this.localIndex.text.length + uletr.subject.length,
                    dataid: letr.id
                });
                this.localIndex.text += uletr.subject;
            }
            BEvent.publishLetrUpdateAvailable([this.convertUserLetrToLetr(letr, uletr.subject, uletr.message, uletr.viewbox)]);
        }
    }
    async fetchNewLetrs() {
        var _a;
        const mainpassword = InMemoryRepo.getMasterPassword();
        const commkey = InMemoryRepo.getCommKey();
        if (mainpassword === undefined || commkey === undefined) {
            return undefined;
        }
        const res = await comm.get(ServerPath.newVLetrs);
        if (res !== undefined) {
            const newletrs = [];
            for (const pletr of res) {
                if (pletr.bundlerBoxes.length > 1) {
                    const b1 = pletr.bundlerBoxes[0];
                    const b2 = pletr.bundlerBoxes[1];
                    const mybox = pletr.mybox;
                    //okay now we have the mybox we can open this box and then we good tgo gp
                    const pass = await SecStore.decryptPasswordBox(b2, mainpassword);
                    if (pass !== undefined) {
                        const pkey = pass.password;
                        const bundle = CommonCrypto.decryptCommBundleBox(b1, pkey);
                        if (bundle !== undefined) {
                            const initkey = bundle.initkey;
                            const mydata = LetrwingCrypto.decryptRSABox(mybox, initkey);
                            if (mydata !== undefined) {
                                const datakey = mydata.indref.indirectionrefkey;
                                const mypbox = await SecStore.generatePasswordBoxFor(datakey, mainpassword);
                                if (mypbox !== undefined) {
                                    const tags = new Set();
                                    if (pletr.isChat) {
                                        tags.add("chat");
                                    }
                                    else {
                                        tags.add("all");
                                    }
                                    // now we have id and password boxid..
                                    const req = {
                                        myboxid: "",
                                        myboxpasswordid: "",
                                        mypasswordbox: (_a = mypbox.ebox) !== null && _a !== void 0 ? _a : new EncryptBox(),
                                        newvlid: pletr.id,
                                        permission: pletr.permission,
                                        tags: Array.from(tags)
                                    };
                                    const res = await comm.post(ServerPath.activateVLetr, req);
                                    if (res !== undefined) {
                                        let subjectAndMessage = await this.decrytLetr(res);
                                        if (subjectAndMessage === undefined ||
                                            (subjectAndMessage === null || subjectAndMessage === void 0 ? void 0 : subjectAndMessage.subject) === undefined) {
                                            continue;
                                        }
                                        let message = "";
                                        if (res.tags.includes(CHATID)) {
                                            subjectAndMessage.subject = res.title;
                                        }
                                        const cletr = this.convertUserLetrToLetr(res, subjectAndMessage.subject, subjectAndMessage.message, subjectAndMessage.viewbox);
                                        newletrs.push(cletr);
                                        if (!res.tags.includes(CHATID)) {
                                            this.addSubjectToIndex(cletr.letrMetadata.subject, cletr.id);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (newletrs.length > 0) {
                BEvent.publishNewLetrAvailable(newletrs);
            }
        }
    }
    setActiveTagId(activetagid) {
        this.activetagid = activetagid;
    }
    async fetchLetrUpdatesSince(time) {
        await this.fetchLatestLetrsSince(time);
        await this.fetchNewLetrs();
    }
    async fetchLatestLetrsSince(time) {
        if (!this.activetagid) {
            return;
        }
        const filter = {
            after: time,
            tagid: this.activetagid,
            before: 0,
            count: 100000
        };
        const letrs = await this.fetchLetrs(filter);
        if (letrs.length > 0) {
            BEvent.publishLetrUpdateAvailable(letrs);
        }
    }
    findLetridForIndex(index, sindex) {
        if (index.length == 1) {
            const item = index[0];
            return item;
        }
        let min = 0;
        let max = index.length - 1;
        while (min <= max) {
            const mid = Math.floor((max + min) / 2);
            console.log(max + " " + min + " " + mid);
            const item = index[mid];
            console.log(item);
            console.log(sindex);
            if (item.startindex <= sindex && item.endindex >= sindex) {
                return item;
            }
            if (item.startindex >= sindex) {
                max = mid - 1;
            }
            else {
                min = mid + 1;
            }
        }
        // dint find it
        return undefined;
    }
    async getLetridsFor(text) {
        if (this.localIndex === undefined) {
            await this.getAllIndex();
        }
        const index = this.localIndex;
        const matchids = new Set();
        if (index !== undefined) {
            let lastfound = 0;
            let indextext = index.text.toLowerCase();
            while (lastfound < indextext.length) {
                const mindex = indextext.indexOf(text, lastfound);
                if (mindex === -1) {
                    break;
                }
                const mid = this.findLetridForIndex(index.datamapper, mindex);
                console.log(mid);
                if (mid != undefined) {
                    matchids.add(mid.dataid);
                    lastfound = mid.endindex + 1;
                }
                else {
                    lastfound += 1;
                }
            }
        }
        return Array.from(matchids);
    }
    async fetchLetrs(filterui) {
        var _a;
        const mpassword = InMemoryRepo.getMasterPassword();
        const ret = [];
        if (mpassword === undefined) {
            return ret;
        }
        let fids = [];
        if (filterui.filterids !== undefined && filterui.filterids.length > 0) {
            fids = filterui.filterids;
        }
        if (filterui.contains !== undefined && filterui.contains.trim()) {
            // lets first search 
            if (this.lastactivetextsearch !== undefined && this.lastactivetextsearch.text === filterui.contains) {
                fids = [...fids, ...this.lastactivetextsearch.ids];
            }
            else {
                fids = await this.getLetridsFor(filterui.contains.trim());
                if (fids.length === 0) {
                    fids.push("noop");
                }
                this.lastactivetextsearch = {
                    text: filterui.contains,
                    ids: fids
                };
            }
        }
        else {
            this.lastactivetextsearch = undefined;
        }
        const filter = {
            tagid: filterui.tagid,
            beforeDate: filterui.before,
            afterDate: filterui.after,
            size: filterui.count,
            selectedGroup: (_a = filterui.selectedGroup) !== null && _a !== void 0 ? _a : [],
            filterids: fids
        };
        if (filterui.tagid === 'archive') {
            filter.tagid = "";
            filter.tagids = ["archive", "userarchive"];
        }
        const res = await comm.post(ServerPath.getShareLetr, filter);
        if (res !== undefined) {
            let updateletr = [];
            // lets decrypt this and c reate letrs
            for (const letr of res) {
                let subjectAndMessage = await this.decrytLetr(letr);
                if (subjectAndMessage === undefined || subjectAndMessage.subject === undefined) {
                    continue;
                }
                if (filterui.tagid === CHATID) {
                    subjectAndMessage.subject = letr.title;
                }
                ret.push(this.convertUserLetrToLetr(letr, subjectAndMessage.subject, subjectAndMessage.message, subjectAndMessage.viewbox));
            }
        }
        return ret;
    }
    async apiDecryptLetr(letr) {
        const ret = await this.decrytLetr(letr);
        if (ret != undefined) {
            return this.convertUserLetrToLetr(letr, ret.subject, "", ret.viewbox);
        }
        return undefined;
    }
    async decrytLetr(letr) {
        try {
            const mpassword = InMemoryRepo.getMasterPassword();
            if (mpassword === undefined) {
                return undefined;
            }
            const mbox = await SecStore.decryptPasswordBox(letr.mypasswordbox, mpassword);
            if (mbox === undefined) {
                return undefined;
            }
            if (letr.version !== undefined && letr.version === "v2") {
                return await this.processLetr(letr, mbox.password);
            }
            return await this.decryptLetrUsingPbox(letr, mbox.password);
        }
        catch (e) {
        }
        return undefined;
    }
    async renameLetr(letrid, newname) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const namebox = LetrwingCrypto.secretBox(newname, box);
        const req = {
            letrid: letrid,
            letrnamebox: namebox
        };
        const res = await comm.post(ServerPath.RenameLetr, req);
        if (res === undefined) {
            return false;
        }
        const subb = LetrwingCrypto.decryptSecretBox(res, box);
        if (subb && subb == newname) {
            return true;
        }
        return false;
    }
    async decryptLetrUsingPbox(letr, myPassBox, setpass = true) {
        const ubox = LetrwingCrypto.decryptSecretBox(letr.myaccessbox, myPassBox);
        if (ubox === undefined) {
            return undefined;
        }
        const uref = JSON.parse(ubox);
        const abox = LetrwingCrypto.decryptSecretBox(letr.accessbox, uref.indirectionrefkey);
        if (abox === undefined) {
            return undefined;
        }
        const aref = JSON.parse(abox);
        const dbox = LetrwingCrypto.decryptSecretBox(letr.databox, aref.indirectionrefkey);
        if (dbox === undefined) {
            return undefined;
        }
        return await this.processLetr(letr, dbox);
    }
    async getMiniLetrs() {
        const mpassword = InMemoryRepo.getMasterPassword();
        if (mpassword === undefined) {
            return undefined;
        }
        const ret = [];
        const mletrs = await comm.get(ServerPath.getMiniLetrs);
        if (mletrs !== undefined) {
            for (const ml of mletrs) {
                if (!ml.databox) {
                    continue;
                }
                const mbox = await SecStore.decryptPasswordBox(ml.databox, mpassword);
                if (mbox === undefined) {
                    continue;
                }
                const dbox = mbox.password;
                let subject = LetrwingCrypto.decryptSecretBox(ml.subject, dbox);
                if (subject === undefined) {
                    continue;
                }
                if (ml.title !== "") {
                    subject = ml.title;
                }
                if (ml.searchbox !== undefined) {
                    const sbox = LetrwingCrypto.decryptSecretBox(ml.searchbox, dbox);
                    if (sbox !== undefined) {
                        try {
                            const sres = JSON.parse(sbox);
                            InMemoryRepo.setLetrSearchBox(ml.id, sres);
                        }
                        catch (ex) {
                        }
                    }
                }
                if (ml.origid) {
                    InMemoryRepo.setLetrOid(ml.id, ml.origid);
                }
                InMemoryRepo.setletrPassword(ml.id, dbox);
                ret.push({ id: ml.id, subject: subject });
            }
        }
        return ret;
    }
    async processLetr(letr, dbox) {
        let subject = LetrwingCrypto.decryptSecretBox(letr.subject, dbox);
        if (subject === undefined) {
            return undefined;
        }
        let message = "";
        if (letr.message !== undefined) {
            const lmessage = LetrwingCrypto.decryptSecretBox(letr.message, dbox);
            if (lmessage !== undefined) {
                message = lmessage;
            }
        }
        if (letr.searchbox !== undefined) {
            const sbox = LetrwingCrypto.decryptSecretBox(letr.searchbox, dbox);
            if (sbox !== undefined) {
                try {
                    const sres = JSON.parse(sbox);
                    InMemoryRepo.setLetrSearchBox(letr.id, sres);
                }
                catch (ex) {
                }
            }
        }
        if (letr.orginalletrid) {
            InMemoryRepo.setLetrOid(letr.id, letr.orginalletrid);
        }
        InMemoryRepo.setletrPassword(letr.id, dbox);
        if (letr.title && letr.tags.indexOf(CHATID) !== -1) {
            subject = letr.title;
        }
        if (!letr.viewkeybox) {
            /*  const lvkey = LetrwingCrypto.getNewKeypair();
              const viewkeybox: EncryptBox = LetrwingCrypto.secretBox(JSON.stringify(lvkey), dbox);
  
              const req: UpdateLetrInfoRequest = {
                  letrid: letr.id,
                  viewbox: viewkeybox,
              }
  
              const res = await comm.post<UserLetr, UpdateLetrInfoRequest>(ServerPath.updateletrinfo, req);
              if(res ) {
                  letr.viewkeybox = res.viewkeybox;
              };*/
        }
        let viewst = undefined;
        if (letr.viewkeybox) {
            const ku = LetrwingCrypto.decryptSecretBox(letr.viewkeybox, dbox);
            if (ku) {
                viewst = JSON.parse(ku);
            }
        }
        return {
            subject: subject,
            message: message,
            dbox: dbox,
            viewbox: viewst
        };
    }
    async getLetrDetail(letrid) {
        if (!letrid) {
            return;
        }
        const box = InMemoryRepo.getLetrPassword(letrid);
        const req = { letrid: letrid };
        const res = await comm.post(ServerPath.getLetrDetails, req);
        if (res !== undefined) {
            if (res.letrWatermark && box) {
                // lets decrypt this now
                try {
                    const str = LetrwingCrypto.decryptSecretBox(res.letrWatermark.watermark, box);
                    res.letrWatermark.wkstr = str;
                }
                catch (ex) { }
            }
            res.isExternal = this.checkHasExternal(res.creatorid, res.memberDetails.map((mem) => mem.id));
        }
        return res;
    }
    async sendLetrLink(letrid, linkid, name, email) {
        const req = {
            linkid: linkid,
            email: email.toLowerCase(),
            letrid: letrid,
            name: name
        };
        return await comm.post(ServerPath.sendLetrLinkEmail, req);
    }
    async deleteLetrLink(letrid, linkid) {
        const req = {
            id: linkid,
            letrid: letrid
        };
        const res = await comm.post(ServerPath.deleteletrlink, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async getLetrLinks(letrid) {
        const uref = InMemoryRepo.getLetrPassword(letrid);
        if (!uref) {
            return undefined;
        }
        const res = await comm.post(ServerPath.getLetrLinks, { letrid: letrid });
        const ret = [];
        if (res !== undefined) {
            res.forEach((r) => {
                const re = this.decryptLetrLink(r, letrid);
                if (re !== undefined) {
                    ret.push(re);
                }
            });
        }
        return ret;
    }
    async createLetrLink(letrid, name, allowedit, roleid) {
        const uref = InMemoryRepo.getLetrPassword(letrid);
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        if (!uref) {
            return undefined;
        }
        if (!sbox) {
            return undefined;
        }
        const rsakey = LetrwingCrypto.getNewKeypair();
        const skey = LetrwingCrypto.getSignKeyPair();
        const spubkey = LetrwingCrypto.signMessage(rsakey.publickey, skey);
        const password = LetrwingCrypto.generateNewSecretKey();
        // now lets do something clever here!!
        const ldata = {
            name: name,
            letrpassword: uref,
            rsakey: rsakey,
            signkey: skey
        };
        const lref = {
            password: password,
            name: name
        };
        const lbox = LetrwingCrypto.secretBox(JSON.stringify(ldata), password);
        const lrbox = LetrwingCrypto.secretBox(JSON.stringify(lref), uref);
        const searchbox = LetrwingCrypto.secretBox(JSON.stringify(sbox), uref);
        const llbox = {
            letrid: letrid,
            id: "",
            userid: "",
            databox: lbox,
            letrrefbox: lrbox,
            memberpublickey: spubkey,
            roleid: roleid,
            membersignkey: skey.publickey,
            password: await (LetrwingCrypto.getHash256(await LetrwingCrypto.getHash256(password))),
            allowedit: allowedit,
            name: name,
            searchbox: searchbox
        };
        const res = await comm.post(ServerPath.createLetrLink, llbox);
        if (res !== undefined) {
            return this.decryptLetrLink(res, letrid);
        }
        return undefined;
    }
    decryptLetrLink(letrlink, letrid) {
        const uref = InMemoryRepo.getLetrPassword(letrid);
        if (!uref) {
            return undefined;
        }
        const lbox = letrlink.databox;
        const lrbox = letrlink.letrrefbox;
        const lrstr = LetrwingCrypto.decryptSecretBox(lrbox, uref);
        if (!lrstr) {
            return undefined;
        }
        const lubox = JSON.parse(lrstr);
        const ldstr = LetrwingCrypto.decryptSecretBox(lbox, lubox.password);
        if (!ldstr) {
            return undefined;
        }
        const ldbox = JSON.parse(ldstr);
        const sbox = letrlink.searchbox;
        if (sbox === undefined) {
            return undefined;
        }
        const searchstr = LetrwingCrypto.decryptSecretBox(sbox, ldbox.letrpassword);
        if (!searchstr) {
            return undefined;
        }
        const sdbox = JSON.parse(searchstr);
        const ret = {
            id: letrlink.id,
            letrid: letrid,
            userid: letrlink.userid,
            databox: ldbox,
            letrrefbox: lubox,
            name: lubox.name,
            memberpublickey: letrlink.memberpublickey,
            membersignkey: letrlink.membersignkey,
            url: letrlink.url,
            password: letrlink.password,
            allowedit: letrlink.allowedit,
            searchbox: sdbox,
            roleid: letrlink.roleid
        };
        return ret;
    }
    async addTagsToLetr(letrid, tagids) {
        const req = { letrid: letrid, tagid: tagids };
        const res = await comm.post(ServerPath.AddLetrToTag, req);
        if (res === undefined || !res) {
            return false;
        }
        return true;
    }
    async addTagToLetr(letrid, tagid) {
        return await this.addTagsToLetr(letrid, [tagid]);
    }
    async removeTagToLetr(letrid, tagid) {
        const req = { letrid: letrid, tagid: [tagid] };
        const res = await comm.post(ServerPath.RemoveLetrToTag, req);
        if (res === undefined || !res) {
            return false;
        }
        return true;
    }
    convertUserLetrToLetr(letr, subject, lastmessage = "", vieykey) {
        var _a;
        const letrui = new Letr();
        letrui.id = letr.id;
        letrui.lastmessage = lastmessage;
        letrui.recipients = letr.memberids;
        letrui.author = letr.creatorid;
        letrui.letrMetadata = new LetrMetadata();
        letrui.letrMetadata.id = letr.id;
        letrui.letrMetadata.creationtime = letr.updatetime;
        letrui.letrMetadata.updatetime = letr.updatetime;
        letrui.letrMetadata.tags = letr.tags;
        letrui.letrMetadata.subject = subject;
        letrui.isExternal = this.checkHasExternal(letr.creatorid, letr.memberids);
        letrui.readonly = letr.readonly;
        letrui.hasConnects = letr.hasConnects;
        letrui.numberofconnects = letr.numberofconnects;
        letrui.letrtype = letr.type;
        letrui.authorname = (_a = letr.creatorname) !== null && _a !== void 0 ? _a : '';
        letrui.letrviewkey = vieykey;
        if (letr.title) {
            letrui.letrMetadata.subject = letr.title;
        }
        this.letrsubjectById.set(letr.id, letrui.letrMetadata.subject);
        if (vieykey) {
            this.letrviewkeyboxbyid.set(letr.id, vieykey);
        }
        if (letr.tags.indexOf(CHATID) > -1) {
            this.letrischat.add(letr.id);
        }
        this.letrsubjectById.set(letr.id, letrui.letrMetadata.subject);
        letrui.title = letr.title;
        letrui.origletrid = letr.orginalletrid;
        if (letr.orginalletrid) {
            InMemoryRepo.setLetrOid(letr.id, letr.orginalletrid);
        }
        return letrui;
    }
    checkHasExternal(author, member) {
        const tenant = InMemoryRepo.getTenant();
        if (tenant !== undefined) {
            if (!author.startsWith(tenant + AppConstants.TenantSep)) {
                return true;
            }
            for (const mem of member) {
                if (!mem.startsWith(tenant + AppConstants.TenantSep)) {
                    return true;
                }
            }
        }
        return false;
    }
    async declineLetrCall(letrid) {
        const req = {
            letrid: letrid,
            incoming: false
        };
        const res = await comm.post(ServerPath.DeclineLetrCall, { letrid: letrid, incoming: true });
        return res;
    }
}
export const LStore = new LetrStore();
