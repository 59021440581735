export const GroupTypeNameAndId = "groups";
export var RegisterRequestType;
(function (RegisterRequestType) {
    RegisterRequestType[RegisterRequestType["SSO"] = 0] = "SSO";
    RegisterRequestType[RegisterRequestType["Email"] = 1] = "Email";
    RegisterRequestType[RegisterRequestType["OrgCode"] = 2] = "OrgCode";
    RegisterRequestType[RegisterRequestType["PinVerify"] = 3] = "PinVerify";
})(RegisterRequestType || (RegisterRequestType = {}));
export var RegisterStage;
(function (RegisterStage) {
    RegisterStage[RegisterStage["Processing"] = 0] = "Processing";
    RegisterStage[RegisterStage["NewUserPinVerify"] = 1] = "NewUserPinVerify";
    RegisterStage[RegisterStage["EmailVerify"] = 2] = "EmailVerify";
    RegisterStage[RegisterStage["PinVerify"] = 3] = "PinVerify";
    RegisterStage[RegisterStage["Register"] = 4] = "Register";
    RegisterStage[RegisterStage["OrgEmail"] = 5] = "OrgEmail";
    RegisterStage[RegisterStage["Error"] = 6] = "Error";
    RegisterStage[RegisterStage["NewUser"] = 7] = "NewUser";
    RegisterStage[RegisterStage["Securing"] = 8] = "Securing";
    RegisterStage[RegisterStage["ProgressError"] = 9] = "ProgressError";
    RegisterStage[RegisterStage["RegisterDevice"] = 10] = "RegisterDevice";
    RegisterStage[RegisterStage["ShowOrgCreated"] = 11] = "ShowOrgCreated";
    RegisterStage[RegisterStage["RegsiterUserToOrg"] = 12] = "RegsiterUserToOrg";
    RegisterStage[RegisterStage["RegisterForOrg"] = 13] = "RegisterForOrg";
})(RegisterStage || (RegisterStage = {}));
export var ObjectUpdateType;
(function (ObjectUpdateType) {
    ObjectUpdateType[ObjectUpdateType["Note"] = 0] = "Note";
    ObjectUpdateType[ObjectUpdateType["Comment"] = 1] = "Comment";
})(ObjectUpdateType || (ObjectUpdateType = {}));
export class RegisterUpdate {
    constructor() {
        this.stage = RegisterStage.Register;
        this.invitesOrg = [];
        this.tenantid = "";
        this.errormessgae = "";
        this.sessionid = "";
    }
}
export class GetGroupsResponse {
    constructor() {
        this.error = false;
        this.groups = [];
    }
}
export class GetPendingContactsResponse {
    constructor() {
        this.error = false;
        this.pendingcon = [];
        this._id = "";
    }
}
export class GetNewInvitesContactsResponse {
    constructor() {
        this.error = false;
        this.newinvites = [];
        this._id = "";
    }
}
export var LetrProgress;
(function (LetrProgress) {
    LetrProgress[LetrProgress["Error"] = 0] = "Error";
    LetrProgress[LetrProgress["Success"] = 1] = "Success";
    LetrProgress[LetrProgress["CreatedBox"] = 2] = "CreatedBox";
    LetrProgress[LetrProgress["Sharing"] = 3] = "Sharing";
})(LetrProgress || (LetrProgress = {}));
export var AddNewConnectResponseStatus;
(function (AddNewConnectResponseStatus) {
    AddNewConnectResponseStatus["Added"] = "Added";
    AddNewConnectResponseStatus["ConnectLetrwingUser"] = "ConnectLetrwingUser";
    AddNewConnectResponseStatus["Failed"] = "Failed";
})(AddNewConnectResponseStatus || (AddNewConnectResponseStatus = {}));
export var LetrLoadStatus;
(function (LetrLoadStatus) {
    LetrLoadStatus[LetrLoadStatus["Loading"] = 0] = "Loading";
    LetrLoadStatus[LetrLoadStatus["Loaded"] = 1] = "Loaded";
    LetrLoadStatus[LetrLoadStatus["Initial"] = 2] = "Initial";
})(LetrLoadStatus || (LetrLoadStatus = {}));
