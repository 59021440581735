var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, SimpleGrid, Stack, Tag, TagLabel, TagRightIcon, Text } from "@chakra-ui/react";
import { ScaleLoader } from "react-spinners";
import { ChoiceType, SchemaItemType } from "@@/Schema";
import { Select } from "chakra-react-select";
import { SketchPicker } from "react-color";
import { StoreContext } from "../uistore/PwaRootStore";
import React, { Suspense } from "react";
import { APPEvent } from "@/events/AppEvents";
import { AppColor } from "../const/AppDefaults";
import { BarcodeScanner } from "../UIComponents/BarcodeScanner";
import { BiBarcodeReader } from "@react-icons/all-files/bi/BiBarcodeReader";
import DatePicker from "react-datepicker";
import { FaBarcode } from "@react-icons/all-files/fa/FaBarcode";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { GrFormClose } from "@react-icons/all-files/gr/GrFormClose";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { MdAdd } from "@react-icons/all-files/md/MdAdd";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { MdRefresh } from "@react-icons/all-files/md/MdRefresh";
import { MdSave } from "@react-icons/all-files/md/MdSave";
import { SchemaCustomPanel } from "./PwaRowsView";
import { Quill } from 'react-quill';
import { RemotePopupViewStore } from "../uistore/RemotePopupViewStore";
import { Observer, observer } from "mobx-react";
import { testIsMobile } from "@@/Util";
import { timeout } from "@@/Util";
import { PwaCustomDisplayItemUI } from "../UIComponents/PwaCustomDisplayItem";
const ReactQuill = React.lazy(async () => await import("react-quill"));
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);
const isMobile = () => window.innerWidth < 650;
let PwaEditForm = class PwaEditForm extends React.Component {
    componentDidMount() {
        var _a;
        const rstore = this.context;
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get((_a = this.props.state.activerowid) !== null && _a !== void 0 ? _a : '');
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        if (perms.has("editrow") && !rstore.getPwaStore().customrowjs.trim()) {
            rstore.getPwaStore().setActiveTab('detail');
        }
        if (!perms.has("editrow") && rstore.getPwaStore().customrowjs.trim()) {
            rstore.getPwaStore().setActiveTab('custom');
        }
    }
    renderRow() {
        const rstore = this.context;
        let readonlyfieds = rstore.getPwaStore().readonlyitems;
        let rred = rstore.getPwaStore().rowreadonly.get(this.props.state.activerowid);
        if (rred) {
            readonlyfieds = rred;
        }
        const fields = this.props.schemaitems.slice().filter(fl => {
            return !readonlyfieds.has(fl.id)
                && fl.type !== SchemaItemType.ID && fl.type !== SchemaItemType.SquenceNumber;
        }).sort((it1, it2) => it1.orderindex - it2.orderindex);
        const row = rstore.getPwaStore().rowsMap.get(this.props.state.activerowid);
        if (!row) {
            return React.createElement(Box, null);
        }
        const chscmeacell = new Map();
        const achema = rstore.getPwaStore().getActiveSchema();
        if (achema === undefined) {
            return React.createElement(Box, null);
        }
        const rowentrymap = new Map();
        let filterfields = [];
        let filterparent = false;
        row.entry.forEach((en) => {
            var _a;
            // anything with proper rule shouldnt appear in the detail panel anymore!
            if ((en.readonlyschemalist && en.readonlyschemalist.indexOf(achema.id) !== -1) ||
                rstore.getPwaStore().checkCellHasRule(en, achema)) {
                filterfields.push(en.cellid);
                filterparent = true;
            }
            else {
                const parts = en.cellid.split("----");
                if (!rowentrymap.has(parts[0])) {
                    rowentrymap.set(parts[0], []);
                }
                (_a = rowentrymap.get(parts[0])) === null || _a === void 0 ? void 0 : _a.push(en);
            }
        });
        let flds = fields;
        if (filterfields.length > 0) {
            flds = fields.filter((fld) => filterfields.indexOf(fld.id) === -1 && (!filterparent || !fld.isparent));
        }
        return React.createElement(Box, { key: row.rowid, paddingLeft: '10px', paddingRight: '20px' }, flds.map((item) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const ents = rowentrymap.get(item.id);
            let defaultvalue;
            let dnum = 0;
            let myid = "";
            let urld = undefined;
            let obids = [];
            let vids = new Set();
            let ritemns = [];
            let remoteitemsmap = new Map();
            if (item.type === SchemaItemType.Remote) {
                const ri = item;
                const items = this.props.deps.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                ritemns = items !== null && items !== void 0 ? items : [];
                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                    const rtextra = ri.rtextra;
                    for (const ext of rtextra) {
                        const items = this.props.deps.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                    }
                }
            }
            if (!ents) {
            }
            else {
                const ent = ents[0];
                myid = ent.id;
                if (!ent.decrypted) {
                    rstore.getPwaStore().decryptCell(ent, achema);
                }
                defaultvalue = ent.celldata;
                if (defaultvalue && item.type === SchemaItemType.Date) {
                    dnum = parseInt(defaultvalue);
                }
                if (defaultvalue && item.type === SchemaItemType.URL) {
                    try {
                        urld = JSON.parse(defaultvalue);
                    }
                    catch (ex) { }
                }
                if (ent.linkedids && ent.linkedids.length > 0 && item.type === SchemaItemType.Objects) {
                    const pmap = new Map();
                    (_a = ent.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((ob) => pmap.set("objs-" + ob.id, ob));
                    const smap = new Map();
                    (_b = ent.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sj) => smap.set(sj.id, sj));
                    for (const l of ent.linkedids) {
                        if (l.startsWith("objs-")) {
                            const obj = pmap.get(l);
                            if (obj) {
                                obids.push({
                                    id: l,
                                    type: 'document',
                                    obj: obj
                                });
                                vids.add(l);
                            }
                        }
                        else if (l.startsWith("dch-")) {
                            const parts = l.split("-");
                            if (parts.length > 2) {
                                const did = parts[1];
                                const sid = parts[2];
                                const sh = smap.get(sid);
                                if (sh) {
                                    obids.push({
                                        id: l,
                                        type: 'datasheet',
                                        obj: sh.name,
                                        shs: sh
                                    });
                                    vids.add(l);
                                }
                            }
                        }
                    }
                }
            }
            const shname = (_c = chscmeacell.get(item.id)) !== null && _c !== void 0 ? _c : '';
            return React.createElement(FormControl, { key: item.id, isRequired: item.required, mt: '10px' },
                React.createElement(FormLabel, null, item.name + (shname !== '' ? " (" + shname + ")" : '')),
                item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_d = item.excludedate) !== null && _d !== void 0 ? _d : false, defaultvalue: dnum !== null && dnum !== void 0 ? dnum : 0, onChange: (ts) => {
                        if (ts === dnum) {
                            this.props.state.removePropertiesFromActiveRow(item.id);
                            return;
                        }
                        const id = ts;
                        const entry = {
                            celldata: id + '',
                            cellfindstr: '',
                            cellid: item.id,
                            schemaid: row.entry[0].schemaid,
                            rowindex: row.entry[0].rowindex,
                            rowid: row.entry[0].rowid,
                            id: myid,
                            sortstring: Math.random()
                        };
                        this.props.state.setPropertiesForActiveRow(item.id, entry);
                    }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: item.id, id: item.id, format: (_e = item.format) !== null && _e !== void 0 ? _e : 'dd/MM/yyyy' }),
                item.type === SchemaItemType.Choice &&
                    React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: false, multiselect: (_f = item.multiselect) !== null && _f !== void 0 ? _f : false, schemafieldname: item.name, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                            const entry = {
                                celldata: ch,
                                cellfindstr: '',
                                cellid: item.id,
                                schemaid: row.entry[0].schemaid,
                                rowindex: row.entry[0].rowindex,
                                rowid: row.entry[0].rowid,
                                id: myid,
                                sortstring: Math.random()
                            };
                            this.props.state.setPropertiesForActiveRow(item.id, entry);
                        }, key: item.id + (item.multiselect), id: item.id, list: item.items }),
                item.type === SchemaItemType.Remote &&
                    // <ChoiceInput
                    //     showbarcodescanner={ritemns.length > 0 && !ritemns[0].ischoice ? true : false}
                    //     multiselect={true}
                    //     schemafieldname={item.name}
                    //     defaultvalue={defaultvalue ?? ''}
                    //     onChange={(ch) => {
                    //         if (ch === defaultvalue) {
                    //             this.props.state.removePropertiesFromActiveRow(item.id);
                    //             return;
                    //         }
                    //         const entry: Entry = {
                    //             celldata: ch,
                    //             cellfindstr: '',
                    //             cellid: item.id,
                    //             schemaid: row.entry[0].schemaid,
                    //             rowindex: row.entry[0].rowindex,
                    //             rowid: row.entry[0].rowid,
                    //             id: myid,
                    //             sortstring: Math.random()
                    //         };
                    //         this.props.state.setPropertiesForActiveRow(item.id, entry);
                    //     }}
                    //     key={item.id} id={item.id} list={ritemns.map((item) => {
                    //         const ret: ChoiceDataItem = {
                    //             id: item.id,
                    //             name: item.value,
                    //             type: ChoiceType.Text
                    //         };
                    //         return ret;
                    //     })} />
                    React.createElement(RemoteDisplayItem, { item: item, defaultValue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', remoteitemsmap: remoteitemsmap, rtitems: ritemns, onChange: (ch) => {
                            const entry = {
                                celldata: ch,
                                cellfindstr: '',
                                cellid: item.id,
                                schemaid: row.entry[0].schemaid,
                                rowindex: row.entry[0].rowindex,
                                rowid: row.entry[0].rowid,
                                id: myid,
                                sortstring: Math.random()
                            };
                            this.props.state.setPropertiesForActiveRow(item.id, entry);
                        } }),
                item.type === SchemaItemType.UserChoice &&
                    React.createElement(ChoiceInput, { showbarcodescanner: false, multiselect: (_g = item.multiselect) !== null && _g !== void 0 ? _g : false, schemafieldname: item.name, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                            const entry = {
                                celldata: ch,
                                cellfindstr: '',
                                cellid: item.id,
                                schemaid: row.entry[0].schemaid,
                                rowindex: row.entry[0].rowindex,
                                rowid: row.entry[0].rowid,
                                id: myid,
                                sortstring: Math.random()
                            };
                            this.props.state.setPropertiesForActiveRow(item.id, entry);
                        }, key: item.id, id: item.id, list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                if (u1.type != u2.type) {
                                    if (u1.type === 'manageruser') {
                                        return -1;
                                    }
                                    if (u2.type === 'manageruser') {
                                        return 1;
                                    }
                                    return 0;
                                }
                                return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                            }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))] }),
                item.type === SchemaItemType.Text &&
                    React.createElement(TextInput, { showbarcodeinput: true, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch, pch) => {
                            if (ch.trim() === (defaultvalue === null || defaultvalue === void 0 ? void 0 : defaultvalue.trim())) {
                                this.props.state.removePropertiesFromActiveRow(item.id);
                                return;
                            }
                            const entry = {
                                celldata: ch,
                                cellfindstr: pch,
                                cellid: item.id,
                                schemaid: row.entry[0].schemaid,
                                rowindex: row.entry[0].rowindex,
                                rowid: row.entry[0].rowid,
                                id: myid,
                                sortstring: Math.random()
                            };
                            this.props.state.setPropertiesForActiveRow(item.id, entry);
                        }, key: item.id, id: item.id, richtext: item.richtext }),
                item.type === SchemaItemType.FormInput &&
                    React.createElement(CustomFormInput, { showbarcodeinput: true, state: this.props.state, id: '', defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                            if (ch.trim() === (defaultvalue === null || defaultvalue === void 0 ? void 0 : defaultvalue.trim())) {
                                this.props.state.removePropertiesFromActiveRow(item.id);
                                return;
                            }
                            const entry = {
                                celldata: ch,
                                cellfindstr: ch.toLowerCase(),
                                cellid: item.id,
                                schemaid: row.entry[0].schemaid,
                                rowindex: row.entry[0].rowindex,
                                rowid: row.entry[0].rowid,
                                id: myid,
                                sortstring: Math.random()
                            };
                            this.props.state.setPropertiesForActiveRow(item.id, entry);
                        }, item: item, entryid: myid }),
                item.type === SchemaItemType.URL && React.createElement(URLInput, { state: this.props.state, id: item.id, ent: (_h = ents === null || ents === void 0 ? void 0 : ents[0]) !== null && _h !== void 0 ? _h : {
                        celldata: '',
                        cellfindstr: '',
                        cellid: item.id,
                        schemaid: row.entry[0].schemaid,
                        rowindex: row.entry[0].rowindex,
                        rowid: row.entry[0].rowid,
                        id: myid,
                        sortstring: Math.random()
                    }, item: item }),
                isMobile() && item.type === SchemaItemType.List && React.createElement(ListDisplay, { state: this.props.state, key: item.id, rowid: row.entry[0].rowid, item: item, en: ents !== null && ents !== void 0 ? ents : [] }),
                !isMobile() && item.type === SchemaItemType.List && React.createElement(ListDisplayTable, { state: this.props.state, key: item.id, rowid: row.entry[0].rowid, item: item, en: ents !== null && ents !== void 0 ? ents : [] }),
                item.type === SchemaItemType.Number &&
                    React.createElement(NumberInput, { showbarcodeinput: true, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                            if (ch === defaultvalue) {
                                this.props.state.removePropertiesFromActiveRow(item.id);
                                return;
                            }
                            const nitem = item;
                            // if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                            //     (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                            const entry = {
                                celldata: ch,
                                cellfindstr: ch,
                                cellid: item.id,
                                schemaid: row.entry[0].schemaid,
                                rowindex: row.entry[0].rowindex,
                                rowid: row.entry[0].rowid,
                                id: myid,
                                sortstring: Math.random()
                            };
                            this.props.state.setPropertiesForActiveRow(item.id, entry);
                            // }
                        }, key: item.id, id: item.id, minvalue: item.minvalue, maxvalue: item.maxvalue }),
                item.type === SchemaItemType.Color && React.createElement(ColorInput, { defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                        if (ch === defaultvalue) {
                            this.props.state.removePropertiesFromActiveRow(item.id);
                            return;
                        }
                        const value = ch;
                        const entry = {
                            celldata: value,
                            cellfindstr: value,
                            cellid: item.id,
                            schemaid: row.entry[0].schemaid,
                            rowindex: row.entry[0].rowindex,
                            rowid: row.entry[0].rowid,
                            id: myid,
                            sortstring: Math.random()
                        };
                        this.props.state.setPropertiesForActiveRow(item.id, entry);
                    }, key: item.id, id: item.id }),
                item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { defaultvalue: (defaultvalue && defaultvalue === 'true' ? true : false), onChange: (ch) => {
                        const val = ch ? "true" : 'false';
                        if (defaultvalue && val === defaultvalue) {
                            this.props.state.removePropertiesFromActiveRow(item.id);
                            return;
                        }
                        const entry = {
                            celldata: val,
                            cellfindstr: val,
                            cellid: item.id,
                            schemaid: row.entry[0].schemaid,
                            rowindex: row.entry[0].rowindex,
                            rowid: row.entry[0].rowid,
                            id: myid,
                            sortstring: Math.random()
                        };
                        this.props.state.setPropertiesForActiveRow(item.id, entry);
                    }, key: item.id, id: item.id }),
                item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { obids: obids, onChange: (chns, files) => {
                        if (chns.length === obids.length && files.length === 0) {
                            this.props.state.removePropertiesFromActiveRow(item.id);
                            return;
                        }
                        const entry = {
                            celldata: "",
                            cellfindstr: "",
                            cellid: item.id,
                            schemaid: row.entry[0].schemaid,
                            rowindex: row.entry[0].rowindex,
                            rowid: row.entry[0].rowid,
                            id: myid,
                            sortstring: Math.random(),
                            linkedids: chns.map((ch) => ch.id),
                            objsdec: chns.filter(ch => ch.type === 'document').map((ch) => ch.obj),
                        };
                        this.props.state.setPropertiesForActiveRow(item.id, entry);
                    }, id: item.id, key: item.id }),
                item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { storevale: (ch, ignorerebuilding, ids, fvalue, cellid) => {
                        let myentry = myid;
                        if (cellid) {
                            const ro = rstore.getPwaStore().buildNewRowFromEdits();
                            if (ro) {
                                for (const en of ro.entry) {
                                    if (en.cellid === cellid) {
                                        myentry = en.id;
                                        break;
                                    }
                                }
                            }
                        }
                        const entry = {
                            celldata: ch,
                            cellfindstr: fvalue !== null && fvalue !== void 0 ? fvalue : ch.toLowerCase(),
                            cellid: cellid !== null && cellid !== void 0 ? cellid : item.id,
                            schemaid: row.entry[0].schemaid,
                            rowindex: row.entry[0].rowindex,
                            rowid: row.entry[0].rowid,
                            id: myentry,
                            sortstring: Math.random()
                        };
                        rstore.getPwaStore().pwaeditstore.setPropertiesForActiveRow(cellid !== null && cellid !== void 0 ? cellid : item.id, entry, ignorerebuilding, ids);
                    }, id: item.id, itemid: item.id, item: item }));
        }));
    }
    convertToDataRules() {
        const rstore = this.context;
        let ret = [];
        // const ope = rstore.getPwaStore().rowDataRules.get(rstore.getPwaStore().activeschema + "~~~" + this.props.state.activerowid);
        // if (ope && ope.datarules) {
        //     ret = ope.datarules;
        // }
        return ret;
    }
    convertToRules() {
        var _a, _b;
        const rstore = this.context;
        let ret = [];
        const ope = rstore.getPwaStore().rowRules.get((_b = (_a = rstore.getPwaStore().getActiveSchema()) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '' + "~~~" + this.props.state.activerowid);
        if (ope && ope.data) {
            ret = ope.data;
        }
        return ret;
    }
    render() {
        var _a;
        const rstore = this.context;
        if (!this.props.state.show) {
            return React.createElement(Box, null);
        }
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get((_a = this.props.state.activerowid) !== null && _a !== void 0 ? _a : '');
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        const totalheight = window.innerHeight < 600 ? (window.innerHeight - 100) : 600;
        return React.createElement(Box, { id: "editform", margin: 'auto', marginTop: '10px', w: '100%', h: "100%", bg: 'white', boxShadow: '0 0 5px #b6b6b6' },
            React.createElement(Box, { bg: '#dedfe6', px: 4 },
                React.createElement(Flex, { h: 16, alignItems: 'center', justifyContent: 'space-between' },
                    this.props.state.activerowid !== '' && React.createElement(Flex, { h: '50px', align: 'center', mb: '10px', w: testIsMobile() ? window.innerWidth - 50 : 450, flexWrap: 'wrap' },
                        perms.has("editrow") && React.createElement(Text, { onClick: () => rstore.getPwaStore().setActiveTab('detail'), className: (rstore.getPwaStore().activedetailtab === 'detail') ? 'tab-item-mobile active' : 'tab-item-mobile', _hover: { cursor: 'pointer' }, fontWeight: 'bold' }, "Detail"),
                        rstore.getPwaStore().customrowjs.trim() && React.createElement(Text, { onClick: () => rstore.getPwaStore().setActiveTab('custom'), className: (rstore.getPwaStore().activedetailtab === 'custom') ? 'tab-item-mobile active' : 'tab-item-mobile', _hover: { cursor: 'pointer' }, fontWeight: 'bold', ml: testIsMobile() ? '2px' : '10px' }, 'Custom')),
                    React.createElement(Flex, { alignItems: 'center', justify: 'flex-end', width: '100%' },
                        React.createElement(Stack, { direction: 'row', spacing: 7 },
                            rstore.getPwaStore().activedetailtab === 'detail' && React.createElement(Flex, { flexDir: 'column', alignItems: 'center', justify: 'center', _hover: { cursor: 'pointer' }, onClick: () => {
                                    this.props.onSave();
                                } },
                                React.createElement(Icon, { as: MdSave }),
                                React.createElement(Text, null, "Save")),
                            React.createElement(Icon, { as: MdClose, width: '25px', height: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                                    this.props.state.reset();
                                    this.props.state.setShow(false);
                                    this.props.onCancel();
                                } }))))),
            this.props.state.activerowid !== '' && React.createElement(Box, { w: '100%', h: '100%' },
                React.createElement(Box, { boxShadow: '0 0 5px #b6b6b6', w: '100%', h: testIsMobile() ? window.innerHeight - 250 + "px" : '500px', borderWidth: 1, borderColor: '#dfdfdf' },
                    React.createElement(Box, { m: '10px', width: '95%', height: testIsMobile() ? window.innerHeight - 300 + "px" : '400px', maxH: testIsMobile() ? (window.innerHeight - 300) + "px" : '400px', overflow: 'auto' },
                        rstore.getPwaStore().activedetailtab === 'detail' && this.renderRow(),
                        rstore.getPwaStore().activedetailtab === 'custom' && rstore.getPwaStore().customrowjs && React.createElement(CustomDefinedTab, null)))));
    }
};
PwaEditForm.contextType = StoreContext;
PwaEditForm = __decorate([
    observer
], PwaEditForm);
export { PwaEditForm };
class CustomDefinedTab extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            rebuild: false
        };
    }
    render() {
        const rstore = this.context;
        if (this.state.rebuild) {
            return React.createElement(Box, null,
                React.createElement(Text, null, "Rebuilding"));
        }
        return React.createElement(Box, null,
            React.createElement(SchemaCustomPanel, { viewkey: 'customrowpanel', js: rstore.getPwaStore().customrowjs.trim() }));
    }
}
CustomDefinedTab.contextType = StoreContext;
let CustomDisplayItemCont = class CustomDisplayItemCont extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            refreshing: false
        };
    }
    render() {
        const rstore = this.context;
        let schemaroui = undefined;
        schemaroui = rstore.getPwaStore().buildNewRowFromEdits();
        let id = this.props.id;
        let cid = this.props.id;
        let rindex = "0";
        if (this.props.id.indexOf("----") !== -1) {
            const parts = this.props.id.split("----");
            cid = parts[0] + "----" + parts[1];
            rindex = parts[2];
        }
        if (!schemaroui) {
            return React.createElement(Box, null);
        }
        if (rstore.getPwaStore().loadingrows) {
            return React.createElement(Box, { minH: '90px' });
        }
        if (this.state.refreshing || (rstore.getPwaStore().pwaeditstore.rebuildingcustom && !rstore.getPwaStore().pwaeditstore.ignorerebuildcustomcomponent.has(this.props.id))) {
            return React.createElement(Box, { minH: '90px' });
        }
        if (rstore.getPwaStore().pwaeditstore.forcerebuildcustom.has(this.props.id) ||
            rstore.getPwaStore().pwaeditstore.forcerebuildcustom.has(cid)) {
            return React.createElement(Box, { minH: '90px' });
        }
        return React.createElement(Box, { minH: '90px' },
            React.createElement(Icon, { as: MdRefresh, onClick: async () => {
                    this.setState({ refreshing: true });
                    await timeout(200);
                    this.setState({ refreshing: false });
                }, _hover: { cursor: 'pointer' } }),
            React.createElement(PwaCustomDisplayItemUI, { itemid: this.props.itemid, editmode: true, storevale: (data, ignorerebuilding, ids, fvalue, cellid) => this.props.storevale(data, ignorerebuilding, ids, fvalue, cellid), row: schemaroui, item: this.props.item, subrowindex: this.props.subrowindex }));
    }
};
CustomDisplayItemCont.contextType = StoreContext;
CustomDisplayItemCont = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomDisplayItemCont);
let CustomFormInput = class CustomFormInput extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
        if (this.props.defaultvalue !== undefined && this.props.defaultvalue.trim() !== '') {
            this.props.onChange(this.props.defaultvalue);
        }
    }
    render() {
        const rstore = this.context;
        return React.createElement(Flex, { align: 'center', key: this.props.id },
            React.createElement(InputGroup, { width: '90%', mr: '10px' },
                React.createElement(Input, { ref: (ref) => {
                        this.ref = ref;
                    }, defaultValue: this.props.defaultvalue, type: this.props.item.enablesecret && !this.props.state.showpasswordfor.has(this.props.entryid)
                        ? 'password' : 'text', onChange: (e) => {
                        const ch = e.currentTarget.value;
                        this.props.onChange(ch);
                    } }),
                this.props.item.enablesecret && React.createElement(InputRightElement, { width: "4.5rem" },
                    React.createElement(Button, { h: "1.75rem", size: "sm", onClick: () => {
                            if (this.props.state.showpasswordfor.has(this.props.entryid)) {
                                this.props.state.setHidePasswordFor(this.props.entryid);
                            }
                            else {
                                this.props.state.setShowPasswordFor(this.props.entryid);
                            }
                        } }))),
            this.props.showbarcodeinput && React.createElement(Icon, { onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        if (type === 'bc' && this.ref !== undefined && this.ref !== null) {
                            this.ref.value = data;
                            this.props.onChange(data);
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: FaBarcode, _hover: { cursor: 'pointer' } }));
    }
};
CustomFormInput.contextType = StoreContext;
CustomFormInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomFormInput);
let ListDisplay = class ListDisplay extends React.Component {
    constructor(prop) {
        super(prop);
        this.usedrows = 0;
        this.state = {
            rows: [],
            newrows: [],
            showrows: true,
            addrows: 0
        };
    }
    componentDidMount() {
        const trows = this.arrangeCells();
        this.setState({ rows: trows });
    }
    arrangeCells() {
        // the way to arrange these will be via the 
        var _a;
        this.props.en.sort((en1, en2) => {
            var _a, _b;
            const s1 = (_a = en1.subrowindex) !== null && _a !== void 0 ? _a : -1;
            const s2 = (_b = en2.subrowindex) !== null && _b !== void 0 ? _b : -1;
            if (s1 === -1 && s2 === -1) {
                return 0;
            }
            if (s1 === -1) {
                return 1;
            }
            if (s2 === -1) {
                return -1;
            }
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (this.usedrows < parseInt(parts1[0])) {
                this.usedrows = parseInt(parts1[0]);
            }
            if (this.usedrows < parseInt(parts2[0])) {
                this.usedrows = parseInt(parts2[0]);
            }
            if (parts1[0] === parts2[0]) {
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        let trow = [];
        const rows = [];
        for (const en of this.props.en) {
            const s1 = (_a = en.subrowindex) !== null && _a !== void 0 ? _a : -1;
            if (s1 !== -1) {
                if (trow.indexOf(s1) === -1) {
                    trow.push(s1);
                }
            }
        }
        return trow;
    }
    render() {
        const rstore = this.context;
        const entrymap = new Map();
        const emap = new Map();
        const aschema = rstore.getPwaStore().getActiveSchema();
        if (aschema === undefined) {
            return React.createElement(Box, null);
        }
        for (const ei of this.props.item.items) {
            emap.set(this.props.item.id + "----" + ei.id, ei.item);
        }
        for (const en of this.props.en) {
            if (!en.subrowindex)
                continue;
            entrymap.set(en.cellid + "----" + en.subrowindex, en);
        }
        const lid = this.props.item.id;
        return React.createElement(Box, null,
            React.createElement(Flex, { align: 'center' },
                React.createElement(Input, { width: '30%', type: 'number', onChange: (e) => {
                        const rn = parseInt(e.currentTarget.value);
                        this.setState({ addrows: rn });
                    } }),
                this.state.addrows > 0 && React.createElement(Icon, { as: FaPlus, _hover: { cursor: 'pointer' }, onClick: () => {
                        const rows = this.state.newrows;
                        for (let i = 0; i < this.state.addrows; i++) {
                            const uuid = LetrwingCrypto.getUUID();
                            const rowid = (this.usedrows + this.state.newrows.length + 1) + "----" + uuid;
                            rows.push(rowid);
                        }
                        this.setState({ newrows: rows });
                    } }),
                React.createElement(Button, { ml: '10px', variant: 'unstyled', onClick: () => {
                        this.setState({ showrows: !this.state.showrows });
                    } }, this.state.showrows ? 'Hide Rows' : 'Show Rows'),
                React.createElement(Text, { ml: '10px' }, 'Total Rows ' + (this.state.newrows.length + this.state.rows.length))),
            this.state.showrows && React.createElement(Box, { mt: '4px', ml: '4px' },
                this.state.rows.map((row, index) => {
                    return React.createElement(Box, { key: row },
                        React.createElement(Icon, { "delete-box": 'notebox', as: MdDelete, margin: '5px', onClick: (e) => {
                                const tgt = e.currentTarget.getAttribute("delete-box");
                                if (!tgt)
                                    return;
                                this.props.item.items.forEach((item) => {
                                    const ent = entrymap.get(lid + "----" + item.id + "----" + row);
                                    if (ent) {
                                        this.props.state.addEntryForDeletion(ent);
                                    }
                                });
                                const rows = this.state.rows;
                                rows.splice(index, 1);
                                this.setState({ rows: rows });
                            } }),
                        this.props.item.items.map((litem) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                            const item = litem.item;
                            let ent = (_a = entrymap.get(lid + "----" + item.id + "----" + row)) !== null && _a !== void 0 ? _a : {
                                celldata: '',
                                cellfindstr: '',
                                cellid: lid + "----" + item.id,
                                schemaid: aschema.id,
                                rowindex: -1,
                                rowid: this.props.rowid,
                                subrowindex: row,
                                id: '',
                                sortstring: Math.random(),
                            };
                            let defaultvalue;
                            let dnum = 0;
                            let urld = undefined;
                            let obids = [];
                            let ritemns = [];
                            let remoteitemsmap = new Map();
                            let vids = new Set();
                            if (ent && !ent.decrypted) {
                                rstore.getPwaStore().decryptCell(ent, aschema);
                            }
                            defaultvalue = (_b = ent.celldata) !== null && _b !== void 0 ? _b : '';
                            if (defaultvalue && item.type === SchemaItemType.Date) {
                                dnum = parseInt(defaultvalue);
                            }
                            if (defaultvalue && item.type === SchemaItemType.URL) {
                                try {
                                    urld = JSON.parse(defaultvalue);
                                }
                                catch (ex) { }
                            }
                            if (ent && ent.linkedids && ent.linkedids.length > 0 && item.type === SchemaItemType.Objects) {
                                const pmap = new Map();
                                (_c = ent.objsdec) === null || _c === void 0 ? void 0 : _c.forEach((ob) => pmap.set("objs-" + ob.id, ob));
                                const smap = new Map();
                                (_d = ent.schsdec) === null || _d === void 0 ? void 0 : _d.forEach((sj) => smap.set(sj.id, sj));
                                for (const l of ent.linkedids) {
                                    if (l.startsWith("objs-")) {
                                        const obj = pmap.get(l);
                                        if (obj) {
                                            obids.push({
                                                id: l,
                                                type: 'document',
                                                obj: obj
                                            });
                                            vids.add(l);
                                        }
                                    }
                                    else if (l.startsWith("dch-")) {
                                        const parts = l.split("-");
                                        if (parts.length > 2) {
                                            const did = parts[1];
                                            const sid = parts[2];
                                            const sh = smap.get(sid);
                                            if (sh) {
                                                obids.push({
                                                    id: l,
                                                    type: 'datasheet',
                                                    obj: sh.name,
                                                    shs: sh
                                                });
                                                vids.add(l);
                                            }
                                        }
                                    }
                                }
                            }
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                    const rtextra = ri.rtextra;
                                    for (const ext of rtextra) {
                                        const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                    }
                                }
                            }
                            if (rstore.getPwaStore().checkCellHasRule(ent, aschema)) {
                                return React.createElement("div", { style: { display: 'none' }, key: ent.cellid + "----" + ((_e = ent.subrowindex) !== null && _e !== void 0 ? _e : Math.random() + "") });
                            }
                            return React.createElement(FormControl, { key: ent.cellid + "----" + ((_f = ent.subrowindex) !== null && _f !== void 0 ? _f : Math.random() + ""), isRequired: item.required, mt: '10px' },
                                React.createElement(FormLabel, null, item.name),
                                item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { storevale: (data, ignorerebuilding, ids, fvalue, cellid) => {
                                        var _a, _b;
                                        let myentry = ent;
                                        if (cellid) {
                                            const ro = rstore.getPwaStore().buildNewRowFromEdits();
                                            if (ro) {
                                                for (const en of ro.entry) {
                                                    if (en.cellid === cellid && en.subrowindex === ent.subrowindex) {
                                                        myentry = en;
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                        const id = data;
                                        const entry = {
                                            celldata: id + '',
                                            cellfindstr: fvalue !== null && fvalue !== void 0 ? fvalue : id + "",
                                            cellid: cellid !== null && cellid !== void 0 ? cellid : ent.cellid,
                                            schemaid: ent.schemaid,
                                            rowindex: ent.rowindex,
                                            rowid: ent.rowid,
                                            subrowindex: ent.subrowindex,
                                            id: myentry.id,
                                            sortstring: Math.random()
                                        };
                                        rstore.getPwaStore().pwaeditstore.changedlistitems.add((_a = (cellid !== null && cellid !== void 0 ? cellid : ent.cellid) + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                        rstore.getPwaStore().pwaeditstore.setPropertiesForActiveRow((_b = (cellid !== null && cellid !== void 0 ? cellid : ent.cellid) + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry, ignorerebuilding, ids);
                                    }, itemid: ent.cellid, id: ent.cellid + "----" + ent.subrowindex, item: item, subrowindex: row }),
                                item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_g = item.excludedate) !== null && _g !== void 0 ? _g : false, defaultvalue: dnum !== null && dnum !== void 0 ? dnum : 0, onChange: (ts) => {
                                        var _a, _b, _c, _d;
                                        if (ts === dnum) {
                                            this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                            return;
                                        }
                                        const id = ts;
                                        const entry = {
                                            celldata: id + '',
                                            cellfindstr: '',
                                            cellid: ent.cellid,
                                            schemaid: ent.schemaid,
                                            rowindex: ent.rowindex,
                                            rowid: ent.rowid,
                                            subrowindex: ent.subrowindex,
                                            id: ent.id,
                                            sortstring: Math.random()
                                        };
                                        this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                        this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                    }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: (_h = ent.cellid + "----" + ent.subrowindex) !== null && _h !== void 0 ? _h : '', id: (_j = ent.cellid + "----" + ent.subrowindex) !== null && _j !== void 0 ? _j : '', format: (_k = item.format) !== null && _k !== void 0 ? _k : 'dd/MM/yyyy' }),
                                item.type === SchemaItemType.Choice &&
                                    React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: false, multiselect: (_l = item.multiselect) !== null && _l !== void 0 ? _l : false, schemafieldname: item.name, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                            var _a, _b;
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: '',
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.setPropertiesForActiveRow((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry);
                                        }, key: (_m = ent.cellid + "----" + ent.subrowindex) !== null && _m !== void 0 ? _m : '', id: (_o = ent.cellid + "----" + ent.subrowindex) !== null && _o !== void 0 ? _o : '', list: item.items }),
                                item.type === SchemaItemType.Remote &&
                                    React.createElement(RemoteDisplayItem, { item: item, defaultValue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', remoteitemsmap: remoteitemsmap, rtitems: ritemns, onChange: (ch) => {
                                            var _a, _b;
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: '',
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.setPropertiesForActiveRow((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry);
                                        } }),
                                item.type === SchemaItemType.UserChoice &&
                                    React.createElement(ChoiceInput, { showbarcodescanner: false, multiselect: (_p = item.multiselect) !== null && _p !== void 0 ? _p : false, schemafieldname: item.name, defaultvalue: '', onChange: (ch) => {
                                            var _a, _b;
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: '',
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.setPropertiesForActiveRow((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry);
                                        }, key: (_q = ent.cellid + "----" + ent.subrowindex) !== null && _q !== void 0 ? _q : '', id: (_r = ent.cellid + "----" + ent.subrowindex) !== null && _r !== void 0 ? _r : '', list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                                if (u1.type != u2.type) {
                                                    if (u1.type === 'manageruser') {
                                                        return -1;
                                                    }
                                                    if (u2.type === 'manageruser') {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }
                                                return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                                            }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))] }),
                                item.type === SchemaItemType.Text &&
                                    React.createElement(TextInput, { showbarcodeinput: true, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch, pch) => {
                                            var _a, _b, _c, _d;
                                            if (ch.trim() === (defaultvalue === null || defaultvalue === void 0 ? void 0 : defaultvalue.trim())) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: pch,
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                        }, key: (_s = ent.cellid + "----" + ent.subrowindex) !== null && _s !== void 0 ? _s : '', id: (_t = ent.cellid + "----" + ent.subrowindex) !== null && _t !== void 0 ? _t : '', richtext: item.richtext }),
                                item.type === SchemaItemType.FormInput &&
                                    React.createElement(CustomFormInput, { showbarcodeinput: true, state: this.props.state, id: '', defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                            var _a, _b, _c, _d;
                                            if (ch.trim() === (defaultvalue === null || defaultvalue === void 0 ? void 0 : defaultvalue.trim())) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: ch.toLowerCase(),
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                        }, item: item, entryid: (_u = ent.cellid + "----" + ent.subrowindex) !== null && _u !== void 0 ? _u : '' }),
                                item.type === SchemaItemType.URL &&
                                    React.createElement(URLInput, { state: this.props.state, id: ent.cellid + "----" + ((_v = ent.subrowindex) !== null && _v !== void 0 ? _v : ''), ent: ent, item: item }),
                                item.type === SchemaItemType.Number &&
                                    React.createElement(NumberInput, { showbarcodeinput: true, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                            var _a, _b, _c, _d;
                                            if (ch === defaultvalue) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const nitem = item;
                                            // if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                                            //     (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: ch,
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                            // }
                                        }, key: (_w = ent.cellid + "----" + ent.subrowindex) !== null && _w !== void 0 ? _w : '', id: (_x = ent.cellid + "----" + ent.subrowindex) !== null && _x !== void 0 ? _x : '', minvalue: item.minvalue, maxvalue: item.maxvalue }),
                                item.type === SchemaItemType.Color && React.createElement(ColorInput, { defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                        var _a, _b, _c, _d;
                                        if (ch === defaultvalue) {
                                            this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                            return;
                                        }
                                        const value = ch;
                                        const entry = {
                                            celldata: value,
                                            cellfindstr: value,
                                            cellid: ent.cellid,
                                            schemaid: ent.schemaid,
                                            rowindex: ent.rowindex,
                                            subrowindex: ent.subrowindex,
                                            rowid: ent.rowid,
                                            id: ent.id,
                                            sortstring: Math.random()
                                        };
                                        this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                        this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                    }, key: (_y = ent.cellid + "----" + ent.subrowindex) !== null && _y !== void 0 ? _y : '', id: (_z = ent.cellid + "----" + ent.subrowindex) !== null && _z !== void 0 ? _z : '' }),
                                item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { defaultvalue: (defaultvalue && defaultvalue === 'true' ? true : false), onChange: (ch) => {
                                        var _a, _b, _c, _d;
                                        const val = ch ? "true" : 'false';
                                        if (defaultvalue && val === defaultvalue) {
                                            this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                            return;
                                        }
                                        const entry = {
                                            celldata: val,
                                            cellfindstr: val,
                                            cellid: ent.cellid,
                                            schemaid: ent.schemaid,
                                            rowindex: ent.rowindex,
                                            subrowindex: ent.subrowindex,
                                            rowid: ent.rowid,
                                            id: ent.id,
                                            sortstring: Math.random()
                                        };
                                        this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                        this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                    }, key: (_0 = ent.cellid + "----" + ent.subrowindex) !== null && _0 !== void 0 ? _0 : '', id: (_1 = ent.cellid + "----" + ent.subrowindex) !== null && _1 !== void 0 ? _1 : '' }),
                                item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { obids: obids, onChange: (chns, files) => {
                                        var _a, _b, _c, _d;
                                        if (chns.length === obids.length && files.length === 0) {
                                            this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                            this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                            return;
                                        }
                                        const entry = {
                                            celldata: "",
                                            cellfindstr: "",
                                            cellid: ent.cellid,
                                            schemaid: ent.schemaid,
                                            rowindex: ent.rowindex,
                                            subrowindex: ent.subrowindex,
                                            rowid: ent.rowid,
                                            id: ent.id,
                                            sortstring: Math.random(),
                                            linkedids: chns.map((ch) => ch.id),
                                            objsdec: chns.filter(ch => ch.type === 'document').map((ch) => ch.obj),
                                        };
                                        this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                        this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                    }, id: (_2 = ent.cellid + "----" + ent.subrowindex) !== null && _2 !== void 0 ? _2 : '', key: (_3 = ent.cellid + "----" + ent.subrowindex) !== null && _3 !== void 0 ? _3 : '' }));
                        }));
                }),
                this.state.newrows.map((row, index) => {
                    return React.createElement(Box, { key: row },
                        React.createElement(Icon, { as: MdDelete, onClick: () => {
                                for (const litem of this.props.item.items) {
                                    const item = litem.item;
                                    let cellid = this.props.item.id + "----" + item.id;
                                    let rowid = cellid + "----" + row;
                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                    this.props.state.changedlistitems.delete(rowid);
                                }
                                const nrows = this.state.newrows;
                                nrows.splice(index, 1);
                                this.setState({ newrows: nrows });
                            } }),
                        this.props.item.items.map((litem) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                            const item = litem.item;
                            let cellid = this.props.item.id + "----" + item.id;
                            let rowid = cellid + "----" + row;
                            let ritemns = [];
                            let remoteitemsmap = new Map();
                            if (rstore.getPwaStore().checkRowCellhasRule(this.props.rowid, cellid, aschema)) {
                                return React.createElement(React.Fragment, null);
                            }
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                            }
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                    const rtextra = ri.rtextra;
                                    for (const ext of rtextra) {
                                        const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                    }
                                }
                            }
                            return React.createElement(FormControl, { key: rowid, isRequired: item.required, mt: '10px' },
                                React.createElement(FormLabel, null, item.name),
                                item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { storevale: (data, ignorerebuilding, ids, fvalue, acellid) => {
                                        const id = data;
                                        let myrowid = rowid;
                                        if (acellid) {
                                            myrowid = acellid + "----" + rowid;
                                        }
                                        const entry = {
                                            celldata: id + '',
                                            cellfindstr: fvalue !== null && fvalue !== void 0 ? fvalue : id + "",
                                            cellid: acellid !== null && acellid !== void 0 ? acellid : cellid,
                                            schemaid: aschema.id,
                                            rowindex: -1,
                                            rowid: this.props.rowid,
                                            subrowindex: row,
                                            id: "",
                                            sortstring: Math.random()
                                        };
                                        rstore.getPwaStore().pwaeditstore.changedlistitems.add(myrowid);
                                        rstore.getPwaStore().pwaeditstore.setPropertiesForActiveRow(myrowid, entry, ignorerebuilding, ids);
                                    }, itemid: cellid, id: rowid, item: item, subrowindex: row }),
                                item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_a = item.excludedate) !== null && _a !== void 0 ? _a : false, defaultvalue: item.defaultvalue ? parseInt(item.defaultvalue) : 0, onChange: (ts) => {
                                        if (!ts) {
                                            this.props.state.removePropertiesFromActiveRow(rowid);
                                            this.props.state.changedlistitems.delete(rowid);
                                            return;
                                        }
                                        const id = ts;
                                        const entry = {
                                            celldata: id + '',
                                            cellfindstr: '',
                                            cellid: cellid,
                                            schemaid: aschema.id,
                                            rowindex: -1,
                                            rowid: this.props.rowid,
                                            subrowindex: row,
                                            id: "",
                                            sortstring: Math.random()
                                        };
                                        this.props.state.changedlistitems.add(rowid);
                                        this.props.state.setPropertiesForActiveRow(rowid, entry);
                                    }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: rowid, id: rowid, format: (_b = item.format) !== null && _b !== void 0 ? _b : 'dd/MM/yyyy' }),
                                item.type === SchemaItemType.Choice &&
                                    React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: false, multiselect: (_c = item.multiselect) !== null && _c !== void 0 ? _c : false, schemafieldname: item.name, defaultvalue: (_d = item.defaultvalue) !== null && _d !== void 0 ? _d : '', onChange: (ch) => {
                                            if (!ch) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, key: rowid, id: rowid, list: item.items }),
                                item.type === SchemaItemType.UserChoice &&
                                    React.createElement(ChoiceInput, { showbarcodescanner: false, multiselect: (_e = item.multiselect) !== null && _e !== void 0 ? _e : false, schemafieldname: item.name, defaultvalue: (_f = item.defaultvalue) !== null && _f !== void 0 ? _f : '', onChange: (ch) => {
                                            if (!ch) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, key: rowid, id: rowid, list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                                if (u1.type != u2.type) {
                                                    if (u1.type === 'manageruser') {
                                                        return -1;
                                                    }
                                                    if (u2.type === 'manageruser') {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }
                                                return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                                            }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))] }),
                                item.type === SchemaItemType.Remote &&
                                    React.createElement(RemoteDisplayItem, { item: item, defaultValue: (_g = item.defaultvalue) !== null && _g !== void 0 ? _g : '', remoteitemsmap: remoteitemsmap, rtitems: ritemns, onChange: (ch) => {
                                            if (!ch) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        } }),
                                item.type === SchemaItemType.Text &&
                                    React.createElement(TextInput, { showbarcodeinput: true, defaultvalue: (_h = item.defaultvalue) !== null && _h !== void 0 ? _h : '', onChange: (ch, pch) => {
                                            if (!ch.trim()) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: pch,
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, key: rowid, id: rowid, richtext: item.richtext }),
                                item.type === SchemaItemType.FormInput &&
                                    React.createElement(CustomFormInput, { showbarcodeinput: true, state: this.props.state, id: '', defaultvalue: (_j = item.defaultvalue) !== null && _j !== void 0 ? _j : '', onChange: (ch) => {
                                            if (!ch.trim()) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: ch.toLowerCase(),
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, item: item, entryid: rowid }),
                                item.type === SchemaItemType.URL &&
                                    React.createElement(URLInput, { state: this.props.state, id: rowid, ent: {
                                            celldata: '',
                                            cellfindstr: '',
                                            cellid: cellid,
                                            schemaid: aschema.id,
                                            rowindex: -1,
                                            rowid: this.props.rowid,
                                            subrowindex: row,
                                            id: "",
                                            sortstring: Math.random()
                                        }, item: item }),
                                item.type === SchemaItemType.Number &&
                                    React.createElement(NumberInput, { showbarcodeinput: true, defaultvalue: (_k = item.defaultvalue) !== null && _k !== void 0 ? _k : '', onChange: (ch) => {
                                            if (!ch) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const nitem = item;
                                            // if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                                            //     (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                                            const entry = {
                                                celldata: ch,
                                                cellfindstr: ch,
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                            // }
                                        }, key: rowid, id: rowid, minvalue: item.minvalue, maxvalue: item.maxvalue }),
                                item.type === SchemaItemType.Color && React.createElement(ColorInput, { defaultvalue: (_l = item.defaultvalue) !== null && _l !== void 0 ? _l : '', onChange: (ch) => {
                                        if (!ch) {
                                            this.props.state.removePropertiesFromActiveRow(rowid);
                                            this.props.state.changedlistitems.delete(rowid);
                                            return;
                                        }
                                        const value = ch;
                                        const entry = {
                                            celldata: value,
                                            cellfindstr: value,
                                            cellid: cellid,
                                            schemaid: aschema.id,
                                            rowindex: -1,
                                            rowid: this.props.rowid,
                                            subrowindex: row,
                                            id: "",
                                            sortstring: Math.random()
                                        };
                                        this.props.state.changedlistitems.add(rowid);
                                        this.props.state.setPropertiesForActiveRow(rowid, entry);
                                    }, key: rowid, id: rowid }),
                                item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { defaultvalue: item.defaultvalue && item.defaultvalue === 'true' ? true : false, onChange: (ch) => {
                                        const val = ch ? "true" : 'false';
                                        if (!val) {
                                            this.props.state.removePropertiesFromActiveRow(rowid);
                                            this.props.state.changedlistitems.delete(rowid);
                                            return;
                                        }
                                        const entry = {
                                            celldata: val,
                                            cellfindstr: val,
                                            cellid: cellid,
                                            schemaid: aschema.id,
                                            rowindex: -1,
                                            rowid: this.props.rowid,
                                            subrowindex: row,
                                            id: "",
                                            sortstring: Math.random()
                                        };
                                        this.props.state.changedlistitems.add(rowid);
                                        this.props.state.setPropertiesForActiveRow(rowid, entry);
                                    }, key: rowid, id: rowid }),
                                item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { obids: [], onChange: (chns, files) => {
                                        let hasdiff = true;
                                        if (files.length === 0) {
                                            this.props.state.removePropertiesFromActiveRow(rowid);
                                            this.props.state.changedlistitems.delete(rowid);
                                            return;
                                        }
                                        const entry = {
                                            celldata: "",
                                            cellfindstr: "",
                                            cellid: cellid,
                                            schemaid: aschema.id,
                                            rowindex: -1,
                                            rowid: this.props.rowid,
                                            subrowindex: row,
                                            id: "",
                                            sortstring: Math.random(),
                                            linkedids: [],
                                        };
                                        this.props.state.changedlistitems.add(rowid);
                                        this.props.state.setPropertiesForActiveRow(rowid, entry);
                                    }, id: rowid, key: rowid }));
                        }));
                }),
                React.createElement(Icon, { as: MdAdd, mt: '5px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                        const rows = this.state.newrows;
                        const uuid = LetrwingCrypto.getUUID();
                        const rowid = (this.usedrows + this.state.newrows.length + 1) + "----" + uuid;
                        rows.push(rowid);
                        this.setState({ newrows: rows });
                    } })));
    }
};
ListDisplay.contextType = StoreContext;
ListDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplay);
let ListDisplayTable = class ListDisplayTable extends React.Component {
    constructor(prop) {
        super(prop);
        this.usedrows = 0;
        this.state = {
            rows: [],
            newrows: [],
            showrows: true,
            addrows: 0
        };
    }
    componentDidMount() {
        const trows = this.arrangeCells();
        this.setState({ rows: trows });
    }
    arrangeCells() {
        // the way to arrange these will be via the 
        var _a;
        this.props.en.sort((en1, en2) => {
            var _a, _b;
            const s1 = (_a = en1.subrowindex) !== null && _a !== void 0 ? _a : -1;
            const s2 = (_b = en2.subrowindex) !== null && _b !== void 0 ? _b : -1;
            if (s1 === -1 && s2 === -1) {
                return 0;
            }
            if (s1 === -1) {
                return 1;
            }
            if (s2 === -1) {
                return -1;
            }
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (this.usedrows < parseInt(parts1[0])) {
                this.usedrows = parseInt(parts1[0]);
            }
            if (this.usedrows < parseInt(parts2[0])) {
                this.usedrows = parseInt(parts2[0]);
            }
            if (parts1[0] === parts2[0]) {
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        let trow = [];
        const rows = [];
        for (const en of this.props.en) {
            const s1 = (_a = en.subrowindex) !== null && _a !== void 0 ? _a : -1;
            if (s1 !== -1) {
                if (trow.indexOf(s1) === -1) {
                    trow.push(s1);
                }
            }
        }
        return trow;
    }
    render() {
        const rstore = this.context;
        const entrymap = new Map();
        const emap = new Map();
        const aschema = rstore.getPwaStore().getActiveSchema();
        const listSchemaItems = this.props.item.items.filter((item) => !rstore.getPwaStore().checkSchemaCellHasRule(this.props.item.id + "----" + item.item.id)
            && !rstore.getPwaStore().readonlyitems.has(this.props.item.id + "----" + item.item.id));
        if (aschema === undefined) {
            return React.createElement(Box, null);
        }
        for (const ei of this.props.item.items) {
            emap.set(this.props.item.id + "----" + ei.id, ei.item);
        }
        for (const en of this.props.en) {
            if (!en.subrowindex)
                continue;
            entrymap.set(en.cellid + "----" + en.subrowindex, en);
        }
        const lid = this.props.item.id;
        return React.createElement(Box, null,
            React.createElement(Flex, { align: 'center' },
                React.createElement(Input, { width: '30%', type: 'number', onChange: (e) => {
                        const rn = parseInt(e.currentTarget.value);
                        this.setState({ addrows: rn });
                    } }),
                this.state.addrows > 0 && React.createElement(Icon, { as: FaPlus, _hover: { cursor: 'pointer' }, onClick: () => {
                        const rows = this.state.newrows;
                        for (let i = 0; i < this.state.addrows; i++) {
                            const uuid = LetrwingCrypto.getUUID();
                            const rowid = (this.usedrows + this.state.newrows.length + 1) + "----" + uuid;
                            rows.push(rowid);
                        }
                        this.setState({ newrows: rows });
                    } }),
                React.createElement(Button, { ml: '10px', variant: 'unstyled', onClick: () => {
                        this.setState({ showrows: !this.state.showrows });
                    } }, this.state.showrows ? 'Hide Rows' : 'Show Rows'),
                React.createElement(Text, { ml: '10px' }, 'Total Rows ' + (this.state.newrows.length + this.state.rows.length))),
            this.state.showrows && (this.state.newrows.length + this.state.rows.length) > 0 && React.createElement(Box, { mt: '10px' },
                React.createElement(SimpleGrid, { wordBreak: 'break-word', ml: '30px', columns: listSchemaItems.length + 1, width: listSchemaItems.length > 0 ? listSchemaItems.length * 300 : 600, spacingX: '300px', spacingY: '20px' }, listSchemaItems.map((litem, index) => {
                    const item = litem.item;
                    return React.createElement(Flex, { className: 'border-light', key: item.id + "_header" + index, w: '300px', maxW: '300px', paddingLeft: '10px', paddingRight: '10px' },
                        React.createElement(Text, { fontWeight: 'bold' }, item.name));
                }))),
            this.state.showrows && React.createElement(Box, { mt: '4px', ml: '4px' },
                this.state.rows.map((row, index) => {
                    return React.createElement(Flex, { key: row, align: 'center' },
                        React.createElement(Icon, { "delete-box": 'notebox', as: MdDelete, margin: '5px', color: AppColor.DeleteIconColor, onClick: (e) => {
                                const tgt = e.currentTarget.getAttribute("delete-box");
                                if (!tgt)
                                    return;
                                this.props.item.items.forEach((item) => {
                                    const ent = entrymap.get(lid + "----" + item.id + "----" + row);
                                    if (ent) {
                                        this.props.state.addEntryForDeletion(ent);
                                    }
                                });
                                const rows = this.state.rows;
                                rows.splice(index, 1);
                                this.setState({ rows: rows });
                            } }),
                        React.createElement(SimpleGrid, { className: 'grid', width: listSchemaItems.length > 0 ? listSchemaItems.length * 300 : 600, columns: listSchemaItems.length + 1, spacingX: '300px', spacingY: '20px' }, listSchemaItems.map((litem) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                            const item = litem.item;
                            let ent = (_a = entrymap.get(lid + "----" + item.id + "----" + row)) !== null && _a !== void 0 ? _a : {
                                celldata: '',
                                cellfindstr: '',
                                cellid: lid + "----" + item.id,
                                schemaid: aschema.id,
                                rowindex: -1,
                                rowid: this.props.rowid,
                                subrowindex: row,
                                id: '',
                                sortstring: Math.random(),
                            };
                            let defaultvalue;
                            let dnum = 0;
                            let urld = undefined;
                            let obids = [];
                            let ritemns = [];
                            let remoteitemsmap = new Map();
                            let vids = new Set();
                            if (ent && !ent.decrypted) {
                                rstore.getPwaStore().decryptCell(ent, aschema);
                            }
                            defaultvalue = (_b = ent.celldata) !== null && _b !== void 0 ? _b : '';
                            if (defaultvalue && item.type === SchemaItemType.Date) {
                                dnum = parseInt(defaultvalue);
                            }
                            if (defaultvalue && item.type === SchemaItemType.URL) {
                                try {
                                    urld = JSON.parse(defaultvalue);
                                }
                                catch (ex) { }
                            }
                            if (ent && ent.linkedids && ent.linkedids.length > 0 && item.type === SchemaItemType.Objects) {
                                const pmap = new Map();
                                (_c = ent.objsdec) === null || _c === void 0 ? void 0 : _c.forEach((ob) => pmap.set("objs-" + ob.id, ob));
                                const smap = new Map();
                                (_d = ent.schsdec) === null || _d === void 0 ? void 0 : _d.forEach((sj) => smap.set(sj.id, sj));
                                for (const l of ent.linkedids) {
                                    if (l.startsWith("objs-")) {
                                        const obj = pmap.get(l);
                                        if (obj) {
                                            obids.push({
                                                id: l,
                                                type: 'document',
                                                obj: obj
                                            });
                                            vids.add(l);
                                        }
                                    }
                                    else if (l.startsWith("dch-")) {
                                        const parts = l.split("-");
                                        if (parts.length > 2) {
                                            const did = parts[1];
                                            const sid = parts[2];
                                            const sh = smap.get(sid);
                                            if (sh) {
                                                obids.push({
                                                    id: l,
                                                    type: 'datasheet',
                                                    obj: sh.name,
                                                    shs: sh
                                                });
                                                vids.add(l);
                                            }
                                        }
                                    }
                                }
                            }
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                    const rtextra = ri.rtextra;
                                    for (const ext of rtextra) {
                                        const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                    }
                                }
                            }
                            if (rstore.getPwaStore().checkCellHasRule(ent, aschema)) {
                                return React.createElement("div", { style: { display: 'none' }, key: ent.cellid + "----" + ((_e = ent.subrowindex) !== null && _e !== void 0 ? _e : Math.random() + "") });
                            }
                            return React.createElement(Flex, { key: ent.cellid + "----" + ((_f = ent.subrowindex) !== null && _f !== void 0 ? _f : Math.random() + ""), className: 'border-light', w: '300px', maxW: '300px', paddingLeft: '10px', paddingRight: '10px', align: 'center' },
                                React.createElement(FormControl, { isRequired: item.required, mt: '10px' },
                                    item.type === SchemaItemType.CustomDisplayCol && React.createElement(Observer, null, () => React.createElement(CustomDisplayItemCont, { storevale: (data, ignorerebuilding, ids, fvalue, cellid) => {
                                            var _a, _b, _c;
                                            const id = data;
                                            let myent = ent;
                                            if (cellid) {
                                                const ro = rstore.getPwaStore().buildNeListwRowFromEdits((_a = ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                if (ro) {
                                                    for (const en of ro.entry) {
                                                        if (en.cellid === cellid && en.subrowindex === ent.subrowindex) {
                                                            myent = en;
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                            const entry = {
                                                celldata: id + '',
                                                cellfindstr: fvalue !== null && fvalue !== void 0 ? fvalue : id + "",
                                                cellid: cellid !== null && cellid !== void 0 ? cellid : ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                rowid: ent.rowid,
                                                subrowindex: ent.subrowindex,
                                                id: myent.id,
                                                sortstring: Math.random()
                                            };
                                            rstore.getPwaStore().pwaeditstore.changedlistitems.add((_b = (cellid !== null && cellid !== void 0 ? cellid : ent.cellid) + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                            rstore.getPwaStore().pwaeditstore.setPropertiesForActiveRow((_c = (cellid !== null && cellid !== void 0 ? cellid : ent.cellid) + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '', entry, ignorerebuilding, ids);
                                        }, itemid: ent.cellid, key: ent.cellid + "----" + ent.subrowindex + " " + ent.celldata, id: ent.cellid + "----" + ent.subrowindex, item: item, subrowindex: row })),
                                    item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_g = item.excludedate) !== null && _g !== void 0 ? _g : false, defaultvalue: dnum !== null && dnum !== void 0 ? dnum : 0, onChange: (ts) => {
                                            var _a, _b, _c, _d;
                                            if (ts === dnum) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const id = ts;
                                            const entry = {
                                                celldata: id + '',
                                                cellfindstr: '',
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                rowid: ent.rowid,
                                                subrowindex: ent.subrowindex,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                        }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: (_h = ent.cellid + "----" + ent.subrowindex) !== null && _h !== void 0 ? _h : '', id: (_j = ent.cellid + "----" + ent.subrowindex) !== null && _j !== void 0 ? _j : '', format: (_k = item.format) !== null && _k !== void 0 ? _k : 'dd/MM/yyyy' }),
                                    item.type === SchemaItemType.Choice &&
                                        React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: false, multiselect: (_l = item.multiselect) !== null && _l !== void 0 ? _l : false, schemafieldname: item.name, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                                var _a, _b;
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: '',
                                                    cellid: ent.cellid,
                                                    schemaid: ent.schemaid,
                                                    rowindex: ent.rowindex,
                                                    subrowindex: ent.subrowindex,
                                                    rowid: ent.rowid,
                                                    id: ent.id,
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.setPropertiesForActiveRow((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry);
                                            }, key: (_m = ent.cellid + "----" + ent.subrowindex) !== null && _m !== void 0 ? _m : '', id: (_o = ent.cellid + "----" + ent.subrowindex) !== null && _o !== void 0 ? _o : '', list: item.items }),
                                    item.type === SchemaItemType.Remote &&
                                        React.createElement(RemoteDisplayItem, { item: item, defaultValue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', remoteitemsmap: remoteitemsmap, rtitems: ritemns, onChange: (ch) => {
                                                var _a, _b;
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: '',
                                                    cellid: ent.cellid,
                                                    schemaid: ent.schemaid,
                                                    rowindex: ent.rowindex,
                                                    subrowindex: ent.subrowindex,
                                                    rowid: ent.rowid,
                                                    id: ent.id,
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.setPropertiesForActiveRow((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry);
                                            } }),
                                    item.type === SchemaItemType.UserChoice &&
                                        React.createElement(ChoiceInput, { showbarcodescanner: false, multiselect: (_p = item.multiselect) !== null && _p !== void 0 ? _p : false, schemafieldname: item.name, defaultvalue: '', onChange: (ch) => {
                                                var _a, _b;
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: '',
                                                    cellid: ent.cellid,
                                                    schemaid: ent.schemaid,
                                                    rowindex: ent.rowindex,
                                                    subrowindex: ent.subrowindex,
                                                    rowid: ent.rowid,
                                                    id: ent.id,
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.setPropertiesForActiveRow((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '', entry);
                                            }, key: (_q = ent.cellid + "----" + ent.subrowindex) !== null && _q !== void 0 ? _q : '', id: (_r = ent.cellid + "----" + ent.subrowindex) !== null && _r !== void 0 ? _r : '', list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                                    if (u1.type != u2.type) {
                                                        if (u1.type === 'manageruser') {
                                                            return -1;
                                                        }
                                                        if (u2.type === 'manageruser') {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }
                                                    return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                                                }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))] }),
                                    item.type === SchemaItemType.Text &&
                                        React.createElement(TextInput, { showbarcodeinput: true, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch, pch) => {
                                                var _a, _b, _c, _d;
                                                if (ch.trim() === (defaultvalue === null || defaultvalue === void 0 ? void 0 : defaultvalue.trim())) {
                                                    this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                    this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: pch,
                                                    cellid: ent.cellid,
                                                    schemaid: ent.schemaid,
                                                    rowindex: ent.rowindex,
                                                    subrowindex: ent.subrowindex,
                                                    rowid: ent.rowid,
                                                    id: ent.id,
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                                this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                            }, key: (_s = ent.cellid + "----" + ent.subrowindex) !== null && _s !== void 0 ? _s : '', id: (_t = ent.cellid + "----" + ent.subrowindex) !== null && _t !== void 0 ? _t : '', richtext: item.richtext }),
                                    item.type === SchemaItemType.FormInput &&
                                        React.createElement(CustomFormInput, { showbarcodeinput: true, state: this.props.state, id: '', defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                                var _a, _b, _c, _d;
                                                if (ch.trim() === (defaultvalue === null || defaultvalue === void 0 ? void 0 : defaultvalue.trim())) {
                                                    this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                    this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: ch.toLowerCase(),
                                                    cellid: ent.cellid,
                                                    schemaid: ent.schemaid,
                                                    rowindex: ent.rowindex,
                                                    subrowindex: ent.subrowindex,
                                                    rowid: ent.rowid,
                                                    id: ent.id,
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                                this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                            }, item: item, entryid: (_u = ent.cellid + "----" + ent.subrowindex) !== null && _u !== void 0 ? _u : '' }),
                                    item.type === SchemaItemType.URL &&
                                        React.createElement(URLInput, { state: this.props.state, id: ent.cellid + "----" + ((_v = ent.subrowindex) !== null && _v !== void 0 ? _v : ''), ent: ent, item: item }),
                                    item.type === SchemaItemType.Number &&
                                        React.createElement(NumberInput, { showbarcodeinput: true, defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                                var _a, _b, _c, _d;
                                                if (ch === defaultvalue) {
                                                    this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                    this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                    return;
                                                }
                                                const nitem = item;
                                                // if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                                                //     (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: ch,
                                                    cellid: ent.cellid,
                                                    schemaid: ent.schemaid,
                                                    rowindex: ent.rowindex,
                                                    subrowindex: ent.subrowindex,
                                                    rowid: ent.rowid,
                                                    id: ent.id,
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                                this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                                // }
                                            }, key: (_w = ent.cellid + "----" + ent.subrowindex) !== null && _w !== void 0 ? _w : '', id: (_x = ent.cellid + "----" + ent.subrowindex) !== null && _x !== void 0 ? _x : '', minvalue: item.minvalue, maxvalue: item.maxvalue }),
                                    item.type === SchemaItemType.Color && React.createElement(ColorInput, { defaultvalue: defaultvalue !== null && defaultvalue !== void 0 ? defaultvalue : '', onChange: (ch) => {
                                            var _a, _b, _c, _d;
                                            if (ch === defaultvalue) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const value = ch;
                                            const entry = {
                                                celldata: value,
                                                cellfindstr: value,
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                        }, key: (_y = ent.cellid + "----" + ent.subrowindex) !== null && _y !== void 0 ? _y : '', id: (_z = ent.cellid + "----" + ent.subrowindex) !== null && _z !== void 0 ? _z : '' }),
                                    item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { defaultvalue: (defaultvalue && defaultvalue === 'true' ? true : false), onChange: (ch) => {
                                            var _a, _b, _c, _d;
                                            const val = ch ? "true" : 'false';
                                            if (defaultvalue && val === defaultvalue) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const entry = {
                                                celldata: val,
                                                cellfindstr: val,
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                        }, key: (_0 = ent.cellid + "----" + ent.subrowindex) !== null && _0 !== void 0 ? _0 : '', id: (_1 = ent.cellid + "----" + ent.subrowindex) !== null && _1 !== void 0 ? _1 : '' }),
                                    item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { obids: obids, onChange: (chns, files) => {
                                            var _a, _b, _c, _d;
                                            if (chns.length === obids.length && files.length === 0) {
                                                this.props.state.removePropertiesFromActiveRow((_a = ent.cellid + "----" + ent.subrowindex) !== null && _a !== void 0 ? _a : '');
                                                this.props.state.changedlistitems.delete((_b = ent.cellid + "----" + ent.subrowindex) !== null && _b !== void 0 ? _b : '');
                                                return;
                                            }
                                            const entry = {
                                                celldata: "",
                                                cellfindstr: "",
                                                cellid: ent.cellid,
                                                schemaid: ent.schemaid,
                                                rowindex: ent.rowindex,
                                                subrowindex: ent.subrowindex,
                                                rowid: ent.rowid,
                                                id: ent.id,
                                                sortstring: Math.random(),
                                                linkedids: chns.map((ch) => ch.id),
                                                objsdec: chns.filter(ch => ch.type === 'document').map((ch) => ch.obj),
                                            };
                                            this.props.state.changedlistitems.add((_c = ent.cellid + "----" + ent.subrowindex) !== null && _c !== void 0 ? _c : '');
                                            this.props.state.setPropertiesForActiveRow((_d = ent.cellid + "----" + ent.subrowindex) !== null && _d !== void 0 ? _d : '', entry);
                                        }, id: (_2 = ent.cellid + "----" + ent.subrowindex) !== null && _2 !== void 0 ? _2 : '', key: (_3 = ent.cellid + "----" + ent.subrowindex) !== null && _3 !== void 0 ? _3 : '' })));
                        })));
                }),
                this.state.newrows.map((row, index) => {
                    return React.createElement(Flex, { key: row, align: 'center' },
                        React.createElement(Icon, { as: MdDelete, margin: '5px', color: AppColor.DeleteIconColor, onClick: () => {
                                for (const litem of this.props.item.items) {
                                    const item = litem.item;
                                    let cellid = this.props.item.id + "----" + item.id;
                                    let rowid = cellid + "----" + row;
                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                    this.props.state.changedlistitems.delete(rowid);
                                }
                                const nrows = this.state.newrows;
                                nrows.splice(index, 1);
                                this.setState({ newrows: nrows });
                            } }),
                        React.createElement(SimpleGrid, { className: 'grid', width: listSchemaItems.length > 0 ? listSchemaItems.length * 300 : 600, columns: listSchemaItems.length + 1, spacingX: '300px', spacingY: '20px' }, listSchemaItems.map((litem) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                            const item = litem.item;
                            let cellid = this.props.item.id + "----" + item.id;
                            let rowid = cellid + "----" + row;
                            let ritemns = [];
                            let remoteitemsmap = new Map();
                            if (rstore.getPwaStore().checkRowCellhasRule(this.props.rowid, cellid, aschema)) {
                                return React.createElement(React.Fragment, null);
                            }
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                            }
                            if (item.type === SchemaItemType.Remote) {
                                const ri = item;
                                const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ri.cid);
                                ritemns = items !== null && items !== void 0 ? items : [];
                                remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ri.cid, ritemns);
                                if (ri.rtextra !== undefined && ri.rtextra.length > 0) {
                                    const rtextra = ri.rtextra;
                                    for (const ext of rtextra) {
                                        const items = rstore.getPwaStore().dependentdata.get(ri.id + "---" + ri.sid + "---" + ext.cid);
                                        remoteitemsmap.set(ri.id + "---" + ri.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
                                    }
                                }
                            }
                            return React.createElement(Flex, { key: rowid, className: 'border-light', w: '300px', maxW: '300px', paddingLeft: '10px', paddingRight: '10px', align: 'center' },
                                React.createElement(FormControl, { isRequired: item.required, mt: '10px' },
                                    item.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { storevale: (data, ignorerebuilding, ids, fvalue, cid) => {
                                            const id = data;
                                            let mrowid = rowid;
                                            if (cid) {
                                                mrowid = cid + "----" + row;
                                            }
                                            const entry = {
                                                celldata: id + '',
                                                cellfindstr: fvalue !== null && fvalue !== void 0 ? fvalue : id + "",
                                                cellid: cid !== null && cid !== void 0 ? cid : cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            rstore.getPwaStore().pwaeditstore.changedlistitems.add(mrowid);
                                            rstore.getPwaStore().pwaeditstore.setPropertiesForActiveRow(mrowid, entry, ignorerebuilding, ids);
                                        }, itemid: cellid, id: rowid, item: item, subrowindex: row }),
                                    item.type === SchemaItemType.Date && React.createElement(DateInput, { disabledate: (_a = item.excludedate) !== null && _a !== void 0 ? _a : false, defaultvalue: item.defaultvalue ? parseInt(item.defaultvalue) : 0, onChange: (ts) => {
                                            if (!ts) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const id = ts;
                                            const entry = {
                                                celldata: id + '',
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, showtime: item.inlcudetime, use24hrformat: item.use24hrformat, key: rowid, id: rowid, format: (_b = item.format) !== null && _b !== void 0 ? _b : 'dd/MM/yyyy' }),
                                    item.type === SchemaItemType.Choice &&
                                        React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: false, multiselect: (_c = item.multiselect) !== null && _c !== void 0 ? _c : false, schemafieldname: item.name, defaultvalue: (_d = item.defaultvalue) !== null && _d !== void 0 ? _d : '', onChange: (ch) => {
                                                if (!ch) {
                                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                                    this.props.state.changedlistitems.delete(rowid);
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: '',
                                                    cellid: cellid,
                                                    schemaid: aschema.id,
                                                    rowindex: -1,
                                                    rowid: this.props.rowid,
                                                    subrowindex: row,
                                                    id: "",
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add(rowid);
                                                this.props.state.setPropertiesForActiveRow(rowid, entry);
                                            }, key: rowid, id: rowid, list: item.items }),
                                    item.type === SchemaItemType.UserChoice &&
                                        React.createElement(ChoiceInput, { showbarcodescanner: false, multiselect: (_e = item.multiselect) !== null && _e !== void 0 ? _e : false, schemafieldname: item.name, defaultvalue: (_f = item.defaultvalue) !== null && _f !== void 0 ? _f : '', onChange: (ch) => {
                                                if (!ch) {
                                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                                    this.props.state.changedlistitems.delete(rowid);
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: '',
                                                    cellid: cellid,
                                                    schemaid: aschema.id,
                                                    rowindex: -1,
                                                    rowid: this.props.rowid,
                                                    subrowindex: row,
                                                    id: "",
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add(rowid);
                                                this.props.state.setPropertiesForActiveRow(rowid, entry);
                                            }, key: rowid, id: rowid, list: [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                                                    if (u1.type != u2.type) {
                                                        if (u1.type === 'manageruser') {
                                                            return -1;
                                                        }
                                                        if (u2.type === 'manageruser') {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }
                                                    return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                                                }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))] }),
                                    item.type === SchemaItemType.Remote &&
                                        React.createElement(RemoteDisplayItem, { item: item, defaultValue: (_g = item.defaultvalue) !== null && _g !== void 0 ? _g : '', remoteitemsmap: remoteitemsmap, rtitems: ritemns, onChange: (ch) => {
                                                if (!ch) {
                                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                                    this.props.state.changedlistitems.delete(rowid);
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: '',
                                                    cellid: cellid,
                                                    schemaid: aschema.id,
                                                    rowindex: -1,
                                                    rowid: this.props.rowid,
                                                    subrowindex: row,
                                                    id: "",
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add(rowid);
                                                this.props.state.setPropertiesForActiveRow(rowid, entry);
                                            } }),
                                    item.type === SchemaItemType.Text &&
                                        React.createElement(TextInput, { showbarcodeinput: true, defaultvalue: (_h = item.defaultvalue) !== null && _h !== void 0 ? _h : '', onChange: (ch, pch) => {
                                                if (!ch.trim()) {
                                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                                    this.props.state.changedlistitems.delete(rowid);
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: pch,
                                                    cellid: cellid,
                                                    schemaid: aschema.id,
                                                    rowindex: -1,
                                                    rowid: this.props.rowid,
                                                    subrowindex: row,
                                                    id: "",
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add(rowid);
                                                this.props.state.setPropertiesForActiveRow(rowid, entry);
                                            }, key: rowid, id: rowid, richtext: item.richtext }),
                                    item.type === SchemaItemType.FormInput &&
                                        React.createElement(CustomFormInput, { showbarcodeinput: true, state: this.props.state, id: '', defaultvalue: (_j = item.defaultvalue) !== null && _j !== void 0 ? _j : '', onChange: (ch) => {
                                                if (!ch.trim()) {
                                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                                    this.props.state.changedlistitems.delete(rowid);
                                                    return;
                                                }
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: ch.toLowerCase(),
                                                    cellid: cellid,
                                                    schemaid: aschema.id,
                                                    rowindex: -1,
                                                    rowid: this.props.rowid,
                                                    subrowindex: row,
                                                    id: "",
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add(rowid);
                                                this.props.state.setPropertiesForActiveRow(rowid, entry);
                                            }, item: item, entryid: rowid }),
                                    item.type === SchemaItemType.URL &&
                                        React.createElement(URLInput, { state: this.props.state, id: rowid, ent: {
                                                celldata: '',
                                                cellfindstr: '',
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            }, item: item }),
                                    item.type === SchemaItemType.Number &&
                                        React.createElement(NumberInput, { showbarcodeinput: true, defaultvalue: (_k = item.defaultvalue) !== null && _k !== void 0 ? _k : '', onChange: (ch) => {
                                                if (!ch) {
                                                    this.props.state.removePropertiesFromActiveRow(rowid);
                                                    this.props.state.changedlistitems.delete(rowid);
                                                    return;
                                                }
                                                const nitem = item;
                                                // if ((!ch || nitem.minvalue === undefined || parseFloat(ch) >= nitem.minvalue) &&
                                                //     (!ch || nitem.maxvalue === undefined || parseFloat(ch) <= nitem.maxvalue)) {
                                                const entry = {
                                                    celldata: ch,
                                                    cellfindstr: ch,
                                                    cellid: cellid,
                                                    schemaid: aschema.id,
                                                    rowindex: -1,
                                                    rowid: this.props.rowid,
                                                    subrowindex: row,
                                                    id: "",
                                                    sortstring: Math.random()
                                                };
                                                this.props.state.changedlistitems.add(rowid);
                                                this.props.state.setPropertiesForActiveRow(rowid, entry);
                                                // }
                                            }, key: rowid, id: rowid, minvalue: item.minvalue, maxvalue: item.maxvalue }),
                                    item.type === SchemaItemType.Color && React.createElement(ColorInput, { defaultvalue: (_l = item.defaultvalue) !== null && _l !== void 0 ? _l : '', onChange: (ch) => {
                                            if (!ch) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const value = ch;
                                            const entry = {
                                                celldata: value,
                                                cellfindstr: value,
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, key: rowid, id: rowid }),
                                    item.type === SchemaItemType.Boolean && React.createElement(CheckboxUI, { defaultvalue: item.defaultvalue && item.defaultvalue === 'true' ? true : false, onChange: (ch) => {
                                            const val = ch ? "true" : 'false';
                                            if (!val) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: val,
                                                cellfindstr: val,
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random()
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, key: rowid, id: rowid }),
                                    item.type === SchemaItemType.Objects && React.createElement(ObjectInput, { obids: [], onChange: (chns, files) => {
                                            let hasdiff = true;
                                            if (files.length === 0) {
                                                this.props.state.removePropertiesFromActiveRow(rowid);
                                                this.props.state.changedlistitems.delete(rowid);
                                                return;
                                            }
                                            const entry = {
                                                celldata: "",
                                                cellfindstr: "",
                                                cellid: cellid,
                                                schemaid: aschema.id,
                                                rowindex: -1,
                                                rowid: this.props.rowid,
                                                subrowindex: row,
                                                id: "",
                                                sortstring: Math.random(),
                                                linkedids: [],
                                            };
                                            this.props.state.changedlistitems.add(rowid);
                                            this.props.state.setPropertiesForActiveRow(rowid, entry);
                                        }, id: rowid, key: rowid })));
                        })));
                }),
                React.createElement(Icon, { as: MdAdd, mt: '5px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                        const rows = this.state.newrows;
                        const uuid = LetrwingCrypto.getUUID();
                        const rowid = (this.usedrows + this.state.newrows.length + 1) + "----" + uuid;
                        rows.push(rowid);
                        this.setState({ newrows: rows });
                    } })));
    }
};
ListDisplayTable.contextType = StoreContext;
ListDisplayTable = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplayTable);
let CheckboxUI = class CheckboxUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(Checkbox, { defaultChecked: this.props.defaultvalue, onChange: (e) => {
                    this.props.onChange(e.currentTarget.checked);
                } }));
    }
};
CheckboxUI.contextType = StoreContext;
CheckboxUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CheckboxUI);
let ObjectInput = class ObjectInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.ref = null;
        this.state = {
            activeobjs: prop.obids,
            fileselected: []
        };
    }
    filesSelected() {
        const rstore = this.context;
        if (this.ref && this.ref.files) {
            // lets add them now!!
            let fileselected = this.state.fileselected;
            for (const f of this.ref.files) {
                rstore.getPwaStore().addFile(this.props.id, f);
                fileselected.push(f);
            }
            this.setState({ fileselected: fileselected });
            this.props.onChange(this.state.activeobjs, this.state.fileselected);
            this.ref.value = '';
        }
    }
    render() {
        const rstore = this.context;
        const files = rstore.getPwaStore().objectselectedfiles.get(this.props.id);
        return React.createElement(Box, { mt: '4px', ml: '4px' },
            React.createElement(Flex, null,
                React.createElement("input", { style: { display: 'none' }, ref: (ref) => this.ref = ref, type: 'file', multiple: true, onChange: () => {
                        this.filesSelected();
                    } }),
                React.createElement(Button, { variant: 'solid', w: '100px', onClick: () => {
                        var _a;
                        (_a = this.ref) === null || _a === void 0 ? void 0 : _a.click();
                    } }, "Select Files"),
                files !== undefined && files.length > 0 && React.createElement(Text, { ml: '5px' }, files.length + " files")),
            React.createElement(Box, { mt: '5px', maxH: '200px', overflow: 'auto' }, files != undefined && files.map((f, index) => React.createElement(Flex, { key: f.name + "_" + index, borderBottomColor: 'lightgray', borderBottomWidth: '1px', margin: '5px', w: '100%', align: 'center' },
                React.createElement(Text, { w: '70%', maxW: '70%' }, f.name),
                React.createElement(Flex, { w: '20%', justify: 'flex-end', align: 'center' },
                    React.createElement(Icon, { as: MdClose, _hover: { cursor: 'pointer' }, onClick: () => {
                            rstore.getPwaStore().removeFile(this.props.id, f);
                            let selected = this.state.fileselected;
                            const findex = selected.indexOf(f);
                            if (findex > -1) {
                                selected.splice(findex, 1);
                            }
                            this.setState({ fileselected: selected });
                            this.props.onChange(this.state.activeobjs, this.state.fileselected);
                        } }))))),
            React.createElement(Flex, { flexWrap: 'wrap', maxW: '100%', mt: '10px', maxH: '100px', overflow: 'auto' }, this.state.activeobjs.map((obj) => {
                let name = "";
                if (obj.type === 'document') {
                    name = obj.obj.name;
                }
                else {
                    name = obj.obj + "";
                }
                return React.createElement(Tag, { key: obj.id, margin: '4px' },
                    React.createElement(TagLabel, null, name),
                    React.createElement(TagRightIcon, { as: MdClose, onClick: () => {
                            const nobjs = this.state.activeobjs.filter((ob) => ob.id !== obj.id);
                            this.setState({ activeobjs: nobjs });
                            this.props.onChange(nobjs, this.state.fileselected);
                        } }));
            })));
    }
};
ObjectInput.contextType = StoreContext;
ObjectInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ObjectInput);
let URLInput = class URLInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.urlinputref = null;
        this.urlinputnameref = null;
    }
    render() {
        var _a, _b;
        const rstore = this.context;
        let urld = undefined;
        const ent = this.props.ent;
        const sh = rstore.getPwaStore().getActiveSchema();
        if (sh !== undefined && ent && !ent.decrypted) {
            rstore.getPwaStore().decryptCell(ent, sh);
        }
        let defaultvalue = (_a = ent === null || ent === void 0 ? void 0 : ent.celldata) !== null && _a !== void 0 ? _a : '';
        if (defaultvalue) {
            try {
                urld = JSON.parse(defaultvalue);
            }
            catch (ex) { }
        }
        return React.createElement(Flex, null,
            React.createElement(InputGroup, { width: '70%' },
                React.createElement(Input, { focusBorderColor: AppColor.HeaderIconColor, _placeholder: { fontSize: 12 }, placeholder: 'URL', ref: (ref) => {
                        this.urlinputref = ref;
                    }, defaultValue: urld !== undefined ? urld.url : '', onChange: (e) => {
                        var _a, _b, _c, _d;
                        const ch = e.currentTarget.value;
                        if (urld) {
                            if (urld.url === ch && ((_a = this.urlinputnameref) === null || _a === void 0 ? void 0 : _a.value) === urld.name) {
                                this.props.state.removePropertiesFromActiveRow(this.props.id);
                                return;
                            }
                        }
                        else {
                            if (!ch && !((_b = this.urlinputnameref) === null || _b === void 0 ? void 0 : _b.value)) {
                                this.props.state.removePropertiesFromActiveRow(this.props.id);
                                return;
                            }
                        }
                        const nvalue = {
                            name: (_d = (_c = this.urlinputnameref) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '',
                            url: ch
                        };
                        const entry = {
                            celldata: JSON.stringify(nvalue),
                            cellfindstr: '',
                            cellid: ent.cellid,
                            schemaid: ent.schemaid,
                            rowindex: ent.rowindex,
                            subrowindex: ent.subrowindex,
                            rowid: ent.rowid,
                            id: ent.id,
                            sortstring: Math.random()
                        };
                        this.props.state.setPropertiesForActiveRow(this.props.id, entry);
                    } })),
            React.createElement(Input, { focusBorderColor: AppColor.HeaderIconColor, _placeholder: { fontSize: 12 }, placeholder: 'Name (Optional)', width: '30%', ref: ref => this.urlinputnameref = ref, defaultValue: (_b = urld === null || urld === void 0 ? void 0 : urld.name) !== null && _b !== void 0 ? _b : '', onChange: (e) => {
                    var _a, _b, _c, _d;
                    const ch = e.currentTarget.value;
                    if (urld) {
                        if (urld.name === ch && ((_a = this.urlinputref) === null || _a === void 0 ? void 0 : _a.value) === urld.url) {
                            this.props.state.removePropertiesFromActiveRow(this.props.id);
                            return;
                        }
                    }
                    else {
                        if (!ch && !((_b = this.urlinputref) === null || _b === void 0 ? void 0 : _b.value)) {
                            this.props.state.removePropertiesFromActiveRow(this.props.id);
                            return;
                        }
                    }
                    const nvalue = {
                        name: ch,
                        url: (_d = (_c = this.urlinputref) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : ''
                    };
                    const entry = {
                        celldata: JSON.stringify(nvalue),
                        cellfindstr: '',
                        cellid: ent.cellid,
                        schemaid: ent.schemaid,
                        rowindex: ent.rowindex,
                        subrowindex: ent.subrowindex,
                        rowid: ent.rowid,
                        id: ent.id,
                        sortstring: Math.random()
                    };
                    this.props.state.setPropertiesForActiveRow(this.props.id, entry);
                } }));
    }
};
URLInput.contextType = StoreContext;
URLInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], URLInput);
let TextInput = class TextInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];
        this.modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['clean'],
            ],
        };
    }
    componentDidMount() {
        if (this.props.defaultvalue !== undefined && this.props.defaultvalue.trim() !== '') {
            this.props.onChange(this.props.defaultvalue, this.props.defaultvalue);
        }
    }
    render() {
        return React.createElement(Suspense, { fallback: React.createElement(Flex, { style: { height: '100%' }, justify: 'center', align: 'center' },
                React.createElement(ScaleLoader, { color: 'green' })) },
            React.createElement(Flex, { align: 'center' },
                React.createElement(Box, { w: '90%', mr: '10px' },
                    React.createElement(ReactQuill, { theme: "snow", ref: (ref) => {
                            this.ref = ref;
                        }, defaultValue: this.props.defaultvalue, modules: this.props.richtext ? this.modules : { toolbar: false }, formats: this.props.richtext ? this.formats : [], onChange: (content, delta, source, editor) => {
                            if (this.ref)
                                this.props.onChange(editor.getHTML(), editor.getText());
                        } })),
                this.props.showbarcodeinput && React.createElement(Icon, { onClick: (e) => {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                        const y = (testIsMobile() ? 5 : 20);
                        BarcodeScanner(x, y, ((data, type) => {
                            let obj = "";
                            if (type === 'bc') {
                                this.ref.editor.setText(data);
                                return true;
                            }
                            return false;
                        }));
                    }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } })));
    }
};
TextInput.contextType = StoreContext;
TextInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], TextInput);
let NumberInput = class NumberInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            value: prop.defaultvalue
        };
    }
    componentDidMount() {
        if (this.props.defaultvalue !== undefined && this.props.defaultvalue.trim() !== '') {
            this.props.onChange(this.props.defaultvalue);
        }
    }
    render() {
        return React.createElement(Flex, { align: 'center' },
            React.createElement(Input, { ref: (ref) => {
                    this.ref = ref;
                }, onWheel: (e) => e.currentTarget.blur(), width: '90%', mr: '10px', type: 'number', value: this.state.value, onChange: (e) => {
                    const ch = e.currentTarget.value;
                    this.setState({ value: ch });
                    this.props.onChange(e.currentTarget.value);
                    // if ((!ch || this.props.minvalue === undefined || parseFloat(ch) >= this.props.minvalue) &&
                    //     (!ch || this.props.maxvalue === undefined || parseFloat(ch) <= this.props.maxvalue)) {
                    //     this.setState({ value: ch });
                    //     this.props.onChange(e.currentTarget.value);
                    // }
                } }),
            this.props.showbarcodeinput && React.createElement(Icon, { onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        if (isNaN(Number(data))) {
                            APPEvent.publishApperror("Sorry, barcode is not a valid number");
                            return false;
                        }
                        if (type === 'bc' && this.ref !== undefined && this.ref !== null) {
                            this.ref.value = data;
                            this.setState({ value: data });
                            this.props.onChange(data);
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }));
    }
};
NumberInput.contextType = StoreContext;
NumberInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], NumberInput);
let ColorInput = class ColorInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            color: this.props.defaultvalue
        };
    }
    convertRGBToString(rgb) {
        return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + rgb.a + ")";
    }
    convertRGBStringToRGB(rgb) {
        const clerstr = rgb.replace("rgba(", "").replace(")", "");
        const parts = clerstr.split(",");
        return { r: parseInt(parts[0]), g: parseInt(parts[1]), b: parseInt(parts[2]), a: parseFloat(parts[3]) };
    }
    render() {
        return React.createElement(Box, null,
            this.state.color !== '' && React.createElement(Box, { mb: '5px', w: '20px', h: '20px', borderRadius: '10px', bg: this.state.color }),
            this.state.color === '' && React.createElement(Box, { mb: '5px' },
                React.createElement(Text, null, "Select Color")),
            React.createElement(SketchPicker, { color: this.convertRGBStringToRGB(this.state.color), onChange: ({ rgb }) => {
                    const cl = this.convertRGBToString(rgb);
                    this.props.onChange(cl);
                    this.setState({ color: cl });
                } }));
    }
};
ColorInput.contextType = StoreContext;
ColorInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ColorInput);
let DateInput = class DateInput extends React.Component {
    constructor(prop) {
        super(prop);
        let date;
        if (prop.defaultvalue) {
            if (this.props.defaultvalue) {
                try {
                    date = new Date(this.props.defaultvalue);
                }
                catch (e) {
                }
            }
        }
        this.state = {
            date: date
        };
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(DatePicker, { showTimeInput: this.props.showtime, showTimeSelectOnly: this.props.disabledate, selected: this.state.date, dateFormat: (!this.props.disabledate ? this.props.format : "") + "" + (this.props.showtime ? " HH:mm" : ''), onChange: (date) => {
                    this.props.onChange(date.getTime());
                    this.setState({ date: date });
                } }));
    }
};
DateInput.contextType = StoreContext;
DateInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], DateInput);
let ChoiceInput = class ChoiceInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.supportidmap = new Map();
        this.bidmap = new Map();
        this.state = {
            id: this.props.defaultvalue.split(",")
        };
    }
    componentDidMount() {
        this.supportidmap.clear();
        this.bidmap.clear();
        for (const ci of this.props.list) {
            if (ci.supportid) {
                const parts = ci.supportid.split("---");
                if (parts.length > 1) {
                    const p1 = parts[0];
                    if (p1) {
                        this.bidmap.set(p1, ci.id);
                    }
                    const p2 = parts[1];
                    if (p2) {
                        this.supportidmap.set(p2, ci.id);
                    }
                }
            }
        }
    }
    render() {
        const rstore = this.context;
        if (this.props.multiselect) {
            return React.createElement(Flex, { align: 'center' },
                React.createElement(Box, { width: '90%', mr: '10px' },
                    React.createElement(Select, { isMulti: true, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), selectedOptionStyle: "check", maxMenuHeight: 100, isSearchable: true, value: this.props.list.filter((lt) => {
                            if (this.state.id.indexOf(lt.id) != -1) {
                                return true;
                            }
                            return false;
                        }).map((lt) => ({ label: lt.name, value: lt.id })).sort((s1, s2) => {
                            const sindex = this.state.id.indexOf(s1.value);
                            const s2index = this.state.id.indexOf(s2.value);
                            return sindex - s2index;
                        }), onChange: (e) => {
                            this.setState({ id: e.map((e) => e.value) });
                            this.props.onChange(e.map((lt) => lt.value).join(","));
                        }, closeMenuOnSelect: false })),
                this.props.showbarcodeinputscanner && React.createElement(Icon, { onClick: (e) => {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                        const y = (testIsMobile() ? 5 : 20);
                        BarcodeScanner(x, y, ((data, type) => {
                            let found = false;
                            if (type === 'bc') {
                                for (const ci of this.props.list) {
                                    if (ci.name.toLowerCase() === data.toLowerCase()) {
                                        found = true;
                                        const checkid = ci.id;
                                        const aids = this.state.id;
                                        aids.push(checkid);
                                        this.setState({ id: aids });
                                        this.props.onChange(aids.join(","));
                                    }
                                }
                            }
                            return found;
                        }));
                    }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }),
                this.props.showbarcodescanner && React.createElement(Icon, { ml: '5px', onClick: (e) => {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                        const y = (testIsMobile() ? 5 : 20);
                        BarcodeScanner(x, y, ((data, type) => {
                            var _a, _b;
                            let obj = "";
                            if (type === 'bc') {
                                const checkid = data;
                                obj = (_a = this.bidmap.get(checkid)) !== null && _a !== void 0 ? _a : '';
                            }
                            else {
                                try {
                                    const tr = JSON.parse(data);
                                    const checkid = tr.rowid;
                                    obj = (_b = this.supportidmap.get(checkid)) !== null && _b !== void 0 ? _b : '';
                                }
                                catch (ex) { }
                            }
                            if (obj) {
                                const aids = this.state.id;
                                if (aids.indexOf(obj) === -1) {
                                    aids.push(obj);
                                }
                                this.setState({ id: aids });
                                this.props.onChange(aids.join(","));
                                return true;
                            }
                            return false;
                        }));
                    }, mt: '10px', as: FaBarcode, _hover: { cursor: 'pointer' } }));
        }
        return React.createElement(Flex, { align: 'center' },
            React.createElement(Box, { width: '90%', mr: '10px' },
                React.createElement(Select, { selectedOptionStyle: "check", maxMenuHeight: 100, isSearchable: true, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), value: this.props.list.filter((lt) => {
                        if (this.state.id.indexOf(lt.id) != -1) {
                            return true;
                        }
                        return false;
                    }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                        this.setState({ id: [e.value] });
                        this.props.onChange(e.value);
                    } })),
            this.props.showbarcodeinputscanner && React.createElement(Icon, { onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        let found = false;
                        if (type === 'bc') {
                            for (const ci of this.props.list) {
                                if (ci.name.toLowerCase() === data.toLowerCase()) {
                                    found = true;
                                    const checkid = ci.id;
                                    const aids = this.state.id;
                                    aids.push(checkid);
                                    this.setState({ id: aids });
                                    this.props.onChange(aids.join(","));
                                    break;
                                }
                            }
                        }
                        return found;
                    }));
                }, mt: '10px', as: BiBarcodeReader, _hover: { cursor: 'pointer' } }),
            this.props.showbarcodescanner && React.createElement(Icon, { ml: '5px', onClick: (e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (testIsMobile() ? 5 : window.innerWidth / 2 - 20);
                    const y = (testIsMobile() ? 5 : 20);
                    BarcodeScanner(x, y, ((data, type) => {
                        var _a, _b;
                        let obj = "";
                        if (type === 'bc') {
                            const checkid = data;
                            obj = (_a = this.bidmap.get(checkid)) !== null && _a !== void 0 ? _a : '';
                        }
                        else {
                            try {
                                const tr = JSON.parse(data);
                                const checkid = tr.rowid;
                                obj = (_b = this.supportidmap.get(checkid)) !== null && _b !== void 0 ? _b : '';
                            }
                            catch (ex) { }
                        }
                        if (obj) {
                            this.setState({ id: [obj] });
                            this.props.onChange(obj);
                            return true;
                        }
                        return false;
                    }));
                }, mt: '10px', as: FaBarcode, _hover: { cursor: 'pointer' } }));
    }
};
ChoiceInput.contextType = StoreContext;
ChoiceInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ChoiceInput);
let RemoteDisplayItem = class RemoteDisplayItem extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            id: ''
        };
    }
    render() {
        var _a;
        const rstore = this.context;
        const item = this.props.item;
        const rtmap = this.props.remoteitemsmap;
        const defaultValue = (_a = this.props.defaultValue) !== null && _a !== void 0 ? _a : '';
        const rtitems = this.props.rtitems;
        const ritem = item;
        let rtextra = ritem.rtextra;
        if (rtextra !== undefined) {
            rtextra = rtextra.filter((ext) => !ext.isObject);
        }
        let selecteditemsDisplayItems = [];
        let rtDisplayItems = [];
        if (this.state.id) {
            const id = ritem.id + "---" + ritem.sid + "---" + this.state.id;
            const items = rtmap.get(id);
            if (items !== undefined) {
                const itmap = new Map();
                items.map((it) => {
                    if (it.rowid !== undefined) {
                        itmap.set(it.rowid, it);
                    }
                });
                for (const it of rtitems) {
                    if (it.rowid !== undefined) {
                        const item = itmap.get(it.rowid);
                        if (item !== undefined) {
                            const newitem = {
                                id: it.id,
                                value: item.value,
                                rowid: it.rowid
                            };
                            rtDisplayItems.push(newitem);
                        }
                    }
                }
            }
        }
        else {
            rtDisplayItems = this.props.rtitems;
        }
        const en = rstore.getPwaStore().pwaeditstore.editrowproperty.get(this.props.item.id);
        if (en) {
            const id = ritem.id + "---" + ritem.sid + "---" + ritem.cid;
            const vals = rstore.getPwaStore().dependentdata.get(id);
            if (vals) {
                // now we have this
                const bits = en.celldata.split(",");
                if (bits && bits.length > 0) {
                    selecteditemsDisplayItems = vals.filter((val) => bits.indexOf(val.id) !== -1);
                }
            }
        }
        else if (this.props.defaultValue) {
            const id = ritem.id + "---" + ritem.sid + "---" + ritem.cid;
            const vals = rstore.getPwaStore().dependentdata.get(id);
            if (vals) {
                // now we have this
                const bits = this.props.defaultValue.split(",");
                if (bits && bits.length > 0) {
                    selecteditemsDisplayItems = vals.filter((val) => bits.indexOf(val.id) !== -1);
                }
            }
        }
        return React.createElement(Box, null,
            rtextra !== undefined && rtextra.length > 0 && rtextra.map((val) => React.createElement(Tag, { key: val.cid, margin: '4px', bg: this.state.id === val.cid ? AppColor.SideBarIconColor : '#EDF2F7', _hover: { cursor: 'pointer' }, onClick: () => {
                    this.setState({ id: val.cid });
                } },
                React.createElement(TagLabel, null, val.name),
                React.createElement(TagRightIcon, { key: val.cid + "_icon", _hover: { cursor: 'pointer' }, onClick: (e) => {
                        e.stopPropagation();
                        this.setState({ id: '' });
                    }, as: GrFormClose }))),
            React.createElement(ChoiceInput, { showbarcodeinputscanner: true, showbarcodescanner: rtDisplayItems.length > 0 && !rtDisplayItems[0].ischoice ? true : false, multiselect: ritem.singleselect ? false : true, schemafieldname: item.name, defaultvalue: defaultValue, onChange: (ch) => {
                    this.props.onChange(ch);
                }, key: item.id, id: item.id, list: rtDisplayItems.map((item) => {
                    var _a, _b;
                    const ret = {
                        id: item.id,
                        name: item.value,
                        type: ChoiceType.Text,
                        supportid: ((_a = item.rowbarcode) !== null && _a !== void 0 ? _a : '') + '---' + ((_b = item.rowid) !== null && _b !== void 0 ? _b : ''),
                    };
                    return ret;
                }) }),
            React.createElement(Box, { w: '200px', maxHeight: '200px', overflow: 'auto' }, selecteditemsDisplayItems.map((item, index) => React.createElement(Box, { m: '10px', key: this.props.item.id + "_main_" + "_" + index },
                React.createElement(RemoteView, { key: this.props.item.id + "_" + index, index: index, item: this.props.item, rtItem: item })))));
    }
};
RemoteDisplayItem.contextType = StoreContext;
RemoteDisplayItem = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteDisplayItem);
let RemoteView = class RemoteView extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
    }
    componentDidMount() {
    }
    render() {
        const rstore = this.context;
        const ritem = this.props.item;
        const rtextra = ritem.rtextra;
        if (rtextra === undefined) {
            return React.createElement(Box, null);
        }
        const rowid = this.props.rtItem.rowid;
        if (!rowid) {
            return React.createElement(Box, null);
        }
        let remoteitemsmap = new Map();
        const items = rstore.getPwaStore().dependentdata.get(ritem.id + "---" + ritem.sid + "---" + ritem.cid);
        remoteitemsmap.set(ritem.id + "---" + ritem.sid + "---" + ritem.cid, items !== null && items !== void 0 ? items : []);
        for (const ext of rtextra) {
            const items = rstore.getPwaStore().dependentdata.get(ritem.id + "---" + ritem.sid + "---" + ext.cid);
            remoteitemsmap.set(ritem.id + "---" + ritem.sid + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
        }
        let rtDisplayItems = [];
        for (const ext of rtextra) {
            const id = ritem.id + "---" + ritem.sid + "---" + ext.cid;
            const items = rstore.getPwaStore().dependentdata.get(id);
            if (items !== undefined) {
                for (const it of items) {
                    if (it.rowid === rowid) {
                        let ri = {
                            id: it.id,
                            label: ext.name,
                            rowid: it.rowid,
                            value: it.value,
                            objects: it.objects
                        };
                        rtDisplayItems.push(ri);
                        break;
                    }
                }
            }
        }
        return React.createElement(Box, { ref: (re) => this.myref = re, className: 'remote-thumbnail', pos: 'relative', backgroundColor: '#FFFFFF' },
            React.createElement(Box, { onClick: (e) => {
                    e.stopPropagation();
                    // if (this.props.obj.documentreference === ThumbNailStore.activethumid) return;
                    RemotePopupViewStore.setActiveId(this.props.item.id);
                    if (this.myref) {
                        RemotePopupViewStore.reset();
                        RemotePopupViewStore.setLeftPos(this.myref.getBoundingClientRect().x);
                        RemotePopupViewStore.setTopPos(this.myref.getBoundingClientRect().y);
                        RemotePopupViewStore.setBGColor('#FFFFFF');
                        RemotePopupViewStore.addRtDisplayItems(rtDisplayItems);
                    }
                }, width: this.props.width !== undefined ? this.props.width + "px" : '200px', height: this.props.height !== undefined ? this.props.height + "px" : '100px' }, rtDisplayItems.map((en) => React.createElement(Box, { w: '100%', key: en.id + "_tn" },
                React.createElement(Text, { fontWeight: 'bold', className: 'remote-thumbnail-text' }, en.label),
                React.createElement(Text, { className: 'remote-thumbnail-text' }, en.value)))));
    }
};
RemoteView.contextType = StoreContext;
RemoteView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteView);
