class Paths {
    constructor() {
        this.root = "";
        this.application = this.root + "application"; // new login end point
        this.app = this.root + "app/";
        this.pwa = this.root + "pwa/";
        this.webform = this.root + "webform/";
        this.identityops = "identity/identityop/";
        this.devices = "devices";
        this.group = "group";
        this.bank = "bank";
        this.letr = "letr";
        this.integration = "integration";
        this.tags = "tags";
        this.file = "file";
        this.contact = "contact";
        this.modulejob = "modulejob";
        this.admin = "admin";
        this.admindetails = "adminlogindetails";
        this.connects = "connects";
        this.userpofile = "userpofile";
        this.appadmin = "appadmin";
        this.draft = "draft";
        this.search = "search";
        this.note = "note";
        this.tab = "tab";
        this.schema = "schema";
        this.invite = "invite";
        this.userdetail = "userdetail";
        this.message = "message";
        this.tenantmetadata = "tenant/metadata";
        this.changes = "changes";
        this.masterkey = "masterkey";
        this.organisation = "org";
        this.unread = "unread";
        this.organisationcreate = "orgcreation";
        this.qrCode = "QRCode";
        this.adminLoginDetails = this.admin + "/" + this.admindetails + "/deets";
        this.emailIdentify = "identity/emailidentity";
        this.pinVerify = "identity/authcodevalid";
        this.registerinfo = this.identityops + "info";
        this.saveRegisterInfo = this.identityops + "registerUser";
        this.registerDevice = this.identityops + "registerDevice";
        this.deviceLogin = this.app + "login";
        this.index = this.app + "index";
        this.addMasterKey = this.app + this.masterkey + '/addMasterKey';
        this.getMasterKey = this.app + this.masterkey + '/getMasterKey';
        this.hasMasterKey = this.app + this.masterkey + '/hasMasterKey';
        this.getMiniLocker = this.app + this.bank + "/getMyLocker";
        this.addUserIndexbox = this.app + this.bank + "/updateUserIndex";
        this.getOrgContactNewBox = this.app + this.bank + "/getOrgContactNewBox";
        this.getOrgContactBox = this.app + this.bank + "/getOrgContactBox";
        this.updateOrgContactBox = this.app + this.bank + "/updateOrgContactBox";
        this.addExternalContactCommBox = this.app + this.bank + "/addExternalContactCommBox";
        this.deleteExternalContact = this.app + this.contact + "/deleteContact";
        this.blockMyContact = this.app + this.group + "/blockMyContact";
        this.getOrgAppUsers = this.app + this.group + "/getAppUsers";
        this.addtab = this.app + this.tab + "/addTab";
        this.gettab = this.app + this.tab + "/getTabs";
        this.deletetab = this.app + this.tab + "/deleteTab";
        this.updatetab = this.app + this.tab + "/updateTab";
        this.getAllTabs = this.app + this.tab + "/getAllTabs";
        this.unreadRecentLogs = this.app + this.unread + "/getRecentLogs";
        this.recentLogs = this.app + this.unread + "/getLogsSince";
        this.getLetrHistory = this.app + this.unread + "/getLetrHistory";
        this.getAllAssignedItems = this.app + this.unread + "/getAllAssignedTasks";
        this.getUserAssignedTask = this.app + this.unread + "/getUserAssignedTask";
        this.getUnreadTasks = this.app + this.unread + "/getAllUnseenTasks";
        this.getIntegrationKey = this.app + this.integration + "/getIntegrationKey";
        this.addIntegration = this.app + this.integration + "/addIntegration";
        this.updateIntegration = this.app + this.integration + "/updateIntegration";
        this.getIntegrations = this.app + this.integration + "/getIntegrations";
        this.deleteIntegration = this.app + this.integration + "/deleteIntegration";
        this.logout = this.app + "logout";
        this.MYDevices = this.app + this.devices + "/mydevices";
        this.DELETEDEVICE = this.app + this.devices + "/deletedevices";
        this.HasNotToken = this.app + this.devices + "/deviceNotRegistered";
        this.RegNotToken = this.app + this.devices + "/deviceNotTokenReg";
        this.Groups = this.app + this.group + "/getAll";
        this.AddGroups = this.app + this.group + "/addNewGroup";
        this.getManagedGroups = this.app + this.group + "/getAllManagedContact";
        this.getAllManagedContactNewLetrs = this.app + this.letr + "/getAllManagedContactNewLetrs";
        this.getManagedContactLetrs = this.app + this.letr + "/getManagedContactLetrs";
        this.activateManagedLetr = this.app + this.letr + "/activateManagedContact";
        this.StartNewLetr = this.app + this.letr + "/newletr";
        this.AddIndex = this.app + this.letr + "/addUserIndex";
        this.MessageReadReceipt = this.app + this.message + "/getMessageReadTime";
        this.GetLetrIndex = this.app + this.letr + "/getUserLetrIndex";
        this.StartNewChat = this.app + this.letr + "/newchat";
        this.AddProjectObject = this.app + this.letr + "/addProjectObject";
        this.GetProjectObjects = this.app + this.letr + "/getProjectObjects";
        this.GetProjectObject = this.app + this.letr + "/getProjectObject";
        this.updateProjectObject = this.app + this.letr + "/updateProjectObject";
        this.moveProjectObject = this.app + this.letr + "/moveProjectObject";
        this.CopyProjectObject = this.app + this.letr + "/copyProjectObject";
        this.EmptyTrash = this.app + this.letr + "/emptyTrash";
        this.AddAclGroup = this.app + this.letr + "/addaccessgroup";
        this.UpdateAclGroup = this.app + this.letr + "/updateaccessgroup";
        this.DeleteAclGroup = this.app + this.letr + "/deleteAccessGroup";
        this.UpdateProjectObjectAcl = this.app + this.letr + "/applyAclToObject";
        this.GetAclGroups = this.app + this.letr + "/getAccessGroups";
        this.ApplyAclToProject = this.app + this.letr + "/applyAclToObject";
        this.isUserAdmin = this.app + this.devices + "/isUserAdmin";
        this.UpdateLetrMetadataForGroup = this.app + this.letr + "/updateLetrMetadata";
        this.GetLetrsForTags = this.app + this.letr + "/getLetrsForTags";
        this.GetLetrDetails = this.app + this.letr + "/getLetrDetail";
        this.GetLetrsSince = this.app + this.letr + "/letrsSince";
        this.GetNewLetrs = this.app + this.letr + "/getBeginLetrs";
        this.MarkActiveLetrs = this.app + this.letr + "/markLetrActive";
        this.RenameLetr = this.app + this.letr + "/renameLetr";
        this.unreadLetrMetadata = this.app + this.letr + "/getUnreadLetrMetadata";
        this.AddLetrToTag = this.app + this.letr + "/addLetrToTag";
        this.RemoveLetrToTag = this.app + this.letr + "/removeLetrToTag";
        this.addMiscContact = this.app + this.group + "/addMiscContact";
        this.getMiscContacts = this.app + this.group + "/getMiscContacts";
        this.AddPasswordBank = this.app + this.bank + "/addMyPasswordBox";
        this.AddCommBank = this.app + this.bank + "/addCommBox";
        this.AddShareBox = this.app + this.bank + "/storeShareBox";
        this.AddShareBoxes = this.app + this.bank + "/storeShareBoxes";
        this.getMyAdminLocker = this.app + this.bank + "/getMyAdminLocker";
        this.getAdminCryptBoxes = this.app + this.bank + "/getAdminCryptBxes";
        this.getTags = this.app + this.tags + "/getAll";
        this.saveTags = this.app + this.tags + "/upsert";
        this.saveMessage = this.app + this.message + "/save";
        this.getMessages = this.app + this.message + "/getMessages";
        this.getNMessagesBefore = this.app + this.message + "/getNMessagesBefore";
        this.getAllImagesMessages = this.app + this.message + '/getImagesMessage';
        this.getDocumentMessage = this.app + this.message + '/getDocumentMessage';
        this.newMessages = this.app + this.message + "/newmessages";
        this.deliverMessages = this.app + this.message + "/deliverMessage";
        this.activateMessage = this.app + this.message + "/activateNewMessage";
        this.addFileHeader = this.app + this.file + "/addFileHeader";
        this.addFileChunks = this.app + this.file + "/addFileChunks";
        this.addFileChunk = this.app + this.file + "/addFileChunk";
        this.getActiveFilesSince = this.app + this.file + "/getActiveFilesSince";
        this.getLatestActiveFiles = this.app + this.file + "/getLatestActiveFiles";
        this.getFileInfo = this.app + this.file + "/download/info";
        this.getFileViewerContent = this.app + this.file + "/download/pdffileinfo";
        this.getPwaFileViewerContent = this.pwa + "/pdffileinfo";
        this.getSchemaViews = this.pwa + "/getSchemaViews";
        this.getPwaAppUsers = this.pwa + "/getPwaAppUsers";
        this.getPwaFileMeta = this.pwa + "/getFileMeta";
        this.getPwaProjectObject = this.pwa + "/getProjectObject";
        this.getFileChunks = this.app + this.file + "/download/fileChunk";
        this.getFileProcessStatus = this.app + this.file + "/download/fileprocessstatus";
        this.getUserDetails = this.app + this.userdetail + "/info";
        this.addUserImage = this.app + this.userdetail + "/addProfileImageDetail";
        this.getUserImage = this.app + this.userdetail + "/getUserProfileImage";
        this.downloadUserImage = this.app + this.userdetail + "/userimage/download/";
        this.getUserImageTime = this.app + this.userdetail + "/getUserProfileImageTime";
        this.getMyContactDetails = this.app + this.userdetail + "/getUserDetails";
        this.getchanges = this.app + this.changes + '/changes';
        this.inviteUser = this.app + this.invite + '/newinvitation';
        this.pendingUser = this.app + this.invite + '/pendinginvitations';
        this.invites = this.app + this.invite + '/invites';
        this.publicContactInvites = this.app + this.invite + '/newinvitespubliccontact';
        this.deleteInvitation = this.app + this.invite + '/deleteinvitation';
        this.acceptinvite = this.app + this.invite + '/acceptinvite';
        this.deleteinvite = this.app + this.invite + '/deleteinvite';
        this.websocketLogin = this.app + "websocketlogin";
        this.getRoomSession = this.app + this.letr + "/startLetrCall";
        this.getNodeKeys = this.app + this.schema + "/getTriggerKey";
        this.addTrigger = this.app + this.schema + "/addTriggerBox";
        this.getTriggerBox = this.app + this.schema + "/getTriggerBox";
        this.addDatasheet = this.app + this.schema + "/addDatasheet";
        this.attachbarcode = this.app + this.schema + "/attachBarcode";
        this.updateDatasheet = this.app + this.schema + "/updateDatasheet";
        this.deleteDatasheet = this.app + this.schema + "/deleteDatasheet";
        this.getDatasheet = this.app + this.schema + "/getDatasheets";
        this.addWebView = this.app + this.schema + "/addSchemaView";
        this.updateWebView = this.app + this.schema + "/updateSchemaView";
        this.deleteWebView = this.app + this.schema + "/deleteSchemaView";
        this.getWebViews = this.app + this.schema + "/getSchemaViews";
        this.addAppUserGeneralKey = this.app + this.appadmin + "/addAppUserGeneralKey";
        this.getAppUsergeneralKey = this.app + this.group + "/getAppUsergeneralKey";
        this.addBookmark = this.app + this.file + "/addBookmark";
        this.getBookmark = this.app + this.file + "/getBookmarks";
        this.deleteBookmark = this.app + this.file + "/deleteBookmark";
        this.addField = this.app + this.letr + "/addFieldsForDocument";
        this.getField = this.app + this.letr + "/getFieldsForDocuments";
        this.deleteField = this.app + this.letr + "/deleteFieldsForDocument";
        this.addFieldToObject = this.app + this.letr + "/addFieldForObject";
        this.searchmetadata = this.app + this.search + "/searchMetadata";
        this.getRoles = this.app + this.letr + "/getLetrRoles";
        this.addRole = this.app + this.letr + "/addLetrRole";
        this.updateRole = this.app + this.letr + "/updateLetrRole";
        this.deleteRole = this.app + this.letr + "/deleteLetrRole";
        this.addSchema = this.app + this.schema + "/addSchema";
        this.updateSchema = this.app + this.schema + "/updateSchema";
        this.deleteSchema = this.app + this.schema + "/deleteSchema";
        this.getSchemas = this.app + this.schema + "/getSchemas";
        this.addrow = this.app + this.schema + "/addRow";
        this.addrows = this.app + this.schema + "/addRows";
        this.updaterow = this.app + this.schema + "/updateRow";
        this.updaterows = this.app + this.schema + "/updateRows";
        this.updatepkrow = this.app + this.schema + "/updatePKRow";
        this.getRows = this.app + this.schema + "/getRows";
        this.getRemoteRows = this.app + this.schema + "/getRemoteRows";
        this.getRowsIncremental = this.app + this.schema + "/getRowsIncremental";
        this.addRowsToChild = this.app + this.schema + "/addRowToChild";
        this.removeRowsToChild = this.app + this.schema + "/removeRowToChild";
        this.deleteRow = this.app + this.schema + "/deleteRow";
        this.deleteRows = this.app + this.schema + "/deleteRows";
        this.duplicateRows = this.app + this.schema + "/duplicateRows";
        this.getLetrProjectNames = this.app + this.letr + "/getLetrProjectNames";
        this.checkEmailOrg = this.organisation + "/checkEmailOrg";
        this.verifyEmailAndGetResetBox = this.organisation + "/verifyEmailAndGetResetBox";
        this.checkOrgName = this.organisation + "/checkOrgName";
        this.orgVerifyPin = this.organisation + "/verifyEmail";
        this.registerOrg = this.organisationcreate + "/registerNewOrg";
        this.resetUserPassword = this.organisationcreate + "/resetUserPassword";
        this.acceptInviteToken = this.organisationcreate + "/getIdentityToken";
        this.getMatrix = this.tenantmetadata + "/getTenantMatrix";
        this.getShareLetr = this.app + this.letr + "/getshareletr";
        this.acceptQRCode = this.app + this.qrCode + "/connect";
        this.memberCommBundles = this.app + this.group + "/getmemberCommBundle";
        this.createShareLetr = this.app + this.letr + "/shareletr";
        this.getNMessages = this.app + this.message + "/getNMessages";
        this.editMessage = this.app + this.message + "/editMessage";
        this.deleteMessage = this.app + this.message + "/deleteMessage";
        this.replyMessage = this.app + this.message + "/replyMessage";
        this.getNReplyMessages = this.app + this.message + "/getNReplyMessages";
        this.getLetrDetails = this.app + this.letr + "/getLetrDetail";
        this.addMemberToLetr = this.app + this.letr + "/addMemberToLetr";
        this.removeMemberToLetr = this.app + this.letr + "/removeMemberToLetr";
        this.updateMemeber = this.app + this.letr + "/updateLetrMember";
        this.newVLetrs = this.app + this.letr + "/getNewVLetr";
        this.activateVLetr = this.app + this.letr + "/activateLetr";
        this.allreplies = this.app + this.message + "/getNThreads";
        this.myManagedContacts = this.app + this.contact + "/myPublicContacts";
        this.myNewManagedContacts = this.app + this.contact + "/myNewPublicContacts";
        this.activateNewManagedContacts = this.app + this.contact + "/activatePublicContacts";
        this.getOrgContacts = this.app + this.contact + "/getOrgContacts";
        this.getmodpubkey = this.app + this.modulejob + "/getmodpubkey";
        this.getorgmodpubkey = this.app + this.modulejob + "/getorgmodpubkey";
        this.addnewjob = this.app + this.modulejob + "/addnewjob";
        this.resumitjob = this.app + this.modulejob + "/resumitjob";
        this.getConnects = this.app + this.connects + "/getconnects";
        this.addConnects = this.app + this.connects + "/addconnect";
        this.deleteConnect = this.app + this.connects + "/deleteconnect";
        this.addConnectHintPassword = this.app + this.letr + "/storeConnectAccessPassword";
        this.letrConnectDetail = this.app + this.letr + "/letrConnectDetail";
        this.getConnectCommBundle = this.app + this.letr + "/getConnectCommBundle";
        this.storeMyDevicePasswordBox = this.app + this.bank + "/addMyDeviceLoginPasswordBox";
        this.getMyDevicePasswordBox = this.app + this.bank + "/getMyDeviceLoginPasswordBox";
        this.addNewRegisterDeviceBox = this.app + this.bank + "/addNewRegisterDeviceBox";
        this.loggedinuseremailverification = this.app + this.bank + "/emailidentity";
        this.validateauthcode = this.app + this.bank + "/authcodevalidate";
        this.lockEdit = this.app + this.draft + "/lockEdit";
        this.getDraftContent = this.app + this.draft + "/getDraftContent";
        this.verifyAndGetLoginAnotherDevice = this.organisation + "/verifyAndGetLoginAnotherDevice";
        this.getIdentityTokenForAnotherDeviceLogin = this.organisation + "/getIdentityTokenForAnotherDeviceLogin";
        this.getOrgverification = this.app + this.devices + "/getOrgverification";
        this.getUserPolicyAcceptace = this.app + this.devices + "/getUserPolicyAcceptace";
        this.downloadedRecoveryCode = this.app + this.devices + "/downloadedRecoveryCode";
        this.getMyRecoveryBox = this.app + this.devices + "/getMyRecoveryBox";
        this.getAllPolicies = this.app + this.devices + "/getAllPolicies";
        this.acceptPolicy = this.app + this.devices + "/acceptPolicy";
        this.recoveryDownloaded = this.app + this.devices + "/recoveryDownloaded";
        this.getPasteFormmated = this.app + this.draft + "/getPasteFormmated";
        this.addDraftChunk = this.app + this.draft + "/addDraftChunk";
        this.addDraftChunkMeta = this.app + this.draft + "/addDraftChunkMeta";
        this.getDraftChunk = this.app + this.draft + "/getDraftChunk";
        this.getDraftChunkMeta = this.app + this.draft + "/getDraftChunkMeta";
        this.createNewDraft = this.app + this.draft + "/createNewDraft";
        this.updateDraftContent = this.app + this.draft + "/updateDraftContent";
        this.getDraftdetail = this.app + this.draft + "/getDraftdetails";
        this.updateLatestDraftContent = this.app + this.draft + "/updateLatestDraftContent";
        this.updateLock = this.app + this.draft + "/updateLock";
        this.draftexport = this.app + this.draft + "/addExportJobDetail";
        this.draftExportJobStatus = this.app + this.draft + "/exportJobStatus";
        this.draftUpdate = this.app + this.draft + "/addDraftUpdate";
        this.getDraftUpdates = this.app + this.draft + "/getDraftUpdates";
        this.deleteuser = this.app + this.appadmin + "/deleteUser";
        this.activateUser = this.app + this.appadmin + "/activateUser";
        this.enableUser = this.app + this.appadmin + "/enableUser";
        this.addAppUser = this.app + this.appadmin + "/addAppUser";
        this.deleteAppUser = this.app + this.appadmin + "/deleteAppUsers";
        this.getAppUser = this.app + this.appadmin + "/getAppUsers";
        this.updateAppUser = this.app + this.appadmin + "/updateAppUser";
        this.calcualteAclString = this.app + this.letr + "/calcualteAclString";
        this.editMessageAcl = this.app + this.message + "/editMessageAcl";
        this.getversion = this.app + this.letr + "/getversion";
        this.addversion = this.app + this.letr + "/addversion";
        this.getMyProfile = this.app + this.userpofile + "/getMyProfile";
        this.getProfile = this.app + this.userpofile + "/getProfile";
        this.startNewProfileImage = this.app + this.userpofile + "/startNewProfileImage";
        this.uploadProfileImage = this.app + this.userpofile + "/uploadProfileImage";
        this.getProfiles = this.app + this.userpofile + "/getProfiles";
        this.profileimageurl = this.app + "/urlaccess/profileimage";
        this.getInternalUsers = this.app + this.appadmin + "/getInternalUsers";
        this.getOrganisationContacts = this.app + this.appadmin + "/getOrganisationContacts";
        this.adminresetpassword = this.app + this.appadmin + "/adminresetpassword";
        this.validAdminpassword = this.app + this.appadmin + "/validAdminpassword";
        this.getStorageLogs = this.app + this.appadmin + "/getStorageLogs";
        this.inviteInternalUser = this.app + this.appadmin + "/inviteInternalUser";
        this.getOrgContactKeysBox = this.app + this.appadmin + "/getOrgContactKeysBox";
        this.insertOrgContactKeysBox = this.app + this.appadmin + "/insertOrgContactKeysBox";
        this.showOrgContactTo = this.app + this.appadmin + "/showOrgContactTo";
        this.getRecentBills = this.app + this.appadmin + "/getAllRecentBills";
        this.startBillPayment = this.app + this.appadmin + "/startBillPayment";
        this.paymentCompleted = this.app + this.appadmin + "/paymentCompleted";
        this.hideOrgContactsFrom = this.app + this.appadmin + "/hideOrgContactsFrom";
        this.addNewOrgContact = this.app + this.appadmin + "/addNewOrgContact";
        this.editOrgContact = this.app + this.appadmin + "/editOrgContact";
        this.edituser = this.app + this.appadmin + "/editUser";
        this.cancelinvite = this.app + this.appadmin + "/cancelinvite";
        this.getOrgAdminDetail = this.app + this.appadmin + "/orgdetail";
        this.addwatermark = this.app + this.appadmin + "/addwatermark";
        this.updateContactEmail = this.app + this.appadmin + "/updateContactEmail";
        this.getQuota = this.app + this.appadmin + "/getQuota";
        this.calculatePrice = this.app + this.appadmin + "/calculatePrice";
        this.updateQuota = this.app + this.appadmin + "/updateQuota";
        this.addOrgExternalContactPassword = this.app + this.appadmin + "/addOrgExternalContactPassword";
        this.getOrgExternalContactPassword = this.app + this.appadmin + "/getOrgExternalContactPassword";
        this.saveSchemaState = this.app + this.schema + "/saveSchemaState";
        this.getSchemaState = this.app + this.schema + "/getSchemaState";
        this.sendPwaEmailAuth = this.pwa + "sendEmailAuthCode";
        this.sendPwaPhoneAuth = this.pwa + "sendPhoneAuthCode";
        this.addSchemaState = this.pwa + "addSchemaState";
        this.pwagetSchemaState = this.pwa + "getSchemaState";
        this.validateEmailCode = this.pwa + "validateEmailCode";
        this.validatePhoneCode = this.pwa + "validatePhoneCode";
        this.pwaindex = this.pwa + "index";
        this.registernot = this.pwa + "registerForNotifications";
        this.unregisternot = this.pwa + "unregisterForNotifications";
        this.pwahistory = this.pwa + "getHistory";
        this.pwahistorynew = this.pwa + "getHistoryNew";
        this.pwagetRowRule = this.pwa + "getRowRules";
        this.updateSessionName = this.pwa + "updateSessionName";
        this.getAllorganisatiomDetails = this.pwa + "getAllorganisatiomDetails";
        this.storeEboxToSession = this.pwa + "storeEboxToSession";
        this.getUserContents = this.pwa + "getUserContents";
        this.getUserWorkables = this.pwa + "getUserWorkables";
        this.PWAReachable = this.pwa + "reachable";
        this.pwaWipe = this.pwa + "wipe";
        this.pwagetUpdatesSince = this.pwa + "getUpdatesSince";
        this.pwagetSchemaDependent = this.pwa + "getSchemaDependent";
        this.pwastoreShareBox = this.pwa + "storeShareBox";
        this.pwaaddFileHeader = this.pwa + "addFileHeader";
        this.pwaaddFileChunk = this.pwa + "addFileChunk";
        this.pwaaddProjectObject = this.pwa + "addProjectObject";
        this.pwainsertProjectObject = this.pwa + "insertProjectObject";
        this.pwaaddRow = this.pwa + "addRow";
        this.pwaaddToConnectedWorksheet = this.pwa + "addToConnectedWorksheet";
        this.updateRow = this.pwa + "updateRow";
        this.pwaDeleteRows = this.pwa + "deleteRows";
        this.pwaGetRemoteSchema = this.pwa + "getRemoteSchema";
        this.pwagetRemoteRows = this.pwa + "getRemoteRows";
        this.pwaGetBookmark = this.pwa + "/getBookmarks";
        this.getMyDevices = this.pwa + "getmydevices";
        this.pwaWipeDevice = this.pwa + "wipedevice";
        this.pwaGetTriggerBox = this.pwa + "/getTriggerBox";
        this.pwaLLMRequest = this.pwa + "/makeLLMRequest";
        this.pwaOcrImage = this.pwa + "/ocrImages";
        this.pwaOcrImageStatus = this.pwa + "/ocrImagesStatus";
        this.pwalatest = this.pwa + "getAllLatest";
        this.webformstoreShareBox = this.webform + "storeShareBox";
        this.webformaddFileHeader = this.webform + "addFileHeader";
        this.webformaddFileChunk = this.webform + "addFileChunk";
        this.webformaddProjectObject = this.webform + "addProjectObject";
        this.webforminsertProjectObject = this.webform + "insertProjectObject";
        this.webformaddRow = this.webform + "addRow";
        this.webformuserdetail = this.webform + "userdetail";
        this.getCompanyDirectory = this.app + this.group + "/getCompanyDirectory";
        this.userCanPublishContact = this.app + this.group + "/userCanPublishContact";
        this.publishMyContactToCompanyDircetory = this.app + this.group + "/publishMyContactToCompanyDircetory";
        this.getMyTeam = this.app + this.group + "/getMyTeam";
        this.getMyLetrwingContact = this.app + this.group + "/getMyLetrwingContact";
        this.discover = this.app + this.group + "/discoverLetrwingUser";
        this.sendInviteToCompantContact = this.app + this.group + "/sendInviteToCompantContact";
        this.DeclineLetrCall = this.app + this.letr + "/declineLetrCall";
        this.getCallRecords = this.app + this.letr + "/getCallRecords";
        this.updateletrinfo = this.app + this.letr + "/updateLetrInfo";
        this.updateSchemaDependentRows = this.app + this.letr + "/updateSchemaDependentRows";
        this.getOrdDetail = this.app + this.appadmin + "/orgdetail";
        this.getUserDevices = this.app + this.appadmin + "/getUserDevices";
        this.getstorageboxes = this.app + this.appadmin + "/getstorageboxes";
        this.passwordcheck = this.app + this.appadmin + "/passwordcheck";
        this.getAllCryptBoxes = this.app + this.appadmin + "/getAllCryptBoxes";
        this.adminusers = this.app + this.appadmin + "/adminusers";
        this.userAdminLocker = this.app + this.appadmin + "/userAdminLocker";
        this.userAdminVLetrs = this.app + this.appadmin + "/userAdminVLetrs";
        this.userAdminTags = this.app + this.appadmin + "/userAdminTags";
        this.letrwingcontacts = this.app + this.appadmin + "/letrwingcontacts";
        this.deleteLetrwingContact = this.app + this.appadmin + "/deleteLetrwingContact";
        this.userdeviceedit = this.app + this.appadmin + "/userdeviceedit";
        this.searchquery = this.app + this.search + "/searchquery";
        this.getProjectObjectsDetails = this.app + this.letr + "/getProjectObjectsDetails";
        this.getMessagesForIds = this.app + this.message + "/getMessagesForIds";
        this.getMiniLetrs = this.app + this.letr + "/getMiniLetrs";
        this.createLetrLink = this.app + this.letr + "/createletrlink";
        this.getLetrLinks = this.app + this.letr + "/getLetrLinks";
        this.deleteletrlink = this.app + this.letr + "/deleteletrlink";
        this.adminGetFileInfo = this.app + this.appadmin + "/adminfileinfo";
        this.adminGetFileViewerContent = this.app + this.appadmin + "/adminpdffileinfo";
        this.adminGetDraftdetails = this.app + this.appadmin + "/getDraftdetails";
        this.adminGetDraftChunk = this.app + this.appadmin + "/getDraftChunk";
        this.adminGetDraftChunkMeta = this.app + this.appadmin + "/getDraftChunkMeta";
        this.adminGetMessageReplies = this.app + this.appadmin + "/messagereplies";
        this.adminGetLetrDetails = this.app + this.appadmin + "/getLetrDetails";
        this.adminDeleteLetr = this.app + this.appadmin + "/deleteLetr";
        this.adminRemoveMemberFromLetr = this.app + this.appadmin + "/removeMemberFromLetr";
        this.adminAddMemberToLetr = this.app + this.appadmin + "/addMemberToLetr";
        this.adminGetLetrLinks = this.app + this.appadmin + "/getLetrLinks";
        this.getProjectObjectParents = this.app + this.letr + "/getProjectObjectsParentids";
        this.addSchemaForm = this.app + this.schema + "/addSchemaWebForm";
        this.getUpdatesSince = this.app + this.schema + "/getUpdatesSince";
        this.updateSchemaForm = this.app + this.schema + "/updateSchemaWebForm";
        this.getSchemaForm = this.app + this.schema + "/getSchemaAllWebForms";
        this.deleteSchemaForm = this.app + this.schema + "/deleteWebform";
        this.getSchemaDependent = this.app + this.schema + "/getSchemaDependent";
        this.addSchemaPrintTemplate = this.app + this.schema + "/addSchemaPrintTemplate";
        this.updateSchemaPrintTemplate = this.app + this.schema + "/updateSchemaPrintTemplate";
        this.getSchemaPrintTemplate = this.app + this.schema + "/getSchemaAllPrintTemplate";
        this.deleteSchemaPrintTemplate = this.app + this.schema + "/deletePrintTemplate";
        this.getAllSchemaAnalytics = this.app + this.schema + "/getAllSchemaAnalytics";
        this.addRowRule = this.app + this.schema + "/addRowRule";
        this.getRowRule = this.app + this.schema + "/getRowRule";
        this.adminGetProjectObjects = this.app + this.appadmin + "/getProjectObjects";
        this.adminLetrMessages = this.app + this.appadmin + "/adminletrmessages";
        this.adminMemberCommBundles = this.app + this.appadmin + "/getmemberCommBundle";
        this.sendLetrLinkEmail = this.app + this.letr + "/sendLetrLinkEmail";
        this.getProjectFolders = this.app + this.letr + "/getProjectFolders";
        this.savenote = this.app + this.note + "/addNewNote";
        this.getnotes = this.app + this.note + "/getNotes";
        this.updatenotes = this.app + this.note + "/updateNote";
        this.deleteNote = this.app + this.note + "/deleteNote";
        this.deleteComment = this.app + this.note + "/deleteComment";
        this.getcomments = this.app + this.note + "/getAllComments";
        this.savereply = this.app + this.note + "/addNewNoteReply";
        this.updateComment = this.app + this.note + "/updateComment";
        this.adminGetProjectFolders = this.app + this.appadmin + "/getProjectFolders";
        this.admingetnotes = this.app + this.appadmin + "/getNotes";
        this.adminGetDatasheet = this.app + this.appadmin + "/getDatasheets";
        this.adminGetRows = this.app + this.appadmin + "/getRows";
        this.adminGetcomments = this.app + this.appadmin + "/getAllComments";
        this.adminGetSchemas = this.app + this.appadmin + "/getSchemas";
        this.enableDiableWatermarkForObject = this.app + this.letr + "/enableDiableWatermarkForObject";
        this.addWatermarkForLetr = this.app + this.letr + "/addWatermarkForLetr";
        this.updateLetrLink = this.app + this.letr + "/updateLetrLink";
        this.adminGetRoles = this.app + this.appadmin + "/getLetrRoles";
        this.adminUpdateMemeber = this.app + this.appadmin + "/updateLetrMember";
        this.adminUpdateLetrLink = this.app + this.appadmin + "/updateLetrLink";
        this.admindeleteletrlink = this.app + this.appadmin + "/deleteletrlink";
        this.adminGetUserAssignedTask = this.app + this.appadmin + "/getUserAssignedTask";
        this.adminGetRowRule = this.app + this.appadmin + "/getRowRule";
        this.adminGetAllSchemaAnalytics = this.app + this.appadmin + "/getAllSchemaAnalytics";
        this.adminGetSchemaDependent = this.app + this.appadmin + "/getSchemaDependent";
        this.adminGetField = this.app + this.appadmin + "/getFieldsForDocuments";
        this.adminGetRowsIncremental = this.app + this.appadmin + "/adminGetRowsIncremental";
    }
}
export const ServerPath = new Paths();
