var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class OrganisationMenuStore {
    constructor() {
        this.loading = false;
        this.orgs = [];
        this.orgsmap = new Map();
        this.width = 300;
        this.top = 0;
        this.left = 0;
        this.show = false;
        this.posright = false;
        this.orgcodein = new Map();
        this.decryptingorg = new Set();
        this.selectedodgid = "";
        makeObservable(this);
    }
    setInputText(te, id) {
        this.orgcodein.set(id, te);
    }
    setDecryptOrg(id) {
        this.decryptingorg.add(id);
    }
    removeDecryptOrg(id) {
        this.decryptingorg.delete(id);
    }
    setPosRight(right) {
        this.posright = right;
    }
    setShow(show) {
        this.show = show;
    }
    setLoading(lod) {
        this.loading = lod;
    }
    addOrg(org) {
        this.orgs.push(org);
        this.orgsmap.set(org.id, org);
    }
    removeOrg(orgs) {
        for (const oldorg of orgs) {
            const index = this.orgs.indexOf(oldorg);
            if (index > -1) {
                this.orgs.splice(index, 1);
            }
            this.orgsmap.delete(oldorg.id);
        }
    }
    setOrganisations(orgs) {
        this.orgs.splice(0, this.orgs.length);
        this.orgs.push(...orgs);
        for (const o of orgs) {
            this.orgsmap.set(o.id, o);
        }
    }
    setWidth(wd) {
        this.width = wd;
    }
    setLeft(left) {
        this.left = left;
    }
    setTop(top) {
        this.top = top;
    }
    setSelectedOrgid(id) {
        this.selectedodgid = id;
    }
    replaceOrg(oldorg, neworg) {
        const index = this.orgs.indexOf(oldorg);
        if (index > -1) {
            this.orgs.splice(index, 1, neworg);
        }
        this.orgsmap.set(neworg.id, neworg);
    }
    reset() {
        this.orgcodein.clear();
        this.decryptingorg.clear();
        this.setOrganisations([]);
        this.setShow(false);
        this.selectedodgid = "";
        this.orgsmap.clear();
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], OrganisationMenuStore.prototype, "orgs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "top", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "left", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "posright", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], OrganisationMenuStore.prototype, "orgcodein", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], OrganisationMenuStore.prototype, "decryptingorg", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], OrganisationMenuStore.prototype, "selectedodgid", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setInputText", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setDecryptOrg", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "removeDecryptOrg", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setPosRight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setLoading", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "addOrg", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "removeOrg", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setOrganisations", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setLeft", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setTop", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "setSelectedOrgid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "replaceOrg", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], OrganisationMenuStore.prototype, "reset", null);
