var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class UserMenuStore {
    constructor() {
        this.width = 300;
        this.top = 0;
        this.left = 0;
        this.show = false;
        this.posright = false;
        this.mydevices = new Map();
        makeObservable(this);
    }
    getDevices() {
        return Array.from(this.mydevices.values());
    }
    addDevices(device) {
        this.mydevices.set(device.id, device);
    }
    removeDevices(id) {
        this.mydevices.delete(id);
    }
    setPosRight(right) {
        this.posright = right;
    }
    setShow(show) {
        this.show = show;
    }
    setWidth(wd) {
        this.width = wd;
    }
    setLeft(left) {
        this.left = left;
    }
    setTop(top) {
        this.top = top;
    }
    reset() {
        this.setShow(false);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], UserMenuStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], UserMenuStore.prototype, "top", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], UserMenuStore.prototype, "left", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], UserMenuStore.prototype, "show", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], UserMenuStore.prototype, "posright", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], UserMenuStore.prototype, "mydevices", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "addDevices", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "removeDevices", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "setPosRight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "setShow", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "setLeft", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "setTop", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserMenuStore.prototype, "reset", null);
