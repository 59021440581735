var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from "mobx";
export class CalendarViewState {
    constructor() {
        this.building = false;
        this.elements = new Map();
        this.events = [];
        this.width = 600;
        this.height = 600;
        makeObservable(this);
    }
    setBuilding(building) {
        this.building = building;
    }
    setWidth(width) {
        this.width = width;
    }
    setHeight(height) {
        this.height = height;
    }
    addElement(id, ele) {
        if (ele.rangedates.length < 2) {
            ele.rangedates.push(ele.rangedates[0]);
        }
        ele.rangedates.sort((r1, r2) => r1 - r2);
        this.events.push({
            resource: ele,
            start: new Date(ele.rangedates[0]),
            end: new Date(ele.rangedates[1]),
            allDay: false
        });
        ele.indexitem = this.events.length - 1;
        this.elements.set(id, ele);
    }
    updateElement(id, ele) {
        const cele = this.elements.get(id);
        if (cele) {
            if (ele.rangedates.length < 2) {
                ele.rangedates.push(ele.rangedates[0]);
            }
            ele.rangedates.sort((r1, r2) => r1 - r2);
            const index = cele.indexitem;
            if (index !== undefined) {
                this.events.splice(index, 1, {
                    resource: ele,
                    start: new Date(ele.rangedates[0]),
                    end: new Date(ele.rangedates[1]),
                    allDay: false
                });
                ele.indexitem = cele.indexitem;
            }
            this.elements.set(id, ele);
        }
    }
    clear() {
        this.elements.clear();
        this.setBuilding(false);
        this.events.splice(0, this.events.length);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], CalendarViewState.prototype, "building", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], CalendarViewState.prototype, "elements", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CalendarViewState.prototype, "events", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CalendarViewState.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CalendarViewState.prototype, "height", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], CalendarViewState.prototype, "setBuilding", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], CalendarViewState.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], CalendarViewState.prototype, "setHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], CalendarViewState.prototype, "addElement", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], CalendarViewState.prototype, "updateElement", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CalendarViewState.prototype, "clear", null);
