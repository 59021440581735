var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CardItem } from "@/share/CardItem";
import { action, makeObservable, observable } from "mobx";
export class KanbanStore {
    constructor() {
        this.cards = [];
        this.cardbyid = new Map();
        this.dragging = false;
        this.status = [];
        this.groupby = [];
        this.groupbycards = new Map();
        this.fieldid = "";
        this.groupbyid = "";
        this.draggingitem = undefined;
        this.kandragoverstatus = "";
        this.activecardid = "";
        this.buildingview = false;
        this.cardsByStatus = new Map();
        this.width = 600;
        this.height = 600;
        makeObservable(this);
    }
    setWidth(width) {
        this.width = width;
    }
    setHeight(height) {
        this.height = height;
    }
    setBuildingView(building) {
        this.buildingview = building;
    }
    setDragging(dragging) {
        this.dragging = dragging;
    }
    setActivecardid(id) {
        this.activecardid = id;
    }
    setActiveStatus(id) {
        this.kandragoverstatus = id;
    }
    setStatuses(st) {
        this.status.splice(0, this.status.length);
        this.status.push(...st);
    }
    setGroupBy(st) {
        this.groupby.splice(0, this.groupby.length);
        this.groupby.push(...st);
    }
    addCard(card) {
        var _a, _b, _c, _d, _e;
        this.cards.push(card);
        if (!this.cardsByStatus.has(card.status)) {
            this.cardsByStatus.set(card.status, []);
        }
        (_a = this.cardsByStatus.get(card.status)) === null || _a === void 0 ? void 0 : _a.push(card);
        this.cardbyid.set(card.cradid, card);
        if (card.groupid) {
            if (!this.groupbycards.has(card.groupid)) {
                this.groupbycards.set(card.groupid, new Map());
            }
            if (!((_b = this.groupbycards.get(card.groupid)) === null || _b === void 0 ? void 0 : _b.get(card.status))) {
                (_c = this.groupbycards.get(card.groupid)) === null || _c === void 0 ? void 0 : _c.set(card.status, []);
            }
            (_e = (_d = this.groupbycards.get(card.groupid)) === null || _d === void 0 ? void 0 : _d.get(card.status)) === null || _e === void 0 ? void 0 : _e.push(card);
        }
    }
    updateCard(card) {
        var _a, _b, _c, _d, _e, _f;
        const acrd = this.cardbyid.get(card.cradid);
        if (acrd) {
            let intialgid = acrd.groupid;
            let intialstatus = acrd.status;
            const oldcard = (_a = this.groupbycards.get(intialgid)) === null || _a === void 0 ? void 0 : _a.get(intialstatus);
            if (oldcard) {
                const oindex = oldcard.indexOf(acrd);
                oldcard.splice(oindex, 1);
            }
            if (acrd.groupid !== card.groupid) {
                // we should also update the this
            }
            // we should update this 
            const cards = this.cardsByStatus.get(acrd.status);
            const cindex = cards === null || cards === void 0 ? void 0 : cards.indexOf(acrd);
            if (cindex !== undefined && cindex > -1) {
                cards === null || cards === void 0 ? void 0 : cards.splice(cindex, 1);
            }
            if (!this.cardsByStatus.has(card.status)) {
                this.cardsByStatus.set(card.status, []);
            }
            (_b = this.cardsByStatus.get(card.status)) === null || _b === void 0 ? void 0 : _b.push(acrd);
            acrd.setGroupid(card.groupid);
            acrd.setStatus(card.status);
            for (const [k, fld] of card.displayfields) {
                acrd.addDisplayField(k, fld);
            }
            // lets remove the card from it now!
            if (acrd.groupid) {
                if (!this.groupbycards.has(acrd.groupid)) {
                    this.groupbycards.set(acrd.groupid, new Map());
                }
                if (!((_c = this.groupbycards.get(acrd.groupid)) === null || _c === void 0 ? void 0 : _c.get(acrd.status))) {
                    (_d = this.groupbycards.get(acrd.groupid)) === null || _d === void 0 ? void 0 : _d.set(acrd.status, []);
                }
                (_f = (_e = this.groupbycards.get(acrd.groupid)) === null || _e === void 0 ? void 0 : _e.get(acrd.status)) === null || _f === void 0 ? void 0 : _f.push(acrd);
            }
        }
    }
    clear() {
        this.cards.splice(0, this.cards.length);
        this.setActiveStatus("");
        this.setDragging(false);
        this.setActivecardid("");
        this.setStatuses([]);
        this.setGroupBy([]);
        this.cardsByStatus.clear();
        this.groupbycards.clear();
        this.groupbyid = "";
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], KanbanStore.prototype, "cards", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], KanbanStore.prototype, "dragging", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], KanbanStore.prototype, "status", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], KanbanStore.prototype, "groupby", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], KanbanStore.prototype, "groupbycards", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], KanbanStore.prototype, "kandragoverstatus", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], KanbanStore.prototype, "activecardid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], KanbanStore.prototype, "buildingview", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], KanbanStore.prototype, "cardsByStatus", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], KanbanStore.prototype, "width", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], KanbanStore.prototype, "height", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setBuildingView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setDragging", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setActivecardid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setActiveStatus", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setStatuses", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "setGroupBy", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [CardItem]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "addCard", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [CardItem]),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "updateCard", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], KanbanStore.prototype, "clear", null);
