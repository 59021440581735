var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { observer } from "mobx-react";
import React from "react";
import { StoreContext } from "../uistore/PwaRootStore";
import { Box, Button, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import { Textarea } from '@chakra-ui/react';
import { testIsMobile } from "@@/Util";
let PwaAiForm = class PwaAiForm extends React.Component {
    constructor(prop) {
        super(prop);
        this.ref = null;
        this.state = {
            useslow: false,
            prompt: ""
        };
    }
    filesSelected() {
        const rstore = this.context;
        if (this.ref && this.ref.files) {
            // lets add them now!!
            let fs = [];
            for (const ff of this.ref.files) {
                fs.push(ff);
            }
            if (this.state.useslow) {
                rstore.getPwaStore().ocrFileOnBackend(fs);
            }
            else {
                rstore.getPwaStore().ocrFile(fs);
            }
            this.ref.value = '';
        }
    }
    componentDidMount() {
        const rstore = this.context;
        const pt = rstore.getPwaStore().getAIPrompt();
        this.setState({ prompt: pt });
    }
    render() {
        const rstore = this.context;
        return React.createElement(Box, { margin: 'auto', marginTop: '10px', w: window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100, h: '100%' },
            React.createElement(Box, { mt: '10px', pos: 'relative', bg: 'whiteAlpha.400', boxShadow: '0 0 5px #b6b6b6', w: window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100, h: testIsMobile() ? window.innerHeight - 250 + "px" : '500px', borderWidth: 1, borderColor: '#dfdfdf' },
                React.createElement(Flex, { height: '30px', flexWrap: 'wrap', align: 'center' },
                    React.createElement("input", { style: { display: 'none' }, accept: "image/png, image/jpeg", ref: (ref) => this.ref = ref, type: 'file', multiple: true, onChange: () => {
                            this.filesSelected();
                        } }),
                    React.createElement(Button, { onClick: () => {
                            var _a;
                            //we need to let them upload file here!
                            (_a = this.ref) === null || _a === void 0 ? void 0 : _a.click();
                        } }, "Upload Image"),
                    React.createElement(Button, { marginLeft: '10px', onClick: () => {
                            // now we just need to run ai!
                            let pt = "";
                            if (this.state.prompt) {
                                pt = this.state.prompt + "\n" + rstore.getPwaStore().aicontent;
                            }
                            rstore.getPwaStore().runAi(pt);
                        } }, "Parse"),
                    React.createElement(Button, { marginLeft: '10px', onClick: () => { rstore.getPwaStore().setAddNewViewEntryViaAi(false); } }, "Close"),
                    React.createElement(Text, { marginLeft: '10px' }, rstore.getPwaStore().aimessages)),
                React.createElement(Checkbox, { marginTop: '30px', isChecked: this.state.useslow, onChange: (e) => {
                        this.setState({ useslow: e.currentTarget.checked });
                    } }, "Use slow but more powerful ocr engine"),
                React.createElement(Input, { placeholder: "Prompt", value: this.state.prompt, onChange: (e) => this.setState({ prompt: e.currentTarget.value }) }),
                React.createElement(Textarea, { height: '300px', marginTop: '30px', value: rstore.getPwaStore().aicontent, onChange: (ev) => rstore.getPwaStore().setAiContent(ev.currentTarget.value) })));
    }
};
PwaAiForm.contextType = StoreContext;
PwaAiForm = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PwaAiForm);
export { PwaAiForm };
