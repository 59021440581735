var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, action } from "mobx";
export class CardItem {
    constructor() {
        this.status = "";
        this.groupid = "";
        this.cardindex = -1;
        this.displayfields = new Map();
        this.cradid = "";
        makeObservable(this);
    }
    setStatus(status) {
        this.status = status;
    }
    setGroupid(gid) {
        this.groupid = gid;
    }
    addDisplayField(id, value) {
        this.displayfields.set(id, value);
    }
    removeDisplayField(id) {
        this.displayfields.delete(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], CardItem.prototype, "status", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CardItem.prototype, "groupid", void 0);
__decorate([
    observable,
    __metadata("design:type", Map)
], CardItem.prototype, "displayfields", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CardItem.prototype, "setStatus", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CardItem.prototype, "setGroupid", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], CardItem.prototype, "addDisplayField", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CardItem.prototype, "removeDisplayField", null);
