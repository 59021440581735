var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import React from 'react';
import { Box, ChakraProvider, Text, extendTheme, Container, Heading, Stack } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';
import { observer } from 'mobx-react';
import { RootStore, StoreContext } from './uistore/PwaRootStore';
import { PwaAuthState } from './uistore/PwaStore';
import { PwaLogin } from './PwaLogin';
import { PwaApp } from './view/PwaApp';
import { ConfirmDialog } from './components/PwaConfirmDialog';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThumbNailStore } from './uistore/ThumbNailStore';
import { PwaPdfPageStore } from './UIComponents/PwaPdfPageStore';
import { PwaWorkingDialog } from './UIComponents/PwaWorkingDialog';
const theme = extendTheme({
    components: {
        MultiSelect: MultiSelectTheme
    },
    colors: {
        lwcolor: {
            50: "#516a82",
            100: "#516a82",
            500: "#516a82",
        }
    }
});
let Pwa = class Pwa extends React.Component {
    constructor(props) {
        super(props);
        this.hidden = true;
        this.visbiliotycb = () => {
            if (document.hidden) {
            }
            else {
                // load my orgs and also connect the 
                this.rstore.getPwaStore().setInternetStatus(navigator.onLine);
            }
        };
        this.rstore = new RootStore();
        ThumbNailStore.ispwa = true;
    }
    async componentDidMount() {
        document.addEventListener('visibilitychange', this.visbiliotycb);
        await this.rstore.getPwaStore().init();
        if (await this.rstore.getPwaStore().checkAppState()) {
            const log = await this.rstore.getPwaStore().userLoggedIn();
            if (log) {
                this.rstore.getPwaStore().setPwaLoginState(PwaAuthState.Loggedin);
                setTimeout(() => {
                    this.handleHash();
                }, 50);
            }
            else {
                this.rstore.getPwaStore().setPwaLoginState(PwaAuthState.Loggedout);
            }
        }
        else {
            console.log("logged in 3");
            this.rstore.getPwaStore().setPwaLoginState(PwaAuthState.AppError);
        }
        if (this.hidden) {
            this.showRoot();
            this.hidden = false;
        }
    }
    documentClicked(dmessgae, rowid, entryid, cellid) {
        var _a, _b, _c, _d, _e, _f, _g;
        PwaPdfPageStore.pages = new Map();
        this.rstore.getPwaStore().orgname = (_a = dmessgae.origname) !== null && _a !== void 0 ? _a : dmessgae.name;
        this.rstore.getPwaStore().setTitle(dmessgae.name);
        this.rstore.getPwaStore().setActiveItemid(dmessgae.id, rowid, entryid, false);
        PwaPdfPageStore.activeobjectid = dmessgae.id;
        this.rstore.getPwaStore().setViewerActive(true);
        // this.rstore.documentDetailStore.canedit = false;
        // this.rstore.getPwaStore().draftid = "";
        this.rstore.getPwaStore().info = {
            fileid: dmessgae.documentreference,
            letrid: this.rstore.getPwaStore().getLetrid(),
            accesskey: dmessgae.accesskey,
            boxid: dmessgae.accesscode,
            version: (_b = dmessgae.versions) !== null && _b !== void 0 ? _b : [],
            replace: []
        };
        this.rstore.getPwaStore().setActiveDocumentId(dmessgae.id);
        this.rstore.getPwaStore().setLoading(false);
        this.rstore.getPwaMiniViewerStore().reset();
        this.rstore.getPwaMiniViewerStore().activeletrid = this.rstore.getPwaStore().getLetrid();
        this.rstore.getPwaMiniViewerStore().activeobjectid = dmessgae.id;
        this.rstore.getPwaMiniViewerStore().activedraftid = dmessgae.documentreference;
        // this.rstore.draftDetailStore.letrid = this.rstore.projectStore.activeletrid;
        // this.rstore.draftDetailStore.objectid = dmessgae.id;
        // this.rstore.draftDetailStore.draftid = dmessgae.documentreference;
        this.rstore.getPwaMiniViewerStore().fileid = dmessgae.documentreference;
        this.rstore.getPwaMiniViewerStore().accesskey = dmessgae.accesskey;
        // this.rstore.miniviewStore.getAllNotes();
        const metadata = this.rstore.getPwaStore().filemetamap.get(dmessgae.documentreference);
        if (metadata !== undefined) {
            this.rstore.getPwaStore().setPdfPageDetails((_d = (_c = metadata.ingestunboxed) === null || _c === void 0 ? void 0 : _c.detail) !== null && _d !== void 0 ? _d : []);
            this.rstore.getPwaStore().activedocumentreference = dmessgae.documentreference;
            this.rstore.getPwaStore().activerowid = rowid;
            this.rstore.getPwaStore().activeaccesskey = dmessgae.accesskey;
            this.rstore.getPwaStore().activecellid = cellid;
            this.rstore.getPwaStore().activeentryid = entryid;
            this.rstore.getPwaMiniViewerStore().setTotalPages((_g = (_f = (_e = metadata.ingestunboxed) === null || _e === void 0 ? void 0 : _e.detail) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0);
            this.rstore.getPwaMiniViewerStore().increamentPageNumber();
            this.rstore.getPwaStore().resetPdfPage();
            setTimeout(() => this.rstore.getPwaStore().setShow(true), 100);
            this.rstore.getPwaStore().loadFindDicts((fdict) => {
                setTimeout(() => this.rstore.getPwaMiniViewerStore().parseAndBuildFindDic(fdict), 10);
            });
            this.rstore.getPwaStore().checkAndGetBookmarks((bookmarks) => {
                this.rstore.getPwaMiniViewerStore().bookmarkstate.reset();
                this.rstore.getPwaMiniViewerStore().bookmarkstate.setLoading(true);
                if (bookmarks && bookmarks.length > 0) {
                    bookmarks.forEach((pg, index) => {
                        pg.sortindex = index + 1;
                        this.rstore.getPwaMiniViewerStore().bookmarkstate.addBookmark(pg);
                    });
                }
                this.rstore.getPwaMiniViewerStore().bookmarkstate.arrangeTree();
                this.rstore.getPwaMiniViewerStore().bookmarkstate.setLoading(false);
            });
            if (metadata.ingestunboxed !== undefined) {
                if (!metadata.ingestunboxed.haspdf &&
                    metadata.ingestunboxed.hasviewer && metadata.ingestunboxed.isExcel) {
                    this.rstore.getPwaStore().setIsExcel(true);
                }
                else {
                    this.rstore.getPwaStore().setViewable(metadata.ingestunboxed.haspdf);
                    this.rstore.getPwaStore().setIsExcel(false);
                }
                this.rstore.getPwaStore().setQueued(false);
            }
            else {
                this.rstore.getPwaStore().setQueued(true);
                this.rstore.getPwaStore().setViewable(false);
            }
        }
    }
    handleHash() {
        const res = this.rstore.getPwaStore().orgmenustore.orgs;
        if (res.length > 0) {
            let found = false;
            if (window.location.hash.length > 1) {
                const route = this.rstore.getPwaStore().parseHash(window.location.hash);
                if (route && route.id) {
                    const rf = res.filter((r) => route.id === r.id && r.isopen);
                    if (rf.length > 0) {
                        setTimeout(() => {
                            this.rstore.getPwaStore().setSelectOrgId(rf[0].id, false);
                            if (route.view.startsWith("Doc~~")) {
                                console.log("handling route here");
                                const docparts = route.view.split("~~");
                                if (docparts.length > 3) {
                                    const rowid = docparts[1];
                                    const entryid = docparts[2];
                                    const docid = docparts[3];
                                    this.rstore.getPwaStore().handleRoute(route, (res) => {
                                        this.documentClicked(res, rowid, entryid, "");
                                    });
                                }
                            }
                            else {
                                this.rstore.getPwaStore().handleRoute(route, undefined);
                            }
                        }, 500);
                        found = true;
                    }
                }
            }
            if (!found) {
                // we can also 
                const lastorg = localStorage.getItem("lastorg");
                if (lastorg) {
                    const rf = res.filter((r) => r.id === lastorg && r.isopen);
                    if (rf.length > 0) {
                        setTimeout(() => {
                            this.rstore.getPwaStore().setSelectOrgId(rf[0].id);
                        }, 500);
                        found = true;
                    }
                }
                if (!found) {
                    const rf = res.filter((r) => r.isopen);
                    if (rf.length > 0) {
                        setTimeout(() => {
                            this.rstore.getPwaStore().setSelectOrgId(rf[0].id);
                        }, 500);
                        found = true;
                    }
                }
            }
            if (!found) {
                this.rstore.getPwaStore().setSelectOrgId("");
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.visbiliotycb);
    }
    componentDidUpdate() {
        if (this.hidden) {
            this.showRoot();
            this.hidden = false;
        }
    }
    showSvg() {
        const loading = document.getElementById("loading_svg");
        const root = document.getElementById("root");
        if (loading) {
            loading.style.display = "block";
        }
        if (root) {
            root.style.display = "none";
        }
    }
    showRoot() {
        const loading = document.getElementById("loading_svg");
        const root = document.getElementById("root");
        if (loading) {
            loading.style.display = "none";
        }
        if (root) {
            root.style.display = "block";
        }
    }
    render() {
        return (React.createElement(StoreContext.Provider, { value: this.rstore },
            React.createElement(ChakraProvider, { theme: theme },
                React.createElement(ConfirmDialog, null),
                React.createElement(PwaWorkingDialog, null),
                React.createElement(ToastContainer, null),
                this.rstore.getPwaStore().pwaauthstate === PwaAuthState.Loggedin && React.createElement(PwaApp, null),
                this.rstore.getPwaStore().pwaauthstate === PwaAuthState.Loggedout && React.createElement(PwaLogin, null),
                this.rstore.getPwaStore().pwaauthstate === PwaAuthState.AppError && React.createElement(Container, { maxW: '3xl' },
                    React.createElement(Stack, { as: Box, textAlign: 'center', spacing: { base: 8, md: 14 }, py: { base: 20, md: 36 } },
                        React.createElement(Heading, { fontWeight: 600, fontSize: { base: '2xl', sm: '4xl', md: '6xl' }, lineHeight: '110%' },
                            "Setup Error",
                            React.createElement("br", null)),
                        React.createElement(Text, { color: 'gray.500' }, "Sorry, we are struggling to setup this device. Please contact support for resolving this issue."))))));
    }
};
Pwa = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], Pwa);
export { Pwa };
