import { comm } from '@/Comm/comm';
import { ServerPath } from '@@/ServerPath';
class UserProfileStore {
    async getMyProfile() {
        const profile = await comm.get(ServerPath.getMyProfile);
        if (profile == undefined) {
            return undefined;
        }
        return profile;
    }
    getProfileRequest(id, size) {
        const ret = {
            userid: id,
            size: size
        };
        return ret;
    }
    async getLetrProfiles(ids) {
        const profile = await comm.post(ServerPath.getProfiles, { data: ids });
        if (profile == undefined) {
            return undefined;
        }
        return profile;
    }
    async updateProfilePhoto(file) {
    }
    async getProfile(id) {
        const profile = await comm.post(ServerPath.getProfile, { data: id });
        if (profile == undefined) {
            return undefined;
        }
        return profile;
    }
}
export const ProfileStore = new UserProfileStore();
