import { Convert } from "@/background/Converter";
import { Auth } from "@/background/store/AuthStore";
import { RegStore } from "@/background/store/RegisterStore";
import { BEvent } from "@/events/BackgroundEvents";
import { DeviceAndOrgStage, DeviceAndOrgUpdate, DeviceAndOrgUpdateStage } from "@/share/DeviceAndOrg";
import { GetGroupsResponse, GroupTypeNameAndId, ObjectUpdateType } from "@/share/models";
import { AppState } from "@/share/AppState";
import { LoginDeviceRequest, LoginDeviceUpdateState } from "@/share/LoginDevice";
import { APPEvent } from "@/events/AppEvents";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { GStore } from "@/background/store/GroupStore";
import { LocalRepo } from "@/db/Repo";
import { UserStatus } from "@@/Group";
import { NewInvitesType } from "@/share/NewInvite";
import { ExternalContactEditResponse, ExternalEditOpt } from "@/share/ExternalContactEdit";
import { PersonalGroupEditOpt, PersonalGroupresponse } from "@/share/PersonalGroupEdit";
import { MasterKeyRequestType, MasterKeyResponse } from "@/share/MasterKeyReq";
import { MKService } from "@/background/store/MasterKeyStore";
import { TagRequestType } from "@/share/Tag";
import { TStore } from "@/background/store/TagStore";
import { LetrEditrequestType, LetrRequestType } from "@/share/Letr";
import { LStore } from "@/background/store/LetrStore";
import { MessageUIRequestType, MessageUIResponse } from "@/share/Message";
import { MStore } from "@/background/store/MessageStore";
import { NStore } from "@/background/store/NotifyStore";
import { MessageType } from "@@/Message";
import { DStore } from "@/background/store/Devicestore";
import { FileDownloadType } from "@/share/FileRequest";
import { FStore } from "@/background/store/FileStore";
import { AStore } from "@/background/store/AllRepliesStore";
import { ManagedStore } from "@/background/store/ManagedStore";
import { ConnectStore } from "@/background/store/ConnectsStore";
import { MediaStore } from "@/background/store/MediaStore";
import { LoginViaDeviceStore } from "@/background/store/LoginViaDeviceStore";
import { OrgContactStore } from "@/background/store/OrgContactStore";
import { ProjectStore } from "@/background/store/ProjectStore";
import { ProjectObjectType } from "@@/Project";
import { CHATID } from "@@/Tags";
import { ProfileStore } from "@/background/store/UserProfileStore";
import { UserAdminStore } from "@/background/store/AdminService";
import { DraftStore } from "@/background/store/DraftStore";
import { DraftType } from "@@/Draft";
import { CallStore } from "@/background/store/CallStore";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { SearchStore } from "@/background/store/SearchStore";
import { SearchType } from "@@/ModuleJob";
import { NoteStore } from "@/background/store/Notestore";
import { LiveStore } from "@/background/store/LiveStore";
import { comm } from "@/Comm/comm";
import { SchemaStore } from "@/background/store/SchemaStore";
import { UnreadStore } from "@/background/store/UnreadStore";
class Handler {
    constructor() {
        BEvent.addSubscriberForNewAppMessage((msg) => this.handle(msg));
        BEvent.addSubscriberForNewLetrAvailable((letrs) => this.sendNewLetrs(letrs));
        BEvent.addSubscriberForLetrUpdateAvailable((letrs) => this.sendLetrUpdate(letrs));
        BEvent.addSubscriberForMessageAvailable((msg) => this.sendMessageAvailable(msg));
        BEvent.addSubscriberForQuota((qt) => {
            this.sendMessage("quota", qt);
        });
        BEvent.addSubscriberForAppState(state => {
            if (state === AppState.Logout) {
                this.loggedOut();
            }
            else if (state === AppState.APPDown) {
                const res = { state: AppState.APPDown, registerTypes: [] };
                this.sendMessage("appstate", res);
            }
        });
        BEvent.addSubscriberForNotesUpdate((upds) => {
            this.sendMessage("notesupdate", upds);
        });
        BEvent.addSubscriberForContactUpdate(() => {
            this.getPendingInvites();
            this.getNewInvitations();
        });
        BEvent.addSubscriberForInvites((inv) => this.publishInvites(inv));
        BEvent.addSubscriberForDeleteMessage((msg) => this.deleteMessage(msg));
        BEvent.addSubscriberForReply((msg) => this.sendReplyAvailable(msg));
        BEvent.addSubscriberForChildTags((ts) => this.sendChildrenTags(ts));
        BEvent.addSubscriberForEditReply((msg) => this.sendReplyEditAvailable(msg));
        BEvent.addSubscriberForDeleteReply((msg) => this.sendDeleteReply(msg));
        BEvent.addSubscriberForNewPubliContacts((cons) => this.sendPublicContacts(cons));
        BEvent.addSubscriberForNewManagedLetrResponse((res) => this.sendNewManagedLetrResponse(res));
        BEvent.addSubscriberForUnreadInfo((info) => this.sendUnreadInfo(info));
        BEvent.addSubscriberForNewActiveLetridCall((lt) => this.getLetrDetailForIncomingCall(lt));
        BEvent.addSubscriberForCallActioned((req) => this.sendMessage("callactioned", req));
        BEvent.addSubscriberForCallEnded((req) => this.sendMessage("callended", req));
        BEvent.addSubscriberForCommentsUpdate((res) => this.sendMessage("getcommentres", res));
        BEvent.addSubscriberForDraftUpdates((ups) => this.sendMessage("gotdraftupdates", ups));
        BEvent.addSubscriberForNotifyFileCount((ups) => this.sendMessage("newfilescount", ups));
        BEvent.addSubscriberForNotifyCount((cun) => {
            const count = {
                count: cun
            };
            this.sendMessage("newnotifycount", count);
        });
        BEvent.addSubscriberForNotifyTaskCount((ct) => this.sendMessage("notifytaskcount", ct));
        BEvent.addSubscriberForSchemaUpdate((update) => this.sendMessage("schemaupdate", update));
    }
    async handle(message) {
        var _a, _b;
        const data = JSON.parse(message);
        if (data.name === 'checkappstate') {
            this.getAppState();
        }
        else if (data.name === 'registerrequest') {
            const req = JSON.parse(message);
            this.registerReg(req.message);
        }
        else if (data.name === 'pinverify') {
            const req = JSON.parse(message);
            this.verifyPin(req.message);
        }
        else if (data.name === 'organddevicereq') {
            const req = JSON.parse(message);
            this.newOrgAndDeviceReq(req.message);
        }
        else if (data.name === 'resendcode') {
            Auth.sendOneTimePassword(data.message);
        }
        else if (data.name === 'logindevice') {
            const msg = JSON.parse(message);
            this.loginUser(msg.message);
        }
        else if (data.name === 'deletethisdevice') {
            Auth.deleteThiDevice(() => {
                this.getAppState();
            });
        }
        else if (data.name === 'logout') {
            Auth.logout();
        }
        else if (data.name === 'groups') {
            this.getGroups();
        }
        else if (data.name === 'invite') {
            const req = JSON.parse(message);
            this.sendInvite(req.message);
        }
        else if (data.name === 'getpending') {
            this.getPendingInvites();
        }
        else if (data.name === 'wipedevice') {
            const req = JSON.parse(message);
            this.wipeDevice(req.message);
        }
        else if (data.name === 'getnewinvites') {
            this.getNewInvitations();
        }
        else if (data.name === 'newinviteresponse') {
            const req = JSON.parse(message);
            this.newInviteAcceptDecline(req.message);
        }
        else if (data.name === 'externalcontactedit') {
            const req = JSON.parse(message);
            this.editExternalContacts(req.message);
        }
        else if (data.name === 'personalgroup') {
            const req = JSON.parse(message);
            this.handlePersonalgroup(req.message);
        }
        else if (data.name === 'masterkey') {
            const req = JSON.parse(message);
            this.handleMasterKey(req.message);
        }
        else if (data.name === 'tags') {
            const req = JSON.parse(message);
            this.handleTagRequest(req.message);
        }
        else if (data.name === 'letrs') {
            const req = JSON.parse(message);
            this.handleLetrRequest(req.message);
        }
        else if (data.name === 'messages') {
            const req = JSON.parse(message);
            this.handleMessageReq(req.message);
        }
        else if (data.name === 'letredit') {
            const req = JSON.parse(message);
            this.letrEditRequest(req.message);
        }
        else if (data.name === 'devices') {
            this.loadDevices();
        }
        else if (data.name === 'activeletr') {
            MStore.setActiveLetr(data.message);
        }
        else if (data.name === 'activetag') {
            LStore.setActiveTagId(data.message);
        }
        else if (data.name === 'uploadfile') {
            const req = JSON.parse(message);
            this.uploadFile(req.message);
        }
        else if (data.name === 'downloadfile') {
            const req = JSON.parse(message);
            this.downloadFile(req.message);
        }
        else if (data.name === 'allreplies') {
            const req = JSON.parse(message);
            this.getAllReplies(req.message);
        }
        else if (data.name === "getmanagedcontacts") {
            this.getManagedContacts();
        }
        else if (data.name === "managedgroupsinvites") {
            this.getManagedGroupsInvites(data.message);
        }
        else if (data.name === 'managedgroups') {
            this.getManagedGroups(data.message);
        }
        else if (data.name === 'managedletrs') {
            const req = JSON.parse(message);
            this.getManagedLetrs(req.message);
        }
        else if (data.name === 'activemanagedcontact') {
            ManagedStore.setactiveContactId(data.message);
        }
        else if (data.name === 'fileprocessstatus') {
            const req = JSON.parse(message);
            this.getFileStatus(req.message);
        }
        else if (data.name === 'pdfpage') {
            const req = JSON.parse(message);
            this.getPdfFilePage(req.message);
        }
        else if (data.name === "getconnects") {
            this.getConnects();
        }
        else if (data.name === 'deleteconnects') {
            const req = JSON.parse(message);
            this.deleteConnects(req.message.emails);
        }
        else if (data.name === 'addconnects') {
            const req = JSON.parse(message);
            this.addConnect(req.message);
        }
        else if (data.name === 'createpasswordhints') {
            const req = JSON.parse(message);
            this.addConnectPasswordHint(req.message);
        }
        else if (data.name === 'fetchletrconnectdetails') {
            const req = JSON.parse(message);
            this.fetchLetrConnectDetail(req.message);
        }
        else if (data.name === 'acceptorgreq') {
            const req = JSON.parse(message);
            this.acceptOrgInvite(req.message);
        }
        else if (data.name === "registerusertoorg") {
            const req = JSON.parse(message);
            this.registerUser(req.message);
        }
        else if (data.name === "activemediaid") {
            MediaStore.setActiveMediaId(data.message);
        }
        else if (data.name === "mediamessage") {
            const req = JSON.parse(message);
            MediaStore.sendMessageToMember(req.message, message);
        }
        else if (data.name === "checkpeerconnected") {
            MediaStore.checkAndReconnectPeer(data.message);
        }
        else if (data.name === "addtoroom") {
            const req = JSON.parse(message);
            const msg = data.message;
            MediaStore.connectCall(req.message.incoming);
        }
        else if (data.name === "registeranotherdevice") {
            const req = JSON.parse(message);
            this.registerAnotherDevice(req.message);
        }
        else if (data.name === "loginviadevice") {
            const req = JSON.parse(message);
            this.loginViaAnotherDevice(req.message);
        }
        else if (data.name === "getorgcontacts") {
            const req = JSON.parse(message);
            this.getOrgContacts(req.message.lastch);
        }
        else if (data.name === "getprojects") {
            const req = JSON.parse(message);
            this.getProject(req.message);
        }
        else if (data.name === "createproject") {
            const req = JSON.parse(message);
            this.createProject(req.message);
        }
        else if (data.name === 'updateproject') {
            const req = JSON.parse(message);
            this.updateProjectObj(req.message);
        }
        else if (data.name === 'moveobject') {
            const req = JSON.parse(message);
            this.moveProjectObj(req.message);
        }
        else if (data.name === "emptytrash") {
            this.emptyTrash(data.message);
        }
        else if (data.name === "addaccessgroup") {
            const req = JSON.parse(message);
            this.addAccessGroup(req.message);
        }
        else if (data.name === "updateaccessgroup") {
            const req = JSON.parse(message);
            this.updateAccessGroup(req.message);
        }
        else if (data.name === "deleteaccessgroup") {
            const req = JSON.parse(message);
            this.deleteAccessGroup(req.message);
        }
        else if (data.name === "getaccessgroups") {
            this.getAccessGroup(data.message);
        }
        else if (data.name === "updateobjectacl") {
            const req = JSON.parse(message);
            this.updateObjectAcl(req.message);
        }
        else if (data.name === "childtags") {
            TStore.loadChildTags();
        }
        else if (data.name === "addtagtoletr") {
            const req = JSON.parse(message);
            this.addTagToLetr(req.message);
        }
        else if (data.name === "getproject") {
            const req = JSON.parse(message);
            this.getProjectObject(req.message);
        }
        else if (data.name === "isadmin") {
            this.checkIsAdmin();
        }
        else if (data.name === "messagereadtime") {
            const req = JSON.parse(message);
            this.readMessageTime(req.message.letrid, req.message.messageid);
        }
        else if (data.name === "orgverificationdetail") {
            this.getOrgverificationDetail();
        }
        else if (data.name === "acceptpolicies") {
            this.acceptPolicies();
        }
        else if (data.name === "policies") {
            this.getPolicies();
        }
        else if (data.name === "calculateaclstring") {
            const req = JSON.parse(message);
            this.calcauletAclString(req.message.letrid, req.message.groupids);
        }
        else if (data.name === "updatemessageacl") {
            const req = JSON.parse(message);
            this.updateMessageAcl(req.message.id, req.message.letrid, req.message.aclids);
        }
        else if (data.name === "getmyprofile") {
            this.getMyProfile();
        }
        else if (data.name === "addinternaluser") {
            const req = JSON.parse(message);
            this.addInternalUser(req.message);
        }
        else if (data.name === "loadinternalusers") {
            this.loadInternalUser();
        }
        else if (data.name === "getmyteam") {
            this.getMyTeam();
        }
        else if (data.name === "getmyletrwingcontacts") {
            this.getMyLetrwingContacts();
        }
        else if (data.name === "loadorgcontacts") {
            this.loadOrgContacts();
        }
        else if (data.name === "editorgcontact") {
            const req = JSON.parse(message);
            this.editOrgContact(req.message);
        }
        else if (data.name === "getcompanydirectory") {
            this.getComapnyDirectory();
        }
        else if (data.name === "connect") {
            const req = JSON.parse(message);
            this.connect(req.message.id);
        }
        else if (data.name === "publish") {
            const req = JSON.parse(message);
            this.publish(req.message.id);
        }
        else if (data.name === "contactpublishable") {
            const req = JSON.parse(message);
            this.checkContactCanBePublished(req.message.id);
        }
        else if (data.name === "createdraft") {
            const req = JSON.parse(message);
            this.createDraft(req.message);
        }
        else if (data.name === "declineletrcall") {
            const req = JSON.parse(message);
            this.declineLetrCall(req.message);
        }
        else if (data.name === "draftdetails") {
            const req = JSON.parse(message);
            this.getDraftDetails(req.message);
        }
        else if (data.name === 'draftedit') {
            const res = JSON.parse(message);
            this.getDraftEdit(res.message);
        }
        else if (data.name === 'draftcontent') {
        }
        else if (data.name === 'savedraftcontet') {
            const res = JSON.parse(message);
            this.saveDraftContent(res.message);
            // now lets store this using draft store!
        }
        else if (data.name === 'publishdraftcontent') {
            const res = JSON.parse(message);
            this.publisgDraft(res.message);
        }
        else if (data.name === 'updateDraftLock') {
            const res = JSON.parse(message);
            this.updateDraftLock(res.message);
        }
        else if (data.name === 'formatpaste') {
            this.formatPaste(data.message);
        }
        else if (data.name === 'fetchcalllogs') {
            const res = JSON.parse(message);
            this.fetchcCallLogs(res.message);
        }
        else if (data.name === 'draftexport') {
            const res = JSON.parse(message);
            this.getExportDraft(res.message);
        }
        else if (data.name === 'draftexportstatus') {
            const res = JSON.parse(message);
            this.getExportDraftStatus(res.message);
        }
        else if (data.name === "admingetorgdetails") {
            this.getAdminOrgDetails();
        }
        else if (data.name === "getuserdevices") {
            const res = JSON.parse(message);
            this.getUserDevices(res.message.userid);
        }
        else if (data.name === "adminusers") {
            this.getUsers();
        }
        else if (data.name === 'createeditdocument') {
            const res = JSON.parse(message);
            this.createEditDocument(res.message);
        }
        else if (data.name === "admindownloadfile") {
            const req = JSON.parse(message);
            this.adminDownloadFile(req.message);
        }
        else if (data.name === 'adminmessages') {
            const req = JSON.parse(message);
            this.handleAdminMessageReq(req.message);
        }
        else if (data.name === "adminloadusertags") {
            const req = JSON.parse(message);
            this.adminLoadUserTags(req.message.userid);
        }
        else if (data.name === "adminloaduserletrsfortag") {
            const req = JSON.parse(message);
            this.adminLoadUserLetrsForTag(req.message.userid, req.message.filter);
        }
        else if (data.name === "adminedituser") {
            const req = JSON.parse(message);
            this.editUser(req.message);
        }
        else if (data.name === "deleteuser") {
            const req = JSON.parse(message);
            this.deleteUser(req.message);
        }
        else if (data.name === "admingetletrwingcontacts") {
            this.adminGetLetriwngContacts();
        }
        else if (data.name === "admindeleteletrwingcontact") {
            const req = JSON.parse(message);
            this.adminDeleteLetrwingContact(req.message);
        }
        else if (data.name === "adminupdatewatermark") {
            const req = JSON.parse(message);
            this.updateWatermark(req.message.watermark);
        }
        else if (data.name === "adminupdatecontactemail") {
            const req = JSON.parse(message);
            this.updateContactEmail(req.message.email, req.message.address);
        }
        else if (data.name === "admingetlicenseinfo") {
            this.adminGetLicenseInfo();
        }
        else if (data.name === "admineditdevice") {
            const req = JSON.parse(message);
            this.adminEditDevice(req.message);
        }
        else if (data.name === "admincancelinvite") {
            const req = JSON.parse(message);
            this.adminCencelInvite(req.message);
        }
        else if (data.name === "adminupdateuserdetail") {
            const req = JSON.parse(message);
            this.adminUpdateUserDetail(req.message);
        }
        else if (data.name === "searchdrive") {
            const req = JSON.parse(message);
            this.searchDrive(req.message);
        }
        else if (data.name === "driveprojectids") {
            const req = JSON.parse(message);
            this.getProjectObjectDetails(req.message);
        }
        else if (data.name === 'letrsearch') {
            const req = JSON.parse(message);
            this.getLetrSearchReq(req.message);
        }
        else if (data.name === 'messagesbyids') {
            const req = JSON.parse(message);
            this.getMessagesById(req.message);
        }
        else if (data.name === "miniletrs") {
            const req = JSON.parse(message);
            this.getMiniLetrs(req.message);
        }
        else if (data.name === "admingetproject") {
            const req = JSON.parse(message);
            this.getAdminProjectObjects(req.message);
        }
        else if (data.name === "createletrlink") {
            const req = JSON.parse(message);
            this.createLetrLink(req.message);
        }
        else if (data.name === "getletrlink") {
            const req = JSON.parse(message);
            this.getLetrLinks(req.message);
        }
        else if (data.name === "deleteletrlink") {
            const req = JSON.parse(message);
            this.deleteLetrLink(req.message);
        }
        else if (data.name === "sendlinkemail") {
            const req = JSON.parse(message);
            this.sendEmailLink(req.message);
        }
        else if (data.name === 'adminpdfpage') {
            const req = JSON.parse(message);
            this.adminGetPdfFilePage(req.message);
        }
        else if (data.name === "admindraftdetails") {
            const req = JSON.parse(message);
            this.getAdminDraftDetails(req.message);
        }
        else if (data.name === "admindraftview") {
            const req = JSON.parse(message);
            this.getAdminDraftContent(req.message);
        }
        else if (data.name === "getprojectfolder") {
            const req = JSON.parse(message);
            this.getProjectFolders(req.message);
        }
        else if (data.name === "adminletrdetail") {
            const req = JSON.parse(message);
            this.getAdminLetrDetail(req.message);
        }
        else if (data.name === "admineditletrmember") {
            const req = JSON.parse(message);
            this.adminLetrEditRequest(req.message);
        }
        else if (data.name === "admindeleteletr") {
            const req = JSON.parse(message);
            this.adminDeleteLetrRequest(req.message);
        }
        else if (data.name === "getobjectparents") {
            const req = JSON.parse(message);
            this.getProjectParent(req.message);
        }
        else if (data.name === "savenote") {
            const req = JSON.parse(message);
            this.saveNote(req.message);
        }
        else if (data.name === "getnotes") {
            const req = JSON.parse(message);
            this.getNotes(req.message);
        }
        else if (data.name === "activeobjectid") {
            const req = JSON.parse(message);
            if (req.message.updatetype === ObjectUpdateType.Note) {
                LiveStore.checkUpdatesForObjectNotes(req.message.objectid, req.message.letrid, req.message.since, req.message.referencid);
            }
            else if (req.message.updatetype === ObjectUpdateType.Comment) {
                LiveStore.checkUpdatesForNotesComments((_a = req.message.noteid) !== null && _a !== void 0 ? _a : '', req.message.letrid, req.message.since);
            }
        }
        else if (data.name === "updatenote") {
            const req = JSON.parse(message);
            this.updateNote(req.message);
        }
        else if (data.name === "deletenote") {
            const req = JSON.parse(message);
            this.deleteNote(req.message);
        }
        else if (data.name === "deletecomment") {
            const req = JSON.parse(message);
            this.deleteComment(req.message);
        }
        else if (data.name === "addcomment") {
            const req = JSON.parse(message);
            this.addComment(req.message);
        }
        else if (data.name === "getcomments") {
            const req = JSON.parse(message);
            this.getComments(req.message);
        }
        else if (data.name === "deletedevice") {
            const res = JSON.parse(message);
            this.deleteDevice(res.message);
        }
        else if (data.name === "adminresetpassword") {
            const res = JSON.parse(message);
            this.resetAdminPassword(res.message);
        }
        else if (data.name === "adddraftupdate") {
            const res = JSON.parse(message);
            this.addDraftUpdate(res.message);
        }
        else if (data.name === "montiordeltas") {
            const res = JSON.parse(message);
            LiveStore.checkUpdatesForObjectDelta(res.message.letrid, res.message.objectid, res.message.draftid, res.message.time, res.message.id);
        }
        else if (data.name === "createtab") {
            const res = JSON.parse(message);
            this.createTab(res.message);
        }
        else if (data.name === "gettabs") {
            const res = JSON.parse(message);
            if (res.message.getall) {
                this.getAllTabs(res.message);
            }
            else {
                this.getTabs(res.message);
            }
        }
        else if (data.name === "deletetab") {
            const res = JSON.parse(message);
            this.deleteTab(res.message);
        }
        else if (data.name === 'addversion') {
            const res = JSON.parse(message);
            this.addVersion(res.message);
        }
        else if (data.name === 'getversion') {
            const res = JSON.parse(message);
            this.getVersion(res.message);
        }
        else if (data.name === 'recoverycodedownloaded') {
            DStore.recoveryCodeDownloaded();
        }
        else if (data.name === 'getrecoverycode') {
            this.getRecoveryCode(data.message);
        }
        else if (data.name === 'resetpasswordemail') {
            this.sendCodeForResetEmail(data.message);
        }
        else if (data.name === 'resetpasswordemailpin') {
            const res = JSON.parse(message);
            this.verifyPinForReset(res.message);
        }
        else if (data.name === 'resetuserpassword') {
            const res = JSON.parse(message);
            this.resetUserPassword(res.message);
        }
        else if (data.name === 'addschema') {
            const res = JSON.parse(message);
            this.addSchema(res.message);
        }
        else if (data.name === 'updateschema') {
            const res = JSON.parse(message);
            this.updateSchema(res.message);
        }
        else if (data.name === 'deleteschema') {
            const res = JSON.parse(message);
            this.deleteSchema(res.message);
        }
        else if (data.name === 'getschemas') {
            const res = JSON.parse(message);
            this.getSchemas(res.message);
        }
        else if (data.name === 'addentry') {
            const res = JSON.parse(message);
            this.addEntry(res.message);
        }
        else if (data.name === 'addentries') {
            const res = JSON.parse(message);
            this.addEntries(res.message);
        }
        else if (data.name === 'getrows') {
            const res = JSON.parse(message);
            this.getRows(res.message);
        }
        else if (data.name === 'adddatasheet') {
            const res = JSON.parse(message);
            this.addDatasheet(res.message);
        }
        else if (data.name === 'getdatasheets') {
            const res = JSON.parse(message);
            this.getDatasheets(res.message);
        }
        else if (data.name === 'updaterow') {
            const res = JSON.parse(message);
            this.updateEntry(res.message);
        }
        else if (data.name === 'addrowtochildsch') {
            const res = JSON.parse(message);
            this.addRowToChild(res.message);
        }
        else if (data.name === 'deleterows') {
            const res = JSON.parse(message);
            this.deleteRows(res.message);
        }
        else if (data.name === 'removeowfromchildsch') {
            const res = JSON.parse(message);
            this.removeRowFromChild(res.message);
        }
        else if (data.name === "admingetprojectfolder") {
            const req = JSON.parse(message);
            this.adminGetProjectFolders(req.message);
        }
        else if (data.name === "admingetnotes") {
            const req = JSON.parse(message);
            this.adminGetNotes(req.message);
        }
        else if (data.name === "updatedatasheet") {
            const req = JSON.parse(message);
            this.updateDatasheet(req.message);
        }
        else if (data.name === 'deletedatasheet') {
            const res = JSON.parse(message);
            this.deleteDatasheet(res.message);
        }
        else if (data.name === 'admingetdatasheets') {
            const res = JSON.parse(message);
            this.adminGetDatasheets(res.message);
        }
        else if (data.name === 'admingetrows') {
            const res = JSON.parse(message);
            this.adminGetRows(res.message);
        }
        else if (data.name === 'admingetschemas') {
            const res = JSON.parse(message);
            this.adminGetSchemas(res.message);
        }
        else if (data.name === "admingetcomments") {
            const req = JSON.parse(message);
            this.adminGetComments(req.message);
        }
        else if (data.name === 'addbookmark') {
            const req = JSON.parse(message);
            this.addBookmark(req.message);
        }
        else if (data.name === 'deletebookmark') {
            const req = JSON.parse(message);
            this.deleteBookmark(req.message);
        }
        else if (data.name === 'addmetadata') {
            const req = JSON.parse(message);
            this.updateMetadataItem(req.message);
        }
        else if (data.name === 'getmetadata') {
            const req = JSON.parse(message);
            this.getMetadata(req.message);
        }
        else if (data.name === "addfieldtoobject") {
            const req = JSON.parse(message);
            this.addFieldToObject(req.message);
        }
        else if (data.name === "deleterole") {
            const req = JSON.parse(message);
            this.deleteRole(req.message);
        }
        else if (data.name === "addrole") {
            const req = JSON.parse(message);
            this.addRole(req.message);
        }
        else if (data.name === "getroles") {
            this.getRoles(data.message);
        }
        else if (data.name === "updaterole") {
            const req = JSON.parse(message);
            this.updateRole(req.message);
        }
        else if (data.name === "updateletrmember") {
        }
        else if (data.name === "storagelogs") {
            const req = JSON.parse(message);
            this.adminStorageLogs(req.message);
        }
        else if (data.name === "recentbills") {
            this.getRecentBills();
        }
        else if (data.name === "startbill") {
            this.startBillingPayment(data.message);
        }
        else if (data.name === "billdone") {
            this.paymentDone(data.message);
        }
        else if (data.name === "getupdatelogs") {
            const logs = JSON.parse(message);
            this.getUpdateLogs(logs.message);
        }
        else if (data.name === "getletrlogs") {
            const logs = JSON.parse(message);
            this.getLetrLogs(logs.message);
        }
        else if (data.name === "getletrupdates") {
            LiveStore.getLetrUpdates(data.message);
        }
        else if (data.name === "activateuser") {
            this.activateUser(data.message);
        }
        else if (data.name === "enableuser") {
            this.enableUser(data.message);
        }
        else if (data.name === "assignedtask") {
            const logs = JSON.parse(message);
            this.getAssignedTasks(logs.message);
        }
        else if (data.name === "newassignedtasks") {
            this.getNewTasks();
        }
        else if (data.name === "buildnameindex") {
            this.buildLterIndex(data.message);
        }
        else if (data.name === "addWatermark") {
            const logs = JSON.parse(message);
            this.addWatermark(logs.message);
        }
        else if (data.name === "editcomment") {
            const reqc = JSON.parse(message);
            this.editComment(reqc.message);
        }
        else if (data.name === "processingfiles") {
            const reqx = JSON.parse(message);
            this.getActiveFiles(reqx.message);
        }
        else if (data.name === "admingetroles") {
            const req = JSON.parse(message);
            this.adminGetRoles(req.message);
        }
        else if (data.name === "addtojobqueue") {
            const req = JSON.parse(message);
            this.addToJobQueue(req.message);
        }
        else if (data.name === "adminenableusers") {
            const req = JSON.parse(message);
            this.adminEnableUser(req.message);
        }
        else if (data.name === "admingetletrlink") {
            const req = JSON.parse(message);
            this.adminGetLetrLinks(req.message);
        }
        else if (data.name === "admindeleteletrlink") {
            const req = JSON.parse(message);
            this.adminDeleteLetrLink(req.message);
        }
        else if (data.name === "addwebform") {
            const req = JSON.parse(message);
            this.addWebForm(req.message.req);
        }
        else if (data.name === "updatewebform") {
            const req = JSON.parse(message);
            this.updateWebForm(req.message.req);
        }
        else if (data.name === "getwebform") {
            const req = JSON.parse(message);
            this.getWebForm(req.message);
        }
        else if (data.name === "deletewebform") {
            const req = JSON.parse(message);
            this.deleteWebForm(req.message);
        }
        else if (data.name === "adminassignedtask") {
            const logs = JSON.parse(message);
            this.getAssignedTasks(logs.message);
        }
        else if (data.name === "addprinttemplate") {
            const req = JSON.parse(message);
            this.addPrintTemplate(req.message);
        }
        else if (data.name === "updateprinttemplate") {
            const req = JSON.parse(message);
            this.updatePrintTemplate(req.message);
        }
        else if (data.name === "getprinttemplate") {
            const req = JSON.parse(message);
            this.getPrintTemplate(req.message);
        }
        else if (data.name === "deleteprinttemplate") {
            const req = JSON.parse(message);
            this.deletePrintTemplate(req.message);
        }
        else if (data.name === 'schemaanalytics') {
            const req = JSON.parse(message);
            this.getSchemaAnalytics(req.message);
        }
        else if (data.name === 'dependententries') {
            const req = JSON.parse(message);
            this.getSchemaDependent(req.message);
        }
        else if (data.name === 'addrowrule') {
            const req = JSON.parse(message);
            this.addRowRule(req.message);
        }
        else if (data.name === 'admindependententries') {
            const req = JSON.parse(message);
            this.adminGetSchemaDependent(req.message);
        }
        else if (data.name === 'adminschemaanalytics') {
            const req = JSON.parse(message);
            this.adminGetSchemaAnalytics(req.message);
        }
        else if (data.name === 'admingetmetadata') {
            const req = JSON.parse(message);
            this.adminGetMetadata(req.message);
        }
        else if (data.name === 'duplicaterows') {
            const res = JSON.parse(message);
            this.duplicateRows(res.message);
        }
        else if (data.name === "addappuser") {
            const res = JSON.parse(message);
            this.addAppUser(res.message);
        }
        else if (data.name === "updateappuser") {
            const res = JSON.parse(message);
            this.updateAppUser(res.message);
        }
        else if (data.name === "getappusers") {
            const res = JSON.parse(message);
            this.getAppUsers(res.message);
        }
        else if (data.name === "deleteappusers") {
            const res = JSON.parse(message);
            this.deleteAppUser(res.message);
        }
        else if (data.name === "activeschemaupdate") {
            const req = JSON.parse(message);
            LiveStore.checkSchemaUpdates((_b = req.message.letrid) !== null && _b !== void 0 ? _b : '', req.message.datasheetid, req.message.since, req.message.schemaid);
        }
        else if (data.name === 'orgappusers') {
            this.getOrgAppUser();
        }
        else if (data.name === 'getremoterows') {
            const res = JSON.parse(message);
            this.getRemoteRows(res.message);
        }
        else if (data.name === "updaterows") {
            const res = JSON.parse(message);
            this.updateRows(res.message);
        }
        else if (data.name === 'getreportschemas') {
            const res = JSON.parse(message);
            this.getReportSchema(res.message);
        }
        else if (data.name === "getwebviews") {
            const res = JSON.parse(message);
            this.getWebViews(res.message);
        }
        else if (data.name === 'addwebview') {
            const res = JSON.parse(message);
            this.addWebView(res.message);
        }
        else if (data.name === 'deletewebview') {
            const res = JSON.parse(message);
            this.deleteWebView(res.message);
        }
        else if (data.name === 'updatewebview') {
            const res = JSON.parse(message);
            this.updateWebView(res.message);
        }
        else if (data.name === 'attachbarcode') {
            const res = JSON.parse(message);
            this.attachBarcode(res.message);
        }
        else if (data.name === 'addtriggers') {
            const res = JSON.parse(message);
            this.addTriggers(res.message);
        }
        else if (data.name === 'gettriggers') {
            const res = JSON.parse(message);
            this.getTriggers(res.message);
        }
        else if (data.name === 'renameletr') {
            const res = JSON.parse(message);
            this.renameLetr(res.message);
        }
        else if (data.name === "updateschemadeprows") {
            const res = JSON.parse(message);
            this.updateSchemaDependRows(res.message);
        }
        else if (data.name === "getintegrations") {
            const res = JSON.parse(message);
            this.getIntegrations(res.message);
        }
        else if (data.name === "addintegration") {
            const res = JSON.parse(message);
            this.addIntegration(res.message);
        }
        else if (data.name === "deleteintegration") {
            const res = JSON.parse(message);
            this.deleteIntegration(res.message);
        }
        else if (data.name === "updateintegration") {
            const res = JSON.parse(message);
            this.updateIntegration(res.message);
        }
        else if (data.name === "updateandaddrows") {
            const res = JSON.parse(message);
            this.updateAndAddRows(res.message);
        }
        else if (data.name === "getUpdatesSince") {
            const res = JSON.parse(message);
            this.getUpdatesSince(res.message);
        }
        else if (data.name === "storeschemastatedata") {
            const res = JSON.parse(message);
            this.storeSchemaStateData(res.message);
        }
        else if (data.name === "getschemastatedata") {
            const res = JSON.parse(message);
            this.getSchemaStateData(res.message);
        }
    }
    async addIntegration(req) {
        const sav = await SchemaStore.addIntegration(req);
        this.sendMessage("addintegration", sav);
    }
    async updateIntegration(req) {
        const sav = await SchemaStore.updateIntegration(req);
        this.sendMessage("addintegration", sav);
    }
    async deleteIntegration(req) {
        const sav = await SchemaStore.deleteIntegration(req);
        const resp = {
            ok: sav,
            id: req.id
        };
        this.sendMessage("deleteintegration", resp);
    }
    async getIntegrations(req) {
        const ints = await SchemaStore.getIntegrations(req.schemaid, req.letrid);
        this.sendMessage("getintegrations", ints !== null && ints !== void 0 ? ints : []);
    }
    async addTriggers(tis) {
        var _a, _b;
        const res = await SchemaStore.addTriggers(tis, (_a = tis[0].schemaid) !== null && _a !== void 0 ? _a : '', (_b = tis[0].letrid) !== null && _b !== void 0 ? _b : '');
        const resp = {
            ok: res === undefined ? false : true,
            tigs: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("addtriggers", resp);
    }
    async getTriggers(tis) {
        var _a, _b;
        const res = await SchemaStore.getTriggers((_a = tis.schemaid) !== null && _a !== void 0 ? _a : '', (_b = tis.letrid) !== null && _b !== void 0 ? _b : '');
        const resp = {
            ok: res === undefined ? false : true,
            tigs: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("gettriggers", resp);
    }
    async deleteWebView(req) {
        const res = await SchemaStore.deleteSchemaView(req.view.id, req.shid, req.letrid);
        const resp = {
            ok: res,
            id: req.view.id,
            shid: req.shid
        };
        this.sendMessage("deletewebview", resp);
    }
    async updateWebView(req) {
        const res = await SchemaStore.updateSchemaView(req.view, req.sh, req.letrid, req.asscols);
        const resp = {
            shid: req.sh.id,
            view: res
        };
        this.sendMessage("updatewebview", resp);
    }
    async renameLetr(req) {
        const res = await LStore.renameLetr(req.letrid, req.newname);
        const resp = {
            letrid: req.letrid,
            newname: req.newname,
            ok: res !== null && res !== void 0 ? res : false
        };
        this.sendMessage("renameletr", resp);
    }
    async attachBarcode(req) {
        const res = await SchemaStore.attachBarcode(req);
        const resp = {
            res: req,
            ok: res
        };
        this.sendMessage("attachbarcode", resp);
    }
    async addWebView(req) {
        const res = await SchemaStore.addSchemaView(req.view, req.sh, req.letrid, req.asscols);
        const resp = {
            shid: req.sh.id,
            view: res
        };
        this.sendMessage("addwebview", resp);
    }
    async getWebViews(req) {
        if (!req.sh)
            return;
        const res = await SchemaStore.getSchemaView(req.sh, req.letrid);
        const resp = {
            schemaid: req.sh.id,
            views: res
        };
        this.sendMessage("getwebviews", resp);
    }
    async getOrgAppUser() {
        const res = await GStore.getOrgAppUsers();
        this.sendMessage("getorgappusers", res);
    }
    async addAppUser(user) {
        let kbox;
        if (user.mk) {
            kbox = await UserAdminStore.checkAndAddAppContactbox(user.mk);
        }
        if (!kbox) {
            const resp = {
                us: [],
                ok: false,
                type: 'add'
            };
            this.sendMessage("addappuser", resp);
            return;
        }
        const res = await GStore.addAppUser(user.us[0], user.mk, kbox);
        const resp = {
            us: res ? [res] : [],
            ok: res ? true : false,
            type: 'add'
        };
        this.sendMessage("addappuser", resp);
    }
    async updateAppUser(user) {
        let kbox;
        if (user.mk) {
            kbox = await UserAdminStore.checkAndAddAppContactbox(user.mk);
        }
        if (!kbox) {
            const resp = {
                us: [],
                ok: false,
                type: 'update'
            };
            this.sendMessage("updateadduser", resp);
            return;
        }
        const res = await GStore.updateAppUser(user.us[0], user.mk, kbox);
        const resp = {
            us: res ? [res] : [],
            ok: res ? true : false,
            type: 'update'
        };
        this.sendMessage("updateadduser", resp);
    }
    async getAppUsers(user) {
        let kbox;
        if (user.mk) {
            kbox = await UserAdminStore.checkAndAddAppContactbox(user.mk);
        }
        const res = await GStore.getAppUsers(user.mk, kbox);
        const resp = {
            us: res ? res : [],
            ok: res ? true : false,
            type: 'get'
        };
        this.sendMessage("getappuser", resp);
    }
    async deleteAppUser(user) {
        const res = await GStore.deleteAppUser(user);
        const resp = {
            us: [],
            ok: res ? true : false,
            delids: user,
            type: 'delete'
        };
        this.sendMessage("deleteappuser", resp);
    }
    async duplicateRows(req) {
        const resp = await SchemaStore.duplicateRows(req.rids, req.sid, req.letrid, req.duplicateall);
        const ret = {
            sid: req.sid,
            letrid: req.letrid,
            rows: req.rows,
            duplicateres: resp
        };
        this.sendMessage("duplicaterowsres", ret);
    }
    async adminGetMetadata(req) {
        const ditems = await UserAdminStore.getFields(req.id, req.userid);
        const ret = {
            data: ditems !== null && ditems !== void 0 ? ditems : [],
            isChooser: req.isChooser,
            userid: req.userid,
            letrid: req.id
        };
        this.sendMessage("admingetmetadata", ret);
    }
    async adminGetSchemaDependent(req) {
        const shmap = new Map();
        const depps = await UserAdminStore.getAllDependentEnt(req.rules, req.sh, req.letrid, req.schemaid, req.en, shmap, req.userid);
        const darr = [];
        for (const [k, v] of depps) {
            const dd = {
                id: k,
                entries: Array.from(v.values())
            };
            darr.push(dd);
        }
        const res = {
            letrid: req.letrid,
            schemaid: req.schemaid,
            depn: darr,
            schemas: Array.from(shmap.values()),
            floating: req.floating,
            userid: req.userid
        };
        this.sendMessage("admindependentresp", res);
    }
    async adminGetSchemaAnalytics(req) {
        const res = await UserAdminStore.getSchemaAnalytics(req.letrid, req.userid);
        const resp = {
            letrid: req.letrid,
            ans: res,
            floating: req.floating,
            userid: req.userid
        };
        this.sendMessage("admingetschemaanaytics", resp);
    }
    async addRowRule(rulw) {
        const res = await SchemaStore.storeRules(rulw);
        this.sendMessage("rowrules", res);
    }
    async storeSchemaStateData(req) {
        const res = await SchemaStore.addSchemaStateData(req);
        const resp = {
            ok: res === undefined ? false : true,
            data: res,
            id: req.id
        };
        this.sendMessage("storeschemastatedata", resp);
    }
    async getSchemaStateData(req) {
        const res = await SchemaStore.getSchemaStateData(req.schemaid, req.letrid, req.id);
        const resp = {
            ok: res === undefined ? false : true,
            data: res,
            id: req.id
        };
        this.sendMessage("getschemastatedata", resp);
    }
    async getSchemaDependent(req) {
        const shmap = new Map();
        const done = new Map();
        if (req.alreadydoneschema) {
            for (const rd of req.alreadydoneschema) {
                done.set(rd, new Set(["attach"]));
            }
        }
        const depps = await SchemaStore.getAllDependentEnt(req.rules, req.sh, req.letrid, req.schemaid, req.en, shmap, done, true);
        const darr = [];
        for (const [k, v] of depps) {
            const dd = {
                id: k,
                entries: Array.from(v.values())
            };
            darr.push(dd);
        }
        const res = {
            letrid: req.letrid,
            schemaid: req.schemaid,
            depn: darr,
            schemas: Array.from(shmap.values()),
            floating: req.floating
        };
        this.sendMessage("dependentresp", res);
    }
    async getSchemaAnalytics(req) {
        const res = await SchemaStore.getSchemaAnalytics(req.letrid);
        const resp = {
            letrid: req.letrid,
            ans: res,
            floating: req.floating
        };
        this.sendMessage("getschemaanaytics", resp);
    }
    async getUpdatesSince(req) {
        const res = await SchemaStore.getUpdateSchemaSince(req.schemaid, req.since, req.reqschemaid, req.letrid);
        this.sendMessage("getupdatesince", res);
    }
    async updateAndAddRows(req) {
        if (req.entries.length > 0) {
            const res = await SchemaStore.updateAndDeleteEntries(req.entries, [], req.letrid, req.schema.id, req.schema.accesskey, req.schema.nonce, req.schema.items);
        }
        if (req.delentries.length > 0) {
            const re1 = await SchemaStore.storeEntriesInChunk(req.delentries, req.letrid, req.schema.id, req.schema.accesskey, req.schema.nonce, "", req.schema.items);
        }
        this.sendMessage("updateandaddrows", "done");
    }
    async updateRows(req) {
        const res = await SchemaStore.updateAndDeleteEntries(req.entries, req.delentries, req.letrid, req.schema.id, req.schema.accesskey, req.schema.nonce, req.schema.items);
        const resp = {
            row: res !== null && res !== void 0 ? res : [],
            schemaid: req.schema.id,
            letrid: req.letrid
        };
        this.sendMessage("updaterows", resp);
    }
    async adminGetAssignedTasks(req) {
        const res = await UserAdminStore.getUserAssignedTasks(req);
        const resp = {
            logs: res !== null && res !== void 0 ? res : [],
            reverse: req.reverse,
            userid: req.userid
        };
        this.sendMessage("adminassignedtask", resp);
    }
    async deletePrintTemplate(id) {
        const res = await SchemaStore.deleteSchemaPrintTemplate(id.id, id.schemaid, id.letrid);
        const resp = {
            id: id.id,
            ok: res !== null && res !== void 0 ? res : false
        };
        this.sendMessage("deleteprinttemplate", resp);
    }
    async getPrintTemplate(form) {
        const res = await SchemaStore.getSchemaPrintTemplate(form.schemaid, form.letrid);
        this.sendMessage("getprinttemplate", res);
    }
    async addPrintTemplate(form) {
        const res = await SchemaStore.addSchemaPrintTemplate(form);
        const resp = {
            ok: res !== undefined,
            res: res !== null && res !== void 0 ? res : form
        };
        this.sendMessage("addprinttemplate", resp);
    }
    async updatePrintTemplate(form) {
        const res = await SchemaStore.updateSchemaPrintTemplate(form);
        const resp = {
            ok: res !== undefined,
            res: res !== null && res !== void 0 ? res : form
        };
        this.sendMessage("updateprinttemplate", resp);
    }
    async deleteWebForm(id) {
        const res = await SchemaStore.deleteSchemaWebForm(id.id, id.schemaid, id.letrid);
        const resp = {
            id: id.id,
            ok: res !== null && res !== void 0 ? res : false
        };
        this.sendMessage("deletewebform", resp);
    }
    async getWebForm(form) {
        const res = await SchemaStore.getSchemaWebForm(form.schemaid, form.letrid);
        this.sendMessage("getwebform", res);
    }
    async addWebForm(form) {
        const res = await SchemaStore.addSchemaWebForm(form);
        const resp = {
            ok: res !== undefined,
            res: res !== null && res !== void 0 ? res : form
        };
        this.sendMessage("addwebform", resp);
    }
    async updateWebForm(form) {
        const res = await SchemaStore.updateSchemaWebForm(form);
        const resp = {
            ok: res !== undefined,
            res: res !== null && res !== void 0 ? res : form
        };
        this.sendMessage("updatewebform", resp);
    }
    async adminDeleteLetrLink(req) {
        const res = await UserAdminStore.deleteLetrLink(req.letrid, req.id, req.userid);
        const resp = {
            letrid: req.letrid,
            id: req.id,
            userid: req.userid,
            ok: res
        };
        this.sendMessage("admindeletelinkletr", resp);
    }
    async adminGetLetrLinks(req) {
        const res = await UserAdminStore.getLetrLinks(req.letrid, req.userid);
        const resp = {
            letrid: req.letrid,
            userid: req.userid,
            links: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("admingetletrlinkres", resp);
    }
    async adminEnableUser(req) {
        let retarr = [];
        for (let uid of req.userid) {
            const res = await UserAdminStore.enableUser(uid);
            retarr.push({ userid: uid, success: res });
        }
        this.sendMessage("adminedituserres", retarr);
    }
    async adminGetRoles(req) {
        const res = await UserAdminStore.getAllLetrRoles(req.letrid, req.userid);
        this.sendMessage("admingetrole", res);
    }
    async addToJobQueue(req) {
        var _a;
        await FStore.addUploadedFileToJobQueue(req.reference, req.accesskey, 0, req.name, '', req.letrid, req.ocr, req.ocrlang, false);
        const res = {
            id: req.reference,
            letrid: req.letrid,
            requesttype: FileDownloadType.Metadata,
        };
        const info = await FStore.getFileMetadata(req.reference, (_a = req.accesskey) !== null && _a !== void 0 ? _a : "");
        if (info !== undefined) {
            res.info = info;
        }
        res.ischooser = false;
        this.sendMessage("downloadfile", res);
    }
    async getActiveFiles(req) {
        const res = await FStore.getActiveFilesSince(req);
        const resp = {
            reverse: req.reverse,
            files: res
        };
        this.sendMessage("processingfiles", resp);
    }
    async addWatermark(req) {
        const res = await LStore.addWatermark(req.letrid, req.watermark);
        req.ok = res;
        this.sendMessage("addwatermark", req);
    }
    async buildLterIndex(letrid) {
        await SearchStore.buildNameIndexForLetr(letrid);
    }
    async getAssignedTasks(req) {
        const res = await UnreadStore.getUserAssignedTasks(req);
        const resp = {
            logs: res !== null && res !== void 0 ? res : [],
            reverse: req.reverse
        };
        this.sendMessage("assignedtask", resp);
    }
    async getNewTasks() {
        const res = await UnreadStore.getAllNewTasks();
        this.sendMessage("newassignedtasks", res !== null && res !== void 0 ? res : []);
    }
    async activateUser(userid) {
        const res = await UserAdminStore.activateUser(userid);
        const resp = {
            success: res === 0 ? true : false,
            userid: userid
        };
        this.sendMessage("activateuser", resp);
    }
    async getLetrLogs(req) {
        const resp = await UnreadStore.getLetrUpdates(req);
        this.sendMessage("getletrlogs", resp);
    }
    async getUpdateLogs(req) {
        const logs = await UnreadStore.getAllUnreadLogs(req);
        const res = {
            logs: logs,
            reverse: req.reverse
        };
        this.sendMessage("getrecentlogs", res);
    }
    async paymentDone(id) {
        const res = await UserAdminStore.billPaid(id);
        this.sendMessage("billdone", res);
    }
    async startBillingPayment(id) {
        let res = await UserAdminStore.startBillPayment(id);
        if (!res) {
            const dummy = {
                bill: undefined,
                billid: id,
                ordertoken: ''
            };
            res = dummy;
        }
        else {
            res.billid = id;
        }
        this.sendMessage("startbill", res);
    }
    async getRecentBills() {
        const ret = await UserAdminStore.getRecentBills();
        this.sendMessage("recentbills", ret !== null && ret !== void 0 ? ret : []);
    }
    async deleteRole(role) {
        const res = await LStore.deleteRole(role);
        const resp = {
            ok: res,
            id: role.id
        };
        this.sendMessage("deleterole", resp);
    }
    async addRole(role) {
        const res = await LStore.addRole(role);
        const resp = {
            ok: res !== undefined ? true : false,
            role: res,
            rid: role.id
        };
        this.sendMessage("addrole", resp);
    }
    async enableUser(userid) {
        const res = await UserAdminStore.enableUser(userid);
        const resp = {
            success: res,
            userid: userid
        };
        this.sendMessage("activateuser", resp);
    }
    async getRoles(id) {
        const res = await LStore.getAllLetrRoles(id);
        this.sendMessage("getrole", res);
    }
    async updateRole(role) {
        const res = await LStore.updateRole(role);
        const resp = {
            ok: res !== undefined ? true : false,
            role: res,
            rid: role.id
        };
        this.sendMessage("updaterole", resp);
    }
    async addFieldToObject(req) {
        const resp = await ProjectStore.addMetadataToObject(req.objectid, req.letrid, req.items, req.sitems);
        const result = {
            objectid: req.objectid,
            letrid: req.letrid,
            obejct: resp
        };
        this.sendMessage("addfieldtoobject", result);
    }
    async getMetadata(req) {
        const ditems = await ProjectStore.getFields(req.id);
        const ret = {
            data: ditems !== null && ditems !== void 0 ? ditems : [],
            isChooser: req.isChooser
        };
        this.sendMessage("getmetadataobject", ret);
    }
    async updateMetadataItem(req) {
        if (req.additems.length > 0) {
            await ProjectStore.addFields(req.letrid, req.additems);
        }
        if (req.deleteitems.length > 0) {
            await ProjectStore.deleteFields(req.letrid, req.deleteitems.map((di) => di.id));
        }
        const ditems = await ProjectStore.getFields(req.letrid);
        const ret = {
            data: ditems !== null && ditems !== void 0 ? ditems : [],
            isChooser: false
        };
        this.sendMessage("getmetadataobject", ret);
        this.sendMessage("addmetadataobject", ditems !== null && ditems !== void 0 ? ditems : []);
    }
    async deleteBookmark(req) {
        const resp = {
            result: [],
            fileid: req.fileid
        };
        for (const id of req.id) {
            const res = await FStore.deleteBookmark(id, req.fileid);
            resp.result.push({
                id: id,
                ok: res !== null && res !== void 0 ? res : false
            });
        }
        this.sendMessage("deletebookmark", resp);
    }
    async addBookmark(req) {
        if (req.bookmark) {
            const res = await FStore.addBookmark(req.bookmark, req.key, req.fileid);
            req.bookmark = res;
            this.sendMessage("addbookmark", req);
        }
    }
    async deleteDatasheet(req) {
        const ok = await SchemaStore.deleteDatasheet(req.id, req.letrid);
        const ares = {
            ok: ok,
            id: req.id
        };
        this.sendMessage("deletedatasheet", ares);
    }
    async deleteRows(req) {
        const ret = await SchemaStore.deleteRows(req.rids, req.sid, req.letrid, req.deleteall);
        const resp = {
            sid: req.sid,
            letrid: req.letrid,
            rids: [],
        };
        if (ret !== undefined) {
            if (req.deleteall) {
                resp['deleteall'] = true;
            }
            else {
                resp['rids'] = ret.rowids;
            }
        }
        this.sendMessage("deleterows", resp);
    }
    async addRowToChild(row) {
        const res = await SchemaStore.addRowToSchema(row);
        const resp = {
            ok: res,
            schemaid: row.schemaid,
            childschema: row.childid,
            rowid: row.rowids
        };
        this.sendMessage("addrowtochildsch", resp);
    }
    async removeRowFromChild(row) {
        const res = await SchemaStore.removeRowToSchema(row);
        const resp = {
            ok: res,
            schemaid: row.schemaid,
            childschema: row.childid,
            rowid: row.rowids
        };
        this.sendMessage("removerowfromchildsch", resp);
    }
    async updateEntry(req) {
        var _a, _b, _c;
        const res = await SchemaStore.updateEntries(req.entries, req.letrid, req.sid, req.accesskey, req.nonce, (_a = req.rowid) !== null && _a !== void 0 ? _a : '', req.schemaitems, (_b = req.deleteentries) !== null && _b !== void 0 ? _b : [], req.nvs, req.assigns, (_c = req.custompackets) !== null && _c !== void 0 ? _c : []);
        const resp = {
            reqid: req.reqid,
            rowid: req.rowid,
            row: res,
            sid: req.sid,
            duplicaterequest: req.duplicaterequest
        };
        this.sendMessage("updateentry", resp);
    }
    async getDatasheets(req) {
        const ret = await SchemaStore.getAllDatasheets(req.letrid);
        this.sendMessage("getdatasheets", ret);
    }
    async adminGetDatasheets(req) {
        const ret = await UserAdminStore.getAllDatasheets(req.letrid, req.userid);
        this.sendMessage("admingetdatasheetsres", ret);
    }
    async adminStorageLogs(req) {
        const ret = await UserAdminStore.getStorageLogs(req);
        this.sendMessage("storagelogs", ret !== null && ret !== void 0 ? ret : []);
    }
    async getRows(req) {
        var _a;
        const id = Math.random() + ""; // maybe we will need two one for floating separate or maybe not .. need to test 
        const ret = await SchemaStore.loadRowsInBatches(req.letrid, req.schemaid, req.dataids, req.privatekey, req.schamenonce, req.schemakey, (loaded, rows) => {
            if (SchemaStore.activeloadingworksheetid !== id)
                return;
            const resp = {
                rows: rows,
                floating: req.floating,
                schemaid: req.schemaid,
                rules: [],
                islast: false
            };
            this.sendMessage("partialrows", resp);
        }, id);
        if (SchemaStore.activeloadingworksheetid !== id)
            return;
        const rules = await SchemaStore.getAllRules(req.schemaid, req.letrid);
        const depns = await SchemaStore.checkAndGetAllRemoteItems(req.sitems, req.letrid, req.schemaid);
        const darr = [];
        if (depns) {
            if (depns && depns.data) {
                for (const rd of depns.data) {
                    if (!rd.schemaopen)
                        continue;
                    if (rd.schemaopen.rules || rd.rulesopen) {
                        // shall we just get all the depend
                        const data = await SchemaStore.getAllDependentEnt((_a = rd.rulesopen) !== null && _a !== void 0 ? _a : [], rd.schemaopen, req.letrid, rd.schema.id, rd.entries, new Map(), new Map(), false, false);
                        // now we have everything we need and we can now do some parsing of rules as well?
                        for (const [k, v] of data) {
                            const dd = {
                                id: k,
                                entries: Array.from(v.values())
                            };
                            darr.push(dd);
                        }
                    }
                }
            }
        }
        const resp = {
            rows: ret,
            floating: req.floating,
            schemaid: req.schemaid,
            rules: rules,
            deps: depns,
            depsdep: darr,
            islast: true
        };
        if (SchemaStore.activeloadingworksheetid !== id)
            return;
        this.sendMessage("partialrows", resp);
    }
    async getRemoteRows(req) {
        var _a, _b, _c;
        const id = Math.random() + ""; // maybe we will need two one for floating separate or maybe not .. need to test 
        const ret = await SchemaStore.getRemoteRows(req.letrid, req.schemaid, (_a = req.rowids) !== null && _a !== void 0 ? _a : [], req.dataids);
        const rules = await SchemaStore.getAllRules(req.schemaid, req.letrid);
        const depns = await SchemaStore.checkAndGetAllRemoteItems((_b = req.sitems) !== null && _b !== void 0 ? _b : [], req.letrid, req.schemaid);
        const darr = [];
        if (depns) {
            if (depns && depns.data) {
                for (const rd of depns.data) {
                    if (!rd.schemaopen)
                        continue;
                    if (rd.schemaopen.rules || rd.rulesopen) {
                        // shall we just get all the depend
                        const data = await SchemaStore.getAllDependentEnt((_c = rd.rulesopen) !== null && _c !== void 0 ? _c : [], rd.schemaopen, req.letrid, rd.schema.id, rd.entries, new Map());
                        // now we have everything we need and we can now do some parsing of rules as well?
                        for (const [k, v] of data) {
                            const dd = {
                                id: k,
                                entries: Array.from(v.values())
                            };
                            darr.push(dd);
                        }
                    }
                }
            }
        }
        const resp = {
            rows: ret,
            floating: false,
            schemaid: req.schemaid,
            rules: rules,
            deps: depns,
            depsdep: darr
        };
        this.sendMessage("remoterowsres", resp);
    }
    async adminGetRows(req) {
        var _a;
        const id = Math.random() + ""; // maybe we will need two one for floating separate or maybe not .. need to test 
        const ret = await UserAdminStore.loadRowsInBatches(req.letrid, req.schemaid, req.dataids, req.privatekey, req.schamenonce, req.schemakey, (loaded, rows) => {
            if (UserAdminStore.activeloadingworksheetid !== id)
                return;
            const resp = {
                rows: rows,
                floating: req.floating,
                schemaid: req.schemaid,
                rules: [],
                islast: false,
                userid: req.userid
            };
            this.sendMessage("adminpartialrows", resp);
        }, id, req.userid);
        if (UserAdminStore.activeloadingworksheetid !== id)
            return;
        const rules = await UserAdminStore.getAllRules(req.schemaid, req.letrid, req.userid);
        const depns = await UserAdminStore.checkAndGetAllRemoteItems(req.sitems, req.letrid, req.schemaid, req.userid);
        const darr = [];
        if (depns) {
            if (depns && depns.data) {
                for (const rd of depns.data) {
                    if (!rd.schemaopen)
                        continue;
                    if (rd.schemaopen.rules || rd.rulesopen) {
                        // shall we just get all the depend
                        const data = await SchemaStore.getAllDependentEnt((_a = rd.rulesopen) !== null && _a !== void 0 ? _a : [], rd.schemaopen, req.letrid, rd.schema.id, rd.entries, new Map(), new Map(), true);
                        // now we have everything we need and we can now do some parsing of rules as well?
                        for (const [k, v] of data) {
                            const dd = {
                                id: k,
                                entries: Array.from(v.values())
                            };
                            darr.push(dd);
                        }
                    }
                }
            }
        }
        const resp = {
            rows: ret,
            floating: req.floating,
            schemaid: req.schemaid,
            rules: rules,
            deps: depns,
            depsdep: darr,
            islast: true,
            userid: req.userid
        };
        this.sendMessage("adminpartialrows", resp);
    }
    // public async adminGetRows(req: AdminGetSchemaRows) {
    //     const ret = await UserAdminStore.loadRowsInBatches(req.letrid, req.schemaid, req.userid);
    //     const rules = await UserAdminStore.getAllRules(req.schemaid, req.letrid, req.userid);
    //     const depns = await UserAdminStore.checkAndGetAllRemoteItems(req.sitems, req.letrid, req.schemaid, req.userid);
    //     const resp: AdminSchemaRowRes = {
    //         rows: ret,
    //         floating: req.floating,
    //         schemaid: req.schemaid,
    //         rules: rules,
    //         userid: req.userid,
    //         deps: depns
    //     };
    //     this.sendMessage("admingetrowsres", resp);
    // }
    async addEntry(req) {
        var _a;
        const res = await SchemaStore.storeEntry(req.entries, req.letrid, req.sid, req.accesskey, req.nonce, "", req.schemaitems, req.nvs, req.assigns, (_a = req.custompackets) !== null && _a !== void 0 ? _a : []);
        const resp = {
            reqid: req.reqid,
            row: res,
            sid: req.sid,
            duplicaterequest: req.duplicaterequest
        };
        this.sendMessage("addentry", resp);
    }
    async updateSchemaDependRows(req) {
        const res = await SchemaStore.updateSchemaDependentRows(req.entry, req.updateentry, req.letrid, req.schemaid, req.updateschemaid, req.schema.items, req.schema.accesskey, req.schema.nonce);
        const resp = {
            updateschemaid: req.updateschemaid,
            entry: res
        };
        this.sendMessage("updateschemadeprows", resp);
    }
    async addEntries(req) {
        if (req.schemaupdate) {
            const res = await SchemaStore.updateSchema(req.schemaupdate);
            if (res === undefined) {
                const resp = {
                    reqid: req.reqid,
                    row: [],
                    sid: req.sid,
                    duplicaterequest: req.duplicaterequest,
                    ok: false
                };
                this.sendMessage("addentries", resp);
                return;
            }
        }
        const res = await SchemaStore.storeEntriesInChunk(req.entries, req.letrid, req.sid, req.accesskey, req.nonce, "", req.schemaitems, req.nvs, req.assigns);
        const resp = {
            reqid: req.reqid,
            row: res === null || res === void 0 ? void 0 : res.sort((r1, r2) => r1.rowindex - r2.rowindex),
            sid: req.sid,
            duplicaterequest: req.duplicaterequest,
            ok: res !== undefined ? true : false
        };
        this.sendMessage("addentries", resp);
    }
    async addSchema(req) {
        if (!req.sh) {
            return;
        }
        const res = await SchemaStore.addSchema(req.sh);
        const ares = {
            id: req.id,
            sh: res
        };
        this.sendMessage("addschema", ares);
    }
    async updateSchema(req) {
        var _a;
        if (!req.sh) {
            return;
        }
        const res = await SchemaStore.updateSchema(req.sh, (_a = req.rowupdatereq) !== null && _a !== void 0 ? _a : []);
        const ares = {
            sch: res !== null && res !== void 0 ? res : req.sh,
            ok: res ? true : false
        };
        if (req.colEditId) {
            ares['colEditId'] = req.colEditId;
        }
        else if (req.colDeleteId) {
            ares['colDeleteId'] = req.colDeleteId;
        }
        else if (req.deletereportid) {
            ares['deletereportid'] = req.deletereportid;
        }
        this.sendMessage("updateschema", ares);
    }
    async deleteSchema(req) {
        const res = await SchemaStore.deleteSchema(req);
        const ares = {
            ok: res.valueOf(),
            id: req.id
        };
        this.sendMessage("deleteschema", ares);
    }
    async addDatasheet(req) {
        if (!req.sh) {
            return;
        }
        const res = await SchemaStore.addDatasheet(req.sh);
        const ares = {
            id: req.id,
            sh: res
        };
        this.sendMessage("adddatasheet", ares);
    }
    async updateDatasheet(req) {
        if (!req.sh) {
            return;
        }
        const res = await SchemaStore.updateDatasheet(req.sh);
        const ares = {
            ok: res !== undefined ? true : false,
            sch: res !== null && res !== void 0 ? res : req.sh
        };
        this.sendMessage("updatedatasheet", ares);
    }
    async getSchemas(req) {
        const res = await SchemaStore.getAllSchemas(req.letrid, req.datasheetid, []);
        const resp = {
            schema: res,
            floating: req.floating
        };
        this.sendMessage("getschemas", resp);
    }
    async getReportSchema(req) {
        var _a;
        const res = await SchemaStore.getAllSchemas(req.letrid, req.datasheetid, (_a = req.schemaids) !== null && _a !== void 0 ? _a : []);
        const resp = {
            schema: res,
            floating: req.floating
        };
        this.sendMessage("getreportschemas", resp);
    }
    async adminGetSchemas(req) {
        const res = await UserAdminStore.getAllSchemas(req.letrid, req.datasheetid, req.userid);
        const resp = {
            schema: res,
            floating: req.floating,
            userid: req.userid
        };
        this.sendMessage("admingetschemasres", resp);
    }
    async resetUserPassword(req) {
        const res = await Auth.resetUserPassword(req.req, req.sessionid);
        // okay we have reset the password lets reset the device and we should reload the page
        if (res) {
            Auth.deleteThiDevice(() => {
                const resp = {
                    ok: res
                };
                this.sendMessage("resetpasswordresponse", resp);
            });
        }
        else {
            const resp = {
                ok: res
            };
            this.sendMessage("resetpasswordresponse", resp);
        }
    }
    async verifyPinForReset(req) {
        const sok = await Auth.verifyResetPinAndgetBox(req.email, req.pin);
        const resp = {
            email: req.email,
            rebox: sok
        };
        this.sendMessage("resetpasswordpinresp", resp);
    }
    async sendCodeForResetEmail(email) {
        const sok = await Auth.sendOneTimePassword(email);
        const resp = {
            email: email,
            sent: false,
            isactiveaccount: true
        };
        if (sok) {
            resp.isactiveaccount = sok.tenantid ? true : false;
            resp.sent = true;
        }
        ;
        this.sendMessage("resetpasswordverifyemail", resp);
    }
    async getRecoveryCode(pwd) {
        DStore.getRecoverycode(pwd, (code) => {
            // now we can send this 
            const seccode = {
                secret: code !== null && code !== void 0 ? code : '',
                useraction: true
            };
            this.sendMessage("shownewusersecretdialog", seccode);
        });
    }
    async getVersion(obj) {
        const res = await LStore.getVersion(obj.objectid, obj.letrid);
        const resp = {
            letrid: obj.letrid,
            objectid: obj.objectid,
            vinfo: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("getversion", resp);
    }
    async addVersion(obj) {
        const res = await LStore.addVersion(obj.objectid, obj.letrid, obj.vinfo);
        const resp = {
            letrid: obj.letrid,
            objectid: obj.objectid,
            vinfo: res
        };
        this.sendMessage("addversion", resp);
    }
    async deleteTab(req) {
        const res = await LStore.deleteTab(req.id, req.letrid);
        const resp = {
            id: req.id,
            ok: res
        };
        this.sendMessage("deletetab", resp);
    }
    async getAllTabs(req) {
        var _a;
        const tabs = await LStore.getAllTabs(req.letrid, (_a = req.objectid) !== null && _a !== void 0 ? _a : '');
        const resp = {
            letrid: req.letrid,
            tabs: tabs,
            getall: true,
            objectid: req.objectid
        };
        this.sendMessage("gettabsresp", resp);
    }
    async getTabs(req) {
        const tabs = await LStore.getTabs(req.letrid);
        const resp = {
            letrid: req.letrid,
            tabs: tabs
        };
        this.sendMessage("gettabsresp", resp);
    }
    async createTab(req) {
        const res = await LStore.createNewTab(req.name, req.objectid, req.letrid, req.viewers, req.configdata);
        const resp = {
            letrid: req.letrid,
            objectid: req.objectid,
            ok: res
        };
        this.sendMessage("createtabresp", resp);
    }
    async getDraftUpdates(update) {
        const res = await DraftStore.getDraftUpdates(update.letrid, update.objectid, update.origobjectid, update.draftid, update.time);
        this.sendMessage("gotdraftupdates", res !== null && res !== void 0 ? res : []);
    }
    async addDraftUpdate(update) {
        const res = await DraftStore.storeDraftUpdate(update.letrid, update.objectid, update.draftid, update.update, update.id, update.origobjectid);
    }
    async resetAdminPassword(req) {
        const res = await UserAdminStore.resetUserPassword(req.password, req.userid);
        const resp = {
            userid: req.userid,
            password: req.password,
            ok: res !== null && res !== void 0 ? res : false
        };
        this.sendMessage("adminpasswordres", resp);
    }
    async deleteDevice(req) {
        if (req.deviceid === Auth.adeviceid) {
            Auth.deleteThiDevice((res) => {
                // now we can do things here!
                const resp = {
                    id: req.deviceid,
                    ok: res
                };
                this.getAppState();
                this.sendMessage("deletedeviceres", resp);
            });
        }
        else {
            const res = await Auth.deleteDevice(req.deviceid);
            const resp = {
                id: req.deviceid,
                ok: res
            };
            this.sendMessage("deletedeviceres", resp);
        }
    }
    async getComments(req) {
        const coms = await NoteStore.getComments(req.noteid, req.letrid, req.objectid);
        const res = {
            noteid: req.noteid,
            letrid: req.letrid,
            comments: coms !== null && coms !== void 0 ? coms : []
        };
        this.sendMessage("getcommentres", res);
    }
    async adminGetComments(req) {
        const coms = await UserAdminStore.getComments(req.noteid, req.letrid, req.objectid, req.userid);
        const res = {
            noteid: req.noteid,
            letrid: req.letrid,
            comments: coms !== null && coms !== void 0 ? coms : [],
            userid: req.userid,
            ischooser: req.floating
        };
        this.sendMessage("admingetcommentres", res);
    }
    async editComment(req) {
        const res = await NoteStore.updateComment(req.letrid, req.objectid, req.noteid, req.text);
        const resp = {
            letrid: req.letrid,
            objectid: req.objectid,
            com: res,
            cid: req.replaceid
        };
        this.sendMessage("editcomment", resp);
    }
    async addComment(req) {
        const res = await NoteStore.addComment(req.text, req.letrid, req.objectid, req.noteid);
        const resp = {
            letrid: req.letrid,
            replaceid: req.replaceid,
            noteid: req.noteid,
            comment: res
        };
        this.sendMessage("addcommentres", resp);
    }
    async deleteNote(req) {
        const res = await NoteStore.deleteNote(req);
        const resp = {
            letrid: req.letrid,
            objectid: req.objectid,
            id: req.id,
            ok: res,
            type: ObjectUpdateType.Note
        };
        this.sendMessage("deletenote", resp);
    }
    async deleteComment(req) {
        const res = await NoteStore.deleteComment(req);
        const resp = {
            letrid: req.letrid,
            objectid: req.objectid,
            id: req.id,
            ok: res,
            type: ObjectUpdateType.Comment
        };
        this.sendMessage("deletenote", resp);
    }
    async updateNote(note) {
        const res = await NoteStore.updateNote(note.note, note.letrid, note.objectid);
        const resp = {
            note: res,
            letrid: note.letrid,
            objectid: note.objectid,
            reqid: note.reqid
        };
        this.sendMessage("savednote", resp);
    }
    async saveNote(note) {
        const res = await NoteStore.savenote(note.note, note.letrid, note.objectid, note.comment);
        const resp = {
            note: res,
            letrid: note.letrid,
            objectid: note.objectid,
            reqid: note.reqid
        };
        this.sendMessage("savednote", resp);
    }
    async getNotes(note) {
        const res = await NoteStore.getNotes(note.letrid, note.objectid, note.referencid);
        const resp = {
            letrid: note.letrid,
            objectid: note.objectid,
            note: res !== null && res !== void 0 ? res : [],
            ischooser: note.ischooser
        };
        this.sendMessage("getnotesres", resp);
    }
    async adminGetNotes(note) {
        const res = await UserAdminStore.getNotes(note.letrid, note.objectid, note.referencid, note.userid);
        const resp = {
            letrid: note.letrid,
            objectid: note.objectid,
            note: res !== null && res !== void 0 ? res : [],
            ischooser: note.ischooser,
            userid: note.userid
        };
        this.sendMessage("admingetnotesres", resp);
    }
    async getProjectParent(req) {
        // we cna do clever things here now!
        const res = await ProjectStore.getParentsForObjects(req.letrid, req.ids);
        const resp = {
            letrid: req.letrid,
            parent: res !== null && res !== void 0 ? res : [],
            id: req.reqid
        };
        this.sendMessage("getobjectparentsres", resp);
    }
    async getProjectFolders(req) {
        const res = await ProjectStore.getProjectFolders(req.letrid);
        const resp = {
            letrid: req.letrid, folders: res !== null && res !== void 0 ? res : [],
            isChooser: req.isChooser
        };
        this.sendMessage("getprojectfoldersres", resp);
    }
    async adminGetProjectFolders(req) {
        const res = await UserAdminStore.getProjectFolders(req.letrid, req.userid);
        const resp = {
            letrid: req.letrid, folders: res !== null && res !== void 0 ? res : [],
            isChooser: req.isChooser
        };
        this.sendMessage("admingetprojectfoldersres", resp);
    }
    async sendEmailLink(req) {
        const res = await LStore.sendLetrLink(req.letrid, req.linkid, req.name, req.email);
        const resp = Object.assign(Object.assign({}, req), { ok: res !== null && res !== void 0 ? res : false });
        this.sendMessage("sendLetrEmailLinkres", resp);
    }
    async deleteLetrLink(req) {
        const res = await LStore.deleteLetrLink(req.letrid, req.linkid);
        const resp = {
            letrid: req.letrid,
            linkid: req.linkid,
            ok: res
        };
        this.sendMessage("deletelinkletr", resp);
    }
    async createLetrLink(req) {
        const res = await LStore.createLetrLink(req.letrid, req.name, req.allowedit, req.roleid);
        if (res !== undefined) {
            const resp = {
                letrid: req.letrid,
                letrlink: res
            };
            this.sendMessage("createletrlinksres", resp);
        }
        else {
            this.sendMessage("createletrlinksres", res);
        }
    }
    async getLetrLinks(req) {
        const res = await LStore.getLetrLinks(req.letrid);
        const resp = {
            letrid: req.letrid,
            links: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("getletrlinkres", resp);
    }
    async getMiniLetrs(req) {
        const lts = await LStore.getMiniLetrs();
        const res = {
            reqid: req,
            miniletrs: lts !== null && lts !== void 0 ? lts : []
        };
        this.sendMessage("miniletrs", res);
    }
    async getMessagesById(req) {
        const res = await MStore.getMessagesForIds(req.ids, req.letrid);
        const resp = {
            letrid: req.letrid,
            reqid: req.reqid,
            msgs: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("messagebyidsres", resp);
    }
    async getLetrSearchReq(req) {
        const res = await SearchStore.searchTerm(req.searchterm, req.letrid, req.type, "", req.operation, req.mfilter);
        const resp = {
            reqid: req.reqid,
            letrid: req.letrid,
            matches: res
        };
        this.sendMessage("letrsearch", resp);
    }
    async getProjectObjectDetails(req) {
        const res = await ProjectStore.getProjectObjectsDetailsWithParentPath(req.letrid, req.ids);
        const resp = {
            letrid: req.letrid,
            objs: res !== null && res !== void 0 ? res : [],
            reqid: req.reqid
        };
        this.sendMessage("projectobjectdetails", resp);
    }
    async searchDrive(req) {
        const res = await SearchStore.searchTerm(req.searchtext, req.letrid, req.type, "", SearchType.Phrase, req.mfilter);
        const resu = {
            letrid: req.letrid,
            searchtext: req.searchtext,
            type: req.type,
            matches: res
        };
        this.sendMessage("searchresultmatchres", resu);
    }
    async getExportDraft(req) {
        const res = await DraftStore.exportExcel(req.data, req.letrid, req.accesskey, req.draftid);
        const result = {
            draftid: req.draftid,
            letrid: req.letrid,
            submitted: res
        };
        this.sendMessage("draftexport", result);
        this.getExportDraftStatus(req);
    }
    async adminDeleteLetrRequest(req) {
        const ret = await UserAdminStore.deleteLetr(req.letrid, req.userid);
        const res = {
            letrid: req.letrid,
            result: ret
        };
        this.sendMessage("admindeleteletrres", res);
    }
    async adminLetrEditRequest(req) {
        var _a;
        const res = { type: req.type, letrid: req.letrid, userid: req.userid };
        if (req.type === LetrEditrequestType.Add) {
            const detail = await UserAdminStore.addMembersToLetr(req.letrid, req.memberid, (_a = req.tagids) !== null && _a !== void 0 ? _a : [], req.userid);
            res.detail = detail;
        }
        else if (req.type === LetrEditrequestType.Delete) {
            const detail = await UserAdminStore.removeMemberFromLetr(req.letrid, req.memberid[0].userid, false, req.userid);
            res.detail = detail;
        }
        else if (req.type === LetrEditrequestType.update) {
            const detail = await UserAdminStore.updateLetrMember(req.letrid, req.memberid, req.userid);
            res.detail = detail;
            res.memberid = req.memberid;
        }
        else if (req.type === LetrEditrequestType.updatelink) {
            const links = await UserAdminStore.updateLinksForLetr(req.letrid, req.memberid, req.userid);
            res.links = links !== null && links !== void 0 ? links : [];
            res.memberid = req.memberid;
        }
        this.sendMessage("admineditletrmember", res);
    }
    async getAdminLetrDetail(req) {
        var _a;
        let response;
        let ldetail = undefined;
        if (req.letrid !== undefined) {
            ldetail = await UserAdminStore.getLetrDetail(req.letrid, req.userid);
        }
        response = {
            type: req.type,
            error: false,
            letrs: [],
            letrdetail: ldetail,
            letrid: (_a = req.letrid) !== null && _a !== void 0 ? _a : "",
            cpassword: []
        };
        this.sendMessage("adminLetrsResponse", response);
    }
    async getAdminDraftContent(req) {
        const res = await UserAdminStore.getDraftContent(req.id, req.letrid, req.accesskey, -1, req.userid);
        const resp = {
            draftid: req.id,
            res: res
        };
        this.sendMessage("adminDraftLockResponse", resp);
    }
    async getAdminDraftDetails(req) {
        const res = await UserAdminStore.getDraftDetails(req.draftid, req.letrid, req.accesskey, req.userid);
        // now we have everything lets do something clever here
        const result = {
            draftid: req.draftid,
            detail: undefined
        };
        if (res !== undefined) {
            const rdet = {
                version: res.details,
                haseditpermission: res.hasEditPermission,
            };
            result.detail = rdet;
        }
        this.sendMessage("admindraftdetailresponse", result);
    }
    async adminGetPdfFilePage(req) {
        const page = await UserAdminStore.getPdfViewerPage({
            id: req.id, pagename: req.pagename, key: req.accesskey,
            userid: req.userid
        });
        const res = {
            pagename: req.pagename,
            id: req.id,
            page: page,
            pagenumber: req.pagenumber,
            userid: req.userid,
            ischooser: req.ischooser
        };
        this.sendMessage("adminpdfpage", res);
    }
    async getAdminProjectObjects(req) {
        const ret = await UserAdminStore.loadLetrProjects(req.letrid, req.parentid, req.userid);
        const res = {
            letrid: req.letrid,
            parentid: req.parentid,
            projects: ret,
            isChooser: false
        };
        this.sendMessage("adminprojectobjres", res);
    }
    async adminUpdateUserDetail(r) {
        const ret = await UserAdminStore.editUser(r.name, r.newrole, r.disableuser, r.userid);
        const res = { userid: r.userid, success: ret, name: r.name, newrole: r.newrole };
        this.sendMessage("adminupdateuserdetailres", res);
    }
    async adminCencelInvite(req) {
        const ret = await UserAdminStore.cancelinvite(req.userid);
        this.sendMessage("admincancelinviteres", ret);
    }
    async adminEditDevice(req) {
        const ret = await UserAdminStore.editDevice(req.userid, req.deviceid, req.type);
        const res = {
            userid: req.userid,
            deviceid: req.deviceid,
            type: req.type,
            success: ret
        };
        this.sendMessage("admineditdeviceres", res);
    }
    async adminGetLicenseInfo() {
        const res = await UserAdminStore.getQuota();
        this.sendMessage("admingetlicenseinfores", res);
    }
    async updateContactEmail(email, add) {
        const res = await UserAdminStore.updateEmail(email, add);
        this.sendMessage("adminupdatecontactemailres", res);
    }
    async updateWatermark(mark) {
        const res = await UserAdminStore.updateWatermark(mark);
        this.sendMessage("adminupdatewatermarkres", res);
    }
    async adminDeleteLetrwingContact(req) {
        let retarr = [];
        for (const c of req) {
            const res = await UserAdminStore.deleteLetrwingContact(c.connectid, c.userid);
            retarr.push({ connectid: c.connectid, userid: c.userid, success: res });
        }
        this.sendMessage("admindeleteletrwingcontactres", retarr);
    }
    async adminGetLetriwngContacts() {
        const ret = await UserAdminStore.getLetrwingContacts();
        if (ret !== undefined) {
            this.sendMessage("adminletrwingcontactsres", ret);
        }
    }
    async deleteUser(req) {
        const ret = await UserAdminStore.deleteUser(req.userid);
        const res = {
            success: ret !== null && ret !== void 0 ? ret : false,
            userid: req.userid
        };
        this.sendMessage("deleteuser", res);
    }
    async editUser(req) {
        let retarr = [];
        for (let r of req) {
            const ret = await UserAdminStore.editUser(r.name, r.newrole, r.disableuser, r.userid);
            let st = undefined;
            if (ret && !r.disableuser) {
                st = UserStatus.Active;
            }
            retarr.push({ userid: r.userid, success: ret, status: st });
        }
        this.sendMessage("adminedituserres", retarr);
    }
    async adminLoadUserLetrsForTag(userid, filter) {
        const res = await UserAdminStore.loadUserLetrsForTag(userid, filter);
        if (res !== undefined) {
            this.sendMessage("adminuserletrsfortagres", res);
        }
    }
    async adminLoadUserTags(userid) {
        const res = await UserAdminStore.getUserTags(userid);
        if (res !== undefined) {
            this.sendMessage("adminloadusertagsres", res);
        }
    }
    async adminDownloadFile(req) {
        var _a, _b;
        const res = {
            id: req.id,
            letrid: req.letrid,
            requesttype: req.requesttype,
            userid: req.userid,
            ischooser: req.isChooser
        };
        if (req.requesttype === FileDownloadType.Metadata) {
            const info = await UserAdminStore.getFileMetadata(req.id, (_a = req.accesskey) !== null && _a !== void 0 ? _a : "", (_b = req.userid) !== null && _b !== void 0 ? _b : "");
            if (info !== undefined) {
                res.info = info;
            }
            this.sendMessage("admindownloadfileres", res);
        }
        else if (req.requesttype === FileDownloadType.Download) {
            FStore.downloadFile(req, (res) => {
                res.ischooser = req.isChooser;
                res.queueid = req.queueid;
                this.sendMessage("admindownloadfileres", res);
            });
        }
    }
    async getUsers() {
        const ret = await UserAdminStore.getUsers();
        if (ret !== undefined) {
            this.sendMessage("adminusersres", ret);
        }
    }
    async getUserDevices(userid) {
        const ret = await UserAdminStore.getUserDevices(userid);
        if (ret !== undefined) {
            this.sendMessage("adminuserdevicesres", ret);
        }
    }
    async getAdminOrgDetails() {
        const ret = await UserAdminStore.getOrgDetails();
        if (ret && !ret.address) {
            ret.address = [];
        }
        this.sendMessage("adminorgdetailres", ret);
    }
    async getExportDraftStatus(req) {
        const res = await DraftStore.getDraftExportJobStatus(req.draftid, req.letrid, req.accesskey);
        // we should just send this now!!
        this.sendMessage("draftexportstatus", res);
    }
    async formatPaste(cont) {
        const res = await DraftStore.getPasteFormatted(cont);
        this.sendMessage("formatpaste", res);
    }
    async publisgDraft(content) {
        var _a;
        const cont = await DraftStore.publishContent(content.draftid, content.letrid, content.accesskey, content.content, (_a = content.contentupdatetime) !== null && _a !== void 0 ? _a : -1);
        const resp = {
            draftid: content.draftid,
            result: cont !== undefined ? true : false
        };
        this.sendMessage("publisheddraft", resp);
    }
    async updateDraftLock(lock) {
        const res = await DraftStore.updateLock(lock.id, lock.letrid);
        const resp = {
            id: lock.id,
            updated: res
        };
        this.sendMessage("lockdraftupdated", resp);
    }
    async saveDraftContent(res) {
        // now lets do something with this!
        var _a;
        const resp = await DraftStore.storeDraft(res.content, res.letrid, res.draftid, res.accesskey, (_a = res.contentupdatetime) !== null && _a !== void 0 ? _a : -1);
        const respinse = {
            draftid: res.draftid,
            result: resp
        };
        this.sendMessage("saveddraft", respinse);
    }
    async getDraftEdit(req) {
        let res = undefined;
        if (req.readonlymode) {
            res = await DraftStore.getDraftContentForRead(req.id, req.letrid, req.accesskey, -1, req.objectid, req.origid);
        }
        else {
            res = await DraftStore.getEditDraft(req.id, req.letrid, req.accesskey, req.objectid, req.origid);
        }
        const resp = {
            draftid: req.id,
            res: res,
            ischooser: req.isChooser
        };
        this.sendMessage("draftlockresponse", resp);
    }
    async getDraftDetails(req) {
        const res = await DraftStore.getDraftDetails(req.draftid, req.letrid, req.accesskey);
        // now we have everything lets do something clever here
        const result = {
            draftid: req.draftid,
            detail: undefined
        };
        if (res !== undefined) {
            const rdet = {
                version: res.details,
                haseditpermission: res.hasEditPermission,
            };
            result.detail = rdet;
        }
        result.isChooser = req.ischooser;
        this.sendMessage("draftdetailresponse", result);
    }
    showUsersSecretDialog() {
    }
    async checkUserAcceptedPolicies() {
        // if not then just navigate them to the policy page that's it
        const ret = await DStore.checkUserAcceptedPolicies();
        if (ret !== undefined) {
            this.sendMessage("policiesaccepted", ret);
        }
    }
    async declineLetrCall(req) {
        const ret = await LStore.declineLetrCall(req.letrid);
        this.sendMessage("declineletrcallres", ret);
    }
    async fetchcCallLogs(req) {
        const ret = await CallStore.fetchCallLogs(req.since, req.after, req.count, req.ascending, req.type);
        if (ret !== undefined) {
            const res = {
                logs: ret,
                append: req.append
            };
            this.sendMessage("callrecordres", res);
        }
    }
    async getLetrDetailForIncomingCall(letrid) {
        const ret = await LStore.getLetrDetailForIncomingCall(letrid);
        if (ret !== undefined) {
            if (ret.tags.indexOf(CHATID) !== -1) {
                this.sendMessage("incomingcall", { 'letrid': letrid, 'letrdetail': ret });
            }
            else {
                this.sendMessage("newactivecallletrid", letrid);
            }
        }
    }
    async getComapnyDirectory() {
        const ret = await GStore.getCompanyDirectory();
        this.sendMessage("companydirectory", ret);
    }
    async connect(id) {
        const res = await GStore.connect(id);
        this.sendMessage("connectresponse", res);
    }
    async deleteConnection(id) {
    }
    async publish(id) {
        const res = await GStore.publicContact(id);
        this.sendMessage("publishresponse", res);
    }
    async checkContactCanBePublished(id) {
        const res = await GStore.checkContactCanBePublished(id);
        this.sendMessage("contactpublishableres", res);
    }
    async editOrgContact(req) {
        const ret = await UserAdminStore.editOrgContact(req.id, req.name, req.email, req.number, req.defaultpassword, req.defaultpasswordhint);
        this.sendMessage("editorgcontactres", ret);
    }
    async loadOrgContacts() {
        const ret = await UserAdminStore.loadOrgContacts();
        this.sendMessage("loadorgcontactsres", ret);
    }
    async getMyLetrwingContacts() {
        const ret = await GStore.getMyLetrwingContacts();
        this.sendMessage("getmyletrwingcontactsres", ret);
    }
    async getMyTeam() {
        const ret = await GStore.getMyTeam();
        this.sendMessage("getmyteamres", ret);
    }
    async getMyProfile() {
        const ret = await ProfileStore.getMyProfile();
        this.sendMessage("getmyprofile", ret);
    }
    async addInternalUser(req) {
        const ret = await UserAdminStore.addInternalUser(req.name, req.email, req.role);
        this.sendMessage("addinternaluserres", ret);
    }
    async loadInternalUser() {
        const ret = await UserAdminStore.loadInternalusers();
        this.sendMessage("loadinternalusersres", ret);
    }
    async updateMessageAcl(id, letrid, aclids) {
        const res = await MStore.updateMessageAcl(id, letrid, aclids);
        this.sendMessage("updatemessageacl", res);
    }
    async calcauletAclString(letrid, req) {
        const res = await ProjectStore.calcauletAclString(letrid, req);
        this.sendMessage("calculateaclstring", res);
    }
    async readMessageTime(letrid, mid) {
        const res = await MStore.getMessageReadReceipt(letrid, mid);
        const resp = {
            letrid: letrid,
            messageid: mid,
            readtime: res !== null && res !== void 0 ? res : []
        };
        this.sendMessage("messagereadtime", resp);
    }
    async checkIsAdmin() {
        var _a;
        const res = (_a = await Auth.checkIsAdmin()) !== null && _a !== void 0 ? _a : false;
        this.sendMessage("isadmin", res);
    }
    async addTagToLetr(req) {
        const resp = [];
        for (const re of req) {
            const ok = await LStore.addTagsToLetr(re.letrid, re.tagids);
            resp.push({ id: re.letrid, ok: ok });
        }
        this.sendMessage("taggingletrres", resp);
    }
    async sendChildrenTags(tags) {
        this.sendMessage("childtags", tags);
    }
    async updateAccessGroup(grp) {
        const res = await ProjectStore.updateAclGroup(grp);
        this.sendMessage("updateaccessgroup", res);
    }
    async deleteAccessGroup(grp) {
        const res = await ProjectStore.deleteAclGroup(grp.id, grp.projectid);
        this.sendMessage("deleteaccessgroup", res);
        if (!res) {
            this.sendMessage("addaccessgroup", grp);
        }
    }
    async addAccessGroup(grp) {
        const res = await ProjectStore.addAclGroup(grp);
        this.sendMessage("addaccessgroup", res);
    }
    async getAccessGroup(letrid) {
        const res = await ProjectStore.getAclGroup(letrid);
        this.sendMessage("getaccessgroups", res);
    }
    async updateObjectAcl(req) {
        const res = await ProjectStore.updateProjectObjectAcl(req);
        this.sendMessage("updateobjectacl", res);
    }
    async emptyTrash(letrid) {
        let res = await ProjectStore.emptyTrash(letrid);
        if (res === undefined) {
            res = false;
        }
        this.sendMessage("emptytrash", res);
    }
    async moveProjectObj(req) {
        var _a;
        const res = await ProjectStore.moveProjectObject(req.project, req.letrid, req.newparentid, (_a = req.delete) !== null && _a !== void 0 ? _a : false);
        const resu = Object.assign(Object.assign({}, req), { success: res });
        this.sendMessage("moveobject", resu);
    }
    async updateProjectObj(req) {
        const res = await ProjectStore.updateProjectObject(req.project, req.letrid);
        // lets return this result back and we done!
        const resp = {
            letrid: req.letrid,
            project: res,
            success: res ? true : false
        };
        this.sendMessage("updateprojectres", resp);
    }
    async createEditDocument(req) {
        let pdfpage = await FStore.getPdfViewerPage({
            id: req.fileid,
            pagename: "viewer",
            key: req.accesskey
        });
        const resp = {
            letrid: req.letrid,
            folderid: req.folderid,
            fileid: req.fileid,
            ok: false
        };
        const id = await DraftStore.createDraft(req.letrid, req.name);
        if (id !== undefined) {
            const pent = {
                type: ProjectObjectType.Draft,
                parentid: req.folderid,
                updatetime: 0,
                id: "",
                accessids: [],
                name: req.name,
                drafttype: req.type,
                draftreference: id[1],
                accesskey: id[0],
                draftsize: 0,
            };
            const proj = await ProjectStore.createProject(pent, req.letrid);
            if (proj !== undefined && pdfpage !== undefined) {
                // lets try getting the content and we done!
                if (req.type === DraftType.DOCX) {
                    pdfpage = LetrwingCrypto.fromBase64ToUTF8(pdfpage);
                }
                else {
                    pdfpage = LetrwingCrypto.fromBase64ToUTF8(pdfpage);
                }
                const resp = await DraftStore.storeDraft(pdfpage, req.letrid, id[1], id[0]);
            }
            if (proj !== undefined) {
                resp.ok = true;
                resp.project = proj;
                const res = {
                    letrid: req.letrid,
                    project: proj
                };
                this.sendMessage("createproject", res);
            }
            // okay that's it lets just send the response back!!
        }
        this.sendMessage("editdocument", resp);
    }
    async createDraft(req) {
        const id = await DraftStore.createDraft(req.letrid, req.entity.name);
        if (id !== undefined) {
            req.entity.accesskey = id[0];
            req.entity.draftreference = id[1];
            // now we can create a new request
            const proj = await ProjectStore.createProject(req.entity, req.letrid);
            const res = {
                letrid: req.letrid,
                project: proj
            };
            this.sendMessage("createproject", res);
        }
    }
    async createProject(req) {
        const proj = await ProjectStore.createProject(req.project, req.letrid);
        const res = {
            letrid: req.letrid,
            project: proj
        };
        this.sendMessage("createproject", res);
    }
    async getProjectObject(prjs) {
        const pjs = await ProjectStore.loadProjectObject(prjs.letrid, prjs.objectid, prjs.origid);
        const res = {
            letrid: prjs.letrid,
            objectid: prjs.objectid,
            object: pjs
        };
        this.sendMessage("gotproject", res);
    }
    async getProject(prjs) {
        const pjs = await ProjectStore.loadLetrProjects(prjs.letrid, prjs.parentid);
        const res = {
            letrid: prjs.letrid,
            parentid: prjs.parentid,
            projects: pjs,
            isChooser: prjs.isChooser
        };
        this.sendMessage("gotprojects", res);
    }
    async getOrgContacts(lch) {
        const ocs = await OrgContactStore.getOrgContacts(lch);
        this.sendMessage("getorgcontactsres", ocs);
    }
    loginUser(msg) {
        Auth.loginDevice(msg, (updates) => {
            this.sendMessage("logindeviceupdate", updates);
            if (updates.state === LoginDeviceUpdateState.Success) {
                setTimeout(() => {
                    this.userLoggedIn();
                }, 100);
            }
        }, (str) => this.sendMessage("apperror", str));
    }
    async getOrgverificationDetail() {
        const ret = await DStore.getOrgVerification();
        if (ret !== undefined) {
            this.sendMessage("orgverificationdetail", ret);
        }
    }
    async acceptPolicies() {
        const ret = await DStore.acceptPolicies();
        if (ret !== undefined) {
            this.sendMessage("policiesaccepted", true);
        }
    }
    async getPolicies() {
        const ret = await DStore.getPolicies();
        if (ret !== undefined) {
            this.sendMessage("policies", ret);
        }
    }
    async loginViaAnotherDevice(req) {
        const dres = await LoginViaDeviceStore.getLoginDetails(req.appcode, req.appid, req.devicecode + "");
        let success = false;
        let message = "";
        if (dres !== undefined) {
            // now we can try login into the device and see if this all work okay
            RegStore.registerThisDevice(dres.password, dres.token, dres.tenant, (res) => {
                let update = new DeviceAndOrgUpdate();
                if (res) {
                    update.updateStage = DeviceAndOrgUpdateStage.RegsiteredDevice;
                    const ares = { state: AppState.Login, registerTypes: [] };
                    this.sendMessage("appstate", ares);
                    const loginReq = new LoginDeviceRequest(dres.password);
                    Auth.loginDevice(loginReq, (updates) => {
                        this.sendMessage("logindeviceupdate", updates);
                        if (updates.state === LoginDeviceUpdateState.Success) {
                            setTimeout(() => {
                                this.userLoggedIn();
                            }, 100);
                            success = true;
                        }
                    }, (str) => {
                        message = "Sorry, device has been registered but failed to login. Please try login manually.";
                        this.sendMessage("apperror", str);
                    });
                }
                else {
                    update.updateStage = DeviceAndOrgUpdateStage.DeviceRegError;
                    message = "Sorry, failed to register device. Please try again later.";
                }
                this.sendMessage("organddevicerequpdate", update);
            });
        }
        else {
            message = "Sorry, failed to setup this device.";
        }
        const response = {
            status: success,
            message: message
        };
        this.sendMessage("loginviadeviceresponse", response);
    }
    async registerAnotherDevice(req) {
        const res = await Auth.registerAnotherDevice(req.devicecode);
        this.sendMessage("registeranotherdeviceresponse", res);
    }
    async fetchLetrConnectDetail(req) {
        const res = await ConnectStore.fetchLetrConnectDetail(req.letrid, req.connectid);
        this.sendMessage("letrconnectdetails", res);
    }
    async addConnectPasswordHint(con) {
        const res = await ConnectStore.addConnectsPasswordHints(con);
        this.sendMessage("addconnectpasswordhint", res);
    }
    async addConnect(con) {
        const res = await ConnectStore.addConnect(con.email);
        this.sendMessage("addconnect", res);
    }
    async deleteConnects(cons) {
        const res = await ConnectStore.deleteConnect(cons);
        this.sendMessage("deleteconnects", res);
    }
    async getConnects() {
        const res = await ConnectStore.getConnects();
        this.sendMessage("connects", res);
    }
    async getPdfFilePage(req) {
        let page = undefined;
        if (req.pagename === 'bookmarks') {
            const result = await FStore.getBookmark(req.id, req.accesskey);
            page = JSON.stringify(result);
        }
        else {
            page = await FStore.getPdfViewerPage({ id: req.id, pagename: req.pagename, key: req.accesskey });
        }
        const res = {
            pagename: req.pagename,
            id: req.id,
            page: page,
            pagenumber: req.pagenumber,
            ischooser: req.ischooser
        };
        this.sendMessage("pdfpage", res);
    }
    sendUnreadInfo(info) {
        this.sendMessage("unreadinfo", info);
    }
    sendNewManagedLetrResponse(res) {
        this.sendMessage("managedletrs", res);
    }
    getNewManagedLetrs(email) {
        const req = {
            email: email,
            before: new Date().getTime(),
            count: 20
        };
        this.getManagedLetrs(req);
    }
    async getManagedLetrs(req) {
        const lts = await ManagedStore.getAllLetrsForManagedContacts(req.email, req.before, req.count);
        const res = { email: req.email, letrs: lts };
        this.sendMessage("managedletrs", res);
    }
    async getFileStatus(req) {
        const status = await FStore.getFileStatus(req);
        const res = { id: req.id, status: status };
        this.sendMessage("filestatusresponse", res);
    }
    async getManagedGroups(id) {
        const rt = await ManagedStore.getManagedGroups(id);
        const res = { email: id, grps: rt !== null && rt !== void 0 ? rt : [] };
        this.sendMessage("managedgroups", res);
    }
    async getManagedGroupsInvites(id) {
        const rt = await ManagedStore.getAllManagedContactsInvites(id);
        const res = { email: id, inv: rt !== null && rt !== void 0 ? rt : [] };
        this.sendMessage("managedcontactsinvites", res);
        this.getManagedGroups(id);
    }
    async getManagedContacts() {
        const conts = await ManagedStore.getAllManagedContacts();
        this.sendPublicContacts(conts);
    }
    sendPublicContacts(conts) {
        this.sendMessage("managedcontacts", conts);
    }
    async getAllReplies(req) {
        const userid = InMemoryRepo.getUserIDWithTenant();
        const resp = await AStore.loadAllReplies(userid != undefined ? userid : '', req.time, !req.before, req.count);
        if (resp !== undefined) {
            this.sendMessage("allreplies", resp);
        }
    }
    async downloadFile(req) {
        var _a;
        const res = {
            id: req.id,
            letrid: req.letrid,
            requesttype: req.requesttype,
        };
        if (req.requesttype === FileDownloadType.Metadata) {
            const info = await FStore.getFileMetadata(req.id, (_a = req.accesskey) !== null && _a !== void 0 ? _a : "");
            if (info !== undefined) {
                res.info = info;
            }
            res.ischooser = req.isChooser;
            this.sendMessage("downloadfile", res);
        }
        else if (req.requesttype === FileDownloadType.Download) {
            FStore.downloadFile(req, (res) => {
                res.ischooser = req.isChooser;
                res.queueid = req.queueid;
                this.sendMessage("downloadfile", res);
            });
        }
    }
    uploadFile(req) {
        FStore.uploadFile(req, (progress) => {
            this.sendMessage("uploadprogress", progress);
        });
    }
    async loadDevices() {
        var _a;
        const uds = (_a = await DStore.getDevices()) !== null && _a !== void 0 ? _a : [];
        this.sendMessage("devices", uds);
    }
    sendMessageAvailable(msg) {
        if (msg.length > 0) {
            const res = new MessageUIResponse();
            res.msg = msg;
            res.letrid = msg[0].letrid;
            res.type = MessageUIRequestType.MessageUpdates;
            this.sendMessage("message", res);
            msg.filter(m => m.user._id !== InMemoryRepo.getUserIDWithTenant())
                .forEach(m => {
                if (m.type === MessageType.Text) {
                    NStore.sendNotification("New Message");
                }
                else if (m.type === MessageType.File) {
                    NStore.sendNotification("New File");
                }
            });
        }
    }
    sendReplyAvailable(rmsg) {
        const res = new MessageUIResponse();
        res.msg = [rmsg.reply];
        res.letrid = rmsg.letrid;
        res.type = MessageUIRequestType.AddReply;
        res.newcount = rmsg.replyCount != undefined ? rmsg.replyCount : 0;
        res.parentid = rmsg.parentid;
        if (rmsg.reply.user._id !== InMemoryRepo.getUserIDWithTenant()) {
            this.sendMessage("message", res);
            if (rmsg.reply.type === MessageType.Text) {
                NStore.sendNotification("New Reply");
            }
            else if (rmsg.reply.type === MessageType.File) {
                NStore.sendNotification("New File");
            }
        }
    }
    sendReplyEditAvailable(rmsg) {
        const res = new MessageUIResponse();
        res.msg = [rmsg];
        res.letrid = rmsg.letrid;
        res.type = MessageUIRequestType.EditReply;
        if (rmsg.user._id !== InMemoryRepo.getUserIDWithTenant()) {
            this.sendMessage("message", res);
        }
    }
    sendDeleteReply(rmsg) {
        const reply = rmsg.reply;
        if (reply._id !== InMemoryRepo.getUserIDWithTenant()) {
            this.sendMessage("deletereply", rmsg);
        }
    }
    deleteMessage(msg) {
        this.sendMessage("deletemessage", msg);
    }
    async letrEditRequest(req) {
        var _a;
        const res = { type: req.type, letrid: req.letrid };
        if (req.type === LetrEditrequestType.Add) {
            const detail = await LStore.addMembersToLetr(req.letrid, req.memberid, (_a = req.tagids) !== null && _a !== void 0 ? _a : []);
            res.detail = detail;
        }
        else if (req.type === LetrEditrequestType.Delete) {
            const detail = await LStore.removeMemberFromLetr(req.letrid, req.memberid[0].userid, req.isConnect);
            res.detail = detail;
        }
        else if (req.type === LetrEditrequestType.update) {
            const detail = await LStore.updateMembersForLetr(req.letrid, req.memberid);
            res.detail = detail;
            res.memberid = req.memberid;
        }
        else if (req.type === LetrEditrequestType.updatelink) {
            const links = await LStore.updateLinksForLetr(req.letrid, req.memberid);
            res.links = links !== null && links !== void 0 ? links : [];
            res.memberid = req.memberid;
        }
        this.sendMessage("letredit", res);
    }
    async handleMessageReq(req) {
        const res = new MessageUIResponse();
        res.type = req.type;
        res.letrid = req.letrid;
        res.replaceId = req.replaceId;
        if (req.type === MessageUIRequestType.Fetch) {
            const messages = await MStore.loadMessages(req.letrid, req.time, !req.before, req.count, req.excludetype);
            res.msg = messages;
        }
        else if (req.type === MessageUIRequestType.Add && req.msgs !== undefined) {
            const nmsg = req.msgs;
            res.replaceId = req.replaceId;
            const msg = await MStore.saveMessage(nmsg, "", nmsg.acl);
            if (msg !== undefined) {
                res.msg = [msg];
            }
        }
        else if ((req.type === MessageUIRequestType.Edit || req.type === MessageUIRequestType.EditReply) && req.msgs !== undefined) {
            const emsg = await MStore.editMessage(req.msgs);
            if (emsg !== undefined) {
                res.msg = [emsg];
            }
        }
        else if ((req.type === MessageUIRequestType.Delete || req.type === MessageUIRequestType.DeleteReply)
            && req.msgs !== undefined) {
            const ok = await MStore.deleteMessage(req.msgs);
            const isreply = req.type === MessageUIRequestType.DeleteReply;
            if (ok === undefined || !ok) {
                res.type = isreply ? MessageUIRequestType.AddReply : MessageUIRequestType.Add;
                res.msg = [req.msgs];
            }
        }
        else if (req.type === MessageUIRequestType.FetchReplies) {
            const replies = await MStore.loadReplies(req.parentid, req.letrid, req.time, !req.before, req.count);
            res.msg = replies;
        }
        else if (req.type === MessageUIRequestType.AddReply && req.msgs !== undefined) {
            const resp = await MStore.reply(req.msgs, req.parentid, req.time);
            if (resp !== undefined) {
                res.msg = resp.latestreplied;
                res.newcount = resp.totalcount;
                res.parentid = req.parentid;
            }
        }
        this.sendMessage("message", res);
    }
    async handleAdminMessageReq(req) {
        const res = new MessageUIResponse();
        res.type = req.type;
        res.letrid = req.letrid;
        res.replaceId = req.replaceId;
        if (req.type === MessageUIRequestType.Fetch) {
            const messages = await UserAdminStore.loadMessages(req.letrid, req.time, !req.before, req.count, req.excludetype, req.userid);
            res.msg = messages;
        }
        else if ((req.type === MessageUIRequestType.Delete || req.type === MessageUIRequestType.DeleteReply)
            && req.msgs !== undefined) {
            const ok = await MStore.deleteMessage(req.msgs);
            const isreply = req.type === MessageUIRequestType.DeleteReply;
            if (ok === undefined || !ok) {
                res.type = isreply ? MessageUIRequestType.AddReply : MessageUIRequestType.Add;
                res.msg = [req.msgs];
            }
        }
        else if (req.type === MessageUIRequestType.FetchReplies) {
            const replies = await UserAdminStore.loadReplies(req.parentid, req.letrid, req.time, !req.before, req.count, req.userid);
            res.msg = replies;
        }
        this.sendMessage("adminmessage", res);
    }
    sendNewLetrs(letr) {
        let response = {
            type: LetrRequestType.Fetch,
            letrs: letr,
            error: false,
            letrid: "",
            cpassword: []
        };
        this.sendMessage("letrs", response);
        letr.forEach((lt) => {
            if (lt.title) {
                NStore.sendNotification("New chat with: " + lt.title);
            }
            else {
                NStore.sendNotification("New Letr: " + lt.letrMetadata.subject);
            }
        });
    }
    sendLetrUpdate(letr) {
        let response = {
            type: LetrRequestType.Fetch,
            letrs: letr,
            error: false,
            letrid: "",
            cpassword: []
        };
        this.sendMessage("letrs", response);
    }
    userLoggedIn() {
        const res = {
            state: AppState.LoggedIn, registerTypes: [],
            username: InMemoryRepo.getName(), userid: InMemoryRepo.getUserIDWithTenant(),
            sessionid: InMemoryRepo.getSessionId(),
            deviceid: comm.getClientId(),
            orgid: InMemoryRepo.getTenant()
        };
        this.sendMessage("appstate", res);
        this.getOrgverificationDetail();
        this.checkUserAcceptedPolicies();
        this.checkUserDownloadedCode();
        this.getGroups();
    }
    async checkUserDownloadedCode() {
        const downloaded = await DStore.checkrecoveryDownloadedCode();
        if (!downloaded) {
            DStore.getMyRecoveryCode((code) => {
                if (code) {
                    // now we can send this 
                    const seccode = {
                        secret: code,
                        useraction: false
                    };
                    this.sendMessage("shownewusersecretdialog", seccode);
                }
            });
        }
    }
    async handleTagRequest(tagreq) {
        let res;
        if (tagreq.type === TagRequestType.Load) {
            const tags = TStore.checkAndLoadTags();
            res = {
                tags: tags,
                error: "",
                type: tagreq.type
            };
        }
        else if (tagreq.type === TagRequestType.Add) {
            const resp = await TStore.storeNewTags(tagreq.tags);
            res = {
                tags: resp,
                error: "",
                type: tagreq.type
            };
        }
        if (res !== undefined) {
            this.sendMessage("tags", res);
            TStore.loadChildTags();
        }
    }
    async handleLetrRequest(req) {
        var _a, _b, _c;
        let response;
        if (req.type === LetrRequestType.Fetch) {
            let letrs = [];
            if (req.filter) {
                letrs = await LStore.fetchLetrs(req.filter);
            }
            response = {
                letrs: letrs,
                type: req.type,
                error: false,
                letrid: (_a = req.letrid) !== null && _a !== void 0 ? _a : "",
                cpassword: []
            };
        }
        else if (req.type === LetrRequestType.Add) {
            const adreq = req.newletrreq;
            let letrs = [];
            let cpassword = [];
            let error = false;
            if (adreq) {
                const letr = await LStore.createNewLetr(adreq, () => { });
                if (letr === undefined) {
                    error = true;
                }
                else {
                    letrs.push(letr.letr);
                    cpassword = [...letr.connectPassword];
                }
            }
            response = {
                letrs: letrs,
                type: req.type,
                error: error,
                letrid: (_b = req.letrid) !== null && _b !== void 0 ? _b : "",
                cpassword: cpassword
            };
        }
        else if (req.type === LetrRequestType.FetchDetail && req.letrid !== undefined) {
            const res = await LStore.getLetrDetail(req.letrid);
            response = {
                type: req.type,
                error: false,
                letrs: [],
                letrdetail: res,
                letrid: (_c = req.letrid) !== null && _c !== void 0 ? _c : "",
                cpassword: []
            };
        }
        if (response !== undefined) {
            this.sendMessage('letrs', response);
        }
    }
    async handleMasterKey(req) {
        const res = new MasterKeyResponse();
        if (req.type === MasterKeyRequestType.Get) {
            MKService.getMasterKey(req.password, (mk) => {
                res.masterkey = mk !== null && mk !== void 0 ? mk : "";
                res.type = req.type;
                res.error = mk != undefined && mk ? false : true;
                res.hasKey = !res.error;
                this.sendMessage("masterkeyresponse", res);
            });
        }
        else if (req.type === MasterKeyRequestType.Add && req.masterkey) {
            MKService.saveMasterPassword(req.masterkey, req.password, (succ) => {
                res.error = !succ;
                res.type = req.type;
                res.masterkey = req.masterkey;
                this.sendMessage("masterkeyresponse", res);
            });
        }
        else if (req.type === MasterKeyRequestType.Has) {
            const result = await MKService.hasMasterKey();
            if (result) {
                UserAdminStore.upgradeToAdminSession();
            }
            res.hasKey = result;
            req.type = req.type;
            this.sendMessage("masterkeyresponse", res);
        }
        else if (req.type === MasterKeyRequestType.GetM) {
            const ml = await MKService.getMasterKeyClean();
            if (ml) {
                res.masterkey = ml !== null && ml !== void 0 ? ml : "";
                res.type = req.type;
                this.sendMessage("masterkeyresponse", res);
            }
        }
    }
    async handlePersonalgroup(req) {
        const res = new PersonalGroupresponse();
        if (req.edit === PersonalGroupEditOpt.Add) {
            const group = await GStore.createNewGroup(req.name, req.memberids);
            res.group = group;
            res.edit = req.edit;
            res.error = group === undefined ? true : false;
        }
        else {
        }
        this.sendMessage("personalgroupresponse", res);
    }
    async editExternalContacts(req) {
        const result = [];
        for (const ex of req) {
            if (ex.editopt === ExternalEditOpt.Delete) {
                const res = await GStore.deleteExternalContact(ex.grp);
                result.push(new ExternalContactEditResponse(ex.grp, ex.editopt, res));
            }
        }
        this.sendMessage("externalcontacteditresponse", result);
    }
    async newInviteAcceptDecline(req) {
        var _a, _b, _c, _d;
        if (req.accept) {
            await GStore.acceptInvite(req.id, (_a = req.isPublic) !== null && _a !== void 0 ? _a : false, (_b = req.email) !== null && _b !== void 0 ? _b : "");
        }
        else {
            await GStore.declineInvite(req.id, (_c = req.isPublic) !== null && _c !== void 0 ? _c : false, (_d = req.email) !== null && _d !== void 0 ? _d : "");
        }
        if (req.isPublic) {
            this.getManagedGroupsInvites(req.email);
        }
        else {
            this.getGroups();
            this.getMyLetrwingContacts();
            this.getNewInvitations();
        }
    }
    async getNewInvitations() {
        try {
            const nivites = await GStore.getAllInvitations();
            this.publishInvites(nivites);
        }
        catch (ex) {
            this.sendMessage("newinvites", "error");
        }
    }
    publishInvites(nivites) {
        const id = Math.random() + "";
        LocalRepo.store({ typename: NewInvitesType, data: { _id: id, newinvites: nivites !== null && nivites !== void 0 ? nivites : [], error: false } }, () => {
            this.sendMessage("newinvites", id);
        });
    }
    async wipeDevice(req) {
        try {
            Auth.deleteThiDevice((done) => {
                this.getAppState();
            });
        }
        catch (ex) { }
    }
    async getPendingInvites() {
        try {
            const invites = await GStore.getPendingInvites();
            // const id = Math.random() + "";
            // LocalRepo.store<GetPendingContactsResponse>({ typename: PendingInvitesType, data: { _id: id, pendingcon: invites ?? [], error: false } }, () => {
            //     this.sendMessage('pendinginvites', id);
            // });
            this.sendMessage('allpendinginvites', invites);
        }
        catch (ex) {
            this.sendMessage('pendinginvites', 'error');
        }
    }
    async sendInvite(req) {
        const result = await GStore.sendInvite(req.name, req.email, req.isInternal);
        this.sendMessage("inviteresponse", result ? 'OK' : 'error');
        this.getPendingInvites();
    }
    async verifyPin(req) {
        const res = await Auth.verifyPin(req.pin, req.email, req.tenant);
        this.sendregisterUpdate(res);
    }
    async registerReg(req) {
        const res = await Auth.register(req);
        this.sendregisterUpdate(res);
    }
    async acceptOrgInvite(req) {
        const res = await Auth.acceptinvite(req.orgid, req.session);
        this.sendMessage("acceptorgres", res);
    }
    async getGroups() {
        const gre = new GetGroupsResponse();
        try {
            const groups = await GStore.getAllGroups();
            if (groups === undefined) {
                gre.error = true;
            }
            else {
                gre.error = false;
                gre.groups = groups;
            }
        }
        catch (ex) {
            gre.error = true;
        }
        this.sendGroup(gre.groups, gre.error);
    }
    sendGroup(grps, error) {
        LocalRepo.store({ typename: GroupTypeNameAndId, data: { _id: GroupTypeNameAndId, data: grps, error: error } }, () => {
            this.sendMessage("groupsresponse", GroupTypeNameAndId);
        });
    }
    sendregisterUpdate(update) {
        this.sendMessage("regsiterstageupdate", update);
    }
    getAppState() {
        Auth.checkAndSetApp((res) => {
            if (res.state === AppState.LoggedIn) {
                this.userLoggedIn();
            }
            else {
                this.sendMessage("appstate", res);
            }
        });
    }
    loggedOut() {
        const res = { state: AppState.Logout, registerTypes: [] };
        this.sendMessage("appstate", res);
    }
    sendMessage(name, data, notifyMessage = "") {
        const message = Convert.convertToSendMessage(name, data);
        APPEvent.publishForNewBackgroundMessage(message);
        // we need stuff here now
        if (notifyMessage) {
            NStore.sendNotification(notifyMessage);
        }
    }
    registerUser(req) {
        RegStore.registerUserAndDevice(req.pin, req.secret, req.name, req.token, req.orgid, (update) => {
            if (update.updateStage == DeviceAndOrgUpdateStage.RegsiteredDevice) {
                setTimeout(() => {
                    this.loginUser({ password: req.pin, masterpassword: '', sessionid: '' });
                }, 100);
            }
            this.sendMessage("organddevicerequpdate", update);
        });
    }
    newOrgAndDeviceReq(req) {
        if (req.reqStage === DeviceAndOrgStage.NewOrgReq) {
            RegStore.registerNewTeamAndSignInUser(req.req, (update) => {
                this.sendMessage("organddevicerequpdate", update);
            });
        }
        else if (req.reqStage === DeviceAndOrgStage.RegisterDevice) {
            const dres = req.req;
            RegStore.registerThisDevice(dres.password, dres.token, dres.tenantid, (res) => {
                let update = new DeviceAndOrgUpdate();
                if (res) {
                    update.updateStage = DeviceAndOrgUpdateStage.RegsiteredDevice;
                    setTimeout(() => {
                        this.loginUser({ password: dres.password, masterpassword: '', sessionid: '' });
                    }, 4000);
                }
                else {
                    update.updateStage = DeviceAndOrgUpdateStage.RegisterUserDeviceFail;
                }
                this.sendMessage("organddevicerequpdate", update);
            });
        }
    }
}
export const BackgroundHander = new Handler();
