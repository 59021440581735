import { Event } from '@/events/Event';
class AppEvent {
    constructor() {
        this.getSchemaStateData = new Event("getschemastatedata");
        this.storeSchemaStateData = new Event("storeschemastatedata");
        this.getupdatessince = new Event("getupdatesince");
        this.updateandadd = new Event("updateandaddrows");
        this.updateintergation = new Event("updateintegration");
        this.deleteintegration = new Event("deleteintegration");
        this.addintegration = new Event("addintegration");
        this.integrations = new Event("getintegrations");
        this.updateschemadeprows = new Event("updateschemadeprows");
        this.renameletr = new Event("renameletr");
        this.addTriggers = new Event("addtriggers");
        this.getTriggers = new Event("gettriggers");
        this.attachbarcode = new Event("attachbarcode");
        this.updatewebview = new Event("updatewebview");
        this.deleteview = new Event("deletewebview");
        this.addview = new Event("addwebview");
        this.getViews = new Event("getwebviews");
        this.updaterows = new Event("updaterow");
        this.remoterowsres = new Event("remoterowsres");
        this.getorgappuser = new Event("orgappusers");
        this.addappuser = new Event("addappuser");
        this.deleteappuser = new Event("deleteappuser");
        this.updateappuser = new Event("updateappuser");
        this.getappuser = new Event("getappuser");
        this.adminpartialrows = new Event("adminpartialrows");
        this.partialrows = new Event("partialrows");
        this.rowrules = new Event("rowrules");
        this.depndres = new Event("dependentresp");
        this.schemaana = new Event("getschemaanaytics");
        this.getwebforms = new Event("getwebform");
        this.deletewebform = new Event("deletewebform");
        this.updatewebform = new Event("updatewebform");
        this.addwebform = new Event("addwebform");
        this.getprinttemplate = new Event("getprinttemplate");
        this.deleteprinttemplate = new Event("deleteprinttemplate");
        this.updateprinttemplate = new Event("updateprinttemplate");
        this.addprinttemplate = new Event("addprinttemplate");
        this.closeworkingdialog = new Event("closeworkingdialog");
        this.files = new Event("processingfiles");
        this.editcomment = new Event("editcomment");
        this.addwatermark = new Event("addwatermark");
        this.assignedtaskcount = new Event("notifytaskcount");
        this.newfilescount = new Event("newfilescount");
        this.assignedtasks = new Event("assignedtask");
        this.newassignedtasks = new Event("newassignedtasks");
        this.localLetrDetail = new Event("localletrdetail");
        this.activateuser = new Event("activateuser");
        this.orgid = new Event("orgid");
        this.admindeleteres = new Event("deleteuser");
        this.getletrlogs = new Event("getletrlogs");
        this.recentlogs = new Event("getrecentlogs");
        this.newnotifycount = new Event("newnotifycount");
        this.billdone = new Event("billdone");
        this.startbill = new Event("startbill");
        this.recentbills = new Event("recentbills");
        this.storagelogs = new Event("storagelogs");
        this.quota = new Event("quota");
        this.deleterole = new Event("deleterole");
        this.updaterole = new Event("updaterole");
        this.addRole = new Event("addrole");
        this.getRole = new Event("getrole");
        this.addfieldforobject = new Event("addfieldtoobject");
        this.addmetadata = new Event("addmetadataobject");
        this.deletemetadata = new Event("deletemetadataobject");
        this.getmetadata = new Event("getmetadataobject");
        this.deletebookmark = new Event("deletebookmark");
        this.addbookmark = new Event("addbookmark");
        this.updatedatasheet = new Event("updatedatasheet");
        this.deleterows = new Event("deleterows");
        this.addref = new Event("addrowtochildsch");
        this.removeref = new Event("removeowfromchildsch");
        this.updateschema = new Event("updateschema");
        this.deleteschema = new Event("deleteschema");
        this.deletedatasheet = new Event("deletedatasheet");
        this.getrows = new Event("getrows");
        this.addentry = new Event("addentry");
        this.addentries = new Event("addentries");
        this.updateentry = new Event("updateentry");
        this.getdatasheets = new Event("getdatasheets");
        this.getschemas = new Event("getschemas");
        this.addschema = new Event("addschema");
        this.adddatasheet = new Event("adddatasheet");
        this.resetuserpassword = new Event("resetpasswordresponse");
        this.verifypinresforreset = new Event("resetpasswordpinresp");
        this.sendResetVerifyEmail = new Event("resetpasswordverifyemail");
        this.showusersecretdialog = new Event("shownewusersecretdialog");
        this.addversion = new Event("addversion");
        this.getversion = new Event("getversion");
        this.deletetabres = new Event("deletetab");
        this.gettabsres = new Event("gettabsresp");
        this.createtabres = new Event("createtabresp");
        this.draftupdates = new Event("gotdraftupdates");
        this.adminpassword = new Event("adminpasswordres");
        this.deletedev = new Event("deletedeviceres");
        this.savecomment = new Event("addcommentres");
        this.getcomments = new Event("getcommentres");
        this.deletenote = new Event("deletenote");
        this.updatenotes = new Event("notesupdate");
        this.savednoteres = new Event("savednote");
        this.getNotesRes = new Event("getnotesres");
        this.objectparents = new Event("getprojectfoldersres");
        this.foldersres = new Event("getprojectfoldersres");
        this.sendletremailres = new Event("sendLetrEmailLinkres");
        this.deleteletrlink = new Event("deletelinkletr");
        this.getletrlinkres = new Event("getletrlinkres");
        this.createletrlinksres = new Event("createletrlinksres");
        this.miniletrs = new Event("miniletrs");
        this.msgbyids = new Event("messagebyidsres");
        this.letrsearch = new Event("letrsearch");
        this.objectdetails = new Event("projectobjectdetails");
        this.searchresp = new Event("searchresultmatchres");
        this.editdres = new Event("editdocument");
        this.spreadsheetcontent = new Event("spreadsheetcont");
        this.draftexport = new Event("draftexport");
        this.draftexportstatus = new Event("draftexportstatus");
        this.formatePaste = new Event("formatpaste");
        this.publisheddraft = new Event("publisheddraft");
        this.lockdraftupdate = new Event("lockdraftupdated");
        this.saveddraft = new Event("saveddraft");
        this.showexpandedview = new Event("showeditorexpandedview");
        this.draftLockResponse = new Event("draftlockresponse");
        this.isadmin = new Event("isadmin");
        this.addAclGroup = new Event("addaccessgroup");
        this.updateAclGroup = new Event("updateaccessgroup");
        this.deleteAclGroup = new Event("deleteaccessgroup");
        this.getAclGroup = new Event("getaccessgroups");
        this.updateObjectAclGroup = new Event("updateobjectacl");
        this.emptytrash = new Event("emptytrash");
        this.fileStatusResponse = new Event("filestatusresponse");
        this.managedContacts = new Event("managedcontacts");
        this.activeletr = new Event("activeletr");
        this.messageResponse = new Event("message");
        this.authStateEvent = new Event("appstate");
        this.sendmessageEvent = new Event("sendmessagetobackground");
        this.newbackgroundmessage = new Event("newmessagefrombackground");
        this.resgitertypes = new Event("registertypes");
        this.registerupdate = new Event("regsiterstageupdate");
        this.apperror = new Event("apperror");
        this.orAndDeviceRegUpdate = new Event("organddevicerequpdate");
        this.loginupdate = new Event("logindeviceupdate");
        this.username = new Event("username");
        this.reset = new Event("reset");
        this.groupsResponse = new Event("groupsresponse");
        this.inviteResponse = new Event("inviteresponse");
        this.pendinginvites = new Event("pendinginvites");
        this.newinvites = new Event("newinvites");
        this.externalContactEditResponse = new Event('externalcontacteditresponse');
        this.groupEditResponse = new Event('personalgroupresponse');
        this.masterKeyResponse = new Event('masterkeyresponse');
        this.tagsResponse = new Event('tags');
        this.letrsResponse = new Event("letrs");
        this.userid = new Event("userid");
        this.sessionid = new Event("sessionid");
        this.deviceid = new Event("deviceid");
        this.letrEdit = new Event("letredit");
        this.devices = new Event("devices");
        this.uploadProgress = new Event("uploadprogress");
        this.deleteMessageResponse = new Event("deletemessage");
        this.downloadFile = new Event("downloadfile");
        this.replyResponse = new Event("reply");
        this.deletereply = new Event("deletereply");
        this.allreplies = new Event("allreplies");
        this.managednewinvites = new Event("managedgroupsinvites");
        this.managedgroups = new Event("managedgroups");
        this.managedLetrs = new Event("managedletrs");
        this.unreadinfo = new Event("unreadinfo");
        this.fileStatusResponse = new Event("filestatusresponse");
        this.pdffile = new Event("pdfpage");
        this.pdffilemeta = new Event("pdfpagemetadta");
        this.connects = new Event("connects");
        this.addconnect = new Event("addconnect");
        this.deleteconnect = new Event("deleteconnects");
        this.addConnectPasswordHint = new Event("addconnectpasswordhint");
        this.letrConnectDetails = new Event("letrconnectdetails");
        this.openSaveDialog = new Event("opensavedialog");
        this.savelocation = new Event("savelocation");
        this.activeLetrObject = new Event("activeletroobject");
        this.orgacceptres = new Event("acceptorgres");
        this.showmedia = new Event("showmedia");
        this.activemediaid = new Event("activemediaroomid");
        this.mediashut = new Event("mediashut");
        this.activeletridcall = new Event("newactivecallletrid");
        this.registerAnotherDevice = new Event("registeranotherdeviceresponse");
        this.loginViaDevice = new Event("loginviadeviceresponse");
        this.orgcontacts = new Event("getorgcontactsres");
        this.projectsres = new Event("gotprojects");
        this.projectObject = new Event("projectobject");
        this.createObject = new Event("createproject");
        this.updateobject = new Event("updateprojectres");
        this.moveObject = new Event("moveobject");
        this.childtags = new Event("childtags");
        this.taggingletrres = new Event("taggingletrres");
        this.projectobjectres = new Event("getproject");
        this.messagereadtime = new Event("messagereadtime");
        this.orgverifydetail = new Event("orgverificationdetail");
        this.policies = new Event("policies");
        this.policiesaccepted = new Event("policiesaccepted");
        this.calculateAclString = new Event("calculateaclstring");
        this.updateMessageAcl = new Event("updatemessageacl");
        this.getmyprofile = new Event("getmyprofile");
        this.addinternaluserres = new Event("addinternaluserres");
        this.loadinternaluserres = new Event("loadinternalusersres");
        this.getmyteamres = new Event("getmyteamres");
        this.getmyletrwingcontactsres = new Event("getmyletrwingcontactsres");
        this.loadorgcontactsres = new Event("loadorgcontactsres");
        this.editorgcontactres = new Event("editorgcontactres");
        this.activemessage = new Event("activemessage");
        this.allpendinginvites = new Event("allpendinginvites");
        this.comapnydirectory = new Event("companydirectory");
        this.connectresponse = new Event("connectresponse");
        this.publishresponse = new Event("publishresponse");
        this.contactpublishableres = new Event("contactpublishableres");
        this.declineletrcallres = new Event("declineletrcallres");
        this.incomingcall = new Event("incomingcall");
        this.draftdetails = new Event("draftdetailresponse");
        this.acceptincomingcall = new Event("acceptincomingcall");
        this.callactioned = new Event("callactioned");
        this.callended = new Event("callended");
        this.callrecordres = new Event("callrecordres");
        this.adminorgdetailres = new Event("adminorgdetailres");
        this.adminusersres = new Event("adminusersres");
        this.adminuserdevicesres = new Event("adminuserdevicesres");
        this.admindownloadfileres = new Event("admindownloadfileres");
        this.adminloadusertagsres = new Event("adminloadusertagsres");
        this.adminletrchanged = new Event("letrchanged");
        this.letruserchanged = new Event("letruserchanged");
        this.admintagchanged = new Event("admintagchanged");
        this.adminuserletrsfortagres = new Event("adminuserletrsfortagres");
        this.adminedituserres = new Event("adminedituserres");
        this.adminletrwingcontactsres = new Event("adminletrwingcontactsres");
        this.admindeleteletrwingcontactres = new Event("admindeleteletrwingcontactres");
        this.adminupdatewatermarkres = new Event("adminupdatewatermarkres");
        this.adminupdatecontactemailres = new Event("adminupdatecontactemailres");
        this.admingetlicenseinfores = new Event("admingetlicenseinfores");
        this.admineditdeviceres = new Event("admineditdeviceres");
        this.admincancelinviteres = new Event("admincancelinviteres");
        this.adminupdateuserdetailres = new Event("adminupdateuserdetailres");
        this.adminprojectobjres = new Event("adminprojectobjres");
        this.adminpdfpage = new Event("adminpdfpage");
        this.admindraftdetailresponse = new Event("admindraftdetailresponse");
        this.adminDraftLockResponse = new Event("adminDraftLockResponse");
        this.adminLetrsResponse = new Event("adminLetrsResponse");
        this.admineditletrmember = new Event("admineditletrmember");
        this.admindeleteletrres = new Event("admindeleteletrres");
        this.newletr = new Event("newletr");
        this.adminobjectparents = new Event("adminobjectparents");
        this.adminGetNotesRes = new Event("admingetnotesres");
        this.admingetdatasheetsres = new Event("admingetdatasheetsres");
        this.admingetrowsres = new Event("admingetrowsres");
        this.admingetschemasres = new Event("admingetschemasres");
        this.admingetcommentres = new Event("admingetcommentres");
        this.adminGetRole = new Event("admingetrole");
        this.adminGetletrlinkres = new Event("admingetletrlinkres");
        this.admindeleteletrlink = new Event("admindeleteletrlink");
        this.adminassignedtasks = new Event("adminassignedtask");
        this.admindepndres = new Event("admindependentresp");
        this.adminschemaana = new Event("admingetschemaanaytics");
        this.admingetmetadata = new Event("admingetmetadata");
        this.adminspreadsheetcontent = new Event("adminspreadsheetcont");
        this.duplicaterowsres = new Event("duplicaterowsres");
        this.schemaupdate = new Event("schemaupdate");
        this.getreportschemas = new Event("getreportschemas");
    }
    addSubscriberForGetSchemaStateData(sub) {
        this.getSchemaStateData.subscribe(sub);
    }
    removeSubscriberForGetSchemaStateData(sub) {
        this.getSchemaStateData.unsubscribe(sub);
    }
    publishForGetSchemaStateData(time) {
        this.getSchemaStateData.publish(time);
    }
    addSubscriberForStoreSchemaStateData(sub) {
        this.storeSchemaStateData.subscribe(sub);
    }
    removeSubscriberForStoreSchemaStateData(sub) {
        this.storeSchemaStateData.unsubscribe(sub);
    }
    publishForStoreSchemaStateData(time) {
        this.storeSchemaStateData.publish(time);
    }
    addSubscriberForGetUpdateSince(sub) {
        this.getupdatessince.subscribe(sub);
    }
    removeSubscriberForGetUpdateSince(sub) {
        this.getupdatessince.unsubscribe(sub);
    }
    publishForGetUpdateSince(time) {
        this.getupdatessince.publish(time);
    }
    addSubscriberForUpdateAndAddRows(sub) {
        this.updateandadd.subscribe(sub);
    }
    removeSubscriberForUpdateAndAddRows(sub) {
        this.updateandadd.unsubscribe(sub);
    }
    publishForUpdateAndAddRows(time) {
        this.updateandadd.publish(time);
    }
    addSubscriberForUpdateIntegrations(sub) {
        this.updateintergation.subscribe(sub);
    }
    removeSubscriberForUpdateIntegrations(sub) {
        this.updateintergation.unsubscribe(sub);
    }
    publishForUpdateIntegrationss(time) {
        this.updateintergation.publish(time);
    }
    addSubscriberForDeleteIntegrations(sub) {
        this.deleteintegration.subscribe(sub);
    }
    removeSubscriberForDeleteIntegrations(sub) {
        this.deleteintegration.unsubscribe(sub);
    }
    publishForDeleteIntegrationss(time) {
        this.deleteintegration.publish(time);
    }
    addSubscriberForAddIntegrations(sub) {
        this.addintegration.subscribe(sub);
    }
    removeSubscriberForAddIntegrations(sub) {
        this.addintegration.unsubscribe(sub);
    }
    publishForAddIntegrationss(time) {
        this.addintegration.publish(time);
    }
    addSubscriberForGetIntegrations(sub) {
        this.integrations.subscribe(sub);
    }
    removeSubscriberForGetIntegrations(sub) {
        this.integrations.unsubscribe(sub);
    }
    publishForGetIntegrationss(time) {
        this.integrations.publish(time);
    }
    addSubscriberForUpdateSchemaDepRows(sub) {
        this.updateschemadeprows.subscribe(sub);
    }
    removeSubscriberForUpdateSchemaDepRows(sub) {
        this.updateschemadeprows.unsubscribe(sub);
    }
    publishForUpdateSchemaDepRows(time) {
        this.updateschemadeprows.publish(time);
    }
    addSubscriberForRenameLetr(sub) {
        this.renameletr.subscribe(sub);
    }
    removeSubscriberForRennameLetr(sub) {
        this.renameletr.unsubscribe(sub);
    }
    publishForRennameLetr(time) {
        this.renameletr.publish(time);
    }
    addSubscriberForAddtriggers(sub) {
        this.addTriggers.subscribe(sub);
    }
    removeSubscriberForAddtriggers(sub) {
        this.addTriggers.unsubscribe(sub);
    }
    publishForAddtriggers(time) {
        this.addTriggers.publish(time);
    }
    addSubscriberForGettriggers(sub) {
        this.getTriggers.subscribe(sub);
    }
    removeSubscriberForGettriggers(sub) {
        this.getTriggers.unsubscribe(sub);
    }
    publishForGettriggers(time) {
        this.getTriggers.publish(time);
    }
    addSubscriberForAttachBarcode(sub) {
        this.attachbarcode.subscribe(sub);
    }
    removeSubscriberForAttachBarcod(sub) {
        this.attachbarcode.unsubscribe(sub);
    }
    publishForAttachBarcod(time) {
        this.attachbarcode.publish(time);
    }
    addSubscriberForUpdateWebViews(sub) {
        this.updatewebview.subscribe(sub);
    }
    removeSubscriberForUpdateWebViews(sub) {
        this.updatewebview.unsubscribe(sub);
    }
    publishForUpdateWebViews(time) {
        this.updatewebview.publish(time);
    }
    addSubscriberForDeleteWebViews(sub) {
        this.deleteview.subscribe(sub);
    }
    removeSubscriberForDeleteWebViews(sub) {
        this.deleteview.unsubscribe(sub);
    }
    publishForDeleteWebViews(time) {
        this.deleteview.publish(time);
    }
    addSubscriberForAddWebViews(sub) {
        this.addview.subscribe(sub);
    }
    removeSubscriberForAddWebViews(sub) {
        this.addview.unsubscribe(sub);
    }
    publishForAddWebViews(time) {
        this.addview.publish(time);
    }
    addSubscriberForGetWebViews(sub) {
        this.getViews.subscribe(sub);
    }
    removeSubscriberForGetWebViews(sub) {
        this.getViews.unsubscribe(sub);
    }
    publishForGetWebViews(time) {
        this.getViews.publish(time);
    }
    addSubscriberForGetReportSchema(sub) {
        this.getreportschemas.subscribe(sub);
    }
    removeSubscriberForGetReportSchema(sub) {
        this.getreportschemas.unsubscribe(sub);
    }
    publishForGetReportSchema(time) {
        this.getreportschemas.publish(time);
    }
    addSubscriberForUpdateRows(sub) {
        this.updaterows.subscribe(sub);
    }
    removeSubscriberForUpdateRows(sub) {
        this.updaterows.unsubscribe(sub);
    }
    publishUpdateRows(content) {
        this.updaterows.publish(content);
    }
    addSubscriberForRemoteRows(sub) {
        this.remoterowsres.subscribe(sub);
    }
    removeSubscriberForRemoteRows(sub) {
        this.remoterowsres.unsubscribe(sub);
    }
    publishRemoteRows(content) {
        this.remoterowsres.publish(content);
    }
    addSubscriberForSchemaUpdate(sub) {
        this.schemaupdate.subscribe(sub);
    }
    removeSubscriberForSchemaUpdate(sub) {
        this.schemaupdate.unsubscribe(sub);
    }
    publishSchemaUpdate(content) {
        this.schemaupdate.publish(content);
    }
    addSubscriberForGetorgAppUser(sub) {
        this.getorgappuser.subscribe(sub);
    }
    removeSubscriberForGetorgAppUse(sub) {
        this.getorgappuser.unsubscribe(sub);
    }
    publishGetorgAppUse(content) {
        this.getorgappuser.publish(content);
    }
    addSubscriberForAddAppUser(sub) {
        this.addappuser.subscribe(sub);
    }
    removeSubscriberForAddAppUse(sub) {
        this.addappuser.unsubscribe(sub);
    }
    publishAddAppUse(content) {
        this.addappuser.publish(content);
    }
    addSubscriberForUpdateAppUser(sub) {
        this.updateappuser.subscribe(sub);
    }
    removeSubscriberForUpdateAppUse(sub) {
        this.updateappuser.unsubscribe(sub);
    }
    publishUpdateAppUse(content) {
        this.updateappuser.publish(content);
    }
    addSubscriberForGetAppUser(sub) {
        this.getappuser.subscribe(sub);
    }
    removeSubscriberForGetAppUse(sub) {
        this.getappuser.unsubscribe(sub);
    }
    publishGetAppUse(content) {
        this.getappuser.publish(content);
    }
    addSubscriberForDeleteAppUser(sub) {
        this.deleteappuser.subscribe(sub);
    }
    removeSubscriberForDeleteAppUse(sub) {
        this.deleteappuser.unsubscribe(sub);
    }
    publishDeleteAppUse(content) {
        this.deleteappuser.publish(content);
    }
    addSubscriberForAdminPartialRows(sub) {
        this.adminpartialrows.subscribe(sub);
    }
    removeSubscriberForAdminPartialRows(sub) {
        this.adminpartialrows.unsubscribe(sub);
    }
    publishAdminPartialRows(content) {
        this.adminpartialrows.publish(content);
    }
    addSubscriberForDuplicateRowRes(sub) {
        this.duplicaterowsres.subscribe(sub);
    }
    removeSubscriberForDuplicateRowRes(sub) {
        this.duplicaterowsres.unsubscribe(sub);
    }
    publishDuplicateRowRes(content) {
        this.duplicaterowsres.publish(content);
    }
    addSubscriberForPartialRows(sub) {
        this.partialrows.subscribe(sub);
    }
    removeSubscriberForPartialRows(sub) {
        this.partialrows.unsubscribe(sub);
    }
    publishPartialRows(content) {
        this.partialrows.publish(content);
    }
    addSubscriberForAdminSpreadsheetCont(sub) {
        this.adminspreadsheetcontent.subscribe(sub);
    }
    removeSubscriberForAdminSpreadsheetCon(sub) {
        this.adminspreadsheetcontent.unsubscribe(sub);
    }
    publishAdminSpreadsheetCon(content) {
        this.adminspreadsheetcontent.publish(content);
    }
    publishForAdminGetMetadata(met) {
        this.admingetmetadata.publish(met);
    }
    addSubcriberForAdminGetMetadataa(sub) {
        this.admingetmetadata.subscribe(sub);
    }
    removeSubcriberForAdminGetMetadata(sub) {
        this.admingetmetadata.unsubscribe(sub);
    }
    addSubscriberForAdminSchemaAnalytics(sub) {
        this.adminschemaana.subscribe(sub);
    }
    removeSubscriberForAdminSchemaAnalytics(sub) {
        this.adminschemaana.unsubscribe(sub);
    }
    publishAdminSchemaAnalytics(res) {
        this.adminschemaana.publish(res);
    }
    addSubscriberForAdminDependResp(sub) {
        this.admindepndres.subscribe(sub);
    }
    removeSubscriberForAdminDependResp(sub) {
        this.admindepndres.unsubscribe(sub);
    }
    publishAdminDependResp(res) {
        this.admindepndres.publish(res);
    }
    addSubscriberForRowRules(sub) {
        this.rowrules.subscribe(sub);
    }
    removeSubscriberRowRules(sub) {
        this.rowrules.unsubscribe(sub);
    }
    publishRowRules(res) {
        this.rowrules.publish(res);
    }
    addSubscriberForDependResp(sub) {
        this.depndres.subscribe(sub);
    }
    removeSubscriberDependResp(sub) {
        this.depndres.unsubscribe(sub);
    }
    publishDependResp(res) {
        this.depndres.publish(res);
    }
    addSubscriberForSchemaAnalytics(sub) {
        this.schemaana.subscribe(sub);
    }
    removeSubscriberSchemaAnalytics(sub) {
        this.schemaana.unsubscribe(sub);
    }
    publishSchemaAnalytics(res) {
        this.schemaana.publish(res);
    }
    addSubscriberForAdminAssignedTasks(sub) {
        this.adminassignedtasks.subscribe(sub);
    }
    removeSubscriberAdminAssignedTasks(sub) {
        this.adminassignedtasks.unsubscribe(sub);
    }
    publishAdminAssignedTasks(res) {
        this.adminassignedtasks.publish(res);
    }
    addSubscriberForGetPrintTemplate(sub) {
        this.getprinttemplate.subscribe(sub);
    }
    removeSubscriberForGetPrintTemplat(sub) {
        this.getprinttemplate.unsubscribe(sub);
    }
    publishGetPrintTemplat(time) {
        this.getprinttemplate.publish(time);
    }
    addSubscriberForDeletePrintTemplat(sub) {
        this.deleteprinttemplate.subscribe(sub);
    }
    removeSubscriberForDeletePrintTemplat(sub) {
        this.deleteprinttemplate.unsubscribe(sub);
    }
    publishDeletePrintTemplat(time) {
        this.deleteprinttemplate.publish(time);
    }
    addSubscriberForUpdatePrintTemplate(sub) {
        this.updateprinttemplate.subscribe(sub);
    }
    removeSubscriberForUpdatePrintTemplate(sub) {
        this.updateprinttemplate.unsubscribe(sub);
    }
    publishUpdatePrintTemplate(time) {
        this.updateprinttemplate.publish(time);
    }
    addSubscriberForAddPrintTemplate(sub) {
        this.addprinttemplate.subscribe(sub);
    }
    removeSubscriberForAddPrintTemplate(sub) {
        this.addprinttemplate.unsubscribe(sub);
    }
    publishAddPrintTemplate(time) {
        this.addprinttemplate.publish(time);
    }
    addSubscriberForGetWebForm(sub) {
        this.getwebforms.subscribe(sub);
    }
    removeSubscriberForGetWebForm(sub) {
        this.getwebforms.unsubscribe(sub);
    }
    publishGetWebForm(time) {
        this.getwebforms.publish(time);
    }
    addSubscriberForDeleteWebForm(sub) {
        this.deletewebform.subscribe(sub);
    }
    removeSubscriberForDeleteWebForm(sub) {
        this.deletewebform.unsubscribe(sub);
    }
    publishDeleteWebForm(time) {
        this.deletewebform.publish(time);
    }
    addSubscriberForUpdateWebForm(sub) {
        this.updatewebform.subscribe(sub);
    }
    removeSubscriberForUpdateWebForm(sub) {
        this.updatewebform.unsubscribe(sub);
    }
    publishUpdateWebForm(time) {
        this.updatewebform.publish(time);
    }
    addSubscriberForAddWebForm(sub) {
        this.addwebform.subscribe(sub);
    }
    removeSubscriberForAddWebForm(sub) {
        this.addwebform.unsubscribe(sub);
    }
    publishAddWebForm(time) {
        this.addwebform.publish(time);
    }
    addSubscriberForCloseWorkingDialog(sub) {
        this.closeworkingdialog.subscribe(sub);
    }
    removeSubscriberForCloseWorkingDialog(sub) {
        this.closeworkingdialog.unsubscribe(sub);
    }
    publishCloseWorkingDialog(time) {
        this.closeworkingdialog.publish(time);
    }
    addSubscriberForAdminGetLetrlink(sub) {
        this.adminGetletrlinkres.subscribe(sub);
    }
    removeSubscriberForAdminGetLetrlink(sub) {
        this.adminGetletrlinkres.unsubscribe(sub);
    }
    publishAdminGetLetrlink(time) {
        this.adminGetletrlinkres.publish(time);
    }
    addSubcriberForAdminGetRole(sub) {
        this.adminGetRole.subscribe(sub);
    }
    removeSubcriberForAdminGetRole(sub) {
        this.adminGetRole.unsubscribe(sub);
    }
    publishForAdminGetRole(met) {
        this.adminGetRole.publish(met);
    }
    addSubscriberForEditComment(sub) {
        this.editcomment.subscribe(sub);
    }
    removeSubscriberEditComment(sub) {
        this.editcomment.unsubscribe(sub);
    }
    publishEditComment(tags) {
        this.editcomment.publish(tags);
    }
    addSubscriberForAddWatermark(sub) {
        this.addwatermark.subscribe(sub);
    }
    removeSubscriberAddWatermark(sub) {
        this.addwatermark.unsubscribe(sub);
    }
    publishAddWatermark(tags) {
        this.addwatermark.publish(tags);
    }
    addSubscriberForProcessingFiles(sub) {
        this.files.subscribe(sub);
    }
    removeSubscriberProcessingFiles(sub) {
        this.files.unsubscribe(sub);
    }
    publishProcessingFiles(tags) {
        this.files.publish(tags);
    }
    addSubscriberForNewFileCount(sub) {
        this.newfilescount.subscribe(sub);
    }
    removeSubscriberNewFileCount(sub) {
        this.newfilescount.unsubscribe(sub);
    }
    publishNewFileCount(tags) {
        this.newfilescount.publish(tags);
    }
    addSubscriberForAssignedTasksCount(sub) {
        this.assignedtaskcount.subscribe(sub);
    }
    removeSubscriberAssignedTasksCount(sub) {
        this.assignedtaskcount.unsubscribe(sub);
    }
    publishAssignedTasksCount(tags) {
        this.assignedtaskcount.publish(tags);
    }
    addSubscriberForNewAssignedTasks(sub) {
        this.newassignedtasks.subscribe(sub);
    }
    removeSubscriberNewAssignedTasks(sub) {
        this.newassignedtasks.unsubscribe(sub);
    }
    publishNewAssignedTasks(tags) {
        this.newassignedtasks.publish(tags);
    }
    addSubscriberForAssignedTasks(sub) {
        this.assignedtasks.subscribe(sub);
    }
    removeSubscriberAssignedTasks(sub) {
        this.assignedtasks.unsubscribe(sub);
    }
    publishAssignedTasks(tags) {
        this.assignedtasks.publish(tags);
    }
    addSubscriberForLocalLetrDetail(sub) {
        this.localLetrDetail.subscribe(sub);
    }
    removeSubscriberLocalLetrDetail(sub) {
        this.localLetrDetail.unsubscribe(sub);
    }
    publishLocalLetrDetail(tags) {
        this.localLetrDetail.publish(tags);
    }
    addSubscriberForActivateUser(sub) {
        this.activateuser.subscribe(sub);
    }
    removeSubscriberActivateUser(sub) {
        this.activateuser.unsubscribe(sub);
    }
    publishActivateUser(tags) {
        this.activateuser.publish(tags);
    }
    addSubscriberForAdminDeleteUser(sub) {
        this.admindeleteres.subscribe(sub);
    }
    removeSubscriberAdminDeleteUser(sub) {
        this.admindeleteres.unsubscribe(sub);
    }
    publishAdminDeleteUser(tags) {
        this.admindeleteres.publish(tags);
    }
    addSubscriberForLetrLogs(sub) {
        this.getletrlogs.subscribe(sub);
    }
    removeSubscriberLetrLogs(sub) {
        this.getletrlogs.unsubscribe(sub);
    }
    publishLetrLogs(tags) {
        this.getletrlogs.publish(tags);
    }
    addSubscriberForRecentLogs(sub) {
        this.recentlogs.subscribe(sub);
    }
    removeSubscriberRecentLogs(sub) {
        this.recentlogs.unsubscribe(sub);
    }
    publishRecentLogs(tags) {
        this.recentlogs.publish(tags);
    }
    addSubscriberForNewNotifyCount(sub) {
        this.newnotifycount.subscribe(sub);
    }
    removeSubscriberrNewNotifyCount(sub) {
        this.newnotifycount.unsubscribe(sub);
    }
    publishNewNotifyCount(tags) {
        this.newnotifycount.publish(tags);
    }
    addSubscriberForBillDone(sub) {
        this.billdone.subscribe(sub);
    }
    removeSubscriberrBillDone(sub) {
        this.billdone.unsubscribe(sub);
    }
    publishBillDone(tags) {
        this.billdone.publish(tags);
    }
    addSubscriberForStartBill(sub) {
        this.startbill.subscribe(sub);
    }
    removeSubscriberrStartBill(sub) {
        this.startbill.unsubscribe(sub);
    }
    publishStartBill(tags) {
        this.startbill.publish(tags);
    }
    addSubscriberForRecentBills(sub) {
        this.recentbills.subscribe(sub);
    }
    removeSubscriberrRecentBills(sub) {
        this.recentbills.unsubscribe(sub);
    }
    publishrRecentBills(tags) {
        this.recentbills.publish(tags);
    }
    addSubscriberForStorageLogs(sub) {
        this.storagelogs.subscribe(sub);
    }
    removeSubscriberrStorageLogs(sub) {
        this.storagelogs.unsubscribe(sub);
    }
    publishrStorageLogs(tags) {
        this.storagelogs.publish(tags);
    }
    addSubscriberForQuota(sub) {
        this.quota.subscribe(sub);
    }
    removeSubscriberrQuota(sub) {
        this.quota.unsubscribe(sub);
    }
    publishrQuota(tags) {
        this.quota.publish(tags);
    }
    addSubcriberForDeleteRole(sub) {
        this.deleterole.subscribe(sub);
    }
    removeSubcriberForDeleteRole(sub) {
        this.deleterole.unsubscribe(sub);
    }
    publishForDeleteRole(met) {
        this.deleterole.publish(met);
    }
    addSubcriberForUpdateRole(sub) {
        this.updaterole.subscribe(sub);
    }
    removeSubcriberForUpdateRole(sub) {
        this.updaterole.unsubscribe(sub);
    }
    publishForUpdateRole(met) {
        this.updaterole.publish(met);
    }
    addSubcriberForGetRole(sub) {
        this.getRole.subscribe(sub);
    }
    removeSubcriberForGetRole(sub) {
        this.getRole.unsubscribe(sub);
    }
    publishForGetRole(met) {
        this.getRole.publish(met);
    }
    addSubcriberForAddRole(sub) {
        this.addRole.subscribe(sub);
    }
    removeSubcriberForAddRole(sub) {
        this.addRole.unsubscribe(sub);
    }
    publishForAddRole(met) {
        this.addRole.publish(met);
    }
    addSubcriberForAddField(sub) {
        this.addfieldforobject.subscribe(sub);
    }
    removeSubcriberForAddField(sub) {
        this.addfieldforobject.unsubscribe(sub);
    }
    publishForAddField(met) {
        this.addfieldforobject.publish(met);
    }
    addSubcriberForAddMetadata(sub) {
        this.addmetadata.subscribe(sub);
    }
    removeSubcriberForAddMetadata(sub) {
        this.addmetadata.unsubscribe(sub);
    }
    publishForGetMetadata(met) {
        this.getmetadata.publish(met);
    }
    addSubcriberForGetMetadataa(sub) {
        this.getmetadata.subscribe(sub);
    }
    removeSubcriberForGetMetadata(sub) {
        this.getmetadata.unsubscribe(sub);
    }
    publishForAddMetadata(met) {
        this.addmetadata.publish(met);
    }
    addSubcriberForDeleteMetadata(sub) {
        this.deletemetadata.subscribe(sub);
    }
    removeSubcriberForDeleteMetadata(sub) {
        this.deletemetadata.unsubscribe(sub);
    }
    publishForDeleteMetadata(met) {
        this.deletemetadata.publish(met);
    }
    addSubscriberForAddBookmark(sub) {
        this.addbookmark.subscribe(sub);
    }
    removeSubscriberForAddBookmark(sub) {
        this.addbookmark.unsubscribe(sub);
    }
    publishForAddBookmark(time) {
        this.addbookmark.publish(time);
    }
    addSubscriberForDeleteBookmark(sub) {
        this.deletebookmark.subscribe(sub);
    }
    removeSubscriberForDeleteBookmark(sub) {
        this.deletebookmark.unsubscribe(sub);
    }
    publishForDeleteBookmark(time) {
        this.deletebookmark.publish(time);
    }
    addSubscriberForUpdateDatasheet(sub) {
        this.updatedatasheet.subscribe(sub);
    }
    removeSubscriberForUpdateDatasheet(sub) {
        this.updatedatasheet.unsubscribe(sub);
    }
    publishForUpdateDatasheet(time) {
        this.updatedatasheet.publish(time);
    }
    addSubscriberForDeleteRow(sub) {
        this.deleterows.subscribe(sub);
    }
    removeSubscriberForDeleteRow(sub) {
        this.deleterows.unsubscribe(sub);
    }
    publishForDeleteRow(time) {
        this.deleterows.publish(time);
    }
    addSubscriberForRemoveRowFromChildSh(sub) {
        this.removeref.subscribe(sub);
    }
    removeSubscriberForRemoveRowFromChildSh(sub) {
        this.removeref.unsubscribe(sub);
    }
    publishForRemoveRowFromChildSh(time) {
        this.removeref.publish(time);
    }
    addSubscriberForAddRow(sub) {
        this.addref.subscribe(sub);
    }
    removeSubscriberForAddRow(sub) {
        this.addref.unsubscribe(sub);
    }
    publishForAddRow(time) {
        this.addref.publish(time);
    }
    addSubscriberForDeleteDatasheet(sub) {
        this.deletedatasheet.subscribe(sub);
    }
    removeSubscriberForDeleteDatasheet(sub) {
        this.deletedatasheet.unsubscribe(sub);
    }
    publishForDeleteDatasheet(time) {
        this.deletedatasheet.publish(time);
    }
    addSubscriberForDeleteSchema(sub) {
        this.deleteschema.subscribe(sub);
    }
    removeSubscriberForDeleteSchema(sub) {
        this.deleteschema.unsubscribe(sub);
    }
    publishForDeleteSchema(time) {
        this.deleteschema.publish(time);
    }
    addSubscriberForUpdateSchema(sub) {
        this.updateschema.subscribe(sub);
    }
    removeSubscriberForUpdateSchema(sub) {
        this.updateschema.unsubscribe(sub);
    }
    publishForUpdateSchema(time) {
        this.updateschema.publish(time);
    }
    addSubscriberForGetRows(sub) {
        this.getrows.subscribe(sub);
    }
    removeSubscriberForGetRows(sub) {
        this.getrows.unsubscribe(sub);
    }
    publishForGetRows(time) {
        this.getrows.publish(time);
    }
    addSubscriberForUpdateEntry(sub) {
        this.updateentry.subscribe(sub);
    }
    removeSubscriberForUpdateEntry(sub) {
        this.updateentry.unsubscribe(sub);
    }
    publishForUpdateEntry(time) {
        this.updateentry.publish(time);
    }
    addSubscriberForAddEntry(sub) {
        this.addentry.subscribe(sub);
    }
    removeSubscriberForAddEntry(sub) {
        this.addentry.unsubscribe(sub);
    }
    publishForAddEntry(time) {
        this.addentry.publish(time);
    }
    addSubscriberForAddEntries(sub) {
        this.addentries.subscribe(sub);
    }
    removeSubscriberForAddEntries(sub) {
        this.addentries.unsubscribe(sub);
    }
    publishForAddEntries(time) {
        this.addentries.publish(time);
    }
    addSubscriberForGetDatasheet(sub) {
        this.getdatasheets.subscribe(sub);
    }
    removeSubscriberForGetDatasheet(sub) {
        this.getdatasheets.unsubscribe(sub);
    }
    publishForGetDatasheet(time) {
        this.getdatasheets.publish(time);
    }
    addSubscriberForGetSchema(sub) {
        this.getschemas.subscribe(sub);
    }
    removeSubscriberForGetSchema(sub) {
        this.getschemas.unsubscribe(sub);
    }
    publishForGetSchema(time) {
        this.getschemas.publish(time);
    }
    addSubscriberForAddDatasheet(sub) {
        this.adddatasheet.subscribe(sub);
    }
    removeSubscriberForAddDatasheet(sub) {
        this.adddatasheet.unsubscribe(sub);
    }
    publishForAddDatasheet(time) {
        this.adddatasheet.publish(time);
    }
    addSubscriberForAddSchema(sub) {
        this.addschema.subscribe(sub);
    }
    removeSubscriberForAddSchema(sub) {
        this.addschema.unsubscribe(sub);
    }
    publishForAddSchema(time) {
        this.addschema.publish(time);
    }
    addSubscriberForResetPasswordResponse(sub) {
        this.resetuserpassword.subscribe(sub);
    }
    removeSubscriberForResetPasswordResponse(sub) {
        this.resetuserpassword.unsubscribe(sub);
    }
    publishForResetPasswordResponse(time) {
        this.resetuserpassword.publish(time);
    }
    addSubscriberForVerifyPinRes(sub) {
        this.verifypinresforreset.subscribe(sub);
    }
    removeSubscriberForVerifyPinRes(sub) {
        this.verifypinresforreset.unsubscribe(sub);
    }
    publishForVerifyPinRes(time) {
        this.verifypinresforreset.publish(time);
    }
    addSubscriberForResentVerifyEmail(sub) {
        this.sendResetVerifyEmail.subscribe(sub);
    }
    removeSubscriberForResentVerifyEmail(sub) {
        this.sendResetVerifyEmail.unsubscribe(sub);
    }
    publishForResentVerifyEmail(time) {
        this.sendResetVerifyEmail.publish(time);
    }
    addSubscriberForShowSecretDialog(sub) {
        this.showusersecretdialog.subscribe(sub);
    }
    removeSubscriberForShowSecretDialog(sub) {
        this.showusersecretdialog.unsubscribe(sub);
    }
    publishForShowSecretDialog(time) {
        this.showusersecretdialog.publish(time);
    }
    addSubscriberForGetVersion(sub) {
        this.getversion.subscribe(sub);
    }
    removeSubscriberForGetVersion(sub) {
        this.getversion.unsubscribe(sub);
    }
    publishForGetVersion(time) {
        this.getversion.publish(time);
    }
    addSubscriberForAddVersion(sub) {
        this.addversion.subscribe(sub);
    }
    removeSubscriberForAddVersion(sub) {
        this.addversion.unsubscribe(sub);
    }
    publishForAddVersion(time) {
        this.addversion.publish(time);
    }
    addSubscriberForDeleteTab(sub) {
        this.deletetabres.subscribe(sub);
    }
    removeSubscriberForDeleteTab(sub) {
        this.deletetabres.unsubscribe(sub);
    }
    publishForDeleteTab(time) {
        this.deletetabres.publish(time);
    }
    addSubscriberForTabsReq(sub) {
        this.gettabsres.subscribe(sub);
    }
    removeSubscriberForTabsReq(sub) {
        this.gettabsres.unsubscribe(sub);
    }
    publishForTabsReq(time) {
        this.gettabsres.publish(time);
    }
    addSubscriberForCreatedResp(sub) {
        this.createtabres.subscribe(sub);
    }
    removeSubscriberForCreatedResp(sub) {
        this.createtabres.unsubscribe(sub);
    }
    publishForCreatedResp(time) {
        this.createtabres.publish(time);
    }
    addSubscriberForDraftUpdates(sub) {
        this.draftupdates.subscribe(sub);
    }
    removeSubscriberForDraftUpdates(sub) {
        this.draftupdates.unsubscribe(sub);
    }
    publishDraftUpdates(time) {
        this.draftupdates.publish(time);
    }
    addSubscriberForAdminPassword(sub) {
        this.adminpassword.subscribe(sub);
    }
    removeSubscriberForAdminPassword(sub) {
        this.adminpassword.unsubscribe(sub);
    }
    publishAdminPassword(time) {
        this.adminpassword.publish(time);
    }
    addSubscriberForDeleteDevice(sub) {
        this.deletedev.subscribe(sub);
    }
    removeSubscriberForDeleteDevice(sub) {
        this.deletedev.unsubscribe(sub);
    }
    publishDeleteDevice(time) {
        this.deletedev.publish(time);
    }
    addSubscriberForSaveComments(sub) {
        this.savecomment.subscribe(sub);
    }
    removeSubscriberForSaveComments(sub) {
        this.savecomment.unsubscribe(sub);
    }
    publishSaveComments(time) {
        this.savecomment.publish(time);
    }
    addSubscriberForGetComments(sub) {
        this.getcomments.subscribe(sub);
    }
    removeSubscriberForGetComments(sub) {
        this.getcomments.unsubscribe(sub);
    }
    publishGetComments(time) {
        this.getcomments.publish(time);
    }
    addSubscriberForNoteDelete(sub) {
        this.deletenote.subscribe(sub);
    }
    removeSubscriberForNoteDelete(sub) {
        this.deletenote.unsubscribe(sub);
    }
    publishNoteDelete(time) {
        this.deletenote.publish(time);
    }
    addSubscriberForNoteUpdate(sub) {
        this.updatenotes.subscribe(sub);
    }
    removeSubscriberForNoteUpdate(sub) {
        this.updatenotes.unsubscribe(sub);
    }
    publishNoteUpdate(time) {
        this.updatenotes.publish(time);
    }
    addSubscriberForGetNote(sub) {
        this.getNotesRes.subscribe(sub);
    }
    removeSubscriberForGetNote(sub) {
        this.getNotesRes.unsubscribe(sub);
    }
    publishGetNote(time) {
        this.getNotesRes.publish(time);
    }
    addSubscriberForSaveNote(sub) {
        this.savednoteres.subscribe(sub);
    }
    removeSubscriberForSaveNote(sub) {
        this.savednoteres.unsubscribe(sub);
    }
    publishSaveNote(time) {
        this.savednoteres.publish(time);
    }
    addSubscriberForObjectParentFolder(sub) {
        this.objectparents.subscribe(sub);
    }
    removeSubscriberForObjectParentFolder(sub) {
        this.objectparents.unsubscribe(sub);
    }
    publishObjectParentFolder(time) {
        this.objectparents.publish(time);
    }
    addSubscriberForProjectFolders(sub) {
        this.foldersres.subscribe(sub);
    }
    removeSubscriberForProjectFolders(sub) {
        this.foldersres.unsubscribe(sub);
    }
    publishProjectFolders(time) {
        this.foldersres.publish(time);
    }
    addSubscriberForSendLetrlinkemail(sub) {
        this.sendletremailres.subscribe(sub);
    }
    removeSubscriberForSendLetrlinkemail(sub) {
        this.sendletremailres.unsubscribe(sub);
    }
    publishSendLetrlinkemail(time) {
        this.sendletremailres.publish(time);
    }
    addSubscriberForDeleteLetrlink(sub) {
        this.deleteletrlink.subscribe(sub);
    }
    removeSubscriberForDeleteLetrlink(sub) {
        this.deleteletrlink.unsubscribe(sub);
    }
    publishDeleteLetrlink(time) {
        this.deleteletrlink.publish(time);
    }
    addSubscriberForGetLetrlink(sub) {
        this.getletrlinkres.subscribe(sub);
    }
    removeSubscriberForGetLetrlink(sub) {
        this.getletrlinkres.unsubscribe(sub);
    }
    publishGetLetrlink(time) {
        this.getletrlinkres.publish(time);
    }
    addSubscriberForCreateLetrlink(sub) {
        this.createletrlinksres.subscribe(sub);
    }
    removeSubscriberForCreateLetrlink(sub) {
        this.createletrlinksres.unsubscribe(sub);
    }
    publishCreateLetrlink(time) {
        this.createletrlinksres.publish(time);
    }
    addSubscriberForMsgByIds(sub) {
        this.msgbyids.subscribe(sub);
    }
    removeSubscriberForMsgByIds(sub) {
        this.msgbyids.unsubscribe(sub);
    }
    publishMsgByIds(time) {
        this.msgbyids.publish(time);
    }
    addSubscriberForMiniLetrs(sub) {
        this.miniletrs.subscribe(sub);
    }
    removeSubscriberForMiniLetrs(sub) {
        this.miniletrs.unsubscribe(sub);
    }
    publishMiniLetrs(time) {
        this.miniletrs.publish(time);
    }
    addSubscriberForLetrSearch(sub) {
        this.letrsearch.subscribe(sub);
    }
    removeSubscriberForLetrSearch(sub) {
        this.letrsearch.unsubscribe(sub);
    }
    publishLetrSearch(time) {
        this.letrsearch.publish(time);
    }
    addSubscriberForProjectObjectDetails(sub) {
        this.objectdetails.subscribe(sub);
    }
    removeSubscriberForProjectObjectDetails(sub) {
        this.objectdetails.unsubscribe(sub);
    }
    publishProjectObjectDetails(time) {
        this.objectdetails.publish(time);
    }
    addSubscriberForSearchMatchResp(sub) {
        this.searchresp.subscribe(sub);
    }
    removeSubscriberForSearchMatchResp(sub) {
        this.searchresp.unsubscribe(sub);
    }
    publishSearchMatchResp(time) {
        this.searchresp.publish(time);
    }
    addSubscriberForEditDocument(sub) {
        this.editdres.subscribe(sub);
    }
    removeSubscriberForEditDocument(sub) {
        this.editdres.unsubscribe(sub);
    }
    publishEditDocument(time) {
        this.editdres.publish(time);
    }
    addSubscriberForSpreadsheetCont(sub) {
        this.spreadsheetcontent.subscribe(sub);
    }
    removeSubscriberForSpreadsheetCon(sub) {
        this.spreadsheetcontent.unsubscribe(sub);
    }
    publishSpreadsheetCon(time) {
        this.spreadsheetcontent.publish(time);
    }
    addSubscriberForDraftExport(sub) {
        this.draftexport.subscribe(sub);
    }
    removeSubscriberForDraftExport(sub) {
        this.draftexport.unsubscribe(sub);
    }
    publishDraftExport(time) {
        this.draftexport.publish(time);
    }
    addSubscriberForLetrUserChanged(sub) {
        this.letruserchanged.subscribe(sub);
    }
    publishLetrUserChanged(id) {
        this.letruserchanged.publish(id);
    }
    addSubscriberForAdminDraftLockResponse(sub) {
        this.adminDraftLockResponse.subscribe(sub);
    }
    addSubscriberForAdminLetrDeleteRes(sub) {
        this.admindeleteletrres.subscribe(sub);
    }
    removeSubscriberForAdminLetrDeleteRes(sub) {
        this.admindeleteletrres.unsubscribe(sub);
    }
    publishAdminLetrDeleteRes(response) {
        this.admindeleteletrres.publish(response);
    }
    addSubscriberForAdminEditLetrMember(sub) {
        this.admineditletrmember.subscribe(sub);
    }
    removeSubscriberForAdminEditLetrMember(sub) {
        this.admineditletrmember.unsubscribe(sub);
    }
    publishAdminEditLetrMember(response) {
        this.admineditletrmember.publish(response);
    }
    addSubscriberForAdminLetrsResponse(sub) {
        this.adminLetrsResponse.subscribe(sub);
    }
    removeSubscriberForAdminLetrsResponse(sub) {
        this.adminLetrsResponse.unsubscribe(sub);
    }
    publishAdminLetrsResponse(response) {
        this.adminLetrsResponse.publish(response);
    }
    removeSubscriberForAdminDraftLockResponse(sub) {
        this.adminDraftLockResponse.unsubscribe(sub);
    }
    publishAdminDraftLockResponse(time) {
        this.adminDraftLockResponse.publish(time);
    }
    // public addSubscriberForDraftDetail(sub: Listener<DraftDetailResponse | undefined>) {
    //     this.admindraftdetailresponse.subscribe(sub);
    // }
    addSubscriberForAdminDraftDetail(sub) {
        this.admindraftdetailresponse.subscribe(sub);
    }
    removeSubscriberForAdminDraftDetail(sub) {
        this.admindraftdetailresponse.unsubscribe(sub);
    }
    publishAdminDraftDetail(time) {
        this.admindraftdetailresponse.publish(time);
    }
    addSubscriberForAdminPdfFilePage(sub) {
        this.adminpdfpage.subscribe(sub);
    }
    removeSubscriberForAdminPdfFilePage(sub) {
        this.adminpdfpage.unsubscribe(sub);
    }
    publishAdminPdfFilePage(response) {
        this.adminpdfpage.publish(response);
    }
    addSubscriberForAdminLetrChanged(sub) {
        this.adminletrchanged.subscribe(sub);
    }
    publishAdminLetrChanged(letr) {
        this.adminletrchanged.publish(letr);
    }
    addSubscriberForAdminProjectObjectRes(sub) {
        this.adminprojectobjres.subscribe(sub);
    }
    removeSubscriberFoAdminProjectObjectRes(sub) {
        this.adminprojectobjres.subscribe(sub);
    }
    publishAdminProjectObjectRes(res) {
        this.adminprojectobjres.publish(res);
    }
    addSubscriberForAdminUpdateUserDetailRes(sub) {
        this.adminupdateuserdetailres.subscribe(sub);
    }
    removeSubscriberFoAdminUpdateUserDetailRes(sub) {
        this.adminupdateuserdetailres.subscribe(sub);
    }
    publishAdminUpdateUserDetailRes(res) {
        this.adminupdateuserdetailres.publish(res);
    }
    addSubscriberForAdminCancelInviteRes(sub) {
        this.admincancelinviteres.subscribe(sub);
    }
    removeSubscriberForAdminCancelInviteRes(sub) {
        this.admincancelinviteres.subscribe(sub);
    }
    publishAdminCancelInviteRes(res) {
        this.admincancelinviteres.publish(res);
    }
    addSubscriberForAdminEditDeviceRes(sub) {
        this.admineditdeviceres.subscribe(sub);
    }
    removeSubscriberForAdminEditDeviceRes(sub) {
        this.admineditdeviceres.subscribe(sub);
    }
    publishAdminEditDeviceRes(res) {
        this.admineditdeviceres.publish(res);
    }
    addSubscriberForAdminGetLicenseInfoRes(sub) {
        this.admingetlicenseinfores.subscribe(sub);
    }
    removeSubscriberForAdminGetLicenseInfoRes(sub) {
        this.admingetlicenseinfores.subscribe(sub);
    }
    publishAdminGetLicenseInfoRes(quota) {
        this.admingetlicenseinfores.publish(quota);
    }
    addSubscriberForAdminUpdateContactEmailRes(sub) {
        this.adminupdatecontactemailres.subscribe(sub);
    }
    removeSubscriberForAdminUpdateContactEmailRes(sub) {
        this.adminupdatecontactemailres.subscribe(sub);
    }
    publishAdminUpdateContactEmailRes(res) {
        this.adminupdatecontactemailres.publish(res);
    }
    addSubscriberForAdminUpdateWatermarkRes(sub) {
        this.adminupdatewatermarkres.subscribe(sub);
    }
    removeSubscriberForAdminUpdateWatermarkRes(sub) {
        this.adminupdatewatermarkres.subscribe(sub);
    }
    publishAdminUpdateWatermarkRes(res) {
        this.adminupdatewatermarkres.publish(res);
    }
    addSubscriberForAdminDeleteLetrwingContactRes(sub) {
        this.admindeleteletrwingcontactres.subscribe(sub);
    }
    removeSubscriberForAdminDeleteLetrwingContactRes(sub) {
        this.admindeleteletrwingcontactres.subscribe(sub);
    }
    publishAdminDeleteLetrwingContactRes(res) {
        this.admindeleteletrwingcontactres.publish(res);
    }
    addSubscriberForAdminLetrwingContactsRes(sub) {
        this.adminletrwingcontactsres.subscribe(sub);
    }
    removeSubscriberForAdminLetrwingContactsRes(sub) {
        this.adminletrwingcontactsres.subscribe(sub);
    }
    publishAdminLetrwingContactsRes(res) {
        this.adminletrwingcontactsres.publish(res);
    }
    addSubscriberForAdminEditUserRequestRes(sub) {
        this.adminedituserres.subscribe(sub);
    }
    removeSubscriberForAdminEditUserRequestRes(sub) {
        this.adminedituserres.subscribe(sub);
    }
    publishAdminEditUserRequestRes(res) {
        this.adminedituserres.publish(res);
    }
    addSubscriberForAdminUserLetrsFortag(sub) {
        this.adminuserletrsfortagres.subscribe(sub);
    }
    removeSubscriberForAdminUserLetrsFortag(sub) {
        this.adminuserletrsfortagres.subscribe(sub);
    }
    publishAdminUserLetrsFortag(tags) {
        this.adminuserletrsfortagres.publish(tags);
    }
    addSubscriberForAdminTagChanged(sub) {
        this.admintagchanged.subscribe(sub);
    }
    removeSubscriberForAdminTagChanged(sub) {
        this.admintagchanged.subscribe(sub);
    }
    publishAdminTagChanged(tag) {
        this.admintagchanged.publish(tag);
    }
    addSubscriberForAdminLoadUserTagsRes(sub) {
        this.adminloadusertagsres.subscribe(sub);
    }
    removeSubscriberForAdminLoadUserTagsRes(sub) {
        this.adminloadusertagsres.unsubscribe(sub);
    }
    publishAdminLoadUserTagsRes(response) {
        this.adminloadusertagsres.publish(response);
    }
    addSubscriberForAdminMessageResponse(sub) {
        this.messageResponse.subscribe(sub);
    }
    removeSubscriberForAdminMssageResponse(sub) {
        this.messageResponse.unsubscribe(sub);
    }
    publishAdminMessageResponse(response) {
        this.messageResponse.publish(response);
    }
    addSubscriberForAdminDownload(sub) {
        this.admindownloadfileres.subscribe(sub);
    }
    removeSubscriberForAdminDownload(sub) {
        this.admindownloadfileres.unsubscribe(sub);
    }
    publishAdminDownload(response) {
        this.admindownloadfileres.publish(response);
    }
    addSubcriberForAdminUserDevicesRes(sub) {
        this.adminuserdevicesres.subscribe(sub);
    }
    removeSubscriberForAdminUserDevicesRes(sub) {
        this.adminuserdevicesres.unsubscribe(sub);
    }
    publishAdminUserDevicesRes(users) {
        this.adminuserdevicesres.publish(users);
    }
    addSubcriberForAdminUsersRes(sub) {
        this.adminusersres.subscribe(sub);
    }
    removeSubscriberForAdminUsersRes(sub) {
        this.adminusersres.unsubscribe(sub);
    }
    publishAdminUsersRes(users) {
        this.adminusersres.publish(users);
    }
    addSubcriberForOrgDetails(sub) {
        this.adminorgdetailres.subscribe(sub);
    }
    removeSubscriberForOrgDetails(sub) {
        this.adminorgdetailres.unsubscribe(sub);
    }
    publishOrgDetails(detail) {
        this.adminorgdetailres.publish(detail);
    }
    addSubscriberForDraftStatus(sub) {
        this.draftexportstatus.subscribe(sub);
    }
    removeSubscriberForDraftStatus(sub) {
        this.draftexportstatus.unsubscribe(sub);
    }
    publishDraftStatus(time) {
        this.draftexportstatus.publish(time);
    }
    addSubscriberForFormatPaste(sub) {
        this.formatePaste.subscribe(sub);
    }
    removeSubscriberForFormatPaste(sub) {
        this.formatePaste.unsubscribe(sub);
    }
    publishFormatPaste(time) {
        this.formatePaste.publish(time);
    }
    addSubscriberForDraftPublished(sub) {
        this.publisheddraft.subscribe(sub);
    }
    removeSubscriberForDraftPublished(sub) {
        this.publisheddraft.unsubscribe(sub);
    }
    publishDraftPublished(time) {
        this.publisheddraft.publish(time);
    }
    addSubscriberForUpdateDraftLock(sub) {
        this.lockdraftupdate.subscribe(sub);
    }
    removeSubscriberForUpdateDraftLock(sub) {
        this.lockdraftupdate.unsubscribe(sub);
    }
    publishUpdateDraftLock(time) {
        this.lockdraftupdate.publish(time);
    }
    addSubscriberForSavedDraftContent(sub) {
        this.saveddraft.subscribe(sub);
    }
    removeSubscriberForSavedDraftContent(sub) {
        this.saveddraft.unsubscribe(sub);
    }
    publishSavedDraftContent(time) {
        this.saveddraft.publish(time);
    }
    addSubscriberForShowExpandedView(sub) {
        this.showexpandedview.subscribe(sub);
    }
    removeSubscriberForShowExpandedView(sub) {
        this.showexpandedview.unsubscribe(sub);
    }
    publishShowExpandedView(time) {
        this.showexpandedview.publish(time);
    }
    addSubscriberForDraftLockResponse(sub) {
        this.draftLockResponse.subscribe(sub);
    }
    removeSubscriberForDraftLockResponse(sub) {
        this.draftLockResponse.unsubscribe(sub);
    }
    publishDraftLockResponse(time) {
        this.draftLockResponse.publish(time);
    }
    addSubscriberForDraftDetail(sub) {
        this.draftdetails.subscribe(sub);
    }
    removeSubscriberForDraftDetail(sub) {
        this.draftdetails.unsubscribe(sub);
    }
    publishDraftDetail(time) {
        this.draftdetails.publish(time);
    }
    addSubscriberForOrgverificationDetail(sub) {
        this.orgverifydetail.subscribe(sub);
    }
    removeSubscriberForOrgverificationDetail(sub) {
        this.orgverifydetail.unsubscribe(sub);
    }
    publishOrgverificationDetail(time) {
        this.orgverifydetail.publish(time);
    }
    publishCallRecordRes(res) {
        this.callrecordres.publish(res);
    }
    addSubscriberForCallRecordRes(sub) {
        this.callrecordres.subscribe(sub);
    }
    removeSubscriberForCallRecordRes(sub) {
        this.callrecordres.unsubscribe(sub);
    }
    publishAcceptIncomingCall(letrid) {
        this.acceptincomingcall.publish(letrid);
    }
    addSubscriberForAcceptIncomingCall(sub) {
        this.acceptincomingcall.subscribe(sub);
    }
    removeSubscriberForAcceptIncomingCall(sub) {
        this.acceptincomingcall.unsubscribe(sub);
    }
    publishCallEnded(req) {
        this.callended.publish(req);
    }
    addSubscriberForCallEnded(sub) {
        this.callended.subscribe(sub);
    }
    removeSubscriberForCallEnded(sub) {
        this.callended.unsubscribe(sub);
    }
    publishCallActioned(req) {
        this.callactioned.publish(req);
    }
    addSubscriberForCallActioned(sub) {
        this.callactioned.subscribe(sub);
    }
    removeSubscriberForCallActioned(sub) {
        this.callactioned.unsubscribe(sub);
    }
    publishIncomingCall(detail) {
        this.incomingcall.publish(detail);
    }
    addSubscriberForIncomingCall(sub) {
        this.incomingcall.subscribe(sub);
    }
    removeSubscriberForIncomingCall(sub) {
        this.incomingcall.unsubscribe(sub);
    }
    addSubscriberForContactPublishableRes(sub) {
        this.contactpublishableres.subscribe(sub);
    }
    removeSubscriberForContactPublishableRes(sub) {
        this.contactpublishableres.unsubscribe(sub);
    }
    publishContactPublishableRes(connected) {
        this.contactpublishableres.publish(connected);
    }
    addSubscriberForContactPublishResponse(sub) {
        this.publishresponse.subscribe(sub);
    }
    removeSubscriberForContactPublishResponse(sub) {
        this.publishresponse.unsubscribe(sub);
    }
    publishContactPublishResponse(connected) {
        this.publishresponse.publish(connected);
    }
    addSubscriberForConnectResponse(sub) {
        this.connectresponse.subscribe(sub);
    }
    removeSubscriberForConnectResponse(sub) {
        this.connectresponse.unsubscribe(sub);
    }
    publishConnectResponse(connected) {
        this.connectresponse.publish(connected);
    }
    addSubscriberForCompanyDirectory(sub) {
        this.comapnydirectory.subscribe(sub);
    }
    removeSubscriberForCompanyDirectory(sub) {
        this.comapnydirectory.unsubscribe(sub);
    }
    publishCompanyDirectory(contacts) {
        this.comapnydirectory.publish(contacts);
    }
    addSubscriberForAllPendingInvites(sub) {
        this.allpendinginvites.subscribe(sub);
    }
    removeSubscriberForAllPendingInvites(sub) {
        this.allpendinginvites.unsubscribe(sub);
    }
    publishAllPendingInvites(contacts) {
        this.allpendinginvites.publish(contacts);
    }
    addSubscriberForEditOrgContactsRes(sub) {
        this.editorgcontactres.subscribe(sub);
    }
    removeSubscriberForEditOrgContactsRes(sub) {
        this.editorgcontactres.unsubscribe(sub);
    }
    publishEditOrgContactsRes(orgcontacts) {
        this.editorgcontactres.publish(orgcontacts);
    }
    addSubscriberForLoadOrgContactsRes(sub) {
        this.loadorgcontactsres.subscribe(sub);
    }
    removeSubscriberForLoadOrgContactsRes(sub) {
        this.loadorgcontactsres.unsubscribe(sub);
    }
    publishLoadOrgContactsRes(orgcontacts) {
        this.loadorgcontactsres.publish(orgcontacts);
    }
    addSubscriberForGetMyLetrwingContactRes(sub) {
        this.getmyletrwingcontactsres.subscribe(sub);
    }
    removeSubscriberForGetMyLetrwingContactRes(sub) {
        this.getmyletrwingcontactsres.unsubscribe(sub);
    }
    publishGetMyLetrwingContactRes(users) {
        this.getmyletrwingcontactsres.publish(users);
    }
    addSubscriberForGetMyTeamRes(sub) {
        this.getmyteamres.subscribe(sub);
    }
    removeSubscriberForGetMyTeamRes(sub) {
        this.getmyteamres.unsubscribe(sub);
    }
    publishGetMyTeamRes(users) {
        this.getmyteamres.publish(users);
    }
    addSubscriberForLoadInternalUsersRes(sub) {
        this.loadinternaluserres.subscribe(sub);
    }
    removeSubscriberForLoadInternalUsersRes(sub) {
        this.loadinternaluserres.unsubscribe(sub);
    }
    publishLoadInternalUsersRes(users) {
        this.loadinternaluserres.publish(users);
    }
    addSubscriberForAddInternalUserRes(sub) {
        this.addinternaluserres.subscribe(sub);
    }
    removeSubscriberForAddInternalUserRes(sub) {
        this.addinternaluserres.unsubscribe(sub);
    }
    publishGetAddInternalUserRes(profile) {
        this.addinternaluserres.publish(profile);
    }
    addSubscriberForGetMyProfile(sub) {
        this.getmyprofile.subscribe(sub);
    }
    removeSubscriberForGetMyProfile(sub) {
        this.getmyprofile.unsubscribe(sub);
    }
    publishGetMyProfile(profile) {
        this.getmyprofile.publish(profile);
    }
    addSubscriberForDeclineCallRes(sub) {
        this.declineletrcallres.subscribe(sub);
    }
    removeSubscriberForDeclineCallRes(sub) {
        this.declineletrcallres.unsubscribe(sub);
    }
    publishDeclineCallRes(res) {
        this.declineletrcallres.publish(res);
    }
    addSubscriberForPolicies(sub) {
        this.policies.subscribe(sub);
    }
    removeSubscriberForPolicies(sub) {
        this.policies.unsubscribe(sub);
    }
    publishPolicies(policies) {
        this.policies.publish(policies);
    }
    addSubscriberForPoliciesAccepted(sub) {
        this.policiesaccepted.subscribe(sub);
    }
    removeSubscriberForPoliciesAccepted(sub) {
        this.policiesaccepted.unsubscribe(sub);
    }
    publishPoliciesAccepted(accepted) {
        this.policiesaccepted.publish(accepted);
    }
    addSubscriberForMessageReadTime(sub) {
        this.messagereadtime.subscribe(sub);
    }
    removeSubscriberForMessageReadTime(sub) {
        this.messagereadtime.unsubscribe(sub);
    }
    publishMessageReadTime(time) {
        this.messagereadtime.publish(time);
    }
    addSubscriberForIsAdmin(sub) {
        this.isadmin.subscribe(sub);
    }
    removeSubscriberForIsAdmin(sub) {
        this.isadmin.unsubscribe(sub);
    }
    publishIsAdmin(tags) {
        this.isadmin.publish(tags);
    }
    addSubscriberForProjectObjectElem(sub) {
        this.projectobjectres.subscribe(sub);
    }
    removeSubscriberForProjectObjectElem(sub) {
        this.projectobjectres.unsubscribe(sub);
    }
    publishProjectObjectElem(tags) {
        this.projectobjectres.publish(tags);
    }
    addSubscriberForTaggingLetr(sub) {
        this.taggingletrres.subscribe(sub);
    }
    removeSubscriberForTaggingLetr(sub) {
        this.taggingletrres.unsubscribe(sub);
    }
    publishTaggingLetr(tags) {
        this.taggingletrres.publish(tags);
    }
    addSubscriberForChildTags(sub) {
        this.childtags.subscribe(sub);
    }
    removeSubscriberForChildTag(sub) {
        this.childtags.unsubscribe(sub);
    }
    publishChildTag(tags) {
        this.childtags.publish(tags);
    }
    addSubscriberForUpdateMessageAcl(sub) {
        this.updateMessageAcl.subscribe(sub);
    }
    removeSubscriberForUpdateMessageAcl(sub) {
        this.updateMessageAcl.unsubscribe(sub);
    }
    publishUpdateMessageAcl(res) {
        this.updateMessageAcl.publish(res);
    }
    addSubscriberForCalculateAclString(sub) {
        this.calculateAclString.subscribe(sub);
    }
    removeSubscriberForCalculateAclString(sub) {
        this.calculateAclString.unsubscribe(sub);
    }
    publishCalculateAclString(res) {
        this.calculateAclString.publish(res);
    }
    addSubscriberForUpdateObjectAcl(sub) {
        this.updateObjectAclGroup.subscribe(sub);
    }
    removeSubscriberForUpdateObjectAcl(sub) {
        this.updateObjectAclGroup.unsubscribe(sub);
    }
    publishUpdateObjectAcl(res) {
        this.updateObjectAclGroup.publish(res);
    }
    addSubscriberForDeleteAccessGroup(sub) {
        this.deleteAclGroup.subscribe(sub);
    }
    removeSubscriberForDeleteAccessGroup(sub) {
        this.deleteAclGroup.unsubscribe(sub);
    }
    publishDeleteAccessGroup(res) {
        this.deleteAclGroup.publish(res);
    }
    addSubscriberForGetAccessGroup(sub) {
        this.getAclGroup.subscribe(sub);
    }
    removeSubscriberForGetAccessGroup(sub) {
        this.getAclGroup.unsubscribe(sub);
    }
    publishGetAccessGroup(res) {
        this.getAclGroup.publish(res);
    }
    addSubscriberForUpdateAccessGroup(sub) {
        this.updateAclGroup.subscribe(sub);
    }
    removeSubscriberForUpdateAccessGroup(sub) {
        this.updateAclGroup.unsubscribe(sub);
    }
    publishUpdateAccessGroup(res) {
        this.updateAclGroup.publish(res);
    }
    addSubscriberForAddAccessGroup(sub) {
        this.addAclGroup.subscribe(sub);
    }
    removeSubscriberForAddAccessGroup(sub) {
        this.addAclGroup.unsubscribe(sub);
    }
    publishAddAccessGroup(res) {
        this.addAclGroup.publish(res);
    }
    addSubscriberForEmptyTrash(sub) {
        this.emptytrash.subscribe(sub);
    }
    removeSubscriberForEmptyTrash(sub) {
        this.emptytrash.unsubscribe(sub);
    }
    publishEmptyTrash(res) {
        this.emptytrash.publish(res);
    }
    addSubscriberForMoveObject(sub) {
        this.moveObject.subscribe(sub);
    }
    removeSubscriberForMoveObject(sub) {
        this.moveObject.unsubscribe(sub);
    }
    publishMoveObject(res) {
        this.moveObject.publish(res);
    }
    addSubscriberForUpdateObject(sub) {
        this.updateobject.subscribe(sub);
    }
    removeSubscriberForUpdateObject(sub) {
        this.updateobject.unsubscribe(sub);
    }
    publishUpdateObject(res) {
        this.updateobject.publish(res);
    }
    addSubscriberForCreateObject(sub) {
        this.createObject.subscribe(sub);
    }
    removeSubscriberForCreateObjec(sub) {
        this.createObject.unsubscribe(sub);
    }
    publishCreateObjec(res) {
        this.createObject.publish(res);
    }
    addSubscriberForProjectObject(sub) {
        this.projectObject.subscribe(sub);
    }
    removeSubscriberForProjectObject(sub) {
        this.projectObject.unsubscribe(sub);
    }
    publishProjectObject(res) {
        this.projectObject.publish(res);
    }
    addSubscriberForProjects(sub) {
        this.projectsres.subscribe(sub);
    }
    removeSubscriberForProjectss(sub) {
        this.projectsres.unsubscribe(sub);
    }
    publishProjects(res) {
        this.projectsres.publish(res);
    }
    addSubscriberForOrgContacts(sub) {
        this.orgcontacts.subscribe(sub);
    }
    removeSubscriberForOrgContacts(sub) {
        this.orgcontacts.unsubscribe(sub);
    }
    publishOrgContacts(res) {
        this.orgcontacts.publish(res);
    }
    addSubscriberForLoginViaDevice(sub) {
        this.loginViaDevice.subscribe(sub);
    }
    removeSubscriberForLoginViaDevice(sub) {
        this.loginViaDevice.unsubscribe(sub);
    }
    publishLoginViaDevice(res) {
        this.loginViaDevice.publish(res);
    }
    addSubscriberForRegisterAnotherDevice(sub) {
        this.registerAnotherDevice.subscribe(sub);
    }
    removeSubscriberForRegisterAnotherDevice(sub) {
        this.registerAnotherDevice.unsubscribe(sub);
    }
    publishRegisterAnotherDevice(res) {
        this.registerAnotherDevice.publish(res);
    }
    addSubscriberForNewActiveLetrid(sub) {
        this.activeletridcall.subscribe(sub);
    }
    removeSubscriberForNewActiveLetrid(sub) {
        this.activeletridcall.unsubscribe(sub);
    }
    publishNewActiveLetridCall(sub) {
        this.activeletridcall.publish(sub);
    }
    addSubscriberForMediaShut(sub) {
        this.mediashut.subscribe(sub);
    }
    removeSubscriberForMediaShut(sub) {
        this.mediashut.unsubscribe(sub);
    }
    publisMediaShut() {
        this.mediashut.publish();
    }
    addSubscriberForActiveMedaiId(sub) {
        this.activemediaid.subscribe(sub);
    }
    removeSubscriberForActiveMedaiId(sub) {
        this.activemediaid.unsubscribe(sub);
    }
    publisActiveMediaRoomId(id) {
        this.activemediaid.publish(id);
    }
    addSubscriberForShowMedia(sub) {
        this.showmedia.subscribe(sub);
    }
    removeSubscriberForShowMedia(sub) {
        this.showmedia.unsubscribe(sub);
    }
    publishShowMedia(req) {
        this.showmedia.publish(req);
    }
    addSubscriberForAcceptRes(sub) {
        this.orgacceptres.subscribe(sub);
    }
    removeSubscriberForAcceptRes(sub) {
        this.orgacceptres.unsubscribe(sub);
    }
    publishAcceptRes(localtion) {
        this.orgacceptres.publish(localtion);
    }
    addSubscriberForActiveLetrObject(sub) {
        this.activeLetrObject.subscribe(sub);
    }
    removeSubscriberForActiveLetrObject(sub) {
        this.activeLetrObject.unsubscribe(sub);
    }
    publishActiveLetrObject(localtion) {
        this.activeLetrObject.publish(localtion);
    }
    addSubscriberForSaveLocation(sub) {
        this.savelocation.subscribe(sub);
    }
    removeSubscriberForSaveLocation(sub) {
        this.savelocation.unsubscribe(sub);
    }
    publishSaveLocation(localtion) {
        this.savelocation.publish(localtion);
    }
    addSubscriberForOpenSaveDialog(sub) {
        this.openSaveDialog.subscribe(sub);
    }
    removeSubscriberForOpenSaveDialog(sub) {
        this.openSaveDialog.unsubscribe(sub);
    }
    publishOpenSaveDialog() {
        this.openSaveDialog.publish();
    }
    addSubscriberForLetrConnectDetails(sub) {
        this.letrConnectDetails.subscribe(sub);
    }
    removeSubscriberForLetrConnectDetails(sub) {
        this.letrConnectDetails.unsubscribe(sub);
    }
    publishLetrConnectDetails(response) {
        this.letrConnectDetails.publish(response);
    }
    addSubscriberForAddConnectPasswordhint(sub) {
        this.addConnectPasswordHint.subscribe(sub);
    }
    removeSubscriberForAddConnectPasswordhintt(sub) {
        this.addConnectPasswordHint.unsubscribe(sub);
    }
    publishAddConnectPasswordhint(response) {
        this.addConnectPasswordHint.publish(response);
    }
    addSubscriberForDeleteConnect(sub) {
        this.deleteconnect.subscribe(sub);
    }
    removeSubscriberForDeleteConnect(sub) {
        this.deleteconnect.unsubscribe(sub);
    }
    publishDeleteConnect(response) {
        this.deleteconnect.publish(response);
    }
    addSubscriberForAddConnect(sub) {
        this.addconnect.subscribe(sub);
    }
    removeSubscriberForAddConnect(sub) {
        this.addconnect.unsubscribe(sub);
    }
    publishAddConnect(response) {
        this.addconnect.publish(response);
    }
    addSubscriberForConnects(sub) {
        this.connects.subscribe(sub);
    }
    removeSubscriberForConnect(sub) {
        this.connects.unsubscribe(sub);
    }
    publishConnects(response) {
        this.connects.publish(response);
    }
    addSubscriberForPdfFilePage(sub) {
        this.pdffile.subscribe(sub);
    }
    removeSubscriberForPdfFilePage(sub) {
        this.pdffile.unsubscribe(sub);
    }
    publishPdfFilePage(response) {
        this.pdffile.publish(response);
    }
    addSubscriberForPdfFileMeta(sub) {
        this.pdffilemeta.subscribe(sub);
    }
    removeSubscriberForPdfFileMeta(sub) {
        this.pdffilemeta.unsubscribe(sub);
    }
    publishPdfFileMeta(response) {
        this.pdffilemeta.publish(response);
    }
    addSubscriberForFileStatusResponse(sub) {
        this.fileStatusResponse.subscribe(sub);
    }
    removeSubscriberForFileStatusResponse(sub) {
        this.fileStatusResponse.unsubscribe(sub);
    }
    publishFileStatusResponse(response) {
        this.fileStatusResponse.publish(response);
    }
    addSubscriberForManagedContacts(sub) {
        this.managedContacts.subscribe(sub);
    }
    removeSubscriberForManagedContacts(sub) {
        this.managedContacts.unsubscribe(sub);
    }
    publishManagedContacts(response) {
        this.managedContacts.publish(response);
    }
    addSubscriberForManagedLetrs(sub) {
        this.managedLetrs.subscribe(sub);
    }
    removeSubscriberForManagedLetrs(sub) {
        this.managedLetrs.unsubscribe(sub);
    }
    publishManagedLetrs(response) {
        this.managedLetrs.publish(response);
    }
    addSubscriberForUnreadInfo(sub) {
        this.unreadinfo.subscribe(sub);
    }
    removeSubscriberForUnreadInfo(sub) {
        this.unreadinfo.unsubscribe(sub);
    }
    publishUnreadInfo(response) {
        this.unreadinfo.publish(response);
    }
    addSubscriberForManagedGroups(sub) {
        this.managedgroups.subscribe(sub);
    }
    removeSubscriberForManagedGroups(sub) {
        this.managedgroups.unsubscribe(sub);
    }
    publishManagedGroups(response) {
        this.managedgroups.publish(response);
    }
    addSubscriberForManagedGroupInvites(sub) {
        this.managednewinvites.subscribe(sub);
    }
    removeSubscriberForManagedGroupInvites(sub) {
        this.managednewinvites.unsubscribe(sub);
    }
    publishManagedGroupInvites(response) {
        this.managednewinvites.publish(response);
    }
    addSubscriberForDownload(sub) {
        this.downloadFile.subscribe(sub);
    }
    removeSubscriberForDownload(sub) {
        this.downloadFile.unsubscribe(sub);
    }
    publishDownload(response) {
        this.downloadFile.publish(response);
    }
    addSubscriberForUploadProgress(sub) {
        this.uploadProgress.subscribe(sub);
    }
    removeSubscriberForUploadProgress(sub) {
        this.uploadProgress.unsubscribe(sub);
    }
    publishUploadProgress(response) {
        this.uploadProgress.publish(response);
    }
    addSubscriberForDevices(sub) {
        this.devices.subscribe(sub);
    }
    removeSubscriberForDevices(sub) {
        this.devices.unsubscribe(sub);
    }
    publishDevices(response) {
        this.devices.publish(response);
    }
    addSubscriberForLetrEdit(sub) {
        this.letrEdit.subscribe(sub);
    }
    removeSubscriberForLetrEdit(sub) {
        this.letrEdit.unsubscribe(sub);
    }
    publishLetrEdit(response) {
        this.letrEdit.publish(response);
    }
    addSubscriberForActiveLetr(sub) {
        this.activeletr.subscribe(sub);
    }
    removeSubscriberForActiveLetr(sub) {
        this.activeletr.unsubscribe(sub);
    }
    publishActiveLetr(response) {
        this.activeletr.publish(response);
    }
    addSubscriberForActiveMessage(sub) {
        this.activemessage.subscribe(sub);
    }
    removeSubscriberForActiveMessage(sub) {
        this.activemessage.unsubscribe(sub);
    }
    publishActiveMessage(response) {
        this.activemessage.publish(response);
    }
    addSubscriberForMessageResponse(sub) {
        this.messageResponse.subscribe(sub);
    }
    removeSubscriberForMssageResponse(sub) {
        this.messageResponse.unsubscribe(sub);
    }
    publishMessageResponse(response) {
        this.messageResponse.publish(response);
    }
    addSubscriberForDeleteMessageResponse(sub) {
        this.deleteMessageResponse.subscribe(sub);
    }
    removeSubscriberForDeleteMessageResponse(sub) {
        this.deleteMessageResponse.unsubscribe(sub);
    }
    publishDeleteMessage(response) {
        this.deleteMessageResponse.publish(response);
    }
    addSubscriberForLetrsResponse(sub) {
        this.letrsResponse.subscribe(sub);
    }
    removeSubscriberForLetrsResponse(sub) {
        this.letrsResponse.unsubscribe(sub);
    }
    publishLetrsResponse(response) {
        this.letrsResponse.publish(response);
    }
    addSubscriberForTagsResponse(sub) {
        this.tagsResponse.subscribe(sub);
    }
    removeSubscriberForTagsResponse(sub) {
        this.tagsResponse.unsubscribe(sub);
    }
    publishTagsResponse(response) {
        this.tagsResponse.publish(response);
    }
    addSubscriberForPersonalGroupResponse(sub) {
        this.groupEditResponse.subscribe(sub);
    }
    removeSubscriberForPersonalGroupResponse(sub) {
        this.groupEditResponse.unsubscribe(sub);
    }
    publishPersonalGroupResponse(response) {
        this.groupEditResponse.publish(response);
    }
    addSubscriberForMasterKeyResponse(sub) {
        this.masterKeyResponse.subscribe(sub);
    }
    removeSubscriberForMasterKeyResponse(sub) {
        this.masterKeyResponse.unsubscribe(sub);
    }
    publishMasterKeyResponse(response) {
        this.masterKeyResponse.publish(response);
    }
    addSubscriberForExternalContactResponse(sub) {
        this.externalContactEditResponse.subscribe(sub);
    }
    removeSubscriberForExternalContactResponse(sub) {
        this.externalContactEditResponse.unsubscribe(sub);
    }
    publishExternalContactResponse(response) {
        this.externalContactEditResponse.publish(response);
    }
    addSubscriberForPendingInvites(sub) {
        this.pendinginvites.subscribe(sub);
    }
    removeSubscribeForPendingInvites(sub) {
        this.pendinginvites.unsubscribe(sub);
    }
    publishPendingInvites(update) {
        this.pendinginvites.publish(update);
    }
    addSubscriberForNewInvites(sub) {
        this.newinvites.subscribe(sub);
    }
    removeSubscribeForNewInvites(sub) {
        this.newinvites.unsubscribe(sub);
    }
    publishNewInvite(update) {
        this.newinvites.publish(update);
    }
    addSubscriberForInviteResponse(sub) {
        this.inviteResponse.subscribe(sub);
    }
    removeSubscribeFoInviteResponse(sub) {
        this.inviteResponse.unsubscribe(sub);
    }
    publishInviteResponse(update) {
        this.inviteResponse.publish(update);
    }
    addSubscriberForGroups(sub) {
        this.groupsResponse.subscribe(sub);
    }
    removeSubscribeForGroups(sub) {
        this.groupsResponse.unsubscribe(sub);
    }
    publishGroups(update) {
        this.groupsResponse.publish(update);
    }
    addSubscriberForLoginDevice(sub) {
        this.loginupdate.subscribe(sub);
    }
    removeSubscribeForLoginUpdate(sub) {
        this.loginupdate.unsubscribe(sub);
    }
    publishForLoginUpdate(update) {
        this.loginupdate.publish(update);
    }
    addSubscriberForReset(sub) {
        this.reset.subscribe(sub);
    }
    removeSubscribeForReset(sub) {
        this.reset.unsubscribe(sub);
    }
    publishForReset() {
        this.reset.publish();
    }
    addSubscriberForUserName(sub) {
        this.username.subscribe(sub);
    }
    removeSubscribeForUsername(sub) {
        this.username.unsubscribe(sub);
    }
    publishForUsername(update) {
        this.username.publish(update);
    }
    addSubscriberForSessionid(sub) {
        this.sessionid.subscribe(sub);
    }
    removeSubscribeForSessionid(sub) {
        this.sessionid.unsubscribe(sub);
    }
    publishForSessionid(update) {
        this.sessionid.publish(update);
    }
    addSubscriberForDeviceid(sub) {
        this.deviceid.subscribe(sub);
    }
    removeSubscribeForDeviceid(sub) {
        this.deviceid.unsubscribe(sub);
    }
    publishForDeviceid(update) {
        this.deviceid.publish(update);
    }
    addSubscriberForUserID(sub) {
        this.userid.subscribe(sub);
    }
    removeSubscribeForUserID(sub) {
        this.userid.unsubscribe(sub);
    }
    publishForUserID(update) {
        this.userid.publish(update);
    }
    addSubscriberForOrgID(sub) {
        this.orgid.subscribe(sub);
    }
    removeSubscribeForOrgID(sub) {
        this.orgid.unsubscribe(sub);
    }
    publishForOrgID(update) {
        this.orgid.publish(update);
    }
    addSubscriberForDeviceOrgUpdate(sub) {
        this.orAndDeviceRegUpdate.subscribe(sub);
    }
    removeSubscribeForDeviceOrgUpdate(sub) {
        this.orAndDeviceRegUpdate.unsubscribe(sub);
    }
    publishForDeviceOrgUpdate(update) {
        this.orAndDeviceRegUpdate.publish(update);
    }
    addSubscriberFoApperror(sub) {
        this.apperror.subscribe(sub);
    }
    removeSubscriberForAppError(sub) {
        this.apperror.unsubscribe(sub);
    }
    publishApperror(appstate) {
        this.apperror.publish(appstate);
    }
    addSubscriberForRegisterStage(sub) {
        this.registerupdate.subscribe(sub);
    }
    removeSubscriberForRegisterStage(sub) {
        this.registerupdate.unsubscribe(sub);
    }
    publishRegisterStage(appstate) {
        this.registerupdate.publish(appstate);
    }
    addSubscriberForAppState(sub) {
        this.authStateEvent.subscribe(sub);
    }
    removeSubscriberForAppState(sub) {
        this.authStateEvent.unsubscribe(sub);
    }
    publishAppState(appstate) {
        this.authStateEvent.publish(appstate);
    }
    addSubscriberForSendMessage(sub) {
        this.sendmessageEvent.subscribe(sub);
    }
    removeSubscriberForSendMessage(sub) {
        this.sendmessageEvent.unsubscribe(sub);
    }
    publishSendMessage(message) {
        this.sendmessageEvent.publish(message);
    }
    addSubscriberForNewBackgroundMessage(sub) {
        this.newbackgroundmessage.subscribe(sub);
    }
    removeSubscriberForNewBackgroundMessage(sub) {
        this.newbackgroundmessage.unsubscribe(sub);
    }
    publishForNewBackgroundMessage(message) {
        this.newbackgroundmessage.publish(message);
    }
    addSubscriberForRegisterTypes(sub) {
        this.resgitertypes.subscribe(sub);
    }
    removeSubscriberForRegisterTpes(sub) {
        this.resgitertypes.unsubscribe(sub);
    }
    publishForRegisterTypes(types) {
        this.resgitertypes.publish(types);
    }
    addSubscriberForReply(sub) {
        this.replyResponse.subscribe(sub);
    }
    deleteSubscriberForReply(sub) {
        this.replyResponse.unsubscribe(sub);
    }
    publishReply(msg) {
        this.replyResponse.publish(msg);
    }
    addSubscriberDeleteReply(sub) {
        this.deletereply.subscribe(sub);
    }
    deleteSubscriberDeleteReply(sub) {
        this.deletereply.unsubscribe(sub);
    }
    publishDeleteReply(msg) {
        this.deletereply.publish(msg);
    }
    addSubscriberForAllReplies(sub) {
        this.allreplies.subscribe(sub);
    }
    deleteSubscriberForAllReplies(sub) {
        this.allreplies.unsubscribe(sub);
    }
    publishAllReplies(res) {
        this.allreplies.publish(res);
    }
    addSubscriberForNewLetr(sub) {
        this.newletr.subscribe(sub);
    }
    removeSubscriberForNewLetr(sub) {
        this.newletr.unsubscribe(sub);
    }
    publishNewLetr() {
        this.newletr.publish();
    }
    addSubscriberForAdminProjectFolders(sub) {
        this.foldersres.subscribe(sub);
    }
    removeSubscriberForAdminProjectFolders(sub) {
        this.foldersres.unsubscribe(sub);
    }
    publishAdminProjectFolders(res) {
        this.foldersres.publish(res);
    }
    addSubscriberForAdminObjectParentFolder(sub) {
        this.adminobjectparents.subscribe(sub);
    }
    removeSubscriberForAdminObjectParentFolder(sub) {
        this.adminobjectparents.unsubscribe(sub);
    }
    publishAdminObjectParentFolder(res) {
        this.adminobjectparents.publish(res);
    }
    addSubscriberForAdminGetNote(sub) {
        this.adminGetNotesRes.subscribe(sub);
    }
    removeSubscriberForAdminGetNote(sub) {
        this.adminGetNotesRes.unsubscribe(sub);
    }
    publishAdminGetNote(res) {
        this.adminGetNotesRes.publish(res);
    }
    addSubscriberForAdminGetDatasheet(sub) {
        this.admingetdatasheetsres.subscribe(sub);
    }
    removeSubscriberForAdminGetDatasheet(sub) {
        this.admingetdatasheetsres.unsubscribe(sub);
    }
    publishForAdminGetDatasheet(res) {
        this.admingetdatasheetsres.publish(res);
    }
    addSubscriberForAdminForGetRows(sub) {
        this.admingetrowsres.subscribe(sub);
    }
    removeSubscriberForAdminGetRows(sub) {
        this.admingetrowsres.unsubscribe(sub);
    }
    publishForAdminGetRows(res) {
        this.admingetrowsres.publish(res);
    }
    addSubscriberForAdminGetSchema(sub) {
        this.admingetschemasres.subscribe(sub);
    }
    removeSubscriberForAdminGetSchema(sub) {
        this.admingetschemasres.unsubscribe(sub);
    }
    publishForAdminGetSchema(res) {
        this.admingetschemasres.publish(res);
    }
    addSubscriberForAdminGetComments(sub) {
        this.admingetcommentres.subscribe(sub);
    }
    removeSubscriberForAdminGetComments(sub) {
        this.admingetcommentres.unsubscribe(sub);
    }
    publishAdminGetComments(res) {
        this.admingetcommentres.publish(res);
    }
    addSubscriberForAdminDeleteLetrlink(sub) {
        this.admindeleteletrlink.subscribe(sub);
    }
    removeSubscriberForAdminDeleteLetrlink(sub) {
        this.admindeleteletrlink.unsubscribe(sub);
    }
    publishAdminDeleteLetrlink(res) {
        this.admindeleteletrlink.publish(res);
    }
}
export const APPEvent = new AppEvent();
