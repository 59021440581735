import { comm } from "@/Comm/comm";
import { BEvent } from "@/events/BackgroundEvents";
import { CommonCrypto } from "@@/Cryptography";
import { EncryptBox } from "@@/EncryptBox";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { SecStore } from "./SecurityStore";
import { LetrRequestType } from "@/share/Letr";
import { Apphandler } from "@/ipc/MainAppHandler";
import { LStore } from "./LetrStore";
import { AppState } from "@/share/AppState";
import { NStore } from "./NotifyStore";
class Store {
    constructor() {
        this.activecontactid = "";
        this.passforConatct = new Map();
        BEvent.addSubscriberForNewPublicContacts((co) => {
            this.getAllNewManagedContacts();
        });
        BEvent.addSubscriberForAppState((state) => {
            if (state === AppState.Register || state === AppState.Logout) {
                this.passforConatct = new Map();
            }
        });
        BEvent.addSubscriberForNewManagedLetr(async (em) => {
            await this.getAllNewLetrsForManagedContacts(em);
            if (this.activecontactid !== em) {
                //Show notification
                NStore.sendNotification("New Managed Letr");
            }
            else {
                const lts = await this.getAllLetrsForManagedContacts(em, 0, 20, new Date().getTime() - (60 * 60 * 1000), false);
                const res = { email: this.activecontactid, letrs: lts };
                BEvent.publishNewManagedLetrResponse(res);
            }
        });
    }
    async getAllManagedContacts() {
        const mp = InMemoryRepo.getMasterPassword();
        if (mp === undefined) {
            return [];
        }
        // now lets fetch them
        const ret = await comm.get(ServerPath.myManagedContacts);
        if (ret !== undefined) {
            const conts = [];
            for (const r of ret) {
                const password = await SecStore.decryptPasswordBox(r.passwordbox, mp);
                if (password === undefined) {
                    continue;
                }
                const pass = LetrwingCrypto.decryptSecretBox(r.box, password.password);
                if (pass === undefined) {
                    continue;
                }
                this.passforConatct.set(r.contact.email, pass);
                conts.push(r.contact);
            }
            return conts;
        }
        return [];
    }
    async getAllManagedContactsInvites(id) {
        const kp = SecStore.getSecurityCommHeader();
        const req = { publiccontactid: id };
        const res = await comm.post(ServerPath.publicContactInvites, req, [kp.header]);
        if (res === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(res, kp.kp);
        return dmsg;
    }
    async getManagedGroups(id) {
        const req = { email: id };
        const kph = SecStore.getSecurityCommHeader();
        const grps = await comm.post(ServerPath.getManagedGroups, req, [kph.header]);
        if (grps === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(grps, kph.kp);
        return dmsg;
    }
    setactiveContactId(activecontactid) {
        this.activecontactid = activecontactid;
    }
    async getAllLetrsForManagedContacts(email, before, count, after = 0, fetchlatest = true) {
        var _a;
        if (!this.passforConatct.has(email)) {
            return [];
        }
        if (fetchlatest) {
            // maybe this should go in the websocket ??
            await this.getAllNewLetrsForManagedContacts(email);
        }
        const filter = {
            tagid: email,
            beforeDate: before,
            size: count,
            afterDate: after,
            selectedGroup: []
        };
        const res = await comm.post(ServerPath.getManagedContactLetrs, filter);
        const ret = [];
        if (res !== undefined) {
            for (const r of res) {
                try {
                    const mbox = r.mypasswordbox;
                    const key = LetrwingCrypto.decryptSecretBox(mbox, (_a = this.passforConatct.get(email)) !== null && _a !== void 0 ? _a : '');
                    if (key === undefined) {
                        continue;
                    }
                    const membox = JSON.parse(key);
                    const rd = await LStore.decryptLetrUsingPbox(r, membox.indref.indirectionrefkey);
                    if (rd === undefined || rd.dbox === undefined) {
                        continue;
                    }
                    const uletr = LStore.convertUserLetrToLetr(r, rd.subject, rd.message, rd.viewbox);
                    ret.push(uletr);
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
        return ret;
    }
    async getAllNewLetrsForManagedContacts(email) {
        const mp = InMemoryRepo.getMasterPassword();
        if (mp === undefined) {
            return;
        }
        const req = { email: email };
        const letrs = await comm.post(ServerPath.getAllManagedContactNewLetrs, req);
        if (letrs === undefined) {
            return;
        }
        for (const letr of letrs) {
            const bbox = letr.bundlerBoxes;
            if (bbox.length < 3) {
                continue;
            }
            const pas = await SecStore.decryptPasswordBox(bbox[1], mp);
            if (pas !== undefined) {
                const mlboxpass = LetrwingCrypto.decryptSecretBox(bbox[0], pas.password);
                if (mlboxpass === undefined) {
                    continue;
                }
                const mlbox = LetrwingCrypto.decryptSecretBox(bbox[2], mlboxpass);
                if (mlbox === undefined) {
                    continue;
                }
                try {
                    const mbox = JSON.parse(mlbox);
                    let dbox = undefined;
                    for (const ekey of mbox.ephemeralKeys) {
                        if (ekey.reference === letr.bundleRefid) {
                            const data = LetrwingCrypto.decryptRSABox(letr.mybox, ekey.initkey);
                            if (data === null) {
                                continue;
                            }
                            dbox = data;
                        }
                    }
                    if (!dbox) {
                        for (const ekey of mbox.longTermKeys) {
                            if (ekey.reference === letr.bundleRefid) {
                                const data = LetrwingCrypto.decryptRSABox(letr.mybox, ekey.initkey);
                                if (data === null) {
                                    continue;
                                }
                                dbox = data;
                            }
                        }
                    }
                    if (dbox !== undefined) {
                        const lebox = LetrwingCrypto.secretBox(JSON.stringify(dbox), mlboxpass);
                        const sid = await SecStore.storeShareBox(lebox);
                        if (sid === undefined) {
                            continue;
                        }
                        // now we can just activate this letr and we done..
                        const req = {
                            myboxid: dbox.indref.indirectionid,
                            myboxpasswordid: sid,
                            newvlid: letr.id,
                            permission: letr.permission,
                            tags: [email],
                            mypasswordbox: new EncryptBox()
                        };
                        // now we have main letr lts activate them..
                        const ret = await comm.post(ServerPath.activateManagedLetr, req);
                    }
                }
                catch (ex) {
                }
            }
        }
    }
    async getAllNewManagedContacts() {
        const mp = InMemoryRepo.getMasterPassword();
        const ckey = InMemoryRepo.getCommKey();
        if (mp === undefined || ckey === undefined) {
            return;
        }
        const ret = await comm.get(ServerPath.myNewManagedContacts);
        if (ret !== undefined) {
            const ncontacts = [];
            for (const r of ret) {
                if (r.passwordboxes.length < 2) {
                    continue;
                }
                const pb = await SecStore.decryptPasswordBox(r.passwordboxes[1], mp);
                if (pb === undefined) {
                    continue;
                }
                const bundle = CommonCrypto.decryptCommBundleBox(r.passwordboxes[0], pb.password);
                if (bundle !== undefined) {
                    const initkey = bundle.initkey;
                    const mydata = LetrwingCrypto.decryptRSABoxRawString(r.accessbox, initkey);
                    if (mydata === null) {
                        continue;
                    }
                    // now we have the access key lets do one thing
                    const pbox = await SecStore.generateAndStoreNewPasswordBox(mp, ckey);
                    if (pbox !== undefined) {
                        const ebox = LetrwingCrypto.secretBox(mydata, pbox.password);
                        const npbox = {
                            id: r.id,
                            passwordboxid: pbox.id,
                            passwordbox: new EncryptBox(),
                            contact: r.cont,
                            box: ebox,
                            userid: ""
                        };
                        const ok = await comm.post(ServerPath.activateNewManagedContacts, [npbox]);
                        if (ok) {
                            ncontacts.push(r.cont);
                            this.passforConatct.set(r.cont.email, mydata);
                        }
                    }
                }
            }
            BEvent.publishNewPubliContact(ncontacts);
            // now lets just publish this and we done!
        }
    }
    loadManagedLetr(letrid) {
        // this.setLoading(true);
        const req = {
            type: LetrRequestType.FetchDetail,
            letrid: letrid
        };
        Apphandler.sendMessage("letrs", req);
    }
}
export const ManagedStore = new Store();
