import { comm } from "@/Comm/comm";
import { BEvent } from "@/events/BackgroundEvents";
import { UpdateLogType } from "@@/Project";
import { ServerPath } from "@@/ServerPath";
import { InMemoryRepo } from "./InMemoryDb";
import { ProjectStore } from "./ProjectStore";
import { SchemaStore } from "./SchemaStore";
class Store {
    constructor() {
        BEvent.addSubscriberForUnreadLetr(ul => {
            this.parseAndPublishUnreadDetails(ul);
        });
    }
    async getLetrUpdates(req) {
        var _a;
        const aid = InMemoryRepo.getLetrPassword(req.letrid);
        if (!aid) {
            return [];
        }
        const res = await comm.post(ServerPath.getLetrHistory, req);
        if (res) {
            const pobj = new Map();
            const sobj = new Map();
            if (res.objs) {
                const bobjs = ProjectStore.convertProjecObjectToProjectEntity(res.objs, aid, req.letrid);
                if (bobjs) {
                    for (const bob of bobjs) {
                        pobj.set(bob.id, bob);
                    }
                }
            }
            if (res.schemas) {
                for (const sch of res.schemas) {
                    const osch = SchemaStore.convertBoxToSchema(sch, req.letrid, aid);
                    if (osch) {
                        sobj.set(osch.id, osch);
                    }
                }
            }
            for (const item of res.logs) {
                item.letrid = req.letrid;
                if (item.parentid.startsWith("obj-")) {
                    const id = item.parentid.substring("obj-".length);
                    const oj = pobj.get(id);
                    if (oj) {
                        item.parentobj = oj;
                    }
                }
                else if (item.parentid.startsWith("sch-")) {
                    const id = item.parentid.substring("sch-".length);
                    const oj = sobj.get(id);
                    if (oj) {
                        item.parentobj = oj;
                    }
                }
            }
        }
        return (_a = res === null || res === void 0 ? void 0 : res.logs) !== null && _a !== void 0 ? _a : [];
    }
    async getAllNewTasks() {
        const tasks = await comm.get(ServerPath.getUnreadTasks);
        if (tasks !== undefined) {
            this.parseTasks(tasks);
        }
        return tasks;
    }
    async getAssignedTasks() {
        const tasks = await comm.get(ServerPath.getAllAssignedItems);
        if (tasks !== undefined) {
            this.parseTasks(tasks);
        }
        return tasks;
    }
    async getUserAssignedTasks(req) {
        const tasks = await comm.post(ServerPath.getUserAssignedTask, req);
        if (tasks != undefined) {
            this.parseTasks(tasks);
        }
        return tasks !== null && tasks !== void 0 ? tasks : [];
    }
    parseTasks(tasks) {
        for (const log of tasks) {
            const oid = log.letrid;
            const aid = InMemoryRepo.getLetridFromOrigId(log.letrid);
            if (!aid) {
                continue;
            }
            log.letrid = aid;
            const box = InMemoryRepo.getLetrPassword(log.letrid);
            if (box === undefined) {
                continue;
            }
            if (log.itemid.startsWith("obj-")) {
                if (log.obj) {
                    const obj = ProjectStore.convertProjecObjectToProjectEntity([log.obj], box, log.letrid);
                    if (obj && obj.length > 0) {
                        log.message = obj[0].name + " task assigned to you";
                        log.itemurl = oid + "~~Doc~~" + obj[0].id;
                    }
                    else {
                        log.message = undefined;
                    }
                }
            }
            else if (log.itemid.startsWith("sch-")) {
                if (log.databox) {
                    const dt = SchemaStore.convertMiniSchemaEnBoxToDt(log.databox.schemadata, log.itemid.substring("sch-".length), log.letrid, box);
                    const parts = log.itemid.split("-");
                    if (dt && parts.length > 2) {
                        log.message = dt.name + " task assigned to you";
                        log.itemurl = oid + "~~Datasheet~~" + log.databox.datasheetid + "~~" + log.databox.id + "~~" + parts[2];
                    }
                    else {
                        log.message = undefined;
                    }
                }
            }
        }
    }
    async getAllUnreadLogs(req) {
        const logs = await comm.post(ServerPath.recentLogs, req);
        if (logs !== undefined) {
            for (const log of logs) {
                const oid = log.letrid;
                const aid = InMemoryRepo.getLetridFromOrigId(log.letrid);
                if (!aid) {
                    continue;
                }
                log.letrid = aid;
                if (log.mytype === UpdateLogType.Letr) {
                    const box = InMemoryRepo.getLetrPassword(log.letrid);
                    if (box === undefined) {
                        continue;
                    }
                    if (log.itemid.startsWith("obj-")) {
                        if (log.obj) {
                            const obj = ProjectStore.convertProjecObjectToProjectEntity([log.obj], box, log.letrid);
                            if (obj && obj.length > 0) {
                                if (log.newentry) {
                                    log.message = "New " + obj[0].type + " " + obj[0].name + " added.";
                                }
                                else {
                                    log.message = obj[0].type + " " + obj[0].name + " updated.";
                                }
                                log.accessurl = oid + "~~Doc~~" + obj[0].id;
                            }
                        }
                    }
                    else if (log.itemid.startsWith("datasheet-")) {
                        if (log.databox) {
                            const dt = SchemaStore.convertMiniDatasheetEnBoxToDt(log.databox, log.itemid.substring("datasheet-".length), log.letrid, box);
                            if (dt) {
                                if (log.newentry) {
                                    log.message = "New datasheet " + dt.name + " added.";
                                }
                                else {
                                    log.message = "Datasheet " + dt.name + " updated.";
                                }
                                log.accessurl = oid + "~~Datasheet~~" + dt.id;
                            }
                        }
                    }
                    else if (log.itemid.startsWith("sch-") || log.sheetnote) {
                        if (log.databox) {
                            const dt = SchemaStore.convertMiniSchemaEnBoxToDt(log.databox, "", log.letrid, box);
                            if (dt) {
                                let name = dt.name;
                                if (log.datasheetbox) {
                                    const dtm = SchemaStore.convertMiniDatasheetEnBoxToDt(log.datasheetbox, "", log.letrid, box);
                                    if (dtm)
                                        name += " ( " + dtm.name + " )";
                                }
                                if (log.newentry) {
                                    if (log.sheetnote) {
                                        log.message = "New link added to " + name;
                                    }
                                    else {
                                        log.message = "New row added to " + name;
                                    }
                                }
                                else {
                                    if (log.sheetnote) {
                                        log.message = name + " link updated";
                                    }
                                    else {
                                        log.message = name + " row updated.";
                                    }
                                }
                                if (log.sheetnote) {
                                    const parts = log.sheetnote.split("-");
                                    log.accessurl = oid + "~~Datasheet~~" + dt.datasheetid + "~~" + parts[2] + "~~" + parts[3];
                                }
                                else {
                                    const parts = log.itemid.split("-");
                                    if (parts.length > 2) {
                                        log.accessurl = oid + "~~Datasheet~~" + dt.datasheetid + "~~" + parts[1] + "~~" + parts[2];
                                    }
                                }
                            }
                        }
                    }
                    else if (log.itemid.startsWith("note-")) {
                        if (log.obj) {
                            const obj = ProjectStore.convertProjecObjectToProjectEntity([log.obj], box, log.letrid);
                            if (obj && obj.length > 0) {
                                if (log.newentry) {
                                    log.message = "New note added on " + obj[0].name;
                                }
                                else {
                                    log.message = obj[0].name + " note updated.";
                                }
                                log.accessurl = oid + "~~Doc~~" + obj[0].id + "~~" + log.itemid.split("-")[1];
                            }
                        }
                    }
                }
            }
            return logs;
        }
        return [];
    }
    parseAndPublishUnreadDetails(details) {
        const unreadtags = new Map();
        const unreadlets = new Set();
        for (const deets of details.vtagsCollect) {
            unreadlets.add(deets.id);
            deets.tags.forEach((tag) => {
                var _a;
                let num = (_a = unreadtags.get(tag)) !== null && _a !== void 0 ? _a : 0;
                num += 1;
                unreadtags.set(tag, num);
            });
        }
        const utags = [];
        unreadtags.forEach((val, key) => {
            utags.push({ tagid: key, unread: val });
        });
        const info = {
            newinvites: details.newinvites,
            tagsNumber: utags,
            unreadletrs: Array.from(unreadlets),
            addedAsmanager: details.addedAsmanager,
            unreadPublicContactInvites: details.unreadPublicContactInvites,
            unreadPublicContactLetrs: details.unreadPublicContactLetrs,
            newinvitescount: details.newinvitescount,
            activecalls: details.activecalls,
            missedcalls: details.missedcalls
        };
        // now lets do something here!
        BEvent.publishUnreadInfo(info);
    }
}
export const UnreadStore = new Store();
