import { JobType, SearchDataType, SearchType } from "@@/ModuleJob";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { comm } from "@/Comm/comm";
import { JobPriority } from "@@/File";
import { ServerPath } from "@@/ServerPath";
import { timeout } from "./MediaStore";
import { EncryptBox } from "@@/EncryptBox";
import { SchemaItemType } from "@@/Schema";
import { stripHTML } from "@@/Util";
class Store {
    constructor() {
        this.stopwords = ["i", "me", "my", "myself", "we", "our", "ours", "ourselves", "you", "your", "yours", "yourself",
            "yourselves", "he", "him", "his", "himself", "she", "her", "hers", "herself", "it", "its", "itself", "they",
            "them", "their", "theirs", "themselves", "what", "which", "who", "whom", "this", "that", "these", "those",
            "am", "is", "are", "was", "were", "be", "been", "being", "have", "has", "had", "having", "do", "does",
            "did", "doing", "a", "an", "the", "and", "but", "if", "or", "because", "as", "until", "while", "of", "at",
            "by", "for", "with", "about", "against", "between", "into", "through", "during", "before", "after", "above",
            "below", "to", "from", "up", "down", "in", "out", "on", "off", "over", "under", "again", "further", "then",
            "once", "here", "there", "when", "where", "why", "how", "all", "any", "both", "each", "few", "more", "most",
            "other", "some", "such", "no", "nor", "not", "only", "own", "same", "so", "than", "too", "very", "s", "t",
            "can", "will", "just", "don", "should", "now"];
        this.nameindex = new Map();
        this.activeletr = "";
        this.buildingindex = false;
    }
    async buildNameIndexForLetr(letrid) {
        this.activeletr = letrid;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (!box) {
            this.nameindex = new Map();
            return;
        }
        this.buildingindex = true;
        const req = { letrid: letrid };
        const ret = await comm.post(ServerPath.getLetrProjectNames, req);
        if (ret && letrid === this.activeletr) {
            // lets do some more work!!
            let count = 0;
            while (letrid === this.activeletr && count < ret.length) {
                let i = 0;
                while (i < 200 && count < ret.length) {
                    const item = ret[count];
                    if (item.name) {
                        try {
                            const iname = LetrwingCrypto.decryptSecretBox(item.name, box);
                            if (iname) {
                                this.nameindex.set(item.id, {
                                    id: item.id,
                                    namestr: iname.toLowerCase(),
                                    name: new EncryptBox()
                                });
                            }
                        }
                        catch (ex) { }
                    }
                    count++;
                    i++;
                }
                await timeout(20);
            }
        }
        this.buildingindex = false;
    }
    async searchNameInLetr(letrid, name) {
        if (this.activeletr !== letrid) {
            return undefined;
        }
        let n = 0;
        while (this.buildingindex && n < 100) {
            await timeout(500);
            n++;
        }
        if (n > 100) {
            return undefined;
        }
        const ret = [];
        let nl = name.toLowerCase();
        for (const [_, item] of this.nameindex) {
            if (this.activeletr !== letrid) {
                return undefined;
            }
            if (item.namestr.indexOf(nl) !== -1) {
                ret.push(item.id);
            }
        }
        const termsindex = [];
        for (const st of ret) {
            termsindex.push({
                id: st,
                itemid: st,
                parentid: '',
                position: {
                    wordposition: 0,
                    pagenumber: 0,
                    actualposition: 0
                },
                type: SearchDataType.DriveName
            });
        }
        const res = {
            result: [{
                    term: '',
                    indexdata: termsindex
                }],
            extraitems: []
        };
        return res;
    }
    removeItem(id, letrid) {
        if (this.activeletr === letrid) {
            this.nameindex.delete(id);
        }
    }
    async addToIndex(text, pid, letrid, op, type) {
        if (type === SearchDataType.DriveName) {
            if (this.activeletr === letrid) {
                this.nameindex.set(pid, {
                    id: pid,
                    name: new EncryptBox(),
                    namestr: text.toLowerCase()
                });
            }
            return;
        }
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        const olid = InMemoryRepo.getLetrOid(letrid);
        if (sbox !== null && sbox !== undefined && olid !== undefined) {
            const ejob = {
                letrid: olid,
                itemid: pid,
                text: text,
                operation: op,
                datatype: type,
                datakey: sbox.key,
                nonce: sbox.nonce,
                randomddata: sbox.randomdata
            };
            const job = {
                jobtype: JobType.SearchIndex,
                data: LetrwingCrypto.getBase64FromUT8(JSON.stringify(ejob))
            };
            const res = await comm.get(ServerPath.getmodpubkey);
            if (res !== undefined) {
                const req = [];
                for (const re of res) {
                    const box = LetrwingCrypto.box(JSON.stringify(job), re.publickey);
                    if (box !== null) {
                        req.push({
                            data: box,
                            lrvmdata: re,
                            reference: LetrwingCrypto.hash512str("project" + pid),
                            priority: JobPriority.Low
                        });
                    }
                }
                if (req.length > 0) {
                    const mid = await comm.post(ServerPath.addnewjob, req);
                    if (mid === undefined) {
                        return false;
                    }
                    return true;
                }
            }
        }
        return false;
    }
    async searchMetadata(letrid, filter = []) {
        var _a, _b, _c, _d;
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        const olid = InMemoryRepo.getLetrOid(letrid);
        if (sbox === null || sbox === undefined || olid === undefined) {
            return undefined;
        }
        const mfilter = [];
        const extraids = new Map();
        for (const fl of filter) {
            let evals = [];
            if (fl.fielditem.type === SchemaItemType.FormInput || fl.fielditem.type === SchemaItemType.Text ||
                fl.fielditem.type === SchemaItemType.URL) {
                if (fl.fielditem.type === SchemaItemType.Text) {
                    fl.value = (_a = stripHTML(fl.value)) !== null && _a !== void 0 ? _a : '';
                }
                if (fl.value.trim()) {
                    extraids.set(fl.fielditem.id, fl);
                }
                continue;
            }
            if ((fl.fielditem.type === SchemaItemType.Choice) ||
                (fl.fielditem.type === SchemaItemType.UserChoice)) {
                const vals = fl.value.split(",");
                for (const val of vals) {
                    const eb = LetrwingCrypto.secretBoxWithNonce(val, sbox.key, sbox.nonce);
                    if (eb) {
                        evals.push(eb.encryptmsg);
                    }
                }
            }
            const eva = LetrwingCrypto.secretBoxWithNonce(fl.value, sbox.key, sbox.nonce);
            if (!eva) {
                continue;
            }
            let eva2 = "";
            if (fl.value2) {
                const eva = LetrwingCrypto.secretBoxWithNonce(fl.value, sbox.key, sbox.nonce);
                if (eva) {
                    eva2 = eva.encryptmsg;
                }
            }
            mfilter.push(Object.assign(Object.assign({}, fl), { id: fl.id, value: eva.encryptmsg, value2: eva2, vvalues: evals }));
        }
        const query = {
            orderedterms: [],
            type: SearchType.Phrase,
            itemid: olid,
            itemtype: SearchDataType.DriveName,
            letrid: letrid,
            mfilter: mfilter,
            extrafilterids: Array.from(extraids.keys())
        };
        const res = await comm.post(ServerPath.searchmetadata, query);
        if (res !== undefined) {
            let fids = res.ids;
            if (extraids != null && extraids.size > 0) {
                if (res.extraitems != null && res.extraitems.length > 0) {
                    let newdata = [];
                    if (mfilter.length == 0) {
                        // this is just on field of type text
                        // lets check which one will work now
                        // loop over extra items 
                        for (const eitem of res.extraitems) {
                            try {
                                eitem.data.nonce = sbox.nonce;
                                let sv = LetrwingCrypto.decryptSecretBox(eitem.data, sbox.key);
                                if (!sv) {
                                    continue;
                                }
                                const ceval = extraids.get(eitem.fieldid);
                                if (ceval) {
                                    if (ceval.fielditem.type === SchemaItemType.Text) {
                                        sv = (_b = stripHTML(sv)) !== null && _b !== void 0 ? _b : '';
                                        if (!sv) {
                                            continue;
                                        }
                                    }
                                    if (sv.trim().toLowerCase().indexOf(ceval.value.trim().toLowerCase()) !== -1) {
                                        fids.push(eitem.objectid);
                                    }
                                }
                            }
                            catch (ex) {
                            }
                        }
                    }
                    else {
                        const fieldmap = new Map();
                        for (const eit of res.extraitems) {
                            if (!fieldmap.has(eit.objectid)) {
                                fieldmap.set(eit.objectid, new Map());
                            }
                            (_c = fieldmap.get(eit.objectid)) === null || _c === void 0 ? void 0 : _c.set(eit.fieldid, eit);
                        }
                        objectloop: for (const id of fids) {
                            const fields = fieldmap.get(id);
                            if (fields && fields.size > 0) {
                                for (const [fid, value] of extraids) {
                                    const fit = fields.get(fid);
                                    if (!fit) {
                                        continue objectloop;
                                    }
                                    try {
                                        fit.data.nonce = sbox.nonce;
                                        let sv = LetrwingCrypto.decryptSecretBox(fit.data, sbox.key);
                                        if (sv) {
                                            let field = value.value;
                                            if (value.fielditem.type === SchemaItemType.Text) {
                                                sv = (_d = stripHTML(sv)) !== null && _d !== void 0 ? _d : '';
                                                if (!sv) {
                                                    continue objectloop;
                                                }
                                            }
                                            if (sv.toLowerCase().indexOf(field.trim().toLowerCase()) === -1) {
                                                continue objectloop;
                                            }
                                        }
                                        else {
                                            continue objectloop;
                                        }
                                    }
                                    catch (ex) {
                                        continue objectloop;
                                    }
                                }
                                newdata.push(id);
                            }
                        }
                        fids = newdata;
                    }
                }
                else {
                    fids = [];
                }
            }
            const termsindex = [];
            // we shoudl 
            for (const st of fids) {
                termsindex.push({
                    id: st,
                    itemid: st,
                    parentid: '',
                    position: {
                        wordposition: 0,
                        pagenumber: 0,
                        actualposition: 0
                    },
                    type: SearchDataType.DriveName
                });
            }
            const ret = {
                result: [{
                        term: '',
                        indexdata: termsindex
                    }],
                extraitems: []
            };
            return ret;
        }
        return undefined;
    }
    checkAndGetNumberTerm(str) {
        const alphanumstr = str.replace(/[^a-z0-9]+/gi, "");
        const numstr = alphanumstr.replace(/[a-z]+/gi, "");
        if (numstr !== alphanumstr) {
            return str;
        }
        let withdec = str.replace(/[^0-9.]+/gi, "");
        const patt = /^[\$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/;
        if (patt.test(str)) {
            withdec = str[0] + withdec;
        }
        return withdec;
    }
    async searchTerm(text, letrid, type, accesskey, searctype, filter = []) {
        var _a, _b, _c, _d;
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        const olid = InMemoryRepo.getLetrOid(letrid);
        if (sbox === null || sbox === undefined || olid === undefined) {
            return undefined;
        }
        if (!text.trim() && filter.length > 0) {
            return await this.searchMetadata(letrid, filter);
        }
        if (type === SearchDataType.DriveName) {
            return await this.searchNameInLetr(letrid, text);
        }
        // lets now create a encrypted terms and we can then do rest of the things!!
        const mfilter = [];
        const extraids = new Map();
        for (const fl of filter) {
            const evals = [];
            if (fl.fielditem.type === SchemaItemType.FormInput || fl.fielditem.type === SchemaItemType.Text ||
                fl.fielditem.type === SchemaItemType.URL) {
                if (fl.fielditem.type === SchemaItemType.Text) {
                    fl.value = (_a = stripHTML(fl.value)) !== null && _a !== void 0 ? _a : '';
                }
                if (fl.value.trim()) {
                    extraids.set(fl.fielditem.id, fl);
                }
                continue;
            }
            if ((fl.fielditem.type === SchemaItemType.Choice) ||
                (fl.fielditem.type === SchemaItemType.UserChoice)) {
                const vals = fl.value.split(",");
                for (const val of vals) {
                    const eb = LetrwingCrypto.secretBoxWithNonce(val, sbox.key, sbox.nonce);
                    if (eb) {
                        evals.push(eb.encryptmsg);
                    }
                }
            }
            const eva = LetrwingCrypto.secretBoxWithNonce(fl.value, sbox.key, sbox.nonce);
            if (!eva) {
                continue;
            }
            let eva2 = "";
            if (fl.value2) {
                const eva = LetrwingCrypto.secretBoxWithNonce(fl.value, sbox.key, sbox.nonce);
                if (eva) {
                    eva2 = eva.encryptmsg;
                }
            }
            mfilter.push(Object.assign(Object.assign({}, fl), { id: fl.id, value: eva.encryptmsg, value2: eva2, vvalues: evals }));
        }
        const etoterm = new Map();
        // blockchain data
        // blockchain And data
        // enc(blockchain+ (randomdata)) And enc(data+(randomdata))
        // (enc(a) And enc(b)) Or (enc(c) and enc(d)) Or enc (e) or (enc(f) and enc(g))
        // 
        const terms = [];
        const parts = text.trim().replace("\n", " ").split(" ");
        for (const part of parts) {
            if (!part.trim()) {
                continue;
            }
            let word = part.trim().toLowerCase();
            word = word.replace(/[^\w\s]/g, " ")
                .replace(/\s+/g, " ");
            if (!word || (type === SearchDataType.Drive && this.stopwords.indexOf(word) !== -1)) {
                continue;
            }
            word = this.checkAndGetNumberTerm(word); // make sure we send proper number!
            const pbox = LetrwingCrypto.secretBoxWithNonce(word + sbox.randomdata, sbox.key, sbox.nonce);
            if (!pbox) {
                continue;
            }
            etoterm.set(pbox.encryptmsg, word);
            terms.push(pbox.encryptmsg);
        }
        const query = {
            orderedterms: terms,
            type: searctype,
            itemid: olid,
            itemtype: type,
            letrid: letrid,
            mfilter: mfilter,
            extrafilterids: Array.from(extraids.keys())
        };
        const res = await comm.post(ServerPath.searchquery, query);
        if (res !== undefined) {
            let results = res.result;
            if (extraids != null && extraids.size > 0) {
                results = [];
                let init = [];
                let fids = res.result.reduce((acc, it) => ([...acc, ...it.indexdata.map((id) => id.itemid)]), init);
                if (res.extraitems != null && res.extraitems.length > 0) {
                    const fieldmap = new Map();
                    for (const eit of res.extraitems) {
                        if (!fieldmap.has(eit.objectid)) {
                            fieldmap.set(eit.objectid, new Map());
                        }
                        (_b = fieldmap.get(eit.objectid)) === null || _b === void 0 ? void 0 : _b.set(eit.fieldid, eit);
                    }
                    objectloop: for (const id of fids) {
                        const fields = fieldmap.get(id);
                        if (fields && fields.size > 0) {
                            for (const [fid, value] of extraids) {
                                const fit = fields.get(fid);
                                if (!fit) {
                                    continue objectloop;
                                }
                                try {
                                    let sv = LetrwingCrypto.decryptSecretBox(fit.data, sbox.key);
                                    if (sv) {
                                        let field = value.value;
                                        if (value.fielditem.type === SchemaItemType.Text) {
                                            sv = (_c = stripHTML(sv)) !== null && _c !== void 0 ? _c : '';
                                            if (!sv) {
                                                continue objectloop;
                                            }
                                        }
                                        if (sv.toLowerCase().indexOf(field.toLowerCase()) === -1) {
                                            continue objectloop;
                                        }
                                    }
                                }
                                catch (ex) {
                                    continue objectloop;
                                }
                            }
                            fids.push(id);
                        }
                    }
                }
                else {
                    fids = [];
                }
                for (const re of res.result) {
                    const nres = {
                        term: re.term,
                        indexdata: re.indexdata.filter((id) => fids.indexOf(id.itemid) !== -1)
                    };
                    if (nres.indexdata.length > 0) {
                        results.push(nres);
                    }
                }
            }
            res.result = results;
            res.extraitems = [];
            for (const re of res.result) {
                re.term = (_d = etoterm.get(re.term)) !== null && _d !== void 0 ? _d : re.term;
            }
        }
        return res;
    }
}
export const SearchStore = new Store();
