import parse from 'date-fns/parse';
import format from 'date-fns/format';
class DateEngine {
    constructor() {
        this.formats = ["yyyy-M-d hh:mm:ss",
            "yyyy-M-d HH:mm:ss",
            "d/M/yyyy h:m:s", "d-M-yyyy h:m:s", "d/M/yyyy hh:mm:ss", "d/M/yyyy hh:m:s", "d/M/yyyy hh:mm:s", "d/M/yyyy h:mm:s",
            "d/M/yyyy h:m:ss", "d/M/yyyy hh:m:ss", "d/M/yyyy h:mm:ss",
            "d-M-yyyy hh:mm:ss", "d-M-yyyy hh:m:s", "d-M-yyyy hh:mm:s", "d-M-yyyy h:mm:s",
            "d-M-yyyy h:m:ss", "d-M-yyyy hh:m:ss", "d-M-yyyy h:mm:ss",
            "d/M/yyyy H:m:s", "d-M-yyyy H:m:s", "d/M/yyyy HH:mm:ss", "d/M/yyyy HH:m:s", "d/M/yyyy HH:mm:s", "d/M/yyyy H:mm:s",
            "d/M/yyyy H:m:ss", "d/M/yyyy HH:m:ss", "d/M/yyyy H:mm:ss",
            "d-M-yyyy HH:mm:ss", "d-M-yyyy HH:m:s", "d-M-yyyy HH:mm:s", "d-M-yyyy H:mm:s",
            "d-M-yyyy H:m:ss", "d-M-yyyy HH:m:ss", "d-M-yyyy H:mm:ss",
            "d/M/yyyy K:m:s", "d-M-yyyy K:m:s", "d/M/yyyy KK:mm:ss", "d/M/yyyy KK:m:s", "d/M/yyyy KK:mm:s", "d/M/yyyy K:mm:s",
            "d/M/yyyy K:m:ss", "d/M/yyyy KK:m:ss", "d/M/yyyy K:mm:ss",
            "d-M-yyyy KK:mm:ss", "d-M-yyyy KK:m:s", "d-M-yyyy KK:mm:s", "d-M-yyyy K:mm:s",
            "d-M-yyyy K:m:ss", "d-M-yyyy KK:m:ss", "d-M-yyyy K:mm:ss",
            "d/M/yyyy k:m:s", "d-M-yyyy k:m:s", "d/M/yyyy kk:mm:ss", "d/M/yyyy kk:m:s", "d/M/yyyy kk:mm:s", "d/M/yyyy k:mm:s",
            "d/M/yyyy k:m:ss", "d/M/yyyy kk:m:ss", "d/M/yyyy k:mm:ss",
            "d-M-yyyy kk:mm:ss", "d-M-yyyy kk:m:s", "d-M-yyyy kk:mm:s", "d-M-yyyy k:mm:s",
            "d-M-yyyy k:m:ss", "d-M-yyyy kk:m:ss", "d-M-yyyy k:mm:ss",
            "d/M/yy h:m:s", "d-M-yy h:m:s", "d/M/yy hh:mm:ss", "d/M/yy hh:m:s", "d/M/yy hh:mm:s", "d/M/yy h:mm:s", "d/M/yy h:m:ss",
            "d/M/yy hh:m:ss", "d/M/yy h:mm:ss", "d-M-yy hh:mm:ss", "d-M-yy hh:m:s", "d-M-yy hh:mm:s",
            "d-M-yy h:mm:s", "d-M-yy h:m:ss", "d-M-yy hh:m:ss", "d-M-yy h:mm:ss", "d/M/yy H:m:s", "d-M-yy H:m:s", "d/M/yy HH:mm:ss",
            "d/M/yy HH:m:s", "d/M/yy HH:mm:s", "d/M/yy H:mm:s", "d/M/yy H:m:ss", "d/M/yy HH:m:ss", "d/M/yy H:mm:ss", "d-M-yy HH:mm:ss",
            "d-M-yy HH:m:s", "d-M-yy HH:mm:s", "d-M-yy H:mm:s", "d-M-yy H:m:ss", "d-M-yy HH:m:ss", "d-M-yy H:mm:ss",
            "d/M/yy K:m:s", "d-M-yy K:m:s", "d/M/yy KK:mm:ss", "d/M/yy KK:m:s", "d/M/yy KK:mm:s", "d/M/yy K:mm:s", "d/M/yy K:m:ss",
            "d/M/yy KK:m:ss", "d/M/yy K:mm:ss", "d-M-yy KK:mm:ss", "d-M-yy KK:m:s", "d-M-yy KK:mm:s", "d-M-yy K:mm:s", "d-M-yy K:m:ss",
            "d-M-yy KK:m:ss", "d-M-yy K:mm:ss", "d/M/yy k:m:s", "d-M-yy k:m:s", "d/M/yy kk:mm:ss", "d/M/yy kk:m:s", "d/M/yy kk:mm:s",
            "d/M/yy k:mm:s", "d/M/yy k:m:ss", "d/M/yy kk:m:ss", "d/M/yy k:mm:ss",
            "d-M-yy kk:mm:ss", "d-M-yy kk:m:s", "d-M-yy kk:mm:s", "d-M-yy k:mm:s", "d-M-yy k:m:ss", "d-M-yy kk:m:ss", "d-M-yy k:mm:ss"
        ];
        this.usformats = ["M/d/yyyy h:m:s", "M-d-yyyy h:m:s", "M/d/yyyy hh:mm:ss", "M/d/yyyy hh:m:s", "M/d/yyyy hh:mm:s", "M/d/yyyy h:mm:s",
            "M/d/yyyy h:m:ss", "M/d/yyyy hh:m:ss", "M/d/yyyy h:mm:ss", "M-d-yyyy hh:mm:ss", "M-d-yyyy hh:m:s", "M-d-yyyy hh:mm:s", "M-d-yyyy h:mm:s",
            "M-d-yyyy h:m:ss", "M-d-yyyy hh:m:ss", "M-d-yyyy h:mm:ss", "M/d/yyyy H:m:s", "M-d-yyyy H:m:s", "M/d/yyyy HH:mm:ss", "M/d/yyyy HH:m:s",
            "M/d/yyyy HH:mm:s", "M/d/yyyy H:mm:s", "M/d/yyyy H:m:ss", "M/d/yyyy HH:m:ss", "M/d/yyyy H:mm:ss", "M-d-yyyy HH:mm:ss", "M-d-yyyy HH:m:s",
            "M-d-yyyy HH:mm:s", "M-d-yyyy H:mm:s", "M-d-yyyy H:m:ss", "M-d-yyyy HH:m:ss", "M-d-yyyy H:mm:ss", "M/d/yyyy K:m:s", "M-d-yyyy K:m:s",
            "M/d/yyyy KK:mm:ss", "M/d/yyyy KK:m:s", "M/d/yyyy KK:mm:s", "M/d/yyyy K:mm:s", "M/d/yyyy K:m:ss", "M/d/yyyy KK:m:ss", "M/d/yyyy K:mm:ss",
            "M-d-yyyy KK:mm:ss", "M-d-yyyy KK:m:s", "M-d-yyyy KK:mm:s", "M-d-yyyy K:mm:s", "M-d-yyyy K:m:ss", "M-d-yyyy KK:m:ss", "M-d-yyyy K:mm:ss",
            "M/d/yyyy k:m:s", "M-d-yyyy k:m:s", "M/d/yyyy kk:mm:ss", "M/d/yyyy kk:m:s", "M/d/yyyy kk:mm:s", "M/d/yyyy k:mm:s", "M/d/yyyy k:m:ss",
            "M/d/yyyy kk:m:ss", "M/d/yyyy k:mm:ss", "M-d-yyyy kk:mm:ss", "M-d-yyyy kk:m:s", "M-d-yyyy kk:mm:s", "M-d-yyyy k:mm:s", "M-d-yyyy k:m:ss",
            "M-d-yyyy kk:m:ss", "M-d-yyyy k:mm:ss", "M/d/yy", "M/d/yy h:m:s", "M-d-yy h:m:s", "M/d/yy hh:mm:ss", "M/d/yy hh:m:s", "M/d/yy hh:mm:s", "M/d/yy h:mm:s",
            "M/d/yy h:m:ss", "M/d/yy hh:m:ss", "M/d/yy h:mm:ss", "M-d-yy hh:mm:ss", "M-d-yy hh:m:s", "M-d-yy hh:mm:s", "M-d-yy h:mm:s",
            "M-d-yy h:m:ss", "M-d-yy hh:m:ss", "M-d-yy h:mm:ss", "M/d/yy H:m:s", "M-d-yy H:m:s", "M/d/yy HH:mm:ss", "M/d/yy HH:m:s",
            "M/d/yy HH:mm:s", "M/d/yy H:mm:s", "M/d/yy H:m:ss", "M/d/yy HH:m:ss", "M/d/yy H:mm:ss", "M-d-yy HH:mm:ss", "M-d-yy HH:m:s",
            "M-d-yy HH:mm:s", "M-d-yy H:mm:s", "M-d-yy H:m:ss", "M-d-yy HH:m:ss", "M-d-yy H:mm:ss", "M/d/yy K:m:s", "M-d-yy K:m:s",
            "M/d/yy KK:mm:ss", "M/d/yy KK:m:s", "M/d/yy KK:mm:s", "M/d/yy K:mm:s", "M/d/yy K:m:ss", "M/d/yy KK:m:ss", "M/d/yy K:mm:ss",
            "M-d-yy KK:mm:ss", "M-d-yy KK:m:s", "M-d-yy KK:mm:s", "M-d-yy K:mm:s", "M-d-yy K:m:ss", "M-d-yy KK:m:ss", "M-d-yy K:mm:ss",
            "M/d/yy k:m:s", "M-d-yy k:m:s", "M/d/yy kk:mm:ss", "M/d/yy kk:m:s", "M/d/yy kk:mm:s", "M/d/yy k:mm:s", "M/d/yy k:m:ss",
            "M/d/yy kk:m:ss", "M/d/yy k:mm:ss", "M-d-yy kk:mm:ss", "M-d-yy kk:m:s", "M-d-yy kk:mm:s", "M-d-yy k:mm:s", "M-d-yy k:m:ss",
            "M-d-yy kk:m:ss", "M-d-yy k:mm:ss"
        ];
        this.allformats = [...this.formats, ...this.usformats];
    }
    displayDateWIthFormat(test, includetime, excludedate = false) {
        if (test === undefined) {
            return '';
        }
        try {
            const d = new Date(parseInt(test + ""));
            let formatd = "";
            if (!excludedate) {
                formatd = "dd/MM/yyyy";
            }
            if (includetime) {
                formatd += " HH:mm";
            }
            return format(d, formatd);
        }
        catch (ex) {
            console.log("Exception parsing date  " + test);
        }
        return '';
    }
    displayOnlyDate(test) {
        if (test === undefined) {
            return 'missing';
        }
        const d = new Date(test);
        return format(d, 'dd/MM/yyyy');
    }
    displayDate(test) {
        if (test === undefined) {
            return 'missing';
        }
        const d = new Date(test);
        const cdate = new Date();
        if (d.getDate() === cdate.getDate() && d.getFullYear() === cdate.getFullYear() && d.getMonth() === cdate.getMonth()) {
            return format(d, 'HH:mm');
        }
        return format(d, 'dd/MM/yy HH:mm');
    }
    displayTime(time) {
        if (time === undefined) {
            return 'missing';
        }
        const d = new Date(time);
        return format(d, 'HH:mm');
    }
    getTodayTimeOrDate(time) {
        if (time === undefined) {
            return 'missing';
        }
        const d = new Date(time);
        const cdate = new Date();
        if (d.getDate() === cdate.getDate() && d.getFullYear() === cdate.getFullYear() && d.getMonth() === cdate.getMonth()) {
            return format(d, 'HH:mm');
        }
        return format(d, 'dd/MM/yyyyy HH:mm');
    }
    getDateFolderName() {
        const d = new Date();
        return format(d, 'yyyy_mm_dd_HH_mm_ss');
    }
    getTodayOrFormatDate(time) {
        if (time === undefined) {
            return 'missing';
        }
        const d = new Date(time);
        const cdate = new Date();
        if (d.getDate() === cdate.getDate() && d.getFullYear() === cdate.getFullYear() && d.getMonth() === cdate.getMonth()) {
            return 'Today';
        }
        return format(d, 'dd/MM/yyyyy');
    }
    checkAndReturnValidDate(datestring) {
        for (const f of this.allformats) {
            const d = parse(datestring, f, new Date().getTime());
            if (!isNaN(d.getTime())) {
                return d;
            }
        }
        return undefined;
    }
}
export const DateLib = new DateEngine();
