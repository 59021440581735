import { Comparator, ConditionType, RuleType, SchemaItemType } from "./Schema";
import { LetrwingCrypto } from "./LetrwingCommonCrypto";
import { stripHTML } from "./Util";
// we need to evaluate all of these first
export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
export const sortEntriesByRow = (en1, en2) => {
    var _a, _b;
    const s1 = (_a = en1.subrowindex) !== null && _a !== void 0 ? _a : -1;
    const s2 = (_b = en2.subrowindex) !== null && _b !== void 0 ? _b : -1;
    if (s1 === -1 && s2 === -1) {
        return 0;
    }
    if (s1 === -1) {
        return 1;
    }
    if (s2 === -1) {
        return -1;
    }
    const parts1 = s1.split("----");
    const parts2 = s2.split("----");
    if (parts1[0] === parts2[0]) {
        // if(parts1.length < 2 || parts2.length < 2) {
        //     return -1;
        // }
        return parts1[1].localeCompare(parts2[1]);
    }
    return parseInt(parts1[0]) - parseInt(parts2[0]);
};
export const convertDepToRows = (dep) => {
    var _a, _b;
    const sbycols = new Map();
    const entrybyschema = new Map();
    for (const k of Array.from(dep.keys())) {
        const vals = dep.get(k);
        const parts = k.split("~~~");
        if (!entrybyschema.has(parts[1])) {
            entrybyschema.set(parts[1], []);
        }
        if (vals) {
            const ens = vals;
            for (const en of ens) {
                (_a = entrybyschema.get(parts[1])) === null || _a === void 0 ? void 0 : _a.push(en);
            }
        }
    }
    // now we have entries by schema lets do a rows now
    for (const sid of Array.from(entrybyschema.keys())) {
        const ens = entrybyschema.get(sid);
        ens === null || ens === void 0 ? void 0 : ens.sort((e1, e2) => {
            var _a, _b;
            if (e1.rowindex === e2.rowindex) {
                const s1 = (_a = e1.subrowindex) !== null && _a !== void 0 ? _a : -1;
                const s2 = (_b = e2.subrowindex) !== null && _b !== void 0 ? _b : -1;
                if (s1 === -1 && s2 === -1) {
                    return 0;
                }
                if (s1 === -1) {
                    return 1;
                }
                if (s2 === -1) {
                    return -1;
                }
                const parts1 = s1.split("----");
                const parts2 = s2.split("----");
                if (parts1.length < 2 || parts2.length < 2) {
                    return -1;
                }
                if (parts1[0] === parts2[0]) {
                    return parts1[1].localeCompare(parts2[1]);
                }
                return parseInt(parts1[0]) - parseInt(parts2[0]);
            }
            return e1.rowindex - e2.rowindex;
        });
        if (ens) {
            const rowmap = new Map();
            for (const en of ens) {
                if (!rowmap.has(en.rowid)) {
                    rowmap.set(en.rowid, {
                        rowid: en.rowid,
                        rowindex: en.rowindex,
                        entry: []
                    });
                }
                (_b = rowmap.get(en.rowid)) === null || _b === void 0 ? void 0 : _b.entry.push(en);
            }
            // now we have rowmap
            const rows = Array.from(rowmap.values()).sort((r1, r2) => r1.rowindex - r2.rowindex);
            sbycols.set(sid, rows);
        }
    }
    return sbycols;
};
export const dateRuleParser = (row, rowrules, sbycols, shs, workingid, ritems = new Map(), overwrite = false) => {
};
export const textRuleParser = (row, rowrules, sbycols, shs, workingid, ritems = new Map(), overwrite = false) => {
    var _a, _b, _c;
    // steps: 
    // step1: fiund all valid rows as per conditions
    // step2: parse and create data rows
    // if list type then sublist otherwie single row!
    // return is [{cellid: '', celldata: '', subrowindex:''}]
    const ret = [];
    const myschema = shs.get(workingid);
    if (!myschema)
        return ret;
    const cellid = rowrules.colid;
    const islisttype = cellid.indexOf("----") !== -1;
    let takenrowsnumber = 0;
    let emap = new Map();
    for (const en of row.entry) {
        if (!emap.has(en.cellid)) {
            emap.set(en.cellid, []);
        }
        (_a = emap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
    }
    let parentid = "";
    let nextrowcount = 0;
    const ens = emap.get(cellid);
    if (cellid.indexOf("----") !== -1) {
        parentid = cellid.split("----")[0];
        const listitem = myschema.items.filter(it => it.id === parentid);
        if (listitem && listitem.length > 0) {
            const lit = listitem[0];
            if (lit.items && lit.items.length > 0) {
                for (const litem of lit.items) {
                    const lens = emap.get(lit.id + "----" + litem.item.id);
                    if (lens && lens.length > 0) {
                        for (const en of lens) {
                            const sr = en.subrowindex;
                            if (sr) {
                                const pp = sr.split("----");
                                if (pp.length > 1) {
                                    const inval = parseInt(pp[0]);
                                    if (!isNaN(inval) && inval > nextrowcount) {
                                        nextrowcount = inval + 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    let resuls = [];
    let celldata = [];
    let activecell = "";
    for (const rule of rowrules.rule) {
        // now lets run this over 
        if (rule.type === RuleType.Append) {
            if (islisttype) {
                if (celldata.length > 0) {
                    resuls.push(...celldata);
                }
                celldata = [];
            }
        }
        if (rule.params && rule.params.length > 0) {
            const rp = rule.params[0];
            if (rp.type === "userdefinedstring") {
                if (celldata.length > 0) {
                    // append to everything
                    let length = celldata.length;
                    for (let i = 0; i < length; i++) {
                        const celv = celldata[i];
                        celldata.splice(i, 1, (celv + " " + rp.value));
                    }
                }
                else if (!islisttype) {
                    celldata.push(rp.value);
                }
                continue;
            }
            const rpval = rp.value;
            const parts = rpval.split("~~~");
            if (parts.length > 2) {
                let myresults = [];
                const did = parts[0];
                const sid = parts[1];
                const cid = parts[2];
                const srows = sbycols.get(sid);
                const rulesh = shs.get(sid);
                if (!rulesh)
                    continue;
                if (srows && srows.length > 0) {
                    const matchingrows = [];
                    const conds = filterInvalidConsitions((_b = rp.conds) !== null && _b !== void 0 ? _b : []);
                    if (conds && conds.length > 0) {
                        for (const sr of srows) {
                            let myrowne = new Map();
                            for (const en of sr.entry) {
                                if (!myrowne.has(en.cellid)) {
                                    myrowne.set(en.cellid, []);
                                }
                                (_c = myrowne.get(en.cellid)) === null || _c === void 0 ? void 0 : _c.push(en);
                            }
                            for (const con of conds) {
                                for (const p of con.params) {
                                    if (p.leftcond) {
                                        const ens = myrowne.get(p.leftcond);
                                        if (ens && ens.length > 0) {
                                            for (const e of ens) {
                                                if (e.ruleopen && !e.decrypted) {
                                                    // lets decrypt this too?
                                                    parser([e], rulesh, sbycols, shs, ritems);
                                                    e.decrypted = true;
                                                }
                                                else if (!e.decrypted) {
                                                    decryptCell(e, rulesh, ritems);
                                                    e.decrypted = true;
                                                }
                                            }
                                        }
                                    }
                                    if (p.rightschemacon) {
                                        const ens = emap.get(p.rightschemacon);
                                        if (ens && ens.length > 0) {
                                            for (const e of ens) {
                                                if (e.ruleopen && !e.decrypted) {
                                                    // lets decrypt this too?
                                                    parser([e], myschema, sbycols, shs, ritems);
                                                    e.decrypted = true;
                                                }
                                                else if (!e.decrypted) {
                                                    decryptCell(e, myschema, ritems);
                                                    e.decrypted = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (compareRowForRuleCond(conds, row, sr, myschema, rulesh)) {
                                matchingrows.push(sr);
                            }
                        }
                    }
                    else {
                        // matchingrows.push(...srows);
                    }
                    for (const ro of matchingrows) {
                        const entryies = [];
                        for (const en of ro.entry) {
                            if (en.cellid === cid) {
                                if (!en.decrypted) {
                                    if (en.ruleopen) {
                                        parser([en], rulesh, sbycols, shs, ritems);
                                    }
                                    else {
                                        decryptCell(en, rulesh, ritems);
                                    }
                                    en.decrypted = true;
                                }
                                entryies.push(en);
                            }
                        }
                        entryies.sort((e1, e2) => {
                            var _a, _b;
                            const s1 = (_a = e1.subrowindex) !== null && _a !== void 0 ? _a : -1;
                            const s2 = (_b = e2.subrowindex) !== null && _b !== void 0 ? _b : -1;
                            if (s1 === -1 && s2 === -1) {
                                return 0;
                            }
                            if (s1 === -1) {
                                return 1;
                            }
                            if (s2 === -1) {
                                return -1;
                            }
                            const parts1 = s1.split("----");
                            const parts2 = s2.split("----");
                            if (parts1[0] === parts2[0]) {
                                return parts1[1].localeCompare(parts2[1]);
                            }
                            return parseInt(parts1[0]) - parseInt(parts2[0]);
                        });
                        // now we have all sorted entries 
                        // lets check 
                        // we might have to give conditions to combine entries too ??
                        // cant think of use but lets see 
                        if (islisttype) {
                            if (rule.type === RuleType.Append) {
                                for (let i = 0; i < entryies.length; i++) {
                                    // for ap[pend just keep appending!
                                    celldata.push(entryies[i].celldata);
                                }
                            }
                            else {
                                // 
                                for (let i = 0; i < entryies.length; i++) {
                                    let acell = "";
                                    if (celldata.length > i) {
                                        acell = celldata[i];
                                    }
                                    acell += entryies[i].celldata;
                                    celldata[i] = acell;
                                }
                            }
                        }
                        else {
                            let acell = "";
                            if (celldata.length > 0) {
                                acell = celldata[0];
                            }
                            for (const en of entryies) {
                                acell += " " + en.celldata;
                            }
                            celldata[0] = acell;
                        }
                    }
                }
            }
        }
    }
    if (celldata.length > 0) {
        resuls.push(...celldata);
    }
    if (resuls.length > 0) {
        for (const st of resuls) {
            ret.push({
                celldata: st,
                cellid: cellid,
                subrowindex: nextrowcount + "----" + LetrwingCrypto.getUUID()
            });
            nextrowcount++;
        }
    }
    return ret;
};
export const parser = (entries, schea, sbycols, shs, ritems = new Map()) => {
    // we should alreast have sorted columns now!!
    var _a, _b;
    for (const en of entries) {
        if (en.ruleopen) {
            en.celldata = parseRulesEntry(en, en.ruleopen, sbycols, (_a = en.ruleopen.isshrule) !== null && _a !== void 0 ? _a : false, schea.id, schea, shs, ritems);
            const prec = parseInt((_b = en.ruleopen.precision) !== null && _b !== void 0 ? _b : '');
            if (!isNaN(prec)) {
                let res = parseFloat(en.celldata);
                var tenToN = 10 ** prec;
                res = (Math.round(res * tenToN)) / tenToN;
                en.celldata = res + "";
            }
        }
    }
    function parseRulesEntry(en, crule, rows, isshrule, myshid, mainschema, shs, ritems) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const valuebyentry = new Map();
        const locaritems = new Map();
        for (const k of Array.from(ritems.keys())) {
            const kpa = k.split("---");
            if (kpa.length > 2) {
                locaritems.set(kpa[1] + "---" + kpa[2], (_a = ritems.get(k)) !== null && _a !== void 0 ? _a : []);
            }
            else {
                locaritems.set(k, (_b = ritems.get(k)) !== null && _b !== void 0 ? _b : []);
            }
        }
        /**
         * idea here is we go over these param and get all values first
         */
        let myroindex = en.rowindex;
        const eparts = en.cellid.split("----");
        const myrows = rows.get(myshid);
        const myrowschema = shs.get(myshid);
        if (!myrowschema) {
            return "";
        }
        if (myrows) {
            for (let i = 0; i < myrows.length; i++) {
                const r = myrows[i];
                if (r.rowid === en.rowid) {
                    myroindex = i;
                    break;
                }
            }
        }
        else {
            return "";
        }
        let myrow = myrows[myroindex];
        let prevstageres = "";
        let entryval = new Map();
        let index = -1;
        let myrowne = new Map();
        for (const en of myrow.entry) {
            if (!myrowne.has(en.cellid)) {
                myrowne.set(en.cellid, []);
            }
            (_c = myrowne.get(en.cellid)) === null || _c === void 0 ? void 0 : _c.push(en);
        }
        for (const rule of crule.rule) {
            index++;
            if (rule.conds && rule.conds.length > 0) {
                for (const con of rule.conds) {
                    // whatever is left and right dependent we need to decryt them
                    for (const p of con.params) {
                        if (p.leftcond) {
                            const ens = myrowne.get(p.leftcond);
                            if (ens && ens.length > 0) {
                                for (const e of ens) {
                                    if (e.ruleopen && !e.decrypted) {
                                        // lets decrypt this too?
                                        e.celldata = parseRulesEntry(e, e.ruleopen, rows, isshrule, myshid, mainschema, shs, ritems);
                                        e.decrypted = true;
                                    }
                                    else if (!entryval.has(e.id) && !e.decrypted) {
                                        const msh = shs.get(myshid);
                                        if (msh) {
                                            decryptCell(e, msh, ritems);
                                        }
                                    }
                                }
                                if (p.rightschemacon) {
                                    const rens = (_d = myrowne.get(p.rightschemacon)) !== null && _d !== void 0 ? _d : [];
                                    for (const e of rens) {
                                        if (e.ruleopen && !e.decrypted) {
                                            // lets decrypt this too?
                                            e.celldata = parseRulesEntry(e, e.ruleopen, rows, isshrule, myshid, mainschema, shs, ritems);
                                            e.decrypted = true;
                                        }
                                        else if (!entryval.has(e.id) && !e.decrypted) {
                                            const msh = shs.get(myshid);
                                            if (msh) {
                                                decryptCell(e, msh, ritems);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // lets check if we can run this rule here ot not!!
                if (!checkRowSatifyCond(rule, myrow, en, myrowschema, myrowschema)) {
                    continue;
                }
            }
            for (const param of rule.params) {
                // lets open the con ditions as well!!
                if (param.type === 'schema') {
                    const parts = param.value.split("~~~");
                    const did = parts[0];
                    const sid = parts[1];
                    const cid = parts[2];
                    let rnum = isshrule ? 0 : myroindex; // we need to find out what's our true 
                    if (parts.length > 3) {
                        let pn = parseInt(parts[3]) - 1;
                        rnum = isshrule ? pn + myroindex : pn;
                    }
                    // now we have exact row number
                    let orows = rows.get(sid);
                    if (!orows)
                        continue;
                    if (sid !== myshid) {
                        orows.sort((r1, r2) => r1.rowindex - r2.rowindex);
                    }
                    if (param.conds && param.conds.length > 0) {
                        for (const cond of param.conds) {
                            for (const p of cond.params) {
                                if (p.leftcond) {
                                    // this is cell of sid 
                                    const leftcellid = p.leftcond;
                                    for (const or of orows) {
                                        for (const e of or.entry) {
                                            if (e.cellid === leftcellid) {
                                                if (!entryval.has(e.id) && e.ruleopen && !e.decrypted) {
                                                    // lets decrypt this too?
                                                    e.celldata = parseRulesEntry(e, e.ruleopen, rows, isshrule, sid, mainschema, shs, ritems);
                                                    e.decrypted = true;
                                                }
                                                else if (!entryval.has(e.id) && !e.decrypted) {
                                                    const msh = shs.get(sid);
                                                    if (msh) {
                                                        decryptCell(e, msh, ritems);
                                                    }
                                                }
                                                entryval.set(e.id, e.celldata);
                                            }
                                        }
                                    }
                                    if (p.rightschemacon) {
                                        for (const e of myrow.entry) {
                                            if (e.cellid === p.rightschemacon) {
                                                if (!e.decrypted) {
                                                    if (e.ruleopen) {
                                                        e.celldata = parseRulesEntry(e, e.ruleopen, rows, (_e = e.ruleopen.isshrule) !== null && _e !== void 0 ? _e : false, myshid, mainschema, shs, ritems);
                                                        e.decrypted = true;
                                                    }
                                                    else if (!entryval.has(e.id) && !e.decrypted) {
                                                        const msh = shs.get(myshid);
                                                        if (msh) {
                                                            decryptCell(e, msh, ritems);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        const sentries = [];
                        for (const orow of orows) {
                            for (const se of orow.entry) {
                                if (se.cellid === cid) {
                                    if (!entryval.has(se.id) && se.ruleopen && !se.decrypted) {
                                        // lets decrypt this too?
                                        se.celldata = parseRulesEntry(se, se.ruleopen, rows, isshrule, sid, mainschema, shs, ritems);
                                        se.decrypted = true;
                                    }
                                    else if (!entryval.has(se.id) && !se.decrypted) {
                                        const msh = shs.get(sid);
                                        if (msh) {
                                            decryptCell(se, msh, ritems);
                                        }
                                    }
                                    entryval.set(se.id, se.celldata);
                                    sentries.push(se);
                                }
                            }
                        }
                        if (sentries.length === 0)
                            continue;
                    }
                    else {
                        // okay if this is rule based on rows then we need this to be nice!!
                        let rnum = myroindex;
                        if (parts.length > 3) {
                            let pn = parseInt(parts[3]) - 1;
                            rnum = isshrule ? pn + myroindex : pn;
                        }
                        if (orows.length <= rnum)
                            continue;
                        const orow = orows[rnum];
                        // all entries we need are here now
                        const sentries = [];
                        for (const se of orow.entry) {
                            if (se.cellid === cid) {
                                if (!entryval.has(se.id) && se.ruleopen && !se.decrypted) {
                                    // lets decrypt this too?
                                    se.celldata = parseRulesEntry(se, se.ruleopen, rows, isshrule, sid, mainschema, shs, ritems);
                                    se.decrypted = true;
                                }
                                else if (!entryval.has(se.id) && !se.decrypted) {
                                    const msh = shs.get(sid);
                                    if (msh) {
                                        decryptCell(se, msh, ritems);
                                    }
                                }
                                entryval.set(se.id, se.celldata);
                                sentries.push(se);
                            }
                        }
                        if (sentries.length === 0)
                            continue;
                    }
                }
            }
            const othersublist = new Map();
            const values = [];
            for (const param of rule.params) {
                if (param.type !== 'schema') {
                    values.push(param.value);
                    continue;
                }
                // okay now we need to do some clever things to calculate result for each param!
                const parts = param.value.split("~~~");
                const did = parts[0];
                const sid = parts[1];
                const cid = parts[2];
                let rnum = isshrule ? 0 : myroindex; // we need to find out what's our true 
                if (parts.length > 3) {
                    let pn = parseInt(parts[3]) - 1;
                    rnum = isshrule ? pn + myroindex : pn;
                }
                // now we have exact row number
                const orows = rows.get(sid);
                const mysh = shs.get(sid);
                if (!orows)
                    continue;
                // if we have other rule we 
                if (param.conds && param.conds.length > 0) {
                    if (!mysh || !myrowschema)
                        continue;
                    const pres = parseConditionalParam(param, orows, myrow, en, mysh, myrowschema, locaritems);
                    if (pres === 'invalid')
                        continue;
                    //we need to get the reuslkt for this from conditional parser!!
                    values.push(pres + "");
                    continue;
                }
                rnum = myroindex;
                if (parts.length > 3) {
                    let pn = parseInt(parts[3]) - 1;
                    rnum = isshrule ? pn + myroindex : pn;
                }
                if (orows.length <= rnum)
                    continue;
                const orow = orows[rnum];
                // now we have everything lets collect our 
                const sentries = [];
                // collect entry by parent
                // if there is sublist
                for (const se of orow.entry) {
                    if (se.cellid === cid) {
                        if (se.cellid.indexOf("----") === -1) {
                            const msh = shs.get(sid);
                            if (!se.decrypted && se.ruleopen && msh) {
                                se.celldata = parseRulesEntry(se, se.ruleopen, rows, isshrule, sid, mainschema, shs, ritems);
                                se.decrypted = true;
                            }
                            else if (!se.decrypted && msh)
                                decryptCell(se, msh, ritems);
                            values.push(se.celldata);
                            break;
                        }
                        const parts = se.cellid.split("----");
                        if (sid !== myshid || (parts[0] !== eparts[0])) {
                            if (!othersublist.has(parts[0])) {
                                othersublist.set(parts[0], []);
                            }
                            const msh = shs.get(sid);
                            if (!se.decrypted && se.ruleopen && msh) {
                                se.celldata = parseRulesEntry(se, se.ruleopen, rows, isshrule, sid, mainschema, shs, ritems);
                                se.decrypted = true;
                            }
                            else if (!se.decrypted && msh)
                                decryptCell(se, msh, ritems);
                            (_f = othersublist.get(parts[0])) === null || _f === void 0 ? void 0 : _f.push(se);
                        }
                        else {
                            // if same parent then only apply to same children row
                            if (se.subrowindex === en.subrowindex) {
                                const msh = shs.get(sid);
                                if (!se.decrypted && se.ruleopen && msh) {
                                    se.celldata = parseRulesEntry(se, se.ruleopen, rows, isshrule, sid, mainschema, shs, ritems);
                                    se.decrypted = true;
                                }
                                else if (!se.decrypted && msh)
                                    decryptCell(se, msh, ritems);
                                values.push(se.celldata);
                            }
                        }
                    }
                }
            }
            if (othersublist.size > 0) {
                for (const v of Array.from(othersublist.values())) {
                    v.sort((e1, e2) => {
                        var _a, _b;
                        const s1 = (_a = e1.subrowindex) !== null && _a !== void 0 ? _a : -1;
                        const s2 = (_b = e2.subrowindex) !== null && _b !== void 0 ? _b : -1;
                        if (s1 === -1 && s2 === -1) {
                            return 0;
                        }
                        if (s1 === -1) {
                            return 1;
                        }
                        if (s2 === -1) {
                            return -1;
                        }
                        const parts1 = s1.split("----");
                        const parts2 = s2.split("----");
                        if (parts1[0] === parts2[0]) {
                            return parts1[1].localeCompare(parts2[1]);
                        }
                        return parseInt(parts1[0]) - parseInt(parts2[0]);
                    });
                    let result = 0;
                    let prevsindex = "";
                    let rowresult = rule.type === RuleType.Multiply || rule.type === RuleType.Divide || rule.type === RuleType.Power
                        ? 1 : 0;
                    let myindex = 0;
                    for (const e of v) {
                        if (!prevsindex) {
                            prevsindex = (_g = e.subrowindex) !== null && _g !== void 0 ? _g : '';
                        }
                        if (e.subrowindex !== prevsindex) {
                            result += rowresult;
                            prevsindex = (_h = e.subrowindex) !== null && _h !== void 0 ? _h : '';
                            rowresult = rule.type === RuleType.Multiply || rule.type === RuleType.Divide || rule.type === RuleType.Power
                                ? 1 : 0;
                        }
                        const num = parseFloat(e.celldata);
                        if (isNaN(num))
                            continue;
                        // now we have everything lets move one!!
                        if (rule.type === RuleType.Add) {
                            rowresult += num;
                        }
                        else if (rule.type === RuleType.Subtract) {
                            if (myindex === 0)
                                rowresult = num;
                            else
                                rowresult -= num;
                        }
                        else if (rule.type === RuleType.Multiply) {
                            if (myindex === 0)
                                rowresult = num;
                            else
                                rowresult *= num;
                        }
                        else if (rule.type === RuleType.Divide && num !== 0) {
                            if (myindex === 0)
                                rowresult = num;
                            else
                                rowresult /= num;
                        }
                        else if (rule.type === RuleType.Power) {
                            if (myindex === 0)
                                rowresult = num;
                            else
                                rowresult ^= num;
                        }
                        myindex++;
                    }
                    result += rowresult;
                    values.push(result + '');
                }
            }
            // lets check if we can do this row or not!!
            // now all sub list done as well just calulat ethe result and we done
            // if this is one rule then we should just need to 0 ?
            let rowresult = crule.rule.length > 0 && (rule.type === RuleType.Multiply
                || rule.type === RuleType.Divide || rule.type === RuleType.Power) ? 1 : 0;
            // we need to add prev stage result as well
            let myindex = 0;
            for (const v of values) {
                const num = parseFloat(v);
                if (isNaN(num))
                    continue;
                if (rule.type === RuleType.Add) {
                    rowresult += num;
                }
                else if (rule.type === RuleType.Subtract) {
                    if (myindex === 0)
                        rowresult = num;
                    else
                        rowresult -= num;
                }
                else if (rule.type === RuleType.Multiply) {
                    if (myindex === 0)
                        rowresult = num;
                    else
                        rowresult *= num;
                }
                else if (rule.type === RuleType.Divide) {
                    if (myindex === 0)
                        rowresult = num;
                    else if (num !== 0)
                        rowresult /= num;
                }
                else if (rule.type === RuleType.Power) {
                    if (myindex === 0)
                        rowresult = num;
                    else
                        rowresult ^= num;
                }
                myindex++;
            }
            if (index > 0) {
                const pnum = parseFloat(prevstageres);
                if (!isNaN(pnum)) {
                    const ptype = (_j = rule.prevstageresult) !== null && _j !== void 0 ? _j : rule.type;
                    if (ptype === RuleType.Add)
                        rowresult = pnum + rowresult;
                    else if (ptype === RuleType.Subtract)
                        rowresult = pnum - rowresult;
                    else if (ptype === RuleType.Multiply)
                        rowresult = pnum * rowresult;
                    else if (ptype === RuleType.Divide) {
                        if (rowresult === 0)
                            rowresult = 0;
                        else
                            rowresult = pnum / rowresult;
                    }
                    else if (ptype === RuleType.Power)
                        rowresult = pnum ^ rowresult;
                }
            }
            prevstageres = rowresult + "";
        }
        return prevstageres;
    }
};
export const compareRowForRuleCond = (conds, myrow, compareRow, myschema, compareSchema) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const comparemap = new Map();
    const myrowmap = new Map();
    for (const en of compareRow.entry) {
        if (!comparemap.has(en.cellid)) {
            comparemap.set(en.cellid, []);
        }
        (_a = comparemap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
    }
    for (const en of myrow.entry) {
        if (!myrowmap.has(en.cellid)) {
            myrowmap.set(en.cellid, []);
        }
        (_b = myrowmap.get(en.cellid)) === null || _b === void 0 ? void 0 : _b.push(en);
    }
    let prevstageres = false;
    if (conds && conds.length > 0) {
        let index = -1;
        for (const cond of conds) {
            index++;
            let mystageco = cond.contype;
            if (index > 0) {
                let pcom = mystageco;
                if (cond.prevtype) {
                    pcom = cond.prevtype;
                }
                if (pcom === ConditionType.And && !prevstageres) {
                    // now it's false from preveious stage !
                    continue;
                }
                else if (pcom === ConditionType.Or && prevstageres) {
                    continue;
                }
            }
            // lets see what this condition will do 
            // we need to do and with all we have !
            let paramresuls = [];
            const myitems = new Map();
            const compareitems = new Map();
            for (const it of myschema.items) {
                if (it.type === SchemaItemType.List) {
                    const lit = it;
                    for (const li of lit.items) {
                        myitems.set(it.id + "----" + li.item.id, li.item);
                    }
                }
                else {
                    myitems.set(it.id, it);
                }
            }
            for (const it of compareSchema.items) {
                if (it.type === SchemaItemType.List) {
                    const lit = it;
                    for (const li of lit.items) {
                        compareitems.set(it.id + "----" + li.item.id, li.item);
                    }
                }
                else {
                    compareitems.set(it.id, it);
                }
            }
            for (const param of cond.params) {
                let leftcon = param.leftcond;
                if (!leftcon || !param.comp)
                    continue;
                const ens = comparemap.get(leftcon);
                if (!ens)
                    continue; // ignore this condition
                const leftitem = compareitems.get(leftcon);
                if (!leftitem)
                    continue;
                let rightitems = undefined;
                if (param.rightschemacon) {
                    rightitems = myitems.get(param.rightschemacon);
                }
                const ritems = new Map();
                const litems = new Map();
                if (rightitems && rightitems.type === SchemaItemType.Choice) {
                    for (const ci of rightitems.items) {
                        ritems.set(ci.id, ci.name);
                    }
                }
                if (leftitem && leftitem.type === SchemaItemType.Choice) {
                    for (const ci of leftitem.items) {
                        litems.set(ci.id, ci.name);
                    }
                }
                // lets check now!
                const comp = param.comp;
                let result = false;
                if (comp === Comparator.Equal || comp === Comparator.NotEqual) {
                    let conval = "";
                    if (param.rightschemacon) {
                        // now lets get all the ens 
                        const mmens = myrowmap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                let comparevalue = men.celldata;
                                let lstr = [];
                                let lids = [];
                                let lrvals = [];
                                // we need to construst this here
                                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                    const parts = comparevalue.split(",");
                                    lids = parts;
                                    for (const p of parts) {
                                        const pv = litems.get(p);
                                        if (pv) {
                                            lstr.push(pv.toLowerCase());
                                        }
                                    }
                                }
                                else {
                                    lstr.push(((_c = stripHTML(comparevalue)) !== null && _c !== void 0 ? _c : '').toLowerCase());
                                    lids = lstr;
                                    if (men.remotevalue) {
                                        lrvals = men.remotevalue;
                                    }
                                }
                                for (const smen of mmens) {
                                    let rcomval = smen.celldata;
                                    let rstr = [];
                                    let rids = [];
                                    let rrvals = [];
                                    if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                        const parts = rcomval.split(",");
                                        rids = parts;
                                        for (const p of parts) {
                                            const pv = ritems.get(p);
                                            if (pv) {
                                                rstr.push(pv.toLowerCase());
                                            }
                                        }
                                    }
                                    else {
                                        rstr.push(((_d = stripHTML(rcomval)) !== null && _d !== void 0 ? _d : '').toLowerCase());
                                        rids = rstr;
                                        if (smen.remotevalue) {
                                            rrvals = smen.remotevalue;
                                        }
                                    }
                                    result = false;
                                    if (lstr.length === rstr.length && rstr.length > 0 && lstr.length > 0) {
                                        let contains = true;
                                        for (const rst of rstr) {
                                            if (lstr.indexOf(rst) === -1) {
                                                contains = false;
                                                break;
                                            }
                                        }
                                        result = contains;
                                    }
                                    if (!result && rids.length === lids.length && rids.length > 0 && lids.length > 0) {
                                        let contains = true;
                                        for (const rst of rids) {
                                            if (lids.indexOf(rst) === -1) {
                                                contains = false;
                                                break;
                                            }
                                        }
                                        result = contains;
                                    }
                                    if (!result && rrvals.length > 0) {
                                        // lets compare with everything again!
                                        if (lstr.length === rrvals.length && rrvals.length > 0 && rrvals.length > 0) {
                                            let contains = true;
                                            for (const rst of rrvals) {
                                                if (lstr.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && rrvals.length === lids.length && rrvals.length > 0 && lids.length > 0) {
                                            let contains = true;
                                            for (const rst of rrvals) {
                                                if (lids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && lrvals.length > 0) {
                                            let contains = true;
                                            for (const rst of rrvals) {
                                                if (lrvals.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                    }
                                    if (!result && lrvals.length > 0) {
                                        if (rstr.length === lrvals.length && lrvals.length > 0 && lrvals.length > 0) {
                                            let contains = true;
                                            for (const rst of lrvals) {
                                                if (rstr.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && lrvals.length === rids.length && lrvals.length > 0 && rids.length > 0) {
                                            let contains = true;
                                            for (const rst of lrvals) {
                                                if (rids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond) {
                        for (const men of ens) {
                            let comparevalue = men.celldata;
                            let lstr = [];
                            // we need to construst this here
                            if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                const parts = comparevalue.split(",");
                                for (const p of parts) {
                                    const pv = litems.get(p);
                                    if (pv) {
                                        lstr.push(pv.toLowerCase());
                                    }
                                }
                            }
                            else {
                                lstr.push((_e = stripHTML(comparevalue)) !== null && _e !== void 0 ? _e : ''.toLowerCase());
                            }
                            // or we can strip all 
                            // we can do clever things here!
                            if (lstr.join(" ").toLowerCase()
                                === param.rightcond.toLowerCase()) {
                                result = true;
                                break;
                            }
                        }
                    }
                    if (comp === Comparator.NotEqual) {
                        // reverse of equal is not equal
                        result = !result;
                    }
                }
                else if (comp === Comparator.Greater) {
                    if (param.rightschemacon) {
                        const mmens = myrowmap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval > pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval > pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.Less) {
                    if (param.rightschemacon) {
                        const mmens = myrowmap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval < pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval < pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.LessAndEqual) {
                    if (param.rightschemacon) {
                        const mmens = myrowmap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval <= pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval <= pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.GreaterAndEqual) {
                    if (param.rightschemacon) {
                        const mmens = myrowmap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval >= pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval >= pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.Contains || comp === Comparator.NotContains) {
                    // now just check conatin stuff and we done!!
                    if (param.rightschemacon) {
                        const mmens = myrowmap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = men.celldata;
                                let lstrs = [];
                                let lids = [];
                                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                    const cparts = cval.split(",");
                                    lids = cparts;
                                    let rvalusstring = "";
                                    for (const cp of cparts) {
                                        const vh = litems.get(cp);
                                        if (vh) {
                                            lstrs.push(vh.toLowerCase());
                                        }
                                    }
                                }
                                else {
                                    lstrs.push((_f = stripHTML(cval)) !== null && _f !== void 0 ? _f : ''.toLowerCase());
                                    lids = lstrs;
                                }
                                for (const smen of mmens) {
                                    const pval = smen.celldata;
                                    let rstrs = [];
                                    let rids = [];
                                    if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                        // okay if they both choice 
                                        const pparts = pval.split(",");
                                        rids = pparts;
                                        // lets construct value stuff now
                                        // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                        let lvalusstring = "";
                                        for (const cp of pparts) {
                                            const vh = ritems.get(cp);
                                            if (vh) {
                                                rstrs.push(vh.toLowerCase());
                                            }
                                        }
                                    }
                                    else {
                                        rstrs.push((_g = stripHTML(pval)) !== null && _g !== void 0 ? _g : ''.toLowerCase());
                                        rids = rstrs;
                                    }
                                    if (lstrs.length > 0 && rstrs.length > 0) {
                                        let contains = true;
                                        if (lstrs.length > rstrs.length) {
                                            for (const rst of rstrs) {
                                                if (lstrs.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        else {
                                            for (const rst of lstrs) {
                                                if (rstrs.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        result = contains;
                                    }
                                    else {
                                        // the contain because they both empty now?
                                        // maybe no
                                        result = false;
                                    }
                                    if (!result && lids.length > 0 && rids.length > 0) {
                                        let contains = true;
                                        if (lids.length > rids.length) {
                                            for (const rst of rids) {
                                                if (lids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        else {
                                            for (const rst of lids) {
                                                if (rids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        result = contains;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = (param.rightcond);
                        for (const men of ens) {
                            const cval = (men.celldata);
                            let lstrs = [];
                            if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                const cparts = cval.split(",");
                                // lets construct value stuff now
                                // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                let rvalusstring = "";
                                for (const cp of cparts) {
                                    const vh = litems.get(cp);
                                    if (vh) {
                                        lstrs.push(vh.toLowerCase());
                                    }
                                }
                            }
                            else {
                                lstrs.push((_h = stripHTML(cval)) !== null && _h !== void 0 ? _h : ''.toLowerCase());
                            }
                            if (lstrs.indexOf(pval.toLowerCase()) !== -1) {
                                result = true;
                                break;
                            }
                        }
                    }
                    if (comp === Comparator.NotContains) {
                        result = !result;
                    }
                }
                if (mystageco === ConditionType.And && !result) {
                    paramresuls.splice(0, paramresuls.length);
                    paramresuls.push(false);
                    break;
                }
                else if (mystageco === ConditionType.Or && result) {
                    paramresuls.splice(0, paramresuls.length);
                    paramresuls.push(true);
                    break;
                }
                paramresuls.push(result);
            }
            // now we have the paramres
            let mystageout = false;
            if (paramresuls.length === 0) {
            }
            else if (paramresuls.length === 1) {
                mystageout = paramresuls[0];
            }
            else {
                mystageout = paramresuls[0];
                for (let i = 1; i < paramresuls.length; i++) {
                    if (mystageco === ConditionType.And) {
                        mystageout = paramresuls[i] && mystageout;
                    }
                    else if (mystageco === ConditionType.Or) {
                        mystageout = paramresuls[i] || mystageout;
                    }
                }
            }
            if (index > 0) {
                if (cond.prevtype === ConditionType.And) {
                    prevstageres = prevstageres && mystageout;
                }
                else {
                    prevstageres = prevstageres || mystageout;
                }
            }
            else {
                prevstageres = mystageout;
            }
        }
    }
    return prevstageres;
};
export const checkRowSatifyCond = (rule, compareRow, evaluatingEntry, myschema, compareSchema) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const comparemap = new Map();
    for (const en of compareRow.entry) {
        if (!comparemap.has(en.cellid)) {
            comparemap.set(en.cellid, []);
        }
        for (const en of compareRow.entry) {
            if (!comparemap.has(en.cellid)) {
                comparemap.set(en.cellid, []);
            }
            // we should check here for sublist and we should keep entry of only s
            if (evaluatingEntry.cellid.indexOf("----") !== -1) {
                // okay we have sub item
                const evparrs = evaluatingEntry.cellid.split("----");
                if (evparrs[0] === en.cellid.split("----")[0]) {
                    if (en.subrowindex === evaluatingEntry.subrowindex) {
                        (_a = comparemap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
                    }
                }
                else {
                    (_b = comparemap.get(en.cellid)) === null || _b === void 0 ? void 0 : _b.push(en);
                }
            }
            else {
                // now what if en is sublist?
                (_c = comparemap.get(en.cellid)) === null || _c === void 0 ? void 0 : _c.push(en);
            }
        }
    }
    let prevstageres = false;
    if (rule.conds && rule.conds.length > 0) {
        let index = -1;
        for (const cond of rule.conds) {
            index++;
            // idea here is if we have or we should break as soon we have one 1
            // if and we should break as soon we have 0
            let mystageco = cond.contype;
            if (index > 0) {
                let pcom = mystageco;
                if (cond.prevtype) {
                    pcom = cond.prevtype;
                }
                if (pcom === ConditionType.And && !prevstageres) {
                    // now it's false from preveious stage !
                    continue;
                }
                else if (pcom === ConditionType.Or && prevstageres) {
                    continue;
                }
            }
            // lets see what this condition will do 
            // we need to do and with all we have !
            let paramresuls = [];
            const myitems = new Map();
            const compareitems = new Map();
            for (const it of myschema.items) {
                if (it.type === SchemaItemType.List) {
                    const lit = it;
                    for (const li of lit.items) {
                        myitems.set(it.id + "----" + li.item.id, li.item);
                    }
                }
                else {
                    myitems.set(it.id, it);
                }
            }
            for (const it of compareSchema.items) {
                if (it.type === SchemaItemType.List) {
                    const lit = it;
                    for (const li of lit.items) {
                        compareitems.set(it.id + "----" + li.item.id, li.item);
                    }
                }
                else {
                    compareitems.set(it.id, it);
                }
            }
            for (const param of cond.params) {
                let leftcon = param.leftcond;
                if (!leftcon || !param.comp)
                    continue;
                const ens = comparemap.get(leftcon);
                if (!ens)
                    continue; // ignore this condition
                const leftitem = myitems.get(leftcon);
                if (!leftitem)
                    continue;
                let rightitems = undefined;
                if (param.rightschemacon) {
                    rightitems = compareitems.get(param.rightschemacon);
                }
                const ritems = new Map();
                const litems = new Map();
                if (rightitems && rightitems.type === SchemaItemType.Choice) {
                    for (const ci of rightitems.items) {
                        ritems.set(ci.id, ci.name);
                    }
                }
                if (leftitem && leftitem.type === SchemaItemType.Choice) {
                    for (const ci of leftitem.items) {
                        litems.set(ci.id, ci.name);
                    }
                }
                // lets check now!
                const comp = param.comp;
                let result = false;
                if (comp === Comparator.Equal || comp === Comparator.NotEqual) {
                    let conval = "";
                    if (param.rightschemacon) {
                        // now lets get all the ens 
                        const mmens = comparemap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                let comparevalue = men.celldata;
                                let lstr = [];
                                let lids = [];
                                let lrvals = [];
                                // we need to construst this here
                                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                    const parts = comparevalue.split(",");
                                    lids = parts;
                                    for (const p of parts) {
                                        const pv = litems.get(p);
                                        if (pv) {
                                            lstr.push(pv.toLowerCase());
                                        }
                                        lstr.push(p);
                                    }
                                }
                                else {
                                    lstr.push((_d = stripHTML(comparevalue)) !== null && _d !== void 0 ? _d : ''.toLowerCase());
                                    lids = lstr;
                                    if (men.remotevalue) {
                                        lrvals = men.remotevalue;
                                    }
                                }
                                for (const smen of mmens) {
                                    let rcomval = smen.celldata;
                                    let rstr = [];
                                    let rids = [];
                                    let rrvals = [];
                                    if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                        const parts = rcomval.split(",");
                                        rids = parts;
                                        for (const p of parts) {
                                            const pv = ritems.get(p);
                                            if (pv) {
                                                rstr.push(pv.toLowerCase());
                                            }
                                        }
                                    }
                                    else {
                                        rstr.push(rcomval.toLowerCase());
                                        rids = rstr;
                                        if (smen.remotevalue) {
                                            rrvals = smen.remotevalue;
                                        }
                                    }
                                    result = false;
                                    if (lstr.length === rstr.length && rstr.length > 0 && lstr.length > 0) {
                                        let contains = true;
                                        for (const rst of rstr) {
                                            if (lstr.indexOf(rst) === -1) {
                                                contains = false;
                                                break;
                                            }
                                        }
                                        result = contains;
                                    }
                                    if (!result && rids.length === lids.length && rids.length > 0 && lids.length > 0) {
                                        let contains = true;
                                        for (const rst of rids) {
                                            if (lids.indexOf(rst) === -1) {
                                                contains = false;
                                                break;
                                            }
                                        }
                                        result = contains;
                                    }
                                    if (!result && rrvals.length > 0) {
                                        // lets compare with everything again!
                                        if (lstr.length === rrvals.length && rrvals.length > 0 && rrvals.length > 0) {
                                            let contains = true;
                                            for (const rst of rrvals) {
                                                if (lstr.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && rrvals.length === lids.length && rrvals.length > 0 && lids.length > 0) {
                                            let contains = true;
                                            for (const rst of rrvals) {
                                                if (lids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && lrvals.length > 0) {
                                            let contains = true;
                                            for (const rst of rrvals) {
                                                if (lrvals.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                    }
                                    if (!result && lrvals.length > 0) {
                                        if (rstr.length === lrvals.length && lrvals.length > 0 && lrvals.length > 0) {
                                            let contains = true;
                                            for (const rst of lrvals) {
                                                if (rstr.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && lrvals.length === rids.length && lrvals.length > 0 && rids.length > 0) {
                                            let contains = true;
                                            for (const rst of lrvals) {
                                                if (rids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond) {
                        for (const men of ens) {
                            let comparevalue = men.celldata;
                            let lstr = [];
                            // we need to construst this here
                            if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                const parts = comparevalue.split(",");
                                for (const p of parts) {
                                    const pv = litems.get(p);
                                    if (pv) {
                                        lstr.push(pv.toLowerCase());
                                    }
                                }
                            }
                            else {
                                lstr.push((_e = stripHTML(comparevalue)) !== null && _e !== void 0 ? _e : ''.toLowerCase());
                            }
                            // or we can strip all 
                            // we can do clever things here!
                            if (lstr.join(" ").toLowerCase()
                                === param.rightcond.toLowerCase()) {
                                result = true;
                                break;
                            }
                        }
                    }
                    if (comp === Comparator.NotEqual) {
                        // reverse of equal is not equal
                        result = !result;
                    }
                }
                else if (comp === Comparator.Greater) {
                    if (param.rightschemacon) {
                        const mmens = comparemap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval > pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval > pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.Less) {
                    if (param.rightschemacon) {
                        const mmens = comparemap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval < pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval < pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.LessAndEqual) {
                    if (param.rightschemacon) {
                        const mmens = comparemap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval <= pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval <= pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.GreaterAndEqual) {
                    if (param.rightschemacon) {
                        const mmens = comparemap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                for (const smen of mmens) {
                                    const pval = parseFloat(smen.celldata);
                                    if (cval >= pval) {
                                        // we have one that is greater now!
                                        // is this a home run 
                                        result = true;
                                        break;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = parseFloat(param.rightcond);
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            if (cval >= pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else if (comp === Comparator.Contains || comp === Comparator.NotContains) {
                    // now just check conatin stuff and we done!!
                    if (param.rightschemacon) {
                        const mmens = comparemap.get(param.rightschemacon);
                        if (mmens) {
                            for (const men of ens) {
                                const cval = men.celldata;
                                let lstrs = [];
                                let lids = [];
                                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                    const cparts = cval.split(",");
                                    lids = cparts;
                                    let rvalusstring = "";
                                    for (const cp of cparts) {
                                        const vh = litems.get(cp);
                                        if (vh) {
                                            lstrs.push(vh.toLowerCase());
                                        }
                                    }
                                }
                                else {
                                    lstrs.push((_f = stripHTML(cval)) !== null && _f !== void 0 ? _f : ''.toLowerCase());
                                    lids = lstrs;
                                }
                                for (const smen of mmens) {
                                    const pval = smen.celldata;
                                    let rstrs = [];
                                    let rids = [];
                                    if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                        // okay if they both choice 
                                        const pparts = pval.split(",");
                                        rids = pparts;
                                        // lets construct value stuff now
                                        // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                        let lvalusstring = "";
                                        for (const cp of pparts) {
                                            const vh = ritems.get(cp);
                                            if (vh) {
                                                rstrs.push(vh.toLowerCase());
                                            }
                                        }
                                    }
                                    else {
                                        rstrs.push((_g = stripHTML(pval)) !== null && _g !== void 0 ? _g : ''.toLowerCase());
                                        rids = rstrs;
                                    }
                                    if (lstrs.length > 0 && rstrs.length > 0) {
                                        let contains = true;
                                        if (lstrs.length > rstrs.length) {
                                            for (const rst of rstrs) {
                                                if (lstrs.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        else {
                                            for (const rst of lstrs) {
                                                if (rstrs.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        result = contains;
                                    }
                                    else {
                                        // the contain because they both empty now?
                                        // maybe no
                                        result = false;
                                    }
                                    if (!result && lids.length > 0 && rids.length > 0) {
                                        let contains = true;
                                        if (lids.length > rids.length) {
                                            for (const rst of rids) {
                                                if (lids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        else {
                                            for (const rst of lids) {
                                                if (rids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                        }
                                        result = contains;
                                    }
                                }
                                if (result)
                                    break;
                            }
                        }
                    }
                    else if (param.rightcond !== undefined) {
                        const pval = (param.rightcond);
                        for (const men of ens) {
                            const cval = (men.celldata);
                            let lstrs = [];
                            if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                const cparts = cval.split(",");
                                // lets construct value stuff now
                                // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                let rvalusstring = "";
                                for (const cp of cparts) {
                                    const vh = litems.get(cp);
                                    if (vh) {
                                        lstrs.push(vh.toLowerCase());
                                    }
                                    lstrs.push(cp);
                                }
                            }
                            else {
                                lstrs.push((_h = stripHTML(cval)) !== null && _h !== void 0 ? _h : ''.toLowerCase());
                            }
                            if (lstrs.indexOf(pval.toLowerCase()) !== -1) {
                                result = true;
                                break;
                            }
                        }
                    }
                    if (comp === Comparator.NotContains) {
                        result = !result;
                    }
                }
                if (mystageco === ConditionType.And && !result) {
                    paramresuls.splice(0, paramresuls.length);
                    paramresuls.push(false);
                    break;
                }
                else if (mystageco === ConditionType.Or && result) {
                    paramresuls.splice(0, paramresuls.length);
                    paramresuls.push(true);
                    break;
                }
                paramresuls.push(result);
            }
            // now we have the paramres
            let mystageout = false;
            if (paramresuls.length === 0) {
            }
            else if (paramresuls.length === 1) {
                mystageout = paramresuls[0];
            }
            else {
                mystageout = paramresuls[0];
                for (let i = 1; i < paramresuls.length; i++) {
                    if (mystageco === ConditionType.And) {
                        mystageout = paramresuls[i] && mystageout;
                    }
                    else if (mystageco === ConditionType.Or) {
                        mystageout = paramresuls[i] || mystageout;
                    }
                }
            }
            if (index > 0) {
                if (cond.prevtype === ConditionType.And) {
                    prevstageres = prevstageres && mystageout;
                }
                else {
                    prevstageres = prevstageres || mystageout;
                }
            }
            else {
                prevstageres = mystageout;
            }
        }
    }
    return prevstageres;
};
export const parseConditionalParam = (param, sh, compareRow, evaluatingEntry, myschema, compareSchema, ritem) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const comparemap = new Map();
    for (const en of compareRow.entry) {
        if (en.cellid.indexOf("----") === -1 && comparemap.has(en.cellid)) {
            continue;
        }
        if (!comparemap.has(en.cellid)) {
            comparemap.set(en.cellid, []);
        }
        // we should check here for sublist and we should keep entry of only s
        if (evaluatingEntry.cellid.indexOf("----") !== -1) {
            // okay we have sub item
            const evparrs = evaluatingEntry.cellid.split("----");
            if (evparrs[0] === en.cellid.split("----")[0]) {
                if (en.subrowindex === evaluatingEntry.subrowindex) {
                    (_a = comparemap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
                }
            }
            else {
                (_b = comparemap.get(en.cellid)) === null || _b === void 0 ? void 0 : _b.push(en);
            }
        }
        else {
            // now what if en is sublist?
            (_c = comparemap.get(en.cellid)) === null || _c === void 0 ? void 0 : _c.push(en);
        }
    }
    const bits = param.value.split("~~~");
    if (param.conds && param.conds.length > 0) {
        let atchingcolumns = [];
        // okay idea here is simple now!!
        const islist = bits[2].indexOf("----") != -1;
        const parent = bits[2].split("----")[0];
        let hascommonconds = false;
        let subrowcons = [];
        for (const r of sh) {
            const rmap = new Map();
            for (const en of r.entry) {
                if (en.cellid.indexOf("----") === -1 && rmap.has(en.cellid)) {
                    continue;
                }
                if (!rmap.has(en.cellid)) {
                    rmap.set(en.cellid, []);
                }
                (_d = rmap.get(en.cellid)) === null || _d === void 0 ? void 0 : _d.push(en);
            }
            let index = -1;
            let prevstageres = false;
            for (const cond of param.conds) {
                index++;
                // idea here is if we have or we should break as soon we have one 1
                // if and we should break as soon we have 0
                let mystageco = cond.contype;
                if (index > 0) {
                    let pcom = mystageco;
                    if (cond.prevtype) {
                        pcom = cond.prevtype;
                    }
                    if (pcom === ConditionType.And && !prevstageres) {
                        // now it's false from preveious stage !
                        continue;
                    }
                    else if (pcom === ConditionType.Or && prevstageres) {
                        continue;
                    }
                }
                // lets see what this condition will do 
                // we need to do and with all we have !
                let paramresuls = [];
                const myitems = new Map();
                const compareitems = new Map();
                for (const it of myschema.items) {
                    if (it.type === SchemaItemType.List) {
                        const lit = it;
                        for (const li of lit.items) {
                            myitems.set(it.id + "----" + li.item.id, li.item);
                        }
                    }
                    else {
                        myitems.set(it.id, it);
                    }
                }
                for (const it of compareSchema.items) {
                    if (it.type === SchemaItemType.List) {
                        const lit = it;
                        for (const li of lit.items) {
                            compareitems.set(it.id + "----" + li.item.id, li.item);
                        }
                    }
                    else {
                        compareitems.set(it.id, it);
                    }
                }
                const validsubrows = new Set();
                for (const cparam of cond.params) {
                    let leftcon = cparam.leftcond;
                    if (!leftcon || !cparam.comp)
                        continue;
                    const ens = rmap.get(leftcon);
                    //TODO: this is when we comparing list 
                    // item and we just need only sybrow that is a match
                    if (!ens) {
                        continue;
                    } // ignore this condition
                    // lets check now!
                    const comp = cparam.comp;
                    let result = false;
                    const leftitem = myitems.get(leftcon);
                    if (!leftitem)
                        continue;
                    let rightitems = undefined;
                    if (cparam.rightschemacon) {
                        rightitems = compareitems.get(cparam.rightschemacon);
                    }
                    const ritems = new Map();
                    const litems = new Map();
                    if (rightitems && rightitems.type === SchemaItemType.Choice) {
                        for (const ci of rightitems.items) {
                            ritems.set(ci.id, ci.name);
                        }
                    }
                    if (leftitem && leftitem.type === SchemaItemType.Choice) {
                        for (const ci of leftitem.items) {
                            litems.set(ci.id, ci.name);
                        }
                    }
                    let collectmatchingrows = false;
                    if (leftcon.indexOf("----") != -1) {
                        const lb = leftcon.split("----")[0];
                        if (lb === parent) {
                            collectmatchingrows = true;
                            hascommonconds = true;
                            subrowcons.push(cond);
                        }
                    }
                    // how shall we collect this now!!
                    if (comp === Comparator.Equal || comp === Comparator.NotEqual) {
                        let conval = "";
                        if (cparam.rightschemacon) {
                            // now lets get all the ens 
                            const mmens = comparemap.get(cparam.rightschemacon);
                            if (mmens) {
                                for (const men of ens) {
                                    let comparevalue = men.celldata;
                                    let lstr = [];
                                    let lids = [];
                                    let lrvals = [];
                                    // we need to construst this here
                                    if (leftitem.type === SchemaItemType.Remote) {
                                        // we should filter this as well
                                        if (!men.remotevalue) {
                                            attachRemoteValue(men, leftitem, ritem);
                                        }
                                    }
                                    if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                        const parts = comparevalue.split(",");
                                        lids = parts;
                                        for (const p of parts) {
                                            const pv = litems.get(p);
                                            if (pv) {
                                                lstr.push(pv.toLowerCase());
                                            }
                                        }
                                    }
                                    else {
                                        lstr.push(((_e = stripHTML(comparevalue)) !== null && _e !== void 0 ? _e : '').toLowerCase());
                                        lids = lstr;
                                        if (men.remotevalue) {
                                            lrvals = men.remotevalue.map((l) => { var _a; return ((_a = stripHTML(l)) !== null && _a !== void 0 ? _a : '').toLowerCase(); });
                                        }
                                    }
                                    for (const smen of mmens) {
                                        let rcomval = smen.celldata;
                                        let rstr = [];
                                        let rids = [];
                                        let rrvals = [];
                                        if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                            const parts = rcomval.split(",");
                                            rids = parts;
                                            for (const p of parts) {
                                                const pv = ritems.get(p);
                                                if (pv) {
                                                    rstr.push(pv.toLowerCase());
                                                }
                                            }
                                        }
                                        else {
                                            rstr.push(((_f = stripHTML(rcomval)) !== null && _f !== void 0 ? _f : '').toLowerCase());
                                            rids = rstr;
                                            if (smen.remotevalue) {
                                                rrvals = smen.remotevalue.map((r) => { var _a; return ((_a = stripHTML(r)) !== null && _a !== void 0 ? _a : '').toLowerCase(); });
                                            }
                                        }
                                        result = false;
                                        if (lstr.length === rstr.length && rstr.length > 0 && lstr.length > 0) {
                                            let contains = true;
                                            for (const rst of rstr) {
                                                if (lstr.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && rids.length === lids.length && rids.length > 0 && lids.length > 0) {
                                            let contains = true;
                                            for (const rst of rids) {
                                                if (lids.indexOf(rst) === -1) {
                                                    contains = false;
                                                    break;
                                                }
                                            }
                                            result = contains;
                                        }
                                        if (!result && rrvals.length > 0) {
                                            // lets compare with everything again!
                                            if (lstr.length === rrvals.length && rrvals.length > 0 && lstr.length > 0) {
                                                let contains = true;
                                                for (const rst of rrvals) {
                                                    if (lstr.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                                result = contains;
                                            }
                                            if (!result && rrvals.length === lids.length && rrvals.length > 0 && lids.length > 0) {
                                                let contains = true;
                                                for (const rst of rrvals) {
                                                    if (lids.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                                result = contains;
                                            }
                                            if (!result && lrvals.length > 0) {
                                                let contains = true;
                                                for (const rst of rrvals) {
                                                    if (lrvals.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                                result = contains;
                                            }
                                        }
                                        if (!result && lrvals.length > 0) {
                                            if (rstr.length === lrvals.length && lrvals.length > 0 && lrvals.length > 0) {
                                                let contains = true;
                                                for (const rst of lrvals) {
                                                    if (rstr.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                                result = contains;
                                            }
                                            if (!result && lrvals.length === rids.length && lrvals.length > 0 && rids.length > 0) {
                                                let contains = true;
                                                for (const rst of lrvals) {
                                                    if (rids.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                                result = contains;
                                            }
                                        }
                                    }
                                    if (result)
                                        break;
                                }
                            }
                        }
                        else if (cparam.rightcond) {
                            for (const men of ens) {
                                let comparevalue = men.celldata;
                                let lstr = [];
                                let lrids = [];
                                if (leftitem.type === SchemaItemType.Remote) {
                                    // we should filter this as well
                                    if (!men.remotevalue) {
                                        attachRemoteValue(men, leftitem, ritem);
                                    }
                                }
                                // we need to construst this here
                                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                    const parts = comparevalue.split(",");
                                    for (const p of parts) {
                                        const pv = litems.get(p);
                                        if (pv) {
                                            lstr.push(pv.toLowerCase());
                                        }
                                    }
                                }
                                else {
                                    lstr.push((_g = stripHTML(comparevalue)) !== null && _g !== void 0 ? _g : ''.toLowerCase());
                                }
                                if (comparevalue === cparam.rightcond) {
                                    result = true;
                                    break;
                                }
                                // or we can strip all 
                                // we can do clever things here!
                                if ((!result && lstr.join(" ").toLowerCase())
                                    === cparam.rightcond.toLowerCase()) {
                                    result = true;
                                    break;
                                }
                                if (!result && men.remotevalue && men.remotevalue.join(" ").toLowerCase() === cparam.rightcond.toLowerCase()) {
                                    result = true;
                                    break;
                                }
                            }
                        }
                        if (comp === Comparator.NotEqual) {
                            // reverse of equal is not equal
                            result = !result;
                        }
                    }
                    else if (comp === Comparator.Greater) {
                        if (cparam.rightschemacon) {
                            const mmens = comparemap.get(cparam.rightschemacon);
                            if (mmens) {
                                for (const men of ens) {
                                    const cval = parseFloat(men.celldata);
                                    for (const smen of mmens) {
                                        const pval = parseFloat(smen.celldata);
                                        if (cval > pval) {
                                            // we have one that is greater now!
                                            // is this a home run 
                                            result = true;
                                            break;
                                        }
                                    }
                                    if (result)
                                        break;
                                }
                            }
                        }
                        else if (cparam.rightcond !== undefined) {
                            const pval = parseFloat(cparam.rightcond);
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                if (cval > pval) {
                                    // we have one that is greater now!
                                    // is this a home run 
                                    result = true;
                                    break;
                                }
                            }
                        }
                    }
                    else if (comp === Comparator.Less) {
                        if (cparam.rightschemacon) {
                            const mmens = comparemap.get(cparam.rightschemacon);
                            if (mmens) {
                                for (const men of ens) {
                                    const cval = parseFloat(men.celldata);
                                    for (const smen of mmens) {
                                        const pval = parseFloat(smen.celldata);
                                        if (cval < pval) {
                                            // we have one that is greater now!
                                            // is this a home run 
                                            result = true;
                                            break;
                                        }
                                    }
                                    if (result)
                                        break;
                                }
                            }
                        }
                        else if (cparam.rightcond !== undefined) {
                            const pval = parseFloat(cparam.rightcond);
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                if (cval < pval) {
                                    // we have one that is greater now!
                                    // is this a home run 
                                    result = true;
                                    break;
                                }
                            }
                        }
                    }
                    else if (comp === Comparator.LessAndEqual) {
                        if (cparam.rightschemacon) {
                            const mmens = comparemap.get(cparam.rightschemacon);
                            if (mmens) {
                                for (const men of ens) {
                                    const cval = parseFloat(men.celldata);
                                    for (const smen of mmens) {
                                        const pval = parseFloat(smen.celldata);
                                        if (cval <= pval) {
                                            // we have one that is greater now!
                                            // is this a home run 
                                            result = true;
                                            break;
                                        }
                                    }
                                    if (result)
                                        break;
                                }
                            }
                        }
                        else if (cparam.rightcond !== undefined) {
                            const pval = parseFloat(cparam.rightcond);
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                if (cval <= pval) {
                                    // we have one that is greater now!
                                    // is this a home run 
                                    result = true;
                                    break;
                                }
                            }
                        }
                    }
                    else if (comp === Comparator.GreaterAndEqual) {
                        if (cparam.rightschemacon) {
                            const mmens = comparemap.get(cparam.rightschemacon);
                            if (mmens) {
                                for (const men of ens) {
                                    const cval = parseFloat(men.celldata);
                                    for (const smen of mmens) {
                                        const pval = parseFloat(smen.celldata);
                                        if (cval >= pval) {
                                            // we have one that is greater now!
                                            // is this a home run 
                                            result = true;
                                            break;
                                        }
                                    }
                                    if (result)
                                        break;
                                }
                            }
                        }
                        else if (cparam.rightcond !== undefined) {
                            const pval = parseFloat(cparam.rightcond);
                            for (const men of ens) {
                                const cval = parseFloat(men.celldata);
                                if (cval >= pval) {
                                    // we have one that is greater now!
                                    // is this a home run 
                                    result = true;
                                    break;
                                }
                            }
                        }
                    }
                    else if (comp === Comparator.Contains || comp === Comparator.NotContains) {
                        // now just check conatin stuff and we done!!
                        if (cparam.rightschemacon) {
                            const mmens = comparemap.get(cparam.rightschemacon);
                            if (mmens) {
                                for (const men of ens) {
                                    const cval = men.celldata;
                                    let lstrs = [];
                                    let lids = [];
                                    if (leftitem.type === SchemaItemType.Remote) {
                                        // we should filter this as well
                                        if (!men.remotevalue) {
                                            attachRemoteValue(men, leftitem, ritem);
                                        }
                                    }
                                    if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                        const cparts = cval.split(",");
                                        lids = cparts;
                                        let rvalusstring = "";
                                        for (const cp of cparts) {
                                            const vh = litems.get(cp);
                                            if (vh) {
                                                lstrs.push(vh.toLowerCase());
                                            }
                                        }
                                    }
                                    else {
                                        lstrs.push((_h = stripHTML(cval)) !== null && _h !== void 0 ? _h : ''.toLowerCase());
                                        lids = lstrs;
                                    }
                                    for (const smen of mmens) {
                                        const pval = smen.celldata;
                                        let rstrs = [];
                                        let rids = [];
                                        if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                            // okay if they both choice 
                                            const pparts = pval.split(",");
                                            rids = pparts;
                                            // lets construct value stuff now
                                            // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                            let lvalusstring = "";
                                            for (const cp of pparts) {
                                                const vh = ritems.get(cp);
                                                if (vh) {
                                                    rstrs.push(vh.toLowerCase());
                                                }
                                            }
                                        }
                                        else {
                                            rstrs.push((_j = stripHTML(pval)) !== null && _j !== void 0 ? _j : ''.toLowerCase());
                                            rids = rstrs;
                                        }
                                        if (lstrs.length > 0 && rstrs.length > 0) {
                                            let contains = true;
                                            if (lstrs.length > rstrs.length) {
                                                for (const rst of rstrs) {
                                                    if (lstrs.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                            }
                                            else {
                                                for (const rst of lstrs) {
                                                    if (rstrs.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                            }
                                            result = contains;
                                        }
                                        else {
                                            // the contain because they both empty now?
                                            // maybe no
                                            result = false;
                                        }
                                        if (!result && lids.length > 0 && rids.length > 0) {
                                            let contains = true;
                                            if (lids.length > rids.length) {
                                                for (const rst of rids) {
                                                    if (lids.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                            }
                                            else {
                                                for (const rst of lids) {
                                                    if (rids.indexOf(rst) === -1) {
                                                        contains = false;
                                                        break;
                                                    }
                                                }
                                            }
                                            result = contains;
                                        }
                                    }
                                    if (result)
                                        break;
                                }
                            }
                        }
                        else if (cparam.rightcond !== undefined) {
                            const pval = (cparam.rightcond);
                            for (const men of ens) {
                                const cval = (men.celldata);
                                let lstrs = [];
                                if (leftitem.type === SchemaItemType.Remote) {
                                    // we should filter this as well
                                    if (!men.remotevalue) {
                                        attachRemoteValue(men, leftitem, ritem);
                                    }
                                }
                                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                    const cparts = cval.split(",");
                                    // lets construct value stuff now
                                    // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                    let rvalusstring = "";
                                    for (const cp of cparts) {
                                        const vh = litems.get(cp);
                                        if (vh) {
                                            lstrs.push(vh.toLowerCase());
                                        }
                                    }
                                }
                                else {
                                    lstrs.push((_k = stripHTML(cval)) !== null && _k !== void 0 ? _k : ''.toLowerCase());
                                }
                                if (cval.indexOf(pval) !== -1) {
                                    result = true;
                                    break;
                                }
                                if (lstrs.indexOf(pval.toLowerCase()) !== -1) {
                                    result = true;
                                    break;
                                }
                                if (!result && men.remotevalue && men.remotevalue.indexOf(pval.toLowerCase()) !== -1) {
                                    result = true;
                                    break;
                                }
                            }
                        }
                        if (comp === Comparator.NotContains) {
                            result = !result;
                        }
                    }
                    if (mystageco === ConditionType.And && !result) {
                        paramresuls.splice(0, paramresuls.length);
                        paramresuls.push(false);
                        break;
                    }
                    else if (mystageco === ConditionType.Or && result) {
                        paramresuls.splice(0, paramresuls.length);
                        paramresuls.push(true);
                        break;
                    }
                    paramresuls.push(result);
                }
                // now we have the paramres
                let mystageout = false;
                if (paramresuls.length === 0) {
                }
                else if (paramresuls.length === 1) {
                    mystageout = paramresuls[0];
                }
                else {
                    mystageout = paramresuls[0];
                    for (let i = 1; i < paramresuls.length; i++) {
                        if (mystageco === ConditionType.And) {
                            mystageout = paramresuls[i] && mystageout;
                        }
                        else if (mystageco === ConditionType.Or) {
                            mystageout = paramresuls[i] || mystageout;
                        }
                    }
                }
                if (index > 0) {
                    if (cond.prevtype === ConditionType.And) {
                        prevstageres = prevstageres && mystageout;
                    }
                    else {
                        prevstageres = prevstageres || mystageout;
                    }
                }
                else {
                    prevstageres = mystageout;
                }
            }
            if (prevstageres) {
                const en = rmap.get(bits[2]);
                // lets get the result of this en
                if (en) {
                    if (bits[2].indexOf("----") !== -1) {
                        let result = 0;
                        if (subrowcons.length > 0) {
                            // we need to filter the ones we can jsutify!!
                            const validrows = filterSubRowEntries(en, r, subrowcons, compareRow, bits[2].split("----")[0], evaluatingEntry, myschema, compareSchema, ritem);
                            // we just need to use this entrues only!!
                            for (const en1 of en) {
                                if (en1.subrowindex && validrows.has(en1.subrowindex)) {
                                    result += parseFloat(en1.celldata);
                                }
                            }
                        }
                        else {
                            for (const en1 of en) {
                                result += parseFloat(en1.celldata);
                            }
                        }
                        // do we gave any left condition that we need to filter this 
                        // there might be some left that will only applicatio
                        atchingcolumns.push(result + "");
                    }
                    else {
                        atchingcolumns.push(en[0].celldata);
                    }
                }
            }
        }
        let result = 0;
        if (atchingcolumns.length > 0) {
            let action = RuleType.Add;
            if (param.matchingrow) {
                action = param.matchingrow;
            }
            let sr = action === RuleType.Multiply || action === RuleType.Divide ? 1 : 0;
            let havevalid = false;
            for (const sc of atchingcolumns) {
                const sfc = parseFloat(sc);
                if (isNaN(sfc))
                    continue;
                if (action === RuleType.Add) {
                    havevalid = true;
                    sr += sfc;
                }
                else if (action === RuleType.Subtract) {
                    sr -= sfc;
                    havevalid = true;
                }
                else if (action === RuleType.Multiply) {
                    sr *= sfc;
                    havevalid = true;
                }
                else if (action === RuleType.Divide) {
                    sr /= sfc;
                    havevalid = true;
                }
            }
            if (!havevalid)
                return 'invalid';
            result = sr;
        }
        return result;
    }
    return 0;
};
const filterSubRowEntries = (en, row, cond, comparerow, parent, evaluatingEntry, myschema, compareSchema, lritems) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const enmyrow = new Map();
    let evaparent = undefined;
    if (evaluatingEntry.cellid.indexOf("----") !== -1) {
        const eb = evaluatingEntry.cellid.split("----");
        evaparent = eb[0];
    }
    const evasubrowindex = (_a = evaluatingEntry.subrowindex) !== null && _a !== void 0 ? _a : '';
    const ret = new Set();
    const myrowmap = new Map();
    for (const e of row.entry) {
        if (e.cellid.indexOf("----") === -1 || !e.subrowindex)
            continue;
        const eparent = e.cellid.split("----")[0];
        if (eparent != parent)
            continue;
        if (!myrowmap.has(e.subrowindex)) {
            myrowmap.set(e.subrowindex, []);
        }
        (_b = myrowmap.get(e.subrowindex)) === null || _b === void 0 ? void 0 : _b.push(e);
    }
    for (const e of en) {
        if (e.subrowindex) {
            if (!enmyrow.has(e.subrowindex)) {
                enmyrow.set(e.subrowindex, []);
            }
            (_c = enmyrow.get(e.subrowindex)) === null || _c === void 0 ? void 0 : _c.push(e);
        }
    }
    const comaprowen = new Map();
    for (const e of comparerow.entry) {
        if (!comaprowen.has(e.cellid)) {
            comaprowen.set(e.cellid, []);
        }
        let epar = undefined;
        if (e.cellid.indexOf("----") !== -1) {
            epar = e.cellid.split("----")[0];
        }
        // okay if we have subrow on right side only include one that is on the same subrow!!
        if (epar && evaparent && epar === evaparent) {
            if (e.subrowindex === evasubrowindex) {
                (_d = comaprowen.get(e.cellid)) === null || _d === void 0 ? void 0 : _d.push(e);
            }
        }
        else {
            (_e = comaprowen.get(e.cellid)) === null || _e === void 0 ? void 0 : _e.push(e);
        }
    }
    const myitems = new Map();
    const compareitems = new Map();
    for (const it of myschema.items) {
        if (it.type === SchemaItemType.List) {
            const lit = it;
            for (const li of lit.items) {
                myitems.set(it.id + "----" + li.item.id, li.item);
            }
        }
        else {
            myitems.set(it.id, it);
        }
    }
    for (const it of compareSchema.items) {
        if (it.type === SchemaItemType.List) {
            const lit = it;
            for (const li of lit.items) {
                compareitems.set(it.id + "----" + li.item.id, li.item);
            }
        }
        else {
            compareitems.set(it.id, it);
        }
    }
    // now we just need to check which row atify our conditions!!
    for (const rin of Array.from(enmyrow.keys())) {
        const ens = enmyrow.get(rin);
        const cems = myrowmap.get(rin);
        if (!cems || cems.length <= 1)
            continue;
        // we have subrow index now!!
        const cemsmap = new Map();
        for (const ce of cems) {
            cemsmap.set(ce.cellid, ce);
        }
        let ismatch = false;
        let eachstageresults = [];
        let conindex = 0;
        for (const con of cond) {
            let mystageresult = false;
            let paramresults = [];
            for (const p of con.params) {
                let result = false;
                const leftc = p.leftcond;
                const leftitem = myitems.get(leftc);
                if (!leftitem)
                    continue;
                let rightitems = undefined;
                if (p.rightschemacon) {
                    rightitems = compareitems.get(p.rightschemacon);
                }
                const ritems = new Map();
                const litems = new Map();
                if (rightitems && rightitems.type === SchemaItemType.Choice) {
                    for (const ci of rightitems.items) {
                        ritems.set(ci.id, ci.name);
                    }
                }
                if (leftitem && leftitem.type === SchemaItemType.Choice) {
                    for (const ci of leftitem.items) {
                        litems.set(ci.id, ci.name);
                    }
                }
                const lefte = cemsmap.get(leftc);
                if (!lefte)
                    continue;
                if (leftitem.type === SchemaItemType.Remote) {
                    // we should filter this as well
                    if (!lefte.remotevalue) {
                        attachRemoteValue(lefte, leftitem, lritems);
                    }
                }
                // okay we have lefte 
                let lstr = [];
                let lids = [];
                let lrvals = [];
                // we need to construst this here
                if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                    const parts = lefte.celldata.split(",");
                    lids = parts;
                    for (const p of parts) {
                        const pv = litems.get(p);
                        if (pv) {
                            lstr.push(pv.toLowerCase());
                        }
                    }
                }
                else {
                    lstr.push(((_f = stripHTML(lefte.celldata)) !== null && _f !== void 0 ? _f : '').toLowerCase());
                    lids = lstr;
                    if (lefte.remotevalue) {
                        lrvals = lefte.remotevalue.map((l) => { var _a; return ((_a = stripHTML(l)) !== null && _a !== void 0 ? _a : '').toLowerCase(); });
                    }
                }
                if (p.rightschemacon) {
                    const res = comaprowen.get(p.rightschemacon);
                    for (const re of res !== null && res !== void 0 ? res : []) {
                        if (p.comp == Comparator.Equal || p.comp == Comparator.NotEqual) {
                            let rcomval = re.celldata;
                            let rstr = [];
                            let rids = [];
                            let rrvals = [];
                            if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                const parts = rcomval.split(",");
                                rids = parts;
                                for (const p of parts) {
                                    const pv = ritems.get(p);
                                    if (pv) {
                                        rstr.push(pv.toLowerCase());
                                    }
                                }
                            }
                            else {
                                rstr.push(((_g = stripHTML(rcomval)) !== null && _g !== void 0 ? _g : '').toLowerCase());
                                rids = rstr;
                                if (re.remotevalue) {
                                    rrvals = re.remotevalue.map((r) => { var _a; return ((_a = stripHTML(r)) !== null && _a !== void 0 ? _a : '').toLowerCase(); });
                                }
                            }
                            if (lstr.length === rstr.length && rstr.length > 0 && lstr.length > 0) {
                                let contains = true;
                                for (const rst of rstr) {
                                    if (lstr.indexOf(rst) === -1) {
                                        contains = false;
                                        break;
                                    }
                                }
                                result = contains;
                            }
                            if (!result && rids.length === lids.length && rids.length > 0 && lids.length > 0) {
                                let contains = true;
                                for (const rst of rids) {
                                    if (lids.indexOf(rst) === -1) {
                                        contains = false;
                                        break;
                                    }
                                }
                                result = contains;
                            }
                            if (!result && rrvals.length > 0) {
                                // lets compare with everything again!
                                if (lstr.length === rrvals.length && rrvals.length > 0 && lstr.length > 0) {
                                    let contains = true;
                                    for (const rst of rrvals) {
                                        if (lstr.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                    result = contains;
                                }
                                if (!result && rrvals.length === lids.length && rrvals.length > 0 && lids.length > 0) {
                                    let contains = true;
                                    for (const rst of rrvals) {
                                        if (lids.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                    result = contains;
                                }
                                if (!result && lrvals.length > 0) {
                                    let contains = true;
                                    for (const rst of rrvals) {
                                        if (lrvals.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                    result = contains;
                                }
                            }
                            if (!result && lrvals.length > 0) {
                                if (rstr.length === lrvals.length && lrvals.length > 0 && lrvals.length > 0) {
                                    let contains = true;
                                    for (const rst of lrvals) {
                                        if (rstr.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                    result = contains;
                                }
                                if (!result && lrvals.length === rids.length && lrvals.length > 0 && rids.length > 0) {
                                    let contains = true;
                                    for (const rst of lrvals) {
                                        if (rids.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                    result = contains;
                                }
                            }
                            if (p.comp === Comparator.NotEqual) {
                                result != result;
                            }
                            if (result)
                                break;
                        }
                        else if (p.comp == Comparator.Greater || p.comp == Comparator.LessAndEqual) {
                            const cval = parseFloat(lefte.celldata);
                            const pval = parseFloat(re.celldata);
                            if (cval > pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                            }
                            if (p.comp === Comparator.LessAndEqual) {
                                result != result;
                            }
                            if (result)
                                break;
                        }
                        else if (p.comp == Comparator.Less || p.comp == Comparator.GreaterAndEqual) {
                            const cval = parseFloat(lefte.celldata);
                            const pval = parseFloat(re.celldata);
                            if (cval < pval) {
                                // we have one that is greater now!
                                // is this a home run 
                                result = true;
                            }
                            if (p.comp === Comparator.GreaterAndEqual) {
                                result != result;
                            }
                            if (result)
                                break;
                        }
                        else if (p.comp == Comparator.Contains || p.comp == Comparator.NotContains) {
                            const cval = lefte.celldata;
                            let lstrs = [];
                            let lids = [];
                            if ((leftitem === null || leftitem === void 0 ? void 0 : leftitem.type) === SchemaItemType.Choice) {
                                const cparts = cval.split(",");
                                lids = cparts;
                                let rvalusstring = "";
                                for (const cp of cparts) {
                                    const vh = litems.get(cp);
                                    if (vh) {
                                        lstrs.push(vh.toLowerCase());
                                    }
                                }
                            }
                            else {
                                lstrs.push((_h = stripHTML(cval)) !== null && _h !== void 0 ? _h : ''.toLowerCase());
                                lids = lstrs;
                            }
                            const pval = re.celldata;
                            let rstrs = [];
                            let rids = [];
                            if ((rightitems === null || rightitems === void 0 ? void 0 : rightitems.type) === SchemaItemType.Choice) {
                                // okay if they both choice 
                                const pparts = pval.split(",");
                                rids = pparts;
                                // lets construct value stuff now
                                // maybe we should sepearte items using ____[[[[]]]]____ rarely anyone will use such string?
                                let lvalusstring = "";
                                for (const cp of pparts) {
                                    const vh = ritems.get(cp);
                                    if (vh) {
                                        rstrs.push(vh.toLowerCase());
                                    }
                                }
                            }
                            else {
                                rstrs.push((_j = stripHTML(pval)) !== null && _j !== void 0 ? _j : ''.toLowerCase());
                                rids = rstrs;
                            }
                            if (lstrs.length > 0 && rstrs.length > 0) {
                                let contains = true;
                                if (lstrs.length > rstrs.length) {
                                    for (const rst of rstrs) {
                                        if (lstrs.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                }
                                else {
                                    for (const rst of lstrs) {
                                        if (rstrs.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                }
                                result = contains;
                            }
                            else {
                                // the contain because they both empty now?
                                // maybe no
                                result = false;
                            }
                            if (!result && lids.length > 0 && rids.length > 0) {
                                let contains = true;
                                if (lids.length > rids.length) {
                                    for (const rst of rids) {
                                        if (lids.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                }
                                else {
                                    for (const rst of lids) {
                                        if (rids.indexOf(rst) === -1) {
                                            contains = false;
                                            break;
                                        }
                                    }
                                }
                                result = contains;
                            }
                            if (p.comp === Comparator.NotContains) {
                                result != result;
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else {
                    const rcon = p.rightcond;
                    if (p.comp === Comparator.Equal) {
                        if (lstr.join(" ").toLowerCase()
                            === p.rightcond.toLowerCase()) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.NotEqual) {
                        if (lstr.join(" ").toLowerCase()
                            !== p.rightcond.toLowerCase()) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.Contains) {
                        if (lstr.indexOf(p.rightcond.toLowerCase()) !== -1) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.NotContains) {
                        if (lstr.indexOf(p.rightcond.toLowerCase()) === -1) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.Greater) {
                        const cval = parseFloat(lefte.celldata);
                        const pval = parseFloat(p.rightcond);
                        if (cval > pval) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.Less) {
                        const cval = parseFloat(lefte.celldata);
                        const pval = parseFloat(p.rightcond);
                        if (cval < pval) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.LessAndEqual) {
                        const cval = parseFloat(lefte.celldata);
                        const pval = parseFloat(p.rightcond);
                        if (cval <= pval) {
                            result = true;
                        }
                    }
                    else if (p.comp === Comparator.GreaterAndEqual) {
                        const cval = parseFloat(lefte.celldata);
                        const pval = parseFloat(p.rightcond);
                        if (cval >= pval) {
                            result = true;
                        }
                    }
                }
                if (con.contype === ConditionType.And && !result) {
                    paramresults.splice(0, paramresults.length);
                    paramresults.push(false);
                    break;
                }
                else if (con.contype === ConditionType.Or && result) {
                    paramresults.splice(0, paramresults.length);
                    paramresults.push(true);
                    break;
                }
                else {
                    paramresults.push(result);
                }
                // now we have the reslt lets see what we need here!!
            }
            if (paramresults.length === 0)
                continue;
            if (paramresults.length === 1) {
                mystageresult = paramresults[0];
            }
            else {
                for (let i = 0; i < paramresults.length; i++) {
                    if (i === 0) {
                        mystageresult = paramresults[i];
                    }
                    else {
                        if (con.contype === ConditionType.And) {
                            mystageresult && (mystageresult = paramresults[i]);
                        }
                        else if (con.contype === ConditionType.Or) {
                            mystageresult || (mystageresult = paramresults[i]);
                        }
                    }
                }
            }
            if (conindex === 0) {
                ismatch = mystageresult;
            }
            else {
                if (con.prevtype === ConditionType.And) {
                    ismatch && (ismatch = mystageresult);
                }
                else {
                    ismatch || (ismatch = mystageresult);
                }
            }
        }
        if (ismatch) {
            ret.add(rin);
        }
    }
    return ret;
};
export const getDisplayForID = (en) => {
    const data = en.celldata;
    let ret = "";
    for (let i = 0; i < data.length; i++) {
        if (i > 0 && i % 3 === 0) {
            ret += "-";
        }
        ret += data[i];
    }
    return ret;
};
export const decryptCell = (fitem, achema, ritems = new Map()) => {
    var _a, _b, _c;
    let myitem = undefined;
    for (const si of achema.items) {
        if (si.type === SchemaItemType.List) {
            const lsi = si;
            for (const ssi of lsi.items) {
                if (ssi.item.id === fitem.cellid) {
                    myitem = ssi.item;
                    break;
                }
            }
        }
        else {
            if (si.id === fitem.cellid) {
                myitem = si;
                break;
            }
        }
    }
    if ((myitem === null || myitem === void 0 ? void 0 : myitem.type) === SchemaItemType.ID) {
        fitem.decrypted = true;
        return fitem;
    }
    if ((myitem === null || myitem === void 0 ? void 0 : myitem.type) === SchemaItemType.SquenceNumber) {
        fitem.decrypted = true;
        fitem.celldata = fitem.celldata.substring("sqn-".length);
        return fitem;
    }
    if (fitem.celldata && fitem.celldata.startsWith("pbk---")) {
        let pk = achema.webformkeys.priavatekey;
        if (fitem.schemaid === ((_a = achema.parentschema) === null || _a === void 0 ? void 0 : _a.id)) {
            pk = achema.parentschema.webformkeys.priavatekey;
        }
        if (!pk) {
            fitem.celldata = '';
            return;
        }
        try {
            const emsg = fitem.celldata.substring("pbk---".length);
            const jsonstring = LetrwingCrypto.getUTF8FromBase64(emsg);
            const js = JSON.parse(jsonstring);
            const rawdata = LetrwingCrypto.decryptRSABoxRawString(js, { privatkey: pk, publickey: '' });
            fitem.celldata = rawdata !== null && rawdata !== void 0 ? rawdata : '';
            fitem.cellfindstr = rawdata !== null && rawdata !== void 0 ? rawdata : '';
            // we should also do display str here
            fitem.decrypted = true;
        }
        catch (ex) {
            console.log(ex);
        }
        return;
    }
    let key = achema.accesskey;
    let nonce = achema.nonce;
    if (fitem.schemaid === ((_b = achema.parentschema) === null || _b === void 0 ? void 0 : _b.id)) {
        key = achema.parentschema.accesskey;
        nonce = achema.parentschema.nonce;
    }
    const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: fitem.celldata, nonce: '', publickey: '' }, key, nonce);
    fitem.celldata = val !== null && val !== void 0 ? val : '';
    if (fitem.cellfindstr) {
        const val = LetrwingCrypto.decryptBoxWithNonceToStr({ encryptmsg: fitem.cellfindstr, nonce: '', publickey: '' }, key, nonce);
        fitem.cellfindstr = val !== null && val !== void 0 ? val : '';
    }
    else {
        fitem.cellfindstr = '';
    }
    if (myitem && myitem.type === SchemaItemType.Remote && ritems.size > 0) {
        const srit = myitem;
        const id = srit.id + "---" + srit.sid + "---" + srit.cid;
        const dens = ritems.get(id);
        if (dens) {
            const mit = fitem.celldata.split(",");
            for (const vk of dens) {
                if (mit.indexOf(vk.id) !== -1) {
                    if (!fitem.remotevalue)
                        fitem.remotevalue = [];
                    (_c = fitem.remotevalue) === null || _c === void 0 ? void 0 : _c.push(vk.value);
                }
            }
        }
    }
    if (myitem) {
        displayStr(fitem, myitem, ritems);
    }
    fitem.decrypted = true;
};
export const displayStr = (en, entry, dependentdata) => {
    let choices = [];
    let remotevals = [];
    let udata = undefined;
    let vids = new Set();
    if (entry.type === SchemaItemType.Choice) {
        const ebots = en.celldata.split(",");
        if (ebots.length > 0) {
            if (!entry.multiselect) {
                const cval = ebots[0];
                for (const val of entry.items) {
                    if (cval === (val.id)) {
                        choices.push(val);
                        break;
                    }
                }
            }
            else {
                for (const val of entry.items) {
                    if (ebots.indexOf(val.id) !== -1) {
                        choices.push(val);
                    }
                }
            }
        }
        en.displaystr = choices.map(c => c.name).join(", ");
    }
    else if (entry.type === SchemaItemType.Remote) {
        const uent = entry;
        const id = uent.id + "---" + uent.sid + "---" + uent.cid;
        const vals = dependentdata.get(id);
        if (vals) {
            // now we have this
            const bits = en.celldata.split(",");
            if (bits && bits.length > 0) {
                remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1);
                if (remotevals.length > 0) {
                    en.displaystr = remotevals.map((val) => val.value).join(", ");
                }
            }
        }
    }
    if (en.celldata && entry.type === SchemaItemType.URL) {
        try {
            udata = JSON.parse(en.celldata);
            en.displaystr = "<a href=\"" + udata.url + "\" >" + udata.name + "</a>";
        }
        catch (ex) { }
    }
};
export const attachRemoteValue = (fitem, myitem, locaritems = new Map()) => {
    var _a;
    if (myitem && myitem.type === SchemaItemType.Remote && locaritems.size > 0) {
        const srit = myitem;
        const id = srit.sid + "---" + srit.cid;
        const dens = locaritems.get(id);
        if (dens) {
            const mit = fitem.celldata.split(",");
            fitem.remotevalue = [];
            for (const vk of dens) {
                if (mit.indexOf(vk.id) !== -1) {
                    (_a = fitem.remotevalue) === null || _a === void 0 ? void 0 : _a.push(vk.value);
                }
            }
        }
    }
};
export const filterInvalidConsitions = (cond) => {
    const ret = [];
    for (const co of cond) {
        if (co.params) {
            const vparam = [];
            for (const vp of co.params) {
                if (vp.leftcond && vp.comp !== undefined && (vp.rightcond !== undefined || vp.rightschemacon !== undefined)) {
                    vparam.push(vp);
                }
            }
            if (vparam.length > 0) {
                const nc = Object.assign(Object.assign({}, co), { params: vparam });
                ret.push(nc);
            }
        }
    }
    return ret;
};
export const canShowRemoteEntry = (evaluatingEntry, conds, rowentries) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const comparemap = new Map();
    for (const en of rowentries) {
        if (!comparemap.has(en.cellid)) {
            comparemap.set(en.cellid, []);
        }
        // we should check here for sublist and we should keep entry of only s
        if (evaluatingEntry.cellid.indexOf("----") !== -1) {
            // okay we have sub item
            const evparrs = evaluatingEntry.cellid.split("----");
            if (evparrs[0] === en.cellid.split("----")[0]) {
                if (en.subrowindex === evaluatingEntry.subrowindex) {
                    (_a = comparemap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
                }
            }
            else {
                (_b = comparemap.get(en.cellid)) === null || _b === void 0 ? void 0 : _b.push(en);
            }
        }
        else {
            (_c = comparemap.get(en.cellid)) === null || _c === void 0 ? void 0 : _c.push(en);
        }
    }
    // we have all entried lets check if we can 
    let index = -1;
    let prevstageres = false;
    for (const cond of conds) {
        index++;
        if (!cond.params || cond.params.length === 0)
            continue;
        // idea here is if we have or we should break as soon we have one 1
        // if and we should break as soon we have 0
        let mystageco = cond.contype;
        if (index > 0) {
            let pcom = mystageco;
            if (cond.prevtype) {
                pcom = cond.prevtype;
            }
            if (pcom === ConditionType.And && !prevstageres) {
                // now it's false from preveious stage !
                continue;
            }
            else if (pcom === ConditionType.Or && prevstageres) {
                continue;
            }
        }
        // lets see what this condition will do 
        // we need to do and with all we have !
        let paramresuls = [];
        for (const param of cond.params) {
            let leftcon = param.leftcond;
            if (!leftcon || !param.comp)
                continue;
            const ens = comparemap.get(leftcon);
            if (!ens)
                continue; // ignore this condition
            // lets check now!
            const comp = param.comp;
            let result = false;
            if (comp === Comparator.Equal || comp === Comparator.NotEqual) {
                let conval = "";
                if (param.rightschemacon) {
                    // now lets get all the ens 
                    const mmens = comparemap.get(param.rightschemacon);
                    if (mmens) {
                        for (const men of ens) {
                            const rrvals = (_e = (_d = men.remotevalue) === null || _d === void 0 ? void 0 : _d.map((v) => v.toLowerCase())) !== null && _e !== void 0 ? _e : [];
                            for (const smen of mmens) {
                                const rvals = [men.celldata.toLowerCase()];
                                const lvals = [smen.celldata.toLowerCase()];
                                const lrvals = (_g = (_f = smen.remotevalue) === null || _f === void 0 ? void 0 : _f.map((v) => v.toLowerCase())) !== null && _g !== void 0 ? _g : [];
                                for (const v of rvals) {
                                    if (lvals.indexOf(v) !== -1) {
                                        result = true;
                                        break;
                                    }
                                }
                                if (!result && rrvals.length > 0) {
                                    for (const v of rrvals) {
                                        if (lvals.indexOf(v) !== -1) {
                                            result = true;
                                            break;
                                        }
                                    }
                                    if (!result && lrvals.length > 0) {
                                        for (const v of rrvals) {
                                            if (lrvals.indexOf(v) !== -1) {
                                                result = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                                if (!result && lrvals.length > 0) {
                                    for (const v of lrvals) {
                                        if (rvals.indexOf(v) !== -1) {
                                            result = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else if (param.rightcond) {
                    for (const men of ens) {
                        if (men.celldata === param.rightcond) {
                            result = true;
                            break;
                        }
                        if (men.celldata.toLowerCase()
                            === param.rightcond.toLowerCase()) {
                            result = true;
                            break;
                        }
                    }
                }
                if (comp === Comparator.NotEqual) {
                    // reverse of equal is not equal
                    result = !result;
                }
            }
            else if (comp === Comparator.Greater) {
                if (param.rightschemacon) {
                    const mmens = comparemap.get(param.rightschemacon);
                    if (mmens) {
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            for (const smen of mmens) {
                                const pval = parseFloat(smen.celldata);
                                const rvals = [cval];
                                const lvals = [pval];
                                if (men.remotevalue) {
                                    //  rvals.push(parseFloat(men.remotevalue));
                                }
                                if (smen.remotevalue) {
                                    //   lvals.push(parseFloat(smen.remotevalue));
                                }
                                for (const v of rvals) {
                                    for (const lb of lvals) {
                                        if (v > lb) {
                                            result = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else if (param.rightcond !== undefined) {
                    const pval = parseFloat(param.rightcond);
                    for (const men of ens) {
                        const cval = parseFloat(men.celldata);
                        if (cval > pval) {
                            // we have one that is greater now!
                            // is this a home run 
                            result = true;
                            break;
                        }
                    }
                }
            }
            else if (comp === Comparator.Less) {
                if (param.rightschemacon) {
                    const mmens = comparemap.get(param.rightschemacon);
                    if (mmens) {
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            for (const smen of mmens) {
                                const pval = parseFloat(smen.celldata);
                                const rvals = [cval];
                                const lvals = [pval];
                                if (men.remotevalue) {
                                    // rvals.push(parseFloat(men.remotevalue));
                                }
                                if (smen.remotevalue) {
                                    //  lvals.push(parseFloat(smen.remotevalue));
                                }
                                for (const v of rvals) {
                                    for (const lb of lvals) {
                                        if (v < lb) {
                                            result = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else if (param.rightcond !== undefined) {
                    const pval = parseFloat(param.rightcond);
                    for (const men of ens) {
                        const cval = parseFloat(men.celldata);
                        if (cval < pval) {
                            // we have one that is greater now!
                            // is this a home run 
                            result = true;
                            break;
                        }
                    }
                }
            }
            else if (comp === Comparator.LessAndEqual) {
                if (param.rightschemacon) {
                    const mmens = comparemap.get(param.rightschemacon);
                    if (mmens) {
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            for (const smen of mmens) {
                                const pval = parseFloat(smen.celldata);
                                const rvals = [cval];
                                const lvals = [pval];
                                if (men.remotevalue) {
                                    //rvals.push(parseFloat(men.remotevalue));
                                }
                                if (smen.remotevalue) {
                                    // lvals.push(parseFloat(smen.remotevalue));
                                }
                                for (const v of rvals) {
                                    for (const lb of lvals) {
                                        if (v <= lb) {
                                            result = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else if (param.rightcond !== undefined) {
                    const pval = parseFloat(param.rightcond);
                    for (const men of ens) {
                        const cval = parseFloat(men.celldata);
                        if (cval <= pval) {
                            // we have one that is greater now!
                            // is this a home run 
                            result = true;
                            break;
                        }
                    }
                }
            }
            else if (comp === Comparator.GreaterAndEqual) {
                if (param.rightschemacon) {
                    const mmens = comparemap.get(param.rightschemacon);
                    if (mmens) {
                        for (const men of ens) {
                            const cval = parseFloat(men.celldata);
                            for (const smen of mmens) {
                                const pval = parseFloat(smen.celldata);
                                const rvals = [cval];
                                const lvals = [pval];
                                if (men.remotevalue) {
                                    //  rvals.push(parseFloat(men.remotevalue));
                                }
                                if (smen.remotevalue) {
                                    //  lvals.push(parseFloat(smen.remotevalue));
                                }
                                for (const v of rvals) {
                                    for (const lb of lvals) {
                                        if (v >= lb) {
                                            result = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else if (param.rightcond !== undefined) {
                    const pval = parseFloat(param.rightcond);
                    for (const men of ens) {
                        const cval = parseFloat(men.celldata);
                        if (cval >= pval) {
                            // we have one that is greater now!
                            // is this a home run 
                            result = true;
                            break;
                        }
                    }
                }
            }
            else if (comp === Comparator.Contains || comp === Comparator.NotContains) {
                // now just check conatin stuff and we done!!
                if (param.rightschemacon) {
                    const mmens = comparemap.get(param.rightschemacon);
                    if (mmens) {
                        for (const men of ens) {
                            const cval = men.celldata;
                            for (const smen of mmens) {
                                const pval = smen.celldata;
                                if (cval.indexOf(pval) !== -1
                                    || pval.indexOf(cval) !== -1) {
                                    // we have one that is greater now!
                                    // is this a home run 
                                    result = true;
                                    break;
                                }
                                if (cval.toLowerCase().indexOf(pval.toLowerCase()) !== -1
                                    || pval.toLowerCase().indexOf(cval.toLowerCase()) !== -1) {
                                    // we have one that is greater now!
                                    // is this a home run 
                                    result = true;
                                    break;
                                }
                            }
                            if (result)
                                break;
                        }
                    }
                }
                else if (param.rightcond !== undefined) {
                    const pval = (param.rightcond);
                    for (const men of ens) {
                        const cval = (men.celldata);
                        if (cval.indexOf(pval) !== -1 || pval.indexOf(cval) !== -1) {
                            // we have one that is greater now!
                            // is this a home run 
                            result = true;
                            break;
                        }
                        if (cval.toLowerCase().indexOf(pval.toLowerCase()) !== -1 || pval.toLowerCase().indexOf(cval.toLowerCase()) !== -1) {
                            // we have one that is greater now!
                            // is this a home run 
                            result = true;
                            break;
                        }
                    }
                }
                if (comp === Comparator.NotContains) {
                    result = !result;
                }
            }
            if (mystageco === ConditionType.And && !result) {
                paramresuls.splice(0, paramresuls.length);
                paramresuls.push(false);
                break;
            }
            else if (mystageco === ConditionType.Or && result) {
                paramresuls.splice(0, paramresuls.length);
                paramresuls.push(true);
                break;
            }
            paramresuls.push(result);
        }
        // now we have the paramres
        let mystageout = false;
        if (paramresuls.length === 0) {
        }
        else if (paramresuls.length === 1) {
            mystageout = paramresuls[0];
        }
        else {
            mystageout = paramresuls[0];
            for (let i = 1; i < paramresuls.length; i++) {
                if (mystageco === ConditionType.And) {
                    mystageout = paramresuls[i] && mystageout;
                }
                else if (mystageco === ConditionType.Or) {
                    mystageout = paramresuls[i] || mystageout;
                }
            }
        }
        if (index > 0) {
            if (cond.prevtype === ConditionType.And) {
                prevstageres = prevstageres && mystageout;
            }
            else {
                prevstageres = prevstageres || mystageout;
            }
        }
        else {
            prevstageres = mystageout;
        }
    }
    return prevstageres;
};
