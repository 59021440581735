var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Suspense } from 'react';
import { observer } from 'mobx-react';
import { FadeLoader } from 'react-spinners';
import { ViewerFindState } from '../uistore/ViewerFindState';
import { PdfPageStore } from '../UIComponents/PdfPagestore';
import { StoreContext } from '../uistore/PwaRootStore';
import { LetrwingCrypto } from '@@/LetrwingCommonCrypto';
import { PwaPdfPageStore } from '../UIComponents/PwaPdfPageStore';
import { Flex } from '@chakra-ui/react';
import { AppColor } from '../const/AppDefaults';
const MainViewrComponent = React.lazy(() => import('./PwaMainViewer'));
// class State {
//     @observable public show = false;
//     @observable public activefileid = "";
//     private activefilekey = "";
//     public onclosecb?: () => void;
//     public pagedetails: PdfPageDetail[] = [];
//     private activepagenumber = 0;
//     constructor() {
//         makeObservable(this);
//     }
//     @action
//     public setPdfPageDetails(details: PdfPageDetail[]) {
//         this.pagedetails = details;
//     }
//     public setActiveFileKey(key: string) {
//         this.activefilekey = key;
//     }
//     @action
//     public setActiveFileId(fid: string) {
//         this.activefileid = fid;
//     }
//     @action
//     public reset() {
//         this.setShow(false);
//         this.setActiveFileId("");
//     }
//     public getPdfInfo() {
//         const req: PdfFileMetadataRequest = {
//             id: this.activefileid,
//             accesskey: this.activefilekey
//         };
//         Apphandler.sendMessage("pdffilemeta", req);
//     }
//     public getPdfPage(pagenumber: number) {
//         this.loadPdfPage(pagenumber);
//     }
//     private async loadPdfPage(page: number) {
//         if(this.pagedetails.length <= page) {
//             return new Uint8Array();
//         }
//         const deet = this.pagedetails[page];
//         const req: PdfPageRequest = {
//             id: this.activefileid,
//             pagename: deet.filename,
//             pagenumber: page,
//             accesskey: this.activefilekey
//         };
//     }
//     private loadPdfPage1(page: number) {
//         if(this.pagedetails.length < page) {
//             return;
//         }
//         const deet = this.pagedetails[page];
//         const req: PdfPageRequest = {
//             id: this.activefileid,
//             pagename: deet.filename,
//             pagenumber: page,
//             accesskey: this.activefilekey
//         };
//         Apphandler.sendMessage("pdfpage", req);
//         if(!PdfPageStore.pages.has(deet.filename+"-words")) {
//             const req: PdfPageRequest = {
//                 id: this.activefileid,
//                 pagename: deet.filename+"-words",
//                 pagenumber: page,
//                 accesskey: this.activefilekey
//             };
//             deet.wordloaded = true;
//             Apphandler.sendMessage("pdfpage", req);
//         }
//     }
//     public getPdfPageDetail(num: number) {
//         if(this.pagedetails.length > num) {
//             return this.pagedetails[num];
//         }
//         return undefined;
//     }
//     @action
//     public setShow(show: boolean) {
//         this.show = show;
//         if(!this.show) {
//             this.onclosecb?.();
//         }
//     }
// }
// export const PdfViewerState = new State();
let PwaPdfViewer = class PwaPdfViewer extends React.Component {
    render() {
        const rstore = this.context;
        return React.createElement(Suspense, { fallback: React.createElement(Flex, { h: window.innerHeight - 150 + 'px', align: 'center', justify: 'center' },
                React.createElement(FadeLoader, { color: AppColor.SideBarIconColor })) },
            React.createElement(MainViewrComponent, { viewerfindstate: new ViewerFindState(), getPageDetail: (num) => rstore.getPwaStore().getPdfPageDetail(num), onClose: () => {
                    rstore.getPwaStore().setShow(false);
                    rstore.getPwaStore().setViewerActive(false);
                    let hash = window.location.hash;
                    hash = hash.split("~~~Doc")[0];
                    window.history.replaceState({}, document.title, hash);
                }, totalpages: rstore.getPwaStore().pagedetails.length, getPage: (page) => rstore.getPwaStore().getPdfPage(page, (res) => {
                    var _a, _b, _c;
                    if (rstore.getPwaMiniViewerStore().pagestate.has(page)) {
                        const deet = rstore.getPwaStore().pagedetails[page];
                        if (deet) {
                            let pagestr = new Uint8Array();
                            pagestr = LetrwingCrypto.base64ToBin(res);
                            (_a = rstore.getPwaMiniViewerStore().pagestate.get((page))) === null || _a === void 0 ? void 0 : _a.setPage(pagestr);
                            (_b = rstore.getPwaMiniViewerStore().pagestate.get((page))) === null || _b === void 0 ? void 0 : _b.setPageLoading(false);
                            (_c = rstore.getPwaMiniViewerStore().pagestate.get((page))) === null || _c === void 0 ? void 0 : _c.setDownloadingPage(false);
                            PwaPdfPageStore.pages.set(deet.filename, pagestr);
                            if (!PdfPageStore.pages.has(deet.filename + "-words")) {
                                rstore.getPwaStore().loadWords(page, (res) => {
                                    if (res) {
                                        const pgarr = JSON.parse(res);
                                        const pdet = rstore.getPwaMiniViewerStore().pagestate.get(page);
                                        if (pdet) {
                                            pdet.setWordDetails(pgarr);
                                            const cods = rstore.getPwaMiniViewerStore().sethighlightterms.get(page + 1);
                                            if (cods !== undefined && pgarr !== undefined) {
                                                const words = pgarr;
                                                cods.forEach((cod) => {
                                                    if (words.length > cod) {
                                                        const wcod = words[cod];
                                                        const rect = {
                                                            x1: wcod.x1,
                                                            y1: wcod.y1,
                                                            x2: wcod.x2,
                                                            y2: wcod.y2
                                                        };
                                                        pdet === null || pdet === void 0 ? void 0 : pdet.setHighCods(rect);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                });
                                deet.wordloaded = true;
                            }
                        }
                    }
                }) }));
    }
};
PwaPdfViewer.contextType = StoreContext;
PwaPdfViewer = __decorate([
    observer
], PwaPdfViewer);
export { PwaPdfViewer };
