import { comm } from "@/Comm/comm";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { ServerPath } from "@@/ServerPath";
import { SecStore } from "./SecurityStore";
class GroupStore {
    async getAllGroups() {
        const seqreq = SecStore.getSecurityCommHeader();
        const res = await comm.get(ServerPath.Groups, [seqreq.header]);
        if (res === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(res, seqreq.kp);
        if (dmsg !== undefined) {
            return dmsg.contacts;
        }
        return undefined;
    }
    async addMiscContact(cont) {
        return await comm.post(ServerPath.addMiscContact, cont);
    }
    async getMiscContact() {
        return await comm.get(ServerPath.getMiscContacts);
    }
    async sendInvite(name, email, internal) {
        const req = {
            fullname: name,
            email: email.toLowerCase(),
            internal: internal
        };
        const kph = SecStore.getSecurityCommHeader();
        const res = await comm.post(ServerPath.inviteUser, req, [kph.header]);
        if (res === undefined || res === 'InvalidDevice' || res.toLowerCase() !== 'ok') {
            return false;
        }
        return true;
    }
    async getOrgAppUsers() {
        const users = await comm.get(ServerPath.getOrgAppUsers);
        const ret = [];
        if (users) {
            for (const u of users) {
                if (u.status && u.status === 'Dead')
                    continue;
                if (!u.publickey) {
                    continue;
                }
                const r = Object.assign(Object.assign({}, u), { privatekey: '', code: '' });
                ret.push(r);
            }
        }
        return ret;
    }
    async getAppUsers(masterkey, keybox) {
        var _a;
        const pkey = keybox === null || keybox === void 0 ? void 0 : keybox.publickey;
        const users = await comm.get(ServerPath.getAppUser);
        const ret = [];
        if (users) {
            const updateusers = [];
            for (const u of users) {
                if (u.codebox && u.orgbox) {
                    if (u.status && u.status === 'Dead')
                        continue;
                    const code = LetrwingCrypto.decryptSecretBox(u.codebox, masterkey);
                    if (code === undefined)
                        continue;
                    const ch = await LetrwingCrypto.getHash256(code);
                    const ost = LetrwingCrypto.decryptSecretBox(u.orgbox, ch);
                    if (ost === undefined)
                        continue;
                    let gpk = (_a = keybox === null || keybox === void 0 ? void 0 : keybox.publickey) !== null && _a !== void 0 ? _a : '';
                    if (u.generickeybox) {
                        const gst = LetrwingCrypto.decryptSecretBox(u.generickeybox, ch);
                        try {
                            if (gst) {
                                const gk = JSON.parse(gst);
                                gpk = gk.publickey;
                            }
                        }
                        catch (ex) { }
                    }
                    const r = Object.assign(Object.assign({}, u), { privatekey: ost, code: code, genericpublickey: gpk });
                    ret.push(r);
                    if (!u.generickeybox) {
                        updateusers.push(r);
                    }
                }
            }
            if (keybox) {
                for (const uu of updateusers) {
                    await this.updateAppUser(uu, masterkey, keybox);
                }
            }
        }
        return ret;
    }
    async addAppUser(user, mk, keybox) {
        const cidebox = LetrwingCrypto.secretBox(user.code, mk);
        const ch = await LetrwingCrypto.getHash256(user.code);
        const orgbox = LetrwingCrypto.secretBox(user.privatekey, ch);
        let gbox = user.generickeybox;
        if (keybox) {
            gbox = LetrwingCrypto.secretBox(JSON.stringify(keybox.keybox), ch);
        }
        const au = {
            id: '',
            orgbox: orgbox,
            codebox: cidebox,
            hint: user.hint,
            phonenumber: user.phonenumber,
            countrycode: user.countrycode,
            email: user.email,
            name: user.name,
            orgid: '',
            publickey: user.publickey,
            generickeybox: gbox
        };
        const ur = await comm.post(ServerPath.addAppUser, au);
        if (ur === null || ur === void 0 ? void 0 : ur.su) {
            const u = ur.su;
            if (u.codebox && u.orgbox) {
                const code = LetrwingCrypto.decryptSecretBox(u.codebox, mk);
                if (code === undefined)
                    return undefined;
                const ch = await LetrwingCrypto.getHash256(code);
                const ost = LetrwingCrypto.decryptSecretBox(u.orgbox, ch);
                if (ost === undefined)
                    return undefined;
                let gpk = "";
                if (u.generickeybox) {
                    const gst = LetrwingCrypto.decryptSecretBox(u.generickeybox, ch);
                    try {
                        if (gst) {
                            const gk = JSON.parse(gst);
                            gpk = gk.publickey;
                        }
                    }
                    catch (ex) { }
                }
                const r = Object.assign(Object.assign({}, u), { privatekey: ost, code: code, genericpublickey: gpk });
                console.log(r);
                return r;
            }
        }
        return undefined;
    }
    async updateAppUser(user, mk, keybox) {
        const cidebox = LetrwingCrypto.secretBox(user.code, mk);
        const ch = await LetrwingCrypto.getHash256(user.code);
        const orgbox = LetrwingCrypto.secretBox(user.privatekey, ch);
        let gbox = user.generickeybox;
        if (keybox) {
            gbox = LetrwingCrypto.secretBox(JSON.stringify(keybox.keybox), ch);
        }
        const au = {
            id: user.id,
            orgbox: orgbox,
            codebox: cidebox,
            hint: user.hint,
            phonenumber: user.phonenumber,
            countrycode: user.countrycode,
            email: user.email,
            name: user.name,
            orgid: user.orgid,
            publickey: user.publickey,
            generickeybox: gbox
        };
        const ur = await comm.post(ServerPath.updateAppUser, au);
        if (ur === null || ur === void 0 ? void 0 : ur.su) {
            const u = ur.su;
            if (u.codebox && u.orgbox) {
                const code = LetrwingCrypto.decryptSecretBox(u.codebox, mk);
                if (code === undefined)
                    return undefined;
                const ch = await LetrwingCrypto.getHash256(code);
                const ost = LetrwingCrypto.decryptSecretBox(u.orgbox, ch);
                if (ost === undefined)
                    return undefined;
                let gpk = "";
                if (u.generickeybox) {
                    const gst = LetrwingCrypto.decryptSecretBox(u.generickeybox, ch);
                    try {
                        if (gst) {
                            const gk = JSON.parse(gst);
                            gpk = gk.publickey;
                        }
                    }
                    catch (ex) { }
                }
                const r = Object.assign(Object.assign({}, u), { privatekey: ost, code: code, genericpublickey: gpk });
                return r;
            }
        }
        return undefined;
    }
    async deleteAppUser(user) {
        const req = user.map((u) => ({ id: u }));
        const res = await comm.post(ServerPath.deleteAppUser, req);
        return res !== null && res !== void 0 ? res : false;
    }
    async getPendingInvites() {
        const kph = SecStore.getSecurityCommHeader();
        const res = await comm.get(ServerPath.pendingUser, [kph.header]);
        if (res === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(res, kph.kp);
        return dmsg;
    }
    async getAllInvitations() {
        const kp = SecStore.getSecurityCommHeader();
        const res = await comm.get(ServerPath.invites, [kp.header]);
        if (res === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(res, kp.kp);
        return dmsg;
    }
    async acceptInvite(contact, isPublic = false, publicemail = "") {
        const req = { invitationid: contact, isPublic: isPublic, email: publicemail };
        const res = await comm.post(ServerPath.acceptinvite, req);
        if (res === undefined) {
            return false;
        }
        return true;
    }
    async declineInvite(contact, isPublic = false, publicemail = "") {
        const req = { invitationid: contact, isPublic: isPublic, email: publicemail };
        const res = await comm.post(ServerPath.deleteinvite, req);
        if (res === undefined) {
            return false;
        }
        return true;
    }
    async deleteExternalContact(contact) {
        const req = { contactid: contact.userid };
        const res = await comm.post(ServerPath.deleteExternalContact, req);
        if (res === undefined || !res) {
            return false;
        }
        return res;
    }
    async createNewGroup(name, memberids) {
        const req = {
            name: name,
            memberids: memberids
        };
        const res = await comm.post(ServerPath.AddGroups, req);
        if (res === undefined) {
            return undefined;
        }
        return res;
    }
    async getMyTeam() {
        console.log('getmyteam');
        const res = await comm.get(ServerPath.getMyTeam);
        if (res == undefined) {
            return undefined;
        }
        return res;
    }
    async getMyLetrwingContacts() {
        const res = await comm.get(ServerPath.getMyLetrwingContact);
        if (res == undefined) {
            return undefined;
        }
        return res;
    }
    async getCompanyDirectory() {
        const res = await comm.get(ServerPath.getCompanyDirectory);
        if (res == undefined) {
            return undefined;
        }
        return res;
    }
    async checkContactCanBePublished(cid) {
        const res = await comm.post(ServerPath.userCanPublishContact, { data: cid });
        if (res == undefined) {
            return false;
        }
        return res;
    }
    async publicContact(cid) {
        const res = await comm.post(ServerPath.publishMyContactToCompanyDircetory, { data: cid });
        if (res == undefined) {
            return false;
        }
        return res;
    }
    async connect(conid) {
        const res = await comm.post(ServerPath.sendInviteToCompantContact, { data: conid });
        if (res == undefined) {
            return false;
        }
        return res;
    }
    async discoverEmail(email) {
        const req = {
            email: email,
            phonenumber: ""
        };
        const res = await comm.post(ServerPath.discover, req);
        if (res == undefined) {
            return undefined;
        }
        return res;
    }
    async discoverPhonenumber(pnumber) {
        const req = {
            email: "",
            phonenumber: pnumber
        };
        const res = await comm.post(ServerPath.discover, req);
        if (res == undefined) {
            return undefined;
        }
        return res;
    }
}
export const GStore = new GroupStore();
