var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { comm } from '@/Comm/comm';
import { APPEvent } from '@/events/AppEvents';
import { emailStringVerify } from '@@/Util';
import { Avatar, Box, Button, Center, Flex, FormControl, Heading, HStack, Icon, Input, InputGroup, InputLeftElement, Link, Stack, Text } from '@chakra-ui/react';
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { MdPhone } from '@react-icons/all-files/md/MdPhone';
import { observer } from 'mobx-react';
import React from 'react';
import { BeatLoader } from 'react-spinners';
import { OfflineMessage } from './UIComponents/PwaOfflineMessage';
import { PwaAuthState } from './uistore/PwaStore';
import { StoreContext } from './uistore/PwaRootStore';
let PwaLogin = class PwaLogin extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            email: '',
            phonenumber: '',
            sending: false,
            showverify: false,
            resending: false,
            code: ''
        };
    }
    async sendAuthCode() {
        const rstore = this.context;
        const res = await rstore.getPwaStore().sendAuthCode(this.state.email.trim().toLowerCase(), this.state.phonenumber.trim());
        if (!res) {
            APPEvent.publishApperror("Sorry, failed to send auth code");
        }
        this.setState({ sending: false, resending: false, showverify: res });
    }
    async verifyCode() {
        const rstore = this.context;
        const res = await rstore.getPwaStore().verifyCode(this.state.email.trim().toLowerCase(), this.state.phonenumber.trim(), this.state.code);
        let login = false;
        if (!res) {
            APPEvent.publishApperror("Sorry, verification failed. Please try again.");
        }
        else {
            comm.setSessionid(res);
            await rstore.getPwaStore().setupUserSession(res);
            if (await rstore.getPwaStore().userLoggedIn()) {
                login = true;
            }
            else {
                APPEvent.publishApperror("Sorry, failed to setup properly. Please try again or contact support.");
            }
        }
        this.setState({ sending: false, resending: false });
        if (login) {
            rstore.getPwaStore().setPwaLoginState(PwaAuthState.Loggedin);
        }
    }
    render() {
        const rstore = this.context;
        return React.createElement(Flex, { flexDirection: "column", width: "100wh", height: "100vh", backgroundColor: "gray.200", justifyContent: "center", alignItems: "center" },
            React.createElement(OfflineMessage, null),
            React.createElement(Stack, { flexDir: "column", mb: "2", justifyContent: "center", alignItems: "center" },
                React.createElement(Avatar, { bg: "teal.500" }),
                React.createElement(Heading, { color: "teal.400" }, "Welcome"),
                React.createElement(Box, { minW: { base: "90%", md: "468px" } },
                    React.createElement("form", null,
                        !this.state.showverify && React.createElement(Stack, { spacing: 4, p: "1rem", backgroundColor: "whiteAlpha.900", boxShadow: "md" },
                            React.createElement(FormControl, null,
                                React.createElement(InputGroup, null,
                                    React.createElement(InputLeftElement, { pointerEvents: "none", children: React.createElement(Icon, { as: FaUser, color: "gray.300" }) }),
                                    React.createElement(Input, { type: "email", value: this.state.email, onChange: (e) => {
                                            this.setState({ email: e.currentTarget.value, phonenumber: '' });
                                        }, placeholder: "email address" }))),
                            React.createElement(Flex, { w: '100%', justify: 'center', align: 'center', mt: '5px' },
                                React.createElement(Text, null, "OR")),
                            React.createElement(FormControl, null,
                                React.createElement(InputGroup, null,
                                    React.createElement(InputLeftElement, { pointerEvents: "none", children: React.createElement(Icon, { as: MdPhone, color: "gray.300" }) }),
                                    React.createElement(Input, { type: "number", value: this.state.phonenumber, onChange: (e) => {
                                            if (e.currentTarget.value.length > 10)
                                                return;
                                            this.setState({ phonenumber: e.currentTarget.value, email: '' });
                                        }, placeholder: "phone number" }))),
                            !this.state.sending && React.createElement(Button, { borderRadius: 0, disabled: !rstore.getPwaStore().online, type: "submit", variant: "solid", colorScheme: "teal", width: "full", onClick: () => {
                                    if (!this.state.email.trim() && !this.state.phonenumber.trim()) {
                                        return;
                                    }
                                    if (this.state.email) {
                                        if (!emailStringVerify(this.state.email)) {
                                            APPEvent.publishApperror("Please enter valid email");
                                            return;
                                        }
                                    }
                                    this.setState({ sending: true });
                                    this.sendAuthCode();
                                } }, "Login"),
                            this.state.sending && React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                                React.createElement(BeatLoader, null))),
                        this.state.showverify && React.createElement(Stack, { spacing: 4, p: "1rem", backgroundColor: "whiteAlpha.900", boxShadow: "md" },
                            React.createElement(Center, null,
                                React.createElement(Heading, { lineHeight: 1.1, fontSize: { base: '2xl', md: '3xl' } },
                                    this.state.email.trim() !== '' && 'Verify your Email',
                                    this.state.phonenumber.trim() !== '' && 'Verify your Phone Number')),
                            React.createElement(Center, { fontSize: { base: 'sm', sm: 'md' } }, 'We have sent code to your ' + (this.state.email.trim() !== '' ? 'email' : 'phone')),
                            React.createElement(Center, { fontSize: { base: 'sm', sm: 'md' }, fontWeight: "bold" }, this.state.email + this.state.phonenumber),
                            React.createElement(FormControl, null,
                                React.createElement(Center, null,
                                    React.createElement(HStack, null,
                                        React.createElement(Input, { value: this.state.code, onChange: (e) => this.setState({ code: e.currentTarget.value }) })))),
                            React.createElement(Stack, { spacing: 6 },
                                !this.state.sending && React.createElement(Button, { borderRadius: 0, disabled: !rstore.getPwaStore().online, variant: "solid", colorScheme: "teal", width: "full", onClick: () => {
                                        this.setState({ sending: true });
                                        this.verifyCode();
                                    } }, "Verify"),
                                this.state.sending && React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                                    React.createElement(BeatLoader, null)),
                                !this.state.sending && rstore.getPwaStore().online && React.createElement(Stack, { direction: { base: 'column', sm: 'row' }, align: 'start', justify: 'flex-end' },
                                    !this.state.resending && React.createElement(Link, { onClick: () => {
                                            this.setState({ resending: true });
                                            this.sendAuthCode();
                                        }, color: 'green.500' }, "Resend Code"),
                                    this.state.resending && React.createElement(BeatLoader, null),
                                    React.createElement(Link, { onClick: () => {
                                            this.setState({ email: '', phonenumber: '', resending: false, sending: false, showverify: false });
                                        }, color: 'red.500' }, "Restart"))))))));
    }
};
PwaLogin.contextType = StoreContext;
PwaLogin = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PwaLogin);
export { PwaLogin };
