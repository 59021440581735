import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { comm } from "@/Comm/comm";
import { EncryptBox } from "@@/EncryptBox";
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { MessageDeliveryStatus, MessageType } from "@@/Message";
import { SearchDataType, SearchOperation } from "@@/ModuleJob";
import { ProjectObjectType } from "@@/Project";
import { FieldStatus, SchemaItemType } from "@@/Schema";
import { ServerPath } from "@@/ServerPath";
import { CONVERTUTIL } from "./ConvertUtil";
import { LStore } from "./LetrStore";
import { MStore } from "./MessageStore";
import { SearchStore } from "./SearchStore";
class Store {
    async loadProjectObject(letrid, objectid, origid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        // lets make a comm request 
        const res = await comm.post(ServerPath.GetProjectObject, { letrid: letrid, parentid: objectid, aobjectid: origid });
        let ret = [];
        if (res !== undefined) {
            ret = this.convertProjecObjectToProjectEntity([res], box, letrid);
        }
        if (ret !== undefined && ret.length > 0) {
            return ret[0];
        }
        return undefined;
    }
    async addFields(letrid, fields) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        // now we can add these to the server now!!
        const sfields = [];
        for (const fld of fields) {
            const sfild = {
                id: fld.id,
                fieldparentid: "",
                letrid: letrid,
                data: LetrwingCrypto.secretBox(JSON.stringify(fld), box),
                status: FieldStatus.Live
            };
            sfields.push(sfild);
        }
        const req = {
            letrid: letrid,
            fields: sfields
        };
        const res = await comm.post(ServerPath.addField, req);
        return res;
    }
    async getFields(letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            fields: []
        };
        const res = await comm.post(ServerPath.getField, req);
        const result = [];
        if (res) {
            for (const fld of res) {
                // lets create schema item here and we done here!!
                if (fld.data) {
                    const sstr = LetrwingCrypto.decryptSecretBox(fld.data, box);
                    if (sstr) {
                        try {
                            const sch = JSON.parse(sstr);
                            if (sch) {
                                result.push(Object.assign(Object.assign({}, sch), { id: fld.id, letrid: letrid }));
                            }
                        }
                        catch (ex) { }
                    }
                }
            }
        }
        return result;
    }
    async addMetadataToObject(objectid, letrid, fld, sitems = []) {
        var _a, _b;
        const box = InMemoryRepo.getLetrPassword(letrid);
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        if (sbox === undefined) {
            return undefined;
        }
        const assignedto = [];
        let uass = false;
        const smap = new Map();
        sitems.forEach((si) => smap.set(si.id, si));
        const res = [];
        for (const fl of fld) {
            delete fl.objs;
            delete fl.objsdec;
            delete fl.schsdec;
            delete fl.schms;
            const sitem = smap.get(fl.fieldid);
            if ((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice && sitem.addtotasks) {
                let datastr = (_a = fl.datastr) !== null && _a !== void 0 ? _a : '';
                assignedto.push(...datastr.split(","));
                uass = true;
            }
            const ebox = LetrwingCrypto.secretBoxWithNonce((_b = fl.datastr) !== null && _b !== void 0 ? _b : '', sbox.key, sbox.nonce);
            const eboxes = [];
            if (fl.datastr && (((sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.Choice) ||
                (sitem === null || sitem === void 0 ? void 0 : sitem.type) === SchemaItemType.UserChoice)) {
                const splivalues = fl.datastr.split(",");
                for (const sv of splivalues) {
                    if (sv) {
                        const eb = LetrwingCrypto.secretBoxWithNonce(sv, sbox.key, sbox.nonce);
                        if (eb) {
                            eboxes.push(eb.encryptmsg);
                        }
                    }
                }
            }
            res.push(Object.assign(Object.assign({}, fl), { data: ebox, datastr: '', searchitems: eboxes }));
        }
        const req = {
            letrid: letrid,
            objectid: objectid,
            items: res,
            assignedto: assignedto,
            updateassignee: uass,
            sitems: []
        };
        const result = await comm.post(ServerPath.addFieldToObject, req);
        if (!result) {
            return undefined;
        }
        return await this.loadProjectObject(letrid, objectid, objectid);
    }
    async deleteFields(letrid, flds) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            letrid: letrid,
            fields: []
        };
        for (const fld of flds) {
            req.fields.push({
                id: fld,
                fieldparentid: "",
                letrid: letrid,
                data: new EncryptBox(),
                status: FieldStatus.Dead
            });
        }
        const res = await comm.post(ServerPath.deleteField, req);
        const result = [];
        if (res) {
            for (const fld of res) {
                // lets create schema item here and we done here!!
                if (fld.data) {
                    const sstr = LetrwingCrypto.decryptSecretBox(fld.data, box);
                    if (sstr) {
                        try {
                            const sch = JSON.parse(sstr);
                            if (sch) {
                                result.push(Object.assign(Object.assign({}, sch), { id: fld.id, letrid: letrid }));
                            }
                        }
                        catch (ex) { }
                    }
                }
            }
        }
        return result;
    }
    async loadLetrProjects(letrid, parentid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return [];
        }
        // lets make a comm request 
        const res = await comm.post(ServerPath.GetProjectObjects, { letrid: letrid, parentid: parentid, aobjectid: parentid });
        let ret = [];
        if (res !== undefined) {
            ret = this.convertProjecObjectToProjectEntity(res, box, letrid);
        }
        return ret;
    }
    async aclProjectObject() {
    }
    async calcauletAclString(letrid, req) {
        const res = await comm.post(ServerPath.calcualteAclString, {
            letrid: letrid,
            groupids: req
        });
        if (res !== undefined) {
            return res;
        }
        return undefined;
    }
    async emptyTrash(letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const res = await comm.post(ServerPath.EmptyTrash, { letrid: letrid });
        return res;
    }
    async copyProjectObject(entity, letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const req = {
            po: [entity.id],
            letrid: letrid
        };
        const res = await comm.post(ServerPath.CopyProjectObject, req);
        if (res !== undefined) {
            await SearchStore.addToIndex(entity.name, res.id, letrid, SearchOperation.Add, SearchDataType.DriveName);
            const obj = this.convertProjecObjectToProjectEntity([res], box, letrid);
            if (obj.length > 0) {
                return obj[0];
            }
        }
        return undefined;
    }
    async getProjectObjectsDetailsWithParentPath(letrid, ids) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const res = await comm.post(ServerPath.getProjectObjectsDetails, { letrid: letrid, pids: ids });
        let ret = [];
        if (res !== undefined) {
            const result = this.convertProjecObjectToProjectEntity(res, box, letrid);
            // now we just need to return we are interested in and rest we can forget
            const mobjs = new Map();
            for (const br of result) {
                mobjs.set(br.id, br);
            }
            for (const id of ids) {
                const bobj = mobjs.get(id);
                if (bobj !== undefined) {
                    // lets get it's parent path
                    const parentoath = [""];
                    let parentid = bobj.parentid;
                    let n = 0;
                    while (n < 500) {
                        if (parentid === "root") {
                            parentoath.push("Home");
                            break;
                        }
                        else if (parentid === "trash") {
                            parentoath.push("Trash");
                            break;
                        }
                        const pobj = mobjs.get(parentid);
                        if (pobj === undefined) {
                            break;
                        }
                        parentid = pobj.parentid;
                        parentoath.push(pobj.name);
                        n++;
                    }
                    bobj.parentpath = "/" + parentoath.reverse().join("/");
                    if (bobj.parentpath[bobj.parentpath.length - 1] === "/") {
                        bobj.parentpath = bobj.parentpath.substring(0, bobj.parentpath.length - 1);
                    }
                    ret.push(bobj);
                }
            }
        }
        return ret;
    }
    async createProject(entity, letrid) {
        var _a, _b;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        // now we can create something magical here
        const pobj = this.convertEntityToProjectObject(entity, box, letrid, entity.parentid);
        if (pobj !== undefined) {
            pobj.projectid = letrid;
            pobj.accessids.push("everyone");
            const res = await comm.post(ServerPath.AddProjectObject, pobj);
            if (res !== undefined) {
                // lets also add the index here!
                //  await SearchStore.addToIndex(entity.name, res.id, letrid, SearchOperation.Add, SearchDataType.DriveName);
                const obj = this.convertProjecObjectToProjectEntity([res], box, letrid);
                if (obj.length > 0) {
                    res.projectid = letrid;
                    this.addProjectInfo(res, obj[0]);
                    if (res.type === ProjectObjectType.Document) {
                        const dob = obj[0];
                        await LStore.addVersion(res.id, letrid, {
                            name: (_a = dob.origname) !== null && _a !== void 0 ? _a : obj[0].name,
                            isDownloadable: false,
                            filid: dob.documentreference,
                            boxid: dob.accesscode,
                            versionnumber: 1,
                            acesskey: dob.accesskey,
                            size: dob.size,
                            creatorid: (_b = InMemoryRepo.getUserIDWithTenant()) !== null && _b !== void 0 ? _b : ''
                        });
                    }
                    return obj[0];
                }
            }
        }
        return undefined;
    }
    async addProjectInfo(obj, basemsg) {
        var _a, _b, _c;
        const message = obj.type + ": " + basemsg.name + " added in organiser";
        let color = "orange";
        if (obj.type === ProjectObjectType.Bookmark) {
            color = "red";
        }
        else if (obj.type === ProjectObjectType.Document) {
            color = "#37ff37";
        }
        else if (obj.type === ProjectObjectType.Folder) {
            const fmsg = basemsg;
            color = (_a = fmsg.color) !== null && _a !== void 0 ? _a : "orange";
        }
        else if (obj.type === ProjectObjectType.Draft) {
            color = "#199acf";
        }
        const pmsg = {
            objectid: obj.id,
            objecttype: obj.type,
            text: message,
            letrid: (_b = obj.projectid) !== null && _b !== void 0 ? _b : "",
            _id: "",
            createdAt: new Date().getTime(),
            type: MessageType.Project,
            replycount: 0,
            user: { _id: "", name: "" },
            status: MessageDeliveryStatus.Activating,
            color: color
        };
        // now lets add this message first
        MStore.saveMessage(pmsg, obj.id, (_c = obj.aclstring) !== null && _c !== void 0 ? _c : "");
    }
    convertEntityToProjectObject(ent, key, letrid, parentid) {
        var _a;
        if (ent.type === ProjectObjectType.Document) {
            const dent = ent;
            // lets do comething here now
            const finfo = {
                accesscode: dent.accesscode,
                accesskey: dent.accesskey,
                accessid: dent.documentreference,
                size: dent.size,
                versions: dent.versions,
                replace: [],
                width: 0,
                height: 0,
                name: dent.name,
                origname: dent.origname
            };
            const ef = LetrwingCrypto.secretBox(JSON.stringify(finfo), key);
            const ret = {
                type: ProjectObjectType.Document,
                id: ent.id,
                data: ef,
                parentid: parentid,
                updatetime: 0,
                childrencount: 0,
                accessids: [],
                itemreference: dent.documentreference,
                name: LetrwingCrypto.secretBox(dent.name, key)
            };
            return ret;
        }
        else if (ent.type === ProjectObjectType.Folder) {
            const dent = ent;
            const det = {
                name: dent.name,
                color: (_a = dent.color) !== null && _a !== void 0 ? _a : 'orange'
            };
            const ef = LetrwingCrypto.secretBox(JSON.stringify(det), key);
            const ret = {
                type: ProjectObjectType.Folder,
                id: ent.id,
                data: ef,
                parentid: parentid,
                updatetime: 0,
                childrencount: 0,
                accessids: [],
                name: LetrwingCrypto.secretBox(dent.name, key)
            };
            return ret;
        }
        else if (ent.type === ProjectObjectType.Bookmark) {
            const dent = ent;
            const bdetail = {
                name: dent.name,
                url: dent.url
            };
            const ef = LetrwingCrypto.secretBox(JSON.stringify(bdetail), key);
            const ret = {
                type: ProjectObjectType.Bookmark,
                id: ent.id,
                data: ef,
                parentid: parentid,
                updatetime: 0,
                childrencount: 0,
                accessids: [],
                name: LetrwingCrypto.secretBox(dent.name, key)
            };
            return ret;
        }
        else if (ent.type === ProjectObjectType.Draft) {
            const denet = ent;
            const dsave = {
                name: denet.name,
                accesskey: denet.accesskey,
                reference: denet.draftreference,
                type: denet.drafttype
            };
            const ebox = LetrwingCrypto.secretBox(JSON.stringify(dsave), key);
            const ret = {
                type: ProjectObjectType.Draft,
                id: ent.id,
                data: ebox,
                parentid: parentid,
                updatetime: 0,
                childrencount: 0,
                accessids: [],
                name: LetrwingCrypto.secretBox(denet.name, key)
            };
            return ret;
        }
        return undefined;
    }
    async moveProjectObject(objectid, letrid, newparentid, del = false) {
        const req = {
            objectid: objectid,
            newparentid: newparentid,
            projectid: letrid,
            delete: del
        };
        const res = await comm.post(ServerPath.moveProjectObject, req);
        if (res === undefined || !res) {
            return false;
        }
        if (newparentid === "_trash" || req.delete) {
            for (const obj of objectid) {
                SearchStore.removeItem(obj, letrid);
            }
        }
        return true;
    }
    async addAclGroup(grp) {
        const res = await comm.post(ServerPath.AddAclGroup, grp);
        if (res !== undefined) {
            res.projectid = grp.projectid;
        }
        return res;
    }
    async updateAclGroup(grp) {
        const res = await comm.post(ServerPath.UpdateAclGroup, grp);
        return res;
    }
    async deleteAclGroup(id, letrid) {
        const res = await comm.post(ServerPath.DeleteAclGroup, {
            projectid: letrid,
            id: id,
            name: "",
            creatorid: '',
            accessids: []
        });
        return res;
    }
    async getAclGroup(letrid) {
        const res = await comm.post(ServerPath.GetAclGroups, { projectid: letrid });
        if (res !== undefined) {
            res.forEach((r) => r.projectid = letrid);
        }
        return res;
    }
    async updateProjectObjectAcl(req) {
        const res = await comm.post(ServerPath.ApplyAclToProject, req);
        return res;
    }
    async updateProjectObject(ent, letrid) {
        var _a;
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const update = this.convertEntityToProjectObject(ent, box, letrid, ent.parentid);
        if (update === undefined) {
            return undefined;
        }
        update.projectid = letrid;
        const ret = await comm.post(ServerPath.updateProjectObject, update);
        if (ret !== undefined) {
            await SearchStore.addToIndex(ent.name, ret.id, letrid, SearchOperation.Add, SearchDataType.DriveName);
            const result = this.convertProjecObjectToProjectEntity([ret], box, letrid);
            if (ret.messageid !== undefined && ret.messageid && result !== undefined &&
                result.length > 0) {
                ret.projectid = letrid;
                this.addEditsForProjectObject(ret, result[0]);
            }
            return (_a = result === null || result === void 0 ? void 0 : result[0]) !== null && _a !== void 0 ? _a : undefined;
        }
        return undefined;
    }
    async getProjectFolders(letrid) {
        const box = InMemoryRepo.getLetrPassword(letrid);
        if (box === undefined) {
            return undefined;
        }
        const ret = await comm.post(ServerPath.getProjectFolders, { letrid: letrid, parentid: '', aobjectid: '' });
        if (ret !== undefined) {
            const ojs = this.convertProjecObjectToProjectEntity(ret, box, letrid);
            console.log(ojs);
            return ojs;
        }
        return undefined;
    }
    async addEditsForProjectObject(obj, basemsg) {
        // we need t get the message id and then we can just add this as reply that's it
        var _a, _b, _c;
        const message = obj.type + ": " + basemsg.name + " updated";
        let color = "orange";
        if (obj.type === ProjectObjectType.Bookmark) {
            color = "red";
        }
        else if (obj.type === ProjectObjectType.Document) {
            color = "#37ff37";
        }
        else if (obj.type === ProjectObjectType.Folder) {
            const fmsg = basemsg;
            color = (_a = fmsg.color) !== null && _a !== void 0 ? _a : "orange";
        }
        const pmsg = {
            objectid: obj.id,
            objecttype: obj.type,
            text: message,
            letrid: (_b = obj.projectid) !== null && _b !== void 0 ? _b : "",
            _id: "",
            createdAt: new Date().getTime(),
            type: MessageType.Project,
            replycount: 0,
            user: { _id: "", name: "" },
            status: MessageDeliveryStatus.Activating,
            color: color
        };
        // now lets add this message first
        MStore.reply(pmsg, (_c = obj.messageid) !== null && _c !== void 0 ? _c : "", 0);
    }
    async getParentsForObjects(letrid, objectids) {
        const req = {
            letrid: letrid,
            ids: objectids
        };
        const res = await comm.post(ServerPath.getProjectObjectParents, req);
        return res;
    }
    convertProjecObjectToProjectEntity(objs, accesskey, letrid, attachmetada = true) {
        var _a, _b;
        const ret = [];
        // lets try this work now
        const sbox = InMemoryRepo.getLetrSearchBox(letrid);
        for (const re of objs) {
            const pbox = re.data;
            let fld = [];
            if (attachmetada && sbox && re.metadata) {
                for (const mfld of re.metadata) {
                    try {
                        mfld.data.nonce = sbox.nonce;
                        const str = LetrwingCrypto.decryptSecretBox(mfld.data, sbox.key);
                        if (mfld.objs && mfld.objs.length > 0) {
                            mfld.objsdec = this.convertProjecObjectToProjectEntity(mfld.objs, accesskey, letrid, false);
                        }
                        if (mfld.schms && mfld.schms.length > 0) {
                            if (mfld.schms) {
                                const shs = [];
                                for (const sh of mfld.schms) {
                                    const sok = CONVERTUTIL.convertBoxToSchema(sh, letrid, accesskey);
                                    if (sok)
                                        shs.push(sok);
                                }
                                mfld.schsdec = shs;
                            }
                        }
                        fld.push(Object.assign(Object.assign({}, mfld), { datastr: str }));
                    }
                    catch (ex) { }
                }
            }
            const pboxopen = LetrwingCrypto.decryptSecretBox(pbox, accesskey);
            if (pboxopen !== undefined) {
                // now if the object is type document then it has fileinfo 
                // optherwise it;s just the name ?
                if (re.type === ProjectObjectType.Document) {
                    try {
                        const fileino = JSON.parse(pboxopen);
                        const dentity = {
                            id: re.id,
                            name: fileino.name,
                            accesscode: fileino.accesscode,
                            accesskey: fileino.accesskey,
                            versions: fileino.versions,
                            documentreference: fileino.accessid,
                            size: fileino.size,
                            type: ProjectObjectType.Document,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            accessids: re.accessids,
                            origname: (_a = fileino.origname) !== null && _a !== void 0 ? _a : fileino.name,
                            metadata: fld,
                            thumbdetail: re.thumbdetails
                        };
                        ret.push(dentity);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Folder) {
                    try {
                        const fde = JSON.parse(pboxopen);
                        const fen = {
                            name: fde.name,
                            color: (_b = fde.color) !== null && _b !== void 0 ? _b : 'orange',
                            id: re.id,
                            childrencount: re.childrencount,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            type: ProjectObjectType.Folder,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(fen);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Bookmark) {
                    try {
                        const bdetail = JSON.parse(pboxopen);
                        const ben = {
                            name: bdetail.name,
                            url: bdetail.url,
                            id: re.id,
                            updatetime: re.updatetime,
                            parentid: re.parentid,
                            type: ProjectObjectType.Bookmark,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(ben);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
                else if (re.type === ProjectObjectType.Draft) {
                    try {
                        const bdetail = JSON.parse(pboxopen);
                        const ben = {
                            name: bdetail.name,
                            type: ProjectObjectType.Draft,
                            id: re.id,
                            updatetime: re.updatetime,
                            accesskey: bdetail.accesskey,
                            draftreference: bdetail.reference,
                            drafttype: bdetail.type,
                            draftsize: 0,
                            parentid: re.parentid,
                            accessids: re.accessids,
                            metadata: fld
                        };
                        ret.push(ben);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        return ret;
    }
}
export const ProjectStore = new Store();
