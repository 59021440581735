import { BEvent } from "@/events/BackgroundEvents";
import { AppState } from "@/share/AppState";
export var DataKey;
(function (DataKey) {
    DataKey["UserName"] = "username";
    DataKey["TenantId"] = "tenantid";
    DataKey["UserId"] = "userid";
    DataKey["UserIdWithTenant"] = "useridtenant";
    DataKey["SessionId"] = "sessionid";
    DataKey["MainPassword"] = "mainpassword";
    DataKey["SessionCommKey"] = "sessioncommkey";
    DataKey["IndexKey"] = "indexkey";
    DataKey["UserKeys"] = "userkeys";
    DataKey["LetrsPasswords"] = "letrpasswords";
    DataKey["UserPassword"] = "userpassword";
    DataKey["UserEmail"] = "useremail";
})(DataKey || (DataKey = {}));
class InMemoryData {
    constructor() {
        this.data = new Map();
        this.letrPasswordMap = new Map();
        this.oletrMap = new Map();
        this.roletrMap = new Map();
        this.letrAccessMap = new Map();
        this.letrsearchbox = new Map();
        this.matrixbox = [];
        BEvent.addSubscriberForAppState((state) => {
            if (state === AppState.Register || state === AppState.Logout) {
                this.reset();
            }
        });
    }
    store(key, value) {
        const sdata = { data: value };
        this.data.set(key, JSON.stringify(sdata));
    }
    get(key) {
        const value = this.data.get(key);
        if (value !== undefined) {
            const ad = JSON.parse(value);
            return ad.data;
        }
        return undefined;
    }
    setMasterPassword(pass) {
        this.store(DataKey.MainPassword, pass);
    }
    setUserKeys(locker) {
        this.store(DataKey.UserKeys, locker);
    }
    setUserName(name) {
        this.store(DataKey.UserName, name);
    }
    setUserPassword(password) {
        this.store(DataKey.UserPassword, password);
    }
    setUserid(userid) {
        this.store(DataKey.UserId, userid);
    }
    setSessionId(sid) {
        this.store(DataKey.SessionId, sid);
    }
    setTenantId(tid) {
        this.store(DataKey.TenantId, tid);
    }
    setUserWithtenant(uid) {
        this.store(DataKey.UserIdWithTenant, uid);
    }
    setEmail(email) {
        this.store(DataKey.UserEmail, email);
    }
    getEmail() {
        return this.get(DataKey.UserEmail);
    }
    setCommKey(key) {
        this.store(DataKey.SessionCommKey, key);
    }
    setUserIndexKey(key) {
        this.store(DataKey.IndexKey, key);
    }
    setLetrSearchBox(id, sbox) {
        this.letrsearchbox.set(id, sbox);
    }
    getLetrSearchBox(id) {
        return this.letrsearchbox.get(id);
    }
    setLetrOid(id, sbox) {
        this.oletrMap.set(id, sbox);
        this.roletrMap.set(sbox, id);
    }
    getLetridFromOrigId(oid) {
        return this.roletrMap.get(oid);
    }
    getLetrOid(id) {
        return this.oletrMap.get(id);
    }
    setletrPassword(id, password) {
        this.letrPasswordMap.set(id, password);
    }
    getLetrPassword(id) {
        return this.letrPasswordMap.get(id);
    }
    setletrAccess(id, password) {
        this.letrAccessMap.set(id, password);
    }
    getIndexKey() {
        return this.get(DataKey.IndexKey);
    }
    getLetrAccess(id) {
        return this.letrAccessMap.get(id);
    }
    getUserPassword() {
        return this.get(DataKey.UserPassword);
    }
    getMasterPassword() {
        return this.get(DataKey.MainPassword);
    }
    getCommKey() {
        return this.get(DataKey.SessionCommKey);
    }
    getuserId() {
        return this.get(DataKey.UserId);
    }
    getName() {
        return this.get(DataKey.UserName);
    }
    getUserIDWithTenant() {
        return this.get(DataKey.UserIdWithTenant);
    }
    getTenant() {
        return this.get(DataKey.TenantId);
    }
    getUserLocker() {
        return this.get(DataKey.UserKeys);
    }
    getSessionId() {
        return this.get(DataKey.SessionId);
    }
    reset() {
        this.data = new Map();
        this.letrPasswordMap = new Map();
    }
}
export const InMemoryRepo = new InMemoryData();
