import appconfig from "@/config/Config.json";
class Config {
    constructor() {
        this.tenantid = appconfig.tenantid;
        this.appurl = appconfig.appurl;
        this.wssurl = appconfig.wssurl;
        this.appextension = appconfig.appextension;
        this.root = "/letrbox";
        this.admin = this.appurl + this.root + "/admin.html";
        this.iconpath = "assets/letrwing.png";
        this.callsound = "assets/call.mp3";
        // public readonly isdev = appconfig.isdev;
        this.appName = "LetrWing";
    }
}
export const AppConfig = new Config();
