var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as Regression from "regression";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from "pdfmake/build/pdfmake";
import { AppColor, FontSize } from "../const/AppDefaults";
import { ArcElement, BarController, BarElement, CategoryScale, Chart, DoughnutController, Filler, Legend, LineController, LineElement, LinearScale, PieController, PointElement, Tooltip } from "chart.js";
import { ProjectObjectType } from "@@/Project";
import { BeatLoader, FadeLoader, ScaleLoader } from 'react-spinners';
import { Box, Button, Checkbox, Flex, FormControl, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, Link, Spinner, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { ChoiceType, CustomFilterType, SchemaItemType, ViewType } from "@@/Schema";
import { Clipboard, color } from '@/util/Util';
import { Select } from "chakra-react-select";
import { Select as ChakraSelect } from "@chakra-ui/react";
import { Observer, observer } from "mobx-react";
import { StoreContext } from "../uistore/PwaRootStore";
import React, { Suspense } from 'react';
import { ReactTree } from "@naisutech/react-tree";
import { SearchMetadataOp } from "@@/ModuleJob";
import { TableVirtuoso, Virtuoso } from "react-virtuoso";
import { convertRGBStringToRGB, isIpadScreen, numberToWord, stripHTML, testIsMobile } from "@@/Util";
import { getDisplayForID, sortEntriesByRow, timeout } from "@@/RuleParser";
import { BarcodeScanner } from "../UIComponents/BarcodeScanner";
import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { BiBarcodeReader } from "@react-icons/all-files/bi/BiBarcodeReader";
import { DateLib } from "@@/Datelib";
import DatePicker from "react-datepicker";
import { FaClipboard } from "@react-icons/all-files/fa/FaClipboard";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FcClearFilters } from "@react-icons/all-files/fc/FcClearFilters";
import { FcDataSheet } from "@react-icons/all-files/fc/FcDataSheet";
import { FcDocument } from "@react-icons/all-files/fc/FcDocument";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { GrGrid } from '@react-icons/all-files/gr/GrGrid';
import { HiDocumentReport } from "@react-icons/all-files/hi/HiDocumentReport";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { MdError } from "@react-icons/all-files/md/MdError";
import { MdRefresh } from "@react-icons/all-files/md/MdRefresh";
import { MdSave } from "@react-icons/all-files/md/MdSave";
import { MdSearch } from "@react-icons/all-files/md/MdSearch";
import { MdViewColumn } from "@react-icons/all-files/md/MdViewColumn";
import { PopupImageThumb } from "./PopupImageThumb";
import { PwaEditForm } from "./PwaEditForm";
import PwaFilterPanel from "../UIComponents/PwaFilterPanel";
import { PwaForm } from "./PwaForm";
import { PwaPdfPageStore } from "../UIComponents/PwaPdfPageStore";
import { PwaRemotePopupView } from "./PwaRemotePopupView";
import PwaSortPanel from "../UIComponents/PwaSortPanel";
import { RemotePopupViewStore } from "../uistore/RemotePopupViewStore";
import { ThumbNailStore } from "../uistore/ThumbNailStore";
import { TiTick } from "@react-icons/all-files/ti/TiTick";
import htmlToPdfmake from "html-to-pdfmake";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { PwaCustomDisplayItemUI } from "../UIComponents/PwaCustomDisplayItem";
import { MdInfo } from "@react-icons/all-files/md/MdInfo";
import { GrDrag } from "@react-icons/all-files/gr/GrDrag";
const ReactQuill = React.lazy(async () => await import("react-quill"));
const XLSX = await import("xlsx-js-style");
import Timeline from 'react-calendar-timeline';
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { PwaDetail } from "./PwaDetail";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { PwaAiForm } from "./PwaAiForm";
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;
const pmake = pdfMake;
pmake.vfs = pdfFonts.pdfMake.vfs;
pmake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    },
    Bookman: {
        normal: 'Bookman Old Style Regular.ttf',
        bold: 'Bookman Old Style Bold.ttf',
        italics: 'Bookman Old Style Cyr Italic.ttf',
        bolditalics: 'Bookman Old Style Bold Italic.ttf'
    }
};
const CHView = React.lazy(async () => await import("../UIComponents/ChartView"));
Chart.register(BarController, CategoryScale, LinearScale, BarElement, PieController, DoughnutController, ArcElement, Legend, LineController, LineElement, PointElement, Tooltip, Filler, ChartDataLabels);
Chart.defaults.plugins.legend.display = true;
Chart.defaults.plugins.tooltip.enabled = true;
const cardcolors = [
    ["#0a447d", "#adbaff"],
    ["#0a447d", "#eafbf9"]
];
const popupcolors = [
    ["#0a447d", "#f0f2ff"],
    ["#0a447d", "#f9fffe"]
];
const viewheight = () => window.innerHeight - 330;
const isMobile = () => window.innerWidth < 650;
let PwaRowsView = class PwaRowsView extends React.Component {
    constructor(prop) {
        super(prop);
        this.updatestimeout = undefined;
        this.virtref = React.createRef();
        this.workingcode = 0;
        this.dragging = false;
        this.dragid = "";
        this.startleft = 0;
        this.mcb = () => {
            this.dragging = false;
            this.dragid = "";
        };
    }
    componentDidMount() {
        const rstore = this.context;
        if (!rstore.getPwaStore().currentpermissions.has("viewrows") && rstore.getPwaStore().currentpermissions.has("addrow")) {
            rstore.getPwaStore().setAddNewViewEntry(true);
        }
        window.addEventListener("mouseup", this.mcb);
        const view = localStorage.getItem(rstore.getPwaStore().selecteditemid + "_view");
        if (view !== null) {
            rstore.getPwaStore().setActiveDisplayView(view);
        }
        else {
            rstore.getPwaStore().setActiveDisplayView('grid');
        }
    }
    componentWillUnmount() {
        if (this.updatestimeout) {
            clearTimeout(this.updatestimeout);
        }
        window.removeEventListener("mouseup", this.mcb);
    }
    showScrollToEditElementButton(isc) {
        var _a;
        const rstore = this.context;
        if (rstore.getPwaStore().pwaeditstore.activerowid === '')
            return;
        if (isc) {
            //   rstore.getPwaStore().setShowScrollToButton(false);
        }
        else {
            const sidv = document.getElementById("scrollableDiv");
            const srect = sidv === null || sidv === void 0 ? void 0 : sidv.getBoundingClientRect();
            // we okay here! we never render lot of items so this shouldbe fairly quick
            const item = document.getElementById("editform");
            let founditem;
            let prevtop = -1;
            const comval = ((_a = srect === null || srect === void 0 ? void 0 : srect.top) !== null && _a !== void 0 ? _a : 120) + 10;
            if (item) {
                const rect = item.getBoundingClientRect();
                const top = rect.top;
                // if (top > (comval + 5) || top < (comval - 5)) {
                //     rstore.getPwaStore().setShowScrollToButton(true);
                // } else {
                //     rstore.getPwaStore().setShowScrollToButton(false);
                // }
            }
        }
    }
    sort(r1, r2) {
        const rstore = this.context;
        // if (rstore.getPwaStore().sortdialogstate.valuesbyid.size > 0) {
        //     const smd: SortMetaData = Array.from(rstore.getPwaStore().sortdialogstate.valuesbyid.values())[0];
        //     let en1: Entry | undefined = undefined;
        //     let en2: Entry | undefined = undefined;
        //     for (let i=0; i< r1.entry.length; i++){
        //         const e = r1.entry[i]
        //         console.log(e.cellid+ " " + smd.id);
        //         if (e.cellid === smd.id) {
        //             en1 = e;
        //             break;
        //         }
        //     }
        //     for (let i=0; i< r2.entry.length; i++){
        //         const e = r2.entry[i]
        //         console.log(e.cellid+ " " + smd.id);
        //         if (e.cellid === smd.id) {
        //             en2 = e;
        //             break;
        //         }
        //     }
        //     if (en1 !== undefined && en2 !== undefined) {
        //         const comp = en1.celldata.toLowerCase().localeCompare(en2.celldata.toLowerCase());
        //         if (comp === 0) {
        //             return r1.rowindex - r2.rowindex;
        //         }
        //         return comp;
        //     }
        // }
        // then we should use customer sorter!
        let csr = rstore.getPwaStore().customSorter;
        if (csr) {
            return csr(r1.entry, r2.entry);
        }
        return r1.rowindex - r2.rowindex;
    }
    renderHeader() {
        const rstore = this.context;
        const items = rstore.getPwaStore().currentitems.slice().
            sort((it1, it2) => it1.orderindex - it2.orderindex).filter((fld) => !rstore.getPwaStore().hiddencolumns.has(fld.id));
        ;
        const rowsld = rstore.getPwaStore().rowsMap.size;
        let stickyleft = 96;
        return React.createElement("tr", null,
            rowsld > 0 && items.length > 0 &&
                React.createElement("th", { style: { width: 50, position: 'sticky', left: 0, backgroundColor: '#839eb8' } }, (rstore.getPwaStore().currentpermissions.has("editrow") || rstore.getPwaStore().currentpermissions.has("addrow")) &&
                    React.createElement(Checkbox, { isChecked: rstore.getPwaStore().pwaeditstore.selectallrows, onChange: (e) => {
                            if (e.currentTarget.checked) {
                                rstore.getPwaStore().selectAllRow();
                            }
                            else {
                                rstore.getPwaStore().unselectAllRow();
                            }
                        } })),
            rowsld > 0 && React.createElement("th", { style: {
                    'position': 'sticky',
                    left: 46,
                    backgroundColor: '#839eb8'
                } }),
            rowsld > 0 && items.map((item) => {
                var _a, _b;
                let width = (_a = rstore.getPwaStore().columnwidths.get(item.id)) !== null && _a !== void 0 ? _a : 300;
                ;
                ;
                let ref = null;
                let subitemwidth = 150;
                let llength = 0;
                if (item.type === SchemaItemType.List && item.items && item.items.length > 0) {
                    llength = item.items.length;
                    width = llength * 150;
                    if (width < 250) {
                        width = 250;
                    }
                    subitemwidth = width / llength;
                }
                let myleft = stickyleft;
                if (item.freese) {
                    stickyleft += width;
                }
                return React.createElement("th", { ref: (re) => ref = re, key: item.id, style: {
                        minWidth: (_b = rstore.getPwaStore().columnwidths.get(item.id)) !== null && _b !== void 0 ? _b : 300,
                        'position': item.freese ? 'sticky' : undefined,
                        left: item.freese ? myleft : undefined,
                        width: width, backgroundColor: item.freese ? "#839eb8" :
                            'whiteAlpha.500', borderRightWidth: '1px',
                        borderRightColor: '#d3dbe2'
                    } },
                    React.createElement(Flex, { align: 'center', w: width + "px", pos: 'relative', justify: (item.type === SchemaItemType.Choice || item.type === SchemaItemType.UserChoice
                            || item.type === SchemaItemType.Date || item.type === SchemaItemType.List || item.type === SchemaItemType.Boolean) ?
                            'center' : 'flex-start', paddingRight: item.type === SchemaItemType.Number ? '5px' : undefined },
                        item.type !== SchemaItemType.List && React.createElement(Box, { style: { position: 'absolute', right: '-12px', width: 20, height: '40px' }, _hover: { cursor: 'ew-resize' }, onMouseDown: (e) => {
                                this.dragging = true;
                                this.dragid = item.id;
                                this.startleft = e.clientX;
                            }, onMouseMove: (e) => {
                                var _a;
                                if (this.dragging && this.dragid === item.id) {
                                    const culef = e.clientX;
                                    const diff = culef - this.startleft;
                                    const wd = (_a = rstore.getPwaStore().columnwidths.get(item.id)) !== null && _a !== void 0 ? _a : 300;
                                    let nw = wd + diff;
                                    if (nw < 50) {
                                        nw = 50;
                                    }
                                    rstore.getPwaStore().setColWidth(item.id, nw);
                                }
                            }, onMouseUp: (e) => {
                                this.dragging = false;
                                this.dragid = "";
                            } },
                            React.createElement(Icon, { as: GrDrag, h: '35px' })),
                        React.createElement(Text, null, item.name)),
                    item.type === SchemaItemType.List && item.items && item.items.length > 0 && React.createElement(Flex, { marginTop: '5px', width: width + "px" }, item.items.map((it, index) => React.createElement(Flex, { key: index, width: subitemwidth + "px", align: 'center', justify: (item.type === SchemaItemType.Choice || item.type === SchemaItemType.UserChoice
                            || item.type === SchemaItemType.Date || item.type === SchemaItemType.List || item.type === SchemaItemType.Boolean) ?
                            'center' : 'flex-start', paddingRight: item.type === SchemaItemType.Number ? '5px' : undefined },
                        React.createElement(Text, null, it.item.name)))));
            }),
            ["Last Modified"].map(lm => {
                var _a, _b;
                let width = (_a = rstore.getPwaStore().columnwidths.get(lm)) !== null && _a !== void 0 ? _a : 300;
                let ref = null;
                let subitemwidth = 150;
                return React.createElement("th", { ref: (re) => ref = re, key: lm, style: {
                        minWidth: (_b = rstore.getPwaStore().columnwidths.get(lm)) !== null && _b !== void 0 ? _b : 300,
                        'position': undefined,
                        left: undefined,
                        width: width, backgroundColor: 'whiteAlpha.500', borderRightWidth: '1px',
                        borderRightColor: '#d3dbe2'
                    } },
                    React.createElement(Flex, { align: 'center', w: width + "px", pos: 'relative', justify: 'center' },
                        React.createElement(Box, { style: { position: 'absolute', right: '-12px', width: 20, height: '40px' }, _hover: { cursor: 'ew-resize' }, onMouseDown: (e) => {
                                this.dragging = true;
                                this.dragid = lm;
                                this.startleft = e.clientX;
                            }, onMouseMove: (e) => {
                                var _a;
                                if (this.dragging && this.dragid === lm) {
                                    const culef = e.clientX;
                                    const diff = culef - this.startleft;
                                    const wd = (_a = rstore.getPwaStore().columnwidths.get(lm)) !== null && _a !== void 0 ? _a : 300;
                                    let nw = wd + diff;
                                    if (nw < 50) {
                                        nw = 50;
                                    }
                                    rstore.getPwaStore().setColWidth(lm, nw);
                                }
                            }, onMouseUp: (e) => {
                                this.dragging = false;
                                this.dragid = "";
                            } },
                            React.createElement(Icon, { as: GrDrag, h: '35px' })),
                        React.createElement(Text, null, lm)));
            }));
    }
    renderRow(row, index) {
        return React.createElement(SchemaRowUI, { key: Math.random() + "", row: row, index: index + 1 });
    }
    async quickFilter() {
        const rstore = this.context;
        const items = rstore.getPwaStore().getAllFields().slice();
        const ditems = items.filter((itm) => itm.type !== SchemaItemType.List &&
            !rstore.getPwaStore().hiddencolumns.has(itm.id)).
            sort((it1, it2) => it1.orderindex - it2.orderindex);
        const itemMap = new Map();
        ditems.map((item) => itemMap.set(item.id, item));
        const idlist = rstore.getPwaStore().filterdialogstate.idlist;
        const selecteditems = idlist.split(",");
        let colsToSearch = selecteditems;
        let allcols = false;
        const allnrcols = [];
        const rulecols = [];
        for (const itm of ditems) {
            if (!rstore.getPwaStore().checkSchemaCellHasRule(itm.id)) {
                allnrcols.push(itm.id);
            }
        }
        if (selecteditems.length === 1 && selecteditems[0] === '_allnr') {
            colsToSearch = allnrcols;
        }
        for (const it of colsToSearch) {
            const item = itemMap.get(it);
            if (item !== undefined) {
                const sitem = {
                    id: item.id,
                    value: rstore.getPwaStore().filterdialogstate.searchstr,
                    value2: '',
                    fielditem: item,
                    v2float: -1,
                    vfloat: -1,
                    optype: SearchMetadataOp.Equal,
                    numbercompare: false
                };
                rstore.getPwaStore().filterdialogstate.addValue(item.id, sitem);
            }
        }
        rstore.getPwaStore().unselectAllRow();
        rstore.getPwaStore().pwaeditstore.setActiveRowId("");
        rstore.getPwaStore().pwaeditstore.setSelectedId("");
        rstore.getPwaStore().setSearchActive(true);
        rstore.wdialog.setMessage("Filtering rows...");
        await rstore.getPwaStore().slowFilterRows(true, () => {
            rstore.wdialog.setOpen(true);
        }, (msg) => rstore.wdialog.setMessage(msg));
        rstore.wdialog.setOpen(false);
        rstore.getPwaStore().setShowRowFilter(false);
        rstore.getPwaStore().pwaeditstore.setSelectedId('');
        rstore.getPwaStore().selectFirstRow();
    }
    quickFilter_OLD() {
        const rstore = this.context;
        const idlist = rstore.getPwaStore().filterdialogstate.idlist;
        const selecteditems = idlist.split(",");
        const items = rstore.getPwaStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        const ditems = items.filter((item) => !rstore.getPwaStore().hiddencolumns.has(item.id));
        const itemMap = new Map();
        ditems.map((item) => itemMap.set(item.id, item));
        for (const it of selecteditems) {
            const item = itemMap.get(it);
            if (item !== undefined) {
                const sitem = {
                    id: item.id,
                    value: rstore.getPwaStore().filterdialogstate.searchstr,
                    value2: '',
                    fielditem: item,
                    v2float: -1,
                    vfloat: -1,
                    optype: SearchMetadataOp.Equal,
                    numbercompare: false
                };
                rstore.getPwaStore().filterdialogstate.addValue(item.id, sitem);
            }
        }
        rstore.getPwaStore().unselectAllRow();
        rstore.getPwaStore().pwaeditstore.setActiveRowId("");
        rstore.getPwaStore().filterRows(true);
    }
    render() {
        const rstore = this.context;
        const items = rstore.getPwaStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        const rowsarr = [];
        let srows = Array.from(rstore.getPwaStore().uirows.values()).filter((r) => !rstore.getPwaStore().hiddenrows.has(r.rowid)).slice().sort((r1, r2) => this.sort(r1, r2));
        const cardview = rstore.getPwaStore().activedisplayview === 'card';
        const lcount = rstore.getPwaStore().getCountOfItemsSaving();
        const isview = rstore.getPwaStore().selecteditemid.indexOf("web-") === 0;
        let showhistory = undefined;
        if (!isview) {
            const form = rstore.getPwaStore().activeforms.get(rstore.getPwaStore().selecteditemid);
            if (form) {
                showhistory = form.history;
            }
        }
        else {
            const view = rstore.getPwaStore().getActiveWebview();
            if (view && view.reverseorder) {
                srows = srows.reverse();
            }
        }
        for (let i = 0; i < srows.length; i = (window.innerWidth < 1100) ? i + 1 : i + 2) {
            const dr = [];
            const sr = srows[i];
            sr.uirowindex = i;
            dr.push(sr);
            if ((window.innerWidth >= 1100) && (i + 1) < srows.length) {
                srows[i + 1].uirowindex = i + 1;
                dr.push(srows[i + 1]);
            }
            rowsarr.push(dr);
        }
        const searchoptitems = rstore.getPwaStore().getAllFields().slice();
        const ditems = searchoptitems.filter((itm) => !rstore.getPwaStore().hiddencolumns.has(itm.id)
            && itm.type !== SchemaItemType.List);
        let searchopts = [];
        searchopts.push({
            id: '_allnr',
            name: 'All non-rule columns',
            type: ChoiceType.Text
        });
        ditems.forEach((itm) => {
            searchopts.push({
                id: itm.id,
                name: itm.name,
                type: ChoiceType.Text
            });
        });
        return React.createElement(Box, { margin: 'auto', marginTop: '10px', w: window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100, h: '100%' },
            React.createElement(PopupImageThumb, null),
            React.createElement(PwaRemotePopupView, null),
            React.createElement(CustomInfoPanel, null),
            rstore.getPwaStore().showrowsilter && React.createElement(FilterView, { displayitems: items }),
            rstore.getPwaStore().showssortdialog && React.createElement(SortPanel, { displayitems: items }),
            rstore.getPwaStore().pwaeditstore.activerowid !== "" && rstore.getPwaStore().pwaeditstore.show &&
                (rstore.getPwaStore().currentpermissions.has("editrow") || rstore.getPwaStore().customrowjs.trim()) &&
                // !cardview && !isMobile() &&
                React.createElement(EditRowView, { items: items }),
            rstore.getPwaStore().showcolumnview && React.createElement(ColumnsView, null),
            this.props.showheader && React.createElement(Box, { bg: '#dedfe6', px: 4 },
                React.createElement(Flex, { w: '100%', justify: 'center', paddingTop: '5px', alignItems: 'center' },
                    React.createElement(Text, { fontWeight: 'bold' }, rstore.getPwaStore().activeviewtitle)),
                React.createElement(Flex, { h: 16, alignItems: 'center' },
                    React.createElement(Flex, { alignItems: 'center', _hover: { cursor: 'pointer' }, onClick: () => {
                            let hash = window.location.hash;
                            if (rstore.getPwaStore().selecteditemid.indexOf("web-") === 0) {
                                if (rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().currentpermissions.has("viewrows")) {
                                    rstore.getPwaStore().setAddNewViewEntry(false);
                                    return;
                                }
                                if (rstore.getPwaStore().addnewviewentryviaai && rstore.getPwaStore().currentpermissions.has("viewrows")) {
                                    rstore.getPwaStore().setAddNewViewEntryViaAi(false);
                                    return;
                                }
                                if (!rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().pwaeditstore.activerowid !== "" && rstore.getPwaStore().currentpermissions.has("editrow")) {
                                    rstore.getPwaStore().pwaeditstore.setActiveRowId("");
                                    rstore.getPwaStore().cancelEntryEdit();
                                    return;
                                }
                                rstore.getPwaStore().currentroute = rstore.getPwaStore().parseHash(window.location.hash);
                                window.history.back();
                                return;
                            }
                            hash = hash.replace("~~~detail", "");
                            window.history.replaceState({}, document.title, hash);
                            rstore.getPwaStore().setShowDetail(false);
                        } },
                        React.createElement(Icon, { as: BiArrowBack })),
                    (!rstore.getPwaStore().displayjs.trim() || rstore.getPwaStore().keepbuttonwithcustomview) && (rstore.getPwaStore().addnewviewentry || rstore.getPwaStore().uirows.size === 0) && rstore.getPwaStore().pwaeditstore.activerowid === "" &&
                        React.createElement(Flex, { ml: '10px', flexDir: 'column', alignItems: 'center', _hover: { cursor: 'pointer' }, onClick: async () => {
                                if (rstore.getPwaStore().formentry.size === 0 && rstore.getPwaStore().objectselectedfiles.size === 0) {
                                    // we need reporting here!
                                    return;
                                }
                                await rstore.getPwaStore().createFormEntryJob();
                                rstore.getPwaStore().setAddNewViewEntry(false);
                                rstore.getPwaStore().pwaeditstore.clearActiveRow();
                                rstore.getPwaStore().pwaeditstore.setSelectedId('');
                            } },
                            React.createElement(Icon, { w: '30px', h: '25px', _hover: { cursor: 'pointer' }, as: MdSave }),
                            React.createElement(Text, null, "Save")),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && (rstore.getPwaStore().addnewviewentry || rstore.getPwaStore().uirows.size === 0) && rstore.getPwaStore().pwaeditstore.activerowid === "" &&
                        React.createElement(Flex, { ml: '10px', onClick: () => rstore.getPwaStore().clearActiveEdits(), flexDir: 'column', alignItems: 'center', justify: 'center', _hover: { cursor: 'pointer' } },
                            React.createElement(Icon, { w: '30px', h: '25px', as: MdRefresh }),
                            React.createElement(Text, null, "Reset")),
                    (!rstore.getPwaStore().displayjs.trim() || rstore.getPwaStore().keepbuttonwithcustomview) && isview
                        && !rstore.getPwaStore().addnewviewentryviaai
                        && !rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().uirows.size > 0 && rstore.getPwaStore().pwaeditstore.activerowid === "" &&
                        rstore.getPwaStore().currentpermissions.has("addrow") &&
                        rstore.getPwaStore().pwaeditstore.selectedrows.size === 0 &&
                        React.createElement(Flex, { alignItems: 'center', justify: 'center', _hover: { cursor: 'pointer' }, flexDir: 'column', ml: '10px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Create', onClick: () => {
                                    rstore.getPwaStore().clearActiveEdits();
                                    rstore.getPwaStore().setAddNewViewEntry(true);
                                } },
                                React.createElement(Icon, { w: '22px', h: '22px', _hover: { cursor: 'pointer' }, as: FaPlus, color: AppColor.ActionMenuIcon, onClick: () => {
                                        rstore.getPwaStore().clearActiveEdits();
                                        rstore.getPwaStore().setAddNewViewEntry(true);
                                    } })),
                            React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, "Create")),
                    (!rstore.getPwaStore().displayjs.trim() || rstore.getPwaStore().keepbuttonwithcustomview) && isview && !rstore.getPwaStore().addnewviewentry && !rstore.getPwaStore().addnewviewentryviaai && rstore.getPwaStore().uirows.size > 0 && rstore.getPwaStore().pwaeditstore.activerowid === "" &&
                        rstore.getPwaStore().currentpermissions.has("addrow") &&
                        rstore.getPwaStore().pwaeditstore.selectedrows.size === 0 && rstore.getPwaStore().showaicreate &&
                        React.createElement(Flex, { alignItems: 'center', justify: 'center', _hover: { cursor: 'pointer' }, flexDir: 'column', ml: '10px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'AI Assist', onClick: () => {
                                    rstore.getPwaStore().clearActiveEdits();
                                    rstore.getPwaStore().setAddNewViewEntryViaAi(true);
                                } },
                                React.createElement(Icon, { w: '22px', h: '22px', _hover: { cursor: 'pointer' }, as: FaPlus, color: AppColor.ActionMenuIcon, onClick: () => {
                                        rstore.getPwaStore().clearActiveEdits();
                                        rstore.getPwaStore().setAddNewViewEntryViaAi(true);
                                    } })),
                            React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, "AI Assist")),
                    !rstore.getPwaStore().fetchingupdates && isview
                        && !rstore.getPwaStore().addnewviewentryviaai
                        && !rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().pwaeditstore.activerowid === ""
                        && rstore.getPwaStore().currentpermissions.has("viewrows")
                        && rstore.getPwaStore().pwaeditstore.selectedrows.size === 0 && rstore.getPwaStore().uirows.size > 0 &&
                        React.createElement(Flex, { flexDir: 'column', alignItems: 'center', ml: '10px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Refresh', onClick: () => {
                                    rstore.getPwaStore().loadRowsForActiveForm(0);
                                } },
                                React.createElement(Icon, { w: '22px', h: '22px', _hover: { cursor: 'pointer' }, as: MdRefresh, color: AppColor.ActionMenuIcon, ml: '1px' })),
                            React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, "Refresh")),
                    isview && !rstore.getPwaStore().fetchingupdates && isview
                        && !rstore.getPwaStore().addnewviewentryviaai
                        && !rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().uirows.size > 0
                        && rstore.getPwaStore().pwaeditstore.activerowid === "" && rstore.getPwaStore().currentpermissions.has("viewrows") &&
                        rstore.getPwaStore().pwaeditstore.selectedrows.size === 0 &&
                        React.createElement(Flex, { align: 'center', justify: 'center', flexDir: 'column', ml: '10px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Filter', onClick: (e) => {
                                    e.stopPropagation();
                                    // lets reset the search filter here !
                                    rstore.getPwaStore().filterdialogstate.clear();
                                    rstore.getPwaStore().setSearchActive(false);
                                    rstore.getPwaStore().showAllRows();
                                    rstore.getPwaStore().setShowRowFilter(!rstore.getPwaStore().showrowsilter);
                                } }, React.createElement(Icon, { w: '22px', h: '22px', color: !rstore.getPwaStore().searchactive && (rstore.getPwaStore().filterdialogstate.valuesbyid.size > 0) ? 'green' :
                                    AppColor.ActionMenuIcon, as: FaFilter, ml: '1px' })),
                            React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, 'Filter')),
                    !rstore.getPwaStore().searchactive && (rstore.getPwaStore().filterdialogstate.valuesbyid.size > 0) &&
                        React.createElement(Icon, { w: '22px', h: '22px', onClick: (e) => {
                                e.stopPropagation();
                                rstore.getPwaStore().filterdialogstate.clear();
                                rstore.getPwaStore().setShowRowFilter(false);
                                rstore.getPwaStore().showAllRows();
                            }, _hover: { cursor: 'pointer' }, color: AppColor.DeleteIconColor, as: MdClose, ml: '5px' }),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && isview
                        && !rstore.getPwaStore().addnewviewentryviaai
                        && !rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().pwaeditstore.activerowid === ""
                        && rstore.getPwaStore().currentpermissions.has("viewrows") && rstore.getPwaStore().pwaeditstore.selectedrows.size === 0 &&
                        React.createElement(Flex, { align: 'center', justify: 'center', flexDir: 'column', ml: '10px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Reports', onClick: (e) => {
                                    rstore.getPwaStore().addReportToHash();
                                    rstore.getPwaStore().setShowReport(true);
                                } },
                                React.createElement(Icon, { w: '22px', h: '22px', color: AppColor.ActionMenuIcon, as: HiDocumentReport, ml: '1px' })),
                            React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, 'Reports')),
                    !rstore.getPwaStore().addnewviewentryviaai
                        &&
                            !rstore.getPwaStore().addnewviewentry && React.createElement(Observer, null, () => React.createElement(ChakraSelect, { size: 'md', width: '100px', onChange: (e) => {
                            rstore.getPwaStore().setActiveDisplayView(e.currentTarget.value);
                        }, defaultValue: rstore.getPwaStore().activedisplayview },
                        React.createElement("option", { value: 'grid', key: 'grid' }, 'Grid'),
                        React.createElement("option", { value: 'card', key: 'card' }, 'Card'),
                        React.createElement("option", { value: 'twopanel', key: 'twopanel' }, 'Panel'))),
                    !isMobile() && !rstore.getPwaStore().addnewviewentryviaai
                        &&
                            !rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().pwaeditstore.activerowid === "" &&
                        (!isview || rstore.getPwaStore().activeview === ViewType.Grid) &&
                        (rstore.getPwaStore().currentpermissions.has("editrow") || rstore.getPwaStore().currentpermissions.has("addrow"))
                        && !cardview && rstore.getPwaStore().pwaeditstore.selectedrows.size > 0 &&
                        React.createElement(Flex, { align: 'center', justify: 'center', flexDir: 'column', ml: '10px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Delete selected row(s)', _hover: { cursor: 'pointer' }, onClick: () => {
                                    rstore.confirmStore.setMessage("Are you sure you want to delete " + rstore.getPwaStore().pwaeditstore.selectedrows.size + "entries?");
                                    rstore.confirmStore.setOkCB(async () => {
                                        await rstore.getPwaStore().deleteRows();
                                        rstore.getPwaStore().pwaeditstore.reset();
                                    });
                                    rstore.confirmStore.setOpen(true);
                                } },
                                React.createElement(Icon, { w: '22px', h: '22px', color: AppColor.ActionMenuIcon, as: MdDelete, ml: '1px' })),
                            React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, 'Delete')),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && !isMobile()
                        && !rstore.getPwaStore().addnewviewentryviaai
                        &&
                            !rstore.getPwaStore().addnewviewentry && rstore.getPwaStore().pwaeditstore.activerowid === "" &&
                        (!isview || rstore.getPwaStore().activeview === ViewType.Grid) && rstore.getPwaStore().activedisplayview === 'grid' &&
                        rstore.getPwaStore().uirows.size > 0 &&
                        React.createElement(Box, { pos: 'relative' },
                            React.createElement(Flex, { align: 'center', justify: 'center', flexDir: 'column', ml: '15px' },
                                React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Show Columns', onClick: (e) => {
                                        e.stopPropagation();
                                        const rect = e.currentTarget.getBoundingClientRect();
                                        const fx = rect.x;
                                        const fy = rect.y;
                                        rstore.getPwaStore().cfx = fx;
                                        rstore.getPwaStore().cfy = fy;
                                        rstore.getPwaStore().setShowColumns(!rstore.getPwaStore().showcolumnview);
                                    } },
                                    React.createElement(Icon, { w: '22px', h: '22px', color: AppColor.ActionMenuIcon, as: MdViewColumn, ml: '1px' })),
                                React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, 'Columns'))),
                    rstore.getPwaStore().custompaneljs != '' && React.createElement(Box, { pos: 'relative' },
                        React.createElement(Flex, { align: 'center', justify: 'center', flexDir: 'column', ml: '15px' },
                            React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Custom Panel', onClick: (e) => {
                                    e.stopPropagation();
                                    rstore.getPwaStore().setShowinfopanelshow(true);
                                } },
                                React.createElement(Icon, { w: '22px', h: '22px', color: AppColor.ActionMenuIcon, as: MdViewColumn, ml: '1px' })),
                            React.createElement(Flex, null,
                                React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, 'Custom Panel')))),
                    lcount > 0 && React.createElement(Flex, { alignItems: 'center', justify: 'center', ml: '4px' },
                        React.createElement(Text, null, "Saving " + lcount + " rows")),
                    rstore.getPwaStore().loadingrows && React.createElement(Flex, { ml: '10px', alignItems: 'center' },
                        React.createElement(Spinner, null)),
                    rstore.getPwaStore().fetchingupdates && React.createElement(Flex, { ml: '10px', alignItems: 'center' },
                        React.createElement(Text, null, "Checking updates..")))),
            React.createElement(Summary, null),
            React.createElement(FormMessage, null),
            !rstore.getPwaStore().addnewviewentry
                && !rstore.getPwaStore().addnewviewentryviaai
                && (rstore.getPwaStore().uirows.size > 0 &&
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview))
                && (showhistory || rstore.getPwaStore().currentpermissions.has("viewrows")) &&
                React.createElement(Box, { pos: 'relative' },
                    rstore.getPwaStore().activeview === ViewType.Grid && (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && React.createElement(Flex, { w: '100%', mt: '10px', mb: '10px', ml: '5px', flexDirection: isMobile() ? 'column' : 'row', width: isMobile() ? window.innerWidth - 50 : '100%' },
                        React.createElement(Flex, { align: 'center', width: isMobile() ? '100%' : 'auto', mr: '20px', mb: isMobile() ? '5px' : '0px' },
                            React.createElement(Text, { fontWeight: 'bold', mr: '10px' }, "Scan"),
                            React.createElement(Icon, { as: BiBarcodeReader, width: '30px', height: '30px', color: AppColor.SideBarIconColor, _hover: { cursor: 'pointer' }, onClick: () => {
                                    const x = 5;
                                    const y = 5;
                                    BarcodeScanner(x, y, (data, type) => {
                                        let rowid = "";
                                        let barcode = "";
                                        if (type === 'qr') {
                                            try {
                                                const obj = JSON.parse(data);
                                                rowid = obj.rowid;
                                            }
                                            catch (e) {
                                                console.log(e);
                                            }
                                        }
                                        else {
                                            barcode = data;
                                        }
                                        rstore.getPwaStore().filterdialogstate.clear();
                                        rstore.getPwaStore().setShowRowFilter(false);
                                        rstore.getPwaStore().showAllRows();
                                        rstore.getPwaStore().filterdialogstate.setScannerActive(true);
                                        rstore.getPwaStore().filterUsingScanner(barcode, rowid);
                                        return true;
                                    });
                                } }),
                            rstore.getPwaStore().filterdialogstate.scanneractive &&
                                React.createElement(Icon, { w: '22px', h: '22px', onClick: (e) => {
                                        e.stopPropagation();
                                        rstore.getPwaStore().filterdialogstate.clear();
                                        rstore.getPwaStore().setShowRowFilter(false);
                                        rstore.getPwaStore().showAllRows();
                                    }, _hover: { cursor: 'pointer' }, color: AppColor.DeleteIconColor, as: MdClose, ml: '5px' })),
                        !rstore.getPwaStore().filterdialogstate.scanneractive && React.createElement(Box, { width: isMobile() ? '100%' : '40%', mb: isMobile() ? '5px' : '0px', zIndex: 20 },
                            React.createElement(ChoiceInput, { id: 'choice', key: Math.random() + "", multiselect: true, schemafieldname: 'item.name', defaultvalue: rstore.getPwaStore().filterdialogstate.idlist, onChange: (ch) => {
                                    const idlisthasall = rstore.getPwaStore().filterdialogstate.idlist;
                                    const chlist = ch.split(',');
                                    let chstr = ch;
                                    if (idlisthasall === '_allnr') {
                                        rstore.getPwaStore().filterdialogstate.setSearchIdList("");
                                        chstr = ch.split(',').filter((id) => id !== '_allnr').join(',');
                                    }
                                    else if (chlist.indexOf('_allnr') !== -1) {
                                        chstr = '_allnr';
                                    }
                                    rstore.getPwaStore().filterdialogstate.setSearchIdList(chstr);
                                }, showbarcodescanner: false, list: searchopts })),
                        !rstore.getPwaStore().filterdialogstate.scanneractive && React.createElement(Flex, { ml: isMobile() ? '0px' : '5px', mt: isMobile() ? '5px' : '0px', width: isMobile() ? '100%' : '45%' },
                            React.createElement(Observer, null, () => React.createElement(Input, { size: 'md', value: rstore.getPwaStore().filterdialogstate.searchstr, onChange: (e) => {
                                    const input = e.currentTarget.value;
                                    rstore.getPwaStore().filterdialogstate.setSearchString(input);
                                }, onKeyDown: async (e) => {
                                    if (e.key === 'Enter') {
                                        if (rstore.getPwaStore().filterdialogstate.searchstr && rstore.getPwaStore().filterdialogstate.idlist) {
                                            rstore.getPwaStore().showAllRows();
                                            await this.quickFilter();
                                        }
                                    }
                                } })),
                            React.createElement(IconButton, { ml: '10px', "aria-label": 'Filter', icon: React.createElement(Icon, { w: '20px', h: '20px', as: MdSearch }), onClick: async (e) => {
                                    if (rstore.getPwaStore().filterdialogstate.searchstr && rstore.getPwaStore().filterdialogstate.idlist) {
                                        e.stopPropagation();
                                        rstore.getPwaStore().showAllRows();
                                        await this.quickFilter();
                                    }
                                } }),
                            rstore.getPwaStore().searchactive && rstore.getPwaStore().filterdialogstate.valuesbyid.size > 0 &&
                                React.createElement(Box, { pos: testIsMobile() ? 'inherit' : 'relative' },
                                    React.createElement(Flex, { align: 'center', justify: 'center', flexDir: 'column', ml: '15px' },
                                        React.createElement(Box, { className: 'icon-button-box', padding: '5px', title: 'Reset filter', onClick: (e) => {
                                                e.stopPropagation();
                                                rstore.getPwaStore().filterdialogstate.clear();
                                                rstore.getPwaStore().setShowRowFilter(false);
                                                rstore.getPwaStore().setSearchActive(false);
                                                rstore.getPwaStore().showAllRows();
                                            } },
                                            React.createElement(Icon, { w: '22px', h: '22px', color: AppColor.ActionMenuIcon, as: FcClearFilters, ml: '1px' })),
                                        React.createElement(Text, { fontSize: FontSize.IconTitleFontSize, fontWeight: 'bold' }, 'Reset'))))),
                    (isMobile() || cardview) && (!isview || rstore.getPwaStore().activeview === ViewType.Grid) && React.createElement(Box, { id: "scrollableDiv", pos: 'absolute', top: '15px' }),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && (isMobile() || cardview) && (!isview || rstore.getPwaStore().activeview === ViewType.Grid) && rstore.getPwaStore().uirows.size !== rstore.getPwaStore().hiddenrows.size
                        && rstore.getPwaStore().activedisplayview !== 'twopanel' && React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: viewheight() }, data: rowsarr, ref: this.virtref, 
                        // isScrolling={(isc) => this.showScrollToEditElementButton(isc)}
                        itemContent: (index, row) => React.createElement(RowDisplay, { rows: row, items: items, key: row[0].rowid + "_rowdisplay", virtref: this.virtref }) })),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && !isMobile()
                        && rstore.getPwaStore().activedisplayview === 'twopanel'
                        && rstore.getPwaStore().uirows.size !== rstore.getPwaStore().hiddenrows.size
                        && React.createElement(PanelDisplay, { rowsarr: srows, items: items, virtref: this.virtref }),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && !isMobile() && !cardview && (!isview || rstore.getPwaStore().activeview === ViewType.Grid) && rstore.getPwaStore().uirows.size !== rstore.getPwaStore().hiddenrows.size &&
                        rstore.getPwaStore().activedisplayview === 'grid' &&
                        React.createElement(Box, { w: '100%', h: viewheight(), backgroundColor: '#FFFFFF', className: "datatable" },
                            React.createElement(Observer, null, () => React.createElement(TableVirtuoso, { className: "styled-table " +
                                    (!rstore.getPwaStore().currentpermissions.has("editrow") ? "styled-nohover" : ""), overscan: 50, data: srows, style: { height: viewheight() }, components: {
                                    EmptyPlaceholder: () => {
                                        return (React.createElement("tbody", null,
                                            React.createElement("tr", null,
                                                React.createElement("td", null, "Empty"))));
                                    },
                                    // set the colspan below to the amount of columns you have.
                                    FillerRow: ({ height }) => {
                                        return (React.createElement("tr", null,
                                            React.createElement("td", { colSpan: items.length, style: { height: height, padding: 0, border: 0 } })));
                                    }
                                }, ref: (ref) => rstore.getPwaStore().tableref = ref, fixedHeaderContent: () => React.createElement(Observer, null, () => this.renderHeader()), itemContent: (index, data) => this.renderRow(data, index) }))),
                    (!rstore.getPwaStore().displayjs.trim() || this.props.forcemyview) && rstore.getPwaStore().activeview === ViewType.Chart && React.createElement(Observer, null, () => React.createElement(Box, { w: '100%', h: window.innerHeight - 150 + 'px', overflow: 'auto' }, Array.from(rstore.getPwaStore().chartstate.values()).map((cs) => React.createElement(Box, { key: cs.id, w: '100%', h: '500px' },
                        React.createElement(Flex, { key: cs.id, mt: '5px', w: '100%', h: '450px', align: 'center', justify: 'center', ml: '20px' },
                            React.createElement(Flex, { h: '450px', align: 'center', justify: 'center' },
                                React.createElement(Suspense, { fallback: React.createElement(Flex, { style: { height: '100%' }, justify: 'center', align: 'center' },
                                        React.createElement(ScaleLoader, { color: 'green' })) },
                                    React.createElement(CHView, { state: cs, onclick: (din) => {
                                        } }))))))))),
            (rstore.getPwaStore().displayjs.trim() && !this.props.forcemyview) && React.createElement(SchemaCustomPanel, { viewkey: "mainviewkey", js: rstore.getPwaStore().displayjs.trim() }),
            (((rstore.getPwaStore().filterdialogstate.scanneractive || rstore.getPwaStore().filterdialogstate.valuesbyid.size > 0)
                && rstore.getPwaStore().rowsMap.size === rstore.getPwaStore().hiddenrows.size)
                && isview && rstore.getPwaStore().currentpermissions.has("addrow")) && React.createElement(Box, { w: '100%', h: '100%' },
                React.createElement(Flex, { flexDirection: 'column', h: '100%', align: 'center', justify: 'center' },
                    React.createElement(Icon, { h: '30px', w: '30px', as: FcDataSheet, color: AppColor.SideBarIconColor }),
                    React.createElement(Text, { mt: '15px', color: AppColor.MessageColor }, "No entries found"))),
            (rstore.getPwaStore().addnewviewentry || (((!rstore.getPwaStore().displayjs.trim() && !rstore.getPwaStore().filterdialogstate.scanneractive
                && rstore.getPwaStore().filterdialogstate.valuesbyid.size === 0) && !rstore.getPwaStore().loadingcontents
                && !rstore.getPwaStore().loadingrows && !rstore.getPwaStore().loadingitem
                && rstore.getPwaStore().uirows.size === 0 || !rstore.getPwaStore().currentpermissions.has("viewrows"))
                && isview && rstore.getPwaStore().currentpermissions.has("addrow"))) && !this.props.forcemyview && React.createElement(Box, null,
                React.createElement(AddEntry, null)),
            (rstore.getPwaStore().addnewviewentryviaai) && React.createElement(Box, null,
                React.createElement(AddEntryViaAi, null)));
    }
};
PwaRowsView.contextType = StoreContext;
PwaRowsView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PwaRowsView);
export { PwaRowsView };
let CustomInfoPanel = class CustomInfoPanel extends React.Component {
    render() {
        var _a;
        const rstore = this.context;
        if (!rstore.getPwaStore().showinfopanelshow && !rstore.getPwaStore().custompaneljs) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Box, { zIndex: 9000, position: 'absolute', right: isIpadScreen() ? undefined : '0px', left: isIpadScreen() ? '0px' : undefined, backgroundColor: '#ffffff', top: '70px', height: window.innerHeight - 300, width: isIpadScreen() ? window.innerWidth - 40 : window.innerWidth - 200, borderColor: 'lightgray', borderWidth: '1px' },
            React.createElement(Box, { ml: '10px' },
                React.createElement(Button, { onClick: () => rstore.getPwaStore().setShowinfopanelshow(false) }, "Close")),
            React.createElement(SchemaCustomPanel, { js: (_a = rstore.getPwaStore().custompaneljs) !== null && _a !== void 0 ? _a : '', viewkey: "info-panel" }));
    }
};
CustomInfoPanel.contextType = StoreContext;
CustomInfoPanel = __decorate([
    observer
], CustomInfoPanel);
let SchemaCustomPanel = class SchemaCustomPanel extends React.Component {
    constructor(prop) {
        super(prop);
        this.gconfig = undefined;
        this.filtericonref = null;
        this.activeregfitmodel = undefined;
        this.state = {
            addtable: false,
            buildingview: true,
            buildingmessage: 'Building custom view',
            filter: undefined,
            showfilterpanel: false,
            filtersels: new Map(),
            hiderowids: new Set(),
            tabs: [],
            rows: new Map(),
            applyingfilter: false,
            showsidepanel: false,
            activerowid: "",
            sidepanelid: "",
            buttons: [],
            selectedtabid: "",
            regfitmodel: undefined,
            originalview: false,
            treedata: undefined,
            tabnamefilter: ""
        };
    }
    generatePdf(data) {
        var _a;
        const pdfdata = data.data;
        const pdf = pdfMake.createPdf(pdfdata);
        if (data.output == 'download') {
            const pdfname = (_a = data.name) !== null && _a !== void 0 ? _a : 'data';
            pdf.download(pdfname + ".pdf");
        }
        else if (data.output === 'binary') {
            if (data.binarycb) {
                pdf.getBlob((dd) => { var _a; return (_a = data.binarycb) === null || _a === void 0 ? void 0 : _a.call(data, dd); });
            }
        }
        else {
            if (data.base64cb) {
                pdf.getBase64((dd) => {
                    var _a;
                    (_a = data.base64cb) === null || _a === void 0 ? void 0 : _a.call(data, dd);
                });
            }
        }
    }
    sendEmail(data) {
    }
    async generateExcelData(data) {
        const rstore = this.context;
        const workbook = XLSX.utils.book_new();
        workbook.Props = {
            Title: data.filename,
            CreatedDate: new Date()
        };
        for (const wk of data.worksheets) {
            let dataarr = [];
            let headerarr = [];
            let subheadarr = [];
            let ranges = [];
            let colsinfo = [];
            workbook.SheetNames.push(wk.sheetname);
            const items = wk.header;
            if (items && items.length > 0) {
                let mycolindex = 0;
                let haschildren = false;
                for (let i = 0; i < items.length; i++) {
                    const ii = items[i];
                    if (ii.children && ii.children.length > 0) {
                        haschildren = true;
                        break;
                    }
                }
                for (let i = 0; i < items.length; i++) {
                    const ii = items[i];
                    let addemoty = 0;
                    if (ii.children && ii.children.length > 0) {
                        addemoty = ii.children.length;
                        ranges.push({
                            s: {
                                r: 0,
                                c: mycolindex
                            },
                            e: {
                                r: 0,
                                c: mycolindex + ii.children.length - 1
                            }
                        });
                        mycolindex += ii.children.length;
                    }
                    else {
                        if (haschildren) {
                            ranges.push({
                                s: {
                                    r: 0,
                                    c: mycolindex
                                },
                                e: {
                                    r: haschildren ? 1 : 0,
                                    c: mycolindex
                                }
                            });
                        }
                        mycolindex++;
                    }
                    headerarr.push({
                        v: ii.name,
                        s: {
                            font: { bold: true },
                            alignment: { horizontal: 'center' }
                        },
                        t: "s"
                    });
                    for (let eio = 0; eio < addemoty; eio++) {
                        headerarr.push({
                            v: '',
                            t: 's'
                        });
                    }
                    if (addemoty > 0) {
                        let index = 0;
                        for (let si = headerarr.length - addemoty; si < headerarr.length; si++) {
                            // now we can add stuff to this index!!
                            subheadarr[si] = ({
                                v: ii.children[index],
                                s: {
                                    font: { bold: true },
                                    alignment: { horizontal: 'center' }
                                },
                                t: "s"
                            });
                            index++;
                        }
                    }
                }
                dataarr.push(headerarr);
                if (subheadarr.length > 0) {
                    dataarr.push(subheadarr);
                }
            }
            const data = wk.rows;
            let arowindex = 0;
            for (const row of data) {
                let index = 0;
                const crow = [];
                for (const col of row) {
                    crow.push({
                        t: col.type === 'number' ? 'n' : 's',
                        v: col.value
                    });
                    if (col.rowspan > 1) {
                        ranges.push({
                            s: {
                                r: arowindex,
                                c: index
                            },
                            e: {
                                r: arowindex + col.rowspan - 1,
                                c: index
                            }
                        });
                    }
                    index++;
                }
                arowindex++;
                dataarr.push(crow);
            }
            const wdata = XLSX.utils.aoa_to_sheet(dataarr);
            if (ranges.length > 0) {
                wdata['!merges'] = ranges;
            }
            if (wk.colwidths && wk.colwidths.length > 0) {
                for (const wids of wk.colwidths) {
                    colsinfo.push({
                        wpx: wids
                    });
                }
                wdata['!cols'] = colsinfo;
            }
            workbook.Sheets[wk.sheetname] = wdata;
            await timeout(20);
        }
        // now we can just download this and we done here!!
        XLSX.writeFile(workbook, data.filename + ".xlsx", { compression: true });
    }
    getRemoteItemDetail(item, entry) {
        var _a;
        const rstore = this.context;
        const itemref = item.remotref;
        const cid = item.remotcolref;
        if (!itemref || !cid) {
            return undefined;
        }
        rstore.getPwaStore().addSchemaRowsToRemote(itemref, cid, item.id);
        let remoteitemsmap = new Map();
        const items = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + cid);
        remoteitemsmap.set(item.id + "---" + itemref + "---" + cid, items !== null && items !== void 0 ? items : []);
        for (const ext of (_a = item.remoteextracol) !== null && _a !== void 0 ? _a : []) {
            rstore.getPwaStore().addSchemaRowsToRemote(itemref, ext.cid, item.id);
            const items = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + ext.cid);
            remoteitemsmap.set(item.id + "---" + itemref + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
        }
        const vals = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + cid);
        let mainval = "";
        let remotevals = [];
        if (vals) {
            // now we have this
            const bits = entry.value.split(",");
            if (bits && bits.length > 0) {
                remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1);
                if (remotevals.length > 0) {
                    mainval = remotevals.map((val) => val.value).join(", ");
                }
            }
        }
        return remotevals;
    }
    fitdata(datapoints, dimentions = 2, precision = 3, type = 'linear') {
        if (dimentions === 2) {
            if (type === 'linear') {
                const res = Regression.linear(datapoints, {
                    precision: precision,
                });
                return res;
            }
            if (type === 'exp') {
                const res = Regression.exponential(datapoints, {
                    precision: precision,
                });
                return res;
            }
            if (type === 'pow') {
                const res = Regression.power(datapoints, {
                    precision: precision,
                });
                return res;
            }
            if (type === 'log') {
                const res = Regression.logarithmic(datapoints, {
                    precision: precision,
                });
                return res;
            }
        }
        const res = Regression.polynomial(datapoints, {
            precision: precision,
            order: dimentions
        });
        return res;
    }
    predict(res, pnum) {
        return res.predict(pnum);
    }
    rgbToHex(c) {
        const rgba = convertRGBStringToRGB(c);
        let hex = '';
        let alpha = 1;
        if (rgba.length > 2) {
            hex = color(rgba[0], rgba[1], rgba[2]);
        }
        if (rgba.length > 3) {
            alpha = rgba[3];
        }
        return { hex: hex, alpha: alpha };
    }
    componentDidMount() {
        var _a, _b, _c;
        // now lets put things toger!
        const rstore = this.context;
        const sh = rstore.getPwaStore().getActiveSchema();
        if (!sh)
            return;
        const srows = Array.from(rstore.getPwaStore().uirows.values()).filter((r) => !rstore.getPwaStore().hiddenrows.has(r.rowid)).slice().filter((r) => !r.hidden).sort((r1, r2) => (r1.rowindex - r2.rowindex));
        const config = {
            myschemaid: sh.id,
            canshare: (data) => navigator.share !== undefined && navigator.canShare(data),
            share: (data) => {
                if (navigator.share !== undefined && navigator.canShare(data)) {
                    navigator.share(data);
                }
            },
            getschema: (id) => {
                return rstore.getPwaStore().schemasmap.get(id);
            },
            getRemoteItemDetail: (item, entry) => {
                return this.getRemoteItemDetail(item, entry);
            },
            generateexcel: (data) => {
                this.generateExcelData(data);
            },
            hideorginalrows: (ids) => {
                rstore.getPwaStore().showAllRows();
                for (const id of ids) {
                    rstore.getPwaStore().hiderow(id);
                }
            },
            hiderows: (ids) => {
                this.setState({ hiderowids: new Set(ids) });
            },
            setselecttabid: (id) => {
                this.setState({ selectedtabid: id });
            },
            listentrysorter: (e1, e2) => sortEntriesByRow(e1, e2),
            schemaitems: sh.items.map((item) => {
                const rit = toJS(item);
                if (item.type === SchemaItemType.List) {
                    const lite = item;
                    if (lite.items) {
                        const ni = lite.items.map((it) => toJS(it));
                        rit.items = ni;
                    }
                }
                return rit;
            }),
            fitpredictdata: (data, order, preci) => {
                const rt = this.fitdata(data, order, preci);
                this.activeregfitmodel = rt;
            },
            predictdata: (x) => {
                if (!this.activeregfitmodel)
                    return undefined;
                return this.predict(this.activeregfitmodel, x);
            },
            fitdatacurve: () => {
                var _a;
                if (!this.activeregfitmodel)
                    return [];
                return [...(_a = this.activeregfitmodel) === null || _a === void 0 ? void 0 : _a.points];
            },
            striphtml: (str) => { var _a; return (_a = stripHTML(str)) !== null && _a !== void 0 ? _a : ''; },
            htmltopdfmake: (htmldata) => htmlToPdfmake(htmldata),
            rows: srows,
            getschemarows: (sid) => {
                return rstore.getPwaStore().getDependSchemaRows(sid);
            },
            getSchemaRowsByName: (name) => {
                return rstore.getPwaStore().getDependentSchemaRowsByName(name);
            },
            getseleectedrow: () => {
                const rows = [];
                for (const r of rstore.getPwaStore().pwaeditstore.selectedrows) {
                    const dr = rstore.getPwaStore().rowsMap.get(r);
                    if (dr) {
                        rows.push(dr);
                    }
                }
                return rows;
            },
            storeshareddata: (data) => {
                rstore.getPwaStore().customcolshareddataforrows.set(this.props.viewkey, { data: data });
            },
            getshareddata: () => { var _a, _b; return (_b = (_a = rstore.getPwaStore().customcolshareddataforrows.get(this.props.viewkey)) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : ''; },
            getactiverow: () => rstore.getPwaStore().uirows.get(rstore.getPwaStore().pwaeditstore.selectedid ?
                rstore.getPwaStore().pwaeditstore.selectedid : rstore.getPwaStore().pwaeditstore.activerowid),
            updateconfig: (builder) => {
                var _a, _b, _c;
                this.gconfig = builder;
                if (((_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.viewtype) === 'table' || ((_b = this.gconfig) === null || _b === void 0 ? void 0 : _b.viewtype) === 'gantt' || ((_c = this.gconfig) === null || _c === void 0 ? void 0 : _c.viewtype) === 'tree') {
                    this.updateStateFromGconfg();
                }
            },
            decryptCell: (entry, sid) => {
                if (!entry.decrypted) {
                    rstore.getPwaStore().decryptEntry(entry, sid);
                }
                return entry;
            },
            getupdatessince: async (sid, since, cb) => {
                const res = await rstore.getPwaStore().getUpdateSchemaSince(sh.id, since, sid);
                cb(res);
            },
            showalertdialog: (msg) => {
                rstore.aletrDialogStore.setMessage(msg);
                rstore.aletrDialogStore.setOpen(true);
            },
            showconfirmdialog: (msg, cb) => {
                rstore.confirmStore.setMessage(msg);
                rstore.confirmStore.setOkCB(() => cb());
                rstore.confirmStore.setOpen(true);
            },
            showpanel: (panel) => {
                this.setState({ showsidepanel: true, sidepanelid: panel.tabid });
            },
            showreportdialog: (reprt) => {
                // we can create report diaolg here now!!
            },
            generatepdf: (pdfdata) => {
                this.generatePdf(pdfdata);
            },
            sendemail: (edata) => {
                this.sendEmail(edata);
            },
            contentbuildingmessage: (msg) => {
                this.setState({ buildingmessage: msg });
            },
            contentready: (rows) => {
                var _a;
                if (Array.isArray(rows)) {
                    const smap = new Map();
                    rows.forEach((r) => smap.set(r.id, r));
                    this.setState({ buildingview: false, rows: smap });
                }
                else if (((_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.viewtype) === 'gantt') {
                    const cd = rows;
                    this.setState({ buildingview: false, ganttdata: cd });
                }
                else {
                    this.setState({ buildingview: false, treedata: rows });
                }
            },
            displaydate: (ts, includetime = false) => includetime ? DateLib.displayDate(ts) : DateLib.displayOnlyDate(ts),
            updaterow: (rowid) => {
                var _a;
                const row = this.state.rows.get(rowid);
                if (row) {
                    const srow = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.buildrow(row);
                    if (srow) {
                        this.state.rows.set(srow.id, srow);
                        this.setState({ rows: this.state.rows });
                    }
                }
            },
            numToWords: (str) => { var _a; return (_a = numberToWord(str)) !== null && _a !== void 0 ? _a : ''; },
            rgbToHex: (str) => { var _a; return (_a = this.rgbToHex(str)) !== null && _a !== void 0 ? _a : ''; }
        };
        try {
            const func = new Function("config", this.props.js);
            const builder = func(config);
            this.gconfig = builder;
            if (((_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.viewtype) === 'table' || ((_b = this.gconfig) === null || _b === void 0 ? void 0 : _b.viewtype) === 'gantt' || ((_c = this.gconfig) === null || _c === void 0 ? void 0 : _c.viewtype) === 'tree') {
                this.updateStateFromGconfg();
            }
        }
        catch (ex) {
            console.log(ex);
            this.setState({ buildingview: false, buildingmessage: "Sorry, failed to build the custom view" });
        }
    }
    updateStateFromGconfg() {
        var _a, _b, _c;
        if (!this.gconfig)
            return;
        const rstore = this.context;
        this.setState({
            addtable: this.gconfig.viewtype === 'tree' || this.gconfig.viewtype === 'gantt' ? false : true,
            filter: this.gconfig.customfiler,
            buttons: (_a = this.gconfig.custombuttons) !== null && _a !== void 0 ? _a : [],
            tabs: (_b = this.gconfig.customtabs) !== null && _b !== void 0 ? _b : [],
            originalview: this.gconfig.keepmaindisplay,
        });
        // if (this.gconfig.keepmaindisplay) {
        //     rstore.mainSchemaStore.setWebViewNormalDisplat(true);
        // }
        rstore.getPwaStore().setKeepButtonsWithCustomView((_c = this.gconfig.keepmaindisplay) !== null && _c !== void 0 ? _c : false);
    }
    componentWillUnmount() {
        this.setState({ buildingview: true });
    }
    addTable(header) {
    }
    renderFilterDisplay() {
        var _a, _b;
        const opts = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.customfiler) === null || _b === void 0 ? void 0 : _b.filteroptions;
        if (!opts || this.state.filtersels.size === 0)
            return React.createElement(React.Fragment, null);
        let values = [];
        for (const opt of opts) {
            const sop = this.state.filtersels.get(opt.id);
            if (sop && sop.displayvalue && sop.displayvalue.length > 0) {
                let value = [];
                if (opt.type === CustomFilterType.DateRange) {
                    value = [sop.displayvalue.join(" - ")];
                }
                else {
                    value = sop.displayvalue;
                }
                values.push({
                    label: opt.itemlabel,
                    values: value,
                    id: opt.id
                });
            }
        }
        return React.createElement(Flex, { ml: '10px', align: 'center', flexWrap: 'wrap' }, values.map((val, index) => React.createElement(Flex, { key: val.id + "_" + index, align: 'center' },
            React.createElement(Text, { fontWeight: 'bold' }, val.label),
            val.values.map((v) => React.createElement(Tag, { key: Math.random() + "", ml: '5px' },
                React.createElement(TagLabel, null, v))))));
    }
    addCustomButtons() {
        return React.createElement(Box, { marginTop: '10px', marginLeft: '10px' },
            React.createElement(Flex, { align: 'center', flexWrap: 'wrap' }, this.state.buttons.map((btn) => React.createElement(Button, { margin: '5px', key: Math.random() + '', colorScheme: btn.color, onClick: () => {
                    btn.onclick();
                }, ml: '10px' }, btn.label))));
    }
    addCustomHeader() {
        return React.createElement(Box, null,
            React.createElement(Flex, { align: 'center' },
                this.state.filter !== undefined && this.state.filter.filteroptions !== undefined
                    && this.state.filter.filteroptions.length > 0 && this.addFilter(),
                this.state.buttons !== undefined &&
                    this.state.buttons.length > 0 && this.addCustomButtons()),
            React.createElement(Flex, { mt: '5px', align: 'center' }, this.renderFilterDisplay()));
    }
    addCustomTabs() {
        if (!this.state.tabs || this.state.tabs.length === 0) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Box, { mb: '10px' },
            React.createElement(Flex, { wrap: 'wrap', align: 'center' }, this.state.tabs.map((tab) => React.createElement(Text, { key: tab.tabid, onClick: () => tab.onselect(), color: this.state.selectedtabid === tab.tabid ? 'green' : 'black', fontSize: '18px', ml: '10px', fontWeight: 'bold', _hover: { cursor: 'pointer', textDecoration: 'underline' } }, tab.name))));
    }
    addFilter() {
        var _a;
        const filters = this.state.filter;
        return React.createElement(Box, null,
            React.createElement(Flex, { align: 'center' },
                React.createElement(Flex, { _hover: { cursor: 'pointer' }, onClick: () => {
                        this.setState({ showfilterpanel: !this.state.showfilterpanel });
                    }, flexDir: "column", width: 'fit-content', padding: '5px', ref: (ref) => this.filtericonref = ref, justify: 'center', align: 'center' },
                    React.createElement(Icon, { as: FaFilter, color: this.state.filtersels.size > 0 ? AppColor.FilterActive : AppColor.SideBarIconColor, w: '25px', h: '25px' }),
                    React.createElement(Text, null, (_a = filters === null || filters === void 0 ? void 0 : filters.filterlabel) !== null && _a !== void 0 ? _a : '')),
                this.state.filtersels.size > 0 && React.createElement(Icon, { as: FcClearFilters, h: '25px', w: '25px', _hover: { cursor: 'pointer' }, ml: '5px', onClick: () => {
                        var _a, _b;
                        const cb = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.customfiler) === null || _b === void 0 ? void 0 : _b.filtercb;
                        if (cb) {
                            cb([], () => {
                                this.setState({ applyingfilter: false });
                            });
                        }
                        this.setState({ showfilterpanel: false, filtersels: new Map(), applyingfilter: cb ? true : false, showsidepanel: false, activerowid: '' });
                    } }),
                this.state.applyingfilter && React.createElement(Box, { ml: '5px' },
                    React.createElement(BeatLoader, null))));
    }
    renderHeader() {
        var _a, _b;
        const headers = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.headers) !== null && _b !== void 0 ? _b : [];
        let stickyleft = 0;
        return React.createElement("tr", null, headers.map((h) => {
            let width = 200;
            let ref = null;
            let subitemwidth = 100;
            let llength = 0;
            if (h.children && h.children.length > 0) {
                llength = h.children.length;
                width = llength * 100;
                if (width < 200) {
                    width = 200;
                }
                subitemwidth = width / llength;
            }
            if (h.width) {
                width = h.width;
                if (llength > 0) {
                    subitemwidth = width / llength;
                }
            }
            let myleft = stickyleft;
            if (h.freeze) {
                stickyleft += width;
            }
            return React.createElement("th", { key: h.id, style: {
                    width: width, minWidth: width, left: h.freeze ? myleft : undefined,
                    position: h.freeze ? 'sticky' : undefined,
                    backgroundColor: h.freeze ? '#839eb9' :
                        undefined, zIndex: h.freeze ? 1 : 0
                } },
                React.createElement(Box, { w: width + 'px' },
                    React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                        React.createElement(Text, null, h.name)),
                    React.createElement(Flex, { w: width + 'px' }, h.children && h.children.length > 0 && h.children.map((h) => {
                        return React.createElement(Flex, { key: h.id, align: 'center', justify: 'center', width: subitemwidth + "px" },
                            React.createElement(Text, null, h.name));
                    }))));
        }));
    }
    renderRemoteThumb(mainitem, rextra, item) {
        var _a;
        let myref = null;
        const rstore = this.context;
        const itemref = item.remotref;
        if (!itemref) {
            return React.createElement(React.Fragment, null);
        }
        let rtDisplayItems = [];
        for (const ext of (_a = item.remoteextracol) !== null && _a !== void 0 ? _a : []) {
            const id = item.id + "---" + itemref + "---" + ext.cid;
            const items = rstore.getPwaStore().dependentdata.get(id);
            if (items !== undefined) {
                for (const it of items) {
                    if (it.rowid === mainitem.rowid) {
                        let ri = {
                            id: it.id,
                            label: ext.name,
                            rowid: it.rowid,
                            value: it.value,
                            objects: it.objects
                        };
                        rtDisplayItems.push(ri);
                        break;
                    }
                }
            }
        }
        return React.createElement(Box, { ref: (re) => myref = re, className: 'remote-thumbnail', pos: 'relative', backgroundColor: '#ffffff' },
            React.createElement(Box, { onClick: (e) => {
                    e.stopPropagation();
                    RemotePopupViewStore.setActiveId(item.id);
                    if (myref) {
                        RemotePopupViewStore.reset();
                        RemotePopupViewStore.setLeftPos(myref.getBoundingClientRect().x);
                        RemotePopupViewStore.setTopPos(myref.getBoundingClientRect().y);
                        RemotePopupViewStore.setBGColor('#ffffff');
                        RemotePopupViewStore.addRtDisplayItems(rtDisplayItems);
                    }
                }, width: '100px', height: '75px' }, rtDisplayItems.map((en) => React.createElement(Box, { w: '100%', key: en.id + "_tn" },
                React.createElement(Text, { fontWeight: 'bold', className: 'remote-thumbnail-text' }, en.label),
                React.createElement(Text, { className: 'remote-thumbnail-text' }, en.value)))));
    }
    renderRemoteItem(item, entry) {
        var _a;
        const rstore = this.context;
        let myref = null;
        const itemref = item.remotref;
        const cid = item.remotcolref;
        if (!itemref || !cid) {
            return React.createElement(React.Fragment, null);
        }
        rstore.getPwaStore().addSchemaRowsToRemote(itemref, cid, item.id);
        // now lets get the rows and see if we can build this now!!
        let remoteitemsmap = new Map();
        const items = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + cid);
        remoteitemsmap.set(item.id + "---" + itemref + "---" + cid, items !== null && items !== void 0 ? items : []);
        for (const ext of (_a = item.remoteextracol) !== null && _a !== void 0 ? _a : []) {
            rstore.getPwaStore().addSchemaRowsToRemote(itemref, ext.cid, item.id);
            const items = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + ext.cid);
            remoteitemsmap.set(item.id + "---" + itemref + "---" + ext.cid, items !== null && items !== void 0 ? items : []);
        }
        const vals = rstore.getPwaStore().dependentdata.get(item.id + "---" + itemref + "---" + cid);
        let mainval = "";
        let remotevals = [];
        if (vals) {
            // now we have this
            const bits = entry.value.split(",");
            if (bits && bits.length > 0) {
                remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1);
                if (remotevals.length > 0) {
                    mainval = remotevals.map((val) => val.value).join(", ");
                }
            }
        }
        return React.createElement(Flex, { flexWrap: 'wrap', w: '100%', justify: 'center' }, remotevals.map((ch, index) => React.createElement(Box, { margin: '5px', key: ch.id + '_main' },
            item.remoteextracol &&
                item.remoteextracol.length > 0 && this.renderRemoteThumb(ch, item.remoteextracol, item),
            React.createElement(Tag, { key: ch.id, m: '3px' }, ch.value))));
    }
    renderList(ens, header, rowid) {
        let trow = [];
        for (const en of ens) {
            const s1 = en.orderindex;
            if (trow.indexOf(s1) === -1) {
                trow.push(s1);
            }
        }
        const enmap = new Map();
        for (const en of ens) {
            enmap.set(en.cellid + "----" + en.orderindex, en);
        }
        let width = 300;
        let subitemwidth = 150;
        let llength = 0;
        if (header.children.length > 0) {
            llength = header.children.length;
            width = llength * 150;
            if (width < 250) {
                width = 250;
            }
            subitemwidth = width / llength;
        }
        return React.createElement(Box, { w: width + 'px' }, trow.map((row, index) => {
            return React.createElement(Flex, { w: width + 'px', key: row + "_" + header.id + "_" + rowid, borderBottomWidth: (index < trow.length - 1 ? '1px' : '0px'), borderBottomColor: '#d3dbe2' }, header.children.map((ch) => {
                const aid = ch.id + "----" + row;
                const en = enmap.get(aid);
                if (!en) {
                    return React.createElement(Box, { h: '100%', w: subitemwidth + "px", key: aid });
                }
                if (ch.remotref && ch.remotcolref) {
                    return React.createElement(Box, { h: '100%', w: subitemwidth + "px", key: aid }, this.renderRemoteItem(ch, en));
                }
                return React.createElement(Box, { h: '100%', w: subitemwidth + "px", key: aid },
                    ch.rendertype === 'date' && React.createElement(Text, null, DateLib.displayOnlyDate(parseInt(en.value))),
                    ch.rendertype === 'datetime' && React.createElement(Text, null, DateLib.displayDate(parseInt(en.value))),
                    ch.rendertype === 'color' && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: en.value }),
                    ch.rendertype === 'text' && React.createElement(Box, { dangerouslySetInnerHTML: { __html: en.value } }),
                    ch.rendertype === 'boolean' && en.value === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' }),
                    ch.rendertype === 'boolean' && en.value === 'false' && React.createElement(Icon, { width: '22px', height: '22px', as: MdClose, color: 'red' }));
            }));
        }));
    }
    rowclicked(row) {
        var _a, _b;
        if (this.state.activerowid === row.id) {
            this.setState({ activerowid: '' });
        }
        else {
            this.setState({ activerowid: row.id });
        }
        (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.onrowclick) === null || _b === void 0 ? void 0 : _b.call(_a, row);
    }
    renderRow(row, index) {
        var _a, _b, _c, _d, _e;
        const displayrow = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.buildrow(row);
        if (!displayrow || !displayrow.cols) {
            return React.createElement("td", null);
        }
        const headers = (_c = (_b = this.gconfig) === null || _b === void 0 ? void 0 : _b.headers) !== null && _c !== void 0 ? _c : [];
        if (headers.length === 0) {
            return React.createElement("td", null);
        }
        // now lets get items as per headers now!!
        // if we have children in header we need to us ethat too!!
        const items = headers;
        const enmap = new Map();
        for (const col of (_d = displayrow.cols) !== null && _d !== void 0 ? _d : []) {
            if (!enmap.has(col.cellid)) {
                enmap.set(col.cellid, []);
            }
            (_e = enmap.get(col.cellid)) === null || _e === void 0 ? void 0 : _e.push(col);
        }
        let stickyleft = 0;
        return React.createElement(React.Fragment, null, items.map((item) => {
            const headerid = item.id;
            let width = 300;
            let ref = null;
            let subitemwidth = 150;
            let llength = 0;
            if (item.children && item.children.length > 0) {
                llength = item.children.length;
                width = llength * 150;
                if (width < 250) {
                    width = 250;
                }
                subitemwidth = width / llength;
            }
            if (item.width) {
                width = item.width;
                if (llength > 0) {
                    subitemwidth = width / llength;
                }
            }
            let myleft = stickyleft;
            if (item.freeze) {
                stickyleft += width;
            }
            if (item.children && item.children.length > 0) {
                // lets get total rows!!
                const ens = [];
                for (const ch of item.children) {
                    const myens = enmap.get(ch.id);
                    if (myens) {
                        ens.push(...myens);
                    }
                }
                return React.createElement("td", { onClick: () => {
                        this.rowclicked(row);
                    }, style: {
                        position: item.freeze ? 'sticky' : undefined,
                        left: item.freeze ? myleft : undefined,
                        width: width,
                        minWidth: width,
                        backgroundColor: this.state.activerowid === row.id ? '#829eb975' : item.freeze ? 'white' : undefined
                    }, key: item.id + row.id + " " }, this.renderList(ens, item, row.id));
            }
            const ens = enmap.get(headerid);
            if (!ens) {
                return React.createElement("td", { onClick: () => {
                        this.rowclicked(row);
                    }, style: {
                        position: item.freeze ? 'sticky' : undefined,
                        left: item.freeze ? myleft : undefined,
                        width: width,
                        minWidth: width,
                        backgroundColor: this.state.activerowid === row.id ? '#829eb975' : item.freeze ? 'white' : undefined
                    }, key: Math.random() + '' });
            }
            if (item.remotref && item.remotcolref) {
                return React.createElement("td", { onClick: () => {
                        this.rowclicked(row);
                    }, style: {
                        position: item.freeze ? 'sticky' : undefined,
                        left: item.freeze ? myleft : undefined,
                        width: width,
                        minWidth: width,
                        backgroundColor: this.state.activerowid === row.id ? '#829eb975' : item.freeze ? 'white' : undefined
                    }, key: ens[0].id }, this.renderRemoteItem(item, ens[0]));
            }
            return React.createElement("td", { onClick: () => {
                    this.rowclicked(row);
                }, style: {
                    position: item.freeze ? 'sticky' : undefined,
                    left: item.freeze ? myleft : undefined,
                    width: width,
                    minWidth: width,
                    backgroundColor: this.state.activerowid === row.id ? '#829eb975' : item.freeze ? 'white' : undefined
                }, key: ens[0].id },
                React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                    item.rendertype === 'date' && React.createElement(Text, null, DateLib.displayOnlyDate(parseInt(ens[0].value))),
                    item.rendertype === 'datetime' && React.createElement(Text, null, DateLib.displayDate(parseInt(ens[0].value))),
                    item.rendertype === 'color' && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: ens[0].value }),
                    item.rendertype === 'text' && React.createElement(Box, { dangerouslySetInnerHTML: { __html: ens[0].value } }),
                    item.rendertype === 'boolean' && ens[0].value === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' }),
                    item.rendertype === 'boolean' && ens[0].value === 'false' && React.createElement(Icon, { width: '22px', height: '22px', as: MdClose, color: 'red' })));
        }));
    }
    render_card(displayrow, hh, myindex) {
        var _a, _b, _c, _d, _e;
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : Math.floor((window.innerWidth - 165) / 2);
        const headers = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.headers) !== null && _b !== void 0 ? _b : [];
        const enmap = new Map();
        if (this.gconfig) {
            displayrow = (_c = this.gconfig) === null || _c === void 0 ? void 0 : _c.buildrow(displayrow);
        }
        for (const col of (_d = displayrow.cols) !== null && _d !== void 0 ? _d : []) {
            if (!enmap.has(col.cellid)) {
                enmap.set(col.cellid, []);
            }
            (_e = enmap.get(col.cellid)) === null || _e === void 0 ? void 0 : _e.push(col);
        }
        const mycolo = myindex % 2 === 0 ? cardcolors[0][1] : cardcolors[1][1];
        return React.createElement(Box, { _hover: { cursor: 'pointer' }, onClick: () => {
                this.rowclicked(displayrow);
            }, margin: '10px', style: {
                backgroundColor: this.state.activerowid === displayrow.id ? '#829eb975' : mycolo
            }, minH: '100px', w: totalwidth, ml: window.innerWidth < 600 ? "0px" : '20px', boxShadow: '5px 0 5px 5px #b6b6b6', className: 'card-items' }, hh.map((h, index) => {
            return React.createElement(Flex, { marginTop: '5px', minH: '50px', key: displayrow.id + "_" + index, w: '100%' }, h.map(sh => {
                var _a;
                const ens = (_a = enmap.get(sh.id)) !== null && _a !== void 0 ? _a : [];
                const isremote = sh.remotref;
                return React.createElement(Box, { ml: '5px', key: displayrow.id + "_" + sh.id, w: (95 / h.length) + "%" },
                    React.createElement(Flex, { w: '100%', align: 'center', justify: 'center' },
                        React.createElement(Text, { fontWeight: 'bold', fontSize: '15px' }, sh.name)),
                    React.createElement(Box, null,
                        sh.children && sh.children.length > 0 && this.renderList(ens, sh, displayrow.id),
                        (!sh.children || sh.children.length === 0) && isremote && this.renderRemoteItem(sh, ens[0]),
                        (!sh.children || sh.children.length === 0) && !isremote && React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                            sh.rendertype === 'date' && React.createElement(Text, null, DateLib.displayOnlyDate(parseInt(ens[0].value))),
                            sh.rendertype === 'datetime' && React.createElement(Text, null, DateLib.displayDate(parseInt(ens[0].value))),
                            sh.rendertype === 'color' && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: ens[0].value }),
                            sh.rendertype === 'text' && React.createElement(Box, { dangerouslySetInnerHTML: { __html: ens[0].value } }),
                            sh.rendertype === 'boolean' && ens[0].value === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' }),
                            sh.rendertype === 'boolean' && ens[0].value === 'false' && React.createElement(Icon, { width: '22px', height: '22px', as: MdClose, color: 'red' }))));
            }));
        }));
    }
    build_headers(headers) {
        const hh = [];
        for (let i = 0; i < headers.length;) {
            const h = headers[i];
            const ret = [];
            ret.push(h);
            i++;
            if (h.remotref || (h.children && h.children.length > 0)) {
                hh.push(ret);
                continue;
            }
            if (i < headers.length) {
                const h2 = headers[i];
                if (h2.remotref || (h.children && h.children.length > 0)) {
                    continue;
                }
                ret.push(h2);
                hh.push(ret);
                i++;
            }
        }
        return hh;
    }
    createCards() {
        var _a, _b;
        const rows = Array.from(this.state.rows.values())
            .filter((rowid) => !this.state.hiderowids.has(rowid.id)).sort((r1, r2) => r1.sortindex.localeCompare(r2.sortindex));
        const headers = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.headers) !== null && _b !== void 0 ? _b : [];
        const dh = this.build_headers(headers);
        return React.createElement(Box, { mt: '5px', h: viewheight() }, rows.length > 0 && React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: viewheight() }, data: rows, itemContent: (index, row) => this.render_card(row, dh, index) })));
    }
    createTable() {
        var _a, _b;
        const rows = Array.from(this.state.rows.values())
            .filter((rowid) => !this.state.hiderowids.has(rowid.id)).sort((r1, r2) => r1.sortindex.localeCompare(r2.sortindex));
        const headers = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.headers) !== null && _b !== void 0 ? _b : [];
        let h = viewheight();
        if (this.state.tabs && this.state.tabs.length > 0) {
            h -= 100;
        }
        return React.createElement(Box, { h: h }, rows.length > 0 && React.createElement(Observer, null, () => React.createElement(TableVirtuoso, { className: "styled-table", overscan: 50, data: rows, style: { height: h }, components: {
                EmptyPlaceholder: () => {
                    return (React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "Empty"))));
                },
                // set the colspan below to the amount of columns you have.
                FillerRow: ({ height }) => {
                    return (React.createElement("tr", null,
                        React.createElement("td", { colSpan: headers.length, style: { height: height, padding: 0, border: 0 } })));
                }
            }, fixedHeaderContent: () => React.createElement(Observer, null, () => this.renderHeader()), itemContent: (index, data) => this.renderRow(data, index) })));
    }
    renderFilterPanel() {
        var _a, _b, _c, _d, _e, _f, _g;
        return React.createElement(Box, null,
            this.state.showfilterpanel && React.createElement(Box, { backgroundColor: '#ffffff', zIndex: 500, pos: 'fixed', minHeight: 400, minWidth: window.innerWidth < 500 ? window.innerWidth - 40 : 500, maxH: window.innerHeight - 300, maxW: window.innerWidth - 40, overflow: 'auto', boxShadow: '5px 5px 5px 5px lightgray', left: ((_b = (_a = this.filtericonref) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().x) !== null && _b !== void 0 ? _b : 10) + 'px', top: ((_d = (_c = this.filtericonref) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().y) !== null && _d !== void 0 ? _d : 10) + 'px' },
                React.createElement(Flex, { w: '100%', align: 'center', justify: 'flex-end', mr: '10px', h: '50px', borderBottomColor: 'lightgray', borderBottomWidth: '1px' },
                    React.createElement(Button, { disabled: this.state.filtersels.size === 0, size: 'sm', colorScheme: 'lwcolor', onClick: () => {
                            var _a, _b;
                            const cb = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.customfiler) === null || _b === void 0 ? void 0 : _b.filtercb;
                            if (cb) {
                                cb(Array.from(this.state.filtersels.values()), () => {
                                    this.setState({ applyingfilter: false });
                                });
                            }
                            this.setState({ showfilterpanel: false, applyingfilter: cb ? true : false, showsidepanel: false, activerowid: '' });
                        } }, "Apply"),
                    React.createElement(Icon, { _hover: { cursor: 'pointer' }, onClick: () => {
                            this.setState({ showfilterpanel: false, filtersels: new Map() });
                        }, w: '25px', h: '25px', ml: '5px', as: MdClose, color: AppColor.CloseButton })),
                React.createElement(Box, { mt: '10px', padding: '10px' }, ((_g = (_f = (_e = this.gconfig) === null || _e === void 0 ? void 0 : _e.customfiler) === null || _f === void 0 ? void 0 : _f.filteroptions) !== null && _g !== void 0 ? _g : []).map((item) => {
                    var _a;
                    let choicopts = [];
                    let choicemap = new Map();
                    if (item.type === CustomFilterType.Choice) {
                        const ci = item;
                        if (Array.isArray(ci.options)) {
                            choicopts = ci.options;
                        }
                        else {
                            const fpm = Array.from(this.state.filtersels.values());
                            choicopts = (_a = ci.options(fpm)) !== null && _a !== void 0 ? _a : [];
                        }
                        choicopts.forEach((c) => choicemap.set(c.value, c));
                    }
                    let choicedefvale = "";
                    let ddefaultvalue = 0;
                    let ddefval2 = 0;
                    if (item.type === CustomFilterType.DateRange) {
                        const fm = this.state.filtersels.get(item.id);
                        if (fm && fm.selids && fm.selids.length > 0) {
                            const nv = parseInt(fm.selids[0]);
                            if (!isNaN(nv)) {
                                ddefaultvalue = nv;
                            }
                        }
                        if (fm && fm.selids && fm.selids.length > 1) {
                            const nv = parseInt(fm.selids[1]);
                            if (!isNaN(nv)) {
                                ddefval2 = nv;
                            }
                        }
                    }
                    else if (item.type === CustomFilterType.Choice) {
                        const fm = this.state.filtersels.get(item.id);
                        if (fm && fm.selids) {
                            if (Array.isArray(fm.selids)) {
                                choicedefvale = fm.selids.join(",");
                            }
                            else {
                                choicedefvale = fm.selids;
                            }
                        }
                    }
                    return React.createElement(FormControl, { key: item.id, mt: '10px' },
                        React.createElement(Flex, { align: 'center', mb: '15px' }, React.createElement(Text, { mr: '40px', style: {
                                color: AppColor.FilterHeaderFontColor,
                                fontSize: FontSize.FilterHeaderFontSize, fontWeight: 'bold'
                            } }, item.itemlabel)),
                        item.type === CustomFilterType.Choice && React.createElement(ChoiceInput, { id: item.id + "_choice", defaultvalue: choicedefvale, key: Math.random() + "", multiselect: item.multichoice, schemafieldname: 'item.name', onChange: (ch) => {
                                const dvalues = [];
                                for (const c of ch.split(",")) {
                                    const dbb = choicemap.get(c);
                                    if (dbb) {
                                        dvalues.push(dbb.label);
                                    }
                                }
                                const fm = this.state.filtersels;
                                fm.set(item.id, {
                                    id: item.id,
                                    selids: ch.split(","),
                                    displayvalue: dvalues
                                });
                                this.setState({ filtersels: fm });
                            }, showbarcodescanner: false, list: choicopts.map((item) => {
                                var _a;
                                const ret = {
                                    id: item.value,
                                    name: (_a = stripHTML(item.label)) !== null && _a !== void 0 ? _a : '',
                                    type: ChoiceType.Text
                                };
                                return ret;
                            }) }),
                        item.type === CustomFilterType.DateRange && React.createElement(Box, null,
                            React.createElement(Flex, { w: '100%' },
                                React.createElement(Flex, { w: '50%' },
                                    React.createElement(Text, { mr: '5px', color: AppColor.FilterItemLabelFontColor, fontSize: FontSize.FilterItemLabelFontSize }, "Start: "),
                                    React.createElement(DateInput, { disbaledate: false, defaultvalue: ddefaultvalue, onChange: (ts) => {
                                            const smap = this.state.filtersels;
                                            const fm = smap.get(item.id);
                                            let selids = [];
                                            let displayvalue = [];
                                            if (fm && Array.isArray(fm.selids)) {
                                                selids = fm.selids;
                                            }
                                            selids[0] = ts > -1 ? ts + '' : '';
                                            if ((fm === null || fm === void 0 ? void 0 : fm.displayvalue) && fm.displayvalue.length > 0) {
                                                displayvalue = fm.displayvalue;
                                            }
                                            if (ts > -1) {
                                                const didate = DateLib.displayDate(ts);
                                                displayvalue[0] = didate;
                                            }
                                            else {
                                                displayvalue[0] = "";
                                            }
                                            if (selids[0] === '') {
                                                if (selids.length === 1 || selids[1] === '') {
                                                    smap.delete(item.id);
                                                }
                                            }
                                            else {
                                                smap.set(item.id, {
                                                    id: item.id,
                                                    selids: selids,
                                                    displayvalue: displayvalue
                                                });
                                            }
                                            this.setState({ filtersels: smap });
                                        }, showtime: true, use24hrformat: true, key: item.id, id: item.id, format: 'dd/MM/yyyy' })),
                                React.createElement(Flex, { w: '50%' },
                                    React.createElement(Text, { ml: '5px', mr: '5px' }, "End: "),
                                    React.createElement(DateInput, { disbaledate: false, defaultvalue: ddefval2, onChange: (ts) => {
                                            const smap = this.state.filtersels;
                                            const fm = smap.get(item.id);
                                            let selids = ["", ""];
                                            let displayvalue = ["", ""];
                                            if (fm && Array.isArray(fm.selids)) {
                                                selids = fm.selids;
                                            }
                                            if ((fm === null || fm === void 0 ? void 0 : fm.displayvalue) && fm.displayvalue.length > 0) {
                                                displayvalue = fm.displayvalue;
                                            }
                                            if (ts > -1) {
                                                const didate = DateLib.displayDate(ts);
                                                displayvalue[1] = didate;
                                            }
                                            else {
                                                displayvalue[1] = "";
                                            }
                                            selids[1] = ts > -1 ? ts + '' : '';
                                            if (selids[0] === '') {
                                                if (selids.length === 1 || selids[1] === '') {
                                                    smap.delete(item.id);
                                                }
                                            }
                                            else {
                                                smap.set(item.id, {
                                                    id: item.id,
                                                    selids: selids,
                                                    displayvalue: displayvalue
                                                });
                                            }
                                            this.setState({ filtersels: smap });
                                        }, showtime: true, use24hrformat: true, key: item.id, id: item.id, format: 'dd/MM/yyyy' })))));
                }))),
            !this.state.showfilterpanel && React.createElement(React.Fragment, null));
    }
    create_panel_table(headers, rows) {
        const renderh = () => {
            return React.createElement("tr", null, headers.map((h) => {
                let width = 150;
                return React.createElement("th", { key: Math.random() + "", style: { width: width, minWidth: 150 } },
                    React.createElement(Box, { w: width + 'px' },
                        React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                            React.createElement(Text, null, h))));
            }));
        };
        const renrow = (row) => {
            return React.createElement(React.Fragment, null, row.map((r) => {
                var _a;
                return React.createElement("td", { key: Math.random() + "" },
                    React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                        React.createElement(Text, null, (_a = stripHTML(r)) !== null && _a !== void 0 ? _a : '')));
            }));
        };
        return React.createElement(TableVirtuoso, { className: "styled-table", overscan: 50, data: rows, style: { height: window.innerHeight < 500 ? window.innerHeight - 40 : 500 }, components: {
                EmptyPlaceholder: () => {
                    return (React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "Empty"))));
                },
                // set the colspan below to the amount of columns you have.
                FillerRow: ({ height }) => {
                    return (React.createElement("tr", null,
                        React.createElement("td", { colSpan: headers.length, style: { height: height, padding: 0, border: 0 } })));
                }
            }, fixedHeaderContent: () => React.createElement(Observer, null, () => renderh()), itemContent: (index, data) => renrow(data) });
    }
    tabsFooter() {
        if (!this.state.tabs || this.state.tabs.length === 0) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Box, { ml: '10px' },
            React.createElement(Flex, { h: "35px", whiteSpace: 'nowrap', fontSize: '12px' },
                React.createElement(InputGroup, { height: '35px', ml: '10px', w: '150px', minW: '150px' },
                    React.createElement(InputLeftElement, { pointerEvents: 'none' },
                        React.createElement(Icon, { as: FaSearch, color: 'gray.300' })),
                    React.createElement(Input, { height: '35px', value: this.state.tabnamefilter, onChange: (e) => {
                            this.setState({ tabnamefilter: e.currentTarget.value });
                        }, placeholder: 'Search' })),
                React.createElement(Flex, { alignItems: 'flex-end', w: window.innerWidth - 400, overflow: 'auto' }, this.state.tabs.filter((f) => !this.state.tabnamefilter.trim() || f.name.toLowerCase().indexOf(this.state.tabnamefilter.toLowerCase())
                    !== -1).map((tab) => React.createElement(Flex, { align: 'center', className: (this.state.selectedtabid === tab.tabid) ? 'sheet-panel-active' : 'sheet-panel', _hover: { cursor: 'pointer', textDecoration: 'underline' }, fontSize: '18px', ml: '10px', fontWeight: 'bold', onClick: () => tab.onselect(), key: tab.tabid },
                    React.createElement(Icon, { mr: '10px', as: GrGrid, w: '14px', h: '14px' }),
                    React.createElement(Box, null,
                        React.createElement(Text, { fontSize: '14px', fontWeight: 600 }, tab.name)))))));
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        const rstore = this.context;
        if (this.state.buildingview || (this.state.originalview && rstore.getPwaStore().loadingrows)) {
            return React.createElement(Flex, { w: '100%', justify: 'center', align: 'center', h: viewheight() },
                React.createElement(FadeLoader, { color: AppColor.MainLoaderColor }),
                React.createElement(Text, { mt: '10px' }, this.state.buildingmessage));
        }
        if (this.state.originalview) {
            return React.createElement(Box, null,
                this.addCustomHeader(),
                React.createElement(PwaRowsView, { showheader: false, title: '', forcemyview: true }));
        }
        const cdata = this.state.ganttdata;
        return React.createElement(Box, { ref: (ref) => {
            }, pos: 'relative', h: viewheight() },
            this.state.showsidepanel && React.createElement(Box, null,
                React.createElement(CustomSidePanel, { key: this.state.activerowid, defaulttab: this.state.sidepanelid, onClose: () => this.setState({ showsidepanel: false, activerowid: '' }), tabs: (_c = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.sidepanelconfig) === null || _b === void 0 ? void 0 : _b.tabs) !== null && _c !== void 0 ? _c : [], paneldirection: (_f = (_e = (_d = this.gconfig) === null || _d === void 0 ? void 0 : _d.sidepanelconfig) === null || _e === void 0 ? void 0 : _e.direction) !== null && _f !== void 0 ? _f : 'right', top: ((_h = (_g = this.filtericonref) === null || _g === void 0 ? void 0 : _g.getBoundingClientRect().y) !== null && _h !== void 0 ? _h : 10), ontabclick: (cl) => {
                        var _a, _b;
                        const data = (_b = (_a = this.gconfig) === null || _a === void 0 ? void 0 : _a.sidepanelconfig) === null || _b === void 0 ? void 0 : _b.tabclickcb(cl, this.state.activerowid);
                        if (cl.tabdisplay === 'table') {
                            const tddata = data;
                            if (testIsMobile()) {
                                return React.createElement(CustomSidePanelCard, { headers: tddata.header, rows: tddata.rows });
                            }
                            return React.createElement(CustomSidePanelTable, { headers: tddata.header, rows: tddata.rows });
                        }
                        else if (cl.tabdisplay === 'tree') {
                            const tddata = data;
                            return React.createElement(CustomSidePanelTree, { nodes: tddata.tree });
                        }
                        else if (cl.tabdisplay === 'chart') {
                            const cdata = data;
                            return React.createElement(CustomSidePanelChart, { key: this.state.activerowid + "_chart" + cl.tabid, color: cdata.color, fill: cdata.fill, title: cdata.title, bg: cdata.bg, labels: cdata.labels, charttype: cdata.type, rows: cdata.rows });
                        }
                        return React.createElement(Box, null,
                            React.createElement(Text, null, "Missing display"));
                    } })),
            this.renderFilterPanel(),
            this.addCustomHeader(),
            this.state.addtable && ((_j = this.gconfig) === null || _j === void 0 ? void 0 : _j.viewtype) !== 'gantt' && ((_k = this.gconfig) === null || _k === void 0 ? void 0 : _k.viewtype) !== 'tree' && ((_l = this.gconfig) === null || _l === void 0 ? void 0 : _l.viewtype) !== 'stack' && testIsMobile() && this.createCards(),
            this.state.addtable && ((_m = this.gconfig) === null || _m === void 0 ? void 0 : _m.viewtype) !== 'gantt' && ((_o = this.gconfig) === null || _o === void 0 ? void 0 : _o.viewtype) !== 'tree' && ((_p = this.gconfig) === null || _p === void 0 ? void 0 : _p.viewtype) !== 'stack' && !testIsMobile() && this.createTable(),
            !this.state.addtable && ((_q = this.gconfig) === null || _q === void 0 ? void 0 : _q.viewtype) !== 'gantt' && ((_r = this.gconfig) === null || _r === void 0 ? void 0 : _r.viewtype) === 'tree' && React.createElement(CustomSidePanelTree, { nodes: (_t = (_s = this.state.treedata) === null || _s === void 0 ? void 0 : _s.tree) !== null && _t !== void 0 ? _t : [] }),
            this.state.addtable && ((_u = this.gconfig) === null || _u === void 0 ? void 0 : _u.viewtype) !== 'gantt' && ((_v = this.gconfig) === null || _v === void 0 ? void 0 : _v.viewtype) !== 'tree'
                && ((_w = this.gconfig) === null || _w === void 0 ? void 0 : _w.viewtype) === 'stack'
                && React.createElement(CustomStackView, { stack: (_y = (_x = this.gconfig) === null || _x === void 0 ? void 0 : _x.stack) !== null && _y !== void 0 ? _y : [] }),
            !this.state.addtable && cdata !== undefined && ((_z = this.gconfig) === null || _z === void 0 ? void 0 : _z.viewtype) === 'gantt' &&
                React.createElement(Box, { width: '100%', height: '100%' },
                    React.createElement(CustomSchedulerView, { data: cdata, key: this.state.activerowid + "_chart" })),
            React.createElement(Box, { position: 'absolute' }, this.tabsFooter()));
    }
};
SchemaCustomPanel.contextType = StoreContext;
SchemaCustomPanel = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], SchemaCustomPanel);
export { SchemaCustomPanel };
let CustomSchedulerView = class CustomSchedulerView extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        const grps = [];
        const items = [];
        for (const r of this.props.data.resources) {
            const tg = {
                id: r.id,
                title: r.name
            };
            grps.push(tg);
        }
        for (const ri of this.props.data.events) {
            items.push({
                id: ri.id,
                title: ri.title,
                group: ri.resourceid,
                start_time: ri.start,
                end_time: ri.end
            });
        }
        return React.createElement(Box, { w: '100%', h: '100%' },
            React.createElement(Timeline, { groups: grps, items: items, defaultTimeStart: this.props.data.minStartTime, defaultTimeEnd: this.props.data.maxEndTime }));
    }
};
CustomSchedulerView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomSchedulerView);
class CustomStackTableView extends React.Component {
    constructor(prop) {
        super(prop);
    }
    renderList(ens, header, rowid) {
        let trow = [];
        for (const en of ens) {
            const s1 = en.orderindex;
            if (trow.indexOf(s1) === -1) {
                trow.push(s1);
            }
        }
        const enmap = new Map();
        for (const en of ens) {
            enmap.set(en.cellid + "----" + en.orderindex, en);
        }
        let width = 300;
        let subitemwidth = 150;
        let llength = 0;
        if (header.children.length > 0) {
            llength = header.children.length;
            width = llength * 150;
            if (width < 250) {
                width = 250;
            }
            subitemwidth = width / llength;
        }
        return React.createElement(Box, { w: width + 'px' }, trow.map((row, index) => {
            return React.createElement(Flex, { w: width + 'px', key: row + "_" + header.id + "_" + rowid, borderBottomWidth: (index < trow.length - 1 ? '1px' : '0px'), borderBottomColor: '#d3dbe2' }, header.children.map((ch) => {
                const aid = ch.id + "----" + row;
                const en = enmap.get(aid);
                if (!en) {
                    return React.createElement(Box, { h: '100%', w: subitemwidth + "px", key: aid });
                }
                return React.createElement(Box, { h: '100%', w: subitemwidth + "px", key: aid },
                    ch.rendertype === 'date' && React.createElement(Text, null, DateLib.displayOnlyDate(parseInt(en.value))),
                    ch.rendertype === 'datetime' && React.createElement(Text, null, DateLib.displayDate(parseInt(en.value))),
                    ch.rendertype === 'color' && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: en.value }),
                    ch.rendertype === 'text' && React.createElement(Box, { dangerouslySetInnerHTML: { __html: en.value } }),
                    ch.rendertype === 'boolean' && en.value === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' }),
                    ch.rendertype === 'boolean' && en.value === 'false' && React.createElement(Icon, { width: '22px', height: '22px', as: MdClose, color: 'red' }));
            }));
        }));
    }
    renderRow(displayrow) {
        var _a, _b;
        const headers = this.props.stack.header;
        if (headers.length === 0) {
            return React.createElement("td", { key: Math.random() + "" });
        }
        // now lets get items as per headers now!!
        // if we have children in header we need to us ethat too!!
        const items = headers;
        const enmap = new Map();
        for (const col of (_a = displayrow.cols) !== null && _a !== void 0 ? _a : []) {
            if (!enmap.has(col.cellid)) {
                enmap.set(col.cellid, []);
            }
            (_b = enmap.get(col.cellid)) === null || _b === void 0 ? void 0 : _b.push(col);
        }
        return React.createElement(React.Fragment, null, items.map((item) => {
            const headerid = item.id;
            if (item.children && item.children.length > 0) {
                // lets get total rows!!
                const ens = [];
                for (const ch of item.children) {
                    const myens = enmap.get(ch.id);
                    if (myens) {
                        ens.push(...myens);
                    }
                }
                return React.createElement("td", { onClick: () => {
                    }, key: item.id + displayrow.id + " " }, this.renderList(ens, item, displayrow.id));
            }
            const ens = enmap.get(headerid);
            if (!ens) {
                return React.createElement("td", { key: Math.random() + '' });
            }
            return React.createElement("td", { key: ens[0].id },
                React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                    item.rendertype === 'date' && React.createElement(Text, null, DateLib.displayOnlyDate(parseInt(ens[0].value))),
                    item.rendertype === 'datetime' && React.createElement(Text, null, DateLib.displayDate(parseInt(ens[0].value))),
                    item.rendertype === 'color' && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: ens[0].value }),
                    item.rendertype === 'text' && React.createElement(Box, { dangerouslySetInnerHTML: { __html: ens[0].value } }),
                    item.rendertype === 'boolean' && ens[0].value === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' }),
                    item.rendertype === 'boolean' && ens[0].value === 'false' && React.createElement(Icon, { width: '22px', height: '22px', as: MdClose, color: 'red' })));
        }));
    }
    renderHeader() {
        const headers = this.props.stack.header;
        return React.createElement("tr", null, headers.map((h) => {
            let width = 300;
            let ref = null;
            let subitemwidth = 150;
            let llength = 0;
            if (h.children && h.children.length > 0) {
                llength = h.children.length;
                width = llength * 150;
                if (width < 250) {
                    width = 250;
                }
                subitemwidth = width / llength;
            }
            if (h.width) {
                width = h.width;
                if (llength > 0) {
                    subitemwidth = width / llength;
                }
            }
            return React.createElement("th", { key: h.id, style: { width: width, minWidth: 200 } },
                React.createElement(Box, { w: width + 'px' },
                    React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                        React.createElement(Text, null, h.name)),
                    React.createElement(Flex, { w: width + 'px' }, h.children && h.children.length > 0 && h.children.map((h) => {
                        return React.createElement(Flex, { key: h.id, align: 'center', justify: 'center', width: subitemwidth + "px" },
                            React.createElement(Text, null, h.name));
                    }))));
        }));
    }
    render() {
        const headers = this.props.stack.header;
        const panelheight = window.innerHeight < 800 ? window.innerHeight : 800;
        return React.createElement(Box, { h: panelheight }, this.props.stack.rows.length > 0 && React.createElement(Observer, null, () => React.createElement(TableVirtuoso, { className: "styled-table", overscan: 50, data: this.props.stack.rows, style: { height: panelheight }, components: {
                EmptyPlaceholder: () => {
                    return (React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "Empty"))));
                },
                // set the colspan below to the amount of columns you have.
                FillerRow: ({ height }) => {
                    return (React.createElement("tr", null,
                        React.createElement("td", { colSpan: headers.length, style: { height: height, padding: 0, border: 0 } })));
                }
            }, fixedHeaderContent: () => React.createElement(Observer, null, () => this.renderHeader()), itemContent: (index, data) => this.renderRow(data) })));
    }
}
let CustomStackView = class CustomStackView extends React.Component {
    constructor(prop) {
        super(prop);
    }
    arrangeStack() {
        const stackbyrows = [];
        let done = 0;
        let row = [];
        for (const st of this.props.stack) {
            if (st.fullrow) {
                if (row.length > 0) {
                    stackbyrows.push(row);
                }
                row = [];
                row.push(st);
                stackbyrows.push(row);
                row = [];
            }
            else if (row.length > 1) {
                stackbyrows.push(row);
                row = [];
                row.push(st);
            }
        }
        if (row.length > 0) {
            stackbyrows.push(row);
        }
        return stackbyrows;
    }
    renderStack(st) {
        if (st.type === 'table') {
            return React.createElement(CustomStackTableView, { stack: st });
        }
        if (st.type === 'chart') {
            const cst = st;
            return React.createElement(CustomSidePanelChart, { key: st.id + "_chart", color: cst.color, fill: cst.fill, title: cst.name, bg: cst.bg, labels: cst.labels, charttype: cst.ctype, rows: cst.row });
        }
        if (st.type === 'tree') {
            const tst = st;
            return React.createElement(CustomSidePanelTree, { nodes: tst.tree });
        }
        return React.createElement(React.Fragment, null);
    }
    render() {
        const st = this.arrangeStack();
        const panelheight = window.innerHeight < 800 ? window.innerHeight : 800;
        return React.createElement(Box, null, st.map((row) => {
            if (row.length > 1) {
                React.createElement(Flex, { w: '100%', h: panelheight, align: 'center' },
                    React.createElement(Box, { w: '50%' }, this.renderStack(row[0])),
                    React.createElement(Box, { w: '50%' }, this.renderStack(row[1])));
            }
            else {
                React.createElement(Flex, { w: '100%', h: panelheight, align: 'center' },
                    React.createElement(Box, { w: '100%' }, this.renderStack(row[0])));
            }
        }));
    }
};
CustomStackView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomStackView);
class CustomSidePanelChart extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        if (this.props.charttype === 'line' || this.props.charttype === 'pie') {
            const xlan = this.props.labels[0];
            const ylan = this.props.labels[1];
            const points = this.props.rows;
            return React.createElement(Box, { width: window.innerWidth > 600 ? 500 : window.innerWidth - 100, height: window.innerHeight > 600 ? 500 : window.innerHeight - 50 },
                React.createElement("canvas", { ref: ref => {
                        const cn = ref === null || ref === void 0 ? void 0 : ref.getContext('2d');
                        if (!cn)
                            return;
                        const dp = points.map((pt) => parseFloat(pt[1]));
                        const dset = [{
                                data: dp,
                                type: this.props.charttype === 'bar' || this.props.charttype === 'horizontal' ? 'bar' :
                                    this.props.charttype === 'line' ? 'line' : 'pie',
                                fill: this.props.fill,
                                borderColor: this.props.color,
                                backgroundColor: this.props.bg,
                                label: this.props.title
                            }];
                        if (this.props.charttype === 'horizontal') {
                            new Chart(cn, {
                                type: 'bar',
                                data: {
                                    datasets: dset,
                                    labels: points.map((pt) => pt[0]),
                                },
                                options: {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        }
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'right',
                                        },
                                        title: {
                                            display: true,
                                            text: this.props.title
                                        }
                                    }
                                }
                            });
                            return;
                        }
                        if (this.props.charttype === 'pie') {
                            const options = {
                                tooltips: {
                                    enabled: false
                                },
                                plugins: {
                                    datalabels: {
                                        font: {
                                            weight: "bold",
                                            size: 8,
                                        },
                                        formatter: (value, context) => {
                                            if (context && context.chart && context.chart.data && context.chart.data.labels) {
                                                return context.chart.data.labels[context.dataIndex] + "\n" + value + "%";
                                            }
                                            return value + "%";
                                        },
                                        color: "#FFFFFF"
                                    }
                                }
                            };
                            new Chart(cn, {
                                type: 'pie',
                                data: {
                                    datasets: dset,
                                    labels: points.map((pt) => pt[0]),
                                },
                                options: options
                            });
                            return;
                        }
                        const chcn = new Chart(cn, {
                            type: this.props.charttype === 'bar' ? 'bar' :
                                this.props.charttype === 'line' ? 'line' : 'pie',
                            data: {
                                datasets: dset,
                                labels: points.map((pt) => pt[0]),
                            },
                            options: {
                                interaction: {
                                    mode: 'nearest'
                                },
                                backgroundColor: this.props.bg,
                                elements: {
                                    point: {
                                        radius: 5,
                                    }
                                },
                                scales: {
                                    y: {
                                        title: {
                                            display: true,
                                            text: ylan
                                        }
                                    },
                                    x: {
                                        title: {
                                            display: true,
                                            text: xlan
                                        }
                                    }
                                }
                            }
                        });
                    }, width: "500", height: "500" }));
        }
        return React.createElement(React.Fragment, null);
    }
}
let DateInput = class DateInput extends React.Component {
    constructor(prop) {
        super(prop);
        let date;
        if (prop.defaultvalue) {
            if (this.props.defaultvalue) {
                try {
                    date = new Date(this.props.defaultvalue);
                }
                catch (e) {
                }
            }
        }
        this.state = {
            date: date
        };
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(DatePicker, { showTimeInput: this.props.showtime, selected: this.state.date, showMonthDropdown: true, showYearDropdown: true, isClearable: true, withPortal: true, showTimeSelectOnly: this.props.disbaledate, dateFormat: (this.props.disbaledate ? "" : this.props.format) + "" + (this.props.showtime ? " HH:mm" : ''), onChange: (date) => {
                    if (!date) {
                        this.props.onChange(-1);
                        this.setState({ date: undefined });
                        return;
                    }
                    this.props.onChange(date.getTime());
                    this.setState({ date: date });
                } }));
    }
};
DateInput.contextType = StoreContext;
DateInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], DateInput);
class CustomSidePanelTree extends React.Component {
    constructor(prop) {
        super(prop);
    }
    convertNodesToDisplayNodes(nodes, p) {
        const ret = [];
        for (const n of nodes) {
            ret.push({
                id: n.id,
                label: n.label,
                parentId: p,
                items: []
            });
            if (n.children && n.children.length > 0) {
                ret.push(...this.convertNodesToDisplayNodes(n.children, n.id));
            }
        }
        return ret;
    }
    render() {
        return React.createElement(Box, { style: { height: window.innerHeight < 500 ? window.innerHeight - 40 : 500 } },
            React.createElement(ReactTree, { nodes: this.convertNodesToDisplayNodes(this.props.nodes, null) }));
    }
}
class CustomSidePanelTable extends React.Component {
    constructor(prop) {
        super(prop);
        this.renderh = () => {
            const headers = this.props.headers;
            return React.createElement("tr", null, headers.map((h) => {
                let width = 150;
                return React.createElement("th", { key: Math.random() + "", style: { width: width, minWidth: 150 } },
                    React.createElement(Box, { w: width + 'px' },
                        React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                            React.createElement(Text, null, h))));
            }));
        };
        this.renrow = (row) => {
            return React.createElement(React.Fragment, null, row.map((r) => {
                var _a;
                return React.createElement("td", { key: Math.random() + "" },
                    React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                        React.createElement(Text, null, (_a = stripHTML(r)) !== null && _a !== void 0 ? _a : '')));
            }));
        };
        this.state = {
            filtertext: ''
        };
    }
    checkcansowrow(r) {
        if (!this.state.filtertext.trim())
            return true;
        for (const sr of r) {
            const val = sr + '';
            if (val.trim().toLowerCase().indexOf(this.state.filtertext.trim().toLowerCase()) !== -1)
                return true;
        }
        return false;
    }
    render() {
        const headers = this.props.headers;
        const rows = this.props.rows.filter((r) => this.checkcansowrow(r));
        return React.createElement(Box, null,
            React.createElement(Input, { m: '10px', width: '200px', placeholder: 'Filter content', value: this.state.filtertext, onChange: (e) => {
                    this.setState({ filtertext: e.currentTarget.value });
                } }),
            React.createElement(TableVirtuoso, { className: "styled-table", overscan: 50, data: rows, style: { height: window.innerHeight < 500 ? window.innerHeight - 40 : 500 }, components: {
                    EmptyPlaceholder: () => {
                        return (React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", null, "Empty"))));
                    },
                    // set the colspan below to the amount of columns you have.
                    FillerRow: ({ height }) => {
                        return (React.createElement("tr", null,
                            React.createElement("td", { colSpan: headers.length, style: { height: height, padding: 0, border: 0 } })));
                    }
                }, fixedHeaderContent: () => React.createElement(Observer, null, () => this.renderh()), itemContent: (index, data) => this.renrow(data) }));
    }
}
class CustomSidePanelCard extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            filtertext: ''
        };
    }
    checkcansowrow(r) {
        if (!this.state.filtertext.trim())
            return true;
        for (const sr of r) {
            const val = sr + '';
            if (val.trim().toLowerCase().indexOf(this.state.filtertext.trim().toLowerCase()) !== -1)
                return true;
        }
        return false;
    }
    buildheaders() {
        const dh = [];
        for (let i = 0; i < this.props.headers.length;) {
            const h = this.props.headers[i];
            const ret = [];
            ret.push(h);
            i++;
            if (this.props.headers.length > i) {
                const h2 = this.props.headers[i];
                i++;
                ret.push(h2);
            }
            dh.push(ret);
        }
        return dh;
    }
    render_card(data, headers, myindex) {
        const totalwidth = window.innerWidth < 700 ? window.innerWidth - 50 : 700;
        const mycolo = myindex % 2 === 0 ? cardcolors[0][1] : cardcolors[1][1];
        return React.createElement(Box, { margin: '10px', style: {
                backgroundColor: mycolo
            }, minH: '100px', w: totalwidth, ml: window.innerWidth < 600 ? "0px" : '20px', boxShadow: '5px 0 5px 5px #b6b6b6', className: 'card-items' }, headers.map((h, outindex) => {
            return React.createElement(Flex, { marginTop: '5px', minH: '50px', key: Math.random() + "", w: '100%' }, h.map((sh, index) => {
                var _a;
                let offset = outindex * 2;
                const dd = data.length > index ? (_a = stripHTML(data[offset + index])) !== null && _a !== void 0 ? _a : '' : '';
                return React.createElement(Box, { ml: '5px', key: Math.random() + "", w: (95 / h.length) + "%" },
                    React.createElement(Flex, { w: '100%', align: 'center', justify: 'center' },
                        React.createElement(Text, { fontWeight: 'bold', fontSize: '15px' }, sh)),
                    React.createElement(Box, null,
                        React.createElement(Flex, { w: '100%', justify: 'center', align: 'center' },
                            React.createElement(Text, null, dd))));
            }));
        }));
    }
    render() {
        const headers = this.props.headers;
        const rows = this.props.rows.filter((r) => this.checkcansowrow(r));
        const dh = this.buildheaders();
        return React.createElement(Box, null,
            React.createElement(Input, { m: '10px', width: '200px', placeholder: 'Filter content', value: this.state.filtertext, onChange: (e) => {
                    this.setState({ filtertext: e.currentTarget.value });
                } }),
            rows.length > 0 && React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: viewheight() }, data: rows, itemContent: (index, row) => this.render_card(row, dh, index) })));
    }
}
let CustomSidePanel = class CustomSidePanel extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            activetabid: '',
            mybox: undefined,
        };
    }
    componentDidMount() {
        if (this.state.activetabid !== this.props.defaulttab) {
            const mb = this.props.ontabclick(this.props.tabs.filter((tab) => tab.tabid === this.props.defaulttab)[0]);
            this.setState({ activetabid: this.props.defaulttab, mybox: mb });
        }
    }
    render() {
        return React.createElement(Box, { zIndex: 10000, minH: '500px', maxW: window.innerWidth < 700 ? window.innerWidth - 50 : 700, pos: 'fixed', backgroundColor: '#ffffff', boxShadow: '5px 5px 5px 5px lightgray', left: this.props.paneldirection === 'left' ? 5 : undefined, right: this.props.paneldirection === 'right' ? 5 : undefined, top: this.props.top, w: window.innerWidth < 700 ? window.innerWidth - 50 : 700 },
            React.createElement(Box, { w: '100%', pos: 'relative' },
                React.createElement(Flex, { maxW: '90%', flexWrap: 'wrap', align: 'center', margin: '10px' }, this.props.tabs.map((tab) => React.createElement(Text, { key: tab.tabid, _hover: { cursor: 'pointer' }, onClick: () => {
                        if (this.state.activetabid === tab.tabid)
                            return;
                        const mybody = this.props.ontabclick(tab);
                        this.setState({ activetabid: tab.tabid, mybox: mybody });
                    }, className: this.state.activetabid === tab.tabid ? 'tab-item-mobile active' : 'tab-item-mobile', fontWeight: 'bold' }, tab.tablable))),
                React.createElement(Icon, { as: MdClose, color: 'red', w: '25px', h: '25px', pos: 'absolute', right: '10px', top: '5px', _hover: { cursor: 'pointer' }, onClick: () => {
                        this.props.onClose();
                    } })),
            React.createElement(Box, { mt: '10px' }, this.state.mybox !== undefined && this.state.mybox));
    }
};
CustomSidePanel = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomSidePanel);
let ChoiceInput = class ChoiceInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.supportidmap = new Map();
        this.bidmap = new Map();
        this.ref = null;
        this.state = {
            id: this.props.defaultvalue.split(",")
        };
    }
    componentDidMount() {
        this.supportidmap.clear();
        this.bidmap.clear();
        for (const ci of this.props.list) {
            if (ci.supportid) {
                const parts = ci.supportid.split("---");
                if (parts.length > 1) {
                    const p1 = parts[0];
                    if (p1) {
                        this.bidmap.set(p1, ci.id);
                    }
                    const p2 = parts[1];
                    if (p2) {
                        this.supportidmap.set(p2, ci.id);
                    }
                }
            }
        }
    }
    render() {
        const rstore = this.context;
        if (this.props.multiselect) {
            return React.createElement(Box, { width: '100%' },
                React.createElement(Select, { isMulti: true, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), selectedOptionStyle: "check", maxMenuHeight: 100, isSearchable: true, value: this.props.list.filter((lt) => {
                        if (this.state.id.indexOf(lt.id) != -1) {
                            return true;
                        }
                        return false;
                    }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                        this.setState({ id: e.map((e) => e.value) });
                        this.props.onChange(e.map((lt) => lt.value).join(","));
                    }, closeMenuOnSelect: false }));
        }
        return React.createElement(Box, { width: '100%' },
            React.createElement(Select, { selectedOptionStyle: "check", maxMenuHeight: 100, isSearchable: true, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), value: this.props.list.filter((lt) => {
                    if (this.state.id.indexOf(lt.id) != -1) {
                        return true;
                    }
                    return false;
                }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                    this.setState({ id: [e.value] });
                    this.props.onChange(e.value);
                }, size: 'sm' }));
    }
};
ChoiceInput.contextType = StoreContext;
ChoiceInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ChoiceInput);
let ColumnsView = class ColumnsView extends React.Component {
    render() {
        const rstore = this.context;
        if (!rstore.getPwaStore().showcolumnview) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Box, { w: '200px', zIndex: 20000, backgroundColor: 'white', boxShadow: '10px 10px 10px lightgray', pos: 'fixed', left: rstore.getPwaStore().cfx - 60, top: rstore.getPwaStore().cfy + 60, maxH: '200px', overflowY: 'auto' }, rstore.getPwaStore().currentitems.map((field) => React.createElement(Flex, { align: 'center', ml: '5px', w: '100%', h: '40px', key: field.id, _hover: { cursor: 'pointer', opacity: '0.9', backgroundColor: '#829eb975' } },
            React.createElement(Checkbox, { defaultChecked: !rstore.getPwaStore().hiddencolumns.has(field.id), checked: !rstore.getPwaStore().hiddencolumns.has(field.id), onChange: (e) => {
                    if (e.currentTarget.checked) {
                        rstore.getPwaStore().showColumn(field.id);
                    }
                    else {
                        rstore.getPwaStore().hideColumn(field.id);
                    }
                } }),
            React.createElement(Text, { title: field.name, ml: '10px', overflow: 'hidden', textOverflow: 'ellipsis', maxW: '200px', fontWeight: 'bold' }, field.name))));
    }
};
ColumnsView.contextType = StoreContext;
ColumnsView = __decorate([
    observer
], ColumnsView);
export { ColumnsView };
let SchemaRowUI = class SchemaRowUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    selectOrUnselectRow() {
        const rstore = this.context;
        if (!rstore.getPwaStore().currentpermissions.has("editrow"))
            return;
        const isview = rstore.getPwaStore().selecteditemid.indexOf("web-") === 0;
        if (!isview) {
            return;
        }
        if (rstore.getPwaStore().pwaeditstore.activerowid === this.props.row.rowid) {
            rstore.getPwaStore().pwaeditstore.setActiveRowId("");
        }
        else {
            rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.row.rowid);
            rstore.getPwaStore().pwaeditstore.setShow(true);
        }
    }
    showAlert(item, en) {
        const rstore = this.context;
        if (en === undefined) {
            return false;
        }
        return rstore.getPwaStore().checkRowEnnrtyNeedAlert(this.props.row, en.cellid);
    }
    arrangeCells(ens) {
        // the way to arrange these will be via the 
        var _a;
        ens.sort((en1, en2) => {
            var _a, _b;
            const s1 = (_a = en1.subrowindex) !== null && _a !== void 0 ? _a : -1;
            const s2 = (_b = en2.subrowindex) !== null && _b !== void 0 ? _b : -1;
            if (s1 === -1 && s2 === -1) {
                return 0;
            }
            if (s1 === -1) {
                return 1;
            }
            if (s2 === -1) {
                return -1;
            }
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (parts1[0] === parts2[0]) {
                // if(parts1.length < 2 || parts2.length < 2) {
                //     return -1;
                // }
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        let trow = [];
        const rows = [];
        for (const en of ens) {
            const s1 = (_a = en.subrowindex) !== null && _a !== void 0 ? _a : -1;
            if (s1 !== -1) {
                if (trow.indexOf(s1) === -1) {
                    trow.push(s1);
                }
            }
        }
        return trow;
    }
    render() {
        var _a, _b;
        const rstore = this.context;
        const fields = rstore.getPwaStore().currentitems.slice().
            sort((it1, it2) => it1.orderindex - it2.orderindex).filter((fld) => !rstore.getPwaStore().hiddencolumns.has(fld.id));
        const emap = new Map();
        const sh = rstore.getPwaStore().getActiveSchema();
        if (sh === undefined) {
            return React.createElement(Box, null);
        }
        let lm = 0;
        let fmap = new Map();
        for (const f of fields) {
            fmap.set(f.id, f);
            if (f.type === SchemaItemType.List) {
                const fl = f;
                for (const fli of fl.items) {
                    fmap.set(f.id + "----" + fli.id, fli.item);
                }
            }
        }
        for (const en of this.props.row.entry) {
            if (!emap.has(en.cellid)) {
                emap.set(en.cellid, []);
            }
            if (!en.decrypted && sh) {
                if (en.ruleopen) {
                    rstore.getPwaStore().parseCellRules(en, sh);
                }
                else {
                    rstore.getPwaStore().decryptCell(en, sh);
                }
            }
            if (en.decrypted) {
                (_a = emap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
            if (fmap.has(en.cellid) && en.updatetime && en.updatetime > lm) {
                lm = en.updatetime;
            }
        }
        let colchoices = new Map();
        for (const fld of fields) {
            const ens = emap.get(fld.id);
            if (fld.type !== SchemaItemType.List && fld.type === SchemaItemType.Choice) {
                const citm = fld;
                if (citm.isStatus) {
                    if (ens) {
                        const en = ens[0];
                        const ebots = en.celldata.split(",");
                        if (ebots.length > 0) {
                            if (!citm.multiselect) {
                                const cval = ebots[0];
                                for (const val of fld.items) {
                                    if (cval === (val.id) && val.color && val.color.length > 0) {
                                        colchoices.set(val.id, val);
                                    }
                                }
                            }
                            else {
                                for (const val of fld.items) {
                                    if (ebots.indexOf(val.id) !== -1) {
                                        colchoices.set(val.id, val);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (fld.type === SchemaItemType.List) {
                const litem = fld;
                let displayitems = (((_b = litem.items) !== null && _b !== void 0 ? _b : []).map((it) => (Object.assign(Object.assign({}, it.item), { id: litem.id + "----" + it.item.id }))));
                const sortedmap = new Map();
                let sortedrows = [];
                displayitems.forEach((item) => {
                    const ens = emap.get(item.id);
                    if (ens) {
                        ens.sort(sortEntriesByRow);
                        ens.forEach((en) => {
                            if (en.subrowindex) {
                                if (sortedrows.indexOf(en.subrowindex) === -1) {
                                    sortedrows.push(en.subrowindex);
                                }
                                if (!sortedmap.has(en.subrowindex)) {
                                    sortedmap.set(en.subrowindex, new Map());
                                }
                                const cellid = en.cellid;
                                let cellmap = sortedmap.get(en.subrowindex);
                                if (!cellmap) {
                                    cellmap = new Map();
                                    sortedmap.set(en.subrowindex, cellmap);
                                }
                                cellmap.set(cellid, [en]);
                            }
                        });
                    }
                });
                sortedrows.sort((s1, s2) => {
                    const parts1 = s1.split("----");
                    const parts2 = s2.split("----");
                    if (parts1[0] === parts2[0]) {
                        // if(parts1.length < 2 || parts2.length < 2) {
                        //     return -1;
                        // }
                        return parts1[1].localeCompare(parts2[1]);
                    }
                    return parseInt(parts1[0]) - parseInt(parts2[0]);
                });
                for (let i = 0; i < sortedrows.length; i++) {
                    const sr = sortedrows[i];
                    for (const entry of displayitems) {
                        if (entry.type === SchemaItemType.Choice) {
                            const citm = entry;
                            if (citm.isStatus) {
                                const enmap = sortedmap.get(sr);
                                if (enmap) {
                                    const en = enmap === null || enmap === void 0 ? void 0 : enmap.get(entry.id);
                                    if (en && en.length > 0) {
                                        const ebots = en[0].celldata.split(",");
                                        if (ebots.length > 0) {
                                            if (!citm.multiselect) {
                                                const cval = ebots[0];
                                                for (const val of citm.items) {
                                                    if (cval === (val.id) && val.color && val.color.length > 0) {
                                                        colchoices.set(val.id, val);
                                                    }
                                                }
                                            }
                                            else {
                                                for (const val of citm.items) {
                                                    if (ebots.indexOf(val.id) !== -1 && val.color && val.color.length > 0) {
                                                        colchoices.set(val.id, val);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        let stickyleft = 96;
        return React.createElement(React.Fragment, null,
            React.createElement("td", { style: { zIndex: 1, 'position': 'sticky', left: 0, 'backgroundColor': rstore.getPwaStore().pwaeditstore.activerowid === this.props.row.rowid ? '#829eb9' : 'white' } }, (rstore.getPwaStore().currentpermissions.has("editrow") || rstore.getPwaStore().currentpermissions.has("addrow")) &&
                React.createElement(Flex, { w: '100%', justify: 'center' },
                    React.createElement(Observer, null, () => React.createElement(Checkbox, { isChecked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.row.rowid), checked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.row.rowid), onChange: (e) => e.currentTarget.checked ?
                            rstore.getPwaStore().pwaeditstore.setSelectedRow(this.props.row) :
                            rstore.getPwaStore().pwaeditstore.setUnSelectedRow(this.props.row.rowid) })))),
            React.createElement("td", { onClick: () => {
                    this.selectOrUnselectRow();
                }, style: {
                    zIndex: 1, 'position': 'sticky', left: 46, backgroundColor: rstore.getPwaStore().pwaeditstore.activerowid === this.props.row.rowid ? '#829eb9' :
                        rstore.getPwaStore().triggerrowids.has(this.props.row.rowid) ? '#ee5555' : 'white'
                } },
                React.createElement(Flex, { w: '100%', justify: 'flex-end', align: 'center' },
                    React.createElement(Flex, { align: 'center', flexWrap: 'wrap', maxW: '100px', mr: '2px' }, Array.from(colchoices.values()).map((col) => {
                        return React.createElement(Box, { _hover: { cursor: 'pointer' }, key: 'color_col_main ' + this.props.index + "" + col.id, borderWidth: 1, borderColor: '#404040', title: col.name, margin: '2px', borderRadius: '10px', width: '20px', height: '20px', bg: col.color });
                    })),
                    React.createElement(Text, { style: { wordBreak: 'keep-all' } }, this.props.index))),
            fields.map((en) => {
                var _a, _b;
                let myleft = stickyleft;
                let width = (_a = rstore.getPwaStore().columnwidths.get(en.id)) !== null && _a !== void 0 ? _a : 300;
                ;
                let ref = null;
                let subitemwidth = 150;
                let llength = 0;
                if (en.type === SchemaItemType.List && en.items && en.items.length > 0) {
                    llength = en.items.length;
                    width = llength * 150;
                    if (width < 250) {
                        width = 250;
                    }
                    subitemwidth = width / llength;
                }
                if (en.freese) {
                    stickyleft += width;
                }
                return React.createElement("td", { onClick: () => {
                        this.selectOrUnselectRow();
                    }, style: {
                        zIndex: en.freese ? 1 : 0,
                        left: en.freese ? myleft : undefined,
                        position: en.freese ? 'sticky' : undefined,
                        width: width,
                        'backgroundColor': rstore.getPwaStore().pwaeditstore.activerowid === this.props.row.rowid ? '#829eb975' :
                            this.showAlert(en.id, (_b = emap.get(en.id)) === null || _b === void 0 ? void 0 : _b[0]) ? '#ee5555' : (en.freese ? 'white' :
                                undefined)
                    }, 
                    // style={{ 'backgroundColor': rstore.getPwaStore().pwaeditstore.activerowid === this.props.row.rowid ? '#829eb975' : this.showAlert(en.id, emap.get(en.id)?.[0]) ? '#ee5555' : undefined }}
                    key: en.id },
                    React.createElement(DisplayItemRow, { width: width, rowui: this.props.row, item: en, entry: emap, isGrid: true }));
            }),
            ["LM"].map(item => {
                var _a;
                let width = (_a = rstore.getPwaStore().columnwidths.get(item)) !== null && _a !== void 0 ? _a : 300;
                let ref = null;
                let subitemwidth = 150;
                let llength = 0;
                return React.createElement("td", { onClick: () => {
                        this.selectOrUnselectRow();
                    }, style: {
                        width: width,
                        minWidth: width,
                        zIndex: 0,
                        left: undefined,
                        position: undefined,
                        'backgroundColor': rstore.getPwaStore().activerowid === this.props.row.rowid ? '#829eb975' :
                            undefined
                    }, key: "LM_" + this.props.row.rowid },
                    React.createElement(Flex, { flexWrap: 'wrap', w: '100%', justify: 'center' },
                        React.createElement(Text, null, DateLib.displayDateWIthFormat(lm, true))));
            }));
    }
};
SchemaRowUI.contextType = StoreContext;
SchemaRowUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], SchemaRowUI);
let FilterView = class FilterView extends React.Component {
    sort(r1, r2) {
        return r1.rowindex - r2.rowindex;
    }
    render() {
        const rstore = this.context;
        const width = window.innerWidth < 600 ? window.innerWidth - 50 : 500;
        return React.createElement(Box, { pos: 'fixed', top: window.innerHeight / 4 + "px", left: '5%', bg: 'white', className: rstore.getPwaStore().showrowsilter ? 'slide-right' : '', zIndex: 1000 },
            React.createElement(Box, { w: width, boxShadow: '10px 10px 10px lightgray', borderColor: AppColor.FilterHeaderFontColor, borderWidth: 1, maxH: '500px', overflow: 'auto' },
                React.createElement(Suspense, { fallback: React.createElement(Flex, { flexDirection: 'column', style: { height: '100%' }, justify: 'center', align: 'center' },
                        React.createElement(ScaleLoader, { color: 'green' }),
                        React.createElement(Text, { style: { fontWeight: 'bold', fontSize: '15px' } }, "Intialising Content...")) },
                    React.createElement(PwaFilterPanel, { onClosePress: () => {
                            rstore.getPwaStore().setShowRowFilter(false);
                            rstore.getPwaStore().showAllRows();
                        }, onApply: async () => {
                            rstore.wdialog.setMessage("Filtering rows...");
                            await rstore.getPwaStore().slowFilterRows(false, () => {
                                rstore.wdialog.setOpen(true);
                            }, (msg) => rstore.wdialog.setMessage(msg));
                            rstore.wdialog.setOpen(false);
                            rstore.getPwaStore().setShowRowFilter(false);
                            rstore.getPwaStore().selectFirstRow();
                        }, dependentdata: rstore.getPwaStore().dependentdata, state: rstore.getPwaStore().filterdialogstate, items: this.props.displayitems.filter((item) => item.type !== SchemaItemType.CustomDisplayCol) }))));
    }
};
FilterView.contextType = StoreContext;
FilterView = __decorate([
    observer
], FilterView);
export { FilterView };
let EditRowView = class EditRowView extends React.Component {
    render() {
        const rstore = this.context;
        const width = (window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100) + "px";
        const height = isMobile() ? window.innerHeight - 50 : viewheight() + 50;
        return React.createElement(Box, { pos: 'absolute', top: "140px", bg: 'white', zIndex: 100 },
            React.createElement(Box, { w: width, boxShadow: '10px 10px 10px lightgray', borderColor: AppColor.FilterHeaderFontColor, borderWidth: 1, maxH: height, overflow: 'auto' },
                React.createElement(Suspense, { fallback: React.createElement(Flex, { flexDirection: 'column', style: { height: '100%' }, justify: 'center', align: 'center' },
                        React.createElement(ScaleLoader, { color: 'green' }),
                        React.createElement(Text, { style: { fontWeight: 'bold', fontSize: '15px' } }, "Intialising Content...")) },
                    React.createElement(PwaEditForm, { deps: rstore.getPwaStore().dependentdata, schemaitems: this.props.items, state: rstore.getPwaStore().pwaeditstore, onSave: async () => {
                            await rstore.getPwaStore().saveActiveRowEdits();
                            rstore.getPwaStore().pwaeditstore.reset();
                        }, onCancel: () => {
                            rstore.getPwaStore().pwaeditstore.reset();
                            rstore.getPwaStore().setShowScrollToButton(false);
                        } }))));
    }
};
EditRowView.contextType = StoreContext;
EditRowView = __decorate([
    observer
], EditRowView);
export { EditRowView };
let RowDetail = class RowDetail extends React.Component {
    render() {
        const rstore = this.context;
        const width = (window.innerWidth < 1100 ? window.innerWidth - 20 : window.innerWidth - 100) + "px";
        const height = viewheight() - 50;
        return React.createElement(Box, { bg: 'white', zIndex: 100 },
            React.createElement(Box, { w: '100%', maxH: height },
                React.createElement(Suspense, { fallback: React.createElement(Flex, { flexDirection: 'column', style: { height: '100%' }, justify: 'center', align: 'center' },
                        React.createElement(ScaleLoader, { color: 'green' }),
                        React.createElement(Text, { style: { fontWeight: 'bold', fontSize: '15px' } }, "Intialising Content...")) },
                    React.createElement(PwaDetail, { virtref: this.props.virtref, deps: rstore.getPwaStore().dependentdata, schemaitems: this.props.items, state: rstore.getPwaStore().pwaeditstore, onCancel: () => {
                            rstore.getPwaStore().pwaeditstore.reset();
                            rstore.getPwaStore().setShowScrollToButton(false);
                        } }))));
    }
};
RowDetail.contextType = StoreContext;
RowDetail = __decorate([
    observer
], RowDetail);
export { RowDetail };
let SortPanel = class SortPanel extends React.Component {
    render() {
        const rstore = this.context;
        return React.createElement(Box, { pos: 'fixed', top: window.innerHeight / 4 + "px", left: '5%', bg: 'white', className: rstore.getPwaStore().showrowsilter ? 'slide-right' : '', zIndex: 1000 },
            React.createElement(Box, { w: '500px', boxShadow: '10px 10px 10px lightgray', borderColor: AppColor.FilterHeaderFontColor, borderWidth: 1, maxH: '500px', overflow: 'auto' },
                React.createElement(Suspense, { fallback: React.createElement(Flex, { flexDirection: 'column', style: { height: '100%' }, justify: 'center', align: 'center' },
                        React.createElement(ScaleLoader, { color: 'green' }),
                        React.createElement(Text, { style: { fontWeight: 'bold', fontSize: '15px' } }, "Intialising Content...")) },
                    React.createElement(PwaSortPanel, { onClosePress: () => {
                            rstore.getPwaStore().setShowSortDialog(false);
                            rstore.getPwaStore().showAllRows();
                        }, onApply: () => {
                            rstore.getPwaStore().filterRows();
                            rstore.getPwaStore().setShowRowFilter(false);
                        }, state: rstore.getPwaStore().sortdialogstate, items: this.props.displayitems }))));
    }
};
SortPanel.contextType = StoreContext;
SortPanel = __decorate([
    observer
], SortPanel);
export { SortPanel };
let Summary = class Summary extends React.Component {
    render() {
        const rstore = this.context;
        let updatemessage = [];
        const jobcoun = rstore.getPwaStore().getJobStatsForSelectedItem();
        if (jobcoun[0] > 0) {
            updatemessage.push("Adding " + jobcoun[0]);
        }
        if (jobcoun[1] > 0) {
            updatemessage.push("Updating " + jobcoun[1]);
        }
        if (jobcoun[2] > 0) {
            updatemessage.push("Deleting " + jobcoun[1]);
        }
        let msg = '';
        if (updatemessage.length > 0) {
            msg = "Summary : " + updatemessage.join(", ");
            msg += " row(s)";
        }
        if (!msg) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Flex, { justify: 'space-between', align: 'center' },
            React.createElement(Text, { color: 'orange' }, msg));
    }
};
Summary.contextType = StoreContext;
Summary = __decorate([
    observer
], Summary);
let FormMessage = class FormMessage extends React.Component {
    render() {
        const rstore = this.context;
        const msg = rstore.getPwaStore().formmessage;
        if (!msg) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(Flex, { justify: 'space-between', align: 'center' },
            React.createElement(Text, { color: 'red' }, msg),
            React.createElement(Icon, { as: MdClose, width: '25px', height: '25px', _hover: { cursor: 'pointer' }, onClick: () => rstore.getPwaStore().addFormmessage("") }));
    }
};
FormMessage.contextType = StoreContext;
FormMessage = __decorate([
    observer
], FormMessage);
let AddEntry = class AddEntry extends React.Component {
    render() {
        return React.createElement(PwaForm, null);
    }
};
AddEntry = __decorate([
    observer
], AddEntry);
let AddEntryViaAi = class AddEntryViaAi extends React.Component {
    render() {
        console.log("we here??");
        return React.createElement(PwaAiForm, null);
    }
};
AddEntryViaAi = __decorate([
    observer
], AddEntryViaAi);
let RowDisplay = class RowDisplay extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Flex, { margin: '10px' }, this.props.rows.map((row, index) => React.createElement(ViewCard, { key: row.rowid, rowui: row, displayitems: this.props.items, virtref: this.props.virtref })));
    }
};
RowDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RowDisplay);
let PanelDisplay = class PanelDisplay extends React.Component {
    constructor(prop) {
        super(prop);
    }
    componentDidMount() {
        const rstore = this.context;
        let perms = new Set();
        if (this.props.rowsarr.length > 0) {
            rstore.getPwaStore().pwaeditstore.setSelectedId(this.props.rowsarr[0].rowid);
            rstore.getPwaStore().pwaeditstore.setShowDetail(true);
        }
    }
    render() {
        const rstore = this.context;
        const items = rstore.getPwaStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex).filter((fld) => !rstore.getPwaStore().hiddencolumns.has(fld.id));
        // if (this.props.rowsarr.length === 0) {
        // }
        return React.createElement(HStack, null,
            React.createElement(Box, { w: '30%', height: viewheight() },
                React.createElement(Observer, null, () => React.createElement(Virtuoso, { style: { height: viewheight() }, data: this.props.rowsarr, ref: (ref) => {
                        rstore.getPwaStore().panelref = ref;
                    }, 
                    // isScrolling={(isc) => this.showScrollToEditElementButton(isc)}
                    itemContent: (index, row) => React.createElement(LeftPanelCard, { key: row.rowid, rowui: row, displayitems: this.props.items, virtref: this.props.virtref }) }))),
            React.createElement(Box, { width: '70%', height: viewheight() },
                React.createElement(RowDetail, { items: items, virtref: this.props.virtref })));
    }
};
PanelDisplay.contextType = StoreContext;
PanelDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], PanelDisplay);
let ViewCard = class ViewCard extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        const rstore = this.context;
        const myids = new Set();
        const enmap = new Map();
        const sh = rstore.getPwaStore().getActiveSchema();
        let fmap = new Map();
        for (const f of this.props.displayitems) {
            fmap.set(f.id, f);
            if (f.type === SchemaItemType.List) {
                const fl = f;
                for (const fli of fl.items) {
                    fmap.set(f.id + "----" + fli.id, fli.item);
                }
            }
        }
        let lm = 0;
        for (const en of this.props.rowui.entry) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            if (!en.decrypted && sh) {
                if (en.ruleopen) {
                    rstore.getPwaStore().parseCellRules(en, sh);
                }
                else {
                    rstore.getPwaStore().decryptCell(en, sh);
                }
            }
            if (en.decrypted) {
                (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
            if (fmap.has(en.cellid) && en.updatetime && en.updatetime > lm) {
                lm = en.updatetime;
            }
        }
        let listindex = new Set();
        let index = 0;
        for (const item of this.props.displayitems) {
            if (item.type === SchemaItemType.List) {
                listindex.add(index);
            }
            index++;
        }
        const displyitemsrow = [];
        let mitems = [];
        for (let i = 0; i < this.props.displayitems.length; i++) {
            const item = this.props.displayitems[i];
            if (item.type === SchemaItemType.List || item.type === SchemaItemType.Remote) {
                if (mitems.length > 0) {
                    displyitemsrow.push(mitems);
                    mitems = [];
                }
                displyitemsrow.push([item]);
            }
            else {
                if (mitems.length > 1) {
                    displyitemsrow.push(mitems);
                    mitems = [];
                }
                mitems.push(item);
            }
        }
        if (mitems.length > 0) {
            displyitemsrow.push(mitems);
        }
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : Math.floor((window.innerWidth - 165) / 2);
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get(this.props.rowui.rowid);
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        const items = rstore.getPwaStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        // if (rstore.getPwaStore().pwaeditstore.activerowid === this.props.rowui.rowid && rstore.getPwaStore().currentpermissions.has("editrow")) {
        //     return <Box ml={window.innerWidth < 600 ? '0px' : '20px'} w={totalwidth + "px"}>
        //         <PwaEditForm
        //             deps={rstore.getPwaStore().dependentdata}
        //             schemaitems={items} state={rstore.getPwaStore().pwaeditstore}
        //             onSave={async () => {
        //                 await rstore.getPwaStore().saveActiveRowEdits();
        //                 rstore.getPwaStore().pwaeditstore.reset();
        //             }}
        //             onCancel={() => {
        //                 rstore.getPwaStore().pwaeditstore.reset();
        //                 rstore.getPwaStore().setShowScrollToButton(false);
        //             }}
        //         />
        //     </Box>
        // }
        return React.createElement(Box, { ml: window.innerWidth < 600 ? '0px' : '20px', w: totalwidth + "px", bg: this.props.rowui.uirowindex > -1 ? (this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][1] : cardcolors[1][1]) : 'white', boxShadow: '0 0 5px #b6b6b6', "data-page": this.props.rowui.uirowindex, className: "card-items" },
            React.createElement(Flex, { align: 'center' },
                React.createElement(Flex, { w: '50%' }, rstore.getPwaStore().triggerrowids.has(this.props.rowui.rowid) &&
                    React.createElement(Icon, { as: MdError, w: '25px', h: '25px', color: 'red', marginLeft: '5px' })),
                React.createElement(Flex, { w: '50%', justify: 'flex-end' },
                    React.createElement(Observer, null, () => React.createElement(Checkbox, { mx: '10px', w: '25px', h: '25px', isChecked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.rowui.rowid), checked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.rowui.rowid), onChange: (e) => e.currentTarget.checked ?
                            rstore.getPwaStore().pwaeditstore.setSelectedRow(this.props.rowui) :
                            rstore.getPwaStore().pwaeditstore.setUnSelectedRow(this.props.rowui.rowid) })),
                    React.createElement(Text, null, DateLib.displayDateWIthFormat(lm, true)),
                    perms.has("editrow") && React.createElement(Icon, { as: FaEdit, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            var _a, _b;
                            rstore.getPwaStore().pwaeditstore.setRowIndex(this.props.rowui.uirowindex);
                            rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                            rstore.getPwaStore().pwaeditstore.setShow(true);
                            (_b = (_a = this.props.virtref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex({
                                index: rstore.getPwaStore().pwaeditstore.rowindex,
                                behavior: 'smooth',
                                align: 'start'
                            });
                        } }),
                    !perms.has("editrow") && rstore.getPwaStore().customrowjs.trim() && React.createElement(Icon, { as: MdInfo, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            var _a, _b;
                            rstore.getPwaStore().pwaeditstore.setRowIndex(this.props.rowui.uirowindex);
                            rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                            rstore.getPwaStore().pwaeditstore.setShow(true);
                            (_b = (_a = this.props.virtref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex({
                                index: rstore.getPwaStore().pwaeditstore.rowindex,
                                behavior: 'smooth',
                                align: 'start'
                            });
                        } }),
                    (perms.has("editrow") || perms.has("addrow")) && React.createElement(Icon, { as: MdDelete, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            rstore.confirmStore.setMessage("Are you sure you delete this entry from the history?");
                            rstore.confirmStore.setOkCB(() => {
                                rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                                rstore.getPwaStore().deleteRows();
                            });
                            rstore.confirmStore.setOpen(true);
                        } }))),
            React.createElement(Box, { padding: '5px' }, displyitemsrow.map((entrys, index) => {
                return React.createElement(Flex, { mt: '10px', key: this.props.rowui.rowid + "_entryitems" + "_" + index, w: '100%', justifyContent: 'space-between' }, entrys.map((en) => React.createElement(Box, { key: en.id, w: en.type === SchemaItemType.List || en.type === SchemaItemType.Remote ? totalwidth + 'px' : totalwidth / 2 + 'px' },
                    React.createElement(Flex, { color: this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][0] : cardcolors[1][0], w: '100%', justify: (en.type === SchemaItemType.List || en.type === SchemaItemType.Remote) ?
                            'center' : 'flex-start', align: 'center' },
                        React.createElement(Text, { fontWeight: 'bold' }, en.name)),
                    React.createElement(DisplayItemRow, { rowui: this.props.rowui, item: en, entry: enmap, isGrid: false }))));
            })));
    }
};
ViewCard.contextType = StoreContext;
ViewCard = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ViewCard);
let DetailCard = class DetailCard extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        const rstore = this.context;
        const myids = new Set();
        const enmap = new Map();
        const sh = rstore.getPwaStore().getActiveSchema();
        for (const en of this.props.rowui.entry) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            if (!en.decrypted && sh) {
                if (en.ruleopen) {
                    rstore.getPwaStore().parseCellRules(en, sh);
                }
                else {
                    rstore.getPwaStore().decryptCell(en, sh);
                }
            }
            if (en.decrypted) {
                (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
        }
        let listindex = new Set();
        let index = 0;
        for (const item of this.props.displayitems) {
            if (item.type === SchemaItemType.List) {
                listindex.add(index);
            }
            index++;
        }
        const displyitemsrow = [];
        let mitems = [];
        for (let i = 0; i < this.props.displayitems.length; i++) {
            const item = this.props.displayitems[i];
            if (item.type === SchemaItemType.List || item.type === SchemaItemType.Remote) {
                if (mitems.length > 0) {
                    displyitemsrow.push(mitems);
                    mitems = [];
                }
                displyitemsrow.push([item]);
            }
            else {
                if (mitems.length > 1) {
                    displyitemsrow.push(mitems);
                    mitems = [];
                }
                mitems.push(item);
            }
        }
        if (mitems.length > 0) {
            displyitemsrow.push(mitems);
        }
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : Math.floor((window.innerWidth - 165) / 2);
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get(this.props.rowui.rowid);
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        const items = rstore.getPwaStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        // if (rstore.getPwaStore().pwaeditstore.activerowid === this.props.rowui.rowid && rstore.getPwaStore().currentpermissions.has("editrow")) {
        //     return <Box ml={window.innerWidth < 600 ? '0px' : '20px'} w={totalwidth + "px"}>
        //         <PwaEditForm
        //             deps={rstore.getPwaStore().dependentdata}
        //             schemaitems={items} state={rstore.getPwaStore().pwaeditstore}
        //             onSave={async () => {
        //                 await rstore.getPwaStore().saveActiveRowEdits();
        //                 rstore.getPwaStore().pwaeditstore.reset();
        //             }}
        //             onCancel={() => {
        //                 rstore.getPwaStore().pwaeditstore.reset();
        //                 rstore.getPwaStore().setShowScrollToButton(false);
        //             }}
        //         />
        //     </Box>
        // }
        return React.createElement(Box, { ml: window.innerWidth < 600 ? '0px' : '20px', w: totalwidth + "px", bg: this.props.rowui.uirowindex > -1 ? (this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][1] : cardcolors[1][1]) : 'white', boxShadow: '0 0 5px #b6b6b6', "data-page": this.props.rowui.uirowindex, className: "card-items" },
            React.createElement(Flex, { align: 'center' },
                React.createElement(Flex, { w: '50%' }, rstore.getPwaStore().triggerrowids.has(this.props.rowui.rowid) &&
                    React.createElement(Icon, { as: MdError, w: '25px', h: '25px', color: 'red', marginLeft: '5px' })),
                React.createElement(Flex, { w: '50%', justify: 'flex-end' },
                    React.createElement(Observer, null, () => React.createElement(Checkbox, { mx: '10px', w: '25px', h: '25px', isChecked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.rowui.rowid), checked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.rowui.rowid), onChange: (e) => e.currentTarget.checked ?
                            rstore.getPwaStore().pwaeditstore.setSelectedRow(this.props.rowui) :
                            rstore.getPwaStore().pwaeditstore.setUnSelectedRow(this.props.rowui.rowid) })),
                    perms.has("editrow") && React.createElement(Icon, { as: FaEdit, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            var _a, _b;
                            rstore.getPwaStore().pwaeditstore.setRowIndex(this.props.rowui.uirowindex);
                            rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                            rstore.getPwaStore().pwaeditstore.setShow(true);
                            (_b = (_a = this.props.virtref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex({
                                index: rstore.getPwaStore().pwaeditstore.rowindex,
                                behavior: 'smooth',
                                align: 'start'
                            });
                        } }),
                    !perms.has("editrow") && rstore.getPwaStore().customrowjs.trim() && React.createElement(Icon, { as: MdInfo, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            var _a, _b;
                            rstore.getPwaStore().pwaeditstore.setRowIndex(this.props.rowui.uirowindex);
                            rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                            rstore.getPwaStore().pwaeditstore.setShow(true);
                            (_b = (_a = this.props.virtref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex({
                                index: rstore.getPwaStore().pwaeditstore.rowindex,
                                behavior: 'smooth',
                                align: 'start'
                            });
                        } }),
                    (perms.has("editrow") || perms.has("addrow")) && React.createElement(Icon, { as: MdDelete, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            rstore.confirmStore.setMessage("Are you sure you delete this entry from the history?");
                            rstore.confirmStore.setOkCB(() => {
                                rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                                rstore.getPwaStore().deleteRows();
                            });
                            rstore.confirmStore.setOpen(true);
                        } }))),
            React.createElement(Box, { padding: '5px' }, displyitemsrow.map((entrys, index) => {
                return React.createElement(Flex, { mt: '10px', key: this.props.rowui.rowid + "_entryitems" + "_" + index, w: '100%', justifyContent: 'space-between' }, entrys.map((en) => React.createElement(Box, { key: en.id, w: en.type === SchemaItemType.List || en.type === SchemaItemType.Remote ? totalwidth + 'px' : totalwidth / 2 + 'px' },
                    React.createElement(Flex, { color: this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][0] : cardcolors[1][0], w: '100%', justify: (en.type === SchemaItemType.List || en.type === SchemaItemType.Remote) ?
                            'center' : 'flex-start', align: 'center' },
                        React.createElement(Text, { fontWeight: 'bold' }, en.name)),
                    React.createElement(DisplayItemRow, { rowui: this.props.rowui, item: en, entry: enmap, isGrid: false }))));
            })));
    }
};
DetailCard.contextType = StoreContext;
DetailCard = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], DetailCard);
let LeftPanelCard = class LeftPanelCard extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        const rstore = this.context;
        const myids = new Set();
        const enmap = new Map();
        const sh = rstore.getPwaStore().getActiveSchema();
        const view = rstore.getPwaStore().getActiveWebview();
        let fmap = new Map();
        for (const f of this.props.displayitems) {
            fmap.set(f.id, f);
            if (f.type === SchemaItemType.List) {
                const fl = f;
                for (const fli of fl.items) {
                    fmap.set(f.id + "----" + fli.id, fli.item);
                }
            }
        }
        if (view === undefined) {
            return React.createElement(React.Fragment, null);
        }
        let lm = 0;
        for (const en of this.props.rowui.entry) {
            if (!enmap.has(en.cellid)) {
                enmap.set(en.cellid, []);
            }
            if (!en.decrypted && sh) {
                if (en.ruleopen) {
                    rstore.getPwaStore().parseCellRules(en, sh);
                }
                else {
                    rstore.getPwaStore().decryptCell(en, sh);
                }
            }
            if (en.decrypted) {
                (_a = enmap.get(en.cellid)) === null || _a === void 0 ? void 0 : _a.push(en);
            }
            if (fmap.has(en.cellid) && en.updatetime && en.updatetime > lm) {
                lm = en.updatetime;
            }
        }
        let listindex = new Set();
        const twopanelfields = view.twopanelfields;
        let tflds = [];
        if (twopanelfields !== undefined && twopanelfields.length > 0) {
            for (let i = 0; i < twopanelfields.length; i++) {
                const f = twopanelfields[i];
                tflds.push(f);
            }
        }
        let displaypanelitems = [];
        if (tflds.length > 0) {
            let itemmap = new Map();
            this.props.displayitems.map((it) => itemmap.set(it.id, it));
            for (let i = 0; i < tflds.length; i++) {
                const tfid = tflds[i];
                const item = itemmap.get(tfid);
                if (item) {
                    displaypanelitems.push(item);
                }
            }
        }
        else {
            let idx = 0;
            for (const item of this.props.displayitems) {
                if (item.type !== SchemaItemType.List && (item.type === SchemaItemType.Text
                    || item.type === SchemaItemType.FormInput
                    || item.type === SchemaItemType.ID
                    || item.type === SchemaItemType.SquenceNumber
                    || item.type === SchemaItemType.Date)) {
                    displaypanelitems.push(item);
                    if (idx === 4) {
                        break;
                    }
                    idx++;
                }
            }
        }
        let index = 0;
        for (const item of this.props.displayitems) {
            if (item.type === SchemaItemType.List) {
                listindex.add(index);
            }
            index++;
        }
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : Math.floor((window.innerWidth - 165) / 2);
        let perms = new Set();
        const rmes = rstore.getPwaStore().rowpermissions.get(this.props.rowui.rowid);
        if (rmes) {
            perms = rmes;
        }
        else {
            perms = rstore.getPwaStore().currentpermissions;
        }
        const items = rstore.getPwaStore().currentitems.slice().sort((it1, it2) => it1.orderindex - it2.orderindex);
        return React.createElement(Box, { className: 'panel-item', bg: rstore.getPwaStore().pwaeditstore.selectedid === this.props.rowui.rowid ? AppColor.HighlightColor : 'white', "data-page": this.props.rowui.uirowindex, _hover: { cursor: 'pointer' }, onClick: () => {
                rstore.getPwaStore().pwaeditstore.setRowIndex(this.props.rowui.uirowindex);
                rstore.getPwaStore().pwaeditstore.setSelectedId(this.props.rowui.rowid);
                rstore.getPwaStore().pwaeditstore.setActiveRow(this.props.rowui.rowid, this.props.rowui);
                rstore.getPwaStore().setActiveTab('detail');
                rstore.getPwaStore().pwaeditstore.setShowDetail(true);
            } },
            React.createElement(Flex, { align: 'center' },
                React.createElement(Flex, { w: '50%' }, rstore.getPwaStore().triggerrowids.has(this.props.rowui.rowid) &&
                    React.createElement(Icon, { as: MdError, w: '25px', h: '25px', color: 'red', marginLeft: '5px' })),
                React.createElement(Flex, { w: '50%', justify: 'flex-end' },
                    React.createElement(Observer, null, () => React.createElement(Checkbox, { mx: '10px', w: '25px', h: '25px', isChecked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.rowui.rowid), checked: rstore.getPwaStore().pwaeditstore.selectedrows.has(this.props.rowui.rowid), onChange: (e) => e.currentTarget.checked ?
                            rstore.getPwaStore().pwaeditstore.setSelectedRow(this.props.rowui) :
                            rstore.getPwaStore().pwaeditstore.setUnSelectedRow(this.props.rowui.rowid) })),
                    React.createElement(Text, null, DateLib.displayDateWIthFormat(lm, true)),
                    !perms.has("editrow") && rstore.getPwaStore().customrowjs.trim() && React.createElement(Icon, { as: MdInfo, mx: '10px', w: '25px', h: '25px', _hover: { cursor: 'pointer' }, onClick: () => {
                            var _a, _b;
                            rstore.getPwaStore().pwaeditstore.setRowIndex(this.props.rowui.uirowindex);
                            rstore.getPwaStore().pwaeditstore.setActiveRowId(this.props.rowui.rowid);
                            rstore.getPwaStore().pwaeditstore.setShow(true);
                            (_b = (_a = this.props.virtref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex({
                                index: rstore.getPwaStore().pwaeditstore.rowindex,
                                behavior: 'smooth',
                                align: 'start'
                            });
                        } }))),
            React.createElement(Box, { paddingLeft: '2px' }, displaypanelitems.map((en, index) => {
                return React.createElement(Box, { key: this.props.rowui.rowid + "_entryitems" + "_" + index, w: '100%', justifyContent: 'space-between' },
                    React.createElement(Flex, { w: '100%', justify: 'flex-start', align: 'center' },
                        React.createElement(Text, { fontWeight: 'bold' }, en.name)),
                    React.createElement(DisplayItemRow, { rowui: this.props.rowui, item: en, entry: enmap, isGrid: false }));
            })));
    }
};
LeftPanelCard.contextType = StoreContext;
LeftPanelCard = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], LeftPanelCard);
class DisplayItemRow extends React.Component {
    constructor(prop) {
        super(prop);
    }
    copyLinkPassword(password, label) {
        Clipboard(password);
        toast("Password coped to the clipboard", {
            position: 'top-center',
            type: 'info'
        });
    }
    async documentClicked(dmessgae, rowid, entryid, cellid) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const rstore = this.context;
        if (dmessgae.id === rstore.getPwaStore().activeitemid) {
            rstore.getPwaStore().setViewerActive(true);
            rstore.getPwaStore().setActiveItemid(dmessgae.id, rowid, entryid);
            setTimeout(() => rstore.getPwaStore().setShow(true), 100);
            return;
        }
        PwaPdfPageStore.pages = new Map();
        rstore.getPwaStore().orgname = (_a = dmessgae.origname) !== null && _a !== void 0 ? _a : dmessgae.name;
        rstore.getPwaStore().setTitle(dmessgae.name);
        rstore.getPwaStore().setActiveItemid(dmessgae.id, rowid, entryid);
        PwaPdfPageStore.activeobjectid = dmessgae.id;
        rstore.getPwaStore().setViewerActive(true);
        // rstore.documentDetailStore.canedit = false;
        // rstore.getPwaStore().draftid = "";
        rstore.getPwaStore().info = {
            fileid: dmessgae.documentreference,
            letrid: rstore.getPwaStore().getLetrid(),
            accesskey: dmessgae.accesskey,
            boxid: dmessgae.accesscode,
            version: (_b = dmessgae.versions) !== null && _b !== void 0 ? _b : [],
            replace: []
        };
        rstore.getPwaStore().setActiveDocumentId(dmessgae.id);
        rstore.getPwaStore().setLoading(false);
        rstore.getPwaMiniViewerStore().reset();
        rstore.getPwaMiniViewerStore().activeletrid = rstore.getPwaStore().getLetrid();
        rstore.getPwaMiniViewerStore().activeobjectid = dmessgae.id;
        rstore.getPwaMiniViewerStore().activedraftid = dmessgae.documentreference;
        // rstore.draftDetailStore.letrid = rstore.projectStore.activeletrid;
        // rstore.draftDetailStore.objectid = dmessgae.id;
        // rstore.draftDetailStore.draftid = dmessgae.documentreference;
        rstore.getPwaMiniViewerStore().fileid = dmessgae.documentreference;
        rstore.getPwaMiniViewerStore().accesskey = dmessgae.accesskey;
        // rstore.miniviewStore.getAllNotes();
        let metadata = rstore.getPwaStore().filemetamap.get(dmessgae.documentreference);
        rstore.getPwaStore().activedocumentreference = dmessgae.documentreference;
        rstore.getPwaStore().activerowid = rowid;
        rstore.getPwaStore().activeaccesskey = dmessgae.accesskey;
        rstore.getPwaStore().activecellid = cellid;
        rstore.getPwaStore().activeentryid = entryid;
        setTimeout(() => rstore.getPwaStore().setShow(true), 100);
        if (metadata === undefined) {
            //Fetch metadata
            await rstore.getPwaStore().fetchMetadata((res) => {
                metadata = res;
            });
        }
        if (metadata !== undefined) {
            rstore.getPwaStore().setPdfPageDetails((_d = (_c = metadata.ingestunboxed) === null || _c === void 0 ? void 0 : _c.detail) !== null && _d !== void 0 ? _d : []);
            rstore.getPwaStore().enableWatermark(metadata.watermarkstr !== undefined);
            rstore.getPwaStore().setWatermark((_f = (_e = metadata.watermarkstr) === null || _e === void 0 ? void 0 : _e.watermark) !== null && _f !== void 0 ? _f : '');
            rstore.getPwaMiniViewerStore().setTotalPages((_j = (_h = (_g = metadata.ingestunboxed) === null || _g === void 0 ? void 0 : _g.detail) === null || _h === void 0 ? void 0 : _h.length) !== null && _j !== void 0 ? _j : 0);
            rstore.getPwaMiniViewerStore().increamentPageNumber();
            rstore.getPwaStore().resetPdfPage();
            rstore.getPwaStore().loadFindDicts((fdict) => {
                setTimeout(() => rstore.getPwaMiniViewerStore().parseAndBuildFindDic(fdict), 10);
            });
            rstore.getPwaStore().checkAndGetBookmarks((bookmarks) => {
                rstore.getPwaMiniViewerStore().bookmarkstate.reset();
                rstore.getPwaMiniViewerStore().bookmarkstate.setLoading(true);
                if (bookmarks && bookmarks.length > 0) {
                    bookmarks.forEach((pg, index) => {
                        pg.sortindex = index + 1;
                        rstore.getPwaMiniViewerStore().bookmarkstate.addBookmark(pg);
                    });
                }
                rstore.getPwaMiniViewerStore().bookmarkstate.arrangeTree();
                rstore.getPwaMiniViewerStore().bookmarkstate.setLoading(false);
            });
            if (metadata.ingestunboxed !== undefined) {
                if (!metadata.ingestunboxed.haspdf &&
                    metadata.ingestunboxed.hasviewer && metadata.ingestunboxed.isExcel) {
                    rstore.getPwaStore().setIsExcel(true);
                }
                else {
                    rstore.getPwaStore().setViewable(metadata.ingestunboxed.haspdf);
                    rstore.getPwaStore().setIsExcel(false);
                }
                rstore.getPwaStore().setQueued(false);
            }
            else {
                rstore.getPwaStore().setQueued(true);
                rstore.getPwaStore().setViewable(false);
            }
        }
    }
    render() {
        var _a, _b, _c;
        if (this.props.item.type === SchemaItemType.List) {
            return React.createElement(ListDisplay, { rowui: this.props.rowui, item: this.props.item, ens: this.props.entry, isGrid: this.props.isGrid });
        }
        const ens = this.props.entry.get(this.props.item.id);
        if (!ens || (ens === null || ens === void 0 ? void 0 : ens.length) === 0) {
            return React.createElement(Box, { h: '20px' },
                React.createElement(Text, null, ''));
        }
        const rstore = this.context;
        const en = ens[0];
        const entry = this.props.item;
        let choices = [];
        let remotevals = [];
        let udata = undefined;
        let obids = [];
        let vids = new Set();
        if (entry.type === SchemaItemType.Choice) {
            const ebots = en.celldata.split(",");
            if (ebots.length > 0) {
                if (!entry.multiselect) {
                    const cval = ebots[0];
                    for (const val of entry.items) {
                        if (cval === (val.id)) {
                            choices.push(val);
                            break;
                        }
                    }
                }
                else {
                    for (const val of entry.items) {
                        if (ebots.indexOf(val.id) !== -1) {
                            choices.push(val);
                        }
                    }
                }
            }
            en.displaystr = choices.map(c => c.name).join(", ");
        }
        else if (entry.type === SchemaItemType.UserChoice) {
            const ebots = en.celldata.split(",");
            if (ebots.length > 0) {
                const users = [...Array.from(rstore.getPwaStore().activeusers.values()).slice().sort((u1, u2) => {
                        if (u1.type != u2.type) {
                            if (u1.type === 'manageruser') {
                                return -1;
                            }
                            if (u2.type === 'manageruser') {
                                return 1;
                            }
                            return 0;
                        }
                        return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
                    }).map(u => ({ id: u.id, name: u.name, type: ChoiceType.Text }))];
                if (!entry.multiselect) {
                    const cval = ebots[0];
                    for (const val of users) {
                        if (cval === (val.id)) {
                            choices.push(val);
                            break;
                        }
                    }
                }
                else {
                    for (const val of users) {
                        if (ebots.indexOf(val.id) !== -1) {
                            choices.push(val);
                        }
                    }
                }
            }
            en.displaystr = choices.map(c => c.name).join(", ");
        }
        else if (entry.type === SchemaItemType.Remote) {
            const uent = entry;
            let uentID = uent.id;
            if (uentID.indexOf("----") !== -1) {
                const bits = uentID.split("----");
                if (bits.length > 1) {
                    uentID = bits[1];
                }
            }
            const id = uentID + "---" + uent.sid + "---" + uent.cid;
            const vals = rstore.getPwaStore().dependentdata.get(id);
            if (vals) {
                // now we have this
                const bits = en.celldata.split(",");
                if (bits && bits.length > 0) {
                    remotevals = vals.filter((val) => bits.indexOf(val.id) !== -1).sort((r1, r2) => {
                        const r1index = bits.indexOf(r1.id);
                        const r2index = bits.indexOf(r2.id);
                        return r1index - r2index;
                    });
                    if (remotevals.length > 0) {
                        en.displaystr = remotevals.map((val) => val.value).join(", ");
                    }
                }
            }
        }
        if (en.celldata && entry.type === SchemaItemType.URL) {
            try {
                udata = JSON.parse(en.celldata);
                en.displaystr = "<a href=\"" + udata.url + "\" >" + udata.name + "</a>";
            }
            catch (ex) { }
        }
        if (en.linkedids && en.linkedids.length > 0 && entry.type === SchemaItemType.Objects) {
            const pmap = new Map();
            (_a = en.objsdec) === null || _a === void 0 ? void 0 : _a.forEach((ob) => pmap.set("objs-" + ob.id, ob));
            const smap = new Map();
            (_b = en.schsdec) === null || _b === void 0 ? void 0 : _b.forEach((sj) => smap.set(sj.id, sj));
            for (const l of en.linkedids) {
                if (l.startsWith("objs-")) {
                    const obj = pmap.get(l);
                    if (obj) {
                        const tds = obj.type === ProjectObjectType.Document ? obj.thumbdetail : undefined;
                        obids.push({
                            id: l,
                            type: 'document',
                            obj: obj,
                            name: obj.name,
                            thumdet: tds
                        });
                        vids.add(l);
                    }
                }
                else if (l.startsWith("dch-")) {
                    const parts = l.split("-");
                    if (parts.length > 2) {
                        const did = parts[1];
                        const sid = parts[2];
                        const sh = smap.get(sid);
                        if (sh) {
                            obids.push({
                                id: l,
                                type: 'datasheet',
                                obj: sh.name,
                                name: sh.name
                            });
                            vids.add(l);
                        }
                    }
                }
            }
            en.displaystr = obids.map(c => { var _a; return (_a = c.name) !== null && _a !== void 0 ? _a : ''; }).join(", ");
        }
        let showalert = rstore.getPwaStore().checkRowEnnrtyNeedAlert(this.props.rowui, en.cellid);
        return React.createElement(Box, { width: this.props.width !== undefined ? this.props.width + "px" : undefined, backgroundColor: showalert ? '#ee5555' : '' },
            entry.type === SchemaItemType.ID && React.createElement(Box, null,
                React.createElement(Text, null, getDisplayForID(en)),
                " "),
            entry.type === SchemaItemType.SquenceNumber && React.createElement(Box, null,
                React.createElement(Text, null, en.celldata),
                " "),
            entry.type === SchemaItemType.CustomDisplayCol && React.createElement(CustomDisplayItemCont, { itemid: entry.id, row: this.props.rowui, item: entry, subrowindex: this.props.subrowindex }),
            entry.type === SchemaItemType.Text && React.createElement(Box, { dangerouslySetInnerHTML: { __html: en.celldata } }),
            entry.type === SchemaItemType.Date && React.createElement(Flex, { flexWrap: 'wrap', w: '100%' },
                React.createElement(Text, null, DateLib.displayDateWIthFormat(en.celldata, entry.inlcudetime, (_c = entry.excludedate) !== null && _c !== void 0 ? _c : false))),
            entry.type === SchemaItemType.Choice && choices.length > 0 && !entry.multiselect &&
                React.createElement(Flex, { w: '100%', align: 'center' },
                    choices.length > 0 && choices[0].color !== undefined && choices[0].color.length > 0 && React.createElement(Box, { _hover: { cursor: 'pointer' }, key: 'main_col_blob' + choices[0].id, borderWidth: 1, borderColor: '#404040', title: choices[0].name, marginRight: '5px', borderRadius: '10px', width: '20px', height: '20px', bg: choices[0].color }),
                    React.createElement(Text, null, choices != undefined && choices.length > 0 ? choices[0].name : '')),
            entry.type === SchemaItemType.Choice && choices.length > 0 && entry.multiselect && React.createElement(Box, { w: '100%' }, choices.map((ch) => React.createElement(Flex, { align: 'center', key: 'tag_col_' + ch.id },
                ch.color && ch.color.length > 0 && React.createElement(Box, { _hover: { cursor: 'pointer' }, borderWidth: 1, borderColor: '#404040', title: ch.name, margin: '2px', borderRadius: '10px', width: '20px', height: '20px', bg: ch.color }),
                React.createElement(Tag, { key: ch.id, m: '3px' }, ch.name)))),
            entry.type === SchemaItemType.UserChoice && !entry.multiselect && React.createElement(Flex, { w: '100%', justify: 'center' },
                React.createElement(Text, null, choices != undefined && choices.length > 0 ? choices[0].name : '')),
            entry.type === SchemaItemType.Remote && React.createElement(Flex, { flexWrap: 'wrap', w: '100%', justify: 'center' }, remotevals.map((ch, index) => {
                return React.createElement(Box, { margin: '5px', key: ch.id + '_main', width: '100%' },
                    React.createElement(RemoteView, { index: index, item: entry, rtItem: ch }),
                    React.createElement(Tag, { key: ch.id, m: '3px' }, ch.value));
            })),
            entry.type === SchemaItemType.UserChoice && entry.multiselect && React.createElement(Flex, { flexWrap: 'wrap', w: '100%', justify: 'center' }, choices.map((ch) => React.createElement(Tag, { key: ch.id, m: '3px' }, ch.name))),
            entry.type === SchemaItemType.Color && React.createElement(Box, { w: '20px', h: '20px', borderRadius: '10px', bg: en.celldata }),
            entry.type === SchemaItemType.Boolean && React.createElement(Flex, { w: '100%', align: 'center' }, en.celldata === 'true' && React.createElement(Icon, { width: '22px', height: '22px', as: TiTick, color: 'green' })),
            entry.type === SchemaItemType.Number && React.createElement(Flex, { w: '100%', paddingRight: '5px' }, en.celldata),
            entry.type === SchemaItemType.FormInput && React.createElement(Flex, { align: 'center' },
                React.createElement(Text, null, entry.enablesecret ? '****' : en.celldata),
                entry.enablesecret && React.createElement(Icon, { onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.copyLinkPassword(en.celldata, entry.name);
                    }, as: FaClipboard, ml: '5px' })),
            entry.type === SchemaItemType.URL && udata !== undefined && React.createElement(Link, { href: (udata.url), isExternal: true },
                ((udata.name !== undefined && udata.name !== '') ? udata.name :
                    udata.url),
                React.createElement(Icon, { as: FiExternalLink, mx: '2px' })),
            entry.type === SchemaItemType.Objects && React.createElement(Flex, { flexWrap: 'wrap', maxW: '100%', mt: '10px' }, obids.map((obj, index) => {
                let name = "";
                let shwthumb = false;
                let ratio = -1;
                let tdet = {
                    name: '',
                    width: 0,
                    height: 0
                };
                if (obj.type === 'document') {
                    const bo = obj.obj;
                    name = bo.name;
                    if (bo.type === ProjectObjectType.Document) {
                        const do1 = bo;
                        if (do1.thumbdetail) {
                            let zerothum = -1;
                            for (const fg of do1.thumbdetail) {
                                if (fg.name.startsWith("0_thumb")) {
                                    if (fg.width > 0 && fg.height > 0) {
                                        zerothum = fg.height / fg.width;
                                        tdet = fg;
                                    }
                                    break;
                                }
                            }
                            if (zerothum > 0) {
                                shwthumb = true;
                                ratio = zerothum;
                            }
                        }
                    }
                }
                else {
                    name = obj.obj + "";
                }
                if (shwthumb) {
                    return React.createElement(Box, { key: obj.id + "_" + index, w: '100px', onClick: () => {
                            // lets open this
                            if (ThumbNailStore.activethumid) {
                                ThumbNailStore.setActiveThumb("");
                            }
                        } },
                        React.createElement(Flex, { align: 'center' },
                            React.createElement(ThumbNail, { clickcb: () => {
                                }, rowid: en.rowid, cellid: en.cellid, entryid: en.id, obj: obj.obj, ratio: ratio, thumbdet: tdet }),
                            !ThumbNailStore.loadingthumbs.has(obj.obj.documentreference) &&
                                ThumbNailStore.thumbs.has(obj.obj.documentreference) &&
                                React.createElement(Observer, null, () => React.createElement(Icon, { as: FaEye, ml: '5px', _hover: { cursor: 'pointer' }, onClick: (e) => {
                                        if (ThumbNailStore.activethumid) {
                                            ThumbNailStore.setActiveThumb("");
                                        }
                                        if (obj.type === 'document') {
                                            if (rstore.getPwaStore().online) {
                                                this.documentClicked(obj.obj, en.rowid, en.id, en.cellid);
                                            }
                                            else {
                                                toast("Sorry, content is not available offline. Please ensure that your device is connected to the interent.", {
                                                    position: 'top-right',
                                                    type: 'error'
                                                });
                                            }
                                        }
                                        else if (obj.type === 'datasheet') {
                                        }
                                    } }))),
                        React.createElement(Flex, { align: 'center' },
                            React.createElement(Tag, { ml: '5px', key: obj.id, margin: '4px' },
                                React.createElement(TagLabel, { _hover: { cursor: 'pointer' }, onClick: () => {
                                        if (rstore.getPwaStore().online) {
                                            this.documentClicked(obj.obj, en.rowid, en.id, en.cellid);
                                        }
                                        else {
                                            toast("Sorry, content is not available offline. Please ensure that your device is connected to the interent.", {
                                                position: 'top-right',
                                                type: 'error'
                                            });
                                        }
                                    } }, name))));
                }
                return React.createElement(Tag, { onClick: () => {
                        // lets open this
                    }, key: obj.id, margin: '4px' },
                    React.createElement(TagLeftIcon, { as: obj.type === 'document' ? FcDocument : FcDataSheet }),
                    React.createElement(TagLabel, { whiteSpace: 'normal', _hover: { cursor: 'pointer' }, onClick: () => {
                            if (obj.type === 'document') {
                                if (rstore.getPwaStore().online) {
                                    this.documentClicked(obj.obj, en.rowid, en.id, en.cellid);
                                }
                                else {
                                    toast("Sorry, content is not available offline. Please ensure that your device is connected to the interent.", {
                                        position: 'top-right',
                                        type: 'error'
                                    });
                                }
                            }
                            else if (obj.type === 'datasheet') {
                            }
                        } }, name));
            })));
    }
}
DisplayItemRow.contextType = StoreContext;
let CustomDisplayItemCont = class CustomDisplayItemCont extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        const rstore = this.context;
        if (rstore.getPwaStore().loadingrows) {
            return React.createElement(React.Fragment, null);
        }
        // if(rstore.getPwaStore().fetchingupdates) {
        //     return <></>
        // }
        return React.createElement(PwaCustomDisplayItemUI, { key: Math.random() + "", itemid: this.props.itemid, storevale: (Data) => { var _a, _b; return (_b = (_a = this.props).storevale) === null || _b === void 0 ? void 0 : _b.call(_a, Data); }, row: this.props.row, item: this.props.item, subrowindex: this.props.subrowindex });
    }
};
CustomDisplayItemCont.contextType = StoreContext;
CustomDisplayItemCont = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomDisplayItemCont);
let ThumbNail = class ThumbNail extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
        this.state = {
            hover: false
        };
    }
    async componentDidMount() {
        const rstore = this.context;
        if (!ThumbNailStore.thumbs.has(this.props.obj.documentreference)) {
            if (!ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference)) {
                ThumbNailStore.setLoadingThumb(this.props.obj.documentreference);
                rstore.getPwaStore().addDocumentEntity(this.props.obj.id, this.props.obj);
                const page = await rstore.getPwaStore().getPdfViewerContent(this.props.obj.documentreference, "0_thumb", this.props.rowid, rstore.getPwaStore().getActiveFormUserId(), this.props.obj.accesskey, this.props.cellid, this.props.entryid, this.props.obj.id);
                ThumbNailStore.addThumb(this.props.obj.documentreference, page !== null && page !== void 0 ? page : '');
            }
        }
    }
    render() {
        var _a, _b;
        return React.createElement(Box, { ref: (re) => this.myref = re, width: '100px', height: 75 + 'px', pos: 'relative' },
            ThumbNailStore.loadingthumbs.has(this.props.obj.documentreference) && React.createElement(Text, null, "Loading.."),
            ThumbNailStore.thumbs.has(this.props.obj.documentreference) && React.createElement("img", { onMouseDown: () => {
                    console.log("oops");
                }, onClick: (e) => {
                    e.stopPropagation();
                    if (this.props.obj.documentreference === ThumbNailStore.activethumid)
                        return;
                    ThumbNailStore.setActiveThumb(this.props.obj.documentreference);
                    if (this.myref) {
                        let left = this.myref.getBoundingClientRect().x;
                        if (window.innerWidth < 400) {
                            left = 0;
                        }
                        else if ((window.innerWidth - left) < 350) {
                            const diff = window.innerWidth - left;
                            const cover = 350 - diff;
                            let newleft = left - cover;
                            if (newleft < 0) {
                                newleft = 0;
                            }
                            left = newleft;
                        }
                        ThumbNailStore.setLeftPos(left);
                        ThumbNailStore.setTopPos(this.myref.getBoundingClientRect().y);
                    }
                    ThumbNailStore.clickcb = () => this.props.clickcb();
                }, width: '100px', height: '75px', style: { objectFit: 'contain', width: 100, height: 75, cursor: 'pointer' }, src: (_a = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _a !== void 0 ? _a : '' }),
            this.state.hover && React.createElement("img", { style: { position: 'absolute', top: 0, left: 0, width: 300, height: 200, objectFit: 'contain' }, src: (_b = 'data:image/png;base64,' + ThumbNailStore.thumbs.get(this.props.obj.documentreference)) !== null && _b !== void 0 ? _b : '' }));
    }
};
ThumbNail.contextType = StoreContext;
ThumbNail = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ThumbNail);
let ListDisplay = class ListDisplay extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        var _a;
        let displayitems = ((_a = this.props.item.items) !== null && _a !== void 0 ? _a : []).map((it) => (Object.assign(Object.assign({}, it.item), { id: this.props.item.id + "----" + it.item.id })));
        const sortedmap = new Map();
        let sortedrows = [];
        displayitems.forEach((item) => {
            const ens = this.props.ens.get(item.id);
            if (ens) {
                ens.sort(sortEntriesByRow);
                ens.forEach((en) => {
                    if (en.subrowindex) {
                        if (sortedrows.indexOf(en.subrowindex) === -1) {
                            sortedrows.push(en.subrowindex);
                        }
                        if (!sortedmap.has(en.subrowindex)) {
                            sortedmap.set(en.subrowindex, new Map());
                        }
                        const cellid = en.cellid;
                        let cellmap = sortedmap.get(en.subrowindex);
                        if (!cellmap) {
                            cellmap = new Map();
                            sortedmap.set(en.subrowindex, cellmap);
                        }
                        cellmap.set(cellid, [en]);
                    }
                });
            }
        });
        sortedrows.sort((s1, s2) => {
            const parts1 = s1.split("----");
            const parts2 = s2.split("----");
            if (parts1[0] === parts2[0]) {
                // if(parts1.length < 2 || parts2.length < 2) {
                //     return -1;
                // }
                return parts1[1].localeCompare(parts2[1]);
            }
            return parseInt(parts1[0]) - parseInt(parts2[0]);
        });
        const totalwidth = window.innerWidth < 600 ? (window.innerWidth - 35) : Math.floor((window.innerWidth - 195) / 2);
        let swidth = displayitems.length * 120;
        if (swidth < totalwidth) {
            swidth = totalwidth;
        }
        return React.createElement(Box, { mt: '10px', ml: '10px', w: this.props.isGrid ? undefined : window.innerWidth < 600 ? (window.innerWidth - 55) + "px" : (Math.floor((window.innerWidth - 195) / 2)) + 'px', maxW: this.props.isGrid ? undefined :
                window.innerWidth < 600 ? (window.innerWidth - 55) + "px" : (Math.floor((window.innerWidth - 195) / 2)) + 'px', overflowX: this.props.isGrid ? 'hidden' : 'auto', minH: '40px' },
            !this.props.isGrid && React.createElement(Flex, { color: this.props.rowui.uirowindex % 2 === 0 ? cardcolors[0][0] : cardcolors[1][0], wordBreak: 'break-word', width: 150 * displayitems.length, alignItems: 'center', justifyContent: 'center', borderBottomColor: 'lightgray', borderBottomWidth: '1px' }, displayitems.map((item, index) => React.createElement(Flex, { justifyContent: 'center', paddingLeft: '5px', key: item.id + "_header" + index, w: '150px', maxW: '150px' },
                React.createElement(Text, { fontWeight: 'bold' }, item.name)))),
            sortedrows.map((sr, index) => {
                return React.createElement(Flex, { width: 150 * displayitems.length, alignItems: 'center', justifyItems: 'center', wordBreak: 'break-word', borderBottomColor: this.props.isGrid ? 'lightgray' : 'lightgray', borderBottomWidth: index < (sortedmap.size - 1) ? this.props.isGrid ? '1px' : '1px' : '0px', mt: '5px', key: sr + "_row" },
                    displayitems.map((item) => {
                        const enmap = sortedmap.get(sr);
                        const key = item.id + "_" + sr;
                        if (!enmap) {
                            return React.createElement(Box, { paddingLeft: '5px', key: key, w: '150px', maxW: '150px' });
                        }
                        const en = enmap === null || enmap === void 0 ? void 0 : enmap.get(item.id);
                        if (!en) {
                            return React.createElement(Box, { paddingLeft: '5px', key: key, w: '150px', maxW: '150px' });
                        }
                        return React.createElement(Flex, { justifyContent: 'center', paddingLeft: '5px', key: key + "_cont", w: '150px', maxW: '150px' },
                            React.createElement(DisplayItemRow, { width: 150, rowui: this.props.rowui, item: item, entry: enmap, isGrid: this.props.isGrid, subrowindex: sr }));
                    }),
                    sortedrows.length === 0 && React.createElement(Box, { w: '100%', h: '20px' }));
            }));
        /*
                                                return <Box ml={'10px'} w={window.innerWidth < 600 ? (window.innerWidth - 35) + "px" : '500px'}
                                                    bg={'whiteAlpha.400'} boxShadow={'0 0 5px #b6b6b6'} >
                                                    <Box padding='5px'>
                                                        {displyitemsrow.map((entrys, index) => {
                                                            return <Flex key={this.props.item.id + "_entryitems" + "_" + index} w='100%' justifyContent={'space-between'}>
                                                                {entrys.map((en) => <Box key={en.id} w='240px' >
                                                                    <Text fontWeight={'bold'}>{en.name}</Text>
                                                                    <DisplayItemRow item={en} entry={this.props.ens} />
                                                                </Box>)}
                                                            </Flex>
                                                        })}
                                                    </Box>
                                                </Box>*/
    }
};
ListDisplay = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ListDisplay);
let RemoteView = class RemoteView extends React.Component {
    constructor(prop) {
        super(prop);
        this.myref = null;
    }
    componentDidMount() {
    }
    render() {
        const rstore = this.context;
        const ritem = this.props.item;
        const rtextra = ritem.rtextra;
        if (rtextra === undefined || rtextra.length === 0) {
            return React.createElement(Box, null);
        }
        const rowid = this.props.rtItem.rowid;
        if (!rowid) {
            return React.createElement(Box, null);
        }
        let rtDisplayItems = [];
        const mid = ritem.id + "---" + ritem.sid + "---" + ritem.cid;
        for (const ext of rtextra) {
            let uentID = ritem.id;
            if (uentID.indexOf("----") !== -1) {
                const bits = uentID.split("----");
                if (bits.length > 1) {
                    uentID = bits[1];
                }
            }
            const id = uentID + "---" + ritem.sid + "---" + ext.cid;
            if (id == mid)
                continue;
            const items = rstore.getPwaStore().dependentdata.get(id);
            if (items !== undefined) {
                for (const it of items) {
                    if (it.rowid === rowid) {
                        let ri = {
                            id: it.id,
                            label: ext.name,
                            rowid: it.rowid,
                            value: it.value,
                            objects: it.objects,
                            cellid: it.cellid,
                            schemaid: it.schemaid
                        };
                        rtDisplayItems.push(ri);
                        break;
                    }
                }
            }
        }
        if (rtDisplayItems.length === 0) {
            return React.createElement(Box, null);
        }
        return React.createElement(Box, { w: '100%', ref: (re) => this.myref = re, className: 'remote-thumbnail', pos: 'relative', backgroundColor: '#FFFFFF' },
            React.createElement(Box, { onClick: (e) => {
                    e.stopPropagation();
                    // if (this.props.obj.documentreference === ThumbNailStore.activethumid) return;
                    RemotePopupViewStore.setActiveId(this.props.item.id);
                    if (this.myref) {
                        RemotePopupViewStore.reset();
                        RemotePopupViewStore.setLeftPos(this.myref.getBoundingClientRect().x);
                        RemotePopupViewStore.setTopPos(this.myref.getBoundingClientRect().y);
                        RemotePopupViewStore.setBGColor('#FFFFFF');
                        RemotePopupViewStore.addRtDisplayItems(rtDisplayItems);
                    }
                }, width: '100%', height: '100px' }, rtDisplayItems.map((en) => React.createElement(Box, { w: '100%', key: en.id + "_tn" },
                React.createElement(Text, { fontWeight: 'bold', className: 'remote-thumbnail-text' }, en.label),
                React.createElement(Text, { className: 'remote-thumbnail-text' }, en.value)))));
    }
};
RemoteView.contextType = StoreContext;
RemoteView = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], RemoteView);
