export var LiveType;
(function (LiveType) {
    LiveType["NewLetr"] = "NewLetr";
    LiveType["TagChange"] = "TagChange";
    LiveType["UpdateOrgContact"] = "UpdateOrgContact";
    LiveType["DeleteLetr"] = "DeleteLetr";
    LiveType["NewMessage"] = "NewMessage";
    LiveType["EditMessage"] = "EditMessage";
    LiveType["DeleteMessage"] = "DeleteMessage";
    LiveType["Reply"] = "Reply";
    LiveType["ReplyEdit"] = "ReplyEdit";
    LiveType["ReplyDelete"] = "ReplyDelete";
    LiveType["NewInvite"] = "NewInvite";
    LiveType["UpdateLetr"] = "UpdateLetr";
    LiveType["DeleteDevice"] = "DeleteDevice";
    LiveType["NewPublicContact"] = "NewPublicContact";
    LiveType["NewManagedLetr"] = "NewManagedLetr";
    LiveType["UnreadUpdate"] = "UnreadDetails";
    LiveType["ExternalInviteAccepted"] = "ExternalInviteAccepted";
    LiveType["ExternalInviteDeclined"] = "ExternalInviteDeclined";
    LiveType["ActiveLetrCall"] = "ActiveLetrCall";
    LiveType["CallActioned"] = "CallActioned";
    LiveType["CallEnded"] = "CallEnded";
})(LiveType || (LiveType = {}));
export var EventType;
(function (EventType) {
    EventType[EventType["Update"] = 0] = "Update";
    EventType[EventType["New"] = 1] = "New";
})(EventType || (EventType = {}));
export const parseEvent = (event) => {
    const levent = JSON.parse(event);
    if (!levent || !levent.message) {
        return 'unknown';
    }
    if (levent.message.type === LiveType.NewLetr) {
        return "NewLetr";
    }
    else if (levent.message.type === LiveType.TagChange) {
        return "TagChange";
    }
    else if (levent.message.type === LiveType.UpdateOrgContact) {
        return "UpdateOrgContact";
    }
    else if (levent.message.type === LiveType.ActiveLetrCall) {
        const message = levent.message;
        const parts = message.message;
        const ret = {
            activecallletrid: message.message
        };
        return ret;
    }
    else if (levent.message.type === LiveType.NewInvite) {
        return "NewInvite";
    }
    else if (levent.message.type === LiveType.NewMessage) {
        const levent2 = JSON.parse(event);
        return { msg: levent2.message.message };
    }
    else if (levent.message.type === LiveType.DeleteLetr) {
        return { letrid: levent.message.message };
    }
    else if (levent.message.type === LiveType.DeleteMessage) {
        const levent2 = JSON.parse(event);
        return { dmsg: levent2.message.message };
    }
    else if (levent.message.type === LiveType.EditMessage) {
        const levent2 = JSON.parse(event);
        return { emsg: levent2.message.message };
    }
    else if (levent.message.type === LiveType.DeleteDevice) {
        return "deleteme";
    }
    else if (levent.message.type === LiveType.UpdateLetr) {
        const levent2 = JSON.parse(event);
        return { letr: levent2.message.message };
    }
    else if (levent.message.type === LiveType.Reply) {
        const levent2 = JSON.parse(event);
        return { rmsg: levent2.message.message };
    }
    else if (levent.message.type === LiveType.ReplyEdit) {
        const levent2 = JSON.parse(event);
        return { remsg: levent2.message.message };
    }
    else if (levent.message.type === LiveType.ReplyDelete) {
        const levent2 = JSON.parse(event);
        return { rdmsg: levent2.message.message };
    }
    else if (levent.message.type === LiveType.NewPublicContact) {
        return "NewPublicContact";
    }
    else if (levent.message.type === LiveType.NewManagedLetr) {
        return { email: levent.message.message };
    }
    else if (levent.message.type === LiveType.UnreadUpdate) {
        const levent2 = JSON.parse(event);
        return { detail: levent2.message.message };
    }
    else if (levent.message.type === LiveType.ExternalInviteAccepted ||
        levent.message.type === LiveType.ExternalInviteDeclined) {
        const ret = {
            message: levent.message.message
        };
        return ret;
    }
    else if (levent.message.type === LiveType.CallActioned) {
        const ret = {
            callid: levent.message.message
        };
        return ret;
    }
    else if (levent.message.type === LiveType.CallEnded) {
        const ret = {
            activecallid: levent.message.message
        };
        return ret;
    }
    return "unknown";
};
export const instanceOfCallActioned = (object) => {
    return 'callid' in object;
};
export const instanceOfCallEnded = (object) => {
    return 'activecallid' in object;
};
export const instanceOfActiveCallLetr = (object) => {
    return 'activecallletrid' in object;
};
export const instanceOfUnreadDetail = (object) => {
    return 'detail' in object;
};
export const instanceOfExternalContact = (object) => {
    return 'message' in object;
};
export const instanceOfUpdateLetrLive = (object) => {
    return 'letr' in object;
};
export const instanceOfDeleteLetr = (object) => {
    return 'letrid' in object;
};
export const instanceOfNewMessage = (object) => {
    return 'msg' in object;
};
export const instanceOfEditMessage = (object) => {
    return 'emsg' in object;
};
export const instanceOfDeleteMessage = (object) => {
    return 'dmsg' in object;
};
export const instanceOfReply = (object) => {
    return 'rmsg' in object;
};
export const instanceOfReplyEdit = (object) => {
    return 'remsg' in object;
};
export const instanceOfDeleteReply = (object) => {
    return 'rdmsg' in object;
};
export const instanceOfNewManagedLetr = (object) => {
    return 'email' in object;
};
