var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Box, Button, Checkbox, Flex, Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Text } from '@chakra-ui/react';
import { Select as CSelect } from 'chakra-react-select';
import { observer } from 'mobx-react';
import React, { Suspense } from 'react';
import { ScaleLoader } from 'react-spinners';
import DatePicker from "react-datepicker";
import { SketchPicker } from "react-color";
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { AppColor } from '../const/AppDefaults';
const ReactQuill = React.lazy(async () => await import("react-quill"));
let Panel = class Panel extends React.Component {
    constructor(prop) {
        super(prop);
    }
    renderRow() {
        const fields = this.props.items;
        let displaymap = new Map();
        this.props.items.map((it) => displaymap.set(it.id, it));
        return React.createElement(Box, { key: "filter", paddingLeft: '10px', paddingRight: '10px' },
            React.createElement(Select, { width: '250px', size: 'sm', placeholder: 'Select Report', onChange: (e) => {
                    const schemaitem = displaymap.get(e.currentTarget.value);
                    if (schemaitem !== undefined) {
                        this.props.state.addValue(schemaitem.id, { id: schemaitem.id, fielditem: schemaitem, aschending: true });
                    }
                } }, this.props.items.map((tab) => React.createElement("option", { key: tab.id, value: tab.id }, tab.name))));
    }
    render() {
        return React.createElement(Box, { pos: 'relative', w: '100%', maxH: '500px' },
            React.createElement(Flex, { pos: 'absolute', w: '100%', align: 'center', justify: 'flex-end', backgroundColor: 'white', borderBottomWidth: '1px', borderBottomColor: 'lightgray' },
                React.createElement(Button, { disabled: this.props.state.valuesbyid.size === 0 ? true : false, onClick: () => {
                        this.props.onApply();
                    } }, "Apply"),
                React.createElement(Icon, { ml: '10px', h: '22px', w: '22px', as: MdClose, color: AppColor.DeleteIconColor, _hover: { cursor: 'pointer' }, onClick: () => {
                        this.props.state.clear();
                        this.props.onClosePress();
                    } })),
            React.createElement(Box, { w: '100%', overflow: 'auto', maxH: '400px' }, this.renderRow()));
    }
};
Panel = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], Panel);
export const PwaSortPanel = (prop) => React.createElement(Panel, Object.assign({}, prop));
export default PwaSortPanel;
let CheckboxUI = class CheckboxUI extends React.Component {
    constructor(prop) {
        super(prop);
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(Checkbox, { defaultChecked: this.props.defaultvalue, onChange: (e) => {
                    this.props.onChange(e.currentTarget.checked);
                } }));
    }
};
CheckboxUI = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CheckboxUI);
let TextInput = class TextInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
        ];
        this.modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['clean'],
            ],
        };
    }
    render() {
        return React.createElement(Suspense, { fallback: React.createElement(Flex, { style: { height: '100%' }, justify: 'center', align: 'center' },
                React.createElement(ScaleLoader, { color: 'green' })) },
            React.createElement(ReactQuill, { theme: "snow", ref: (ref) => {
                    this.ref = ref;
                }, placeholder: 'Contains', defaultValue: this.props.defaultvalue, modules: this.props.richtext ? this.modules : { toolbar: false }, formats: this.props.richtext ? this.formats : [], onChange: (content, delta, source, editor) => {
                    if (this.ref)
                        this.props.onChange(editor.getHTML(), editor.getText());
                } }));
    }
};
TextInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], TextInput);
let CustomNumberInput = class CustomNumberInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            value: prop.defaultvalue
        };
    }
    render() {
        return React.createElement(NumberInput, { allowMouseWheel: true, value: this.state.value, onChange: (val) => {
                const ch = val;
                if ((!ch || this.props.minvalue === undefined || parseFloat(ch) >= this.props.minvalue) &&
                    (!ch || this.props.maxvalue === undefined || parseFloat(ch) <= this.props.maxvalue)) {
                    this.setState({ value: ch });
                    this.props.onChange(val);
                }
            }, min: this.props.minvalue, max: this.props.maxvalue },
            React.createElement(NumberInputField, null),
            React.createElement(NumberInputStepper, null,
                React.createElement(NumberIncrementStepper, null),
                React.createElement(NumberDecrementStepper, null)));
    }
};
CustomNumberInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], CustomNumberInput);
let ColorInput = class ColorInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            color: this.props.defaultvalue
        };
    }
    convertRGBToString(rgb) {
        return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + rgb.a + ")";
    }
    convertRGBStringToRGB(rgb) {
        const clerstr = rgb.replace("rgba(", "").replace(")", "");
        const parts = clerstr.split(",");
        return { r: parseInt(parts[0]), g: parseInt(parts[1]), b: parseInt(parts[2]), a: parseFloat(parts[3]) };
    }
    render() {
        return React.createElement(Box, null,
            this.state.color !== '' && React.createElement(Box, { mb: '5px', w: '20px', h: '20px', borderRadius: '10px', bg: this.state.color }),
            this.state.color === '' && React.createElement(Box, { mb: '5px' },
                React.createElement(Text, null, "Select Color")),
            React.createElement(SketchPicker, { color: this.convertRGBStringToRGB(this.state.color), onChange: ({ rgb }) => {
                    const cl = this.convertRGBToString(rgb);
                    this.props.onChange(cl);
                    this.setState({ color: cl });
                } }));
    }
};
ColorInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ColorInput);
let DateInput = class DateInput extends React.Component {
    constructor(prop) {
        super(prop);
        let date;
        if (prop.defaultvalue) {
            if (this.props.defaultvalue) {
                try {
                    date = new Date(this.props.defaultvalue);
                }
                catch (e) {
                }
            }
        }
        this.state = {
            date: date
        };
    }
    render() {
        return React.createElement(Box, null,
            React.createElement(DatePicker, { showTimeInput: this.props.showtime, selected: this.state.date, showTimeSelectOnly: this.props.disbaledate, dateFormat: (this.props.disbaledate ? "" : this.props.format) + "" + (this.props.showtime ? " HH:mm" : ''), onChange: (date) => {
                    this.props.onChange(date.getTime());
                    this.setState({ date: date });
                } }));
    }
};
DateInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], DateInput);
let ChoiceInput = class ChoiceInput extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            id: this.props.defaultvalue.split(",")
        };
    }
    render() {
        if (this.props.multiselect) {
            return React.createElement(Box, null,
                React.createElement(CSelect, { isMulti: true, options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), selectedOptionStyle: "check", value: this.props.list.filter((lt) => {
                        if (this.state.id.indexOf(lt.id) != -1) {
                            return true;
                        }
                        return false;
                    }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                        this.setState({ id: e.map((e) => e.value) });
                        this.props.onChange(e.map((lt) => lt.value).join(","));
                    }, closeMenuOnSelect: false }));
        }
        return React.createElement(Box, null,
            React.createElement(CSelect, { selectedOptionStyle: "check", options: this.props.list.map((lt) => ({ label: lt.name, value: lt.id })), value: this.props.list.filter((lt) => {
                    if (this.state.id.indexOf(lt.id) != -1) {
                        return true;
                    }
                    return false;
                }).map((lt) => ({ label: lt.name, value: lt.id })), onChange: (e) => {
                    this.setState({ id: [e.value] });
                    this.props.onChange(e.value);
                } }));
    }
};
ChoiceInput = __decorate([
    observer,
    __metadata("design:paramtypes", [Object])
], ChoiceInput);
