import { comm } from '@/Comm/comm';
import { AuthHeaders as AuthHeader } from '@@/AuthHeader';
import { UserDevice } from '@@/Device';
import { DeviceMetaData } from '@@/DeviceMetadata';
import { LetrwingCrypto } from '@@/LetrwingCommonCrypto';
import { ServerPath } from '@@/ServerPath';
import { Auth } from './AuthStore';
import { InMemoryRepo } from './InMemoryDb';
export const DeviceIDTypename = "DeviceID";
export class DeviceIIDInfo {
    constructor() {
        this.deviceid = "";
        this._id = "";
    }
}
class DeviceStore {
    async getOrgVerification() {
        const ret = await comm.get(ServerPath.getOrgverification);
        return ret;
    }
    async getDevices() {
        const header = [];
        const kp = LetrwingCrypto.getNewKeypair();
        header.push({ key: AuthHeader.RESPONSEKEY, value: kp.publickey });
        const devicesbox = await comm.get(ServerPath.MYDevices, header);
        if (devicesbox === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(devicesbox, kp);
        if (dmsg == null) {
            return undefined;
        }
        const ret = [];
        for (let i = 0; i < dmsg.devices.length; i++) {
            const msg = dmsg.devices[i];
            const metainfo = await this.decryptDeviceInfo(msg);
            if (metainfo === undefined) {
                console.log("Found corrupted entry in devices list");
            }
            else {
                const ud = new UserDevice();
                ud.id = msg.id;
                ud.numberofsession = msg.numberofsession;
                ud.metadat = metainfo;
                ud.lastloggedintime = msg.lastloggedintime;
                ret.push(ud);
            }
        }
        return ret;
    }
    async decryptDeviceInfo(device) {
        var _a;
        try {
            const info = device.extrainfo;
            const dkp = (_a = InMemoryRepo.getUserLocker()) === null || _a === void 0 ? void 0 : _a.deviceInfoKey;
            if (dkp !== undefined) {
                const debox = JSON.parse(LetrwingCrypto.getUTF8FromBase64(info));
                const dinfo = LetrwingCrypto.decryptRSABox(debox, dkp);
                if (dinfo === null) {
                    return undefined;
                }
                return dinfo;
            }
            return undefined;
        }
        catch (ex) {
            console.log(ex);
            return undefined;
        }
    }
    getDeviceMetadata() {
        const type = "Browser " + navigator.userAgent;
        const platform = navigator.platform;
        const meta = new DeviceMetaData();
        meta.deviceType = "Browser";
        meta.deviceOS = platform;
        meta.deviceBrand = type;
        meta.deviceModelName = navigator.appVersion;
        meta.deviceName = type + " " + platform;
        return meta;
    }
    async acceptPolicies() {
        const ret = await comm.get(ServerPath.acceptPolicy);
        if (ret !== undefined && ret) {
            return true;
        }
        return false;
    }
    async recoveryCodeDownloaded() {
        const ret = await comm.get(ServerPath.recoveryDownloaded);
        if (ret !== undefined && ret) {
            return true;
        }
        return false;
    }
    async getPolicies() {
        const ret = await comm.get(ServerPath.getAllPolicies);
        if (ret === undefined) {
            return [];
        }
        return ret;
    }
    async checkUserAcceptedPolicies() {
        // if not then just navigate them to the policy page that's it
        let ret = await comm.get(ServerPath.getUserPolicyAcceptace);
        return ret;
    }
    async checkrecoveryDownloadedCode() {
        // if not then just navigate them to the policy page that's it
        let ret = await comm.get(ServerPath.downloadedRecoveryCode);
        return ret;
    }
    getRecoverycode(mypassword, cb) {
        Auth.getValidatePasswordHeader(mypassword, async (header) => {
            if (header === undefined) {
                cb(undefined);
                return false;
            }
            const sres = await comm.get(ServerPath.getMyRecoveryBox, [header]);
            if (sres === undefined || !sres) {
                cb(undefined);
                return false;
            }
            // now lets try decrypting this box now!
            const mpwd = InMemoryRepo.getMasterPassword();
            if (!mpwd) {
                cb(undefined);
                return false;
            }
            const sec = LetrwingCrypto.decryptSecretBox(sres, mpwd);
            cb(sec);
            return true;
        });
    }
    getMyRecoveryCode(cb) {
        const mypassword = InMemoryRepo.getUserPassword();
        if (!mypassword) {
            cb(undefined);
            return false;
        }
        this.getRecoverycode(mypassword, cb);
    }
}
export const DStore = new DeviceStore();
