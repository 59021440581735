export class DeviceMetaData {
    constructor() {
        this.deviceBrand = "";
        this.deviceManufacturer = "";
        this.deviceOS = "";
        this.deviceModelName = "";
        this.deviceName = "";
        this.deviceType = "";
    }
}
export class UserDevice {
    constructor() {
        this.id = "";
        this.metadat = new DeviceMetaData();
        this.numberofsession = 0;
    }
}
export class EncryptedUserDevice {
    constructor() {
        this.id = "";
        this.extrainfo = "";
        this.numberofsession = 0;
        this.lastloggedintime = 0;
    }
}
export class EncryptedUserDevices {
    constructor() {
        this.devices = [];
    }
}
