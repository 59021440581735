import { comm } from "@/Comm/comm";
import { ServerPath } from "@@/ServerPath";
import { DEFAULT_TAGS } from '@@/Defaults';
import { SecStore } from "./SecurityStore";
import { InMemoryRepo } from "@/background/store/InMemoryDb";
import { ChatTag, Tag } from '@@/Tags';
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { TagsRequest } from '@@/Response';
import { BEvent } from "@/events/BackgroundEvents";
class TagStore {
    constructor() {
        this.tags = new Map();
        BEvent.addSubscriberForAppState((state) => {
            this.tags = new Map(); // if we have state change we dont need this to be here.. 
        });
    }
    checkAndLoadTags() {
        if (this.tags.size === 0) {
            this.loadTopLevelTags();
        }
        return Array.from(this.tags.values());
    }
    getDirectMessageTag() {
        return ChatTag.id;
    }
    async getDefaultTag() {
        return "all";
    }
    loadTopLevelTags() {
        const tags = DEFAULT_TAGS.DefaultTags;
        for (const t of tags) {
            this.tags.set(t.id, t);
        }
        return tags;
    }
    async loadChildTags() {
        try {
            const mp = InMemoryRepo.getMasterPassword();
            if (mp === undefined) {
                return undefined;
            }
            const coke = InMemoryRepo.getCommKey();
            if (coke === undefined) {
                return undefined;
            }
            let tagsres = await comm.get(ServerPath.getTags);
            if (tagsres === undefined) {
                return undefined;
            }
            const rtags = tagsres.tags;
            const ptags = [];
            for (const stag of rtags) {
                const pbox = await SecStore.decryptPasswordBox(stag.box, mp);
                const ts = stag.tag;
                if (pbox !== undefined) {
                    for (const t of ts) {
                        if (!t.parent) {
                            continue; // we dont need this anymore
                        }
                        try {
                            const name = JSON.parse(LetrwingCrypto.getUTF8FromBase64(t.name));
                            const dname = LetrwingCrypto.decryptSecretBox(name, pbox.password);
                            if (dname !== null && dname) {
                                const tg = new Tag(t.id, dname, t.applyToAll, t.category, t.icon, t.childrenEnabled, false, false, false, t.parent);
                                tg.color = t.color;
                                ptags.push(tg);
                                this.tags.set(t.id, tg);
                            }
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }
                }
            }
            BEvent.publishChildTag(ptags);
        }
        catch (ex) {
        }
    }
    async loadTopLevelTags_old() {
        try {
            let tagsres = await comm.get(ServerPath.getTags);
            // now if we have tags great if not then create some and then we done!!
            if (tagsres === undefined) {
                return undefined;
            }
            const mp = InMemoryRepo.getMasterPassword();
            if (mp === undefined) {
                return undefined;
            }
            const coke = InMemoryRepo.getCommKey();
            if (coke === undefined) {
                return undefined;
            }
            if (tagsres.tags.length === 0) {
                // lets create default one and 
                const storagepassword = await SecStore.generateAndStoreNewPasswordBox(mp, coke);
                if (storagepassword === undefined) {
                    return undefined;
                }
                const stags = [];
                const tags = DEFAULT_TAGS.DefaultTags;
                for (const tag of tags) {
                    const name = LetrwingCrypto.secretBox(tag.name, storagepassword.password);
                    const savetag = new Tag(tag.id, LetrwingCrypto.getBase64(LetrwingCrypto.getBinFromUTF8(JSON.stringify(name))), tag.applyToAll, tag.category, tag.icon, tag.childrenEnabled, tag.isDefault, tag.isGroupTag, tag.isPrivateTag);
                    stags.push({
                        tag: savetag,
                        passwordBox: storagepassword.id
                    });
                }
                const req = new TagsRequest();
                req.stags = stags;
                // now we have the tags lets just store them!
                const res = await comm.post(ServerPath.saveTags, req);
                if (res === undefined) {
                    return undefined;
                }
                tagsres = res;
            }
            const rtags = tagsres.tags;
            const ptags = [];
            for (const stag of rtags) {
                const pbox = await SecStore.decryptPasswordBox(stag.box, mp);
                const ts = stag.tag;
                if (pbox !== undefined) {
                    for (const t of ts) {
                        try {
                            const name = JSON.parse(LetrwingCrypto.getUTF8FromBase64(t.name));
                            const dname = LetrwingCrypto.decryptSecretBox(name, pbox.password);
                            if (dname !== null && dname) {
                                const isDefault = t.isDefault || dname === DEFAULT_TAGS.ALLLetrsName;
                                const isPrivate = t.isPrivateTag || dname === DEFAULT_TAGS.PrivateTagName;
                                const isGroup = t.isGroupTag || dname === DEFAULT_TAGS.GroupTagName;
                                const tg = new Tag(t.id, dname, t.applyToAll, t.category, t.icon, t.childrenEnabled, isDefault, isGroup, isPrivate, t.parent);
                                ptags.push(tg);
                                this.tags.set(t.id, tg);
                            }
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }
                }
            }
            return ptags;
        }
        catch (ex) {
            console.log(ex);
        }
        return undefined;
    }
    async storeNewTags(tags) {
        let rep = [];
        for (const t of tags) {
            const res = await this.storeNewChildTag(t);
            if (res !== undefined) {
                rep = [...rep, ...res];
            }
        }
        return rep;
    }
    async storeNewChildTag(tag) {
        const mp = InMemoryRepo.getMasterPassword();
        if (mp === undefined) {
            return undefined;
        }
        const coke = InMemoryRepo.getCommKey();
        if (coke === undefined) {
            return undefined;
        }
        const storagepassword = await SecStore.generateAndStoreNewPasswordBox(mp, coke);
        if (storagepassword === undefined) {
            return undefined;
        }
        const name = LetrwingCrypto.secretBox(tag.name, storagepassword.password);
        const savetag = new Tag(tag.id, LetrwingCrypto.getBase64FromUT8(JSON.stringify(name)), tag.applyToAll, tag.category, tag.icon, tag.childrenEnabled, tag.isDefault, tag.isGroupTag, tag.isPrivateTag, tag.parent);
        savetag.color = tag.color;
        const stags = [];
        stags.push({
            tag: savetag,
            passwordBox: storagepassword.id
        });
        const req = new TagsRequest();
        req.stags = stags;
        // now we have the tags lets just store them!
        const res = await comm.post(ServerPath.saveTags, req);
        if (res === undefined) {
            return undefined;
        }
        for (const stag of res.tags) {
            const pbox = await SecStore.decryptPasswordBox(stag.box, mp);
            if (pbox === undefined) {
                continue;
            }
            const ts = stag.tag;
            const ptags = [];
            for (const t of ts) {
                try {
                    const name = JSON.parse(LetrwingCrypto.getUTF8FromBase64(t.name));
                    const dname = LetrwingCrypto.decryptSecretBox(name, pbox.password);
                    if (dname !== undefined) {
                        const ntag = new Tag(t.id, dname, t.applyToAll, t.category, t.icon, t.childrenEnabled, false, false, false, t.parent);
                        ntag.color = t.color;
                        ptags.push(ntag);
                    }
                }
                catch (ex) {
                }
            }
            return ptags;
        }
    }
}
export const TStore = new TagStore();
