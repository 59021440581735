import { comm, Is403 } from "@/Comm/comm";
import { AppState } from "@/share/AppState";
import { ServerPath } from '@@/ServerPath';
import { LoginTypes } from '@@/LoginDetails';
import { LetrwingLib } from "@/share/Lib";
import { AuthHeaders } from "@/share/AuthHeaders";
import { RegisterRequestType, RegisterStage, RegisterUpdate } from "@/share/models";
import { LocalRepo } from "@/db/Repo";
import { AuthMethod, OrgHeader } from '@@/RegisterModel';
import { LetrwingCrypto } from "@@/LetrwingCommonCrypto";
import { AuthHeaders as AuthHeader } from "@@/AuthHeader";
import { UserPasswordBox } from "@@/EncryptBox";
import { LoginDeviceUpdate, LoginDeviceUpdateState } from "@/share/LoginDevice";
import { CommonCrypto } from "@@/Cryptography";
import { DeleteDeviceRequest, DeviceLoginResponse } from '@@/Device';
import { BEvent } from "@/events/BackgroundEvents";
import { InMemoryRepo } from "./InMemoryDb";
import { SecStore } from "@/background/store/SecurityStore";
import { MKService } from "@/background/store/MasterKeyStore";
import { randomAlphaNumericString } from "@/util/Util";
import { NStore } from "./NotifyStore";
export const LocalDeviceTypeName = "LocalDeviceStoragePacket";
const randomstring = "devicstoragekeyakdhkahdka skadhkdlsnfk skfhsjfh";
class AuthStore {
    constructor() {
        this.setting = 0;
        this.adeviceid = "";
        this.waitingCb = [];
    }
    checkAndSetApp(cb) {
        if (this.setting > 0) {
            this.waitingCb.push(cb);
            return;
        }
        this.setting = 1;
        this.getLocalAuthData(async (err, document) => {
            let resp = { state: AppState.Register, registerTypes: [LoginTypes.Email, LoginTypes.OrgCode] };
            let publish = true;
            const tenant = LetrwingLib.getTenant();
            if (tenant) {
                BEvent.publishTenant(tenant);
            }
            if (err !== null || document === null || (tenant && tenant !== document.tenant)) {
                const res = await comm.get(ServerPath.adminLoginDetails);
                if (res !== undefined) {
                    resp.registerTypes = res.authTypes;
                }
            }
            else {
                if (!tenant && document.tenant) {
                    BEvent.publishTenant(document.tenant);
                }
                const loggedin = await this.isLoggedIn(false);
                if (loggedin === 1) {
                    resp.state = AppState.LoggedIn;
                }
                else {
                    // check if there is persist stuff if there is just try using that first
                    publish = false;
                    this.checkAndLoginThisDevice(document.userid, document.deviceid, cb);
                }
            }
            if (publish) {
                this.publishAppState(resp.state);
                cb(resp);
                this.waitingCb.forEach((cb) => cb(resp));
                this.waitingCb = [];
                this.setting = 0;
            }
        });
    }
    getLocalAuthData(cb) {
        LocalRepo.get(LocalDeviceTypeName, LocalDeviceTypeName, cb);
    }
    async checkAndReconnect() {
        const connected = await this.isLoggedIn();
        if (connected === 1) {
            BEvent.publishReconnect();
        }
        else if (connected === 0) {
            setTimeout(() => {
                this.checkAndReconnect();
            }, 5000);
        }
        else {
            // we have 403 now!
            this.logout();
        }
    }
    async verifyResetPinAndgetBox(email, pin) {
        const req = {
            code: pin,
            email: email
        };
        const res = await comm.post(ServerPath.verifyEmailAndGetResetBox, req, [OrgHeader]);
        return res;
    }
    async resetUserPassword(req, sessionid) {
        const res = await comm.post(ServerPath.resetUserPassword, req, [OrgHeader,
            { key: AuthHeader.OrgSession, value: sessionid }]);
        return res !== null && res !== void 0 ? res : false;
    }
    deleteThiDevice(donecb) {
        this.getLocalAuthData(async (err, doc) => {
            LocalRepo.deleteAllDB();
            if (err != null && doc != null) {
                await this.deleteDevice(doc.deviceid);
            }
            const tenant = LetrwingLib.getTenant();
            if (!tenant) {
                BEvent.publishTenant("");
            }
            InMemoryRepo.reset();
            this.publishAppState(AppState.Register);
            donecb(true);
        });
    }
    async deleteDevice(deviceid) {
        const drequest = new DeleteDeviceRequest();
        drequest.deviceid = deviceid;
        const res = await comm.post(ServerPath.DELETEDEVICE, drequest);
        return res !== null && res !== void 0 ? res : false;
    }
    getValidatePasswordHeader(password, cb) {
        LocalRepo.get(LocalDeviceTypeName, LocalDeviceTypeName, async (err, document) => {
            if (err !== null || document === null || !document.localMatrixBox) {
                cb(undefined);
                return;
            }
            let key = CommonCrypto.getMatrixPasswordFromBox(password, document.localMatrixBox);
            if (key === undefined) {
                cb(undefined);
                return;
            }
            cb({ key: AuthHeader.VerifyPassword, value: key });
        });
    }
    async validatePasswordAndGetMatrix(password, cb) {
        LocalRepo.get(LocalDeviceTypeName, LocalDeviceTypeName, async (err, document) => {
            if (err !== null || document === null || !document.localMatrixBox) {
                cb(undefined, undefined);
                return;
            }
            let key = CommonCrypto.getMatrixPasswordFromBox(password, document.localMatrixBox);
            if (key === undefined) {
                cb(undefined, undefined);
                return;
            }
            const cid = await CommonCrypto.getClientIdForDevice(document.userid, document.deviceid);
            comm.setClientId(cid);
            const matrix = await comm.get(ServerPath.getMatrix, [
                { key: AuthHeader.DeviceMatrixAuth, value: key },
            ]);
            if (matrix === undefined) {
                cb(undefined, undefined);
                return;
            }
            cb(matrix, document);
        });
    }
    async persistPassword(password, sessionid, tenant) {
        const devicekey = LetrwingCrypto.generateNewSecretKey();
        const ebox = LetrwingCrypto.secretBox(password, devicekey);
        const hash = await LetrwingCrypto.getHash256(randomstring);
        const hasdkey = LetrwingCrypto.secretBox(devicekey, hash);
        if (ebox !== undefined) {
            const response = await comm.post(ServerPath.storeMyDevicePasswordBox, ebox);
            if (response !== undefined) {
                const deviceauth = {
                    key: hasdkey,
                    sessionid: sessionid,
                    tenant: tenant,
                    _id: "devicelogindetail"
                };
                LocalRepo.store({
                    typename: "DeviceLoginDetail",
                    data: deviceauth
                });
            }
        }
    }
    async registerAnotherDevice(devicecode) {
        var _a;
        const password = InMemoryRepo.getUserPassword();
        const email = InMemoryRepo.getEmail();
        if (!password || !email) {
            return undefined;
        }
        const rbox = {
            password: password,
            email: email,
            tenant: (_a = InMemoryRepo.getTenant()) !== null && _a !== void 0 ? _a : ""
        };
        const code = randomAlphaNumericString(8);
        const hash = await LetrwingCrypto.getHash256(code);
        const ebox = LetrwingCrypto.secretBox(JSON.stringify(rbox), hash);
        if (ebox === undefined) {
            return undefined;
        }
        const req = {
            devicecode: devicecode,
            box: ebox,
            appcode: ""
        };
        const res = await comm.post(ServerPath.addNewRegisterDeviceBox, req);
        if (res !== undefined) {
            const ret = {
                appcode: code,
                appid: res.appcode
            };
            return ret;
        }
        return undefined;
    }
    checkAndLoginThisDevice(userid, deviceid, cb) {
        LocalRepo.get("DeviceLoginDetail", "devicelogindetail", async (err, document) => {
            let publish = true;
            let resp = { state: AppState.Register, registerTypes: [LoginTypes.Email, LoginTypes.OrgCode] };
            if (err !== null || document === null || !document.key) {
                this.publishAppState(AppState.Logout);
                resp.state = AppState.Login;
            }
            else {
                const hashk = await LetrwingCrypto.getHash256(randomstring);
                const key = LetrwingCrypto.decryptSecretBox(document.key, hashk);
                if (key) {
                    const clientid = await CommonCrypto.getClientIdForDevice(userid, deviceid);
                    const header = [
                        { key: AuthHeader.CLIENTID, value: clientid },
                        { key: AuthHeader.DEVICESESSION, value: document.sessionid },
                    ];
                    const ebox = await comm.get(ServerPath.getMyDevicePasswordBox, header);
                    if (ebox !== undefined) {
                        const password = LetrwingCrypto.decryptSecretBox(ebox, key);
                        if (password) {
                            publish = false;
                            this.loginDevice({
                                password: password,
                                masterpassword: undefined,
                                sessionid: document.sessionid
                            }, (st) => {
                                if (st.state === LoginDeviceUpdateState.InvalidPin ||
                                    st.state === LoginDeviceUpdateState.InvalidDevice) {
                                    this.publishAppState(AppState.Logout);
                                    resp.state = AppState.Login;
                                    this.publishAppState(resp.state);
                                    cb(resp);
                                    this.waitingCb.forEach((cb) => cb(resp));
                                    this.waitingCb = [];
                                    this.setting = 0;
                                }
                                else if (st.state === LoginDeviceUpdateState.Success) {
                                    resp.state = AppState.LoggedIn;
                                    this.adeviceid = deviceid;
                                    cb(resp);
                                    this.waitingCb.forEach((cb) => cb(resp));
                                    this.waitingCb = [];
                                    this.setting = 0;
                                }
                            }, () => {
                                this.publishAppState(AppState.Logout);
                                resp.state = AppState.Login;
                            });
                        }
                    }
                    else {
                        this.publishAppState(AppState.Logout);
                        resp.state = AppState.Login;
                    }
                }
                else {
                    this.publishAppState(AppState.Logout);
                    resp.state = AppState.Login;
                }
            }
            if (publish) {
                this.publishAppState(resp.state);
                cb(resp);
                this.waitingCb.forEach((cb) => cb(resp));
                this.waitingCb = [];
                this.setting = 0;
            }
        });
    }
    async loginDevice(req, updateCb, apperrorcb) {
        const password = req.password;
        this.validatePasswordAndGetMatrix(password, async (matrix, document) => {
            let update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, invalid pin");
            if (matrix === undefined || document === undefined) {
                const update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, invalid pin");
                updateCb(update);
                comm.setClientId("");
                return;
            }
            // now time to validate device password
            update = new LoginDeviceUpdate(LoginDeviceUpdateState.ValidatingDevice);
            updateCb(update);
            const dpassword = await CommonCrypto.getDevicePasswordFrom(password, document.deviceid, document.userid, matrix, document.devicebox);
            if (dpassword === undefined) {
                update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, invalid pin");
                updateCb(update);
                comm.setClientId("");
                return;
            }
            const header = [
                { key: AuthHeader.CLIENTAUTH, value: dpassword },
                { key: AuthHeader.DEVICESESSION, value: req.sessionid },
            ];
            // okay lets make a request to server
            const response = await comm.get(ServerPath.deviceLogin, header);
            if (response === undefined) {
                update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, invalid pin");
                updateCb(update);
                comm.setClientId("");
                return;
            }
            else {
                if (response.loginResponse == DeviceLoginResponse.Success) {
                    if (response.sesisonid) {
                        comm.setSessionid(response.sesisonid);
                        InMemoryRepo.setEmail(response.email);
                        InMemoryRepo.setCommKey(response.sessionpublickey);
                        InMemoryRepo.setSessionId(response.sesisonid);
                        InMemoryRepo.setUserid(response.userid);
                        InMemoryRepo.setUserName(response.fullname);
                        InMemoryRepo.setUserWithtenant(document.tenant + "__<<>>__" + response.userid);
                        InMemoryRepo.setTenantId(document.tenant);
                        InMemoryRepo.setUserPassword(password);
                        InMemoryRepo.matrixbox = matrix;
                        // now we can just get boot starpping stuff that's all
                        this.persistPassword(password, response.sesisonid, document.tenant);
                        update = new LoginDeviceUpdate(LoginDeviceUpdateState.Bootstrapping);
                        updateCb(update);
                        const mlocker = await this.getUserMiniLocker();
                        if (mlocker === undefined) {
                            update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, failed bootstrapping the app. Please try again later or contact support is problem persist");
                            updateCb(update);
                            return;
                        }
                        const ukeys = await CommonCrypto.setupUserLocker(password, mlocker.passwordvault, matrix);
                        if (!ukeys) {
                            update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, failed bootstrapping the app. Please try again later or contact support is problem persist");
                            updateCb(update);
                            return;
                        }
                        InMemoryRepo.setMasterPassword(ukeys.mainpassword);
                        InMemoryRepo.setUserKeys(ukeys.keysLocker);
                        update = new LoginDeviceUpdate(LoginDeviceUpdateState.Success);
                        this.publishAppState(AppState.LoggedIn);
                        updateCb(update);
                        NStore.checkAndAskForNotificationPerm();
                        if (mlocker.refill) {
                            const pbox = await SecStore.generateAndStoreNewPasswordBox(ukeys.mainpassword, response.sessionpublickey);
                            if (pbox === undefined) {
                                apperrorcb === null || apperrorcb === void 0 ? void 0 : apperrorcb("Sorry, but secure communication channel didn't setup properly.Please try signing in again later");
                                return;
                            }
                            const keys = await CommonCrypto.refillCommKeys(mlocker.refill, pbox, ukeys.keysLocker.identityKey, ukeys.keysLocker.signKey);
                            if (keys === undefined) {
                                apperrorcb === null || apperrorcb === void 0 ? void 0 : apperrorcb("Sorry, but secure communication channel didn't setup properly.Please try signing in again later");
                                return;
                            }
                            // now we can store them..
                            const ebox = LetrwingCrypto.box(JSON.stringify(keys), response.sessionpublickey);
                            const res = await comm.post(ServerPath.AddCommBank, ebox);
                            if (!res) {
                                apperrorcb === null || apperrorcb === void 0 ? void 0 : apperrorcb("Sorry, but secure communication channel didn't setup properly.Please try signing in again later");
                                return;
                            }
                        }
                        let updateikey = true;
                        if (response.userindexbox !== undefined) {
                            try {
                                const ikey = LetrwingCrypto.decryptSecretBox(response.userindexbox, ukeys.mainpassword);
                                if (ikey !== undefined && ikey) {
                                    console.log('updateikey inside 1 ' + ikey);
                                    InMemoryRepo.setUserIndexKey(ikey);
                                    updateikey = false;
                                }
                            }
                            catch (ex) { }
                        }
                        if (updateikey) {
                            console.log('updateikey inside');
                            const skey = LetrwingCrypto.generateNewSecretKey();
                            const sbox = LetrwingCrypto.secretBox(skey, ukeys.mainpassword);
                            //lets now add this box
                            const res = await comm.post(ServerPath.addUserIndexbox, sbox);
                            if (res !== undefined && res) {
                                InMemoryRepo.setUserIndexKey(skey);
                            }
                        }
                        if (req.masterpassword !== undefined && req.masterpassword) {
                            MKService.storeMasterKey(req.masterpassword, password, (res) => {
                                if (!res) {
                                    apperrorcb === null || apperrorcb === void 0 ? void 0 : apperrorcb("Sorry, but fialed to store master password on letrwing. Please try saving again  using Admin tab under Settings");
                                }
                            });
                        }
                        BEvent.publishNewLetr("fetch");
                        BEvent.publishNewPublicContacts("fetch");
                        return;
                    }
                    else {
                        update = new LoginDeviceUpdate(LoginDeviceUpdateState.InvalidPin, "Sorry, invalid pin");
                        updateCb(update);
                        comm.setClientId("");
                        return;
                    }
                }
            }
        });
    }
    async checkIsAdmin() {
        const isad = await comm.get(ServerPath.isUserAdmin);
        return isad;
    }
    publishAppState(state) {
        BEvent.publishAppState(state);
    }
    async isLoggedIn(publish = true) {
        const sid = InMemoryRepo.getSessionId();
        if (sid === undefined || !sid) {
            return false;
        }
        const response = await comm.getOr403(ServerPath.index);
        if (response !== undefined && !(response instanceof Is403) && response === "OK") {
            return 1;
        }
        else if (response !== undefined && response instanceof Is403) {
            return -1;
        }
        return 0;
    }
    async getUserMiniLocker() {
        const header = [];
        const kp = LetrwingCrypto.getNewKeypair();
        header.push({ key: AuthHeader.RESPONSEKEY, value: kp.publickey });
        const response = await comm.get(ServerPath.getMiniLocker, header);
        if (response === undefined) {
            return undefined;
        }
        const dmsg = LetrwingCrypto.decryptRSABox(response, kp);
        if (dmsg == null) {
            return undefined;
        }
        const vault = LetrwingCrypto.fromBase64ToUTF8(dmsg.vault);
        const storagBox = LetrwingCrypto.fromBase64ToUTF8(dmsg.storage);
        const encryptbox = LetrwingCrypto.fromBase64ToUTF8(dmsg.encryptbox);
        const ret = new UserPasswordBox(JSON.parse(storagBox), JSON.parse(encryptbox), JSON.parse(vault));
        const type = dmsg.refill;
        return {
            passwordvault: ret,
            refill: type,
            publickey: dmsg.publickey,
            deviceStorageKey: dmsg.deviceStorageKey
        };
    }
    async getAppState() {
        const tenant = LetrwingLib.getTenant();
        const headers = [];
        if (tenant) {
            headers.push({ key: AuthHeaders.TenantHeader, value: tenant });
        }
        const resp = await comm.get(ServerPath.adminLoginDetails, headers);
        let types = [];
        if (resp !== undefined) {
            types = resp.authTypes;
        }
        return { state: AppState.Register, registerTypes: types };
    }
    async register(req) {
        var _a;
        const ret = new RegisterUpdate();
        if (req.type === RegisterRequestType.Email) {
            const res = await this.sendOneTimePassword(req.email);
            const stage = (res === null || res === void 0 ? void 0 : res.auth) === AuthMethod.New ? RegisterStage.NewUserPinVerify : RegisterStage.PinVerify;
            ret.stage = stage;
            ret.tenantid = (_a = res === null || res === void 0 ? void 0 : res.tenantid) !== null && _a !== void 0 ? _a : "";
            return ret;
        }
        else if (req.type === RegisterRequestType.OrgCode) {
        }
        else if (req.type === RegisterRequestType.SSO) {
        }
        ret.stage = RegisterStage.Error;
        return ret;
    }
    async verifyPin(pin, email, tenantid) {
        const ret = new RegisterUpdate();
        if (tenantid) {
            const res = await this.verifyTenantPin(pin, email, tenantid);
            if (res !== undefined) {
                ret.stage = RegisterStage.RegisterDevice;
                ret.identitytoken = res;
            }
            else {
                ret.errormessgae = "Invalid Pin";
                ret.stage = RegisterStage.PinVerify;
            }
            return ret;
        }
        const res = await comm.post(ServerPath.orgVerifyPin, { code: pin, id: email }, [OrgHeader]);
        if (res !== undefined) {
            ret.stage = (res.res === AuthMethod.SentCode && res.organisationsInvites.length)
                ? RegisterStage.RegisterForOrg :
                RegisterStage.NewUser;
            ret.tenantid = "";
            ret.invitesOrg = res.organisationsInvites;
            ret.sessionid = res.sessionid;
        }
        else {
            ret.errormessgae = "Invalid Pin";
            ret.stage = RegisterStage.NewUserPinVerify;
        }
        return ret;
    }
    async acceptinvite(orgid, sessionid) {
        const req = { orgid: orgid };
        const token = await comm.post(ServerPath.acceptInviteToken, req, [OrgHeader,
            { key: AuthHeader.OrgSession, value: sessionid }]);
        return token;
    }
    async verifyTenantPin(pin, email, tenant) {
        const kp = LetrwingCrypto.getNewKeypair();
        const ret = await comm.post(ServerPath.pinVerify, { code: pin, email: email, publickey: kp.publickey }, [{ key: AuthHeader.TenantID, value: tenant }]);
        if (ret !== undefined && ret) {
            const box = JSON.parse(LetrwingCrypto.fromBase64ToUTF8(ret.valueOf()));
            const token = LetrwingCrypto.decryptRSABox(box, kp);
            return token;
        }
        else {
            return undefined;
        }
    }
    async logout() {
        const ret = await comm.get(ServerPath.logout);
        InMemoryRepo.reset();
        LocalRepo.getAndRemove("DeviceLoginDetail", "devicelogindetail", () => { });
    }
    async sendOneTimePassword(email) {
        const res = await comm.post(ServerPath.checkEmailOrg, { email: email }, [OrgHeader]);
        return res;
    }
    orgcodeCheck(code) {
    }
}
export const Auth = new AuthStore();
