export var DeviceAndOrgStage;
(function (DeviceAndOrgStage) {
    DeviceAndOrgStage[DeviceAndOrgStage["NewOrgReq"] = 0] = "NewOrgReq";
    DeviceAndOrgStage[DeviceAndOrgStage["RegUserToOrg"] = 1] = "RegUserToOrg";
    DeviceAndOrgStage[DeviceAndOrgStage["RegisterDevice"] = 2] = "RegisterDevice";
})(DeviceAndOrgStage || (DeviceAndOrgStage = {}));
export var DeviceAndOrgUpdateStage;
(function (DeviceAndOrgUpdateStage) {
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["RegsiteredDevice"] = 0] = "RegsiteredDevice";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["RegisteredOrg"] = 1] = "RegisteredOrg";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["RegisteredUser"] = 2] = "RegisteredUser";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["RegCreationError"] = 3] = "RegCreationError";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["AddingUserError"] = 4] = "AddingUserError";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["DeviceRegError"] = 5] = "DeviceRegError";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["RegisterUserDeviceFail"] = 6] = "RegisterUserDeviceFail";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["ShowOrgCreated"] = 7] = "ShowOrgCreated";
    DeviceAndOrgUpdateStage[DeviceAndOrgUpdateStage["ShowOrgCreatedAndDeviceFailed"] = 8] = "ShowOrgCreatedAndDeviceFailed";
})(DeviceAndOrgUpdateStage || (DeviceAndOrgUpdateStage = {}));
export class DeviceRegReq {
    constructor(password, tenantid, token) {
        this.password = password;
        this.tenantid = tenantid;
        this.token = token;
    }
}
export class NewOrgReq {
    constructor(email, teamname, sessionid, userpin, username, secret) {
        this.email = email;
        this.teamname = teamname;
        this.sessionid = sessionid;
        this.userpin = userpin;
        this.username = username;
        this.secret = secret;
    }
}
export class DeviceAndOrgReq {
    constructor(req, reqStage) {
        this.req = req;
        this.reqStage = reqStage;
    }
}
export class DeviceAndOrgUpdate {
    constructor() {
        this.updateStage = DeviceAndOrgUpdateStage.RegisteredOrg;
        this.masterpassword = "";
    }
}
