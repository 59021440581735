export var DraftType;
(function (DraftType) {
    DraftType["DOCX"] = "DOCX";
    DraftType["PPTX"] = "PPTX";
    DraftType["XLSX"] = "XLSX";
    DraftType["Diagram"] = "Diagram";
})(DraftType || (DraftType = {}));
export var ExcelDraftUpdateType;
(function (ExcelDraftUpdateType) {
    ExcelDraftUpdateType[ExcelDraftUpdateType["action"] = 0] = "action";
    ExcelDraftUpdateType[ExcelDraftUpdateType["sheet"] = 1] = "sheet";
    ExcelDraftUpdateType[ExcelDraftUpdateType["image"] = 2] = "image";
    ExcelDraftUpdateType[ExcelDraftUpdateType["hyperlink"] = 3] = "hyperlink";
    ExcelDraftUpdateType[ExcelDraftUpdateType["cell"] = 4] = "cell";
    ExcelDraftUpdateType[ExcelDraftUpdateType["chart"] = 5] = "chart";
    ExcelDraftUpdateType[ExcelDraftUpdateType["general"] = 6] = "general";
})(ExcelDraftUpdateType || (ExcelDraftUpdateType = {}));
