var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { APPEvent } from "@/events/AppEvents";
import { Apphandler } from "@/ipc/MainAppHandler";
import { action, makeObservable, observable } from "mobx";
class Store {
    constructor() {
        this.thumbs = new Map();
        this.loadingthumbs = new Set();
        this.activethumid = "";
        this.positinleft = 0;
        this.positintop = 0;
        this.thumbpowidt = 300;
        this.thumpoheight = 300;
        this.ispwa = false;
        makeObservable(this);
        APPEvent.addSubscriberForPdfFilePage((page) => {
            var _a;
            if (page.pagename.startsWith("0_thumb") && this.loadingthumbs.has(page.id)) {
                this.addThumb(page.id, (_a = page.page) !== null && _a !== void 0 ? _a : '');
            }
        });
        APPEvent.addSubscriberForActiveLetr((lt) => {
            this.reset();
        });
    }
    setWidth(wi) {
        this.thumbpowidt = wi;
    }
    setHeight(hi) {
        this.thumpoheight = hi;
    }
    setActiveThumb(id) {
        this.activethumid = id;
    }
    setTopPos(pos) {
        this.positintop = pos;
    }
    setLeftPos(pos) {
        this.positinleft = pos;
    }
    addThumb(id, page) {
        this.thumbs.set(id, page);
        this.loadingthumbs.delete(id);
    }
    reset() {
        this.thumbs.clear();
        this.loadingthumbs.clear();
        this.setLeftPos(0);
        this.setActiveThumb("");
        this.setTopPos(0);
    }
    loadThumb(id, key) {
        if (!this.loadingthumbs.has(id)) {
            this.loadingthumbs.add(id);
            const req = {
                id: id,
                pagename: "0_thumb",
                pagenumber: 0,
                accesskey: key,
            };
            if (!this.ispwa) {
                Apphandler.sendMessage("pdfpage", req);
            }
        }
    }
    setLoadingThumb(id) {
        this.loadingthumbs.add(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", Map)
], Store.prototype, "thumbs", void 0);
__decorate([
    observable,
    __metadata("design:type", Set)
], Store.prototype, "loadingthumbs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "activethumid", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "positinleft", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "positintop", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "thumbpowidt", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "thumpoheight", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setWidth", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setHeight", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setActiveThumb", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setTopPos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setLeftPos", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "addThumb", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Store.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "loadThumb", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setLoadingThumb", null);
export const ThumbNailStore = new Store();
