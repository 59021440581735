export var Capability;
(function (Capability) {
    Capability["ManageDocument"] = "ManageDocument";
    Capability["ViewDocument"] = "ViewDocument";
    Capability["ExportDocument"] = "ExportDocument";
    Capability["UploadDocument"] = "UploadDocument";
    Capability["ManageDatasheet"] = "ManageDatasheet";
    Capability["ViewDatasheet"] = "ViewDatasheet";
    Capability["ManageSchema"] = "ManageSchema";
    Capability["ManageUser"] = "ManageUser";
    Capability["ViewUser"] = "ViewUser";
    Capability["AddDocumentNote"] = "AddDocumentNote";
    Capability["ManageRows"] = "ManageRows";
    Capability["AddDatasheetLink"] = "AddDatasheetLink";
})(Capability || (Capability = {}));
export const getCapLabel = (cap) => {
    switch (cap) {
        case Capability.ManageDocument: {
            return "Manage Document";
        }
        case Capability.ViewDocument: {
            return "View Document";
        }
        case Capability.ManageDatasheet: {
            return "Manage Datasheet";
        }
        case Capability.ViewDatasheet: {
            return "View Datasheet";
        }
        case Capability.ManageSchema: {
            return "Manage Schema";
        }
        case Capability.ManageUser: {
            return "Manage User";
        }
        case Capability.ViewUser: {
            return "View User";
        }
        case Capability.ExportDocument: {
            return "Export Document";
        }
        case Capability.UploadDocument: {
            return "Upload Document";
        }
        case Capability.AddDocumentNote: {
            return "Add Document Note";
        }
        case Capability.AddDatasheetLink: {
            return "Add Datasheet Link";
        }
        case Capability.ManageRows: {
            return "Manage Rows";
        }
    }
};
export var RoleStatus;
(function (RoleStatus) {
    RoleStatus["Live"] = "Live";
    RoleStatus["Dead"] = "Dead";
})(RoleStatus || (RoleStatus = {}));
export var ProjectObjectType;
(function (ProjectObjectType) {
    ProjectObjectType["Folder"] = "Folder";
    ProjectObjectType["Document"] = "Document";
    ProjectObjectType["Bookmark"] = "Bookmark";
    ProjectObjectType["Draft"] = "Draft";
})(ProjectObjectType || (ProjectObjectType = {}));
export function instanceOfProjectEntity(obj) {
    return "type" in obj &&
        "name" in obj &&
        "updatetime" in obj &&
        "parentid" in obj;
}
;
export var UpdateLogType;
(function (UpdateLogType) {
    UpdateLogType["Contact"] = "Contact";
    UpdateLogType["Device"] = "Device";
    UpdateLogType["Letr"] = "Letr";
})(UpdateLogType || (UpdateLogType = {}));
